import * as admin from '../../../apis/admins'
import { createSelector } from 'reselect'
import * as cookies from '../../../utilities/cookies'
import { hideModal } from '../core'
import { setSnackbarMessage } from '../../actions'
import { getUser } from '../../../utilities/userUtilities'

const CREATE_USER_REQUESTED = 'CREATE_USER_REQUESTED'
const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
const CREATE_USER_FAILED = 'CREATE_USER_FAILED'

export const createUser = (email, firstName, lastName, jurisdictionId) => (dispatch) => {
	dispatch({ type: CREATE_USER_REQUESTED, data: {} })

	return admin.createUser(email, firstName, lastName, jurisdictionId).then((response) => {
		if (response && response.isSuccessful === true) {
			dispatch(fetchUsers())
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'SUCCESS', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'FAILED', isSuccess: false }
			})
		}
	})
}

const DISABLE_USER_REQUESTED = 'DISABLE_USER_REQUESTED'
const DISABLE_USER_SUCCESS = 'DISABLE_USER_SUCCESS'
const DISABLE_USER_FAILED = 'DISABLE_USER_FAILED'

export const disableUser = (id) => (dispatch) => {
	dispatch({ type: DISABLE_USER_REQUESTED, data: {} })

	return admin.disableUser(id).then((response) => {
		if (response && response.isSuccessful === true) {
			dispatch(fetchUsers())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'SUCCESS', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'FAILED', isSuccess: false }
			})
		}
	})
}

const ENABLE_USER_REQUESTED = 'ENABLE_USER_REQUESTED'
const ENABLE_USER_SUCCESS = 'ENABLE_USER_SUCCESS'
const DENABLE_USER_FAILED = 'ENABLE_USER_FAILED'

export const enableUser = (id) => (dispatch) => {
	dispatch({ type: ENABLE_USER_REQUESTED, data: {} })

	return admin.enableUser(id).then((response) => {
		if (response && response.isSuccessful === true) {
			dispatch(fetchUsers())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'SUCCESS', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'FAILED', isSuccess: false }
			})
		}
	})
}

const FETCH_USERS_REQUESTED = 'FETCH_USERS_REQUESTED'
const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
const FETCH_USERS_FAILED = 'FETCH_USERS_FAILED'

export const fetchUsers = () => (dispatch) => {
	dispatch({ type: FETCH_USERS_REQUESTED, data: {} })

	return admin.fetch().then((response) => {
		if (response && response.isSuccessful === true) {
			return dispatch({
				type: FETCH_USERS_SUCCESS,
				data: response.users
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'FAILED', isSuccess: false }
			})
		}
	})
}

const SET_ADMIN_USER_SORT_REQUESTED = 'SET_ADMIN_USER_SORT_REQUESTED'

export const setAdminUserSort = (field, isDescending) => (dispatch, state) => {
	let currentState = state()

	let data = currentState.admin.admins.sort((a, b) => {
		if (isDescending) {
			return a[field] > b[field] ? 1 : -1
		} else {
			return b[field] > a[field] ? 1 : -1
		}
	})
	return dispatch({
		type: SET_ADMIN_USER_SORT_REQUESTED,
		data: { items: data, field: field, isDescending: isDescending }
	})
}

const initial = {
	admin: null,
	admins: [],
	sortField: 'id',
	isDescending: false,
	isCreating: false
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_USERS_SUCCESS:
			return { ...state, admins: action.data }
			break
		case SET_ADMIN_USER_SORT_REQUESTED:
			return {
				...state,
				cases: [ ...action.data.items ],
				sortField: action.data.field,
				isDescending: action.data.isDescending
			}
		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.admin

export const sortAdminUserDirectionSelector = createSelector(mainSelector, (state) => {
	return state.isDescending
})

export const sortAdminUserFieldSelector = createSelector(mainSelector, (state) => {
	return state.sortField
})

export const adminUsersSelector = createSelector(mainSelector, (state) => {
	if (state && state.admins && state.admins.length > 0) {
		let collection = state.admins.map((r) => {
			let name = null
			if (r.firstName && r.lastName) {
				name = `${r.firstName} ${r.lastName}`
			} else if (r.firstName && !r.lastName) {
				name = `${r.firstName}`
			} else if (!r.firstName && r.lastName) {
				name = `${r.lastName}`
			}

			return {
				id: r.id,
				email: r.email,
				name: name ? name : r.email,
				enabled: r.enabled
			}
		})
		return collection
	}
	return []
})
