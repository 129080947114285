import React, { Fragment } from 'react'
import { Typography, Radio, Select, MenuItem } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import QuestionContainer from './QuestionContainer'
const useIconStyles = makeStyles({
	label: {
		'$&svg': {
			width: '2em',
			height: '2em'
		}
	}
})

const renderBubble = (range, classes) => {
	let bubbles = []
	for (let index = 0; index < range; index++) {
		let r = (
			<Grid item style={{ padingLeft: '5px' }}>
				<Grid container>
					<Grid item style={{ width: '24px' }}>
						<Radio color="primary" disabled={true} classes={{ root: classes.label }} />
					</Grid>
				</Grid>
			</Grid>
		)
		bubbles.push(r)
	}
	return bubbles
}

const renderKey = (range) => {
	let bubbles = []
	for (let index = 0; index < range; index++) {
		let r = (
			<Grid item style={{ padingLeft: '15px' }}>
				<Grid container>
					<Grid item style={{ width: '24px' }}>
						<Typography variant="subtitle1">{index}</Typography>
					</Grid>
					<Grid item>
						&nbsp;
					</Grid>
				</Grid>
			</Grid>
		)
		bubbles.push(r)
	}
	return bubbles
}

const renderDropdown = (range, val, onChange) => {
	let arr = [...Array(range).keys()]
	return <Select
		value={val}
		onChange={onChange}
		displayEmpty={true}
		style={{
			fontSize: '17px',
			lineHeight: '1',
			letterSpacing: '0.25px'
		}}
	>
		{arr.map((el) => (
			<MenuItem value={el} id={el}>
				{el}
			</MenuItem>
		))}
	</Select>
}

export default ({ question, positionDisplay }) => {
	const classes = useIconStyles()
	return (

		<Grid data-testid="grouped-question-survey-readonly" container>
			<Grid item xs={12}>
				<QuestionContainer
					title={question.title}
					positionDisplay={positionDisplay}
					instructions={question.instructions}
					displayInstructionAsIcon={question.displayInstructionAsIcon}
					children={
						<Fragment>
							{question && !question.isDropdown && <Grid item style={{ width: '100%' }}>
								<Grid container>
									<Grid item xs={12} sm={4}></Grid>

									<Grid item xs={12} sm={8}>
										<Grid container justify="flex-start">
											{renderKey(question.range)}
										</Grid>
									</Grid>
								</Grid>
							</Grid>}
							{question &&
								question.categories &&
								question.categories
									.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
									.map((r) => {
										return (
											question.isDropdown
												? <Grid item>
													<Grid container data-testid="dropdown-component-range" className="range-component">
														<Grid item xs={10} sm={6}>
															<Typography
																variant='subtitle1'>
																{r.label}
																{r.tag && ` (${r.tag}) `}
															</Typography>
														</Grid>

														<Grid item xs={2} sm={2}>
																{renderDropdown(question.range)}
														</Grid>
													</Grid>
												</Grid>
												: <Grid item>
													<Grid data-testid="grouped-question-preview" container style={{ paddingBottom: '13px', width: '100%' }}>
														<Grid item xs={12} sm={4} md={6}>
															<Typography
																variant='subtitle1'
																style={{ fontSize: '17px', fontWeight: '400' }}>
																{r.label}
																{r.tag && ` (${r.tag}) `}
															</Typography>
														</Grid>

														<Grid item xs={12} sm={8} md={6}>
															<Grid container justify="flex-start">
																{renderBubble(question.range, classes)}
															</Grid>
														</Grid>
													</Grid>
												</Grid>
										)
									})}
						</Fragment>
					}
				/>
			</Grid>
		</Grid>

	)
}
