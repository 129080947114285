import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Pencil from '../assets/pencil'
import { setPhoto } from '../redux/modules/user'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { useTheme, withStyles } from '@material-ui/core'


const styles = (theme) => ({
	gridImageStyle: {
		height: '128px',
		width: '128px',
		zIndex: '1',
		cursor: 'pointer',
		borderRadius: '50%',
		transform: 'translateY(-50%)',
		[theme.breakpoints.down('sm')]: {
			height: '96px',
			width: '96px',
		}
	},
	imageStyle: {
		height: '128px',
		width: '128px',
		objectFit: 'cover',
		boxShadow: 'grey 3px 6px 12px -4px',
		borderRadius: '50%',
		[theme.breakpoints.down('sm')]: {
			height: '96px',
			width: '96px',
		}
	},
	pencilStyle: {
		position: 'absolute',
		transform: 'translate(40px, 35px)',
		borderRadius: '50%',
		cursor: 'pointer',
		[theme.breakpoints.down('sm')]: {
			transform: 'translate(20px, 17px)',
		},
		"& > svg": {
			height: '64px',
			width: '64px',
			[theme.breakpoints.down('sm')]: {
				height: '32px',
				width: '32px',
			},
		}
	}
})

const UploadImage = ({ entity, data, uploadPhoto, onImageUpdated, canEdit, classes }) => {
	const theme = useTheme()

	const [ imageOpacity, setImageOpacity ] = useState(1)
	const [ pencilIndex, setPencilOpacity ] = useState(0)
	const [ imageLoaded, setImageLoaded ] = useState(false)

	const uploadImage = (e) => {
		var file = e.target.files[0]
		uploadPhoto(entity, data.id, file)
		e.target.files = null
	}

	return (
		<Grid item style={{ width: '100%' }}>
			<Grid container justify="center" style={{ maxHeight: '10px' }}>
				<Grid className={classes.gridImageStyle}
					item
					onMouseOver={() => {
						canEdit && setImageOpacity(0.4)
						canEdit && setPencilOpacity(1)
					}}
					onMouseOut={() => {
						canEdit && setImageOpacity(1)
						canEdit && setPencilOpacity(0)
					}}
					onClick={() => canEdit && document.getElementById('file-input').click()}
				>
					<input
						id="file-input"
						type="file"
						accept=".jpg, .png,.bmp"
						name="name"
						style={{ display: 'none' }}
						onChange={uploadImage}
					/>
					{imageLoaded && (
						<div className={classes.pencilStyle}
							style={{opacity: pencilIndex}}
						>
							<Pencil fill={theme.palette.primary.A800} viewBox="0 0 30 30" />
						</div>
					)}
					<img
						className={classes.imageStyle}
						style={{
							opacity: imageOpacity
						}}
						onLoad={() => setImageLoaded(true)}
						src={data.profileImageUrl ? data.profileImageUrl : '/avatar.png'}
					/>
				</Grid>
			</Grid>
		</Grid>
	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
	uploadPhoto: (entity, id, photo) => {
		dispatch(setPhoto(entity, id, photo, ownProps.onImageUpdated))
	}
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withStyles(styles))

export default enhance(UploadImage)
