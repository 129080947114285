import React, { lazy, Suspense } from 'react'
import componentLoader from '../../utilities/componentLoader'

const TreatmentDetail = lazy(() => componentLoader(() => import('./components/TreatmentDetail')))

export default () => (
	<div>
		<Suspense fallback={<div />}>
			<section>
				<TreatmentDetail />
			</section>
		</Suspense>
	</div>
)
