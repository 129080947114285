import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

const ViewProtocolMenuItem = ({ intl, protocol }) => {
	const history = useHistory()
	const onClick = () => {
		history.push(`/protocols/${protocol.id}/details`)
	}

	return (
		<MenuItem
			onClick={() => {
				onClick()
			}}
		>
			{intl.formatMessage({ id: 'app.general.view' })}
		</MenuItem>
	)
}

const enhance = compose(injectIntl)

export default enhance(ViewProtocolMenuItem)
