import React, { lazy, Suspense } from 'react'
import componentLoader from '../../utilities/componentLoader'
import PrivateRoute from '../../routes/PrivateRoute'

const Imports = lazy(() => componentLoader(() => import('./components/Imports')))
const ImportStepper = lazy(() => componentLoader(() => import('./components/ImportStepper')))
const ImportPage = lazy(() => componentLoader(() => import('./components/ImportPage')))

export default () => {
	return (
		<div>
			<Suspense fallback={<div />}>
				<section>
					<PrivateRoute exact={true} path="/imports" component={Imports} />
					<PrivateRoute exact={true} path="/imports/:importId/type/:type/operation/:operation/step/:step" component={ImportStepper} />
					<PrivateRoute exact={true} path="/imports/:importId" component={ImportPage} />
				</section>
			</Suspense>
		</div>
	)
}
