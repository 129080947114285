import React, { useEffect, forwardRef, useImperativeHandle } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import { uuidv4 } from '../../utilities/utilities'
import DefaultFormTextField from '../DefaultFormTextField'
import FormFilledInputSelectLight from '../FormFilledInputSelectLight'
import { useStore, useSelector, shallowEqual, useDispatch, connect } from 'react-redux'
import { fileTypesSelector } from '../../redux/selectors'
import { fetchFileTypes } from '../../redux/actions'
import { change, formValueSelector } from 'redux-form'
import { required } from '../../utilities/validators'
import { useIntl } from 'react-intl'
import FormCheckBox from '../FormCheckBox'
const requiredValidator = required

const formName = 'createQuestion'
const selector = formValueSelector(formName)

const q = forwardRef((props, ref) => {
	const store = useStore()
	const intl = useIntl()
    const types = useSelector(fileTypesSelector, shallowEqual)
	const dispatch = useDispatch()

	useImperativeHandle(ref, () => ({
		getQuestion() {
			return {
				id: uuidv4().replace('-', ''),
				title: selector(store.getState(), 'question'),
				type: 'file',
				instructions: selector(store.getState(), 'instructions'),
				tag: selector(store.getState(), 'tag'),
				isOptional: selector(store.getState(), 'isOptional'),
				fileType: selector(store.getState(), 'fileType'),
				hideImageForPatient: selector(store.getState(), 'hideImageForPatient'),
				displayInstructionAsIcon: selector(store.getState(), 'displayInstructionAsIcon'),
			}
		}
	}))

    useEffect(() => {
		dispatch(fetchFileTypes())
		dispatch(change(formName, 'hideImageForPatient', true))
    }, [])

	useEffect(() => {
		var el = document.getElementsByName('tag')
		if (el != null) {
			el[0].focus()
		}
	}, [])

	const handleHideImageForPatientChange = (e) => {
		dispatch(change(formName, 'hideImageForPatient', e.target.checked))
    }

	return (
		<Grid container direction="column" alignItems="flex-start">
			<Grid item style={{ width: '100%' }}>
				<DefaultFormTextField
					label="Tag"
					variant="filled"
					name="tag"
					fullWidth
					validators={[ requiredValidator ]}
					inputProps={{ maxLength: 254 }}
					maxLength={254}
				/>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				<DefaultFormTextField
					label="Question"
					variant="filled"
					name="question"
					fullWidth
					validators={[ requiredValidator ]}
					inputProps={{ maxLength: 1023 }}
					maxLength={1023}
				/>
			</Grid>

			<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
				<DefaultFormTextField
					label="Instructions"
					variant="filled"
					fullWidth
					name="instructions"
					inputProps={{ maxLength: 2047 }}
					maxLength={2047}
					multiline
					rows="3"
				/>
			</Grid>
			<FormCheckBox
				name="displayInstructionAsIcon"
				color="primary"
				label={intl.formatMessage({ id: 'app.survey.questions.displayInstructionAsIcon' })}
			/>
			<FormCheckBox
				name="isOptional"
				color="primary"
				label={intl.formatMessage({ id: 'app.survey.questions.isOptional' })}
			/>
            <Grid item>
				<Typography variant="h4" style={{ fontWeight: '400', paddingTop: '20px', paddingBottom: '10px' }}>
                    File
                </Typography>
			</Grid>
            <Grid item>
                <Grid container spacing={2}>
                    <Grid item style={{ width: '200px' }}>
                        <FormFilledInputSelectLight
                            label="Type"
                            name="fileType"
                            validators={[requiredValidator]}
                            data={types}
                            style={{ color: 'black' }}
                            fullWidth
						/>
					</Grid>
				</Grid>
                {
					props.fileType == 10 &&
					<Grid item>
						<Grid container style={{ flexDirection: 'row' }}>
							<Grid item style={{ paddingTop: '6px' }}>
								<Typography
									variant="body2"
									style={{ fontSize: '17px', fontWeight: '400' }}
								>
									{intl.formatMessage({ id: 'app.survey.questions.hideImageForPatient.switch' })}
								</Typography>
							</Grid>
							<Grid item>
								<Switch
									checked={props.hideImageForPatient}
									onChange={handleHideImageForPatientChange}
									color="primary"
									inputProps={{ 'aria-label': 'secondary checkbox' }}
								/>
							</Grid>
						</Grid>
					</Grid>
                }
            </Grid>
		</Grid>
	)
})

const mapStateToProps = (state) => ({
	fileType: selector(state, 'fileType'),
	hideImageForPatient: selector(state, 'hideImageForPatient')
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(q)