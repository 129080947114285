import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FormTextField from './FormTextField'
import LanguageSelectorV2 from '../components/LanguageSelectorV2'
import { email, required } from '../utilities/validators'
import Button from '@material-ui/core/Button'
import useTheme from '@material-ui/core/styles/useTheme'
import { injectIntl, FormattedDate } from 'react-intl'
import { useSelector, shallowEqual, useDispatch, connect } from 'react-redux'
import { compose } from 'recompose'
import { isInvitingSelector, languagesSelector, circleSelector } from '../redux/selectors'
import { fetchLanguages, fetchCircle } from '../redux/actions'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { checkCanSponsor } from '../apis/subscription'
import CalmButton from './CalmButton'
import { Radio, RadioGroup } from '@material-ui/core'
import SponsorSelector from './SponsorSelector'

const CASE_FUNDING_TYPE = {
	CIRCLE: 0,
	SELF: 1,
	OTHER: 2
}

const InvitationForm = ({ handleSubmit, onSubmitForm, intl, availableCredits, circleId, fetchCircle, circle, isTeamMember = false, showSponsored = true }) => {
	const theme = useTheme()
    const dispatch = useDispatch()
	const isInviting = useSelector(isInvitingSelector, shallowEqual)
    const languages = useSelector(languagesSelector, shallowEqual)

	const [ sponsored, setSponsored ] = useState(false)
	const [ errorText, setErrorText ] = useState()
	const [ caseFunding, setCaseFunding] = useState(0)

	useEffect(() => {
        fetchCircle(circleId)
        dispatch(fetchLanguages())
    }, [])

	useEffect(() => {
        if(circle) {
			setCaseFunding(circle.defaultCaseFunding)
		}
    }, [circle && circle.id])

	const handleChange = (e) => {
		if (e && e.target && e.target.checked === true) {
			setSponsored(true)
		} else {
			setSponsored(false)
		}
	}

	const prepSubmit = async (values) => {
		setErrorText()
		values.sponsored = sponsored
		values.caseFunding = caseFunding

        if (sponsored) {
            const canSponsorEmail = await checkCanSponsor(values.email, circleId)

            if (!canSponsorEmail || !canSponsorEmail.isSuccessful) 
            {
                let messageId, params = {};

                switch(canSponsorEmail.errorCode)
                {
                	case 17:
						messageId = 'app.invitation.cannotSponsor.notFound';
                		break;
					case 18:
						messageId = 'app.invitation.cannotSponsor.noUsers';
                		break;                		
                	case 29:
                		params = { CircleName: canSponsorEmail.errorMessage };
						messageId = 'app.invitation.cannotSponsor.circleDoesNotHaveSubsctiption';
                		break;                		
                	default:
						params = { SponsorName: canSponsorEmail.sponsorName };
                		messageId = 'app.invitation.cannotSponsor';
                }

                setErrorText(intl.formatMessage({ id: messageId }, params));
            } 
            else 
            {
                onSubmitForm(values)
            }
        } else {
            onSubmitForm(values)
        }
	}

	return (
		<form onSubmit={handleSubmit(prepSubmit)}>
			<Grid
				container
				direction="column"
				className="admin-modal"
			>
				<Grid item style={{ width: '100%', textAlign: 'center' }}>
					<Typography
						variant="h1"
						style={{ color: 'white', paddingBottom: '32px' }}
					>
						{intl.formatMessage({ id: 'app.invitation.inviteMembers' })}
					</Typography>
				</Grid>

				<Grid item style={{ width: '100%' }}>
					<FormTextField
						label={intl.formatMessage({ id: 'app.general.email' }) + '*'}
						fullWidth
						type="text"
						name="email"
						margin="normal"
						variant="filled"
						validators={[required, email]}
					/>
				</Grid>

				<Grid item style={{ width: '100%', minHeight: '90px' }}>
					<LanguageSelectorV2
						label={intl.formatMessage({ id: 'app.general.communicationOnLabel' })}
						fullWidth
						data={languages}
						name="languageId"
						variant="filled"
					/>
				</Grid>
			
				{!isTeamMember && showSponsored && (
					<Grid item style={{ marginTop: '10px', width: '100%', }}>
						<Typography
							variant="body1"
							style={{ color: 'white', paddingBottom: '10px' }}
						>
							{intl.formatMessage({ id: 'app.circles.casesFundedByLabel' })}
						</Typography>
						<RadioGroup>
							<Grid
								container
								spacing={1}
								direction='column'
								style={{ width: '100%' }}
							>
								<Grid item xs="auto">
									<FormControlLabel
										label={intl.formatMessage({ id: 'app.circles.casesFundedByCircleLabel' })}
										control={
											<Radio
												color="secondary"
												onChange={() => setCaseFunding(0)}
												checked={caseFunding == CASE_FUNDING_TYPE.CIRCLE}
											/>
										}
									/>
								</Grid>
								<Grid item xs="auto">
									<FormControlLabel
										label={intl.formatMessage({ id: 'app.circles.casesFundedBySelfLabel' })}
										control={
											<Radio
												color="secondary"
												onChange={() => setCaseFunding(1)}
												checked={caseFunding == CASE_FUNDING_TYPE.SELF}
											/>
										}
									/>
								</Grid>
								<Grid item xs="auto">
									<FormControlLabel
										label={intl.formatMessage({ id: 'app.circles.casesFundedByOtherLabel' })}
										control={
											<Radio
												color="secondary"
												onChange={() => setCaseFunding(2)}
												checked={caseFunding == CASE_FUNDING_TYPE.OTHER}
											/>
										}
									/>
								</Grid>
							</Grid>
						</RadioGroup>
						{caseFunding == CASE_FUNDING_TYPE.OTHER &&
							<div style={{ paddingTop: '10px' }}>
								<SponsorSelector
									label={intl.formatMessage({ id: 'app.circles.casesFundedByLabel' }) + '*'}
									fullWidth
									type="text"
									name="caseSponsorId"
									validators={[caseFunding == CASE_FUNDING_TYPE.OTHER ? required : null]}
									variant="filled"
									defaultLabel={circle.sponsorName}
								/>
							</div>
						}
					</Grid>
				)}
				<Grid
					item
					style={{
						paddingBottom: errorText ? '10px' : '0',
						width: '100%',
						marginTop: '10px',
						textAlign: 'center'
					}}
				>
					<CalmButton
						variant="contained"
						color="primary"
						style={{
							width: '200px'
						}}
						type="submit"
						size="large"
						disabled={isInviting}
					>
						{intl.formatMessage({ id: 'app.invitation.send' })}
					</CalmButton>
				</Grid>

				{errorText && (
					<Grid item style={{ paddingBottom: '8px', width: '100%' }}>
						<Typography variant="caption" style={{ color: 'red' }}>
							{errorText}
						</Typography>
					</Grid>
				)}
			</Grid>
		</form>
	)
}

const mapStateToProps = (state, ownProps) => ({
    circle: circleSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchCircle: (circleId) => dispatch(fetchCircle(circleId)),
})

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)

export default enhance(InvitationForm)

