import React, { Fragment, useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useTheme from '@material-ui/core/styles/useTheme'
import { Link, useRouteMatch } from 'react-router-dom'
import { fetchProtocolVersions } from '../../../redux/actions'
import { protocolSelector, protocolVersionsSelector } from '../../../redux/selectors'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import InputBase from '@material-ui/core/InputBase'
import { useConstant } from '../../../utilities/useConstant'
import { debounceMilliseconds } from '../../../utilities/constants'
import SearchIcon from '@material-ui/icons/Search'
import { Paper, Switch } from '@material-ui/core'
import VersionTable from './VersionTable'
import CompositeLabelPaper from '../../../components/CompositeLabelPaper'
import { ArrowLeftOutlined } from '@material-ui/icons'

var debounce = require('lodash/debounce')

const ProtocolVersions = ({ intl, getVersions, versions }) => {
	const theme = useTheme()
	const match = useRouteMatch()

	const [search, setSearch] = useState()
	const [ showArchived, setShowArchived ] = useState(false)

	useEffect(
		() => {
			if (match.params.protocolId) {
				applyFilter(search, showArchived)
			}
		},
		[]
	)

	const applyFilter = (searchPhrase, showArchived) => {
		if (searchPhrase && searchPhrase.length > 2) {
			getVersions(match.params.protocolId, searchPhrase, showArchived)
		}
		if (!searchPhrase || searchPhrase.length === 0) {
			getVersions(match.params.protocolId, '', showArchived)
		}
	}

	const onChange = (value) => {
		setSearch(value)

		applyFilter(value, showArchived)
	}

	const OnSearchChanged = useConstant(() => debounce(onChange, debounceMilliseconds, { leading: true }))

	const handleChangeShowArchived = (e) => {
		setShowArchived(e.target.checked)

		applyFilter(search, e.target.checked)
	}

	return (
		<div className="admin-fullheight-wrapper">
			<Grid
				container
				spacing={2}
				direction="column"
			>
				<Grid item>
					<Link
						to={`/protocols/${match.params.protocolId}`}
						style={{
							textDecoration: 'none',
							color: '#2e7df6'
						}}
					>
						<ArrowLeftOutlined viewBox="0 0 24 15" />{' '}
						{intl
							.formatMessage({
								id: 'app.protocol.backToRootButtonLabel'
							})
							.toUpperCase()}{' '}
					</Link>
				</Grid>
				<Grid item>
					<Typography variant="h1" id="headerText" >
						{intl.formatMessage({ id: 'app.protocols.versionsHeader' })}
					</Typography>
				</Grid>
				<Grid item>
					<Paper style={{ width: '100%', padding: '10px', boxSizing: 'border-box' }}>
						<InputBase
							fullWidth
							placeholder={intl.formatMessage({ id: 'app.general.searchPlaceholder' })}
							startAdornment={
								<div style={{ margin: '2px 10px 0 4px' }}>
									<SearchIcon />
								</div>
							}
							onChange={(e) => OnSearchChanged(e.target.value)}
							style={{ minHeight: '34px', lineHeight: 'normal' }}
						/>
					</Paper>
				</Grid>
				<Grid item>
					<Grid
						container
						alignItems='center'
					>
						<Grid item>
							<Typography
								variant="h6"
								style={{
									color: "#007aff",
									margin: 0
								}}
							>
								{intl.formatMessage({ id: 'app.protocols.showArchivedLabel' })}
							</Typography>
						</Grid>
						<Grid item>
							<Switch
								checked={showArchived}
								onChange={handleChangeShowArchived}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item style={{ marginTop: '40px' }}>
					<CompositeLabelPaper
						Component={VersionTable}
						data={versions}
						search={search}
						showArchived={showArchived}
						protocolId={match.params.protocolId}
					/>
				</Grid>
			</Grid>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	versions: protocolVersionsSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	getVersions: (id, searchPhrase, showArchived) => dispatch(fetchProtocolVersions(id, searchPhrase, showArchived))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(ProtocolVersions)
