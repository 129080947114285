import React, { useEffect } from 'react'
import { compose } from 'recompose'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'
import ProtocolSelectorControl from './ProtocolSelectorControl'
import OutlinedIconHelpTooltip from '../../../components/OutlinedIconHelpTooltip'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { fetchProtocols, fetchProtocol } from '../../../redux/actions'
import { formattedProtocolsSelector, childProtocolListSelector } from '../../../redux/selectors'
import { required } from '../../../utilities/validators'

const requiredValidator = required
const styles = {
	root: {
		background: 'black'
	},
	input: {
		color: 'white',
		backgroundColor: 'rgb(36, 48, 65)'
	}
}

const ProtocolSelector = ({ intl, protocols, getProtocols, loadSubProtocol, subProtocols }) => {
	return (
		<Grid container>
			<Grid item style={{ width: '100%', paddingBottom: '15px', height: '75px' }}>
				<ProtocolSelectorControl
					name="protocolId"
					label={intl.formatMessage({ id: 'app.circles.searchProtocolLabel' })}
					suggestions={protocols && protocols.filter((r) => r.isActive)}
					validators={[ requiredValidator ]}
					onProtocolSelected={loadSubProtocol}
					noMatchText={intl.formatMessage({ id: 'app.noMatches' })}
				/>
			</Grid>

			<Grid item style={{ paddingLeft: '50px', width: '100%' }}>
				{subProtocols && (
					<Grid container style={{ width: '100%', paddingTop: '10px' }}>
						<Grid item>
							<div
								style={{
									borderBottom: 'dashed 1px white',
									borderLeft: 'dashed 1px white',
									height: 'calc(100% - 50px)',
									marginLeft: '-35px'
								}}
							>
								&nbsp;
							</div>
						</Grid>
						<Grid item style={{ width: '100%', paddingLeft: '20px', paddingTop: '15px' }}>
							<Grid container direction="column">
								<Grid item>
									<Grid
										item
										style={{
											textAlign: 'right',
											alignSelf: 'flex-end',
											color: 'white',
											width: '100%'
										}}
									/>
								</Grid>
								<Grid item style={{ height: '75px' }}>
									<ProtocolSelectorControl
										name="subProtocolId"
										label=""
										suggestions={subProtocols && subProtocols.filter((r) => r.isActive)}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				)}
			</Grid>
		</Grid>
	)
}

const mapStateToProps = (state, ownProps) => ({
	protocols: formattedProtocolsSelector(state),
	subProtocols: childProtocolListSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	loadSubProtocol: (id) => dispatch(fetchProtocol(id))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles), injectIntl)

export default enhance(ProtocolSelector)
