import React from 'react'
import { compose } from 'recompose'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import Add from '@material-ui/icons/Add'
import { showModal } from '../../../redux/actions'
import { useTheme } from '@material-ui/core/styles'
import ModifySponsorModal from './ModifySponsorModal'

const styles = {
	root: {
		background: 'black'
	},
	input: {
		color: 'white',
		backgroundColor: 'rgb(36, 48, 65)'
	}
}

const AddSponsorButton = ({ onCreateClick, intl }) => {
	const theme = useTheme()

	return (
		<Button variant="contained" color="primary" onClick={() => onCreateClick()}>
			<Add style={{ color: 'white', marginRight: '5px', fontSize: '18px', fontWeight: '700' }} />
				{intl.formatMessage({ id: 'app.sponsors.addSponsorButton' })}
		</Button>
	)
}

const mapStateToProps = (state, ownProps) => ({})
const mapDispatchToProps = (dispatch, ownProps) => ({
	onCreateClick: () => dispatch(showModal(ModifySponsorModal,
		{
			mode: 2
		}))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withStyles(styles))

export default enhance(AddSponsorButton)
