import React, { useState, forwardRef, useImperativeHandle, useEffect, Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import DefaultFormTextField from '../DefaultFormTextField'
import { formValueSelector } from 'redux-form'
import { required } from '../../utilities/validators'
import { useStore } from 'react-redux'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'
import { useSelector, shallowEqual } from 'react-redux'
import { languageNameSelector } from '../../redux/selectors'
const requiredValidator = required
const selector = formValueSelector('editQuestion')

export default forwardRef((props, ref) => {
	const { question } = props
	let languages = useSelector(languageNameSelector, shallowEqual)
	const store = useStore()
	const dispatch = useDispatch()
	useEffect(
		() => {
			if (question && question.data) {
				dispatch(change('editQuestion', 'question', question.data.title))
				dispatch(change('editQuestion', 'instructions', question.data.instructions))
				dispatch(change('editQuestion', 'tag', question.data.tag))
			}
		},
		[ question ]
	)

	useImperativeHandle(ref, () => ({
		getQuestion() {
			return {
				id: question.data.id,
				title: selector(store.getState(), 'question'),
				type: 'file',
				canonicalId: question.data.canonicalId,
				instructions: selector(store.getState(), 'instructions'),
				tag: selector(store.getState(), 'tag')
			}
		}
	}))

	return (
		<Grid container direction="column" alignItems="flex-start">
			<Grid item style={{ width: '100%' }}>
				<Typography variant="subtitle2">
					Title ({languages(1)}): {question.data.baseTitle}
				</Typography>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				<DefaultFormTextField
					label={`Title (${languages(question.data.languageId)})`}
					variant="filled"
					fullWidth
					name="question"
					validators={[ requiredValidator ]}
					inputProps={{ maxLength: 1023 }}
					maxLength={1023}
				/>
			</Grid>
            {question.data.baseInstructions && (
				<Fragment>
					<Grid item style={{ width: '100%' }}>
						<Typography variant="subtitle2">
							Instruction ({languages(1)}): {question.data.baseInstructions}
						</Typography>
					</Grid>
					<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
						<DefaultFormTextField
							label={`Instructions (${languages(question.data.languageId)})`}
							variant="filled"
							name="instructions"
							fullWidth
							inputProps={{ maxLength: 2047 }}
							rows="3"
							maxLength={2047}
							multiline
						/>
					</Grid>
				</Fragment>
			)}
		</Grid>
	)
})
