import * as serviceProvider from '../../../apis/serviceProvider'
import * as invitations from '../../../apis/serviceProviderInvitation'
import * as cookies from '../../../utilities/cookies'
import * as resources from '../../../apis/resource'
import history from '../../../utilities/history'
import { createSelector } from 'reselect'
import { hideModal, stopSaving } from '../core'
import { getUser } from '../../../utilities/userUtilities'

const ADD_SERVICEPROVIDER_REQUESTED = 'ADD_SERVICEPROVIDER_REQUESTED'
const ADD_SERVICEPROVIDER_SUCCESS = 'ADD_SERVICEPROVIDER_SUCCESS'
const ADD_SERVICEPROVIDER_FAILED = 'ADD_SERVICEPROVIDER_FAILED'

export const addServiceProvider  = (
	name,
	defaultAssigneeId,
	founderId
) => (dispatch) => {
	dispatch({ type: ADD_SERVICEPROVIDER_REQUESTED, data: {} })

    serviceProvider
		.addServiceProvider(
			name,
			defaultAssigneeId,
			founderId
		)
		.then((serviceProvider) => {
			if (serviceProvider && serviceProvider.isSuccessful) {
				history.push(`/serviceProviders/${serviceProvider.id}`)
				dispatch(hideModal())
				return dispatch({ type: ADD_SERVICEPROVIDER_SUCCESS, data: serviceProvider })
			} else {
				dispatch({ type: ADD_SERVICEPROVIDER_FAILED, data: serviceProvider })
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: `app.errorMessages.${serviceProvider.errorCode}`, isSuccess: false }
				})
			}
		})
	return
}

const SEARCH_SERVICEPROVIDER_REQUESTED = 'SEARCH_SERVICEPROVIDER_REQUESTED'
const SEARCH_SERVICEPROVIDER_SUCCESS = 'SEARCH_SERVICEPROVIDER_SUCCESS'
const SEARCH_SERVICEPROVIDER_FAILED = 'SEARCH_SERVICEPROVIDER_FAILED'

export const searchServiceProviders  = (searchPhrase) => (dispatch) => {
	dispatch({ type: SEARCH_SERVICEPROVIDER_REQUESTED, data: {} })
	serviceProvider.fetchServiceProviders(searchPhrase).then((serviceProviders) => {
		if (serviceProviders && serviceProviders.isSuccessful && serviceProviders.serviceProviders) {
			let data = serviceProviders.serviceProviders

			return dispatch({ type: SEARCH_SERVICEPROVIDER_SUCCESS, data: data })
		} else {
			return dispatch({ type: SEARCH_SERVICEPROVIDER_FAILED, data: {} })
		}
	})
	return
}

const ARCHIVE_CIRCLE_REQUESTED = 'ARCHIVE_CIRCLE_REQUESTED'
const ARCHIVE_CIRCLE_SUCCESS = 'ARCHIVE_CIRCLE_SUCCESS'
const ARCHIVE_CIRCLE_FAILED = 'ARCHIVE_CIRCLE_FAILED'

export const archiveCircle = (id) => (dispatch, getState) => {
	dispatch({ type: ARCHIVE_CIRCLE_REQUESTED, data: {} })
	//serviceProvider.archiveCircle(id).then((response) => {
	//	if (response && response.isSuccessful) {
	//		var state = getState()
	//		if (state && state.serviceProvider && state.serviceProvider.searchCircles && state.serviceProvider.searchCircles.length > 0) {
	//			let r = [...state.serviceProvider.searchCircles]
	//			r.map((r) => {
	//				if (r.id === id) {
	//					r.isArchived = true
	//				}
	//			})
	//			dispatch({
	//				type: 'SET_SNACKBAR_MESSAGE',
	//				data: { message: 'app.circles.successfullyArchived', isSuccess: true }
	//			})
	//			return dispatch({ type: SEARCH_SERVICEPROVIDER_SUCCESS, data: r })
	//		}
	//	} else {
	//		return dispatch({
	//			type: 'SET_SNACKBAR_MESSAGE',
	//			data: { message: 'app.circles.failedToArchive', isSuccess: false }
	//		})
	//	}
	//})
	return
}

const FETCH_SERVICEPROVIDER_REQUESTED = 'FETCH_SERVICEPROVIDER_REQUESTED'
const FETCH_SERVICEPROVIDER_SUCCESS = 'FETCH_SERVICEPROVIDER_SUCCESS'
const FETCH_SERVICEPROVIDER_FAILED = 'FETCH_SERVICEPROVIDER_FAILED'

export const fetchServiceProvider = (id) => (dispatch) => {
	dispatch({ type: FETCH_SERVICEPROVIDER_REQUESTED, data: {} })
	serviceProvider.fetchServiceProvider(id).then((serviceProvider) => {
		if (serviceProvider) {
			return dispatch({ type: FETCH_SERVICEPROVIDER_SUCCESS, data: serviceProvider })
		} else {
			return dispatch({ type: FETCH_SERVICEPROVIDER_FAILED, data: {} })
		}
	})
	return
}

const SET_SERVICEPROVIDER_MEMBER_REQUESTED = 'SET_SERVICEPROVIDER_MEMBER_REQUESTED'
const SET_SERVICEPROVIDER_MEMBER_SUCCESS = 'SET_SERVICEPROVIDER_MEMBER_SUCCESS'
const SET_SERVICEPROVIDER_MEMBER_FAILED = 'SET_SERVICEPROVIDER_MEMBER_FAILED'

export const addServiceProviderMember = (serviceProviderId, userId) => (dispatch) => {
	dispatch({ type: SET_SERVICEPROVIDER_MEMBER_REQUESTED, data: {} })

    return serviceProvider.addServiceProviderMember(serviceProviderId, userId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(getServiceProviderMembersById(serviceProviderId))
			dispatch({ type: SET_SERVICEPROVIDER_MEMBER_SUCCESS, data: response })
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.general.success`, isSuccess: true }
			})
			return dispatch(hideModal())
		} else {
			dispatch({ type: SET_SERVICEPROVIDER_MEMBER_FAILED, data: {} })
			if (response && response.isIneligible) {
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: `app.serviceProviders.failedToInviteIneligibleMember`, isSuccess: false }
				})
			}
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.serviceProviders.failedToAddMember`, isSuccess: false }
			})
		}
	})
	return
}

const SET_SERVICEPROVIDER_INVITATION_MEMBER_REQUESTED = 'SET_SERVICEPROVIDER_INVITATION_MEMBER_REQUESTED'
const SET_SERVICEPROVIDER_INVITATION_MEMBER_SUCCESS = 'SET_SERVICEPROVIDER_INVITATION_MEMBER_SUCCESS'
const SET_SERVICEPROVIDER_INVITATION_MEMBER_FAILED = 'SET_SERVICEPROVIDER_INVITATION_MEMBER_FAILED'

export const inviteServiceProviderMember = (email, serviceProviderId, languageId, sponsored) => (dispatch) => {
    dispatch({ type: SET_SERVICEPROVIDER_INVITATION_MEMBER_REQUESTED, data: {} })

    let user = cookies.get('user')

	return invitations.inviteServiceProviderMember(user.id, email, serviceProviderId, languageId).then((response) => {
        if (response && response.isSuccessful) {
			dispatch(getServiceProviderMembersById(serviceProviderId))
            dispatch({ type: SET_SERVICEPROVIDER_INVITATION_MEMBER_SUCCESS, data: response })
            dispatch({
                type: 'SET_SNACKBAR_MESSAGE',
                data: { message: `app.general.success`, isSuccess: true }
            })
            return dispatch(hideModal())
        } else {
            dispatch({ type: SET_SERVICEPROVIDER_INVITATION_MEMBER_FAILED, data: {} })
            if (response && response.isIneligible) {
                return dispatch({
                    type: 'SET_SNACKBAR_MESSAGE',
                    data: { message: `app.serviceProviders.failedToInviteIneligibleMember`, isSuccess: false }
                })
            }
            return dispatch({
                type: 'SET_SNACKBAR_MESSAGE',
                data: { message: `app.serviceProviders.failedToInviteMember`, isSuccess: false }
            })
        }
    })
    return
}

const ARCHIVE_SERVICEPROVIDER_REQUESTED = 'ARCHIVE_SERVICEPROVIDER_REQUESTED'
const ARCHIVE_SERVICEPROVIDER_SUCCESS = 'ARCHIVE_SERVICEPROVIDER_SUCCESS'
const ARCHIVE_SERVICEPROVIDER_FAILED = 'ARCHIVE_SERVICEPROVIDER_FAILED'

export const archiveServiceProvider = (id) => (dispatch) => {
    dispatch({ type: ARCHIVE_SERVICEPROVIDER_REQUESTED, data: {} })

    return serviceProvider.archiveServiceProvider(id).then((reponse) => {
        if (reponse && reponse.isSuccessful) {
        	 dispatch({
                type: 'SET_SNACKBAR_MESSAGE',
                data: {
                    message: 'app.serviceProviders.archiveSuccessed',
                    isSuccess: true
                }
            })

			dispatch(searchServiceProviders());
        } else {
            dispatch({
                type: 'SET_SNACKBAR_MESSAGE',
                data: {
                    message: 'app.serviceProviders.archiveFailed',
                    isSuccess: true
                }
            })
            return dispatch({ type: ARCHIVE_SERVICEPROVIDER_FAILED, data: {} })
        }
    })
}

const UNARCHIVE_SERVICEPROVIDER_REQUESTED = 'UNARCHIVE_SERVICEPROVIDER_REQUESTED'
const UNARCHIVE_SERVICEPROVIDER_SUCCESS = 'UNARCHIVE_SERVICEPROVIDER_SUCCESS'
const UNARCHIVE_SERVICEPROVIDER_FAILED = 'UNARCHIVE_SERVICEPROVIDER_FAILED'

export const unarchiveServiceProvider = (id) => (dispatch) => {
    dispatch({ type: UNARCHIVE_SERVICEPROVIDER_REQUESTED, data: {} })

    return serviceProvider.unarchiveServiceProvider(id).then((reponse) => {
        if (reponse && reponse.isSuccessful) {
			 dispatch({
                type: 'SET_SNACKBAR_MESSAGE',
                data: {
                    message: 'app.serviceProviders.unarchiveSuccessed',
                    isSuccess: true
                }
            })

			dispatch(searchServiceProviders());
        } else {
            dispatch({
                type: 'SET_SNACKBAR_MESSAGE',
                data: {
                    message: 'app.serviceProviders.unarchiveFailed',
                    isSuccess: true
                }
            })
            return dispatch({ type: UNARCHIVE_SERVICEPROVIDER_FAILED, data: {} })
        }
    })
}

const FETCH_SERVICEPROVIDER_MEMBERS_REQUESTED = 'FETCH_SERVICEPROVIDER_MEMBERS_REQUESTED'
const FETCH_SERVICEPROVIDER_MEMBERS_SUCCESS = 'FETCH_SERVICEPROVIDER_MEMBERS_SUCCESS'
const FETCH_SERVICEPROVIDER_MEMBERS_FAILED = 'FETCH_SERVICEPROVIDER_MEMBERS_FAILED'

export const getServiceProviderMembersById = (id, searchPhrase) => (dispatch) => {
	if (!id) {
		return dispatch({ type: FETCH_SERVICEPROVIDER_MEMBERS_REQUESTED, data: {} })
	}

	return serviceProvider.fetchServiceProviderMembers(id, searchPhrase).then((members) => {
		if (members) {
			let data = members.members

			return dispatch({ type: FETCH_SERVICEPROVIDER_MEMBERS_SUCCESS, data: data })
		} else {
			return dispatch({ type: FETCH_SERVICEPROVIDER_MEMBERS_FAILED, data: {} })
		}
	})
	return
}

const FETCH_CIRCLES_FILTER_REQUESTED = 'FETCH_CIRCLES_FILTER_REQUESTED'
const FETCH_CIRCLES_FILTER_SUCCESS = 'FETCH_CIRCLES_FILTER_SUCCESS'
const FETCH_CIRCLES_FILTER_FAILED = 'FETCH_CIRCLES_FILTER_FAILED'

export const fetchCircleFilters = () => (dispatch) => {
	dispatch({ type: FETCH_CIRCLES_FILTER_REQUESTED, data: {} })

	const options = [
		{ option: 'ALL', label: 'All Circles' },
		{ option: 'SPONSORED', label: 'Sponsored Circles' },
		{ option: 'MANAGE', label: 'Circles I Manage' },
		{ option: 'JOINED', label: 'Joined Circles' },
		{ option: 'ARCHIVED', label: 'Archived Circles' }
	]
	return dispatch({ type: FETCH_CIRCLES_FILTER_SUCCESS, data: options })
}

const SET_SERVICEPROVIDERS_FILTER_REQUESTED = 'SET_SERVICEPROVIDERS_FILTER_REQUESTED'
const SET_SERVICEPROVIDERS_FILTER_SUCCESS = 'SET_SERVICEPROVIDERS_FILTER_SUCCESS'
const SET_SERVICEPROVIDERS_FILTER_FAILED = 'SET_SERVICEPROVIDERS_FILTER_FAILED'

export const setServiceProviderFilter = (filter) => (dispatch) => {
	dispatch({ type: SET_SERVICEPROVIDERS_FILTER_REQUESTED, data: {} })

	return dispatch({ type: SET_SERVICEPROVIDERS_FILTER_SUCCESS, data: filter })
}

const FETCH_CIRCLES_AUTOCOMPLETE_REQUESTED = 'FETCH_CIRCLES_AUTOCOMPLETE_REQUESTED'
const FETCH_CIRCLES_AUTOCOMPLETE_SUCCESS = 'FETCH_CIRCLES_AUTOCOMPLETE_SUCCESS'
const FETCH_CIRCLES_AUTOCOMPLETE_FAILED = 'FETCH_CIRCLES_AUTOCOMPLETE_FAILED'

export const fetchCircleAutoComplete = (searchPhrase, noMatchMessage) => (dispatch) => {
	dispatch({ type: FETCH_CIRCLES_AUTOCOMPLETE_REQUESTED, data: {} })
 //   serviceProvider.fetchCircleAutoComplete(searchPhrase).then((response) => {
	//	if (response && response.isSuccessful) {
	//		if (response.circles && response.circles.length > 0) {
	//			return dispatch({
	//				type: FETCH_CIRCLES_AUTOCOMPLETE_SUCCESS,
	//				data: response.circles
	//			})
	//		} else {
	//			return dispatch({
	//				type: FETCH_CIRCLES_AUTOCOMPLETE_SUCCESS,
	//				data: [{ id: -1, name: noMatchMessage }]
	//			})
	//		}
	//	} else {
	//		return dispatch({ type: FETCH_CIRCLES_AUTOCOMPLETE_FAILED, data: {} })
	//	}
	//})
	return
}

const FETCH_PROTOCOL_CIRCLES_AUTOCOMPLETE_REQUESTED = 'FETCH_PROTOCOL_CIRCLES_AUTOCOMPLETE_REQUESTED'
const FETCH_PROTOCOL_CIRCLES_AUTOCOMPLETE_SUCCESS = 'FETCH_PROTOCOL_CIRCLES_AUTOCOMPLETE_SUCCESS'
const FETCH_PROTOCOL_CIRCLES_AUTOCOMPLETE_FAILED = 'FETCH_PROTOCOL_CIRCLES_AUTOCOMPLETE_FAILED'

export const fetchProtocolCirclesAutoComplete = (protocolId, searchPhrase, noMatchMessage) => (dispatch) => {
	dispatch({ type: FETCH_PROTOCOL_CIRCLES_AUTOCOMPLETE_REQUESTED, data: {} })
	//serviceProvider.fetchCircleByProtocolAutoComplete(protocolId, searchPhrase).then((response) => {
	//	if (response && response.isSuccessful) {
	//		if (response.circles && response.circles.length > 0) {
	//			return dispatch({
	//				type: FETCH_PROTOCOL_CIRCLES_AUTOCOMPLETE_SUCCESS,
	//				data: response.circles
	//			})
	//		} else {
	//			return dispatch({
	//				type: FETCH_PROTOCOL_CIRCLES_AUTOCOMPLETE_SUCCESS,
	//				data: [{ id: -1, name: noMatchMessage }]
	//			})
	//		}
	//	} else {
	//		return dispatch({ type: FETCH_PROTOCOL_CIRCLES_AUTOCOMPLETE_FAILED, data: {} })
	//	}
	//})
	return
}

//const UPLOAD_SERVICEPROVIDER_BACKGROUND_REQUESTED = 'UPLOAD_SERVICEPROVIDER_BACKGROUND_REQUESTED'
//const UPLOAD_SERVICEPROVIDER_BACKGROUND_SUCCESS = 'UPLOAD_SERVICEPROVIDER_BACKGROUND_SUCCESS'
//const UPLOAD_SERVICEPROVIDER_BACKGROUND_FAILED = 'UPLOAD_SERVICEPROVIDER_BACKGROUND_FAILED'

//export const setBackground = (entity, id, photo, onImageUpdated) => (dispatch) => {
//	dispatch({ type: UPLOAD_SERVICEPROVIDER_BACKGROUND_REQUESTED, data: {} })
//	let formData = new FormData()
//	formData.append('files', photo)

//    return serviceProvider.setBackgroundImage(entity, id, formData).then((response) => {
//		if (response) {
//			if (onImageUpdated) dispatch(onImageUpdated(id))
//			return dispatch({ type: UPLOAD_SERVICEPROVIDER_BACKGROUND_SUCCESS, data: response })
//		} else {
//			return dispatch({ type: UPLOAD_SERVICEPROVIDER_BACKGROUND_FAILED, data: {} })
//		}
//	})
//	return
//}

//const RESET_SERVICEPROVIDER_BACKGROUND_REQUESTED = 'RESET_SERVICEPROVIDER_BACKGROUND_REQUESTED'
//const RESET_SERVICEPROVIDER_BACKGROUND_SUCCESS = 'RESET_SERVICEPROVIDER_BACKGROUND_SUCCESS'
//const RESET_SERVICEPROVIDER_BACKGROUND_FAILED = 'RESET_SERVICEPROVIDER_BACKGROUND_FAILED'

//export const resetBackground = (entity, id, onImageUpdated) => (dispatch) => {
//	dispatch({ type: RESET_SERVICEPROVIDER_BACKGROUND_REQUESTED, data: {} })

//	return circleProvider.resetBackgroundImage(entity, id).then((response) => {
//		if (response) {
//			if (onImageUpdated) dispatch(onImageUpdated(id))
//			return dispatch({ type: RESET_SERVICEPROVIDER_BACKGROUND_SUCCESS, data: response })
//		} else {
//			return dispatch({ type: RESET_SERVICEPROVIDER_BACKGROUND_FAILED, data: {} })
//		}
//	})
//	return
//}

const UPDATE_SERVICEPROVIDER_DETAILS_REQUESTED = 'UPDATE_SERVICEPROVIDER_DETAILS_REQUESTED'
const UPDATE_SERVICEPROVIDER_DETAILS_SUCCESS = 'UPDATE_SERVICEPROVIDER_DETAILS_SUCCESS'
const UPDATE_SERVICEPROVIDER_DETAILS_FAILED = 'UPDATE_SERVICEPROVIDER_DETAILS_FAILED'

export const updateServiceProviderDetailsById = (serviceProviderDetails) => (dispatch) => {
	dispatch({ type: UPDATE_SERVICEPROVIDER_DETAILS_REQUESTED, data: {} })
	
	return serviceProvider.updateServiceProviderDetailsById(serviceProviderDetails).then((serviceProviderDetails) => {
		if (serviceProviderDetails && serviceProviderDetails.isSuccessful) {
			dispatch(fetchServiceProvider(serviceProviderDetails.id))
			dispatch(hideModal())
			if (serviceProviderDetails.isArchived) {
				dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.serviceProviders.circleArchived', isSuccess: true }
				})
				history.push('/serviceProviders')
			} else {
				return dispatch({ type: UPDATE_SERVICEPROVIDER_DETAILS_SUCCESS, data: serviceProviderDetails })
			}
		} else {
			if (serviceProviderDetails.errorCode == 56) {
				dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.serviceProviders.member.ineligibleAccount', isSuccess: false }
				})
			}
			return dispatch({ type: UPDATE_SERVICEPROVIDER_DETAILS_FAILED, data: {} })
		}
	})
}

const UPDATE_CIRCLE_TERMS_REQUESTED = 'UPDATE_CIRCLE_TERMS_REQUESTED'
const UPDATE_CIRCLE_TERMS_SUCCESS = 'UPDATE_CIRCLE_TERMS_SUCCESS'
const UPDATE_CIRCLE_TERMS_FAILED = 'UPDATE_CIRCLE_TERMS_FAILED'

export const updateTerms = (id, terms) => (dispatch) => {
	dispatch({ type: UPDATE_CIRCLE_TERMS_REQUESTED, data: {} })
//	return serviceProvider.updateTerms(id, terms).then((circleDetails) => {
//		if (circleDetails && circleDetails.isSuccessful) {
//			dispatch(fetchCircle(id))
//			dispatch(hideModal())
//			if (circleDetails.isArchived) {
//				dispatch({
//					type: 'SET_SNACKBAR_MESSAGE',
//					data: { message: 'app.serviceProviders.circleArchived', isSuccess: true }
//				})
//				history.push('/circles')
//			} else {
//				return dispatch({ type: UPDATE_SERVICEPROVIDER_DETAILS_SUCCESS, data: circleDetails })
//			}
//		} else {
//			return dispatch({ type: UPDATE_SERVICEPROVIDER_DETAILS_FAILED, data: {} })
//		}
//	})
}

const RESEND_SERVICEPROVIDER_INVITATION_REQUESTED = 'RESEND_SERVICEPROVIDER_INVITATION_REQUESTED'
const RESEND_SERVICEPROVIDER_INVITATION_SUCCESS = 'RESEND_SERVICEPROVIDER_INVITATION_SUCCESS'
const RESEND_SERVICEPROVIDER_INVITATION_FAILED = 'RESEND_SERVICEPROVIDER_INVITATION_FAILED'

export const resendServiceProviderInvitation = (invitationId, serviceProviderId) => (dispatch) => {
    dispatch({ type: RESEND_SERVICEPROVIDER_INVITATION_REQUESTED, data: {} })

    let user = cookies.get('user')

	return invitations.resendServiceProviderInvitation(invitationId, serviceProviderId).then((response) => {
        if (response && response.isSuccessful) {
            return dispatch({
                type: 'SET_SNACKBAR_MESSAGE',
                data: { message: 'app.userProfile.teamMembeResendInvitateSuccess', isSuccess: true }
            })
        } else {
            return dispatch({
                type: 'SET_SNACKBAR_MESSAGE',
                data: { message: 'app.userProfile.teamMembeResendInvitateFailed', isSuccess: false }
            })
        }
    })
    return
}

const SET_CIRCLE_MEMBERS_KEY_REQUESTED = 'SET_CIRCLE_MEMBERS_KEY_REQUESTED'
const SET_CIRCLE_MEMBERS_KEY_SUCCESS = 'SET_CIRCLE_MEMBERS_KEY_SUCCESS'
const SET_CIRCLE_MEMBERS_KEY_FAILED = 'SET_CIRCLE_MEMBERS_KEY_FAILED'

export const setCircleMemberKey = (searchTerm) => (dispatch) => {
	dispatch({ type: SET_CIRCLE_MEMBERS_KEY_REQUESTED, data: {} })
	return dispatch({ type: SET_CIRCLE_MEMBERS_KEY_SUCCESS, data: searchTerm })
}

const REINSTATE_CIRCLE_REQUESTED = 'REINSTATE_CIRCLE_REQUESTED'
const REINSTATE_CIRCLE_SUCCESS = 'REINSTATE_CIRCLE_SUCCESS'
const REINSTATE_CIRCLE_FAILED = 'REINSTATE_CIRCLE_FAILED'

export const reinstateCircle = (circleId) => (dispatch, getState) => {
	dispatch({ type: REINSTATE_CIRCLE_REQUESTED, data: {} })

	//return serviceProvider.reinstateCircleArchive(circleId).then((response) => {
	//	if (response && response.isSuccessful) {
	//		var state = getState()
	//		if (state && state.serviceProvider && state.serviceProvider.searchCircles && state.serviceProvider.searchCircles.length > 0) {
	//			let r = [...state.serviceProvider.searchCircles]
	//			r.map((r) => {
	//				if (r.id === circleId) {
	//					r.isArchived = false
	//				}
	//			})
	//			dispatch({
	//				type: 'SET_SNACKBAR_MESSAGE',
	//				data: { message: 'app.circles.successfullyReinstated', isSuccess: true }
	//			})
	//			return dispatch({ type: SEARCH_SERVICEPROVIDER_SUCCESS, data: r })
	//		}
	//	} else {
	//		dispatch({
	//			type: 'SET_SNACKBAR_MESSAGE',
	//			data: { message: 'app.circles.reinstatedFailed', isSuccess: true }
	//		})
	//		return dispatch({ type: REINSTATE_CIRCLE_FAILED, data: {} })
	//	}
	//})
}

const REMOVE_USER_CIRCLE_REQUESTED = 'REMOVE_USER_CIRCLE_REQUESTED'
const REMOVE_USER_CIRCLE_SUCCESS = 'REMOVE_USER_CIRCLE_SUCCESS'
const REMOVE_USER_CIRCLE_FAILED = 'REMOVE_USER_CIRCLE_FAILED'

export const removeUserCircleMember = (member, circleId) => (dispatch) => {
	dispatch({ type: REMOVE_USER_CIRCLE_REQUESTED, data: {} })

	//return serviceProvider.removeUserCircleById(member.email, circleId).then((response) => {
	//	if (response && response.isSuccessful) {
	//		dispatch(fetchCircle(circleId))
	//		dispatch(hideModal())
	//		dispatch({
	//			type: 'SET_SNACKBAR_MESSAGE',
	//			data: { message: 'app.serviceProviders.removeUserCircleSuccess', isSuccess: true }
	//		})
	//		return dispatch({ type: REMOVE_USER_CIRCLE_SUCCESS, data: member.email })
	//	} else {
	//		return dispatch({
	//			type: 'SET_SNACKBAR_MESSAGE',
	//			data: { message: 'app.serviceProviders.removeUserCircleFailed', isSuccess: false }
	//		})
	//	}
	//})
	return
}

const LEAVE_CIRCLE_REQUESTED = 'LEAVE_CIRCLE_REQUESTED'
const LEAVE_CIRCLE_SUCCESS = 'LEAVE_CIRCLE_SUCCESS'
const LEAVE_CIRCLE_FAILED = 'LEAVE_CIRCLE_FAILED'

export const leaveCircle = (circleId) => (dispatch) => {
	dispatch({ type: LEAVE_CIRCLE_REQUESTED, data: {} })
	//return serviceProvider.submitLeaveCircleById(circleId).then((response) => {
	//	if (response && response.isSuccessful) {
	//		dispatch(hideModal())
	//		return dispatch({ type: LEAVE_CIRCLE_SUCCESS, data: circleId })
	//	} else {
	//		return dispatch({
	//			type: 'SET_SNACKBAR_MESSAGE',
	//			data: { message: 'app.serviceProviders.leaveCircleFailed', isSuccess: false }
	//		})
	//	}
	//})
}

const DISTRIBUTE_CIRCLE_RESOURCES_REQUESTED = 'DISTRIBUTE_CIRCLE_RESOURCES_REQUESTED'
const DISTRIBUTE_CIRCLE_RESOURCES_SUCCESS = 'DISTRIBUTE_CIRCLE_RESOURCES_SUCCESS'
const DISTRIBUTE_CIRCLE_RESOURCES_FAILED = 'DISTRIBUTE_CIRCLE_RESOURCES_FAILED'

export const distributeResources = (circleId, numberUsers, numberCases) => (dispatch) => {
	dispatch({ type: DISTRIBUTE_CIRCLE_RESOURCES_REQUESTED, data: {} })
	return resources.distribute(circleId, numberUsers, numberCases).then((response) => {
		if (response) {
			dispatch({ type: DISTRIBUTE_CIRCLE_RESOURCES_SUCCESS, data: response })
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'Distributed Successfully', isSuccess: true }
			})
		} else {
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.errorMessages.${response.errorCode}`, isSuccess: false }
			})
		}
	})
}

const FETCH_SERVICEPROVIDER_AUTOCOMPLETE_REQUESTED = 'FETCH_SERVICEPROVIDER_AUTOCOMPLETE_REQUESTED'
const FETCH_SERVICEPROVIDER_AUTOCOMPLETE_SUCCESS = 'FETCH_SERVICEPROVIDER_AUTOCOMPLETE_SUCCESS'
const FETCH_SERVICEPROVIDER_AUTOCOMPLETE_FAILED = 'FETCH_SERVICEPROVIDER_AUTOCOMPLETE_FAILED'

export const fetchServiceProviderAutoComplete = (searchPhrase) => (dispatch) => {
    dispatch({ type: FETCH_SERVICEPROVIDER_AUTOCOMPLETE_REQUESTED, data: {} })
	serviceProvider.fetchServiceProviderAutoComplete(searchPhrase).then((serviceProviders) => {
		if (serviceProviders) {
			return dispatch({ type: FETCH_SERVICEPROVIDER_AUTOCOMPLETE_SUCCESS, data: serviceProviders.serviceProviders })
        } else {
            return dispatch({ type: FETCH_SERVICEPROVIDER_AUTOCOMPLETE_FAILED, data: {} })
        }
    })
    return
}

const SET_SERVICEPROVIDER_SORT_REQUESTED = 'SET_SERVICEPROVIDER_SORT_REQUESTED'

export const setServiceProvidersSort = (field, isDescending) => (dispatch, state) => {
	let currentState = state()

	let d = [...currentState.serviceProvider.searchServiceProviders]
	let data = d.sort((a, b) => {
		if (isDescending) {
			return a[field] > b[field] ? -1 : 1
		} else {
			return b[field] > a[field] ? -1 : 1
		}
	})

	return dispatch({
		type: SET_SERVICEPROVIDER_SORT_REQUESTED,
		data: { items: data, field: field, isDescending: isDescending }
	})
}
const SET_CIRCLE_MEMBER_SORT_REQUESTED = 'SET_CIRCLE_MEMBER_SORT_REQUESTED'

export const setCircleMemberSort = (field, isDescending) => (dispatch, state) => {
	let currentState = state()

	let d = [...currentState.serviceProvider.serviceProviderMembers]

	if (field === 'name') {
		let data = d.sort((a, b) => {
			let a1 = a['name'] || a['email']
			let b1 = b['name'] || b['email']
			console.log(a1, b1)
			if (isDescending) {
				return a1 > b1 ? -1 : 1
			} else {
				return b1 > a1 ? -1 : 1
			}
		})
		return dispatch({
			type: SET_CIRCLE_MEMBER_SORT_REQUESTED,
			data: { items: data, field: field, isDescending: isDescending }
		})
	} else {
		let data = d.sort((a, b) => {
			if (isDescending) {
				return a[field] > b[field] ? -1 : 1
			} else {
				return b[field] > a[field] ? -1 : 1
			}
		})
		return dispatch({
			type: SET_CIRCLE_MEMBER_SORT_REQUESTED,
			data: { items: data, field: field, isDescending: isDescending }
		})
	}
}

const FETCH_TREATMENT_REQUESTED = 'FETCH_TREATMENT_REQUESTED'
const FETCH_TREATMENT_SUCCESS = 'FETCH_TREATMENT_SUCCESS'
const FETCH_TREATMENT_FAILED = 'FETCH_TREATMENT_FAILED'

export const getTreatmentById = (id) => (dispatch) => {
	dispatch({ type: FETCH_TREATMENT_REQUESTED, data: {} })

	if (!id) {
		dispatch({ type: FETCH_TREATMENT_SUCCESS, data: null });
	}
	else {
  //      serviceProvider.fetchTreatment(id).then((response) => {
		//	if (response && response.isSuccessful) {
		//		if (response.treatment) {
		//			dispatch({ type: FETCH_TREATMENT_SUCCESS, data: response.treatment })
		//		}
		//	} else {
		//		dispatch({ type: FETCH_TREATMENT_FAILED, data: {} })
		//		return dispatch({
		//			type: 'SET_SNACKBAR_MESSAGE',
		//			data: { message: `app.treatments.loadingFailed`, isSuccess: false }
		//		})
		//	}
		//})
	}
	return
}

const FETCH_PATHOLOGY_REQUESTED = 'FETCH_PATHOLOGY_REQUESTED'
const FETCH_PATHOLOGY_SUCCESS = 'FETCH_PATHOLOGY_SUCCESS'
const FETCH_PATHOLOGY_FAILED = 'FETCH_PATHOLOGY_FAILED'

export const getPathologyById = (id) => (dispatch) => {
	dispatch({ type: FETCH_PATHOLOGY_REQUESTED, data: {} })

	if (!id) {
		dispatch({ type: FETCH_PATHOLOGY_SUCCESS, data: null });
	}
	else {
  //      serviceProvider.fetchPathology(id).then((response) => {
		//	if (response && response.isSuccessful) {
		//		if (response.pathology) {
		//			dispatch({ type: FETCH_PATHOLOGY_SUCCESS, data: response.pathology })
		//		}
		//	} else {
		//		dispatch({ type: FETCH_PATHOLOGY_FAILED, data: {} })
		//		return dispatch({
		//			type: 'SET_SNACKBAR_MESSAGE',
		//			data: { message: `app.pathologies.loadingFailed`, isSuccess: false }
		//		})
		//	}
		//})
	}
	return
}

const MAKE_SERVICEPROVIDER_ADMINISTRATOR_REQUESTED = 'MAKE_SERVICEPROVIDER_ADMINISTRATOR_REQUESTED'
const MAKE_SERVICEPROVIDER_ADMINISTRATOR_SUCCESS = 'MAKE_SERVICEPROVIDER_ADMINISTRATOR_SUCCESS'
const MAKE_SERVICEPROVIDER_ADMINISTRATOR_FAILED = 'MAKE_SERVICEPROVIDER_ADMINISTRATOR_FAILED'

export const makeAdministratorServiceProviderMember = (serviceProviderId, userId) => (dispatch) => {
	dispatch({ type: MAKE_SERVICEPROVIDER_ADMINISTRATOR_REQUESTED, data: {} })

	return serviceProvider.makeAdminMemberByServiceProviderUserId(serviceProviderId, userId).then((response) => {
		if (response && response.isSuccessful) {

			dispatch(getServiceProviderMembersById(serviceProviderId));

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.serviceProviders.assignAdministratorSuccess',
					isSuccess: true
				}
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.serviceProviders.assignAdministratorFailed',
					isSuccess: false
				}
			})
		}
	})
	return
}

const REMOVE_USER_SERVICEPROVIDER_REQUESTED = 'REMOVE_USER_SERVICEPROVIDER_REQUESTED'
const REMOVE_USER_SERVICEPROVIDER_SUCCESS = 'REMOVE_USER_SERVICEPROVIDER_SUCCESS'
const REMOVE_USER_SERVICEPROVIDER_FAILED = 'REMOVE_USER_SERVICEPROVIDER_FAILED'

export const removeUserServiceProviderMember = (serviceProviderId, userId) => (dispatch, getState) => {
	dispatch({ type: REMOVE_USER_SERVICEPROVIDER_REQUESTED, data: {} })

	return serviceProvider.removeUserServiceProviderById(serviceProviderId, userId).then((response) => {
		if (response && response.isSuccessful) {
            dispatch(hideModal())
            dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.serviceProviders.removeUserServiceProviderSuccess',
					isSuccess: true
				}
			})

			dispatch(getServiceProviderMembersById(serviceProviderId));

			return dispatch({
				type: REMOVE_USER_SERVICEPROVIDER_SUCCESS
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.serviceProviders.removeUserServiceProviderFailed',
					isSuccess: false
				}
			})
		}
	})
	return
}

const REMOVE_INVITE_SERVICEPROVIDER_REQUESTED = 'REMOVE_INVITE_SERVICEPROVIDER_REQUESTED'
const REMOVE_INVITE_SERVICEPROVIDER_SUCCESS = 'REMOVE_INVITE_SERVICEPROVIDER_SUCCESS'
const REMOVE_INVITE_SERVICEPROVIDER_FAILED = 'REMOVE_INVITE_SERVICEPROVIDER_FAILED'

export const removeUserServiceProviderInvite = (serviceProviderId, inviteId) => (dispatch, getState) => {
    dispatch({ type: REMOVE_INVITE_SERVICEPROVIDER_REQUESTED, data: {} })

	return invitations.removeInviteServiceProviderById(serviceProviderId, inviteId).then((response) => {
        if (response && response.isSuccessful) {
            dispatch(hideModal())
            dispatch({
                type: 'SET_SNACKBAR_MESSAGE',
                data: {
                    message: 'app.serviceProviders.removeUserServiceProviderSuccess',
                    isSuccess: true
                }
            })

            dispatch(getServiceProviderMembersById(serviceProviderId));

            return dispatch({
                type: REMOVE_INVITE_SERVICEPROVIDER_SUCCESS
            })
        } else {
            return dispatch({
                type: 'SET_SNACKBAR_MESSAGE',
                data: {
                    message: 'app.serviceProviders.removeUserServiceProviderFailed',
                    isSuccess: false
                }
            })
        }
    })
    return
}

const MAKE_SERVICEPROVIDER_DEFAULTASSIGNEE_REQUESTED = 'MAKE_SERVICEPROVIDER_DEFAULTASSIGNEE_REQUESTED'
const MAKE_SERVICEPROVIDER_DEFAULTASSIGNEE_SUCCESS = 'MAKE_SERVICEPROVIDER_DEFAULTASSIGNEE_SUCCESS'
const MAKE_SERVICEPROVIDER_DEFAULTASSIGNEE_FAILED = 'MAKE_SERVICEPROVIDER_DEFAULTASSIGNEE_FAILED'

export const makeDefaultAssigneeServiceProviderMember = (serviceProviderId, userId) => (dispatch) => {
	dispatch({ type: MAKE_SERVICEPROVIDER_DEFAULTASSIGNEE_REQUESTED, data: {} })

	return serviceProvider.makeDefaultAssignee(serviceProviderId, userId).then((response) => {
		if (response && response.isSuccessful) {

			dispatch(getServiceProviderMembersById(serviceProviderId));

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.serviceProviders.makeDefaultAssigneeSuccess',
					isSuccess: true
				}
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.serviceProviders.makeDefaultAssigneeFailed',
					isSuccess: false
				}
			})
		}
	})
	return
}

const REMOVE_SERVICEPROVIDER_ADMINISTRATOR_REQUESTED = 'REMOVE_SERVICEPROVIDER_ADMINISTRATOR_REQUESTED'
const REMOVE_SERVICEPROVIDER_ADMINISTRATOR_SUCCESS = 'REMOVE_SERVICEPROVIDER_ADMINISTRATOR_SUCCESS'
const REMOVE_SERVICEPROVIDER_ADMINISTRATOR_FAILED = 'REMOVE_SERVICEPROVIDER_ADMINISTRATOR_FAILED'

export const removeServiceProviderAdministrator = (serviceProviderId, userId) => (dispatch) => {
    dispatch({ type: REMOVE_SERVICEPROVIDER_ADMINISTRATOR_REQUESTED, data: {} })

    return serviceProvider.removeServiceProviderAdministrator(serviceProviderId, userId).then((response) => {
        if (response && response.isSuccessful) {

            dispatch(getServiceProviderMembersById(serviceProviderId));

            return dispatch({
                type: 'SET_SNACKBAR_MESSAGE',
                data: {
                    message: 'app.serviceProviders.removeAdministratorSuccess',
                    isSuccess: true
                }
            })
        } else {
            return dispatch({
                type: 'SET_SNACKBAR_MESSAGE',
                data: {
                    message: 'app.serviceProviders.removeAdministratorFailed',
                    isSuccess: false
                }
            })
        }
    })
    return
}

const CLEAR_SERVICEPROVIDER_DEFAULTASSIGNEE_REQUESTED = 'CLEAR_SERVICEPROVIDER_DEFAULTASSIGNEE_REQUESTED'
const CLEAR_SERVICEPROVIDER_DEFAULTASSIGNEE_SUCCESS = 'CLEAR_SERVICEPROVIDER_DEFAULTASSIGNEE_SUCCESS'
const CLEAR_SERVICEPROVIDER_DEFAULTASSIGNEE_FAILED = 'CLEAR_SERVICEPROVIDER_DEFAULTASSIGNEE_FAILED'

export const clearServiceProviderDefaultAssignee = (serviceProviderId, userId) => (dispatch, getState) => {
	dispatch({ type: CLEAR_SERVICEPROVIDER_DEFAULTASSIGNEE_REQUESTED, data: {} })

	return serviceProvider.clearServiceProviderDefaultAssignee(serviceProviderId, userId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(hideModal())
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.serviceProviders.clearServiceProviderDefaultAssigneeSuccess',
					isSuccess: true
				}
			})

			dispatch(getServiceProviderMembersById(serviceProviderId));

			return dispatch({
				type: CLEAR_SERVICEPROVIDER_DEFAULTASSIGNEE_SUCCESS
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.serviceProviders.clearServiceProviderDefaultAssigneeFailed',
					isSuccess: false
				}
			})
		}
	})
	return
}

const UPDATE_CIRCLE_TREATMENT_REQUESTED = 'UPDATE_CIRCLE_TREATMENT_REQUESTED'
const UPDATE_CIRCLE_TREATMENT_SUCCESS = 'UPDATE_CIRCLE_TREATMENT_SUCCESS'
const UPDATE_CIRCLE_TREATMENT_FAILED = 'UPDATE_CIRCLE_TREATMENT_FAILED'

export const updateCircleTreatment = (data) => (dispatch) => {
	dispatch({ type: UPDATE_CIRCLE_TREATMENT_REQUESTED, data: {} })
	//return serviceProvider.updateCircleTreatment(data).then((response) => {
	//	if (response) {
	//		dispatch(hideModal())
	//		return dispatch({
	//			type: UPDATE_CIRCLE_TREATMENT_SUCCESS,
	//			data: data.treatmentId
	//		})
	//	} else {
	//		return dispatch({
	//			type: UPDATE_CIRCLE_TREATMENT_FAILED,
	//			data: {}
	//		})
	//	}
	//})
}

const UPDATE_CIRCLE_PATHOLOGY_REQUESTED = 'UPDATE_CIRCLE_PATHOLOGY_REQUESTED'
const UPDATE_CIRCLE_PATHOLOGY_SUCCESS = 'UPDATE_CIRCLE_PATHOLOGY_SUCCESS'
const UPDATE_CIRCLE_PATHOLOGY_FAILED = 'UPDATE_CIRCLE_PATHOLOGY_FAILED'

export const updateCirclePathology = (data) => (dispatch) => {
	dispatch({ type: UPDATE_CIRCLE_PATHOLOGY_REQUESTED, data: {} })
	//return serviceProvider.updateCirclePathology(data).then((response) => {
	//	if (response) {
	//		dispatch(hideModal())
	//		return dispatch({
	//			type: UPDATE_CIRCLE_PATHOLOGY_SUCCESS,
	//			data: data.pathologyId
	//		})
	//	} else {
	//		return dispatch({
	//			type: UPDATE_CIRCLE_PATHOLOGY_FAILED,
	//			data: {}
	//		})
	//	}
	//})
}

const UPDATE_PLAN_REQUESTED = 'UPDATE_PLAN_REQUESTED'
const UPDATE_PLAN_SUCCESS = 'UPDATE_PLAN_SUCCESS'
const UPDATE_PLAN_FAILED = 'UPDATE_PLAN_FAILED'

export const updatePlan = (userCount, caseCount, circleId) => (dispatch) => {
	dispatch({ type: UPDATE_PLAN_REQUESTED, data: {} })
	//return serviceProvider.updatePlan(userCount, caseCount, circleId).then((response) => {
	//	dispatch(stopSaving())
	//	if (response) {
	//		dispatch(hideModal())
	//		return dispatch({
	//			type: 'SET_SNACKBAR_MESSAGE',
	//			data: { message: 'Success', isSuccess: true }
	//		})
	//	} else {
	//		return dispatch({
	//			type: 'SET_SNACKBAR_MESSAGE',
	//			data: { message: 'app.serviceProviders.updatePlanFailed', isSuccess: false }
	//		})
	//	}
	//})
}

const SET_SERVICE_PROVIDER_SEARCH_TERM_REQUESTED = 'SET_SERVICE_PROVIDER_SEARCH_TERM_REQUESTED'
const SET_SERVICE_PROVIDER_SEARCH_TERM_SUCCESS = 'SET_SERVICE_PROVIDER_SEARCH_TERM_SUCCESS'
const SET_SERVICE_PROVIDER_SEARCH_TERM_FAILED = 'SET_SERVICE_PROVIDER_SEARCH_TERM_FAILED'

export const setServiceProviderSearchTerm = (value) => (dispatch) => {
	dispatch({ type: SET_SERVICE_PROVIDER_SEARCH_TERM_REQUESTED, data: {} })
	return dispatch({ type: SET_SERVICE_PROVIDER_SEARCH_TERM_SUCCESS, data: value })
}


const SET_RELATED_ENTITIES_FILTER = 'SET_RELATED_ENTITIES_FILTER'

export const setRelatedEntitiesFilter = (filter) => (dispatch) => {
	return dispatch({ type: SET_RELATED_ENTITIES_FILTER, data: filter })
}


const SEARCH_SERVICE_PROVIDER_RELATED_PROTOCOLS_REQUESTED = 'SEARCH_SERVICE_PROVIDER_RELATED_PROTOCOLS_REQUESTED'
const SEARCH_SERVICE_PROVIDER_RELATED_PROTOCOLS_SUCCESS = 'SEARCH_SERVICE_PROVIDER_RELATED_PROTOCOLS_SUCCESS'
const SEARCH_SERVICE_PROVIDER_RELATED_PROTOCOLS_FAILED = 'SEARCH_SERVICE_PROVIDER_RELATED_PROTOCOLS_FAILED'

export const searchRelatedProtocols = (serviceProviderId, searchPhrase) => (dispatch) => {
	dispatch({ type: SEARCH_SERVICE_PROVIDER_RELATED_PROTOCOLS_REQUESTED, data: null })

	return serviceProvider
		.searchRelatedProtocols(serviceProviderId, searchPhrase)
		.then((response) => {
			if (response?.isSuccessful) {
				dispatch({ type: SEARCH_SERVICE_PROVIDER_RELATED_PROTOCOLS_SUCCESS, data: response.protocols })

				return response
			}
			else {
				dispatch({ type: SEARCH_SERVICE_PROVIDER_RELATED_PROTOCOLS_FAILED, data: null })

				dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: `app.serviceProviders.relatedProtocols.failed`, isSuccess: false }
				})

				return null
			}
		})
}


const SEARCH_SERVICE_PROVIDER_RELATED_PROTOCOL_SURVEYS_REQUESTED = 'SEARCH_SERVICE_PROVIDER_RELATED_PROTOCOL_SURVEYS_REQUESTED'
const SEARCH_SERVICE_PROVIDER_RELATED_PROTOCOL_SURVEYS_SUCCESS = 'SEARCH_SERVICE_PROVIDER_RELATED_PROTOCOL_SURVEYS_SUCCESS'
const SEARCH_SERVICE_PROVIDER_RELATED_PROTOCOL_SURVEYS_FAILED = 'SEARCH_SERVICE_PROVIDER_RELATED_PROTOCOL_SURVEYS_FAILED'

export const searchRelatedProtocolSurveys = (serviceProviderId, searchPhrase) => (dispatch) => {
	dispatch({ type: SEARCH_SERVICE_PROVIDER_RELATED_PROTOCOL_SURVEYS_REQUESTED, data: null })

	return serviceProvider
		.searchRelatedProtocolSurveys(serviceProviderId, searchPhrase)
		.then((response) => {
			if (response?.isSuccessful) {
				dispatch({ type: SEARCH_SERVICE_PROVIDER_RELATED_PROTOCOL_SURVEYS_SUCCESS, data: response.protocolSurveys })

				return response
			}
			else {
				dispatch({ type: SEARCH_SERVICE_PROVIDER_RELATED_PROTOCOL_SURVEYS_FAILED, data: null })

				dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: `app.serviceProviders.relatedProtocolSurveys.failed`, isSuccess: false }
				})

				return null
			}
		})
}


const initial = {
    serviceProviders: null,
	searchServiceProviders: [],
    serviceProvider: null,
	filters: null,
	filter: 'ALL',
	searchTerm: '',
	serviceProviderMembers: [],
	serviceProviderInvitations: [],
	autoCompleteSuggestions: [],
	autoCompleteSuggestionsByProtocol: [],
	isCreating: false,
	sortField: 'modifiedDateSeconds',
	isDescending: true,
	memberSortField: 'dateJoinedSeconds',
	memberIsDescending: false,
    isAdding: false,
	treatment: null,
	pathology: null,
	serviceProviderSearchTerm: '',
	relatedEntitiesFilter: 0,
	relatedProtocols: [],
	relatedProtocolSurveys: []
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_SERVICEPROVIDER_AUTOCOMPLETE_SUCCESS:
            return { ...state, autoCompleteSuggestions: action.data }
		case SET_SERVICEPROVIDER_MEMBER_REQUESTED:
			return { ...state, isAdding: true }
		case SET_SERVICEPROVIDER_MEMBER_FAILED:
			return { ...state, isAdding: false }
		case SET_SERVICEPROVIDER_INVITATION_MEMBER_REQUESTED:
            return { ...state, isInviting: true }
		case SET_SERVICEPROVIDER_INVITATION_MEMBER_SUCCESS:
            return {
				...state,
                isInviting: false,
                serviceProviderInvitations: [
                    ...state.serviceProviderInvitations.slice(0, action.data),
                    action.data,
                    ...state.serviceProviderInvitations.slice(action.pos)
                ]
            }
		case SET_SERVICEPROVIDER_INVITATION_MEMBER_FAILED:
            return { ...state, isInviting: false }
        case SET_SERVICEPROVIDER_MEMBER_SUCCESS:
			return {
				...state,
				serviceProviderMembers: [
					...state.serviceProviderMembers.slice(0, action.data),
					action.data,
					...state.serviceProviderMembers.slice(action.pos)
				], isAdding: false
			}
		case SET_CIRCLE_MEMBER_SORT_REQUESTED:
			return {
				...state,
				serviceProviderMembers: action.data.items,
				memberSortField: action.data.field,
				memberIsDescending: action.data.isDescending
			}
		case SET_SERVICEPROVIDER_SORT_REQUESTED:
			return {
				...state,
				searchServiceProviders: [...action.data.items],
				sortField: action.data.field,
				isDescending: action.data.isDescending
			}
		case SEARCH_SERVICEPROVIDER_SUCCESS:
			return { ...state, searchServiceProviders: action.data }
		case ADD_SERVICEPROVIDER_SUCCESS:
			return {
				...state,
				isCreating: false,
				serviceProviderMembers: [
					...state.serviceProviderMembers.slice(0, action.data),
					action.data,
					...state.serviceProviderMembers.slice(action.pos)
				]
			}
		case ADD_SERVICEPROVIDER_FAILED:
			return {
				...state,
				isCreating: false
			}
		case FETCH_SERVICEPROVIDER_SUCCESS:
			return { ...state, serviceProvider: action.data }
		case FETCH_SERVICEPROVIDER_MEMBERS_SUCCESS:
			return { ...state, serviceProviderMembers: action.data }
        case FETCH_CIRCLES_FILTER_SUCCESS:
			return { ...state, filters: action.data }
		case SET_SERVICEPROVIDERS_FILTER_SUCCESS:
			return { ...state, filter: action.data }
		case UPDATE_SERVICEPROVIDER_DETAILS_SUCCESS:
			return { ...state, serviceProvider: action.data }
		case RESEND_SERVICEPROVIDER_INVITATION_SUCCESS:
			return { ...state, serviceProvider: action.data }
		case SET_CIRCLE_MEMBERS_KEY_SUCCESS:
			return { ...state, searchTerm: action.data }
		case REINSTATE_CIRCLE_SUCCESS:
			return {
				...state,
				serviceProviders: state.serviceProviders.map(
					(serviceProvider) =>
						serviceProvider.id === action.data.id ? { ...serviceProvider, isArchived: action.data.isArchived } : serviceProvider
				)
			}
		case LEAVE_CIRCLE_SUCCESS:
			return { ...state, serviceProviders: state.serviceProviders.filter((item) => item.id !== action.data) }
		case REMOVE_USER_CIRCLE_SUCCESS:
			return { ...state, serviceProviderMembers: state.serviceProviderMembers.filter((item) => item.email !== action.data) }
		case DISTRIBUTE_CIRCLE_RESOURCES_SUCCESS:
			return {
				...state,
				serviceProviders: state.serviceProviders.map(
					(serviceProvider) =>
                    serviceProvider.id === action.data.id
							? { ...serviceProvider, numberUsers: action.data.numberUsers, numberCases: action.data.numberCases }
							: serviceProvider
				)
			}
		case ADD_SERVICEPROVIDER_REQUESTED:
			return { ...state, isCreating: true }
		case UPDATE_CIRCLE_TREATMENT_SUCCESS:
			return { ...state, serviceProvider: { ...state.serviceProvider, treatmentId: action.data } }
		case UPDATE_CIRCLE_PATHOLOGY_SUCCESS:
			return { ...state, serviceProvider: { ...state.serviceProvider, pathologyId: action.data } }
		case FETCH_TREATMENT_SUCCESS:
			return { ...state, treatment: action.data }
		case FETCH_PATHOLOGY_SUCCESS:
			return { ...state, pathology: action.data }
		case FETCH_CIRCLES_AUTOCOMPLETE_SUCCESS:
			return { ...state, autoCompleteSuggestions: action.data }
		case FETCH_PROTOCOL_CIRCLES_AUTOCOMPLETE_SUCCESS:
			return { ...state, autoCompleteSuggestionsByProtocol: action.data }
		case CLEAR_SERVICEPROVIDER_REQUESTED:
            return { ...state, circle: null }
		case SET_SERVICE_PROVIDER_SEARCH_TERM_SUCCESS:
			return {
				...state,
				serviceProviderSearchTerm: action.data
			}
	
		case SET_RELATED_ENTITIES_FILTER:
			return {
				...state,
				relatedEntitiesFilter: action.data
			}
		
		case SEARCH_SERVICE_PROVIDER_RELATED_PROTOCOLS_REQUESTED:
			return {
				...state,
				relatedProtocols: []
			}
		case SEARCH_SERVICE_PROVIDER_RELATED_PROTOCOLS_SUCCESS:
			return {
				...state,
				relatedProtocols: action.data
			}
		
		case SEARCH_SERVICE_PROVIDER_RELATED_PROTOCOL_SURVEYS_REQUESTED:
			return {
				...state,
				relatedProtocolSurveys: []
			}
		case SEARCH_SERVICE_PROVIDER_RELATED_PROTOCOL_SURVEYS_SUCCESS:
			return {
				...state,
				relatedProtocolSurveys: action.data
			}

		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.serviceProvider
const coreSelector = (state) => state.core
const stateSelector = (state) => state

export const isCreatingSelector = createSelector(mainSelector, (state) => state.isCreating)

export const serviceProvidersSelector = createSelector(mainSelector, (state) => state.serviceProviders)
export const serviceProviderSelector = createSelector(mainSelector, (state) => state.serviceProvider)

export const serviceProviderMembersSelector = createSelector(mainSelector, (state) => {
	if (state.serviceProviderMembers && state.searchTerm) {
		let searchTerm = state.searchTerm.toLowerCase()
		return state.serviceProviderMembers.filter(
			(f) =>
				f.email.toLowerCase().includes(searchTerm) ||
				f.firstName.toLowerCase().includes(searchTerm) ||
				f.lastName.toLowerCase().includes(searchTerm)
		)
	} else {
		return state.serviceProviderMembers
	}
})

const CLEAR_SERVICEPROVIDER_REQUESTED = 'FETCH_SERVICEPROVIDER_REQUESTED'

export const clearServiceProvider = () => (dispatch) => {
	return dispatch({ type: CLEAR_SERVICEPROVIDER_REQUESTED, data: {} })
}

export const serviceProviderFilterSelector = createSelector(mainSelector, (state) => state.filters)
export const canEditServiceProviderBackgroundImageSelector = createSelector(mainSelector, (state) => {
	let user = getUser()
	if (user && state.serviceProvider) {
		if (state.serviceProvider.administrator && state.serviceProvider.administrator.id === user.id) {
			return true
		}
	}
	return false
})
export const canAddServiceProviderMemberSelector = createSelector(mainSelector, (state) => {
	let user = getUser()

	if (user && state.serviceProvider) {
		return true
	}
	return false
})
export const canLeaveCircleSelector = createSelector(mainSelector, (state) => {
	let user = getUser()

	if (user && state) if (state.founder && state.founder.id !== user.id) return true

	return false
})
export const canInviteMemberCircleSelector = createSelector(stateSelector, (state) => {
	let user = getUser()
	if (state && state.serviceProvider && user) {
		if (state.subscriptionUser && state.subscriptionUser.availableUsers === 0) return false
		if (state.serviceProvider.founder && state.serviceProvider.founder.id === user.id) return true
		if (state.serviceProvider.administrator && state.serviceProvider.administrator.id === user.id) return true
	}
	return false
})
export const canEmailAdministratorSelector = createSelector(mainSelector, (state) => {
	let user = getUser()
	if (user && state) if (state.founder && state.founder.id !== user.id) return true
	return false
})
export const canReinstateCircleSelector = createSelector(mainSelector, (state) => {
	let user = getUser()
	if (user && state) if (state.isArchived && state.founder && user.id === state.founder.id) return true
	return false
})
export const invitedCircleMemberSelector = createSelector(coreSelector, (state) => {
	if (state && state.params && state.params.invitedCircle) {
		return state.params.invitedCircle
	}
	return null
})
export const canMakeServiceProviderAdministratorSelector = createSelector(stateSelector, (state) => {
	let user = getUser()
	if (user && state)
		if (state.serviceProvider && state.member)
			if (state.serviceProvider.administrator && user.id === state.serviceProvider.administrator.id && state.member.status === 1)
				return true
	return false
})
export const canResendEmailInvitationSelector = createSelector(stateSelector, (state) => {
	let user = getUser()
	if (user && state)
		if (state.serviceProvider && state.member)
			if (state.serviceProvider.administrator && user.id === state.serviceProvider.administrator.id && state.member.status === 0)
				return true
	return false
})
export const canRemoveUserCircleSelector = createSelector(stateSelector, (state) => {
	let user = getUser()
	if (user && state)
		if (state.serviceProvider && state.member)
			if (state.serviceProvider.administrator && user.id === state.serviceProvider.administrator.id) return true
	return false
})
export const filteredServiceProvidersSelector = createSelector(mainSelector, (state) => {
	let data = state.serviceProviders ? state.serviceProviders : null

	if (data) {
		switch (state.filter) {
			case 0:
				return data.filter((f) => !f.isArchived)
			case 1:
				return data.filter((f) => f.isSponsored)
			case 2:
				return data.filter((f) => f.isArchived)
			default:
				return data
		}
	}
})
export const canViewServiceProviderMemberSelector = createSelector(mainSelector, (state) => {
	if (state && state.serviceProvider && state.serviceProvider.isArchived) {
		return false
	}
	return true
})
export const isAdminServiceProviderSelector = createSelector(mainSelector, (state) => {
	let user = getUser()
	if (user && state.serviceProvider) {
		if (state.serviceProvider.administrator && state.serviceProvider.administrator.id === user.id) {
			return true
		}
	}
	return false
})

export const serviceProviderAutoCompleteSuggestionSelector = createSelector(mainSelector, (state) => {
	if (state && state.autoCompleteSuggestions) {
		return state.autoCompleteSuggestions.filter((x) => !x.isArchived)
	}
	return []
})

//export const circlesByProtocolAutoCompleteSuggestionSelector = createSelector(mainSelector, (state) => {
//	if (state && state.autoCompleteSuggestionsByProtocol) {
//		return state.autoCompleteSuggestionsByProtocol
//	}
//	return []
//})

export const formattedCircleProtocolsSelector = createSelector(mainSelector, (state) => {
	return state.serviceProviders
		? state.serviceProviders.map((r) => {
			return {
				name: r.name,
				description: r.description,
				founder: r.founder ? `${r.founder.firstName} ${r.founder.lastName}` : '',
				observationalProtocol: r.observationalProtocol,
				label: r.observationProtocol ? `${r.observationProtocol.name} | ${r.name}` : r.name,
				id: r.id,
				protocolId: r.observationProtocol.id,
				isPhiFree: r.phi
			}
		})
		: []
})

export const formattedCirclesSelector = createSelector(mainSelector, (state) => {
	return state.serviceProviders
		? state.serviceProviders.map((r) => {
			return {
				name: r.name,
				description: r.description,
				founder: r.founder ? `${r.founder.firstName} ${r.founder.lastName}` : '',
				label: r.founder ? `${r.name} | ${r.founder.firstName} ${r.founder.lastName}` : r.name,
				id: r.id,
				isPhiFree: r.phi
			}
		})
		: []
})
export const canDistributeResourcesSelector = createSelector(mainSelector, (state) => {
	if (state) {
		if (state.isSponsored) return true
	}
	return false
})

export const searchServiceProvidersSelector = createSelector(mainSelector, (state) => {
	let data = state.searchServiceProviders ? state.searchServiceProviders : null

	if (data) {
		switch (state.filter) {
			case 0:
                return data;
            case 1:
				return data.filter((f) => f.isArchived)
			default:
				return data
		}
	}
	return []
})

export const hasCirclesSelector = createSelector(mainSelector, (state) => {
	return state.hasServiceProviders
})
export const sortServiceProvidersDirectionSelector = createSelector(mainSelector, (state) => {
	return state.isDescending
})

export const sortServiceProvidersFieldSelector = createSelector(mainSelector, (state) => {
	return state.sortField
})

export const sortMembersDirectionSelector = createSelector(mainSelector, (state) => {
	return state.memberIsDescending
})

export const sortMembersFieldSelector = createSelector(mainSelector, (state) => {
	return state.memberSortField
})

export const isInvitingSelector = createSelector(mainSelector, (state) => {
	return state.isInviting
})

export const circleTreatmentSelector = createSelector(mainSelector, (state) => state.treatment)
export const circlePathologySelector = createSelector(mainSelector, (state) => state.pathology)

export const serviceProviderSearchTermSelector = createSelector(mainSelector, (state) => {
	return state.serviceProviderSearchTerm
})

export const relatedEntitiesFilterSelector = createSelector(mainSelector, (state) => {
	return state.relatedEntitiesFilter
})

export const relatedProtocolsSelector = createSelector(mainSelector, (state) => {
	return state.relatedProtocols
})

export const relatedProtocolSurveysSelector = createSelector(mainSelector, (state) => {
	return state.relatedProtocolSurveys
})
