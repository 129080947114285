import * as circle from '../../../apis/circle'
import * as cookies from '../../../utilities/cookies'
import * as invitations from '../../../apis/circleInvitation'
import * as resources from '../../../apis/resource'
import history from '../../../utilities/history'
import { createSelector } from 'reselect'
import { hideModal, stopSaving } from '../core'
import { getUser } from '../../../utilities/userUtilities'
import { setNewReportInitiated } from '../reports'

const ADD_CIRCLE_REQUESTED = 'ADD_CIRCLE_REQUESTED'
const ADD_CIRCLE_SUCCESS = 'ADD_CIRCLE_SUCCESS'
const ADD_CIRCLE_FAILED = 'ADD_CIRCLE_FAILED'

export const addCircle = (
	name,
	status,
	phiValue,
	numberCases,
	numberUsers,
	isSponsored,
	protocolId,
	founderId,
	administratorId,
	pathologyId,
	treatmentId,
	sponsorId
) => (dispatch) => {
	dispatch({ type: ADD_CIRCLE_REQUESTED, data: {} })

	circle
		.addCircle(
			name,
			status,
			phiValue,
			numberCases,
			numberUsers,
			isSponsored,
			protocolId,
			founderId,
			administratorId,
			pathologyId,
			treatmentId,
			sponsorId
		)
		.then((circle) => {
			if (circle && circle.isSuccessful) {
				history.push(`/circles/${circle.id}`)
				dispatch(hideModal())
				return dispatch({ type: ADD_CIRCLE_SUCCESS, data: circle })
			} else {
				dispatch({ type: ADD_CIRCLE_FAILED, data: circle })
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: `app.errorMessages.${circle.errorCode}`, isSuccess: false }
				})
			}
		})
	return
}

const SEARCH_CIRCLES_REQUESTED = 'SEARCH_CIRCLES_REQUESTED'
const SEARCH_CIRCLES_SUCCESS = 'SEARCH_CIRCLES_SUCCESS'
const SEARCH_CIRCLES_FAILED = 'SEARCH_CIRCLES_FAILED'

export const searchCircles = (searchPhrase) => (dispatch, getState) => {
	let state = getState()
    dispatch({ type: SEARCH_CIRCLES_REQUESTED, data: {} })
    circle.fetchCircles(searchPhrase, state.circle.filter).then((circles) => {
        if (circles && circles.isSuccessful && circles.circles) {
            let data = circles.circles
			return dispatch({ 
				type: SEARCH_CIRCLES_SUCCESS, 
				data: {
					items: data,
					hasMore: circles.hasMore
				} 
			})
        } else {
            return dispatch({ type: SEARCH_CIRCLES_FAILED, data: {} })
        }
    })
    return
}

const SEARCH_ADDITIONAL_CIRCLES_REQUESTED = 'SEARCH_ADDITIONAL_CIRCLES_REQUESTED'
const SEARCH_ADDITIONAL_CIRCLES_SUCCESS = 'SEARCH_ADDITIONAL_CIRCLES_SUCCESS'
const SEARCH_ADDITIONAL_CIRCLES_FAILED = 'SEARCH_ADDITIONAL_CIRCLES_FAILED'

export const searchAdditionalCircles = (searchPhrase, offset) => (dispatch, getState) => {
	let state = getState()
    dispatch({ type: SEARCH_ADDITIONAL_CIRCLES_REQUESTED, data: {} })
    circle.fetchCircles(searchPhrase, state.circle.filter, offset).then((circles) => {
        if (circles && circles.isSuccessful && circles.circles) {
            let data = circles.circles
			return dispatch({ 
				type: SEARCH_ADDITIONAL_CIRCLES_SUCCESS, 
				data: {
					items: data,
					hasMore: circles.hasMore
				} 
			})
        } else {
            return dispatch({ type: SEARCH_ADDITIONAL_CIRCLES_FAILED, data: {} })
        }
    })
    return
}

function download(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}

const DOWNLOAD_USAGE_STATISTICS_REQUESTED = 'DOWNLOAD_USAGE STATISTICS_REQUESTED'
const DOWNLOAD_USAGE_STATISTICS_SUCCESS = 'DOWNLOAD_USAGE STATISTICS_SUCCESS'
const DOWNLOAD_USAGE_STATISTICS_FAILED = 'DOWNLOAD_USAGE STATISTICS_FAILED'

export const fetchCircleUsageStatistics = (startDate, endDate) => (dispatch) => {
	dispatch({ type: DOWNLOAD_USAGE_STATISTICS_REQUESTED })

	circle.fetchCircleUsageStatistics(startDate, endDate).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(hideModal())
			return dispatch({ type: DOWNLOAD_USAGE_STATISTICS_SUCCESS, data: {} })
		} else {
			dispatch({ type: DOWNLOAD_USAGE_STATISTICS_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.fetchReportFailed', isSuccess: false }
			})
		}
	})

	return
}

export const fetchCircleUsageStatisticsRawData = (startDate, endDate) => (dispatch) => {
	dispatch({ type: DOWNLOAD_USAGE_STATISTICS_REQUESTED })

	circle.fetchCircleUsageStatisticsRawData(startDate, endDate).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(hideModal())
			return dispatch({ type: DOWNLOAD_USAGE_STATISTICS_SUCCESS, data: {} })
		} else {
			dispatch({ type: DOWNLOAD_USAGE_STATISTICS_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.fetchReportFailed', isSuccess: false }
			})
		}
	})

	return
}

export const fetchExecutiveUsageStatistics = (startDate, endDate, executiveStatisticType) => (dispatch) => {
	dispatch({ type: DOWNLOAD_USAGE_STATISTICS_REQUESTED })

	circle.fetchExecutiveUsageStatistics(startDate, endDate, executiveStatisticType).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(hideModal())
			return dispatch({ type: DOWNLOAD_USAGE_STATISTICS_SUCCESS, data: {} })
		} else {
			dispatch({ type: DOWNLOAD_USAGE_STATISTICS_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.fetchReportFailed', isSuccess: false }
			})
		}
	})

	return
}

const ARCHIVE_CIRCLE_REQUESTED = 'ARCHIVE_CIRCLE_REQUESTED'
const ARCHIVE_CIRCLE_SUCCESS = 'ARCHIVE_CIRCLE_SUCCESS'
const ARCHIVE_CIRCLE_FAILED = 'ARCHIVE_CIRCLE_FAILED'

export const archiveCircle = (id) => (dispatch, getState) => {
	dispatch({ type: ARCHIVE_CIRCLE_REQUESTED, data: {} })
	circle.archiveCircle(id).then((response) => {
		if (response && response.isSuccessful) {
			var state = getState()
			if (state && state.circle && state.circle.searchCircles && state.circle.searchCircles.length > 0) {
				let r = [ ...state.circle.searchCircles ]
				r.map((r) => {
					if (r.id === id) {
						r.isArchived = true
					}
				})
				dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.circles.successfullyArchived', isSuccess: true }
				})
				return dispatch({ type: SEARCH_CIRCLES_SUCCESS, data: r })
			}
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.circles.failedToArchive', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_CIRCLE_REQUESTED = 'FETCH_CIRCLE_REQUESTED'
const FETCH_CIRCLE_SUCCESS = 'FETCH_CIRCLE_SUCCESS'
const FETCH_CIRCLE_FAILED = 'FETCH_CIRCLE_FAILED'

export const fetchCircle = (id) => (dispatch) => {
	dispatch({ type: FETCH_CIRCLE_REQUESTED, data: {} })
	circle.fetchCircle(id).then((circle) => {
		if (circle) {
			return dispatch({ type: FETCH_CIRCLE_SUCCESS, data: circle })
		} else {
			return dispatch({ type: FETCH_CIRCLE_FAILED, data: {} })
		}
	})
	return
}

const SET_CIRCLE_INVITATION_MEMBER_REQUESTED = 'SET_CIRCLE_INVITATION_MEMBER_REQUESTED'
const SET_CIRCLE_INVITATION_MEMBER_SUCCESS = 'SET_CIRCLE_INVITATION_MEMBER_SUCCESS'
const SET_CIRCLE_INVITATION_MEMBER_FAILED = 'SET_CIRCLE_INVITATION_MEMBER_FAILED'

export const inviteCircleMember = (email, circleId, languageId, sponsored, caseFunding, caseSponsorId) => (dispatch) => {
	dispatch({ type: SET_CIRCLE_INVITATION_MEMBER_REQUESTED, data: {} })

	let user = cookies.get('user')

	return invitations.inviteCircleMember(user.id, email, circleId, languageId, sponsored, caseFunding, caseSponsorId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(getCircleMembersById(circleId))
			dispatch({ type: SET_CIRCLE_INVITATION_MEMBER_SUCCESS, data: response })
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.general.success`, isSuccess: true }
			})
			return dispatch(hideModal())
		} else {
			dispatch({ type: SET_CIRCLE_INVITATION_MEMBER_FAILED, data: {} })
			
			if (response && response.isIneligible) {
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: `app.circle.failedToInviteIneligibleMember`, isSuccess: false }
				})
			}
			
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 
					response && response.errorCode === 15 
					? 'app.circle.failedToInviteMemberNTO' 
					: `app.circle.failedToInviteMember`,
					 isSuccess: false }
			})
		}
	})
	return
}

const FETCH_CIRCLE_INVITATIONS_REQUESTED = 'FETCH_CIRCLE_INVITATIONS_REQUESTED'
const FETCH_CIRCLE_INVITATIONS_SUCCESS = 'FETCH_CIRCLE_INVITATIONS_SUCCESS'
const FETCH_CIRCLE_INVITATIONS_FAILED = 'FETCH_CIRCLE_INVITATIONS_FAILED'

export const getCircleMembersById = (id, searchPhrase) => (dispatch, state) => {
	if (!id) {
		return dispatch({ type: FETCH_CIRCLE_INVITATIONS_REQUESTED, data: {} })
	}

	return circle.fetchCircleMembers(id, searchPhrase).then((members) => {
		if (members) {
			let data = members.members

            let currentState = state()

			let response = applyCircleMemberSort(data, currentState.circle.memberSortField, currentState.circle.memberIsDescending);

			return dispatch({ type: FETCH_CIRCLE_INVITATIONS_SUCCESS, data: response.items })
		} else {
			return dispatch({ type: FETCH_CIRCLE_INVITATIONS_FAILED, data: {} })
		}
	})
	return
}

const FETCH_CIRCLES_FILTER_REQUESTED = 'FETCH_CIRCLES_FILTER_REQUESTED'
const FETCH_CIRCLES_FILTER_SUCCESS = 'FETCH_CIRCLES_FILTER_SUCCESS'
const FETCH_CIRCLES_FILTER_FAILED = 'FETCH_CIRCLES_FILTER_FAILED'

export const fetchCircleFilters = () => (dispatch) => {
	dispatch({ type: FETCH_CIRCLES_FILTER_REQUESTED, data: {} })

	const options = [
		{ option: 'ALL', label: 'All Circles' },
		{ option: 'SPONSORED', label: 'Sponsored Circles' },
		{ option: 'MANAGE', label: 'Circles I Manage' },
		{ option: 'JOINED', label: 'Joined Circles' },
		{ option: 'ARCHIVED', label: 'Archived Circles' }
	]
	return dispatch({ type: FETCH_CIRCLES_FILTER_SUCCESS, data: options })
}

const SET_CIRCLES_FILTER_REQUESTED = 'SET_CIRCLES_FILTER_REQUESTED'
const SET_CIRCLES_FILTER_SUCCESS = 'SET_CIRCLES_FILTER_SUCCESS'
const SET_CIRCLES_FILTER_FAILED = 'SET_CIRCLES_FILTER_FAILED'

export const setCircleFilter = (filter) => (dispatch, getState) => {
	let state = getState()
	dispatch({ type: SET_CIRCLES_FILTER_REQUESTED, data: {} })

	dispatch({ type: SET_CIRCLES_FILTER_SUCCESS, data: filter })
	return dispatch(searchCircles(state.circle.circleSearchTerm))
}

const FETCH_CIRCLES_AUTOCOMPLETE_REQUESTED = 'FETCH_CIRCLES_AUTOCOMPLETE_REQUESTED'
const FETCH_CIRCLES_AUTOCOMPLETE_SUCCESS = 'FETCH_CIRCLES_AUTOCOMPLETE_SUCCESS'
const FETCH_CIRCLES_AUTOCOMPLETE_FAILED = 'FETCH_CIRCLES_AUTOCOMPLETE_FAILED'

export const fetchCircleAutoComplete = (searchPhrase, noMatchMessage) => (dispatch) => {
	dispatch({ type: FETCH_CIRCLES_AUTOCOMPLETE_REQUESTED, data: {} })
	circle.fetchCircleAutoComplete(searchPhrase).then((response) => {
		if (response && response.isSuccessful) {
			if (response.circles && response.circles.length > 0) {
				return dispatch({
					type: FETCH_CIRCLES_AUTOCOMPLETE_SUCCESS,
					data: response.circles
				})
			} else {
				return dispatch({
					type: FETCH_CIRCLES_AUTOCOMPLETE_SUCCESS,
					data: [ { id: -1, name: noMatchMessage } ]
				})
			}
		} else {
			return dispatch({ type: FETCH_CIRCLES_AUTOCOMPLETE_FAILED, data: {} })
		}
	})
	return
}

const FETCH_PROTOCOL_CIRCLES_AUTOCOMPLETE_REQUESTED = 'FETCH_PROTOCOL_CIRCLES_AUTOCOMPLETE_REQUESTED'
const FETCH_PROTOCOL_CIRCLES_AUTOCOMPLETE_SUCCESS = 'FETCH_PROTOCOL_CIRCLES_AUTOCOMPLETE_SUCCESS'
const FETCH_PROTOCOL_CIRCLES_AUTOCOMPLETE_FAILED = 'FETCH_PROTOCOL_CIRCLES_AUTOCOMPLETE_FAILED'

export const fetchProtocolCirclesAutoComplete = (protocolId, searchPhrase, noMatchMessage) => (dispatch) => {
	dispatch({ type: FETCH_PROTOCOL_CIRCLES_AUTOCOMPLETE_REQUESTED, data: {} })
	circle.fetchCircleByProtocolAutoComplete(protocolId, searchPhrase).then((response) => {
		if (response && response.isSuccessful) {
			if (response.circles && response.circles.length > 0) {
				return dispatch({
					type: FETCH_PROTOCOL_CIRCLES_AUTOCOMPLETE_SUCCESS,
					data: response.circles
				})
			} else {
				return dispatch({
					type: FETCH_PROTOCOL_CIRCLES_AUTOCOMPLETE_SUCCESS,
					data: [ { id: -1, name: noMatchMessage } ]
				})
			}
		} else {
			return dispatch({ type: FETCH_PROTOCOL_CIRCLES_AUTOCOMPLETE_FAILED, data: {} })
		}
	})
	return
}

const FETCH_CIRCLES_BY_USER_AUTOCOMPLETE_REQUESTED = 'FETCH_CIRCLES_BY_USER_AUTOCOMPLETE_REQUESTED'
const FETCH_CIRCLES_BY_USER_AUTOCOMPLETE_SUCCESS = 'FETCH_CIRCLES_BY_USER_AUTOCOMPLETE_SUCCESS'
const FETCH_CIRCLES_BY_USER_AUTOCOMPLETE_FAILED = 'FETCH_CIRCLES_BY_USER_AUTOCOMPLETE_FAILED'

export const fetchCircleByUserAutoComplete = (userId, searchPhrase, noMatchMessage) => (dispatch) => {
	dispatch({ type: FETCH_CIRCLES_BY_USER_AUTOCOMPLETE_REQUESTED, data: {} })
	circle.fetchCircleByUserAutoComplete(userId, searchPhrase).then((response) => {
		if (response && response.isSuccessful) {
			if (response.circles && response.circles.length > 0) {
				return dispatch({
					type: FETCH_CIRCLES_BY_USER_AUTOCOMPLETE_SUCCESS,
					data: response.circles
				})
			} else {
				return dispatch({
					type: FETCH_CIRCLES_AUTOCOMPLETE_SUCCESS,
					data: [ { id: -1, name: noMatchMessage } ]
				})
			}
		} else {
			return dispatch({ type: FETCH_CIRCLES_BY_USER_AUTOCOMPLETE_FAILED, data: {} })
		}
	})
	return
}

const FETCH_SHARED_CIRCLES_AUTOCOMPLETE_REQUESTED = 'FETCH__SHARED_CIRCLES_AUTOCOMPLETE_REQUESTED'
const FETCH_SHARED_CIRCLES_AUTOCOMPLETE_SUCCESS = 'FETCH__SHARED_CIRCLES_AUTOCOMPLETE_SUCCESS'
const FETCH_SHARED_CIRCLES_AUTOCOMPLETE_FAILED = 'FETCH__SHARED_CIRCLES_AUTOCOMPLETE_FAILED'

export const fetchSharedWithCircleAutoComplete = (userId, searchPhrase, noMatchMessage, protocolVersionId) => (dispatch) => {
	dispatch({ type: FETCH_SHARED_CIRCLES_AUTOCOMPLETE_REQUESTED, data: {} })
	circle.fetchCircleByUserAutoComplete(userId, searchPhrase, protocolVersionId).then((response) => {
		if (response && response.isSuccessful) {
			if (response.circles && response.circles.length > 0) {
				return dispatch({
					type: FETCH_SHARED_CIRCLES_AUTOCOMPLETE_SUCCESS,
					data: response.circles
				})
			} else {
				return dispatch({
					type: FETCH_SHARED_CIRCLES_AUTOCOMPLETE_SUCCESS,
					data: [ { id: -1, name: noMatchMessage } ]
				})
			}
		} else {
			return dispatch({ type: FETCH_SHARED_CIRCLES_AUTOCOMPLETE_FAILED, data: {} })
		}
	})
	return
}

const UPLOAD_CIRCLE_BACKGROUND_REQUESTED = 'UPLOAD_CIRCLE_BACKGROUND_REQUESTED'
const UPLOAD_CIRCLE_BACKGROUND_SUCCESS = 'UPLOAD_CIRCLE_BACKGROUND_SUCCESS'
const UPLOAD_CIRCLE_BACKGROUND_FAILED = 'UPLOAD_CIRCLE_BACKGROUND_FAILED'

export const setBackground = (entity, id, photo, onImageUpdated) => (dispatch) => {
	dispatch({ type: UPLOAD_CIRCLE_BACKGROUND_REQUESTED, data: {} })
	let formData = new FormData()
	formData.append('files', photo)
	return circle.setBackgroundImage(entity, id, formData).then((response) => {
		if (response) {
			if (onImageUpdated) dispatch(onImageUpdated(id))
			return dispatch({ type: UPLOAD_CIRCLE_BACKGROUND_SUCCESS, data: response })
		} else {
			return dispatch({ type: UPLOAD_CIRCLE_BACKGROUND_FAILED, data: {} })
		}
	})
	return
}

const RESET_CIRCLE_BACKGROUND_REQUESTED = 'RESET_CIRCLE_BACKGROUND_REQUESTED'
const RESET_CIRCLE_BACKGROUND_SUCCESS = 'RESET_CIRCLE_BACKGROUND_SUCCESS'
const RESET_CIRCLE_BACKGROUND_FAILED = 'RESET_CIRCLE_BACKGROUND_FAILED'

export const resetBackground = (entity, id, onImageUpdated) => (dispatch) => {
	dispatch({ type: RESET_CIRCLE_BACKGROUND_REQUESTED, data: {} })

	return circle.resetBackgroundImage(entity, id).then((response) => {
		if (response) {
			if (onImageUpdated) dispatch(onImageUpdated(id))
			return dispatch({ type: RESET_CIRCLE_BACKGROUND_SUCCESS, data: response })
		} else {
			return dispatch({ type: RESET_CIRCLE_BACKGROUND_FAILED, data: {} })
		}
	})
	return
}

const UPDATE_CIRCLE_DETAILS_REQUESTED = 'UPDATE_CIRCLE_DETAILS_REQUESTED'
const UPDATE_CIRCLE_DETAILS_SUCCESS = 'UPDATE_CIRCLE_DETAILS_SUCCESS'
const UPDATE_CIRCLE_DETAILS_FAILED = 'UPDATE_CIRCLE_DETAILS_FAILED'

export const updateCircleDetailsById = (circleDetails) => (dispatch) => {
	dispatch({ type: UPDATE_CIRCLE_DETAILS_REQUESTED, data: {} })
	return circle.updateCircleDetailsById(circleDetails).then((circleDetails) => {
		if (circleDetails && circleDetails.isSuccessful) {
			dispatch(fetchCircle(circleDetails.id))
			dispatch(hideModal())
			if (circleDetails.isArchived) {
				dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.circle.circleArchived', isSuccess: true }
				})
				history.push('/circles')
			} else {
				return dispatch({ type: UPDATE_CIRCLE_DETAILS_SUCCESS, data: circleDetails })
			}
		} else {
			return dispatch({ type: UPDATE_CIRCLE_DETAILS_FAILED, data: {} })
		}
	})
}

const UPDATE_CIRCLE_TERMS_REQUESTED = 'UPDATE_CIRCLE_TERMS_REQUESTED'
const UPDATE_CIRCLE_TERMS_SUCCESS = 'UPDATE_CIRCLE_TERMS_SUCCESS'
const UPDATE_CIRCLE_TERMS_FAILED = 'UPDATE_CIRCLE_TERMS_FAILED'

export const updateTerms = (id, terms) => (dispatch) => {
	dispatch({ type: UPDATE_CIRCLE_TERMS_REQUESTED, data: {} })
	return circle.updateTerms(id, terms).then((circleDetails) => {
		if (circleDetails && circleDetails.isSuccessful) {
			dispatch(fetchCircle(id))
			dispatch(hideModal())
			if (circleDetails.isArchived) {
				dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.circle.circleArchived', isSuccess: true }
				})
				history.push('/circles')
			} else {
				return dispatch({ type: UPDATE_CIRCLE_DETAILS_SUCCESS, data: circleDetails })
			}
		} else {
			return dispatch({ type: UPDATE_CIRCLE_DETAILS_FAILED, data: {} })
		}
	})
}

const RESEND_CIRCLE_INVITATION_REQUESTED = 'RESEND_CIRCLE_INVITATION_REQUESTED'
const RESEND_CIRCLE_INVITATION_SUCCESS = 'RESEND_CIRCLE_INVITATION_SUCCESS'
const RESEND_CIRCLE_INVITATION_FAILED = 'RESEND_CIRCLE_INVITATION_FAILED'

export const resendCircleInvitationEmail = (invitationId, circleId) => (dispatch) => {
	dispatch({ type: RESEND_CIRCLE_INVITATION_REQUESTED, data: {} })

	let user = cookies.get('user')

	return invitations.resendCircleInvitation(invitationId, circleId).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.userProfile.teamMembeResendInvitateSuccess', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.userProfile.teamMembeResendInvitateFailed', isSuccess: false }
			})
		}
	})
	return
}

const SET_CIRCLE_MEMBERS_KEY_REQUESTED = 'SET_CIRCLE_MEMBERS_KEY_REQUESTED'
const SET_CIRCLE_MEMBERS_KEY_SUCCESS = 'SET_CIRCLE_MEMBERS_KEY_SUCCESS'
const SET_CIRCLE_MEMBERS_KEY_FAILED = 'SET_CIRCLE_MEMBERS_KEY_FAILED'

export const setCircleMemberKey = (searchTerm) => (dispatch) => {
	dispatch({ type: SET_CIRCLE_MEMBERS_KEY_REQUESTED, data: {} })
	return dispatch({ type: SET_CIRCLE_MEMBERS_KEY_SUCCESS, data: searchTerm })
}

const REINSTATE_CIRCLE_REQUESTED = 'REINSTATE_CIRCLE_REQUESTED'
const REINSTATE_CIRCLE_SUCCESS = 'REINSTATE_CIRCLE_SUCCESS'
const REINSTATE_CIRCLE_FAILED = 'REINSTATE_CIRCLE_FAILED'

export const reinstateCircle = (circleId) => (dispatch, getState) => {
	dispatch({ type: REINSTATE_CIRCLE_REQUESTED, data: {} })

	return circle.reinstateCircleArchive(circleId).then((response) => {
		if (response && response.isSuccessful) {
			var state = getState()
			if (state && state.circle && state.circle.searchCircles && state.circle.searchCircles.length > 0) {
				let r = [ ...state.circle.searchCircles ]
				r.map((r) => {
					if (r.id === circleId) {
						r.isArchived = false
					}
				})
				dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.circles.successfullyReinstated', isSuccess: true }
				})
				return dispatch({ type: SEARCH_CIRCLES_SUCCESS, data: {items: r} })
			}
		} else {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.circles.reinstatedFailed', isSuccess: true }
			})
			return dispatch({ type: REINSTATE_CIRCLE_FAILED, data: {} })
		}
	})
}

const MAKE_CIRCLE_ADMINISTRATOR_REQUESTED = 'MAKE_CIRCLE_ADMINISTRATOR_REQUESTED'
const MAKE_CIRCLE_ADMINISTRATOR_SUCCESS = 'MAKE_CIRCLE_ADMINISTRATOR_SUCCESS'
const MAKE_CIRCLE_ADMINISTRATOR_FAILED = 'MAKE_CIRCLE_ADMINISTRATOR_FAILED'

export const makeAdministratorMember = (userId, circleId) => (dispatch) => {
	dispatch({ type: MAKE_CIRCLE_ADMINISTRATOR_REQUESTED, data: {} })
	return circle.makeAdminMemberByCircleUserId(userId, circleId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(fetchCircle(circleId))
			dispatch(getCircleMembersById(circleId, null))
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.circle.assignAdministratorSuccess', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 
					response && response.errorCode === 15 
					? 'app.circle.assignAdministratorFailedNTO' 
					: `app.circle.assignAdministratorFailed`
					, isSuccess: false }
			})
		}
	})
	return
}

const MAKE_CIRCLE_SPONSOR_REQUESTED = 'MAKE_CIRCLE_SPONSOR_REQUESTED'
const MAKE_CIRCLE_SPONSOR_SUCCESS = 'MAKE_CIRCLE_SPONSOR_SUCCESS'
const MAKE_CIRCLE_SPONSOR_FAILED = 'MAKE_CIRCLE_SPONSOR_FAILED'

export const makeSponsorMember = (userId, circleId) => (dispatch) => {
	dispatch({ type: MAKE_CIRCLE_SPONSOR_REQUESTED, data: {} })
	return circle.makeSponsorMemberByCircleUserId(userId, circleId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(searchCircles())
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.circle.assignSponsorSuccess', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 
					response && response.errorCode === 15 
					? 'app.circle.assignSponsorFailedNTO' 
					: `app.circle.assignSponsorFailed`
					, isSuccess: false }
			})
		}
	})
	return
}

const REMOVE_USER_CIRCLE_REQUESTED = 'REMOVE_USER_CIRCLE_REQUESTED'
const REMOVE_USER_CIRCLE_SUCCESS = 'REMOVE_USER_CIRCLE_SUCCESS'
const REMOVE_USER_CIRCLE_FAILED = 'REMOVE_USER_CIRCLE_FAILED'

export const removeUserCircleMember = (member, circleId) => (dispatch) => {
	dispatch({ type: REMOVE_USER_CIRCLE_REQUESTED, data: {} })

	return circle.removeUserCircleById(member.email, circleId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(fetchCircle(circleId))
			dispatch(hideModal())
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.circle.removeUserCircleSuccess', isSuccess: true }
			})
			return dispatch({ type: REMOVE_USER_CIRCLE_SUCCESS, data: member.email })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.circle.removeUserCircleFailed', isSuccess: false }
			})
		}
	})
	return
}

const LEAVE_CIRCLE_REQUESTED = 'LEAVE_CIRCLE_REQUESTED'
const LEAVE_CIRCLE_SUCCESS = 'LEAVE_CIRCLE_SUCCESS'
const LEAVE_CIRCLE_FAILED = 'LEAVE_CIRCLE_FAILED'

export const leaveCircle = (circleId) => (dispatch) => {
	dispatch({ type: LEAVE_CIRCLE_REQUESTED, data: {} })
	return circle.submitLeaveCircleById(circleId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(hideModal())
			return dispatch({ type: LEAVE_CIRCLE_SUCCESS, data: circleId })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.circle.leaveCircleFailed', isSuccess: false }
			})
		}
	})
}

const DISTRIBUTE_CIRCLE_RESOURCES_REQUESTED = 'DISTRIBUTE_CIRCLE_RESOURCES_REQUESTED'
const DISTRIBUTE_CIRCLE_RESOURCES_SUCCESS = 'DISTRIBUTE_CIRCLE_RESOURCES_SUCCESS'
const DISTRIBUTE_CIRCLE_RESOURCES_FAILED = 'DISTRIBUTE_CIRCLE_RESOURCES_FAILED'

export const distributeResources = (circleId, numberUsers, numberCases) => (dispatch) => {
	dispatch({ type: DISTRIBUTE_CIRCLE_RESOURCES_REQUESTED, data: {} })
	return resources.distribute(circleId, numberUsers, numberCases).then((response) => {
		if (response) {
			dispatch({ type: DISTRIBUTE_CIRCLE_RESOURCES_SUCCESS, data: response })
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'Distributed Successfully', isSuccess: true }
			})
		} else {
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.errorMessages.${response.errorCode}`, isSuccess: false }
			})
		}
	})
}

const SET_CIRCLE_SORT_REQUESTED = 'SET_CIRCLE_SORT_REQUESTED'

export const setCirclesSort = (field, isDescending) => (dispatch, state) => {
	let currentState = state()

	let d = [ ...currentState.circle.searchCircles ]
	let data = d.sort((a, b) => {
		if (isDescending) {
			return a[field] > b[field] ? -1 : 1
		} else {
			return b[field] > a[field] ? -1 : 1
		}
	})

	return dispatch({
		type: SET_CIRCLE_SORT_REQUESTED,
		data: { items: data, field: field, isDescending: isDescending }
	})
}
const SET_CIRCLE_MEMBER_SORT_REQUESTED = 'SET_CIRCLE_MEMBER_SORT_REQUESTED'

const applyCircleMemberSort = (items, field, isDescending) => {
    let data = field === 'name'
		? items.sort((a, b) => {
            let a1 = a['name'] || a['email']
            let b1 = b['name'] || b['email']
            console.log(a1, b1)
            if (isDescending) {
                return a1 > b1 ? -1 : 1
            } else {
                return b1 > a1 ? -1 : 1
            }
        })
		: items.sort((a, b) => {
            if (isDescending) {
                return a[field] > b[field] ? -1 : 1
            } else {
                return b[field] > a[field] ? -1 : 1
            }
        });

    return { items: data, field: field, isDescending: isDescending };
}

export const setCircleMemberSort = (field, isDescending) => (dispatch, state) => {
	let currentState = state()

	let d = [ ...currentState.circle.circleInvitations ]

    let response = applyCircleMemberSort(d, field, isDescending);

    return dispatch({
        type: SET_CIRCLE_MEMBER_SORT_REQUESTED,
		data: { items: response.items, field: response.field, isDescending: response.isDescending }
    })
}

const FETCH_TREATMENT_REQUESTED = 'FETCH_TREATMENT_REQUESTED'
const FETCH_TREATMENT_SUCCESS = 'FETCH_TREATMENT_SUCCESS'
const FETCH_TREATMENT_FAILED = 'FETCH_TREATMENT_FAILED'

export const getTreatmentById = (id) => (dispatch) => {
	dispatch({ type: FETCH_TREATMENT_REQUESTED, data: {} })

	if(!id)
	{
		dispatch({ type: FETCH_TREATMENT_SUCCESS, data: null });
	}
	else
	{
		circle.fetchTreatment(id).then((response) => {
			if (response && response.isSuccessful) {
				if (response.treatment) {
					dispatch({ type: FETCH_TREATMENT_SUCCESS, data: response.treatment })
				}
			} else {
				dispatch({ type: FETCH_TREATMENT_FAILED, data: {} })
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: `app.treatments.loadingFailed`, isSuccess: false }
				})
			}
		})
	}
	return
}

const FETCH_PATHOLOGY_REQUESTED = 'FETCH_PATHOLOGY_REQUESTED'
const FETCH_PATHOLOGY_SUCCESS = 'FETCH_PATHOLOGY_SUCCESS'
const FETCH_PATHOLOGY_FAILED = 'FETCH_PATHOLOGY_FAILED'

export const getPathologyById = (id) => (dispatch) => {
	dispatch({ type: FETCH_PATHOLOGY_REQUESTED, data: {} })

	if(!id)
	{
		dispatch({ type: FETCH_PATHOLOGY_SUCCESS, data: null });
	}
	else
	{
		circle.fetchPathology(id).then((response) => {
			if (response && response.isSuccessful) {
				if (response.pathology) {
					dispatch({ type: FETCH_PATHOLOGY_SUCCESS, data: response.pathology })
				}
			} else {
				dispatch({ type: FETCH_PATHOLOGY_FAILED, data: {} })
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: `app.pathologies.loadingFailed`, isSuccess: false }
				})
			}
		})
	}
	return
}

const UPDATE_CIRCLE_TREATMENT_REQUESTED = 'UPDATE_CIRCLE_TREATMENT_REQUESTED'
const UPDATE_CIRCLE_TREATMENT_SUCCESS = 'UPDATE_CIRCLE_TREATMENT_SUCCESS'
const UPDATE_CIRCLE_TREATMENT_FAILED = 'UPDATE_CIRCLE_TREATMENT_FAILED'

export const updateCircleTreatment = (data) => (dispatch) => {
	dispatch({ type: UPDATE_CIRCLE_TREATMENT_REQUESTED, data: {} })
	return circle.updateCircleTreatment(data).then((response) => {
		if (response) {
			dispatch(hideModal())
			return dispatch({
				type: UPDATE_CIRCLE_TREATMENT_SUCCESS,
				data: data.treatmentId
			})
		} else {
			return dispatch({
				type: UPDATE_CIRCLE_TREATMENT_FAILED,
				data: {}
			})
		}
	})
}

const UPDATE_CIRCLE_PATHOLOGY_REQUESTED = 'UPDATE_CIRCLE_PATHOLOGY_REQUESTED'
const UPDATE_CIRCLE_PATHOLOGY_SUCCESS = 'UPDATE_CIRCLE_PATHOLOGY_SUCCESS'
const UPDATE_CIRCLE_PATHOLOGY_FAILED = 'UPDATE_CIRCLE_PATHOLOGY_FAILED'

export const updateCirclePathology = (data) => (dispatch) => {
	dispatch({ type: UPDATE_CIRCLE_PATHOLOGY_REQUESTED, data: {} })
	return circle.updateCirclePathology(data).then((response) => {
		if (response) {
			dispatch(hideModal())
			return dispatch({
				type: UPDATE_CIRCLE_PATHOLOGY_SUCCESS,
				data: data.pathologyId
			})
		} else {
			return dispatch({
				type: UPDATE_CIRCLE_PATHOLOGY_FAILED,
				data: {}
			})
		}
	})
}

const UPDATE_PLAN_REQUESTED = 'UPDATE_PLAN_REQUESTED'
const UPDATE_PLAN_SUCCESS = 'UPDATE_PLAN_SUCCESS'
const UPDATE_PLAN_FAILED = 'UPDATE_PLAN_FAILED'

export const updatePlan = (userCount, caseCount, subscriptionExpiration, circleId) => (dispatch) => {
	dispatch({ type: UPDATE_PLAN_REQUESTED, data: {} })
	return circle.updatePlan(userCount, caseCount, subscriptionExpiration, circleId).then((response) => {
		dispatch(stopSaving())
		if (response) {
			dispatch(hideModal())
			dispatch(fetchCircle(circleId))
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'Success', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.circle.updatePlanFailed', isSuccess: false }
			})
		}
	})
}

const GET_STATISTICS_EXPORT_REQUESTED = 'GET_STATISTICS_EXPORT_REQUESTED'
const GET_STATISTICS_EXPORT_SUCCESS = 'GET_STATISTICS_EXPORT_SUCCESS'
const GET_STATISTICS_EXPORT_FAILED = 'GET_STATISTICS_EXPORT_FAILED'

export const getCircleStatisticsExport = (circleId) => (dispatch) => {
	dispatch({ type: GET_STATISTICS_EXPORT_REQUESTED })

	return circle.getStatisticsExport(circleId).then((response) => {
		if (response) {
			dispatch({ type: GET_STATISTICS_EXPORT_SUCCESS })

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'Success', isSuccess: true }
			})
		} else {
			dispatch({ type: GET_STATISTICS_EXPORT_FAILED })

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.exportFailed', isSuccess: false }
			})
		}
	})
}

const CHANGE_HIDE_PATIENT_RESULTS_REQUESTED = 'CHANGE_HIDE_PATIENT_RESULTS_REQUESTED'
const CHANGE_HIDE_PATIENT_RESULTS_SUCCESS = 'CHANGE_HIDE_PATIENT_RESULTS_SUCCESS'
const CHANGE_HIDE_PATIENT_RESULTS_FAILED = 'CHANGE_HIDE_PATIENT_RESULTS_FAILED'

export const changeCircleHidePatientResults = (circleId) => (dispatch) => {
	dispatch({ type: CHANGE_HIDE_PATIENT_RESULTS_REQUESTED, data: {} })
	return circle.changeHidePatientResults(circleId).then((response) => {
		if (response) {
			return dispatch({
				type: CHANGE_HIDE_PATIENT_RESULTS_SUCCESS,
				data: {}
			})
		} else {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.circle.updatePlanFailed', isSuccess: false }
			})

			return dispatch({
				type: CHANGE_HIDE_PATIENT_RESULTS_FAILED,
				data: {}
			})
		}
	})
}

const GENERATE_INVITATION_LINK_REQUESTED = 'GENERATE_INVITATION_LINK_REQUESTED'
const GENERATE_INVITATION_LINK_SUCCESS = 'GENERATE_INVITATION_LINK_SUCCESS'
const GENERATE_INVITATION_LINK_FAILED = 'GENERATE_INVITATION_LINK_FAILED'

export const generateCircleInvitationLink = (circleId, isSponsored) => (dispatch) => {
	dispatch({ type: GENERATE_INVITATION_LINK_REQUESTED, data: {} })
	return circle.generateCircleInvitationLink(circleId, isSponsored).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({
				type: GENERATE_INVITATION_LINK_SUCCESS,
				data: response.link
			})
		} else {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.circles.generateLinkFailedToGenerateLink', isSuccess: false }
			})

			return dispatch({
				type: GENERATE_INVITATION_LINK_FAILED,
				data: {}
			})
		}
	})
}

const CHANGE_ACCESS_TO_PI_DATA_REQUESTED = 'CHANGE_ACCESS_TO_PI_DATA_REQUESTED'
const CHANGE_ACCESS_TO_PI_DATA_SUCCESS = 'CHANGE_ACCESS_TO_PI_DATA_SUCCESS'
const CHANGE_ACCESS_TO_PI_DATA_FAILED = 'CHANGE_ACCESS_TO_PI_DATA_FAILED'

export const changeAccessToPiData = (circleId, isAdmin) => (dispatch) => {
	dispatch({ type: CHANGE_ACCESS_TO_PI_DATA_REQUESTED, data: {} })
	return circle.changeAccessToPiData(circleId, isAdmin).then((response) => {
		if (response) {
			return dispatch({
				type: CHANGE_ACCESS_TO_PI_DATA_SUCCESS,
				data: {}
			})
		} else {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.circle.updatePlanFailed', isSuccess: false }
			})

			return dispatch({
				type: CHANGE_ACCESS_TO_PI_DATA_FAILED,
				data: {}
			})
		}
	})
}

const CHANGE_ACCESS_TO_ALL_DATA_REQUESTED = 'CHANGE_ACCESS_TO_ALL_DATA_REQUESTED'
const CHANGE_ACCESS_TO_ALL_DATA_SUCCESS = 'CHANGE_ACCESS_TO_ALL_DATA_SUCCESS'
const CHANGE_ACCESS_TO_ALL_DATA_FAILED = 'CHANGE_ACCESS_TO_ALL_DATA_FAILED'

export const changeAccessToAllData = (circleId, isAdmin) => (dispatch) => {
	dispatch({ type: CHANGE_ACCESS_TO_ALL_DATA_REQUESTED, data: {} })
	return circle.changeAccessToAllData(circleId, isAdmin).then((response) => {
		if (response) {
			return dispatch({
				type: CHANGE_ACCESS_TO_ALL_DATA_SUCCESS,
				data: {}
			})
		} else {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.circle.updatePlanFailed', isSuccess: false }
			})

			return dispatch({
				type: CHANGE_ACCESS_TO_ALL_DATA_FAILED,
				data: {}
			})
		}
	})
}

const CLEAR_INVITATION_LINK_REQUESTED = 'CLEAR_INVITATION_LINK_REQUESTED'

export const clearCircleInvitationLink = () => (dispatch) => {
	return dispatch({ type: CLEAR_INVITATION_LINK_REQUESTED, data: {} })
}

const STOP_SPONSORING_MEMBER_REQUESTED = 'STOP_SPONSORING_MEMBER_REQUESTED'
const STOP_SPONSORING_MEMBER_SUCCESS = 'STOP_SPONSORING_MEMBER_SUCCESS'
const STOP_SPONSORING_MEMBER_FAILED = 'STOP_SPONSORING_MEMBER_FAILED'

export const stopSponsoringCircleMember = (userId, circleId) => (dispatch) => {
	dispatch({ type: STOP_SPONSORING_MEMBER_REQUESTED, data: {} })
	return circle.stopSponsoringMember(userId, circleId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(getCircleMembersById(circleId))
			dispatch(fetchCircle(circleId))
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.circle.stopSponsoringMemberSuccess', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.circle.stopSponsoringMemberFailed', isSuccess: false }
			})
		}
	})
}

export const stopSponsoringCircleMemberInvitation = (invitationId, circleId) => (dispatch) => {
	dispatch({ type: STOP_SPONSORING_MEMBER_REQUESTED, data: {} })
	
	return circle.stopSponsoringMemberInvitation(invitationId, circleId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(getCircleMembersById(circleId))
			dispatch(fetchCircle(circleId))
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.circle.stopSponsoringMemberSuccess', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.circle.stopSponsoringMemberFailed', isSuccess: false }
			})
		}
	})
}

export const startSponsoringCircleMemberInvitation = (invitationId, circleId) => (dispatch) => {
	dispatch({ type: STOP_SPONSORING_MEMBER_REQUESTED, data: {} })
	
	return circle.startSponsoringMemberInvitation(invitationId, circleId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(getCircleMembersById(circleId))
			dispatch(fetchCircle(circleId))
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.circle.startSponsoringMemberSuccess', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.circle.startSponsoringMemberFailed', isSuccess: false }
			})
		}
	})
}

const START_SPONSORING_MEMBER_REQUESTED = 'START_SPONSORING_MEMBER_REQUESTED'
const START_SPONSORING_MEMBER_SUCCESS = 'START_SPONSORING_MEMBER_SUCCESS'
const START_SPONSORING_MEMBER_FAILED = 'START_SPONSORING_MEMBER_FAILED'

export const startSponsoringCircleMember = (userId, circleId) => (dispatch) => {
	dispatch({ type: START_SPONSORING_MEMBER_REQUESTED, data: {} })
	return circle.startSponsoringMember(userId, circleId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(getCircleMembersById(circleId))
			dispatch(fetchCircle(circleId))
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.circle.startSponsoringMemberSuccess', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.circle.startSponsoringMemberFailed', isSuccess: false }
			})
		}
	})
}

const SET_CIRCLE_SEARCH_TERM_REQUESTED = 'SET_CIRCLE_SEARCH_TERM_REQUESTED'
const SET_CIRCLE_SEARCH_TERM_SUCCESS = 'SET_CIRCLE_SEARCH_TERM_SUCCESS'
const SET_CIRCLE_SEARCH_TERM_FAILED = 'SET_PROTOCOL_SEARCH_TERM_FAILED'

export const setCircleSearchTerm = (value) => (dispatch) => {
	dispatch({ type: SET_CIRCLE_SEARCH_TERM_REQUESTED, data: {} })
	return dispatch({ type: SET_CIRCLE_SEARCH_TERM_SUCCESS, data: value })
}

const ALTER_CAN_CHANGE_CASE_FUNDING_REQUESTED = 'ALTER_CAN_CHANGE_CASE_FUNDING_REQUESTED'
const ALTER_CAN_CHANGE_CASE_FUNDING_SUCCESS = 'ALTER_CAN_CHANGE_CASE_FUNDING_SUCCESS'
const ALTER_CAN_CHANGE_CASE_FUNDING_FAILED = 'ALTER_CAN_CHANGE_CASE_FUNDING_FAILED'

export const alterCanChangeCaseFunding = (circleId) => (dispatch) => {
	dispatch({ type: ALTER_CAN_CHANGE_CASE_FUNDING_REQUESTED, data: {} })
	return circle.alterCanChangeCaseFunding(circleId).then((response) => {
		if (response) {
			return dispatch({
				type: ALTER_CAN_CHANGE_CASE_FUNDING_SUCCESS,
				data: {}
			})
		} else {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.circle.updatePlanFailed', isSuccess: false }
			})

			return dispatch({
				type: ALTER_CAN_CHANGE_CASE_FUNDING_FAILED,
				data: {}
			})
		}
	})
}

const CHANGE_DEFAULT_CASE_FUNDING_REQUESTED = 'CHANGE_DEFAULT_CASE_FUNDING_REQUESTED'
const CHANGE_DEFAULT_CASE_FUNDING_SUCCESS = 'CHANGE_DEFAULT_CASE_FUNDING_SUCCESS'
const CHANGE_DEFAULT_CASE_FUNDING_FAILED = 'CHANGE_DEFAULT_CASE_FUNDING_FAILED'

export const changeDefaultCaseFunding = (circleId, value) => (dispatch) => {
	dispatch({ type: CHANGE_DEFAULT_CASE_FUNDING_REQUESTED, data: {} })
	return circle.changeDefaultCaseFunding(circleId, value).then((response) => {
		if (response) {
			dispatch(fetchCircle(circleId))
			return dispatch({
				type: CHANGE_DEFAULT_CASE_FUNDING_SUCCESS,
				data: {}
			})
		} else {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.circle.updatePlanFailed', isSuccess: false }
			})

			return dispatch({
				type: CHANGE_DEFAULT_CASE_FUNDING_FAILED,
				data: {}
			})
		}
	})
}

const CHANGE_CASE_FUNDING_REQUESTED = 'CHANGE_CASE_FUNDING_REQUESTED'
const CHANGE_CASE_FUNDING_SUCCESS = 'CHANGE_CASE_FUNDING_SUCCESS'
const CHANGE_CASE_FUNDING_FAILED = 'CHANGE_CASE_FUNDING_FAILED'

export const changeCaseFunding = (circleId, userId, value) => (dispatch) => {
	dispatch({ type: CHANGE_CASE_FUNDING_REQUESTED, data: {} })
	return circle.changeCaseFunding(circleId, userId, value).then((response) => {
		if (!!response?.isSuccessful) {
			dispatch(getCircleMembersById(circleId))
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.circles.changeCaseFundingSuccess', isSuccess: true }
			})

			return dispatch({
				type: CHANGE_CASE_FUNDING_SUCCESS,
				data: {}
			})
		} else {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.circles.changeCaseFundingError', isSuccess: false }
			})

			return dispatch({
				type: CHANGE_CASE_FUNDING_FAILED,
				data: {}
			})
		}
	})
}

const CHANGE_CASE_SPONSOR_REQUESTED = 'CHANGE_CASE_SPONSOR_REQUESTED'
const CHANGE_CASE_SPONSOR_SUCCESS = 'CHANGE_CASE_SPONSOR_SUCCESS'
const CHANGE_CASE_SPONSOR_FAILED = 'CHANGE_CASE_SPONSOR_FAILED'

export const changeCaseSponsor = (circleId, userId, caseFunding, caseSponsorId) => (dispatch) => {
	dispatch({ type: CHANGE_CASE_SPONSOR_REQUESTED, data: {} })
	return circle.changeCaseSponsor(circleId, userId, caseFunding, caseSponsorId).then((response) => {
		if (!!response?.isSuccessful) {
			dispatch(hideModal())
			dispatch(getCircleMembersById(circleId))
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.circles.changeCaseFundingSuccess', isSuccess: true }
			})

			return dispatch({
				type: CHANGE_CASE_SPONSOR_SUCCESS,
				data: {}
			})
		} else {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.circles.changeCaseFundingError', isSuccess: false }
			})

			return dispatch({
				type: CHANGE_CASE_SPONSOR_FAILED,
				data: {}
			})
		}
	})
}

const FETCH_CIRCLE_REPORT_DATA_EXPORT_REQUESTED = 'FETCH_CIRCLE_REPORT_DATA_EXPORT_REQUESTED'
const FETCH_CIRCLE_REPORT_DATA_EXPORT_SUCCESS = 'FETCH_CIRCLE_REPORT_DATA_EXPORT_SUCCESS'
const FETCH_CIRCLE_REPORT_DATA_EXPORT_FAILED = 'FETCH_CIRCLE_REPORT_DATA_EXPORT_FAILED'

export const fetchCircleReportDataExport = (circleId, headersType) => (dispatch) => {
	dispatch({ type: FETCH_CIRCLE_REPORT_DATA_EXPORT_REQUESTED, data: {} })

	circle.getCircleReportDataExport(circleId, headersType).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(hideModal())
			dispatch(setNewReportInitiated())
			return dispatch({ type: FETCH_CIRCLE_REPORT_DATA_EXPORT_SUCCESS, data: {} })
		} else {
			dispatch({ type: FETCH_CIRCLE_REPORT_DATA_EXPORT_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.fetchReportFailed', isSuccess: false }
			})
		}
	})
}

const GET_CIRCLE_MEMBER_PATIENT_INVITATION_LINK_REQUESTED = 'GET_CIRCLE_MEMBER_PATIENT_INVITATION_LINK_REQUESTED'
const GET_CIRCLE_MEMBER_PATIENT_INVITATION_LINK_SUCCESS = 'GET_CIRCLE_MEMBER_PATIENT_INVITATION_LINK_SUCCESS'
const GET_CIRCLE_MEMBER_PATIENT_INVITATION_LINK_FAILED = 'GET_CIRCLE_MEMBER_PATIENT_INVITATION_LINK_FAILED'

export const getCircleMemberPatientInvitationLink = (circleId, member) => (dispatch) => {
	dispatch({ type: GET_CIRCLE_MEMBER_PATIENT_INVITATION_LINK_REQUESTED, data: {} })
	
	circle.fetchCircleMemberPatientInvitationLink(circleId, member.userId)
		.then((response) => {
			if (
				response
				&& response.isSuccessful
			) {
				navigator.permissions.query({ name: 'clipboard-write' }).then((result) => {
                    if (result.state == 'granted' || result.state == 'prompt') {
                        navigator.clipboard.writeText(response.link).then(
                            function () {
                                return dispatch({
                                    type: 'SET_SNACKBAR_MESSAGE',
                                    data: { message: 'app.circles.generateLinkCopiedToClipboard', isSuccess: true }
                                })
                            },
                            function () {
                                /* clipboard write failed */
                            }
                        )
                    } else {
                        return dispatch({
                            type: 'SET_SNACKBAR_MESSAGE',
                            data: { message: 'app.circles.generateLinkAccessToClipboardIsDenied', isSuccess: false }
                        })
                    }
                }).catch(() => {
                    let successful = false;

                    try
                    {
                        var textArea = document.createElement("textarea");
                        textArea.value = response.link;

                        textArea.style.top = "0";
                        textArea.style.left = "0";
                        textArea.style.position = "fixed";

                        document.body.appendChild(textArea);

                        textArea.focus();
                        textArea.select();

                        successful = document.execCommand('copy');

                        document.body.removeChild(textArea);
                    }
                    catch (err)
                    {
                        console.error('Unable to copy', err);
                    }

                    if (successful) {
                        return dispatch({
                            type: 'SET_SNACKBAR_MESSAGE',
                            data: { message: 'app.circles.generateLinkCopiedToClipboard', isSuccess: true }
                        })
                    }
                    else
                    {
                        return dispatch({
                            type: 'SET_SNACKBAR_MESSAGE',
                            data: { message: 'app.circles.generateLinkFailedToGenerateLink', isSuccess: false }
                        })
                    }
                })

				return dispatch({ type: GET_CIRCLE_MEMBER_PATIENT_INVITATION_LINK_SUCCESS, data: {} })
			} else {
				return dispatch({ type: GET_CIRCLE_MEMBER_PATIENT_INVITATION_LINK_FAILED, data: {} })
			}
		})
}

const SET_MEMBER_CASES_RATE_REQUESTED = 'SET_MEMBER_CASES_RATE_REQUESTED'
const SET_MEMBER_CASES_RATE_SUCCESS = 'SET_MEMBER_CASES_RATE_SUCCESS'
const SET_MEMBER_CASES_RATE_FAILED = 'SET_MEMBER_CASES_RATE_FAILED'

export const setMemberCasesRate = (circleId, member, casesRate) => (dispatch) => {
	dispatch({ type: SET_MEMBER_CASES_RATE_REQUESTED, data: {} })

	circle.changeMemberCasesRate(circleId, member.userId, casesRate)
		.then((response) => {
			if (response && response.isSuccessful) {
				dispatch({ type: SET_MEMBER_CASES_RATE_SUCCESS, data: {} })

				dispatch(hideModal())

				dispatch(fetchCircle(circleId))

				dispatch(getCircleMembersById(circleId))

				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.circle.members.casesRateSuccessfullySaved', isSuccess: true }
				})
			} else {
				dispatch({ type: SET_MEMBER_CASES_RATE_FAILED, data: {} })

				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.circle.members.failedToSaveCasesRate', isSuccess: false }
				})
			}
		})
}

const EXPORT_NON_COMPLIANT_SUBSCRIBERS_REQUESTED = 'EXPORT_NON_COMPLIANT_SUBSCRIBERS_REQUESTED'
const EXPORT_NON_COMPLIANT_SUBSCRIBERS_SUCCESS = 'EXPORT_NON_COMPLIANT_SUBSCRIBERS_SUCCESS'
const EXPORT_NON_COMPLIANT_SUBSCRIBERS_FAILED = 'EXPORT_NON_COMPLIANT_SUBSCRIBERS_FAILED'

export const exportNonCompliantSubscribers = (circleId, startDate, endDate) => (dispatch) => {
	dispatch({ type: EXPORT_NON_COMPLIANT_SUBSCRIBERS_REQUESTED, data: {} })

	circle.exportNonCompliantSubscribers(circleId, startDate, endDate)
		.then((response) => {
			if (
				!!response
				&& !!response.isSuccessful
			) {
				dispatch(hideModal())
				dispatch(setNewReportInitiated())
				return dispatch({ type: DOWNLOAD_USAGE_STATISTICS_SUCCESS, data: {} })
			}
			else {
				dispatch({ type: EXPORT_NON_COMPLIANT_SUBSCRIBERS_FAILED })

				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.general.exportFailed', isSuccess: false }
				})
			}
		})
}

const EXPORT_NON_COMPLIANT_SURVEYS_REQUESTED = 'EXPORT_NON_COMPLIANT_SURVEYS_REQUESTED'
const EXPORT_NON_COMPLIANT_SURVEYS_SUCCESS = 'EXPORT_NON_COMPLIANT_SURVEYS_SUCCESS'
const EXPORT_NON_COMPLIANT_SURVEYS_FAILED = 'EXPORT_NON_COMPLIANT_SURVEYS_FAILED'

export const exportNonCompliantSurveys = (circleId, params, fileName) => (dispatch, getState) => {
	dispatch({ type: EXPORT_NON_COMPLIANT_SURVEYS_REQUESTED, data: {} })

	let state = getState()

	let filters = {
		onboarding: state.chart.onboarding,
		periodFrom: state.chart.periodFrom,
		periodTo: state.chart.periodTo,
		surveyCompliance: state.chart.surveyCompliance,
		compliantSurveys: state.chart.compliantSurveys,
		filterInvestigators: state.chart.filterInvestigators,
		investigators: state.chart.includedInvestigators,
		filterFunders: state.chart.filterFunders,
		funders: state.chart.includedFunders,
		...params
	}

	let selectedProtocolVersion = state.protocol.selectedComplianceProtocolVersion

	circle.exportNonCompliantSurveys(circleId, selectedProtocolVersion, filters, fileName)
		.then((response) => {
			if (
				!!response
				&& !!response.isSuccessful
			) {
				dispatch(hideModal())
				dispatch(setNewReportInitiated())
				dispatch({ type: EXPORT_NON_COMPLIANT_SURVEYS_SUCCESS, data: {} })
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'Success', isSuccess: true }
				})
			}
			else {
				dispatch({ type: EXPORT_NON_COMPLIANT_SURVEYS_FAILED })

				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.general.exportFailed', isSuccess: false }
				})
			}
		})
}


const EXPORT_NON_COMPLIANT_PATIENTS_REQUESTED = 'EXPORT_NON_COMPLIANT_PATIENTS_REQUESTED'
const EXPORT_NON_COMPLIANT_PATIENTS_SUCCESS = 'EXPORT_NON_COMPLIANT_PATIENTS_SUCCESS'
const EXPORT_NON_COMPLIANT_PATIENTS_FAILED = 'EXPORT_NON_COMPLIANT_PATIENTS_FAILED'

export const exportNonCompliantPatients = (circleId, startDate, endDate, allPatients) => (dispatch) => {
	dispatch({ type: EXPORT_NON_COMPLIANT_PATIENTS_REQUESTED, data: {} })

	circle.exportNonCompliantPatients(circleId, startDate, endDate, allPatients)
		.then((response) => {
			if (
				!!response
				&& !!response.isSuccessful
			) {
				dispatch(hideModal())
				dispatch(setNewReportInitiated())
				dispatch({ type: EXPORT_NON_COMPLIANT_PATIENTS_SUCCESS, data: {} })
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'Success', isSuccess: true }
				})
			}
			else {
				dispatch({ type: EXPORT_NON_COMPLIANT_PATIENTS_FAILED })

				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.general.exportFailed', isSuccess: false }
				})
			}
		})
}

const initial = {
	circles: null,
	searchCircles: [],
	circle: null,
	filters: null,
	filter: 'Active',
	searchTerm: '',
	circleInvitations: [],
	autoCompleteSuggestions: [],
	autoCompleteSuggestionsByProtocol: [],
	autoCompleteSuggestionsByUser: [],
	isCreating: false,
	sortField: 'modifiedDateSeconds',
	isDescending: true,
	memberSortField: 'dateJoinedSeconds',
	memberIsDescending: false,
	isInviting: false,
	treatment: null,
	pathology: null,
	invitationLink: '',
	isReportDownloading: false,
	hasMore: true,
	isLoading: false,
	circleSearchTerm: '',
	circleReportIsExporting: false,
	sharedWithAutoCompleteSuggestions: []
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case SET_CIRCLE_INVITATION_MEMBER_REQUESTED:
			return { ...state, isInviting: true }
		case SET_CIRCLE_INVITATION_MEMBER_SUCCESS:
			return { ...state, isInviting: false }
		case SET_CIRCLE_INVITATION_MEMBER_FAILED:
			return { ...state, isInviting: false }
		case SET_CIRCLE_SEARCH_TERM_SUCCESS:
			return { ...state, circleSearchTerm: action.data }
		case SET_CIRCLE_MEMBER_SORT_REQUESTED:
			return {
				...state,
				circleInvitations: action.data.items,
				memberSortField: action.data.field,
				memberIsDescending: action.data.isDescending
			}
		case SET_CIRCLE_SORT_REQUESTED:
			return {
				...state,
				searchCircles: [ ...action.data.items ],
				sortField: action.data.field,
				isDescending: action.data.isDescending
			}
		case SEARCH_CIRCLES_SUCCESS:
			return { 
				...state, 
				searchCircles: action.data.items,
				hasMore: action.data.hasMore 
			}
		case SEARCH_ADDITIONAL_CIRCLES_SUCCESS:
			return {
				...state,
				searchCircles: [ ...state.searchCircles, ...action.data.items],
				hasMore: action.data.hasMore,
				isLoading: false
			}
		case SEARCH_ADDITIONAL_CIRCLES_FAILED:
			return {
				...state,
				hasMore: false,
				isLoading: false
			}
		case SEARCH_ADDITIONAL_CIRCLES_REQUESTED:
			return {
				...state,
				isLoading: true
			}
		case ADD_CIRCLE_SUCCESS:
			return {
				...state,
				isCreating: false,
				circleInvitations: [
					...state.circleInvitations.slice(0, action.data),
					action.data,
					...state.circleInvitations.slice(action.pos)
				]
			}
		case ADD_CIRCLE_FAILED:
			return {
				...state,
				isCreating: false
			}
		case FETCH_CIRCLE_SUCCESS:
			return { ...state, circle: action.data }
		case FETCH_CIRCLE_INVITATIONS_SUCCESS:
			return { ...state, circleInvitations: action.data }
		case SET_CIRCLE_INVITATION_MEMBER_SUCCESS:
			return {
				...state,
				circleInvitations: [
					...state.circleInvitations.slice(0, action.data),
					action.data,
					...state.circleInvitations.slice(action.pos)
				]
			}
		case FETCH_CIRCLES_FILTER_SUCCESS:
			return { ...state, filters: action.data }
		case SET_CIRCLES_FILTER_SUCCESS:
			return { ...state, filter: action.data }
		case UPDATE_CIRCLE_DETAILS_SUCCESS:
			return { ...state, circle: action.data }
		case RESEND_CIRCLE_INVITATION_SUCCESS:
			return { ...state, circle: action.data }
		case SET_CIRCLE_MEMBERS_KEY_SUCCESS:
			return { ...state, searchTerm: action.data }
		case REINSTATE_CIRCLE_SUCCESS:
			return {
				...state,
				circles: state.circles.map(
					(circle) =>
						circle.id === action.data.id ? { ...circle, isArchived: action.data.isArchived } : circle
				)
			}
		case LEAVE_CIRCLE_SUCCESS:
			return { ...state, circles: state.circles.filter((item) => item.id !== action.data) }
		case REMOVE_USER_CIRCLE_SUCCESS:
			return { ...state, circleInvitations: state.circleInvitations.filter((item) => item.email !== action.data) }
		case DISTRIBUTE_CIRCLE_RESOURCES_SUCCESS:
			return {
				...state,
				circles: state.circles.map(
					(circle) =>
						circle.id === action.data.id
							? { ...circle, numberUsers: action.data.numberUsers, numberCases: action.data.numberCases }
							: circle
				)
			}
		case ADD_CIRCLE_REQUESTED:
			return { ...state, isCreating: true }
		case UPDATE_CIRCLE_TREATMENT_SUCCESS:
			return { ...state, circle: {...state.circle, treatmentId: action.data }}
		case UPDATE_CIRCLE_PATHOLOGY_SUCCESS:
			return { ...state, circle: {...state.circle, pathologyId: action.data }}
		case FETCH_TREATMENT_SUCCESS:
			return { ...state, treatment: action.data }
		case FETCH_PATHOLOGY_SUCCESS:
			return { ...state, pathology: action.data }
		case FETCH_CIRCLES_AUTOCOMPLETE_SUCCESS:
			return { ...state, autoCompleteSuggestions: action.data }
		case FETCH_PROTOCOL_CIRCLES_AUTOCOMPLETE_SUCCESS:
			return { ...state, autoCompleteSuggestionsByProtocol: action.data }
		case FETCH_CIRCLES_BY_USER_AUTOCOMPLETE_SUCCESS:
			return { ...state, autoCompleteSuggestionsByUser: action.data }
		case GENERATE_INVITATION_LINK_SUCCESS:
			return { ...state, invitationLink: action.data }
		case CLEAR_INVITATION_LINK_REQUESTED:
			return { ...state, invitationLink: '' }
		case 'SIGN_OUT_REQUESTED':
			return initial
		case DOWNLOAD_USAGE_STATISTICS_REQUESTED:
			return { 
				...state, 
				isReportDownloading: true
			}
		case GET_STATISTICS_EXPORT_REQUESTED:
			return { 
				...state, 
				isReportDownloading: true
			}
		case DOWNLOAD_USAGE_STATISTICS_SUCCESS:
			return { 
				...state, 
				isReportDownloading: false
			}
		case GET_STATISTICS_EXPORT_SUCCESS:
			return { 
				...state, 
				isReportDownloading: false
			}
		case DOWNLOAD_USAGE_STATISTICS_FAILED:
			return { 
				...state, 
				isReportDownloading: false
			}
		case GET_STATISTICS_EXPORT_FAILED:
            return { 
				...state, 
				isReportDownloading: false
			}
		case FETCH_CIRCLE_REPORT_DATA_EXPORT_REQUESTED:
			return {
				...state,
				circleReportIsExporting: true
			}
		case FETCH_CIRCLE_REPORT_DATA_EXPORT_SUCCESS:
			return {
				...state,
				circleReportIsExporting: false
			}
		case FETCH_CIRCLE_REPORT_DATA_EXPORT_FAILED:
			return {
				...state,
				circleReportIsExporting: false
			}
		case EXPORT_NON_COMPLIANT_SURVEYS_SUCCESS:
			return { 
				...state, 
				isReportDownloading: false
			}
		case EXPORT_NON_COMPLIANT_SURVEYS_FAILED:
			return { 
				...state, 
				isReportDownloading: false
			}
		case EXPORT_NON_COMPLIANT_PATIENTS_SUCCESS:
			return { 
				...state, 
				isReportDownloading: false
			}
		case EXPORT_NON_COMPLIANT_PATIENTS_FAILED:
			return { 
				...state, 
				isReportDownloading: false
			}
		case FETCH_SHARED_CIRCLES_AUTOCOMPLETE_SUCCESS:
			return { ...state, sharedWithAutoCompleteSuggestions: action.data }
		case FETCH_SHARED_CIRCLES_AUTOCOMPLETE_REQUESTED:
			return { ...state, sharedWithAutoCompleteSuggestions: [] }
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.circle
const coreSelector = (state) => state.core
const stateSelector = (state) => state

export const isCreatingSelector = createSelector(mainSelector, (state) => state.isCreating)

export const circlesSelector = createSelector(mainSelector, (state) => state.circles)
export const circleSelector = createSelector(mainSelector, (state) => state.circle)
export const circleInvitationsSelector = createSelector(mainSelector, (state) => {
		return state.circleInvitations
})
export const circlesFilterSelector = createSelector(mainSelector, (state) => state.filters)
export const canEditCircleBackgroundImageSelector = createSelector(mainSelector, (state) => {
	let user = getUser()
	if (user && state.circle) {
		if (state.circle.administrator && state.circle.administrator.id === user.id) {
			return true
		}
	}
	return false
})
export const canAddCircleMemberSelector = createSelector(mainSelector, (state) => {
	let user = getUser()

	if (user && state.circle) {
		return true
	}
	return false
})
export const canLeaveCircleSelector = createSelector(mainSelector, (state) => {
	let user = getUser()

	if (user && state) if (state.founder && state.founder.id !== user.id) return true

	return false
})
export const canInviteMemberCircleSelector = createSelector(stateSelector, (state) => {
	let user = getUser()
	if (state && state.circle && user) {
		if (state.subscriptionUser && state.subscriptionUser.availableUsers === 0) return false
		if (state.circle.founder && state.circle.founder.id === user.id) return true
		if (state.circle.administrator && state.circle.administrator.id === user.id) return true
	}
	return false
})
export const canEmailAdministratorSelector = createSelector(mainSelector, (state) => {
	let user = getUser()
	if (user && state) if (state.founder && state.founder.id !== user.id) return true
	return false
})
export const canReinstateCircleSelector = createSelector(mainSelector, (state) => {
	let user = getUser()
	if (user && state) if (state.isArchived && state.founder && user.id === state.founder.id) return true
	return false
})
export const invitedCircleMemberSelector = createSelector(coreSelector, (state) => {
	if (state && state.params && state.params.invitedCircle) {
		return state.params.invitedCircle
	}
	return null
})
export const canMakeAdministratorSelector = createSelector(stateSelector, (state) => {
	let user = getUser()
	if (user && state)
		if (state.circle && state.member)
			if (state.circle.administrator && user.id === state.circle.administrator.id && state.member.status === 1)
				return true
	return false
})
export const canResendEmailInvitationSelector = createSelector(stateSelector, (state) => {
	let user = getUser()
	if (user && state)
		if (state.circle && state.member)
			if (state.circle.administrator && user.id === state.circle.administrator.id && state.member.status === 0)
				return true
	return false
})
export const canRemoveUserCircleSelector = createSelector(stateSelector, (state) => {
	let user = getUser()
	if (user && state)
		if (state.circle && state.member)
			if (state.circle.administrator && user.id === state.circle.administrator.id) return true
	return false
})
export const filteredCirclesSelector = createSelector(mainSelector, (state) => {
	let data = state.circles ? state.circles : null

	if (data) {
		switch (state.filter) {
			case 0:
				return data.filter((f) => !f.isArchived)
			case 1:
				return data.filter((f) => f.isSponsored)
			case 2:
				return data.filter((f) => f.isArchived)
			default:
				return data
		}
	}
})
export const canViewCircleMemberSelector = createSelector(mainSelector, (state) => {
	if (state && state.circle && state.circle.isArchived) {
		return false
	}
	return true
})
export const isAdminCircleSelector = createSelector(mainSelector, (state) => {
	let user = getUser()
	if (user && state.circle) {
		if (state.circle.administrator && state.circle.administrator.id === user.id) {
			return true
		}
	}
	return false
})

export const circleAutoCompleteSuggestionSelector = createSelector(mainSelector, (state) => {
	if (state && state.autoCompleteSuggestions) {
		return state.autoCompleteSuggestions
	}
	return []
})

export const circlesByProtocolAutoCompleteSuggestionSelector = createSelector(mainSelector, (state) => {
	if (state && state.autoCompleteSuggestionsByProtocol) {
		return state.autoCompleteSuggestionsByProtocol
	}
	return []
})

export const circlesByUserAutoCompleteSuggestionSelector = createSelector(mainSelector, (state) => {
	if (state && state.autoCompleteSuggestionsByUser) {
		return state.autoCompleteSuggestionsByUser
	}
	return []
})

export const formattedCircleProtocolsSelector = createSelector(mainSelector, (state) => {
	return state.circles
		? state.circles.map((r) => {
				return {
					name: r.name,
					description: r.description,
					founder: r.founder ? `${r.founder.firstName} ${r.founder.lastName}` : '',
					observationalProtocol: r.observationalProtocol,
					label: r.observationProtocol ? `${r.observationProtocol.name} | ${r.name}` : r.name,
					id: r.id,
					protocolId: r.observationProtocol.id,
					isPhiFree: r.phi
				}
			})
		: []
})

export const formattedCirclesSelector = createSelector(mainSelector, (state) => {
	return state.circles
		? state.circles.map((r) => {
				return {
					name: r.name,
					description: r.description,
					founder: r.founder ? `${r.founder.firstName} ${r.founder.lastName}` : '',
					label: r.founder ? `${r.name} | ${r.founder.firstName} ${r.founder.lastName}` : r.name,
					id: r.id,
					isPhiFree: r.phi
				}
			})
		: []
})
export const canDistributeResourcesSelector = createSelector(mainSelector, (state) => {
	if (state) {
		if (state.isSponsored) return true
	}
	return false
})

export const searchCirclesSelector = createSelector(mainSelector, (state) => {
	let data = state.searchCircles ? state.searchCircles : null

	// if (data) {
	// 	switch (state.filter) {
	// 		case 0:
	// 			return data.filter((f) => !f.isArchived)
	// 		case 2:
	// 			return data.filter((f) => f.isSponsored)
	// 		case 1:
	// 			return data.filter((f) => f.isArchived)
	// 		default:
	// 			return data
	// 	}
	// }
	// return []

	return data
})

export const hasCirclesSelector = createSelector(mainSelector, (state) => {
	return state.hasCircles
})
export const sortCirclesDirectionSelector = createSelector(mainSelector, (state) => {
	return state.isDescending
})

export const sortCirclesFieldSelector = createSelector(mainSelector, (state) => {
	return state.sortField
})

export const sortMembersDirectionSelector = createSelector(mainSelector, (state) => {
	return state.memberIsDescending
})

export const sortMembersFieldSelector = createSelector(mainSelector, (state) => {
	return state.memberSortField
})

export const isInvitingSelector = createSelector(mainSelector, (state) => {
	return state.isInviting
})

export const isReportDownloadingSelector = createSelector(mainSelector, (state) => {
	return state.isReportDownloading
})

export const circleTreatmentSelector = createSelector(mainSelector, (state) => state.treatment)
export const circlePathologySelector = createSelector(mainSelector, (state) => state.pathology)
export const circleInvitationLinkSelector = createSelector(mainSelector, (state) => state.invitationLink)
export const circleSubscriptionExpirationSelector = createSelector(mainSelector, (state) => state.circle && state.circle.subscriptionExpirationDate)

export const hasMoreCirclesSelector = createSelector(mainSelector, (state) => {
	return state.hasMore
})

export const isLoadingCirclesSelector = createSelector(mainSelector, (state) => {
	return state.isLoading
})

export const circleSearchTermSelector = createSelector(mainSelector, (state) => {
	return state.circleSearchTerm
})

export const circleFilterSelector = createSelector(mainSelector, (state) => {
	return state.filter
})

export const circleReportIsExportingSelector = createSelector(mainSelector, (state) => {
	return state && state.circleReportIsExporting
})

export const sharedWithCircleAutoCompleteSuggestionSelector = createSelector(mainSelector, (state) => {
	if (state && state.sharedWithAutoCompleteSuggestions) {
		return state.sharedWithAutoCompleteSuggestions
	}
	return []
})