import * as timeZone from '../../../apis/timeZone'
import { createSelector } from 'reselect'
import * as cookies from '../../../utilities/cookies'

const FETCH_TIMEZONES_REQUESTED = 'FETCH_TIMEZONES_REQUESTED'
const FETCH_TIMEZONES_SUCCESS = 'FETCH_TIMEZONES_SUCCESS'
const FETCH_TIMEZONES_FAILED = 'FETCH_TIMEZONES_FAILED'
const FETCH_TIMEZONES_NOP = 'FETCH_TIMEZONES_NOP'

export const fetchTimeZones = () => (dispatch, getState) => {
	var state = getState()

	if (!state.timeZone.timeZones || state.timeZone.timeZones.length === 0) {
		dispatch({ type: FETCH_TIMEZONES_REQUESTED, data: {} })
		timeZone.fetchTimeZones().then((timeZones) => {
			if (timeZones) {
				return dispatch({ type: FETCH_TIMEZONES_SUCCESS, data: timeZones })
			} else {
				return dispatch({ type: FETCH_TIMEZONES_FAILED, data: {} })
			}
		})
	}
	return dispatch({ type: FETCH_TIMEZONES_NOP, data: {} })
}

const initial = {
	timeZones: []
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_TIMEZONES_SUCCESS:
			return { ...state, timeZones: action.data }
		default:
			return { ...state }
	}
}

export const mainSelector = (state) => state.timeZone

export const timeZonesSelector = (state) => state.timeZone.timeZones