import React from "react"
import { TableHead, TableRow } from "@material-ui/core"
import { useIntl } from "react-intl"
import EntityAuditTableCell from "./EntityAuditTableCell"


const EntityAuditTableHeader = ({}) => {
    const intl = useIntl()

    return (
        <TableHead>
            <TableRow>
                <EntityAuditTableCell>
                    { intl.formatMessage({ id: 'app.auditTable.head.entityTypeLabel' }) }
                </EntityAuditTableCell>
                <EntityAuditTableCell>
                    { intl.formatMessage({ id: 'app.auditTable.head.localizationLabel' }) }
                </EntityAuditTableCell>
                <EntityAuditTableCell>
                    { intl.formatMessage({ id: 'app.auditTable.head.entityActionTypeLabel' }) }
                </EntityAuditTableCell>
                <EntityAuditTableCell>
                    { intl.formatMessage({ id: 'app.auditTable.head.newValueLabel' }) }
                </EntityAuditTableCell>
                <EntityAuditTableCell>
                    { intl.formatMessage({ id: 'app.auditTable.head.userLabel' }) }
                </EntityAuditTableCell>
                <EntityAuditTableCell>
                    { intl.formatMessage({ id: 'app.auditTable.head.actionDateLabel' }) }
                </EntityAuditTableCell>
                <EntityAuditTableCell>
                    { intl.formatMessage({ id: 'app.auditTable.head.commentsLabel' }) }
                </EntityAuditTableCell>
            </TableRow>
        </TableHead>
    )
}

 export default EntityAuditTableHeader