import React from 'react'
import Translation from '../assets/translation'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

export default ({}) => {
	return (
		<Grid container alignItems="center">
			<Grid item>
				<Translation />
			</Grid>
			<Grid item style={{ marginLeft: '5px', marginTop: '-4px' }}>
				<Typography variant="caption">Translation Required</Typography>
			</Grid>
		</Grid>
	)
}
