import React, { useEffect, useState, Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { compose } from 'recompose'
import { connect, useSelector, shallowEqual, useDispatch } from 'react-redux'
import { reduxForm } from 'redux-form'
import { useRouteMatch, Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { ArrowLeftOutlined } from '@material-ui/icons'
import {
	swapBundleQuestionPosition,
	removeBundleQuestion,
	fetchBundleBuilderQuestions,
	updateEntityQuestion,
	addFormulaElement,
	removeLastFormulaElement,
	clearFormula,
	addBundleQuestion,
	addExistingBundleQuestion,
	fetchGroup,
	showModal,
	updateHideFromPatient,
	setExtendedFormula
} from '../../../redux/actions'
import {
	builderbundleQuestionTagsSelector,
	bundleNotFoundSelector,
	bundleRequestReceivedSelector,
	scoringGroupSelector,
	isExtendedModeSelector,
	extendedFormulaSelector
} from '../../../redux/selectors'
import ExpressionBuilder from './ExpressionBuilder'
import Bundles from './Bundles'
import KeyPad from './KeyPad'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import LanguageNavigator from './LanguageNavigator'
import EditGroupModal from './EditGroupModal'
import GroupForm from './GroupForm'
import CreateIcon from '@material-ui/icons/Create'
import { Switch } from '@material-ui/core'
import FunctionList from './FunctionList'
import ScoringGroupDetails from './ScoringGroupDetails'
import ScoringGroupAudit  from './ScoringGroupAudit'

const ScoringGroup = ({ loadGroup, intl, notFound, responseReceived, group, updateHideFromPatient }) => {
	const match = useRouteMatch()
	const tags = useSelector(builderbundleQuestionTagsSelector, shallowEqual)
	const extendedMode = useSelector(isExtendedModeSelector, shallowEqual)
	const extendedFormula = useSelector(extendedFormulaSelector, shallowEqual)
	const [ showEdit, setShowEdit ] = useState(false)
	const [ tabIndex, setTabIndex ] = useState(0)
	const [ currentVersion, setCurrentVersion ] = useState(null)
	const [ parent, setParent ] = useState(null)
	const [hideFromPatient, setHideFromPatient] = useState(false)
	const [selection, setSelection] = useState({
		start: null,
		end: null
	})

	const dispatch = useDispatch()
	useEffect(
		() => {
			if (match.params.groupId) {
				loadGroup(match.params.groupId)
			}
		},
		[ match.params.groupId, responseReceived ]
	)

	useEffect(
		() => {
			if (group) {
				setHideFromPatient(group.hideFromPatient)
			}
		},
		[ group ]
	)

	const insertExtendedFormulaValue = (value) => {
		let begin = extendedFormula.substring(0, selection.start)
		let end = extendedFormula.substring(selection.end)
		dispatch(setExtendedFormula(begin + value + end))
	}

	const addOperator = (next) => {
		dispatch(addFormulaElement({ type: 'operator', operator: next, text: next }))
	}

	const addConstant = (constant) => {
		dispatch(addFormulaElement({ type: 'constant', constant: parseFloat(constant), text: constant }))
	}

	const addTag = (tag) => {
		if (extendedMode) {
			insertExtendedFormulaValue(tag)
		}
		else {
			dispatch(addFormulaElement({ type: 'tag', text: tag, tag: tag, constant: null, operator: null }))
		}
	}

	const backspace = () => {
		dispatch(removeLastFormulaElement())
	}

	const clear = () => {
		dispatch(clearFormula())
	}

	const handleChange = (event, newValue) => {
		setTabIndex(newValue)
	}

	const edit = (id, name) => {
		if (id && group) {
			dispatch(showModal(EditGroupModal, { id: id, name: name }))
		}
	}

	const changeHideFromPatient = (e) => {
		setHideFromPatient(e.target.checked)
		updateHideFromPatient(match.params.groupId)
	}

	const handleExtendedFormulaSelectionChange = (value) => {
		setSelection(value)
	}

	const handleFunctionClicked = (value) => {
		insertExtendedFormulaValue(value)
	}

	return (
		<Fragment>
			<Grid
				container
				justify="space-between"
				style={{
					width: '100%',
					padding: '40px 3% 25px'
				}}
				direction="column"
			>
				<Grid item style={{ paddingLeft: '3%' }}>
					<Typography variant="body2">
						<Link to="/sg" style={{ textDecoration: 'none', color: '#2e7df6' }}>
							<ArrowLeftOutlined viewBox="0 0 24 15" />{' '}
							{intl.formatMessage({ id: 'app.sg.backToOverview' }).toUpperCase()}
						</Link>
					</Typography>
				</Grid>
				<Grid item style={{ marginTop: '32px' }}>
					<AppBar
						position="static"
						color="default"
						elevation={0}
						style={{
							width: '100%',
							marginTop: '-5px',
							paddingLeft: '3%',
							borderBottom: 'solid 1px black'
						}}
					>
						<Tabs
							value={tabIndex}
							onChange={handleChange}
							indicatorColor="primary"
							textColor="primary"
							variant="scrollable"
							scrollButtons="off"
							style={{ width: '100%', border: 'none' }}
							TabIndicatorProps={{
								style: { backgroundColor: '#007AFF', color: '#007AFF', height: '4px' }
							}}
						>
							<Tab
								label={intl.formatMessage({ id: 'app.sg.formulaTab' })}
								style={{
									fontSize: '17px',
									lineHeight: '24px',
									letter: '0.16px',
									color: '#007AFF',
									fontWeight: '700',
									textTransform: 'none',
									marginBottom: '19px'
								}}
							/>
							<Tab
								label={intl.formatMessage({ id: 'app.sg.detailTab' })}
								style={{
									fontSize: '17px',
									lineHeight: '24px',
									letter: '0.16px',
									color: '#007AFF',
									fontWeight: '700',
									textTransform: 'none',
									marginBottom: '19px'
								}}
							/>
							<Tab
								label={intl.formatMessage({ id: 'app.sg.changeLogsTab' })}
								style={{
									fontSize: '17px',
									lineHeight: '24px',
									letter: '0.16px',
									color: '#007AFF',
									fontWeight: '700',
									textTransform: 'none',
									marginBottom: '19px'
								}}
							/>
						</Tabs>
					</AppBar>
				</Grid>
				<Grid item>
					<Grid container direction="row" style={{ marginTop: '30px', padding: '0 3% 0 3%' }}>
						<Grid
							item
							style={{ flex: 0.7, cursor: showEdit ? 'pointer' : '' }}
							onClick={() => {
								if (group && group.name) {
									setShowEdit(false)
									edit(match.params.groupId, group.name)
								}
							}}
							onMouseOver={() => setShowEdit(true)}
							onMouseOut={() => setShowEdit(false)}
						>
							<Grid
								container
								justify="space-between"
								style={{ width: '100%', maxWidth: '600px' }}
								alignItems="center"
							>
								<Grid item style={{ flex: 0.9 }}>
									<Grid container direction="column">
										<Grid item style={{ minHeight: '75px' }}>
											<Typography variant="h1" style={{ wordBreak: 'break-word' }}>
												{group && group.name}
												{showEdit && <CreateIcon style={{ fontSize: '24px' }} />}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid item style={{ flex: 0.3 }}>
						<Grid container justify="flex-end" style={{ position: 'relative' }}>
					<Grid item style={{ paddingTop: '6px' }}>
						<Typography
							variant="body2"
							style={{ fontSize: '17px', color: 'black', fontWeight: '600' }}
						>
							{intl.formatMessage({ id: 'app.scoringGroup.hideFromPatient' })}
							</Typography>
					</Grid>
					<Grid item>
					<Switch
								checked={hideFromPatient}
								onChange={changeHideFromPatient}
								value="hideFromPatient"
								color="primary"
								inputProps={{ 'aria-label': 'secondary checkbox' }}
							/>
					</Grid>
				
			</Grid>
						
							</Grid>
					</Grid>
				</Grid>

				{tabIndex == '0' && (
					<Grid item>
						<div style={{ padding: '0 3%' }}>
							<Grid container direction="column">
								<Grid item>
									<Grid container direction="column" spacing={2}>
										<Grid item style={{ width: '100%', paddingTop: '3%' }}>
											<Paper>
												<ExpressionBuilder
													id={match.params.groupId}
													onExtendedFormulaSelectionChange={handleExtendedFormulaSelectionChange}
												/>
											</Paper>
										</Grid>

										<Grid item style={{ width: '100%' }}>
											<Grid container spacing={2}>
												<Grid
													item
													xs={3}
												>
													<Grid
														container
														spacing={2}
														direction="column"
													>
														{
															!extendedMode
															&& <Grid
																item
															>
																<Paper style={{ padding: '10px' }}>
																	<Grid container>
																		<Grid item>
																			<KeyPad
																				onAddConstant={addConstant}
																				onAddOperator={addOperator}
																				onAddTag={addTag}
																				onBackspace={backspace}
																				onClear={clear}
																			/>
																		</Grid>
																	</Grid>
																</Paper>
															</Grid>
														}
														{
															extendedMode
															&& <Grid
																item
															>
																<Paper style={{ padding: '10px' }}>
																	<Grid
																		container
																		direction="column"
																	>
																		<Grid item>
																			<Typography
																				variant="body2"
																				style={{
																					paddingLeft: '16px',
																					fontSize: '17px',
																					color: 'black',
																					fontWeight: '600'
																				}}
																			>
																				{intl.formatMessage({ id: 'app.scoringGroup.functions' })}
																			</Typography>
																		</Grid>
																		<Grid item>
																			<FunctionList
																				onFunctionClicked={handleFunctionClicked}
																			/>
																		</Grid>
																	</Grid>
																</Paper>
															</Grid>
														}
													</Grid>
												</Grid>
												<Grid
													item
													xs={9}
												>
													<Bundles id={match.params.groupId} onAddTag={addTag} />
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								{/* <Grid item>
									{responseReceived &&
									notFound && (
										<Typography>{intl.formatMessage({ id: 'app.bundle.notFound' })}</Typography>
									)}
								</Grid> */}
							</Grid>
						</div>
					</Grid>
				)}
				{tabIndex == '1' && (
					<Grid item>
						<div style={{ padding: '0 3%' }}>
							<ScoringGroupDetails groupId={match.params.groupId} />
						</div>
					</Grid>
				)}
				{tabIndex == '2' && (
					<Grid item>
						<div style={{ padding: '0 3%' }}>
							<ScoringGroupAudit />
						</div>
					</Grid>
				)}
			</Grid>
		</Fragment>
	)
}
const mapStateToProps = (state, ownProps) => ({
	group: scoringGroupSelector(state),
	notFound: bundleNotFoundSelector(state),
	responseReceived: bundleRequestReceivedSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	loadGroup: (id) => dispatch(fetchGroup(id)),
	loadGroupQuestions: (id) => dispatch(fetchBundleBuilderQuestions(id, null, 1, false)),
	addQuestion: (data, position, id) => dispatch(addBundleQuestion(data, position, id)),
	addExistingQuestion: (id, typeId, position, bundleId) =>
		dispatch(addExistingBundleQuestion(id, typeId, position, bundleId)),
	removeBundleQuestion: (question, id) => dispatch(removeBundleQuestion(question, id)),
	updateBundleQuestion: (question, id, languageId) =>
		dispatch(updateEntityQuestion(question, languageId, fetchBundleBuilderQuestions, id, false)),
	swapBundleQuestionPosition: (nodeA, nodeB, id) => dispatch(swapBundleQuestionPosition(nodeA, nodeB, id)),
	updateHideFromPatient: (id) => dispatch(updateHideFromPatient(id)),
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'bundle'
	})
)

export default enhance(ScoringGroup)
