import React from "react"
import { Grid } from "@material-ui/core"
import LanguageNavigator from "./LanguageNavigator"
import PrivateRoute from "../../../routes/PrivateRoute"
import Container from "../../../components/questionBuilder/Container"
import ViewContainer from '../../../components/questionViewer/Container'
import QuestionDetails from "./QuestionDetails"
import SaveQuestionModal from "./SaveQuestionModal"
import { hideModal, loadQuestion, showModal, stopSaving, updateQuestion } from "../../../redux/actions"
import { injectIntl } from "react-intl"
import { useRouteMatch } from "react-router"
import { connect, useDispatch } from "react-redux"
import { compose } from "recompose"


const QuestionContent = ({
    questionList,
    questionCollection
}) => {
	const match = useRouteMatch()
	const dispatch = useDispatch()


	const handleUpdateQuestion = (question) => {
		const questionId = match?.params?.questionId
		const questionType = match?.params?.questionType
		const languageId = match?.params?.languageId

		dispatch(
			showModal(
				SaveQuestionModal,
				{
                    entityId: questionId,
                    entityTypeId: questionType,
                    onClose: () => {
                        dispatch(hideModal())
                        dispatch(stopSaving())
                    },
					saveQuestionCallback: (commentsObj) => {
						dispatch(
							updateQuestion(
								question,
								languageId,
                                commentsObj
							)
						)
							.then(result => {
								if (result) {
									dispatch(hideModal())
									dispatch(
										loadQuestion(questionId, questionType, languageId)
									)
								}
							})
					}
				}
			)
		)
	}


    return (
        <Grid container direction="row" spacing={2}>
            <Grid item style={{ flex: 0.3 }}>
                <LanguageNavigator />
            </Grid>
            <Grid item style={{ flex: 0.7, height: '100%' }}>
                <Grid container direction="column">
                    <Grid item>
                        <PrivateRoute
                            exact={true}
                            path="/questions/:questionId/type/:questionType/details"
                            component={QuestionDetails}
                        />

                        {
                            match?.params?.languageId == 1 
                            && <PrivateRoute
                                exact={true}
                                path="/questions/:questionId/type/:questionType/languages/:languageId"
                                list={questionList}
                                collection={questionCollection}
                                onUpdateCallback={handleUpdateQuestion}
                                suppressNonDiscreteQuestions={false}
                                showScoreEntry={false}
                                component={Container}
                                propertyStateName={"state"}
                                sameStateAllEntities={false}
                                canSetInUseStateAllEntities={true}
                                canSetNotInUseStateAllEntities={true}
                                canSetArchiveStateAllEntities={true}
                                canCloneQuestion={false}
                                isOptionalDisabled={true}
                            />
                        }

                        {
                            match?.params?.languageId != 1 
                            && <PrivateRoute
                                exact={true}
                                path="/questions/:questionId/type/:questionType/languages/:languageId"
                                list={questionList}
                                collection={questionCollection}
                                onUpdateCallback={handleUpdateQuestion}
                                languageId={match.params.languageId}
                                component={ViewContainer}
                                showScoreEntry={false}
                                canCloneQuestion={false}
                            />
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = (dispatch, ownProps) => ({
})

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)

export default enhance(QuestionContent)
