import React, { useEffect, useState } from 'react'
import Header from './components/Header'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import loginBackground from '../../assets/login/arrows-bright.jpg'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getUserCirclesById, getUserDetailsById } from '../../redux/actions'
import { userCirclesSelector, userDetailsSelector } from '../../redux/selectors'
import { getUser } from '../../utilities/userUtilities'
import { injectIntl } from 'react-intl'
import TeamMembers from './components/TeamMembers'
import Subscription from './components/Subscription'
import SubscriptionSponsor from './components/SubscriptionSponsor'
import YourPlan from './components/YourPlan'
import { useRouteMatch } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { ArrowLeftOutlined } from '@material-ui/icons'
import GetStatisticsButton from './components/GetStatisticsButton'
import TeamBackgroundImage from './components/TeamBackgroundImage'
import CircleTable from './components/CircleTable'
import CircleFilter from './components/CircleFilter'
import CompositeLabelPaper from '../../components/CompositeLabelPaper'
import RelatedServiceProvidersTable from './components/RelatedServiceProvidersTable'
import RelatedServiceProviderFilter from './components/RelatedServiceProviderFilter'

const UserProfile = ({ intl, fetchUser, userDetails, fetchCircles, userCircles }) => {
	const [ id, setId ] = useState(null)
	const [ user, setUser ] = useState(getUser())
	const match = useRouteMatch()

	useEffect(() => {
		if (match.params.userId) {
			const _id = parseInt(match.params.userId)

			setId(_id)
			fetchUser(_id)
			fetchCircles(_id)
		} else {
			let user = getUser()
			if (user) {
				setId(user.id)
				fetchUser(user.id)
				fetchCircles(user.id)
			}
		}
	}, [])

	return (
		<div>
			<Grid container style={{ marginTop: '30px' }} justify='space-between'>
				<Grid item xs={12} md={8} style={{ paddingLeft: '7%', height: '65px', width: '100%' }}>
					<Link to="/subscribers" style={{ textDecoration: 'none', color: '#2e7df6' }}>
						<Typography variant="body2" style={{ paddingBottom: '16px' }}>
							<ArrowLeftOutlined viewBox="0 0 24 15" />{' '}
							{intl.formatMessage({ id: 'app.circle.backToSubscribers' }).toUpperCase()}
						</Typography>
					</Link>
				</Grid>
				{/* {userDetails && userDetails.lastLogin && 
					<Grid item xs={12} md={4} style={{ paddingBottom: '16px', display: 'flex', justifyContent: 'flex-end' }}>
						<GetStatisticsButton userId={id}/>
					</Grid>
				} */}
			</Grid>
			<TeamBackgroundImage
						data-testid="user-profile-bg-image"
						data={userDetails}
						onBackgroundUpdated={getUserDetailsById}
						canEdit={true}
					/>
			<div style={{ padding: '0 5%', marginTop: '0px', zIndex: 100, minHeight: '300px' }}>
				<Header
					userDetails={userDetails}
					onImageUpdated={getUserDetailsById}
					onLogoUpdated={getUserDetailsById}
				/>
			</div>
			<div style={{ padding: '0 5%', marginTop: '-25px', zIndex: 100, }}>
				<TeamMembers user={userDetails} />
			</div>
			<div style={{ padding: '70px 5% 0 5%', zIndex: 100, }}>
				<Subscription user={userDetails} />
			</div>
            <div style={{ padding: '70px 5% 0 5%', zIndex: 100, }}>
				<SubscriptionSponsor user={userDetails} />
			</div>
            <div style={{ padding: '70px 5% 0 5%', zIndex: 100, }}>
				<YourPlan user={userDetails} />
            </div>
			<div style={{ padding: '70px 5% 0 5%', zIndex: 100, }}>
				{userDetails && <CompositeLabelPaper
					label={ intl.formatMessage({ id: 'app.userProfile.circles.allCirclesFilter' }) }
					Component={CircleTable}
					user={user}
					data={userCircles}
					Filter={CircleFilter}
				/>}
            </div>
			<div style={{ padding: '70px 5% 120px', zIndex: 100, }}>
				{
					!!id
					&& <CompositeLabelPaper
						label={ intl.formatMessage({ id: 'app.userProfile.relatedServiceProviders.title' }) }
						Component={ RelatedServiceProvidersTable }
						Filter={ RelatedServiceProviderFilter }
						dense
					/>
				}
            </div>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	userDetails: userDetailsSelector(state),
	userCircles: userCirclesSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchUser: (id) => dispatch(getUserDetailsById(id)),
	fetchCircles: (id) => dispatch(getUserCirclesById(id))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(UserProfile)
