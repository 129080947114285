import * as auth from '../../../apis/auth'
//import { createSelector } from 'reselect'
import history from '../../../utilities/history'
import * as cookies from '../../../utilities/cookies'
import { hideModal } from '../core'

const SIGN_IN_REQUESTED = 'SIGN_IN_REQUESTED'
const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
const SIGN_IN_FAILED = 'SIGN_IN_FAILED'

export const signIn = (email, password, returnUrl) => (dispatch) => {
	dispatch({ type: SIGN_IN_REQUESTED, data: {} })
	return auth.signIn(email, password).then((response) => {
		if (response && response.isSuccessful) {
			if (returnUrl) {
				history.push(returnUrl)
			} else {
				history.push('/')
			}
			return dispatch({ type: SIGN_IN_SUCCESS, data: response.user })
		} else {
			if (response.errorCode && response.errorCode === 10) {
				history.push(`/auth/changePassword?email=${encodeURIComponent(email)}`)
			} else {
				cookies.remove('user')
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.login.error', isSuccess: false }
				})
			}
		}
	})
}

const CHANGE_PASSWORD_REQUESTED = 'CHANGE_PASSWORD_REQUESTED'
const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED'

export const changePassword = (id, oldPassword, newPassword, type) => (dispatch) => {
	dispatch({ type: CHANGE_PASSWORD_REQUESTED, data: {} })
	return auth.changePassword(id, oldPassword, newPassword, type).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(hideModal())
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.successfullyUpdated', isSuccess: true }
			})
			return dispatch({ type: CHANGE_PASSWORD_SUCCESS, data: response.user })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.login.error', isSuccess: false }
			})
		}
	})
}

const NEW_USER_CHANGE_PASSWORD_REQUESTED = 'NEW_USER_CHANGE_PASSWORD_REQUESTED'
const NEW_USER_CHANGE_PASSWORD_SUCCESS = 'NEW_USER_CHANGE_PASSWORD_SUCCESS'
const NEW_USER_CHANGE_PASSWORD_FAILED = 'NEW_USER_CHANGE_PASSWORD_FAILED'

export const newUserChangePassword = (email, oldPassword, newPassword, type) => (dispatch) => {
	dispatch({ type: NEW_USER_CHANGE_PASSWORD_REQUESTED, data: {} })
	return auth.newUserChangePassword(email, oldPassword, newPassword).then((response) => {
		if (response && response.isSuccessful) {
			history.push('/')

			return dispatch({ type: CHANGE_PASSWORD_SUCCESS, data: response.user })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.login.error', isSuccess: false }
			})
		}
	})
}

const SIGN_OUT_REQUESTED = 'SIGN_OUT_REQUESTED'
const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS'
//const SIGN_OUT_FAILED = 'SIGN_OUT_FAILED'

export const signOut = (email, password) => (dispatch) => {
	dispatch({ type: SIGN_OUT_REQUESTED, data: {} })
	localStorage.removeItem('user')
	return auth.signOut().then((response) => {
		history.push('/auth/login')
		return dispatch({ type: SIGN_OUT_SUCCESS, data: {} })
	})
}

const RESET_FAILED_AUTHENTICATION_REQUESTED = 'RESET_FAILED_AUTHENTICATION_REQUESTED'
const RESET_FAILED_AUTHENTICATION_SUCCESS = 'RESET_FAILED_AUTHENTICATION_SUCCESS'

export const resetFailedLogin = (email, password) => (dispatch) => {
	dispatch({ type: RESET_FAILED_AUTHENTICATION_REQUESTED, data: {} })

	return
}

const CONFIRM_FORGOT_PASSWORD_EMAIL_REQUESTED = 'CONFIRM_FORGOT_PASSWORD_EMAIL_REQUESTED'
const CONFIRM_FORGOT_PASSWORD_EMAIL_SUCCESS = 'CONFIRM_FORGOT_PASSWORD_EMAIL_SUCCESS'
const CONFIRM_FORGOT_PASSWORD_EMAIL_FAILED = 'CONFIRM_FORGOT_PASSWORD_EMAIL_FAILED'

export const confirmForgotPasswordEmail = (email, code, password) => (dispatch) => {
	dispatch({ type: CONFIRM_FORGOT_PASSWORD_EMAIL_REQUESTED, data: {} })
	return auth.confirmForgotPassword(email, code, password).then((response) => {
		if (response && response.ok) {
			history.push('/auth/login')
			return dispatch({ type: CONFIRM_FORGOT_PASSWORD_EMAIL_SUCCESS, data: { confirmedSuccessfully: true } })
		} else {
			return dispatch({ type: CONFIRM_FORGOT_PASSWORD_EMAIL_FAILED, data: { confirmedSuccessfully: false } })
		}
	})
}

const FORGOT_PASSWORD_EMAIL_REQUESTED = 'FORGOT_PASSWORD_EMAIL_REQUESTED'
const FORGOT_PASSWORD_EMAIL_SUCCESS = 'FORGOT_PASSWORD_EMAIL_SUCCESS'
const FORGOT_PASSWORD_EMAIL_FAILED = 'FORGOT_PASSWORD_EMAIL_FAILED'

export const requestForgotPasswordEmail = (email) => (dispatch) => {
	dispatch({ type: FORGOT_PASSWORD_EMAIL_REQUESTED, data: {} })
	return auth
		.requestForgotPasswordEmail(email)
		.then((response) => {
			if (!response || !response.ok) {
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.errorMessages.2', isSuccess: false }
				})
			} else return response.json()
		})
		.then((result) => {
			if (result && result.isSuccessful) {
				history.push('/auth/confirmpassword?email=' + encodeURIComponent(email))
				return dispatch({ type: FORGOT_PASSWORD_EMAIL_SUCCESS, data: { requestedSuccessfully: true } })
			} else {
				if (result.errorCode === 1) {
					dispatch({
						type: 'SET_SNACKBAR_MESSAGE',
						data: { message: 'app.forgotPass.unconfirmedError', isSuccess: false }
					})
					history.push(`/auth/register/confirmation?email=${encodeURIComponent(email)}`)
					return
				} else {
					dispatch({
						type: 'SET_SNACKBAR_MESSAGE',
						data: { message: 'app.forgotPass.error', isSuccess: false }
					})
				}
			}
		})
}

const initial = {
	confirmed: false,
	error: false,
	failedLogin: false,
	requestedSuccessfully: null,
	requestPasswordStatus: null,
	user: null,
	confirmationResent: false
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case SIGN_IN_SUCCESS:
			return { ...state, user: action.data }
		case SIGN_IN_FAILED:
			return { ...state, failedLogin: true }
		case RESET_FAILED_AUTHENTICATION_REQUESTED:
			return { ...state, failedLogin: false }
		case FORGOT_PASSWORD_EMAIL_SUCCESS:
			return { ...state }
		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}

export const confirmedSelector = (state) => {
	return state.auth.confirmed
}

export const errorSelector = (state) => {
	return state.auth.error
}

export const userSelector = (state) => {
	return state.auth.user
}
