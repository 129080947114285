import { authenticatedFetch } from './base'

export const fetchUserById = (id) => {
	return authenticatedFetch(`/api/users/${id}`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const fetchFullUserById = (id) => {
	return authenticatedFetch(`/api/users/${id}/full`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const fetchUserNotificationSettingsById = (id) => {
	return authenticatedFetch(`/api/users/${id}/notificationsettings`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}
export const updateUserNotificationSettingsById = (id, outlier, invitation, protocol) => {
	let notifications = [
		{
			id: 1,
			interval: outlier
		},
		{
			id: 2,
			interval: protocol
		},
		{
			id: 3,
			interval: invitation
		}
	]

	return authenticatedFetch(`/api/users/${id}/notificationsettings`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ notifications: notifications })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const updateUserDetailsById = (user) => {
	return authenticatedFetch(`/api/users/${user.id}`, {
		method: 'put',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(user)
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}
export const ignoreInitialPathologiesAndProms = (id) => {
	return authenticatedFetch(`/api/users/${id}/ignoreinitialpathologies`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const updateUserLanguage = (id, language) => {
	return authenticatedFetch(`/api/users/${id}/language/${language}`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ langauge: language })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const setPathologiesAndProms = (id, pathologies = [], proms = [], isInitialSetting = false) => {
	let url = isInitialSetting ? `/api/users/${id}/addinitialpathologies` : `/api/users/${id}/addinitialpathologies`

	return authenticatedFetch(url, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ pathologies: pathologies, proms: proms })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const fetchTeamMembers = (id) => {
	return authenticatedFetch(`/api/users/${id}/members`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const setUserImage = (entity, id, photo) => {
	//users or circle
	let url = `/api/${entity}/${id}/photo`

	return authenticatedFetch(url, {
		method: 'post',
		body: photo
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const setTeamLogo = (id, photo) => {
	let url = `/api/teams/${id}/logo`

	return authenticatedFetch(url, {
		method: 'post',
		body: photo
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const resetUserImage = (entity, id) => {
	let url = `/api/${entity}/${id}/photo`

	return authenticatedFetch(url, {
		method: 'DELETE'
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const fetchAlerts = () => {
	return authenticatedFetch(`/api/users/alerts`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const fetchUserAutoComplete = (searchPhrase = '', subscribersOnly, activeSubscriptionOnly) => {
	return authenticatedFetch(`/api/users/autocomplete?subscribers=${subscribersOnly ? true : false}&activeSubscriptionOnly=${activeSubscriptionOnly ? true : false}&searchPhrase=${encodeURIComponent(searchPhrase)}`,
		{
			method: 'get'
		})
		.then(function (response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (circle) {
			return circle
		})
}

export const fetchUserCirclesById = (id) => {
	return authenticatedFetch(`/api/users/${id}/circles`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const searchUserRelatedServiceProviders = (userId, userRelatedServiceProviderFilter) => {
	var url = `/api/users/${userId}/relatedServiceProviders`

	var query = ''

	if (!!userRelatedServiceProviderFilter) {
		query += `userRelatedServiceProviderFilter=${userRelatedServiceProviderFilter}`
	}

	if (query.length > 0) {
		url += `?${query}`
	}

	return authenticatedFetch(url, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}
