import React, { useState } from "react";
import { Table, TableBody } from "@material-ui/core";
import RelatedProtocolTableHeader from "./RelatedProtocolTableHeader";
import RelatedProtocolTableEmptyRow from "./RelatedProtocolTableEmptyRow";
import RelatedProtocolTableRow from "./RelatedProtocolTableRow";
import { sgRelatedProtocolsSelector } from "../../../redux/selectors";
import { compose } from "recompose";
import { connect } from "react-redux";
import { useEffect } from "react";


const RelatedProtocolsTable = ({
    protocols
}) => {


    return (
        <Table>
            <RelatedProtocolTableHeader />
            <TableBody>
                {
                    !!protocols?.length
                    && protocols.map(protocol => {
                        return (
                            <RelatedProtocolTableRow protocol={protocol} />
                        )
                    })
                }
                {
                    !protocols?.length
                    && <RelatedProtocolTableEmptyRow />
                }
            </TableBody>
        </Table>
    )
}

const mapStateToProps = (state, ownProps) => ({
    protocols: sgRelatedProtocolsSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(RelatedProtocolsTable);
