import React from 'react'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Radio from '@material-ui/core/Radio'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { Field, reduxForm } from 'redux-form'
import Lock from '@material-ui/icons/Lock'
import LockOpen from '@material-ui/icons/LockOpen'
import useTheme from '@material-ui/core/styles/useTheme'
import FormNumberTextField from './FormNumberTextField'
import Avatar from '@material-ui/core/Avatar'

const NumberedRow = ({ intl, number, label }) => {
	var theme = useTheme()
	return (
		<Grid container direction="column">
			<Grid item>
				<Grid container direction="row" alignItems="center">
					<Grid item style={{ marginLeft: '-7px' }}>
						<Avatar
							style={{
								width: 20,
								height: 20,
								color: 'black',
								backgroundColor: '#7AB5E0'
							}}
						>
							{number}
						</Avatar>
					</Grid>
					<Grid item style={{ paddingLeft: '10px' }}>
						<Typography
							style={{
								color: '#fff',
								fontWeight: '400',
								fontSize: '22px'
							}}
							align="left"
						>
							{label}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

const enhance = compose(injectIntl)

export default enhance(NumberedRow)
