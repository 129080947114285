import { authenticatedFetch } from './base'

export const pepOnboardingExport = (filters) => {
	return authenticatedFetch(`/api/dashboard/exportCompletedPreTreatment`, {
			method: 'POST',
			headers: { 'content-type': 'application/json' },
			body: JSON.stringify(
				{
					...filters
				}
			)
		})
		.then(function (response) {
			if (response.status === 200) {
				const header = response.headers.get("content-disposition")
				if (!header) {
					return { isSuccessful: false }
				}

				const fileName = header.split('"')[1];
				return response.blob().then(blob => [fileName, blob])
			}
		})
		.then(function ([filename, blob]) {
			try {
				var element = document.createElement('a')
				element.setAttribute('href', URL.createObjectURL(blob))
				element.setAttribute('download', filename)

				element.style.display = 'none'
				document.body.appendChild(element)

				element.click()

				document.body.removeChild(element)
				return { isSuccessful: true }
			} catch (e) {
				console.error(e)
				return { isSuccessful: false }
			}
		})
}

export const datapointsCountExport = (filters) => {
	return authenticatedFetch(`/api/dashboard/exportDatapoints`, {
			method: 'POST',
			headers: { 'content-type': 'application/json' },
			body: JSON.stringify(
				{
					...filters
				}
			)
		})
		.then(function (response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (response) {
			return response
		})
}