import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useTheme from '@material-ui/core/styles/useTheme'
import { isSavingSelector } from '../../../redux/selectors'
import { connect, useDispatch } from 'react-redux'
import { change, reduxForm } from 'redux-form'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { required, requiredArray } from '../../../utilities/validators'
import { exportSubscribers, startSaving } from '../../../redux/actions'
import CalmButton from '../../../components/CalmButton'
import { FormControlLabel, Radio, RadioGroup, Switch } from '@material-ui/core'
import { useState } from 'react'
import SponsorsMultiSelect from '../../../components/SponsorsMultiSelect'
import FormDateField from '../../../components/FormDateField'

const formName = "exportSubscribers"

const EXPORT_TYPE = {
	ALL: 0,
	ACTIVE_SPONSORS: 1,
	EXCLUDE_SPONSORS: 2
}

const ExportSubscribersModal = ({
	intl,
	handleSubmit,
	exportSubscribers,
	isSaving,
	startSaving
}) => {
	const theme = useTheme()
	const dispatch = useDispatch()
	
	const [includeWithoutSponsor, setIncludeWithoutSponsor] = useState(false)
	const [sponsorFilter, setSponsorFilter] = useState(0)

	useEffect(() => {
		let today = new Date()
		let startDate = new Date()
		startDate.setDate(startDate.getDate() - 30)
		dispatch(change(formName, 'endDate', today))
		dispatch(change(formName, 'startDate', startDate))
	}, [])

	const onSubmitForm = (values) => {
		startSaving()
		exportSubscribers({
			startDate: values.startDate,
			endDate: values.endDate,
			includeWithoutSponsor: includeWithoutSponsor,
			sponsorFilter: sponsorFilter,
			excludeSponsors: Array.isArray(values.excludeSponsors) ? values.excludeSponsors : null
		})
	}

	return (
		<form onSubmit={handleSubmit(onSubmitForm)}>
			<Grid
				container
				direction="column"
				justify="flex-start"
				alignItems="center"
				style={{ width: '75rem', padding: '4rem', margin: 'auto', backgroundColor: theme.palette.secondary.main }}
			>
				<Grid item style={{ alignSelf: 'center' }}>
					<Typography
						variant="h1"
						style={{
							color: '#fff',
							marginBottom: '25px',
						}}
					>
						{intl.formatMessage({ id: 'app.subscribers.exportSubscribersHeader' })}
					</Typography>
				</Grid>
				<Grid item style={{ alignSelf: 'flex-start', width: '100%' }}>
				<Typography
						variant="body1"
						style={{
							color: '#fff',
							marginBottom: '25px',
						}}
						align="left"
					>
						{intl.formatMessage({ id: 'app.subscribers.exportSubscribersFilterLabel' })}
					</Typography>
				</Grid>
				<Grid item container style={{ alignSelf: 'flex-start', width: '100%', display: 'flex' }} spacing={2}>
					<Grid item style={{ flex: 0.5 }}>
						<FormDateField
                            label="From"
                            fullWidth
                            type="text"
                            name="startDate"
                            variant="filled"
							design="dark"
                        />
                    </Grid>
                    <Grid item style={{ flex: 0.5 }}>
                        <FormDateField
                            label="To"
                            fullWidth
                            type="text"
                            name="endDate"
                            variant="filled"
							design="dark"
                        />
                    </Grid>
				</Grid>
				<Grid item style={{ alignSelf: 'flex-start', width: '100%', minHeight: '60px' }}>
					<Grid
						container
						alignItems='center'
					>
						<Grid item>
							<Switch
								color='primary'
								checked={includeWithoutSponsor}
								onChange={(e) => setIncludeWithoutSponsor(e.target.checked)}
							/>
						</Grid>
						<Grid item>
							<Typography
								variant="subtitle1"
								style={{
									color: '#fff'
								}}
								align="left"
							>
								{intl.formatMessage({ id: 'app.subscribers.exportSubscribersIncludeWithNoSponsorLabel' })}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item style={{ alignSelf: 'flex-start', width: '100%' }}>
					<RadioGroup>
                        <Grid
                            container
                            spacing={1}
                            direction='column'
                            style={{ width: '100%' }}
                        >
                            <Grid item xs="auto">
                                <FormControlLabel
                                    label={intl.formatMessage({ id: 'app.subscribers.exportSubscribersAllSponsorsFilterLabel' })}
                                    control={
                                        <Radio
                                            color="secondary"
                                            onChange={() => setSponsorFilter(0)}
                                            checked={sponsorFilter == EXPORT_TYPE.ALL}
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs="auto">
                                <FormControlLabel
                                    label={intl.formatMessage({ id: 'app.subscribers.exportSubscribersActiveSponsorsFilterLabel' })}
                                    control={
                                        <Radio
                                            color="secondary"
                                            onChange={() => setSponsorFilter(1)}
                                            checked={sponsorFilter == EXPORT_TYPE.ACTIVE_SPONSORS}
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs="auto">
                                <FormControlLabel
                                    label={intl.formatMessage({ id: 'app.subscribers.exportSubscribersExcludeSponsorsFilterLabel' })}
                                    control={
                                        <Radio
                                            color="secondary"
                                            onChange={() => setSponsorFilter(2)}
                                            checked={sponsorFilter == EXPORT_TYPE.EXCLUDE_SPONSORS}
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </RadioGroup>
                    {sponsorFilter == EXPORT_TYPE.EXCLUDE_SPONSORS &&
                        <div style={{ paddingTop: '10px' }}>
                            <SponsorsMultiSelect
                                label={intl.formatMessage({ id: 'app.subscribers.exportSubscribersExcludeSponsorsSelectorLabel' }) + '*'}
                                fullWidth
                                type="text"
                                name="excludeSponsors"
                                validators={[sponsorFilter == EXPORT_TYPE.EXCLUDE_SPONSORS ? requiredArray : null]}
                                variant="filled"
								allowArchived={true}
                            />
                        </div>
                    }
				</Grid>
				<Grid item>
					<CalmButton
						style={{
							width: '267px',
							marginTop: '40px'
						}}
						disabled={isSaving}
						type="submit"
						variant="contained"
						color="primary"
					>
						{intl.formatMessage({ id: 'app.sponsors.submitButtonLabel' })}
					</CalmButton>
				</Grid>
			</Grid>
		</form>
	)
}

const mapStateToProps = (state) => ({
	isSaving: isSavingSelector(state)
})

const mapDispatchToProps = (dispatch) => ({
	startSaving: () => dispatch(startSaving()),
	exportSubscribers: (filters) => dispatch(exportSubscribers(filters))
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: formName,
		touchOnChange: true,
		touchOnBlur: true
	})
)

export default enhance(ExportSubscribersModal)
