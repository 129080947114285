import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles({
	root: {
		width: 200
	},
	markLabel: {
		fontSize: '12px',
		color: 'gray'
	}
})

export default ({ question }) => {
	const classes = useStyles()
	const [value, setValue] = useState(null)
	const [marks, setMarks] = useState(undefined)


	useEffect(
		() => {
			let inc = question.stepValue.toString().match(/\.(\d+)/)?.pop().length
			if (!inc) {
				inc = 1
			}
			else {
				inc = parseInt('1' + String().padStart(inc, '0'))
			}
			const q = {
				startValue: question.startValue * inc,
			  endValue: question.endValue * inc,
			  stepValue: question.stepValue * inc
			}
	  
			const maxSteps = 10
			const step = Math.ceil(Math.ceil((q.endValue - q.startValue) / maxSteps) / q.stepValue) * q.stepValue
      		const arrayLength = Math.floor((q.endValue - q.startValue) / step) + 1
			setMarks(
				question.stepValue
					? Array.from( { length: arrayLength },
							(_, index) => {
								let mark = parseFloat(((q.startValue + step * index) / inc).toFixed(2))
								if (mark > (q.endValue / inc))
									mark = (q.endValue / inc)
								return {
									value: mark,
									label: mark
								}
							}
						)
					: undefined
			)
		},
		[question]
	)


	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	return (
		<Grid>
			<Grid container spacing={2} style={{ paddingLeft: '5px' }}>
				{question.startText && (
					<Grid item className="Mui-disabled" style={{ paddingTop: '6px' }}>
						<Typography variant='subtitle1' style={{ fontWeight: '400' }}>
							{question.startText}
						</Typography>
					</Grid>
				)}
				{question.startImagePath && (
					<Grid item style={{ paddingTop: '6px' }}>
						<img src={question.startImagePath} height='32px' width='32px' />
					</Grid>
				)}
				<Grid item xs>
					{question.startValue != null &&
						question.endValue != null && (
							<Slider
								value={value}
								onChange={handleChange}
								min={question.startValue}
								max={question.endValue}
								step={question.stepValue}
								aria-labelledby='continuous-slider'
								disabled
								marks={marks}
								classes={{
									markLabel: classes.markLabel
								}}
							/>
						)}
				</Grid>
				{question.endImagePath && (
					<Grid item style={{ paddingTop: '6px' }}>
						<img src={question.endImagePath} height='32px' width='32px' />
					</Grid>
				)}
				{question.endText && (
					<Grid item className="Mui-disabled" style={{ paddingTop: '6px' }}>
						<Typography variant='subtitle1' style={{ fontWeight: '400' }}>
							{question.endText}
						</Typography>
					</Grid>
				)}
			</Grid>
		</Grid>
	)
}
