import moment from "moment"
import React, { useLayoutEffect } from "react"

export const uuidv4 = () => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		var r = (Math.random() * 16) | 0,
			v = c == 'x' ? r : (r & 0x3) | 0x8
		return v.toString(16)
	})
}

export const questionCode = (instanceId, questionId, questionType) => {
	return `Q_${instanceId}_${questionId}_${questionType}`
}

export const questionId = (questionId, questionType) => {
	return `Q_${questionId}_${questionType}`
}

export const categoryCode = (questionId, categoryid) => {
	return `C_${questionId}_${categoryid}`
}

export const answerCode = (questionId, answerId) => {
	return `A_${questionId}_${answerId}`
}

export const questionValue = (questionType, value) => {
	let result = value

	if (value !== null && value !== undefined) {
		switch (questionType) {
			case 1:
				result = value ? 1 : 0
				break
			case 2:
				result = value ? 1 : 0
				break
			case 3:
				result = Number.parseFloat(value)
				break
			case 4:
				result = `"${value}"`
				break
			case 5:
				result = `"${value}"`
				break
			case 6:
				result = Number.parseFloat(value)
				break
			case 7:
				result = `"${moment(value).format("YYYY-MM-DD")}"`
				break
			case 9:
				result = Number.parseFloat(value)
				break
			case 10:
			case 11:
				result = `"${value}"`
				break
			default:
				break;
		}
	}
	else {
		result = undefined
	}

	return result
}

export const evalRule = (questionsContext, rule) => {
	try
	{
		return eval(`${questionsContext}Boolean(${rule})`)
	}
	catch(err) {}

	return undefined
}

export const simplifyString = (value) => {
	if (!value || (typeof value !== "string" && !(value instanceof String))) {
		return value
	}

	return value.replace(/\s+/g, " ").trim()
}

export const getImportType = (type, intl) => {
	switch (type) {
		case 0:
			return intl.formatMessage({ id: 'app.imports.importTypePatients' })
		case 1:
			return intl.formatMessage({ id: 'app.imports.importTypeCases' })
		case 2:
			return intl.formatMessage({ id: 'app.imports.importTypeSurveys' })
	}
}

export const getImportOperationType = (type, intl) => {
	switch (type) {
		case 0:
			return intl.formatMessage({ id: 'app.imports.importTypeOperationAdd' })
		case 1:
			return intl.formatMessage({ id: 'app.imports.importTypeOperationUpdate' })
		case 2:
			return intl.formatMessage({ id: 'app.imports.importTypeOperationDelete' })
	}
}

export const getImportStatus = (type, intl) => {
	switch (type) {
		case 0:
			return intl.formatMessage({ id: 'app.imports.fileUploadPending' })
		case 1:
			return intl.formatMessage({ id: 'app.imports.mappingPending' })
		case 2:
			return intl.formatMessage({ id: 'app.imports.processing' })
		case 3:
			return intl.formatMessage({ id: 'app.imports.finished' })
		case 4:
			return intl.formatMessage({ id: 'app.imports.canceled' })
		case 5:
			return intl.formatMessage({ id: 'app.imports.error' })
		case 6:
			return intl.formatMessage({ id: 'app.imports.waitingForConfirmation' })
		case 7:
			return intl.formatMessage({ id: 'app.imports.confirmed' })
	}
}

const store = {};

const handleResize = (key) => {
	// get all elements with the same key
	const elements = store[key];

	if (elements) {
		let max = 0;
		// find the element with highest clientHeight value
		elements.forEach((element) => {
			if (element.current && element.current.clientHeight > max) {
				max = element.current.clientHeight;
			}
		});
		// update height of all 'joined' elements
		elements.forEach((element) => {
			if (element.current) {
				element.current.style.minHeight = `${max}px`;
			}
		});
	}
};
  
// Add element to the store when component is mounted and return cleanup function
const add = (key, element) => {
	// create store if missing
	if (!store[key]) {
		store[key] = [];
	}

	store[key].push(element);

	// cleanup function
	return () => {
		const index = store[key].indexOf(element);
		if (index > -1) {
			store[key].splice(index, 1);
		}
	};
};
  
export const useSyncRefHeight = (refs, deps) => {
	useLayoutEffect(() => {
		// store cleanup functions for each entry
		const cleanups = [];
		refs.forEach(([key, element]) => {
			// add element ref to store
			cleanups.push(add(key, element));
		});
		return () => {
			// cleanup when component is destroyed
			cleanups.forEach((cleanup) => cleanup());
		};
	}, []);

	useLayoutEffect(() => {
		// when any of the dependencies changes, update all elements heights
		refs.forEach(([key]) => {
			handleResize(key);
		});
	}, deps);
};