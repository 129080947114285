import React, { Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import RequiresTranslationBadge from '../../RequiresTranslationBadge'
import RuleDescriptionRow from '../../../pages/surveyViewer/components/RuleDescriptionRow'
export default ({
	question,
	surveyEntity
}) => {
	return (
			<Grid
				data-testid="readonly-text-question-survey-component"
				container
				direction="column"
				justify="flex-start"
			>
				<Grid item>
					<Typography variant="subtitle1">
						<span dangerouslySetInnerHTML={{ __html:question.subtitle }} />
					</Typography>
				</Grid>
				{
					question
					&& !question.isBundle
					&& !!surveyEntity
					&& !!surveyEntity.isConditionalVisibility
					&& surveyEntity.state === 1
					&& !!surveyEntity.visibilityRuleDescription.length
					&& <RuleDescriptionRow
						description={surveyEntity.visibilityRuleDescription}
						style={{
							width: '100%',
							marginTop: '5px'
						}}
					/>
				}
				<Grid item>
					<Typography variant="subtitle2" style={{ marginTop: '7px', marginBottom: '16px'}}>
						<span dangerouslySetInnerHTML={{ __html:question.bodyCopy }} />
					</Typography>
				</Grid>{' '}
				{question &&
				!question.id && (
					<Grid item style={{ marginTop: '10px' }}>
						<RequiresTranslationBadge />
					</Grid>
				)}
			</Grid>
	)
}
