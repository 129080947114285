import React from 'react'
import ReactDOM from 'react-dom'
import { Router, Switch } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core'
import theme from './utilities/theme'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import reducer from './redux/reducers'
import thunk from 'redux-thunk'
import Root from './pages/index'
import PrivateRoute from './routes/PrivateRoute'
import SecureRoot from './pages/SecureRoot'
import { addLocaleData, IntlProvider } from 'react-intl'
import { composeWithDevTools } from 'redux-devtools-extension'
import AppUpdatesChecker from './utilities/AppUpdatesChecker'
import { ErrorLogger } from './components/ErrorLogger'

import translations from './utilities/languages/locales'
import history from './utilities/history'
import locale2 from 'locale2'

if (!Intl.PluralRules) {
	require('@formatjs/intl-pluralrules/polyfill')
	require('@formatjs/intl-pluralrules/dist/locale-data/en') // Add locale data for de
	require('@formatjs/intl-pluralrules/dist/locale-data/es') // Add locale data for de
}

if (!Intl.RelativeTimeFormat) {
	require('@formatjs/intl-relativetimeformat/polyfill')
	require('@formatjs/intl-relativetimeformat/dist/locale-data/en') // Add locale data for de
	require('@formatjs/intl-relativetimeformat/dist/locale-data/es') // Add locale data for de
}

var locale = 'en'

if (locale2) {
	locale = locale2.substring(0, 2)
}

if (!translations[locale]) {
	locale = 'en'
}

const logger = (store) => (next) => (action) => {
	console.log('dispatching', action)
	let result = next(action)
	console.log('next state', store.getState())
	return result
}
// manually load scripts (available on window obj)
const head = document.querySelector('head')
const script = document.createElement('script')

script.setAttribute('src', 'https://js.stripe.com/v3/')
head.appendChild(script)

const middlewares = [thunk]
if (process.env.NODE_ENV === 'development') {
	middlewares.push(logger)
}

const middlewareEnhancer = applyMiddleware(...middlewares)
const composedEnhancers = process.env.NODE_ENV === 'development' ? composeWithDevTools(middlewareEnhancer) : compose(middlewareEnhancer)

const store = createStore(reducer, composedEnhancers)
//const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk, logger)))

ReactDOM.render(
		<Provider store={store}>
			<MuiThemeProvider theme={theme}>
				<IntlProvider locale={locale} defaultLocale="en" key={locale} messages={translations[locale]}>
					<AppUpdatesChecker>
						<Router history={history}>
							<Switch>
								<Route path="/auth" component={SecureRoot} />
								<PrivateRoute path="/" component={Root} />
							</Switch>
						</Router>
					</AppUpdatesChecker>
				</IntlProvider>
			</MuiThemeProvider>
		</Provider>,
	document.getElementById('root')
)
