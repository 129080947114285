import * as api from '../../../apis/units'
import * as cookies from '../../../utilities/cookies'
import history from '../../../utilities/history'
import { hideModal, stopSaving } from '../../actions'
import { createSelector } from 'reselect'

const ADD_UNIT_REQUESTED = 'ADD_UNIT_REQUESTED'
const ADD_UNIT_SUCCESS = 'ADD_UNIT_SUCCESS'
const ADD_UNIT_FAILED = 'ADD_UNIT_FAILED'

export const addUnit = (name, suffix, measurementTypeId, languageId, rootId) => (dispatch) => {
	dispatch({ type: ADD_UNIT_REQUESTED, data: null })

	api.addUnit(name, suffix, measurementTypeId, languageId, rootId).then((unit) => {
		dispatch(stopSaving())
		if (unit && unit.isSuccessful) {
			dispatch(hideModal())
			history.push(`/units/${unit.id}`)
			dispatch({ type: ADD_UNIT_SUCCESS, data: null })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.general.successfullyUpdated`, isSuccess: true }
			})
		} else {
			dispatch({ type: ADD_UNIT_FAILED, data: null })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.units.addFailed`, isSuccess: false }
			})
		}
	})
	return
}

const DEACTIVATE_UNIT_REQUESTED = 'DEACTIVATE_UNIT_REQUESTED'
const DEACTIVATE_UNIT_SUCCESS = 'DEACTIVATE_UNIT_SUCCESS'
const DEACTIVATE_UNIT_FAILED = 'DEACTIVATE_UNIT_FAILED'

export const deactivateUnit = (id) => (dispatch) => {
	dispatch({ type: DEACTIVATE_UNIT_REQUESTED, data: {} })

	api.deactivateUnit(id).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(searchUnits())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.units.successfullyDeactivated`, isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.units.deactivationFailed`, isSuccess: false }
			})
		}
	})
	return
}

const EDIT_UNIT_REQUESTED = 'EDIT_UNIT_REQUESTED'
const EDIT_UNIT_SUCCESS = 'EDIT_UNIT_SUCCESS'
const EDIT_UNIT_FAILED = 'EDIT_UNIT_FAILED'

export const editUnit = (id, name, suffix, measurementTypeId, rootId) => (dispatch) => {
	dispatch({ type: ADD_UNIT_REQUESTED, data: {} })

	api.editUnit(id, name, suffix, measurementTypeId).then((unit) => {
		dispatch(stopSaving())
		if (unit && unit.isSuccessful) {
			dispatch(fetchUnit(rootId))
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.general.successfullyUpdated`, isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.general.UpdateFailed`, isSuccess: false }
			})
		}
	})
	return
}

const SEARCH_UNITS_REQUESTED = 'SEARCH_UNITS_REQUESTED'
const SEARCH_UNITS_SUCCESS = 'SEARCH_UNITS_SUCCESS'
const SEARCH_UNITS_FAILED = 'SEARCH_UNITS_FAILED'

export const searchUnits = (searchPhrase) => (dispatch) => {
	dispatch({ type: SEARCH_UNITS_REQUESTED, data: {} })
	api.search(searchPhrase).then((response) => {
		if (response && response.isSuccessful && response.units) {
			let data = response.units.sort((a, b) => {
				return a['modifiedDateSeconds'] > b['modifiedDateSeconds'] ? -1 : 1
			})
			return dispatch({ type: SEARCH_UNITS_SUCCESS, data: data })
		} else {
			return dispatch({ type: SEARCH_UNITS_FAILED, data: {} })
		}
	})
	return
}

const FETCH_UNITS_REQUESTED = 'FETCH_UNITS_REQUESTED'
const FETCH_UNITS_SUCCESS = 'FETCH_UNITS_SUCCESS'
const FETCH_UNITS_FAILED = 'FETCH_UNITS_FAILED'

export const fetchUnits = () => (dispatch) => {
	dispatch({ type: FETCH_UNITS_REQUESTED, data: {} })
	api.search().then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_UNITS_SUCCESS, data: response.units })
		} else {
			return dispatch({ type: FETCH_UNITS_FAILED, data: {} })
		}
	})
	return
}

const FETCH_UNIT_REQUESTED = 'FETCH_UNIT_REQUESTED'
const FETCH_UNIT_SUCCESS = 'FETCH_UNIT_SUCCESS'
const FETCH_UNIT_FAILED = 'FETCH_UNIT_FAILED'

export const fetchUnit = (id) => (dispatch) => {
	dispatch({ type: FETCH_UNIT_REQUESTED, data: {} })
	api.fetch(id).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_UNIT_SUCCESS, data: response })
		} else {
			return dispatch({ type: FETCH_UNIT_FAILED, data: {} })
		}
	})
	return
}

const FETCH_UNITS_BY_TYPE_REQUESTED = 'FETCH_UNITS_BY_TYPE_REQUESTED'
const FETCH_UNITS_BY_TYPE_SUCCESS = 'FETCH_UNITS_BY_TYPE_SUCCESS'
const FETCH_UNITS_BY_TYPE_FAILED = 'FETCH_UNITS_BY_TYPE_FAILED'

export const fetchUnitsByType = (measurementTypeId) => (dispatch) => {
	dispatch({ type: FETCH_UNITS_BY_TYPE_REQUESTED, data: {} })
	api.fetchUnitsByType(measurementTypeId).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_UNITS_BY_TYPE_SUCCESS, data: response.units })
		} else {
			return dispatch({ type: FETCH_UNITS_BY_TYPE_FAILED, data: {} })
		}
	})
	return
}

const FETCH_TYPES_REQUESTED = 'FETCH_TYPES_REQUESTED'
const FETCH_TYPES_SUCCESS = 'FETCH_TYPES_SUCCESS'
const FETCH_TYPES_FAILED = 'FETCH_TYPES_FAILED'

export const fetchMeasurementTypes = () => (dispatch) => {
	dispatch({ type: FETCH_TYPES_REQUESTED, data: {} })
	api.fetchTypes().then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_TYPES_SUCCESS, data: response.measurementTypes })
		} else {
			return dispatch({ type: FETCH_TYPES_FAILED, data: {} })
		}
	})
	return
}

const SET_UNIT_SORT_REQUESTED = 'SET_UNIT_SORT_REQUESTED'

export const setUnitSort = (field, isDescending) => (dispatch, state) => {
	let currentState = state()

	let x = [ ...currentState.units.searchUnits ]

	let data = x.sort((a, b) => {
		if (isDescending) {
			return a[field] > b[field] ? -1 : 1
		} else {
			return b[field] > a[field] ? -1 : 1
		}
	})

	return dispatch({
		type: SET_UNIT_SORT_REQUESTED,
		data: { items: data, field: field, isDescending: isDescending }
	})
}

const SET_UNIT_SEARCH_TERM_REQUESTED = 'SET_UNIT_SEARCH_TERM_REQUESTED'
const SET_UNIT_SEARCH_TERM_SUCCESS = 'SET_UNIT_FILTER_SUCCESS'
const SET_UNIT_SEARCH_TERM_FAILED = 'SET_UNIT_FILTER_FAILED'

export const setUnitSearchTerm = (value) => (dispatch) => {
	dispatch({ type: SET_UNIT_SEARCH_TERM_REQUESTED, data: {} })

	return dispatch({ type: SET_UNIT_SEARCH_TERM_SUCCESS, data: value })
}


const CREATE_OR_UPDATE_MEASUREMENT_TYPE_REQUESTED = 'CREATE_OR_UPDATE_MEASUREMENT_TYPE_REQUESTED'

export const createOrUpdateMeasurementType = (measurementTypeId, name) => (dispatch, getState) => {
	dispatch({ type: CREATE_OR_UPDATE_MEASUREMENT_TYPE_REQUESTED, data: {} })

	api.createOrUpdateMeasurementType(measurementTypeId, name).then((response) => {
		dispatch(stopSaving())

		if (response && response.isSuccessful) {
			dispatch(hideModal());

			const unitsState = getState().units

			dispatch(searchMeasurementTypes(unitsState.measurementTypeSearchTerm, unitsState.measurementTypeSearchOffset))

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.general.successfullyUpdated`, isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.measurementTypes.failedToCreate`, isSuccess: false }
			})
		}
	})
	return
}


const ENABLE_MEASUREMENT_TYPE_REQUESTED = 'ENABLE_MEASUREMENT_TYPE_REQUESTED'

export const enableMeasurementType = (measurementTypeId, enabled, index) => (dispatch, getState) => {
	dispatch({ type: ENABLE_MEASUREMENT_TYPE_REQUESTED, data: {} })

	api.enableMeasurementType(measurementTypeId, enabled).then((response) => {
		dispatch(stopSaving())

		if (response && response.isSuccessful) {
			const unitsState = getState().units

			let offset = index >= 5 ? index - 5 : 0

			dispatch(searchMeasurementTypes(unitsState.measurementTypeSearchTerm, offset))

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.general.successfullyUpdated`, isSuccess: true }
			})
		} else if (!enabled) {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.measurementTypes.failedToDeactivate`, isSuccess: false }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.general.UpdateFailed`, isSuccess: false }
			})
		}
	})
	return
}

const SEARCH_MEASUREMENT_TYPES_REQUESTED = 'SEARCH_MEASUREMENT_TYPES_REQUESTED'
const SEARCH_MEASUREMENT_TYPES_SUCCESS = 'SEARCH_MEASUREMENT_TYPES_SUCCESS'
const SEARCH_MEASUREMENT_TYPES_FAILED = 'SEARCH_MEASUREMENT_TYPES_FAILED'

export const searchMeasurementTypes = (searchPhrase, offset, reload = false) => (dispatch, getState) => {
	const unitsState = getState().units

	let measurementTypes = unitsState.measurementTypeSearchResult
	const sortBy = unitsState.measurementTypeSearchSortBy
	const isDescending = unitsState.measurementTypeSearchSortDescending

	dispatch({
		type: SEARCH_MEASUREMENT_TYPES_REQUESTED,
		data: {
			offset: offset
		}
	})

	api.searchMeasurementTypes(searchPhrase, offset).then((response) => {
		if (response && response.isSuccessful) {
			if (!!reload) {
				measurementTypes = []
			}
			
			if (offset == measurementTypes.length) {
				measurementTypes = [
					...measurementTypes,
					...(response.measurementTypes)
				]
			}
			else {
				let respI = 0;
				for (let i = offset; i < offset + response.measurementTypes.length; i++) {
					if (measurementTypes.length <= i) {
						measurementTypes.push(response.measurementTypes[respI])
					}
					else {
						measurementTypes[i] = response.measurementTypes[respI]
					}

					respI++;
				}
			}

			return dispatch({ type: SEARCH_MEASUREMENT_TYPES_SUCCESS,
				data: {
					searchTerm: searchPhrase,
					measurementTypes: measurementTypes.sort((a, b) => sortMeasurementTypes(a, b, sortBy, isDescending)),
					offset: offset,
					total: response.total
				}
			})
		} else {
			dispatch({
				type: SEARCH_MEASUREMENT_TYPES_FAILED,
				data: {
					searchTerm: searchPhrase,
					measurementTypes: [],
					offset: 0,
					total: 0
				}
			})
		
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.measurementTypes.failedToFetch`, isSuccess: false }
			})
		}
	})
	return
}

const SET_SEARCH_MEASUREMENT_TYPES_SORT = 'SET_SEARCH_MEASUREMENT_TYPES_SORT'

export const setSearchMeasurementTypesSort = (sortBy, isDescending) => (dispatch, getState) => {
	const unitsState = getState().units

	let measurementTypes = unitsState.measurementTypeSearchResult.sort((a, b) => sortMeasurementTypes(a, b, sortBy, isDescending))

	dispatch({
		type: SET_SEARCH_MEASUREMENT_TYPES_SORT,
		data: {
			measurementTypes: [...measurementTypes],
			sortBy,
			isDescending
		}
	})
}

const sortMeasurementTypes = (a, b, sortBy, isDescending) => {
	if (isDescending) {
		if (a[sortBy] > b[sortBy]) {
			return -1
		}

		if (a[sortBy] < b[sortBy]) {
			return 1
		}

		return 0
	}
	else {
		if (a[sortBy] < b[sortBy]) {
			return -1
		}

		if (a[sortBy] > b[sortBy]) {
			return 1
		}

		return 0
	}
}


const initial = {
	units: [],
	unit: null,
	isCreatingUnits: false,
	searchUnits: [],
	sortField: 'modifiedDateSeconds',
	isDescending: true,
	searchTerm: '',
	measurementTypes: [],
	measurementTypeSearchTerm: '',
	measurementTypeSearchResult: [],
	measurementTypeSearchOffset: 0,
	measurementTypeSearchTotal: 0,
	measurementTypeSearchLoading: false,
	measurementTypeSearchSortBy: "modifyDate",
	measurementTypeSearchSortDescending: true
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case ADD_UNIT_REQUESTED:
			return { ...state, isCreatingUnits: true }
		case ADD_UNIT_SUCCESS:
			return { ...state, isCreatingUnits: false }
		case ADD_UNIT_FAILED:
			return { ...state, isCreatingUnits: false }
		case FETCH_TYPES_SUCCESS:
			return { ...state, measurementTypes: action.data }
		case FETCH_UNIT_SUCCESS:
			return { ...state, unit: action.data }
		case FETCH_UNITS_SUCCESS:
		case FETCH_UNITS_BY_TYPE_SUCCESS:
			return { ...state, units: action.data }
		case SEARCH_UNITS_REQUESTED:
			return { ...state, searchUnits: [] }
		case SEARCH_UNITS_SUCCESS:
			return { ...state, searchUnits: action.data }
		case SET_UNIT_SORT_REQUESTED:
			return {
				...state,
				searchUnits: action.data.items,
				sortField: action.data.field,
				isDescending: action.data.isDescending
			}
		case SET_UNIT_SEARCH_TERM_SUCCESS:
			return { ...state, searchTerm: action.data }

		case SEARCH_MEASUREMENT_TYPES_REQUESTED:
			return {
				...state,
				measurementTypeSearchOffset: action.data.offset,
				measurementTypeSearchLoading: true
			}
		case SEARCH_MEASUREMENT_TYPES_SUCCESS:
			return {
				...state,
				measurementTypeSearchTerm: action.data.searchTerm,
				measurementTypeSearchResult: action.data.measurementTypes,
				measurementTypeSearchOffset: action.data.offset,
				measurementTypeSearchTotal: action.data.total,
				measurementTypeSearchLoading: false
			}
		case SEARCH_MEASUREMENT_TYPES_FAILED:
			return {
				...state,
				measurementTypeSearchTerm: action.data.searchTerm,
				measurementTypeSearchResult: action.data.measurementTypes,
				measurementTypeSearchOffset: action.data.offset,
				measurementTypeSearchTotal: action.data.total,
				measurementTypeSearchLoading: false
			}

		case SET_SEARCH_MEASUREMENT_TYPES_SORT:
			return {
				...state,
				measurementTypeSearchResult: action.data.measurementTypes,
				measurementTypeSearchSortBy: action.data.sortBy,
				measurementTypeSearchSortDescending: action.data.isDescending
			}

		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.units

export const measurementTypesSelector = createSelector(mainSelector, (state) => {
	if (state && state.measurementTypes && state.measurementTypes.length > 0) {
		var s = state.measurementTypes.map((r) => {
			return { value: r.id, name: r.name }
		})

		return s
	}
	return []
})

export const searchUnitsSelector = createSelector(mainSelector, (state) => {
	return state.searchUnits
})

export const hasUnitsSelector = createSelector(mainSelector, (state) => {
	return state.units && state.units.length > 0
})

export const unitsSelector = createSelector(mainSelector, (state) => {
	return state.units
})

export const unitSuffixSelector = createSelector(mainSelector, unitsSelector, (state, units) => (id) => {
	if (units && units.length > 0) {
		let x = units.find((r) => r.id === id)

		if (x) return x.suffix
	}
	return null
})

export const sortUnitsDirectionSelector = createSelector(mainSelector, (state) => {
	return state.isDescending
})

export const sortUnitsFieldSelector = createSelector(mainSelector, (state) => {
	return state.sortField
})

export const unitSelector = createSelector(mainSelector, (state) => {
	return state.unit
})

export const isCreatingUnitsSelector = createSelector(mainSelector, (state) => {
	return state.isCreatingUnits
})

export const unitsSearchTermSelector = createSelector(mainSelector, (state) => {
	return state.searchTerm
})

export const measurementTypesSearchTermSelector = createSelector(mainSelector, (state) => {
	return state.measurementTypeSearchTerm
})

export const measurementTypesSearchResultSelector = createSelector(mainSelector, (state) => {
	return state.measurementTypeSearchResult
})

export const measurementTypesSearchOffsetSelector = createSelector(mainSelector, (state) => {
	return state.measurementTypeSearchOffset
})

export const measurementTypesSearchTotalSelector = createSelector(mainSelector, (state) => {
	return state.measurementTypeSearchTotal
})

export const measurementTypesSearchLoadingSelector = createSelector(mainSelector, (state) => {
	return state.measurementTypeSearchLoading
})

export const measurementTypesSearchSortBySelector = createSelector(mainSelector, (state) => {
	return state.measurementTypeSearchSortBy
})

export const measurementTypesSearchSortDescendingSelector = createSelector(mainSelector, (state) => {
	return state.measurementTypeSearchSortDescending
})

