import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useTheme from '@material-ui/core/styles/useTheme'
import { connect, useDispatch } from 'react-redux'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { hideModal } from '../../../redux/actions'
import EntityAuditCommentFields from '../../../components/entityAudit/EntityAuditCommentFields'
import CalmButton from '../../../components/CalmButton'
import { reduxForm } from 'redux-form'
import { investigatorsForProtocolVersionEntityAuditSelector } from '../../../redux/selectors'
import { isAuditCommentsRequired } from '../../../utilities/auditUtils'

const SaveQuestionModal = ({
	saveQuestionCallback,
	intl,
	entityId,
	entityTypeId,
	handleSubmit,
	form,
	investigators
}) => {
	const theme = useTheme()
	const dispatch = useDispatch()

	const onSaveQuestion = (values) => {
		localStorage.setItem('entityAuditCommentsPrefill', JSON.stringify({
			commentText: values.commentText,
			protocolVersionId: values.protocolVersionId,
			relatedTaskUrl: values.relatedTaskUrl,
			investigatorId: investigators && investigators.some((x) => x.id === values.investigatorId) ? values.investigatorId : null
		}))
		if (!!saveQuestionCallback) {
			saveQuestionCallback(values)
		}
		else {
			dispatch(hideModal())
		}
	}

	useEffect(() => {}, [])

	return (
		<form onSubmit={handleSubmit(onSaveQuestion)}>
		<Grid
			container
			direction="column"
			alignItems="center"
			style={{ width: '75rem', padding: '4rem', margin: 'auto', backgroundColor: theme.palette.secondary.main }}
		>
			<Grid item style={{ alignSelf: 'center' }}>
				<Typography
					variant="h1"
					style={{
						color: '#fff',
						marginBottom: '25px',
					}}
				>
					{intl.formatMessage({ id: 'app.question.saveQuestionDialog.title' })}
				</Typography>
			</Grid>
			<Grid item style={{ alignSelf: 'flex-start' }}>
				<Typography
					variant="body2"
					style={{
						color: '#fff',
						marginBottom: '25px',
					}}
					align="left"
				>
					{intl.formatMessage({ id: 'app.question.saveQuestionDialog.message' })}
				</Typography>
			</Grid>
			{ 
                isAuditCommentsRequired() && 
				<EntityAuditCommentFields entityId={entityId} entityTypeId={entityTypeId}  formName={form}/>
			}
			<Grid item>
				<CalmButton
					type="submit"
					style={{
						width: '267px',
						marginTop: '40px'
					}}
					variant="contained"
					color="primary"
				>
					{intl.formatMessage({ id: 'app.question.saveQuestionDialog.button' })}
				</CalmButton>
			</Grid>
		</Grid>
		</form>
	)
}

const mapStateToProps = (state, ownProps) => ({
	investigators: investigatorsForProtocolVersionEntityAuditSelector(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm ({
		form: 'saveQuestion',
		touchOnChange: true,
		touchOnBlur: true
	})
)

export default enhance(SaveQuestionModal)
