import React, { useState } from 'react'
import { FormattedDate, injectIntl } from 'react-intl'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import NameCell from './NameCell'
import LastLoginCell from './LastLoginCell'
import MemberStatusIcon from './MemberStatusIcon'
import Menu from './Menu'
import MemberLanguageAbbreviation from '../../../components/member/MemberLanguageAbbreviation'
import Member2FAState from '../../../components/member/Member2FAState'

const SubscriberRow = ({ subscriber, user, intl }) => {
	const [ minHeight, setMinHeight ] = useState(64)

	const getExpiredColor = () => {
		return 
	}

	const getBorderColor = () => {
		if (subscriber.lastLogin && subscriber.expires) {
			return (new Date(new Date().toDateString()).getTime() <= new Date(subscriber.expires).getTime()) ? '#007aff': '#ff8556'
		} else {
			return new Date(new Date().toDateString()).getTime() <= new Date(subscriber.expires).getTime() ? '#fff' : '#fff'
		}
	}

	return (
		<TableRow
			key={subscriber.id}
			style={{
				// backgroundColor: '#F6F6F6'
			}}
		>
			<TableCell align="left" size="medium" style={{ position: 'relative', padding: '0' }}>
				<div style={{
						backgroundColor: `${getBorderColor()}`,
						width: '4px',
						height: 'calc(100% - 16px)',
						position: 'absolute',
						left: 0,
						top: '8px'
				}}></div>
				<div style={{ marginLeft: '26px' }}>
				<MemberStatusIcon subscriber={subscriber} />
				</div>
			</TableCell>
			<TableCell align="left" size="small">
				<NameCell subscriber={subscriber} setHeightCallback={setMinHeight} />
			</TableCell>
            <TableCell align="left" size="small">
                <Typography
                    variant="body2"
                    style={{
						textAlign: 'left',
						fontWeight: '400',
						fontSize: '17px',
						lineHeight: '22px',
						letterSpacing: '0.51px'
					}}
                >
                    {subscriber.sponsorName}
                </Typography>
                <Typography
                    variant="body2"
                    style={{
						textAlign: 'left',
						fontWeight: '700',
						fontSize: '17px',
						lineHeight: '22px',
						letterSpacing: '0.51px'
					}}
                >
					{
						!!subscriber.sponsorName
						&& !!subscriber.sponsorType
						&& `(${ intl.formatMessage({ id: `app.sponsors.sponsorSourceType.${subscriber.sponsorType}` }) })`
					}
                </Typography>
			</TableCell>
            <TableCell align="left" size="small">
                <Typography
                    variant="body2"
                    style={{
						textAlign: 'left',
						fontWeight: '400',
						fontSize: '17px',
						lineHeight: '22px',
						letterSpacing: '0.51px'
					}}
				>
					{
						subscriber.expires &&
						(
							new Date(new Date().toDateString()).getTime() <= new Date(subscriber.expires).getTime()
								? <FormattedDate value={subscriber.expires} />
								: intl.formatMessage({ id: 'app.subscribers.subscriptionExpired' })
                        )
                    }
                </Typography>
            </TableCell>
            <TableCell align="left" size="small">
                <Typography
                    variant="body2"
                    style={{
						textAlign: 'left',
						fontWeight: '400',
						fontSize: '17px',
						lineHeight: '22px',
						letterSpacing: '0.51px'
					}}
				>
					{
						subscriber.isOpenApiEnabled
							? intl.formatMessage({ id: 'app.subscribers.apiColumnValueEnabled' })
							: intl.formatMessage({ id: 'app.subscribers.apiColumnValueDisabled' })
                    }
                </Typography>
            </TableCell>
            <TableCell
                data-testid="member-row-2fa-state"
                align="left">
				<MemberLanguageAbbreviation member={subscriber} />
            </TableCell>
			<TableCell align="left" size="small">
				<Typography
					variant="body2"
					style={{
						textAlign: 'left',
						fontWeight: '400',
						fontSize: '17px',
						lineHeight: '22px',
						letterSpacing: '0.51px'
					}}
				>
					{subscriber.jurisdictionName}
				</Typography>
			</TableCell>
            <TableCell
                data-testid="member-row-2fa-state"
                align="left">
				<Member2FAState member={subscriber} />
            </TableCell>
			<TableCell align="left" size="small">
				<Typography
					variant="body2"
					style={{
						textAlign: 'left',
						fontWeight: '400',
						fontSize: '17px',
						lineHeight: '22px',
						letterSpacing: '0.51px',
						maxWidth: '100px'
					}}
				>
					{subscriber && subscriber.teamName && subscriber.teamName.length > 200 ? (
						subscriber.teamName.substring(0, 100) + '...'
					) : (
						subscriber.teamName
					)}
				</Typography>
			</TableCell>
			<TableCell align="left" size="small">
				<Typography
					variant="body2"
					style={{
						textAlign: 'left',
						fontWeight: '400',
						fontSize: '17px',
						lineHeight: '22px',
						letterSpacing: '0.51px'
					}}
				>
					{subscriber.circleCount}
				</Typography>
			</TableCell>
			<TableCell align="left" size="small">
				<LastLoginCell subscriber={subscriber} />
			</TableCell>

			<TableCell align="right" size="small" style={{ padding: '5px 0' }}>
				{
					subscriber && subscriber.id > 1 &&
					<Menu subscriber={subscriber} />
                }
			</TableCell>
		</TableRow>
	)
}

export default injectIntl(SubscriberRow)
