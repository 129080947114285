import React from "react";
import { TableCell, TableHead, TableRow } from "@material-ui/core";
import { useIntl } from "react-intl";


const RelatedServiceProviderTableHeader = ({}) => {
    const intl = useIntl()


    return (
        <TableHead>
            <TableRow>
                <TableCell
                    width={10}
                    style={{
                        padding: 0,
                        margin: 0
                    }}
                >
                <div/>
                </TableCell>
                <TableCell>
                    { intl.formatMessage({ id: "app.userProfile.relatedServiceProviders.nameLabel" }) }
                </TableCell>
                <TableCell>
                    { intl.formatMessage({ id: "app.userProfile.relatedServiceProviders.roleNameLabel" }) }
                </TableCell>
                <TableCell>
                    { intl.formatMessage({ id: "app.userProfile.relatedServiceProviders.defaultAssigneeLabel" }) }
                </TableCell>
                <TableCell>
                    { intl.formatMessage({ id: "app.userProfile.relatedServiceProviders.invitationLabel" }) }
                </TableCell>
                <TableCell>
                    { intl.formatMessage({ id: "app.userProfile.relatedServiceProviders.removeLabel" }) }
                </TableCell>
            </TableRow>
        </TableHead>
    )
}

export default RelatedServiceProviderTableHeader;
