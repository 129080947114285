import React, { useState, Fragment } from 'react'
import Autosuggest from 'react-autosuggest'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import Popper from '@material-ui/core/Popper'
import { Field, change } from 'redux-form'
import { useStyles, makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { searchBundleAutocomplete, clearBundleAutocomplete } from '../../../redux/actions'
import { useSelector, shallowEqual } from 'react-redux'
import { autocompleteBundleSelector } from '../../../redux/selectors'
import { useConstant } from '../../../utilities/useConstant'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'

var debounce = require('lodash/debounce')

const useASStyles = makeStyles((theme) => ({
	root: {
		//height: 75,
		color: 'black',
		flexGrow: 1
	},
	// container: {
	// 	position: 'relative'
	// },
	// suggestionsContainerOpen: {
	// 	position: 'absolute',
	// 	zIndex: 1300,
	// 	marginTop: theme.spacing(1),
	// 	left: 0,
	// 	right: 0
	// },
	suggestion: {
		display: 'block'
	},
	suggestionsList: {
		margin: 0,
		padding: 0,
		listStyleType: 'none'
	},
	optionStyle: {
		overflowY: 'auto',
		'& ul li .MuiMenuItem-root': {
			whiteSpace: 'normal',
		}
	},
	divider: {
		height: theme.spacing(2)
	}
}))

const styles = {
	input: {
		color: 'black',
		fontSize: '1rem'
	},

	root: {
		color: 'black'
	},
	focused: {
		color: 'black',
		'&$focused': {
			color: 'black'
		}
	}
}

const useLabelStyles = makeStyles({
	root: {
		color: 'black',
		marginLeft: '24px'
	},
	focused: {
		color: 'black',
		marginLeft: '-3px'
	}
})

const useInputStyles = makeStyles({
	root: {
		color: 'black'
	},
	focused: {
		color: 'black',
		'&$focused': {
			color: 'black'
		}
	}
})

export const QuestionSelectorControl = ({
	suggestions,
	label,
	meta: { initial, form, touched, invalid, error },
	input,
	onQuestionSelectedCallback,
	onQuestionClearedCallback
}) => {
	const bundles = useSelector(autocompleteBundleSelector, shallowEqual)
	const dispatch = useDispatch()
	var labelStyles = useLabelStyles()
	var inputStyles = useInputStyles()
	const [ noMatch, setNoMatch ] = useState(false)
	const [ matchSelected, setMatchSelected ] = useState(false)
	const [ stateSuggestions, setSuggestions ] = useState(bundles)
	const classes = useASStyles()
	const [ anchorEl, setAnchorEl ] = React.useState(null)
	const [ state, setState ] = React.useState({
		label: ''
	})

	const handleSuggestionsFetchRequested = ({ value }) => {
		if (value && value.length > 0) {
			dispatch(searchBundleAutocomplete(value))
		} else {
			dispatch(clearBundleAutocomplete())
		}
	}

	const onSuggestionRequested = useConstant(() => debounce(handleSuggestionsFetchRequested, 250, { leading: true }))

	function renderSuggestion(suggestion, { query, isHighlighted }) {
		return (
			<MenuItem selected={isHighlighted} component="div">
				<div>{suggestion.name}</div>
			</MenuItem>
		)
	}

	function renderInputComponent(inputProps) {
		const { classes, inputRef = () => {}, ref, ...other } = inputProps

		return (
			<TextField
				fullWidth
				autoComplete={false}
				InputLabelProps={{
					classes: {
						root: labelStyles.root,
						focused: labelStyles.focused,
						shrink: labelStyles.focused
					}
				}}
				error={touched && invalid}
				helperText={touched && error}
				classes={{
					root: inputStyles.root,
					error: inputStyles.error
				}}
				style={styles}
				InputProps={{
					...inputProps,
					inputRef: (node) => {
						ref(node)
						inputRef(node)
					},

					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon style={{ margin: '-5px -9px 0 11px', color: 'black', display: 'block' }} />
						</InputAdornment>
					)
				}}
				inputProps={{ style: { color: 'black' }, autocomplete: 'dont' }}
				shrink="true"
				variant="filled"
				{...other}
			/>
		)
	}

	const handleSuggestionsClearRequested = () => {
		setSuggestions([])
	}

	const onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
		if (suggestion && suggestion.id > -1) {
			dispatch(change(form, input.name, suggestion.id))
			setNoMatch(false)
			setMatchSelected(true)

			onQuestionSelectedCallback(suggestion)
		} else {
			setState({
				...state,
				value: null,
				label: ''
			})

			onQuestionClearedCallback()

			dispatch(change(form, input.name, ''))
		}
	}

	function getSuggestionValue(suggestion) {
		return suggestion
	}

	const autosuggestProps = {
		renderInputComponent,
		suggestions: bundles,
		onSuggestionsFetchRequested: onSuggestionRequested,
		onSuggestionsClearRequested: handleSuggestionsClearRequested,
		onSuggestionSelected: onSuggestionSelected,
		getSuggestionValue,
		renderSuggestion
	}

	const handleChange = (event, { newValue }) => {
		if (newValue && newValue.id) {
			dispatch(change(form, input.name, newValue.id))
			setNoMatch(false)
			setMatchSelected(true)
			if (onQuestionSelectedCallback) {
				onQuestionSelectedCallback(newValue.id)
			}
		} else {
			dispatch(change(form, input.name, null))
			setNoMatch(true)
			setMatchSelected(false)
			if (onQuestionSelectedCallback) {
				onQuestionSelectedCallback(null)
			}
		}
		setState({
			...state,
			value: newValue,
			label: newValue && newValue.name ? newValue.name : newValue
		})
	}

	return (
		<div className={classes.root}>
			<Autosuggest
				{...autosuggestProps}
				inputProps={{
					classes,
					id: 'react-autosuggest-popper',
					label: label,
					value: state.label,
					onChange: handleChange,
					onBlur: (e, x) => {
						setAnchorEl(null)

						//input.onChange(e.target.value)
						if (noMatch || !matchSelected) {
							setState({
								...state,
								value: '',
								label: ''
							})
							dispatch(clearBundleAutocomplete())
							if (onQuestionClearedCallback) {
								onQuestionClearedCallback()
							}
							dispatch(change(form, input.name, ''))
						}
					},
					inputRef: (node) => {
						setAnchorEl(node)
					}
				}}
				theme={{
					suggestionsList: classes.suggestionsList,
					suggestion: classes.suggestion
				}}
				renderSuggestionsContainer={(options) => (
					<Popper anchorEl={anchorEl} open={Boolean(options.children)} style={{ zIndex: 1300 }}	
                		placement="bottom-start"       
						modifiers={{
      						flip: {
        						enabled: false,
      						},
	  						preventOverflow: {
      						  	enabled: true,
      						  	boundariesElement: 'viewPort',
      						},
						}}
					>
						<Paper
							square
							{...options.containerProps}
							className={classes.optionStyle}
							style={{
								maxHeight: anchorEl ? window.innerHeight-anchorEl.parentElement.getBoundingClientRect().bottom : undefined,
								width: anchorEl ? anchorEl.parentElement.clientWidth : undefined,
								marginLeft: anchorEl ? -(anchorEl.parentElement.clientWidth-anchorEl.clientWidth) : undefined }}
						>
							{options.children}
						</Paper>
					</Popper>
				)}
			/>
		</div>
	)
}

export default ({
	label,
	name,
	validators,
	maxLength = 255,
	noMatchText = 'No Matches Found',
	onQuestionSelectedCallback,
	onQuestionClearedCallback,
	...other
}) => {
	return (
		<Field
			name={name}
			component={QuestionSelectorControl}
			label={label}
			maxLength={maxLength}
			fullWidth
			validate={validators}
			noMatchText={noMatchText}
			onQuestionSelectedCallback={onQuestionSelectedCallback}
			onQuestionClearedCallback={onQuestionClearedCallback}
			{...other}
		/>
	)
}
