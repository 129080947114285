import * as emailApi from '../../../apis/emailTemplates'
import * as smsApi from '../../../apis/smsTemplates'
import * as pushApi from '../../../apis/pushTemplates'
import { createSelector } from 'reselect'
import { hideModal, stopSaving } from '../core'

const PROTOCOL_VERSION_LEVEL_TEMPLATES = [5, 8, 11, 12, 13, 14, 15, 16]
const SURVEY_LEVEL_TEMPLATES = [8, 11, 12, 14]
const CIRCLE_LEVEL_TEMPLATES = [3, 4]

const FETCH_TEMPLATE_REQUESTED = 'FETCH_TEMPLATE_REQUESTED'
const FETCH_TEMPLATE_SUCCESS = 'FETCH_TEMPLATE_SUCCESS'
const FETCH_TEMPLATE_FAILED = 'FETCH_TEMPLATE_FAILED'

export const fetchTemplate = (versionId, surveyInstanceId, circleId, languageId, type) => (dispatch) => {
	dispatch({ type: FETCH_TEMPLATE_REQUESTED, data: {} })
	emailApi.fetchTemplate(versionId, surveyInstanceId, circleId, languageId, type).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_TEMPLATE_SUCCESS, data: response })
		} else {
			return dispatch({ type: FETCH_TEMPLATE_FAILED, data: null })
		}
	})
	return
}

const FETCH_TEMPLATE_TYPES_REQUESTED = 'FETCH_TEMPLATE_TYPES_REQUESTED'
const FETCH_TEMPLATE_TYPES_SUCCESS = 'FETCH_TEMPLATE_TYPES_SUCCESS'
const FETCH_TEMPLATE_TYPES_FAILED = 'FETCH_TEMPLATE_TYPES_FAILED'

export const fetchTemplateTypes = () => (dispatch) => {
	dispatch({ type: FETCH_TEMPLATE_TYPES_REQUESTED, data: {} })
	emailApi.fetchTypes().then((response) => {
		if (response) {
			return dispatch({ type: FETCH_TEMPLATE_TYPES_SUCCESS, data: response })
		} else {
			return dispatch({ type: FETCH_TEMPLATE_TYPES_FAILED, data: null })
		}
	})
	return
}

const UPDATE_TEMPLATE_REQUESTED = 'UPDATE_TEMPLATE_REQUESTED'
const UPDATE_TEMPLATE_SUCCESS = 'UPDATE_TEMPLATE_SUCCESS'
const UPDATE_TEMPLATE_FAILED = 'UPDATE_TEMPLATE_FAILED'

export const updateTemplate = (versionId, surveyInstanceId, circleId, languageId, type, htmlPart, subjectPart, commentsObj) => (dispatch) => {
	dispatch({ type: UPDATE_TEMPLATE_REQUESTED, data: {} })

	if (isEmailTemplateType(type)) {
		emailApi.updateTemplate(versionId, surveyInstanceId, circleId, languageId, type, htmlPart, subjectPart, commentsObj).then((response) => {
			dispatch(stopSaving())
			if (response && response.isSuccessful) {
				dispatch({ type: UPDATE_TEMPLATE_SUCCESS, data: response })

				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: `app.template.updateSuccessful`, isSuccess: true }
				})
			} else {
				dispatch({ type: UPDATE_TEMPLATE_FAILED, data: response?.errorMessage })
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: `app.template.updateFailed`, isSuccess: false }
				})
			}
		})
	} else if (isSmsTemplateType(type)) {
		smsApi.updateTemplate(versionId, surveyInstanceId, languageId, type, htmlPart, commentsObj).then((response) => {
			dispatch(stopSaving())
			if (response && response.isSuccessful) {
				dispatch({ type: UPDATE_TEMPLATE_SUCCESS, data: response })

				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: `app.template.updateSuccessful`, isSuccess: true }
				})
			} else {
				dispatch({ type: UPDATE_TEMPLATE_FAILED, data: response?.errorMessage })
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: `app.template.updateFailed`, isSuccess: false }
				})
			}
		})
	} else if (isPushTemplateType(type)) {
		pushApi.updateTemplate(versionId, surveyInstanceId, languageId, type, htmlPart, commentsObj).then((response) => {
			dispatch(stopSaving())
			if (response && response.isSuccessful) {
				dispatch({ type: UPDATE_TEMPLATE_SUCCESS, data: response })

				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: `app.template.updateSuccessful`, isSuccess: true }
				})
			} else {
				dispatch({ type: UPDATE_TEMPLATE_FAILED, data: response?.errorMessage })
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: `app.template.updateFailed`, isSuccess: false }
				})
			}
		})
	}
	return
}

const SEND_TEMPLATE_EMAIL_REQUESTED = 'SEND_TEMPLATE_EMAIL_REQUESTED'
const SEND_TEMPLATE_EMAIL_SUCCESS = 'SEND_TEMPLATE_EMAIL_SUCCESS'
const SEND_TEMPLATE_EMAIL_FAILED = 'SEND_TEMPLATE_EMAIL_FAILED'

export const sendTemplateEmail = (versionId, surveyInstanceId, circleId, languageId, type, email) => (dispatch) => {
	dispatch({ type: SEND_TEMPLATE_EMAIL_REQUESTED, data: {} })

	emailApi.sendTemplateEmail(versionId, surveyInstanceId, circleId, languageId, type, email).then((response) => {
		dispatch(stopSaving())
		if (response && response.isSuccessful) {
			dispatch({ type: SEND_TEMPLATE_EMAIL_SUCCESS, data: {} })
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.template.sendEmailSuccess`, isSuccess: true }
			})
		} else {
			dispatch({ type: SEND_TEMPLATE_EMAIL_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.template.sendEmailFailed`, isSuccess: false }
			})
		}
	})
	return
}

export const sendTemplateSms = (versionId, surveyInstanceId, languageId, type, phoneNumber) => (dispatch) => {
	dispatch({ type: SEND_TEMPLATE_EMAIL_REQUESTED, data: {} })

	smsApi.sendTemplateSms(versionId, surveyInstanceId, languageId, type, phoneNumber).then((response) => {
		dispatch(stopSaving())
		if (response && response.isSuccessful) {
			dispatch({ type: SEND_TEMPLATE_EMAIL_SUCCESS, data: {} })
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.template.sendSmsSuccess`, isSuccess: true }
			})
		} else {
			dispatch({ type: SEND_TEMPLATE_EMAIL_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.template.sendSmsFailed`, isSuccess: false }
			})
		}
	})
	return
}

export const sendTemplatePush = (versionId, surveyInstanceId, languageId, type, email) => (dispatch) => {
	dispatch({ type: SEND_TEMPLATE_EMAIL_REQUESTED, data: {} })

	pushApi.sendTemplate(versionId, surveyInstanceId, languageId, type, email).then((response) => {
		dispatch(stopSaving())
		if (response && response.isSuccessful) {
			dispatch({ type: SEND_TEMPLATE_EMAIL_SUCCESS, data: {} })
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.template.sendPushSuccess`, isSuccess: true }
			})
		} else {
			dispatch({ type: SEND_TEMPLATE_EMAIL_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.template.sendPushFailed`, isSuccess: false }
			})
		}
	})
	return
}

const initial = {
	template: null,
	types: [],
	templateError: null
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_TEMPLATE_REQUESTED:
			return { ...state, template: null, templateError: null }
		case FETCH_TEMPLATE_SUCCESS:
			return { ...state, template: action.data }
		case FETCH_TEMPLATE_FAILED:
			return { ...state, template: null }
		case FETCH_TEMPLATE_TYPES_REQUESTED:
			return { ...state, types: [] }
		case FETCH_TEMPLATE_TYPES_SUCCESS:
			return { ...state, types: action.data }
		case FETCH_TEMPLATE_TYPES_FAILED:
			return { ...state, types: [] }
		case UPDATE_TEMPLATE_REQUESTED:
			return { ...state, templateError: null }
		case UPDATE_TEMPLATE_SUCCESS:
			return { ...state, template: action.data }
		case UPDATE_TEMPLATE_FAILED:
			return { ...state, templateError: action.data }
		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.emailTemplates

export const emailTemplateSelector = createSelector(mainSelector, (state) => {
	return state && state.template
})

export const emailTemplateTypesSelector = createSelector(
	[
		state => state.emailTemplates,
		(state, params) => params
	],
	(state, {versionId, surveyInstanceId, circleId}) => {
		let allTypes = state && state.types
		if (surveyInstanceId) {
			return allTypes && allTypes.filter(x => SURVEY_LEVEL_TEMPLATES.includes(x.id))
		}

		if (versionId) {
			return allTypes && allTypes.filter(x => PROTOCOL_VERSION_LEVEL_TEMPLATES.includes(x.id))
		}

		if (circleId) {
			return allTypes && allTypes.filter(x => CIRCLE_LEVEL_TEMPLATES.includes(x.id))
		}

		return allTypes
	}
)

export const emailTemplateErrorSelector = createSelector(mainSelector, (state) => {
	return state && state.templateError
})

export const isEmailTemplateType = (type) => {
	return ![12, 13, 14, 16].includes(type)
}

export const isSmsTemplateType = (type) => {
	return [12, 13, 16].includes(type);
}

export const isPushTemplateType = (type) => {
	return type == 14;
}
