import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import FilledNumberTextFieldLight from '../FilledNumberTextFieldLight'
import { useSelector, shallowEqual } from 'react-redux'
import { unitSuffixSelector, hasUnitsSelector } from '../../redux/selectors'
import { fetchUnits } from '../../redux/actions'
import { useDispatch } from 'react-redux'
import QuestionContainer from './QuestionContainer'

export default ({ question, position, positionDisplay }) => {
	const dispatch = useDispatch()
	let units = useSelector(unitSuffixSelector, shallowEqual)
	let hasUnits = useSelector(hasUnitsSelector, shallowEqual)

	useEffect(
		() => {
			if (!hasUnits) {
				dispatch(fetchUnits())
			}
		},
		[ hasUnits ]
	)

	return (
			<Grid data-testid="question-number-question" container direction="column">
				<Grid item>
					<QuestionContainer
						title={question.title}
						position={position}
						instructions={question.instructions}
						positionDisplay={positionDisplay}
						displayInstructionAsIcon={question.displayInstructionAsIcon}
						children={
							<Grid container style={{ width: '100%' }} alignItems="center" spacing={2}>
								<Grid item style={{ flex: 0.8 }}>
									<FilledNumberTextFieldLight
										type="number"
										pattern="/^(\d{0,3})\.?\d{0,3}?$/"
										allowDecimal={question.numberType === 'fp'}
										maxLength={question.precision ? question.precision + 1 : 5}
										style={{ paddingTop: '7px' }}
									/>
								</Grid>
								<Grid item style={{ flex: 0.2 }}>
									<Typography variant="subtitle1" style={{ fontWeight: '400' }}>
										{units(question.unitId)}
									</Typography>
								</Grid>
							</Grid>
						}
					/>
				</Grid>
			</Grid>

	)
}
