import React from 'react'
import { reduxForm } from 'redux-form'
import { compose, shallowEqual } from 'recompose'
import { injectIntl } from 'react-intl'
import { connect, useDispatch, useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { greaterThanValue, number, required } from '../../utilities/validators'
import { isSavingSelector } from '../../redux/selectors'
import { Typography, useTheme } from '@material-ui/core'
import FormTextField from '../../components/FormTextField'
import CalmButton from '../../components/CalmButton'
import { moveQuestionToPosition, sendTemplateEmail, startSaving } from '../../redux/actions'
import FormNumberTextField from '../FormNumberTextField'

const greaterThanValueValidator = greaterThanValue(0)

const MoveQuestionToPositionModal = ({ intl, handleSubmit, id, totalQuestions, move }) => {
	const dispatch = useDispatch()
	const isSaving = useSelector(isSavingSelector, shallowEqual)

	const onSubmitForm = (values) => {
		dispatch(startSaving())
		move(id, values.position)
	}

	return (
		<form onSubmit={handleSubmit(onSubmitForm)}>
			<Grid
				container
				direction="column"
				className="admin-modal"
			>
				<Grid item style={{ width: '100%', textAlign: 'center' }}>
					<Typography
						variant="h1"
						style={{ color: 'white', paddingBottom: '32px' }}
					>
						{intl.formatMessage({ id: 'app.survey.moveToPositionModalHeader' })}
					</Typography>
				</Grid>

				<Grid item style={{ width: '100%', textAlign: 'center' }}>
					<FormNumberTextField
						label={intl.formatMessage({ id: 'app.survey.moveToPositionModalFieldLabel' }) + '*'}
						type="text"
						name="position"
						maxLength={3}
						variant="filled"
						validators={[ number, required, greaterThanValueValidator ]}
					/>
				</Grid>
				<Grid item style={{ width: '100%', textAlign: 'center' }}>
					<Typography
						variant="h6"
						style={{ color: 'white', paddingBottom: '32px' }}
					>
						{`${intl.formatMessage({ id: 'app.survey.moveToPositionTotalLabel' })}: ${totalQuestions}`}
					</Typography>
				</Grid>

				<Grid
					item
					style={{
						width: '100%',
						marginTop: '10px',
						textAlign: 'center'
					}}
				>
                    <CalmButton
                        variant="contained"
                        color="primary"
						style={{
							width: '200px'
						}}
						type="submit"
						size="large"
						disabled={isSaving}
					>
						{intl.formatMessage({ id: 'app.survey.moveToPositionModalButtonLabel' })}
					</CalmButton>
				</Grid>
			</Grid>
		</form>
	)
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => ({
	move: (id, position) => {
		dispatch(moveQuestionToPosition(id, position))
	}
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: 'moveToPosition'
	}),
	injectIntl
)

export default enhance(MoveQuestionToPositionModal)
