import React, { useEffect } from 'react'
import { compose } from 'recompose'
import { connect, useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl'
import { Button, Typography } from '@material-ui/core'
import { fetchCurrentBackendVersion, setVersionMismatchAlertVisibility } from '../redux/actions'
import { currentBackendVersionSelector, isVersionMismatchAlertVisibleSelector } from '../redux/selectors'
import { useTheme, withStyles } from '@material-ui/core'

const styles = (theme) => ({
	alertMessage: {
        position: 'absolute',
        zIndex: 99999,
        top: '0',
        left: 0,
        right: 0,
        margin: 'auto',
        padding: '5px 25px',
        fontSize: '24px',
        boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.25)',
        backgroundImage: 'linear-gradient(to bottom, #072243, #030f20)',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        color: '#fff',
        margin: '0 0 0 264px',
        width: 'calc(100% - 264px - 16px)',
        height: '100px',
        overflow: 'auto',

        "& + div div[class*='mainContentInner']": {
            paddingTop: '100px',
            [theme.breakpoints.down('xs')]: {
                paddingTop: '165px',
            }
        },
        "& + * .menuButton": {
            top: '65px',
            [theme.breakpoints.down('xs')]: {
                top: '135px',
            }            
        },
        [theme.breakpoints.down('md')]: {
	        margin: '0',
            width: 'calc(100% - 16px)',
            height: '65px',            
            padding: '5px',
            "& *": {
                fontSize: '14px',
            }
            
		},
        [theme.breakpoints.down('xs')]: {
            fontSize: '10px',
            height: '136px',
            justifyContent: 'center', 
            flexDirection: 'column',
            textAlign: 'center'
		},
    },
    reloadButton: {
        backgroundColor: 'rgb(0, 122, 255)',
        color: '#fff',
        "&:hover, &:focus": {
            backgroundColor: 'rgb(0, 122, 255)',
            color: '#fff',
        },
        [theme.breakpoints.down('md')]: {
            padding: '5px 10px',
            fontSize: '14px',
            height: 'auto',
        },
    }
})


const AppUpdatesChecker = ({ intl, children, loadCurrentBackendVersion, currentBackendVersion, showVersionMismatchAlert, hideVersionMismatchAlert, isVersionMismatchAlertVisible, classes }) => {
    const dispatch = useDispatch()

    useEffect(
        () => {

            const f = () => dispatch(function () {
                loadCurrentBackendVersion();
            });

            f();

            let timer = setInterval(f, 5 * 60 * 1000)

            return () => {
                clearInterval(timer)
            }
        }
        , []

    )

    useEffect(
        () => {
            if (currentBackendVersion && process.env.REACT_APP_EXECUTION_ID && currentBackendVersion !== process.env.REACT_APP_EXECUTION_ID) {
                showVersionMismatchAlert();
            } else {
                hideVersionMismatchAlert();
            }
        }
        , [currentBackendVersion]
    )

    return [
        (isVersionMismatchAlertVisible &&
        <div data-print="none" className={classes.alertMessage}>
            <div>
                <Typography variant='body1'>
                    {intl.formatMessage({ id: 'app.versionMismatchAlert.text' })}
                </Typography>
            </div>
            <div>
                <Button className={classes.reloadButton}
                    type='button'
                    variant="contained"
					color="primary"
                    onClick={() => { window.location.reload() }}
                >
                    {intl.formatMessage({ id: 'app.versionMismatchAlert.action' })}
                </Button>
            </div>
            </div>),
        children];
}

const mapStateToProps = (state) => ({
    currentBackendVersion: currentBackendVersionSelector(state),
    isVersionMismatchAlertVisible: isVersionMismatchAlertVisibleSelector(state)
})

const mapDispatchToProps = (dispatch) => ({
    loadCurrentBackendVersion: () => dispatch(fetchCurrentBackendVersion()),
    showVersionMismatchAlert: () => dispatch(setVersionMismatchAlertVisibility(true)),
    hideVersionMismatchAlert: () => dispatch(setVersionMismatchAlertVisibility(false))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withStyles(styles))

export default enhance(AppUpdatesChecker)
