import React, { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useIntl } from 'react-intl'
import { compose } from 'recompose'
import { reduxForm, change } from 'redux-form'
import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { isSavingSelector } from '../../../redux/selectors'
import FormFilledNumberTextFieldLight from '../../../components/FormFilledNumberTextFieldLight'
import { mustBeLessThanValue, required } from '../../../utilities/validators'

const formName = 'protocolVersionScoringGroupSurveySettings'
const lowerLessThanMaxValueValidator = mustBeLessThanValue('benchmarcMaxValue')

const Form = ({ handleSubmit, currentSurvey, onSaveCallback, versionId, scoringGroupId, scoringGroupSettings }) => {
	const dispatch = useDispatch()
	const isSaving = useSelector(isSavingSelector, shallowEqual)
	const intl = useIntl()

	useEffect(
		() => {
			dispatch(change(formName, 'benchmarcSingleValue', currentSurvey && currentSurvey.benchmarcSingleValue))
			dispatch(change(formName, 'benchmarcMinValue', currentSurvey && currentSurvey.benchmarcMinValue))
			dispatch(change(formName, 'benchmarcMaxValue', currentSurvey && currentSurvey.benchmarcMaxValue))
		},
		[ currentSurvey ]
	)

	const onSubmit = (values) => {
		if (values) {
			onSaveCallback && onSaveCallback(versionId, scoringGroupId, currentSurvey.surveyInstanceId, values)
		}
	}

	return (
		currentSurvey && <form onSubmit={handleSubmit(onSubmit)}>
			<Grid container justify="flex-end">
				<Grid item>
					<Button
						variant="contained"
						color="primary"
						disabled={isSaving}
						type="submit"
					>
						{intl.formatMessage({
							id: 'app.general.saveChangesButtonText'
						})}
					</Button>
				</Grid>
			</Grid>
			<div style={{ backgroundColor: 'rgb(225, 238, 255)', marginTop: '26px', padding: '3%' }}>
				<Grid container direction="column">
					<Grid container direction="column">
						<Typography
							style={{ fontSize: '13px', lineHeight: '16px', letterSpacing: '2.08px', color: '#011020' }}>
							{intl.formatMessage({ id: 'app.protocol.scoringGroups.benchmarcValueLabel' })}
						</Typography>
						{
							scoringGroupSettings && scoringGroupSettings.benchmarcType === 0 &&
							<Grid item style={{ marginTop: '17px' }}>
								<FormFilledNumberTextFieldLight
									label={intl.formatMessage({ id: 'app.protocol.scoringGroups.benchmarcSingleValueLabel' })}
									fullWidth
									allowDecimal={true}
									name="benchmarcSingleValue"
									validators={required}
								/>
							</Grid>
						}
						{
							scoringGroupSettings && scoringGroupSettings.benchmarcType === 1 &&
							<Grid container direction="row" style={{ marginTop: '17px', justifyContent: 'space-between' }}>
								<Grid item style={{ flex: '0.4' }}>
									<FormFilledNumberTextFieldLight
										label={intl.formatMessage({ id: 'app.protocol.scoringGroups.benchmarcMinValueLabel' })}
										fullWidth
										allowDecimal={true}
										name="benchmarcMinValue"
										validators={[required, lowerLessThanMaxValueValidator]}
									/>
								</Grid>
								<Grid item style={{ flex: '0.4' }}>
									<FormFilledNumberTextFieldLight
										label={intl.formatMessage({ id: 'app.protocol.scoringGroups.benchmarcMaxValueLabel' })}
										fullWidth
										allowDecimal={true}
										name="benchmarcMaxValue"
										validators={required}
									/>
								</Grid>
							</Grid>
						}

					</Grid>
				</Grid>
			</div>


		</form>
	)
}

const enhance = compose(
	reduxForm({
		form: formName,
		touchOnChange: true,
		touchOnBlur: true
	})
)

export default enhance(Form)