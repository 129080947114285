import React, { Fragment, useEffect, useState } from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { connect, useDispatch } from 'react-redux'
import { useTheme, withStyles } from '@material-ui/core/styles'
import SearchBar from './SearchBar'
import Filter from './Filter'
import { searchCircles, setCircleSearchTerm, setProtocolFilter, showModal } from '../../../redux/actions'
import CompositeLabelPaper from '../../../components/CompositeLabelPaper'
import LabelPaper from '../../../components/LabelPaper'
import { searchCirclesSelector, hasProtocolSelector, circleSearchTermSelector } from '../../../redux/selectors'
import { getUser } from '../../../utilities/userUtilities'
import AddCircleButton from './AddCircleButton'
import CircleTable from './CircleTable'
import UsageStatisticsModal from './UsageStatisticsModal'

const styles = {
	root: {
		background: 'black'
	},
	input: {
		color: 'white',
		backgroundColor: 'rgb(36,48,65)'
	},
	gradient: {
		background:
			'linear-gradient(45deg,  #d4e1f7 0%,#d4e1f7 50%,#ffffff 50%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */',
		filter:
			"progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4e1f7', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */"
	}
}

const Circles = ({ 
	intl,
	circles,
	getCircles,
	setSearch,
	circleFilter
}) => {
	const [user, setUser] = useState(getUser())

    const dispatch = useDispatch()
    const theme = useTheme()

	useEffect(() => {
		if (circles?.length == 0) {
			getCircles(circleFilter)
		}
	}, [])

	return (
		<div className="admin-fullheight-wrapper">
			<Fragment>
				<Grid container direction="row" style={{ paddingBottom: '32px', justifyContent: 'space-between' }}>
					<Grid item>
						<Typography variant="h1" id="headerText" >
							Circles
						</Typography>
					</Grid>
                    <Grid item>
                        <Button
                            data-testid="user-profile-header-button-edit"
							onClick={() => { dispatch(showModal(UsageStatisticsModal, { })) }}
                            variant="text"
                            size="large"
						>                            
                                {intl.formatMessage({
									id: 'app.circles.usageStatistics'
								})}
                        </Button>
                    </Grid>
				</Grid>
				<Grid container justify="space-between" style={{ paddingBottom: '50px' }}>
					<Grid item style={{ flex: 0.95 }}>
						<SearchBar onSearchChange={setSearch} />
					</Grid>
					<Grid item style={{ paddingTop: '10px' }}>
						<AddCircleButton />
					</Grid>
				</Grid>
				<CompositeLabelPaper
					label={intl.formatMessage({ id: 'app.circles.noFilter' })}
					Component={CircleTable}
					user={user}
					data={circles}
					Filter={Filter}
				/>
			</Fragment>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	circles: searchCirclesSelector(state),
	hasProtocols: hasProtocolSelector(state),
	circleFilter: circleSearchTermSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	getCircles: (filter) => dispatch(searchCircles(filter)),
	setSearch: (value) => dispatch(setCircleSearchTerm(value))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withStyles(styles))

export default enhance(Circles)
