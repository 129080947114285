import React, {useState} from 'react'
import Button from "@material-ui/core/Button";


const CalmButton = (props) => {
	const [executing, setExecuting] = useState(false);

    const {
        disabled,
        onClick,
        ...otherProps
    } = props;

    const onRealClick = async () => {
        setExecuting(true);
        try {
			if(onClick)
			{
				await onClick();
			} 
        } finally {
            setExecuting(false);
        }
	};
	
    return(
		<Button 
			onClick={onRealClick} 
			disabled={executing || disabled}
			{...otherProps}
		/>
    )
}

export default CalmButton