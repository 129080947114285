import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Login from './login'
import loginBackground from '../assets/login/arrows-bright.jpg'
import ForgotPassword from '../pages/forgotPassword'
import ConfirmForgotPassword from '../pages/forgotPassword/ConfirmForgotPassword'
import ChangePassword from '../pages/changePassword'
import Snackbar from '../components/Snackbar'

import { compose } from 'recompose'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = (theme) => ({
	
	// Set css/scss variables colors, in the same palette as in theme.js
	'@global': {
		':root': {
			'--palette-light': theme.palette.light,
			'--palette-dark': theme.palette.dark,
			'--palette-lightCell': theme.palette.lightCell,
			'--palette-darkCell': theme.palette.darkCell,
			'--palette-blue': theme.palette.blue,
			'--palette-primary-light': theme.palette.primary.light,
			'--palette-primary-A100': theme.palette.primary.A100,
			'--palette-primary-A200': theme.palette.primary.A200,
			'--palette-primary-A300': theme.palette.primary.A300,
			'--palette-primary-main': theme.palette.primary.main,
			'--palette-primary-A500': theme.palette.primary.A500,
			'--palette-primary-A600': theme.palette.primary.A600,
			'--palette-primary-link': theme.palette.primary.link,
			'--palette-primary-A800': theme.palette.primary.A800,
			'--palette-primary-dark': theme.palette.primary.dark,
			'--palette-secondary-light': theme.palette.secondary.light,
			'--palette-secondary-A100': theme.palette.secondary.A100,
			'--palette-secondary-A200': theme.palette.secondary.A200,
			'--palette-secondary-A300': theme.palette.secondary.A300,
			'--palette-secondary-main': theme.palette.secondary.main,
			'--palette-secondary-contrast': theme.palette.secondary.contrast,
			'--palette-secondary-input': theme.palette.secondary.input,
			'--palette-secondary-inputHover': theme.palette.secondary.inputHover,
			'--palette-secondary-additional': theme.palette.secondary.additional,
			'--palette-secondary-A600': theme.palette.secondary.A600,
			'--palette-secondary-A700': theme.palette.secondary.A700,
			'--palette-secondary-A800': theme.palette.secondary.A800,
			'--palette-secondary-dark': theme.palette.secondary.dark,
			'--palette-text-primary': theme.palette.text.primary,
			'--palette-text-secondary': theme.palette.text.secondary,
			'--palette-text-disabled': theme.palette.text.disabled,
			'--palette-text-hint': theme.palette.text.hint,
			'--palette-background-default': theme.palette.background.default,
			'--palette-action-disabledBackground': theme.palette.action.disabledBackground,
			'--palette-action-disabled': theme.palette.action.disabled,
			'--palette-success-main': theme.palette.success.main,
			'--palette-error-main': theme.palette.error.main,
			'--palette-error-light': theme.palette.error.light,
			'--palette-warning-main': theme.palette.warning.main,
			'--palette-info-main': theme.palette.info.main,

		}
	}
})

const SecureRoot = () => {
	return (
		<div
			style={{
				minHeight: '100vh',
				backgroundImage: `url(${loginBackground})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center center',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			}}
		>
			<Switch>
				<Route path="/auth/login" component={Login} />
				<Route exact path="/auth/forgotpassword" component={ForgotPassword} />
				<Route exact path="/auth/confirmpassword" component={ConfirmForgotPassword} />
				<Route exact path="/auth/changePassword" component={ChangePassword} />
			</Switch>

			<Snackbar />
		</div>
	)
}

export default SecureRoot
