import React, { useEffect } from 'react'
import { useDispatch, useStore, useSelector, shallowEqual } from 'react-redux'
import { useIntl } from 'react-intl'
import { compose } from 'recompose'
import { reduxForm, change, formValueSelector } from 'redux-form'
import { useTheme, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import DefaultFormTextField from '../DefaultFormTextField'
import { isSavingSelector } from '../../redux/selectors'

const formName = 'patientUpdateTriggerTemplate'

const Form = ({ template, languageId, type, handleSubmit, onSaveCallback, versionId }) => {
	const dispatch = useDispatch()
	const isSaving = useSelector(isSavingSelector, shallowEqual)
	const theme = useTheme()
	const intl = useIntl()

	useEffect(
		() => {
			dispatch(change(formName, 'body', template && template.template ? template.template.body : ''))
			dispatch(change(formName, 'defaultBody', template && template.defaultTemplate ? template.defaultTemplate.body : ''))
		},
		[ template ]
	)

	const onSubmit = (values) => {
		if (values && languageId) {
			onSaveCallback && onSaveCallback(versionId, languageId, type, values.body)
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container justify="flex-end">
				<Grid item>
					<Button
                        variant="contained"
                        color="primary"
						disabled={isSaving}
						type="submit"
					>
						{intl.formatMessage({
							id: 'app.general.saveChangesButtonText'
						})}
					</Button>
				</Grid>
			</Grid>
			{template && template.fields && <Grid container direction="column">
				<Typography
					style={{ fontSize: '16px', lineHeight: '16px', letterSpacing: '2.08px', color: '#011020' }}
				>
					{intl.formatMessage({ id: 'app.templates.fieldsList' })}:
				</Typography>

				<Grid item style={{ marginTop: '17px' }}>
					<Typography
						style={{ fontSize: '13px', lineHeight: '16px', letterSpacing: '2.08px', color: '#011020' }}
					>
						{template.fields.join(', ')}
					</Typography>
				</Grid>
			</Grid>}

			<div style={{ backgroundColor: 'rgb(225, 238, 255)', marginTop: '26px', padding: '3%' }}>
				<Grid container direction="column">
					<Typography
						style={{ fontSize: '13px', lineHeight: '16px', letterSpacing: '2.08px', color: '#011020' }}
					>
						{intl.formatMessage({ id: 'app.patientUpdateTemplates.labels.message' })}
					</Typography>

					<Grid item style={{ marginTop: '17px' }}>
						<DefaultFormTextField
							variant="filled"
							name="body"
							fullWidth
							multiline={true}
							rows={15}
						/>
					</Grid>

					{
						versionId && (<div style={{ marginTop: '20px'}}>
							<Typography
								style={{ fontSize: '13px', lineHeight: '16px', letterSpacing: '2.08px', color: '#011020' }}>
								{intl.formatMessage({ id: 'app.patientUpdateTemplates.labels.defaultMessage' })}
							</Typography>

							<Grid item style={{ marginTop: '17px' }}>
								<DefaultFormTextField
									variant="filled"
									name="defaultBody"
									fullWidth
									multiline={true}
									disabled={true}
									rows={15}
								/>
							</Grid>
						</div>
						)
					}
				</Grid>
			</div>


		</form>
	)
}

const enhance = compose(
	reduxForm({
		form: formName,
		touchOnChange: true,
		touchOnBlur: true
	})
)

export default enhance(Form)
