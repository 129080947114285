import React, { useState, forwardRef, useRef, useImperativeHandle } from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import FormTextField from '../FormTextField'
import DefaultFormTextField from '../DefaultFormTextField'
import { makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { uuidv4 } from '../../utilities/utilities'
import { useIntl } from 'react-intl'
import { required } from '../../utilities/validators'
import { useStore } from 'react-redux'
import { formValueSelector } from 'redux-form'
const selector = formValueSelector('createQuestion')

const requiredValidator = required

export default forwardRef((props, ref) => {
	const store = useStore()

	useImperativeHandle(ref, () => ({
		getQuestion() {
			return {
				id: uuidv4().replace('-', ''),
				title: selector(store.getState(), 'question'),
				type: 'tf',
				instructions: selector(store.getState(), 'instructions'),
				tag: selector(store.getState(), 'tag')
			}
		}
	}))

	return (
		<Grid container direction="column" alignItems="flex-start">
			<Grid item style={{ width: '100%' }}>
				<DefaultFormTextField
					label="Tag"
					variant="filled"
					name="tag"
					fullWidth
					inputProps={{ maxLength: 254 }}
					maxLength={254}
				/>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				<DefaultFormTextField
					label="Question"
					variant="filled"
					name="question"
					validators={[ requiredValidator ]}
					fullWidth
					inputProps={{ maxLength: 1023 }}
					maxLength={1023}
				/>
			</Grid>

			<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
				<DefaultFormTextField
					label="Instructions"
					variant="filled"
					name="instructions"
					fullWidth
					inputProps={{ maxLength: 2047 }}
					maxLength={2047}
					multiline
					rows="3"
				/>
			</Grid>
		</Grid>
	)
})
