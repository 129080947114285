import * as api from '../../../apis/export'
import { setNewReportInitiated } from '../reports'

const PEP_ONBOARDING_EXPORT_REQUESTED = 'PEP_ONBOARDING_EXPORT_REQUESTED'
const PEP_ONBOARDING_EXPORT_SUCCESS = 'PEP_ONBOARDING_EXPORT_SUCCESS'
const PEP_ONBOARDING_EXPORT_FAILED = 'PEP_ONBOARDING_EXPORT_FAILED'

export const pepOnboardingExport = () => (dispatch, state) => {
	dispatch({ type: PEP_ONBOARDING_EXPORT_REQUESTED, data: {} })
	let currentState = state()
	return api.pepOnboardingExport({
		from: currentState.dashboard.onboardingPeriodFrom,
		to: currentState.dashboard.onboardingPeriodTo,
	}).then((response) => {
		if (response) {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'Success', isSuccess: true }
			})
		} else {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.exportFailed', isSuccess: false }
			})
		}
	})
}

const DATAPOINTS_COUNT_EXPORT_REQUESTED = 'DATAPOINTS_COUNT_EXPORT_REQUESTED'
const DATAPOINTS_COUNT_EXPORT_SUCCESS = 'DATAPOINTS_COUNT_EXPORT_SUCCESS'
const DATAPOINTS_COUNT_EXPORT_FAILED = 'DATAPOINTS_COUNT_EXPORT_FAILED'

export const datapointsCountExport = () => (dispatch, state) => {
	dispatch({ type: DATAPOINTS_COUNT_EXPORT_REQUESTED, data: {} })
	let currentState = state()
	return api.datapointsCountExport({
		startDate: currentState.dashboard.datapointsPeriodFrom,
		endDate: currentState.dashboard.datapointsPeriodTo,
	}).then((response) => {
		if (response) {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'Success', isSuccess: true }
			})
			dispatch(setNewReportInitiated())
		} else {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.exportFailed', isSuccess: false }
			})
		}
	})
}

const initial = {

}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		default:
			return { ...state }
	}
}



