import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useTheme from '@material-ui/core/styles/useTheme'
import { isSavingSelector, sponsorSelector } from '../../../redux/selectors'
import { connect } from 'react-redux'
import { change, formValueSelector, reduxForm } from 'redux-form'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import FormTextField from '../../../components/FormTextField'
import { required } from '../../../utilities/validators'
import { fetchSponsor, startSaving, editSponsor, addSponsor, clearSponsor } from '../../../redux/actions'
import CalmButton from '../../../components/CalmButton'
import { Switch } from '@material-ui/core'
import FormSelect from '../../../components/FormSelect'
import { useState } from 'react'
import FormDateField from '../../../components/FormDateField'

const requiredValidator = required
const selector = formValueSelector('modifySponsor')

const ModifySponsorModal = ({
	intl,
	handleSubmit,
	add,
	isSaving,
	start,
	id,
	mode,
	fetchSponsor,
	edit,
	clearSponsor,
	initialValues,
	isExecutiveUsage,
	changeExecutiveUsage,
	hasMinimumCommitmentPlan,
	changeHasMinimumCommitmentPlan,
	changeMinimumCommitmentStartDate,
	minimumCommitmentStartDate
}) => {
	const theme = useTheme()

	const MODE = {
		EDIT: 1,
		ADD: 2
	}

	const onSubmitForm = (values) => {
		start()
		if (MODE.ADD === mode) {
			add(values)
		} else {
			edit(id, values)
		}
	}

	useEffect(() => {
		if (id) {
			fetchSponsor(id)
		} else {
			clearSponsor()
		}
	}, [id])

	const handleExecutiveUsageChange = (e) => {
		changeExecutiveUsage(e.target.checked)
	}

	const handleHasMinimumCommitmentChange = (e) => {
		changeHasMinimumCommitmentPlan(e.target.checked)
		if(e.target.checked && !minimumCommitmentStartDate) {
			changeMinimumCommitmentStartDate(new Date())
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmitForm)}>
			<Grid
				container
				direction="column"
				justify="flex-start"
				alignItems="center"
				style={{ width: '75rem', padding: '4rem', margin: 'auto', backgroundColor: theme.palette.secondary.main }}
			>
				<Grid item style={{ alignSelf: 'center' }}>
					<Typography
						variant="h1"
						style={{
							color: '#fff',
							marginBottom: '25px',
						}}
					>
						{intl.formatMessage({ id: MODE.EDIT === mode ? 'app.sponsors.updateSponsorHeader' : 'app.sponsors.addSponsorHeader' })}
					</Typography>
				</Grid>
				<Grid item style={{ alignSelf: 'flex-start', width: '100%' }}>
					<FormTextField
						label={intl.formatMessage({ id: 'app.sponsors.sponsorNameLabel' }) + '*'}
						fullWidth
						type="text"
						name="name"
						maxLength={254}
						validators={[requiredValidator]}
						variant="filled"
					/>
				</Grid>
				<Grid item style={{ alignSelf: 'flex-start', width: '100%', minHeight: '80px' }}>
					<FormSelect
						data={[
							{ name: intl.formatMessage({ id: 'app.sponsors.stripeAccountMain' }), value: 1 },
							{ name: intl.formatMessage({ id: 'app.sponsors.stripeAccountEurope' }), value: 2 }
						]}
						style={{ color: 'white' }}
						label={intl.formatMessage({ id: 'app.sponsors.stripeAccountLabel' }) + '*'}
						fullWidth
						name="stripeAccount"
						validators={[requiredValidator]}
					/>
				</Grid>
				<Grid item style={{ alignSelf: 'flex-start', width: '100%' }}>
					<FormTextField
						label={intl.formatMessage({ id: 'app.sponsors.stripeCoustomerIdLabel' })}
						fullWidth
						type="text"
						name="stripeCustomerId"
						maxLength={254}
						variant="filled"
					/>
				</Grid>
				<Grid item style={{ alignSelf: 'flex-start', width: '100%' }}>
					<FormTextField
						label={intl.formatMessage({ id: 'app.sponsors.stripeSubscriptionIdLabel' })}
						fullWidth
						type="text"
						name="stripeSubscriptionId"
						maxLength={254}
						variant="filled"
					/>
				</Grid>
				<Grid item style={{ alignSelf: 'flex-start', width: '100%' }}>
					<Grid
						container
						alignItems='center'
					>
						<Grid item>
							<Typography
								variant="subtitle1"
								style={{
									color: '#fff'
								}}
								align="left"
							>
								{intl.formatMessage({ id: 'app.sponsors.enableMinimumCommitmentLabel' })}
							</Typography>
						</Grid>
						<Grid item>
							<Switch
								color='primary'
								checked={!!hasMinimumCommitmentPlan}
								onChange={handleHasMinimumCommitmentChange}
							/>
						</Grid>
					</Grid>
				</Grid>
				{hasMinimumCommitmentPlan &&
					<Grid item style={{ alignSelf: 'flex-start', width: '100%' }}>
						<FormDateField
                            label={intl.formatMessage({ id: 'app.sponsors.minimumCommitmentStartDateLabel' })}
                            fullWidth
                            type="text"
                            name="minimumCommitmentStartDate"
                            validators={[requiredValidator]}
                            variant="filled"
							design="dark"
                        />
					</Grid>
				}
				<Grid item style={{ alignSelf: 'flex-start', width: '100%' }}>
					<Grid
						container
						alignItems='center'
					>
						<Grid item>
							<Typography
								variant="subtitle1"
								style={{
									color: '#fff'
								}}
								align="left"
							>
								{intl.formatMessage({ id: 'app.sponsors.executiveUsageLabel' })}
							</Typography>
						</Grid>
						<Grid item>
							<Switch
								color='primary'
								checked={isExecutiveUsage ? true : false}
								onChange={handleExecutiveUsageChange}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<CalmButton
						style={{
							width: '267px',
							marginTop: '40px'
						}}
						disabled={isSaving}
						type="submit"
						variant="contained"
						color="primary"
					>
						{intl.formatMessage({ id: 'app.sponsors.submitButtonLabel' })}
					</CalmButton>
				</Grid>
			</Grid>
		</form>
	)
}

const mapStateToProps = (state, ownProps) => ({
	isSaving: isSavingSelector(state),
	initialValues: sponsorSelector(state),
	isExecutiveUsage: selector(state, 'isExecutiveUsage'),
	hasMinimumCommitmentPlan: selector(state, 'hasMinimumCommitmentPlan'),
	minimumCommitmentStartDate: selector(state, 'minimumCommitmentStartDate'),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchSponsor: (id) => dispatch(fetchSponsor(id)),
	add: (values) => dispatch(addSponsor(values)),
	edit: (id, values) => dispatch(editSponsor(id, values)),
	start: () => dispatch(startSaving()),
	clearSponsor: () => dispatch(clearSponsor()),
	changeExecutiveUsage: (value) => dispatch(change('modifySponsor', 'isExecutiveUsage', value)),
	changeHasMinimumCommitmentPlan: (value) => dispatch(change('modifySponsor', 'hasMinimumCommitmentPlan', value)),
	changeMinimumCommitmentStartDate: (value) => dispatch(change('modifySponsor', 'minimumCommitmentStartDate', value)),
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'modifySponsor',
		touchOnChange: true,
		touchOnBlur: true,
		enableReinitialize: true
	})
)

export default enhance(ModifySponsorModal)
