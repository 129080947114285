import React from "react";
import { TableRow } from "@material-ui/core";
import RelatedProtocolModificationTableCell from "./RelatedProtocolModificationTableCell";
import RelatedProtocolVersionTableCell from "./RelatedProtocolVersionTableCell";
import RelatedProtocolVisibleTableCell from "./RelatedProtocolVisibleTableCell";
import RelatedProtocolCircleTableCell from "./RelatedProtocolCircleTableCell";


const RelatedProtocolTableRow = ({
    protocol
}) => {
    return (
        <TableRow>
            <RelatedProtocolModificationTableCell protocol={ protocol } />
            <RelatedProtocolCircleTableCell protocol={ protocol } />
            <RelatedProtocolVersionTableCell protocol={ protocol } />
            <RelatedProtocolVisibleTableCell protocol={ protocol } />
        </TableRow>
    )
}

export default RelatedProtocolTableRow;
