import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import SubscriptionTableHeader from '../../../components/member/SubscriptionTableHeader'
import SubscriptionRow from './SubscriptionRow'


export default ({ data, user, circle }) => (
    <Table data-testid="subscription-table" style={{ width: 'calc(100% + 64px)', margin: '50px -32px 0 -32px' }}>
        <SubscriptionTableHeader />
        <TableBody>
            {data &&
            data
                .map((subscription) => <SubscriptionRow subscription={subscription} key={subscription.id} />)}
        </TableBody>
    </Table>
)
