import React, { Suspense } from 'react'
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { useLocation } from 'react-router'
import { compose } from "recompose"
import queryString from 'query-string'
import RelatedProtocols from './components/RelatedProtocols'
import { Grid, Typography } from '@material-ui/core'
import RelatedSurveys from './components/RelatedSurveys'
import RelatedBundles from './components/RelatedBundles'
import CompositeLabelPaper from '../../components/CompositeLabelPaper'


const renderEntities = (
    questionId,
    questionType,
    bundleId,
    surveyId,
    intl
) => {
    const qId = questionId ?? null
    const qType = questionType
    const bId = !!questionId ? null : bundleId ?? null
    const sId = !!questionId || !!bundleId ? null : surveyId ?? null

    return (
        <Grid
            container
            direction='column'
            spacing={5}
            style={{
                width: '100%',
                padding: '40px 3% 25px'
            }}
        >
            <Grid
                item
                xs={12}
            >
                <Typography variant="h1"
                    style={{
                        marginBottom: '27px'
                    }}
                >
                    { intl.formatMessage({ id: "app.relatedEntities.title" }) }
                </Typography>
            </Grid>

            <Grid
                item
                xs={12}
            >
                <CompositeLabelPaper
                    label={intl.formatMessage({ id: 'app.relatedEntities.relatedProtocolsTitle' })}
                    Component={RelatedProtocols}
                    questionId={qId}
                    questionType={qType}
                    bundleId={bId}
                    surveyId={sId}
                    style={{
                        paddingTop: '20px'
                    }}
            />
            </Grid>

            {
                (
                    !!qId
                    || !!bId
                )
                && <Grid
                    item
                    xs={12}
                >
                     <CompositeLabelPaper
                        label={intl.formatMessage({ id: 'app.relatedEntities.relatedSurveysTitle' })}
                        Component={RelatedSurveys}
                        questionId={qId}
                        questionType={qType}
                        bundleId={bId}
                        style={{
                            paddingTop: '20px'
                        }}
                    />
                </Grid>
            }

            {
                !!qId
                && <Grid
                    item
                    xs={12}
                >
                    <CompositeLabelPaper
                        label={intl.formatMessage({ id: 'app.relatedEntities.relatedBundlesTitle' })}
                        Component={RelatedBundles}
                        questionId={qId}
                        questionType={qType}
                        style={{
                            paddingTop: '20px'
                        }}
                   />
                </Grid>
            }
        </Grid>
    )
}

const RelatedEntities = ({
    intl
}) => {
	const location = useLocation()
    const questionId = parseInt(queryString.parse(location.search).questionId)
    const questionType = parseInt(queryString.parse(location.search).questionType)
    const bundleId = parseInt(queryString.parse(location.search).bundleId)
    const surveyId = parseInt(queryString.parse(location.search).surveyId)

    return (
        <div>
            <Suspense fallback={<div />}>
                <section>
                    {
                        renderEntities(questionId, questionType, bundleId, surveyId, intl)
                    }
                </section>
            </Suspense>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
})
const mapDispatchToProps = (dispatch, ownProps) => ({
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl
)

export default enhance(RelatedEntities)
