import React from "react";
import { useIntl } from "react-intl";
import { Grid, Paper } from "@material-ui/core";
import DetailsNavigatorRow from "./DetailsNavigatorRow";
import { useEffect } from "react";


const DetailsNavigator = ({
    selectedOptionId,
    onSelectCallback
}) => {
    const intl = useIntl()


    return (
        <Paper>
			<Grid container direction="column" style={{ width: '100%' }}>
                <Grid item>
					<Grid container direction="column">
                        <Grid
                            item
                        >
                            <DetailsNavigatorRow
                                label={ intl.formatMessage({ id: "app.sg.details.relatedEntitiesButton" }) }
                                optionId="relatedEntities"
                                selected={selectedOptionId == "relatedEntities"}
                                onSelectCallback={onSelectCallback}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default DetailsNavigator;
