import React, { useState, useEffect, Fragment } from 'react'
import IconButton from '@material-ui/core/IconButton'
import MuiMenu from '@material-ui/core/Menu'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { MenuItem } from '@material-ui/core'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'

const ITEM_HEIGHT = 48

const AnswerControlMenu = ({ intl, question, answer, archiveAnswer, getCurrentAnswerState }) => {
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	useEffect(() => { }, [])

	return (
		<div>
			<Fragment>
				<IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
					<MoreVertIcon style={{ color: 'black' }} />
				</IconButton>
				<MuiMenu
					id="long-menu"
					anchorEl={anchorEl}
					keepMounted
					open={open}
					onClose={handleClose}
					PaperProps={{
						style: {
							maxHeight: ITEM_HEIGHT * 4.5,
							width: 200
						}
					}}
				>
					<MenuItem
						onClick={() => {
							archiveAnswer(answer.baseId, question.baseId, question.questionType, answer.state)
							handleClose()
						}}
					>
						{
							(
								answer.state === 3
								&& !getCurrentAnswerState(answer.baseId, question.baseId, question.questionType)
							)
							|| getCurrentAnswerState(answer.baseId, question.baseId, question.questionType) === 3
								? intl.formatMessage({ id: 'app.survey.unarchiveMenuItemText' })
								: intl.formatMessage({ id: 'app.survey.archiveMenuItemText' })
						}
					</MenuItem>
					{/*
						answer.state !== 3
						&& getCurrentAnswerState(answer.baseId, question.baseId, question.questionType) !== 3
						&& <MenuItem
							onClick={() => {
								handleClose()
							}}
						>
							{ intl.formatMessage({ id: 'app.survey.conditionVisibilityMenuItemText' }) }
						</MenuItem>*/
					}
				</MuiMenu>
			</Fragment>
		</div>
	)
}

const enhance = compose(injectIntl)

export default enhance(AnswerControlMenu)
