import React, { useEffect, Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import { useTheme } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import { connect, useDispatch } from 'react-redux'
import { changeScoringGroupBundleState, fetchGroupBundles, showModal } from '../../../redux/actions'
import { scoringGroupBundlesSelector, showArchivedBundlesSelector } from '../../../redux/selectors'
import { useSelector, shallowEqual } from 'react-redux'
import { useIntl } from 'react-intl'
import Tag from '../../../components/survey/components/Tag'
import SearchQuestion from './SearchQuestion'
import Menu from './Menu'
import RemoveScoringGroupBundleModal from './RemoveScoringGroupBundleModal'
import { compose } from 'recompose'
import ChangeScoringGroupBundleStateModal from './ChangeScoringGroupBundleStateModal'
import { BUNDLE_STATE } from '../../../utilities/constants'


const Bundle = ({
	groupId,
	onAddTag,
	isArchivedBundlesVisible,
	removeBundle,
	changeBundleState
}) => {
	const theme = useTheme()
	const dispatch = useDispatch()
	const group = useSelector(scoringGroupBundlesSelector, shallowEqual)
	const intl = useIntl()


	useEffect(() => {
		dispatch(fetchGroupBundles(groupId))
	}, [])


	const onRemoveBundle = (bundleId) => {
		removeBundle(groupId, bundleId)
	}

	const onChangeBundleState = (bundle, newState) => {
		changeBundleState(groupId, bundle, newState, isArchivedBundlesVisible)
	}

	const bundleHeaderColor = (bundleState) => {
		return bundleState == BUNDLE_STATE.IN_USE ? '#C1CEE4' : 'grey'
	}

	const bundleContentColor = (bundleState) => {
		return bundleState == BUNDLE_STATE.IN_USE ? 'white' : 'lightgrey'
	}

	return (
		<Fragment>
			<SearchQuestion groupId={groupId}/>
			{group &&
				group.map((d) => {
					return (
						<Grid
							container
							direction="column"
							style={{
								marginTop: '34px',
								backgroundColor: bundleContentColor(d.state)
							}}
						>
							<Grid item>
								<div style={{ padding: '10px', backgroundColor: bundleHeaderColor(d.state) }}>
									<Grid
										container
										alignItems='center'
										justify='space-between'
										style={{
											width: "100%"
										}}
									>
										<Grid item>
											<Grid container>
												<Grid item>
													<GroupWorkIcon style={{ color: '#fff', paddingTop: '5px' }} />
												</Grid>
												<Grid item>
													<Typography
														style={{
															color: '#fff',
															marginLeft: '10px'
														}}
													>{d.bundleName}</Typography>
												</Grid>
											</Grid>
										</Grid>
										<Grid
											item
										>
											<Menu
												bundle={d}
												// onRemoveCallback={
												// 	() => onRemoveBundle(d.bundleId)
												// }
												onChangeStateCallback={onChangeBundleState}
												canSetNotInUseState={false}
											/>
										</Grid>
									</Grid>
								</div>
							</Grid>
							<Grid item>
								{d.questions &&
									d.questions.map((r, i) => {
										return (
											<Grid
												container
												alignItems="center"
												key={r.questionId}
												style={{
													padding: '10px ',
													borderLeft: 'solid 1px #C1CEE4',
													borderRight: 'solid 1px #C1CEE4',
													borderBottom: 'solid 1px #C1CEE4'
												}}
											>
												<Grid item style={{ flex: 1 }}>
													<Grid container direction="column" spacing={1}>
														<Grid item>
															<Tag tag={r.questionTag} />

															<Typography
																style={{
																	fontSize: '15px',
																	marginTop: '-10px'
																}}
															>
																{r.questionTitle}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item>
													<Button
														disabled={d.state == BUNDLE_STATE.ARCHIVED}
														disableRipple={true}
														disableFocusRipple={true}
                                                        onClick={() => onAddTag(r.questionTag)}
                                                        variant="text"
													>
															ADD
														<AddCircleOutlineIcon
															style={{
																fontSize: '24px',
																fontWeight: '400',
																marginLeft: '5px'
															}}
														/>
													</Button>
												</Grid>
											</Grid>
										)
									})}
							</Grid>
						</Grid>
					)
				})}
		</Fragment>
	)
}

const mapStateToProps = (state, ownProps) => ({
    isArchivedBundlesVisible: showArchivedBundlesSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	removeBundle: (scoringGroupId, bundleId) => dispatch(
		showModal(
			RemoveScoringGroupBundleModal,
			{
				scoringGroupId,
				bundleId
			}
		)
	),
	changeBundleState: (scoringGroupId, bundle, newState, isArchivedBundlesVisible) => {
		if (newState == BUNDLE_STATE.IN_USE) {
			dispatch(changeScoringGroupBundleState(scoringGroupId, bundle, newState, isArchivedBundlesVisible))
		}
		else if (newState == BUNDLE_STATE.ARCHIVED) {
			dispatch(
				showModal(
					ChangeScoringGroupBundleStateModal,
					{
						scoringGroupId,
						bundle,
						newState
					}
				)
			)
		}
	}
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(Bundle)
