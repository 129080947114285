import React, { useState, useEffect, Fragment, useRef } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { injectIntl } from 'react-intl'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import LabelIcon from '@material-ui/icons/Label'
import { useTheme } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { saveFormula, fetchFormula, setExtendedFormula, setExtendedMode, validateFormula, showModal, hideModal } from '../../../redux/actions'
import { formulaSelector, formulaLimitsSelector, isExtendedModeSelector, extendedFormulaSelector, isExtendedFormulaValidSelector } from '../../../redux/selectors'
import { mustBeLessThanValue } from '../../../utilities/validators'
import { useRouteMatch } from 'react-router-dom'
import { evaluate } from 'mathjs'
import { useSelector, shallowEqual } from 'react-redux'
import FormFilledNumberTextFieldLight from '../../../components/FormFilledNumberTextFieldLight'
import { useIntl } from 'react-intl'
import { TextField, Switch } from '@material-ui/core'
import EntityAuditCommentModal from "../../../components/entityAudit/EntityAuditCommentModal"
import { isAuditCommentsRequired } from '../../../utilities/auditUtils'

const lowerLessThanFenceValueValidator = mustBeLessThanValue('upperFence')
const lowerLessThanBoundsValueValidator = mustBeLessThanValue('upperBounds')
const Builder = ({
	id,
	expressionCollection,
	store,
	useField,
	reset,
	onExtendedFormulaSelectionChange,
	handleSubmit
}) => {
	const theme = useTheme()
	const dispatch = useDispatch()
	const match = useRouteMatch()
	const intl = useIntl()

	const formula = useSelector(formulaSelector, shallowEqual)
	const extendedFormula = useSelector(extendedFormulaSelector, shallowEqual)
	const extendedMode = useSelector(isExtendedModeSelector, shallowEqual)
	const extendedFormulaValid = useSelector(isExtendedFormulaValidSelector, shallowEqual)

	const [ isValid, setIsTestValid ] = useState(true)
	const [ currentExtendedFormula, setCurrentExtendedFormula ] = useState(null)
	const [extendedFormulaSelection, setExtendedFormulaSelection] = useState({
		start: 0,
		end: 0
	})

	const currentExtendedFormulaInput = useRef()


	const getExpressionFromFormula = (formula) => {
		return formula.map((element) => {
			if (element && (element.type === 'constant' || element.type === 'operator')) {
				return element.text
			}

			if (element && element.type === 'tag') {
				if (element.tag) {
					return 2
				}
			}
		}).join('')
	}

	const convertFormula = (formula) => {
		return formula.map((element) => {
			if (element && (element.type === 'constant' || element.type === 'operator')) {
				return element.text
			}

			if (element && element.type === 'tag') {
				return element.tag
			}
		}).join('')
	}

	const validateExpression = (formula) => {
		if (formula) {
			var expr = getExpressionFromFormula(formula)
			try {
				var s = evaluate(expr)

				setIsTestValid(true)
			} catch (error) {
				setIsTestValid(false)
			}
		}
	}

	useEffect(
		() => {
			dispatch(fetchFormula(id))
		},
		[ id ]
	)

	useEffect(
		() => {
			validateExpression(formula)
		},
		[ formula ]
	)

	useEffect(
		() => {
			if (!extendedMode) {
				validateExpression(formula)
			}
		},
		[ extendedMode ]
	)

	useEffect(
		() => {
			if (
				extendedFormula !== null
				&& extendedFormula !== currentExtendedFormula
			) {
				console.log("EXPRESSION BUILDER extendedFormula changed", currentExtendedFormula, currentExtendedFormula, )
				let selection = {...extendedFormulaSelection}
				let newLength = !!extendedFormula?.length ? extendedFormula.length : 0
				let currentLength = !!currentExtendedFormula?.length ? currentExtendedFormula.length : 0
				selection.end += (newLength - currentLength)
				setExtendedFormulaSelection(selection)
				if (!!onExtendedFormulaSelectionChange) {
					onExtendedFormulaSelectionChange(selection)
				}

				setCurrentExtendedFormula(extendedFormula)
				
				if (currentExtendedFormulaInput.current) {
					currentExtendedFormulaInput.current.focus()
				}

				dispatch(validateFormula(match.params.groupId, extendedFormula))
			}
		},
		[ extendedFormula ]
	)

	useEffect(
		() => {
			if (
				currentExtendedFormula !== null
				&& extendedFormula !== currentExtendedFormula
			) {
				console.log("EXPRESSION BUILDER currentExtendedFormula changed", currentExtendedFormula, currentExtendedFormula)
				dispatch(setExtendedFormula(currentExtendedFormula))
			}
			else if (!!currentExtendedFormulaInput.current?.setSelectionRange) {
				currentExtendedFormulaInput.current.setSelectionRange(extendedFormulaSelection.end, extendedFormulaSelection.end)
			}
		},
		[ currentExtendedFormula ]
	)

	useEffect(
		() => {
			if (
				extendedFormulaValid !== null
				&& extendedFormulaValid !== undefined
				&& extendedMode
			) {
				setIsTestValid(extendedFormulaValid)
			}
		},
		[ extendedFormulaValid, extendedMode ]
	)

	const renderExpression = () => {
		if (formula) {
			var result = formula.map((r) => {
				if (r && r.type === 'constant') {
					return (
						<Grid item style={{ marginTop: '-4px' }}>
							<Typography component="span" variant="subtitle1" style={{ marginRight: '5px' }}>
								{r.text}
							</Typography>
						</Grid>
					)
				}
				if (r && r.type === 'operator') {
					return (
						<Grid item>
							<Typography component="span" variant="subtitle2" style={{ marginRight: '5px' }}>
								{r.text}
							</Typography>
						</Grid>
					)
				}
				if (r && r.type === 'tag') {
					return (
						<Grid item>
							<Chip
								icon={<LabelIcon />}
								label={r.tag ? r.tag : 'ERROR'}
								style={{ marginRight: '5px', height: '18px', fontSize: '1.2rem' }}
							/>{' '}
						</Grid>
					)
				}
			})
			return result
		}
	}

	const handleChangeExtendedMode = () => {
		let mode = !extendedMode
		dispatch(setExtendedMode(mode))
		if (mode && !extendedFormula?.length) {
			let convertedFormula = convertFormula(formula)
			dispatch(setExtendedFormula(convertedFormula))
		}
	}

	const handleChangeCurrentExtendedFormula = (e) => {
		setCurrentExtendedFormula(e.target.value)
	}

	const handleBlurCurrentExtendedFormula = () => {
		dispatch(validateFormula(match.params.groupId, currentExtendedFormula))
	}

	const handleSelectCurrentExtendedFormula = () => {
		let selection = {
			start: currentExtendedFormulaInput.current.selectionStart,
			end: currentExtendedFormulaInput.current.selectionEnd
		}
		setExtendedFormulaSelection(selection)
		if (!!onExtendedFormulaSelectionChange) {
			onExtendedFormulaSelectionChange(selection)
		}
	}

	const onSubmitForm = (values) => {
		if (values) {
			if (isAuditCommentsRequired())
			{
				dispatch(
					showModal(
						EntityAuditCommentModal,
						{
							entityId: match.params.groupId,
							entityTypeId: 20, //scoring group
							saveCallback: (commentsObj) => {
								dispatch(hideModal())
								dispatch(saveFormula(
									match.params.groupId,
									parseFloat(values.upperFence),
									parseFloat(values.lowerFence),
									parseFloat(values.upperBounds),
									parseFloat(values.lowerBounds),
									commentsObj
								))
							}
						}
					)
				)
			}
			else 
			{
				dispatch(saveFormula(
					match.params.groupId,
					parseFloat(values.upperFence),
					parseFloat(values.lowerFence),
					parseFloat(values.upperBounds),
					parseFloat(values.lowerBounds)
				))
			}
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmitForm)}>
			<div>
				<Grid container direction="column" style={{ padding: '3%' }}>
					<Grid item>
						<Grid
							container
							direction="row"
							justify="space-between"
						>
							<Grid item>
								<Typography style={{ fontSize: '35px', lineHeight: '44px', letterSpacing: '0.25px' }}>
									{intl.formatMessage({ id: 'app.sg.formulaBuilderHeaderText' })}
								</Typography>
							</Grid>

							<Grid item>
								<Grid
									container
									direction="row"
									alignItems="center"
								>
									<Grid item>
										<Typography style={{ fontSize: '17px', color: 'black', fontWeight: '600' }}>
											{intl.formatMessage({ id: 'app.sg.extendedMode' })}
										</Typography>
									</Grid>
									<Grid item>
										<Switch
											checked={extendedMode}
											onChange={handleChangeExtendedMode}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Grid item style={{ flex: 0.7, marginTop: '19px' }}>
						{
							!extendedMode
							&& <Fragment>
								<Grid container>{renderExpression()}&nbsp;</Grid>
								<hr style={{ borderColor: isValid ? 'black' : 'red' }} />
							</Fragment>
						}
						{
							extendedMode
							&& <TextField
								fullWidth
								inputRef={currentExtendedFormulaInput}
								value={currentExtendedFormula}
								onChange={handleChangeCurrentExtendedFormula}
								onBlur={handleBlurCurrentExtendedFormula}
								onSelect={handleSelectCurrentExtendedFormula}
							/>
						}
						{!isValid && <div style={{ color: 'red' }}>Invalid expression</div>}
					</Grid>

					<Grid item>
						<Grid
							container
							justify="space-between"
							alignItems="center"
							style={{ width: '100%', marginTop: '34px' }}
						>
							<Grid item>
								<Grid container direction="column">
									<Grid item>
										{' '}
										<Typography
											style={{
												fontSize: '13px',
												lineHeight: '16px',
												letterSpacing: '2.08px',
												color: '#011020'
											}}
										>
											{intl.formatMessage({ id: 'app.sg.limitsText' })}
										</Typography>
									</Grid>
									<Grid item>
										<Grid container style={{ width: '100%', marginTop: '18px' }} direction="row">
											<Grid item style={{ flex: 0.5 }}>
												<FormFilledNumberTextFieldLight
													label={intl.formatMessage({ id: 'app.general.lowerText' })}
													name="lowerBounds"
													allowDecimal={true}
													validators={[ lowerLessThanBoundsValueValidator ]}
												/>
											</Grid>
											<Grid item style={{ flex: 0.5, marginLeft: '20px' }}>
												<FormFilledNumberTextFieldLight
													label={intl.formatMessage({ id: 'app.general.upperText' })}
													name="upperBounds"
													allowDecimal={true}
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid item>
								<Grid container direction="column">
									<Grid item>
										<Typography
											style={{
												fontSize: '13px',
												lineHeight: '16px',
												letterSpacing: '2.08px',
												color: '#011020'
											}}
										>
											{intl.formatMessage({ id: 'app.sg.fencesText' })}
										</Typography>
									</Grid>
									<Grid item>
										<Grid container style={{ width: '100%', marginTop: '18px' }} direction="row">
											<Grid item style={{ flex: 0.5 }}>
												<FormFilledNumberTextFieldLight
													label={intl.formatMessage({ id: 'app.general.lowerText' })}
													name="lowerFence"
													allowDecimal={true}
													validators={[ lowerLessThanFenceValueValidator ]}
												/>
											</Grid>
											<Grid item style={{ flex: 0.5, marginLeft: '20px' }}>
												<FormFilledNumberTextFieldLight
													label={intl.formatMessage({ id: 'app.general.upperText' })}
													allowDecimal={true}
													name="upperFence"
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid item>
								<Grid container direction="column">
									<Grid item>&nbsp;</Grid>
									<Grid item>
										<Button
                                            variant="contained"
                                            color="primary"
											disabled={
												(
													extendedMode
													&& !extendedFormulaValid
												)
												|| (
													!extendedMode
													&& !isValid
												)
											}
											type="submit"
										>
											SAVE
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
		</form>
	)
}

const mapStateToProps = (state, ownProps) => ({
	initialValues: formulaLimitsSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'expression',
		enableReinitialize: true,
		touchOnBlur: true,
		touchOnChange: true
	})
)

export default enhance(Builder)
