import React, { useRef, useEffect } from 'react'
import { compose } from 'recompose'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'
import { stopUpdatingQuestion, startSaving, stopSaving } from '../../redux/actions'
import { isSavingSelector } from '../../redux/selectors'
import { useSelector, shallowEqual } from 'react-redux'
import TextQuestionEditor from './TextQuestionEditor'
import FileQuestionEditor from './FileQuestionEditor'
import TFQuestionEditor from './TFQuestionEditor'
import NumberQuestionEditor from './NumberQuestionEditor'
import DateQuestionEditor from './DateQuestionEditor'
import RadioQuestionEditor from './RadioSelectEditor/SurveyQuestionTypeRadioSelectEditor'
import MultiQuestionEditor from './MultiSelectEditor/SurveyQuestionTypeMultiSelectEditor'
import GroupedQuestionEditor from './GroupedEditor/SurveyQuestionTypeGroupedEditor'
import SurveyContentTextEditor from './SurveyContentTextEditor'
import AnalogQuestionEditor from './AnalogQuestionEditor'
import { useDispatch } from 'react-redux'
import { reduxForm } from 'redux-form'
import { useTheme } from '@material-ui/core'

const renderEditor = (childRef, question, showScoreEntry, isBundle, isDialog, isOptionalDisabled = false) => {
	if (question && question.data) {

		isBundle = isBundle || question.data.isBundle;
		let isBundleQuestion = question.data.isBundle
		let isOptional = !isOptionalDisabled && !isBundleQuestion

		switch (question.data.questionType) {
			// case 4:
			// 	return <TFQuestionEditor ref={childRef} question={question} />
			// 	break
			case 5:
				return <TextQuestionEditor ref={childRef} question={question} isBundle={isBundle} dark={isDialog} canSetOptional={isOptional} />
				break
			case 6:
				return <NumberQuestionEditor ref={childRef} question={question} isBundle={isBundle} dark={isDialog} canSetOptional={isOptional} />
				break
			case 7:
				return <DateQuestionEditor ref={childRef} question={question} isBundle={isBundle} dark={isDialog} canSetOptional={isOptional} />
				break
			case 1:
				return <RadioQuestionEditor ref={childRef} question={question} isBundle={isBundle} showScoreEntry={showScoreEntry} dark={isDialog} canSetOptional={isOptional} />
				break
			case 2:
				return <MultiQuestionEditor ref={childRef} question={question} isBundle={isBundle} showScoreEntry={showScoreEntry} dark={isDialog} canSetOptional={isOptional} />
				break
			case 3:
				return <GroupedQuestionEditor ref={childRef} question={question} isBundle={isBundle} showScoreEntry={showScoreEntry} dark={isDialog} canSetOptional={isOptional} />
				break
			case 8:
				return <SurveyContentTextEditor ref={childRef} question={question} isBundle={isBundle} dark={isDialog} canSetOptional={isOptional} />
				break
			case 9:
				return <AnalogQuestionEditor ref={childRef} question={question} isBundle={isBundle} dark={isDialog} canSetOptional={isOptional} />
				break
			case 10: case 11:
				return <FileQuestionEditor ref={childRef} question={question} isBundle={isBundle} dark={isDialog} canSetOptional={isOptional} />
				break
		}
	}
}

const QuestionContainer = ({
	node,
	list,
	intl,
	question,
	onCancel,
	onAddCallback,
	onUpdateCallback,
	handleSubmit,
	showScoreEntry,
	isBundle,
	onCancelCloneCallback,
	isDialog = false,
	isOptionalDisabled = false
}) => {
	const childRef = useRef()
	const dispatch = useDispatch()
	const theme = useTheme()
	const isSaving = useSelector(isSavingSelector)
	const onSubmitForm = () => {
		if (childRef) {
			if (
				!!question.data.cloneId
				&& !!onAddCallback
			) {
				dispatch(startSaving())
				let questionData = childRef.current.getQuestion()
				questionData.baseId = question.data.baseId
				onAddCallback(questionData, question.data.sortOrder, true)
			}
			else if (onUpdateCallback) {
				dispatch(startSaving())
				let questionData = childRef.current.getQuestion()
				onUpdateCallback(questionData)
			}
		}
	}

	const onCancelSave = () => {
		dispatch(stopSaving())
		dispatch(stopUpdatingQuestion())

		if (
			!!question.data.cloneId
			&& !!onCancelCloneCallback
		) {
			onCancelCloneCallback(question.data.cloneId)
		}
	}

	const keyPressHandler = (e) => {
		if (e && e.keyCode === 27) {
			dispatch(stopSaving())
			dispatch(stopUpdatingQuestion())
		}
	}

	return (
		<div id="questionEditorPanel" style={{ border: 'none' }} onKeyDown={keyPressHandler}>
			<form onSubmit={handleSubmit(onSubmitForm)}>
				<Grid container direction="column" style={{ marginBottom: isDialog ? '0px' : '40px' }}>
					<Grid item style={{ padding: '3% 0', width: '100%' }}>
						<Grid container direction="row" style={{ width: '100%' }} justify="flex-start">
							<Grid item style={{ flex: 1 }}>
								{question && question.data && renderEditor(childRef, question, showScoreEntry, isBundle, isDialog, isOptionalDisabled)}
							</Grid>
						</Grid>
					</Grid>
					<Grid item style={{ padding: '20px 3%' }}>
						<Grid container spacing={2} style={{ justifyContent: 'center'}}>
							<Grid item>
								<Button
									variant="outlined"
									color="primary"
									onClick={onCancelSave}
								>
									{intl.formatMessage({ id: 'app.surveyBuilder.discardButtonText' })}
								</Button>
							</Grid>
							<Grid item>
								<Button
									variant="contained"
									color="primary"
									disabled={isSaving}
									type="submit"
								>
									{intl.formatMessage({ id: 'app.surveyBuilder.saveButtonText' })}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</div>
	)
}

const enhance = compose(
	reduxForm({
		form: 'editQuestion',
		touchOnChange: true,
		touchOnBlur: true
	}),
	injectIntl
)

export default enhance(QuestionContainer)
