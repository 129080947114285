import React, { Fragment } from 'react'
import { Snackbar, Grid, Typography, useTheme, Paper } from '@material-ui/core'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'


const RuleHelperSnackbar = ({
    open,
    style,
    intl
}) => {
    const theme = useTheme()
	const COMPONENT_BACKGROUND = theme.palette.grey[800]
    const CONTENT_BACKGROUND = theme.palette.secondary.main
    const EXAMPLE_BACKGROUND = theme.palette.grey[800]
  
    const entities = [
        {
            title: "Comparison operations:",
            columns: 2,
            examples: [
                {
                    parts: [
                        {
                            title: `is equals:`,
                            text: `==`
                        }
                    ]
                },
                {
                    parts: [
                        {
                            title: `is not equals:`,
                            text: `!=`
                        }
                    ]
                },
                {
                    parts: [
                        {
                            title: `is less than or equals:`,
                            text: `<=`
                        }
                    ]
                },
                {
                    parts: [
                        {
                            title: `is greater than or equals:`,
                            text: `>=`
                        }
                    ]
                },
                {
                    parts: [
                        {
                            title: `is less than:`,
                            text: `<`
                        }
                    ]
                },
                {
                    parts: [
                        {
                            title: `is greater than:`,
                            text: `>`
                        }
                    ]
                }
            ]
        },
        {
            title: "Logical operations:",
            examples: [
                {
                    parts: [
                        {
                            title: `and:`,
                            text: `&&`
                        }
                    ]
                },
                {
                    parts: [
                        {
                            title: `or:`,
                            text: `||`
                        }
                    ]
                }
            ]
        },
        {
            title: "Question will always get optional/required",
            examples: [
                {
                    parts: [
                        {
                            title: `Rule for optional:`,
                            text: `true`
                        }
                    ]
                },
                {
                    parts: [
                        {
                            title: `Rule for required:`,
                            text: `false`
                        }
                    ]
                }
            ]
        },
        {
            title: "Questions with categories and other questions with number values:",
            examples: [
                {
                    parts: [
                        {
                            title: `Rule:`,
                            text: `C_1_2==5`
                        }
                    ]
                },
                {
                    parts: [
                        {
                            title: `Rule for the values between 0 and 5 (null must be excluded):`,
                            text: `C_1_2!=null && C_1_2<=5`
                        }
                    ]
                },
                {
                    parts: [
                        {
                            title: `Rule for the values between 1 and 5:`,
                            text: `C_1_2>=1 && C_1_2<=5`
                        }
                    ]
                }
            ]
        },
        {
            title: "Checkable question rule examples:",
            examples: [
                {
                    parts: [
                        {
                            title: `Multiple checkboxes:<br />`,
                            text: `Q_1_1_2.includes(A_1_2) && Q_1_1_2.includes(A_1_3)`
                        },
                        {
                            title: `OR<br />`,
                            text: `Q_1_1_2.includes(A_1_2) && Q_1_1_2.includes(A_1_3) && Q_1_1_2.length==2`
                        }
                    ]
                },
                {
                    parts: [
                        {
                            title: `Radiobox:`,
                            text: `Q_1_2_2==A_1_2`
                        },
                        {
                            title: `Radiobox (not equal):`,
                            text: `Q_1_2_2!=A_1_2 && Q_1_2_2.length==1`
                        }
                ]
                },
            ]
        },
        {
            title: "Date questions:",
            examples: [
                {
                    parts: [
                        {
                            title: `rule (double quotes are required) - YYYY-MM-DD:`,
                            text: `Q_1_2_7=="2021-12-13"`
                        }
                    ]
                },
                {
                    parts: [
                        {
                            title: `answer to question (without double quotes) - MM/DD/YYYY:`,
                            text: `12/13/2021`
                        }
                    ]
                }
            ]
        },
        {
            title: "Text questions:",
            examples: [
                {
                    parts: [
                        {
                            title: `rule (double quotes are required):`,
                            text: `Q_1_2_5=="some text"`
                        }
                    ]
                },
                {
                    parts: [
                        {
                            title: `answer to question (without double quotes):`,
                            text: `some text`
                        }
                    ]
                }
            ]
        },
        {
            title: "Number questions:",
            examples: [
                {
                    parts: [
                        {
                            title: `rule (without double quotes):`,
                            text: `Q_1_2_6>=8`
                        }
                    ]
                },
                {
                    parts: [
                        {
                            title: `answer to question (without double quotes):`,
                            text: `9`
                        }
                    ]
                }
            ]
        }
    ]

    return (
        <Snackbar
            open={open}
            style={{
                ...style
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
        >
            <Paper
                elevation={3}
                style={{
                    width: '38vw',
                    padding: '1rem',
                    backgroundColor: COMPONENT_BACKGROUND
                }}
            >
                <Paper
                    style={{
                        padding: '3rem 0 3rem 3rem',
                        backgroundColor: CONTENT_BACKGROUND
                    }}
                >
                    <Grid
                        item
                        style={{
                            width: '100%',
                            textAlign: 'center'
                        }}
                    >
                        <Typography
                            variant="h1"
                            style={{
                                color: 'white',
                                marginBottom: '20px'
                            }}
                        >
                            {intl.formatMessage({ id: 'app.survey.ruleHelper.title' })}
                        </Typography>
                    </Grid>
                    <div
                        style={{
                            height: '70vh',
                            overflowY: 'auto'
                        }}
                    >
                        {
                            entities.map((entity, index) => {
                                return (
                                    <Grid
                                        container
                                        direction="column"
                                        key={`rule_entity_${index}`}
                                    >
                                        <Typography
                                            variant="h6"
                                            style={{
                                                color: 'white',
                                                letterSpacing: '2.08px',
                                                fontWeight: '100'
                                            }}
                                        >
                                            {
                                                entity.title
                                            }
                                        </Typography>
                                        {
                                            entity.examples.length > 0
                                            && <ul
                                                style={{
                                                    marginTop: '3px'
                                                }}
                                            >
                                                <Grid 
                                                    container
                                                    direction="row"
                                                >
                                                {
                                                    entity.examples.map((example) => {
                                                        return (
                                                            <Grid
                                                                item
                                                                xs={ !!entity.columns ? 12 / entity.columns : 12 }
                                                            >
                                                            <Typography
                                                                variant="body2"
                                                                style={{
                                                                    color: 'white',
                                                                    letterSpacing: '2.08px',
                                                                    fontWeight: '400',
                                                                    padding: '4px 0'
                                                                }}
                                                            >
                                                                <li>
                                                                    {
                                                                        !!example.parts
                                                                        && example.parts.map((part) => {
                                                                            return (
                                                                                <Grid>
                                                                                    <span
                                                                                        dangerouslySetInnerHTML={{ __html: part.title }}
                                                                                        style={{
                                                                                            paddingRight: '5px'
                                                                                        }}
                                                                                    />
                                                                                    <span
                                                                                        style={{
                                                                                            padding: '2px',
                                                                                            backgroundColor: EXAMPLE_BACKGROUND
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            part.text
                                                                                        }
                                                                                    </span>
                                                                                </Grid>
                                                                            )
                                                                        })
                                                                    }
                                                                </li>
                                                            </Typography>
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                                </Grid>
                                            </ul>
                                        }
                                    </Grid>
                                )
                            })
                        }
                    </div>
                </Paper>
            </Paper>
        </Snackbar>
    )
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({})

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)

export default enhance(RuleHelperSnackbar)
