import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import DefaultFormTextField from '../../DefaultFormTextField'
import DefaultFormNumberField from '../../DefaultFormNumberField'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import { required } from '../../../utilities/validators'
import { uuidv4 } from '../../../utilities/utilities'
import { useIntl } from 'react-intl'
const requiredValidator = required

export default forwardRef((props, ref) => {
	const { onDeleteCallback, answer, showScoreEntry, showCode } = props
	const [ internalAnswer, setAnswer ] = useState(null)
	const [ value, setValue ] = useState(null)
	const [ code, setCode ] = useState(null)
	const [ id, setId ] = useState(uuidv4().replace('-', ''))
	const [ scoreId, setScoreId ] = useState(uuidv4().replace('-', ''))
	const [ codeId, setCodeId ] = useState(uuidv4().replace('-', ''))

	const intl = useIntl()

	useImperativeHandle(ref, () => ({
		getAnswer() {
			let a = []

			return { answer: internalAnswer, value: showScoreEntry ? value : -1, code: showCode ? code : null }
		}
	}))

	useEffect(
		() => {
			if (answer) {
				answer.data.ref = ref
			}
		},
		[ answer, ref ]
	)

	return (
		<Grid container style={{ width: '100%' }} alignItems="center">
			<Grid item style={{ marginLeft: '-40px' }}>
				<IconButton onClick={() => onDeleteCallback(answer)}>
					<DeleteIcon />
				</IconButton>
			</Grid>
			<Grid item style={{ flex: 1, paddingRight: '5px' }}>
				<DefaultFormTextField
					label="Answer"
					validators={[ requiredValidator ]}
					name={id}
					variant="filled"
					onChange={(e) => setAnswer(e.target.value)}
				/>
			</Grid>
			{showScoreEntry && (
				<Grid item style={{ paddingLeft: '5px' }}>
					<DefaultFormNumberField
						label="Score"
						name={scoreId}
						variant="filled"
						allowDecimal={true}
						validators={[ requiredValidator ]}
						onChange={(e) => setValue(e.target.value)}
					/>
				</Grid>
			)}
			{showCode &&
				<Grid item style={{ paddingLeft: '5px' }}>
					<DefaultFormTextField
						label={intl.formatMessage({ id: 'app.survey.questions.answerCodeLabel' })}
						validators={[requiredValidator]}
						name={codeId}
						variant="filled"
						onChange={(e) => setCode(e.target.value)}
					/>
				</Grid>
			}
		</Grid>
	)
})
