import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Tag from './Tag'
import QuestionContainer from './QuestionContainer'
import RequiresTranslationBadge from '../../RequiresTranslationBadge'
export default ({ question, surveyEntity, position, positionDisplay }) => {
	return (
			<Grid data-testid="text-question-survey-component" direction='column' container>
				<Grid item>
					<Tag tag={question.tag} />
				</Grid>
				<Grid item>
					<QuestionContainer
						question={question}
						surveyEntity={surveyEntity}
						children={
							<TextField
								style={{ color: 'black' }}
								variant='filled'
								InputProps={{
									readOnly: true
								}}
								fullWidth
							/>
						}
					/>
				</Grid>
				{question &&
				!question.id && (
					<Grid item style={{ marginTop: '10px' }}>
						<RequiresTranslationBadge />
					</Grid>
				)}
			</Grid>
	)
}
