import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useTheme from '@material-ui/core/styles/useTheme'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import Button from '@material-ui/core/Button'
import { deleteBundle, deleteUserMember, fetchBundle, hideModal } from '../../../redux/actions'
import { bundleSelector } from '../../../redux/selectors'
import { useDispatch } from 'react-redux'
import LocalizedDatePicker from '../../../components/LocalizedDatePicker'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'

const RemoveTeamMemberModal = ({ intl, id, currentUserId, invitationId, removeMember }) => {
	const theme = useTheme()
	const [ enableSchedule, setEnableSchedule] = useState(false)
	const [ removalDate, setRemovalDate ] = useState(new Date(new Date().setDate(new Date().getDate() + 1)))

	const handleSubmit = () => {
		removeMember(enableSchedule, removalDate)
	}

	return (
		<Grid
			container
			direction="column"
			justify="flex-start"
			alignItems="center"
			style={{ width: '75rem', padding: '4rem', margin: 'auto', backgroundColor: theme.palette.secondary.main }}
		>
			<Grid item style={{ alignSelf: 'center' }}>
				<Typography
					variant="h1"
					style={{
						color: '#fff',
						marginBottom: '25px',
					}}
				>
					{intl.formatMessage({ id: 'app.userProfile.removeTeamMemberModalHeader' })}
				</Typography>
			</Grid>
			<Grid item style={{ marginTop: '10px', width: '100%', }}>
						<Typography
							variant="body1"
							style={{ color: 'white', paddingBottom: '10px' }}
						>
							{intl.formatMessage({ id: 'app.userProfile.removeTeamMemberModalRadioHeader' })}
						</Typography>
						<RadioGroup>
							<Grid
								container
								spacing={1}
								direction='column'
								style={{ width: '100%' }}
							>
								<Grid item xs="auto">
									<FormControlLabel
										label={intl.formatMessage({ id: 'app.userProfile.removeTeamMemberModalImmediateLabel' })}
										control={
											<Radio
												color="secondary"
												onChange={() => setEnableSchedule(false)}
												checked={!enableSchedule}
											/>
										}
									/>
								</Grid>
								<Grid item xs="auto">
									<FormControlLabel
										label={intl.formatMessage({ id: 'app.userProfile.removeTeamMemberModalScheduleLabel' })}
										control={
											<Radio
												color="secondary"
												onChange={() => setEnableSchedule(true)}
												checked={enableSchedule}
											/>
										}
									/>
								</Grid>
							</Grid>
						</RadioGroup>
						{enableSchedule &&
							<div style={{ paddingTop: '10px' }}>
								<LocalizedDatePicker
                            onDateChange={setRemovalDate}
                            minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                            defaultDate={removalDate}
                            design = "dark"
                        />
							</div>
						}
					</Grid>
			<Grid item>
				<Button
					variant="contained"
					color="primary"
					label={intl.formatMessage({ id: 'app.general.remove' })}
					style={{
						width: '267px',
						marginTop: '40px'
					}}
					onClick={handleSubmit}
				>
					{intl.formatMessage({ id: 'app.general.remove' })}
				</Button>
			</Grid>
		</Grid>
	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
	removeMember: (enableSchedule, removalDate) => dispatch(deleteUserMember(ownProps.id, ownProps.invitationId, ownProps.currentUserId, enableSchedule, removalDate))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(RemoveTeamMemberModal)
