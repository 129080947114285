import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { useTheme } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Tag from './Tag'
import QuestionContainer from './QuestionContainer'
import RequiresTranslationBadge from '../../RequiresTranslationBadge'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Menu from '../../../pages/surveyViewer/components/AnswerControlMenu'
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon  from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import IdContainer from './IdContainer'
import { useRouteMatch } from 'react-router'
import { answerCode, questionCode } from '../../../utilities/utilities'

const MultiSelectQuestion = ({ intl, question, surveyEntity, position, positionDisplay, showScoreEntry, handleAnswerStateChange, getCurrentAnswerState, getBackgroundColor, defaultBackgroundColor, archiveAnswer, showState }) => {
	const match = useRouteMatch()
	const theme = useTheme()
	const [left, setLeft] = useState([])
	const [right, setRight] = useState([])


	useEffect(
		() => {
			if (question && question.answers) {
				let a1 = []
				let a2 = []
				question.answers.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1)).map((r, i) => {
					r.sortOrder++

					if (r.sortOrder === 1) {
						a1.push(r)
					} else if (r.sortOrder % 2 === 0) {
						a2.push(r)
					} else {
						a1.push(r)
					}
				})
				setLeft(a1)
				setRight(a2)
			}
		},
		[question]
	)
	return (
			<Grid container direction="column">
				<Grid item>
					<Tag tag={question.tag} />
				</Grid>
				<Grid item>
					<QuestionContainer
						question={question}
						surveyEntity={surveyEntity}
						children={
							<Grid className="admin-question-container-50" container direction="column" justify="space-between">
								{question &&
									question.answers &&
									question.answers
										.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
										.map((r, i) => {
											return (
												<Grid
													data-testid="multi-select-question-item"
													container 
													direction="row"
													style={{ 
														backgroundColor: showState 
															? getBackgroundColor(getCurrentAnswerState(r.baseId, question.baseId, question.questionType) || r.state) 
															: defaultBackgroundColor 
													}}
												>
													{showState && <Grid container style={{ flex: 0.05, minWidth: '33px', height: '39px'}}>
														{(r.state < 3 && !getCurrentAnswerState(r.baseId, question.baseId, question.questionType) || getCurrentAnswerState(r.baseId, question.baseId, question.questionType) < 3) &&
															<Checkbox
																icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
																checkedIcon={<CheckBoxIcon fontSize="large" />}
																indeterminateIcon={<IndeterminateCheckBoxIcon fontSize="large" />}
																indeterminate={r.state === 0 && !getCurrentAnswerState(r.baseId, question.baseId, question.questionType)}
																checked={r.state === 1 && !getCurrentAnswerState(r.baseId, question.baseId, question.questionType) || getCurrentAnswerState(r.baseId, question.baseId, question.questionType) === 1}
																color="primary"
																onChange={event => handleAnswerStateChange(r.baseId, question.baseId, question.questionType, event.target.checked)}
																data-testid="checkbox-survey-multiselect"/>}
														{(r.state === 3 && !getCurrentAnswerState(r.baseId, question.baseId, question.questionType) || getCurrentAnswerState(r.baseId, question.baseId, question.questionType) === 3) &&

															<HighlightOffIcon
																fontSize='large'  />}
													</Grid>}
													<Grid container style={{ flex: 0.9 }}>
														<FormControl
														 	className="form-control"
															component="fieldset"
														>
															<FormGroup>
																<FormControlLabel
																	control={
																		<Checkbox 
																			color="primary"
																			disabled value={r.value} /> //onChange={handleChange('gilad')}
																	}
																	label={
																		<Typography
																			variant="subtitle2"
																			style={{ fontSize: '17px', color: 'black' }}
																		>
																			{showScoreEntry ? (
																				`${r.label} ( ${r.value} )`
																			) : (
																					`${r.label}`
																				)}
																			{showState && !question.isBundle && ` (${r.casesCount})`}
																		</Typography>
																	}
																/>
															</FormGroup>
														</FormControl>
													</Grid>
													{
														!!match.params.instanceId
														&& <Grid
															container
															style={{
																alignItems: 'right',
																flex: 0.1
															}}
														>
															<IdContainer
																label={intl.formatMessage({ id: 'app.survey.answerIdLabel' }).replace('%%Id%%', `${question.baseId}_${r.baseId}`)}
																clipboardText={
																	`${questionCode(match.params.instanceId, question.baseId, question.questionType)}.includes(${answerCode(question.baseId, r.baseId)})`
																}
															/>
														</Grid>
													}
													<Grid container style={{ alignItems: 'flex-start', flex: 0.05 }}>
														{
															showState
															&& <Menu
																question={question}
																answer={r}
																archiveAnswer={archiveAnswer}
																getCurrentAnswerState={getCurrentAnswerState}
															/>
														}
													</Grid>
												</Grid>
											)
										})}

							</Grid>
						}
					/>
				</Grid>{' '}
				{question &&
					!question.id && (
						<Grid item style={{ marginTop: '10px' }}>
							<RequiresTranslationBadge />
						</Grid>
					)}
			</Grid>
	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(MultiSelectQuestion)
