import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
	inputRoot: {
		color: 'black'
	},
	inputFocused: {
		color: 'black'
	},
	inputLabel: {
		marginLeft: '0px',
		color: 'black'
	},
	selectMenu: {
		color: 'black',
		height: '20px'
	},
	selectIcon: {
		fontSize: '24px'
	}
})

export default ({
	label,
	initialValue,
	onChangeCallback,
	allowDecimal,
	data,
	type,
	maxLength,
	min,
	max,
	pattern,
	placeholder
}) => {
	var classes = useStyles()
	const [ value, setValue ] = useState(initialValue)

	const onChange = (e) => {
		let reg = new RegExp(pattern, 'g')
		var value = e.target.value
		var isValid = reg.test(value)

		if (isValid) {
			setValue(e.target.value)
			onChangeCallback && onChangeCallback(e.target.value)
		} else {
		}
	}

	const onInput = (e) => {
		if (e && e.target && e.target.value) {
			if (isNaN(e.target.value)) {
				e.target.value = ''
			}
		}
	}

	useEffect(
		() => {
			setValue(initialValue)
		},
		[ initialValue ]
	)

	const onKeyPress = (evt) => {
		var charCode = evt.which ? evt.which : evt.keyCode

		if (charCode < 48 || charCode > 57) {
			if (allowDecimal && charCode === 46) {
				let temp = evt.target.value + String.fromCharCode(charCode)

				var count = (temp.match(/\./g) || []).length
				if (count <= 1) {
					return true
				}
			}
			evt.preventDefault()
			return false
		} else if (charCode === 46 && evt.target.value && evt.target.value.split('.').length > 1) {
			evt.preventDefault()
			return false
		}

		return true
	}

	return (
		<TextField
			label={label}
			onKeyPress={onKeyPress}
			variant="filled"
			fullWidth
			shrink
			value={value}
			placeholder={placeholder}
			onChange={onChange}
			onInput={onInput}
			InputLabelProps={{
				classes: {
					root: classes.inputLabel,
					focused: classes.inputLabel
				}
			}}
			InputProps={{
				classes: {
					root: classes.inputRoot,
					focused: classes.focused,
					input: classes.focused
				}
			}}
			inputProps={{
				style: { color: 'black' },
				maxLength: maxLength,
				min: min,
				max: max,
				pattern: pattern
			}}
		/>
	)
}
