import { combineReducers } from 'redux'
import { reducer as authReducer } from './modules/auth/index'
import { reducer as coreReducer } from './modules/core/index'
import { reducer as promReducer } from './modules/prom/index'
import { reducer as pathologyReducer } from './modules/pathology/index'
import { reducer as userReducer } from './modules/user/index'
import { reducer as countryReducer } from './modules/country/index'
import { reducer as circleReducer } from './modules/circle/index'
import { reducer as serviceProviderReducer } from './modules/serviceProvider/index'
import { reducer as statesReducer } from './modules/state/index'
import { reducer as priceReducer } from './modules/prices/index'
import { reducer as subscriptionReducer } from './modules/subscriptions/index'
import { reducer as protocolReducer } from './modules/observationalProtocol/index'
import { reducer as languageReducer } from './modules/language'
import { reducer as timeZoneReducer } from './modules/timeZone'
import { reducer as adminReducer } from './modules/adminUsers'
import { reducer as surveyReducer } from './modules/surveys'
import { reducer as surveyTranslatorReducer } from './modules/surveyTranslator'
import { reducer as surveyBuilderReducer } from './modules/surveyBuilder'
import { reducer as questionReducer } from './modules/question'
import { reducer as configurationsReducer } from './modules/configuration'
import { reducer as patientsReducer } from './modules/patients'
import { reducer as subscribersReducer } from './modules/subscribers'
import { reducer as surveyViewerReducer } from './modules/surveyViewer'
import { reducer as unitsReducer } from './modules/units'
import { reducer as paymentReducer } from './modules/payment'
import { reducer as globalSearchReducer } from './modules/globalSearch'
import { reducer as bundleReducer } from './modules/bundle'
import { reducer as bundleBuilderReducer } from './modules/bundleBuilder'
import { reducer as questionEditorReducer } from './modules/questionEditor'
import { reducer as statisticsReducer } from './modules/statistics'
import { reducer as treatmentsReducer } from './modules/treatment'
import { reducer as scoringGroupReducer } from './modules/scoringGroup'
import { reducer as termReducer } from './modules/terms'
import { reducer as lambdaOperationsReducer } from './modules/lambdaOperations'
import { reducer as jurisdictionReducer } from './modules/jurisdiction'
import { reducer as emailTemplatesReducer } from './modules/emailTemplates'
import { reducer as sponsorsReducer } from './modules/sponsors'
import { reducer as reportsReducer } from './modules/reports'
import { reducer as chartReducer } from './modules/chart'
import { reducer as importsReducer } from './modules/imports'
import { reducer as dashboardReducer } from './modules/dashboard'
import { reducer as exportReducer } from './modules/export'

import { reducer as form } from 'redux-form'

export default combineReducers({
	auth: authReducer,
	form: form,
	core: coreReducer,
	prom: promReducer,
	pathology: pathologyReducer,
	user: userReducer,
	country: countryReducer,
	circle: circleReducer,
	serviceProvider: serviceProviderReducer,
	states: statesReducer,
	price: priceReducer,
	subscription: subscriptionReducer,
	protocol: protocolReducer,
	admin: adminReducer,
	language: languageReducer,
	survey: surveyReducer,
	translator: surveyTranslatorReducer,
	builder: surveyBuilderReducer,
	question: questionReducer,
	configuration: configurationsReducer,
	patient: patientsReducer,
	subscriber: subscribersReducer,
	readonlySurvey: surveyViewerReducer,
	units: unitsReducer,
	payment: paymentReducer,
	search: globalSearchReducer,
	score: bundleReducer,
	bundleBuilder: bundleBuilderReducer,
	questionEditor: questionEditorReducer,
	statistics: statisticsReducer,
	treatment: treatmentsReducer,
	scoringGroup: scoringGroupReducer,
	term: termReducer,
	lambdaOperations: lambdaOperationsReducer,
	jurisdiction: jurisdictionReducer,
	emailTemplates: emailTemplatesReducer,
	timeZone: timeZoneReducer,
	sponsors: sponsorsReducer,
	reports: reportsReducer,
	chart: chartReducer,
	imports: importsReducer,
	dashboard: dashboardReducer
})
