import React, { useState, useEffect, Fragment } from 'react'
import IconButton from '@material-ui/core/IconButton'
import MuiMenu from '@material-ui/core/Menu'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import ManageSubscriptionMenuItem from './ManageSubscriptionMenuItem'
import ManageSubscriptionCreditsMenuItem from './ManageSubscriptionCreditsMenuItem'
import ExportStatisticsMenuItem from './ExportStatisticsMenuItem'
import ResendInvitationMenuItem from './ResendInvitationMenuItem'
import ChangeApiStatusMenuItem from './ChangeApiStatusMenuItem'
import ExpireSubscriptionMenuItem from './ExpireSubscriptionMenuItem'
const ITEM_HEIGHT = 48

export default ({ intl, subscriber }) => {
	const [ anchorEl, setAnchorEl ] = useState(null)
	const open = Boolean(anchorEl)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	useEffect(() => {}, [])

	return (
		<div>
			<Fragment>
				<IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
					<MoreVertIcon />
				</IconButton>
				<MuiMenu
					id="long-menu"
					anchorEl={anchorEl}
					keepMounted
					open={open}
					onClose={handleClose}
					PaperProps={{
						style: {
							maxHeight: ITEM_HEIGHT * 4.5,
							width: 200,
							backgroundColor: '#243041',
							color: 'white',
							fontWeight: '400'
						}
					}}
				>
					{subscriber && (subscriber.lastLogin || !subscriber.sponsorName) && <ManageSubscriptionMenuItem subscriber={subscriber} onComplete={handleClose} />}
					{/* {subscriber && subscriber.lastLogin && <ExportStatisticsMenuItem subscriber={subscriber} onComplete={handleClose} />} */}
					{subscriber && !subscriber.lastLogin && <ResendInvitationMenuItem subscriber={subscriber} onComplete={handleClose} />}
					{subscriber && subscriber.lastLogin && <ChangeApiStatusMenuItem subscriber={subscriber} onComplete={handleClose} />}
					{subscriber&& !subscriber.lastLogin && subscriber.expires && new Date().getTime() <= new Date(subscriber.expires).getTime() && <ExpireSubscriptionMenuItem subscriber={subscriber} onComplete={handleClose} />}
					{/* <ManageSubscriptionCreditsMenuItem subscriber={subscriber} onComplete={handleClose} /> */}
				</MuiMenu>
			</Fragment>
		</div>
	)
}
