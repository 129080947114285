import React from "react"
import { useIntl } from "react-intl"
import EntityAuditTableCell from "./EntityAuditTableCell"
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import history from '../../utilities/history'
import { Link } from 'react-router-dom'
import ShowMoreText from "react-show-more-text";


const gotoProtocol = (protocolId, protocolVersionId) => {
    //history.push(`/protocols/${protocolId}/version/${protocolVersionId}/details`)
}

const EntityAuditCommentsTableCell = ({entityLog, ...other}) => {
    const intl = useIntl()

    return (
        <EntityAuditTableCell
            {...other}
        >
            <Grid container direction="column" style={{ minWidth: '200px' }}>
            {
                entityLog.commentProtocolVersionId &&
                <Grid item style={{ maxWidth: '600px' }}>
                    <Typography style={{ fontWeight: '700' }}>
                        { intl.formatMessage({ id: `app.auditTable.commentFieldLabel.protocol` }) }: 
                    </Typography>
                    <Link
							to={`/protocols/${entityLog.commentProtocolId}/version/${entityLog.commentProtocolVersionId}/details`}
                            target="_blank"
							style={{
								textDecoration: 'none',
								fontSize: '17px',
								lineHeight: '24px',
								letterSpacing: '0.16px',
								pointerEvents: entityLog.commentProtocolVersionId > 1 ? 'auto' : 'none'
							}}
						>
                    <Typography
                        style={{
                            textAlign: 'left',
                            wordBreak: 'break-word',
                            lineHeight: '24px',
                            letterSpacing: '0.16px',
                        }}                        
                    >
                        { entityLog.commentProtocolVersionName &&  entityLog.commentProtocolVersionName > 200 ? (
                            entityLog.commentProtocolVersionName.substring(0, 200) + '...'
                        ) : (
                            entityLog.commentProtocolVersionName
                        )}
                    </Typography>
                    </Link>
                </Grid>
            }
            {
                entityLog.commentInvestigatorId &&
                <Grid item style={{ maxWidth: '600px' }}>
                    <Typography style={{ fontWeight: '700' }}>
                        { intl.formatMessage({ id: `app.auditTable.commentFieldLabel.investigator` }) }: 
                    </Typography>
                    <Link
							to={entityLog.commentInvestigatorId > 1 ? `/userprofile/${entityLog.commentInvestigatorId}` : '/subscribers'}
                            target="_blank"
							style={{
								textDecoration: 'none',
								fontSize: '17px',
								lineHeight: '24px',
								letterSpacing: '0.16px',
								pointerEvents: entityLog.commentInvestigatorId > 1 ? 'auto' : 'none'
							}}
						>
                                {entityLog.commentInvestigatorName &&
                                <Typography style={{ textAlign: 'left', wordBreak: 'break-word' }}>
                                    { entityLog.commentInvestigatorName && entityLog.commentInvestigatorName.length > 200 ? (
                                        entityLog.commentInvestigatorName.substring(0, 200) + '...'
                                    ) : (
                                        entityLog.commentInvestigatorName
                                    )}
                                </Typography>}
                            <Typography style={{ textAlign: 'left', wordBreak: 'break-word', color: '#011020'}}>
								{ entityLog.commentInvestigatorEmail && entityLog.commentInvestigatorEmail.length > 200 ? (
									entityLog.commentInvestigatorEmail.substring(0, 200) + '...'
								) : (
									entityLog.commentInvestigatorEmail
								)}
							</Typography>
					</Link>
                </Grid>
            }
            {
                entityLog.commentRelatedTaskUrl &&
                <Grid item style={{ maxWidth: '600px' }}>
                    <Typography>
                        <Link
                            to={ { pathname: entityLog.commentRelatedTaskUrl } }
                            target="_blank"
                            style={{
                                textDecoration: 'none',
                                fontSize: '17px',
                                lineHeight: '24px',
                                letterSpacing: '0.16px',
                                pointerEvents: entityLog.commentRelatedTaskUrl ? 'auto' : 'none'
                            }}
                        >
                            <Typography style={{ textAlign: 'left', wordBreak: 'break-word', fontWeight: '700'  }}>
                                { intl.formatMessage({ id: `app.auditTable.commentFieldLabel.relatedTask` }) }
                            </Typography>
                        </Link>
                    </Typography>
                </Grid>
            }
            {
                entityLog.commentText &&
                <Grid item style={{ maxWidth: '600px' }}>
                    <Typography style={{ fontWeight: '700' }}>
                        { intl.formatMessage({ id: `app.auditTable.commentFieldLabel.comment` }) }: 
                    </Typography>
                    <ShowMoreText
                        lines={3}
                        more={ intl.formatMessage({ id: `app.auditTable.showMore` }) }
                        less={ intl.formatMessage({ id: `app.auditTable.showLess` }) }
                        expanded={false}
                        truncatedEndingComponent={"... "}
                    >
                        { entityLog.commentText }
                    </ShowMoreText>            
                </Grid>
            }
            </Grid>
        </EntityAuditTableCell>
    )
}

export default EntityAuditCommentsTableCell