import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useTheme from '@material-ui/core/styles/useTheme'
import { addCircle } from '../../../redux/actions'
import { connect } from 'react-redux'
import { reduxForm, change, formValueSelector } from 'redux-form'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import FormTextField from '../../../components/FormTextField'
import FormCheckBox from '../../../components/FormCheckBox'
import { required } from '../../../utilities/validators'
import NumberedRow from '../../../components/NumberedRow'
import Button from '@material-ui/core/Button'
import PhiSelection from '../../../components/PhiSelection'
import SponsorSelection from '../../circle/components/SponsorSelection'
import ProtocolSelector from './ProtocolSelector'
import { subscriptionCaseSelector, subscriptionUserSelector, isCreatingSelector } from '../../../redux/selectors'
import UserSelector from '../../circle/components/UserSelector'
import NumberedRowTwoColumns from '../../../components/NumberedRowTwoColumns'
import TreatmentSelector from './TreatmentSelector'
import PathologySelector from './PathologySelector'
import AdministratorSelector from '../../circle/components/AdministratorSelector'
import CalmButton from '../../../components/CalmButton'
import SponsorSelector from '../../../components/SponsorSelector'

const requiredValidator = required

const selector = formValueSelector('addCircle')

const CreateCircleModal = ({
	intl,
	handleSubmit,
	add,
	setPhi,
	isSponsored,
	resetCases,
	resetUsers,
	subscriptionUser,
	subscriptionCase,
	isCreating
}) => {
	const theme = useTheme()

	const onSubmitForm = (values) => {
		add(
			values.name,
			values.phi === '2',
			values.numberCases,
			values.numberUsers,
			values.isSponsored,
			values.protocolId,
			values.founderId,
			values.administratorId,
			values.pathologyId,
			values.treatmentId,
			values.sponsorId
		)
	}

	useEffect(() => {
		setPhi('1')
	}, [])

	const onSponsorSelectionChanged = (e) => {
		if (!e.target.checked) {
			resetCases()
			resetUsers()
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmitForm)}>
			<Grid
				container
				direction="column"
				justify="flex-start"
				alignItems="center"
				style={{ width: '75rem', padding: '4rem', margin: 'auto', backgroundColor: theme.palette.secondary.main }}
			>
				<Grid item style={{ alignSelf: 'center' }}>
					<Typography
						variant="h1"
						style={{
							color: '#fff',
							marginBottom: '25px',
						}}
					>
						{intl.formatMessage({ id: 'app.circles.addCircleText' })}
					</Typography>
				</Grid>
				<Grid item style={{ width: '100%', paddingTop: '6px', paddingBottom: '10px' }}>
					<NumberedRow number={1} label={`${intl.formatMessage({ id: 'app.general.name' })}*`} />
				</Grid>
				<Grid item style={{ borderLeft: '5px solid #2F3945', padding: ' 0', width: '100%' }}>
					<Grid container direction="row" alignItems="center" style={{ paddingLeft: '20px' }}>
						<Grid item style={{ width: '100%' }}>
							<FormTextField
								label={intl.formatMessage({ id: 'app.circles.name' }) + '*'}
								fullWidth
								type="text"
								name="name"
								maxLength={255}
								validators={[ requiredValidator ]}
								variant="filled"
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item style={{ width: '100%', paddingTop: '6px', paddingBottom: '15px' }}>
					<NumberedRow
						number={2}
						label={`${intl.formatMessage({ id: 'app.circles.patientHealthInformation' })}*`}
					/>
				</Grid>
				<Grid item style={{ borderLeft: '5px solid #2F3945', padding: ' 0', width: '100%' }}>
					<div style={{ marginLeft: '20px' }}>
						<Grid container direction="column">
							<Grid item>
								<PhiSelection validators={[ requiredValidator ]} />
							</Grid>
						</Grid>
					</div>
				</Grid>
				{/* <Grid item style={{ width: '100%', paddingTop: '6px', paddingBottom: '10px' }}>
					<Grid container direction="column">
						<Grid item>
							<Grid container direction="row">
								<Grid item>
									<NumberedRow
										number={3}
										label={`${intl.formatMessage({ id: 'app.circles.sponsored' })}`}
									/>
								</Grid>
								<Grid item>
									<FormCheckBox
										color="secondary"
										name="isSponsored"
										onChange={onSponsorSelectionChanged}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid> */}

				{/* <Grid item style={{ borderLeft: '5px solid #2F3945', paddingTop: '6px', width: '100%' }}>
					<div style={{ marginLeft: '20px', minHeight: '20px' }}>
						{isSponsored &&
						subscriptionUser &&
						subscriptionCase && (
							<SponsorSelection
								availableUsers={subscriptionUser.availableUsers}
								availableCases={subscriptionCase.availableCases}
							/>
						)}
					</div>
				</Grid> */}

				<Grid item style={{ width: '100%', paddingTop: ' 5px', paddingBottom: '10px' }}>
					<NumberedRow
						number={3}
						label={`${intl.formatMessage({ id: 'app.general.observationalProtocol' })}*`}
					/>
					<Grid item style={{ borderLeft: '5px solid #2F3945', paddingTop: '6px', width: '100%' }}>
						<div style={{ marginLeft: '20px', minHeight: '20px' }}>
							<ProtocolSelector />
						</div>
					</Grid>
				</Grid>
				<Grid item style={{ width: '100%', paddingTop: ' 5px', paddingBottom: '10px' }}>
					<NumberedRowTwoColumns number={4} labelFirst={`${intl.formatMessage({ id: 'app.circles.founderLabel' })}*`} labelSecond={`${intl.formatMessage({ id: 'app.circles.sponsorLabel' })}*`} />
					<Grid item style={{ borderLeft: '5px solid #2F3945', paddingTop: '6px', width: '100%' }}>
						<div style={{ marginLeft: '20px', minHeight: '20px' }}>
							<Grid container direction='row' alignItems="baseline" justify="flex-start" spacing={2}>
								<Grid item style={{ paddingTop: '6px', width: '100%', flex: 0.5 }}>
									<UserSelector
										name="founderId"
										id="founderId"
										validators={[requiredValidator]}
										label={`${intl.formatMessage({ id: 'app.circles.inviteFounderLabel' })}`}
										subscribersOnly={true}
									/>
								</Grid>
								<Grid item style={{ paddingTop: '6px', width: '100%', flex: 0.5 }}>
									<SponsorSelector
										label={intl.formatMessage({ id: 'app.circles.sponsorFieldLabel' }) + '*'}
										fullWidth
										type="text"
										name="sponsorId"
										maxLength={255}
										validators={[ requiredValidator ]}
										variant="filled"
									/>
								</Grid>
							</Grid>
						</div>
					</Grid>
				</Grid>
				<Grid item style={{ width: '100%', paddingTop: ' 5px', paddingBottom: '10px' }}>
					<NumberedRow
						number={5}
						label={`${intl.formatMessage({ id: 'app.circles.administratorLabel' })}*`}
					/>
					<Grid item style={{ borderLeft: '5px solid #2F3945', paddingTop: '6px', width: '100%' }}>
						<div style={{ marginLeft: '20px', minHeight: '20px' }}>
							<AdministratorSelector
								validators={[ requiredValidator ]}
								name="administratorId"
								id="administratorId"
                                subscribersOnly={true}
								label={`${intl.formatMessage({ id: 'app.circles.inviteAdministratorLabel' })}`}
							/>
						</div>
					</Grid>
				</Grid>
				<Grid item style={{ width: '100%', paddingTop: ' 5px', paddingBottom: '10px' }}>
					<NumberedRowTwoColumns
						number={6}
						labelFirst={`${intl.formatMessage({ id: 'app.general.pathology' })}`}
						labelSecond={`${intl.formatMessage({ id: 'app.general.treatment' })}`}
						
					/>
					<Grid item style={{ borderLeft: '5px solid #2F3945', padding: ' 0', width: '100%' }}>
						<div style={{ marginLeft: '20px' }}>
							<Grid container direction="row" alignItems="baseline" justify="flex-start" spacing={2}>
								<Grid item style={{ paddingTop: '6px', width: '100%', flex: 0.5 }}>
									<PathologySelector
										name='pathologyId'
										label={intl.formatMessage({ id: 'app.circles.pathologyLabel' })}
										noMatchText={intl.formatMessage({ id: 'app.noMatches' })}
									/>
								</Grid>
								<Grid item style={{ paddingTop: '6px', width: '100%', flex: 0.5 }}>
									<TreatmentSelector
										name='treatmentId'
										label={intl.formatMessage({ id: 'app.circles.treatmentLabel' })}
										noMatchText={intl.formatMessage({ id: 'app.noMatches' })}
									/>
								</Grid>
							</Grid>
						</div>
					</Grid>
				</Grid>
				<Grid item>
					<CalmButton
						label="Password"
						style={{
							width: '267px',
							marginTop: '40px'
						}}
						type="submit"
						variant="contained"
                        color="primary"
						disabled={isCreating}
					>
						{intl.formatMessage({ id: 'app.circles.create' })}
					</CalmButton>
				</Grid>
			</Grid>
		</form>
	)
}

const mapStateToProps = (state, ownProps) => ({
	isSponsored: selector(state, 'isSponsored'),
	subscriptionUser: subscriptionUserSelector(state),
	subscriptionCase: subscriptionCaseSelector(state),
	isCreating: isCreatingSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	setPhi: (code) => dispatch(change('addCircle', 'phi', code)),
	resetCases: () => dispatch(change('addCircle', 'numberCases', '')),
	resetUsers: () => dispatch(change('addCircle', 'numberUsers', '')),
	add: (name, phiValue, numberCases, numberUsers, isSponsored, protocolId, founderId, administratorId, pathologyId, treatmentId, sponsorId) =>
		dispatch(
			addCircle(name, 0, phiValue, numberCases, numberUsers, isSponsored, protocolId, founderId, administratorId, pathologyId, treatmentId, sponsorId)
		)
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'addCircle'
	})
)

export default enhance(CreateCircleModal)
