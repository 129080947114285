import React from "react"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { compose } from "recompose"
import EntityAudit from "../../../components/entityAudit/EntityAudit"
import { surveyChangeLogKeysSelector, surveyChangeLogsSelector } from "../../../redux/selectors"
import { searchSurveyChangeLogs } from "../../../redux/actions"
import { useRouteMatch } from "react-router"


const SurveyAudit = ({
    keys,
    logs,
    search
}) => {
    const match = useRouteMatch()

    const handleSearchBarValuesChanged = (searchPhrase, searchKeys) => {
        const surveyId = match?.params?.surveyId

        search(surveyId, searchPhrase, searchKeys)
    }

    return (
        <EntityAudit
            keys={keys}
            logs={logs}
            onSearch={handleSearchBarValuesChanged}
        />
    )
}

const mapStateToProps = (state, ownProps) => ({
    keys: surveyChangeLogKeysSelector(state),
	logs: surveyChangeLogsSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    search: (surveyId, searchPhrase, keys) => dispatch(searchSurveyChangeLogs(surveyId, searchPhrase, keys))
})

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)

export default enhance(SurveyAudit)
