import React from "react";
import { TableCell } from "@material-ui/core";
import { FormattedDate } from "react-intl";


const RelatedServiceProviderInvitationTableCell = ({
    serviceProvider
}) => {
    return (
        <TableCell>
            <FormattedDate value={serviceProvider.invitationDate} />
        </TableCell>
    )
}

export default RelatedServiceProviderInvitationTableCell;
