import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import useTheme from '@material-ui/core/styles/useTheme'
import FormNumberTextField from '../../../components/FormNumberTextField'
import { number, maxValue } from '../../../utilities/validators'
const numberValidator = number
const maxValueValidator = maxValue

const SponsoredValue = ({ intl, label, available = 0, entityType, name }) => {
	var theme = useTheme()
	return (
		<Grid container direction="row" style={{ paddingTop: '10px' }}>
			<Grid item style={{ color: 'white', flex: 0.5, paddingLeft: '10px' }}>
				<Typography style={{ color: 'white' }}>{label}</Typography>
				<Typography style={{ color: '#a2a7ab' }}>
					{available} {intl.formatMessage({ id: 'app.general.available' })}
				</Typography>
				<Typography style={{ color: '#a2a7ab', fontSize: '0.9em' }}>{`${entityType} ${intl.formatMessage({
					id: 'app.general.onPlan'
				})}`}</Typography>
			</Grid>
			<Grid item style={{ color: 'white', flex: 0.5, paddingRight: '20px' }}>
				<Grid container direction="column">
					<Grid item>
						<FormNumberTextField
							type="text"
							name={name}
							maxLength={4}
							variant="filled"
							validators={[ numberValidator, maxValueValidator(available) ]}
						/>
						<Typography style={{ color: '#a2a7ab', fontSize: '0.9em' }}>
							{`${entityType} ${intl.formatMessage({
								id: 'app.general.allotments'
							})}`}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

const enhance = compose(injectIntl)

export default enhance(SponsoredValue)
