import { getUser } from '../utilities/userUtilities'

export const signIn = (email, password) => {
	return fetch(`/api/authorization`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ email: email, password: password })
	})
		.then(function(response) {
			return response.json()
		})
		.then(function(data) {
			return data
		})
}

export const changePassword = (id, oldPassword, newPassword, type) => {
	return fetch(`/api/authorization/password`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ id: id, oldPassword, newPassword, type: type })
	})
		.then(function(response) {
			return response.json()
		})
		.then(function(data) {
			return data
		})
}

export const newUserChangePassword = (email, oldPassword, newPassword, type) => {
	return fetch(`/api/authorization/newuserpassword`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ email: email, oldPassword, newPassword, type: type })
	})
		.then(function(response) {
			return response.json()
		})
		.then(function(data) {
			return data
		})
}

export const signOut = () => {
	return fetch(`/api/authorization/signout`, {
		method: 'post',
		headers: { 'content-type': 'application/json' }
	}).then(function(response) {
		return response
	})
}

export const requestForgotPasswordEmail = (email) => {
	return fetch(`/api/authorization/requestpasswordchange`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ email: email })
	}).then(function(response) {
		return response
	})
}

export const confirmForgotPassword = (email, code, password) => {
	return fetch(`/api/authorization/confirmpasswordchange`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ email: email, code: code, password: password })
	}).then(function(response) {
		return response
	})
}

export const refreshToken = () => {
	var user = getUser()
	if (user) {
		return fetch(`/api/authorization/refresh/${user.id}`, { method: 'GET' })
	}
}
