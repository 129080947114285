import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import InputBase from '@material-ui/core/InputBase'
import FilledInput from '@material-ui/core/FilledInput'
import FormHelperText from '@material-ui/core/FormHelperText'
import { makeStyles } from '@material-ui/core/styles'
import MaskedInput from 'react-text-mask'

const useStyles = makeStyles((theme) => ({
	formControl: {
		backgroundColor: 'rgba(0, 0, 0, 0.09)'
	}
}))

function TextMaskCustom(props) {
	const { inputRef, hasFocus, ...other } = props

	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null)
			}}
			mask={[ /\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/ ]}
			placeholderChar={'\u2000'}
			showMask={hasFocus}
			guide={false}
			style={{ marginTop: '8px', color: 'black', marginLeft: '10px' }}
		/>
	)
}

export default ({}) => {
	let classes = useStyles()
	return (
		<FormControl fullWidth>
			<FilledInput
				variant="filled"
				placeholder="YYYY/MM/DD"
				InputProps={{
					classes: {
						formControl: classes.formControl,
						root: classes.formControl
					}
				}}
				classes={{
					formControl: classes.formControl,
					root: classes.formControl
				}}
				inputComponent={TextMaskCustom}
				autoComplete={false}
			/>
		</FormControl>
	)
}
