import { authenticatedFetch } from './base'

export const addPathology = (internalName) => {
	return authenticatedFetch(`/api/pathologies`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			internalName: internalName
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchPathology = (id) => {
	var url = `/api/pathologies/${id}`

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchPathologies = (searchPhrase) => {
	var url = `/api/pathologies`

	if (searchPhrase && searchPhrase.length > 0) {
		url = `${url}?searchPhrase=${encodeURIComponent(searchPhrase)}`
	}
	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const updatePathology = (id, name, description, basePathologyId, languageId, internalName, imageUrl) => {
	return authenticatedFetch(`/api/pathologies`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			name: name,
			description: description,
			basePathologyId: basePathologyId,
			id: id,
			languageId: languageId,
			internalName: internalName,
			imageUrl: imageUrl
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const updatePathologyInternalName = (id, internalName) => {
	return authenticatedFetch(`/api/pathologies/${id}/internalname?internalName=${encodeURIComponent(internalName)}`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}
