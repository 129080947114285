import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useTheme from '@material-ui/core/styles/useTheme'
import {} from '../../redux/actions'
import { planSelector } from '../../redux/selectors'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import FormTextField from '../../components/FormTextField'
import FormDateField from '../../components/FormDateField'
import { required } from '../../utilities/validators'
import Button from '@material-ui/core/Button'
import { fetchPlan, applyChanges } from '../../redux/actions'
import DistributeValue from './components/DistributeValue'
import FormNumberTextField from '../../components/FormNumberTextField'
import { number, maxValue } from '../../utilities/validators'
import { useDispatch } from 'react-redux'
import SponsorSelector from '../../components/SponsorSelector'
const numberValidator = number
const maxValueValidator = maxValue
const requiredValidator = required

const ManageSubscriptionModal = ({ intl, handleSubmit, subscriber, apply, plan }) => {
	const theme = useTheme()
	const dispatch = useDispatch()

	const onSubmitForm = (values) => {
		if (values) {
			apply(subscriber.id, values.subscriptionExpires, values.sponsorId)
		}
	}

	useEffect(() => {
		if (!!subscriber && !!subscriber.id) {
			dispatch(fetchPlan(subscriber.id))
		}
	}, [])

	return (
		<form onSubmit={handleSubmit(onSubmitForm)}>
			<Grid
				container
				direction="column"
				justify="flex-start"
				alignItems="center"
				style={{ width: '55rem', padding: '4rem', margin: 'auto', backgroundColor: theme.palette.secondary.main }}
			>
				<Grid item style={{ alignSelf: 'center' }}>
					<Typography
						variant="h1"
						style={{
							color: '#fff',
							marginBottom: '25px',
						}}
					>
                        Manage Subscription
					</Typography>
				</Grid>
				<Grid item style={{ alignSelf: 'flex-start', width: '100%' }}>
					<Grid container style={{ width: '100%' }} spacing={2}>
						<Grid item style={{ flex: 0.5 }}>
							<FormDateField
								label="Joined"
								fullWidth
								type="text"
								name="subscriptionJoined"
								maxLength={254}
								variant="filled"
								disabled={true}
								design="dark"
							/>
						</Grid>
						<Grid item style={{ flex: 0.5 }}>
							<FormDateField
								label="Expiration*"
								fullWidth
								type="text"
								name="subscriptionExpires"
								maxLength={254}
								validators={[ requiredValidator ]}
								variant="filled"
								design="dark"
							/>
						</Grid>
					</Grid>
				</Grid>
                <Grid item style={{ alignSelf: 'flex-start', width: '100%' }}>
                    <Grid container style={{ width: '100%' }} spacing={2}>
                        <Grid item style={{ flex: 1 }}>
							<SponsorSelector
								label={intl.formatMessage({ id: 'app.circles.sponsorFieldLabel' }) + '*'}
                                fullWidth
                                type="text"
                                name="sponsorId"
                                validators={[requiredValidator]}
                                variant="filled"
								defaultLabel={plan && plan.sponsorName}
							/>
                        </Grid>
                    </Grid>
                </Grid>
				<Grid item>
					<Button
						label="APPLY"
						style={{
							width: '267px',
							marginTop: '40px'
						}}
						type="submit"
                        variant="contained"
                        color="primary"
					>
						UPDATE
					</Button>
				</Grid>
			</Grid>
		</form>
	)
}

const mapStateToProps = (state, ownProps) => ({
	initialValues: planSelector(state),
	plan: planSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	apply: (id, expiration, sponsorId) => dispatch(applyChanges(id, expiration, sponsorId))
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'addSubscription',
		touchOnChange: true,
		touchOnBlur: true,
		enableReinitialize: true
	})
)

export default enhance(ManageSubscriptionModal)
