import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { injectIntl, useIntl } from 'react-intl'
import { Select, useTheme } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import QuestionContainer from './QuestionContainer'

const MultiSelectQuestion = ({ question, positionDisplay }) => {
	const intl = useIntl()

	const theme = useTheme()
	const [ left, setLeft ] = useState([])
	const [ right, setRight ] = useState([])
	useEffect(
		() => {
			if (question && question.answers) {
				let a1 = []
				let a2 = []
				question.answers.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1)).map((r, i) => {
					r.sortOrder++

					if (r.sortOrder === 1) {
						a1.push(r)
					} else if (r.sortOrder % 2 === 0) {
						a2.push(r)
					} else {
						a1.push(r)
					}
				})
				setLeft(a1)
				setRight(a2)
			}
		},
		[ question ]
	)
	return (
			<Grid container direction="column">
				<Grid item>
					<QuestionContainer
						title={question.title}
						positionDisplay={positionDisplay}
						instructions={question.instructions}
						displayInstructionAsIcon={question.displayInstructionAsIcon}
						children={
							<Grid container direction="column" style={{ width: '100%' }}>
								{
									!question.isDropdown
									&& left
									&& left.map((r, i) => {
										return (
											<Grid item style={{ width: '100%' }} key={left[i].id}>
												<Grid container>
													<Grid item  xs={12} sm={6}>
														<FormControl
															className="form-control"
															component="fieldset"
															style={{ marginLeft: '-19px' }}
														>
															<FormGroup>
																<FormControlLabel
																	style={{ color: 'black' }}
																	control={
																		<Checkbox color="primary" disabled value={left[i].value} /> //onChange={handleChange('gilad')}
																	}
																	label={`${left[i].label} ( ${left[i].value} )`}
																/>
															</FormGroup>
														</FormControl>
													</Grid>
													<Grid item  xs={12} sm={6}>
														{right[i] && (
															<FormControl
																className="form-control"
																component="fieldset"
																style={{ marginLeft: '-19px' }}
															>
																<FormGroup>
																	<FormControlLabel
																		style={{ color: 'black' }}
																		control={
																			<Checkbox color="primary" disabled value={right[i].value} /> //onChange={handleChange('gilad')}
																		}
																		label={`${right[i].label} ( ${right[i]
																			.value} )`}
																	/>
																</FormGroup>
															</FormControl>
														)}
													</Grid>
												</Grid>
											</Grid>
										)
									})
								}
								{
									question.isDropdown
									&& <Select
										classNamePrefix='react-select-styled'
										noOptionsMessage={() => null}
										menuPosition="fixed"
										menuPortalTarget={document.body} 
										styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
										disabled={true}
										options={question.answers.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))}
										placeholder={intl.formatMessage({id: 'app.survey.question.dropdownPlaceholder'})}
									/>
								}
							</Grid>
						}
					/>
				</Grid>
			</Grid>

	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(MultiSelectQuestion)
