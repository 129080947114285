import React, { useEffect, useState, forwardRef, Fragment, useImperativeHandle } from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { uuidv4 } from '../../../utilities/utilities'
import AnswerContainer from './AnswerContainer'
import { ListContainer } from '../../../utilities/linkedList'
import { useIntl } from 'react-intl'
import FormFilledInputSelectLight from '../../FormFilledInputSelectLight'
import DefaultFormTextField from '../../DefaultFormTextField'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'
import { useStore } from 'react-redux'
import { formValueSelector } from 'redux-form'
import { required } from '../../../utilities/validators'
import { useSelector, shallowEqual } from 'react-redux'
import { languageNameSelector } from '../../../redux/selectors'
const requiredValidator = required
const selector = formValueSelector('editQuestion')

export default forwardRef((props, ref) => {
	const intl = useIntl()

	const [ answers, setAnswers ] = useState()
	const { question, showScoreEntry } = props
	const [ id, setId ] = useState(0)
	const [ minimumValue, setMinimumValue ] = useState(1)
	const [ range, setRange ] = useState(2)
	const [ answerList, setAnswerList ] = useState(new ListContainer())
	const dispatch = useDispatch()
	const store = useStore()
	let languages = useSelector(languageNameSelector, shallowEqual)


	useEffect(
		() => {
			if (question && question.data) {
				dispatch(change('editQuestion', 'question', question.data.title))
				dispatch(change('editQuestion', 'instructions', question.data.instructions))
				dispatch(change('editQuestion', 'tag', question.data.tag))
				dispatch(change('editQuestion', 'minimumValue', question.data.minimumValue))
				dispatch(change('editQuestion', 'range', question.data.range))
				setId(question.data.id)

				if (answerList) {
					for (let index = 0; index < question.data.categories.length; index++) {
						const element = question.data.categories[index]

						element.answerId = uuidv4().replace('-', '')
						element.scoreId = uuidv4().replace('-', '')

						dispatch(change('editQuestion', element.answerId, element.label))

						answerList.getList().append(element)
					}
					let questionAnswers = new Array(0)

					answerList.getList().traverse((r) => {
						r.id = uuidv4().replace('-', '')
						questionAnswers.push(r)
					})
					setAnswers(questionAnswers)
				}
			}
		},
		[ question ]
	)

	useImperativeHandle(ref, () => ({
		getQuestion() {
			let a = []

			if (answers) {
				let i = 0
				answerList.getList().traverse((r) => {
					let question = selector(store.getState(), r.data.answerId)

					a.push({ answer: { question }, canonicalId: r.data.canonicalId, id: r.data.id, sortOrder: i })
					i++
				})
			}
			return {
				id: question.data.id,
				title: selector(store.getState(), 'question'),
				tag: selector(store.getState(), 'tag'),
				type: 'grouped',
				canonicalId: question.data.canonicalId,
				minimumValue: selector(store.getState(), 'minimumValue'),
				range: selector(store.getState(), 'range'),
				instructions: selector(store.getState(), 'instructions'),
				answers: a
			}
		}
	}))

	return (
		<Grid container direction="column" alignItems="flex-start">
			<Grid item style={{ width: '100%' }}>
				<Typography variant="subtitle2">
					Title ({languages(1)}): {question.data.baseTitle}
				</Typography>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				<DefaultFormTextField
					label={`Title (${languages(question.data.languageId)})`}
					variant="filled"
					fullWidth
					name="question"
					validators={[ requiredValidator ]}
					inputProps={{ maxLength: 1023 }}
					maxLength={1023}
				/>
			</Grid>

			{question.data.baseInstructions && (
				<Fragment>
					<Grid item style={{ width: '100%' }}>
						<Typography variant="subtitle2">
							Instructions ({languages(1)}): {question.data.baseInstructions}
						</Typography>
					</Grid>
					<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
						<DefaultFormTextField
							label={`Instructions (${languages(question.data.languageId)})`}
							variant="filled"
							name="instructions"
							fullWidth
							inputProps={{ maxLength: 2047 }}
							maxLength={2047}
							rows="3"
							multiline
						/>
					</Grid>
				</Fragment>
			)}
			<Grid item style={{ width: '40%', marginTop: '20px' }}>
				{ <FormFilledInputSelectLight
					placeholder="Minimum"
					label="Minimum"
					name="minimumValue"
					initialValue={minimumValue}
					onChangeCallback={setMinimumValue}
					data={
						Array(15).fill().map((_v, i) => {
							return {
								name: i,
								value: i
							}
						})
					}
					style={{ color: 'black' }}
					fullWidth
					disabled={true}
				/> }
			</Grid>
			<Grid item style={{ width: '40%', marginTop: '20px' }}>
				{ <FormFilledInputSelectLight
					placeholder="Maximum"
					label="Maximum"
					name="range"
					initialValue={range}
					onChangeCallback={setRange}
					data={
						Array(15)
							.fill()
							.map((_v, i) => {
								return {
									name: i + 1,
									value: i + 1
								}
							})
					}
					style={{ color: 'black' }}
					fullWidth
					disabled={true}
				/> }
			</Grid>
			<Grid item>
				<Typography variant="h6" style={{ fontWeight: '400', padding: '10px 0' }}>
					{intl.formatMessage({ id: 'app.survey.groupedQuestion.categorySubtitleText' })}
				</Typography>
			</Grid>

			{answers &&
				answers.map((r, i) => {
					return (
						<Grid item key={r.id} style={{ width: '100%', padding: '10px 0 10px 30px ' }}>
							<AnswerContainer answer={r} key={r.id} showScoreEntry={showScoreEntry} />
						</Grid>
					)
				})}
		</Grid>
	)
})
