import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import DropzoneS3Uploader from './DropzoneS3Uploader'

import { useDispatch } from 'react-redux'
import { injectIntl } from 'react-intl'
import { useTheme } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { stopSaving } from '../redux/actions'

import CloudUploadIcon from '@material-ui/icons/CloudUpload'

const progressBarStyle = {
    position: "relative",
    height: "16px",
    backgroundColor: "#e3eefe",
    margin: "10px 10px 20px",
    borderRadius: "10px"
};

const S3FileUploader = ({ question, value, bucketUrl, disabled, intl, ...rest }) => {

    const dispatch = useDispatch()
    const theme = useTheme()

    let onFinishHandler = data => {
        let i = document.getElementById('answers.file._' + question.uniqueId);

        i.value = data.fileUrl;
        i.setAttribute('data-fileKey', data.fileKey);
        i.setAttribute('data-fileName', data.file.name);
        i.setAttribute('data-fileLength', data.file.size);
        i.setAttribute('data-mime', data.file.type);

        dispatch(stopSaving())
    }

    let getMaxSizeMb = () => {
        switch (question.questionType) {
        case 10:
            return 10;
        case 11:
            return 50;
        default:
            throw `Type ${question.questionType} not defined`;
        }
    }

    let getAccept = () => {
        switch (question.questionType) {
            case 10:
                return "image/*";
            case 11:
                return "application/pdf";
            default:
                throw `Type ${question.questionType} not defined`;
        }
    }

    const uploadOptions = {
        server: '/api/file',
    }

    let getUploadFileDescriptionKey = () => {
        switch (question.questionType) {
        case 10:
            return 'app.questions.questionType.image.upload';
        case 11:
            return 'app.questions.questionType.pdf.upload';
        default:
            throw `Type ${question.questionType} not defined`;
        }
    }

    const onDropRejected = () => {
        dispatch({
            type: 'SET_SNACKBAR_MESSAGE',
            data: { message: `Maximum file upload size is ${getMaxSizeMb()}MB`, isSuccess: false }
        });
    }

    return (
        <DropzoneS3Uploader
            onFinish={onFinishHandler}
            s3Url={bucketUrl}
            maxSize={getMaxSizeMb() * 1024 * 1024}
            accept={getAccept()}
            upload={uploadOptions}
            progressComponent={progressBar}
            onDropRejected={onDropRejected}
            fileComponent={renderFile}
            disabled={disabled}>
            <div className="fileUploaderWrapper">
                <p data-testid="dropzone-uploader-text" style={{
                    lineHeight: 1.23,
                    letterSpacing: '2.08px',
                    color: 'rgb(153, 153, 153)',
                    textTransform: 'uppercase'
                }}>
                    {intl.formatMessage({ id: getUploadFileDescriptionKey() })}
                </p>
                <CloudUploadIcon data-testid="dropzone-uploader-icon" style={{ color: "#e8e8e8", fontSize: '50px' }} />
                <Button
                    data-testid="dropzone-uploader-button-file"
                    variant="contained"
                    color='primary'
                    size="large"
                    disabled={disabled}
                    >
                    {intl.formatMessage({ id: 'app.general.chooseFile' })}
                </Button>
            </div>
        </DropzoneS3Uploader>
    );
}

const renderFile = data => {
    return (<div className="rdsu-file" style={{ margin: '20px', 'text-align': 'center' }}>
        <div className="rdsu-file-icon"><span className="fa fa-file-o" style={{ fontSize: '50px' }} /></div>
        <div className="rdsu-filename">{data.uploadedFile.file.name}</div>
    </div>);
}

const progressBar = p => {
    return (
        p.progress > 0 &&
            <div className="progress-bar" style={progressBarStyle}>
            <div className="filler" style={{
                width: `${p.progress}%`,
                height: "100%",
                backgroundColor: "#007aff",
                backgroundSize: "50px 50px",
                boxShadow: "inset 0 -1px 1px rgba(255,255,255,0.3)",
                backgroundImage: "linear-gradient(-45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent)",
                transition: "width .2s ease-in",
                borderRadius: "inherit"
            }} />
        </div>);
}

const FileQuestion = ({ question, position, positionDisplay, value, counter, displayCount, validate, disabled, intl, ...rest }) => {
    return (
        <Grid>
            <S3FileUploader
                value={value}
                question={question}
                bucketUrl={question.tempBucketUrl}
                intl={intl}
                disabled={disabled}
            />
            <input
                className="file-question"
                type="hidden" id={'answers.file._' + question.uniqueId}
                name={'answers.file._' + question.uniqueId}
                data-questionId={question.uniqueId}
                data-fileLength
                data-fileName
                data-mime
            />
        </Grid>
    )
}

const enhance = compose(
    injectIntl
)

export default enhance(FileQuestion)
