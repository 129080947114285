import React, { useEffect } from 'react'
import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

export default ({ circle, setHeightCallback }) => {
	let ref = React.createRef()
	const intl = useIntl()
	useEffect(
		() => {
			if (setHeightCallback && ref.current) {
				if (ref.current.offsetHeight < 64) {
					setHeightCallback(64)
				} else {
					setHeightCallback(ref.current.offsetHeight)
				}
			}
		},
		[ ref ]
	)

	return (
		<Grid container justify="flex-start" ref={ref} id={circle.circleId} alignContent="flex-start" alignItems="flex-start">
			<Grid item style={{ minWidth: '200px' }}>
				<Grid container direction="column" style={{ minWidth: '200px' }}>
					<Grid item>
						<Link
							to={`/circles/${circle.circleId}`}
							style={{
								display: 'block',
								textDecoration: 'none',
								fontWeight: '700',
								fontSize: '17px',
								lineHeight: '20px',
								letterSpacing: '0.16px',
								color: '#011020',
								
							}}
						>
							<span style={{ display: 'block', marginBottom: '5px' }}>
								{circle && circle.circleName && circle.circleName.length > 200 ? (
									circle.circleName.substring(0, 200) + '...'
								) : (
									circle.circleName
								)}
							</span>
						</Link>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}
