import React from "react";
import { TableCell } from "@material-ui/core";
import { Link } from "react-router-dom/cjs/react-router-dom";


const RelatedProtocolModificationTableCell = ({
    protocol
}) => {
    const getText = () => {
        let result = protocol.protocolName

        return result
    }

    return (
        <TableCell>
            <Link to={`/protocols/${protocol.protocolId}/version/${protocol.protocolVersionId}/details`}>
                { getText() }
            </Link>
        </TableCell>
    )
}

export default RelatedProtocolModificationTableCell;
