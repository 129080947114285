import React from 'react'
import { TableCell, TableRow, Typography } from '@material-ui/core'
import TeamMemberStatusIcon from '../../../components/member/MemberStatusIcon'
import TeamMemberMenu from './MemberMenu'
import TeamMemberDateJoined from '../../../components/member/MemberDateJoined'
import TeamMemberCell from '../../../components/member/MemberCell'
import Member2FAState from '../../../components/member/Member2FAState'
import MemberLanguageAbbreviation from '../../../components/member/MemberLanguageAbbreviation'

export default ({ member, user }) => {
	return (
		<TableRow>
			<TableCell align="left" style={{ padding: '5px 0' }} />
			<TableCell align="right" size="small">
				<TeamMemberStatusIcon member={member} />
			</TableCell>
			<TableCell align="left">
				<div style={{ position: 'relative' }}>
					<div
						style={{
							borderLeft: `solid 3px ${member.dateJoined ? 'black' : '#7B889C'}`,
							minHeight: '40px',
							position: 'absolute',
							left: '-90px',
							top: '50%',
							transform: 'translateY(-50%)',
							height: '100%'
						}}
					/>
					<TeamMemberCell member={member} />
				</div>
			</TableCell>
            <TableCell
                data-testid="member-row-2fa-state"
                align="left">
				<MemberLanguageAbbreviation member={member} />
            </TableCell>
            <TableCell align="left" size="small">
                <Typography
                    variant="body2"
                    style={{
						textAlign: 'left',
						fontWeight: '400',
						fontSize: '17px',
						lineHeight: '22px',
						letterSpacing: '0.51px'
					}}
                >
					{member.jurisdictionName}
                </Typography>
            </TableCell>
            <TableCell
                data-testid="member-row-2fa-state"
                align="left">
                <Member2FAState member={member} />
            </TableCell>
			<TableCell align="left">
				<TeamMemberDateJoined member={member} />
			</TableCell>
			<TableCell align="right" style={{ padding: '5px 15px 5px 5px' }}>
				{
					member
					&& member.memberId !== member.ownerId
					&& <TeamMemberMenu member={member} user={user} />
				}
			</TableCell>
		</TableRow>
	)
}
