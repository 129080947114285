import React from 'react'
import { Field } from 'redux-form'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FilledInput from '@material-ui/core/FilledInput'
import { withStyles, makeStyles } from '@material-ui/core/styles'

const useLabelStyles = makeStyles({
	root: {
		color: 'white',
		marginLeft: 0,
		'&$focused': {
			color: 'white'
		}
	},
	icon: {
		color: 'white',
		height: '24px',
		width: '24px'
	},
	focused: {
		color: 'white'
	},
	error: {
		backgroundColor: 'transparent'
	},
	selectMenu: {
		paddingLeft: '10px',
		color: 'white',
		height: '20px'
	}
})

const renderSelectField = ({
	input,
	label,
	meta: { touched, error, invalid },
	children,
	name,
	labelStyles,
	...custom
}) => {
	return (
		<div className="global-input-wrapper">
			<FormControl error={touched && error} style={{ backgroundColor: 'rgb(36, 48, 65)', marginTop: '16px' }} fullWidth>
				<InputLabel
					htmlFor={name}
					classes={{
						root: labelStyles.root,
						focused: labelStyles.focused
					}}
					variant='filled'
				>
					{label}
				</InputLabel>
				<Select
					fullWidth
					error={touched && invalid}
					errorText={touched && error}
					{...input}
					{...custom}
					inputProps={{
						name: name,
						id: name
					}}
					classes={{ icon: labelStyles.icon, selectMenu: labelStyles.selectMenu }}
					input={<FilledInput id={name} name={name} style={{ padding: 0, color: 'white' }} />}
				>
					{children}
				</Select>
			</FormControl>
			{touched && error && <div style={{
				color: 'red',
				margin: '8px 16x',
				fontSize: '14px'
			}}>{error}</div>}
		</div>
	)
}

const JurisdictionSelector = ({ label, name, onChange, data, classes, validators, ...other }) => {
	var labelStyles = useLabelStyles()
	return (

		<Field
			component={renderSelectField}
			name={name}
			onChange={onChange}
			label={label}
			labelStyles={labelStyles}
			validate={validators}
			{...other}
		>
			{data &&
				data.map((r) => (
					<MenuItem key={r.id} value={r.id}>
						{r.name}
					</MenuItem>
				))}
		</Field>

	)
}

export default JurisdictionSelector
