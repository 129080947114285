import React, { useState, forwardRef, Fragment, useImperativeHandle, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import AnswerContainer from './AnswerContainer'
import { ListContainer } from '../../../utilities/linkedList'
import { useIntl } from 'react-intl'
import { required } from '../../../utilities/validators'
import { uuidv4 } from '../../../utilities/utilities'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'
import DefaultFormTextField from '../../DefaultFormTextField'
import { useStore } from 'react-redux'
import { formValueSelector } from 'redux-form'
import { useSelector, shallowEqual } from 'react-redux'
import { languageNameSelector } from '../../../redux/selectors'

const requiredValidator = required

const useStyles = makeStyles({
	root: {
		color: 'black'
	},
	instructionTextRoot: {
		color: 'black',
		marginLeft: '-2px'
	},
	label: {
		textTransform: 'capitalize',
		color: 'black',
		marginLeft: '-3px'
	},
	formControl: {
		minWidth: 120,
		color: 'black'
	}
})
const selector = formValueSelector('editQuestion')

export default forwardRef((props, ref) => {
	let classes = useStyles()
	const intl = useIntl()
	const store = useStore()
	const [ answers, setAnswers ] = useState()
	const { onSubmit, onCancel, question, showScoreEntry } = props
	const [ questionText, setQuestionText ] = useState('')
	const [ instructionText, setInstructionText ] = useState('')
	const [ id, setId ] = useState(0)
	const [ showAddMore, setShowAddMore ] = useState(false)
	const [ showEnterAnswer, setShowEnterAnswer ] = useState(true)
	const [ answerList, setAnswerList ] = useState(new ListContainer())
	const [ refs, setRefs ] = useState([])
	const dispatch = useDispatch()
	let languages = useSelector(languageNameSelector, shallowEqual)
	useEffect(
		() => {
			if (question && question.data) {
				dispatch(change('editQuestion', 'question', question.data.title))
				dispatch(change('editQuestion', 'instructions', question.data.instructions))
				dispatch(change('editQuestion', 'tag', question.data.tag))
				dispatch(change('editQuestion', 'isConsentRequired', question.data.isConsentRequired))

				setId(question.data.id)

				if (answerList) {
					for (let index = 0; index < question.data.answers.length; index++) {
						const element = question.data.answers[index]

						element.answerId = uuidv4().replace('-', '')
						element.scoreId = uuidv4().replace('-', '')

						dispatch(change('editQuestion', element.answerId, element.label))
						dispatch(change('editQuestion', element.scoreId, element.value))
						answerList.getList().append(element)
					}

					let questionAnswers = new Array(0)

					answerList.getList().traverse((r) => {
						questionAnswers.push(r)
					})
					setAnswers(questionAnswers)
				}
			}
		},
		[ question ]
	)
	useImperativeHandle(ref, () => ({
		getQuestion() {
			let a = []

			if (answers) {
				let i = 0
				answerList.getList().traverse((r) => {
					let answer = selector(store.getState(), r.data.answerId)
					let value = selector(store.getState(), r.data.scoreId)

					a.push({ answer: { answer, value, canonicalId: r.data.canonicalId }, id: r.data.id, sortOrder: i })
					i++
				})
			}
			return {
				id: question.data.id,
				title: selector(store.getState(), 'question'),
				tag: question.data.tag,
				type: 'single',
				canonicalId: question.data.canonicalId,
				instructions: selector(store.getState(), 'instructions'),
				answers: a,
                isConsentRequired: selector(store.getState(), 'isConsentRequired')
			}
		}
	}))

	return (
		<Grid container direction="column" alignItems="flex-start">
			<Grid item style={{ width: '100%' }}>
				<Typography variant="subtitle2">
					Title ({languages(1)}): {question.data.baseTitle}
				</Typography>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				<DefaultFormTextField
					label={`Title (${languages(question.data.languageId)})`}
					variant="filled"
					fullWidth
					name="question"
					validators={[ requiredValidator ]}
					inputProps={{ maxLength: 1023 }}
					maxLength={1023}
				/>
			</Grid>

			{question.data.baseInstructions && (
				<Fragment>
					<Grid item style={{ width: '100%' }}>
						<Typography variant="subtitle2">
							Instructions ({languages(1)}): {question.data.baseInstructions}
						</Typography>
					</Grid>
					<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
						<DefaultFormTextField
							label={`Instructions (${languages(question.data.languageId)})`}
							variant="filled"
							name="instructions"
							fullWidth
							inputProps={{ maxLength: 2047 }}
							maxLength={2047}
							rows="3"
							multiline
						/>
					</Grid>
				</Fragment>
			)}
			<Grid item>
				<Typography variant="h4" style={{ fontWeight: '400', paddingBottom: '10px' }}>
					Answers
				</Typography>
			</Grid>

			{answers &&
				answers.map((r, i) => {
					return (
						<Grid item style={{ width: '100%', padding: '10px 0 10px 30px ' }} key={r.id}>
							<AnswerContainer answer={r} key={r.id} showScoreEntry={showScoreEntry} />
						</Grid>
					)
				})}
		</Grid>
	)
})
