import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

export default ({ question }) => {
	return (
		<Grid container direction="column" justify="flex-start" style={{ marginTop: '20px' }}>
			<Grid item>
				<Typography variant="subtitle1" style={{ fontWeight: '400' }}>
					{question.subtitle}
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant="subtitle2" style={{ fontWeight: '400' }}>
					{question.bodyCopy}
				</Typography>
			</Grid>
		</Grid>
	)
}
