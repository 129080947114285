import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useTheme from '@material-ui/core/styles/useTheme'
import { connect } from 'react-redux'
import { reduxForm, change, formValueSelector } from 'redux-form'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import FormTextField from './FormTextField'
import { required, passwordMatch, passwordStrength, email } from '../utilities/validators'
import { changePassword } from '../redux/actions'
import Button from '@material-ui/core/Button'

const requiredValidator = required
const passwordStrengthValidator = passwordStrength
const passwordMatchValidator = passwordMatch('newPassword')

const ChangePasswordModal = ({ intl, handleSubmit, submitRequest, id, type }) => {
	const theme = useTheme()

	const onSubmitForm = (values) => {
		submitRequest(id, values.existingPassword, values.newPassword, type)
	}

	useEffect(() => {}, [])

	return (
		<form onSubmit={handleSubmit(onSubmitForm)}>
			<Grid
				container
				direction="column"
				justify="flex-start"
				alignItems="center"
				style={{ width: '55rem', padding: '4rem', margin: 'auto', backgroundColor: theme.palette.secondary.main }}
			>
				<Grid item>
					<Typography
						variant="h1"
						style={{
							color: '#fff',
							textAlign: 'center',
							marginBottom: '67.4px'
						}}
					>
						{intl.formatMessage({ id: 'app.general.changePassword' })}
					</Typography>
				</Grid>
				<Grid item style={{ width: '100%' }}>
					<FormTextField
						label={intl.formatMessage({ id: 'app.general.existingPassword' })}
						fullWidth
						type="password"
						name="existingPassword"
						margin="normal"
						variant="filled"
						validators={[ requiredValidator ]}
					/>
				</Grid>
				<Grid item>
					<Typography variant="caption" style={{ color: 'white' }}>
						{intl.formatMessage({ id: 'app.changePassword.passwordHelpText' })}
					</Typography>
				</Grid>
				<Grid item style={{ width: '100%' }}>
					<FormTextField
						label={intl.formatMessage({ id: 'app.general.newPassword' })}
						fullWidth
						type="password"
						name="newPassword"
						margin="normal"
						variant="filled"
						validators={[ requiredValidator, passwordStrengthValidator ]}
					/>
				</Grid>
				<Grid item style={{ width: '100%' }}>
					<FormTextField
						label={intl.formatMessage({ id: 'app.general.confirmPassword' })}
						fullWidth
						type="password"
						name="verifyPassword"
						margin="normal"
						variant="filled"
						validators={[ requiredValidator, passwordMatchValidator, passwordMatchValidator ]}
					/>
				</Grid>
				<Grid item>
					<Button
						label={intl.formatMessage({ id: 'app.changePassword.buttonLabel' })}
						style={{
							width: '267px',
							marginTop: '40px'
						}}
						type="submit"
                        size="large"
                        variant="contained"
                        color="primary"
					>
						{intl.formatMessage({ id: 'app.changePassword.buttonLabel' })}
					</Button>
				</Grid>
			</Grid>
		</form>
	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
	submitRequest: (id, oldPassword, newPassword, type) => dispatch(changePassword(id, oldPassword, newPassword, type))
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'addCase',
		touchOnChange: true,
		touchOnBlur: true
	})
)

export default enhance(ChangePasswordModal)
