import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { expireSubscription } from '../../redux/actions'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import Button from '@material-ui/core/Button'

const ExpireSubscriptionModal = ({ intl, subscriber, expireSubscription }) => {
	const onConfirm = () => {
		expireSubscription(subscriber.id)
	}

	return (
        <Grid
            container
            direction="column"
            style={{
                padding: '0 30px 30px',
                backgroundColor: '#011020'
            }}>
            <Grid item style={{textAlign: 'center'}}>
                <Typography
                    variant="h1"
                    style={{color: 'white', paddingTop: '40px', paddingBottom: '30px'}}
                >
                    {intl.formatMessage({id: 'app.subscriber.expireSubscriptionModalHeader'})}
                </Typography>
            </Grid>
            <Grid item style={{textAlign: 'center'}}>
                <Typography
                    variant="h4"
                    style={{color: 'white', fontWeight: '400', paddingTop: '0px', paddingBottom: '30px'}}
                >
                    {intl.formatMessage({id: 'app.subscriber.expireSubscriptionModalBody'}, {email: subscriber.email})}
                </Typography>
            </Grid>
            <Grid item>
                <Grid item style={{ textAlign: 'center' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{
                            width: '200px'
                        }}
                        onClick={onConfirm}
                    >
                        {intl.formatMessage({id: 'app.subscriber.expireSubscriptionModalButtonLabel'})}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	expireSubscription: (userId) => dispatch(expireSubscription(userId))
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl
)

export default enhance(ExpireSubscriptionModal)
