import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import useTheme from '@material-ui/core/styles/useTheme'
import { connect } from 'react-redux'
import { reduxForm, change } from 'redux-form'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import Button from '@material-ui/core/Button'
import { Switch, Typography, styled, alpha } from '@material-ui/core'
import { grey, blue } from '@material-ui/core/colors'
import RuleTextField from './RuleTextField'
import { simplifyString } from '../../../utilities/utilities'


const DarkSwitch = styled(Switch)(({ theme }) => ({
	// '& .MuiSwitch-switchBase': {
	//   color: grey[300],
	//   '&:hover': {
	// 	backgroundColor: alpha(grey[300], theme.palette.action.hoverOpacity),
	//   },
	// },
	// '& .MuiSwitch-switchBase + .MuiSwitch-track': {
	//   backgroundColor: grey[300],
	// },
	// '& .MuiSwitch-switchBase.Mui-checked': {
	// 	color: blue[600],
	// 	'&:hover': {
	// 	  backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
	// 	},
	//   },
	//   '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
	// 	backgroundColor: blue[600],
	//   },
}));


const HelperText = ({
	text,
	disabled
}) => {
	const theme = useTheme()

	return (
		<Typography
			variant="subtitle1"
			style={{
				fontSize: '14px',
				color: disabled ? theme.palette.grey[500] : theme.palette.grey[100],
				fontWeight: '100'
			}}
		>
			{text}
		</Typography>
	)
}

const ConditionsRow = ({
	ruleValidator,
	getRuleDescription,
	onSubmit,
	onDiscard,
	intl,
	initialValues,
	style
}) => {
	const theme = useTheme()
	const [currentValues, setCurrentValues] = useState(null)
	const [isConditionalVisibility, setIsConditionalVisibility] = useState(null)
	const [visibilityError, setVisibilityError] = useState(undefined)
	const [visibilityRuleDescription, setVisibilityRuleDescription] = useState(undefined)
	const [optionalError, setOptionalError] = useState(undefined)
	const [optionalRuleDescription, setOptionalRuleDescription] = useState(undefined)
	const [intervalId, setIntervalId] = useState(null)

	useEffect(
		() => {
			if (!!currentValues && !visibilityRuleDescription && !!getRuleDescription) {
				resetVisibilityDescription(currentValues.visibilityRule)
			}
		},
		[!!currentValues && currentValues.visibilityRule, getRuleDescription]
	)

	useEffect(
		() => {
			if (!!currentValues && !optionalRuleDescription && !!getRuleDescription) {
				resetOptionalDescription(currentValues.optionalRule)
			}
		},
		[!!currentValues && currentValues.optionalRule, getRuleDescription]
	)

	useEffect(
		() => {
			if (!!initialValues && !currentValues) {
				setCurrentValues(initialValues)
				setIsConditionalVisibility(initialValues.isConditionalVisibility)
			}
		},
		[initialValues]
	)

	const handleIsConditionalVisibilityChange = (event) => {
		let fieldId = event.target.id
		let checked = event.target.checked
		currentValues[fieldId] = checked
		setIsConditionalVisibility(checked)
	}

	const resetVisibilityDescription = async(rule) => {
		if (getRuleDescription) {
			try {
				setVisibilityRuleDescription(getRuleDescription(simplifyString(rule), true))
			}
			catch (err) {
				setVisibilityRuleDescription(undefined)
				setVisibilityError(err)
			}
		}
	}

	const setDescription = (value, resetDescriptionFunction) => {
		if (!!intervalId) {
			clearInterval(intervalId)
		}
		setIntervalId(setTimeout(
			() => {
				clearTimeout(intervalId)
				setIntervalId(null)
				resetDescriptionFunction(value)
			},
			500
		))
	}

	const onVisibilityRuleChange = (event) => {
		let fieldId = event.target.id
		currentValues[fieldId] = event.target.value
		setVisibilityError(undefined)
		setDescription(event.target.value, resetVisibilityDescription)
	}

	const resetOptionalDescription = async(rule) => {
		if (getRuleDescription) {
			try {
				setOptionalRuleDescription(getRuleDescription(simplifyString(rule), false))
			}
			catch (err) {
				setOptionalRuleDescription(undefined)
				setOptionalError(err)
			}
		}
	}

	const onOptionalRuleChange = (event) => {
		let fieldId = event.target.id
		currentValues[fieldId] = event.target.value
		setOptionalError(undefined)
		setDescription(event.target.value, resetOptionalDescription)
	}

	const onSubmitForm = () => {
		if (!!currentValues) {
			currentValues.visibilityRule = simplifyString(currentValues.visibilityRule)
			currentValues.optionalRule = simplifyString(currentValues.optionalRule)
		}
		
		let vErr = undefined
		let oErr = undefined
		if (isConditionalVisibility && !currentValues.visibilityRule && !currentValues.optionalRule) {
			vErr = 'Required if Optional Rule field is also empty'
			oErr = 'Required if Visibility Rule field is also empty'
		}
		if (currentValues?.visibilityRule) {
			vErr = ruleValidator(currentValues.question, currentValues.visibilityRule)
		}
		if (currentValues.optionalRule) {
			oErr = ruleValidator(currentValues.question, currentValues.optionalRule)
		}
		setVisibilityError(vErr)
		setOptionalError(oErr)
		if (!vErr && !oErr) {
			onSubmit(currentValues)
		}
	}

	const onDiscardChanges = () => {
		onDiscard(currentValues.question)
	}

	return (
		<div
			style={{
				padding: '1rem',
				backgroundColor: theme.palette.primary.link,
				...style
			}}
		>
			{
				!!currentValues
				&& <Grid
					container
					direction="column"
					justify="flex-start"
					alignItems="center"
				>
					<Grid
						container
						direction="column"
						spacing={3}
						style={{
							margin: '0',
							width: '100%'
						}}
					>
						<Grid container direction="row" alignItems="center" style={{ paddingLeft: '20px' }}>
							<Grid item>
								<Typography
									variant="subtitle1"
									style={{
										fontSize: '16px',
										lineHeight: '16px',
										letterSpacing: '2.08px',
										color: 'white',
										fontWeight: '400'
									}}
								>
									{intl.formatMessage({ id: 'app.survey.isConditionalVisibilityTitle' })}
								</Typography>
							</Grid>
							<Grid item>
								<DarkSwitch
									id="isConditionalVisibility"
									onChange={handleIsConditionalVisibilityChange}
									checked={isConditionalVisibility}
									value={isConditionalVisibility}
									inputProps={{ 'aria-label': 'controlled' }}
								/>
							</Grid>
						</Grid>
						<Grid
							item
						>
							<RuleTextField
								id="visibilityRule"
								label={intl.formatMessage({ id: 'app.survey.visibilityRuleTitle' })}
								fullWidth
								type="text"
								value={currentValues.visibilityRule}
								maxLength={254}
								variant="filled"
								disabled={!isConditionalVisibility}
								error={visibilityError}
								helperText={visibilityError}
								onChange={onVisibilityRuleChange}
							/>

							{
								visibilityRuleDescription
								&& <HelperText
									text={`Description: ${visibilityRuleDescription}`}
									disabled={!isConditionalVisibility}
								/>
							}
						</Grid>
						{
							!currentValues.question.isBundle
							&& currentValues.question.questionType !== 8
							&& <Grid item >
								<RuleTextField
									id="optionalRule"
									label={intl.formatMessage({ id: 'app.survey.optionalRuleTitle' })}
									fullWidth
									type="text"
									value={currentValues.optionalRule}
									maxLength={254}
									variant="filled"
									disabled={!isConditionalVisibility}
									error={optionalError}
									helperText={optionalError}
									onChange={onOptionalRuleChange}
								/>
								{
									<HelperText
										text={optionalRuleDescription}
										disabled={!isConditionalVisibility}
									/>
								}
							</Grid>
						}
					</Grid>
					<Grid container xs={12} spacing={3} style={{marginTop: '5px', marginBottom: '2px', flex: 1, display: 'flex', justifyContent: 'flex-end'}}>
						<Grid item >
							<Button
								variant="outlined"
								color="primary"
								onClick={onDiscardChanges}
							>
								{intl.formatMessage({ id: 'app.survey.discardConditionsButtonLabel' })}
							</Button>
							</Grid>
							<Grid item >
							<Button
								variant="contained"
								color="primary"
								disabled={!!intervalId}
								onClick={onSubmitForm}
							>
								{intl.formatMessage({ id: 'app.survey.applyConditionsButtonLabel' })}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			}
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	initialValues: ownProps.initialValues
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl
)

export default enhance(ConditionsRow)
