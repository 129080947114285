import React, { Fragment, useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import DeleteIcon from '@material-ui/icons/Delete'
import { useTheme } from '@material-ui/core/styles'
import BundleCollectionAutoComplete from './BundleCollectionAutoComplete'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'

const useStyles = makeStyles((theme) => ({
	root: {
		color: 'rgba(0,0,0,0.6)'
	},
	label: {
		textTransform: 'capitalize',
		color: 'rgba(0,0,0,0.6)'
	},
	focused: {
		display: 'none'
	}
}))

const SearchBundle = ({ position, onCancelCallback, onAddCallback, intl }) => {
	const theme = useTheme()
	const [ addBundle, setAddBundle ] = useState(false)
	const [ addedBundle, setAddedBundle ] = useState(null)

	const handleBundleSelected = (group) => {
		setAddBundle(true)
		setAddedBundle(group)
	}
	const handleBundleCleared = () => {
		setAddBundle(false)
	}

	const addBundleCallback = () => {
		if (addedBundle) {
			onAddCallback(addedBundle.id, position)
		}
	}

	return (
		<Grid container wrap="nowrap" style={{ padding: '16px', minHeight: '100px' }}>
			<Grid item xs style={{display: 'flex', alignItems: 'center', paddingRight: '16px'}}>
				<IconButton onClick={onCancelCallback}>
					<DeleteIcon />
				</IconButton>
			</Grid>		
			<Grid item style={{ flexGrow: '100', border: 'dashed 1px black', padding: '16px', borderRadius: '5px' }}>
				<Grid container alignItems="center">
					<Grid item xs style={{ flexGrow: '100' }}>
						<BundleCollectionAutoComplete
							label={intl.formatMessage({ id: 'app.bundles.search' })}
							onQuestionSelectedCallback={handleBundleSelected}
							onQuestionClearedCallback={handleBundleCleared}
						/>
					</Grid>
					<Grid item style={{ paddingLeft: '16px' }}>
						<Button
							veriant="text" onClick={addBundleCallback}>
								ADD
							<AddCircleOutlineIcon
								style={{
									fontSize: '24px',
									color: theme.palette.secondary.A700,
									fontWeight: '400',
									marginLeft: '5px'
								}}
							/>
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

const enhance = compose(injectIntl)

export default enhance(SearchBundle)
