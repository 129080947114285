import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import DefaultFormTextField from '../../DefaultFormTextField'
import { uuidv4 } from '../../../utilities/utilities'
import AnswerContainer from './AnswerContainer'
import { ListContainer } from '../../../utilities/linkedList'
import { mustBeLessThanValue, required } from '../../../utilities/validators'
import FormFilledInputSelectLight from '../../FormFilledInputSelectLight'
import { useDispatch, useStore } from 'react-redux'
import { change, formValueSelector } from 'redux-form'
import { useIntl } from 'react-intl'
import FormCheckBox from '../../FormCheckBox'
import PIDataToggle from '../../PIDataToggle'

const selector = formValueSelector('createQuestion')
const requiredValidator = required
const lowerLessThanMaxValueValidator = mustBeLessThanValue('range')

export default forwardRef((props, ref) => {
	const intl = useIntl()
	const [ answers, setAnswers ] = useState()
	const [ minimumValue, setMinimumValue ] = useState(1)
	const [ range, setRange ] = useState(2)
	const [ answerList, setAnswerList ] = useState(new ListContainer())
	const store = useStore()
	const dispatch = useDispatch()

	useImperativeHandle(ref, () => ({
		getQuestion() {
			let a = []
			if (answers) {
				let i = 0
				answerList.getList().traverse((r) => {
					let answer = r.data.ref.current.getAnswer()

					a.push({ answer, sortOrder: i })

					i++
				})
			}

			return {
				id: uuidv4().replace('-', ''),
				title: selector(store.getState(), 'question'),
				type: 'grouped',
				minimumValue: selector(store.getState(), 'minimumValue'),
				range: selector(store.getState(), 'range'),
				instructions: selector(store.getState(), 'instructions'),
				tag: selector(store.getState(), 'tag'),
				isOptional: selector(store.getState(), 'isOptional'),
				isDropdown: selector(store.getState(), 'isDropdown'),
				answers: a,
				isPiData: selector(store.getState(), 'isPiData'),
				displayInstructionAsIcon: selector(store.getState(), 'displayInstructionAsIcon'),
			}
		}
	}))

	const onDeleteAnswerClick = (answer) => {
		if (answer) {
			let x = answer.data.id
			answerList.getList().remove(answer)
			setAnswerList(answerList)

			var a = answers.filter((r) => r.data.id !== x)
			setAnswers(a)
		}
	}

	const swapAnswer = (nodeA, nodeB) => {
		if (answerList) {
			answerList.getList().swap(nodeA, nodeB)

			let questionAnswers = new Array(0)

			answerList.getList().traverse((r) => {
				questionAnswers.push(r)
			})
			setAnswerList(answerList)
			setAnswers(questionAnswers)
		}
	}

	useEffect(() => {
		onAddNewAnswer()
	}, [])

	const onAddNewAnswer = () => {
		let answer = {}
		answer.id = uuidv4().replace('-', '')

		if (answerList) {
			answerList.getList().append(answer)
			let questionAnswers = new Array(0)

			answerList.getList().traverse((r) => {
				questionAnswers.push(r)
			})
			setAnswerList(answerList)

			setAnswers(questionAnswers)
		}
	}

	useEffect(() => {
		var el = document.getElementsByName('tag')
		if (el != null) {
			el[0].focus()
		}
	}, [])
	return (
		<Grid container direction="column" alignItems="flex-start">
			<Grid item style={{ width: '100%' }}>
				<PIDataToggle defaultValue={false} onChange={(value) => dispatch(change('createQuestion', 'isPiData', value))}/>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				<DefaultFormTextField
					label="Tag"
					variant="filled"
					validators={[requiredValidator]}
					fullWidth
					maxLength={254}
					name="tag"
				/>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				<DefaultFormTextField
					label="Title"
					variant="filled"
					fullWidth
					maxLength={1023}
					name="question"
					validators={[ requiredValidator ]}
				/>
			</Grid>
			<Grid item style={{ width: '100%', marginTop: '10px' }}>
				<DefaultFormTextField
					label="Instructions"
					variant="filled"
					fullWidth
					name="instructions"
					inputProps={{ maxLength: 2047 }}
					maxLength={2047}
					multiline
					rows="3"
				/>
			</Grid>
			<Grid item style={{ width: '100%', marginTop: '10px' }}>
				<FormCheckBox
					name="displayInstructionAsIcon"
					color="primary"
					label={intl.formatMessage({ id: 'app.survey.questions.displayInstructionAsIcon' })}
				/>
			</Grid>
			<Grid item style={{ width: '100%', marginTop: '10px' }}>
				<FormCheckBox
					name="isOptional"
					color="primary"
					label={intl.formatMessage({ id: 'app.survey.questions.isOptional' })}
				/>
			</Grid>
			<Grid item style={{ width: '100%', marginTop: '10px' }}>
				<FormCheckBox
					name="isDropdown"
					color="primary"
					label={intl.formatMessage({ id: 'app.survey.questions.displayAsDropdown' })}
				/>
			</Grid>
			<Grid item style={{ width: '40%', marginTop: '20px' }}>
				<FormFilledInputSelectLight
					placeholder="Minimum"
					label="Minimum"
					name="minimumValue"
					initialValue={minimumValue}
					validators={[ requiredValidator, lowerLessThanMaxValueValidator ]}
					onChangeCallback={setMinimumValue}
					data={
						Array(15)
							.fill()
							.map((_v, i) => {
								return {
									name: i,
									value: i
								}
							})
					}
					style={{ color: 'black' }}
					fullWidth
				/>
			</Grid>
			<Grid item style={{ width: '40%', marginTop: '20px' }}>
				<FormFilledInputSelectLight
					placeholder="Maximum"
					label="Maximum"
					name="range"
					initialValue={range}
					validators={[ requiredValidator ]}
					onChangeCallback={setRange}
					data={
						Array(15)
							.fill()
							.map((_v, i) => {
								return {
									name: i + 1,
									value: i + 1
								}
							})
					}
					style={{ color: 'black' }}
					fullWidth
				/>
			</Grid>
			<Grid item>
				<Typography variant="h6" style={{ fontWeight: '400', padding: '10px 0' }}>
					{intl.formatMessage({ id: 'app.survey.groupedQuestion.categorySubtitleText' })}
				</Typography>
			</Grid>

			{answers &&
				answers.map((r, i) => {
					return (
						<Grid key={r.data.id} item style={{ width: '100%', padding: '10px 0 10px 30px ' }}>
							<AnswerContainer
								key={r.data.id}
								answer={r}
								onDeleteAnswerCallback={onDeleteAnswerClick}
								onSwapRequestedCallback={swapAnswer}
							/>
						</Grid>
					)
				})}

			<Grid item style={{ width: '100%', paddingTop: '16px', alignSelf: 'center' }}>
				<Button style={{ margin: '0' }} variant="outlined" fullWidth color="secondary" onClick={onAddNewAnswer}>
					{intl.formatMessage({ id: 'app.survey.groupedQuestion.addCategoryButtonText' })}
				</Button>
			</Grid>
		</Grid>
	)
})
