import { dispatch } from 'd3'
import { getUser } from '../utilities/userUtilities'
import { authenticatedFetch } from './base'

export const search = (searchPhrase, offset) => {
	var url = `/api/subscriber`
	let urlHasParams = false

	if (searchPhrase && searchPhrase.length > 0) {
		url = `${url}?searchPhrase=${encodeURIComponent(searchPhrase)}`
		urlHasParams = true
	}

	if(offset){
		url += `${urlHasParams ? '&' : '?'}offset=${offset}`
		urlHasParams = true
	}

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const getStatisticsExport = (id) => {
	return authenticatedFetch(`/api/users/${id}/export`, { method: 'GET' })
		.then(function (response) {
			if (response.status === 200) {
				const header = response.headers.get("content-disposition")
				if(!header) {
					return { isSuccessful: false }
				}
				
				const fileName = header.split('"')[1];
				return response.blob().then(blob => [fileName, blob])
			}
		})
		.then(function ([filename, blob]) {
			try {
				var element = document.createElement('a')
				element.setAttribute('href', URL.createObjectURL(blob))
				element.setAttribute('download', filename)

				element.style.display = 'none'
				document.body.appendChild(element)

				element.click()

				document.body.removeChild(element)
				return { isSuccessful: true }
			} catch (e) {
				console.error(e)
				return { isSuccessful: false }
			}
		})
}

export const resendInvitation = (email) => {
	return authenticatedFetch(`/api/subscriber/resend?email=${encodeURIComponent(email)}`, { method: 'POST' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const activateApi = (userId) => {
	return authenticatedFetch(
		`/api/subscriber/activateApi`,
		{
			method: 'post',
			headers: { 'content-type': 'application/json' },
			body: JSON.stringify(
				{
					userId: userId
				}
			)
		}
	)
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const deactivateApi = (userId) => {
	return authenticatedFetch(
		`/api/subscriber/deactivateApi`,
		{
			method: 'post',
			headers: { 'content-type': 'application/json' },
			body: JSON.stringify(
				{
					userId: userId
				}
			)
		}
	)
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const exportSubscribers = (filters) => {
	return authenticatedFetch(`/api/users/exportSubscribers`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(
			{
				...filters
			}
		)
	})
		.then(function (response) {
			if (response.status === 200) {
				const header = response.headers.get("content-disposition")
				if (!header) {
					return { isSuccessful: false }
				}

				const fileName = header.split('"')[1];
				return response.blob().then(blob => [fileName, blob])
			}
		})
		.then(function ([filename, blob]) {
			try {
				var element = document.createElement('a')
				element.setAttribute('href', URL.createObjectURL(blob))
				element.setAttribute('download', filename)

				element.style.display = 'none'
				document.body.appendChild(element)

				element.click()

				document.body.removeChild(element)
				return { isSuccessful: true }
			} catch (e) {
				console.error(e)
				return { isSuccessful: false }
			}
		})
}