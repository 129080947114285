import React, { lazy, Suspense } from 'react'
import componentLoader from '../../utilities/componentLoader'

const ServiceProviderDetails = lazy(() => componentLoader(() => import('./components/ServiceProviderDetails')))

export default () => (
	<div>
		<Suspense fallback={<div/>}>
			<section>
				<ServiceProviderDetails />
			</section>
		</Suspense>
	</div>
)
