import React, { useEffect, useState } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { compose } from 'recompose'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { useLocation } from 'react-router-dom'
import { signIn, resetFailedLogin } from '../../redux/actions'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import FormTextField from '../../components/FormTextField'
import { reduxForm } from 'redux-form'
import { required } from '../../utilities/validators'
import MobileDetect from 'mobile-detect'
import '../../styles/index.scss'
import { injectIntl } from 'react-intl'
import queryString from 'query-string'

const styles = {
	root: {},
	input: {
		color: 'white',
		fontSize: '1.7rem',
		backgroundColor: 'rgb(36, 48, 65)'
	},

	root: {
		color: 'white',
		backgroundColor: 'rgb(36, 48, 65)',
		letterSpacing: '2px'
	},
	focused: {
		color: 'white',
		backgroundColor: 'rgb(36, 48, 65)',
		'&$focused': {
			color: 'white',
			backgroundColor: 'rgb(36, 48, 65)'
		}
	}
}

const Login = ({ login, handleSubmit, intl, resetError }) => {
	const theme = useTheme()
	const location = useLocation()
	const [ isMobile, setIsMobile ] = useState(new MobileDetect(window.navigator.userAgent))
	const [ returnPath, setReturnPath ] = useState(null)

	const onSubmitForm = (values) => {
		login(values.email, values.password, returnPath)
	}

	useEffect(() => {
		resetError()
		const values = queryString.parse(location.search)

		if (values.returnUrl) {
			setReturnPath(values.returnUrl)
		}
	}, [])

	return (
		<form
			onSubmit={handleSubmit(onSubmitForm)}
			style={{minWidth: isMobile.mobile() ? '200px' : '65rem' }}
		>
			<Grid
				id="test"
				container
				direction="column"
				alignItems="center"
				justify="center"
				style={{
					backgroundColor: theme.palette.secondary.main,
					padding: '10%',
					minWidth: isMobile.mobile() ? '200px' : '65rem',
					minHeight: '100%'
				}}
			>
				<Grid item>
					  <Typography
						variant="h1"
                        style={{
                            margin: '0 0 10px 0',
							color: '#fff',
							textAlign: 'center',
						}}
					>
						{intl.formatMessage({ id: 'app.login.title' })}
					</Typography>
				</Grid>
				<Grid item style={{ minWidth: '100%' }}>
					 <FormTextField
						label={intl.formatMessage({ id: 'app.login.email' })}
						fullWidth
						type="text"
						name="email"
						validators={[ required ]}
						variant="filled"
						maxLength={99}
					/>
				</Grid>
				<Grid item style={{ minWidth: '100%' }}>
					    <FormTextField
						label={intl.formatMessage({ id: 'app.login.password' })}
						fullWidth
						type="password"
						name="password"
						variant="filled"
						validators={[ required ]}
						maxLength={99}
					/>
				</Grid>
				<Grid item style={{ minWidth: '100%' }}>
					<Button
						to="/auth/forgotPassword"
						component={Link}
						style={{
							margin: '15px 0 0'
						}}
                        variant="text"
                        color="secondary"
					>
						{intl.formatMessage({ id: 'app.forgotPass.label' })}
					</Button>
				</Grid>
				<Grid item style={{ paddingTop: '10%', minWidth: '100%' }}>
					<Grid container direction="row" justify="center" alignItems="center" style={{ width: '100%' }}>
						{/* <Grid item style={{ paddingRight: '2%' }}>
							<Button
								component={Link}
								to="/auth/register"
								style={{
									color: '#007aff',
									fontSize: '1.5rem',
									width: '100px'
								}}
								variant="outlined"
							>
								{intl.formatMessage({ id: 'app.login.signupLabelText' })}
							</Button>
						</Grid> */}
						<Grid item>
							<Button
                                label="Password"
                                color="primary"
                                variant="contained"
								style={{
									width: '300px'
								}}
								type="submit"
							>
								{intl.formatMessage({ id: 'app.login.signinLabelText' })}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</form>
	)
}

const mapStateToProps = (state, ownProps) => ({
	failed: state.auth.failedLogin
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	login: (email, password, returnUrl) => {
		return dispatch(signIn(email, password, returnUrl))
	},
	resetError: () => dispatch(resetFailedLogin())
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'login'
	}),
	withStyles(styles)
)

export default enhance(Login)
