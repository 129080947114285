import React, { Fragment, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useTheme from '@material-ui/core/styles/useTheme'
import { investigatorsForProtocolVersionEntityAuditSelector, isSurveyQuestionUsedByCasesSelector } from '../../../redux/selectors'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import Button from '@material-ui/core/Button'
import { changeSurveyQuestionState, hideModal } from '../../../redux/actions'
import { QUESTION_STATE } from '../../../utilities/constants'
import EntityAuditCommentFields from '../../../components/entityAudit/EntityAuditCommentFields'
import { reduxForm } from 'redux-form'
import { isAuditCommentsRequired } from '../../../utilities/auditUtils'


const ChangeSurveyQuestionStateModal = ({
    surveyId,
    languageId,
    question,
    newState,
    allEntities,
    intl,
    changeSurveyQuestionState,
    closeModal,
	entityId,
	entityTypeId,
	handleSubmit,
	form,
	investigators
}) => {
	const theme = useTheme()

    const onChangeSurveyQuestionState = (values) => {
        localStorage.setItem('entityAuditCommentsPrefill', JSON.stringify({
			commentText: values.commentText,
			protocolVersionId: values.protocolVersionId,
			relatedTaskUrl: values.relatedTaskUrl,
			investigatorId: investigators && investigators.some((x) => x.id === values.investigatorId) ? values.investigatorId : null
		}))
        changeSurveyQuestionState(surveyId, languageId, question, newState, allEntities, values)
    }

    const getTitle = () => {
        let result = ""
        switch (newState) {
            case QUESTION_STATE.IN_USE:
                result = intl.formatMessage({ id: 'app.survey.setInUseQuestionModalTitle' })
                break
            case QUESTION_STATE.NOT_USED:
                result = intl.formatMessage({ id: 'app.survey.setNotInUseQuestionModalTitle' })
                break
            case QUESTION_STATE.ARCHIVED:
                result = intl.formatMessage({ id: 'app.survey.setArchivedQuestionModalTitle' })
                break
            default:
                break
        }

        return result
    }

    const getText = () => {
        let result = ""
        switch (newState) {
            case QUESTION_STATE.IN_USE:
                result = intl.formatMessage({ id: 'app.survey.setInUseQuestionRelatedEntitiesWarning' })
                break
            case QUESTION_STATE.NOT_USED:
                result = intl.formatMessage({ id: 'app.survey.setNotInUseQuestionRelatedEntitiesWarning' })
                break
            case QUESTION_STATE.ARCHIVED:
                result = intl.formatMessage({ id: 'app.survey.setArchivedQuestionRelatedEntitiesWarning' })
                break
            default:
                break
        }

        return result
    }

	return (
        <form onSubmit={handleSubmit(onChangeSurveyQuestionState)}>
		<Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            style={{ width: '75rem', padding: '4rem', margin: 'auto', backgroundColor: theme.palette.secondary.main }}
        >
            <Grid item style={{ alignSelf: 'center' }}>
                <Typography
                    variant="h1"
                    style={{
                        color: '#fff',
                        marginBottom: '25px',
                    }}
                >
                    {getTitle()}
                </Typography>
            </Grid>
            <Grid item style={{ alignSelf: 'flex-start' }}>
                <Typography
                    variant="body2"
                    style={{
                        color: '#fff',
                        paddingBottom: '25px'
                    }}
                    align="left"
                >
                    {getText()}
                </Typography>
            </Grid>
            { 
                isAuditCommentsRequired() && 
                <EntityAuditCommentFields entityId={entityId} entityTypeId={entityTypeId}  formName={form}/>
            }
            <Grid
                item
            >
                <Grid
                    container
                    direction='row'
                    justify='center'
                >
                    <Grid
                        item
                    >
                        <Button
                            style={{
                                width: '150px',
                                marginTop: '40px'
                            }}
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={closeModal}
                        >
                            {intl.formatMessage({ id: 'app.survey.changeQuestionStateButtonNo' })}
                        </Button>
                    </Grid>
                    <Grid
                        item
                    >
                        <Button
                            style={{
                                width: '150px',
                                marginTop: '40px'
                            }}
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            {intl.formatMessage({ id: 'app.survey.changeQuestionStateButtonYes' })}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        </form>
	)
}

const mapStateToProps = (state, ownProps) => ({
    isSurveyQuestionUsedByCases: isSurveyQuestionUsedByCasesSelector(state),
    investigators: investigatorsForProtocolVersionEntityAuditSelector(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	changeSurveyQuestionState: (surveyId, languageId, question, newState, allEntities, values) => {
        dispatch(changeSurveyQuestionState(surveyId, languageId, question, newState, values))
        dispatch(hideModal())
    },
    closeModal: () => dispatch(hideModal()),
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm ({
		form: 'changeSurveyQuestionState',
		touchOnChange: true,
		touchOnBlur: true
	})
)

export default enhance(ChangeSurveyQuestionStateModal)
