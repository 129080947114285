import React from 'react'
import Paper from '@material-ui/core/Paper'
import * as UI from '../utilities/UIConstants'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const LabelPaper = ({
	Component,
	SearchComponent,
	label,
	filters,
	filter,
	filterSelected,
	classes,
	minHeight = 200,
	...other
}) => (
	<div>
		<Paper elevation={UI.PAPER_ELEVATION} square={true} style={{ minHeight: minHeight, height: '100%' }} className={classes}>
			{label && (
				<Paper
					square={true}
					className="component-label-paper"
					data-testid="only-label-paper"
					style={{
						marginBottom: '0'
					}}
				>
					{!filters && (
						<Typography
							className="component-label-paper-title"
							data-testid="only-label-paper-title"
						>
							{label}
						</Typography>
					)}
					{filters && (
						<FormControl
							className="component-label-paper-title"
							data-testid="select-label-paper-title"
						>
							<Select
								value={filter}
								onChange={filterSelected}
								autoWidth
								displayEmpty={true}
								style={{ color: 'white', fontSize: '18px' }}
							>
								{filters.map((filter) => (
									<MenuItem value={filter.option} id={filter.option}>
										{filter.label} <KeyboardArrowDownIcon/>
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
				</Paper>
			)}
			{SearchComponent && (
				<div className="paper-search-inner" style={{ margin: '32px 32px 36px 32px' }}>
					<SearchComponent data-testid="component-label-paper-search" />
				</div>
			)}
			<div className="paper-inner" style={{ overflow: 'hidden', height: '100%' }}>
				<div data-testid="component-label-paper-content" style={{ margin: '0 32px 1%' }}>{Component && <Component {...other} />}</div>
            </div>
        </Paper>
	</div>
)

export default LabelPaper
