import React from "react"
import { FormattedDate, FormattedTime } from "react-intl"
import EntityAuditTableCell from "./EntityAuditTableCell"


const EntityAuditActionDateTableCell = ({entityLog, ...other}) => {
    return (
        <EntityAuditTableCell
            {...other}
        >
            { <FormattedDate value={entityLog.createDate} /> }
            <br />
            { <FormattedTime value={entityLog.createDate} /> }
        </EntityAuditTableCell>
    )
}

export default EntityAuditActionDateTableCell