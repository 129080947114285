import React, { useState } from 'react'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { lighten, makeStyles } from '@material-ui/core/styles'
import { sortAdminUserDirectionSelector, sortAdminUserFieldSelector } from '../../../redux/selectors'
import { setAdminUserSort } from '../../../redux/actions'
import { connect } from 'react-redux'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(3)
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2)
	},
	table: {
		minWidth: 750
	},
	tableWrapper: {
		overflowX: 'auto'
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1
	}
}))

const AdministratorsHeader = ({ intl, isDescending, changeSort, field }) => {
	return (
		<TableRow>
			<TableCell size="small" align="left" style={{ width: '4px', padding: 0 }} />
			{/* <TableCell align="left" sortDirection={false}>
				<TableSortLabel
					active={field === 'id'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('id', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="subtitle1"
						style={{ fontWeight: 400, letterSpacing: '1px', fontSize: '14px', color: '#232c3b' }}
					>
						{intl.formatMessage({ id: 'app.settings.users.userIdHeaderText' })}
					</Typography>
				</TableSortLabel>
			</TableCell> */}
			<TableCell align="left" sortDirection={false}>
				<TableSortLabel
					active={field === 'email'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('email', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="subtitle1"
						style={{ fontWeight: 400, letterSpacing: '1px', fontSize: '14px', color: '#232c3b' }}
					>
						{intl.formatMessage({ id: 'app.settings.users.emailHeaderText' })}
					</Typography>
				</TableSortLabel>
			</TableCell>
			<TableCell align="left">
				<TableSortLabel
					active={field === 'firstName'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('firstName', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="subtitle1"
						style={{ fontWeight: 400, letterSpacing: '1px', fontSize: '14px', color: '#232c3b' }}
					>
						{intl.formatMessage({ id: 'app.settings.users.nameHeaderText' })}
					</Typography>
				</TableSortLabel>
			</TableCell>

			<TableCell align="left" style={{ width: '10px', padding: '6px 0px 6px 0px' }} />
		</TableRow>
	)
}

const mapStateToProps = (state, ownProps) => ({
	isDescending: sortAdminUserDirectionSelector(state),
	field: sortAdminUserFieldSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	changeSort: (field, direction) => dispatch(setAdminUserSort(field, direction))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(AdministratorsHeader)
