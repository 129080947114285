import React from 'react'
import { Field } from 'redux-form'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FilledInput from '@material-ui/core/FilledInput'
import { makeStyles } from '@material-ui/core/styles'

const useLabelStyles = makeStyles((theme) => ({
	root: {
		color: 'white',
		transform: 'translate(0, 20px) scale(1)',
		overflow: 'visible'
	},
	icon: {
		color: 'white',
		height: '24px',
		width: '24px',
	},
	selectMenu: {
		color: 'white',
		backgroundColor: 'rgb(36, 48, 65)',
	},
	focused: {
		color: 'white',
	},
	shrink: {
		transform: 'translate(0, 8px) scale(0.85)',
		transformOrigin: 'top left',
	},
	error: {
		backgroundColor: 'transparent',
	},
}));

const renderSelectField = ({
	input,
	label,
	meta: { touched, error, invalid },
	children,
	name,
	labelStyles,
	floatLabel = true,
	...custom
}) => {
	return (
		<FormControl
			error={touched && error}
			style={{ padding: 0, backgroundColor: 'rgb(36, 48, 65)', color: 'white' }}
			fullWidth
		>
			<InputLabel
				htmlFor={name}
				shrink={Boolean(input.value) || input.active}
				classes={{
					root: labelStyles.root,
					focused: labelStyles.focused,
					shrink: labelStyles.shrink,
				}}
			>
				{label}
			</InputLabel>
			<Select
				fullWidth
				error={touched && invalid}
				errorText={touched && error}
				{...input}
				{...custom}
				inputProps={{
					name: name,
					id: name
				}}
				classes={{
					icon: labelStyles.icon
				}}
				input={
					<FilledInput
						id={name}
						name={name}
						style={{ padding: 0, color: 'white', backgroundColor: 'rgb(36, 48, 65)' }}
					/>
				}
			>
				{children}
			</Select>
			{touched && error && <div style={{
				color: '#f1493e',
				margin: '8px 16x',
				fontSize: '15px', 
				backgroundColor: '#011020'
			}}>{error}</div>}
		</FormControl>
	)
}

const FormSelect = ({ label, name, onChange, data, floatLabel = true, validators, ...other }) => {
	const labelStyles = useLabelStyles({ floatLabel });
	return (
		<Field
			component={renderSelectField}
			name={name}
			onChange={onChange}
			label={label}
			labelStyles={labelStyles}
			floatLabel={floatLabel}
			validate={validators}
			{...other}
		>
			{data &&
				data.map((r) => (
					<MenuItem key={r.value} value={r.value}>
						{r.name}
					</MenuItem>
				))}
		</Field>
	)
}

export default FormSelect
