import React, { useEffect, Fragment } from 'react'
import { useTheme, createMuiTheme } from '@material-ui/core/styles'
import { compose } from 'recompose'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { fetchTemplateTypes, fetchUsers, resetTerm } from '../../redux/actions'
import { adminUsersSelector } from '../../redux/selectors'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import CompositeLabelPaper from '../../components/CompositeLabelPaper'
import AdministratorsTable from './components/AdministratorsTable'
import AddAdministratorButton from './components/AddAdministratorButton'
import { injectIntl } from 'react-intl'
import SystemConfiguration from './components/SystemConfiguration'
import PatientTerms from './components/PatientTerms'
import ClinicianTerms from './components/ClinicianTerms'
import EmailTemplatesSection from './components/EmailTemplatesSection'
import PatientUpdateTriggerTemplatesSection from './components/PatientUpdateTriggerTemplatesSection'

const styles = {
	root: {},
	input: {
		color: 'white',
		fontSize: '1.7rem'
	}
}

const AdminUserOverview = ({ loadUsers, loadTerms, loadTemplateTypes, users, intl, terms }) => {
	const theme = useTheme()

	useEffect(() => {
		resetTerm()
		loadUsers()
	}, [])

	return (
		<div style={{ padding: '3% 7% 30px' }}>
			<Fragment>
				<Grid container alignItems="center" style={{ paddingBottom: '32px' }}>
					<Grid item>
						<Typography variant="h1">{intl.formatMessage({ id: 'app.settings.headerText' })}</Typography>
					</Grid>
				</Grid>
				<Grid
					container
					alignItems="flex-start"
					direction="column"
					style={{ paddingBottom: '32px', paddingTop: '32px', width: '100%' }}
				>
					<Grid item style={{ paddingBottom: '50px', width: '100%' }}>
						<CompositeLabelPaper label="System Configuration" Component={SystemConfiguration} />
					</Grid>
					<Grid item style={{ width: '100%' }}>
						<Grid
							container
							justify="flex-end"
							alignItems="center"
							style={{ paddingBottom: '32px', width: '100%' }}
						>
							<Grid item>
								<AddAdministratorButton />
							</Grid>
						</Grid>
					</Grid>
					<Grid item style={{ paddingBottom: '50px', width: '100%' }}>
						<CompositeLabelPaper
							label={intl.formatMessage({ id: 'app.settings.users.allUsers' })}
							Component={AdministratorsTable}
							data={users}
						/>
					</Grid>
					<Grid item style={{ width: '100%', paddingBottom: '50px' }}>
						<ClinicianTerms />
					</Grid>
					<Grid item style={{ width: '100%', paddingBottom: '50px' }}>
						<PatientTerms />
					</Grid>
					<Grid item style={{ width: '100%', paddingBottom: '50px' }}>
						<EmailTemplatesSection />
					</Grid>
					<Grid item style={{ width: '100%' }}>
						<PatientUpdateTriggerTemplatesSection />
					</Grid>
				</Grid>
			</Fragment>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	users: adminUsersSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	loadUsers: () => dispatch(fetchUsers())
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(AdminUserOverview)
