import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import LeftNavigation from '../components/LeftNavigation'
import PrivateRoute from '../routes/PrivateRoute'
import ModalContainer from '../components/ModalContainer'
import Snackbar from '../components/Snackbar'
import UserProfile from './userProfile'
import CircleDetails from './circleDetails'
import ServiceProviderDetails from './serviceProviderDetails'
import { ErrorBoundary } from '../components/ErrorBoundary'
import Protocols from './protocols'
import Protocol from './protocol'
import AdminUsers from './settings'
import Surveys from './surveys'
import SurveyDetail from './survey/components/SurveyDetail'
import Questions from './questions/components/Questions'
import Circles from './circles/components/Circles'
import Subscribers from './subscribers/components/Subscribers'
import Sponsors from './sponsors/components/Sponsors'
import UOMs from './unitsOfMeasures'
import UnitOfMeasure from './unitOfMeasure'
import Bundles from './bundles'
import Tools from './tools'
import ServiceProviders from './serviceProviders'
import Dashboard from './dashboard'
import TreatmentDetail from './treatment'
import Treatments from './treatments'
import PathologyDetail from './pathology'
import Pathologies from './pathologies'
import BundleV2 from './bundleV2/components/BundleV2'
import ScoringGroup from './scoringGroup/components/ScoringGroup'
import ScoringGroups from './scoringGroups'
import { compose } from 'recompose'
import withStyles from '@material-ui/core/styles/withStyles'
import { useDispatch } from 'react-redux'
import { getNewCompletedReports, getOverdueOperations } from '../redux/actions'
import OverdueOperationsHeader from '../components/OverdueOperationsHeader'
import ProtocolVersions from './protocol/components/ProtocolVersions'
import RelatedEntities from './relatedEntities'
import Reports from './reports'
import NewCompletedReportsNotificationHeader from '../components/NewCompletedReportsNotificationHeader'
import ReportExportInitiatedNotificationHeader from '../components/ReportExportInitiatedNotificationHeader'
import Question from './question/index'
import Imports from './imports'
import ProtocolVersionScoringGroupSettings from './protocol/components/ProtocolVersionScoringGroupSettings'

const styles = (theme) => ({
	mainContent: {
		position: 'relative',
		minWidth: '320px',
		backgroundColor: theme.palette.dark,
		overflowX: 'hidden',
		display: 'flex',
		[theme.breakpoints.up('lg')]: {
			minWidth: '1200px',
		},
		'& .navOpened': {
			minWidth: '264px',
			maxWidth: '264px',
			minHeight: '100vh',
            maxHeight: '100vh',
			backgroundColor: theme.palette.secondary.inputHover,
			zIndex: 1,
			transition: 'all 0.2s ease',
			overflow: 'hidden',
		},
		'& .navClosed': {
			backgroundColor: theme.palette.secondary.main,
			minHeight: '100vh',
			maxHeight: '100vh',
			minWidth: '0',
			maxWidth: '0',
			width: '0',
			transition: 'all 0.2s ease',
			overflow: 'hidden',
		}
	},
	mainContentInner: {
		minHeight: '100vh',
		maxHeight: '100vh',
		overflowX: 'hidden',
		flexGrow: '100',
		minWidth: '320px',
		zIndex: '2',
		'-webkit-overflow-scrolling': 'touch',
		[theme.breakpoints.down('sm')]: {
			paddingTop: '45px',
		},
		[theme.breakpoints.down('xs')]: {
			paddingTop: '31px',
		},
    },
    
	// Set css/scss variables colors, in the same palette as in theme.js
	'@global': {
		':root': {
			'--palette-light': theme.palette.light,
			'--palette-dark': theme.palette.dark,
			'--palette-lightCell': theme.palette.lightCell,
			'--palette-darkCell': theme.palette.darkCell,
			'--palette-blue': theme.palette.blue,
			'--palette-primary-light': theme.palette.primary.light,
			'--palette-primary-A100': theme.palette.primary.A100,
			'--palette-primary-A200': theme.palette.primary.A200,
			'--palette-primary-A300': theme.palette.primary.A300,
			'--palette-primary-main': theme.palette.primary.main,
			'--palette-primary-A500': theme.palette.primary.A500,
			'--palette-primary-A600': theme.palette.primary.A600,
			'--palette-primary-link': theme.palette.primary.link,
			'--palette-primary-A800': theme.palette.primary.A800,
			'--palette-primary-dark': theme.palette.secondary.main,
			'--palette-secondary-light': theme.palette.secondary.light,
			'--palette-secondary-A100': theme.palette.secondary.A100,
			'--palette-secondary-A200': theme.palette.secondary.A200,
			'--palette-secondary-A300': theme.palette.secondary.A300,
			'--palette-secondary-main': theme.palette.secondary.main,
			'--palette-secondary-contrast': theme.palette.secondary.contrast,
			'--palette-secondary-input': theme.palette.secondary.input,
			'--palette-secondary-inputHover': theme.palette.secondary.inputHover,
			'--palette-secondary-additional': theme.palette.secondary.additional,
			'--palette-secondary-A600': theme.palette.secondary.A600,
			'--palette-secondary-A700': theme.palette.primary.main,
			'--palette-secondary-A800': theme.palette.secondary.A800,
			'--palette-secondary-dark': theme.palette.secondary.dark,
			'--palette-text-primary': theme.palette.text.primary,
			'--palette-text-secondary': theme.palette.text.secondary,
			'--palette-text-disabled': theme.palette.text.disabled,
			'--palette-text-hint': theme.palette.text.hint,
			'--palette-background-default': theme.palette.background.default,
			'--palette-action-disabledBackground': theme.palette.action.disabledBackground,
			'--palette-action-disabled': theme.palette.action.disabled,
			'--palette-success-main': theme.palette.success.main,
			'--palette-error-main': theme.palette.error.main,
			'--palette-error-light': theme.palette.error.light,
			'--palette-warning-main': theme.palette.warning.main,
			'--palette-info-main': theme.palette.info.main,

		}
	}

})


const Root = ({ classes }) => {
	const dispatch = useDispatch()

	useEffect(
		() => {
			dispatch(getOverdueOperations())

			let timer = setInterval(() => dispatch(getOverdueOperations()), 60000)

			return () => {
				clearInterval(timer)
			}
		},
		[]
	)

	useEffect(
		() => {
			dispatch(getNewCompletedReports())

			let timer = setInterval(() => dispatch(getNewCompletedReports()), 30000)

			return () => {
				clearInterval(timer)
			}
		},
		[]
	)

	return (
		<div>
			<div className={ classes.mainContent }>

				<LeftNavigation />
				<Grid item className={ classes.mainContentInner } >
				<OverdueOperationsHeader/>
				<ReportExportInitiatedNotificationHeader/>
				<NewCompletedReportsNotificationHeader/>
					<ErrorBoundary>
						<PrivateRoute exact={true} path="/settings" component={AdminUsers} />
						<PrivateRoute exact={true} path="/userprofile/:userId" component={UserProfile} />
						{/* <PrivateRoute exact={true} path="/circles/" component={Circles} /> */}
						<PrivateRoute exact={true} path="/circles/:circleId" component={CircleDetails} />
						<PrivateRoute exact={true} path="/serviceProviders/:serviceProviderId" component={ServiceProviderDetails} />
						<PrivateRoute
							exact={true}
							path="/circles/:circleId/survey/:surveyId/instance/:instanceId"
							component={CircleDetails}
						/>
						<PrivateRoute exact={true} path="/protocols" component={Protocols} />
						<PrivateRoute exact={true} path="/protocols/:protocolId/version/:versionId" component={Protocol} />
						<PrivateRoute exact={true} path="/protocols/:protocolId/version/:versionId/details" component={Protocol} />
						<PrivateRoute exact={true} path="/protocols/:protocolId/version/:versionId/messages" component={Protocol} />
						<PrivateRoute exact={true} path="/protocols/:protocolId/version/:versionId/defaults" component={Protocol} />
						<PrivateRoute exact={true} path="/protocols/:protocolId/version/:versionId/communication" component={Protocol} />
						<PrivateRoute exact={true} path="/protocols/:protocolId/version/:versionId/relatedEntities" component={Protocol} />
						<PrivateRoute exact={true} path="/protocols/:protocolId/version/:versionId/sg/:sgId" component={ProtocolVersionScoringGroupSettings} />
						<PrivateRoute exact={true} path="/protocols/:protocolId/versions" component={ProtocolVersions} />
						<PrivateRoute exact={true} path="/protocols/:protocolId/details" component={Protocol} />
						<PrivateRoute exact={true} path="/protocols/:protocolId" component={Protocol} />
						<PrivateRoute exact={true} path="/surveys" component={Surveys} />
						<PrivateRoute exact={true} path="/surveys/:surveyId" component={SurveyDetail} />
						<PrivateRoute exact={true} path="/surveys/:surveyId/details" component={SurveyDetail} />
						<PrivateRoute exact={true} path="/questions" component={Questions} />
						<PrivateRoute exact={true} path="/questions/:questionId/type/:questionType/languages/:languageId" component={Question} />
						<PrivateRoute exact={true} path="/questions/:questionId/type/:questionType/details" component={Question} />
						<PrivateRoute exact={true} path="/circles" component={Circles} />
						<PrivateRoute exact={true} path="/serviceProviders" component={ServiceProviders} />
						{/* <PrivateRoute exact={true} path="/patients" component={Patients} /> */}
						<PrivateRoute exact={true} path="/subscribers" component={Subscribers} />
						<PrivateRoute exact={true} path="/sponsors" component={Sponsors} />
						<PrivateRoute exact={true} path="/reports" component={Reports} key={Math.random()} />
						<PrivateRoute exact={true} path="/imports" component={Imports} key={Math.random()} />
						<PrivateRoute exact={true} path="/imports/:importId/type/:type/operation/:operation/step/:step" component={Imports} key={Math.random()} />
						<PrivateRoute exact={true} path="/imports/:importId" component={Imports} />
						<PrivateRoute
							exact={true}
							path="/surveys/:surveyId/languages/:languageId"
							component={SurveyDetail}
						/>
						<PrivateRoute
							exact={true}
							path="/protocols/:protocolId/version/:versionId/survey/:surveyId"
							component={Protocol}
						/>
						<PrivateRoute
							exact={true}
							path="/protocols/:protocolId/version/:versionId/survey/:surveyId/instance/:instanceId"
							component={Protocol}
						/>
						<PrivateRoute exact={true} path="/units" component={UOMs} />
						<PrivateRoute exact={true} path="/dashboard" component={Dashboard} />
						<PrivateRoute path="/tools" component={Tools} />

						<PrivateRoute exact={true} path="/units/:unitId" component={UnitOfMeasure} />
						<PrivateRoute exact={true} path="/bundles/:bundleId/details" component={BundleV2} />
						<PrivateRoute exact={true} path="/bundles" component={Bundles} />
						{/* <PrivateRoute exact={true} path="/bundles/:bundleId" component={Bundle} /> */}
						<PrivateRoute exact={true} path="/bundles/:bundleId" component={BundleV2} />
						<PrivateRoute
							exact={true}
							path="/bundles/:bundleId/languages/:languageId"
							component={BundleV2}
						/>
						<PrivateRoute exact={true} path="/treatments" component={Treatments} />
						<PrivateRoute exact={true} path="/treatments/:treatmentId" component={TreatmentDetail} />
						<PrivateRoute exact={true} path="/pathologies" component={Pathologies} />
						<PrivateRoute exact={true} path="/pathologies/:pathologyId" component={PathologyDetail} />

						<PrivateRoute exact={true} path="/sg" component={ScoringGroups} />
						<PrivateRoute exact={true} path="/sg/:groupId" component={ScoringGroup} />

						<PrivateRoute exact={true} path="/relatedEntities" component={RelatedEntities} />
					</ErrorBoundary>

					</Grid>
				</div>

			<ModalContainer />
			<Snackbar />
		</div>

	)
}
const enhance = compose(withStyles(styles))

export default enhance(Root)
