import React, { lazy, Suspense } from 'react'
import componentLoader from '../../utilities/componentLoader'

const PathologyDetail = lazy(() => componentLoader(() => import('./components/PathologyDetail')))

export default () => (
	<div>
		<Suspense fallback={<div />}>
			<section>
				<PathologyDetail />
			</section>
		</Suspense>
	</div>
)
