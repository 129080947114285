import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { activateApi, deactivateApi } from '../../../redux/actions'

const ChangeApiStatusMenuItem = ({ intl, subscriber, onComplete }) => {
	const history = useHistory()
	const dispatch = useDispatch()
	const onClick = () => {
		if (!subscriber.isOpenApiEnabled) {
			dispatch(activateApi(subscriber))
		}
		else {
			dispatch(deactivateApi(subscriber))
		}
		onComplete()
	}

	return (
		<MenuItem
			onClick={() => {
				onClick()
			}}
		>
			{
				!subscriber.isOpenApiEnabled
					? intl.formatMessage({ id: "app.subscriber.activateApi" })
					: intl.formatMessage({ id: "app.subscriber.deactivateApi" })
			}
		</MenuItem>
	)
}

const enhance = compose(injectIntl)

export default enhance(ChangeApiStatusMenuItem)
