import React from 'react'
import { Grid, TableCell, TableRow, Typography } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import { compose } from 'recompose'
import withStyles from '@material-ui/core/styles/withStyles'
import TableBody from '@material-ui/core/TableBody'
import { CircularProgress } from '@material-ui/core'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { connect } from 'react-redux'
import { hasMoreProtocolVersionsSelector, isLoadingProtocolVersionsSelector } from '../../../redux/selectors'
import { fetchAdditionalProtocolVersions } from '../../../redux/actions'
import { Link } from 'react-router-dom'

const styles = {
	root: {
		background: 'black'
	},
	input: {
		color: 'white',
		backgroundColor: 'rgb(36, 48, 65)'
	}
}

const VersionTable = ({ data, search, hasMore, getVersions, loading, protocolId, showArchived }) => {

	const getAdditionalVersions = () => {
		getVersions(protocolId, search, data.length)
	}

	const [sentryRef] = useInfiniteScroll({
		loading,
		hasNextPage: hasMore,
		onLoadMore: getAdditionalVersions,
		delayInMs: 1000
	});

	return (
		<div className="admin-table-fullheight">
			<Table>
				<TableBody>
					{data && data.map((version) => (
						<TableRow key={version.id}
							style={{
								backgroundColor: version.isArchived ? '#F6F6F6' : 'white'
							}}
						>
							<TableCell component="td" scope="row"
								style={{paddingLeft: '0px'}}
							>
								<Grid
									container
									direction="row"
									spacing={1}									
									
								>
									<Grid item>
										<div
											style={{
												width: '3px',
												height: '100%',
												backgroundColor: version.isArchived ? '#bbb' : '#007aff'
											}}
										/>
									</Grid>
									<Grid item>
										<Link
											to={`/protocols/${protocolId}/version/${version.id}/details`}
											style={{
												display: 'block',
												textDecoration: 'none',
												fontWeight: '700',
												fontSize: '17px',
												lineHeight: '20px',
												letterSpacing: '0.16px',
												color: '#011020',
												
											}}
										>
											<Typography variant="body2">
												{version.name}
											</Typography>
										</Link>
									</Grid>
									
								</Grid>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			{data && data.length > 0 && (loading || hasMore) && (
				<div className="admin-table-progress" ref={sentryRef}>
					<CircularProgress />
				</div>
			)}
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	loading: isLoadingProtocolVersionsSelector(state),
	hasMore: hasMoreProtocolVersionsSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	getVersions: (id, search, offset) => dispatch(fetchAdditionalProtocolVersions(id, search, ownProps.showArchived, offset))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))

export default enhance(VersionTable)
