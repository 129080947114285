import React from 'react'
import { reduxForm } from 'redux-form'
import { compose, shallowEqual } from 'recompose'
import { injectIntl } from 'react-intl'
import { connect, useDispatch, useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { phoneNumber } from '../../utilities/validators'
import { isSavingSelector } from '../../redux/selectors'
import { Typography, useTheme } from '@material-ui/core'
import { PhoneNumberField } from '../../components/PhoneNumberField'
import CalmButton from '../../components/CalmButton'
import { sendTemplateSms, startSaving } from '../../redux/actions'

const phoneNumberValidator = phoneNumber

const SendTestSmsModal = ({ intl, handleSubmit, languageId, type, versionId, surveyInstanceId, sendSms }) => {
	const theme = useTheme()
	const dispatch = useDispatch()
	const isSaving = useSelector(isSavingSelector, shallowEqual)

	const onSubmitForm = (values) => {
		dispatch(startSaving())
		sendSms(versionId, surveyInstanceId, languageId, type, values.phoneNumber)
	}

	return (
		<form onSubmit={handleSubmit(onSubmitForm)}>
			<Grid
				container
				direction="column"
				className="admin-modal"
			>
				<Grid item style={{ width: '100%', textAlign: 'center' }}>
					<Typography
						variant="h1"
						style={{ color: 'white', paddingTop: '48px', paddingBottom: '32px' }}
					>
						{intl.formatMessage({ id: 'app.template.sendSmsHeader' })}
					</Typography>
				</Grid>

				<Grid item style={{ width: '100%', textAlign: 'center' }}>
					<PhoneNumberField
						label={intl.formatMessage({ id: 'app.general.phone' })}
						name='phoneNumber'
						phoneField='phoneNumber'
						validators={[phoneNumberValidator]}
					/>
				</Grid>
				<Grid
					item
					style={{
						width: '100%',
						marginTop: '10px',
						textAlign: 'center'
					}}
				>
                    <CalmButton
                        variant="contained"
                        color="primary"
						style={{
							width: '200px'
						}}
						type="submit"
						size="large"
						disabled={isSaving}
					>
						{intl.formatMessage({ id: 'app.template.sendSmsSubmitButton' })}
					</CalmButton>
				</Grid>
			</Grid>
		</form>
	)
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => ({
	sendSms: (versionId, surveyInstanceId, languageId, type, phoneNumber) => {
		dispatch(sendTemplateSms(versionId, surveyInstanceId, languageId, type, phoneNumber))
	}
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: 'sendTestSms'
	}),
	injectIntl
)

export default enhance(SendTestSmsModal)
