import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from './Button'
import BackspaceIcon from '@material-ui/icons/Backspace'
import { useTheme } from '@material-ui/core/styles'

export default ({ onAddOperator, onAddConstant, onBackspace, onClear }) => {
	const theme = useTheme()

	return (
		<div style={{ }}>
			<Grid container direction="column">
				<Grid item>
					<Grid container direction="column">
						<Grid item>
							<Grid container>
								<Grid item style={{ flex: 0.25 }}>
									<Button label="C" onClick={() => onClear()} />
								</Grid>
								<Grid item style={{ flex: 0.25 }}>
									<Button label="(" onClick={() => onAddOperator('(')} />
								</Grid>
								<Grid item style={{ flex: 0.25 }}>
									<Button label=")" onClick={() => onAddOperator(')')} />
								</Grid>
								<Grid item style={{ flex: 0.25 }}>
									<Button
										label="&nbsp;"
										onClick={onBackspace}
										backgroundColor={theme.palette.secondary.A100}
									>
										<BackspaceIcon style={{ fontSize: '24px' }} />
									</Button>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<Grid container>
								<Grid item style={{ flex: 0.25 }}>
									<Button label="7" onClick={() => onAddConstant('7')} />
								</Grid>
								<Grid item style={{ flex: 0.25 }}>
									<Button label="8" onClick={() => onAddConstant('8')} />
								</Grid>
								<Grid item style={{ flex: 0.25 }}>
									<Button label="9" onClick={() => onAddConstant('9')} />
								</Grid>
								<Grid item style={{ flex: 0.25 }}>
									<Button
										label="/"
										onClick={() => onAddOperator('/')}
										backgroundColor={theme.palette.secondary.A100}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<Grid container>
								<Grid item style={{ flex: 0.25 }}>
									<Button label="4" onClick={() => onAddConstant('4')} />
								</Grid>
								<Grid item style={{ flex: 0.25 }}>
									<Button label="5" onClick={() => onAddConstant('5')} />
								</Grid>
								<Grid item style={{ flex: 0.25 }}>
									<Button label="6" onClick={() => onAddConstant('6')} />
								</Grid>
								<Grid item style={{ flex: 0.25 }}>
									<Button
										label="X"
										onClick={() => onAddOperator('*')}
										backgroundColor={theme.palette.secondary.A100}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<Grid container>
								<Grid item style={{ flex: 0.25 }}>
									<Button label="1" onClick={() => onAddConstant('1')} />
								</Grid>
								<Grid item style={{ flex: 0.25 }}>
									<Button label="2" onClick={() => onAddConstant('2')} />
								</Grid>
								<Grid item style={{ flex: 0.25 }}>
									<Button label="3" onClick={() => onAddConstant('3')} />
								</Grid>
								<Grid item style={{ flex: 0.25 }}>
									<Button
										label="-"
										onClick={() => onAddOperator('-')}
										backgroundColor={theme.palette.secondary.A100}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<Grid container>
								<Grid item style={{ flex: 0.25 }}>
									<Button label="0" onClick={() => onAddConstant('0')} />
								</Grid>

								<Grid item style={{ flex: 0.5 }}>
									<Button label="." onClick={() => onAddOperator('.')} />
								</Grid>
								<Grid item style={{ flex: 0.25 }}>
									<Button
										label="+"
										onClick={() => onAddOperator('+')}
										backgroundColor={theme.palette.secondary.A100}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	)
}
