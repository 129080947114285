import React, { useState } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { compose } from 'recompose'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { requestForgotPasswordEmail } from '../../redux/actions'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import FormTextField from '../../components/FormTextField'
import { reduxForm } from 'redux-form'
import { required } from '../../utilities/validators'
import MobileDetect from 'mobile-detect'
import * as S from '../../utilities/StringConstants'
import '../../styles/index.scss'
import { injectIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
const styles = {
	root: {},
	input: {
		color: 'white',
		fontSize: '1.7rem'
	}
}

const ForgotPassword = ({ submitRequest, handleSubmit, intl }) => {
	const theme = useTheme()
	const history = useHistory()
	const [ isMobile, setIsMobile ] = useState(new MobileDetect(window.navigator.userAgent))
	const onSubmitForm = (values) => {
		submitRequest(values.email)
	}

	return (
		<form
			onSubmit={handleSubmit(onSubmitForm)}
			style={{ minWidth: isMobile.mobile() ? '200px' : '65rem', padding: '10%' }}
		>
			<Grid
				container
				direction="column"
				alignItems="center"
				justify="center"
				style={{
					backgroundColor: theme.palette.secondary.main,
					minWidth: isMobile.mobile() ? '200px' : '65rem',
					padding: '10%'
				}}
			>
				<Grid item style={{ marginBottom: '60px' }}>
					<Typography
						variant="h1"
						style={{
							color: '#fff',
							textAlign: 'center',
						}}
					>
						{intl.formatMessage({ id: 'app.forgotPass.title' })}
					</Typography>
				</Grid>
				<Grid
					item
					style={{
						width: '100%',
						paddingBottom: '10px'
					}}
				>
					<FormTextField
						label={intl.formatMessage({ id: 'app.login.email' })}
						fullWidth
						type="text"
						name="email"
						validators={[ required ]}
						variant="filled"
					/>
				</Grid>

				<Grid
					item
					style={{
						width: '100%'
					}}
				>
					<Link
						to="/auth/login"
						style={{
							color: '#fff',
							textDecoration: 'none',
							textAlign: 'left',
							fontSize: '13px',
							fontWeight: '400',
							letterSpacing: '2.08px',
							textTransform: 'uppercase',
							lineHeight: '1.23',
							display: 'block'
						}}
					>
						{intl.formatMessage({ id: 'app.forgotPass.backLabel' })}
					</Link>
				</Grid>
				<Grid item style={{ width: '100%' }}>
					<Grid container direction="row" justify="center" alignItems="center" style={{ minWidth: '100%' }}>
						<Grid item style={{ marginLeft: '2%' }}>
							<Button
								label="Password"
                                variant="contained"
                                color="primary"
								fullWidth
								type="submit"
							>
								{S.LOGIN_SUBMIT_BUTTON}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</form>
	)
}

const mapDispatchToProps = (dispatch, ownProps) => ({
	submitRequest: (email) => dispatch(requestForgotPasswordEmail(email))
})

const enhance = compose(
	connect(null, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'login'
	}),
	withStyles(styles)
)

export default enhance(ForgotPassword)
