import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useTheme from '@material-ui/core/styles/useTheme'
import { isCreatingProtocolSelector } from '../../../redux/selectors'
import { useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import { reduxForm } from 'redux-form'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import FormTextField from '../../../components/FormTextField'
import { required } from '../../../utilities/validators'
import Button from '@material-ui/core/Button'
import { cloneProtocol, hideModal } from '../../../redux/actions'

const requiredValidator = required

const CloneProtocolModal = ({ intl, handleSubmit, isCreating, protocol, updateName, clone, cancel }) => {
	const theme = useTheme()
	const match = useRouteMatch()

	const onSubmitForm = (values) => {
		clone(protocol.id, values)
	}
	useEffect(
		() => {
			protocol &&
				updateName(`${intl.formatMessage({ id: 'app.protocols.cloneProtocolCloneOfText' })} ${protocol.name}`)
		},
		[ protocol ]
	)

	return (
		<form onSubmit={handleSubmit(onSubmitForm)}>
			<Grid
				container
				direction="column"
				justify="flex-start"
				alignItems="center"
				style={{ width: '75rem', padding: '4rem', margin: 'auto', backgroundColor: theme.palette.secondary.main }}
			>
				<Grid item style={{ alignSelf: 'center' }}>
					<Typography
						variant="h1"
						style={{
							color: '#fff',
							marginBottom: '25px',
						}}
					>
						{intl.formatMessage({ id: 'app.protocols.createProtocolModalTitle' })}
					</Typography>
				</Grid>
				<Grid item style={{ alignSelf: 'flex-start', width: '100%' }}>
					<FormTextField
						label={intl.formatMessage({ id: 'app.protocols.protocolNamePlaceholder' }) + '*'}
						fullWidth
						type="text"
						name="name"
						maxLength={254}
						validators={[ requiredValidator ]}
						variant="filled"
					/>
				</Grid>
				<Grid item style={{ alignSelf: 'flex-start', width: '100%' }}>
					<FormTextField
						label={intl.formatMessage({ id: 'app.protocols.protocolDescriptionPlaceholder' })}
						fullWidth
						type="text"
						name="description"
						maxLength={1023}
						variant="filled"
					/>
				</Grid>
				<Grid item>
					<Grid container spacing={1}>
						<Grid item>
							<Button
								label={intl.formatMessage({ id: 'app.protocols.cloneProtocolCancelButtonLabel' })}
								style={{
									width: '267px',
									marginTop: '40px'
								}}
								onClick={() => cancel()}
                                variant="outlined"
                                color="primary"
							>
								{intl.formatMessage({ id: 'app.protocols.cloneProtocolCancelButtonLabel' })}
							</Button>
						</Grid>
						<Grid item>
							<Button
								label={intl.formatMessage({ id: 'app.protocols.cloneProtocolSaveButtonLabel' })}
								style={{
									width: '267px',
									marginTop: '40px'
								}}
								disabled={isCreating}
								type="submit"
                                variant="contained"
                                color="primary"
							>
								{intl.formatMessage({ id: 'app.protocols.cloneProtocolSaveButtonLabel' })}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</form>
	)
}

const mapStateToProps = (state, ownProps) => ({
	isCreating: isCreatingProtocolSelector(state),
	initialValues: ownProps.protocol
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	clone: (id, values) => dispatch(cloneProtocol(id, values.name, values.description)),
	updateName: (newName) => dispatch(change('cloneProtocol', 'name', newName)),
	cancel: () => dispatch(hideModal())
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'cloneProtocol',
		touchOnChange: true,
		touchOnBlur: true
	})
)

export default enhance(CloneProtocolModal)
