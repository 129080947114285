import * as api from '../../../apis/reports'
import { createSelector } from 'reselect'

const FETCH_REPORTS_REQUESTED = 'FETCH_REPORTS_REQUESTED'
const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS'
const FETCH_REPORTS_FAILED = 'FETCH_REPORTS_FAILED'

export const fetchReports = (searchPhrase) => (dispatch) => {
	dispatch({ type: FETCH_REPORTS_REQUESTED, data: {} })
	api.search(searchPhrase).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_REPORTS_SUCCESS, data: response })
		} else {
			return dispatch({ type: FETCH_REPORTS_FAILED, data: {} })
		}
	})
	return
}

const FETCH_ADDITIONAL_REPORTS_REQUESTED = 'FETCH_ADDITIONAL_REPORTS_REQUESTED'
const FETCH_ADDITIONAL_REPORTS_SUCCESS = 'FETCH_ADDITIONAL_REPORTS_SUCCESS'
const FETCH_ADDITIONAL_REPORTS_FAILED = 'FETCH_ADDITIONAL_REPORTS_FAILED'

export const searchAdditionalReports = (searchPhrase, offset) => (dispatch) => {
	dispatch({ type: FETCH_ADDITIONAL_REPORTS_REQUESTED, data: {} })
	api.search(searchPhrase, offset).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ 
				type: FETCH_ADDITIONAL_REPORTS_SUCCESS, 
				data: response
			})
		} else {
			return dispatch({ type: FETCH_ADDITIONAL_REPORTS_FAILED, data: {} })
		}
	})
	return
}

const SET_REPORT_SORT_REQUESTED = 'SET_REPORT_SORT_REQUESTED'

export const setReportSort = (field, isDescending) => (dispatch, state) => {
	let currentState = state()

	let x = [ ...currentState.reports.reports ]

	let data = x.sort((a, b) => {
		if (isDescending) {
			if(typeof a[field] === 'string' && typeof b[field] === 'string')
				return a[field].toLowerCase() > b[field].toLowerCase() ? -1 : 1
			else
				return (a[field]===null)-(b[field]===null) || -(a[field]>b[field])||+(a[field]<b[field])
		} else {
			if(typeof a[field] === 'string' && typeof b[field] === 'string')
				return b[field].toLowerCase() > a[field].toLowerCase() ? -1 : 1
			else
				return (a[field]===null)-(b[field]===null) || +(a[field]>b[field])||-(a[field]<b[field])
		}
	})

	return dispatch({
		type: SET_REPORT_SORT_REQUESTED,
		data: { items: data, field: field, isDescending: isDescending }
	})
}

const DOWNLOAD_REPORT_EXPORT_REQUESTED = 'DOWNLOAD_REPORT_EXPORT_REQUESTED'
const DOWNLOAD_REPORT_EXPORT_SUCCESS = 'DOWNLOAD_REPORT_EXPORT_SUCCESS'
const DOWNLOAD_REPORT_EXPORT_FAILED = 'DOWNLOAD_REPORT_EXPORT_FAILED'

export const dowloadReportExport = (reportId) => (dispatch) => {
	dispatch({ type: DOWNLOAD_REPORT_EXPORT_REQUESTED, data: {reportId} })
	
	api.downloadReportExport(reportId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({ type: DOWNLOAD_REPORT_EXPORT_SUCCESS, data: {reportId} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.exportSuccessful', isSuccess: true }
			})
		} else {
			dispatch({ type: DOWNLOAD_REPORT_EXPORT_FAILED, data: {reportId} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.fetchReportFailed', isSuccess: false }
			})
		}
	})
}

const GET_NEW_COMPLETED_REPORTS_REQUESTED = 'GET_NEW_COMPLETED_REPORTS_REQUESTED'
const GET_NEW_COMPLETED_REPORTS_SUCCESS = 'GET_NEW_COMPLETED_REPORTS_SUCCESS'
const GET_NEW_COMPLETED_REPORTS_FAILED = 'GET_NEW_COMPLETED_REPORTS_FAILED'

export const getNewCompletedReports = () => (dispatch, getState) => {
	dispatch({ type: GET_NEW_COMPLETED_REPORTS_REQUESTED, data: {} })

	api.getNewCompletedReports().then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: GET_NEW_COMPLETED_REPORTS_SUCCESS, data: response.result })
		} else {
			return dispatch({ type: GET_NEW_COMPLETED_REPORTS_FAILED, data: {} })
		}
	})
}

const SET_REPORT_SEARCH_TERM_REQUESTED = 'SET_REPORT_SEARCH_TERM_REQUESTED'
const SET_REPORT_SEARCH_TERM_SUCCESS = 'SET_REPORT_SEARCH_TERM_SUCCESS'

export const setReportSearchTerm = (value) => (dispatch) => {
	dispatch({ type: SET_REPORT_SEARCH_TERM_REQUESTED, data: {} })
	return dispatch({ type: SET_REPORT_SEARCH_TERM_SUCCESS, data: value })
}

const CLEAR_NEW_COMPLETED_REPORTS_REQUESTED = 'CLEAR_NEW_COMPLETED_REPORTS_REQUESTED'
const CLEAR_NEW_COMPLETED_REPORTS_SUCCESS = 'CLEAR_NEW_COMPLETED_REPORTS_SUCCESS'
const CLEAR_NEW_COMPLETED_REPORTS_FAILED = 'CLEAR_NEW_COMPLETED_REPORTS_FAILED'

export const clearNewCompletedReports = () => (dispatch) => {
	dispatch({ type: CLEAR_NEW_COMPLETED_REPORTS_REQUESTED, data: {} })

	api.clearNewCompletedReports().then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: CLEAR_NEW_COMPLETED_REPORTS_SUCCESS, data: response.result })
		} else {
			return dispatch({ type: CLEAR_NEW_COMPLETED_REPORTS_FAILED, data: {} })
		}
	})
}

const SET_NEW_REPORT_INITIATED_REQUESTED = 'SET_NEW_REPORT_INITIATED_REQUESTED'

export const setNewReportInitiated = () => (dispatch) => {
	return dispatch({ type: SET_NEW_REPORT_INITIATED_REQUESTED, data: {} })
}

const CLEAR_REPORT_EXPORT_MESSAGE_REQUESTED = 'CLEAR_REPORT_EXPORT_MESSAGE_REQUESTED'

export const clearReportExportInitiatedMessage = () => (dispatch) => {
	return dispatch({ type: CLEAR_REPORT_EXPORT_MESSAGE_REQUESTED, data: {} })
}

const initial = {
	reports: [],
	sortField: 'name',
	isDescending: false,
	searchTerm: '',
	hasMore: true,
	isLoading: false,
	newCompletedReports: false,
	downloadingReports: [],
	newReportExportIsInitiated: false,
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_REPORTS_REQUESTED:
			return { ...state, reports: [] }
		case FETCH_REPORTS_SUCCESS:
			return { 
				...state, 
				reports: action.data.reports,
				hasMore: action.data.hasMore,
				sortField: 'name',
				isDescending: false
			 }
		case FETCH_ADDITIONAL_REPORTS_SUCCESS:
			return {
				...state,
				reports: [ ...state.reports, ...action.data.reports],
				hasMore: action.data.hasMore,
				isLoading: false
			}
		case FETCH_ADDITIONAL_REPORTS_FAILED:
			return {
				...state,
				hasMore: false,
				isLoading: false
			}
		case FETCH_ADDITIONAL_REPORTS_REQUESTED:
			return {
				...state,
				isLoading: true
			}
		case SET_REPORT_SORT_REQUESTED:
			return {
				...state,
				reports: action.data.items,
				sortField: action.data.field,
				isDescending: action.data.isDescending
			}
		case SET_REPORT_SEARCH_TERM_SUCCESS:
			return { ...state, searchTerm: action.data }
		case GET_NEW_COMPLETED_REPORTS_SUCCESS:
			return { ...state, newCompletedReports: action.data }
		case CLEAR_NEW_COMPLETED_REPORTS_REQUESTED:
			return { ...state, newCompletedReports: false }
		case DOWNLOAD_REPORT_EXPORT_REQUESTED:
			return { ...state, downloadingReports: [...state.downloadingReports, action.data.reportId] }
		case DOWNLOAD_REPORT_EXPORT_SUCCESS:
			return { ...state, downloadingReports: state.downloadingReports.filter((item) => item !== action.data.reportId) }
		case DOWNLOAD_REPORT_EXPORT_FAILED:
			return { ...state, downloadingReports: state.downloadingReports.filter((item) => item !== action.data.reportId) }
		case SET_NEW_REPORT_INITIATED_REQUESTED:
			return { ...state, newReportExportIsInitiated: true}
		case CLEAR_REPORT_EXPORT_MESSAGE_REQUESTED:
			return { ...state, newReportExportIsInitiated: false}
		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.reports

export const reportsSelector = createSelector(mainSelector, (state) => {
	return state.reports
})

export const hasReportsSelector = createSelector(mainSelector, (state) => {
	return state.reports && state.reports.length > 0
})

export const sortReportsDirectionSelector = createSelector(mainSelector, (state) => {
	return state.isDescending
})

export const sortReportsFieldSelector = createSelector(mainSelector, (state) => {
	return state.sortField
})

export const reportSearchTermSelector = createSelector(mainSelector, (state) => {
	return state.searchTerm
})

export const hasMoreReportsSelector = createSelector(mainSelector, (state) => {
	return state.hasMore
})

export const isLoadingReportsSelector = createSelector(mainSelector, (state) => {
	return state.isLoading
})

export const newCompletedReportsSelector = createSelector(mainSelector, (state) => {
	return state.newCompletedReports
})

export const downloadingReportsSelector = createSelector(mainSelector, (state) => {
	return state.downloadingReports
})

export const newReportExportInitiatedSelector = createSelector(mainSelector, (state) => {
	return state && state.newReportExportIsInitiated
}) 