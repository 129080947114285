import React from 'react'
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { sortMembersDirectionSelector, sortMembersFieldSelector } from '../../redux/selectors'
import { Typography } from '@material-ui/core'
import { useSelector, shallowEqual } from 'react-redux'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { setCircleMemberSort } from '../../redux/actions'
import { useDispatch } from 'react-redux'

const MemberTableHeader = ({ intl }) => {
	const dispatch = useDispatch()
	let isDescending = useSelector(sortMembersDirectionSelector, shallowEqual)
	let field = useSelector(sortMembersFieldSelector, shallowEqual)

	const changeSort = (field, direction) => {
		dispatch(setCircleMemberSort(field, direction))
	}

	return (
		<TableRow>
			<TableCell align="left" style={{ width: '10px', border: 'none', padding: '6px 0px 6px 0px' }} />
			<TableCell
				size="small"
				align="left"
				style={{ width: '10px', border: 'none', padding: '6px 0px 6px 0px' }}
			/>
			<TableCell align="left" style={{ border: 'none' }}>
				<TableSortLabel
					active={field === 'name'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('name', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="subtitle1"
						style={{ fontWeight: 400, letterSpacing: '1px', fontSize: '14px', color: '#232c3b' }}
					>
						{intl.formatMessage({ id: 'app.userProfile.teamMemberTableMemberNameHeader' })}
					</Typography>
				</TableSortLabel>
			</TableCell>
            <TableCell align='left' style={{ border: 'none' }}>
                <TableSortLabel
					active={field === 'language'}
                    direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('language', !isDescending)}
                    IconComponent={ExpandMoreIcon}
                >
                    <Typography variant='subtitle1' style={{ fontWeight: 400, fontSize: '13px', color: '#232c3b', textTransform: 'uppercase' }}>
                        {intl.formatMessage({ id: 'app.general.languageLabel' })}
                    </Typography>
                </TableSortLabel>
            </TableCell>
            <TableCell align="left" style={{ border: 'none' }}>
                <TableSortLabel
                    active={field === 'jurisdictionName'}
                    direction={isDescending ? 'desc' : 'asc'}
                    onClick={(e) => changeSort('jurisdictionName', !isDescending)}
                    IconComponent={ExpandMoreIcon}
                >
                    <Typography
                        variant="subtitle1"
                        style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase'
						}}
                    >
                        {intl.formatMessage({ id: 'app.subscribers.jurisdiction' })}
                    </Typography>
                </TableSortLabel>
            </TableCell>
			<TableCell align='left' style={{ border: 'none' }}>
                <TableSortLabel
                    active={field === 'isMFAEnabled'}
                    direction={isDescending ? 'desc' : 'asc'}
                    onClick={(e) => changeSort('isMFAEnabled', !isDescending)}
                    IconComponent={ExpandMoreIcon}
                >
                    <Typography variant='subtitle1' style={{ fontWeight: 400, fontSize: '13px', color: '#232c3b', textTransform: 'uppercase' }}>
                        {intl.formatMessage({ id: 'app.userProfile.teamMemberTableMember2FAStateHeader' })}
                    </Typography>
                </TableSortLabel>
            </TableCell>
			<TableCell align="left" style={{ border: 'none', width: '201px', maxWidth: '201px' }}>
				<TableSortLabel
					active={field === 'dateJoined'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('dateJoinedSeconds', !isDescending)}
					IconComponent={ExpandMoreIcon}
					sortDirection={true}
				>
					<Typography
						variant="subtitle1"
						style={{
							fontWeight: 400,
							letterSpacing: '1px',
							fontSize: '14px',
							color: '#232c3b',
							border: 'none'
						}}
					>
						{intl.formatMessage({ id: 'app.userProfile.teamMemberTableMemberDateJoinedHeader' })}
					</Typography>
				</TableSortLabel>
			</TableCell>

			<TableCell align="right" style={{ border: 'none' }} />
		</TableRow>
	)
}

const enhance = compose(injectIntl)

export default enhance(MemberTableHeader)
