import { authenticatedFetch } from './base'

export const addPatient = (email, birthDate, firstName, lastName, countryId, phoneNumber) => {
	return authenticatedFetch(`/api/patient`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			email: email,
			firstName: firstName,
			lastName: lastName,
			countryId: countryId,
			birthDate: birthDate,
			phoneNumber: phoneNumber
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const updatePatient = (id, birthDate, firstName, lastName, countryId, phoneNumber) => {
	return authenticatedFetch(`/api/patient/${id}`, {
		method: 'put',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			firstName: firstName,
			lastName: lastName,
			countryId: countryId,
			birthDate: birthDate,
			phoneNumber: phoneNumber
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const resendInvitation = (email) => {
	return authenticatedFetch(`/api/patient/resend`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			email: email
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchPatients = () => {
	return authenticatedFetch(`/api/patient`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchPatientUpdatesTemplateTypes = () => {
	var url = `/api/patientUpdates/types`

    return authenticatedFetch(url, { method: 'GET' })
        .then(function (response) {
            if (response.status === 200) {
                return response.json()
            }
        })
        .then(function (template) {
            return template
        })
}

export const fetchTemplate = (versionId, languageId, type) => {
	var url = `/api/patientUpdates/version/${versionId}/languages/${languageId}/types/${type}`

    return authenticatedFetch(url, { method: 'GET' })
        .then(function (response) {
            if (response.status === 200) {
                return response.json()
            }
        })
        .then(function (template) {
            return template
        })
}

export const updateTriggerTemplate = (versionId, languageId, type, body, commentsObj) => {
	var url = `/api/patientUpdates/version/${versionId}/languages/${languageId}/types/${type}`

	let data = {}
	data.body = body
	data.comments = {}
	data.comments.commentText = commentsObj?.commentText
	data.comments.commentProtocolVersionId = commentsObj?.protocolVersionId
	data.comments.commentRelatedTaskUrl = commentsObj?.relatedTaskUrl
	data.comments.commentInvestigatorId = commentsObj?.investigatorId

    return authenticatedFetch(url, {
            method: 'PUT',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(data)
        })
        .then(function (response) {
            if (response.status === 200) {
                return response.json()
            }
        })
        .then(function (template) {
            return template
        })
}