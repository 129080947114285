import React from "react";
import { TableCell } from "@material-ui/core";
import { useIntl } from "react-intl";


const RelatedProtocolVisibleTableCell = ({
    protocol
}) => {
    const intl = useIntl()


    return (
        <TableCell>
            { intl.formatMessage({ id: `app.sg.details.relatedProtocols.hidden.${protocol.hideFromPatient}` }) }
        </TableCell>
    )
}

export default RelatedProtocolVisibleTableCell;
