import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import DefaultFormTextField from '../DefaultFormTextField'
import FormFilledInputSelectLight from '../FormFilledInputSelectLight'
import { formValueSelector } from 'redux-form'
import { required } from '../../utilities/validators'
import { useStore, useSelector, shallowEqual, useDispatch, connect } from 'react-redux'
import { fileTypesSelector } from '../../redux/selectors'
import { fetchFileTypes } from '../../redux/actions'
import { change } from 'redux-form'
import FormCheckBox from '../FormCheckBox'
import { useIntl } from 'react-intl'
import { getTagValidators } from '../../utilities/questions'
import FormTextField from '../FormTextField'
import FormSelect from '../FormSelect'
const requiredValidator = required

const formName = 'editQuestion'
const selector = formValueSelector(formName)

const q = forwardRef((props, ref) => {
	const { question, dark, canSetOptional = true } = props
	const store = useStore()
	const intl = useIntl()
	const types = useSelector(fileTypesSelector, shallowEqual)
	const dispatch = useDispatch()

    useEffect(
		() => {
			if (question && question.data) {
				dispatch(change(formName, 'question', question.data.title))
				dispatch(change(formName, 'instructions', question.data.instructions))
				dispatch(change(formName, 'tag', question.data.tag))
				dispatch(change(formName, 'isOptional', question.data.isOptional))
				dispatch(change(formName, 'fileType', question.data.questionType))
				dispatch(change(formName, 'hideImageForPatient', question.data.hideImageForPatient))
				dispatch(change(formName, 'displayInstructionAsIcon', question.data.displayInstructionAsIcon))
			}
		},
		[ question ]
	)

    useEffect(() => {
        dispatch(fetchFileTypes())
    }, [])

	useImperativeHandle(ref, () => ({
		getQuestion() {
			return {
				id: question.data.id,
				title: selector(store.getState(), 'question'),
				type: 'file',
				instructions: selector(store.getState(), 'instructions'),
				tag: selector(store.getState(), 'tag'),
				isOptional: selector(store.getState(), 'isOptional'),
				fileType: selector(store.getState(), 'fileType'),
				hideImageForPatient: selector(store.getState(), 'hideImageForPatient'),
				displayInstructionAsIcon: selector(store.getState(), 'displayInstructionAsIcon'),
			}
		}
	}))

	useEffect(() => {
		var el = document.getElementsByName('tag')
		if (el != null) {
			el[0].focus()
		}
	}, [])

    const handleHideImageForPatientChange = (e) => {
		if (!!disabled) {
			return
		}

		dispatch(change(formName, 'hideImageForPatient', e.target.checked))
    }

	const AdaptiveFormTextField = dark ? FormTextField : DefaultFormTextField
	const AdaptiveFormFilledInputSelect = dark ? FormSelect : FormFilledInputSelectLight

	const disabled=!!question.data.cloneId

	return (
		<Grid container direction="column" alignItems="flex-start">
			<Grid item style={{ width: '100%' }}>
				<AdaptiveFormTextField
					label="Tag"
					variant="filled"
					fullWidth
					name="tag"
					inputProps={{ maxLength: 254 }}
					maxLength={254}
					validators={getTagValidators(question)}
				/>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				<AdaptiveFormTextField
					label="Question"
					variant="filled"
					fullWidth
					name="question"
					validators={[ requiredValidator ]}
					inputProps={{ maxLength: 1023 }}
					maxLength={1023}
					disabled={disabled}
				/>
			</Grid>

			<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
				<AdaptiveFormTextField
					label="Instructions"
					variant="filled"
					name="instructions"
					fullWidth
					inputProps={{ maxLength: 2047 }}
					rows="3"
					maxLength={2047}
					multiline
					disabled={disabled}
				/>
			</Grid>
			<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '0px' }}>
				<FormCheckBox
					name="displayInstructionAsIcon"
					color={dark ? "secondary" : "primary"}
					fontColor={dark ? "white" : "black"}
					label={intl.formatMessage({ id: 'app.survey.questions.displayInstructionAsIcon' })}
					disabled={disabled}
				/>
			</Grid>
			{
				!!canSetOptional
				&& <Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '0px' }}>
					<FormCheckBox
						name="isOptional"
						color={dark ? "secondary" : "primary"}
						fontColor={dark ? "white" : "black"}
						label={intl.formatMessage({ id: 'app.survey.questions.isOptional' })}
						disabled={disabled}
					/>
				</Grid>
			}
            <Grid item>
                <Typography variant="h4" style={{ fontWeight: '400', paddingBottom: '10px', color: dark ? 'white' : 'initial' }}>
                    File
                </Typography>
			</Grid>
            <Grid item>
                <Grid container spacing={2}>
                    <Grid item style={{ width: '200px' }}>
                        <AdaptiveFormFilledInputSelect
                            label="Type"
                            name="fileType"
                            validators={[requiredValidator]}
                            data={types}
							style={{ color: disabled ? 'grey' : 'white' }}
                            disabled
                            fullWidth
						/>
                    </Grid>
                </Grid>
			</Grid>
            {
				props.fileType == 10 &&
				<Grid item style={{ opacity: !!disabled ? 0.33 : 1.0 }}>
					<Grid container style={{ flexDirection: 'row' }}>
						<Grid item style={{ paddingTop: '6px' }}>
							<Typography
								variant="body2"
								style={{ fontSize: '17px', fontWeight: '400', color: dark ? 'white' : 'initial' }}
							>
								{intl.formatMessage({ id: 'app.survey.questions.hideImageForPatient.switch' })}
							</Typography>
						</Grid>
						<Grid item>
							<Switch
								checked={props.hideImageForPatient}
								onChange={handleHideImageForPatientChange}
								color="primary"
								inputProps={{ 'aria-label': 'secondary checkbox' }}
							/>
						</Grid>
					</Grid>
				</Grid>
			}
		</Grid>
	)
})

const mapStateToProps = (state) => ({
    fileType: selector(state, 'fileType'),
    hideImageForPatient: selector(state, 'hideImageForPatient')
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(q)