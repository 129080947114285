import React, { useEffect, useState } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { compose } from 'recompose'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { confirmForgotPasswordEmail } from '../../redux/actions'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import FormTextField from '../../components/FormTextField'
import { reduxForm, change } from 'redux-form'
import { required, passwordStrength } from '../../utilities/validators'
import { passwordMatch } from '../../utilities/validators'
import '../../styles/index.scss'
import { injectIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import MobileDetect from 'mobile-detect'

const styles = {
	root: {},
	input: {
		color: 'white',
		fontSize: '1.7rem'
	}
}

const requiredValidator = required
const passwordStrengthValidator = passwordStrength
const passwordMatchValidator = passwordMatch('password')

const ConfirmForgotPassword = ({ classes, submitRequest, handleSubmit, intl, changeCode, changeEmail }) => {
	const location = useLocation()
	const theme = useTheme()
	const [ isMobile ] = useState(new MobileDetect(window.navigator.userAgent))
	const [ isEmailPrepopulated, setIsEmailPrepopulated ] = useState(false)
	const [ isCodePrepopulated, setIsCodePrepopulated ] = useState(false)
	useEffect(() => {
		let params = queryString.parse(location.search)
		if (params.code) {
			changeCode(params.code)
			setIsCodePrepopulated(true)
		}
		if (params.email) {
			changeEmail(params.email)
			setIsEmailPrepopulated(true)
		}
	}, [])

	const onSubmitForm = (values) => {
		submitRequest(values.email, values.code, values.password)
	}
	return (
		<form
			onSubmit={handleSubmit(onSubmitForm)}
			style={{ minWidth: isMobile.mobile() ? '200px' : '65rem' }}
		>
			<Grid
				container
				direction="column"
				alignItems="center"
				justify="center"
				style={{
					minWidth: isMobile.mobile() ? '200px' : '65rem'
				}}
			>
				<Grid item>
					<Grid
						container
						direction="column"
						justify="center"
						style={{
							backgroundColor: theme.palette.secondary.main ,
							padding: '10%'
						}}
					>
						<Grid item>
							<Typography
								variant="h1"
								style={{
									color: '#fff',
									textAlign: 'center',
									marginBottom: '67px'
								}}
							>
								{intl.formatMessage({ id: 'app.confirmPasswordChange.title' })}
							</Typography>
						</Grid>
						<Grid container justify="center">
							<Typography
								variant="subtitle1"
								style={{
									color: '#fff',
									textAlign: 'center',
									fontWeight: '400',
									marginBottom: '2.5rem'
								}}
							>
								{intl.formatMessage({ id: 'app.confirmPasswordChange.success.label' })}
							</Typography>
						</Grid>
						<Grid
							item
							style={{
								marginBottom: '1rem'
							}}
						>
							<FormTextField
								label={intl.formatMessage({ id: 'app.confirmPasswordChange.email' })}
								fullWidth
								type="text"
								name="email"
								margin="normal"
								variant="filled"
								disabled={isEmailPrepopulated}
								inputLabelProps={{
									className: classes.input
								}}
								inputProps={{
									className: classes.input
								}}
								validators={[ required ]}
							/>
						</Grid>
						<Grid
							item
							style={{
								marginBottom: '1rem'
							}}
						>
							<FormTextField
								label={intl.formatMessage({ id: 'app.confirmPasswordChange.token' })}
								fullWidth
								type="text"
								name="code"
								margin="normal"
								variant="filled"
								disabled={isCodePrepopulated}
								inputLabelProps={{
									className: classes.input
								}}
								inputProps={{
									className: classes.input
								}}
								validators={[ requiredValidator ]}
							/>
						</Grid>
						<Grid
							item
							style={{
								marginBottom: '1rem'
							}}
						>
							<FormTextField
								label={intl.formatMessage({ id: 'app.confirmPasswordChange.password' })}
								fullWidth
								type="password"
								name="password"
								margin="normal"
								variant="filled"
								inputLabelProps={{
									className: classes.input
								}}
								inputProps={{
									className: classes.input
								}}
								validators={[ requiredValidator, passwordStrengthValidator ]}
							/>
						</Grid>
						<Grid
							item
							style={{
								marginBottom: '10px'
							}}
						>
							<FormTextField
								label={intl.formatMessage({ id: 'app.confirmPasswordChange.confirmPassword' })}
								fullWidth
								type="password"
								name="confirmPassword"
								margin="normal"
								variant="filled"
								inputLabelProps={{
									className: classes.input
								}}
								inputProps={{
									className: classes.input
								}}
								validators={[ required, passwordMatchValidator ]}
							/>
						</Grid>
						<Grid
							item
							style={{
								marginBottom: '50px'
							}}
						>
							<Link
								to="/auth/login"
								style={{
									color: '#fff',
									textDecoration: 'none',
									textAlign: 'left',
									fontWeight: '400',
									textTransform: 'uppercase',
									lineHeight: '0',
									display: 'block'
								}}
							>
								{intl.formatMessage({ id: 'app.confirmPasswordChange.signinLinkText' })}
							</Link>
						</Grid>
						<Grid
							item
							style={{
								maxwidth: '50%',
								alignSelf: 'center'
							}}
						>
							<Button
                                variant="contained"
                                color="primary"
								type="submit"
							>
								{intl.formatMessage({ id: 'app.confirmPasswordChange.reset' })}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</form>
	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
	changeCode: (code) => dispatch(change('forgotpassword', 'code', code)),
	changeEmail: (email) => dispatch(change('forgotpassword', 'email', email)),
	submitRequest: (email, code, password) => dispatch(confirmForgotPasswordEmail(email, code, password))
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'forgotpassword'
	}),
	withStyles(styles)
)

export default enhance(ConfirmForgotPassword)
