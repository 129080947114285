import React, { useState, forwardRef, createRef, useImperativeHandle, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import AnswerContainer from './AnswerContainer'
import { ListContainer } from '../../../utilities/linkedList'
import { useIntl } from 'react-intl'
import { required } from '../../../utilities/validators'
import { uuidv4 } from '../../../utilities/utilities'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'
import DefaultFormTextField from '../../DefaultFormTextField'
import { useStore } from 'react-redux'
import { formValueSelector } from 'redux-form'
import FormCheckBox from '../../FormCheckBox'
import PIDataToggle from '../../PIDataToggle'
import { getTagValidators } from '../../../utilities/questions'
import FormTextField from '../../FormTextField'
const requiredValidator = required
const selector = formValueSelector('editQuestion')
const useStyles = makeStyles({
	root: {
		color: 'black'
	},
	instructionTextRoot: {
		color: 'black',
		marginLeft: '-2px'
	},
	label: {
		textTransform: 'capitalize',
		color: 'black',
		marginLeft: '-2px'
	},
	formControl: {
		minWidth: 120,
		color: 'black'
	}
})

export default forwardRef((props, ref) => {
	let classes = useStyles()
	const intl = useIntl()
	const [ answers, setAnswers ] = useState()
	const { onSubmit, onCancel, question, showScoreEntry, isBundle, dark, canSetOptional = true } = props
	const [ id, setId ] = useState(0)
	const [ questionText, setQuestionText ] = useState('')
	const [ instructionText, setInstructionText ] = useState(0)
	const [ answerList, setAnswerList ] = useState(new ListContainer())
	const [ refs, setRefs ] = useState([])
	const [ isCodeQuestion, setIsCodeQuestion ] = useState(false)
	const dispatch = useDispatch()
	const store = useStore()

	useEffect(
		() => {
			if (question && question.data) {
				dispatch(change('editQuestion', 'question', question.data.title))
				dispatch(change('editQuestion', 'instructions', question.data.instructions))
				dispatch(change('editQuestion', 'tag', question.data.tag))
				dispatch(change('editQuestion', 'isOptional', question.data.isOptional))
				dispatch(change('editQuestion', 'isConsentRequired', question.data.isConsentRequired))
                dispatch(change('editQuestion', 'isPiData', question.data.isPiData))
                dispatch(change('editQuestion', 'isCodeQuestion', question.data.isCodeQuestion))
                dispatch(change('editQuestion', 'questionCode', question.data.questionCode))
                dispatch(change('editQuestion', 'isDropdown', question.data.isDropdown))
				dispatch(change('editQuestion', 'displayInstructionAsIcon', question.data.displayInstructionAsIcon))

				setIsCodeQuestion(question.data.isCodeQuestion)
				setId(question.data.id)
				
				if (answerList) {
					for (let index = 0; index < question.data.answers.length; index++) {
						const element = question.data.answers[index]

						element.answerId = uuidv4().replace('-', '')
						element.scoreId = uuidv4().replace('-', '')
						element.codeId = uuidv4().replace('-', '')

						dispatch(change('editQuestion', element.answerId, element.label))
						dispatch(change('editQuestion', element.scoreId, element.value))
						dispatch(change('editQuestion', element.codeId, element.code))
						answerList.getList().append(element)
					}

					let questionAnswers = new Array(0)

					answerList.getList().traverse((r) => {
						r.id = uuidv4().replace('-', '')
						questionAnswers.push(r)
					})
					setAnswers(questionAnswers)
				}
			}
		},
		[ question ]
	)

	useImperativeHandle(ref, () => ({
		getQuestion() {
			let a = []
			if (answers) {
				let i = 0
				answerList.getList().traverse((r) => {
					let answer = selector(store.getState(), r.data.answerId)
					let value = selector(store.getState(), r.data.scoreId)
					let code = selector(store.getState(), r.data.codeId)

					a.push({ answer: { answer, value, code }, id: r.data.id, sortOrder: i })
					i++
				})
			}
			return {
				id: question.data.id,
				title: selector(store.getState(), 'question'),
				type: 'multi',
				instructions: selector(store.getState(), 'instructions'),
				tag: selector(store.getState(), 'tag'),
				answers: a,
				isOptional: selector(store.getState(), 'isOptional'),
                isConsentRequired: selector(store.getState(), 'isConsentRequired'),
				isPiData: selector(store.getState(), 'isPiData'),
				isCodeQuestion: selector(store.getState(), 'isCodeQuestion'),
				questionCode: selector(store.getState(), 'questionCode'),
				isDropdown: selector(store.getState(), 'isDropdown'),
				displayInstructionAsIcon: selector(store.getState(), 'displayInstructionAsIcon'),
			}
		}
	}))

	const onDeleteAnswerClick = (answer) => {
		if (answer) {
			let x = answer.data.answerId
			answerList.getList().remove(answer)
			setAnswerList(answerList)

			var a = answers.filter((r) => {
				return r.data.answerId !== x
			})
			setAnswers(a)
		}
	}

	const swapAnswer = (nodeA, nodeB) => {
		if (answerList) {
			if (nodeA) {
				nodeA.data.answer = nodeA.ref.current.getAnswer()
			}
			if (nodeB) {
				nodeB.data.answer = nodeB.ref.current.getAnswer()
			}

			answerList.getList().swap(nodeA, nodeB)

			let questionAnswers = new Array(0)

			answerList.getList().traverse((r) => {
				r.id = uuidv4().replace('-', '')
				questionAnswers.push(r)
			})
			setAnswerList(answerList)
			setAnswers(questionAnswers)
		}
	}

	const onAddNewAnswer = () => {
		let answer = {}
		answer.answerId = uuidv4().replace('-', '')
		answer.scoreId = uuidv4().replace('-', '')
		answer.codeId = uuidv4().replace('-', '')
		if (answerList) {
			answerList.getList().append(answer)
			let questionAnswers = new Array(0)

			answerList.getList().traverse((r) => {
				questionAnswers.push(r)
			})
			setAnswerList(answerList)

			setAnswers(questionAnswers)
		}
	}
	useEffect(() => {
		var el = document.getElementsByName('tag')
		if (el != null) {
			el[0].focus()
		}
	}, [])

	const AdaptiveFormTextField = dark ? FormTextField : DefaultFormTextField

	return (
		<Grid container direction="column" alignItems="flex-start">
			{!isBundle &&
			<Grid item style={{ width: '100%' }}>
				<PIDataToggle
					fontColor={dark ? "white" : "black"}
					defaultValue={question.data.isPiData}
					onChange={(value) => dispatch(change('editQuestion', 'isPiData', value))}
					disabled={!!question.data.cloneId}
				/>
			</Grid>}
			<Grid item style={{ width: '100%' }}>
				<AdaptiveFormTextField
					label="Tag"
					variant="filled"
					fullWidth
					name="tag"
					inputProps={{ maxLength: 254 }}
					maxLength={254}
					validators={getTagValidators(question)}
				/>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				<AdaptiveFormTextField
					label="Question"
					variant="filled"
					fullWidth
					name="question"
					validators={[ requiredValidator ]}
					inputProps={{ maxLength: 1023 }}
					maxLength={1023}
					disabled={!!question.data.cloneId}
				/>
			</Grid>

			<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
				<AdaptiveFormTextField
					label="Instructions"
					variant="filled"
					name="instructions"
					fullWidth
					inputProps={{ maxLength: 2047 }}
					rows="3"
					maxLength={2047}
					multiline
					disabled={!!question.data.cloneId}
				/>
			</Grid>
			<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '0px' }}>			
				<FormCheckBox
					name="displayInstructionAsIcon"
					color={dark ? "secondary" : "primary"}
					fontColor={dark ? "white" : "black"}
					label={intl.formatMessage({ id: 'app.survey.questions.displayInstructionAsIcon' })}
					disabled={!!question.data.cloneId}
				/>
				{
					!!canSetOptional
					&& <FormCheckBox
						name="isOptional"
						color={dark ? "secondary" : "primary"}
						fontColor={dark ? "white" : "black"}
						label={intl.formatMessage({ id: 'app.survey.questions.isOptional' })}
						disabled={!!question.data.cloneId}
					/>
				}
				<FormCheckBox
					name="isDropdown"
					color={dark ? "secondary" : "primary"}
					fontColor={dark ? "white" : "black"}
					label={intl.formatMessage({ id: 'app.survey.questions.displayAsDropdown' })}
					disabled={!!question.data.cloneId}
				/>
				<FormCheckBox
					name="isCodeQuestion"
					color={dark ? "secondary" : "primary"}
					fontColor={dark ? "white" : "black"}
					label={intl.formatMessage({ id: 'app.survey.questions.isCodeQuestion' })}
					disabled={!!question.data.cloneId}
					onChange={e => setIsCodeQuestion(e.target.checked)}
				/>
				{/* {!isBundle &&
                <FormCheckBox
                    name="isConsentRequired"
                    color="primary"
                    label={intl.formatMessage({ id: 'app.survey.questions.isConsentRequired.checkBox' })}
                />} */}
			</Grid>
			{isCodeQuestion && <Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
			<AdaptiveFormTextField
					label={intl.formatMessage({ id: 'app.survey.questions.questionCodeLabel' })}
					variant="filled"
					fullWidth
					name="questionCode"
					inputProps={{ maxLength: 1023 }}
					maxLength={1023}
					disabled={!!question.data.cloneId}
				/>
			</Grid>}
			<Grid item>
				<Typography variant="h4" style={{ fontWeight: '400', paddingBottom: '10px', color: dark ? 'white' : 'initial' }}>
					Multiple Answers
				</Typography>
			</Grid>

			{answers &&
				answers.map((r, i) => {
					return (
						<Grid item style={{ width: '100%', padding: '10px 0 10px 30px ' }} key={r.id}>
							<AnswerContainer
								answer={r}
								key={r.id}
								showScoreEntry={showScoreEntry}
								showCode={isCodeQuestion}
								onDeleteAnswerCallback={onDeleteAnswerClick}
								onSwapRequestedCallback={swapAnswer}
								dark={dark}
								disabled={!!question.data.cloneId}
							/>
						</Grid>
					)
				})}

			{answers &&
			answers.length < 100 && (
				<Grid item style={{ width: '100%', paddingTop: '10px', alignSelf: 'center' }}>
					<Button variant="outlined" fullWidth color="secondary" onClick={onAddNewAnswer}>
						Add Another Answer
					</Button>
				</Grid>
			)}
		</Grid>
	)
})
