import * as api from '../../../apis/bundle'
import { createSelector } from 'reselect'
import { hideModal, stopAddingQuestion, stopBuildingQuestion, fetchBundle } from '../../actions'
import { Node, LinkList, ListContainer } from '../../../utilities/linkedList'
import history from '../../../utilities/history'
import { stopSaving } from '../core'
import { processQuestions } from '../../../utilities/questions'

const FETCH_BUNDLE_BUILDER_QUESTIONS_REQUESTED = 'FETCH_BUNDLE_BUILDER_QUESTIONS_REQUESTED'
const FETCH_BUNDLE_BUILDER_QUESTIONS_SUCCESS = 'FETCH_BUNDLE_BUILDER_QUESTIONS_SUCCESS'
const FETCH_BUNDLE_BUILDER_QUESTIONS_FAILED = 'FETCH_BUNDLE_BUILDER_QUESTIONS_FAILED'

export const fetchBundleBuilderQuestions = (id, onFetchCompleteCallback, languageId, archivedQuestionsVisible) => (dispatch) => {
	let questionIds = new Array(0)
	let hash = {}
	let container = new ListContainer()
	let tags = []
	dispatch({
		type: FETCH_BUNDLE_BUILDER_QUESTIONS_REQUESTED,
		data: {
			list: container,
			ids: questionIds,
			hash: hash,
			tags: tags
		}
	})

	api.fetchBundleQuestions(id, languageId, archivedQuestionsVisible).then((response) => {
		if (response && response.isSuccessful) {
			processQuestions(response, container, questionIds, hash, tags)

			if (onFetchCompleteCallback) {
				dispatch(onFetchCompleteCallback())
			}

			dispatch({
				type: FETCH_BUNDLE_BUILDER_QUESTIONS_SUCCESS,
				data: {
					survey: response,
					list: container,
					ids: questionIds,
					hash: hash,
					tags: tags,
					settings: {
						title: response.title,
						internalDescription: response.internalDescription
					}
				}
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.bundle.fetchBundleFailed', isSuccess: false }
			})
		}
	})
}

const SWAP_BUNDLE_QUESTION_POSITION_REQUESTED = 'SWAP_BUNDLE_QUESTION_POSITION_REQUESTED'
const SWAP_BUNDLE_QUESTION_POSITION_SUCCESS = 'SWAP_BUNDLE_QUESTION_POSITION_SUCCESS'
const SWAP_BUNDLE_QUESTION_POSITION_NOP = 'SWAP_BUNDLE_QUESTION_POSITION_NOP'

export const swapBundleQuestionPosition = (questionA, questionB, id, languageId, archivedQuestionsVisible) => (dispatch, getState) => {
	dispatch({ type: SWAP_BUNDLE_QUESTION_POSITION_REQUESTED, data: {} })
	let state = getState()
	if (questionA && questionB) {
		api.swapQuestionPosition(id, questionA.data.id, questionA.data.questionType, questionB.data.id, questionB.data.questionType).then((response) => {
			if (response && response.isSuccessful) {
				dispatch(fetchBundleBuilderQuestions(id, null, languageId, archivedQuestionsVisible))
			} else {
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.survey.swapSurveyPositionFailed', isSuccess: false }
				})
			}
		})
	}
	return dispatch({ type: SWAP_BUNDLE_QUESTION_POSITION_NOP, data: null })
}

const ADD_BUNDLE_QUESTION_REQUESTED = 'ADD_BUNDLE_QUESTION_REQUESTED'
const ADD_BUNDLE_QUESTION_SUCCESS = 'ADD_BUNDLE_QUESTION_SUCCESS'
const ADD_BUNDLE_QUESTION_FAILED = 'ADD_BUNDLE_QUESTION_FAILED'

export const addBundleQuestion = (newQuestion, position, id, archivedQuestionsVisible, isClone = false, commentsObj) => (dispatch, getState) => {
	dispatch({ type: ADD_BUNDLE_QUESTION_REQUESTED, data: {} })
	let state = getState()

	api.addBundleQuestion(newQuestion, position, id, isClone, commentsObj).then((response) => {
		dispatch(stopAddingQuestion())
		dispatch(stopSaving())
		if (response && response.isSuccessful) {
			dispatch(fetchBundle(id))
			dispatch(fetchBundleBuilderQuestions(id, null, 1, archivedQuestionsVisible))
			dispatch(stopBuildingQuestion())
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.bundle.addNewBundleQuestionSuccess', isSuccess: true }
			})
		} else {
			dispatch(stopAddingQuestion())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.bundle.addNewBundleQuestionFailed', isSuccess: false }
			})
		}
	})
}

const ADD_EXISTING_BUNDLE_QUESTION_REQUESTED = 'ADD_EXISTING_BUNDLE_QUESTION_REQUESTED'
const ADD_EXISTING_BUNDLE_QUESTION_SUCCESS = 'ADD_EXISTING_BUNDLE_QUESTION_SUCCESS'
const ADD_EXISTING_BUNDLE_QUESTION_FAILED = 'ADD_EXISTING_BUNDLE_QUESTION_FAILED'

export const addExistingBundleQuestion = (id, type, position, bundleId, archivedQuestionsVisible, commentsObj) => (dispatch, getState) => {
	dispatch({ type: ADD_EXISTING_BUNDLE_QUESTION_REQUESTED, data: {} })
	let state = getState()

	api.addExistingBundleQuestion(id, type, position, bundleId, commentsObj).then((response) => {
		dispatch(stopSaving())
		if (response && response.isSuccessful) {
			dispatch(fetchBundle(bundleId))
			dispatch(stopBuildingQuestion())
			dispatch(stopAddingQuestion())
			return dispatch(fetchBundleBuilderQuestions(bundleId, null, 1, archivedQuestionsVisible))
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.bundle.addExistingBundleQuestionFailed', isSuccess: false }
			})
		}
	})
}

const REMOVE_BUNDLE_QUESTION_REQUESTED = 'REMOVE_BUNDLE_QUESTION_REQUESTED'
const REMOVE_BUNDLE_QUESTION_SUCCESS = 'REMOVE_BUNDLE_QUESTION_SUCCESS'
const REMOVE_BUNDLE_QUESTION_FAILED = 'REMOVE_BUNDLE_QUESTION_FAILED'

export const removeBundleQuestion = (question, id, archivedQuestionsVisible) => (dispatch, getState) => {
	dispatch({ type: REMOVE_BUNDLE_QUESTION_REQUESTED, data: {} })
	let state = getState()
	api.removeQuestion(id, question.data.id, question.data.questionType).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(fetchBundleBuilderQuestions(id, null, 1, archivedQuestionsVisible))
			dispatch(fetchBundle(id))
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.surveyBuilder.removeFailed', isSuccess: false }
			})
		}
	})
}

const UPDATE_BUNDLE_QUESTION_IS_OPTIONAL_REQUESTED = 'UPDATE_BUNDLE_QUESTION_IS_OPTIONAL_REQUESTED'
const UPDATE_BUNDLE_QUESTION_IS_OPTIONAL_SUCCESS = 'UPDATE_BUNDLE_QUESTION_IS_OPTIONAL_SUCCESS'
const UPDATE_BUNDLE_QUESTION_IS_OPTIONAL_FAILED = 'UPDATE_BUNDLE_QUESTION_IS_OPTIONAL_FAILED'

export const updateBundleQuestionIsOptional = (question, id, commentsObj) => (dispatch, getState) => {
	dispatch({ type: UPDATE_BUNDLE_QUESTION_IS_OPTIONAL_REQUESTED, data: {} })
	let state = getState()
	let questionType = 0

	switch (question.type.toLowerCase()) {
		case 'single':
			questionType = 1
			break
		case 'multi':
			questionType = 2
			break
		case 'grouped':
			questionType = 3
			break
		case 'text':
			questionType = 5
			break
		case 'number':
			questionType = 6
			break
		case 'date':
			questionType = 7
			break
		case 'content':
			questionType = 8;
			break
		case 'analog':
			questionType = 9
			break
		case 'file':
			questionType = question.fileType;
			break
		default:
			questionType = 0
			break
	}

	return api.updateQuestionIsOptional(id, question.id, questionType, question.isOptional, commentsObj).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: UPDATE_BUNDLE_QUESTION_IS_OPTIONAL_SUCCESS })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.surveyBuilder.removeFailed', isSuccess: false }
			})
		}
	})
}

const UPDATE_BUNDLE_INTERNAL_DESCRIPTION_REQUESTED = 'UPDATE_BUNDLE_INTERNAL_DESCRIPTION_REQUESTED'
export const updateBundleInternalDescription = (description) => (dispatch) => {
	return dispatch({ type: UPDATE_BUNDLE_INTERNAL_DESCRIPTION_REQUESTED, data: { description } })
}

const UPDATE_BUNDLE_TITLE_REQUESTED = 'UPDATE_BUNDLE_TITLE_REQUESTED'
export const updateBundleTitle = (description) => (dispatch) => {
	return dispatch({ type: UPDATE_BUNDLE_TITLE_REQUESTED, data: { description } })
}

const START_UPDATING_BUNDLE_QUESTION_REQUESTED = 'START_UPDATING_BUNDLE_QUESTION_REQUESTED'
export const startUpdatingBundleQuestion = (callback) => (dispatch) => {
	return dispatch({ type: START_UPDATING_BUNDLE_QUESTION_REQUESTED, data: callback })
}

const STOP_UPDATING_BUNDLE_QUESTION_REQUESTED = 'STOP_UPDATING_BUNDLE_QUESTION_REQUESTED'
export const stopUpdatingBundleQuestion = () => (dispatch, getState) => {
	const state = getState()

	if (state && state.builder && state.builder.isUpdatingQuestionCallback) {
		state.builder.isUpdatingQuestionCallback()
	}
	return dispatch({ type: STOP_UPDATING_BUNDLE_QUESTION_REQUESTED, data: {} })
}

const initial = {
	bundleBuilder: {
		ids: [],
		list: new ListContainer(),
		hash: {},
		tags: [],
		formula: [],
		fences: {
			upperFence: 0,
			lowerFence: 0
		}
	},
	isBuilding: false,
	isUpdatingQuestionCallback: null
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case START_UPDATING_BUNDLE_QUESTION_REQUESTED:
			return { ...state, isUpdatingQuestionCallback: action.data }
			break

		case UPDATE_BUNDLE_INTERNAL_DESCRIPTION_REQUESTED:
			return {
				...state,
				surveyBuilder: {
					...state.bundleBuilder,
					settings: { ...state.surveyBuilder.settings, internalDescription: action.data.description }
				}
			}
		case UPDATE_BUNDLE_TITLE_REQUESTED:
			return {
				...state,
				survey: { ...state.survey, title: action.data.title }
			}
		case FETCH_BUNDLE_BUILDER_QUESTIONS_REQUESTED:
		case FETCH_BUNDLE_BUILDER_QUESTIONS_SUCCESS:
			return {
				...state,
				isUpdatingQuestionCallback: null,
				bundleBuilder: {
					...state.bundleBuilder,
					list: action.data.list,
					hash: action.data.hash,
					ids: action.data.ids,
					tags: action.data.tags
				}
			}

		case ADD_BUNDLE_QUESTION_SUCCESS:
			return {
				...state,
				bundleBuilder: {
					...state.bundleBuilder,
					list: action.data.list,
					hash: action.data.hash,
					ids: action.data.ids
				}
			}
		case SWAP_BUNDLE_QUESTION_POSITION_SUCCESS:
			return {
				...state,
				bundleBuilder: {
					...state.bundleBuilder,
					list: action.data.list,
					hash: action.data.hash,
					ids: action.data.ids
				}
			}
		case 'SIGN_OUT_REQUESTED':
			return initial

		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.bundleBuilder

export const isUpdatingBundleQuestionSelector = createSelector(mainSelector, (state) => {
	return state && state.isUpdatingQuestionCallback
})

export const builderBundleSelector = createSelector(mainSelector, (state) => {
	if (state && state.bundleBuilder && state.bundleBuilder.survey) {
		return state.bundleBuilder.survey
	}
})

export const builderBundleQuestionListSelector = createSelector(mainSelector, (state) => {
	if (state && state.bundleBuilder && state.bundleBuilder.list) {
		return state.bundleBuilder.list
	}
})

export const builderBundleQuestionHashSelector = createSelector(mainSelector, (state) => {
	if (state && state.bundleBuilder && state.bundleBuilder.hash) {
		return state.bundleBuilder.hash
	}
})

export const builderBundleQuestionIdSelector = createSelector(mainSelector, (state) => {
	if (state && state.bundleBuilder && state.bundleBuilder.ids) {
		return state.bundleBuilder.ids
	}
})

export const builderbundleQuestionTagsSelector = createSelector(mainSelector, (state) => {
	if (state && state.bundleBuilder && state.bundleBuilder.tags) {
		return state.bundleBuilder.tags
	}
})

export const builderBundleQuestionsSelector = createSelector(
	mainSelector,
	builderBundleQuestionIdSelector,
	builderBundleQuestionHashSelector,
	(state, ids, hash) => {
		if (state && ids && ids.length > 0 && hash) {
			let questions = []

			ids.map((r) => {
				if (hash.hasOwnProperty(r)) {
					let x = hash[r]
					questions.push(x)
				} else {
					console.log('No Property', questions)
				}
			})

			return questions
		}
	}
)
