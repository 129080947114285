import React from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
export default () => {
	const theme = useTheme()
	const location = useLocation()

	const getDetailsFontColor = (url) => {
		if (location.pathname.toLowerCase().indexOf(url) > -1) {
			return '#fff'
		} else {
			return '#000'
		}
	}

	const getDetailsBackgroundColor = (url) => {
		if (location.pathname.toLowerCase().indexOf(url) > -1) {
			return theme.palette.secondary.A600
		} else {
			return '#fff'
		}
	}
	const getDetailsBorderColor = (url) => {
		if (location.pathname.toLowerCase().indexOf(url) > -1) {
			return theme.palette.primary.A800
		} else {
			return '#fff'
		}
	}

	return (
		<Grid container direction="column">
			<Grid
				item
				style={{
					width: '100%',
					borderBottom: 'solid 1px rgba(0,0,0,0.5)',
					minHeight: '100px',
					padding: '0 2%',
					backgroundColor: getDetailsBackgroundColor('lockedreminders'),

					borderLeft: `solid 4px ${getDetailsBorderColor('lockedreminders')}`
				}}
			>
				<Grid container justify="space-between" alignItems="center" style={{ minHeight: '100px' }}>
					<Grid item>
						<Link
							to={`/tools/lockedreminders`}
							style={{
								textDecoration: 'none',
								fontSize: '18px'
							}}
						>
							<Typography
								variant="subtitle1"
								style={{
									color: getDetailsFontColor('lockedreminders'),
									textAlign: 'left',
									fontWeight: '400',
									wordBreak: 'break-word'
								}}
							>
								Failed Reminders
							</Typography>
						</Link>
					</Grid>
				</Grid>
			</Grid>
			<Grid item />
		</Grid>
	)
}
