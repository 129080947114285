import React from 'react'
import Paper from '@material-ui/core/Paper'
import * as UI from '../utilities/UIConstants'
import Typography from '@material-ui/core/Typography'

const CompositeLabelPaper = ({ Component, SearchComponent, label, Filter, classes, minHeight = 200, dense=false, ...other }) => (
	<div>
		<Paper elevation={UI.PAPER_ELEVATION} square={true} style={{ minHeight: minHeight }} className={classes}>
			{label && (
				<Paper
					square={true}
					className="component-label-paper"
					data-testid="composite-label-paper"
					style={{
						marginBottom: '0'
					}}
				>
					{Filter ? (
						<Filter />
					) : (
						<Typography
							className="component-label-paper-title"
							data-testid="composite-label-paper-title"
						>
							{label}
						</Typography>
					)}
				</Paper>
			)}
			{SearchComponent && (
				<div className="paper-search-inner" style={{ margin: '32px 32px 36px 32px' }}>
					<SearchComponent data-testid="composite-label-paper-search" />
				</div>
			)}
			<div className="paper-inner" style={{overflow: 'auto', height: '100%' }}>
				<div 
					data-testid="composite-label-paper-content"
					className="composite-label-paper-content"
					style={{ margin: dense ? '0' : '0 32px 1%' }}
				>
					{Component && <Component {...other} />}
				</div>
            </div>
        </Paper>
	</div>
)

export default CompositeLabelPaper
