import React from "react"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { compose } from "recompose"
import EntityAudit from "../../../components/entityAudit/EntityAudit"
import { scoringGroupChangeLogKeysSelector, scoringGroupChangeLogsSelector } from "../../../redux/selectors"
import { searchScoringGroupChangeLogs } from "../../../redux/actions"
import { useRouteMatch } from "react-router"


const ScoringGroupAudit = ({
    keys,
    logs,
    search
}) => {
    const match = useRouteMatch()

    const handleSearchBarValuesChanged = (searchPhrase, searchKeys) => {
        const scoringGroupId = match?.params?.groupId

        search(scoringGroupId, searchPhrase, searchKeys)
    }

    return (
        <EntityAudit
            keys={keys}
            logs={logs}
            onSearch={handleSearchBarValuesChanged}
        />
    )
}

const mapStateToProps = (state, ownProps) => ({
    keys: scoringGroupChangeLogKeysSelector(state),
	logs: scoringGroupChangeLogsSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    search: (scoringGroupId, searchPhrase, keys) => dispatch(searchScoringGroupChangeLogs(scoringGroupId, searchPhrase, keys))
})

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)

export default enhance(ScoringGroupAudit)
