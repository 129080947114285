import React, { useState, forwardRef, useEffect, useImperativeHandle } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { uuidv4 } from '../../utilities/utilities'
import { useSelector, shallowEqual, connect } from 'react-redux'
import { unitsSelector, measurementTypesSelector } from '../../redux/selectors'
import { useIntl } from 'react-intl'
import { fetchUnits, fetchMeasurementTypes, fetchUnitsByType } from '../../redux/actions'
import DefaultFormTextField from '../DefaultFormTextField'
import DefaultFormNumberField from '../DefaultFormNumberField'
import FormFilledInputSelectLight from '../FormFilledInputSelectLight'
import { formValueSelector } from 'redux-form'
import { required, mustBeLessThanValue } from '../../utilities/validators'
import { useStore } from 'react-redux'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'
import FormCheckBox from '../FormCheckBox'
import PIDataToggle from '../PIDataToggle'
import { getTagValidators } from '../../utilities/questions'
import FormTextField from '../FormTextField'
import { Checkbox } from '@material-ui/core'
import FormNumberTextField from '../FormNumberTextField'
import FormSelect from '../FormSelect'
const requiredValidator = required
const selector = formValueSelector('editQuestion')
const lowerLessThanMaxValueValidator = mustBeLessThanValue('maximum')

const useStyles = makeStyles((theme) => ({
	root: {
		color: 'black'
	},

	instructionRoot: {
		color: 'black',
		marginLeft: '-2px'
	},
	label: {
		textTransform: 'capitalize',
		color: 'black',
		marginLeft: '-3px'
	},
	formControl: {
		minWidth: 120,
		color: 'black'
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	menuRoot: {
		color: 'black'
	},
	menuItem: {
		color: 'black'
	}
}))

const q = forwardRef((props, ref) => {
	let classes = useStyles()
	const units = useSelector(unitsSelector, shallowEqual)
	const selectedType = useSelector((state) => selector(state, 'measurementType'), shallowEqual)
	const types = useSelector(measurementTypesSelector, shallowEqual)
	const intl = useIntl()
	const store = useStore()
	const dispatch = useDispatch()
	const { onSubmit, onCancel, question, isBundle, dark, canSetOptional = true } = props

	useEffect(
		() => {
			if (question && question.data) {
				dispatch(change('editQuestion', 'question', question.data.title))
				dispatch(change('editQuestion', 'tag', question.data.tag))
				dispatch(change('editQuestion', 'instructions', question.data.instructions))
				dispatch(change('editQuestion', 'numberType', question.data.unitId))
				dispatch(change('editQuestion', 'measurementType', question.data.measurementTypeId))
				dispatch(change('editQuestion', 'precision', question.data.digits))
				dispatch(change('editQuestion', 'minimum', question.data.minimum))
				dispatch(change('editQuestion', 'maximum', question.data.maximum))
                dispatch(change('editQuestion', 'isRegularExpressionEnabled', question.data.baseIsRegularExpressionEnabled))
				dispatch(change('editQuestion', 'regularExpression', question.data.baseRegularExpression))
				dispatch(change('editQuestion', 'regularExpressionValidation', question.data.baseRegularExpressionValidation))
				dispatch(change('editQuestion', 'isOptional', question.data.isOptional))
                dispatch(change('editQuestion', 'isConsentRequired', question.data.isConsentRequired))
                dispatch(change('editQuestion', 'isPiData', question.data.isPiData))
				dispatch(change('editQuestion', 'displayInstructionAsIcon', question.data.displayInstructionAsIcon))
			}
		},
		[ question ]
	)

	useEffect(
		() => {
			if (selectedType > 0) {
				dispatch(fetchUnitsByType(selectedType))
				dispatch(change('editQuestion', 'numberType', null))
			}
		},
		[ selectedType, question.data.measurementTypeId ]
	)

	useEffect(
		() => {
			if (units) {
				dispatch(change('editQuestion', 'numberType', question.data.unitId))
			}
		},
		[ units ]
	)

	useEffect(() => {
		dispatch(fetchMeasurementTypes())
	}, [])

	useImperativeHandle(ref, () => ({
		getQuestion() {
			return {
				id: question.data.id,
				title: selector(store.getState(), 'question'),
				type: 'number',
				instructions: selector(store.getState(), 'instructions'),
				tag: selector(store.getState(), 'tag'),
				numberType: selector(store.getState(), 'numberType'),
				precision: selector(store.getState(), 'precision'),
				minimum: selector(store.getState(), 'minimum'),
				maximum: selector(store.getState(), 'maximum'),
				isRegularExpressionEnabled: selector(store.getState(), 'isRegularExpressionEnabled'),
				regularExpression: selector(store.getState(), 'regularExpression'),
				regularExpressionValidation: selector(store.getState(), 'regularExpressionValidation'),
                isConsentRequired: selector(store.getState(), 'isConsentRequired'),
				isOptional: selector(store.getState(), 'isOptional'),
				isPiData: selector(store.getState(), 'isPiData'),
				displayInstructionAsIcon: selector(store.getState(), 'displayInstructionAsIcon'),
			}
		}
	}))

	const AdaptiveFormTextField = dark ? FormTextField : DefaultFormTextField
	const AdaptiveFormNumberField = dark ? FormNumberTextField : DefaultFormNumberField
	const AdaptiveFormFilledInputSelect = dark ? FormSelect : FormFilledInputSelectLight

	const disabled=!!question.data.cloneId

	return (
		<Grid container direction="column" alignItems="flex-start">
        {!isBundle &&
			<Grid item style={{ width: '100%' }}>
				<PIDataToggle
					fontColor={dark ? "white" : "black"}
					defaultValue={question.data.isPiData}
					onChange={(value) => dispatch(change('editQuestion', 'isPiData', value))}
					disabled={disabled}
				/>
			</Grid>}
			<Grid item style={{ width: '100%' }}>
				<AdaptiveFormTextField
					label="Tag"
					variant="filled"
					fullWidth
					name="tag"
					inputProps={{ maxLength: 254 }}
					maxLength={254}
					validators={getTagValidators(question)}
				/>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				<AdaptiveFormTextField
					label="Question"
					variant="filled"
					fullWidth
					name="question"
					validators={[ requiredValidator ]}
					inputProps={{ maxLength: 1023 }}
					maxLength={1023}
					disabled={disabled}
				/>
			</Grid>

			<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
				<AdaptiveFormTextField
					label="Instructions"
					variant="filled"
					name="instructions"
					fullWidth
					inputProps={{ maxLength: 2047 }}
					maxLength={2047}
					rows="3"
					multiline
					disabled={disabled}
				/>
			</Grid>
			<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '0px' }}>
				<FormCheckBox
					name="displayInstructionAsIcon"
					color={dark ? "secondary" : "primary"}
					fontColor={dark ? "white" : "black"}
					label={intl.formatMessage({ id: 'app.survey.questions.displayInstructionAsIcon' })}
					disabled={disabled}
				/>
				{
					!!canSetOptional
					&& <FormCheckBox
						name="isOptional"
						color={dark ? "secondary" : "primary"}
						fontColor={dark ? "white" : "black"}
						label={intl.formatMessage({ id: 'app.survey.questions.isOptional' })}
						disabled={disabled}
					/>
				}
                <FormCheckBox
                    name="isRegularExpressionEnabled"
					color={dark ? "secondary" : "primary"}
					fontColor={dark ? "white" : "black"}
                    label={intl.formatMessage({ id: 'app.survey.questions.regularExpression.checkBox' })}
					disabled={disabled}
				/>
				{/* {!isBundle &&
                <FormCheckBox
                    name="isConsentRequired"
                    color="primary"
                    label={intl.formatMessage({ id: 'app.survey.questions.isConsentRequired.checkBox' })}
                />} */}
			</Grid>
			<Grid item>
				<Typography variant="h4" style={{ fontWeight: '400', paddingBottom: '10px', color: dark ? 'white' : 'initial' }}>
					Number Answer
				</Typography>
			</Grid>
			<Grid item>
				<Grid container direction="row" spacing={2}>
					<Grid item>
						<AdaptiveFormNumberField
							label="Minimum"
							name="minimum"
							variant="filled"
							validators={[ lowerLessThanMaxValueValidator ]}
							allowDecimal={true}
							disabled={disabled}
						/>
					</Grid>
					<Grid item>
						<AdaptiveFormNumberField
							label="Maximum"
							name="maximum"
							variant="filled"
							allowDecimal={true}
							disabled={disabled}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid container spacing={2}>
					<Grid item style={{ width: '200px' }}>
						<AdaptiveFormFilledInputSelect
							label="Measurement Type"
							name="measurementType"
							validators={[ requiredValidator ]}
							data={types}
							style={{ color: !!disabled ? 'grey' : 'white' }}
							fullWidth
							disabled={disabled}
						/>
					</Grid>
					<Grid item style={{ width: '200px' }}>
						<AdaptiveFormFilledInputSelect
							label="Unit"
							name="numberType"
							validators={[ requiredValidator ]}
							data={
								units &&
								units.map((r) => {
									return { name: r.suffix, value: r.id }
								})
							}
							style={{ color: !!disabled ? 'grey' : 'white' }}
							fullWidth
							disabled={disabled}
						/>
					</Grid>
					<Grid item style={{ width: '100px' }}>
						<AdaptiveFormFilledInputSelect
							label="Digits"
							name="precision"
							data={[
								{ name: 1, value: 1 },
								{ name: 2, value: 2 },
								{ name: 3, value: 3 },
								{ name: 4, value: 4 },
								{ name: 5, value: 5 }
							]}
							style={{ color: !!disabled ? 'grey' : 'white' }}
							fullWidth
							disabled={disabled}
						/>
					</Grid>
				</Grid>
			</Grid>
            {
				props.isRegularExpressionEnabled &&
				<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
					<Typography variant="h4" style={{ fontWeight: '400', paddingBottom: '10px', color: dark ? 'white' : 'initial' }}>
						{intl.formatMessage({ id: 'app.survey.questions.regularExpression.label' })}
					</Typography>
					<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
						<AdaptiveFormTextField
							label={intl.formatMessage({ id: 'app.survey.questions.regularExpression.value' })}
							variant="filled"
							fullWidth
							name="regularExpression"
							validators={[requiredValidator]}
							inputProps={{ maxLength: 1024 }}
							maxLength={1024}
							multiline
							rows="3"
							disabled={disabled}
						/>
					</Grid>
					<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
						<AdaptiveFormTextField
							label={intl.formatMessage({ id: 'app.survey.questions.regularExpression.message' })}
							variant="filled"
							fullWidth
							name="regularExpressionValidation"
							validators={[requiredValidator]}
							inputProps={{ maxLength: 1024 }}
							maxLength={1024}
							multiline
							rows="3"
							style={{ color: 'white' }}
							disabled={disabled}
						/>
					</Grid>
				</Grid>
			}
		</Grid>
	)
})

const mapStateToProps = (state) => ({
    isRegularExpressionEnabled: selector(state, 'isRegularExpressionEnabled')
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(q)