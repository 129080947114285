import React, { useState } from 'react'
import { Field, reduxForm } from 'redux-form'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import InputBase from '@material-ui/core/InputBase'
import FormHelperText from '@material-ui/core/FormHelperText'
import MaskedInput from 'react-text-mask'

const styles = {
	input: {
		//color: 'white',
		fontSize: '1rem'
		//backgroundColor: 'rgb(36, 48, 65)'
	},

	root: {
		//color: 'white'
	},
	focused: {
		//color: 'white',
		//backgroundColor: 'rgb(36, 48, 65)',
		'&$focused': {
			color: 'white',
			backgroundColor: 'rgb(36, 48, 65)'
		}
	}
}

const useLabelStyles = makeStyles({
	root: {
		//color: 'white',
		//backgroundColor: 'rgb(36, 48, 65)'
	},
	focused: {
		//color: 'white',
		//	backgroundColor: 'rgb(36, 48, 65)',
		'&$focused': {
			color: 'black',
			backgroundColor: 'white'
		}
	}
})

const useInputStyles = makeStyles({
	root: {
		//color: 'white',
		position: 'relative',
		fontSize: '16px'
		//transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
		//backgroundColor: 'rgba(0, 0, 0, 0.09)',
	},
	focused: {
		color: 'black',
		backgroundColor: 'white',
		'&$focused': {
			color: 'black',
			backgroundColor: 'white'
		}
	}
})
function TextMaskCustom(props) {
	const { inputRef, hasFocus, backgroundColor, fontColor, style, ...other } = props

	return (
		<div>
			<MaskedInput
				{...other}
				ref={(ref) => {
					inputRef(ref ? ref.inputElement : null)
				}}
				mask={[ /\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/ ]}
				placeholderChar={'\u2000'}
				showMask={hasFocus}
				guide={false}
				style={{
					...style,
					marginTop: '22px',
					borderBottom: 'solid 1px black'
				}}
			/>
		</div>
	)
}

const renderTextField = ({
	classes,
	label,
	input,
	meta: { touched, invalid, error },
	inputLabelProps,
	inputProps,
	style,
	fullWidth,
	margin,
	variant,
	type,
	labelStyles,
	inputStyles,
	maxLength,
	setHasFocus,
	hasFocus,
	backgroundColor,
	fontColor,
	...custom
}) => {
	return (
		<div className="global-input-wrapper" style={{ width: '90px' }}>
			<FormControl error={touched && invalid} fullWidth>
				<InputLabel
					variant="filled"
					htmlFor="formatted-text-mask-input"
					style={{ backgroundColor: 'white', color: 'black' }}
					classes={{
						root: labelStyles.root,
						focused: labelStyles.focused
					}}
				/>
				<InputBase
					error={touched && invalid}
					style={{ padding: 0 }}
					inputProps={{ maxLength: maxLength }}
					{...custom}
					{...input}
					placeholder="YYYY/MM/DD"
					id="formatted-text-mask-input"
					maxLength={maxLength}
					inputComponent={TextMaskCustom}
					autoComplete={false}
				/>
				<FormHelperText
					style={{ backgroundColor: 'white', margin: 0, padding: '8px 12px 0' }}
					classes={{ root: inputStyles.error }}
				>
					{touched && error}
				</FormHelperText>
			</FormControl>
		</div>
	)
}

const SurveyBirthDateField = ({
	classes,
	label,
	name,
	inputLabelProps,
	inputProps,
	style,
	fullWidth,
	margin,
	variant,
	type,
	validators,
	backgroundColor = 'white',
	fontColor = 'black',
	maxLength = 10
}) => {
	var labelStyles = useLabelStyles()
	var inputStyles = useInputStyles()
	const [ hasFocus, setHasFocus ] = useState(false)
	return (
		<Field
			name={name}
			hasFocus={hasFocus}
			setHasFocus={setHasFocus}
			component={renderTextField}
			label={label}
			inputLabelProps={inputLabelProps}
			inputProps={inputProps}
			style={style}
			fullWidth={fullWidth}
			margin={margin}
			variant={variant}
			type={type}
			validate={validators}
			maxLength={maxLength}
			classes={classes}
			labelStyles={labelStyles}
			inputStyles={inputStyles}
		/>
	)
}

export default withStyles(styles)(SurveyBirthDateField)
