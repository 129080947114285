import React, { useState, useEffect, Fragment } from 'react'
import IconButton from '@material-ui/core/IconButton'
import MuiMenu from '@material-ui/core/Menu'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { MenuItem } from '@material-ui/core'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { useDispatch } from 'react-redux'

const ITEM_HEIGHT = 48

const QuestionControlMenu = ({
	intl,
	question,
	surveyState,
	archiveQuestion,
	getCurrentQuestionState,
	archiveBundle,
	getCurrentBundleState,
	addCondition,
	changeIsQuestionOptional,
	changeIsBundleOptional,
	getCurrentQuestionIsOptionalOverride,
	getCurrentBundleIsOptionalOverride,
	changeIsQuestionBilateral,
	changeIsBundleBilateral,
	getCurrentBundleIsBilateral,
	getCurrentQuestionIsBilateral,
	canChangeBilateral
}) => {
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)
	const dispatch = useDispatch()

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}
	
	useEffect(() => {}, [])

	const getQuestionIsOptionalOverride = () => {
		let current = getCurrentQuestionIsOptionalOverride(question.baseId, question.questionType)
		if(current !== undefined)
			return current
		else
			return question.isOptionalOverride
	}

	const getBundleIsOptionalOverride = () => {
		let current = getCurrentBundleIsOptionalOverride(question.bundleEntityId)
		if(current !== undefined)
			return current
		else
			return question.isOptionalOverride
	}

	const getBundleIsBilateral = () => {
		let current = getCurrentBundleIsBilateral(question.bundleEntityId)
		if(current !== undefined)
			return current
		else
			return question.isBilateral
	}

	const getQuestionIsBilateral = () => {
		let current = getCurrentQuestionIsBilateral(question.baseId, question.questionType)
		if(current !== undefined)
			return current
		else
			return question.isBilateral
	}

	return (
		<div>
			<Fragment>
				<IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
					<MoreVertIcon style={{ color: 'black' }} />
				</IconButton>
				<MuiMenu
					id="long-menu"
					anchorEl={anchorEl}
					keepMounted
					open={open}
					onClose={handleClose}
					PaperProps={{
						style: {
							maxHeight: ITEM_HEIGHT * 4.5,
							width: 250
						}
					}}
				>
					{
						question.isBundle
						&& <MenuItem
							onClick={() => {
								archiveBundle(question.bundleEntityId, question.state)
								handleClose()
							}}
						>
							{
								(
									question.state === 3
									&& !getCurrentBundleState(question.bundleEntityId)
								)
								|| getCurrentBundleState(question.bundleEntityId) === 3
									? intl.formatMessage({ id: 'app.survey.unarchiveMenuItemText' })
									: intl.formatMessage({ id: 'app.survey.archiveMenuItemText' })
							}
						</MenuItem>
					}
					{
						question.isBundle
						&& surveyState === 1
						&& (
							(
								question.state === 1
								&& !getCurrentBundleState(question.bundleEntityId)
							)
							|| getCurrentBundleState(question.bundleEntityId) === 1
						)
						&& <MenuItem
							onClick={() => {
								addCondition(question)
								handleClose()
							}}
						>
							{ intl.formatMessage({ id: 'app.survey.conditionVisibilityMenuItemText' }) }
						</MenuItem>
					}
					{
						!question.isBundle
						&& <MenuItem
							onClick={() => {
								archiveQuestion(question.baseId, question.questionType, question.state)
								handleClose()
							}}
						>
							{
								(
									question.state === 3
									&& !getCurrentQuestionState(question.baseId, question.questionType)
								)
								|| getCurrentQuestionState(question.baseId, question.questionType) === 3
									? intl.formatMessage({ id: 'app.survey.unarchiveMenuItemText' })
									: intl.formatMessage({ id: 'app.survey.archiveMenuItemText' })
							}
						</MenuItem>
					}
					{
						!question.isBundle
						&& surveyState === 1
						&& (
							(
								question.state === 1
								&& !getCurrentQuestionState(question.baseId, question.questionType)
							)
							|| getCurrentQuestionState(question.baseId, question.questionType) === 1
						)
						&& <MenuItem
							onClick={() => {
								addCondition(question)
								handleClose()
							}}
						>
							{ intl.formatMessage({ id: 'app.survey.conditionVisibilityMenuItemText' }) }
						</MenuItem>
					}
					{
						!question.isBundle
						&& question.questionType !== 8
						&& (getQuestionIsOptionalOverride() === null || !getQuestionIsOptionalOverride())
						&& <MenuItem
							onClick={() => {
								changeIsQuestionOptional(question.baseId, question.questionType, true)
								handleClose()
							}}
						>
							{ intl.formatMessage({ id: 'app.survey.makeQuestionOptionalMenuItemText' }) }
						</MenuItem>
					}
					{
						!question.isBundle
						&& question.questionType !== 8
						&& (getQuestionIsOptionalOverride() === null || !!getQuestionIsOptionalOverride())
						&& <MenuItem
							onClick={() => {
								changeIsQuestionOptional(question.baseId, question.questionType, false)
								handleClose()
							}}
						>
							{ intl.formatMessage({ id: 'app.survey.makeQuestionRequiredMenuItemText' }) }
						</MenuItem>
					}
					{
						!question.isBundle
						&& question.questionType !== 8
						&& getQuestionIsOptionalOverride() !== null
						&& <MenuItem
							onClick={() => {
								changeIsQuestionOptional(question.baseId, question.questionType, null)
								handleClose()
							}}
						>
							{ intl.formatMessage({ id: 'app.survey.resetIsQuestionOptionalMenuItemText' }) }
						</MenuItem>
					}
					{
						canChangeBilateral 
						&& !question.isBundle
						&& question.questionType !== 8
						&& getQuestionIsBilateral(question.baseId, question.questionType)
						&& <MenuItem
							onClick={() => {
								changeIsQuestionBilateral(question.baseId, question.questionType, false)
								handleClose()
							}}
						>
							{ intl.formatMessage({ id: 'app.survey.makeQuestionUnilateralMenuItemText' }) }
						</MenuItem>
					}
					{
						canChangeBilateral 
						&& !question.isBundle
						&& question.questionType !== 8
						&& !getQuestionIsBilateral(question.baseId, question.questionType)
						&& <MenuItem
							onClick={() => {
								changeIsQuestionBilateral(question.baseId, question.questionType, true)
								handleClose()
							}}
						>
							{ intl.formatMessage({ id: 'app.survey.makeQuestionBilateralMenuItemText' }) }
						</MenuItem>
					}
					{
						question.isBundle
						&& (getBundleIsOptionalOverride() === null || !getBundleIsOptionalOverride())
						&& <MenuItem
							onClick={() => {
								changeIsBundleOptional(question.bundleEntityId, true)
								handleClose()
							}}
						>
							{ intl.formatMessage({ id: 'app.survey.makeBundleOptionalMenuItemText' }) }
						</MenuItem>
					}
					{
						question.isBundle
						&& (getBundleIsOptionalOverride() === null || !!getBundleIsOptionalOverride())
						&& <MenuItem
							onClick={() => {
								changeIsBundleOptional(question.bundleEntityId, false)
								handleClose()
							}}
						>
							{ intl.formatMessage({ id: 'app.survey.makeBundleRequiredMenuItemText' }) }
						</MenuItem>
					}
					{
						question.isBundle
						&& getBundleIsOptionalOverride() !== null
						&& <MenuItem
							onClick={() => {
								changeIsBundleOptional(question.bundleEntityId, null)
								handleClose()
							}}
						>
							{ intl.formatMessage({ id: 'app.survey.resetIsBundleOptionalMenuItemText' }) }
						</MenuItem>
					}
					{
						canChangeBilateral 
						&& question.isBundle
						&& getBundleIsBilateral(question.bundleEntityId)
						&& <MenuItem
							onClick={() => {
								changeIsBundleBilateral(question.bundleEntityId, false)
								handleClose()
							}}
						>
							{ intl.formatMessage({ id: 'app.survey.makeQuestionUnilateralMenuItemText' }) }
						</MenuItem>
					}
					{
						canChangeBilateral 
						&& question.isBundle
						&& !getBundleIsBilateral(question.bundleEntityId)
						&& <MenuItem
							onClick={() => {
								changeIsBundleBilateral(question.bundleEntityId, true)
								handleClose()
							}}
						>
							{ intl.formatMessage({ id: 'app.survey.makeQuestionBilateralMenuItemText' }) }
						</MenuItem>
					}
				</MuiMenu>
			</Fragment>
		</div>
	)
}

const enhance = compose(injectIntl)

export default enhance(QuestionControlMenu)
