import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import DefaultFormTextField from '../DefaultFormTextField'
import { formValueSelector } from 'redux-form'
import { required } from '../../utilities/validators'
import { useStore, connect } from 'react-redux'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'
import FormCheckBox from '../FormCheckBox'
import { useIntl } from 'react-intl'
import PIDataToggle from '../PIDataToggle'
import { getTagValidators } from '../../utilities/questions'
import FormTextField from '../FormTextField'
import FormNumberTextField from '../FormNumberTextField'
const requiredValidator = required
const selector = formValueSelector('editQuestion')

const q = forwardRef((props, ref) => {
	const { question, isBundle, dark, canSetOptional = true } = props
	const store = useStore()
	const dispatch = useDispatch()
	const intl = useIntl()
	useEffect(
		() => {
			if (question && question.data) {
				dispatch(change('editQuestion', 'question', question.data.title))
				dispatch(change('editQuestion', 'instructions', question.data.instructions))
				dispatch(change('editQuestion', 'tag', question.data.tag))
                dispatch(change('editQuestion', 'isRegularExpressionEnabled', question.data.baseIsRegularExpressionEnabled))
				dispatch(change('editQuestion', 'regularExpression', question.data.baseRegularExpression))
				dispatch(change('editQuestion', 'regularExpressionValidation', question.data.baseRegularExpressionValidation))
				dispatch(change('editQuestion', 'isOptional', question.data.isOptional))
                dispatch(change('editQuestion', 'isConsentRequired', question.data.isConsentRequired))
                dispatch(change('editQuestion', 'isPiData', question.data.isPiData))
				dispatch(change('editQuestion', 'displayInstructionAsIcon', question.data.displayInstructionAsIcon))
			}
		},
		[ question ]
	)

	useImperativeHandle(ref, () => ({
		getQuestion() {
			return {
				id: question.data.id,
				title: selector(store.getState(), 'question'),
				type: 'date',
				instructions: selector(store.getState(), 'instructions'),
				tag: selector(store.getState(), 'tag'),
				isRegularExpressionEnabled: selector(store.getState(), 'isRegularExpressionEnabled'),
				regularExpression: selector(store.getState(), 'regularExpression'),
				regularExpressionValidation: selector(store.getState(), 'regularExpressionValidation'),
                isConsentRequired: selector(store.getState(), 'isConsentRequired'),
				isOptional: selector(store.getState(), 'isOptional'),
				isPiData: selector(store.getState(), 'isPiData'),
				displayInstructionAsIcon: selector(store.getState(), 'displayInstructionAsIcon'),
			}
		}
	}))
	useEffect(() => {
		var el = document.getElementsByName('tag')
		if (el != null) {
			el[0].focus()
		}
	}, [])

	const AdaptiveFormTextField = dark ? FormTextField : DefaultFormTextField

	return (
		<Grid container direction="column" alignItems="flex-start">
        {!isBundle &&
			<Grid item style={{ width: '100%' }}>
				<PIDataToggle
					fontColor={dark ? "white" : "black"}
					defaultValue={question.data.isPiData}
					onChange={(value) => dispatch(change('editQuestion', 'isPiData', value))}
					disabled={!!question.data.cloneId}
				/>
			</Grid>}
			<Grid item style={{ width: '100%' }}>
				<AdaptiveFormTextField
					label="Tag"
					variant="filled"
					fullWidth
					name="tag"
					inputProps={{ maxLength: 254 }}
					maxLength={254}
					validators={getTagValidators(question)}
				/>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				<AdaptiveFormTextField
					label="Question"
					variant="filled"
					fullWidth
					name="question"
					validators={[ requiredValidator ]}
					inputProps={{ maxLength: 1023 }}
					maxLength={1023}
					disabled={!!question.data.cloneId}
				/>
			</Grid>

			<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
				<AdaptiveFormTextField
					label="Instructions"
					variant="filled"
					name="instructions"
					fullWidth
					inputProps={{ maxLength: 2047 }}
					maxLength={2047}
					rows="3"
					multiline
					disabled={!!question.data.cloneId}
				/>
			</Grid>
			<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '0px' }}>
				<FormCheckBox
					name="displayInstructionAsIcon"
					color={dark ? "secondary" : "primary"}
					fontColor={dark ? "white" : "black"}
					label={intl.formatMessage({ id: 'app.survey.questions.displayInstructionAsIcon' })}
					disabled={!!question.data.cloneId}
				/>
				{
					!!canSetOptional
					&& <FormCheckBox
						name="isOptional"
						color={dark ? "secondary" : "primary"}
						fontColor={dark ? "white" : "black"}
						label={intl.formatMessage({ id: 'app.survey.questions.isOptional' })}
						disabled={!!question.data.cloneId}
					/>
				}
                <FormCheckBox
                    name="isRegularExpressionEnabled"
					color={dark ? "secondary" : "primary"}
					fontColor={dark ? "white" : "black"}
                    label={intl.formatMessage({ id: 'app.survey.questions.regularExpression.checkBox' })}
					disabled={!!question.data.cloneId}
				/>
            {/* {!isBundle &&
				<FormCheckBox
                    name="isConsentRequired"
                    color="primary"
                    label={intl.formatMessage({ id: 'app.survey.questions.isConsentRequired.checkBox' })}
                />} */}
			</Grid>
            {
				props.isRegularExpressionEnabled &&
				<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
					<Typography variant="h4" style={{ fontWeight: '400', paddingBottom: '10px', color: dark ? 'white' : 'initial' }}>
						{intl.formatMessage({ id: 'app.survey.questions.regularExpression.label' })}
					</Typography>
					<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
						<AdaptiveFormTextField
							label={intl.formatMessage({ id: 'app.survey.questions.regularExpression.value' })}
							variant="filled"
							fullWidth
							name="regularExpression"
							validators={[requiredValidator]}
							inputProps={{ maxLength: 1024 }}
							maxLength={1024}
							multiline
							rows="3"
							disabled={!!question.data.cloneId}
						/>
					</Grid>
					<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
						<AdaptiveFormTextField
							label={intl.formatMessage({ id: 'app.survey.questions.regularExpression.message' })}
							variant="filled"
							fullWidth
							name="regularExpressionValidation"
							validators={[requiredValidator]}
							inputProps={{ maxLength: 1024 }}
							maxLength={1024}
							multiline
							rows="3"
							disabled={!!question.data.cloneId}
						/>
					</Grid>
				</Grid>
			}
		</Grid>
	)
})

const mapStateToProps = (state) => ({
    isRegularExpressionEnabled: selector(state, 'isRegularExpressionEnabled')
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(q)