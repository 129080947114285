import React, {useEffect, useState} from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useTheme, withStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import { showModal } from '../../../redux/actions'
import Button from '@material-ui/core/Button'
import Add from '@material-ui/icons/Add'
import InviteTeamMember from "../InviteTeamMember"

const AddTeamMembers = ({ onShowModal, classes, intl }) => {
    const [ circleId, setCircleId ] = useState(null)

    const onAddClick = () => {
        onShowModal(circleId)
    }

    return (
		<Button onClick={onAddClick}
            data-testid="add-member-button"
			style={{ margin: '8px -16px 0 32px' }}
			variant="text" 
			color="primary" 
			size="large"
		>
				{intl.formatMessage({ id: 'app.userProfile.addTeamMemberButtonText' })}
			<Add viewBox="-3 -3 30 30"/>
		</Button>
    )
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
    onShowModal: (circleId) => dispatch(showModal(InviteTeamMember, {circleId: circleId}))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(AddTeamMembers)
