import React, { useState, forwardRef, useEffect, useImperativeHandle, Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { uuidv4 } from '../../utilities/utilities'
import { useIntl } from 'react-intl'
import { fetchUnits, fetchMeasurementTypes, fetchUnitsByType } from '../../redux/actions'
import DefaultFormTextField from '../DefaultFormTextField'
import DefaultFormNumberField from '../DefaultFormNumberField'
import FormFilledInputSelectLight from '../FormFilledInputSelectLight'
import FormCheckBox from '../FormCheckBox'
import { formValueSelector } from 'redux-form'
import { required } from '../../utilities/validators'
import { useStore } from 'react-redux'
import { useDispatch, connect } from 'react-redux'
import { change } from 'redux-form'
import { useSelector, shallowEqual } from 'react-redux'
import { languageNameSelector, unitsSelector, measurementTypesSelector } from '../../redux/selectors'
const requiredValidator = required
const selector = formValueSelector('editQuestion')

const useStyles = makeStyles((theme) => ({
	root: {
		color: 'black'
	},

	instructionRoot: {
		color: 'black',
		marginLeft: '-2px'
	},
	label: {
		textTransform: 'capitalize',
		color: 'black',
		marginLeft: '-3px'
	},
	formControl: {
		minWidth: 120,
		color: 'black'
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	menuRoot: {
		color: 'black'
	},
	menuItem: {
		color: 'black'
	}
}))

const q = forwardRef((props, ref) => {
	let classes = useStyles()
	const units = useSelector(unitsSelector, shallowEqual)
	const selectedType = useSelector((state) => selector(state, 'measurementType'), shallowEqual)
	const types = useSelector(measurementTypesSelector, shallowEqual)
	const intl = useIntl()
	const store = useStore()
	const dispatch = useDispatch()
	let languages = useSelector(languageNameSelector, shallowEqual)
	const { onSubmit, onCancel, question } = props

	useEffect(() => {
		dispatch(fetchMeasurementTypes())
	}, [])

	useEffect(
		() => {
			dispatch(fetchUnits())
			if (question && question.data) {
				dispatch(change('editQuestion', 'question', question.data.title))
				dispatch(change('editQuestion', 'tag', question.data.tag))
				dispatch(change('editQuestion', 'instructions', question.data.instructions))
				dispatch(change('editQuestion', 'numberType', question.data.unitId))
				dispatch(change('editQuestion', 'measurementType', question.data.measurementTypeId))
				dispatch(change('editQuestion', 'precision', question.data.digits))
				dispatch(change('editQuestion', 'maximum', question.data.maximum))
				dispatch(change('editQuestion', 'minimum', question.data.minimum))
                dispatch(change('editQuestion', 'isRegularExpressionEnabled', question.data.isRegularExpressionEnabled))
				dispatch(change('editQuestion', 'regularExpression', question.data.regularExpression))
				dispatch(change('editQuestion', 'regularExpressionValidation', question.data.regularExpressionValidation))
                dispatch(change('editQuestion', 'isConsentRequired', question.data.isConsentRequired))
			}
		},
		[ question ]
	)

	useImperativeHandle(ref, () => ({
		getQuestion() {
			return {
				id: question.data.id,
				title: selector(store.getState(), 'question'),
				type: 'number',
				canonicalId: question.data.canonicalId,
				instructions: selector(store.getState(), 'instructions'),
				tag: selector(store.getState(), 'tag'),
				numberType: selector(store.getState(), 'numberType'),
				precision: selector(store.getState(), 'precision'),
				isRegularExpressionEnabled: selector(store.getState(), 'isRegularExpressionEnabled'),
                regularExpression: selector(store.getState(), 'regularExpression'),
                regularExpressionValidation: selector(store.getState(), 'regularExpressionValidation'),
                isConsentRequired: selector(store.getState(), 'isConsentRequired')
			}
		}
	}))

	return (
		<Grid container direction="column" alignItems="flex-start">
			<Grid item style={{ width: '100%' }}>
				<Typography variant="subtitle2">
					Title ({languages(1)}): {question.data.baseTitle}
				</Typography>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				<DefaultFormTextField
					label={`Title (${languages(question.data.languageId)})`}
					variant="filled"
					fullWidth
					name="question"
					validators={[ requiredValidator ]}
					inputProps={{ maxLength: 1023 }}
					maxLength={1023}
				/>
			</Grid>

			{question.data.baseInstructions && (
				<Fragment>
					<Grid item style={{ width: '100%' }}>
						<Typography variant="subtitle2">
							Instructions ({languages(1)}): {question.data.baseInstructions}
						</Typography>
					</Grid>
					<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
						<DefaultFormTextField
							label={`Instructions (${languages(question.data.languageId)})`}
							variant="filled"
							name="instructions"
							fullWidth
							inputProps={{ maxLength: 2047 }}
							rows="3"
							maxLength={2047}
							multiline
						/>
					</Grid>
				</Fragment>
			)}

			<Grid item>
				<Typography variant="h4" style={{ fontWeight: '400', paddingBottom: '10px' }}>
					Number Answer
				</Typography>
			</Grid>
			<Grid item>
				<Grid container direction="row" spacing={2}>
					<Grid item>
						<DefaultFormNumberField
							label="Minimum"
							name="minimum"
							variant="filled"
							disabled={true}
							allowDecimal={true}
						/>
					</Grid>
					<Grid item>
						<DefaultFormNumberField
							label="Maximum"
							name="maximum"
							variant="filled"
							disabled={true}
							allowDecimal={true}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid item>
					<Grid container spacing={2}>
						<Grid item style={{ width: '200px' }}>
							<FormFilledInputSelectLight
								label="Measurement Type"
								name="measurementType"
								validators={[ requiredValidator ]}
								data={types}
								style={{ color: 'black' }}
								fullWidth
								disabled={true}
							/>
						</Grid>
						<Grid item style={{ width: '200px' }}>
							<FormFilledInputSelectLight
								label="Unit"
								name="numberType"
								validators={[ requiredValidator ]}
								data={
									units &&
									units.map((r) => {
										return { name: r.suffix, value: r.id }
									})
								}
								style={{ color: 'black' }}
								fullWidth
								disabled={true}
							/>
						</Grid>
						<Grid item style={{ width: '100px' }}>
							<FormFilledInputSelectLight
								label="Digits"
								name="precision"
								data={[
									{ name: 1, value: 1 },
									{ name: 2, value: 2 },
									{ name: 3, value: 3 },
									{ name: 4, value: 4 },
									{ name: 5, value: 5 }
								]}
								style={{ color: 'black' }}
								fullWidth
								disabled={true}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

            {
				props.isRegularExpressionEnabled &&
				<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
					<Typography variant="h4" style={{ fontWeight: '400', paddingBottom: '10px' }}>
						{intl.formatMessage({ id: 'app.survey.questions.regularExpression.label' })}
					</Typography>
					<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
						<DefaultFormTextField
							label={intl.formatMessage({ id: 'app.survey.questions.regularExpression.value' })}
							variant="filled"
							fullWidth
							name="regularExpression"
							validators={[requiredValidator]}
							inputProps={{ maxLength: 1024 }}
							maxLength={1024}
							multiline
							rows="3"
						/>
					</Grid>
					<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
						<DefaultFormTextField
							label={intl.formatMessage({ id: 'app.survey.questions.regularExpression.message' })}
							variant="filled"
							fullWidth
							name="regularExpressionValidation"
							validators={[requiredValidator]}
							inputProps={{ maxLength: 1024 }}
							maxLength={1024}
							multiline
							rows="3"
						/>
					</Grid>
				</Grid>
			}
		</Grid>
	)
})

const mapStateToProps = (state) => ({
    isRegularExpressionEnabled: selector(state, 'isRegularExpressionEnabled')
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(q)