import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useTheme from '@material-ui/core/styles/useTheme'
import { isCreatingProtocolSelector } from '../../../redux/selectors'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import FormTextField from '../../../components/FormTextField'
import { required } from '../../../utilities/validators'
import Button from '@material-ui/core/Button'
import { editGroup } from '../../../redux/actions'
import { bundleSelector } from '../../../redux/selectors'
import { reduxForm, formValueSelector, change } from 'redux-form'
import { useDispatch } from 'react-redux'
const requiredValidator = required
const selector = formValueSelector('editGroup')

const EditGroupModal = ({ intl, handleSubmit, edit, id, name }) => {
	const theme = useTheme()
	const dispatch = useDispatch()

	const onSubmitForm = (values) => {
		dispatch(editGroup(id, values.name))
	}

	useEffect(
		() => {
			dispatch(change('editGroup', 'name', name))
		},
		[ id, name ]
	)

	return (
		<form onSubmit={handleSubmit(onSubmitForm)}>
			<Grid
				container
				direction="column"
				justify="flex-start"
				alignItems="center"
				style={{ width: '75rem', padding: '4rem', margin: 'auto', backgroundColor: theme.palette.secondary.main }}
			>
				<Grid item style={{ alignSelf: 'center' }}>
					<Typography
						variant="h1"
						style={{
							color: '#fff',
							marginBottom: '25px',
						}}
					>
						{intl.formatMessage({ id: 'app.group.editGroupModalTitle' })}
					</Typography>
				</Grid>
				<Grid item style={{ alignSelf: 'flex-start', width: '100%' }}>
					<FormTextField
						label={intl.formatMessage({ id: 'app.group.groupNamePlaceholder' }) + '*'}
						fullWidth
						type="text"
						name="name"
						maxLength={254}
						validators={[ requiredValidator ]}
						variant="filled"
					/>
				</Grid>

				<Grid item>
					<Button
						label={intl.formatMessage({ id: 'app.group.editButtonLabel' })}
						style={{
							width: '267px',
							marginTop: '40px'
						}}
						type="submit"
                        variant="contained"
                        color="primary"
					>
						{intl.formatMessage({ id: 'app.group.editButtonLabel' })}
					</Button>
				</Grid>
			</Grid>
		</form>
	)
}

const enhance = compose(
	injectIntl,
	reduxForm({
		form: 'editGroup',
		touchOnChange: true,
		touchOnBlur: true
	})
)

export default enhance(EditGroupModal)
