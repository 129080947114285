import React, { useRef } from 'react'
import { compose } from 'recompose'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'
import { stopUpdatingQuestion, startSaving, stopSaving } from '../../redux/actions'
import { isSavingSelector } from '../../redux/selectors'
import TextQuestionTranslator from './TextQuestionTranslator'
import FileQuestionTranslator from './FileQuestionTranslator'
import NumberQuestionTranslator from './NumberQuestionTranslator'
import DateQuestionTranslator from './DateQuestionTranslator'
import RadioQuestionTranslator from './RadioSelectTranslator/SurveyQuestionTypeRadioSelectTranslator'
import MultiQuestionTranslator from './MultiSelectTranslator/SurveyQuestionTypeMultiSelectTranslator'
import GroupedQuestionTranslator from './GroupedTranslator/SurveyQuestionTypeGroupedTranslator'
import SurveyContentTextTranslator from './SurveyContentTextTranslator'
import AnalogQuestionTranslator from './AnalogQuestionTranslator'
import { useDispatch } from 'react-redux'
import { reduxForm } from 'redux-form'
import { useRouteMatch } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import CalmButton from '../../components/CalmButton'

const renderTranslator = (childRef, question, showScoreEntry) => {
	if (question && question.data) {
		switch (question.data.questionType) {
			case 5:
				return <TextQuestionTranslator ref={childRef} question={question} />
				break
			case 6:
				return <NumberQuestionTranslator ref={childRef} question={question} />
				break
			case 7:
				return <DateQuestionTranslator ref={childRef} question={question} />
				break
			case 1:
				return <RadioQuestionTranslator ref={childRef} question={question} showScoreEntry={showScoreEntry} />
				break
			case 2:
				return <MultiQuestionTranslator ref={childRef} question={question} showScoreEntry={showScoreEntry} />
				break
			case 3:
				return <GroupedQuestionTranslator ref={childRef} question={question} showScoreEntry={showScoreEntry} />
				break
			case 8:
				return <SurveyContentTextTranslator ref={childRef} question={question} />
				break
			case 9:
				return <AnalogQuestionTranslator ref={childRef} question={question} />
				break
			case 10: case 11:
				return <FileQuestionTranslator ref={childRef} question={question} />
				break
		}
	}
}

const QuestionContainer = ({
	node,
	list,
	intl,
	question,
	onCancel,
	onUpdateCallback,
	handleSubmit,
	showScoreEntry
}) => {
	const childRef = useRef()
	const dispatch = useDispatch()
	const match = useRouteMatch()
	const theme = useTheme()
	const isSaving = useSelector(isSavingSelector)
	const onSubmitForm = () => {
		if (childRef) {
			if (onUpdateCallback) {
				dispatch(startSaving())
				onUpdateCallback(childRef.current.getQuestion(), match.params.languageId)
			}
		}
	}

	const onCancelSave = () => {
		dispatch(stopSaving())
		dispatch(stopUpdatingQuestion())
	}

	const keyPressHandler = (e) => {
		if (e && e.keyCode === 27) {
			dispatch(stopSaving())
			dispatch(stopUpdatingQuestion())
		}
	}

	return (
		<div id="questionEditorPanel" style={{ border: 'none' }} onKeyDown={keyPressHandler}>
			<form onSubmit={handleSubmit(onSubmitForm)}>
				<Grid container direction="column" style={{ marginBottom: '40px' }}>
					<Grid item style={{ padding: '3% 0', width: '100%' }}>
						<Grid container direction="row" style={{ width: '100%' }} justify="flex-start">
							<Grid item style={{ flex: 1 }}>
								{question && question.data && renderTranslator(childRef, question, showScoreEntry)}
							</Grid>
						</Grid>
					</Grid>
					<Grid item style={{ padding: '20px 3%' }}>
						<Grid container spacing={2} style={{ justifyContent: 'center'}}>
							<Grid item>
								<Button
                                    variant="outlined"
                                    color="primary"
									onClick={onCancelSave}
								>
									{intl.formatMessage({ id: 'app.surveyBuilder.discardButtonText' })}
								</Button>
							</Grid>
							<Grid item>
								<CalmButton
                                    variant="contained"
                                    color="primary"
									disabled={isSaving}
									type="submit"
								>
									{intl.formatMessage({ id: 'app.surveyBuilder.saveButtonText' })}
								</CalmButton>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</div>
	)
}

const enhance = compose(
	reduxForm({
		form: 'editQuestion',
		touchOnChange: true,
		touchOnBlur: true
	}),
	injectIntl
)

export default enhance(QuestionContainer)
