import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { showModal } from '../../../redux/actions'
import { useDispatch } from 'react-redux'
import { getSubscriberStatisticsExport } from '../../../redux/actions'

const ManageSubscriptionMenuItem = ({ intl, subscriber, onComplete }) => {
	const history = useHistory()
	const dispatch = useDispatch()
	const onClick = () => {
		if (onComplete) {
			onComplete()
		}

		dispatch(getSubscriberStatisticsExport(subscriber.id))
	}

	return (
		<MenuItem
			onClick={() => {
				onClick()
			}}
		>
			{intl.formatMessage({ id: 'app.general.getStatisticsMenuItemText' })}
		</MenuItem>
	)
}

const enhance = compose(injectIntl)

export default enhance(ManageSubscriptionMenuItem)
