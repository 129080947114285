import React, { Fragment, useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import withStyles from '@material-ui/core/styles/withStyles'
import SearchBar from './SearchBar'
import Filter from './Filter'
import { useRouteMatch } from 'react-router-dom'
import { searchQuestions, setProtocolFilter, setQuestionSearchTerm } from '../../../redux/actions'
import CompositeLabelPaper from '../../../components/CompositeLabelPaper'
import LabelPaper from '../../../components/LabelPaper'
import { questionsSearchTermSelector, searchQuestionsSelector } from '../../../redux/selectors'
import { getUser } from '../../../utilities/userUtilities'
import QuestionsEmpty from './QuestionsEmpty'
import QuestionTable from './QuestionTable'
import { useSelector, shallowEqual } from 'react-redux'

const styles = {
	root: {
		background: 'black'
	},
	input: {
		color: 'white',
		backgroundColor: 'rgb(36,48,65)'
	},
	gradient: {
		background:
			'linear-gradient(45deg,  #d4e1f7 0%,#d4e1f7 50%,#ffffff 50%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */',
		filter:
			"progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4e1f7', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */"
	}
}

const Questions = ({ 
	classes, 
	intl, 
	getQuestions, 
	setSearch,
	questionFilter
}) => {
	const [ user, setUser ] = useState(getUser())
	const match = useRouteMatch()
	const questions = useSelector(searchQuestionsSelector, shallowEqual)
	useEffect(() => {
		if (questions?.length == 0) {
			getQuestions(questionFilter)
		}
	}, [])

	return (
		<div className="admin-fullheight-wrapper">
			<Fragment>
				<Grid container justify="space-between" alignItems="center" style={{ paddingBottom: '32px' }}>
					<Grid item>
						<Typography variant="h1" id="headerText" >
						Questions
						</Typography>
					</Grid>
				</Grid>
				<Grid container justify="space-between" style={{ paddingBottom: '50px' }}>
					<Grid item style={{ flex: 1 }}>
						<SearchBar onSearchChange={setSearch} />
					</Grid>
					{/* <Grid item style={{ paddingTop: '10px' }}>
						<AddProtocolButton />
					</Grid> */}
				</Grid>
				{!questions || questions.length === 0 ? (
					<CompositeLabelPaper
						label={intl.formatMessage({ id: 'app.questions.noFilter' })}
						classes={classes.gradient}
						Component={QuestionsEmpty}
					/>
				) : (
					<CompositeLabelPaper
						label={intl.formatMessage({ id: 'app.questions.noFilter' })}
						Component={QuestionTable}
						user={user}
						data={questions}
						// Filter={Filter}
					/>
				)}
			</Fragment>

		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	questionFilter: questionsSearchTermSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	getQuestions: (filter) => dispatch(searchQuestions(filter)),
	setSearch: (value) => dispatch(setQuestionSearchTerm(value))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withStyles(styles))

export default enhance(Questions)
