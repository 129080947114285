import React, { useEffect, useState } from 'react'
import { Field, change } from 'redux-form'
import { makeStyles } from '@material-ui/core/styles'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { FormHelperText } from '@material-ui/core'

const useASStyles = makeStyles(() => ({
	root: {
		'& div.special-label': {
			top: 0,
			marginTop: '5px',
			backgroundColor: 'rgb(36, 48, 65)',
			color: 'white',
			marginLeft: '-15px',
			fontSize: '13px'
		}
	},
	inputError: {
		borderBottom: 'solid 2px red !important',
	}
}))

const styles = {
	input: {
		color: 'white',
		fontSize: '1rem',
		backgroundColor: 'rgb(36, 48, 65)',
		border: 'none',
		width: '100%',
		borderRadius: 0,
		paddingBottom: '10px',
		paddingTop: '27px',
		'-webkit-box-shadow': '0 0 0 50px rgb(36, 48, 65) inset',
		'-webkit-text-fill-color': 'white',
	},
	button: {
		paddingBottom: '10px',
		paddingTop: '27px'
	}
}

const PhoneNumber = ({
	label,
	phoneField,
	phoneCountryCodeField,
	style,
	meta: { touched, error, initial, dispatch, form }
}) => {

	const classes = useASStyles()

	const [ value, setValue ] = useState(initial || null)

	useEffect(() => {
		if(initial)
		{
			setValue(initial)
		}
	}, [initial])

	const handleChange = (phone, data) => {
		setValue(phone)
		dispatch(change(form, phoneField, phone))
		dispatch(change(form, phoneCountryCodeField, data.dialCode))
	}

	return (
		<div className="global-input-wrapper" style={{ ...style }}>
			<PhoneInput
				country='us'
				value={value}
				onChange={handleChange}
				enableSearch={true}
				inputStyle={styles.input}
				inputClass= {touched && error ? classes.inputError : null}
				buttonStyle={styles.button}
				containerClass={classes.root}
				specialLabel={label}
				placeholder=''
			/>
			<FormHelperText
				style={{ color: '#f44336', margin: 0, backgroundColor: '#011020' }}
			>
				{touched && error}
			</FormHelperText>
		</div>
	)
}

const renderTextField = (field) => {
	return <PhoneNumber {...field} />
}

export const PhoneNumberField = ({
	label,
	name,
	inputLabelProps,
	inputProps,
	style,
	fullWidth,
	margin,
	variant,
	type,
	validators,
	suggestions,
	maxLength = 255,
	noMatchText = 'No Entries Found',
	...other
}) => {
	return (
		<Field
			name={name}
			component={renderTextField}
			label={label}
			inputLabelProps={inputLabelProps}
			inputProps={inputProps}
			maxLength={maxLength}
			style={style}
			fullWidth
			margin={margin}
			variant={variant}
			type={type}
			validate={validators}
			suggestions={suggestions}
			noMatchText={noMatchText}
			{...other}
		/>
	)
}
