import React, { Fragment, createRef, useState, useEffect } from 'react'
import { Typography, Badge, Chip } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import IdContainer from './IdContainer'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { useRouteMatch } from 'react-router'
import RuleDescriptionRow from '../../../pages/surveyViewer/components/RuleDescriptionRow'
import { questionCode, questionId } from '../../../utilities/utilities'
import { useSelector } from 'react-redux'
import { protocolSelector } from '../../../redux/selectors'

const QuestionContainer = ({ question, surveyEntity, children, intl }) => {
	const match = useRouteMatch()
	let ref = createRef()
	const protocol = useSelector(protocolSelector)

	const getIsRequired = () => {
		if(!surveyEntity)
			return !question.isOptional

		return surveyEntity.isOptionalOverride === false || ((surveyEntity.isOptionalOverride === null || surveyEntity.isOptionalOverride === undefined) && !question.isOptional)
	}

	const getIsBilateral = () => {
		if(!surveyEntity)
			return question.isBilateral

		return surveyEntity.isBilateral || ((surveyEntity.isBilateral === null || surveyEntity.isBilateral === undefined) && question.isBilateral)	
	}

	return (
		<div data-testid="question-container-survey">
			{!!question &&
			(
				<Grid container className="admin-question-container">
					<Grid item xs={8} className="admin-question-title">
						<Typography
							variant="subtitle1">
							{question.title +
								(getIsRequired() ? ' *' : '')
							}
						</Typography>
						{!!match.params.instanceId && protocol && protocol.isBilateral && !question.isBundle &&
							<Chip
								style={{
									fontSize: '14px'
								}}
								label={intl.formatMessage({ id: getIsBilateral() ? 'app.survey.questionIsBilateralLabel' : 'app.survey.questionIsNotBilateralLabel' })}
								color="primary"
								variant="outlined" />
						}
					</Grid>
					<Grid
						item
						xs={4}
						style={{
							display: "flex",
						}}
					>
						{
							!!match.params.instanceId
							&& <Grid data-testid="ids-container" style={{ marginLeft: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '15px' }}>
								<IdContainer									
									label={intl.formatMessage({ id: 'app.survey.questionIdLabel' }).replace('%%Id%%', `${match.params.instanceId}_${question.baseId}_${question.questionType}`)}
									clipboardText={
										question.questionType === 2 ?
										questionCode(match.params.instanceId, question.baseId, question.questionType)
										: `${questionCode(match.params.instanceId, question.baseId, question.questionType)}==`
									}
								/>
								{!question.isBundle && <IdContainer
									color="primary"
									label={questionId(question.baseId, question.questionType)}
									clipboardText={questionId(question.baseId, question.questionType)}
								/>}
							</Grid>
						}
					</Grid>
					{
						!question.isBundle
						&& !!surveyEntity
						&& !!surveyEntity.isConditionalVisibility
						&& surveyEntity.state === 1
						&& <Fragment>
							{
								!!surveyEntity.visibilityRuleDescription
								&& surveyEntity.visibilityRuleDescription.length > 0
								&& <RuleDescriptionRow
									description={surveyEntity.visibilityRuleDescription}
									style={{
										width: '100%',
										marginTop: '5px'
									}}
								/>
							}
							{
								!!surveyEntity.optionalRuleDescription
								&& surveyEntity.optionalRuleDescription.length > 0
								&& <RuleDescriptionRow
									description={surveyEntity.optionalRuleDescription}
									isVisibilityDescription={false}
									style={{
										width: '100%',
										marginTop: '5px'
									}}
								/>
							}
						</Fragment>
					}
					{question.instructions && (
						<Grid item className="admin-question-instructions">
							<Typography
								variant="subtitle2"								
							>
								<span dangerouslySetInnerHTML={{ __html:question.instructions }} />
							</Typography>
						</Grid>
					)}
					<Grid item style={{ width: '100%' }}>
						{children && (
							<Grid container style={{ width: '100%' }}>
								<Grid item xs={12}>
									{children}
								</Grid>
							</Grid>
						)}
					</Grid>
				</Grid>
			)}
		</div>
	)
}

const enhance = compose(injectIntl)

export default enhance(QuestionContainer)
