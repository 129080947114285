import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
export default ({ question }) => {
	return (
		<Grid
			className="admin-question-description"
			data-testid="readonly-text-question-privew"
			container
			direction="column"
			justify="flex-start"
			style={{ marginTop: '20px', paddingBottom: '20px' }}
		>
			<Grid item>
				<Typography variant="subtitle1">
					<span dangerouslySetInnerHTML={{ __html:question.subtitle }} />
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant="subtitle2">
					<span dangerouslySetInnerHTML={{ __html:question.bodyCopy }} />
				</Typography>
			</Grid>
		</Grid>
	)
}
