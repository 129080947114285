import React, { useEffect, useState, Fragment } from 'react'

import Button from '@material-ui/core/Button'

export default ({ label, onClick, children }) => {
	return (
		<div style={{ margin: '1px' }}>
            <Button
                color="primary"
				variant="outlined"
				onClick={onClick}
				elevation={1}
				fullWidth

			>
				{children && children}
				{label && label}
			</Button>
		</div>
	)
}
