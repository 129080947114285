import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import { Field } from 'redux-form'

const useStyles = makeStyles({
	inputRoot: {
		color: 'black'
	},
	inputFocused: {
		color: 'black'
	},
	inputLabel: {
		marginLeft: '0px',
		color: 'black'
	},
	selectMenu: {
		color: 'black',
		height: '20px'
	},
	selectIcon: {
		fontSize: '24px'
	},
	error: {
		backgroundColor: 'transparent',
		margin: 0
	}
})

const renderTextField = ({
	label,
	initialValue,
	onChangeCallback,
	allowDecimal,
	data,
	type,
	maxLength,
	min,
	max,
	pattern,
	placeholder,
	classes,
	input,
	meta: { touched, invalid, error },
	...other
}) => {
	const onChange = (e) => {
		if (e && e.target && e.target.value) {
			let temp = e.target.value
			if (temp && temp.endsWith('.')) {
				temp = temp + '0'
			}
			let b = RegExp(/-?\d*\.{0,1}\d+$/).test(temp)

			if (!b) {
				e.target.value = ''
			}
		}
	}

	const onKeyPress = (evt) => {
		var charCode = evt.which ? evt.which : evt.keyCode

		if (charCode < 48 || charCode > 57) {
			if (allowDecimal && charCode === 46) {
				let temp = evt.target.value + String.fromCharCode(charCode)

				var count = (temp.match(/\./g) || []).length
				if (count <= 1) {
					return true
				}
			}
			if (charCode === 45) {
				let temp = evt.target.value + String.fromCharCode(charCode)
				var count = (temp.match(/\-/g) || []).length
				if (count <= 1) {
					evt.target.value = String.fromCharCode(charCode) + evt.target.value
					evt.preventDefault()
					return false
				}
			}
			evt.preventDefault()
			return false
		} else if (charCode === 46 && evt.target.value && evt.target.value.split('.').length > 1) {
			evt.preventDefault()
			return false
		}

		return true
	}

	return (
		<TextField
			label={label}
			onKeyPress={onKeyPress}
			variant="filled"
			fullWidth
			shrink
			error={touched && invalid}
			helperText={touched && error}
			placeholder={placeholder}
			onInput={onChange}
			onChange={onChange}
			{...input}
			InputLabelProps={{
				classes: {
					root: classes.inputLabel,
					focused: classes.inputLabel
				}
			}}
			InputProps={{
				classes: {
					root: classes.inputRoot,
					error: classes.inputRoot,
					focused: classes.focused,
					input: classes.focused
				}
			}}
			FormHelperTextProps={{
				classes: {
					error: classes.error
				}
			}}
			inputProps={{
				style: { color: 'black' },
				maxLength: maxLength
			}}
			{...other}
		/>
	)
}

const FormFilledNumberTextFieldLight = ({ label, name, onChange, data, validators, ...other }) => {
	var classes = useStyles()
	return (
		<Field
			component={renderTextField}
			name={name}
			validate={validators}
			classes={classes}
			onChange={onChange}
			label={label}
			{...other}
		/>
	)
}

export default FormFilledNumberTextFieldLight
