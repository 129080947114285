import React, { lazy, Suspense } from 'react'
import componentLoader from '../../utilities/componentLoader'

const Treatments = lazy(() => componentLoader(() => import('./components/Treatments')))

export default () => (
	<div>
		<Suspense fallback={<div />}>
			<section>
				<Treatments />
			</section>
		</Suspense>
	</div>
)
