import React from 'react'
import { Chip } from "@material-ui/core";
import { FileCopyOutlined } from '@material-ui/icons';
import { setSnackbarMessage } from '../../../redux/actions';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { injectIntl } from 'react-intl';

const IdContainer = ({
    label,
    clipboardText,
    showSnackbarMessage,
    intl,
    size = 'small',
    color = 'success',
}) => {
    const handleClick = () => {
        navigator.permissions.query({ name: 'clipboard-write' }).then((result) => {
            if (result.state == 'granted' || result.state == 'prompt') {
                navigator.clipboard.writeText(clipboardText)
                showSnackbarMessage(intl.formatMessage({ id: 'app.general.copiedToClipboardText' }))
            }
        });
    }

    const fontSize = size === 'small' ? '14px' : '16px';

    return (
        <div>
            {
                !!label
                && <Chip
                    variant="outlined"
                    color={color} // Default: "success". Can be also "primary"
                    size={size} // Default: "small". Can be also "medium"
                    style={{
                        fontSize: fontSize
                    }}
                    onClick={clipboardText ? handleClick : undefined}
                    label={ label }
                />
            }
            {
                !label
                && !!clipboardText
                && <FileCopyOutlined style={{ color: 'grey', cursor: 'pointer' }} onClick={handleClick} />
            }
        </div>
    );
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
    showSnackbarMessage: (message, isSuccess = true) => dispatch(setSnackbarMessage(message, isSuccess))
})

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)

export default enhance(IdContainer)
