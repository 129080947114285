import React from 'react'
import { TableHead } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import { compose } from 'recompose'
import withStyles from '@material-ui/core/styles/withStyles'
import CircleHeader from './SubscriberHeader'
import SubscriberRow from './SubscriberRow'
import TableBody from '@material-ui/core/TableBody'
import { CircularProgress } from '@material-ui/core'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { connect } from 'react-redux'
import { hasMoreSubscribersSelector, isLoadingSubscribersSelector, subscriberSearchTermSelector } from '../../../redux/selectors'
import { searchAdditionalSubscribers } from '../../../redux/actions'

const styles = {
	root: {
		background: 'black'
	},
	input: {
		color: 'white',
		backgroundColor: 'rgb(36, 48, 65)'
	}
}

const SubscriberTable = ({ data, user, search, hasMore, getSubscribers, loading }) => {

	const getAdditionalSubscribers = () => {
		getSubscribers(search, data.length)
	}

	const [sentryRef] = useInfiniteScroll({
		loading,
		hasNextPage: hasMore,
		onLoadMore: getAdditionalSubscribers,
		delayInMs: 1000
	});

	return (
		<div className="admin-table-fullheight">
			<Table className="table-admin-subscribers" data-testid="subscriber-table" style={{width: 'calc(100% + 64px)', margin: '8px -32px 0', minHeight: '200px', }}>
				<TableHead>
					<CircleHeader />
				</TableHead>
				<TableBody style={{ borderTop: 'none' }}>
					{data &&
						data.map((subscriber) => <SubscriberRow subscriber={subscriber} user={user} key={subscriber.id} />)}
				</TableBody>
			</Table>
			{data && data.length > 0 && (loading || hasMore) && (
				<div className="admin-table-progress" ref={sentryRef}>
					<CircularProgress />
				</div>
			)}
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	loading: isLoadingSubscribersSelector(state),
	hasMore: hasMoreSubscribersSelector(state),
	search: subscriberSearchTermSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	getSubscribers: (search, offset) => dispatch(searchAdditionalSubscribers(search, offset)),
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))

export default enhance(SubscriberTable)
