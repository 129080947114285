import { authenticatedFetch } from './base'

export const fetchFunders = (circles, users) => {
	let searchParams = {
		circles: circles.join(','),
		users: users.join(',')
	}

	return authenticatedFetch(
		`/api/sponsors/getSponsorsForDashboardFilter?${new URLSearchParams(searchParams).toString()}`,
		{
			method: 'get'
		}
	)
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchCircles = (funders, users) => {
	let searchParams = {
		funders: funders.join(','),
		users: users.join(',')
	}

	return authenticatedFetch(
		`/api/circle/getCirclesForDashboardFilter?${new URLSearchParams(searchParams).toString()}`,
		{
			method: 'get'
		}
	)
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchInvestigators = (funders, circles) => {
	let searchParams = {
		funders: funders.join(','),
		circles: circles.join(',')
	}

	return authenticatedFetch(
		`/api/users/getInvestigatorsForDashboardFilter?${new URLSearchParams(searchParams).toString()}`,
		{
			method: 'get'
		}
	)
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const exportReport = (values) => {
	return authenticatedFetch(
		'/api/dashboard/export',
		{
			method: 'post',
			headers: { 'content-type': 'application/json' },
			body: JSON.stringify(values)
		}
	)
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}