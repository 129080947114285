import React from 'react'
import { useTheme, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import { useIntl } from 'react-intl'
import Tag from './Tag'
import QuestionContainer from './QuestionContainer'
import RequiresTranslationBadge from '../../RequiresTranslationBadge'
export default ({ question, position, positionDisplay }) => {
	const theme = useTheme()
	const intl = useIntl()

	return (
			<Grid container direction="column">
				<Grid item>
					<Tag tag={question.tag} />
				</Grid>

				<Grid item>
					<QuestionContainer
						question={question}
						children={
							<FormControl component="fieldset" style={{ marginLeft: '-12px' }}>
								<FormGroup>
									<FormControlLabel
										style={{ color: 'black' }}
										control={<Radio color="primary" disabled />}
										labelPlacement="end"
										label={
											<Typography variant="subtitle2" style={{ fontSize: '17px' }}>
												{intl.formatMessage({ id: 'app.general.true' })}
											</Typography>
										}
									/>
									<FormControlLabel
										style={{ color: 'black' }}
										control={<Radio color="primary" disabled />}
										labelPlacement="end"
										label={
											<Typography variant="subtitle2" style={{ fontSize: '17px' }}>
												{intl.formatMessage({ id: 'app.general.false' })}
											</Typography>
										}
									/>
								</FormGroup>
							</FormControl>
						}
					/>
				</Grid>
				{question &&
				!question.id && (
					<Grid item style={{ marginTop: '10px' }}>
						<RequiresTranslationBadge />
					</Grid>
				)}
			</Grid>
	)
}
