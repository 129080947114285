import React, { useState, useRef, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { Link, useLocation } from 'react-router-dom'
import Logo from '../assets/logo'
import { showModal, signOut, clearProtocols, clearSurveys } from '../redux/actions'
import { compose } from 'recompose'
import { connect, useDispatch } from 'react-redux'
import FormCalendly from './FormCalendly'
import { injectIntl } from 'react-intl'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import Drawer from './globalSearch'
import Search from '@material-ui/icons/Search'
import IconButton from '@material-ui/core/IconButton'
import VpnKey from '@material-ui/icons/VpnKey'
import ChangePasswordModal from './ChangePasswordModal'
import { getUser } from '../utilities/userUtilities'
import MenuIcon from '@material-ui/icons/Menu';
import { isMobile } from './../utilities/mobileDetector'
import { useWindowDimensions } from './../utilities/useWindowDimensions'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Collapse from '@material-ui/core/Collapse';
import { e } from 'mathjs/lib/entry/pureFunctionsAny.generated'

import DashboardIcon from '@material-ui/icons/Dashboard'; // Icon for Dashboard
import DescriptionIcon from '@material-ui/icons/Description'; // For Protocols
import AssessmentIcon from '@material-ui/icons/Assessment'; // For Scorings
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'; // For Circles
import AccountCircleIcon from '@material-ui/icons/AccountCircle'; // For Accounts
import BuildIcon from '@material-ui/icons/Build'; // For Tools


const useStyles = makeStyles((theme) => ({
	content: {
		paddingLeft: '20px',
		textAlign: 'left'
	},
	searchLinkContainer: {
		padding: '10px 5px 10px 26px',
		margin: '0 0 20px 0',
		textAlign: 'left',
		color: "#E3F3FF",
		opacity: 0.6,
		lineHeight: '2px',
		cursor: 'pointer',
		display: 'flex', 
		alignItems: 'center', 
		flexWrap: 'nowrap'
	},
	linkWithIcon: {
		padding: '10px 0 10px 20px',
		textAlign: 'left',
		color: '#E3F3FF',
		borderLeft: `6px solid transparent`,
		fontSize: '14px',
		display: 'flex',
		alignItems: 'center',
	},
	linkWithIconActive: {
		padding: '10px 0 10px 20px',
		textAlign: 'left',
		color: '#E3F3FF',
		fontSize: '14px',
		display: 'flex',
		alignItems: 'center',
		backgroundColor: '#011020',
		borderLeft: `6px solid ${theme.palette.primary.dark}`
	},
	groupTitle: {
		padding: '10px 20px 10px 20px',
		textAlign: 'left',
		color: '#E3F3FF',
		borderLeft: `6px solid transparent`,
        display: 'flex',
		alignItems: 'center',
		fontSize: '14px',
    },
	iconStyle: {
		fontSize: '18px',
		marginRight: '14px',
    },
	links: {
		padding: '10px 0 10px 52px',
		textAlign: 'left',
		color: '#E3F3FF',
		borderLeft: `6px solid transparent`,
		fontSize: '14px',
		display: 'flex',
		alignItems: 'center',
	},
	linkActive: {
		padding: '10px 0 10px 52px',
		textAlign: 'left',
		color: '#E3F3FF',
		fontSize: '14px',
		display: 'flex',
		alignItems: 'center',
		backgroundColor: '#011020',
		borderLeft: `6px solid ${theme.palette.primary.dark}`
	},
	bottomLink: {
		padding: '10px 5px 10px 29px',
		textAlign: 'left',
		color: "#E3F3FF",
		opacity: 0.6,
		borderLeft: `6px solid #011020`
	},
	bottomLinkActive: {
		padding: '10px 5px 10px 29px',
		textAlign: 'left',
		color: "#E3F3FF",
		opacity: 0.6,
		borderLeft: `6px solid #011020`
	},
    linkContainer: {
        minWidth: '100%'
    },
	toggleMenuButton: {
		position: 'fixed',
		top: '0',
		left: '0',
		zIndex: '1111',
        background: theme.palette.secondary.inputHover,
        color: '#fff',
		borderRadius: '0',
		height: '45px',
		[theme.breakpoints.down('xs')]: {
			padding: '4px',
			height: '31px',
		},
		'&:hover, &:focus': {
			background: theme.palette.secondary.inputHover,
		}
    },
    navInnerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        height: '100vh',
        overflowY: 'auto',
        "&::-webkit-scrollbar-track": {
            "-webkit-box-shadow": 'inset 0 0 3px rgba(45,60,82, 1)',
            backgroundColor: 'rgba(45,60,82, 1)',

        },
        "&::-webkit-scrollbar": {
            height: '7px',
            width: '7px',
            borderRadius: '10px',
            backgroundColor: '#011020'
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: '5px',
            "-webkit-box-shadow": 'inset 0 0 3px rgba(59,79,107, 1)',
            backgroundColor: 'rgba(59,79,107, 1)'
        }
    },

    toggleIconContainer: {
        cursor: 'pointer',
    },
	toggleIcon: {
		margin: '0 0 0 auto',
		transition: 'transform 0.3s ease'
	},
	rotateIcon: {
		transform: 'rotate(-180deg)',
	},

}))

const getLinkContainerClass = (link, location, classes) => {
	return location?.pathname?.startsWith(link) ? classes.linkContainer : ''
}

const getLinkClass = (link, location, classes) => {
	return location?.pathname?.startsWith(link) ? classes.linkActive : classes.links
}
const getLinkWithIconClass = (link, location, classes) => {
	return location?.pathname?.startsWith(link) ? classes.linkWithIconActive : classes.linkWithIcon
}
const getBottomLinkClass = (link, location, classes) => {
	return location?.pathname?.startsWith(link) ? classes.bottomLinkActive : classes.bottomLink
}

const LeftNavigation = ({ signOut, intl, changePassword }) => {
	const classes = useStyles()
	const location = useLocation()
	const [ userId, setUserId ] = useState(0)
	const childRef = useRef()
	const dispatch = useDispatch()

	const [openProtocols, setOpenProtocols] = useState(false);
    const [openScorings, setOpenScorings] = useState(false);
    const [openCircles, setOpenCircles] = useState(false);
    const [openAccounts, setOpenAccounts] = useState(false);
	const [openTools, setOpenTools] = useState(false);

	useEffect(() =>
	{
		if (location?.pathname?.startsWith("/protocols") || 
			location?.pathname?.startsWith("/surveys") || 
			location?.pathname?.startsWith("/questions") || 
			location?.pathname?.startsWith("/units"))
		{
			setOpenProtocols(true)
		}
		else if (location?.pathname?.startsWith("/sg") || 
			location?.pathname?.startsWith("/bundles"))
		{
			setOpenScorings(true);
		}
		else if (location?.pathname?.startsWith("/circles") || 
			location?.pathname?.startsWith("/treatments") || 
			location?.pathname?.startsWith("/pathologies"))
		{
			setOpenCircles(true)
		}
		else if (location?.pathname?.startsWith("/subscribers") || 
			location?.pathname?.startsWith("/serviceProviders") || 
			location?.pathname?.startsWith("/sponsors"))
		{
			setOpenAccounts(true);
		}
		else if (location?.pathname?.startsWith("/reports") || 
			location?.pathname?.startsWith("/inports") || 
			location?.pathname?.startsWith("/settings"))
		{
			setOpenTools(true)
		}
	}, [location])

	const handleToggle = (setOpenFunc, isOpen, toggleName) => {
		if (isOpen &&
			((toggleName == "protocols" && 
				(location?.pathname?.startsWith("/protocols") || 
				location?.pathname?.startsWith("/surveys") || 
				location?.pathname?.startsWith("/questions") || 
				location?.pathname?.startsWith("/units"))) ||
			(toggleName == "scorings" && 
				(location?.pathname?.startsWith("/sg") || 
				location?.pathname?.startsWith("/bundles"))) ||
			(toggleName == "circles" && 
				(location?.pathname?.startsWith("/circles") || 
				location?.pathname?.startsWith("/treatments") || 
				location?.pathname?.startsWith("/pathologies"))) ||
			(toggleName == "accounts" && 
				(location?.pathname?.startsWith("/subscribers") || 
				location?.pathname?.startsWith("/serviceProviders") || 
				location?.pathname?.startsWith("/sponsors"))) ||
			(toggleName == "tools" && 
				(location?.pathname?.startsWith("/reports") || 
				location?.pathname?.startsWith("/inports") || 
				location?.pathname?.startsWith("/settings")))
			)
		)
		{
			return; //do not collapse if current location is in clicked section
		}

        setOpenFunc(!isOpen);
    };

	useEffect(() => {
		let user = getUser()
		if (user) {
			setUserId(user.id)
		}
	}, [])

	const handleLogoutClose = () => {
		signOut()
	}

	const handleSearchClicked = () => {
		if (childRef) {
			childRef.current.open()
		}
	}
	const { height, width } = useWindowDimensions();
	const [open, setOpen] = useState(isMobile || width<1280 ? false : true)
	const toggleMenu = () => {
		if (isMobile || width<1280) { setOpen(!open) }
	}

	return (
		<div>
			<IconButton disableRipple className={`${classes.toggleMenuButton} menuButton`} color="primary"
				onClick={toggleMenu} style={{ display: isMobile || width<1280 ? 'block' : 'none' }}>
				<MenuIcon fontSize="large" />
			</IconButton>
			<Grid item className={ open ? 'navOpened': 'navClosed'}>

				<div style={{ minWidth: '264px' }}>

					<div className={classes.navInnerContainer}>
						<Grid item style={{ width: '100%', display: 'flex', flexDirection: 'column', flexShrink: 0 }}>
							<Grid item>
								<Drawer ref={childRef} />
							</Grid>
							<Grid item style={{ paddingTop: '40px', alignSelf: 'center', paddingBottom: '40px' }}>
								<Logo />
							</Grid>
							<Grid
								item
								className={classes.searchLinkContainer}
								onClick={handleSearchClicked}
							>
								<Search className={classes.iconStyle}  />
								<Typography variant="body2">
									{intl.formatMessage({ id: 'app.leftNavigation.search' })}
								</Typography>
							</Grid>
							<Grid item>
                                <Link to="/dashboard" style={{ textDecoration: 'none' }}>
								<Typography className={getLinkWithIconClass('/dashboard', location, classes)}>
									<DashboardIcon className={classes.iconStyle} />
									{intl.formatMessage({ id: 'app.leftNavigation.dashboard' })}
								</Typography>
                                </Link>
                            </Grid>
							<Grid item>
								<div className={classes.toggleIconContainer} onClick={() => handleToggle(setOpenProtocols, openProtocols, "protocols")}>
								<Typography className={classes.groupTitle}>
									<DescriptionIcon className={classes.iconStyle}/>
									{intl.formatMessage({ id: 'app.leftNavigation.protocols' })}
									<ExpandMoreIcon className={`${classes.toggleIcon} ${openProtocols ? classes.rotateIcon : ''}`} />
								</Typography>
								</div>
								<Collapse in={openProtocols}>
									<Grid container direction="column">
										<Grid item className={getLinkContainerClass('/protocols', location, classes)}>
											<Link to="/protocols" onClick={() => dispatch(clearProtocols())} style={{ textDecoration: 'none' }}>
												<Typography variant="body2" className={getLinkClass('/protocols', location, classes)}>
													{intl.formatMessage({ id: 'app.leftNavigation.protocols' })}
												</Typography>
											</Link>
										</Grid>
										<Grid item className={getLinkContainerClass('/surveys', location, classes)}>
											<Link to="/surveys" onClick={() => dispatch(clearSurveys())} style={{ textDecoration: 'none' }}>
												<Typography variant="body2" className={getLinkClass('/surveys', location, classes)}>
													{intl.formatMessage({ id: 'app.leftNavigation.surveys' })}
												</Typography>
											</Link>
										</Grid>
										<Grid item className={getLinkContainerClass('/questions', location, classes)}>
											<Link to="/questions" style={{ textDecoration: 'none' }}>
												<Typography variant="body2" className={getLinkClass('/questions', location, classes)}>
													{intl.formatMessage({ id: 'app.leftNavigation.questions' })}
												</Typography>
											</Link>
										</Grid>
										<Grid item className={getLinkContainerClass('/units', location, classes)}>
											<Link to="/units" style={{ textDecoration: 'none' }}>
												<Typography variant="body2" className={getLinkClass('/units', location, classes)}>
													{intl.formatMessage({ id: 'app.leftNavigation.unitsOfMeasure' })}
												</Typography>
											</Link>
										</Grid>
									</Grid>
								</Collapse>
							</Grid>
							<Grid item>
								<div className={classes.toggleIconContainer} onClick={() => handleToggle(setOpenScorings, openScorings, "scorings")}>
									<Typography className={classes.groupTitle}>
										<AssessmentIcon className={classes.iconStyle}/>
										{intl.formatMessage({ id: 'app.leftNavigation.scorings' })}
										<ExpandMoreIcon className={`${classes.toggleIcon} ${openScorings ? classes.rotateIcon : ''}`} />

									</Typography>
								</div>
								<Collapse in={openScorings}>
									<Grid container direction="column">
										<Grid item className={getLinkContainerClass('/sg', location, classes)}>
											<Link to="/sg" style={{ textDecoration: 'none' }}>
												<Typography variant="body2" className={getLinkClass('/sg', location, classes)}>
													{intl.formatMessage({ id: 'app.leftNavigation.scoringGroups' })}
												</Typography>
											</Link>
										</Grid>
										<Grid item className={getLinkContainerClass('/bundles', location, classes)}>
											<Link to="/bundles" style={{ textDecoration: 'none' }}>
												<Typography variant="body2" className={getLinkClass('/bundles', location, classes)}>
													{intl.formatMessage({ id: 'app.leftNavigation.bundles' })}
												</Typography>
											</Link>
										</Grid>
									</Grid>
								</Collapse>
							</Grid>
							<Grid item>
								<div className={classes.toggleIconContainer} onClick={() => handleToggle(setOpenCircles, openCircles, "circles")}>
									<Typography className={classes.groupTitle}>
										<AssignmentTurnedInIcon className={classes.iconStyle}/>
										{intl.formatMessage({ id: 'app.leftNavigation.circles' })}
										<ExpandMoreIcon className={`${classes.toggleIcon} ${openCircles ? classes.rotateIcon : ''}`} />
									</Typography>
								</div>
								<Collapse in={openCircles}>
									<Grid container direction="column">
										<Grid item className={getLinkContainerClass('/circles', location, classes)}>
											<Link to="/circles" style={{ textDecoration: 'none' }}>
												<Typography variant="body2" className={getLinkClass('/circles', location, classes)}>
													{intl.formatMessage({ id: 'app.leftNavigation.circles' })}
												</Typography>
											</Link>
										</Grid>
										<Grid item className={getLinkContainerClass('/treatments', location, classes)}>
											<Link to="/treatments" style={{ textDecoration: 'none' }}>
												<Typography variant="body2" className={getLinkClass('/treatments', location, classes)}>
													{intl.formatMessage({ id: 'app.leftNavigation.treatments' })}
												</Typography>
											</Link>
										</Grid>
										<Grid item className={getLinkContainerClass('/pathologies', location, classes)}>
											<Link to="/pathologies" style={{ textDecoration: 'none' }}>
												<Typography variant="body2" className={getLinkClass('/pathologies', location, classes)}>
													{intl.formatMessage({ id: 'app.leftNavigation.pathologies' })}
												</Typography>
											</Link>
										</Grid>
									</Grid>
								</Collapse>
							</Grid>
							<Grid item>
								<div className={classes.toggleIconContainer} onClick={() => handleToggle(setOpenAccounts, openAccounts, "accounts")}>
									<Typography className={classes.groupTitle}>
										<AccountCircleIcon className={classes.iconStyle}/>
										{intl.formatMessage({ id: 'app.leftNavigation.accounts' })}
										<ExpandMoreIcon className={`${classes.toggleIcon} ${openAccounts ? classes.rotateIcon : ''}`} />
										</Typography>
								</div>
								<Collapse in={openAccounts}>
									<Grid container direction="column">
										<Grid item className={getLinkContainerClass('/subscribers', location, classes)}>
											<Link to="/subscribers" style={{ textDecoration: 'none' }}>
												<Typography variant="body2" className={getLinkClass('/subscribers', location, classes)}>
													{intl.formatMessage({ id: 'app.leftNavigation.subscribers' })}
												</Typography>
											</Link>
										</Grid>
										<Grid item className={getLinkContainerClass('/serviceProviders', location, classes)}>
											<Link to="/serviceProviders" style={{ textDecoration: 'none' }}>
												<Typography variant="body2" className={getLinkClass('/serviceProviders', location, classes)}>
													{intl.formatMessage({ id: 'app.leftNavigation.serviceProviders' })}
												</Typography>
											</Link>
										</Grid>
										<Grid item className={getLinkContainerClass('/sponsors', location, classes)}>
											<Link to="/sponsors" style={{ textDecoration: 'none' }}>
												<Typography variant="body2" className={getLinkClass('/sponsors', location, classes)}>
													{intl.formatMessage({ id: 'app.leftNavigation.sponsors' })}
												</Typography>
											</Link>
										</Grid>
									</Grid>
								</Collapse>
							</Grid>
							<Grid item>
								<div className={classes.toggleIconContainer} onClick={() => handleToggle(setOpenTools, openTools, "tools")}>
									<Typography className={classes.groupTitle}>
										<BuildIcon className={classes.iconStyle}/>
										{intl.formatMessage({ id: 'app.leftNavigation.tools' })}
										<ExpandMoreIcon className={`${classes.toggleIcon} ${openTools ? classes.rotateIcon : ''}`} />
									</Typography>
								</div>
								<Collapse in={openTools}>
									<Grid container direction="column">
										<Grid item className={getLinkContainerClass('/reports', location, classes)}>
											<Link to="/reports" style={{ textDecoration: 'none' }}>
												<Typography variant="body2" className={getLinkClass('/reports', location, classes)}>
													{intl.formatMessage({ id: 'app.leftNavigation.reports' })}
												</Typography>
											</Link>
										</Grid>
										<Grid item className={getLinkContainerClass('/imports', location, classes)}>
											<Link to="/imports" style={{ textDecoration: 'none' }}>
												<Typography variant="body2" className={getLinkClass('/imports', location, classes)}>
													{intl.formatMessage({ id: 'app.leftNavigation.imports' })}
												</Typography>
											</Link>
										</Grid>
									</Grid>
									<Grid item className={getLinkContainerClass('/settings', location, classes)}>
										<Link to="/settings" style={{ textDecoration: 'none' }}>
											<Typography variant="body2" className={getLinkClass('/settings', location, classes)}>
												{intl.formatMessage({ id: 'app.leftNavigation.settings' })}
											</Typography>
										</Link>
									</Grid>
								</Collapse>
							</Grid>
							<Grid item style={{ flex: 1 }}>
								&nbsp;
							</Grid>
						</Grid>


						<Grid item style={{width: '100%',  flexShrink: 0 }}>
							<Grid
								container
								alignItems="center"
								justify="flex-start"
								className={getBottomLinkClass('/userprofile', location, classes)}
								style={{ borderLeft: 'none', padding: '10px 5px 10px 16px' }}
								onClick={() => changePassword(userId)}
							>
								<Grid item style={{ margin: '6px 0 0 9px' }}>
									<VpnKey />
								</Grid>
								<Grid item>
									<Typography
										variant="body2"
										style={{ borderLeft: 'none', cursor: 'pointer', marginLeft: '10px' }}
									>
										{intl.formatMessage({ id: 'app.profile.updatePasswordText' })}
									</Typography>
								</Grid>
							</Grid>
							<Grid
								container
								className={getBottomLinkClass('/userprofile', location, classes)}
								alignItems="center"
								justify="flex-start"
								style={{ borderLeft: 'none', padding: '10px 5px 10px 16px'  }}
								onClick={handleLogoutClose}
							>
								<Grid item style={{ margin: '6px 0 0 9px' }}>
									<ExitToAppIcon />
								</Grid>
								<Grid item>
									<Typography
										variant="body2"
										style={{ borderLeft: 'none', cursor: 'pointer', marginLeft: '10px' }}
									>
										{intl.formatMessage({ id: 'app.leftNavigation.logout' })}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</div>
				</div>
			</Grid>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
	signOut: () => dispatch(signOut()),
	onDemoClick: () => dispatch(showModal(FormCalendly)),
	changePassword: (id) => dispatch(showModal(ChangePasswordModal, { id: id, type: 1 }))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(LeftNavigation)
