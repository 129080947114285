import { authenticatedFetch } from './base'

export const fetchTerms = (languageId, type) => {
	var url = `/api/terms/languages/${languageId}/types/${type}`

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(terms) {
			return terms
		})
}

export const updateTerm = (body, languageId, type) => {
	return authenticatedFetch(`/api/terms/languages/${languageId}/types/${type}`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			body: body
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchCircleTerms = (circleId, languageId) => {
	var url = `/api/circle/${circleId}/language/${languageId}/terms`

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(terms) {
			return terms
		})
}

export const updateCircleTerm = (circleId, languageId, body) => {
	return authenticatedFetch(`/api/circle/${circleId}/language/${languageId}/terms`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			body: body
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const increaseCircleTermsVersion = (circleId, languageId) => {
	return authenticatedFetch(`/api/circle/${circleId}/language/${languageId}/increaseTermsVersion`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}
