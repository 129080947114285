import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { deleteUserMember, resendInvitationEmail, showModal } from '../../../redux/actions'
import { injectIntl } from 'react-intl'
import RemoveTeamMemberModal from './RemoveTeamMemberModal'

const ITEM_HEIGHT = 48


const createMenu = (intl, member, onMailUser, onResendInvite, onRemoveMember) => {
	let menuComponentItems = []

	menuComponentItems.push((
		<MenuItem onClick={onMailUser}>
			{intl.formatMessage({ id: 'app.userProfile.teamMemberEmail' })}
		</MenuItem>
	))

	if (member.status === 0) {
		menuComponentItems.push((
			<MenuItem onClick={onResendInvite}>
				{intl.formatMessage({ id: 'app.userProfile.teamMemberMenuResend' })}
			</MenuItem>
		))
	}

	menuComponentItems.push((
		<MenuItem onClick={onRemoveMember}>
			{intl.formatMessage({ id: 'app.userProfile.teamMemberMenuRemove' })}
		</MenuItem>
	))

	return menuComponentItems
}

const MemberMenu = ({ member, removeMember, intl, user, resendInvitation }) => {
	const [ anchorEl, setAnchorEl ] = React.useState(null)
	const open = Boolean(anchorEl)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const onRemoveMember = () => {
		if (member) {
			if(member.dateJoined)
			{
				removeMember(member.memberId, null, user.id)
			}
			else
			{
				removeMember(null, member.invitationId, user.id)
			}

			handleClose()
		}
	}
	const onMailUser = () => {
		if (member) {
			window.location.href = `mailto:${member.email}`
			handleClose()
		}
	}

	const onResendInvite = () => {
		if (member) {
			resendInvitation()
			handleClose()
		}
	}

	return (
		<div>
			<IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
				<MoreVertIcon />
			</IconButton>
			{
				member
				&& member.memberId !== member.ownerId
				&& <Menu
					id="long-menu"
					anchorEl={anchorEl}
					keepMounted
					open={open}
					onClose={handleClose}
					PaperProps={{
						style: {
							maxHeight: ITEM_HEIGHT * 4.5,
							width: 200
						}
					}}
				>
					{
						createMenu(intl, member, onMailUser, onResendInvite, onRemoveMember)
					}
				</Menu>
			}
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
	removeMember: (id, invitationId, currentUserId) => dispatch(showModal(RemoveTeamMemberModal, { id, currentUserId, invitationId })),
	resendInvitation: () => dispatch(resendInvitationEmail(ownProps.member.invitationId))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(MemberMenu)
