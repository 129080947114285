import React, { forwardRef, useImperativeHandle, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import DefaultFormTextField from '../DefaultFormTextField'
import { formValueSelector } from 'redux-form'
import { required } from '../../utilities/validators'
import { useStore, useDispatch } from 'react-redux'
import { change } from 'redux-form'
import { useSelector, shallowEqual } from 'react-redux'
import { languageNameSelector } from '../../redux/selectors'
const requiredValidator = required
const selector = formValueSelector('editQuestion')

export default forwardRef((props, ref) => {
	const { question } = props
	let languages = useSelector(languageNameSelector, shallowEqual)
	const store = useStore()
	const dispatch = useDispatch()
	useEffect(
		() => {
			if (question && question.data) {
				dispatch(change('editQuestion', 'subtitle', question.data.subtitle))
				dispatch(change('editQuestion', 'bodyCopy', question.data.bodyCopy))
			}
		},
		[ question ]
	)

	useImperativeHandle(ref, () => ({
		getQuestion() {
			return {
				id: question.data.id,
				canonicalId: question.data.canonicalId,
				title: '',
				type: 'content',
				subtitle: selector(store.getState(), 'subtitle'),
				bodyCopy: selector(store.getState(), 'bodyCopy')
			}
		}
	}))

	return (
		<Grid container direction="column" alignItems="flex-start">
			<Grid item>
				<Typography>Readonly Content</Typography>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				<Typography variant="subtitle2">
					Subtitle ({languages(1)}): {question.data.baseTitle}
				</Typography>
			</Grid>
			<Grid item style={{ width: '100%', paddingBottom: '20px' }}>
				<DefaultFormTextField
					label={`Subtitle (${languages(question.data.languageId)})`}
					variant="filled"
					name="subtitle"
					fullWidth
					validators={[ requiredValidator ]}
					inputProps={{ maxLength: 2047 }}
					rows="3"
					maxLength={2047}
				/>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				<Typography variant="subtitle2">
					Body Copy ({languages(1)}): {question.data.baseBodyCopy}
				</Typography>
			</Grid>
			<Grid item style={{ width: '100%', paddingBottom: '20px' }}>
				<DefaultFormTextField
					label={`Body Copy (${languages(question.data.languageId)})`}
					variant="filled"
					name="bodyCopy"
					rows="5"
					fullWidth
					validators={[ requiredValidator ]}
					inputProps={{ maxLength: 2047 }}
					multiline={true}
					maxLength={2047}
				/>
			</Grid>
		</Grid>
	)
})
