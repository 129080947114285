import React, { useEffect } from 'react'
import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import { useIntl, FormattedDate } from 'react-intl'

export default ({ question }) => {
	const intl = useIntl()
	return (
		<Grid container justify="flex-start" id={question.id} >
			<Grid item>
				<Grid container direction="column">
					<Grid item>
						<Typography variant="body2" style={{ textAlign: 'left', fontWeight: '400' }}>
							{question.modifiedDate && <FormattedDate value={question.modifiedDate} />}
						</Typography>
					</Grid>
					{question.modifiedFirstName &&
					question.modifiedLastName && (
						<Grid item>
							<Typography
								variant="body2"
								style={{ textAlign: 'left', fontWeight: '400', wordBreak: 'break-word' }}
							>
								{`${question.modifiedFirstName} ${question.modifiedLastName}`}
							</Typography>
						</Grid>
					)}
				</Grid>
			</Grid>
		</Grid>
	)
}
