import { authenticatedFetch } from './base'

export const search = (searchPhrase, offset) => {
	var url = `/api/sponsors`
	let urlHasParams = false

	if (searchPhrase && searchPhrase.length > 0) {
		url = `${url}?searchPhrase=${encodeURIComponent(searchPhrase)}`
		urlHasParams = true
	}

	if(offset){
		url += `${urlHasParams ? '&' : '?'}offset=${offset}`
		urlHasParams = true
	}

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(sponsor) {
			return sponsor
		})
}

export const fetch = (id) => {
	var url = `/api/sponsors/${id}`

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(sponsor) {
			return sponsor
		})
}

export const addSponsor = (value) => {
	return authenticatedFetch(`/api/sponsors`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(value)
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(sponsor) {
			return sponsor
		})
}

export const archiveSponsor = (id) => {
	return authenticatedFetch(`/api/sponsors/${id}/archive`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(result) {
			return result
		})
}

export const unarchiveSponsor = (id) => {
	return authenticatedFetch(`/api/sponsors/${id}/unarchive`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(result) {
			return result
		})
}

export const editSponsor = (id, values) => {
	return authenticatedFetch(`/api/sponsors/${id}`, {
		method: 'put',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(values)
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(sponsor) {
			return sponsor
		})
}

export const resetSponsorSubscription = (id) => {
	return authenticatedFetch(`/api/sponsors/${id}/resetSubscription`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(result) {
			return result
		})
}

export const searchAutocomplete = (searchPhrase, allowArchived) => {
	var url = `/api/sponsors/autocomplete`
	let urlHasParams = false

	if (searchPhrase && searchPhrase.length > 0) {
		url = `${url}?searchPhrase=${encodeURIComponent(searchPhrase)}`
		urlHasParams = true
	}

	url += `${urlHasParams ? '&' : '?'}allowArchived=${!!allowArchived}`

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(sponsor) {
			return sponsor
		})
}

export const createSubscription = (id, values) => {
	return authenticatedFetch(`/api/sponsors/${id}/createSubscription`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(values)
	})
		.then(function (response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (sponsor) {
			return sponsor
		})
}

export const checkResetButtonVisibility = () => {
	var url = `/api/sponsors/checkResetButtonVisibility`

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(sponsor) {
			return sponsor
		})
}

export const getUsageLink = (id) => {
	var url = `/api/sponsors/${id}/getUsageLink`

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(sponsor) {
			return sponsor
		})
}