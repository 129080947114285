import React, { useState, forwardRef, useImperativeHandle, useEffect, Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import DefaultFormTextField from '../DefaultFormTextField'
import FormCheckBox from '../FormCheckBox'
import { formValueSelector } from 'redux-form'
import { required } from '../../utilities/validators'
import { useStore } from 'react-redux'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'
import { useIntl } from 'react-intl'
import { useSelector, shallowEqual, connect } from 'react-redux'
import { languageNameSelector } from '../../redux/selectors'
const requiredValidator = required
const selector = formValueSelector('editQuestion')

const q = forwardRef((props, ref) => {
	const { question } = props
	const store = useStore()
    const intl = useIntl()
	let languages = useSelector(languageNameSelector, shallowEqual)
	const dispatch = useDispatch()
	useEffect(
		() => {
			if (question && question.data) {
				dispatch(change('editQuestion', 'question', question.data.title))
				dispatch(change('editQuestion', 'instructions', question.data.instructions))
				dispatch(change('editQuestion', 'tag', question.data.tag))
                dispatch(change('editQuestion', 'isRegularExpressionEnabled', question.data.isRegularExpressionEnabled))
                dispatch(change('editQuestion', 'regularExpression', question.data.regularExpression))
                dispatch(change('editQuestion', 'regularExpressionValidation', question.data.regularExpressionValidation))
                dispatch(change('editQuestion', 'isConsentRequired', question.data.isConsentRequired))
			}
		},
		[ question ]
	)

	useImperativeHandle(ref, () => ({
		getQuestion() {
			return {
				id: question.data.id,
				title: selector(store.getState(), 'question'),
				type: 'date',
				canonicalId: question.data.canonicalId,
				instructions: selector(store.getState(), 'instructions'),
				tag: selector(store.getState(), 'tag'),
				isRegularExpressionEnabled: selector(store.getState(), 'isRegularExpressionEnabled'),
                regularExpression: selector(store.getState(), 'regularExpression'),
                regularExpressionValidation: selector(store.getState(), 'regularExpressionValidation'),
                isConsentRequired: selector(store.getState(), 'isConsentRequired')
			}
		}
	}))

	return (
		<Grid container direction='column' alignItems='flex-start'>
			<Grid item style={{ width: '100%' }}>
				<Typography variant='subtitle2'>
					Title ({languages(1)}): {question.data.baseTitle}
				</Typography>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				<DefaultFormTextField
					label={`Title (${languages(question.data.languageId)})`}
					variant='filled'
					fullWidth
					name='question'
					validators={[ requiredValidator ]}
					inputProps={{ maxLength: 1023 }}
					maxLength={1023}
				/>
			</Grid>

			{question.data.baseInstructions && (
				<Fragment>
					<Grid item style={{ width: '100%' }}>
						<Typography variant='subtitle2'>
							Instructions ({languages(1)}): {question.data.baseInstructions}
						</Typography>
					</Grid>
					<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
						<DefaultFormTextField
							label={`Instructions (${languages(question.data.languageId)})`}
							variant='filled'
							name='instructions'
							fullWidth
							inputProps={{ maxLength: 2047 }}
							rows='3'
							maxLength={2047}
							multiline
						/>
					</Grid>
				</Fragment>
			)}

            {
				props.isRegularExpressionEnabled &&
				<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
					<Typography variant="h4" style={{ fontWeight: '400', paddingBottom: '10px' }}>
						{intl.formatMessage({ id: 'app.survey.questions.regularExpression.label' })}
					</Typography>
					<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
						<DefaultFormTextField
							label={intl.formatMessage({ id: 'app.survey.questions.regularExpression.value' })}
							variant="filled"
							fullWidth
							name="regularExpression"
							validators={[requiredValidator]}
							inputProps={{ maxLength: 1024 }}
							maxLength={1024}
							multiline
							rows="3"
						/>
					</Grid>
					<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
						<DefaultFormTextField
							label={intl.formatMessage({ id: 'app.survey.questions.regularExpression.message' })}
							variant="filled"
							fullWidth
							name="regularExpressionValidation"
							validators={[requiredValidator]}
							inputProps={{ maxLength: 1024 }}
							maxLength={1024}
							multiline
							rows="3"
						/>
					</Grid>
				</Grid>
			}
		</Grid>
	)
})

const mapStateToProps = (state) => ({
    isRegularExpressionEnabled: selector(state, 'isRegularExpressionEnabled')
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(q)