import { refreshToken } from './auth'
import history from '../utilities/history'

const excludedUrls = [
	'/api/utility/log',
	'/api/authorization/refresh/'
]

export const authenticatedFetch = (path, params) => {

	// Adding current time to prevent caching
	if(path.includes('?')) {
		path += `&${(new Date).getTime()}`
	} else {
		path += `?${(new Date).getTime()}`
	}

	return fetch(path, { ...params, credentials: 'include' }).then((response) => {
		if (response.headers.get('token-expired') || response.status === 401) {
			//history.push('/auth/login')
			return refreshToken().then((response) => {
				if (response.status === 200) {
					return fetch(path, { ...params, credentials: 'include' })
				}
				else {
					history.push('/auth/login')
					return response
				}
			})
		}
		else  if ([400, 404, 500].includes(response.status) && !excludedUrls.some(u => response.url.includes(u))) {
			fetch(`/api/utility/log`, {
				method: 'post',
				headers: {
					'Content-Type': 'application/json',
					'utility-log-location': window.location.href
				},
				body:
				JSON.stringify({
					message: 
						'Requested Url: ' + response.url + '\n' +
						'Status: ' + response.status + '\n' +
						'Method: ' + params?.method + '\n' +
						'Body: ' + params?.body,
					priority: 2
				})
			})
		}

		return response
	})
}
