import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import PatientUpdateTriggerTemplates from '../../../components/patientUpdateTriggerTemplates/PatientUpdateTriggerTemplates'

const PatientUpdateTriggerTemplatesSection = ({}) => {
	return (
		<Grid item>
			<Typography variant="h1"
				style={{
					marginBottom: '47px'
				}}
			>
				Patient Update Trigger Templates
			</Typography>

			<PatientUpdateTriggerTemplates />
		</Grid>
	)
}

export default PatientUpdateTriggerTemplatesSection
