import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'
import { reduxForm, change, formValueSelector } from 'redux-form'
import DefaultFormTextField from '../../../components/DefaultFormTextField'
import { startSaving, fetchCopy, saveCopy, showModal, hideModal } from '../../../redux/actions'
import { copySelector, isSavingSelector } from '../../../redux/selectors'
import { useDispatch } from 'react-redux'
import { useTheme, Typography } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { required } from '../../../utilities/validators'
import { useSelector, shallowEqual } from 'react-redux'
import EntityAuditCommentModal from "../../../components/entityAudit/EntityAuditCommentModal"
import { isAuditCommentsRequired } from '../../../utilities/auditUtils'
const selector = formValueSelector('updatePathologyVersion')
const requiredValidator = required

const GroupForm = ({ groupId, languageId, handleSubmit, onSave, parent, pathology }) => {
	const dispatch = useDispatch()
	const theme = useTheme()
	const intl = useIntl()
	const copy = useSelector(copySelector, shallowEqual)
	const isSaving = useSelector(isSavingSelector, shallowEqual)
	useEffect(
		() => {
			if (copy) {
				dispatch(change('updateGroupVersion', 'fullName', copy.fullName))
				dispatch(change('updateGroupVersion', 'shortName', copy.shortName))
				dispatch(change('updateGroupVersion', 'description', copy.description))
				dispatch(change('updateGroupVersion', 'disclaimer', copy.disclaimer))
			}
		},
		[ copy ]
	)

	useEffect(
		() => {
			if (groupId > 0 && languageId > 0) {
				dispatch(fetchCopy(groupId, languageId))
			}
		},
		[ groupId, languageId ]
	)

	const onSubmit = (values) => {
		if (values) {
			if (isAuditCommentsRequired())
			{
				dispatch(
					showModal(
						EntityAuditCommentModal,
						{
							entityId: groupId,
							entityTypeId: 20, //scoring group
							saveCallback: (commentsObj) => {
								dispatch(hideModal())
								dispatch(startSaving())
								dispatch(saveCopy(groupId, languageId, values, commentsObj))
							}
						}
					)
				)
			}
			else 
			{
				dispatch(startSaving())
				dispatch(saveCopy(groupId, languageId, values, values))
			}
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container justify="flex-end">
				<Grid item>
					<Button
                        variant="contained"
                        color="primary"
						disabled={isSaving}
						type="submit"
					>
						{intl.formatMessage({
							id: 'app.general.saveChangesButtonText'
						})}
					</Button>
				</Grid>
			</Grid>
			<div style={{ backgroundColor: 'rgb(225, 238, 255)', marginTop: '26px', padding: '3%' }}>
				<Grid container direction="column">
					<Grid item>
						<Typography
							style={{ fontSize: '13px', lineHeight: '16px', letterSpacing: '2.08px', color: '#011020' }}
						>
							{intl.formatMessage({id: 'app.scoringGroup.shortNameLabel'})}
						</Typography>
						{languageId !== 1 &&
						copy && (
							<Grid item style={{ minHeight: '20px' }}>
								<Grid container>
									<Grid item>
										<Typography
											style={{ fontSize: '13px', lineHeight: '16px', letterSpacing: '2.08px' }}
										>
											{intl.formatMessage({id: 'app.scoringGroup.defaultTextLabel'})}
										</Typography>
									</Grid>
									<Grid item>
										<Typography
											style={{ fontSize: '12px', lineHeight: '16px', letterSpacing: '0.38px' }}
										>
											{copy.baseShortName}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						)}
						<Grid item style={{ marginTop: '17px' }}>
							<DefaultFormTextField
								variant="filled"
								label={intl.formatMessage({id: 'app.scoringGroup.shortNameFieldLabel'})}
								name="shortName"
								fullWidth
								validators={[ requiredValidator ]}
								inputProps={{ maxLength: 254 }}
								maxLength={254}
							/>
						</Grid>
					</Grid>
					<Grid item>
						<Typography
							style={{ fontSize: '13px', lineHeight: '16px', letterSpacing: '2.08px', color: '#011020' }}
						>
							{intl.formatMessage({id: 'app.scoringGroup.fullNameLabel'})}
						</Typography>
						{languageId !== 1 &&
						copy && (
							<Grid item style={{ minHeight: '20px' }}>
								<Grid container>
									<Grid item>
										<Typography
											style={{ fontSize: '13px', lineHeight: '16px', letterSpacing: '2.08px' }}
										>
											{intl.formatMessage({id: 'app.scoringGroup.defaultTextLabel'})}
										</Typography>
									</Grid>
									<Grid item>
										<Typography
											style={{ fontSize: '12px', lineHeight: '16px', letterSpacing: '0.38px' }}
										>
											{copy.baseFullName}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						)}
						<Grid item style={{ marginTop: '17px' }}>
							<DefaultFormTextField
								variant="filled"
								label={intl.formatMessage({id: 'app.scoringGroup.fullNameFieldLabel'})}
								name="fullName"
								fullWidth
								validators={[ requiredValidator ]}
								inputProps={{ maxLength: 254 }}
								maxLength={254}
							/>
						</Grid>
					</Grid>
					<Grid item>
						<Typography
							style={{ fontSize: '13px', lineHeight: '16px', letterSpacing: '2.08px', color: '#011020' }}
						>
							{intl.formatMessage({id: 'app.scoringGroup.descriptionLabel'})}
						</Typography>
						{languageId !== 1 &&
						copy && (
							<Grid item style={{ minHeight: '20px' }}>
								<Grid container>
									<Grid item>
										<Typography
											style={{ fontSize: '13px', lineHeight: '16px', letterSpacing: '2.08px' }}
										>
											{intl.formatMessage({id: 'app.scoringGroup.defaultTextLabel'})}
										</Typography>
									</Grid>
									<Grid item>
										<Typography
											style={{ fontSize: '12px', lineHeight: '16px', letterSpacing: '0.38px' }}
										>
											{copy.baseDescription}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						)}
						<Grid item style={{ marginTop: '17px', marginBottom: '20px' }}>
							<DefaultFormTextField
								variant="filled"
								label={intl.formatMessage({id: 'app.scoringGroup.descriptionFieldLabel'})}
								name="description"
								fullWidth
								multiline={true}
								rows={3}
								maxLength={2047}
								inputProps={{ maxLength: 2047 }}
							/>
						</Grid>
					</Grid>
					<Grid item>
						<Typography
							style={{ fontSize: '13px', lineHeight: '16px', letterSpacing: '2.08px', color: '#011020' }}
						>
							{intl.formatMessage({id: 'app.scoringGroup.disclaimerLabel'})}
						</Typography>
						{languageId !== 1 &&
						copy && (
							<Grid item style={{ minHeight: '20px' }}>
								<Grid container>
									<Grid item>
										<Typography
											style={{ fontSize: '13px', lineHeight: '16px', letterSpacing: '2.08px' }}
										>
											{intl.formatMessage({id: 'app.scoringGroup.defaultTextLabel'})}
										</Typography>
									</Grid>
									<Grid item>
										<Typography
											style={{ fontSize: '12px', lineHeight: '16px', letterSpacing: '0.38px' }}
										>
											{copy.baseDisclaimer}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						)}
						<Grid item style={{ marginTop: '17px' }}>
							<DefaultFormTextField
								variant="filled"
								label={intl.formatMessage({id: 'app.scoringGroup.disclaimerFieldLabel'})}
								name="disclaimer"
								fullWidth
								multiline={true}
								rows={3}
								maxLength={2047}
								inputProps={{ maxLength: 2047 }}
							/>
						</Grid>
					</Grid>
				</Grid>
			</div>
		</form>
	)
}

const enhance = compose(
	reduxForm({
		form: 'updateGroupVersion',
		touchOnChange: true,
		touchOnBlur: true
	}),
	injectIntl
)

export default enhance(GroupForm)
