import React, { lazy, Suspense } from 'react'
import componentLoader from '../../utilities/componentLoader'

const Pathologies = lazy(() => componentLoader(() => import('./components/Pathologies')))

export default () => (
	<div>
		<Suspense fallback={<div />}>
			<section>
				<Pathologies />
			</section>
		</Suspense>
	</div>
)
