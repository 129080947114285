import React, { useEffect, useState, Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { useRouteMatch } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import Container from '../../../components/questionBuilder/Container'
import { Link } from 'react-router-dom'
import { ArrowLeftOutlined } from '@material-ui/icons'
import {
	fetchBundle,
	swapBundleQuestionPosition,
	removeBundleQuestion,
	fetchBundleBuilderQuestions,
	updateEntityQuestion,
	addFormulaElement,
	removeLastFormulaElement,
	clearFormula,
	addBundleQuestion,
	addExistingBundleQuestion,
	showModal,
	hideModal,
	updateBundleQuestionIsOptional,
	changeBundleQuestionState,
	setArchivedBundleQuestionVisible,
	fetchCloneBundleQuestion,
	fetchCancelCloneBundleQuestion
} from '../../../redux/actions'
import {
	bundleSelector,
	builderBundleQuestionListSelector,
	builderBundleQuestionsSelector,
	builderBundleQuestionIdSelector,
	builderbundleQuestionTagsSelector,
	bundleNotFoundSelector,
	bundleRequestReceivedSelector,
	surveyLanguageListSelector,
	isArchivedBundleQuestionVisibleSelector
} from '../../../redux/selectors'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Divider from '@material-ui/core/Divider'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import CreateIcon from '@material-ui/icons/Create'
import { Table, TableRow, TableCell, TableBody, Tabs, Tab } from '@material-ui/core'
import EditBundleModal from './EditBundleModal'
import LanguageNavigator from './LanguageNavigator'
import PrivateRoute from '../../../routes/PrivateRoute'
import ViewContainer from '../../../components/questionViewer/Container'
import ReadonlyContainer from '../../../components/questionPreview/Container'
import LockIcon from '@material-ui/icons/Lock'
import RemoveBundleQuestionModal from './RemoveBundleQuestionModal'
import BundleSettings from './BundleSettings'
import ChangeBundleQuestionStateModal from './ChangeBundleQuestionStateModal'
import BundleAudit from './BundleAudit'
import EntityAuditCommentModal from "../../../components/entityAudit/EntityAuditCommentModal"
import { isAuditCommentsRequired } from '../../../utilities/auditUtils'


const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper
	},
	button: {
		'&:hover': {
			backgroundColor: 'rgba(0,0,0,0.1)'
		}
	},
	buttonLabelAlignment: {
		textAlign: 'left'
	}
}))

const BundleV2 = ({
	group,
	loadGroup,
	questionList,
	questionCollection,
	addQuestion,
	addExistingQuestion,
	swapBundleQuestionPosition,
	removeBundleQuestion,
	loadGroupQuestions,
	updateBundleQuestion,
	updateBundleQuestionIsOptional,
	intl,
	notFound,
	responseReceived,
	archivedQuestionsVisible,
	setArchivedBundleQuestionVisible,
	changeBundleQuestionState
}) => {
	const match = useRouteMatch()
	const classes = useStyles()
	const tags = useSelector(builderbundleQuestionTagsSelector, shallowEqual)
	const [ showEdit, setShowEdit ] = useState(false)
	const dispatch = useDispatch()
	const [ tabIndex, setTabIndex ] = useState(1)

	useEffect(
		() => {
			if (match.params.bundleId) {
				!responseReceived && loadGroup(match.params.bundleId)
				if (match.params.languageId) {
					if (responseReceived && !notFound) {
						loadGroupQuestions(match.params.bundleId, match.params.languageId, archivedQuestionsVisible)
					}
				}
			}
		},
		[ match.params.bundleId, match.params.languageId, responseReceived ]
	)

	const addBundleExistingQuestionCallback = (id, typeId, position, commentsObj) => {
		if (!commentsObj && isAuditCommentsRequired())
		{
			dispatch(
				showModal(
					EntityAuditCommentModal,
					{
						entityId: match.params.bundleId,
						entityTypeId: 15, //bundle
						saveCallback: (commentsObj) => {
							dispatch(hideModal())
							addExistingQuestion(id, typeId, position, match.params.bundleId, archivedQuestionsVisible, commentsObj)
						}
					}
				)
			)
		}
		else {
			addExistingQuestion(id, typeId, position, match.params.bundleId, archivedQuestionsVisible, commentsObj)
		}
	}

	const addBundleQuestionCallback = (data, position, isClone, commentsObjPrev) => {
		if (commentsObjPrev || !isAuditCommentsRequired())
		{
			addQuestion(data, position, match.params.bundleId, archivedQuestionsVisible, isClone, commentsObjPrev)
		}
		else {
			dispatch(
				showModal(
					EntityAuditCommentModal,
					{
						entityId: match.params.bundleId,
						entityTypeId: 15, //bundle
						saveCallback: (commentsObj) => {
							dispatch(hideModal())
							addQuestion(data, position, match.params.bundleId, archivedQuestionsVisible, isClone, commentsObj)
						}
					}
				)
			)
		}
	}

	const swapBundleQuestionCallback = (nodeA, nodeB) => {
		swapBundleQuestionPosition(nodeA, nodeB, match.params.bundleId, match.params.languageId, archivedQuestionsVisible)
	}

	const removeBundleQuestionCallback = (question) => {
		removeBundleQuestion(question, match.params.bundleId)
	}

	const updateBundleQuestionCallback = (question, id, languageId, commentsObjPrev) => {
		if (commentsObjPrev || !isAuditCommentsRequired())
		{
			updateBundleQuestion(question, match.params.languageId, match.params.bundleId, archivedQuestionsVisible, commentsObjPrev)
		}
		else 
		{
			dispatch(
				showModal(
					EntityAuditCommentModal,
					{
						entityId: match.params.bundleId,
						entityTypeId: 15, //bundle
						saveCallback: (commentsObj) => {
							dispatch(hideModal())
							updateBundleQuestion(question, match.params.languageId, match.params.bundleId, archivedQuestionsVisible, commentsObj)
						}
					}
				)
			)
		}
	}

	const onStartUpdatingQuestionCallback = () => {}

	const addOperator = (next) => {
		dispatch(addFormulaElement({ type: 'operator', operator: next, text: next }))
	}

	const addConstant = (constant) => {
		dispatch(addFormulaElement({ type: 'constant', constant: parseFloat(constant), text: constant }))
	}

	const addTag = (tag) => {
		dispatch(addFormulaElement({ type: 'tag', text: tag.tag, tag: tag.tag, constant: null, operator: null }))
	}

	const backspace = () => {
		dispatch(removeLastFormulaElement())
	}

	const clear = () => {
		dispatch(clearFormula())
	}

	const onEdit = () => {
		dispatch(showModal(EditBundleModal, { group: group }))
	}

	const processQuestionUpdate = (question, id, languageId) => {
		if (question) {
			if (isAuditCommentsRequired())
			{
				dispatch(
					showModal(
						EntityAuditCommentModal,
						{
							entityId: match.params.bundleId,
							entityTypeId: 15, //bundle
							saveCallback: (commentsObj) => {
								dispatch(hideModal())
								updateBundleQuestionIsOptional(question, match.params.bundleId, commentsObj).then((r) => {
									updateBundleQuestionCallback(question, id, languageId, commentsObj)
								})
							}
						}
					)
				)
			}		
			else 
			{
				updateBundleQuestionIsOptional(question, match.params.bundleId).then((r) => {
					updateBundleQuestionCallback(question, id, languageId)
				})
			}	
		}
	}

	const onSwitchArchivedVisible = (visible) => {
		setArchivedBundleQuestionVisible(visible)
		loadGroupQuestions(match.params.bundleId, match.params.languageId, visible)
	}

	const onChangeBundleQuestionState = (question, newState, allEntities) => {
		changeBundleQuestionState(
			match.params.bundleId,
			question,
			newState,
			match.params.languageId,
			allEntities
		)
	}

	const handleChangeTabIndex = (_, value) => {
		setTabIndex(value)
	}

	return (
		<div style={{ padding: '3%' }}>
			<Grid container direction='column'>
				<Grid item>
					<Typography variant='body2' style={{ minHeight: '62px', paddingBottom: '16px' }}>
						<Link to='/bundles' style={{ textDecoration: 'none', color: '#2e7df6' }}>
							<ArrowLeftOutlined viewBox='0 0 24 15' />{' '}
							{intl.formatMessage({ id: 'app.bundle.backToBundle' }).toUpperCase()}
						</Link>
					</Typography>
				</Grid>

				<Grid container direction='column' spacing={2}>
					<Grid
						item
						style={{ width: '100%', cursor: 'pointer' }}
						onClick={() => {
							setShowEdit(false)
							onEdit()
						}}
						onMouseOver={() => setShowEdit(true)}
						onMouseOut={() => setShowEdit(false)}>
						<Grid container alignItems='center'>
							<Grid item>
								<Typography
									variant="h1">
									{group && group.name}
									{group &&
									group.isLocked && <LockIcon style={{ fontSize: '24px', color: '#949494' }} />}
								</Typography>
							</Grid>
							<Grid item> {showEdit && <CreateIcon style={{ fontSize: '24px' }} />}</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid item style={{ width: '100%', cursor: false ? 'pointer' : '', marginBottom: '16px', marginTop: '16px' }}>
					<Grid container justify="space-between" alignItems="center">
						<Grid item xs={12}>
							<Tabs
								value={tabIndex}
								onChange={handleChangeTabIndex}
								style={{
									borderBottom: '1px solid #505050'
								}}
							>
								<Tab label={ intl.formatMessage({ id: 'app.bundle.contentButtonText' }) } value={1} style={{fontSize: "20px"}} />
								<Tab label={ intl.formatMessage({ id: 'app.bundle.auditButtonText' }) } value={2} style={{fontSize: "20px"}} />
							</Tabs>
						</Grid>
					</Grid>
				</Grid>

				<Grid item style={{ width: '100%' }}>
					{
						tabIndex == 1
						&& <Grid container style={{ width: '100%' }} spacing={1}>
							<Grid item style={{ flex: 0.3 }}>
								<Grid item>
									<LanguageNavigator />
								</Grid>
							</Grid>
							<Grid item style={{ flex: 0.7 }}>
								<PrivateRoute
									exact={true}
									path="/bundles/:bundleId/details"
									component={BundleSettings}
								/>
								{group &&
								!group.isLocked &&
								match.params.languageId == 1 && (
									<PrivateRoute
										exact={true}
										path='/bundles/:bundleId/languages/:languageId'
										showPreview={false}
										ignoreBundles={true}
										list={questionList}
										collection={questionCollection}
										suppressNonDiscreteQuestions={true}
										onAddCallback={addBundleQuestionCallback}
										onAddExistingCallback={addBundleExistingQuestionCallback}
										onSwapCallback={swapBundleQuestionCallback}
										onRemoveCallback={removeBundleQuestionCallback}
										onUpdateCallback={processQuestionUpdate}
										onStartUpdatingQuestionCallback={onStartUpdatingQuestionCallback}
										showScoreEntry={true}
										searchType={2}
										headerTitle={intl.formatMessage({ id: 'app.survey.bundleContentHeader' })}
										component={Container}
										isBundle={true}
										propertyStateName={"stateInBundle"}
										onChangeQuestionStateCallback={onChangeBundleQuestionState}
										archivedQuestionsVisible={archivedQuestionsVisible}
										onArchivedQuestionsVisibleCallback={onSwitchArchivedVisible}
										canSetInUseState={true}
										canSetInUseStateAllEntities={true}
										canSetArchiveState={true}
										canSetArchiveStateAllEntities={true}
									/>
								)}
								{group &&
								group.isLocked &&
								match.params.languageId == 1 && (
									<Paper style={{ minHeight: '100vh', padding: '2% 7% 2% 7%' }}>
										<ReadonlyContainer list={questionList} collection={questionCollection} />
									</Paper>
								)}
								{match.params.languageId != 1 && (
									<PrivateRoute
										exact={true}
										path='/bundles/:bundleId/languages/:languageId'
										list={questionList}
										ignoreBundles={true}
										collection={questionCollection}
										onUpdateCallback={updateBundleQuestionCallback}
										languageId={match.params.languageId}
										headerTitle={intl.formatMessage({ id: 'app.survey.bundleContentHeader' })}
										component={ViewContainer}
										showScoreEntry={false}
									/>
								)}
							</Grid>
						</Grid>
					}
					{
						tabIndex == 2
						&& <BundleAudit />
					}
				</Grid>

				{/* <Grid item style={{ width: '100%', marginBottom: '16px', marginTop: '16px' }}>
					<Grid container style={{ width: '100%' }} spacing={1}>
						<Grid item style={{ flex: 0.3 }}>
							<Grid item>
								<LanguageNavigator />
							</Grid>
						</Grid>
						<Grid item style={{ flex: 0.7 }}>
							<PrivateRoute
								exact={true}
								path="/bundles/:bundleId/details"
								component={BundleSettings}
							/>
							{group &&
							!group.isLocked &&
							match.params.languageId == 1 && (
								<PrivateRoute
									exact={true}
									path='/bundles/:bundleId/languages/:languageId'
									showPreview={false}
									ignoreBundles={true}
									list={questionList}
									collection={questionCollection}
									suppressNonDiscreteQuestions={true}
									onAddCallback={addBundleQuestionCallback}
									onAddExistingCallback={addBundleExistingQuestionCallback}
									onSwapCallback={swapBundleQuestionCallback}
									onRemoveCallback={removeBundleQuestionCallback}
									onUpdateCallback={processQuestionUpdate}
									onStartUpdatingQuestionCallback={onStartUpdatingQuestionCallback}
									showScoreEntry={true}
									searchType={2}
									headerTitle={intl.formatMessage({ id: 'app.survey.bundleContentHeader' })}
									component={Container}
									isBundle={true}
									propertyStateName={"stateInBundle"}
									onChangeQuestionStateCallback={onChangeBundleQuestionState}
									archivedQuestionsVisible={archivedQuestionsVisible}
									onArchivedQuestionsVisibleCallback={onSwitchArchivedVisible}
									canSetInUseState={true}
									canSetInUseStateAllEntities={true}
									canSetArchiveState={true}
									canSetArchiveStateAllEntities={true}
								/>
							)}
							{group &&
							group.isLocked &&
							match.params.languageId == 1 && (
								<Paper style={{ minHeight: '100vh', padding: '2% 7% 2% 7%' }}>
									<ReadonlyContainer list={questionList} collection={questionCollection} />
								</Paper>
							)}
							{match.params.languageId != 1 && (
								<PrivateRoute
									exact={true}
									path='/bundles/:bundleId/languages/:languageId'
									list={questionList}
									ignoreBundles={true}
									collection={questionCollection}
									onUpdateCallback={updateBundleQuestionCallback}
									languageId={match.params.languageId}
									headerTitle={intl.formatMessage({ id: 'app.survey.bundleContentHeader' })}
									component={ViewContainer}
									showScoreEntry={false}
								/>
							)}
						</Grid>
					</Grid>
				</Grid> */}
				<Grid item>
					{responseReceived &&
					notFound && <Typography>{intl.formatMessage({ id: 'app.bundle.notFound' })}</Typography>}
				</Grid>
			</Grid>
		</div>
	)
}
const mapStateToProps = (state, ownProps) => ({
	group: bundleSelector(state),
	questionList: builderBundleQuestionListSelector(state),
	questionCollection: builderBundleQuestionsSelector(state),
	ids: builderBundleQuestionIdSelector(state),
	tags: builderbundleQuestionTagsSelector(state),
	notFound: bundleNotFoundSelector(state),
	responseReceived: bundleRequestReceivedSelector(state),
	archivedQuestionsVisible: isArchivedBundleQuestionVisibleSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	loadGroup: (id) => dispatch(fetchBundle(id)),
	loadGroupQuestions: (id, languageId, archivedQuestionsVisible) => dispatch(fetchBundleBuilderQuestions(id, null, languageId, archivedQuestionsVisible)),
	addQuestion: (data, position, id, archivedQuestionsVisible, isClone, commentsObj) => dispatch(addBundleQuestion(data, position, id, archivedQuestionsVisible, isClone, commentsObj)),
	addExistingQuestion: (id, typeId, position, bundleId, archivedQuestionsVisible, commentsObj) =>
		dispatch(addExistingBundleQuestion(id, typeId, position, bundleId, archivedQuestionsVisible, commentsObj)),
	removeBundleQuestion: (question, id) => dispatch(
		showModal(
			RemoveBundleQuestionModal,
			{
				question: question,
				bundleId: id
			}
		)
	),
	updateBundleQuestion: (question, languageId, id, archivedQuestionsVisible, commentsObj) => {
		return dispatch(updateEntityQuestion(question, languageId, fetchBundleBuilderQuestions, id, null, archivedQuestionsVisible, commentsObj))
	},
	swapBundleQuestionPosition: (nodeA, nodeB, id, languageId, archivedQuestionsVisible) =>
		dispatch(swapBundleQuestionPosition(nodeA, nodeB, id, languageId, archivedQuestionsVisible)),
	updateBundleQuestionIsOptional: (question, id, commentsObj) => dispatch(updateBundleQuestionIsOptional(question, id, commentsObj)),
	setArchivedBundleQuestionVisible: (visible) => dispatch(setArchivedBundleQuestionVisible(visible)),
	changeBundleQuestionState: (bundleId, question, newState, languageId, allEntities) => dispatch(
		showModal(
			ChangeBundleQuestionStateModal,
			{
				bundleId,
				question,
				newState,
				languageId,
				allEntities,
				entityId: bundleId,
                entityTypeId: 15, //bundle
			}
		)
	)
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'bundle'
	})
)

export default enhance(BundleV2)
