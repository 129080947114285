import * as survey from '../../../apis/survey'
import { createSelector } from 'reselect'
import { hideModal } from '../../actions'
import { Node, LinkList, ListContainer } from '../../../utilities/linkedList'
import { fetchSurvey } from '../../actions'
const FETCH_TRANSLATE_SURVEY_REQUESTED = 'FETCH_TRANSLATE_SURVEY_REQUESTED'
const FETCH_TRANSLATE_SURVEY_SUCCESS = 'FETCH_TRANSLATE_SURVEY_SUCCESS'
const FETCH_ROOT_SURVEY_SUCCESS = 'FETCH_ROOT_SURVEY_SUCCESS'
const FETCH_TRANSLATE_SURVEY_FAILED = 'FETCH_TRANSLATE_SURVEY_FAILED'

export const fetchTranslatedSurvey = (id, languageId = 1) => (dispatch) => {
	dispatch({ type: FETCH_TRANSLATE_SURVEY_REQUESTED, data: {} })

	survey.fetchTranslatedSurvey(id, languageId).then((response) => {
		if (response && response.isSuccessful) {
			let questionIds = new Array(0)
			let hash = {}
			let container = new ListContainer()
			processSurveyQuestions(response, container, questionIds, hash)

			// if (onFetchCompleteCallback) {
			// 	dispatch(onFetchCompleteCallback())
			// }
			return dispatch({
				type: FETCH_TRANSLATE_SURVEY_SUCCESS,
				data: {
					survey: response,
					list: container,
					ids: questionIds,
					hash: hash,
					settings: {
						title: response.title,
						internalDescription: response.internalDescription
					}
				}
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.surveys.fetchSurveyFailed', isSuccess: false }
			})
		}
	})
}

const processSurveyQuestions = (survey, container, questions, hash) => {
	if (survey.questions) {
		survey.questions.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1)).map((r) => {
			container.getList().append(r)
		})
		container.getList().traverse((r) => {
			questions.push(r.data.id)
			hash[r.data.id] = r
		})
	}
}

const UPDATE_SURVEY_TRANSLATED_TITLE_REQUESTED = 'UPDATE_SURVEY_TRANSLATED_TITLE_REQUESTED'
export const updateSurveyTranslatedTitle = (title) => (dispatch) => {
	return dispatch({ type: UPDATE_SURVEY_TRANSLATED_TITLE_REQUESTED, data: { title } })
}

const SAVE_TRANSLATION_REQUESTED = 'SAVE_TRANSLATION_REQUESTED'
const SAVE_TRANSLATION_SUCCESS = 'SAVE_TRANSLATION_SUCCESS'
const SAVE_TRANSLATION_FAILED = 'SAVE_TRANSLATION_FAILED'
export const saveTranslation = (surveyId, baseSurveyId, questions) => (dispatch, getState) => {
	dispatch({ type: SAVE_TRANSLATION_REQUESTED, data: {} })
	let state = getState()

	if (questions) {
		let s = { ...state.translator.surveyTranslator.translatedQuestions.settings }
		let settings = {
			internalDescription: s.internalDescription,
			title: s.title
		}

		survey.saveSurveyTranslation(surveyId, baseSurveyId, questions, settings).then((survey) => {
			if (survey && survey.isSuccessful) {
				let questions = new Array(0)
				let hash = {}
				let container = new ListContainer()
				dispatch(fetchSurvey(baseSurveyId))
				processSurveyQuestions(survey, container, questions, hash)
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'Success', isSuccess: true }
				})
			} else {
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'TODO', isSuccess: false }
				})
			}
		})
	}
	return
}

const UPDATE_SURVEY_TITLE_REQUESTED = 'UPDATE_SURVEY_TITLE_REQUESTED'
export const updateSurveyTranslationTitle = (title) => (dispatch) => {
	return dispatch({ type: UPDATE_SURVEY_TRANSLATED_TITLE_REQUESTED, data: title })
}

const initial = {
	surveyTranslator: {
		translatedQuestions: {
			list: new LinkList(),
			hash: {},
			ids: [],
			settings: {}
		},
		rootQuestions: {
			list: new LinkList(),
			hash: {},
			ids: [],
			settings: {}
		}
	}
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case UPDATE_SURVEY_TRANSLATED_TITLE_REQUESTED:
			return {
				...state,
				surveyTranslator: {
					...state.surveyTranslator,
					translatedQuestions: {
						...state.surveyTranslator.translatedQuestions,
						settings: { ...state.surveyTranslator.translatedQuestions.settings, title: action.data }
					}
				}
			}
		case FETCH_TRANSLATE_SURVEY_SUCCESS:
			return {
				...state,
				surveyTranslator: {
					...state.surveyTranslator,
					translatedQuestions: {
						...state.surveyTranslator.translatedQuestions,
						list: action.data.list,
						hash: action.data.hash,
						ids: action.data.ids,
						settings: action.data.settings
					}
				}
			}
		case FETCH_TRANSLATE_SURVEY_REQUESTED:
			return {
				...state,
				surveyTranslator: {
					translatedQuestions: {
						list: new LinkList(),
						hash: {},
						ids: [],
						settings: {}
					},
					rootQuestions: {
						list: new LinkList(),
						hash: {},
						ids: [],
						settings: {}
					}
				}
			}
		case FETCH_ROOT_SURVEY_SUCCESS:
			return {
				...state,
				surveyTranslator: {
					...state.surveyTranslator,
					rootQuestions: {
						...state.surveyTranslator.rootQuestions,
						list: action.data.list,
						hash: action.data.hash,
						ids: action.data.ids,
						settings: action.data.settings
					}
				}
			}

		case 'SIGN_OUT_REQUESTED':
			return initial

		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.translator

export const translatedSurveyQuestionIdSelector = createSelector(mainSelector, (state) => {
	if (
		state &&
		state.surveyTranslator &&
		state.surveyTranslator.translatedQuestions &&
		state.surveyTranslator.translatedQuestions.ids
	) {
		return state.surveyTranslator.translatedQuestions.ids
	}
})

export const rootSurveyQuestionHashSelector = createSelector(mainSelector, (state) => {
	if (
		state &&
		state.surveyTranslator &&
		state.surveyTranslator.rootQuestions &&
		state.surveyTranslator.rootQuestions.hash
	) {
		return state.surveyTranslator.rootQuestions.hash
	}
})

export const translatedSurveyQuestionHashSelector = createSelector(mainSelector, (state) => {
	if (
		state &&
		state.surveyTranslator &&
		state.surveyTranslator.translatedQuestions &&
		state.surveyTranslator.translatedQuestions.hash
	) {
		return state.surveyTranslator.translatedQuestions.hash
	}
})

export const translatedSurveySettingsSelector = createSelector(mainSelector, (state) => {
	if (
		state &&
		state.surveyTranslator &&
		state.surveyTranslator.translatedQuestions &&
		state.surveyTranslator.translatedQuestions.settings
	) {
		return state.surveyTranslator.translatedQuestions.settings
	}
})

export const rootSurveySettingsSelector = createSelector(mainSelector, (state) => {
	if (
		state &&
		state.surveyTranslator &&
		state.surveyTranslator.rootQuestions &&
		state.surveyTranslator.rootQuestions.settings
	) {
		return state.surveyTranslator.rootQuestions.settings
	}
})

export const baseSurveyQuestionIdSelector = createSelector(mainSelector, (state) => {
	return state && state.baseSurveyQuestionIds
})

export const surveySelector = createSelector(mainSelector, (state) => {
	return state.survey
})

export const surveySettingsSelector = createSelector(mainSelector, (state) => {
	return state && state.surveyTranslator && state.surveyTranslator.settings
})

export const surveyLanguagesSelector = createSelector(mainSelector, (state) => {
	return state && state.surveyLanguages
})

export const surveyQuestionListSelector = createSelector(mainSelector, (state) => {
	return state.surveyQuestions && state.surveyQuestions.getList()
})

export const translatedSurveyQuestionsSelector = createSelector(
	mainSelector,
	translatedSurveyQuestionIdSelector,
	translatedSurveyQuestionHashSelector,
	(state, ids, hash) => {
		if (state && ids && ids.length > 0 && hash) {
			let questions = []

			ids.map((r) => {
				if (hash.hasOwnProperty(r)) {
					let x = hash[r]
					questions.push(x)
				} else {
					console.log('No Property', questions)
				}
			})

			return questions
		}
	}
)

export const previewSurveyQuestionsSelector = createSelector(
	mainSelector,
	translatedSurveyQuestionsSelector,
	(state, questions) => {
		if (state && questions && questions.length > 0) {
			let questionCollection = questions.map((r) => {
				let x = { ...r }
				x.data = x.data.childQuestion
				return x
			})
			return questionCollection
		}
		return []
	}
)

export const rootQuestionSelector = createSelector(
	mainSelector,
	rootSurveyQuestionHashSelector,
	(state, hash) => (id) => {
		if (state && Object.keys(hash).length > 0 && id) {
			if (hash.hasOwnProperty(id)) {
				return hash[id]
			}
		}
		return null
	}
)
