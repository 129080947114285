import * as questions from '../../../apis/question'
import * as cookies from '../../../utilities/cookies'
import history from '../../../utilities/history'
import { createSelector } from 'reselect'
import { processQuestions } from '../../../utilities/questions'
import { ListContainer } from '../../../utilities/linkedList'

const SEARCH_QUESTIONS_REQUESTED = 'SEARCH_QUESTIONS_REQUESTED'
const SEARCH_QUESTIONS_SUCCESS = 'SEARCH_QUESTIONS_SUCCESS'
const SEARCH_QUESTIONS_FAILED = 'SEARCH_QUESTIONS_FAILED'

export const searchQuestions = (searchPhrase) => (dispatch) => {
	dispatch({ type: SEARCH_QUESTIONS_REQUESTED, data: {} })
	questions.search(searchPhrase).then((questions) => {
		if (questions && questions.isSuccessful && questions.questions) {
			let data = questions.questions
			return dispatch({ 
				type: SEARCH_QUESTIONS_SUCCESS, 
				data: { 
					items: data,
					hasMore: questions.hasMore
				} 
			})
		} else {
			return dispatch({ type: SEARCH_QUESTIONS_FAILED, data: {} })
		}
	})
	return
}

const SEARCH_ADDITIONAL_QUESTIONS_REQUESTED = 'SEARCH_ADDITIONAL_QUESTIONS_REQUESTED'
const SEARCH_ADDITIONAL_QUESTIONS_SUCCESS = 'SEARCH_ADDITIONAL_QUESTIONS_SUCCESS'
const SEARCH_ADDITIONAL_QUESTIONS_FAILED = 'SEARCH_ADDITIONAL_QUESTIONS_FAILED'

export const searchAdditionalQuestions = (searchPhrase, offset) => (dispatch) => {
	dispatch({ type: SEARCH_ADDITIONAL_QUESTIONS_REQUESTED, data: {} })
	questions.search(searchPhrase, offset).then((questions) => {
		if (questions && questions.isSuccessful && questions.questions) {
			let data = questions.questions
			return dispatch({ 
				type: SEARCH_ADDITIONAL_QUESTIONS_SUCCESS, 
				data: { 
					items: data,
					hasMore: questions.hasMore
				} 
			})
		} else {
			return dispatch({ type: SEARCH_ADDITIONAL_QUESTIONS_FAILED, data: {} })
		}
	})
	return
}

const CLEAR_AUTOCOMPLETE_REQUESTED = 'CLEAR_AUTOCOMPLETE_REQUESTED'

export const clearAutocompleteQuestions = () => (dispatch) => {
	dispatch({ type: CLEAR_AUTOCOMPLETE_REQUESTED, data: {} })
	return
}

const QUESTION_AUTOCOMPLETE_REQUESTED = 'QUESTION_AUTOCOMPLETE_REQUESTED'
const QUESTION_AUTOCOMPLETE_SUCCESS = 'QUESTION_AUTOCOMPLETE_SUCCESS'
const QUESTION_AUTOCOMPLETE_FAILED = 'QUESTION_AUTOCOMPLETE_FAILED'

export const searchAutocompleteQuestions = (searchPhrase, searchType = 1, surveyId) => (dispatch) => {
	dispatch({ type: QUESTION_AUTOCOMPLETE_REQUESTED, data: {} })
	questions.autocompleteSearch(searchPhrase, searchType, surveyId).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: QUESTION_AUTOCOMPLETE_SUCCESS, data: response.data })
		} else {
			return dispatch({ type: QUESTION_AUTOCOMPLETE_FAILED, data: {} })
		}
	})
	return
}

const SET_QUESTION_SORT_REQUESTED = 'SET_QUESTION_SORT_REQUESTED'

export const setQuestionSort = (field, isDescending) => (dispatch, state) => {
	let currentState = state()
	var questions = [ ...currentState.question.searchQuestions ]
	let data = questions.sort((a, b) => {
		if (isDescending) {
			return a[field] > b[field] ? -1 : 1
		} else {
			return b[field] > a[field] ? -1 : 1
		}
	})
	return dispatch({
		type: SET_QUESTION_SORT_REQUESTED,
		data: { items: data, field: field, isDescending: isDescending }
	})
}

const SET_QUESTION_FILTER_REQUESTED = 'SET_QUESTION_FILTER_REQUESTED'
const SET_QUESTION_FILTER_SUCCESS = 'SET_QUESTION_FILTER_SUCCESS'
const SET_QUESTION_FILTER_FAILED = 'SET_QUESTION_FILTER_FAILED'

export const setQuestionFilter = (filter) => (dispatch) => {
	dispatch({ type: SET_QUESTION_FILTER_REQUESTED, data: {} })

	return dispatch({ type: SET_QUESTION_FILTER_SUCCESS, data: filter })
}

const SET_QUESTION_SEARCH_TERM_REQUESTED = 'SET_QUESTION_SEARCH_TERM_REQUESTED'
const SET_QUESTION_SEARCH_TERM_SUCCESS = 'SET_QUESTION_SEARCH_TERM_SUCCESS'
const SET_QUESTION_SEARCH_TERM_FAILED = 'SET_QUESTION_SEARCH_TERM_FAILED'

export const setQuestionSearchTerm = (value) => (dispatch) => {
	dispatch({ type: SET_QUESTION_SEARCH_TERM_REQUESTED, data: {} })
	return dispatch({ type: SET_QUESTION_SEARCH_TERM_SUCCESS, data: value })
}

const FETCH_QUESTION_REQUESTED = 'FETCH_QUESTION_REQUESTED'
const FETCH_QUESTION_SUCCESS = 'FETCH_QUESTION_SUCCESS'
const FETCH_QUESTION_FAILED = 'FETCH_QUESTION_FAILED'
export const loadQuestion = (questionId, questionType, languageId) => (dispatch) => {
	dispatch({ type: FETCH_QUESTION_REQUESTED, data: {} })

	questions.fetchQuestion(questionId, questionType, languageId).then((response) => {
		if (
			!!response
			&& !!response.isSuccessful
			&& !!response.questions?.length
		) {
			let questionIds = new Array(0)
			let hash = {}
			let container = new ListContainer()

			processQuestions(response, container, questionIds, hash)

			return dispatch({
				type: FETCH_QUESTION_SUCCESS,
				data: {
					list: container,
					ids: questionIds,
					hash: hash,
					settings: {
						title: response.title,
						internalDescription: response.internalDescription
					}
				}
			})
		} else {
			dispatch({ type: FETCH_QUESTION_FAILED, data: {} })

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.question.failedFetchReferences', isSuccess: true }
			})
		}
	})
	return
}

const FETCH_QUESTION_REFERENCES_REQUESTED = 'FETCH_QUESTION_REFERENCES_REQUESTED'
const FETCH_QUESTION_REFERENCES_SUCCESS = 'FETCH_QUESTION_REFERENCES_SUCCESS'
const FETCH_QUESTION_REFERENCES_FAILED = 'FETCH_QUESTION_REFERENCES_FAILED'
export const loadQuestionReferences = (questionId, questionType) => (dispatch) => {
	dispatch({ type: FETCH_QUESTION_REFERENCES_REQUESTED, data: {} })

	questions.fetchQuestionReferences(questionId, questionType).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({
				type: FETCH_QUESTION_REFERENCES_SUCCESS,
				data: {
					protocols: response.protocols,
					surveys: response.surveys,
					bundles: response.bundles,
					scoringGroups: response.scoringGroups
				}
			})
		} else {
			dispatch({ type: FETCH_QUESTION_REFERENCES_FAILED, data: {} })

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.question.failedFetchReferences', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_QUESTION_CHANGE_LOGS_REQUESTED = 'FETCH_QUESTION_CHANGE_LOGS_REQUESTED'
const FETCH_QUESTION_CHANGE_LOGS_SUCCESS = 'FETCH_QUESTION_CHANGE_LOGS_SUCCESS'
const FETCH_QUESTION_CHANGE_LOGS_FAILED = 'FETCH_QUESTION_CHANGE_LOGS_FAILED'
export const searchQuestionChangeLogs = (questionId, questionType, searchPhrase = null, keys = []) => (dispatch) => {
	dispatch({ type: FETCH_QUESTION_CHANGE_LOGS_REQUESTED, data: {} })

	questions.fetchQuestionChangeLogs(questionId, questionType, searchPhrase, keys).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({
				type: FETCH_QUESTION_CHANGE_LOGS_SUCCESS,
				data: {
					logs: response.logs,
					keys: response.keys
				}
			})
		} else {
			dispatch({ type: FETCH_QUESTION_CHANGE_LOGS_FAILED, data: {} })

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.audit.failedToFetch', isSuccess: false }
			})
		}
	})
	return
}


const initial = {
	questions: [],
	searchQuestions: [],
	sortField: 'title',
	isDescending: true,
	filter: 0,
	autoCompleteQuestions: [],
	hasMore: true,
	isLoading: false,
	searchTerm: '',
	questionBuilder: null,
	questionReferences: null,
	questionReferencesLoading: false,
	logs: [],
	logKeys: []
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case CLEAR_AUTOCOMPLETE_REQUESTED:
			return { ...state, autoCompleteQuestions: [] }
		case QUESTION_AUTOCOMPLETE_SUCCESS:
			return { ...state, autoCompleteQuestions: action.data }
		case SEARCH_QUESTIONS_REQUESTED:
			return { ...state, searchQuestions: [] }
		case SEARCH_QUESTIONS_SUCCESS:
			return { 
				...state, 
				searchQuestions: action.data.items,
				hasMore: action.data.hasMore 
			}
		case SEARCH_ADDITIONAL_QUESTIONS_SUCCESS:
			return {
				...state,
				searchQuestions: [ ...state.searchQuestions, ...action.data.items],
				hasMore: action.data.hasMore,
				isLoading: false
			}
		case SEARCH_ADDITIONAL_QUESTIONS_FAILED:
			return {
				...state,
				hasMore: false,
				isLoading: false
			}
		case SEARCH_ADDITIONAL_QUESTIONS_REQUESTED:
			return {
				...state,
				isLoading: true
			}
		case SET_QUESTION_SEARCH_TERM_SUCCESS:
			return { ...state, searchTerm: action.data }
		case SET_QUESTION_SORT_REQUESTED:
			return {
				...state,
				searchQuestions: action.data.items,
				sortField: action.data.field,
				isDescending: action.data.isDescending
			}
		case SET_QUESTION_FILTER_SUCCESS:
			return { ...state, filter: action.data }

		/** Question References */
		case FETCH_QUESTION_REFERENCES_REQUESTED:
			return {
				...state,
				questionReferences: null,
				questionReferencesLoading: true
			}
		case FETCH_QUESTION_REFERENCES_SUCCESS:
			return {
				...state,
				questionReferences: action.data,
				questionReferencesLoading: false
			}
		case FETCH_QUESTION_REFERENCES_FAILED:
			return {
				...state,
				questionReferencesLoading: false
			}

		/** Question */
		case FETCH_QUESTION_REQUESTED:
			return {
				...state,
				isQuestionBuilderLoading: true
			}
		case FETCH_QUESTION_SUCCESS:
			return {
				...state,
				isQuestionBuilderLoading: false,
				questionBuilder: {
					...state.questionBuilder,
					list: action.data.list,
					hash: action.data.hash,
					ids: action.data.ids,
					settings: action.data.settings
				}
			}
		case FETCH_QUESTION_FAILED:
			return {
				...state,
				isQuestionBuilderLoading: false,
				questionBuilder: {
					list: new ListContainer(),
					ids: [],
					hash: {},
					settings: {}
				}
			}

		/** Question change logs */
		case FETCH_QUESTION_CHANGE_LOGS_REQUESTED:
			return {
				...state,
				logs: [],
				logKeys: []
			}
		case FETCH_QUESTION_CHANGE_LOGS_SUCCESS:
			return {
				...state,
				logs: action.data.logs,
				logKeys: action.data.keys
			}

		/** */
		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.question

export const searchQuestionsSelector = createSelector(mainSelector, (state) => {
	return state.searchQuestions
})

export const autocompleteQuestionsSelector = createSelector(mainSelector, (state) => {
	return state.autoCompleteQuestions ? state.autoCompleteQuestions : []
})

export const sortQuestionsDirectionSelector = createSelector(mainSelector, (state) => {
	return state.isDescending
})

export const sortQuestionsFieldSelector = createSelector(mainSelector, (state) => {
	return state.sortField
})

export const hasMoreQuestionsSelector = createSelector(mainSelector, (state) => {
	return state.hasMore
})

export const isLoadingQuestionsSelector = createSelector(mainSelector, (state) => {
	return state.isLoading
})

export const questionsSearchTermSelector = createSelector(mainSelector, (state) => {
	return state.searchTerm
})

export const questionReferencesSelector = createSelector(mainSelector, (state) => {
	return state.questionReferences
})

export const questionReferencesLoadingSelector = createSelector(mainSelector, (state) => {
	return state.questionReferencesLoading
})


export const questionBuilderListSelector = createSelector(mainSelector, (state) => {
	if (state && state.questionBuilder && state.questionBuilder.list) {
		return state.questionBuilder.list
	}
})

export const questionBuilderHashSelector = createSelector(mainSelector, (state) => {
	if (state && state.questionBuilder && state.questionBuilder.hash) {
		return state.questionBuilder.hash
	}
})

export const questionBuilderIdSelector = createSelector(mainSelector, (state) => {
	if (state && state.questionBuilder && state.questionBuilder.ids) {
		return state.questionBuilder.ids
	}
})

const getQuestionBuilderQuestions = (ids, hash) => {
	if (
		ids?.length > 0
		&& !!hash
	) {
		let questions = []

		ids.map((r) => {
			if (hash.hasOwnProperty(r)) {
				let x = hash[r]
				questions.push(x)
			} else {
				console.log('No Property', questions)
			}
		})

		return questions
	}

	return null
}

export const questionBuilderQuestionsSelector = createSelector(
	mainSelector,
	questionBuilderIdSelector,
	questionBuilderHashSelector,
	(state, ids, hash) => {
		let result = getQuestionBuilderQuestions(ids, hash)

		return result
	}
)

export const questionChangeLogsSelector = createSelector(
	mainSelector,
	(state) => {
		return !!state && state.logs
	}
)

export const questionChangeLogKeysSelector = createSelector(
	mainSelector,
	(state) => {
		return !!state && state.logKeys
	}
)
