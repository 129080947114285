import React, { Fragment, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useTheme from '@material-ui/core/styles/useTheme'
import { bundleQuestionScoringGroupsSelector, isArchivedBundleQuestionVisibleSelector, isBundleQuestionUsedByCasesSelector } from '../../../redux/selectors'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import Button from '@material-ui/core/Button'
import { fetchQuestionUseStatus, hideModal, removeBundleQuestion } from '../../../redux/actions'

const RemoveQuestionModal = ({
    question,
    bundleId,
    intl,
    isBundleQuestionUsedByCases,
    bundleQuestionScoringGroups,
    archivedQuestionsVisible,
    removeBundleQuestion,
    loadBundleQuestionUseStatus,
    closeModal
}) => {
	const theme = useTheme()

	useEffect(
        () => {
            loadBundleQuestionUseStatus(bundleId, question)
        },
        []
    )

    const onRemoveQuestion = () => {
        removeBundleQuestion(question, bundleId, archivedQuestionsVisible)
    }

	return (
		<Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            style={{ width: '75rem', padding: '4rem', margin: 'auto', backgroundColor: theme.palette.secondary.main }}
        >
            <Grid item style={{ alignSelf: 'center' }}>
                <Typography
                    variant="h1"
                    style={{
                        color: '#fff',
                        marginBottom: '25px',
                    }}
                >
                    {intl.formatMessage({ id: 'app.bundle.removeBundleQuestionModalTitle' })}
                </Typography>
            </Grid>
            {
                (
                    isBundleQuestionUsedByCases == false
                    && bundleQuestionScoringGroups?.length == 0
                )
                && <Fragment>
                    <Grid item style={{ alignSelf: 'flex-start' }}>
                        <Typography
                            variant="h3"
                            style={{
                                color: '#fff',
                            }}
                            align="left"
                        >
                            {intl.formatMessage({ id: 'app.bundle.removeBundleQuestionModalText' })}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                    >
                        <Grid
                            container
                            direction='row'
                            justify='center'
                        >
                            <Grid
                                item
                            >
                                <Button
                                    style={{
                                        width: '150px',
                                        marginTop: '40px'
                                    }}
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    onClick={onRemoveQuestion}
                                >
                                    {intl.formatMessage({ id: 'app.bundle.removeBundleQuestionButtonYes' })}
                                </Button>
                            </Grid>
                            <Grid
                                item
                            >
                                <Button
                                    style={{
                                        width: '150px',
                                        marginTop: '40px'
                                    }}
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    onClick={closeModal}
                                >
                                    {intl.formatMessage({ id: 'app.bundle.removeBundleQuestionButtonNo' })}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Fragment>
            }
            {
                !!isBundleQuestionUsedByCases
                && <Fragment>
                    <Grid item style={{ alignSelf: 'flex-start' }}>
                        <Typography
                            variant="h3"
                            style={{
                                color: '#fff',
                            }}
                            align="left"
                        >
                            {intl.formatMessage({ id: 'app.bundle.removeBundleQuestionModalIsUsedByCases' })}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        style={{ alignSelf: 'center' }}
                    >
                        <Button
                            style={{
                                width: '150px',
                                marginTop: '40px'
                            }}
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={closeModal}
                        >
                            {intl.formatMessage({ id: 'app.bundle.removeBundleQuestionButtonOk' })}
                        </Button>
                    </Grid>
               </Fragment>
            }
            {
                bundleQuestionScoringGroups?.length > 0
                && <Fragment>
                    <Grid item style={{ alignSelf: 'flex-start' }}>
                        <Typography
                            variant="h3"
                            style={{
                                color: '#fff',
                            }}
                            align="left"
                        >
                            {intl.formatMessage({ id: 'app.bundle.removeBundleQuestionModalIsUsedByScoringGroups' })}
                        </Typography>
                    </Grid>
                    <Grid item style={{ alignSelf: 'flex-start' }}>
                        <Typography
                            variant="h3"
                            style={{
                                color: '#fff',
                            }}
                            align="left"
                        >
                            {
                                bundleQuestionScoringGroups.map(scoringGroup => {
                                    return (
                                        <div>
                                            {scoringGroup.name}
                                        </div>
                                    )
                                })
                            }
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        style={{ alignSelf: 'center' }}
                    >
                        <Button
                            style={{
                                width: '150px',
                                marginTop: '40px'
                            }}
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={closeModal}
                        >
                            {intl.formatMessage({ id: 'app.bundle.removeBundleQuestionButtonOk' })}
                        </Button>
                    </Grid>
                </Fragment>
            }
        </Grid>
	)
}

const mapStateToProps = (state, ownProps) => ({
    isBundleQuestionUsedByCases: isBundleQuestionUsedByCasesSelector(state),
    bundleQuestionScoringGroups: bundleQuestionScoringGroupsSelector(state),
    archivedQuestionsVisible: isArchivedBundleQuestionVisibleSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	removeBundleQuestion: (question, bundleId, archivedQuestionsVisible) => {
        dispatch(removeBundleQuestion(question, bundleId, archivedQuestionsVisible))
        dispatch(hideModal())
    },
    loadBundleQuestionUseStatus: (bundleId, question) => dispatch(fetchQuestionUseStatus(bundleId, question)),
    closeModal: () => dispatch(hideModal())
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl
)

export default enhance(RemoveQuestionModal)
