import React from 'react'
import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { useIntl, FormattedDate } from 'react-intl'

export default ({ subscriber }) => {
	const intl = useIntl()
	return (
		<Grid container justify="flex-start" id={subscriber.id}>
			<Grid item>
				<Grid container direction="column">
					<Grid item>
						<Typography
							variant="body2"
							style={{
								textAlign: 'left',
								fontWeight: '400',
								fontSize: '17px',
								lineHeight: '22px',
								letterSpacing: '0.51px'
							}}
						>
							{subscriber && subscriber.lastLogin && <FormattedDate value={subscriber.lastLogin} />}
							{!subscriber ||
								(!subscriber.lastLogin && intl.formatMessage({ id: 'app.general.pending' }))}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}
