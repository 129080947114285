import React, { Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { useTheme } from '@material-ui/core/styles'
import PrivateRoute from '../../routes/PrivateRoute'
import FailedReminders from './FailedReminders'
import ToolNavigation from './ToolNavigation'
import { useLocation } from 'react-router-dom'
export default () => {
	const theme = useTheme()
	const loc = useLocation()
	return (
		<div style={{ padding: '2%', maxWidth: '100%' }}>
			<h1>Tools</h1>

			<Grid container justify="flex-start">
				<Grid item style={{ flex: 0.2 }}>
					<Paper style={{ minWidth: '300px', minHeight: '400px' }}>
						<ToolNavigation />
					</Paper>
				</Grid>
				<Grid item style={{ marginLeft: '12px', flex: 0.8 }}>
					{console.log(loc)}
					{loc &&
					loc.pathname &&
					loc.pathname.toLowerCase() !== 'tools' && (
						<Paper style={{ padding: '3%' }}>
							<PrivateRoute exact={true} path="/tools/lockedreminders" component={FailedReminders} />
						</Paper>
					)}
				</Grid>
			</Grid>
		</div>
	)
}
