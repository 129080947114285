import React from 'react'
import Grid from '@material-ui/core/Grid'
import BuilderContainer from './BuilderContainer'

export default ({ headerTitle, list, collection }) => {
	return (
		<Grid container direction="column" style={{ width: '100%' }}>
			<Grid item style={{ marginTop: '10px' }}>
				<BuilderContainer headerTitle={headerTitle} list={list} collection={collection} />
			</Grid>
		</Grid>
	)
}
