import React, { useState, useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from "@material-ui/core/Button";
import Pencil from '../../../assets/pencil'
import UpdateUserProfile from '../UpdateUserProfile'
import { showModal } from '../../../redux/actions'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useTheme, withStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import { getUser } from '../../../utilities/userUtilities'
import UploadImage from '../../../components/UploadImage'
import UploadTeamLogo from './UploadTeamLogo'
import * as UI from '../../../utilities/UIConstants'

const styles = (theme) => ({
})

const Header = ({ onEditClick, userDetails, classes, intl, onImageUpdated, onLogoUpdated }) => {
	const theme = useTheme()

	return (
		<div style={{ position: 'relative', width: '100%', transform: 'translate(0px, -45px)', marginBottom: '45px' }}>
			<Paper
				elevation={UI.PAPER_ELEVATION}
				square={true}
				style={{
					minHeight: '350px',
					backgroundColor: 'white'
				}}
			>
				<div style={{ padding: '0 3%' }}>
					<Grid container direction="column" style={{ color: 'black' }}>
						{userDetails && (
							<UploadImage
								entity="users"
								data={userDetails}
								canEdit={true}
								onImageUpdated={onImageUpdated}
							/>
						)}
						<Grid item style={{ width: '100%' }}>
							<Grid container justify="flex-end">
								<Grid item>
									<Button onClick={onEditClick}
										style={{  margin: '4px -16px 0 0'}}
										variant="text" 
										color="primary" 
										size="large">
											{intl.formatMessage({ id: 'app.profile.details.buttonText' })}
										<Pencil viewBox="-3 -3 30 30" />
									</Button>
								</Grid>
							</Grid>
						</Grid>

						<Grid item style={{ paddingTop: '16px' }}>
							<Grid container direction="row" style={{ alignItems: 'center' }}>
								<Grid item style={{ flex: 1 }}>
									<Grid
										container
										direction="column"
										style={{
											alignItems: 'center',
											textAlign: 'center',
											paddingBottom: '32px',
											borderBottom: '1px solid #ECECEC'
										}}
									>
										<Grid item>
											<Typography
												variant="h2"
												style={{
													letterSpacing: 0.25,
													fontWeight: '400',
													wordBreak: 'break-word',
													maxWidth: '100%'
												}}
											>
												{userDetails && userDetails.firstName}{' '}
												{userDetails && userDetails.lastName}{' '}
												{userDetails && userDetails.credentials}{' '}
											</Typography>
										</Grid>
										<Grid item>
											<Typography variant="body1" style={{ fontWeight: '700' }}>
												{userDetails && userDetails.role && userDetails.role.roleName}{' '}
												{userDetails && userDetails.email && !userDetails.lastLogin && `(${intl.formatMessage({ id: 'app.general.pending' })})`}
											</Typography>
										</Grid>
										<Grid item>
											<Typography variant="body1">{userDetails && userDetails.email} </Typography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid container direction='row' spacing={3} style={{ width: '100%', margin: '0', alignitems: 'center', paddingTop: '32px', paddingBottom: '32px' }}>
								<Grid item xs={12} md={3} style={{ display: 'flex' }}>
									<Grid item>
										<div style={{ padding: '20px 24px 0 0', height: '95px' }}>
											{userDetails && (
												<UploadTeamLogo
													data-testid="user-profile-header-upload-team-logo"
													data={userDetails}
													canEdit={true}
													onImageUpdated={onLogoUpdated}
													height={80}
													width={80}
													translate={-20}
												/>
											)}
										</div>
									</Grid>
									<Grid item>
											{userDetails &&
											userDetails.team && (
												<Typography
												variant='body1'
												style={{
													fontWeight: '700',
													fontSize: '20px',
													lineHeight: '25px',
													letterSpacing: '0.25px'
												}}>
													{userDetails.team.teamName}
												</Typography>
											)}
											{/* {userDetails &&
											!userDetails.team && (
												<Typography variant="body1" style={{ fontWeight: '700' }}>
													TODO: Team Name goes here but it needs to truncate at some point (3 lines
													on Desktop)
												</Typography>
											)} */}
									</Grid>
								</Grid>
								<Grid item xs={12} md={3}>
									{userDetails &&
									userDetails.team &&
									(userDetails.team.streetAddress ||
										userDetails.team.countryName ||
										userDetails.team.city ||
										userDetails.team.state ||
										userDetails.team.postalCode) && (
										<Typography variant="body2">
											{userDetails.team.streetAddress} {userDetails.team.city} {' '}
											{userDetails.team.state} {userDetails.team.countryName} {' '}
											{userDetails.team.postalCode} {' '}
										</Typography>
									)}
									{userDetails &&
									userDetails.team &&
									(!userDetails.team.streetAddress &&
										!userDetails.team.country &&
										!userDetails.team.city &&
										!userDetails.team.state &&
										!userDetails.team.postalCode) && (
										<Typography variant="body2">
											Option to add a address that appears on Patient Reports
										</Typography>
									)}
									<Typography variant="subtitle2" style={{ opacity: 0.38, letterSpacing: '2px' }}>
										{intl.formatMessage({ id: 'app.profile.details.address' })}
									</Typography>
								</Grid>
								<Grid item xs={12} md={2}>
									{userDetails &&
									userDetails.team &&
									userDetails.team.phoneNumber && (
										<Typography variant="body2">{userDetails.team.phoneNumber}</Typography>
									)}
									{userDetails &&
									(!userDetails.team || !userDetails.team.phoneNumber) && (
										<Typography variant="body2">
											Option to add a phone number that appears on Patient Reports
										</Typography>
									)}
									<Typography variant="subtitle2" style={{ opacity: 0.38, letterSpacing: '2px' }}>
										{intl.formatMessage({ id: 'app.profile.details.phoneNumber' })}
									</Typography>
								</Grid>
								<Grid item xs={12} md={2}>
									{userDetails &&
									userDetails.jurisdiction && (
										<Typography
											variant='body2'
											style={{ fontSize: '14px', lineHeight: '20px', letterSpacing: '0.24px' }}>
											{userDetails.jurisdiction}
										</Typography>
									)}
									<Typography
										variant='subtitle2'
										style={{
											opacity: 0.38,
											fontSize: '13px',
											lineHeight: '16px',
											letterSpacing: '2.08px',
											marginTop: '3px'
										}}>
										{intl.formatMessage({
											id: 'app.general.jurisdictionLabel'
										})}
									</Typography>
								</Grid>
								<Grid item xs={12} md={2}>
									{userDetails &&
									(
										<Typography
											variant='body2'
											style={{ fontSize: '14px', lineHeight: '20px', letterSpacing: '0.24px' }}>
											{`${userDetails.timeZoneName} (UTC ${userDetails.timeZoneOffset < 0 ? userDetails.timeZoneOffset : '+' + userDetails.timeZoneOffset})`}
										</Typography>
									)}
									<Typography
										variant='subtitle2'
										style={{
											opacity: 0.38,
											fontSize: '13px',
											lineHeight: '16px',
											letterSpacing: '2.08px',
											marginTop: '3px'
										}}>
										{intl.formatMessage({
											id: 'app.general.timeZoneLabel'
										})}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>
			</Paper>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
	onEditClick: () => dispatch(showModal(UpdateUserProfile))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withStyles(styles))

export default enhance(Header)
