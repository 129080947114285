import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { injectIntl } from 'react-intl'

const MemberLanguageAbbreviation = ({ member, intl }) => (
	<Grid container>
		<Grid item>
			<Typography data-testid="member-2fa-state" variant="body2" style={{ textTransform: 'uppercase', fontWeight: '400' }}>
				{
					member && member.language
                }
			</Typography>
		</Grid>
	</Grid>
)

export default injectIntl(MemberLanguageAbbreviation)
