import React, { useState, useEffect } from 'react'
import { Field, reduxForm } from 'redux-form'
import MuiTextField from '@material-ui/core/TextField'
import { withStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
	root: {
		color: 'black', 
		textOverflow: 'ellipsis'
	},
	label: {
		color: 'black',
		marginLeft: '-3px'
	},
	instructionTextRoot: {
		color: 'black',
		marginLeft: '-1px'
	},
	instructionTextLabel: {
		color: 'black',
		marginLeft: '-2px'
	},
	errorContainer: {
		color: 'red',
		backgroundColor: 'transparent'
	}
})

const TextField = ({
	classes,
	label,
	input,
	meta: { touched, invalid, error },
	inputLabelProps,
	inputProps,
	style,
	fullWidth,
	margin,
	variant,
	type,
	labelStyles,
	inputStyles,
	maxLength,
	disabled,
	backgroundColor,
	fontColor,
	onChange,
	defaultValue,
	initialValue,
	...custom
}) => {
	const [ value, setValue ] = useState(initialValue)
	console.log({ ...custom })
	useEffect(
		() => {
			if (initialValue) {
				setValue(initialValue)
			}
		},
		[ initialValue ]
	)
	return (
		<div className="global-input-wrapper">
			<MuiTextField
				label={label}
				placeholder={label}
				error={touched && invalid}
				helperText={touched && error}
				onChange={onChange}
				{...input}
				margin='dense'
				classes={{
					root: classes.root
				}}
				InputProps={{
					classes: {
						root: classes.root,
						input: classes.root
					}
				}}
				InputLabelProps={{
					classes: {
						root: classes.label,
						focused: classes.label
					}
				}}
				disabled={disabled}
				FormHelperTextProps={{
					classes: {
						contained: classes.errorContainer,
						error: classes.errorContainer
					}
				}}
				inputProps={{ maxLength: maxLength }}
				fullWidth
				margin={margin}
				variant={variant}
				{...custom}
				type={type}
				shrink='true'
			/>
		</div>
	)
}

const FormTextField = ({
	label,
	name,
	inputLabelProps,
	inputProps,
	style,
	fullWidth,
	margin,
	variant,
	type,
	validators,
	disabled,
	maxLength,
	backgroundColor = 'rgb(36, 48, 65)',
	fontColor = 'white',
	onChange,
	...rest
}) => {
	let classes = useStyles()
	return (
		<Field
			name={name}
			component={TextField}
			label={label}
			inputLabelProps={inputLabelProps}
			inputProps={inputProps}
			style={style}
			fullWidth={fullWidth}
			margin={margin}
			variant={variant}
			type={type}
			validate={validators}
			maxLength={maxLength}
			classes={classes}
			disabled={disabled}
			fontColor={fontColor}
			backgroundColor={backgroundColor}
			onChange={onChange}
			{...rest}
		/>
	)
}

export default FormTextField
