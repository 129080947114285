import history from '../utilities/history'
import { authenticatedFetch } from './base'

export const search = (searchPhrase, offset) => {
	var url = `/api/import`
	let urlHasParams = false

	if (searchPhrase && searchPhrase.length > 0) {
		url = `${url}?searchPhrase=${encodeURIComponent(searchPhrase)}`
		urlHasParams = true
	}

	if(offset){
		url += `${urlHasParams ? '&' : '?'}offset=${offset}`
		urlHasParams = true
	}

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const getNewCompletedImports = () => {
	return authenticatedFetch(`/api/import/getNewCompletedImports`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const clearNewCompletedImports = () => {
	return authenticatedFetch(`/api/import/clearNewCompletedImports`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const addImport = (value) => {
	let sharedCircles = value.sharedCircles
	if (typeof(sharedCircles) == 'string') {
		value.sharedCircles = JSON.parse(sharedCircles)
	}

	return authenticatedFetch(`/api/import`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(value)
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchImportFileHeaders = (importId) => {
	return authenticatedFetch(`/api/import/${importId}/getImportFileHeaders`, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchImportDetails = (importId) => {
	return authenticatedFetch(`/api/import/${importId}/details`, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchBucketUrl = () => {
	return authenticatedFetch(`/api/file/bucketUrl`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchPreviewData = (file) => {
	var model = new FormData()
	model.append("file", file.file)

	return authenticatedFetch(
		`/api/import/previewData`,
		{
			method: 'post',
			body: model
		})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchAddData = (importId, file) => {
	var model = new FormData()
	model.append("importId", importId)
	model.append("file", file.file)

	return authenticatedFetch(
		`/api/import/addData`,
		{
			method: 'post',
			body: model
		})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const addImportMapping = (id, values) => {
	return authenticatedFetch(`/api/import/${id}/saveMapping`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(values)
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchLastPatientMapping = (importId) => {
	return authenticatedFetch(`/api/import/${importId}/getLastPatientMapping`, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchLastCaseMapping = (importId) => {
	return authenticatedFetch(`/api/import/${importId}/getLastCaseMapping`, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchLastSurveyMapping = (importId) => {
	return authenticatedFetch(`/api/import/${importId}/getLastSurveyMapping`, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchRedirect = (importId) => {
	return authenticatedFetch(
		`/api/import/${importId}/redirect`,
		{
			method: 'get'
		})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const confirmImport = (id) => {
	return authenticatedFetch(`/api/import/${id}/confirm`, {
		method: 'post',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchImportLogs = (importId) => {
	return authenticatedFetch(`/api/import/${importId}/logs`, { method: 'GET' })
		.then(function (response) {
			if (response.status === 200) {
				const header = response.headers.get("content-disposition")
				if(!header) {
					return { isSuccessful: false }
				}
				
				const fileName = header.split('"')[1];
				return response.blob().then(blob => [fileName, blob])
			}
		})
		.then(function ([filename, blob]) {
			try {
				var element = document.createElement('a')
				element.setAttribute('href', URL.createObjectURL(blob))
				element.setAttribute('download', filename)

				element.style.display = 'none'
				document.body.appendChild(element)

				element.click()

				document.body.removeChild(element)
				return { isSuccessful: true }
			} catch (e) {
				console.error(e)
				return { isSuccessful: false }
			}
		})
}

export const cancelImport = (id) => {
	return authenticatedFetch(`/api/import/${id}/cancel`, {
		method: 'post',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchCanUpdateStatus = (importId) => {
	return authenticatedFetch(
		`/api/import/${importId}/canUpdateStatus`,
		{
			method: 'get'
		})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchImportStatistic = (importId) => {
	return authenticatedFetch(
		`/api/import/${importId}/statistic`,
		{
			method: 'get'
		})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

