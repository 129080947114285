import React from 'react'
import { TableHead } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import { compose } from 'recompose'
import withStyles from '@material-ui/core/styles/withStyles'
import QuestionHeader from './QuestionHeader'
import QuestionRow from './QuestionRow'
import TableBody from '@material-ui/core/TableBody'
import { CircularProgress } from '@material-ui/core'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { connect } from 'react-redux'
import { searchAdditionalQuestions } from '../../../redux/actions'
import { hasMoreQuestionsSelector, isLoadingQuestionsSelector, questionsSearchTermSelector } from '../../../redux/selectors'

const styles = {
	root: {
		background: 'black'
	},
	input: {
		color: 'white',
		backgroundColor: 'rgb(36, 48, 65)'
	}
}

const QuestionTable = ({ data, user, search, hasMore, getQuestions, loading }) => {

	const getAdditionalQuestions = () => {
		getQuestions(search, data.length)
	}

	const [sentryRef] = useInfiniteScroll({
		loading,
		hasNextPage: hasMore,
		onLoadMore: getAdditionalQuestions,
		delayInMs: 1000
	});

	return (
		<div className="admin-table-fullheight">
		<Table data-testid="question-table" stickyHeader>
			<TableHead>
				<QuestionHeader />
			</TableHead>
			<TableBody style={{ borderTop: 'none' }}>
				{data && data.map((question) => <QuestionRow question={question} user={user} key={question.id} />)}
			</TableBody>
		</Table>
		{data && data.length > 0 && (loading || hasMore) && (
			<div className="admin-table-progress" ref={sentryRef}>
				<CircularProgress />
			</div>
		)}
	</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	loading: isLoadingQuestionsSelector(state),
	hasMore: hasMoreQuestionsSelector(state),
	search: questionsSearchTermSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	getQuestions: (search, offset) => dispatch(searchAdditionalQuestions(search, offset)),
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))

export default enhance(QuestionTable)
