import React, { useEffect } from 'react'
import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

export default ({ circle, setHeightCallback }) => {
	let ref = React.createRef()
	const intl = useIntl()
	useEffect(
		() => {
			if (setHeightCallback && ref.current) {
				if (ref.current.offsetHeight < 64) {
					setHeightCallback(64)
				} else {
					setHeightCallback(ref.current.offsetHeight)
				}
			}
		},
		[ ref ]
	)

	return (
		<Grid container justify="flex-start" ref={ref} id={circle.id} alignContent="flex-start" alignItems="flex-start">
			<Grid item style={{ minWidth: '200px' }}>
				<Grid container direction="column" style={{ minWidth: '200px' }}>
					<Grid item>
						<Link
							to={`/circles/${circle.id}`}
							style={{
								display: 'block',
								textDecoration: 'none',
								fontWeight: '700',
								fontSize: '17px',
								lineHeight: '20px',
								letterSpacing: '0.16px',
								color: '#011020',
								
							}}
						>
							<span style={{ display: 'block', marginBottom: '5px' }}>
								{circle && circle.name && circle.name.length > 200 ? (
									circle.name.substring(0, 200) + '...'
								) : (
									circle.name
								)}
							</span>
							<Typography
								variant="body2"
								style={{
									textAlign: 'left',
									fontSize: '12px',
									letterSpacing: 0.16,
									lineHeight: '16px',
									wordBreak: 'break-word',
									color: '#011020'
								}}
							>
								{circle && circle.isSponsored ? (
									intl.formatMessage({ id: 'app.protocol.surveys.sponsored' })
								) : (
									intl.formatMessage({ id: 'app.circle.founded' })
								)}{' '}
								{circle &&
								circle.circleFirstName + ' ' + circle.circleLastName &&
								(circle.circleFirstName + ' ' + circle.circleLastName).length > 200 ? (
									(circle.circleFirstName + ' ' + circle.circleLastName).substring(0, 200) + '...'
								) : (
									circle.circleFirstName + ' ' + circle.circleLastName
								)}
							</Typography>
						</Link>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}
