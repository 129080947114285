import React from 'react'
import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

export default ({ circle }) => {
	return (
		<Grid container justify="flex-start" id={circle.id} style={{ minWidth: '300px' }}>
			<Grid item style={{ minWidth: '200px' }}>
				<Grid container direction="column" style={{ minWidth: '200px' }}>
					<Grid item style={{ maxWidth: '600px' }}>
						<Typography variant="body2" style={{ textAlign: 'left', wordBreak: 'break-word' }}>
							{circle &&
							circle.observationalProtocolName &&
							circle.observationalProtocolName.length > 200 ? (
								circle.observationalProtocolName.substring(0, 200) + '...'
							) : (
								circle.observationalProtocolName
							)}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}
