import React from 'react'

export default ({ width = 32, height = 32 }) => (
	<svg
		version="1.1"
		id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		x="0px"
		y="0px"
		viewBox="0 0 72 72"
		width={`${width}px`}
		height={`${height}px`}
	>
		<g>
			<linearGradient
				id="SVGID_1_"
				gradientUnits="userSpaceOnUse"
				x1="76.4948"
				y1="36.3204"
				x2="-0.599"
				y2="36.3204"
			>
				<stop offset="1.873364e-02" style={{ 'stop-color': 'A6C1E5' }} />
				<stop offset="0.3088" style={{ 'stop-color': '699DD4' }} />
				<stop offset="0.5385" style={{ 'stop-color': '3165B0' }} />
				<stop offset="0.789" style={{ 'stop-color': '001942' }} />
				<stop offset="0.9958" style={{ 'stop-color': '000000' }} />
			</linearGradient>
			<rect x="22.5" y="27.9" class="st0" width="5" height="16.8" />
			<linearGradient
				id="SVGID_2_"
				gradientUnits="userSpaceOnUse"
				x1="76.4948"
				y1="36.3203"
				x2="-0.599"
				y2="36.3203"
			>
				<stop offset="1.873364e-02" style={{ 'stop-color': 'A6C1E5' }} />
				<stop offset="0.3088" style={{ 'stop-color': '699DD4' }} />
				<stop offset="0.5385" style={{ 'stop-color': '3165B0' }} />
				<stop offset="0.789" style={{ 'stop-color': '001942' }} />
				<stop offset="0.9958" style={{ 'stop-color': '000000' }} />
			</linearGradient>
			<rect x="7.6" y="16" class="st1" width="5" height="40.7" />
			<linearGradient
				id="SVGID_3_"
				gradientUnits="userSpaceOnUse"
				x1="76.4948"
				y1="36.3204"
				x2="-0.599"
				y2="36.3204"
			>
				<stop offset="1.873364e-02" style={{ 'stop-color': 'A6C1E5' }} />
				<stop offset="0.3088" style={{ 'stop-color': '699DD4' }} />
				<stop offset="0.5385" style={{ 'stop-color': '3165B0' }} />
				<stop offset="0.789" style={{ 'stop-color': '001942' }} />
				<stop offset="0.9958" style={{ 'stop-color': '000000' }} />
			</linearGradient>
			<rect x="15" y="23.2" class="st2" width="5" height="26.3" />
			<linearGradient
				id="SVGID_4_"
				gradientUnits="userSpaceOnUse"
				x1="76.4948"
				y1="36.3204"
				x2="-0.599"
				y2="36.3204"
			>
				<stop offset="1.873364e-02" style={{ 'stop-color': 'A6C1E5' }} />
				<stop offset="0.3088" style={{ 'stop-color': '699DD4' }} />
				<stop offset="0.5385" style={{ 'stop-color': '3165B0' }} />
				<stop offset="0.789" style={{ 'stop-color': '001942' }} />
				<stop offset="0.9958" style={{ 'stop-color': '000000' }} />
			</linearGradient>
			<rect x="37.4" y="27.9" class="st3" width="5" height="16.8" />
			<linearGradient
				id="SVGID_5_"
				gradientUnits="userSpaceOnUse"
				x1="76.4948"
				y1="36.3204"
				x2="-0.599"
				y2="36.3204"
			>
				<stop offset="1.873364e-02" style={{ 'stop-color': 'A6C1E5' }} />
				<stop offset="0.3088" style={{ 'stop-color': '699DD4' }} />
				<stop offset="0.5385" style={{ 'stop-color': '3165B0' }} />
				<stop offset="0.789" style={{ 'stop-color': '001942' }} />
				<stop offset="0.9958" style={{ 'stop-color': '000000' }} />
			</linearGradient>
			<rect x="29.9" y="32.8" class="st4" width="5" height="7" />
			<linearGradient
				id="SVGID_6_"
				gradientUnits="userSpaceOnUse"
				x1="76.4948"
				y1="36.3204"
				x2="-0.599"
				y2="36.3204"
			>
				<stop offset="1.873364e-02" style={{ 'stop-color': 'A6C1E5' }} />
				<stop offset="0.3088" style={{ 'stop-color': '699DD4' }} />
				<stop offset="0.5385" style={{ 'stop-color': '3165B0' }} />
				<stop offset="0.789" style={{ 'stop-color': '001942' }} />
				<stop offset="0.9958" style={{ 'stop-color': '000000' }} />
			</linearGradient>
			<rect x="0.1" y="20.9" class="st5" width="5" height="30.8" />
			<linearGradient
				id="SVGID_7_"
				gradientUnits="userSpaceOnUse"
				x1="76.4948"
				y1="36.3203"
				x2="-0.599"
				y2="36.3203"
			>
				<stop offset="1.873364e-02" style={{ 'stop-color': 'A6C1E5' }} />
				<stop offset="0.3088" style={{ 'stop-color': '699DD4' }} />
				<stop offset="0.5385" style={{ 'stop-color': '3165B0' }} />
				<stop offset="0.789" style={{ 'stop-color': '001942' }} />
				<stop offset="0.9958" style={{ 'stop-color': '000000' }} />
			</linearGradient>
			<rect x="52.3" y="16" class="st6" width="5" height="40.7" />
			<linearGradient
				id="SVGID_8_"
				gradientUnits="userSpaceOnUse"
				x1="76.4948"
				y1="36.3204"
				x2="-0.599"
				y2="36.3204"
			>
				<stop offset="1.873364e-02" style={{ 'stop-color': 'A6C1E5' }} />
				<stop offset="0.3088" style={{ 'stop-color': '699DD4' }} />
				<stop offset="0.5385" style={{ 'stop-color': '3165B0' }} />
				<stop offset="0.789" style={{ 'stop-color': '001942' }} />
				<stop offset="0.9958" style={{ 'stop-color': '000000' }} />
			</linearGradient>
			<rect x="44.8" y="23.2" class="st7" width="5" height="26.3" />
			<linearGradient
				id="SVGID_9_"
				gradientUnits="userSpaceOnUse"
				x1="76.4948"
				y1="38.5532"
				x2="-0.599"
				y2="38.5532"
			>
				<stop offset="1.873364e-02" style={{ 'stop-color': 'A6C1E5' }} />
				<stop offset="0.3088" style={{ 'stop-color': '699DD4' }} />
				<stop offset="0.5385" style={{ 'stop-color': '3165B0' }} />
				<stop offset="0.789" style={{ 'stop-color': '001942' }} />
				<stop offset="0.9958" style={{ 'stop-color': '000000' }} />
			</linearGradient>
			<rect x="59.4" y="25.4" class="st8" width="5" height="26.3" />
			<linearGradient
				id="SVGID_10_"
				gradientUnits="userSpaceOnUse"
				x1="76.4948"
				y1="38.5532"
				x2="-0.599"
				y2="38.5532"
			>
				<stop offset="1.873364e-02" style={{ 'stop-color': 'A6C1E5' }} />
				<stop offset="0.3088" style={{ 'stop-color': '699DD4' }} />
				<stop offset="0.5385" style={{ 'stop-color': '3165B0' }} />
				<stop offset="0.789" style={{ 'stop-color': '001942' }} />
				<stop offset="0.9958" style={{ 'stop-color': '000000' }} />
			</linearGradient>
			<rect x="66.9" y="30.1" class="st9" width="5" height="16.8" />
		</g>
	</svg>
)
