import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { uuidv4 } from '../../../utilities/utilities'
import AnswerContainer from './AnswerContainer'
import { ListContainer } from '../../../utilities/linkedList'
import DefaultFormTextField from '../../DefaultFormTextField'
import { formValueSelector } from 'redux-form'
import { required } from '../../../utilities/validators'
import { useStore } from 'react-redux'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'
import { useIntl } from 'react-intl'
import FormCheckBox from '../../FormCheckBox'
import PIDataToggle from '../../PIDataToggle'

const selector = formValueSelector('createQuestion')

const requiredValidator = required

export default forwardRef((props, ref) => {
	const [ answers, setAnswers ] = useState()
	const intl = useIntl()
	const [ questionText, setQuestionText ] = useState('')
	const [ instructionText, setInstructionText ] = useState('')
	const [ answerList, setAnswerList ] = useState(new ListContainer())
	const [ isCodeQuestion, setIsCodeQuestion ] = useState(false)
	const store = useStore()
	const dispatch = useDispatch()
	const { showScoreEntry } = props

	useImperativeHandle(ref, () => ({
		getQuestion() {
			let a = []
			if (answers) {
				let i = 0
				answerList.getList().traverse((r) => {
					let answer = r.data.ref.current.getAnswer()
					if (answer && answer.answer) {
						a.push({ answer, sortOrder: i })
						i++
					}
				})
			}

			return {
				id: uuidv4().replace('-', ''),
				title: selector(store.getState(), 'question'),
				type: 'multi',
				instructions: selector(store.getState(), 'instructions'),
				tag: selector(store.getState(), 'tag'),
				answers: a,
				isOptional: selector(store.getState(), 'isOptional'),
				isPiData: selector(store.getState(), 'isPiData'),
                isConsentRequired: selector(store.getState(), 'isConsentRequired'),
				isCodeQuestion: selector(store.getState(), 'isCodeQuestion'),
				questionCode: selector(store.getState(), 'questionCode'),
				isDropdown: selector(store.getState(), 'isDropdown'),
				displayInstructionAsIcon: selector(store.getState(), 'displayInstructionAsIcon'),
			}
		}
	}))

	const onDeleteAnswerClick = (answer) => {
		if (answer) {
			let x = answer.data.id
			answerList.getList().remove(answer)
			setAnswerList(answerList)
			var a = answers.filter((r) => r.data.id !== x)

			setAnswers(a)
		}
	}

	const swapAnswer = (nodeA, nodeB) => {
		if (answerList) {
			answerList.getList().swap(nodeA, nodeB)

			let questionAnswers = new Array(0)

			answerList.getList().traverse((r) => {
				questionAnswers.push(r)
			})
			setAnswerList(answerList)
			setAnswers(questionAnswers)
		}
	}

	useEffect(() => {
		onAddNewAnswer()
	}, [])

	const onAddNewAnswer = () => {
		let answer = {}
		answer.id = uuidv4().replace('-', '')

		if (answerList) {
			answerList.getList().append(answer)
			let questionAnswers = new Array(0)

			answerList.getList().traverse((r) => {
				questionAnswers.push(r)
			})
			setAnswerList(answerList)

			setAnswers(questionAnswers)
		}
	}

	useEffect(() => {
		var el = document.getElementsByName('tag')
		if (el != null) {
			el[0].focus()
		}
	}, [])
	return (
		<Grid container direction="column" alignItems="flex-start">
			<Grid item style={{ width: '100%' }}>
				<PIDataToggle defaultValue={false} onChange={(value) => dispatch(change('createQuestion', 'isPiData', value))}/>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				<DefaultFormTextField
					label="Tag"
					variant="filled"
					name="tag"
					validators={[ requiredValidator ]}
					fullWidth
					inputProps={{ maxLength: 254 }}
					maxLength={254}
				/>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				<DefaultFormTextField
					label="Question"
					variant="filled"
					fullWidth
					name="question"
					validators={[ requiredValidator ]}
					inputProps={{ maxLength: 1023 }}
					maxLength={1023}
				/>
			</Grid>

			<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
				<DefaultFormTextField
					label="Instructions"
					variant="filled"
					fullWidth
					name="instructions"
					inputProps={{ maxLength: 2047 }}
					multiline
					maxLength={2047}
					rows="3"
				/>
			</Grid>
			<FormCheckBox
				name="displayInstructionAsIcon"
				color="primary"
				label={intl.formatMessage({ id: 'app.survey.questions.displayInstructionAsIcon' })}
			/>
			<FormCheckBox
				name="isOptional"
				color="primary"
				label={intl.formatMessage({ id: 'app.survey.questions.isOptional' })}
			/>
			<Grid item style={{ width: '100%' }}>
				<FormCheckBox
					name="isDropdown"
					color="primary"
					label={intl.formatMessage({ id: 'app.survey.questions.displayAsDropdown' })}
				/>
			</Grid>
			<FormCheckBox
				name="isCodeQuestion"
				onChange={e => setIsCodeQuestion(e.target.checked)}
				color="primary"
				label={intl.formatMessage({ id: 'app.survey.questions.isCodeQuestion' })}
			/>
            {/* <FormCheckBox
                name="isConsentRequired"
                color="primary"
                label={intl.formatMessage({ id: 'app.survey.questions.isConsentRequired.checkBox' })}
            /> */}
			{isCodeQuestion && <Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
				<DefaultFormTextField
					label={intl.formatMessage({ id: 'app.survey.questions.questionCodeLabel' })}
					variant="filled"
					fullWidth
					name="questionCode"
					inputProps={{ maxLength: 255 }}
					maxLength={255}
				/>
			</Grid>}
			<Grid item>
				<Typography variant="h4" style={{ fontWeight: '400', paddingBottom: '10px', paddingTop: '10px' }}>
					Multiple Answers
				</Typography>
			</Grid>

			{answers &&
				answers.map((r, i) => {
					return (
						<Grid key={r.data.id} item style={{ width: '100%', padding: '10px 0 10px 30px ' }}>
							<AnswerContainer
								key={r.data.id}
								answer={r}
								showScoreEntry={showScoreEntry}
								onDeleteAnswerCallback={onDeleteAnswerClick}
								onSwapRequestedCallback={swapAnswer}
								showCode={isCodeQuestion}
							/>
						</Grid>
					)
				})}

			{answers &&
			answers.length < 100 && (
				<Grid item style={{ width: '100%', paddingTop: '10px', alignSelf: 'center' }}>
					<Button variant="outlined" fullWidth color="secondary" onClick={onAddNewAnswer}>
						Add Another Answer
					</Button>
				</Grid>
			)}
		</Grid>
	)
})
