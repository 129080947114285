import * as payment from '../../../apis/payment'
import { createSelector } from 'reselect'
import * as cookies from '../../../utilities/cookies'
import { hideModal } from '../core'

const FETCH_STRIPE_PUBLISHABLE_KEY_REQUESTED = 'FETCH_STRIPE_PUBLISHABLE_KEY_REQUESTED'
const FETCH_STRIPE_PUBLISHABLE_KEY_SUCCESS = 'FETCH_STRIPE_PUBLISHABLE_KEY_SUCCESS'
const FETCH_STRIPE_PUBLISHABLE_KEY_FAILED = 'FETCH_STRIPE_PUBLISHABLE_KEY_FAILED'

export const fetchStripePublishableKey = () => (dispatch) => {
	dispatch({ type: FETCH_STRIPE_PUBLISHABLE_KEY_REQUESTED, data: {} })
	payment.fetchStripePublishableKey().then((stripePublishableKey) => {
		if (stripePublishableKey) {
			return dispatch({ type: FETCH_STRIPE_PUBLISHABLE_KEY_SUCCESS, data: stripePublishableKey })
		} else {
			return dispatch({ type: FETCH_STRIPE_PUBLISHABLE_KEY_FAILED, data: {} })
		}
	})
	return
}

const FETCH_CLIENT_SECRET_REQUESTED = 'FETCH_CLIENT_SECRET_REQUESTED'
const FETCH_CLIENT_SECRET_SUCCESS = 'FETCH_CLIENT_SECRET_SUCCESS'
const FETCH_CLIENT_SECRET_FAILED = 'FETCH_CLIENT_SECRET_FAILED'

export const fetchStripeIntent = () => (dispatch) => {
	dispatch({ type: FETCH_CLIENT_SECRET_REQUESTED, data: {} })
	payment.fetchStripeIntent().then((stripeIntent) => {
		if (stripeIntent) {
			return dispatch({ type: FETCH_CLIENT_SECRET_SUCCESS, data: stripeIntent })
		} else {
			return dispatch({ type: FETCH_CLIENT_SECRET_FAILED, data: {} })
		}
	})
	return
}

const CREATE_PAYMENT_METHOD_REQUESTED = 'CREATE_PAYMENT_METHOD_REQUESTED'
const CREATE_PAYMENT_METHOD_SUCCESS = 'CREATE_PAYMENT_METHOD_SUCCESS'
const CREATE_PAYMENT_METHOD_FAILED = 'CREATE_PAYMENT_METHOD_FAILED'

export const createPaymentMethod = (payment_method) => (dispatch) => {
	dispatch({ type: CREATE_PAYMENT_METHOD_REQUESTED, data: {} })
	payment.createPaymentMethod(payment_method).then((response) => {
		if (response) {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.checkout.saveCardSuccess', isSuccess: true }
			})
			return dispatch({ type: CREATE_PAYMENT_METHOD_SUCCESS, data: { response } })
		} else {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.checkout.saveCardError', isSuccess: false }
			})
			return dispatch({ type: CREATE_PAYMENT_METHOD_FAILED, data: {} })
		}
	})
	return
}

const FETCH_PAYMENT_METHOD_REQUESTED = 'FETCH_PAYMENT_METHOD_REQUESTED'
const FETCH_PAYMENT_METHOD_SUCCESS = 'FETCH_PAYMENT_METHOD_SUCCESS'
const FETCH_PAYMENT_METHOD_FAILED = 'FETCH_PAYMENT_METHOD_FAILED'

export const fetchPaymentMethod = () => (dispatch) => {
	dispatch({ type: FETCH_PAYMENT_METHOD_REQUESTED, data: {} })
	payment.fetchPaymentMethod().then((paymentMethod) => {
		if (paymentMethod) {
			return dispatch({ type: FETCH_PAYMENT_METHOD_SUCCESS, data: paymentMethod })
		} else {
			return dispatch({ type: FETCH_PAYMENT_METHOD_FAILED, data: {} })
		}
	})
	return
}

const SUBMIT_PAYMENT_REQUESTED = 'SUBMIT_PAYMENT_REQUESTED'
const SUBMIT_PAYMENT_SUCCESS = 'SUBMIT_PAYMENT_SUCCESS'
const SUBMIT_PAYMENT_FAILED = 'SUBMIT_PAYMENT_FAILED'

export const submitPayment = (userCount, caseCount, circleId) => (dispatch) => {
	dispatch({ type: SUBMIT_PAYMENT_REQUESTED, data: {} })
	payment.submitPayment(userCount, caseCount, circleId).then((payment) => {
		if (payment) {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.checkout.paymentSuccess', isSuccess: true }
			})
			return dispatch({ type: SUBMIT_PAYMENT_SUCCESS, data: payment })
		} else {
			return dispatch({ type: SUBMIT_PAYMENT_FAILED, data: {} })
		}
	})
	return
}

// used to re-init payment for multiple processings in one session
const RESET_PAYMENT = 'RESET_PAYMENT'
export const resetPaymentState = () => (dispatch) => {
	dispatch({ type: RESET_PAYMENT, data: {} })
}

const initial = {
	stripePublishableKey: null,
	stripeIntent: null,
	awaitingPaymentMethod: true,
	paymentMethod: null,
	payment: null
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_STRIPE_PUBLISHABLE_KEY_SUCCESS:
			return { ...state, stripePublishableKey: action.data }
		case FETCH_CLIENT_SECRET_SUCCESS:
			return { ...state, stripeIntent: action.data }
		case FETCH_PAYMENT_METHOD_SUCCESS:
			return { ...state, paymentMethod: action.data, awaitingPaymentMethod: false }
		case FETCH_PAYMENT_METHOD_FAILED:
			return { ...state, paymentMethod: 0, awaitingPaymentMethod: false }
		case CREATE_PAYMENT_METHOD_SUCCESS:
			return { ...state, paymentMethod: action.data.response, awaitingPaymentMethod: false }
		case SUBMIT_PAYMENT_SUCCESS:
			return { ...state, payment: action.data }
		case RESET_PAYMENT:
			return { ...state, payment: false, awaitingPaymentMethod: true }
		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}

export const stripeIntentSelector = (state) => state.payment.stripeIntent
export const paymentMethodSelector = (state) => state.payment.paymentMethod
export const stripePublishableKeySelector = (state) => state.payment.stripePublishableKey
export const paymentSelector = (state) => state.payment.payment
export const awaitingPaymentMethodSelector = (state) => state.payment.awaitingPaymentMethod
