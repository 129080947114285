import React, { useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import MuiMenu from '@material-ui/core/Menu'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { useDispatch } from 'react-redux'
import { archiveSponsor, resetSponsorSubscription, showModal, unarchiveSponsor } from '../../../redux/actions'
import { MenuItem } from '@material-ui/core'
import { useIntl } from 'react-intl'
import ModifySponsorModal from './ModifySponsorModal'
import CreateSubscriptionModal from './CreateSubscriptionModal'
import * as api from '../../../apis/sponsors'

const ITEM_HEIGHT = 48

export default ({ sponsor, showResetButton }) => {
	const [ anchorEl, setAnchorEl ] = useState(null)
	const open = Boolean(anchorEl)
	const dispatch = useDispatch()
	const intl = useIntl()

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleArchiveClick = () => {
		if (sponsor) {
			dispatch(archiveSponsor(sponsor.id))
		}

		handleClose()
	}

	const handleUnarchiveClick = () => {
		if (sponsor) {
			dispatch(unarchiveSponsor(sponsor.id))
		}

        handleClose()
    }

	const handleEditClick = () => {
		if (sponsor) {
			dispatch(showModal(ModifySponsorModal,
				{
					id: sponsor.id,
					mode: 1
				}))
		}

		handleClose()
	}
	
	const handleCreateSubscriptionClick = () => {
		if (sponsor) {
			dispatch(showModal(CreateSubscriptionModal,
				{
					id: sponsor.id
				}))
		}

        handleClose()
	}
	
	const handleResetSubscriptionClick = () => {
		if (sponsor) {
			dispatch(resetSponsorSubscription(sponsor.id))
		}

        handleClose()
	}

	const handleGetUsageLinkClick = () => {
		api.getUsageLink(sponsor.id).then((response) => {
			if (response && response.link) {
                navigator.permissions.query({ name: 'clipboard-write' }).then((result) => {
                    if (result.state == 'granted' || result.state == 'prompt') {
                        navigator.clipboard.writeText(response.link).then(
                            function () {
                                return dispatch({
                                    type: 'SET_SNACKBAR_MESSAGE',
                                    data: { message: 'app.circles.generateLinkCopiedToClipboard', isSuccess: true }
                                })
                            },
                            function () {
                                /* clipboard write failed */
                            }
                        )
                    } else {
                        return dispatch({
                            type: 'SET_SNACKBAR_MESSAGE',
                            data: { message: 'app.circles.generateLinkAccessToClipboardIsDenied', isSuccess: false }
                        })
                    }
                }).catch(() => {
                    let successful = false;

                    try
                    {
                        var textArea = document.createElement("textarea");
                        textArea.value = response.link;

                        textArea.style.top = "0";
                        textArea.style.left = "0";
                        textArea.style.position = "fixed";

                        document.body.appendChild(textArea);

                        textArea.focus();
                        textArea.select();

                        successful = document.execCommand('copy');

                        document.body.removeChild(textArea);
                    }
                    catch (err)
                    {
                        console.error('Unable to copy', err);
                    }

                    if (successful) {
                        return dispatch({
                            type: 'SET_SNACKBAR_MESSAGE',
                            data: { message: 'app.circles.generateLinkCopiedToClipboard', isSuccess: true }
                        })
                    }
                    else
                    {
                        return dispatch({
                            type: 'SET_SNACKBAR_MESSAGE',
                            data: { message: 'app.circles.generateLinkFailedToGenerateLink', isSuccess: false }
                        })
                    }
                })

            } else {
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.circles.generateLinkFailedToGenerateLink', isSuccess: false }
				})
			}
		})

		handleClose()
	}

	return (
		<div>
			<IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
				<MoreVertIcon />
			</IconButton>
			<MuiMenu
				id="long-menu"
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 4.5,
						width: 200,
						backgroundColor: '#243041',
						color: 'white',
						fontWeight: '400'
					}
				}}
			>
				<MenuItem
						onClick={handleEditClick}
						style={{
							textDecoration: 'none',
							color: 'white',
							fontSize: '14px',
							lineHeight: '20px',
							letterSpacing: '0.24'
						}}
					>
						{intl.formatMessage({ id: 'app.sponsors.editMenuItem' })}
				</MenuItem>
				{sponsor.stripeCustomerId  && !sponsor.stripeSubscriptionId && <MenuItem
						onClick={handleCreateSubscriptionClick}
						style={{
							textDecoration: 'none',
							color: 'white',
							fontSize: '14px',
							lineHeight: '20px',
							letterSpacing: '0.24'
						}}
					>
						{intl.formatMessage({ id: 'app.sponsors.createSubscriptionMenuItem' })}
				</MenuItem>}
				{sponsor.stripeCustomerId  && sponsor.stripeSubscriptionId && showResetButton && <MenuItem
						onClick={handleResetSubscriptionClick}
						style={{
							textDecoration: 'none',
							color: 'white',
							fontSize: '14px',
							lineHeight: '20px',
							letterSpacing: '0.24'
						}}
					>
						{intl.formatMessage({ id: 'app.sponsors.resetSubscriptionMenuItem' })}
				</MenuItem>}
				{sponsor.isArchived &&
					<MenuItem
						onClick={handleUnarchiveClick}
						style={{
							textDecoration: 'none',
							color: 'white',
							fontSize: '14px',
							lineHeight: '20px',
							letterSpacing: '0.24'
						}}
					>
						{intl.formatMessage({ id: 'app.sponsors.unarchiveMenuItem' })}
					</MenuItem>
				}
				{!sponsor.isArchived &&
					<MenuItem
						onClick={handleArchiveClick}
						style={{
							textDecoration: 'none',
							color: 'white',
							fontSize: '14px',
							lineHeight: '20px',
							letterSpacing: '0.24'
						}}
					>
						{intl.formatMessage({ id: 'app.sponsors.archiveMenuItem' })}
					</MenuItem>
				}
				{!sponsor.isArchived &&
					<MenuItem
						onClick={handleGetUsageLinkClick}
						style={{
							textDecoration: 'none',
							color: 'white',
							fontSize: '14px',
							lineHeight: '20px',
							letterSpacing: '0.24'
						}}
					>
						{intl.formatMessage({ id: 'app.sponsors.getUsageLinkMenuItem' })}
					</MenuItem>
				}
			</MuiMenu>
		</div>
	)
}
