import React, { Fragment, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useTheme from '@material-ui/core/styles/useTheme'
import { isSurveyQuestionUsedByCasesSelector } from '../../../redux/selectors'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import Button from '@material-ui/core/Button'
import { fetchIsSurveyQuestionInUse, hideModal, removeQuestion } from '../../../redux/actions'

const RemoveSurveyQuestionModal = ({
    question,
    surveyId,
    intl,
    isSurveyQuestionUsedByCases,
    removeQuestion,
    loadSurveyQuestionUseStatus,
    closeModal
}) => {
	const theme = useTheme()

	useEffect(
        () => {
            loadSurveyQuestionUseStatus(surveyId, question)
        },
        []
    )

    const onRemoveQuestion = () => {
        removeQuestion(question, surveyId)
    }

	return (
		<Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            style={{ width: '75rem', padding: '4rem', margin: 'auto', backgroundColor: theme.palette.secondary.main }}
        >
            <Grid item style={{ alignSelf: 'center' }}>
                <Typography
                    variant="h1"
                    style={{
                        color: '#fff',
                        marginBottom: '25px',
                    }}
                >
                    {intl.formatMessage({ id: 'app.survey.removeQuestionModalTitle' })}
                </Typography>
            </Grid>
            {
                isSurveyQuestionUsedByCases == false
                && <Fragment>
                    <Grid item style={{ alignSelf: 'flex-start' }}>
                        <Typography
                            variant="h3"
                            style={{
                                color: '#fff',
                            }}
                            align="left"
                        >
                            {intl.formatMessage({ id: 'app.survey.removeQuestionModalText' })}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                    >
                        <Grid
                            container
                            direction='row'
                            justify='center'
                        >
                            <Grid
                                item
                            >
                                <Button
                                    style={{
                                        width: '150px',
                                        marginTop: '40px'
                                    }}
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    onClick={onRemoveQuestion}
                                >
                                    {intl.formatMessage({ id: 'app.survey.removeQuestionButtonYes' })}
                                </Button>
                            </Grid>
                            <Grid
                                item
                            >
                                <Button
                                    style={{
                                        width: '150px',
                                        marginTop: '40px'
                                    }}
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    onClick={closeModal}
                                >
                                    {intl.formatMessage({ id: 'app.survey.removeQuestionButtonNo' })}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Fragment>
            }
            {
                !!isSurveyQuestionUsedByCases
                && <Fragment>
                    <Grid item style={{ alignSelf: 'flex-start' }}>
                        <Typography
                            variant="h3"
                            style={{
                                color: '#fff',
                            }}
                            align="left"
                        >
                            {intl.formatMessage({ id: 'app.survey.removeQuestionModalIsUsedByCases' })}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        style={{ alignSelf: 'center' }}
                    >
                        <Button
                            style={{
                                width: '150px',
                                marginTop: '40px'
                            }}
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={closeModal}
                        >
                            {intl.formatMessage({ id: 'app.survey.removeQuestionButtonOk' })}
                        </Button>
                    </Grid>
               </Fragment>
            }
        </Grid>
	)
}

const mapStateToProps = (state, ownProps) => ({
    isSurveyQuestionUsedByCases: isSurveyQuestionUsedByCasesSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	removeQuestion: (question, surveyId) => {
        dispatch(removeQuestion(question, surveyId))
        dispatch(hideModal())
    },
    loadSurveyQuestionUseStatus: (surveyId, question) => dispatch(fetchIsSurveyQuestionInUse(surveyId, question)),
    closeModal: () => dispatch(hideModal())
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl
)

export default enhance(RemoveSurveyQuestionModal)
