import React from 'react'
import { useIntl } from 'react-intl'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import TableRow from '@material-ui/core/TableRow'

const CircleHeader = ({ }) => {
	const intl = useIntl()
	return (
		<TableRow>
			<TableCell size="small" align="left" style={{ width: '4px', padding: 0, border: 'none' }} />
			<TableCell align="left" sortDirection={false} style={{ border: 'none' }}>
				<Typography
					variant="subtitle1"
					style={{
						fontWeight: 400,
						letterSpacing: '2.08px',
						fontSize: '13px',
						lineHeight: '16px',
						color: '#011020',
						textTransform: 'uppercase',
						minWidth: '200px'
					}}
				>
					{intl.formatMessage({ id: 'app.userProfile.circles.nameColumnLabel' })}
				</Typography>
			</TableCell>
			<TableCell align="left" style={{ border: 'none' }}>
				<Typography
					variant="subtitle1"
					style={{
						fontWeight: 400,
						letterSpacing: '2.08px',
						fontSize: '13px',
						lineHeight: '16px',
						color: '#011020',
						textTransform: 'uppercase'
					}}
				>
					{intl.formatMessage({ id: 'app.userProfile.circles.protocolColumnLabel' })}
				</Typography>
			</TableCell>
			<TableCell align="left" style={{ border: 'none' }}>
				<Typography
					variant="subtitle1"
					style={{
						fontWeight: 400,
						letterSpacing: '2.08px',
						fontSize: '13px',
						lineHeight: '16px',
						color: '#011020',
						textTransform: 'uppercase'
					}}
				>
					{intl.formatMessage({ id: 'app.userProfile.circles.roleColumnLabel' })}
				</Typography>
			</TableCell>
			<TableCell align="left" style={{ border: 'none' }}>
				<Typography
					variant="subtitle1"
					style={{
						fontWeight: 400,
						letterSpacing: '2.08px',
						fontSize: '13px',
						lineHeight: '16px',
						color: '#011020',
						textTransform: 'uppercase'
					}}
				>
					{intl.formatMessage({ id: 'app.userProfile.circles.invitationDateColumnLabel' })}
				</Typography>
			</TableCell>
			<TableCell align="left" style={{ border: 'none' }}>
				<Typography
					variant="subtitle1"
					style={{
						fontWeight: 400,
						letterSpacing: '2.08px',
						fontSize: '13px',
						lineHeight: '16px',
						color: '#011020',
						textTransform: 'uppercase'
					}}
				>
					{intl.formatMessage({ id: 'app.userProfile.circles.removalDateColumnLabel' })}
				</Typography>
			</TableCell>
		</TableRow>
	)
}

export default CircleHeader
