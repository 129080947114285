import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { useRouteMatch } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Paper, Typography } from '@material-ui/core'
import LanguageNavigator from '../../treatment/components/LanguageNavigator'
import { fetchProtocolScoringGroupDisclaimer, fetchProtocolScoringGroupSettings, fetchProtocolScoringGroupSurveys, fetchProtocolSubVersionDetails, hideModal, showModal, startSaving, updateProtocolVersionScoringGroupDisclaimer, updateProtocolVersionScoringGroupSettings, updateProtocolVersionScoringGroupSurveySettings } from '../../../redux/actions'
import { protocolSubversionDetailsSelector, protocolVersionScoringGroupDisclaimerSelector, protocolVersionScoringGroupSettingsSelector, protocolVersionScoringGroupSurveysSelector } from '../../../redux/selectors'
import ProtocolVersionScoringGroupDisclaimerForm from './ProtocolVersionScoringGroupDisclaimerForm'
import EntityAuditCommentModal from '../../../components/entityAudit/EntityAuditCommentModal'
import { isAuditCommentsRequired } from '../../../utilities/auditUtils'
import SurveyBenchmarcNavigator from './SurveyBenchmarcNavigator'
import ProtocolVersionScoringGroupBenchmarcSettings from './ProtocolVersionScoringGroupBenchmarcSettings'
import ProtocolVersionScoringGroupSurveySettings from './ProtocolVersionScoringGroupSurveySettings'

const ProtocolVersionScoringGroupSettings = ({  }) => {
	const match = useRouteMatch()
	const intl = useIntl()
	const dispatch = useDispatch()

	const scoringGroupSettings = useSelector(protocolVersionScoringGroupSettingsSelector, shallowEqual)
	const version = useSelector(protocolSubversionDetailsSelector, shallowEqual)
	const disclaimer = useSelector(protocolVersionScoringGroupDisclaimerSelector, shallowEqual)
	const surveys = useSelector(protocolVersionScoringGroupSurveysSelector, shallowEqual)

	const [ currentLanguage, setCurrentLanguage ] = useState(1)
	const [ currentSurvey, setCurrentSurvey ] = useState(null)

	useEffect(
		() => {
			if (match.params.versionId) {
				dispatch(fetchProtocolSubVersionDetails(match.params.versionId))
			}
		},
		[match.params.versionId]
	)

	useEffect(
		() => {
			if (surveys.length && currentSurvey === null) {
				setCurrentSurvey(surveys[0])
			}
		},
		[surveys && surveys.length]
	)

	useEffect(
		() => {
			if (match.params.sgId && match.params.versionId) {
				dispatch(fetchProtocolScoringGroupSurveys(match.params.versionId, match.params.sgId))
				dispatch(fetchProtocolScoringGroupSettings(match.params.versionId, match.params.sgId))
			}
		},
		[match.params.sgId, match.params.versionId]
	)

	useEffect(
		() => {
			if (match.params.sgId && match.params.versionId) {
				dispatch(fetchProtocolScoringGroupDisclaimer(match.params.versionId, match.params.sgId, currentLanguage))
			}
		},
		[match.params.sgId, match.params.versionId, currentLanguage]
	)

	const onDisclaimerSaveCallback = (versionId, scoringGroupId, languageId, disclaimer) => {
		if (!isAuditCommentsRequired())
		{
			dispatch(startSaving())
			dispatch(updateProtocolVersionScoringGroupDisclaimer(versionId, scoringGroupId, languageId, disclaimer))
		}
		else {
			dispatch(
				showModal(
					EntityAuditCommentModal,
					{
						entityId: versionId,
						entityTypeId: 17, //protocol
						saveCallback: (commentsObj) => {
							dispatch(hideModal())
							dispatch(startSaving())
							dispatch(updateProtocolVersionScoringGroupDisclaimer(versionId, scoringGroupId, languageId, disclaimer, commentsObj))
						}
					}
				)
			)	
		}	
	}

	const onBenchmarcSettingsSaveCallback = (versionId, scoringGroupId, benchmarcMode, benchmarcType) => {
		if (!isAuditCommentsRequired())
		{
			dispatch(startSaving())
			dispatch(updateProtocolVersionScoringGroupSettings(versionId, scoringGroupId, benchmarcMode, benchmarcType))
		}
		else {
			dispatch(
				showModal(
					EntityAuditCommentModal,
					{
						entityId: versionId,
						entityTypeId: 17, //protocol
						saveCallback: (commentsObj) => {
							dispatch(hideModal())
							dispatch(startSaving())
							dispatch(updateProtocolVersionScoringGroupSettings(versionId, scoringGroupId, benchmarcMode, benchmarcType, commentsObj))
						}
					}
				)
			)	
		}	
	}

	const onSurveySettingsSaveCallback = (versionId, scoringGroupId, surveyInstanceId, values) => {
		if (!isAuditCommentsRequired())
		{
			dispatch(startSaving())
			dispatch(updateProtocolVersionScoringGroupSurveySettings(versionId, scoringGroupId, surveyInstanceId, values))
		}
		else {
			dispatch(
				showModal(
					EntityAuditCommentModal,
					{
						entityId: versionId,
						entityTypeId: 17, //protocol
						saveCallback: (commentsObj) => {
							dispatch(hideModal())
							dispatch(startSaving())
							dispatch(updateProtocolVersionScoringGroupSurveySettings(versionId, scoringGroupId, surveyInstanceId, values, commentsObj))
						}
					}
				)
			)	
		}	
	}

	return (
		<div className="admin-fullheight-wrapper">
			{scoringGroupSettings && version &&
				<Grid container direction='column'>
					<Grid item style={{ marginBottom: '20px' }}>
						<Typography
							variant="h2"
						>
							{intl.formatMessage({ id: 'app.protocol.scoringGroups.settingsHeader' }, { sg: scoringGroupSettings.scoringGroupName, version: `${version.versionNumber}: ${version.createDateText} ${version.versionName}`})}
						</Typography>
					</Grid>
					<Grid item style={{ marginBottom: '20px' }}>
						<Typography
							variant="h3"
						>
							{intl.formatMessage({ id: 'app.protocol.scoringGroups.benchmarcTextHeader' })}
						</Typography>
					</Grid>
					<Grid item style={{minHeight: '550px'}}>
						<Paper style={{ padding: '3%' }}>
							<ProtocolVersionScoringGroupBenchmarcSettings
								settings={scoringGroupSettings}
								onSaveCallback={onBenchmarcSettingsSaveCallback}
								versionId={match.params.versionId}
								scoringGroupId={match.params.sgId}
							/>
						</Paper>
					</Grid>
					<Grid item style={{ marginBottom: '40px' }}>
						<Grid container direction="row" spacing={2}>
							<Grid item style={{ flex: 0.3 }}>
								<SurveyBenchmarcNavigator
									currentSurvey={currentSurvey} 
									onSurveyChange={setCurrentSurvey}
									versionId={match.params.versionId}
								/>
							</Grid>
							<Grid item style={{ flex: 0.7, height: '100%' }}>
								<Paper style={{ padding: '3%' }}>
									<ProtocolVersionScoringGroupSurveySettings
										currentSurvey={currentSurvey}
										onSaveCallback={onSurveySettingsSaveCallback}
										versionId={match.params.versionId}
										scoringGroupId={match.params.sgId}
										scoringGroupSettings={scoringGroupSettings}
									/>
								</Paper>
							</Grid>
						</Grid>
					</Grid>
					<Grid item style={{ marginBottom: '20px' }}>
						<Typography
							variant="h3"
						>
							{intl.formatMessage({ id: 'app.protocol.scoringGroups.disclaimerTextHeader' })}
						</Typography>
					</Grid>
					<Grid item>
						<Grid container direction="row" spacing={2}>
							<Grid item style={{ flex: 0.3 }}>
								<LanguageNavigator currentLanguage={currentLanguage} onLanguageChange={setCurrentLanguage} />
							</Grid>
							<Grid item style={{ flex: 0.7, height: '100%' }}>
								<Paper style={{ padding: '3%' }}>
									<ProtocolVersionScoringGroupDisclaimerForm
										disclaimer={disclaimer}
										languageId={currentLanguage}
										onSaveCallback={onDisclaimerSaveCallback}
										versionId={match.params.versionId}
										scoringGroupId={match.params.sgId}
									/>
								</Paper>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			}
		</div>
	)
}

export default ProtocolVersionScoringGroupSettings
