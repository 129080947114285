import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { compose } from 'recompose'
import { reduxForm, change, formValueSelector } from 'redux-form'
import DefaultFormTextField from '../../../components/DefaultFormTextField'
import { isSavingTermsSelector } from '../../../redux/selectors'
import { useDispatch } from 'react-redux'
import { useTheme, Typography } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { required } from '../../../utilities/validators'
import { useSelector, shallowEqual } from 'react-redux'

const requiredValidator = required

const ClinicianTermsForm = ({ term, parent, languageId, handleSubmit, onSaveCallback }) => {
	const dispatch = useDispatch()
	const isSaving = useSelector(isSavingTermsSelector, shallowEqual)
	const theme = useTheme()
	const intl = useIntl()
	const selector = formValueSelector('clinicianTerms')
	useEffect(
		() => {
			dispatch(change('clinicianTerms', 'body', term))
		},
		[ term ]
	)

	const onSubmit = (values) => {
		if (values && languageId) {
			onSaveCallback && onSaveCallback(values.body, languageId)
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container justify="flex-end">
				<Grid item>
					<Button
                        variant="contained"
                        color="primary"
						disabled={isSaving}
						type="submit"
					>
						{intl.formatMessage({
							id: 'app.general.saveChangesButtonText'
						})}
					</Button>
				</Grid>
			</Grid>
			<div style={{ backgroundColor: 'rgb(225, 238, 255)', marginTop: '26px', padding: '3%' }}>
				<Grid container direction="column">
					<Typography
						style={{ fontSize: '13px', lineHeight: '16px', letterSpacing: '2.08px', color: '#011020' }}
					>
						{intl.formatMessage({ id: 'app.terms.sectionLabel' })}
					</Typography>

					<Grid item style={{ marginTop: '17px' }}>
						<DefaultFormTextField
							variant="filled"
							label="Body"
							name="body"
							fullWidth
							multiline={true}
							rows={15}
							// validators={[ requiredValidator ]}
						/>
					</Grid>
				</Grid>
			</div>
		</form>
	)
}

const enhance = compose(
	reduxForm({
		form: 'clinicianTerms',
		touchOnChange: true,
		touchOnBlur: true
	})
)

export default enhance(ClinicianTermsForm)
