import * as api from '../../../apis/patients'
import * as patientApi from '../../../apis/patient'
import { createSelector } from 'reselect'
import { hideModal } from '../../actions'
import { Node, LinkList, ListContainer } from '../../../utilities/linkedList'
import history from '../../../utilities/history'
import { stopSaving } from '../core'

// const FETCH_PATIENT_REQUESTED = 'FETCH_PATIENT_REQUESTED'
// const FETCH_PATIENT_SUCCESS = 'FETCH_PATIENT_SUCCESS'
// const FETCH_PATIENT_FAILED = 'FETCH_PATIENT_FAILED'

// export const fetchPatient = (id) => (dispatch) => {
// 	if (!id) {
// 		return dispatch({ type: FETCH_PATIENT_SUCCESS, data: null })
// 	}
// 	dispatch({ type: FETCH_PATIENT_REQUESTED, data: {} })
// 	api.getObservationalPatient(id).then((observationPatient) => {
// 		if (observationPatient && observationPatient.isSuccessful) {
// 			return dispatch({ type: FETCH_PATIENT_SUCCESS, data: observationPatient })
// 		} else {
// 			return dispatch({
// 				type: 'SET_SNACKBAR_MESSAGE',
// 				data: { message: 'app.protocol.fetchPatientFailed', isSuccess: false }
// 			})
// 		}
// 	})
// 	return
// }

const FETCH_FAILED_REMINDERS_REQUESTED = 'FETCH_FAILED_REMINDERS_REQUESTED'
const FETCH_FAILED_REMINDERS_SUCCESS = 'FETCH_FAILED_REMINDERS_SUCCESS'
const FETCH_FAILED_REMINDERS_FAILED = 'FETCH_FAILED_REMINDERS_FAILED'
export const fetchFailedReminders = () => (dispatch) => {
	dispatch({ type: FETCH_FAILED_REMINDERS_REQUESTED, data: {} })
	api.getFailedReminders().then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_FAILED_REMINDERS_SUCCESS, data: response.failedReminders })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.fetchPatientsFailed', isSuccess: false }
			})
		}
	})
	return
}

const UNLOCK_REMINDER_REQUESTED = 'UNLOCK_REMINDER_REQUESTED'
const UNLOCK_REMINDER_SUCCESS = 'UNLOCK_REMINDER_SUCCESS'
const UNLOCK_REMINDER_FAILED = 'UNLOCK_REMINDER_FAILED'
export const unlockReminder = (caseId) => (dispatch) => {
	dispatch({ type: UNLOCK_REMINDER_REQUESTED, data: {} })
	api.unlockReminder(caseId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(fetchFailedReminders())
			return dispatch({ type: UNLOCK_REMINDER_SUCCESS, data: {} })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.fetchPatientsFailed', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_PATIENTS_WITH_METRICS_REQUESTED = 'FETCH_PATIENTS_WITH_METRICS_REQUESTED'
const FETCH_PATIENTS_WITH_METRICS_SUCCESS = 'FETCH_PATIENTS_WITH_METRICS_SUCCESS'
const FETCH_PATIENTS_WITH_METRICS_FAILED = 'FETCH_PATIENTS_WITH_METRICS_FAILED'
export const fetchPatientsWithMetrics = (searchPhrase) => (dispatch) => {
	dispatch({ type: FETCH_PATIENTS_WITH_METRICS_REQUESTED, data: {} })
	api.getPatients(searchPhrase).then((patients) => {
		if (patients && patients.isSuccessful) {
			return dispatch({ type: FETCH_PATIENTS_WITH_METRICS_SUCCESS, data: patients.patients })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.fetchPatientsFailed', isSuccess: false }
			})
		}
	})
	return
}

const SET_PATIENT_SORT_REQUESTED = 'SET_PATIENT_SORT_REQUESTED'

export const setPatientsSort = (field, isDescending) => (dispatch, state) => {
	let currentState = state()

	let data = currentState.patient.patients.sort((a, b) => {
		if (isDescending) {
			return a[field] > b[field] ? -1 : 1
		} else {
			return b[field] > a[field] ? -1 : 1
		}
	})
	return dispatch({
		type: SET_PATIENT_SORT_REQUESTED,
		data: { items: data, field: field, isDescending: isDescending }
	})
}

const SET_PATIENTS_FILTER_REQUESTED = 'SET_PATIENTS_FILTER_REQUESTED'
const SET_PATIENTS_FILTER_SUCCESS = 'SET_PATIENTS_FILTER_SUCCESS'
const SET_PATIENTS_FILTER_FAILED = 'SET_PATIENTS_FILTER_FAILED'

export const setPatientsFilter = (filter) => (dispatch) => {
	dispatch({ type: SET_PATIENTS_FILTER_REQUESTED, data: {} })

	return dispatch({ type: SET_PATIENTS_FILTER_SUCCESS, data: filter })
}

const FETCH_UPDATE_TEMPLATE_TYPES_REQUESTED = 'FETCH_UPDATE_TEMPLATE_TYPES_REQUESTED'
const FETCH_UPDATE_TEMPLATE_TYPES_SUCCESS = 'FETCH_UPDATE_TEMPLATE_TYPES_SUCCESS'
const FETCH_UPDATE_TEMPLATE_TYPES_FAILED = 'FETCH_UPDATE_TEMPLATE_TYPES_FAILED'

export const fetchPatientUpdatesTemplateTypes = () => (dispatch) => {
    dispatch({ type: FETCH_UPDATE_TEMPLATE_TYPES_REQUESTED, data: {} })

    patientApi.fetchPatientUpdatesTemplateTypes().then((response) => {
        if (response) {
            return dispatch({ type: FETCH_UPDATE_TEMPLATE_TYPES_SUCCESS, data: response })
        } else {
            return dispatch({ type: FETCH_UPDATE_TEMPLATE_TYPES_FAILED, data: null })
        }
    })
    return
}

const FETCH_UPDATE_TEMPLATE_REQUESTED = 'FETCH_UPDATE_TEMPLATE_REQUESTED'
const FETCH_UPDATE_TEMPLATE_SUCCESS = 'FETCH_UPDATE_TEMPLATE_SUCCESS'
const FETCH_UPDATE_TEMPLATE_FAILED = 'FETCH_UPDATE_TEMPLATE_FAILED'

export const fetchPatientUpdatesTemplate = (versionId, languageId, type) => (dispatch) => {
    dispatch({ type: FETCH_UPDATE_TEMPLATE_REQUESTED, data: {} })
    patientApi.fetchTemplate(versionId, languageId, type).then((response) => {
        if (response && response.isSuccessful) {
            if (response.doesNotExist) {
                dispatch({
                    type: 'SET_SNACKBAR_MESSAGE',
                    data: { message: `app.template.doesNotExist`, isSuccess: false }
                })
            }

            return dispatch({ type: FETCH_UPDATE_TEMPLATE_SUCCESS, data: response.data })
        } else {
            return dispatch({ type: FETCH_UPDATE_TEMPLATE_FAILED, data: null })
        }
    })
    return
}

const UPDATE_TRIGGER_TEMPLATE_REQUESTED = 'UPDATE_TRIGGER_TEMPLATE_REQUESTED'
const UPDATE_TRIGGER_TEMPLATE_SUCCESS   = 'UPDATE_TRIGGER_TEMPLATE_SUCCESS'
const UPDATE_TRIGGER_TEMPLATE_FAILED    = 'UPDATE_TRIGGER_TEMPLATE_FAILED'

export const updateTriggerTemplate = (versionId, languageId, type, body, commentsObj) => (dispatch) => {
	dispatch({ type: UPDATE_TRIGGER_TEMPLATE_REQUESTED, data: {} })

	patientApi.updateTriggerTemplate(versionId, languageId, type, body, commentsObj).then((response) => {
		dispatch(stopSaving())
		if (response && response.isSuccessful) {
			dispatch({ type: UPDATE_TRIGGER_TEMPLATE_SUCCESS, data: response.data })

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.template.updateSuccessful`, isSuccess: true }
			})
		} else {
			dispatch({ type: UPDATE_TRIGGER_TEMPLATE_FAILED, data: {} })
			
			let messageKey;

			switch(response.errorCode)
			{
				case 20:
					messageKey = 'app.template.updateFailed.deleteTranslations';
					break;
				default:
					messageKey = 'app.template.updateFailed';
			}

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: messageKey, isSuccess: false }
			})
		}
	})

	return
}

const initial = {
	patients: [],
	sortField: 'patientName',
	isDescending: true,
	filter: 0,
	autoCompleteSuggestions: [],
	failedReminders: [],
	patientUpdateTriggerTemplateTypes: [],
    patientUpdateTriggerTemplate: null
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_FAILED_REMINDERS_SUCCESS:
			return { ...state, failedReminders: action.data }
		case FETCH_PATIENTS_WITH_METRICS_SUCCESS:
			return {
				...state,
				patients: action.data
			}
		case SET_PATIENT_SORT_REQUESTED:
			return {
				...state,
				patients: [ ...action.data.items ],
				sortField: action.data.field,
				isDescending: action.data.isDescending
			}
		case SET_PATIENTS_FILTER_SUCCESS:
			return { ...state, filter: action.data }
        case FETCH_UPDATE_TEMPLATE_TYPES_REQUESTED:
			return { ...state, patientUpdateTriggerTemplateTypes: [] }
        case FETCH_UPDATE_TEMPLATE_TYPES_SUCCESS:
			return { ...state, patientUpdateTriggerTemplateTypes: action.data }
		case FETCH_UPDATE_TEMPLATE_TYPES_FAILED:
			return { ...state, patientUpdateTriggerTemplateTypes: [] }
        case FETCH_UPDATE_TEMPLATE_REQUESTED:
			return { ...state, patientUpdateTriggerTemplate: null }
        case FETCH_UPDATE_TEMPLATE_SUCCESS:
			return { ...state, patientUpdateTriggerTemplate: action.data }
        case FETCH_UPDATE_TEMPLATE_FAILED:
			return { ...state, patientUpdateTriggerTemplate: null }
		case UPDATE_TRIGGER_TEMPLATE_SUCCESS:
			return { ...state, template: action.data }
        case 'SIGN_OUT_REQUESTED':
			return initial

		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.patient
const filterSelector = (state) => state.patient.filter
export const failedRemindersSelector = (state) => state.patient.failedReminders

export const sortPatientsDirectionSelector = createSelector(mainSelector, (state) => {
	return state.isDescending
})

export const sortPatientsFieldSelector = createSelector(mainSelector, (state) => {
	return state.sortField
})

export const patientsSelector = createSelector(mainSelector, filterSelector, (state, filter) => {
	return state.patients ? state.patients : []
})

export const patientUpdateTriggerTemplateSelector = createSelector(mainSelector, (state) => {
	return state && state.patientUpdateTriggerTemplate
})

export const patientUpdateTriggerTemplateTypeSelector = createSelector(mainSelector, (state) => {
    return state && state.patientUpdateTriggerTemplateTypes
})

export const formattedPatientsSelector = createSelector(mainSelector, (state) => {
	if (state && state.patients) {
		return state.patients.map((r) => {
			return {
				id: r.id,
				name: r.name,
				label: r.name,
				isActive: r.isActive
			}
		})
	}
	return []
})
