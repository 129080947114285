import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import QuestionContainer from './QuestionContainer'
export default ({ question, position, positionDisplay }) => {
	return (
		<Grid data-testid="question-number-question" container>
			<Grid item>
				<QuestionContainer
					title={question.title}
					positionDisplay={positionDisplay}
					instructions={question.instructions}
					displayInstructionAsIcon={question.displayInstructionAsIcon}
					children={
						<TextField
							style={{ backgroundColor: 'white', color: 'black' }}
							variant="filled"
							InputProps={{
								readOnly: true
							}}
						/>
					}
				/>
			</Grid>
		</Grid>
	)
}
