import React, { useState, useEffect, Fragment } from 'react'
import IconButton from '@material-ui/core/IconButton'
import MuiMenu from '@material-ui/core/Menu'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import MenuItem from '@material-ui/core/MenuItem'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import LockIcon from '@material-ui/icons/Lock'
import { QUESTION_STATE } from '../../../utilities/constants'


const ITEM_HEIGHT = 48

export default ({
	bundle,
	onRemoveCallback,
	onChangeStateCallback,
	canSetInUseState = true,
	canSetNotInUseState = true,
	canSetArchiveState = true
}) => {
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)
	const intl = useIntl()
	const dispatch = useDispatch()
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	useEffect(() => { }, [])

	if (
		!!bundle
		&& (
			!!onRemoveCallback
			|| (
				!!onChangeStateCallback
				&& (
					!!canSetInUseState
					|| !!canSetNotInUseState
					|| !!canSetArchiveState
				)
			)
		)
	) {
		return (
			<div>
				<IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
					<MoreVertIcon />
				</IconButton>
				<MuiMenu
					id="long-menu"
					anchorEl={anchorEl}
					keepMounted
					open={open}
					onClose={handleClose}
					PaperProps={{
						style: {
							maxHeight: ITEM_HEIGHT * 4.5,
							width: 200
						}
					}}
				>
					{
						!!onRemoveCallback
						&& <MenuItem
							onClick={() => {
								handleClose()
								onRemoveCallback()
							}}
						>
							{intl.formatMessage({ id: 'aapp.scoringGroup.removeBundleButtonText' })}
						</MenuItem>
					}
					{
						!!onChangeStateCallback
						&& canSetInUseState
						&& bundle.state == QUESTION_STATE.ARCHIVED
						&& <MenuItem
							onClick={() => {
								handleClose()
								onChangeStateCallback(bundle, QUESTION_STATE.IN_USE)
							}}
						>
							{intl.formatMessage({ id: 'app.scoringGroup.setInUseBundleButtonText' })}
						</MenuItem>
					}
					{
						!!onChangeStateCallback
						&& canSetArchiveState
						&& bundle.state == QUESTION_STATE.IN_USE
						&& <MenuItem
							onClick={() => {
								handleClose()
								onChangeStateCallback(bundle, QUESTION_STATE.ARCHIVED)
							}}
						>
							{intl.formatMessage({ id: 'app.scoringGroup.setArchivedBundleButtonText' })}
						</MenuItem>
					}
				</MuiMenu>
			</div>
		)
	}

	return null
}
