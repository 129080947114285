import React from 'react'
import { Field } from 'redux-form'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FilledInput from '@material-ui/core/FilledInput'
import { withStyles, makeStyles } from '@material-ui/core/styles'

const useLabelStyles = makeStyles({
	root: {
		color: 'black',
		marginTop: '7px',
		'&$focused': {
			color: 'black'
		}
	},
	icon: {
		color: 'black',
		height: '24px',
		width: '24px'
	},
	focused: {
		color: 'black',
		marginTop: '7px'
	},
	error: {
		backgroundColor: 'transparent'
	},
	selectMenu: {
		color: 'black',
		height: '20px'
	}
})

const renderSelectField = ({
	input,
	label,
	meta: { touched, error, invalid, submitFailed },
	children,
	name,
	labelStyles,
	hideError,
	errorCallback,
	...custom
}) => {
	return (
		<FormControl
			error={touched && error}
			style={{ minHeight: '82px' }}
			fullWidth
			errorCallback={errorCallback && errorCallback(submitFailed && error)}
		>
			<InputLabel
				htmlFor={name}
				classes={{
					root: labelStyles.root,
					focused: labelStyles.focused
				}}
			>
				{label}
			</InputLabel>
			<Select
				fullWidth
				error={touched && invalid}
				errorText={touched && error}
				{...input}
				{...custom}
				inputProps={{
					name: name,
					id: name
				}}
				classes={{ icon: labelStyles.icon, selectMenu: labelStyles.selectMenu }}
				input={<FilledInput id={name} name={name} style={{ padding: 0, color: 'black' }} />}
			>
				{children}
			</Select>
			{touched && error && !hideError && <div style={{
				color: 'red',
				margin: '8px 16x',
				fontSize: '14px'
			}}>{error}</div>}
		</FormControl>
	)
}

const FormFilledInputSelectLight = ({ label, name, onChange, data, classes, validators, hideError, errorCallback, ...other }) => {
	var labelStyles = useLabelStyles()
	return (
		<Field
			component={renderSelectField}
			name={name}
			onChange={onChange}
			label={label}
			labelStyles={labelStyles}
			validate={validators}
			hideError={hideError}
			errorCallback={errorCallback}
			{...other}
		>
			{data &&
				data.map((r) => (
					<MenuItem key={r.value} value={r.value}>
						{r.name}
					</MenuItem>
				))}
		</Field>
	)
}

export default FormFilledInputSelectLight
