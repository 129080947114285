import React, {useEffect, useState} from 'react'
import {change, reduxForm} from 'redux-form'
import { compose } from 'recompose'
import {injectIntl} from 'react-intl'
import {connect} from 'react-redux'
import {inviteUserMember} from "../../redux/modules/user"
import InvitationForm from "../../components/InvitationForm"
import { userDetailsSelector } from '../../redux/selectors'

const formName = 'inviteTeamMember'

const InviteTeamMember = ({user, changeLanguage, handleSubmit, submitRequest }) => {	
		
	useEffect(() => {
		changeLanguage(user.languageId)
	},
		user && user.languageId)	

	const onSubmitForm = (values) => {
		if (values.email) {
			submitRequest(user.id, values.email.trim(), values.languageId)
		}
	}

	return <InvitationForm handleSubmit={handleSubmit} onSubmitForm={onSubmitForm} isTeamMember={true} />
}

const mapStateToProps = (state, ownProps) => ({
	user: userDetailsSelector(state),
	circleId: state.core.params.circleId
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	submitRequest: (id, email, languageId) => {
		dispatch(inviteUserMember(id, email, languageId))
	},
	changeLanguage: (languageId) => dispatch(change(formName, 'languageId', languageId))
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: formName
	}),
	injectIntl
)

export default enhance(InviteTeamMember)