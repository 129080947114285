import { authenticatedFetch } from './base'

export const fetchResponseRate = (circleId) => {
	return authenticatedFetch(`/api/responseRate/circle/${circleId}`, {
		method: 'get',
	})
		.then(function (response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (obj) {
			return obj
		})
}

export const fetchCircleCasesRate = (circleId) => {
	return authenticatedFetch(`/api/responseRate/circle/${circleId}/casesRate`, {
		method: 'get',
	})
		.then(function (response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (obj) {
			return obj
		})
}

export const fetchSurveyCumulativeComplianceRate = (circleId, userId, filters) => {
	let url = `/api/responseRate/circle/${circleId}/cumulativeSurveyComplianceRate`
	url += `?userId=${userId}`

	let serializedFilter = new URLSearchParams({
		...filters, 
		compliantSurveys: filters.compliantSurveys.join(), 
		investigators: filters.investigators.join(), 
		funders: filters.funders.join()
	}).toString()
	url += `&${serializedFilter}`
	
	return authenticatedFetch(url, {
		method: 'get',
	})
		.then(function (response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (obj) {
			return obj
		})
}
