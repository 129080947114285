import React from 'react'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import ArrowDownward from '@material-ui/icons/ArrowDownward'

export default ({ node, onSwapRequested }) => {
	return (
		<Grid container justify="flex-end">
			<Grid item>
				{node &&
				node.prev && (
					<IconButton style={{ padding: 0 }} onClick={() => onSwapRequested(node, node.prev)}>
						<ArrowUpward />
					</IconButton>
				)}

				{node &&
				node.next && (
					<IconButton style={{ padding: 0 }} onClick={() => onSwapRequested(node.next, node)}>
						<ArrowDownward />
					</IconButton>
				)}
			</Grid>
		</Grid>
	)
}
