import { Divider, List, ListItem, ListItemText, makeStyles, Typography } from '@material-ui/core'
import React, { Fragment, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import { fetchQuestionRelatedBundles } from '../../../redux/actions'
import { relatedBundlesSelector } from '../../../redux/selectors'


const useStyles = makeStyles({
	link: {
		marginBottom: "5px",
		fontSize: "16px"
	},
	icon: {
		display: "inline-block", marginBottom:"-4px", marginLeft: "10px", marginRight: "5px"
	}
})

const RelatedBundles = ({
    questionId,
    questionType,
    style,
    bundles,
    loadByQuestion,
    intl
}) => {
	const classes = useStyles()

    useEffect(
        () => {
            loadByQuestion(questionId, questionType)
        },
        []
    )

    return (
        <div
            style={{
                ...style
            }}
        >
            <Typography variant="h4"
            >
                { intl.formatMessage({ id: "app.relatedEntities.usedByBundles" }).replace("%%USED_COUNT%%", bundles?.length) }
            </Typography>

            <List>
                {
                    !!bundles
                    && bundles.map(
                        (bundle) => {
                            return (
                                <Fragment>
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                <Link to={`/bundles/${bundle.id}/languages/1`}>
                                                    <Typography variant="body2" className={classes.link}>
                                                        {bundle.name}
                                                    </Typography>
                                                </Link>
                                            }
                                        />
                                    </ListItem>
                                    <Divider component="li" />
                                </Fragment>
                            )
                        }
                    )
                }
            </List>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    bundles: relatedBundlesSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    loadByQuestion: (questionId, questionType) => dispatch(fetchQuestionRelatedBundles(questionId, questionType))
})

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)

export default enhance(RelatedBundles)