import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { uuidv4 } from '../../../utilities/utilities'
import AnswerContainer from './AnswerContainer'
import { ListContainer } from '../../../utilities/linkedList'
import { useIntl } from 'react-intl'
import FormFilledInputSelectLight from '../../../components/FormFilledInputSelectLight'
import DefaultFormTextField from '../../DefaultFormTextField'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'
import { useStore } from 'react-redux'
import { formValueSelector } from 'redux-form'
import { mustBeLessThanValue, required } from '../../../utilities/validators'
import FormCheckBox from '../../FormCheckBox'
import PIDataToggle from '../../PIDataToggle'
import { getTagValidators } from '../../../utilities/questions'
import FormTextField from '../../FormTextField'
import FormSelect from '../../FormSelect'
import FormNumberTextField from '../../FormNumberTextField'
import DefaultFormNumberField from '../../DefaultFormNumberField'

const requiredValidator = required
const selector = formValueSelector('editQuestion')

const lowerLessThanMaxValueValidator = mustBeLessThanValue('range')


export default forwardRef((props, ref) => {
	const intl = useIntl()
	const [ answers, setAnswers ] = useState()
	const { question, isBundle, dark, canSetOptional = true } = props
	const [ id, setId ] = useState(0)
	const [ minimumValue, setMinimumValue ] = useState(1)
	const [ range, setRange ] = useState(2)
	const [ answerList, setAnswerList ] = useState(new ListContainer())
	const dispatch = useDispatch()
	const store = useStore()

	useEffect(
		() => {
			if (question && question.data) {
				dispatch(change('editQuestion', 'question', question.data.title))
				dispatch(change('editQuestion', 'instructions', question.data.instructions))
				dispatch(change('editQuestion', 'tag', question.data.tag))
				dispatch(change('editQuestion', 'isOptional', question.data.isOptional))
                dispatch(change('editQuestion', 'isPiData', question.data.isPiData))
                dispatch(change('editQuestion', 'isDropdown', question.data.isDropdown))
				dispatch(change('editQuestion', 'displayInstructionAsIcon', question.data.displayInstructionAsIcon))
				setId(question.data.id)

				if (answerList) {
					for (let index = 0; index < question.data.categories.length; index++) {
						const element = question.data.categories[index]

						element.tagId = uuidv4().replace('-', '')
						element.answerId = uuidv4().replace('-', '')
						element.scoreId = uuidv4().replace('-', '')

						dispatch(change('editQuestion', element.tagId, element.tag))
						dispatch(change('editQuestion', element.answerId, element.label))
						answerList.getList().append(element)
					}
					dispatch(change('editQuestion', 'minimumValue', question.data.minimumValue))
					dispatch(change('editQuestion', 'range', question.data.range))
					let questionAnswers = new Array(0)

					answerList.getList().traverse((r) => {
						r.id = uuidv4().replace('-', '')
						questionAnswers.push(r)
					})
					setAnswers(questionAnswers)
				}
			}
		},
		[ question ]
	)

	useImperativeHandle(ref, () => ({
		getQuestion() {
			let a = []
			if (answers) {
				let i = 0
				answerList.getList().traverse((r) => {
					let question = selector(store.getState(), r.data.answerId)
					let id = isNaN(r.data.id)
					let tag = selector(store.getState(), r.data.tagId)
					a.push({ answer: { question, tag: tag }, id: id ? null : r.data.id, sortOrder: i })
					i++
				})
			}
			return {
				id: question.data.id,
				title: selector(store.getState(), 'question'),
				tag: selector(store.getState(), 'tag'),
				prefix: selector(store.getState(), 'prefix'),
				type: 'grouped',
				minimumValue: selector(store.getState(), 'minimumValue'),
				range: selector(store.getState(), 'range'),
				instructions: selector(store.getState(), 'instructions'),
				answers: a,
				isOptional: selector(store.getState(), 'isOptional'),
				isDropdown: selector(store.getState(), 'isDropdown'),
				isPiData: selector(store.getState(), 'isPiData'),
				displayInstructionAsIcon: selector(store.getState(), 'displayInstructionAsIcon'),
			}
		}
	}))

	const onDeleteAnswerClick = (answer) => {
		if (answer) {
			let x = answer.data.answerId
			answerList.getList().remove(answer)
			setAnswerList(answerList)

			var a = answers.filter((r) => {
				return r.data.answerId !== x
			})
			setAnswers(a)
		}
	}

	const swapAnswer = (nodeA, nodeB) => {
		if (answerList) {
			if (nodeA) {
				nodeA.data.answer = nodeA.ref.current.getAnswer()
			}
			if (nodeB) {
				nodeB.data.answer = nodeB.ref.current.getAnswer()
			}

			answerList.getList().swap(nodeA, nodeB)

			let questionAnswers = new Array(0)

			answerList.getList().traverse((r) => {
				r.id = uuidv4().replace('-', '')
				questionAnswers.push(r)
			})
			setAnswerList(answerList)
			setAnswers(questionAnswers)
		}
	}

	const onAddNewAnswer = () => {
		let answer = {}
		answer.id = uuidv4().replace('-', '')
		answer.tagId = uuidv4().replace('-', '')
		answer.answerId = uuidv4().replace('-', '')
		if (answerList) {
			answerList.getList().append(answer)
			let questionAnswers = new Array(0)

			answerList.getList().traverse((r) => {
				questionAnswers.push(r)
			})
			setAnswerList(answerList)

			setAnswers(questionAnswers)
		}
	}

	useEffect(() => {
		var el = document.getElementsByName('tag')
		if (el != null) {
			el[0].focus()
		}
	}, [])

	const AdaptiveFormTextField = dark ? FormTextField : DefaultFormTextField
	const AdaptiveFormFilledInputSelect = dark ? FormSelect : FormFilledInputSelectLight

	const cloneMode=!!question.data.cloneId

	return (
		<Grid container direction="column" alignItems="flex-start">
        {!isBundle && <Grid item style={{ width: '100%' }}>
				<PIDataToggle
					fontColor={dark ? "white" : "black"}
					defaultValue={question.data.isPiData}
					onChange={(value) => dispatch(change('editQuestion', 'isPiData', value))}
					disabled={cloneMode}
				/>
			</Grid>}
			<Grid item style={{ width: '100%' }}>
				<AdaptiveFormTextField
					label="Tag"
					variant="filled"
					fullWidth
					name="tag"
					inputProps={{ maxLength: 254 }}
					maxLength={254}
					validators={getTagValidators(question)}
				/>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				<AdaptiveFormTextField
					label="Question"
					variant="filled"
					fullWidth
					name="question"
					validators={[ requiredValidator ]}
					inputProps={{ maxLength: 1023 }}
					maxLength={1023}
					disabled={cloneMode}
				/>
			</Grid>

			<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
				<AdaptiveFormTextField
					label="Instructions"
					variant="filled"
					name="instructions"
					fullWidth
					inputProps={{ maxLength: 2047 }}
					rows="3"
					maxLength={2047}
					multiline
					disabled={cloneMode}
				/>
			</Grid>
			{cloneMode && <Grid item style={{ width: '100%' }}>
				<AdaptiveFormTextField
					label="Prefix"
					variant="filled"
					fullWidth
					name="prefix"
					inputProps={{ maxLength: 254 }}
					maxLength={254}
					validators={getTagValidators(question)}
				/>
			</Grid>}
			<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '0px' }}>
				<FormCheckBox
					name="displayInstructionAsIcon"
					color={dark ? "secondary" : "primary"}
					fontColor={dark ? "white" : "black"}
					label={intl.formatMessage({ id: 'app.survey.questions.displayInstructionAsIcon' })}
					disabled={cloneMode}
				/>
			</Grid>
			{
				!!canSetOptional
				&& <Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '0px' }}>
					<FormCheckBox
						name="isOptional"
						color={dark ? "secondary" : "primary"}
						fontColor={dark ? "white" : "black"}
						label={intl.formatMessage({ id: 'app.survey.questions.isOptional' })}
						disabled={cloneMode}
					/>
				</Grid>
			}
			<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '0px' }}>
				<FormCheckBox
					name="isDropdown"
					color={dark ? "secondary" : "primary"}
					fontColor={dark ? "white" : "black"}
					label={intl.formatMessage({ id: 'app.survey.questions.displayAsDropdown' })}
					disabled={cloneMode}
				/>
			</Grid>
			<Grid item style={{ width: '40%', marginTop: '20px' }}>
				{ <AdaptiveFormFilledInputSelect
					placeholder="Minimum"
					label="Minimum"
					name="minimumValue"
					initialValue={minimumValue}
					onChangeCallback={setMinimumValue}
					validators={[ requiredValidator, lowerLessThanMaxValueValidator ]}
					data={
						Array(15).fill().map((_v, i) => {
							return {
								name: i,
								value: i
							}
						})
					}
					style={{ color: !!cloneMode ? 'grey' : 'white' }}
					fullWidth
					disabled={cloneMode}
				/> }
			</Grid>
			<Grid item style={{ width: '40%', marginTop: '20px' }}>
				{ <AdaptiveFormFilledInputSelect
					placeholder="Maximum"
					label="Maximum"
					name="range"
					initialValue={range}
					onChangeCallback={setRange}
					validators={[ requiredValidator ]}
					data={
						Array(15)
							.fill()
							.map((_v, i) => {
								return {
									name: i + 1,
									value: i + 1
								}
							})
					}
					style={{ color: !!cloneMode ? 'grey' : 'white' }}
					fullWidth
					disabled={cloneMode}
				/> }
			</Grid>
			<Grid item>
				<Typography variant="h6" style={{ fontWeight: '400', padding: '10px 0', color: dark ? 'white' : 'initial' }}>
					{intl.formatMessage({ id: 'app.survey.groupedQuestion.categorySubtitleText' })}
				</Typography>
			</Grid>

			{answers &&
				answers.map((r, i) => {
					return (
						<Grid item key={r.id} style={{ width: '100%', padding: '10px 0 10px 30px ' }}>
							<AnswerContainer
								answer={r}
								key={r.id}
								onDeleteAnswerCallback={onDeleteAnswerClick}
								onSwapRequestedCallback={swapAnswer}
								dark={dark}
								disabled={cloneMode}
							/>
						</Grid>
					)
				})}

			<Grid item style={{ width: '100%', paddingTop: '16px', alignSelf: 'center' }}>
				<Button style={{ margin: '0' }} variant="outlined" fullWidth color="secondary" onClick={onAddNewAnswer}>
					{intl.formatMessage({ id: 'app.survey.groupedQuestion.addCategoryButtonText' })}
				</Button>
			</Grid>
		</Grid>
	)
})
