import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { archiveCircle } from '../../../redux/actions'

export default ({ circle, onComplete }) => {
	const dispatch = useDispatch()
	const intl = useIntl()
	const onClick = () => {
		if (circle) {
			if (onComplete) {
				onComplete()
			}
			dispatch(archiveCircle(circle.id))
		}
	}

	return (
		<MenuItem
			onClick={() => {
				onClick()
			}}
		>
			{intl.formatMessage({ id: 'app.circles.archiveMenuItemText' })}
		</MenuItem>
	)
}
