import React from "react";
import { Grid, Typography, useTheme } from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";


const DetailsNavigatorRow = ({
    label,
    optionId,
    selected,
    onSelectCallback
}) => {
	const theme = useTheme()

    const [_selected, _setSelected] = useState(false)


    const getBackgroundColor = () => {
		if (_selected) {
			return theme.palette.secondary.A600
		} else {
			return '#fff'
		}
	}

	const getFontColor = () => {
		if (_selected) {
			return '#fff'
		} else {
			return '#000'
		}
	}

	const getBorderColor = () => {
		if (_selected) {
			return theme.palette.primary.A800
		} else {
			return '#fff'
		}
	}

    useEffect(
        () => {
            if (selected !== _selected) {
                _setSelected(selected)
            }
        },
        [selected]
    )

    const onSelect = () => {
        if (!_selected) {
            _setSelected(true)
        }

        if (
            !!onSelectCallback
            && !!optionId
        ) {
            onSelectCallback(optionId)
        }
    }


    return (
        <div
            style={{
                padding: '0 11px 0 20px',
                backgroundColor: getBackgroundColor(),
                color: getFontColor(),
                borderLeft: `solid 4px ${getBorderColor()}`,
                borderBottom: 'solid 1px black',
                cursor: 'pointer'
            }}
            onClick={onSelect}
        >
            <Grid container justify="space-between" alignItems="center" style={{ minHeight: '100px' }}>
                <Grid item>
                    <Grid container direction="column">
                        <Grid item>
                            {label && (
                                <Typography
                                    variant="subtitle1"
                                    style={{
                                        textAlign: 'left',
                                        wordBreak: 'break-word',
                                        fontSize: '13px',
                                        lineHeight: '16px',
                                        letterSpacing: '2.08px',
                                        fontWeight: '400',
                                        color: 'inherit'
                                    }}
                                >
                                    {label}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default DetailsNavigatorRow;
