import { Grid } from "@material-ui/core";
import React from "react";
import RelatedEntitiesSearchBar from "./RelatedEntitiesSearchBar";
import RelatedProtocolsTable from "./RelatedProtocolsTable";


const RelatedEntities = ({
    groupId
}) => {
    return (
        <Grid
            datatest-id="related-entities-container"
            container
            direction="column"
            alignItems="stretch"
            spacing={4}
        >
            <Grid
                item
            >
                <RelatedEntitiesSearchBar groupId={groupId} />
            </Grid>

            <Grid
                item
            >
                <RelatedProtocolsTable groupId={groupId} />
            </Grid>
        </Grid>
    )
}

export default RelatedEntities;
