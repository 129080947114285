import * as api from '../../../apis/subscriber'
import * as subscriptionApi from '../../../apis/subscription'
import { createSelector } from 'reselect'
import { hideModal, stopSaving } from '../core'

const SEARCH_SUBSCRIBERS_REQUESTED = 'SEARCH_SUBSCRIBERS_REQUESTED'
const SEARCH_SUBSCRIBERS_SUCCESS = 'SEARCH_SUBSCRIBERS_SUCCESS'
const SEARCH_SUBSCRIBERS_FAILED = 'SEARCH_SUBSCRIBERS_FAILED'

export const searchSubscribers = (searchPhrase) => (dispatch) => {
	dispatch({ type: SEARCH_SUBSCRIBERS_REQUESTED, data: { searchPhrase: searchPhrase } })
	api.search(searchPhrase).then((response) => {
		if (response && response.isSuccessful) {
			var s = response.subscribers.map((r) => {
				if (r.teamName && r.teamName.length < 2) {
					r.teamName = null
				}
				if (r.circleCount) {
					r.circleCount = parseInt(r.circleCount)
				}
				return r
			})
			return dispatch({ 
				type: SEARCH_SUBSCRIBERS_SUCCESS, 
				data: {
					items: s,
					hasMore: response.hasMore
				} 
			})
		} else {
			return dispatch({ type: SEARCH_SUBSCRIBERS_FAILED, data: {} })
		}
	})
	return
}

const SEARCH_ADDITIONAL_SUBSCRIBERS_REQUESTED = 'SEARCH_ADDITIONAL_SUBSCRIBERS_REQUESTED'
const SEARCH_ADDITIONAL_SUBSCRIBERS_SUCCESS = 'SEARCH_ADDITIONAL_SUBSCRIBERS_SUCCESS'
const SEARCH_ADDITIONAL_SUBSCRIBERS_FAILED = 'SEARCH_ADDITIONAL_SUBSCRIBERS_FAILED'

export const searchAdditionalSubscribers = (searchPhrase, offset) => (dispatch) => {
	dispatch({ type: SEARCH_ADDITIONAL_SUBSCRIBERS_REQUESTED, data: {} })
	api.search(searchPhrase, offset).then((response) => {
		if (response && response.isSuccessful) {
			var s = response.subscribers.map((r) => {
				if (r.teamName && r.teamName.length < 2) {
					r.teamName = null
				}
				if (r.circleCount) {
					r.circleCount = parseInt(r.circleCount)
				}
				return r
			})
			return dispatch({ 
				type: SEARCH_ADDITIONAL_SUBSCRIBERS_SUCCESS, 
				data: {
					items: s,
					hasMore: response.hasMore
				} 
			})
		} else {
			return dispatch({ type: SEARCH_ADDITIONAL_SUBSCRIBERS_FAILED, data: {} })
		}
	})
	return
}

const FETCH_SUBSCRIPTION_CHANGES_REQUESTED = 'FETCH_SUBSCRIPTION_CHANGES_REQUESTED'
const FETCH_SUBSCRIPTION_CHANGES_SUCCESS = 'FETCH_SUBSCRIPTION_CHANGES_SUCCESS'
const FETCH_SUBSCRIPTION_CHANGES_FAILED = 'FETCH_SUBSCRIPTION_CHANGES_FAILED'

export const fetchSubscriptionChanges = (userId, count, offset) => (dispatch) => {
    dispatch({ type: FETCH_SUBSCRIPTION_CHANGES_REQUESTED, data: {} })

	subscriptionApi.fetchSubscriptionChanges(userId, count, offset).then((response) => {
        if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_SUBSCRIPTION_CHANGES_SUCCESS, data: response.data })
        } else {
            return dispatch({ type: FETCH_SUBSCRIPTION_CHANGES_FAILED, data: {} })
        }
    })
    return
}

const SET_SUBSCRIBER_SORT_REQUESTED = 'SET_SUBSCRIBER_SORT_REQUESTED'

export const setSubscriberSort = (field, isDescending) => (dispatch, state) => {
	let currentState = state()

	let data = currentState.subscriber.searchQuestions.sort((a, b) => {
		if (isDescending) {
			return a[field] > b[field] ? 1 : -1
		} else {
			return b[field] > a[field] ? 1 : -1
		}
	})
	return dispatch({
		type: SET_SUBSCRIBER_SORT_REQUESTED,
		data: { items: data, field: field, isDescending: isDescending }
	})
}

const SET_SUBSCRIBER_FILTER_REQUESTED = 'SET_SUBSCRIBER_FILTER_REQUESTED'
const SET_SUBSCRIBER_FILTER_SUCCESS = 'SET_SUBSCRIBER_FILTER_SUCCESS'
const SET_SUBSCRIBER_FILTER_FAILED = 'SET_SUBSCRIBER_FILTER_FAILED'

export const setSubscriberFilter = (filter) => (dispatch) => {
	dispatch({ type: SET_SUBSCRIBER_FILTER_REQUESTED, data: {} })

	return dispatch({ type: SET_SUBSCRIBER_FILTER_SUCCESS, data: filter })
}

const GET_STATISTICS_EXPORT_REQUESTED = 'GET_STATISTICS_EXPORT_REQUESTED'
const GET_STATISTICS_EXPORT_SUCCESS = 'GET_STATISTICS_EXPORT_SUCCESS'
const GET_STATISTICS_EXPORT_FAILED = 'GET_STATISTICS_EXPORT_FAILED'

export const getSubscriberStatisticsExport = (userId) => (dispatch) => {
	dispatch({ type: GET_STATISTICS_EXPORT_REQUESTED, data: {} })
	return api.getStatisticsExport(userId).then((response) => {
		if (response) {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'Success', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.exportFailed', isSuccess: false }
			})
		}
	})
}

const SET_SUBSCRIBER_SEARCH_TERM_REQUESTED = 'SET_SUBSCRIBER_SEARCH_TERM_REQUESTED'
const SET_SUBSCRIBER_SEARCH_TERM_SUCCESS = 'SET_SUBSCRIBER_SEARCH_TERM_SUCCESS'
const SET_SUBSCRIBER_SEARCH_TERM_FAILED = 'SET_PROTOCOL_SEARCH_TERM_FAILED'

export const setSubscriberSearchTerm = (value) => (dispatch) => {
	dispatch({ type: SET_SUBSCRIBER_SEARCH_TERM_REQUESTED, data: {} })
	return dispatch({ type: SET_SUBSCRIBER_SEARCH_TERM_SUCCESS, data: value })
}

const RESEND_INVITATION_REQUESTED = 'RESEND_INVITATION_REQUESTED'
const RESEND_INVITATION_SUCCESS = 'RESEND_INVITATION_SUCCESS'
const RESEND_INVITATION_FAILED = 'RESEND_INVITATION_FAILED'

export const resendUserInvitation = (email) => (dispatch) => {
	dispatch({ type: RESEND_INVITATION_REQUESTED, data: {} })
	return api.resendInvitation(email).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'Success', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.subscribers.resendInvitationError', isSuccess: false }
			})
		}
	})
}

const ACTIVATE_API_REQUESTED = 'ACTIVATE_API_REQUESTED'
const ACTIVATE_API_SUCCESS = 'ACTIVATE_API_SUCCESS'
const ACTIVATE_API_FAILED = 'ACTIVATE_API_FAILED'

export const activateApi = (subscriber) => (dispatch, getState) => {
	dispatch({ type: ACTIVATE_API_REQUESTED, data: {} })

	let currentState = getState()

	return api.activateApi(subscriber.id).then((response) => {
		if (response && response.isSuccessful) {
			let result = dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.subscriber.activateApiSuccess', isSuccess: true }
			})

			dispatch(
				searchSubscribers(currentState.subscriber.searchPhrase)
			)

			return result
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.subscriber.activateApiError', isSuccess: false }
			})
		}
	})
}

const DEACTIVATE_API_REQUESTED = 'DEACTIVATE_API_REQUESTED'
const DEACTIVATE_API_SUCCESS = 'DEACTIVATE_API_SUCCESS'
const DEACTIVATE_API_FAILED = 'DEACTIVATE_API_FAILED'

export const deactivateApi = (subscriber) => (dispatch, getState) => {
	dispatch({ type: DEACTIVATE_API_REQUESTED, data: {} })

	let currentState = getState()

	return api.deactivateApi(subscriber.id).then((response) => {
		if (response && response.isSuccessful) {
			let result = dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.subscriber.deactivateApiSuccess', isSuccess: true }
			})

			dispatch(
				searchSubscribers(currentState.subscriber.searchPhrase)
			)

			return result
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.subscriber.deactivateApiError', isSuccess: false }
			})
		}
	})
}

const GET_SUBSCRIBERS_EXPORT_REQUESTED = 'GET_SUBSCRIBERS_EXPORT_REQUESTED'
const GET_SUBSCRIBERS_EXPORT_SUCCESS = 'GET_SUBSCRIBERS_EXPORT_SUCCESS'
const GET_SUBSCRIBERS_EXPORT_FAILED = 'GET_SUBSCRIBERS_EXPORT_FAILED'

export const exportSubscribers = (filters) => (dispatch) => {
	dispatch({ type: GET_SUBSCRIBERS_EXPORT_REQUESTED, data: {} })
	return api.exportSubscribers(filters).then((response) => {
		dispatch(stopSaving())
		dispatch(hideModal())
		if (response) {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'Success', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.exportFailed', isSuccess: false }
			})
		}
	})
}

const initial = {
	subscribers: [],
    subscriptionChanges: [],
	searchQuestions: [],
	searchPhrase: null,
	sortField: 'title',
	isDescending: true,
	filter: 0,
	hasMore: true,
	isLoading: false,
	searchTerm: ''
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case SEARCH_SUBSCRIBERS_REQUESTED:
			return {
				...state,
				searchQuestions: [],
				searchPhrase: action.data.searchPhrase
			}
		case SEARCH_SUBSCRIBERS_SUCCESS:
			return { 
				...state, 
				searchQuestions: action.data.items,
				hasMore: action.data.hasMore 
			}
		case SEARCH_ADDITIONAL_SUBSCRIBERS_SUCCESS:
			return {
				...state,
				searchQuestions: [ ...state.searchQuestions, ...action.data.items],
				hasMore: action.data.hasMore,
				isLoading: false
			}
		case SEARCH_ADDITIONAL_SUBSCRIBERS_FAILED:
			return {
				...state,
				hasMore: false,
				isLoading: false
			}
		case SEARCH_ADDITIONAL_SUBSCRIBERS_REQUESTED:
			return {
				...state,
				isLoading: true
			}
		case FETCH_SUBSCRIPTION_CHANGES_REQUESTED:
			return { ...state, subscriptionChanges: [] }
		case FETCH_SUBSCRIPTION_CHANGES_SUCCESS:
			return { ...state, subscriptionChanges: action.data }
		case SET_SUBSCRIBER_SEARCH_TERM_SUCCESS:
			return { ...state, searchTerm: action.data }
		case SET_SUBSCRIBER_SORT_REQUESTED:
			return {
				...state,
				searchQuestions: [ ...action.data.items ],
				sortField: action.data.field,
				isDescending: action.data.isDescending
			}
		case SET_SUBSCRIBER_FILTER_SUCCESS:
			return { ...state, filter: action.data }
		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.subscriber

export const subscriptionChangesSelector = createSelector(mainSelector, (state) => {
	return state.subscriptionChanges
})

export const searchSubscribersSelector = createSelector(mainSelector, (state) => {
	return state.searchQuestions
})

export const sortSubscriberDirectionSelector = createSelector(mainSelector, (state) => {
	return state.isDescending
})

export const sortSubscriberFieldSelector = createSelector(mainSelector, (state) => {
	return state.sortField
})

export const hasMoreSubscribersSelector = createSelector(mainSelector, (state) => {
	return state.hasMore
})

export const isLoadingSubscribersSelector = createSelector(mainSelector, (state) => {
	return state.isLoading
})

export const subscriberSearchTermSelector = createSelector(mainSelector, (state) => {
	return state.searchTerm
})