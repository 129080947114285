import { authenticatedFetch } from './base'

export const getObservationalProtocol = (id) => {
	return authenticatedFetch(`/api/observationalProtocol/${id}`, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const getObservationalProtocolByCircle = (id, circleId) => {
	return authenticatedFetch(`/api/observationalProtocol/${id}/circle/${circleId}`, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const getObservationalProtocolVersion = (id, versionId) => {
	return authenticatedFetch(`/api/observationalProtocol/${id}/version/${versionId}`, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const getObservationalProtocolRootVersion = (id) => {
	return authenticatedFetch(`/api/observationalProtocol/${id}/rootVersion`, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const getObservationalProtocolDetail = (id, versionId) => {
	return authenticatedFetch(`/api/observationalProtocol/${id}/version/${versionId}/details`, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const cloneObservationalProtocol = (id, name, description) => {
	return authenticatedFetch(`/api/observationalProtocol/${id}/clone`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ id, name: name, description: description })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const insertSurvey = (id, versionId, surveyId, sortOrder, phaseId) => {
	return authenticatedFetch(`/api/observationalProtocol/${id}/version/${versionId}/survey/${surveyId}`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ id, surveyId, sortOrder, treatmentPhaseId: phaseId })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const createObservationalProtocol = (values) => {
	return authenticatedFetch(`/api/observationalProtocol`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ ...values, isPrivate: parseInt(values.isPrivate), isBilateral: parseInt(values.isBilateral) })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const saveObservationalProtocol = (id, description, isPrivate) => {
	return authenticatedFetch(`/api/observationalProtocol/${id}`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ id: id, description: description, isPrivate: isPrivate ? true : false })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const saveObservationalProtocolSettings = (id, versionId, values) => {
	return authenticatedFetch(`/api/observationalProtocol/${id}/version/${versionId}/defaults`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ id, ...values})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const saveObservationalProtocolName = (id, name, alias, versionId) => {
	return authenticatedFetch(`/api/observationalProtocol/${id}/name`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ id: id, name: name, alias: alias, versionId: versionId })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const saveSurveyCustomName = (id, versionId, name) => {
	return authenticatedFetch(`/api/observationalProtocol/survey/name`, {
            method: 'PUT',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ id: id, name: name, versionId: versionId })
        })
        .then(function (response) {
            if (response.status === 200) {
                return response.json()
            }
        })
        .then(function (patient) {
            return patient
        })
}

export const updateSurveyPositions = (id, versionId, surveys) => {
	return authenticatedFetch(`/api/observationalProtocol/${id}/version/${versionId}/survey/swap`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ id: id, surveys: surveys })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const deactivate = (id) => {
	return authenticatedFetch(`/api/observationalProtocol/${id}/deactivate`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const activate = (id) => {
	return authenticatedFetch(`/api/observationalProtocol/${id}/activate`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const deleteSurveyInstance = (id, surveyId, instanceId) => {
	return authenticatedFetch(`/api/observationalProtocol/${id}/survey/${surveyId}/instance/${instanceId}`, {
		method: 'DELETE',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const changeSurveyInstanceState = (id, versionId, instanceId, state, commentsObj) => {
	return authenticatedFetch(`/api/observationalProtocol/${id}/version/${versionId}/instance/${instanceId}/updateState/${state}`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			commentText: commentsObj?.commentText,
			commentProtocolVersionId: commentsObj?.protocolVersionId,
			commentRelatedTaskUrl: commentsObj?.relatedTaskUrl,
			commentInvestigatorId: commentsObj?.investigatorId
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const saveSurvey = (protocolId, surveyId, questions, settings) => {
	return authenticatedFetch(`/api/observationalProtocol/${protocolId}/survey/${surveyId}`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ questions: questions, settings: settings })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchSurveyInstance = (protocolId, surveyId, instanceId, versionId) => {
	return authenticatedFetch(`/api/observationalProtocol/${protocolId}/survey/${surveyId}/instance/${instanceId}${versionId ? '/version/' + versionId : ''}`, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const saveSurveyInstance = (protocolId, surveyId, instanceId, data, versionId, commentsObj) => {

	data.comments = {}
	data.comments.commentText = commentsObj?.commentText
	data.comments.commentProtocolVersionId = commentsObj?.protocolVersionId
	data.comments.commentRelatedTaskUrl = commentsObj?.relatedTaskUrl
	data.comments.commentInvestigatorId = commentsObj?.investigatorId

	return authenticatedFetch(`/api/observationalProtocol/${protocolId}/survey/${surveyId}/instance/${instanceId}${versionId ? '/version/' + versionId : ''}`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(data)
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const createFromExisting = (versionId, circleId, userId) => {
	var url = userId ?
		`/api/observationalProtocol/version/${versionId}/circle/${circleId}/user/${userId}/create` :
		`/api/observationalProtocol/version/${versionId}/circle/${circleId}/create`
	return authenticatedFetch(url, {
		method: 'POST',
		headers: { 'content-type': 'application/json' }
	})
		.then(function (response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (patient) {
			return patient
		})
}

export const getObservationalProtocolsWithMetrics = (searchPhrase, filter, offset) => {
	var url = `/api/observationalProtocol/withmetrics`
	let urlHasParams = false

	if (searchPhrase && searchPhrase.length > 0) {
		url = `${url}?searchPhrase=${encodeURIComponent(searchPhrase)}`
		urlHasParams = true
	}

	if(offset){
		url += `${urlHasParams ? '&' : '?'}offset=${offset}`
		urlHasParams = true
	}

	if(Number.isInteger(filter)){
		url += `${urlHasParams ? '&' : '?'}filter=${filter}`
		urlHasParams = true
	}

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const getObservationalProtocols = () => {
	return authenticatedFetch(`/api/observationalProtocol`, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const getRelatedObservationalProtocols = (baseId) => {
	return authenticatedFetch(`/api/observationalProtocol/${baseId}/withmetrics`, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const getSurvey = (protocolId, surveyId) => {
	return authenticatedFetch(`/api/observationalProtocol/${protocolId}/survey/${surveyId}`, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchProtocolAutoComplete = (searchPhrase = '', entityId = '', entytyTypeId = '') => {
	return authenticatedFetch(
		`/api/observationalProtocol/autocomplete?searchPhrase=${encodeURIComponent(searchPhrase)}&entityId=${entityId}&entityTypeId=${entytyTypeId}`,
		{
			method: 'get'
		}
	)
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const getSurveyQuestions = (protocolId, surveyId) => {
	return authenticatedFetch(`/api/observationalProtocol/${protocolId}/survey/${surveyId}/questions`, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const getSurveyQuestionsForCircle = (observationProtocolSurveyId, circleId) => {
	return authenticatedFetch(`/api/observationalProtocol/ops/${observationProtocolSurveyId}/circle/${circleId}/questions`, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const getCircles = (protocolId, searchPhrase) => {
	var url = `/api/observationalProtocol/${protocolId}/circles`

	if (searchPhrase && searchPhrase.length > 0) {
		url = `${url}?searchPhrase=${encodeURIComponent(searchPhrase)}`
	}
	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(data) {
			return data
		})
}

export const getSubscribers = (protocolId, searchPhrase) => {
	var url = `/api/observationalProtocol/${protocolId}/subscribers`

	if (searchPhrase && searchPhrase.length > 0) {
		url = `${url}?searchPhrase=${encodeURIComponent(searchPhrase)}`
	}
	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(data) {
			return data
		})
}

export const getVersions = (protocolId, searchPhrase, showArchived, offset) => {
	var url = `/api/observationalProtocol/${protocolId}/versions`

	url = `${url}?showArchived=${showArchived}`

	if (searchPhrase && searchPhrase.length > 0) {
		url += `&searchPhrase=${encodeURIComponent(searchPhrase)}`
	}

	if(offset){
		url += `&offset=${offset}`
	}

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(data) {
			return data
		})
}

export const getSubVersions = (versionId, searchPhrase, offset) => {
	var url = `/api/observationalProtocol/version/${versionId}/subversions`
	let urlHasParams = false

	if (searchPhrase && searchPhrase.length > 0) {
		url = `${url}?searchPhrase=${encodeURIComponent(searchPhrase)}`
		urlHasParams = true
	}

	if(offset){
		url += `${urlHasParams ? '&' : '?'}offset=${offset}`
		urlHasParams = true
	}

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(data) {
			return data
		})
}

export const addPermission = (protocolId, versionId, serviceProviderId, commentsObj) => {
	var url = `/api/observationalProtocol/${protocolId}/version/${versionId}/provider/${serviceProviderId}`;
	return authenticatedFetch(url, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			commentText: commentsObj?.commentText,
			commentProtocolVersionId: commentsObj?.protocolVersionId,
			commentRelatedTaskUrl: commentsObj?.relatedTaskUrl,
			commentInvestigatorId: commentsObj?.investigatorId
		})
	})
		.then(function (response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (patient) {
			return patient
		})
}

export const makeVersionDefault = (versionId) => {
	var url = `/api/observationalProtocol/version/${versionId}/makeDefault`;
	return authenticatedFetch(url, {
		method: 'POST',
		headers: { 'content-type': 'application/json' }
	})
		.then(function (response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (response) {
			return response
		})
}

export const changeVersionCustomerVisibility = (versionId) => {
	var url = `/api/observationalProtocol/version/${versionId}/changeCustomerVisibility`;
	return authenticatedFetch(url, {
		method: 'POST',
		headers: { 'content-type': 'application/json' }
	})
		.then(function (response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (response) {
			return response
		})
}

export const createNewSubversion = (versionId, values) => {
	var url = `/api/observationalProtocol/version/${versionId}/createNewSubVersion`;
	return authenticatedFetch(url, {
		method: 'POST',
		body: JSON.stringify(values),
		headers: { 'content-type': 'application/json' }
	})
		.then(function (response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (response) {
			return response
		})
}

export const updateSubVersion = (versionId, values) => {
	var url = `/api/observationalProtocol/version/${versionId}/updateSubVersion`;
	return authenticatedFetch(url, {
		method: 'POST',
		body: JSON.stringify(values),
		headers: { 'content-type': 'application/json' }
	})
		.then(function (response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (response) {
			return response
		})
}

export const getSubVersionDetails = (versionId) => {
	var url = `/api/observationalProtocol/version/${versionId}/subversionDetails`

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(data) {
			return data
		})
}

export const copyVersionLayout = (sourceVersionId, targetVersionId) => {
	var url = `/api/observationalProtocol/version/${sourceVersionId}/copyLayout/${targetVersionId}`;
	return authenticatedFetch(url, {
		method: 'POST',
		headers: { 'content-type': 'application/json' }
	})
		.then(function (response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (response) {
			return response
		})
}

export const getProtocolsByQuestion = (questionId, questionType) => {
	var url = `/api/observationalProtocol/question/${questionId}/questionType/${questionType}`

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(data) {
			return data
		})
}

export const getProtocolsByBundle = (bundleId) => {
	var url = `/api/observationalProtocol/bundle/${bundleId}`

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(data) {
			return data
		})
}

export const getProtocolsBySurvey = (surveyId) => {
	var url = `/api/observationalProtocol/survey/${surveyId}`

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(data) {
			return data
		})
}

export const fetchVersionsForCircleAndUser = (circleId, userId) => {
	return authenticatedFetch(
		`/api/observationalProtocol/circle/${circleId}/user/${userId}/versions`,
		{
			method: 'get'
		}
	)
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchVersionsForCircle = (circleId) => {
	return authenticatedFetch(
		`/api/observationalProtocol/circle/${circleId}/versions`,
		{
			method: 'get'
		}
	)
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchVersionsForEntity = (entityId, entityTypeId) => {
	return authenticatedFetch(
		`/api/observationalProtocol/entity/${entityId}/entityType/${entityTypeId}/versions`,
		{
			method: 'get'
		}
	)
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchBilateralAreas = (versionId, languageId) => {
	return authenticatedFetch(
		`/api/observationalProtocol/version/${versionId}/language/${languageId}/bilateralareas`,
		{
			method: 'get'
		}
	)
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const saveBilateralAreas = (versionId, languageId, values) => {
	return authenticatedFetch(`/api/observationalProtocol/version/${versionId}/language/${languageId}/bilateralareas`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(values)
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchScoringGroups = (versionId) => {
	return authenticatedFetch(
		`/api/observationalProtocol/version/${versionId}/scoringGroups`,
		{
			method: 'get'
		}
	)
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchScoringGroupDisclaimer = (versionId, scoringGroupId, languageId) => {
	return authenticatedFetch(
		`/api/observationalProtocol/version/${versionId}/scoringGroup/${scoringGroupId}/language/${languageId}/disclaimer`,
		{
			method: 'get'
		}
	)
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchScoringGroupSurveys = (versionId, scoringGroupId) => {
	return authenticatedFetch(
		`/api/observationalProtocol/version/${versionId}/scoringGroup/${scoringGroupId}/surveys`,
		{
			method: 'get'
		}
	)
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchScoringGroupSettings = (versionId, scoringGroupId) => {
	return authenticatedFetch(
		`/api/observationalProtocol/version/${versionId}/scoringGroup/${scoringGroupId}/settings`,
		{
			method: 'get'
		}
	)
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchSurveysForProtocolVersion = (versionId) => {
	return authenticatedFetch(
		`/api/observationalProtocol/version/${versionId}/surveys`,
		{
			method: 'get'
		}
	)
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchInvestigatorsForProtocolVersion = (circleId, versionId) => {
	return authenticatedFetch(
		`/api/observationalProtocol/circle/${circleId}/version/${versionId}/investigators`,
		{
			method: 'get'
		}
	)
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchInvestigatorsForProtocolVersionEntityAudit = (versionId) => {
	return authenticatedFetch(
		`/api/observationalProtocol/version/${versionId}/investigators`,
		{
			method: 'get'
		}
	)
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchFundersForProtocolVersion = (circleId, versionId) => {
	return authenticatedFetch(
		`/api/observationalProtocol/circle/${circleId}/version/${versionId}/funders`,
		{
			method: 'get'
		}
	)
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchProtocolChangeLogs = (protocolVersionId, searchPhrase, keys) => {
	var url = `/api/observationalProtocol/version/${protocolVersionId}/changeLogs`

	url = `${url}?searchPhrase=${!!searchPhrase?.length && encodeURIComponent(searchPhrase) || ''}`

	if (!!keys?.length) {
		keys.forEach(x => url = `${url}&keys=${x}`)
	}

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(data) {
			return data
		})
}

export const updateProtocolVersionScoringGroupDisclaimer = (versionId, scoringGroupId, languageId, disclaimer, commentsObj) => {
	var url = `/api/observationalProtocol/version/${versionId}/scoringGroup/${scoringGroupId}/language/${languageId}/disclaimer`

	let data = {}
	data.disclaimer = disclaimer
	data.comments = {}
	data.comments.commentText = commentsObj?.commentText
	data.comments.commentProtocolVersionId = commentsObj?.protocolVersionId
	data.comments.commentRelatedTaskUrl = commentsObj?.relatedTaskUrl
	data.comments.commentInvestigatorId = commentsObj?.investigatorId

    return authenticatedFetch(url, {
            method: 'PUT',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(data)
        })
        .then(function (response) {
            if (response.status === 200) {
                return response.json()
            }
        })
        .then(function (result) {
            return result
        })
}

export const updateProtocolVersionScoringGroupSettings = (versionId, scoringGroupId, benchmarcMode, benchmarcType, commentsObj) => {
	var url = `/api/observationalProtocol/version/${versionId}/scoringGroup/${scoringGroupId}/settings`

	let data = {}
	data.benchmarcMode = benchmarcMode
	data.benchmarcType = benchmarcType
	data.comments = {}
	data.comments.commentText = commentsObj?.commentText
	data.comments.commentProtocolVersionId = commentsObj?.protocolVersionId
	data.comments.commentRelatedTaskUrl = commentsObj?.relatedTaskUrl
	data.comments.commentInvestigatorId = commentsObj?.investigatorId

    return authenticatedFetch(url, {
            method: 'PUT',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(data)
        })
        .then(function (response) {
            if (response.status === 200) {
                return response.json()
            }
        })
        .then(function (result) {
            return result
        })
}

export const updateProtocolVersionScoringGroupSurveySettings = (versionId, scoringGroupId, surveyInstanceId, values, commentsObj) => {
	var url = `/api/observationalProtocol/version/${versionId}/scoringGroup/${scoringGroupId}/survey/${surveyInstanceId}/settings`

	let data = {...values}
	data.comments = {}
	data.comments.commentText = commentsObj?.commentText
	data.comments.commentProtocolVersionId = commentsObj?.protocolVersionId
	data.comments.commentRelatedTaskUrl = commentsObj?.relatedTaskUrl
	data.comments.commentInvestigatorId = commentsObj?.investigatorId

    return authenticatedFetch(url, {
            method: 'PUT',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(data)
        })
        .then(function (response) {
            if (response.status === 200) {
                return response.json()
            }
        })
        .then(function (result) {
            return result
        })
}