import * as survey from '../../../apis/survey'
import { createSelector } from 'reselect'
import { hideModal } from '../../actions'
import { Node, LinkList, ListContainer } from '../../../utilities/linkedList'
import { fetchSurvey } from '../../actions'


const processSurveyQuestions = (surveyQuestions, container, questionContainer, hash) => {
	if (surveyQuestions) {
		surveyQuestions.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1)).map((r) => {
			container.getList().append(r)
		})
		container.getList().traverse((r) => {
			questionContainer.push(r.data.uniqueId)
			hash[r.data.uniqueId] = r
		})
	}
}


const FETCH_READONLY_SURVEY_REQUESTED = 'FETCH_READONLY_SURVEY_REQUESTED'
const FETCH_READONLY_SURVEY_SUCCESS = 'FETCH_READONLY_SURVEY_SUCCESS'

export const fetchReadOnlySurvey = (id) => (dispatch) => {
	dispatch({ type: FETCH_READONLY_SURVEY_REQUESTED, data: {} })

	survey.fetchSurvey(id).then((response) => {
		if (response && response.isSuccessful) {
			let positionDisplay = 0
			let x = response.questions.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1)).map((r, i) => {
				r.data = r
				if (r.data.questionType !== 8) {
					positionDisplay++
				}
				r.data.positionDisplay = positionDisplay
				return (r.data = r)
			})

			dispatch({
				type: FETCH_READONLY_SURVEY_SUCCESS,
				data: {
					questions: x
				}
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.surveys.fetchSurveyFailed', isSuccess: false }
			})
		}
	})
}

const FETCH_SURVEY_ACTIVE_QUESTIONS_REQUESTED = 'FETCH_SURVEY_ACTIVE_QUESTIONS_REQUESTED'
const FETCH_SURVEY_ACTIVE_QUESTIONS_SUCCESS = 'FETCH_SURVEY_ACTIVE_QUESTIONS_SUCCESS'

export const fetchSurveyActiveQuestions = (protocolSurveyId, versionId) => (dispatch) => {
	dispatch({ type: FETCH_SURVEY_ACTIVE_QUESTIONS_REQUESTED, data: {} })

	survey.fetchSurveyActiveQuestions(protocolSurveyId, versionId).then((response) => {
		if (response && response.isSuccessful) {
			let questionContainerList = []
			let hashList = []
			let containerList = []
			let revisionsList = []

			response.answeredQuestions.map(answeredQuestions => {
				let questionContainer = new Array(0)
				let hash = {}
				let container = new ListContainer()

				processSurveyQuestions(answeredQuestions.questions, container, questionContainer, hash)

				questionContainerList.push(questionContainer)
				hashList.push(hash)
				containerList.push(container)
				revisionsList.push(answeredQuestions.revisions)
			})

			return dispatch({
				type: FETCH_SURVEY_ACTIVE_QUESTIONS_SUCCESS,
				data: {
					survey: response,
					list: containerList,
					flat: questionContainerList,
					nodes: hashList
				}
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.surveys.fetchSurveyFailed', isSuccess: false }
			})
		}
	})
}

const FETCH_READONLY_SURVEY_BY_VERSION_REQUESTED = 'FETCH_READONLY_SURVEY_BY_VERSION_REQUESTED'
const FETCH_READONLY_SURVEY_BY_VERSION_SUCCESS = 'FETCH_READONLY_SURVEY_BY_VERSION_SUCCESS'

export const fetchReadOnlySurveyByVersion = (surveyId, instanceId, protocolVersionId) => (dispatch) => {
	dispatch({ type: FETCH_READONLY_SURVEY_BY_VERSION_REQUESTED, data: {} })

	return survey.fetchSurveyByVersion(surveyId, instanceId, protocolVersionId).then((response) => {
		if (response && response.isSuccessful) {
			let positionDisplay = 0
			let x = response.items.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1)).map((r, i) => {
				r.data = r
				if (r.data.questionType !== 8) {
					positionDisplay++
				}
				r.data.positionDisplay = positionDisplay
				return (r.data = r)
			})

			dispatch({
				type: FETCH_READONLY_SURVEY_BY_VERSION_SUCCESS,
				data: {
					questions: x
				}
			})
			return x
		} else {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.surveys.fetchSurveyFailed', isSuccess: false }
			})

			return []
		}
	})
}

const FETCH_SURVEY_USAGE_DATA_REQUESTED = 'FETCH_SURVEY_USAGE_DATA_REQUESTED'
const FETCH_SURVEY_USAGE_DATA_SUCCESS = 'FETCH_SURVEY_USAGE_DATA_SUCCESS'

export const fetchSurveyUsageData = (id, instanceId, versionId) => (dispatch) => {
	dispatch({ type: FETCH_SURVEY_USAGE_DATA_REQUESTED, data: {} })

	survey.fetchSurveyUsageData(id, instanceId, versionId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({
				type: FETCH_SURVEY_USAGE_DATA_SUCCESS,
				data: response
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.surveys.fetchSurveyFailed', isSuccess: false }
			})
		}
	})
}


const SAVE_SURVEY_QUESTION_STATE_REQUESTED = 'SAVE_SURVEY_QUESTION_STATE_REQUESTED'
const SAVE_SURVEY_QUESTION_STATE_FAIL = 'SAVE_SURVEY_QUESTION_STATE_FAIL'

export const saveSurveyQuestionState = (protocolId, surveyId, protocolSurveyId, versionId, questionStateList, bundleStateList, answerStateList, commentsObj) => (dispatch) => {
	dispatch({ type: SAVE_SURVEY_QUESTION_STATE_REQUESTED, data: {} })

	survey.changeQuestionState(protocolId, protocolSurveyId, versionId, questionStateList, bundleStateList, commentsObj).then((response) => {
		if (response && response.isSuccessful) {
			survey.changeAnswerState(protocolId, protocolSurveyId, versionId, answerStateList, commentsObj).then((response) => {
				if (response && response.isSuccessful) {
					dispatch(fetchReadOnlySurveyByVersion(surveyId, protocolSurveyId, versionId))
					return dispatch({
						type: 'SET_SNACKBAR_MESSAGE',
						data: { message: 'app.surveys.saveStateSuccess', isSuccess: true }
					})
				} else {
					return dispatch({
						type: 'SET_SNACKBAR_MESSAGE',
						data: { message: 'app.surveys.saveStateFailed', isSuccess: false }
					})
				}
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.surveys.saveStateFailed', isSuccess: false }
			})
		}
	})
}

const SET_SHOW_ARCHIVED_QUESTIONS_FILTER_REQUESTED = 'SET_SHOW_ARCHIVED_QUESTIONS_FILTER_REQUESTED'
const SET_SHOW_ARCHIVED_QUESTIONS_FILTER_SUCCESS = 'SET_SHOW_ARCHIVED_QUESTIONS_FILTER_SUCCESS'

export const setShowArchivedQuestionsFilter = (filter) => (dispatch) => {
	dispatch({ type: SET_SHOW_ARCHIVED_QUESTIONS_FILTER_REQUESTED, data: {} })

	return dispatch({ type: SET_SHOW_ARCHIVED_QUESTIONS_FILTER_SUCCESS, data: filter })
}

const SET_SURVEY_FILTER_REQUESTED = 'SET_SURVEY_FILTER_REQUESTED'
const SET_SURVEY_FILTER_SUCCESS = 'SET_SURVEY_FILTER_SUCCESS'

export const setSurveyQuestionFilter = (filter) => (dispatch) => {
	dispatch({ type: SET_SURVEY_FILTER_REQUESTED, data: {} })

	return dispatch({ type: SET_SURVEY_FILTER_SUCCESS, data: filter })
}

const COPY_SURVEY_QUESTION_RULES_REQUESTED = 'COPY_SURVEY_QUESTION_RULES_REQUESTED'
const COPY_SURVEY_QUESTION_RULES_SUCCESS = 'COPY_SURVEY_QUESTION_RULES_SUCCESS'
const COPY_SURVEY_QUESTION_RULES_FAILED = 'COPY_SURVEY_QUESTION_RULES_FAILED'
export const copySurveyInstaceQuestionRules = (instanceId, copyToInstanceId, versionId, languageId = 1) => (dispatch) => {
	dispatch({ type: COPY_SURVEY_QUESTION_RULES_REQUESTED, data: {} })

	survey.copySurveyInstanceQuestionRules(instanceId, copyToInstanceId, versionId, languageId).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.copySurvey.instanceRulesSuccess', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.copySurvey.instanceRulesFailed', isSuccess: false }
			})
		}
	})
}

const FETCH_SURVEY_CHANGE_LOGS_REQUESTED = 'FETCH_SURVEY_CHANGE_LOGS_REQUESTED'
const FETCH_SURVEY_CHANGE_LOGS_SUCCESS = 'FETCH_SURVEY_CHANGE_LOGS_SUCCESS'
const FETCH_SURVEY_CHANGE_LOGS_FAILED = 'FETCH_SURVEY_CHANGE_LOGS_FAILED'
export const searchSurveyChangeLogs = (surveyId, searchPhrase = null, keys = []) => (dispatch) => {
	dispatch({ type: FETCH_SURVEY_CHANGE_LOGS_REQUESTED, data: {} })

	survey.fetchSurveyChangeLogs(surveyId, searchPhrase, keys).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({
				type: FETCH_SURVEY_CHANGE_LOGS_SUCCESS,
				data: {
					logs: response.logs,
					keys: response.keys
				}
			})
		} else {
			dispatch({ type: FETCH_SURVEY_CHANGE_LOGS_FAILED, data: {} })

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.audit.failedToFetch', isSuccess: false }
			})
		}
	})
	return
}

const initial = {
	questionsLoading: false,
	questions: [],
	groupedQuestions: [],
	surveyQuestionFiter: 2,
	showArchivedQuestions: true,
	usageData: null,
	survey: null,
	surveyQuestions: [],
	surveyQuestionHash: [],
	surveyQuestionIds: [],
	surveyQuestionsLoading: false,
	logs: [],
	logKeys: []
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_READONLY_SURVEY_SUCCESS:
			return {
				...state,
				questions: action.data.questions
			}
		case FETCH_READONLY_SURVEY_REQUESTED:
			return {
				...state,
				questions: []
			}

		case FETCH_SURVEY_ACTIVE_QUESTIONS_SUCCESS:
			return {
				...state,
				survey: action.data.survey,
				surveyQuestions: action.data.list,
				surveyQuestionHash: action.data.nodes,
				surveyQuestionIds: action.data.flat,
				questionsLoading: false
			}		
		case FETCH_SURVEY_ACTIVE_QUESTIONS_REQUESTED:
			return {
				...state,
				survey: null,
				surveyQuestions: [],
				surveyQuestionHash: [],
				surveyQuestionIds: [],
				questionsLoading: true
			}

		case SAVE_SURVEY_QUESTION_STATE_FAIL:
			return {
				...state,
				questionsLoading: false
			}
		case SAVE_SURVEY_QUESTION_STATE_REQUESTED:
			return {
				...state,
				questionsLoading: true
			}

		case FETCH_READONLY_SURVEY_BY_VERSION_SUCCESS:
			return {
				...state,
				questionsLoading: false,
				groupedQuestions: action.data.questions
			}
		case FETCH_READONLY_SURVEY_BY_VERSION_REQUESTED:
			return {
				...state,
				questionsLoading: true,
				groupedQuestions: []
			}
	
		case FETCH_SURVEY_USAGE_DATA_SUCCESS:
			return {
				...state,
				usageData: action.data
			}

		case SET_SHOW_ARCHIVED_QUESTIONS_FILTER_SUCCESS:
			return { 
				...state, 
				showArchivedQuestions: action.data
			}

		case SET_SURVEY_FILTER_SUCCESS:
			return { ...state, surveyQuestionFiter: action.data }

		/** Survey change logs */
		case FETCH_SURVEY_CHANGE_LOGS_REQUESTED:
			return {
				...state,
				logs: [],
				logKeys: []
			}
		case FETCH_SURVEY_CHANGE_LOGS_SUCCESS:
			return {
				...state,
				logs: action.data.logs,
				logKeys: action.data.keys
			}

		case 'SIGN_OUT_REQUESTED':
			return initial

		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.readonlySurvey
export const surveyQuestionsFilterSelector = (state) => state.readonlySurvey.surveyQuestionFiter

export const readonlySurveyQuestionListSelector = createSelector(mainSelector, (state) => {
	return state.questions
})

export const surveyQuestionsLoadingSelector = createSelector(
	mainSelector,
	(state) => {
		return state.questionsLoading
	}
)

export const readonlySurveyQuestionListByVersionSelector = createSelector(mainSelector, surveyQuestionsFilterSelector, (state, filter) => {
	if (!!state.questionsLoading) {
		return []
	}
	else {
		switch (filter) {
			case 0:
				return state.groupedQuestions.filter((r) => {
					return r.state === 1 || (state.showArchivedQuestions && (r.state === 2 || r.state === 3))
				})
			case 1:
				return state.groupedQuestions.filter((r) => {
					return r.rootState === 1 || (state.showArchivedQuestions && (r.rootState === 2 || r.rootState === 3))
				})
			case 2:
				return state.groupedQuestions.filter((r) => {
					return r.state === 1 || r.state === 0 || (state.showArchivedQuestions && (r.state === 2 || r.state === 3))
				})

		}
	}
})

export const surveyUsageDataSelector = createSelector(mainSelector, (state) => {
	return state.usageData
})

export const showArchivedQuestionsSelector = createSelector(mainSelector, (state) => {
	return state.showArchivedQuestions
})

export const questionListSelector = createSelector(mainSelector, (state) => {
	if (state && state.surveyQuestions) return state.surveyQuestions
})

export const questionHashSelector = createSelector(mainSelector, (state) => {
	if (state && state.surveyQuestionHash) return state.surveyQuestionHash
})

export const questionIdSelector = createSelector(mainSelector, (state) => {
	if (state && state.surveyQuestionIds) return state.surveyQuestionIds
})

export const questionsSelector = createSelector(
	mainSelector,
	questionIdSelector,
	questionHashSelector,
	/*(state, ids, hash) => {
		if (state && ids && ids.length > 0 && hash) {
			let questions = []
			ids.map((r) => {
				if (hash.hasOwnProperty(r)) {
					let x = hash[r]
					questions.push(x)
				} else {
					console.info('No Property', questions)
				}
			})

			return questions
		}
		else {
			return []
		}
	}*/

	(state, idsContainers, hash) => {
		if (state && idsContainers && idsContainers.length > 0 && hash) {
			let questionContainers = []

			idsContainers
				.map((ids, index) => {
					let questions = []

					ids.map((r) => {
						if (hash[index].hasOwnProperty(r)) {
							let x = hash[index][r]
							questions.push(x)
						} else {
							console.info('No Property', questions)
						}
					})

					questionContainers.push(questions)
				})

			return questionContainers
		}
	}
)

export const surveyChangeLogsSelector = createSelector(
	mainSelector,
	(state) => {
		return !!state && state.logs
	}
)

export const surveyChangeLogKeysSelector = createSelector(
	mainSelector,
	(state) => {
		return !!state && state.logKeys
	}
)
