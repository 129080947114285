import React from "react";
import { TableCell, TableRow } from "@material-ui/core";
import RelatedServiceProviderNameTableCell from "./RelatedServiceProviderNameTableCell";
import RelatedServiceProviderRoleTableCell from "./RelatedServiceProviderRoleTableCell";
import RelatedServiceProviderInvitationTableCell from "./RelatedServiceProviderInvitationTableCell";
import RelatedServiceProviderRemoveTableCell from "./RelatedServiceProviderRemoveTableCell";
import RelatedServiceProviderDeafultAssigneeTableCell from "./RelatedServiceProviderDeafultAssigneeTableCell";


const RelatedServiceProviderTableRow = ({
    serviceProvider
}) => {
    const getBorderColor = () => {
        return serviceProvider.isArchived ? 'lightgrey' : 'transparent'
    }
    return (
        <TableRow>
            <TableCell style={{
                padding: 0,
                margin: 0
            }}>
                <div style={{ position: 'relative', height: '44px' }}>
                <div
                    style={{
                        backgroundColor: `${getBorderColor()}`,
                        width: '3px',
                        height: 'calc(100%)',
                        position: 'absolute',
                        left: 0
                    }}
                ></div>
                </div>
            </TableCell>
            <RelatedServiceProviderNameTableCell serviceProvider={serviceProvider} />
            <RelatedServiceProviderRoleTableCell serviceProvider={serviceProvider} />
            <RelatedServiceProviderDeafultAssigneeTableCell serviceProvider={serviceProvider} />
            <RelatedServiceProviderInvitationTableCell serviceProvider={serviceProvider} />
            <RelatedServiceProviderRemoveTableCell serviceProvider={serviceProvider} />
        </TableRow>
    )
}

export default RelatedServiceProviderTableRow;
