export const PreTreatmentPhaseId = 0
export const TreatmentPhaseId = 1
export const PostTreatmentPhaseId = 2

export const debounceMilliseconds = 1000
export const minimumSearchCharacters = 0

export const QUESTION_STATE = {
	UNDEFINED: 0,
	IN_USE: 1,
	NOT_USED: 2,
	ARCHIVED: 3
}

export const BUNDLE_STATE = {
	UNDEFINED: 0,
	IN_USE: 1,
	NOT_USED: 2,
	ARCHIVED: 3
}