import React, { useState, useEffect, Fragment } from 'react'
import Person from '@material-ui/icons/Person'
import PersonOutlined from '@material-ui/icons/PersonOutlined'
import Tooltip from '@material-ui/core/Tooltip'
import moment from 'moment'
import { injectIntl } from 'react-intl'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'

const MemberStatusIcon = ({ subscriber, intl }) => {
	const [ tooltip, setToolTip ] = useState('')

	useEffect(() => {
		if (subscriber && subscriber.lastLogin) {
			setToolTip(intl.formatMessage({ id: 'app.general.active' }))
		} else {
			setToolTip(intl.formatMessage({ id: 'app.general.pending' }))
		}
	}, [])

	return (
		<Tooltip title={tooltip} disableFocusListener disableTouchListener>
			<div>
				{subscriber && subscriber.lastLogin && <Person style={{ fontSize: '24px' }} />}
				{subscriber && !subscriber.lastLogin && <PersonOutlined style={{ fontSize: '24px' }} />}
			</div>
		</Tooltip>
	)
}

export default injectIntl(MemberStatusIcon)
