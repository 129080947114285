import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import Table from '@material-ui/core/Table'
import { useTheme } from '@material-ui/core/styles'
import { reduxForm } from 'redux-form'
import { useDispatch } from 'react-redux'
import { fetchFailedReminders } from '../../redux/actions'
import { failedRemindersSelector } from '../../redux/selectors'
import { useSelector, shallowEqual } from 'react-redux'
import FailedReminderMenu from './FailedReminderMenu'

export default () => {
	const theme = useTheme()
	const dispatch = useDispatch()
	const reminders = useSelector(failedRemindersSelector, shallowEqual)

	useEffect(() => {
		dispatch(fetchFailedReminders())
	}, [])
	return (
		<Table
			style={{
				borderCollapse: 'separate',
				borderSpacing: '0 4px'
			}}
		>
			<TableHead>
				<TableRow>
					{/* <TableCell align="left" style={{ border: 'none' }}>
						<TableSortLabel>
							<Typography
								variant="subtitle1"
								style={{ fontWeight: 400, letterSpacing: '1px', fontSize: '14px', color: '#232c3b' }}
							>
								First Name
							</Typography>
						</TableSortLabel>
					</TableCell>
					<TableCell align="left" style={{ border: 'none' }}>
						<TableSortLabel>
							<Typography
								variant="subtitle1"
								style={{ fontWeight: 400, letterSpacing: '1px', fontSize: '14px', color: '#232c3b' }}
							>
								Last Name
							</Typography>
						</TableSortLabel>
					</TableCell>
					<TableCell align="left" style={{ border: 'none' }}>
						<TableSortLabel>
							<Typography
								variant="subtitle1"
								style={{ fontWeight: 400, letterSpacing: '1px', fontSize: '14px', color: '#232c3b' }}
							>
								Email
							</Typography>
						</TableSortLabel>
					</TableCell> */}
					<TableCell align="left" style={{ border: 'none', maxWidth: '100px' }}>
						<TableSortLabel>
							<Typography
								variant="subtitle1"
								style={{ fontWeight: 400, letterSpacing: '1px', fontSize: '14px', color: '#232c3b' }}
							>
								Case
							</Typography>
						</TableSortLabel>
					</TableCell>
					<TableCell align="left" style={{ border: 'none' }}>
						<TableSortLabel>
							<Typography
								variant="subtitle1"
								style={{ fontWeight: 400, letterSpacing: '1px', fontSize: '14px', color: '#232c3b' }}
							>
								Locked
							</Typography>
						</TableSortLabel>
					</TableCell>
					<TableCell align="left" style={{ border: 'none', minWidth: '100%' }}>
						<TableSortLabel>
							<Typography
								variant="subtitle1"
								style={{ fontWeight: 400, letterSpacing: '1px', fontSize: '14px', color: '#232c3b' }}
							/>
						</TableSortLabel>
					</TableCell>
				</TableRow>
			</TableHead>
			<TableBody style={{ borderTop: 'none' }}>
				{reminders &&
					reminders.map((r) => {
						return (
							<TableRow>
								<TableCell>{r && r.caseId}</TableCell>
								<TableCell>{r && r.isLocked ? 'Yes' : 'No'}</TableCell>
								<TableCell align="right">
									<FailedReminderMenu reminder={r} />
								</TableCell>
							</TableRow>
						)
					})}
			</TableBody>
		</Table>
	)
}
