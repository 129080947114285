import React from "react";
import { TableCell } from "@material-ui/core";


const RelatedProtocolVersionTableCell = ({
    protocol
}) => {
    return (
        <TableCell>
            {protocol.protocolVersionName}
        </TableCell>
    )
}

export default RelatedProtocolVersionTableCell;
