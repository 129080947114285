import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useTheme from '@material-ui/core/styles/useTheme'
import { isSavingSelector } from '../../../redux/selectors'
import { connect, useDispatch } from 'react-redux'
import { change, reduxForm } from 'redux-form'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { required } from '../../../utilities/validators'
import { startSaving, createSponsorSubscription } from '../../../redux/actions'
import CalmButton from '../../../components/CalmButton'
import { Switch } from '@material-ui/core'
import FormDateField from '../../../components/FormDateField'

const requiredValidator = required
const formName = 'createSubscription'

const CreateSubscriptionModal = ({ intl, handleSubmit, createSubscription, isSaving, start, id }) => {
	const theme = useTheme()
	const dispatch = useDispatch()

	const [ hasTrial, setHasTrial ] = useState(false)
	const [ defaultTrialDate, setDefaultTrialDate ] = useState()
	const [ defaultStartDate, setDefaultStartDate ] = useState()
	const [ minStartDate, setMinStartDate ] = useState()

	useEffect(() => {
		var today = new Date()
		var defaultTrialDateValue = new Date()
		defaultTrialDateValue.setDate(today.getDate() + 30)
		setDefaultTrialDate(defaultTrialDateValue)
		setDefaultStartDate(new Date())
		setMinStartDate(new Date(today.getFullYear(), today.getMonth(), 1))
		dispatch(change(formName, 'trialEnd', defaultTrialDateValue))
		dispatch(change(formName, 'hasTrial', false))
	}, [])

	const onSubmitForm = (values) => {
		start()
		createSubscription(id, values)
	}	

	const handleSwitchTrial = (e) => {
		setHasTrial(e.target.checked)
		dispatch(change(formName, 'hasTrial', e.target.checked))
		if(!e.target.checked) {
			dispatch(change(formName, 'trialEnd', null))
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmitForm)}>
			<Grid
				container
				direction="column"
				justify="flex-start"
				alignItems="center"
				style={{ width: '75rem', padding: '4rem', margin: 'auto', backgroundColor: theme.palette.secondary.main }}
			>
				<Grid item style={{ alignSelf: 'center' }}>
					<Typography
						variant="h1"
						style={{
							color: '#fff',
							marginBottom: '25px',
						}}
					>
						{intl.formatMessage({ id: 'app.sponsors.createSubscriptionHeader' })}
					</Typography>
				</Grid>
				<Grid item style={{ alignSelf: 'flex-start', width: '100%' }}>
					<Grid container direction="row">
						<Grid item style={{flex: 0.5}}>
							<Grid container justify="flex-start" style={{ position: 'relative' }}>
								<Grid item style={{ paddingTop: '6px' }}>
									<Typography
										variant="body2"
										style={{ fontSize: '17px', color: '#fff' }}
									>
										{intl.formatMessage({ id: 'app.sponsors.hasTrialLabel' })}
									</Typography>
								</Grid>
								<Grid item>
									<Switch
										checked={hasTrial}
										disabled={true}
										onChange={handleSwitchTrial}
										value="hasTrial"
										color="primary"
										inputProps={{ 'aria-label': 'secondary checkbox' }}
									/>
								</Grid>
							</Grid>
						</Grid>
						{hasTrial && <Grid item style={{flex: 0.5}}>
							<FormDateField
								label={intl.formatMessage({ id: 'app.sponsors.lastTrialDayLabel' })}
								fullWidth
								type="text"
								name="trialEnd"
								validators={[requiredValidator]}
								variant="filled"
								disablePast={true}
								defaultDate={defaultTrialDate}
								disabled={true}
								design="dark"
							/>
						</Grid>}
					</Grid>
				</Grid>
				{/* {!hasTrial && <Grid item style={{ alignSelf: 'flex-start', width: '100%' }}>
					<Grid container direction="row">
						<Grid item style={{ flex: 0.5, paddingTop: '6px' }}>
							<Typography
								variant="body2"
								style={{ fontSize: '17px', color: '#fff' }}
							>
								{intl.formatMessage({ id: 'app.sponsors.subscriptionStartDateLabel' })}
							</Typography>
						</Grid>
						<Grid item style={{flex: 0.5}}>
							<FormDateField
								label={intl.formatMessage({ id: 'app.sponsors.subscriptionStartDateLabel' })}
								fullWidth
								type="text"
								name="startDate"
								validators={[requiredValidator]}
								variant="filled"
								disableFuture={true}
								defaultDate={defaultStartDate}
								minDate={minStartDate}
								design="dark"
							/>
						</Grid>
					</Grid>
				</Grid>} */}
				<Grid item>
					<CalmButton
						style={{
							width: '267px',
							marginTop: '40px'
						}}
						disabled={isSaving}
						type="submit"
                        variant="contained"
                        color="primary"
					>
						{intl.formatMessage({ id: 'app.sponsors.submitButtonLabel' })}
					</CalmButton>
				</Grid>
			</Grid>
		</form>
	)
}

const mapStateToProps = (state, ownProps) => ({
	isSaving: isSavingSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	createSubscription: (id, values) => dispatch(createSponsorSubscription(id, values)),
	start: () => dispatch(startSaving())
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: formName,
		touchOnChange: true,
		touchOnBlur: true,
		enableReinitialize: true
	})
)

export default enhance(CreateSubscriptionModal)
