import React, { useEffect, useState } from 'react'
import { Field, reduxForm } from 'redux-form'
import MuiTextField from '@material-ui/core/TextField'
import { withStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
	root: {
		color: 'black'
	},
	label: {
		color: 'black',
		marginLeft: '-3px'
	},
	instructionTextRoot: {
		color: 'black',
		marginLeft: '-1px'
	},
	instructionTextLabel: {
		color: 'black',
		marginLeft: '-2px'
	},
	errorContainer: {
		color: 'red',
		backgroundColor: 'transparent'
	}
})

const TextField = ({
	label,
	input,
	meta: { touched, invalid, error, form },
	inputLabelProps,
	inputProps,
	style,
	fullWidth,
	margin,
	variant,
	type,
	labelStyles,
	inputStyles,
	maxLength,
	disabled,
	backgroundColor,
	fontColor,
	onChange,
	min,
	max,
	pattern,
	allowDecimal,
	initialValue,
	defaultValue,
	...custom
}) => {
	var classes = useStyles()
	const [ value, setValue ] = useState('test')

	const onChangeCallback = (e) => {
		if (e && e.target && e.target.value) {
			let temp = e.target.value
			if (temp && temp.endsWith('.')) {
				temp = temp + '0'
			}
			let b = RegExp(/-?\d*\.{0,1}\d+$/).test(temp)
			let v = ''
			if (b) {
				v = temp
			}

			setValue(v)
			if (onChange) {
				onChange(v)
			}
		}
	}

	const onInput = (e) => {
		if (e && e.target && e.target.value) {
			if (isNaN(e.target.value)) {
				e.target.value = ''
			}
		}
	}

	useEffect(
		() => {
			setValue(initialValue)
		},
		[ initialValue ]
	)

	const onKeyPress = (evt) => {
		var charCode = evt.which ? evt.which : evt.keyCode

		if (charCode < 48 || charCode > 57) {
			if (allowDecimal && charCode === 46) {
				let temp = evt.target.value + String.fromCharCode(charCode)
				var count = (temp.match(/\./g) || []).length
				if (count <= 1) {
					return true
				}
			}
			if (charCode === 45) {
				let temp = evt.target.value + String.fromCharCode(charCode)
				var count = (temp.match(/\-/g) || []).length
				if (count <= 1) {
					evt.target.value = String.fromCharCode(charCode) + evt.target.value
					evt.preventDefault()
					return false
				}
			}
			evt.preventDefault()
			return false
		} else if (charCode === 46 && evt.target.value && evt.target.value.split('.').length > 1) {
			evt.preventDefault()
			return false
		}

		return true
	}
	return (
		<div className="global-input-wrapper">
			<MuiTextField
				label={label}
				placeholder={label}
				error={touched && invalid}
				helperText={touched && error}
				onKeyPress={onKeyPress}
				onChange={onChangeCallback}
				onInput={onInput}
				{...input}
				classes={{
					root: classes.root
				}}
				InputProps={{
					classes: {
						root: classes.root,
						input: classes.root
					}
				}}
				InputLabelProps={{
					classes: {
						root: classes.label,
						focused: classes.label
					}
				}}
				disabled={disabled}
				FormHelperTextProps={{
					classes: {
						contained: classes.errorContainer,
						error: classes.errorContainer
					}
				}}
				inputProps={{
					maxLength: maxLength,
					style: { color: 'black' },

					min: min,
					max: max,
					pattern: pattern
				}}
				defaultValue={'2'}
				// style={{ ...style, backgroundColor: backgroundColor, color: fontColor }}
				fullWidth
				margin={margin}
				variant={variant}
				{...custom}
				type={type}
				shrink="true"
			/>
		</div>
	)
}

const FormTextField = ({
	label,
	name,
	inputLabelProps,
	inputProps,
	style,
	fullWidth,
	margin,
	variant,
	type,
	validators,
	disabled,
	maxLength = 255,
	backgroundColor = 'rgb(36, 48, 65)',
	fontColor = 'white',
	onChange,
	min,
	max,
	pattern,
	allowDecimal,
	defaultValue
}) => {
	return (
		<Field
			name={name}
			component={TextField}
			label={label}
			inputLabelProps={inputLabelProps}
			inputProps={inputProps}
			style={style}
			fullWidth={fullWidth}
			margin={margin}
			variant={variant}
			type={type}
			validate={validators}
			maxLength={maxLength}
			disabled={disabled}
			fontColor={fontColor}
			backgroundColor={backgroundColor}
			onChange={onChange}
			min={min}
			max={max}
			pattern={pattern}
			allowDecimal={allowDecimal}
			defaultValue={defaultValue}
		/>
	)
}

export default FormTextField
