import { createSelector } from 'reselect'
import * as api from '../../../apis/dashboard'
import { setNewReportInitiated } from '../reports'

const allPossibleDashboardExportFilters = [0,1,2]

const updateFilters = (type, currentState, dispatch) => {
	let filters = currentState.dashboard.dashBoardExportFilters
	let index = filters.indexOf(type)
	let filtersToUpdate = allPossibleDashboardExportFilters.filter(x => filters.indexOf(x) < 0 || filters.indexOf(x) > index)
	for (let i = 0; i < filtersToUpdate.length; i++) {
		switch (filtersToUpdate[i]) {
			case 0:
				dispatch(fetchFundersForDashboard())
				break
			case 1:
				dispatch(fetchCirclesForDashboard())
				break
			case 2:
				dispatch(fetchInvestigatorsForDashboard())
				break
		}
	}
}

const SET_DASHBOARD_EXPORT_FILTERS_REQUESTED = 'SET_DASHBOARD_EXPORT_FILTERS_REQUESTED'
export const setDashboardExportFilters = (value) => (dispatch) => {
	dispatch({ type: SET_DASHBOARD_EXPORT_FILTERS_REQUESTED, data: value })
}

const SET_PERIOD_FROM_FILTER_REQUESTED = 'SET_DASHBOARD_PERIOD_FROM_FILTER_REQUESTED'
export const setDashboardPeriodFromFilter = (value) => (dispatch) => {
	dispatch({ type: SET_PERIOD_FROM_FILTER_REQUESTED, data: value })
}

const SET_PERIOD_TO_FILTER_REQUESTED = 'SET_DASHBOARD_PERIOD_TO_FILTER_REQUESTED'
export const setDashboardPeriodToFilter = (value) => (dispatch) => {
	dispatch({ type: SET_PERIOD_TO_FILTER_REQUESTED, data: value })
}

const SET_ONBOARDING_PERIOD_FROM_FILTER_REQUESTED = 'SET_ONBOARDING_PERIOD_FROM_FILTER_REQUESTED'
export const setOnboardingPeriodFromFilter = (value) => (dispatch) => {
	dispatch({ type: SET_ONBOARDING_PERIOD_FROM_FILTER_REQUESTED, data: value })
}

const SET_ONBOARDING_PERIOD_TO_FILTER_REQUESTED = 'SET_ONBOARDING_PERIOD_TO_FILTER_REQUESTED'
export const setOnboardingPeriodToFilter = (value) => (dispatch) => {
	dispatch({ type: SET_ONBOARDING_PERIOD_TO_FILTER_REQUESTED, data: value })
}

const SET_DATAPOINTS_PERIOD_FROM_FILTER_REQUESTED = 'SET_DATAPOINTS_DASHBOARD_PERIOD_FROM_FILTER_REQUESTED'
export const setDatapointsPeriodFromFilter = (value) => (dispatch) => {
	dispatch({ type: SET_DATAPOINTS_PERIOD_FROM_FILTER_REQUESTED, data: value })
}

const SET_DATAPOINTS_PERIOD_TO_FILTER_REQUESTED = 'SET_DATAPOINTS_DASHBOARD_PERIOD_TO_FILTER_REQUESTED'
export const setDatapointsPeriodToFilter = (value) => (dispatch) => {
	dispatch({ type: SET_DATAPOINTS_PERIOD_TO_FILTER_REQUESTED, data: value })
}

const SET_FILTER_INVESTIGATORS_REQUESTED = 'SET_DASHBOARD_FILTER_INVESTIGATORS_REQUESTED'
export const setDashboardFilterInvestigators = (value) => (dispatch, state) => {
	let currentState = state()
	dispatch({ type: SET_FILTER_INVESTIGATORS_REQUESTED, data: value })
	if(!value) {
		updateFilters(2, currentState, dispatch)
	}
}

const SET_INCLUDED_INVESTIGATORS_REQUESTED = 'SET_DASHBOARD_INCLUDED_INVESTIGATORS_REQUESTED'
export const setDashboardIncludedInvestigators = (value) => (dispatch, state) => {
	let currentState = state()
	dispatch({ type: SET_INCLUDED_INVESTIGATORS_REQUESTED, data: value })
	updateFilters(2, currentState, dispatch)
}

const SET_FILTER_FUNDERS_REQUESTED = 'SET_DASHBOARD_FILTER_FUNDERS_REQUESTED'
export const setDashboardFilterFunders = (value) => (dispatch, state) => {
	let currentState = state()
	dispatch({ type: SET_FILTER_FUNDERS_REQUESTED, data: value })
	if(!value) {
		updateFilters(0, currentState, dispatch)
	}
}

const SET_INCLUDED_FUNDERS_REQUESTED = 'SET_DASHBOARD_INCLUDED_FUNDERS_REQUESTED'
export const setDashboardIncludedFunders = (value) => (dispatch, state) => {
	let currentState = state()
	dispatch({ type: SET_INCLUDED_FUNDERS_REQUESTED, data: value })
	updateFilters(0, currentState, dispatch)
}

const SET_FILTER_CIRCLES_REQUESTED = 'SET_DASHBOARD_FILTER_CIRCLES_REQUESTED'
export const setDashboardFilterCircles = (value) => (dispatch, state) => {
	let currentState = state()
	dispatch({ type: SET_FILTER_CIRCLES_REQUESTED, data: value })
	if(!value) {
		updateFilters(1, currentState, dispatch)
	}
}

const SET_INCLUDED_CIRCLES_REQUESTED = 'SET_DASHBOARD_INCLUDED_CIRCLES_REQUESTED'
export const setDashboardIncludedCircles = (value) => (dispatch, state) => {
	let currentState = state()
	dispatch({ type: SET_INCLUDED_CIRCLES_REQUESTED, data: value })
	updateFilters(1, currentState, dispatch)
}

const FETCH_FUNDERS_FOR_DASHBOARD_REQUESTED = 'FETCH_FUNDERS_FOR_DASHBOARD_REQUESTED'
const FETCH_FUNDERS_FOR_DASHBOARD_SUCCESS = 'FETCH_FUNDERS_FOR_DASHBOARD_SUCCESS'
const FETCH_FUNDERS_FOR_DASHBOARD_FAILED = 'FETCH_FUNDERS_FOR_DASHBOARD_FAILED'

export const fetchFundersForDashboard = () => (dispatch, state) => {
	dispatch({ type: FETCH_FUNDERS_FOR_DASHBOARD_REQUESTED, data: {} })

	let currentState = state()
	let circles = currentState.dashboard.filterCircles ? currentState.dashboard.includedCircles : []
	let users = currentState.dashboard.filterInvestigators ? currentState.dashboard.includedInvestigators : []

	api.fetchFunders(circles, users).then((funders) => {
		if (funders) {
			dispatch({ type: FETCH_FUNDERS_FOR_DASHBOARD_SUCCESS, data: funders })
		} else {
			return dispatch({ type: FETCH_FUNDERS_FOR_DASHBOARD_FAILED, data: {} })
		}
	})
	return
}

const FETCH_CIRCLES_FOR_DASHBOARD_REQUESTED = 'FETCH_CIRCLES_FOR_DASHBOARD_REQUESTED'
const FETCH_CIRCLES_FOR_DASHBOARD_SUCCESS = 'FETCH_CIRCLES_FOR_DASHBOARD_SUCCESS'
const FETCH_CIRCLES_FOR_DASHBOARD_FAILED = 'FETCH_CIRCLES_FOR_DASHBOARD_FAILED'

export const fetchCirclesForDashboard = () => (dispatch, state) => {
	dispatch({ type: FETCH_CIRCLES_FOR_DASHBOARD_REQUESTED, data: {} })

	let currentState = state()
	let funders = currentState.dashboard.filterFunders ? currentState.dashboard.includedFunders : []
	let users = currentState.dashboard.filterInvestigators ? currentState.dashboard.includedInvestigators : []

	api.fetchCircles(funders, users).then((circles) => {
		if (circles) {
			dispatch({ type: FETCH_CIRCLES_FOR_DASHBOARD_SUCCESS, data: circles })
		} else {
			return dispatch({ type: FETCH_CIRCLES_FOR_DASHBOARD_FAILED, data: {} })
		}
	})
	return
}

const FETCH_INVESTIGATORS_FOR_DASHBOARD_REQUESTED = 'FETCH_INVESTIGATORS_FOR_DASHBOARD_REQUESTED'
const FETCH_INVESTIGATORS_FOR_DASHBOARD_SUCCESS = 'FETCH_INVESTIGATORS_FOR_DASHBOARD_SUCCESS'
const FETCH_INVESTIGATORS_FOR_DASHBOARD_FAILED = 'FETCH_INVESTIGATORS_FOR_DASHBOARD_FAILED'

export const fetchInvestigatorsForDashboard = () => (dispatch, state) => {
	dispatch({ type: FETCH_INVESTIGATORS_FOR_DASHBOARD_REQUESTED, data: {} })

	let currentState = state()
	let funders = currentState.dashboard.filterFunders ? currentState.dashboard.includedFunders : []
	let circles = currentState.dashboard.filterCircles ? currentState.dashboard.includedCircles : []

	api.fetchInvestigators(funders, circles).then((investigators) => {
		if (investigators) {
			dispatch({ type: FETCH_INVESTIGATORS_FOR_DASHBOARD_SUCCESS, data: investigators })
		} else {
			return dispatch({ type: FETCH_INVESTIGATORS_FOR_DASHBOARD_FAILED, data: {} })
		}
	})
	return
}

const EXPORT_DASHBOARD_REPORT_REQUESTED = 'EXPORT_DASHBOARD_REPORT_REQUESTED'
const EXPORT_DASHBOARD_REPORT_SUCCESS = 'EXPORT_DASHBOARD_REPORT_SUCCESS'
const EXPORT_DASHBOARD_REPORT_FAILED = 'EXPORT_DASHBOARD_REPORT_FAILED'

export const exportDashboardReport = () => (dispatch, state) => {
	dispatch({ type: EXPORT_DASHBOARD_REPORT_REQUESTED, data: {} })

	let currentState = state()
	let values = {
		funders: currentState.dashboard.filterFunders ? currentState.dashboard.includedFunders : currentState.dashboard.funders.map(x => x.id),
		circles: currentState.dashboard.filterCircles ? currentState.dashboard.includedCircles : [],
		investigators: currentState.dashboard.filterInvestigators ? currentState.dashboard.includedInvestigators : [],
		periodFrom: currentState.dashboard.periodFrom,
		periodTo: currentState.dashboard.periodTo,
	}
	
	api.exportReport(values).then((investigators) => {
		if (investigators) {
			dispatch({ type: EXPORT_DASHBOARD_REPORT_SUCCESS, data: {} })
			return dispatch(setNewReportInitiated())
		} else {
			dispatch({ type: EXPORT_DASHBOARD_REPORT_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.exportFailed', isSuccess: false }
			})
		}
	})
	return
}

const initial = {
	dashBoardExportFilters: [],
	periodFrom: null,
	periodTo: null,
	filterInvestigators: false,
	includedInvestigators: [],
	filterFunders: false,
	includedFunders: [],
	filterCircles: false,
	includedCircles: [],
	funders: [],
	circles: [],
	investigators: []
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case SET_DASHBOARD_EXPORT_FILTERS_REQUESTED:
			return {
				...state,
				dashBoardExportFilters: action.data
			}
		case SET_PERIOD_FROM_FILTER_REQUESTED:
			return  {
				...state,
				periodFrom: action.data
			}
		case SET_PERIOD_TO_FILTER_REQUESTED:
			return  {
				...state,
				periodTo: action.data
			}
		case SET_ONBOARDING_PERIOD_FROM_FILTER_REQUESTED:
			return  {
				...state,
				onboardingPeriodFrom: action.data
			}
		case SET_ONBOARDING_PERIOD_TO_FILTER_REQUESTED:
			return  {
				...state,
				onboardingPeriodTo: action.data
			}
		case SET_DATAPOINTS_PERIOD_FROM_FILTER_REQUESTED:
			return  {
				...state,
				datapointsPeriodFrom: action.data
			}
		case SET_DATAPOINTS_PERIOD_TO_FILTER_REQUESTED:
			return  {
				...state,
				datapointsPeriodTo: action.data
			}
		case SET_FILTER_INVESTIGATORS_REQUESTED:
			return {
				...state,
				filterInvestigators: action.data
			}
		case SET_INCLUDED_INVESTIGATORS_REQUESTED:
			return {
				...state,
				includedInvestigators: action.data
			}
		case SET_FILTER_FUNDERS_REQUESTED:
			return {
				...state,
				filterFunders: action.data
			}
		case SET_INCLUDED_FUNDERS_REQUESTED:
			return {
				...state,
				includedFunders: action.data
			}
		case SET_FILTER_CIRCLES_REQUESTED:
			return {
				...state,
				filterCircles: action.data
			}
		case SET_INCLUDED_CIRCLES_REQUESTED:
			return {
				...state,
				includedCircles: action.data
			}
		case FETCH_FUNDERS_FOR_DASHBOARD_FAILED:
			return {
				...state,
				funders: []
			}
		case FETCH_FUNDERS_FOR_DASHBOARD_SUCCESS:
			return {
				...state,
				funders: action.data
			}
		case FETCH_CIRCLES_FOR_DASHBOARD_FAILED:
			return {
				...state,
				circles: []
			}
		case FETCH_CIRCLES_FOR_DASHBOARD_SUCCESS:
			return {
				...state,
				circles: action.data
			}
		case FETCH_INVESTIGATORS_FOR_DASHBOARD_FAILED:
			return {
				...state,
				investigators: []
			}
		case FETCH_INVESTIGATORS_FOR_DASHBOARD_SUCCESS:
			return {
				...state,
				investigators: action.data
			}
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.dashboard

export const dashboardExportFiltersSelector = createSelector(mainSelector, (state) => {
	return state && state.dashBoardExportFilters
})

export const dashboardPeriodFromFilterSelector = createSelector(mainSelector, (state) => {
	return state && state.periodFrom
})

export const dashboardPeriodToFilterSelector = createSelector(mainSelector, (state) => {
	return state && state.periodTo
})

export const onboardingPeriodFromFilterSelector = createSelector(mainSelector, (state) => {
	return state && state.onboardingPeriodFrom
})

export const onboardingPeriodToFilterSelector = createSelector(mainSelector, (state) => {
	return state && state.onboardingPeriodTo
})

export const datapointsPeriodFromFilterSelector = createSelector(mainSelector, (state) => {
	return state && state.datapointsPeriodFrom
})

export const datapointsPeriodToFilterSelector = createSelector(mainSelector, (state) => {
	return state && state.datapointsPeriodTo
})

export const dashboardFilterInvestigatorsSelector = createSelector(mainSelector, (state) => {
	return state && state.filterInvestigators
})

export const dashboardIncludedInvestigatorsSelector = createSelector(mainSelector, (state) => {
	return state && state.includedInvestigators
})

export const dashboardFilterFundersSelector = createSelector(mainSelector, (state) => {
	return state && state.filterFunders
})

export const dashboardIncludedFundersSelector = createSelector(mainSelector, (state) => {
	return state && state.includedFunders
})

export const dashboardFilterCirclesSelector = createSelector(mainSelector, (state) => {
	return state && state.filterCircles
})

export const dashboardIncludedCirclesSelector = createSelector(mainSelector, (state) => {
	return state && state.includedCircles
})

export const fundersForDashboardSelector = createSelector(mainSelector, (state) => {
	return state && state.funders
})

export const circlesForDashboardSelector = createSelector(mainSelector, (state) => {
	return state && state.circles
})

export const investigatorsForDashboardSelector = createSelector(mainSelector, (state) => {
	return state && state.investigators
})