import React from 'react'
import Grid from '@material-ui/core/Grid'
import FilledDateTextFieldLight from '../../FilledDateTextFieldLight'
import Tag from './Tag'
import QuestionContainer from './QuestionContainer'
import RequiresTranslationBadge from '../../RequiresTranslationBadge'
export default ({
	question,
	surveyEntity,
	position,
	positionDisplay
}) => {
	return (
		<Grid data-testid="date-question-survey-component" direction='column' container>

			<Grid item>
				<Tag tag={question.tag} />
			</Grid>
			<Grid item>
				<QuestionContainer
					question={question}
					surveyEntity={surveyEntity}
					children={
						<FilledDateTextFieldLight
							style={{ backgroundColor: 'white', color: 'black' }}
							variant="filled"
							InputProps={{
								readOnly: true
							}}
						/>
					}
				/>
			</Grid>{' '}
			{question &&
			!question.id && (
				<Grid item style={{ marginTop: '10px' }}>
					<RequiresTranslationBadge />
				</Grid>
			)}
		</Grid>

	)
}
