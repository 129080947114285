import { authenticatedFetch } from './base'
import { updateQuestion } from './question'

export const autocompleteSearch = (searchPhrase, surveyId) => {
	var url = `/api/bundle`

	if (searchPhrase && searchPhrase.length > 0) {
		url = `/api/bundle/autocomplete?searchPhrase=${encodeURIComponent(searchPhrase)}`

		if(surveyId){
			url += `&surveyId=${surveyId}`
		}
	}

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const addBundle = (name) => {
	return authenticatedFetch(`/api/bundle`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			name: name
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const editBundle = (id, name, tagName, description) => {
	return authenticatedFetch(`/api/bundle/${id}?name=${name}`, {
		method: 'put',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			name: name,
			tagName: tagName,
			description: description
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchBundle = (id) => {
	return authenticatedFetch(`/api/bundle/${id}`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(prom) {
			return prom
		})
}

export const deleteBundle = (id) => {
	return authenticatedFetch(`/api/bundle/${id}`, { method: 'DELETE' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(prom) {
			return prom
		})
}

export const fetchBundles = (searchPhrase, offset) => {
	var url = `/api/bundle`
	let urlHasParams = false

	if (searchPhrase && searchPhrase.length > 0) {
		url = `${url}?searchPhrase=${encodeURIComponent(searchPhrase)}`
		urlHasParams = true
	}

	if(offset){
		url += `${urlHasParams ? '&' : '?'}offset=${offset}`
		urlHasParams = true
	}

	return authenticatedFetch(url, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(prom) {
			return prom
		})
}

export const fetchBundleQuestions = (id, languageId, archivedQuestionsVisible) => {
	return authenticatedFetch(`/api/bundle/${id}/question/languages/${languageId}/archivedQuestionsVisible/${archivedQuestionsVisible}`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(prom) {
			return prom
		})
}

export const addExistingBundleQuestion = (id, type, position, bundleId, commentsObj) => {
	return authenticatedFetch(`/api/bundle/${bundleId}/question/${id}?sortOrder=${position}&type=${type}`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			commentText: commentsObj?.commentText,
			commentProtocolVersionId: commentsObj?.protocolVersionId,
			commentRelatedTaskUrl: commentsObj?.relatedTaskUrl,
			commentInvestigatorId: commentsObj?.investigatorId
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const addBundleQuestion = (question, position, id, isClone = false, commentsObj) => {
	let url = `/api/bundle/${id}/`

	let data = {}
	data.tag = question.tag
	data.title = question.title
	data.instructions = typeof question.instructions !== 'undefined' ? question.instructions : ''
	data.canonicalId = question.canonicalId
	data.isOptional = typeof question.isOptional === 'undefined' ? false : question.isOptional
	data.baseId = !!isClone ? question.baseId : null
	data.comments = {}
	data.comments.commentText = commentsObj?.commentText
	data.comments.commentProtocolVersionId = commentsObj?.protocolVersionId
	data.comments.commentRelatedTaskUrl = commentsObj?.relatedTaskUrl
	data.comments.commentInvestigatorId = commentsObj?.investigatorId

	switch (question.type) {
		case 'single':
			url = url + 'single'
			data.answers = []			
			data.isConsentRequired = question.isConsentRequired
			data.isCodeQuestion = question.isCodeQuestion
			data.questionCode = question.questionCode
			data.isDropdown = question.isDropdown
			data.displayInstructionAsIcon = question.displayInstructionAsIcon

			for (let index = 0; index < question.answers.length; index++) {
				const answer = question.answers[index].answer
				data.answers.push({
					label: answer.answer,
					value: parseFloat(answer.value).toFixed(10),
					code: answer.code,
					sortOrder: question.answers[index].sortOrder,
					tag: question.answers[index].tag,
				})
			}

			break
		case 'multi':
			url = url + 'multi'
			data.answers = []
			data.isConsentRequired = question.isConsentRequired
			data.isCodeQuestion = question.isCodeQuestion
			data.questionCode = question.questionCode
			data.isDropdown = question.isDropdown
			data.displayInstructionAsIcon = question.displayInstructionAsIcon
			
			for (let index = 0; index < question.answers.length; index++) {
				const answer = question.answers[index].answer
				data.answers.push({
					label: answer.answer,
					value: parseFloat(answer.value).toFixed(10),
					code: answer.code,
					sortOrder: question.answers[index].sortOrder,
					tag: question.answers[index].tag
				})
			}

			break
		case 'grouped':
			url = url + 'grouped'
			data.categories = []
			data.range = question.range
			data.prefix = question.prefix
			data.isDropdown = question.isDropdown
			data.displayInstructionAsIcon = question.displayInstructionAsIcon
			data.minimumValue = question.minimumValue
			for (let index = 0; index < question.answers.length; index++) {
				const answer = question.answers[index].answer
				data.categories.push({
					label: answer.question,
					tag: answer.tag,
					sortOrder: question.answers[index].sortOrder
				})
			}

			break
		case 'tf':
			url = url + 'tf'
			break
		case 'text':
			url = url + 'text'
			data.isRegularExpressionEnabled = question.isRegularExpressionEnabled
			data.regularExpression = question.regularExpression
			data.regularExpressionValidation = question.regularExpressionValidation
			data.isConsentRequired = question.isConsentRequired
			data.displayInstructionAsIcon = question.displayInstructionAsIcon
			break
		case 'file':
			url = url + 'file'
			data.fileType = question.fileType
			data.hideImageForPatient = question.hideImageForPatient
			data.displayInstructionAsIcon = question.displayInstructionAsIcon
			break
		case 'date':
			url = url + 'date'
			data.isRegularExpressionEnabled = question.isRegularExpressionEnabled
			data.regularExpression = question.regularExpression
			data.regularExpressionValidation = question.regularExpressionValidation
			data.isConsentRequired = question.isConsentRequired
			data.displayInstructionAsIcon = question.displayInstructionAsIcon
			break
		case 'number':
			url = url + 'number'
			data.unitId = question.numberType
			data.digits = question.precision
			data.minimum = question.minimum
			data.maximum = question.maximum
			data.isRegularExpressionEnabled = question.isRegularExpressionEnabled
			data.regularExpression = question.regularExpression
			data.regularExpressionValidation = question.regularExpressionValidation
			data.isConsentRequired = question.isConsentRequired
			data.displayInstructionAsIcon = question.displayInstructionAsIcon
			break
		case 'analog':
			var model = new FormData()
			url = url + 'analog'

			if (!!isClone) {
				model.append('baseId', question.baseId)

				url = url + '/clone'
			}

			model.append('isOptional', data.isOptional)
			model.append('startImageFile', question.startImage)
			if (
				!question.startImage
				&& question.startImagePath?.length > 0
			) {
				model.append('startImagePath', question.startImagePath)
			}
			model.append('endImageFile', question.endImage)
			if (
				!question.endImage
				&& question.endImagePath?.length > 0
			) {
				model.append('endImagePath', question.endImagePath)
			}
			model.append('startValue', question.startValue)
			model.append('endValue', question.endValue)
			model.append('stepValue', question.stepValue)
			model.append('startText', !!question.startText ? question.startText : "")
			model.append('endText', !!question.endText ? question.endText : "")
			model.append('displayInstructionAsIcon', question.displayInstructionAsIcon)
			if (question.tag) {
				model.append('tag', question.tag)
			}
			model.append('title', question.title)
			if (question.instructions) {
				model.append('instructions', question.instructions)
			}
			if (question.canonicalId) {
				model.append('canonicalId', question.canonicalId)
			}

			model.append('commentText', commentsObj?.commentText)
			model.append('commentProtocolVersionId', commentsObj?.protocolVersionId)
			model.append('commentRelatedTaskUrl', commentsObj?.relatedTaskUrl)
			model.append('commentInvestigatorId', commentsObj?.investigatorId)

			if (position) {
				url = url + `?sortOrder=${position}`
			}

			return authenticatedFetch(url, {
				method: 'POST',
				body: model
			})
				.then(function(response) {
					if (response.status === 200) {
						return response.json()
					}
				})
				.then(function(patient) {
					return patient
				})
		case 'content':
			url = url + 'copy'
			data = {}
			data.canonicalId = question.canonicalId
			data.subtitle = question.subtitle
			data.bodyCopy = question.bodyCopy
			data.baseId = question.baseId
			data.comments = {}
			data.comments.commentText = commentsObj?.commentText
			data.comments.commentProtocolVersionId = commentsObj?.protocolVersionId
			data.comments.commentRelatedTaskUrl = commentsObj?.relatedTaskUrl
			data.comments.commentInvestigatorId = commentsObj?.investigatorId
			break
	}

	if (!!isClone) {
		url = url + '/clone'
	}

	if (position) {
		url = url + `?sortOrder=${position}`
	}

	return authenticatedFetch(url, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(data)
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const saveFormula = (id, formula, upperFence, lowerFence) => {
	return authenticatedFetch(`/api/scoring/${id}/formula`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			elements: formula,
			upperFence: upperFence,
			lowerFence: lowerFence
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const swapQuestionPosition = (id, leftQuestionId, leftQuestionType, rightQuestionId, rightQuestionType) => {
	return authenticatedFetch(`/api/bundle/${id}/question/swap/l/${leftQuestionId}/${leftQuestionType}/r/${rightQuestionId}/${rightQuestionType}`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const removeQuestion = (id, questionId, type) => {
	return authenticatedFetch(`/api/bundle/${id}/question/${questionId}/type/${type}`, {
		method: 'DELETE',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const updateQuestionIsOptional = (id, questionId, type, isOptional = false, commentsObj) => {
	return authenticatedFetch(`/api/bundle/${id}/question/${questionId}/type/${type}/isoptional/${isOptional}`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			commentText: commentsObj?.commentText,
			commentProtocolVersionId: commentsObj?.protocolVersionId,
			commentRelatedTaskUrl: commentsObj?.relatedTaskUrl,
			commentInvestigatorId: commentsObj?.investigatorId
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const saveSurvey = (name) => {
	return authenticatedFetch(`/api/scoring`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			name: name
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const getQuestionUseStatus = (bundleId, questionId, questionType) => {
	var url = `/api/bundle/${bundleId}/question/${questionId}/questionType/${questionType}/isInUse`

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}


export const getBundlesByQuestion = (questionId, questionType) => {
	var url = `/api/bundle/question/${questionId}/questionType/${questionType}`

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const updateBundleQuestionState = (bundleId, questionId, questionTypeId, newState, commentsObj) => {
	return authenticatedFetch(`/api/bundle/${bundleId}/changeQuestionState`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			questionId: questionId,
			questionType: questionTypeId,
			newState : newState,
			comments: { 
				commentText: commentsObj?.commentText,
				commentProtocolVersionId: commentsObj?.protocolVersionId,
				commentRelatedTaskUrl: commentsObj?.relatedTaskUrl,
				commentInvestigatorId: commentsObj?.investigatorId
			}
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const updateBundleQuestionStateRelatedEntities = (bundleId, questionId, questionTypeId, newState, commentsObj) => {
	return authenticatedFetch(`/api/bundle/${bundleId}/changeQuestionStateRelatedEntities`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			questionId: questionId,
			questionType: questionTypeId,
			newState : newState,
			comments: {
				commentText: commentsObj?.commentText,
				commentProtocolVersionId: commentsObj?.protocolVersionId,
				commentRelatedTaskUrl: commentsObj?.relatedTaskUrl,
				commentInvestigatorId: commentsObj?.investigatorId
			}
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const cloneBundle = (id, name, prefix) => {
	return authenticatedFetch(`/api/bundle/${id}/clone`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			name,
			prefix
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const updateBundleQuestion = (bundleId, question, languageId, commentsObj) => {
	return updateQuestion(question, languageId, `/api/bundle/${bundleId}/`, commentsObj)
}

export const fetchBundleChangeLogs = (bundleId, searchPhrase, keys) => {
	var url = `/api/bundle/${bundleId}/changeLogs`

	url = `${url}?searchPhrase=${!!searchPhrase?.length && encodeURIComponent(searchPhrase) || ''}`

	if (!!keys?.length) {
		keys.forEach(x => url = `${url}&keys=${x}`)
	}

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(data) {
			return data
		})
}
