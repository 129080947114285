import React, { Fragment } from 'react'
import { List, ListItem, ListItemText, makeStyles, useTheme, Divider } from '@material-ui/core'
import { compose } from 'recompose'
import { connect } from 'react-redux'


const useListItemStyles = makeStyles((theme) => ({
	secondary: {
		color: theme.palette.text.disabled
	}
}))

const FunctionList = ({
    onFunctionClicked
}) => {
    const theme = useTheme()
    const listItemStyles = useListItemStyles(theme)

    const functionList = [
        {
            title: "avg",
            description: "avg2(tag1, tag2) ... avg{n}(tag1, tag2, ... tag{n})",
            value: "avg2("
        }
    ]

    const handleFunctionItemClicked = (item) => {
        if (!!onFunctionClicked) {
            onFunctionClicked(item.value)
        }
    }

    const renderFunctionItems = (items) => {
        return items.map((item, index) => {
            return (
                <Fragment>
                    {
                        index > 0
                        && <Divider component="li" />
                    }
                    <ListItem
                        button
                        onClick={_ => handleFunctionItemClicked(item)}
                    >
                        <ListItemText
                            primary={item.title.toUpperCase()}
                            secondary={item.description}
                            classes={listItemStyles}
                        />
                    </ListItem>
                </Fragment>
            )
        })
    }

    return (
        <List
            dense
        >
            {renderFunctionItems(functionList)}
        </List>
    )
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(FunctionList)
