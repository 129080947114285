import * as subscription from '../../../apis/subscription'
import { createSelector } from 'reselect'
import * as cookies from '../../../utilities/cookies'
import { hideModal } from '../core'

const FETCH_SUBSCRIPTION_REQUESTED = 'FETCH_SUBSCRIPTION_REQUESTED'
const FETCH_SUBSCRIPTION_SUCCESS = 'FETCH_SUBSCRIPTION_SUCCESS'
const FETCH_SUBSCRIPTION_FAILED = 'FETCH_SUBSCRIPTION_FAILED'

const SUBSCRIPTION_READY = 'SUBSCRIPTION_READY'

export const fetchSubscription = (forceUpdate) => (dispatch, getState) => {
	let state = getState()
	if (state.subscription.subscription && !forceUpdate) {
		return dispatch({ type: SUBSCRIPTION_READY, data: {} })
	} else {
		dispatch({ type: FETCH_SUBSCRIPTION_REQUESTED, data: {} })
		subscription.fetchSubscription().then((subscription) => {
			if (subscription) {
				return dispatch({ type: FETCH_SUBSCRIPTION_SUCCESS, data: subscription })
			} else {
				return dispatch({ type: FETCH_SUBSCRIPTION_FAILED, data: {} })
			}
		})
		return
	}
}

const FETCH_PLAN_REQUESTED = 'FETCH_PLAN_REQUESTED'
const FETCH_PLAN_SUCCESS = 'FETCH_PLAN_SUCCESS'
const FETCH_PLAN_FAILED = 'FETCH_PLAN_FAILED'

export const fetchPlan = (id) => (dispatch, getState) => {
	dispatch({ type: FETCH_PLAN_REQUESTED, data: {} })
	subscription.fetchPlan(id).then((response) => {
		if (response && response.isSuccessful) 
		{
			return dispatch({ type: FETCH_PLAN_SUCCESS, data: response })
		} 
		else 
		{
			return dispatch({ type: FETCH_PLAN_FAILED, data: {} })
		}
	})
}

const FETCH_SUBSCRIPTION_USER_CASE_REQUESTED = 'FETCH_SUBSCRIPTION_USER_CASE_REQUESTED'
const FETCH_SUBSCRIPTION_USER_CASE_SUCCESS = 'FETCH_SUBSCRIPTION_USER_CASE_SUCCESS'
const FETCH_SUBSCRIPTION_USER_CASE_FAILED = 'FETCH_SUBSCRIPTION_USER_CASE_FAILED'

export const fetchSubscriptionUsersCases = (userId) => (dispatch, getState) => {
	let state = getState()

	dispatch({ type: FETCH_SUBSCRIPTION_USER_CASE_REQUESTED, data: {} })
	subscription.fetchSubscriptionUsersCases(userId).then((subscription) => {
		if (subscription && subscription.isSuccessful) {
			return dispatch({ type: FETCH_SUBSCRIPTION_USER_CASE_SUCCESS, data: subscription })
		} else {
			return dispatch({ type: FETCH_SUBSCRIPTION_USER_CASE_FAILED, data: {} })
		}
	})
	return
}

const FETCH_SUBSCRIPTION_CASE_REQUESTED = 'FETCH_SUBSCRIPTION_CASE_REQUESTED'
const FETCH_SUBSCRIPTION_CASE_SUCCESS = 'FETCH_SUBSCRIPTION_CASE_SUCCESS'
const FETCH_SUBSCRIPTION_CASE_FAILED = 'FETCH_SUBSCRIPTION_CASE_FAILED'

export const fetchSubscriptionCases = () => (dispatch, getState) => {
	let state = getState()
	if (state.subscription.subscriptionCase) {
		return dispatch({ type: SUBSCRIPTION_READY, data: {} })
	} else {
		dispatch({ type: FETCH_SUBSCRIPTION_CASE_REQUESTED, data: {} })
		subscription.fetchSubscriptionCases().then((subscription) => {
			if (subscription) {
				return dispatch({ type: FETCH_SUBSCRIPTION_CASE_SUCCESS, data: subscription })
			} else {
				return dispatch({ type: FETCH_SUBSCRIPTION_CASE_FAILED, data: {} })
			}
		})
		return
	}
}

const FETCH_SUBSCRIPTION_USER_REQUESTED = 'FETCH_SUBSCRIPTION_USER_REQUESTED'
const FETCH_SUBSCRIPTION_USER_SUCCESS = 'FETCH_SUBSCRIPTION_USER_SUCCESS'
const FETCH_SUBSCRIPTION_USER_FAILED = 'FETCH_SUBSCRIPTION_USER_FAILED'

export const fetchSubscriptionUsers = () => (dispatch, getState) => {
	let state = getState()
	if (state.subscription.subscriptionUser) {
		return dispatch({ type: SUBSCRIPTION_READY, data: {} })
	} else {
		dispatch({ type: FETCH_SUBSCRIPTION_USER_REQUESTED, data: {} })
		subscription.fetchSubscriptionUsers().then((subscription) => {
			if (subscription) {
				return dispatch({ type: FETCH_SUBSCRIPTION_USER_SUCCESS, data: subscription })
			} else {
				return dispatch({ type: FETCH_SUBSCRIPTION_USER_FAILED, data: {} })
			}
		})
		return
	}
}

const APPLY_CREDIT_CHANGES_REQUESTED = 'APPLY_CREDIT_CHANGES_REQUESTED'
const APPLY_CREDIT_CHANGES_SUCCESS = 'APPLY_CREDIT_CHANGES_SUCCESS'
const APPLY_CREDIT_CHANGES_FAILED = 'APPLY_CREDIT_CHANGES_FAILED'

export const applyCreditChanges = (id, cases, users) => (dispatch, getState) => {
	dispatch({ type: APPLY_CREDIT_CHANGES_REQUESTED, data: {} })
	subscription.applyCreditChanges(id, cases, users).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.successfullyUpdated', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.UpdateFailed', isSuccess: false }
			})
		}
	})
	return
}

const APPLY_CHANGES_REQUESTED = 'APPLY_CHANGES_REQUESTED'
const APPLY_CHANGES_SUCCESS = 'APPLY_CHANGES_SUCCESS'
const APPLY_CHANGES_FAILED = 'APPLY_CHANGES_FAILED'

export const applyChanges = (id, expiration, sponsorId) => (dispatch, getState) => {
	dispatch({ type: APPLY_CHANGES_REQUESTED, data: {} })
	
	subscription.applyChanges(id, expiration, sponsorId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.successfullyUpdated', isSuccess: true }
			})
		} else {

			if(response && response.errorCode === 28)
			{
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { 
						message: `Sponsor for this subscription cannot be changed as the user is already sponsored by the circle ${response.errorMessage}. you need to stop sponsoring of this subscriber within the circle before assigning a new subscription.`,
						//'app.subscription.alreadyHasCircleSubscription',
						isSuccess: false 
					}
				})
			}
			else
			{
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.general.UpdateFailed', isSuccess: false }
				})	
			}
			
		}
	})
	return
}

const FETCH_CIRCLE_USER_CASE_REQUESTED = 'FETCH_CIRCLE_USER_CASE_REQUESTED'
const FETCH_CIRCLE_USER_CASE_SUCCESS = 'FETCH_CIRCLE_USER_CASE_SUCCESS'
const FETCH_CIRCLE_USER_CASE_FAILED = 'FETCH_CIRCLE_USER_CASE_FAILED'

export const fetchCircleUsersCases = (circleId) => (dispatch, getState) => {
	dispatch({ type: FETCH_CIRCLE_USER_CASE_REQUESTED, data: {} })
	subscription.fetchCircleUsersCases(circleId).then((circleInfo) => {
		if (circleInfo) {
			return dispatch({ type: FETCH_CIRCLE_USER_CASE_SUCCESS, data: circleInfo })
		} else {
			return dispatch({ type: FETCH_CIRCLE_USER_CASE_FAILED, data: {} })
		}
	})
	return
}

const EXPIRE_SUBSCRIPTION_REQUESTED = 'EXPIRE_SUBSCRIPTION_REQUESTED'
const EXPIRE_SUBSCRIPTION_SUCCESS = 'EXPIRE_SUBSCRIPTION_SUCCESS'
const EXPIRE_SUBSCRIPTION_FAILED = 'EXPIRE_SUBSCRIPTION_FAILED'

export const expireSubscription = (userId) => (dispatch) => {
	dispatch({ type: EXPIRE_SUBSCRIPTION_REQUESTED, data: {} })
	
	subscription.expireSubscription(userId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.successfullyUpdated', isSuccess: true }
			})
		} else {
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.general.UpdateFailed', isSuccess: false }
				})	
		}
	})
	return
}

const initial = {
	subscription: null,
	subscriptionUser: null,
	subscriptionCase: null,
	subscriptionUserCaseSuccess: null,
	circleUser: null,
	circleCase: null,
	subscriptionInfo: { totalCases: 0, totalUsers: 0 }
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_PLAN_REQUESTED:
			return { ...state, plan: {} }
		case FETCH_PLAN_SUCCESS:
			return { ...state, plan: action.data }
		case FETCH_SUBSCRIPTION_SUCCESS:
			return { ...state, subscription: action.data }
		case FETCH_SUBSCRIPTION_USER_CASE_SUCCESS:
			return {
				...state,
				subscriptionUser: action.data.subscriptionUserInfo,
				subscriptionCase: action.data.subscriptionCaseInfo,
				subscriptionUserCaseSuccess: true,
				subscriptionInfo: {
					cases: action.data.subscriptionUserInfo.totalUsers,
					users: action.data.subscriptionCaseInfo.totalCases,
					expiration: action.data.expiration
				}
			}
		case FETCH_SUBSCRIPTION_CASE_SUCCESS:
			return { ...state, subscriptionCase: action.data.subscriptionCaseInfo }
		case FETCH_SUBSCRIPTION_USER_SUCCESS:
			return { ...state, subscriptionUser: action.data.subscriptionUserInfo }
		case FETCH_CIRCLE_USER_CASE_SUCCESS:
			return {
				...state,
				circleUser: action.data.circleUserInfo,
				circleCase: action.data.circleCaseInfo
			}
		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}
export const planSelector = (state) => state.subscription.plan
export const subscriptionSelector = (state) => state.subscription.subscription
export const subscriptionUserSelector = (state) => state.subscription.subscriptionUser
export const subscriptionCaseSelector = (state) => state.subscription.subscriptionCase
export const subscriptionUserCaseSuccessSelector = (state) => state.subscription.subscriptionUserCaseSuccess
export const circleUserSelector = (state) => state.subscription.circleUser
export const circleCaseSelector = (state) => state.subscription.circleCase
export const subscriptionInfoSelector = (state) => state.subscription.subscriptionInfo
