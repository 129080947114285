import React, { Fragment } from "react"
import { Table, TableBody, TableCell, TableRow, makeStyles, useTheme } from "@material-ui/core"
import { useIntl } from "react-intl"
import EntityAuditTableCell from "./EntityAuditTableCell"


const useStyles = makeStyles(theme => ({
    lastRow: {
      '&:last-child td': {
        borderBottom: 'none', // Remove bottom border for the last row
      },
    },
  }));


const OrderedItemsTable = ({items, isOldItems}) => {
    return (
        <Fragment>
            {
                !!items
                && <Table>
                    <TableBody>
                        {
                            items.map(x => (
                                <TableRow>
                                    <TableCell
                                        size='small'
                                        style={{
                                            color: x.IsCurrent ? (isOldItems ? '#ff6020' : '#60ff20') : '#303030',
                                            width: '33%',
                                            wordBreak: 'break-word' 
                                        }}
                                    >
                                        { x.Title }
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            }
            {
                !items
                && <div />
            }
        </Fragment>
    )
}


const EntityAuditValueTableCell = ({entityLog, ...other}) => {
    const intl = useIntl()

    const classes = useStyles();


    const getValue = (object, propertyName) => {
        if (!object) {
            return ''
        }

        let value = object[propertyName]

        if (value == undefined || value == null) {
            return ''
        }

        return value
    }

    const drawValues = (newObject, oldObject, propertyName) => {
        const key = propertyName.substring(0, 1).toLowerCase() + propertyName.substring(1)

        let newValue = getValue(newObject, propertyName)

        let oldValue = getValue(oldObject, propertyName)

        if (oldValue === newValue) {
            oldValue = ''
        }

        if (key === 'questionType') {
            newValue = intl.formatMessage({ id: `app.auditTable.questionType.${newValue}` })
        }
        else if (key === 'surveyType') {
            newValue = intl.formatMessage({ id: `app.auditTable.surveyType.${newValue}` })

            if (oldValue !== null && oldValue !== undefined && oldValue !== '') {
                oldValue = intl.formatMessage({ id: `app.auditTable.surveyType.${oldValue}` })
            }
        }
        else if (key === 'isReadOnlyAccess') {
            newValue = intl.formatMessage({ id: `app.auditTable.isReadOnlyAccess.${newValue}` })

            if (oldValue !== null && oldValue !== undefined && oldValue !== '') {
                oldValue = intl.formatMessage({ id: `app.auditTable.isReadOnlyAccess.${oldValue}` })
            }
        }
        else if (key === 'sortedItems') {
            return (
                <TableRow>
                    <EntityAuditTableCell size='small' style={{ width: '34%', fontWeight: '600' }}>
                        { intl.formatMessage({ id: `app.auditTable.entity.${entityLog.entityTypeId}.keys.${key}` }) }
                    </EntityAuditTableCell>
                    <EntityAuditTableCell size='small' style={{ width: '33%', wordBreak: 'break-word' }}>
                        <OrderedItemsTable items={newValue} />
                    </EntityAuditTableCell>
                    <EntityAuditTableCell>
                        <OrderedItemsTable items={oldValue} isOldItems={true} />
                    </EntityAuditTableCell>
                </TableRow>
            )
        }

        return (
            <TableRow>
                <EntityAuditTableCell size='small' style={{ width: '34%', fontWeight: '600' }}>
                    { intl.formatMessage({ id: `app.auditTable.entity.${entityLog.entityTypeId}.keys.${key}` }) }
                </EntityAuditTableCell>
                <EntityAuditTableCell size='small' style={{ width: '33%', wordBreak: 'break-word' }}>
                    { `${newValue}` }
                </EntityAuditTableCell>
                <EntityAuditTableCell
                    size='small'
                    style={{
                        color: '#ff6020',
                        width: '33%',
                        wordBreak: 'break-word' 
                    }}
                >
                    { `${oldValue}` }
                </EntityAuditTableCell>
            </TableRow>
        )
    }

    const parseValues = (newValues, oldValues) => {
        let newObject = JSON.parse(newValues)

        let oldObject = !!oldValues ? JSON.parse(oldValues) : null

        let sortOrder = ["QuestionType", "Title", "SurveyTitle"]

        return (
            !!newObject
                ? Object.keys(newObject)
                    .sort((a, b) => {
                        let idxa = sortOrder.indexOf(a)
                        let idxb = sortOrder.indexOf(b)

                        if (idxa == -1 && idxb == -1) {
                            return a > b ? 1 : a < b ? -1 : 0
                        }
                        else if (idxa == -1) {
                            return 1
                        }
                        else if (idxb == -1) {
                            return -1
                        }

                        return idxa > idxb ? 1 : idxa < idxb ? -1 : 0
                    })
                    .map(x => drawValues(newObject, oldObject, x))
                : <div />
        )
    }

    return (
        <TableCell style={{ width: '55%', padding: '12px 0' }}>
            <Table>
                <TableBody className={classes.lastRow}>
                    { parseValues(entityLog.newEntityValues, entityLog.oldEntityValues) }
                </TableBody>
            </Table>
        </TableCell>
    )
}

export default EntityAuditValueTableCell
