import React, { useState, useEffect, Fragment } from 'react'
import IconButton from '@material-ui/core/IconButton'
import MuiMenu from '@material-ui/core/Menu'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import DisableAdministratorMenuItem from './DisableAdministratorMenuItem'
import EnableAdministratorMenuItem from './EnableAdministratorMenuItem'

const ITEM_HEIGHT = 48

export default ({ intl, administrator }) => {
	const [ anchorEl, setAnchorEl ] = useState(null)
	const open = Boolean(anchorEl)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	useEffect(() => {}, [])

	return (
		<div>
			<Fragment>
				<IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
					<MoreVertIcon />
				</IconButton>
				<MuiMenu
					id="long-menu"
					anchorEl={anchorEl}
					keepMounted
					open={open}
					onClose={handleClose}
					PaperProps={{
						style: {
							maxHeight: ITEM_HEIGHT * 4.5,
							width: 200
						}
					}}
				>
					{administrator &&
					administrator.enabled && (
						<DisableAdministratorMenuItem onComplete={handleClose} administrator={administrator} />
					)}
					{administrator &&
					!administrator.enabled && (
						<EnableAdministratorMenuItem onComplete={handleClose} administrator={administrator} />
					)}
				</MuiMenu>
			</Fragment>
		</div>
	)
}
