import React, { useState, forwardRef, useImperativeHandle, useEffect, Fragment, useRef } from 'react'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'
import Grid from '@material-ui/core/Grid'
import { Field, formValueSelector } from 'redux-form'
import { makeStyles } from '@material-ui/core/styles'
import { useStore } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useSelector, shallowEqual } from 'react-redux'
import { languageNameSelector } from '../../redux/selectors'
import { required } from '../../utilities/validators'
import DefaultFormTextField from '../DefaultFormTextField'
import DefaultFormNumberField from '../DefaultFormNumberField'
import UploadImage from './AnalogTranslator/UploadImage'
import { QuestionAnswerOutlined } from '@material-ui/icons'
import { change } from 'redux-form'

const requiredValidator = required
const selector = formValueSelector('editQuestion')

const useStyles = makeStyles({
	root: {
		width: 200
	}
})

export default forwardRef((props, ref) => {
	const { question } = props
	const store = useStore()
	let languages = useSelector(languageNameSelector, shallowEqual)
	const dispatch = useDispatch()
	const startImageRef = useRef()
	const endImageRef = useRef()

	useEffect(
		() => {
			if (question && question.data) {
				dispatch(change('editQuestion', 'question', question.data.title))
				dispatch(change('editQuestion', 'instructions', question.data.instructions))
				dispatch(change('editQuestion', 'tag', question.data.tag))
				dispatch(change('editQuestion', 'startText', question.data.startText))
				dispatch(change('editQuestion', 'endText', question.data.endText))
				dispatch(change('editQuestion', 'startValue', question.data.startValue))
				dispatch(change('editQuestion', 'endValue', question.data.endValue))
				dispatch(change('editQuestion', 'stepValue', question.data.stepValue))
			}
		},
		[ question ]
	)

	useImperativeHandle(ref, () => ({
		getQuestion() {
			return {
				id: question.data.id,
				title: selector(store.getState(), 'question'),
				type: 'analog',
				canonicalId: question.data.canonicalId,
				instructions: selector(store.getState(), 'instructions'),
				tag: selector(store.getState(), 'tag'),
				startText: selector(store.getState(), 'startText'),
				endText: selector(store.getState(), 'endText'),
				startValue: selector(store.getState(), 'startValue'),
				endValue: selector(store.getState(), 'endValue'),
				stepValue: selector(store.getState(), 'stepValue')
			}
		}
	}))
	return (
		<Grid container direction="column" alignItems="flex-start">
			<Grid item style={{ width: '100%' }}>
				<Typography variant="subtitle2">
					Title ({languages(1)}): {question.data.baseTitle}
				</Typography>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				<DefaultFormTextField
					label={`Title (${languages(question.data.languageId)})`}
					variant="filled"
					fullWidth
					name="question"
					validators={[ requiredValidator ]}
					inputProps={{ maxLength: 1023 }}
					maxLength={1023}
				/>
			</Grid>
			{question.data.baseInstructions && (
				<Fragment>
					<Grid item style={{ width: '100%' }}>
						<Typography variant="subtitle2">
							Instruction ({languages(1)}): {question.data.baseInstructions}
						</Typography>
					</Grid>
					<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
						<DefaultFormTextField
							label={`Instructions (${languages(question.data.languageId)})`}
							variant="filled"
							name="instructions"
							fullWidth
							inputProps={{ maxLength: 2047 }}
							rows="3"
							maxLength={2047}
							multiline
						/>
					</Grid>
				</Fragment>
			)}
			<Grid item>
				<Typography variant="h4" style={{ fontWeight: '400', paddingBottom: '10px' }}>
					Analog Answer
				</Typography>
			</Grid>
			<Grid item>
				<Grid container direction="row" spacing={2}>
					<Grid item style={{ paddingBottom: '10px' }}>
						<DefaultFormNumberField
							label="Start Value"
							allowDecimal={true}
							variant="filled"
							name="startValue"
							fullWidth
							disabled
						/>
					</Grid>
					<Grid item style={{ paddingBottom: '10px' }}>
						<DefaultFormNumberField
							label="End Value"
							allowDecimal={true}
							name="endValue"
							variant="filled"
							fullWidth
							disabled
						/>
					</Grid>
					<Grid item style={{ paddingBottom: '10px' }}>
						<DefaultFormNumberField
							label="Step Value"
							allowDecimal={true}
							name="stepValue"
							variant="filled"
							fullWidth
							disabled
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid container direction="row" spacing={2}>
					<Grid item style={{ paddingBottom: '10px' }}>
						<DefaultFormTextField
							label="Start Text"
							variant="filled"
							name="startText"
							fullWidth
							inputProps={{ maxLength: 254 }}
							maxLength={254}
						/>
					</Grid>
					<Grid item style={{ paddingBottom: '10px' }}>
						<DefaultFormTextField
							label="End Text"
							name="endText"
							fullWidth
							variant="filled"
							inputProps={{ maxLength: 254 }}
							maxLength={254}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid container direction="row" spacing={2}>
					<Grid item style={{ paddingBottom: '10px', width: '207px' }}>
						<Grid container direction="row">
							<Grid item>
								{question.data.startImagePath && (
									<img src={question.data.startImagePath} width="32px" height="32px" />
								)}
								{!question.data.startImagePath && <Typography>N/A</Typography>}
							</Grid>
							<Grid item style={{ padding: '3px 3px 3px 10px' }}>
								<Typography>Start Image</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item style={{ paddingBottom: '10px', width: '207px' }}>
						<Grid container>
							<Grid item direction="row">
								{question.data.endImagePath && (
									<img src={question.data.endImagePath} width="32px" height="32px" />
								)}
								{!question.data.startImagePath && <Typography>N/A</Typography>}
							</Grid>
							<Grid item style={{ padding: '3px 3px 3px 10px' }}>
								<Typography>End Image</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
				
		</Grid>
	)
})
