import React, { Fragment } from 'react'
import { Grid, useTheme, Typography, makeStyles } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'
import { Visibility } from '@material-ui/icons';


const useStyles = makeStyles(() => ({
	alert: {
		'& .MuiAlert-icon': {
            padding: '0',
            paddingRight: '10px',
            margin: 'auto 0'
		},
		'& .MuiAlert-message': {
            padding: '0',
            margin: 'auto 0'
		},
	}
}))

const RuleDescriptionRow = ({
    style,
    description,
    intl,
    isVisibilityDescription = true
}) => {
    const classes = useStyles()

    return (
        <Alert
            color="info"
            icon={
                <Typography
                    variant="subtitle1"
                    style={{
                        width: '30px',
                        height: '30px',
                        textAlign: 'center'
                    }}
                >
                    {
                        isVisibilityDescription
                        && <Visibility />
                    }
                    {
                        !isVisibilityDescription
                        && <div dangerouslySetInnerHTML={{ __html: '&#9913;' }} />
                    }
                </Typography>
            }
            style={{
                ...style
            }}
            className={ classes.alert }
        >
            <Typography
                variant="subtitle2"
            >
                {
                   description
                }
            </Typography>
        </Alert>
    )
}

const enhance = compose(injectIntl)

export default enhance(RuleDescriptionRow)
