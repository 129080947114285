import React from "react";
import { TableCell, TableRow } from "@material-ui/core";
import { useIntl } from "react-intl";


const RelatedProtocolsTableEmptyRow = ({}) => {
    const intl = useIntl()


    return (
        <TableRow>
            <TableCell colSpan={4}>
                <span
                    style={{
                        color: "grey"
                    }}
                >
                    { intl.formatMessage({ id: "app.sg.details.relatedProtocols.empty" }) }
                </span>
            </TableCell>
        </TableRow>
    )
}

export default RelatedProtocolsTableEmptyRow;
