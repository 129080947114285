import { authenticatedFetch } from './base'

export const fetchJurisdictions = () => {
	return authenticatedFetch(`/api/jurisdiction`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(jurisdiction) {
			return jurisdiction
		})
}
