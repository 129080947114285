import React, { useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { withStyles, Typography } from '@material-ui/core'
import {
	fetchTemplate,
	fetchTemplateTypes,
	startSaving,
	updateTemplate,
	hideModal,
	showModal
} from '../../redux/actions'
import { connect, useDispatch } from 'react-redux'
import { emailTemplateSelector } from '../../redux/selectors'
import EmailTemplatesForm from './EmailTemplatesForm'
import EmailTemplateTypeFilter from './EmailTemplateTypeFilter'
import LanguageNavigator from '../../pages/treatment/components/LanguageNavigator'
import EntityAuditCommentModal from "../../components/entityAudit/EntityAuditCommentModal"
import { isAuditCommentsRequired } from '../../utilities/auditUtils'


const styles = () => ({
	button: {
		'&:hover': {
			backgroundColor: 'transparent'
		},
		paddingTop: '32px'
	},
	gradient: {
		background:
			'linear-gradient(45deg,  #d4e1f7 0%,#d4e1f7 50%,#ffffff 50%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */',
		filter:
			"progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4e1f7', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */"
	}
})

const EmailTemplates = ({ loadTemplateTypes, getTemplate, template, onSave, versionId, surveyInstanceId, circleId }) => {
	const [ currentLanguage, setCurrentLanguage ] = useState(1)
	const [ currentType, setCurrentType ] = useState(-1)

	const dispatch = useDispatch()

	useEffect(() => {
		loadTemplateTypes()
	}, [])

	useEffect(
		() => {
			if(currentType >= 0)
				getTemplate(versionId, surveyInstanceId, circleId, currentLanguage, currentType)
		},
		[ currentLanguage, currentType ]
	)

	const onSaveCallback = (languageId, type, htmlPart, subjectPart) => {
		if ((circleId > 1 && surveyInstanceId === undefined && versionId === undefined) ||//don't show modal for circle
			(circleId === undefined && surveyInstanceId === undefined && versionId === undefined) ||//don't show modal for admin settings
			!isAuditCommentsRequired()
		) 
		{
			dispatch(startSaving())
			onSave(versionId, surveyInstanceId, circleId, languageId, type, htmlPart, subjectPart, null)
		}
		else {
			dispatch(
				showModal(
					EntityAuditCommentModal,
					{
						entityId: versionId,
						entityTypeId: 17, //protocol
						saveCallback: (commentsObj) => {
							dispatch(hideModal())
							dispatch(startSaving())
							onSave(versionId, surveyInstanceId, circleId, languageId, type, htmlPart, subjectPart, commentsObj)					
						}
					}
				)
			)
		}
	}

	return (
		<Grid item>			
			<Grid container direction="row" spacing={2}>
				<Grid item style={{ flex: 0.3 }}>
				<Paper
					square={true}
					style={{
						minWidth: '200px',
						marginLeft: '32px',
						marginRight: '32px',
						transform: 'translateY(-50%)',
						backgroundColor: '#011020',
						display: 'table',
						minHeight: '48px',
						marginBottom: '-50px'
					}}
				>
					<EmailTemplateTypeFilter setFilter={setCurrentType} currentFilter={currentType} versionId={versionId} surveyInstanceId={surveyInstanceId} circleId={circleId}/>
				</Paper>
					<LanguageNavigator currentLanguage={currentLanguage} onLanguageChange={setCurrentLanguage} />
				</Grid>
				<Grid item style={{ flex: 0.7, height: '100%' }}>
					<Paper style={{ padding: '3%' }}>
						<EmailTemplatesForm
							template={template && template.template}
							defaultTemplate={template && template.defaultTemplate}
							languageId={currentLanguage}
							type={currentType}
							onSaveCallback={onSaveCallback}
							versionId={versionId}
							surveyInstanceId={surveyInstanceId}
							circleId={circleId}
						/>
					</Paper>
				</Grid>
			</Grid>
		</Grid>
	)
}

const mapStateToProps = (state, ownProps) => ({
	template: emailTemplateSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	getTemplate: (versionId, surveyInstanceId, circleId, languageId, type) => dispatch(fetchTemplate(versionId, surveyInstanceId, circleId, languageId, type)),
	onSave: (versionId, surveyInstanceId, circleId, languageId, type, htmlPart, subjectPart, commentsObj) => dispatch(updateTemplate(versionId, surveyInstanceId, circleId, languageId, type, htmlPart, subjectPart, commentsObj)),
	loadTemplateTypes: () => dispatch(fetchTemplateTypes())
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withStyles(styles))

export default enhance(EmailTemplates)
