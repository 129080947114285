import * as circle from '../../../apis/circle'
import * as cookies from '../../../utilities/cookies'
import * as api from '../../../apis/scoringGroup'
import * as resources from '../../../apis/resource'
import history from '../../../utilities/history'
import { createSelector } from 'reselect'
import { hideModal } from '../core'
import { getUser } from '../../../utilities/userUtilities'
import { stopSaving } from '../../../redux/actions'

const ADD_SCORING_GROUP_REQUESTED = 'ADD_SCORING_GROUP_REQUESTED'
const ADD_SCORING_GROUP_SUCCESS = 'ADD_SCORING_GROUP_SUCCESS'
const ADD_SCORING_GROUP_FAILED = 'ADD_SCORING_GROUP_FAILED'

export const addScoringGroup = (values) => (dispatch) => {
	dispatch({ type: ADD_SCORING_GROUP_REQUESTED, data: null })

	api.addScoringGroup(values).then((response) => {
		dispatch(stopSaving())
		if (response && response.isSuccessful) {
			history.push(`/sg/${response.id}`)
			dispatch(hideModal())
			return dispatch({ type: ADD_SCORING_GROUP_SUCCESS, data: null })
		} else {
			dispatch({ type: ADD_SCORING_GROUP_FAILED, data: null })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.groups.failedToCreateErrorText`, isSuccess: false }
			})
		}
	})
	return
}

const VALIDATE_FORMULA_SUCCESS = 'VALIDATE_FORMULA_SUCCESS'
const VALIDATE_FORMULA_FAILED = 'VALIDATE_FORMULA_FAILED'

export const validateFormula = (id, extendedFormula) => (dispatch) => {
	api.validateFormula(id, extendedFormula).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({ type: VALIDATE_FORMULA_SUCCESS, data: response.data })
		} else {
			dispatch({ type: VALIDATE_FORMULA_FAILED })
		}
		return
	})
	return
}

const SAVE_FORMULA_REQUESTED = 'SAVE_FORMULA_REQUESTED'
const SAVE_FORMULA_SUCCESS = 'SAVE_FORMULA_SUCCESS'
const SAVE_FORMULA_FAILED = 'SAVE_FORMULA_FAILED'

export const saveFormula = (id, upperFence, lowerFence, upperBounds, lowerBounds, commentsObj) => (dispatch, getState) => {
	dispatch({ type: SAVE_FORMULA_REQUESTED, data: null })
	let s = getState()
	var data = s.scoringGroup.formula
	var extendedFormula = s.scoringGroup.extendedFormula
	var isExtendedMode = s.scoringGroup.isExtendedMode
	if (s.scoringGroup.formula.length > 0) {
		data = s.scoringGroup.formula.map((r, i) => {
			r.order = i
			return r
		})
	}

	api.saveFormula(id, data, extendedFormula, isExtendedMode, upperFence, lowerFence, upperBounds, lowerBounds, commentsObj).then((response) => {
		dispatch(stopSaving())
		if (response && response.isSuccessful) {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.groups.saveSuccessful`, isSuccess: true }
			})
		} else {
			dispatch({ type: SAVE_FORMULA_FAILED, data: null })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.groups.failedToSaveFormulaErrorText`, isSuccess: false }
			})
		}
	})
	return
}

const FETCH_SCORING_GROUP_REQUESTED = 'FETCH_SCORING_GROUP_REQUESTED'
const FETCH_SCORING_GROUP_SUCCESS = 'FETCH_SCORING_GROUP_SUCCESS'
const FETCH_SCORING_GROUP_FAILED = 'FETCH_SCORING_GROUP_FAILED'

export const fetchGroup = (id) => (dispatch) => {
	dispatch({ type: FETCH_SCORING_GROUP_REQUESTED, data: {} })

	api.fetchGroup(id).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_SCORING_GROUP_SUCCESS, data: response })
		} else {
			dispatch({ type: FETCH_SCORING_GROUP_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.bundle.failedToLoadGroups', isSuccess: false }
			})
		}
	})
	return
}

const EDIT_SCORING_GROUP_REQUESTED = 'EDIT_SCORING_GROUP_REQUESTED'
const EDIT_SCORING_GROUP_SUCCESS = 'EDIT_SCORING_GROUP_SUCCESS'
const EDIT_SCORING_GROUP_FAILED = 'EDIT_SCORING_GROUP_FAILED'

export const editGroup = (id, name) => (dispatch) => {
	dispatch({ type: EDIT_SCORING_GROUP_REQUESTED, data: {} })

	api.editGroup(id, name).then((response) => {
		dispatch(stopSaving())
		if (response && response.isSuccessful) {
			dispatch(fetchGroup(id))
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.group.savedSuccessfully', isSuccess: true }
			})
		} else {
			dispatch({ type: EDIT_SCORING_GROUP_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.group.failedToSaveSuccessfully', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_SCORING_GROUPS_REQUESTED = 'FETCH_SCORING_GROUPS_REQUESTED'
const FETCH_SCORING_GROUPS_SUCCESS = 'FETCH_SCORING_GROUPS_SUCCESS'
const FETCH_SCORING_GROUPS_FAILED = 'FETCH_SCORING_GROUPS_FAILED'

export const fetchGroups = (searchPhrase) => (dispatch) => {
	dispatch({ type: FETCH_SCORING_GROUPS_REQUESTED, data: {} })

	api.fetchGroups(searchPhrase).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ 
				type: FETCH_SCORING_GROUPS_SUCCESS, 
				data: {
					items: response.groups,
					hasMore: response.hasMore
				} 
			})
		} else {
			dispatch({ type: FETCH_SCORING_GROUPS_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.bundle.failedToLoadGroups', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_ADDITIONAL_SCORING_GROUPS_REQUESTED = 'FETCH_ADDITIONAL_SCORING_GROUPS_REQUESTED'
const FETCH_ADDITIONAL_SCORING_GROUPS_SUCCESS = 'FETCH_ADDITIONAL_SCORING_GROUPS_SUCCESS'
const FETCH_ADDITIONAL_SCORING_GROUPS_FAILED = 'FETCH_ADDITIONAL_SCORING_GROUPS_FAILED'

export const fetchAdditionalGroups = (searchPhrase, offset) => (dispatch) => {
	dispatch({ type: FETCH_ADDITIONAL_SCORING_GROUPS_REQUESTED, data: {} })

	api.fetchGroups(searchPhrase, offset).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ 
				type: FETCH_ADDITIONAL_SCORING_GROUPS_SUCCESS, 
				data: {
					items: response.groups,
					hasMore: response.hasMore
				} 
			})
		} else {
			dispatch({ type: FETCH_ADDITIONAL_SCORING_GROUPS_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.bundle.failedToLoadGroups', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_FORMULA_REQUESTED = 'FETCH_FORMULA_REQUESTED'
const FETCH_FORMULA_SUCCESS = 'FETCH_FORMULA_SUCCESS'
const FETCH_FORMULA_FAILED = 'FETCH_FORMULA_FAILED'

export const fetchFormula = (scoringId) => (dispatch) => {
	dispatch({ type: FETCH_FORMULA_REQUESTED, data: {} })

	api.fetchFormula(scoringId).then((response) => {
		if (response && response.isSuccessful) {
			if (!response.elements || response.elements.length === 0) {
				response.elements = []
			}

			return dispatch({
				type: FETCH_FORMULA_SUCCESS,
				data: response
			})
		} else {
			dispatch({ type: FETCH_FORMULA_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.group.failedToLoadFormula', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_SCORING_GROUP_BUNDLES_REQUESTED = 'FETCH_SCORING_GROUP_BUNDLES_REQUESTED'
const FETCH_SCORING_GROUP_BUNDLES_SUCCESS = 'FETCH_SCORING_GROUP_BUNDLES_SUCCESS'
const FETCH_SCORING_GROUP_BUNDLES_FAILED = 'FETCH_SCORING_GROUP_BUNDLES_FAILED'

export const fetchGroupBundles = (id, searchPhrase, showArchivedBundles) => (dispatch) => {
	dispatch({ type: FETCH_SCORING_GROUP_BUNDLES_REQUESTED, data: {} })

	api.getScoringGroupBundles(id, searchPhrase, showArchivedBundles).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_SCORING_GROUP_BUNDLES_SUCCESS, data: response.bundles })
		} else {
			dispatch({ type: FETCH_SCORING_GROUP_BUNDLES_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.bundle.failedToLoadGroups', isSuccess: false }
			})
		}
	})
	return
}

const ADD_SCORING_GROUP_BUNDLE_REQUESTED = 'ADD_SCORING_BUNDLE_GROUP_REQUESTED'
const ADD_SCORING_GROUP_BUNDLE_SUCCESS = 'ADD_SCORING_GROUP_BUNDLE_SUCCESS'
const ADD_SCORING_GROUP_BUNDLE_FAILED = 'ADD_SCORING_GROUP_BUNDLE_FAILED'

export const addScoringGroupBundle = (id, bundleId) => (dispatch) => {
	dispatch({ type: ADD_SCORING_GROUP_BUNDLE_REQUESTED, data: null })

	api.addScoringGroupBundle(id, bundleId).then((response) => {
		dispatch(stopSaving())
		if (response && response.isSuccessful) {
			dispatch(fetchGroupBundles(id))
			dispatch(clearBundleAutocomplete())
			return dispatch({ type: ADD_SCORING_GROUP_BUNDLE_SUCCESS, data: null })
		} else {
			dispatch({ type: ADD_SCORING_GROUP_BUNDLE_FAILED, data: null })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.groups.failedToCreateErrorText`, isSuccess: false }
			})
		}
	})
	return
}

const SORT_SCORING_GROUPS_REQUESTED = 'SORT_SCORING_GROUPS_REQUESTED'
export const setScoringGroupSort = (field, isDescending) => (dispatch, state) => {
	let currentState = state()

	let data = currentState.scoringGroup.scoringGroups.sort((a, b) => {
		if (isDescending) {
			return a[field] > b[field] ? -1 : 1
		} else {
			return b[field] > a[field] ? -1 : 1
		}
	})
	return dispatch({
		type: SORT_SCORING_GROUPS_REQUESTED,
		data: { items: data, field: field, isDescending: isDescending }
	})
}

const CLEAR_SCORING_GROUP_AUTOCOMPLETE_REQUESTED = 'CLEAR_SCORING_GROUP_AUTOCOMPLETE_REQUESTED'

export const clearBundleAutocomplete = () => (dispatch) => {
	dispatch({ type: CLEAR_SCORING_GROUP_AUTOCOMPLETE_REQUESTED, data: {} })
	return
}

const QUESTION_SCORING_GROUP_AUTOCOMPLETE_REQUESTED = 'QUESTION_SCORING_GROUP_AUTOCOMPLETE_REQUESTED'
const QUESTION_SCORING_GROUP_AUTOCOMPLETE_SUCCESS = 'QUESTION_SCORING_GROUP_AUTOCOMPLETE_SUCCESS'
const QUESTION_SCORING_GROUP_AUTOCOMPLETE_FAILED = 'QUESTION_SCORING_GROUP_AUTOCOMPLETE_FAILED'

export const searchBundleAutocomplete = (searchPhrase) => (dispatch) => {
	dispatch({ type: QUESTION_SCORING_GROUP_AUTOCOMPLETE_REQUESTED, data: {} })
	api.autocompleteSearch(searchPhrase).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: QUESTION_SCORING_GROUP_AUTOCOMPLETE_SUCCESS, data: response.groups })
		} else {
			return dispatch({ type: QUESTION_SCORING_GROUP_AUTOCOMPLETE_FAILED, data: {} })
		}
	})
	return
}

const UPDATE_HIDE_FROM_PATIENT_REQUESTED = 'UPDATE_HIDE_FROM_PATIENT_REQUESTED'
const UPDATE_HIDE_FROM_PATIENT_SUCCESS = 'UPDATE_HIDE_FROM_PATIENT_SUCCESS'
const UPDATE_HIDE_FROM_PATIENT_FAILED = 'UPDATE_HIDE_FROM_PATIENT_FAILED'

export const updateHideFromPatient = (id) => (dispatch) => {
	dispatch({ type: UPDATE_HIDE_FROM_PATIENT_REQUESTED, data: {} })
	api.updateHideFromPatient(id).then((response) => {
		if (response) {
			return dispatch({ type: UPDATE_HIDE_FROM_PATIENT_SUCCESS, data: response.groups })
		} else {
			return dispatch({ type: UPDATE_HIDE_FROM_PATIENT_FAILED, data: {} })
		}
	})
	return
}

const SET_FORMULA_REQUESTED = 'SET_FORMULA_REQUESTED'

export const setFormula = (formula) => (dispatch, getState) => {
	dispatch({
		type: SET_FORMULA_REQUESTED,
		data: formula
	})
}

const SET_EXTENDED_FORMULA_REQUESTED = 'SET_EXTENDED_FORMULA_REQUESTED'

export const setExtendedFormula = (extendedFormula) => (dispatch, getState) => {
	dispatch({
		type: SET_EXTENDED_FORMULA_REQUESTED,
		data: extendedFormula
	})
}

const SET_FORMULA_EXTENDED_MODE = 'SET_FORMULA_EXTENDED_MODE'

export const setExtendedMode = (isExtendedMode) => (dispatch, getState) => {
	dispatch({
		type: SET_FORMULA_EXTENDED_MODE,
		data: isExtendedMode
	})
}

const ADD_FORMULA_ELEMENT_REQUESTED = 'ADD_FORMULA_ELEMENT_REQUESTED'

export const addFormulaElement = (element) => (dispatch, getState) => {
	dispatch({ type: ADD_FORMULA_ELEMENT_REQUESTED, data: element })
}

const REMOVE_LAST_FORMULA_ELEMENT_REQUESTED = 'REMOVE_LAST_FORMULA_ELEMENT_REQUESTED'

export const removeLastFormulaElement = (element) => (dispatch, getState) => {
	dispatch({ type: REMOVE_LAST_FORMULA_ELEMENT_REQUESTED, data: element })
}

const CLEAR_FORMULA_REQUESTED = 'CLEAR_FORMULA_REQUESTED'

export const clearFormula = () => (dispatch, getState) => {
	dispatch({ type: CLEAR_FORMULA_REQUESTED })
}

const UPDATE_SCORING_GROUP_LOWER_FENCE_REQUESTED = 'UPDATE_SCORING_GROUP_LOWER_FENCE_REQUESTED'
export const updateLowerFence = (value) => (dispatch) => {
	return dispatch({ type: UPDATE_SCORING_GROUP_LOWER_FENCE_REQUESTED, data: value })
}

const UPDATE_SCORING_GROUP_UPPER_FENCE_REQUESTED = 'UPDATE_SCORING_GROUP_UPPER_FENCE_REQUESTED'
export const updateUpperFence = (value) => (dispatch) => {
	return dispatch({ type: UPDATE_SCORING_GROUP_UPPER_FENCE_REQUESTED, data: value })
}

const UPDATE_SCORING_GROUP_LOWER_BOUNDS_REQUESTED = 'UPDATE_SCORING_GROUP_LOWER_BOUNDS_REQUESTED'
export const updateLowerBounds = (value) => (dispatch) => {
	return dispatch({ type: UPDATE_SCORING_GROUP_LOWER_BOUNDS_REQUESTED, data: value })
}

const UPDATE_SCORING_GROUP_UPPER_BOUNDS_REQUESTED = 'UPDATE_SCORING_GROUP_UPPER_BOUNDS_REQUESTED'
export const updateUpperBounds = (value) => (dispatch) => {
	return dispatch({ type: UPDATE_SCORING_GROUP_UPPER_BOUNDS_REQUESTED, data: value })
}

const FETCH_SCORING_GROUP_COPY_REQUESTED = 'FETCH_SCORING_GROUP_COPY_REQUESTED'
const FETCH_SCORING_GROUP_COPY_SUCCESS = 'FETCH_SCORING_GROUP_COPY_SUCCESS'
const FETCH_SCORING_GROUP_COPY_FAILED = 'FETCH_SCORING_GROUP_COPY_FAILED'

export const fetchCopy = (id, languageId) => (dispatch) => {
	dispatch({ type: FETCH_SCORING_GROUP_COPY_REQUESTED, data: {} })

	api.fetchCopy(id, languageId).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_SCORING_GROUP_COPY_SUCCESS, data: response })
		} else {
			dispatch({ type: FETCH_SCORING_GROUP_COPY_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.bundle.failedToLoadGroups', isSuccess: false }
			})
		}
	})
	return
}

const SAVE_SCORING_GROUP_COPY_REQUESTED = 'SAVE_SCORING_GROUP_COPY_REQUESTED'
const SAVE_SCORING_GROUP_COPY_SUCCESS = 'SAVE_SCORING_GROUP_COPY_SUCCESS'
const SAVE_SCORING_GROUP_COPY_FAILED = 'SAVE_SCORING_GROUP_COPY_FAILED'

export const saveCopy = (id, languageId, values, commentsObj) => (dispatch) => {
	dispatch({ type: SAVE_SCORING_GROUP_COPY_REQUESTED, data: {} })

	api.saveCopy(id, languageId, values, commentsObj).then((response) => {
		dispatch(stopSaving())
		if (response && response.isSuccessful) {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.savedSuccessfully', isSuccess: true }
			})
		} else {
			dispatch({ type: SAVE_SCORING_GROUP_COPY_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.group.failedToSaveCopy', isSuccess: false }
			})
		}
	})
	return
}

const SET_SCORING_GROUP_SEARCH_TERM_REQUESTED = 'SET_SCORING_GROUP_SEARCH_TERM_REQUESTED'
const SET_SCORING_GROUP_SEARCH_TERM_SUCCESS = 'SET_SCORING_GROUP_SEARCH_TERM_SUCCESS'
const SET_SCORING_GROUP_SEARCH_TERM_FAILED = 'SET_SCORING_GROUP_SEARCH_TERM_FAILED'

export const setScoringGroupSearchTerm = (value) => (dispatch) => {
	dispatch({ type: SET_SCORING_GROUP_SEARCH_TERM_REQUESTED, data: {} })
	return dispatch({ type: SET_SCORING_GROUP_SEARCH_TERM_SUCCESS, data: value })
}

const FETCH_IS_SCORING_GROUP_BUNDLE_IN_USE_REQUESTED = 'FETCH_IS_SCORING_GROUP_BUNDLE_IN_USE_REQUESTED'
const FETCH_IS_SCORING_GROUP_BUNDLE_IN_USE_SUCCESS = 'FETCH_IS_SCORING_GROUP_BUNDLE_IN_USE_SUCCESS'
const FETCH_IS_SCORING_GROUP_BUNDLE_IN_USE_FAILED = 'FETCH_IS_SCORING_GROUP_BUNDLE_IN_USE_FAILED'

export const fetchIsScoringGroupBundleInUse = (id, bundle) => (dispatch) => {
	dispatch({ type: FETCH_IS_SCORING_GROUP_BUNDLE_IN_USE_REQUESTED, data: null })

	api.fetchIsScoringGroupBundleInUse(id, bundle.bundleId).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_IS_SCORING_GROUP_BUNDLE_IN_USE_SUCCESS, data: response.data })
		} else {
			dispatch({ type: FETCH_IS_SCORING_GROUP_BUNDLE_IN_USE_FAILED, data: null })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.groups.failedToRemoveBundleErrorText`, isSuccess: false }
			})
		}
	})
	return
}

const REMOVE_SCORING_GROUP_BUNDLE_REQUESTED = 'REMOVE_SCORING_GROUP_BUNDLE_REQUESTED'
const REMOVE_SCORING_GROUP_BUNDLE_SUCCESS = 'REMOVE_SCORING_GROUP_BUNDLE_SUCCESS'
const REMOVE_SCORING_GROUP_BUNDLE_FAILED = 'REMOVE_SCORING_GROUP_BUNDLE_FAILED'

export const removeScoringGroupBundle = (id, bundleId) => (dispatch) => {
	dispatch({ type: REMOVE_SCORING_GROUP_BUNDLE_REQUESTED, data: null })

	api.removeScoringGroupBundle(id, bundleId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(fetchFormula(id))
			dispatch(fetchGroupBundles(id))
			dispatch(clearBundleAutocomplete())
			return dispatch({ type: REMOVE_SCORING_GROUP_BUNDLE_SUCCESS, data: null })
		} else {
			dispatch({ type: REMOVE_SCORING_GROUP_BUNDLE_FAILED, data: null })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.groups.failedToRemoveBundleErrorText`, isSuccess: false }
			})
		}
	})
	return
}

const CHANGE_SCORING_GROUP_BUNDLE_STATE_REQUESTED = 'CHANGE_SCORING_GROUP_BUNDLE_STATE_REQUESTED'
const CHANGE_SCORING_GROUP_BUNDLE_STATE_SUCCESS = 'CHANGE_SCORING_GROUP_BUNDLE_STATE_SUCCESS'
const CHANGE_SCORING_GROUP_BUNDLE_STATE_FAILED = 'CHANGE_SCORING_GROUP_BUNDLE_STATE_FAILED'

export const changeScoringGroupBundleState = (scoringGroupId, bundle, newState, showArchivedBundles) => (dispatch) => {
	dispatch({ type: CHANGE_SCORING_GROUP_BUNDLE_STATE_REQUESTED, data: null })

	api.updateScoringGroupBundleState(scoringGroupId, bundle.bundleId, newState).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(fetchFormula(scoringGroupId))
			dispatch(fetchGroupBundles(scoringGroupId, null, showArchivedBundles))
			dispatch(clearBundleAutocomplete())
			dispatch({ type: CHANGE_SCORING_GROUP_BUNDLE_STATE_SUCCESS, data: null })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.scoringGroup.successfullyChangeBundleState`, isSuccess: true }
			})
		} else if (response && response.errorCode == 50) {
			dispatch({ type: CHANGE_SCORING_GROUP_BUNDLE_STATE_FAILED, data: null })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.scoringGroup.failedToCleanUpFormula`, isSuccess: false }
			})
		} else {
			dispatch({ type: CHANGE_SCORING_GROUP_BUNDLE_STATE_FAILED, data: null })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.scoringGroup.failedChangeBundleState`, isSuccess: false }
			})
		}
	})
	return
}

const CHANGE_ARCHIVED_BUNDLES_VISIBILITY_SUCCESS = 'CHANGE_ARCHIVED_BUNDLES_VISIBILITY_SUCCESS'

export const changeArchivedBundlesVisibility = (visible) => (dispatch) => {
	dispatch({ type: CHANGE_ARCHIVED_BUNDLES_VISIBILITY_SUCCESS, data: visible })
	return
}


const SEARCH_SCORING_GROUP_RELATED_PROTOCOLS_REQUESTED = 'SEARCH_SCORING_GROUP_RELATED_PROTOCOLS_REQUESTED'
const SEARCH_SCORING_GROUP_RELATED_PROTOCOLS_SUCCESS = 'SEARCH_SCORING_GROUP_RELATED_PROTOCOLS_SUCCESS'
const SEARCH_SCORING_GROUP_RELATED_PROTOCOLS_FAILED = 'SEARCH_SCORING_GROUP_RELATED_PROTOCOLS_FAILED'

export const searchRelatedProtocols = (scoringGroupId, searchPhrase) => (dispatch) => {
	dispatch({ type: SEARCH_SCORING_GROUP_RELATED_PROTOCOLS_REQUESTED, data: null })

	return api
		.searchRelatedProtocols(scoringGroupId, searchPhrase)
		.then((response) => {
			if (response?.isSuccessful) {
				dispatch({ type: SEARCH_SCORING_GROUP_RELATED_PROTOCOLS_SUCCESS, data: response.protocols })

				return response
			}
			else {
				dispatch({ type: SEARCH_SCORING_GROUP_RELATED_PROTOCOLS_FAILED, data: null })

				dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: `app.sg.details.relatedProtocols.failed`, isSuccess: false }
				})

				return null
			}
		})
}

const FETCH_SCORING_GROUP_CHANGE_LOGS_REQUESTED = 'FETCH_SCORING_GROUP_CHANGE_LOGS_REQUESTED'
const FETCH_SCORING_GROUP_CHANGE_LOGS_SUCCESS = 'FETCH_SCORING_GROUP_CHANGE_LOGS_SUCCESS'
const FETCH_SCORING_GROUP_CHANGE_LOGS_FAILED = 'FETCH_SCORING_GROUP_CHANGE_LOGS_FAILED'
export const searchScoringGroupChangeLogs = (scoringGroupId, searchPhrase = null, keys = []) => (dispatch) => {
	dispatch({ type: FETCH_SCORING_GROUP_CHANGE_LOGS_REQUESTED, data: {} })

	api.fetchScoringGroupChangeLogs(scoringGroupId, searchPhrase, keys).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({
				type: FETCH_SCORING_GROUP_CHANGE_LOGS_SUCCESS,
				data: {
					logs: response.logs,
					keys: response.keys
				}
			})
		} else {
			dispatch({ type: FETCH_SCORING_GROUP_CHANGE_LOGS_FAILED, data: {} })

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.audit.failedToFetch', isSuccess: false }
			})
		}
	})
	return
}


const initial = {
	scoringGroup: null,
	scoringGroupBundles: [],
	isCreatingScoringGroup: false,
	bundleNotFound: false,
	bundleRequestReceived: false,
	scoringGroups: [],
	autoCompleteQuestions: [],
	sortField: 'modifiedDateSeconds',
	isDescending: true,
	isBundleEditing: false,
	formula: [],
	extendedFormula: null,
	isExtendedMode: false,
	isExtendedFormulaValid: null,
	upperFence: null,
	lowerFence: null,
	upperBounds: null,
	lowerBounds: null,
	copy: {},
	hasMore: true,
	isLoading: false,
	searchTerm: '',
	isBundleUsed: null,
	showArchivedBundles: false,
	relatedProtocols: []
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_SCORING_GROUP_REQUESTED:
			return {
				...state,
				scoringGroup: {},
				relatedProtocols: []
			}
		case FETCH_SCORING_GROUP_SUCCESS:
			return { ...state, scoringGroup: action.data }
		case FETCH_SCORING_GROUP_COPY_SUCCESS:
			return { ...state, copy: action.data }
		case SET_SCORING_GROUP_SEARCH_TERM_SUCCESS:
			return { ...state, searchTerm: action.data }
		case FETCH_FORMULA_SUCCESS:
			return {
				...state,
				formula: action.data.elements,
				extendedFormula: action.data.extendedFormula,
				isExtendedMode: action.data.isExtendedMode,
				upperFence: action.data.upperFence,
				lowerFence: action.data.lowerFence,
				upperBounds: action.data.upperBounds,
				lowerBounds: action.data.lowerBounds
			}
		case UPDATE_SCORING_GROUP_LOWER_FENCE_REQUESTED:
			return {
				...state,
				lowerFence: action.data
			}
		case UPDATE_SCORING_GROUP_UPPER_FENCE_REQUESTED:
			return {
				...state,
				upperFence: action.data
			}

		case REMOVE_LAST_FORMULA_ELEMENT_REQUESTED:
			let x = state.formula.slice()
			x.pop()
			return {
				...state,
				formula: x
			}
			break
		case CLEAR_FORMULA_REQUESTED:
			return {
				...state,
				formula: [],
				extendedFormula: null,
				isExtendedMode: false
			}
			break
		case SET_FORMULA_REQUESTED:
			if (action && action.data) {
				return {...state, formula: action.data}
			}
			return { ...state }

			break
		case SET_EXTENDED_FORMULA_REQUESTED:
			if (action) {
				return {
					...state,
					extendedFormula: action.data,
					isExtendedFormulaValid: null
				}
			}
			return { ...state }

			break
		case SET_FORMULA_EXTENDED_MODE:
			if (action) {
				return {...state, isExtendedMode: action.data}
			}
			return { ...state }

			break
		case ADD_FORMULA_ELEMENT_REQUESTED:
			return {
				...state,
				formula: [...state.formula, action.data]
			}

			break
		case FETCH_SCORING_GROUP_BUNDLES_REQUESTED:
			return { ...state, scoringGroupBundles: [] }
		case FETCH_SCORING_GROUP_BUNDLES_SUCCESS:
			return { ...state, scoringGroupBundles: action.data }
		case ADD_SCORING_GROUP_REQUESTED:
			return { ...state, isCreatingScoringGroup: true }
		case ADD_SCORING_GROUP_FAILED:
			return { ...state, isCreatingScoringGroup: false }
		case ADD_SCORING_GROUP_REQUESTED:
		// 	return { ...state, isBundleEditing: true }
		// case FETCH_SCORING_GROUP_REQUESTED:
		// 	return { ...state, bundleNotFound: false, bundleRequestReceived: false, bundle: [] }
		// case 'SCORING_GROUP_NOT_FOUND':
		// 	return { ...state, bundleNotFound: true, bundleRequestReceived: true, bundle: [] }
		case CLEAR_SCORING_GROUP_AUTOCOMPLETE_REQUESTED:
			return { ...state, autoCompleteQuestions: [] }
		case ADD_SCORING_GROUP_SUCCESS:
			return { ...state, bundle: action.data, isBundleEditing: true }
		case FETCH_SCORING_GROUPS_SUCCESS:
			return {
				...state,
				scoringGroups: action.data.items,
				hasMore: action.data.hasMore
			}
		case FETCH_ADDITIONAL_SCORING_GROUPS_SUCCESS:
			return {
				...state,
				scoringGroups: [...state.scoringGroups, ...action.data.items],
				hasMore: action.data.hasMore,
				isLoading: false
			}
		case FETCH_ADDITIONAL_SCORING_GROUPS_FAILED:
			return {
				...state,
				hasMore: false,
				isLoading: false
			}
		case FETCH_ADDITIONAL_SCORING_GROUPS_REQUESTED:
			return {
				...state,
				isLoading: true
			}
		// case FETCH_SCORING_GROUP_SUCCESS:
		// 	return { ...state, bundle: action.data, bundleRequestReceived: true }
		case SORT_SCORING_GROUPS_REQUESTED:
			return {
				...state,
				scoringGroups: [...action.data.items],
				sortField: action.data.field,
				isDescending: action.data.isDescending
			}
		case VALIDATE_FORMULA_SUCCESS:
			if (action) {
				return {
					...state,
					isExtendedFormulaValid: action.data
				}
			}
			else {
				return { ...state }
			}
		case VALIDATE_FORMULA_FAILED:
			return {
				...state,
				isExtendedFormulaValid: null
			}
		case FETCH_IS_SCORING_GROUP_BUNDLE_IN_USE_REQUESTED:
			return {
				...state,
				isBundleUsed: null
			}
		case FETCH_IS_SCORING_GROUP_BUNDLE_IN_USE_SUCCESS:
			return {
				...state,
				isBundleUsed: action.data
			}
		case CHANGE_ARCHIVED_BUNDLES_VISIBILITY_SUCCESS:
			return {
				...state,
				showArchivedBundles: action.data
			}

		case SEARCH_SCORING_GROUP_RELATED_PROTOCOLS_SUCCESS:
			return {
				...state,
				relatedProtocols: action.data
			}

		case FETCH_SCORING_GROUP_CHANGE_LOGS_REQUESTED:
			return {
				...state,
				logs: [],
				logKeys: []
			}
		case FETCH_SCORING_GROUP_CHANGE_LOGS_SUCCESS:
			return {
				...state,
				logs: action.data.logs,
				logKeys: action.data.keys
			}

		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.scoringGroup

export const scoringGroupNotFoundSelector = (state) => state.scoringGroup.scoringGroupNotFound
export const scoringGroupRequestReceivedSelector = (state) => state.scoringGroup.scoringGroupRequestReceived
export const scoringGroupsSelector = createSelector(mainSelector, (state) => state.scoringGroups)
export const scoringGroupSelector = createSelector(mainSelector, (state) => state.scoringGroup)
export const scoringGroupBundlesSelector = createSelector(mainSelector, (state) => {
	return state.scoringGroupBundles
})
export const autocompleteScoringGroupSelector = createSelector(mainSelector, (state) => {
	return state.autoCompleteQuestions ? state.autoCompleteQuestions : []
})

export const sortScoringGroupsDirectionSelector = createSelector(mainSelector, (state) => {
	return state.isDescending
})

export const sortScoringGroupsFieldSelector = createSelector(mainSelector, (state) => {
	return state.sortField
})

export const formulaSelector = createSelector(mainSelector, (state) => {
	return state && state.formula
})

export const isExtendedFormulaValidSelector = createSelector(mainSelector, (state) => {
	return state && state.isExtendedFormulaValid
})

export const extendedFormulaSelector = createSelector(mainSelector, (state) => {
	return state && state.extendedFormula
})

export const isExtendedModeSelector = createSelector(mainSelector, (state) => {
	return state && state.isExtendedMode
})

export const upperFenceSelector = createSelector(mainSelector, (state) => {
	return state && state.upperFence
})

export const lowerFenceSelector = createSelector(mainSelector, (state) => {
	return state && state.lowerFence
})

export const upperBoundsSelector = createSelector(mainSelector, (state) => {
	return state && state.upperBounds
})

export const lowerBoundsSelector = createSelector(mainSelector, (state) => {
	return state && state.lowerBounds
})

export const formulaLimitsSelector = createSelector(
	mainSelector,
	upperFenceSelector,
	lowerFenceSelector,
	upperBoundsSelector,
	lowerBoundsSelector,
	(state, upperFence, lowerFence, upperBounds, lowerBounds) => {
		if (state) {
			return {
				upperFence,
				lowerFence,
				upperBounds,
				lowerBounds
			}
		}
	}
)

export const copySelector = createSelector(mainSelector, (state) => {
	return state && state.copy
})

export const hasMoreScoringGroupsSelector = createSelector(mainSelector, (state) => {
	return state.hasMore
})

export const isLoadingScoringGroupsSelector = createSelector(mainSelector, (state) => {
	return state.isLoading
})

export const scoringGroupSearchTermSelector = createSelector(mainSelector, (state) => {
	return state.searchTerm
})

export const isScoringGroupBundleUsedSelector = createSelector(mainSelector, (state) => {
	return state.isBundleUsed
})

export const showArchivedBundlesSelector = createSelector(mainSelector, (state) => {
	return state.showArchivedBundles
})

export const relatedProtocolsSelector = createSelector(mainSelector, (state) => {
	return state.relatedProtocols
})

export const scoringGroupChangeLogsSelector = createSelector(
	mainSelector,
	(state) => {
		return !!state && state.logs
	}
)

export const scoringGroupChangeLogKeysSelector = createSelector(
	mainSelector,
	(state) => {
		return !!state && state.logKeys
	}
)