import React, { forwardRef, useImperativeHandle, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import DefaultFormTextField from '../DefaultFormTextField'
import { formValueSelector } from 'redux-form'
import { required } from '../../utilities/validators'
import { useStore, useDispatch } from 'react-redux'
import { change } from 'redux-form'
import FormTextField from '../FormTextField'
const requiredValidator = required
const selector = formValueSelector('editQuestion')

export default forwardRef((props, ref) => {
	const { question, dark } = props

	const store = useStore()
	const dispatch = useDispatch()
	useEffect(
		() => {
			if (question && question.data) {
				dispatch(change('editQuestion', 'subtitle', question.data.subtitle))
				dispatch(change('editQuestion', 'bodyCopy', question.data.bodyCopy))
			}
		},
		[ question ]
	)

	useImperativeHandle(ref, () => ({
		getQuestion() {
			return {
				id: question.data.id,
				title: '',
				type: 'content',
				subtitle: selector(store.getState(), 'subtitle'),
				bodyCopy: selector(store.getState(), 'bodyCopy')
			}
		}
	}))
	useEffect(() => {
		var el = document.getElementsByName('subtitle')
		if (el != null) {
			el[0].focus()
		}
	}, [])

	const AdaptiveFormTextField = dark ? FormTextField : DefaultFormTextField

	return (
		<Grid container direction="column" alignItems="flex-start">
			<Grid item>
				<Typography style={{color: dark ? 'white' : 'initial'}}>Readonly Content</Typography>
			</Grid>

			<Grid item style={{ width: '100%', paddingBottom: '20px' }}>
				<AdaptiveFormTextField
					label="Subtitle"
					variant="filled"
					name="subtitle"
					fullWidth
					validators={[ requiredValidator ]}
					inputProps={{ maxLength: 2047 }}
					rows="3"
					maxLength={2047}
					disabled={!!question.data.cloneId}
				/>
			</Grid>
			<Grid item style={{ width: '100%', paddingBottom: '20px' }}>
				<AdaptiveFormTextField
					label="Body Copy"
					variant="filled"
					name="bodyCopy"
					fullWidth
					validators={[ requiredValidator ]}
					inputProps={{ maxLength: 2047 }}
					rows="5"
					multiline={true}
					maxLength={2047}
					disabled={!!question.data.cloneId}
				/>
			</Grid>
		</Grid>
	)
})
