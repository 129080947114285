import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { useTheme, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import Tag from './Tag'
import QuestionContainer from './QuestionContainer'
import RequiresTranslationBadge from '../../RequiresTranslationBadge'
import Checkbox from '@material-ui/core/Checkbox';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Menu from '../../../pages/surveyViewer/components/AnswerControlMenu'
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon  from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import { useRouteMatch } from 'react-router'
import IdContainer from './IdContainer'
import { questionCode, answerCode } from '../../../utilities/utilities'

const RadioSelectQuestion = ({ intl, question, surveyEntity, position, positionDisplay, showScoreEntry, handleAnswerStateChange, getCurrentAnswerState, getBackgroundColor, defaultBackgroundColor, archiveAnswer, showState }) => {
	const match = useRouteMatch()
	const theme = useTheme()

	return (
			<Grid container direction="column">
				<Grid item>
					<Tag tag={question.tag} />
				</Grid>
				<Grid item>
					<QuestionContainer
						question={question}
						surveyEntity={surveyEntity}
						children={
							<Grid container direction="column" justify="space-between" className="admin-question-container-50">

								{question &&
									question.answers &&
									question.answers
										.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
										.map((r, i) => {
											return (
									
												<Grid 
													container 
													direction="row" 
													justify="flex-start" 
													style={{ 
														backgroundColor: showState 
															? getBackgroundColor(getCurrentAnswerState(r.baseId, question.baseId, question.questionType) || r.state) 
															: defaultBackgroundColor 
													}}
												>
												 	{showState && <Grid item style={{ flex: 0.1, marginTop: '16px' }}>
													 {(r.state < 3 && !getCurrentAnswerState(r.baseId, question.baseId, question.questionType) || getCurrentAnswerState(r.baseId, question.baseId, question.questionType) < 3) &&
															<Checkbox
																icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
																checkedIcon={<CheckBoxIcon fontSize="large" />}
																indeterminateIcon={<IndeterminateCheckBoxIcon fontSize="large" />}
																indeterminate={r.state === 0 && !getCurrentAnswerState(r.baseId, question.baseId, question.questionType)}
																checked={r.state === 1 && !getCurrentAnswerState(r.baseId, question.baseId, question.questionType) || getCurrentAnswerState(r.baseId, question.baseId, question.questionType) === 1}
																color="primary"
																onChange={event => handleAnswerStateChange(r.baseId, question.baseId, question.questionType, event.target.checked)}
																style={{ padding: '5px 0 0'}} />}
														{(r.state === 3 && !getCurrentAnswerState(r.baseId, question.baseId, question.questionType) || getCurrentAnswerState(r.baseId, question.baseId, question.questionType) === 3) &&

															<HighlightOffIcon
																fontSize='large'
																style={{ paddingTop: '5px' }} />}
												 	</Grid>}
												 	<Grid item style={{ flex: 0.85, marginTop: '8px' }} className="radio-select-question-wrapper" data-testid="radio-select-question-wrapper">
														<FormControl data-testid="survey-select-component" component="fieldset" className={showState ? 'select-component' : ''} style={{ marginLeft: showState ? '-20px' : '-5px' }}>
															<FormGroup>
																<FormControlLabel
																	key={i}																	
																	control={<Radio color="primary" disabled value={r && r.value} key={i} />}
																	labelPlacement="end"
																	label={
																		<Typography
																			variant="subtitle2"
																			style={{ fontSize: '17px', color: 'black' }}
																		>
																			{r && showScoreEntry ? (
																				`${r.label} ( ${r.value} )`
																			) : (
																					`${r.label} `
																				)}
																				{showState && !question.isBundle && ` (${r.casesCount})`}
																		</Typography>
																	}
																/>
															</FormGroup>
														</FormControl>
												 	</Grid>
													{
														!!match.params.instanceId
														&& <Grid
															container
															style={{
																alignItems: 'right',
																flex: 0.1,
																marginTop: '7px'
															}}
														>
															<IdContainer
																label={intl.formatMessage({ id: 'app.survey.answerIdLabel' }).replace('%%Id%%', `${question.baseId}_${r.baseId}`)}
																clipboardText={
																	`${questionCode(match.params.instanceId, question.baseId, question.questionType)}==${answerCode(question.baseId, r.baseId)}`
																}
															/>
														</Grid>
													}
													<Grid item style={{ alignItems: 'flex-start', flex: 0.05 }}>
														{
															showState
															&& <Menu
																question={question}
																answer={r}
																archiveAnswer={archiveAnswer}
																getCurrentAnswerState={getCurrentAnswerState}
															/>
														}
													</Grid>
												 </Grid>

											)
										})}
							</Grid>

						}
					/>
				</Grid>{' '}
				{question &&
					!question.id && (
						<Grid item style={{ marginTop: '10px' }}>
							<RequiresTranslationBadge />
						</Grid>
					)}
			</Grid>
	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(RadioSelectQuestion)
