import React, { useEffect } from 'react'
import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined'
import { useDispatch } from 'react-redux'
import { clearUserDetails } from '../../../redux/actions'

export default ({ subscriber, setHeightCallback }) => {
	let ref = React.createRef()
	const dispatch = useDispatch()

	const onCopy = () => {
		let copyText = document.getElementById(subscriber.email)
		copyText.select()
		document.execCommand('copy')
	}

	useEffect(
		() => {
			if (setHeightCallback && ref.current) {
				if (ref.current.offsetHeight < 64) {
					setHeightCallback(64)
				} else {
					setHeightCallback(ref.current.offsetHeight)
				}
			}
		},
		[ ref ]
	)

	return (
		<Grid container justify="flex-start" ref={ref} id={subscriber.id} style={{ minWidth: '300px' }}>
			<Grid item style={{ minWidth: '200px' }}>
				
				<Grid container direction="column" style={{ minWidth: '200px' }}>
					<Grid item style={{ maxWidth: '600px' }}>
						<Link
							onClick={() => dispatch(clearUserDetails())}
							to={subscriber.id > 1 ? `/userprofile/${subscriber.id}` : '/subscribers'}
							style={{
								textDecoration: 'none',
								fontWeight: '700',
								fontSize: '17px',
								lineHeight: '24px',
								letterSpacing: '0.16px',
								color: 'black',
								pointerEvents: subscriber.id > 1 ? 'auto' : 'none'
							}}
						>
							<Typography variant="subtitle1" style={{ textAlign: 'left', wordBreak: 'break-word' }}>
								{subscriber && subscriber.name && subscriber.name.length > 200 ? (
									subscriber.name.substring(0, 200) + '...'
								) : (
									subscriber.name
								)}
							</Typography>
						</Link>
					</Grid>
					<Grid item style={{ maxWidth: '600px' }}>
						<Typography variant="subtitle2" style={{ textAlign: 'left', wordBreak: 'break-word' }}>
							<Grid container>
								<Grid item>
									<Link
										onClick={() => dispatch(clearUserDetails())}
										to={subscriber.id > 1 ? `/userprofile/${subscriber.id}` : '/subscribers'}
										style={{
											textDecoration: 'none',
											fontWeight: '700',
											fontSize: '17px',
											lineHeight: '24px',
											letterSpacing: '0.16px',
											color: 'black',
											pointerEvents: subscriber.id > 1 ? 'auto' : 'none'
										}}
									>
										<Typography variant="body2">
											{subscriber && subscriber.email && subscriber.email.length > 200 ? (
												subscriber.email.substring(0, 200) + '...'
											) : (
												subscriber.email
											)}{' '}
										</Typography>
									</Link>
								</Grid>
								<Grid item style={{ paddingLeft: '5px', marginTop: '3px' }}>
									<input
										type="text"
										id={subscriber.email}
										name="email"
										value={subscriber.email}
										style={{ position: 'absolute', left: '-9999px' }}
									/>
									<FileCopyOutlined style={{ color: 'grey', cursor: 'pointer' }} onClick={onCopy} />
								</Grid>
							</Grid>
						</Typography>
					</Grid>
					<Grid item style={{ maxWidth: '600px' }}>
						<Typography variant="caption" style={{ textAlign: 'left', wordBreak: 'break-word' }}>
							{subscriber && subscriber.location && subscriber.location.length > 200 ? (
								subscriber.location.substring(0, 200) + '...'
							) : (
								subscriber.location
							)}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}
