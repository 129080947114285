import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useTheme from '@material-ui/core/styles/useTheme'
import {} from '../../redux/actions'
import { planSelector } from '../../redux/selectors'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { required } from '../../utilities/validators'
import Button from '@material-ui/core/Button'
import { fetchPlan, applyCreditChanges } from '../../redux/actions'
import FormNumberTextField from '../../components/FormNumberTextField'
import { number, maxValue } from '../../utilities/validators'
import { useDispatch } from 'react-redux'
const numberValidator = number
const maxValueValidator = maxValue
const requiredValidator = required

const ManageSubscriptionCreditsModal = ({ intl, handleSubmit, subscriber, apply, plan }) => {
	const theme = useTheme()
	const dispatch = useDispatch()

	const onSubmitForm = (values) => {
		if (values) {
			apply(subscriber.id, values.cases, values.users, values.expiration)
		}
	}

	useEffect(() => {
		if (!!subscriber && !!subscriber.id) {
			dispatch(fetchPlan(subscriber.id))
		}
	}, [])

	return (
		<form onSubmit={handleSubmit(onSubmitForm)}>
			<Grid
				container
				direction="column"
				justify="flex-start"
				alignItems="center"
				style={{ width: '55rem', padding: '4rem', margin: 'auto', backgroundColor: theme.palette.secondary.main }}
			>
				<Grid item style={{ alignSelf: 'center' }}>
					<Typography
						variant="h1"
						style={{
							color: '#fff',
							marginBottom: '25px'
						}}
					>
						Apply Credits
					</Typography>
				</Grid>
				<Grid item style={{ alignSelf: 'flex-start', width: '100%' }}>
					<Grid container style={{ width: '100%' }} spacing={2}>
						<Grid item style={{ flex: 0.5 }}>
							<FormNumberTextField
								label={`Users ${plan && `(${plan.totalPaidUsers})`} `}
								type="text"
								name="cases"
								maxLength={4}
								variant="filled"
								validators={[ numberValidator ]}
							/>
						</Grid>
						<Grid item style={{ flex: 0.5 }}>
							<FormNumberTextField
								label={`Cases ${plan && `(${plan.totalPaidCases})`} `}
								type="text"
								name="users"
								maxLength={4}
								variant="filled"
								validators={[ numberValidator ]}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Button
						label="APPLY"
						style={{
							width: '267px',
							marginTop: '40px'
						}}
                        type="submit"
                        variant="contained"
                        color="primary"
					>
						UPDATE
					</Button>
				</Grid>
			</Grid>
		</form>
	)
}

const mapStateToProps = (state, ownProps) => ({
	initialValues: planSelector(state),
	plan: planSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	apply: (id, cases, users) => dispatch(applyCreditChanges(id, cases, users))
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'addSubscriptionCredits',
		touchOnChange: true,
		touchOnBlur: true,
		enableReinitialize: true
	})
)

export default enhance(ManageSubscriptionCreditsModal)
