import React from "react"
import EntityAuditTableCell from "./EntityAuditTableCell"


const EntityAuditLocalizationTableCell = ({entityLog, ...other}) => {
    return (
        <EntityAuditTableCell
            {...other}
        >
            { entityLog.languageAbbreviation }
        </EntityAuditTableCell>
    )
}

export default EntityAuditLocalizationTableCell