import React from "react";
import { TableCell } from "@material-ui/core";
import { Link } from "react-router-dom/cjs/react-router-dom";


const RelatedProtocolCircleTableCell = ({
    protocol
}) => {
    const getText = () => {
        let result = protocol.circleName

        if (!!protocol.userEmail) {
            result = `${protocol.userEmail} | ${result}`
        }

        if (!!protocol.userName) {
            result = `${protocol.userName} | ${result}`
        }

        return result
    }

    return (
        <TableCell>
            { getText() }
        </TableCell>
    )
}

export default RelatedProtocolCircleTableCell;
