import * as language from '../../../apis/language'
import { createSelector } from 'reselect'
import * as cookies from '../../../utilities/cookies'

const FETCH_LANGUAGES_REQUESTED = 'FETCH_LANGUAGES_REQUESTED'
const FETCH_LANGUAGES_SUCCESS = 'FETCH_LANGUAGES_SUCCESS'
const FETCH_LANGUAGES_FAILED = 'FETCH_LANGUAGES_FAILED'
const FETCH_LANGUAGES_NOP = 'FETCH_LANGUAGES_NOP'

export const fetchLanguages = () => (dispatch, getState) => {
	var state = getState()

	if (!state.language.languages || state.language.languages.length === 0) {
		dispatch({ type: FETCH_LANGUAGES_REQUESTED, data: {} })
		language.fetchLanguages().then((languages) => {
			if (languages) {
				return dispatch({ type: FETCH_LANGUAGES_SUCCESS, data: languages })
			} else {
				return dispatch({ type: FETCH_LANGUAGES_FAILED, data: {} })
			}
		})
	}
	return dispatch({ type: FETCH_LANGUAGES_NOP, data: {} })
}

const initial = {
	languages: []
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_LANGUAGES_SUCCESS:
			return { ...state, languages: action.data }
		default:
			return { ...state }
	}
}

export const mainSelector = (state) => state.language

export const languagesSelector = (state) => state.language.languages

export const languageListSelector = createSelector(mainSelector, (state) => {
	if (state.languages) {
		return state.languages.map((r) => {
			return {
				id: r.languageId,
				label: r.displayName
			}
		})
	}
	return []
})

export const languageNameSelector = createSelector(mainSelector, languagesSelector, (state, languages) => (id) => {
	if (languages && languages.length > 0) {
		let x = languages.find((r) => r.languageId === id)

		if (x) return x.displayName
	}
	return null
})
