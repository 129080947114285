import React, { Fragment, useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import FilterListIcon from '@material-ui/icons/FilterList'
import { searchCircles } from '../../../redux/actions'
import { connect, useDispatch } from 'react-redux'
import { useConstant } from '../../../utilities/useConstant'
import { debounceMilliseconds } from '../../../utilities/constants'
import { Typography } from '@material-ui/core'
import { circleSearchTermSelector } from '../../../redux/selectors'
var debounce = require('lodash/debounce')

const SearchBar = ({
	intl,
	onSearchChange,
	circleFilter
}) => {
	const dispatch = useDispatch()
	const [ filter, setCurrentFilter ] = useState(0)

	useEffect(() => {
		setCurrentFilter(circleFilter)
		OnSearchChanged(circleFilter)
	}, [])


	const onChange = (e) => {
		setCurrentFilter(e.target.value)
		OnSearchChanged(e.target.value)
	}

	const changeFilter = (value) => {
		if (value && value.length > 2) {
			dispatch(searchCircles(value))
		}
		if (!value || value.length === 0) {
			dispatch(searchCircles(''))
		}

		if(onSearchChange){
			onSearchChange(value)
		}
	}

	const OnSearchChanged = useConstant(() => debounce(changeFilter, debounceMilliseconds, { leading: true }))
	return (
		<div>
			<Fragment style={{ width: '100%' }}>
				<Paper style={{ width: '100%', padding: '10px', boxSizing: 'border-box' }}>
					<InputBase
						fullWidth
						placeholder={intl.formatMessage({ id: 'app.general.searchPlaceholder' })}
						startAdornment={
							<div style={{ margin: '2px 10px 0 4px' }}>
								<SearchIcon />
							</div>
						}
						value={filter}
						onChange={onChange}
						style={{ minHeight: '34px', lineHeight: 'normal'}}
					/>
				</Paper>
				<Typography variant="body2" style={{ paddingTop: '4px', paddingBottom: '16px', textAlign: 'left' }}>
						{intl.formatMessage({ id: 'app.circles.search' })}
				</Typography>
			</Fragment>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	circleFilter: circleSearchTermSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl
)

export default enhance(SearchBar)
