import React, { useState, useRef } from 'react'
import { compose } from 'recompose'
import Grid from '@material-ui/core/Grid'
import { useDispatch } from 'react-redux'
import { injectIntl } from 'react-intl'
import { useTheme } from '@material-ui/core/styles'
import { useRouteMatch } from 'react-router-dom'
import { reduxForm } from 'redux-form'
import { showModal, hideModal } from '../../redux/actions'
import SearchBundle from './SearchBundle'
import EntityAuditCommentModal from "../entityAudit/EntityAuditCommentModal"
import { isAuditCommentsRequired } from '../../utilities/auditUtils'

const BundleSelector = ({
	node,
	list,
	intl,
	onAddCallback,
	onCancelCallback,
	position,
	handleSubmit,
	suppressNonDiscreteQuestions = false
}) => {
	const theme = useTheme()
	const [ type, setType ] = useState(null)
	const [ showBuilder, setShowBuilder ] = useState(false)
	const childRef = useRef()
	const match = useRouteMatch()
	const dispatch = useDispatch()

	const onCreateNewRequestedCallback = () => {
		setShowBuilder(true)
	}

	const onAddQuestionCallback = (question, commentsObj) => {
		if (isAuditCommentsRequired())
		{
			dispatch(
				showModal(
					EntityAuditCommentModal,
					{
						entityId: match.params.surveyId,
						entityTypeId: 16, //survey
						saveCallback: (commentsObj) => {
							dispatch(hideModal())
							onAddCallback(false, question, commentsObj)
						}
					}
				)
			)		
		}
		else {
			onAddCallback(false, question)
		}
	}

	const onSubmitForm = (values) => {
		onAddQuestionCallback(childRef.current.getQuestion())
	}

	return (
		<form onSubmit={handleSubmit(onSubmitForm)}>
			<Grid container direction="column" style={{ backgroundColor: theme.palette.primary.A200, marginBottom: 0 }}>
				<Grid item>
					<SearchBundle
						position={position}
						onAddCallback={onAddQuestionCallback}
						onCancelCallback={onCancelCallback}
					/>
				</Grid>
			</Grid>
		</form>
	)
}

const enhance = compose(
	reduxForm({
		form: 'createQuestion',
		touchOnChange: true,
		touchOnBlur: true
	}),
	injectIntl
)

export default enhance(BundleSelector)
