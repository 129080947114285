import React from 'react'
import Grid from '@material-ui/core/Grid'
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'
import { useRouteMatch } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import {} from '../../../utilities/constants'
import MoreVertIcon from '@material-ui/icons/MoreVert'

const LanguageNavigator = ({ language }) => {
	const match = useRouteMatch()

	return (
		<Grid container justify="space-between" alignItems="center" style={{ minHeight: '100px' }}>
			<Grid item>
				<Grid container direction="column">
					<Grid item>
						{language && (
							<Typography
								variant="subtitle1"
								style={{
									textAlign: 'left',
									wordBreak: 'break-word',
									fontSize: '13px',
									lineHeight: '16px',
									letterSpacing: '2.08px',
									fontWeight: '400',
									color: 'inherit'
								}}
							>
								{language.label}
							</Typography>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

const enhance = compose(injectIntl)

export default enhance(LanguageNavigator)
