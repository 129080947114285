import React, { useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import { useTheme } from '@material-ui/core/styles'
import QuestionCollectionAutoComplete from './QuestionCollectionAutoComplete'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import { Typography } from '@material-ui/core'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { useDispatch, useSelector } from 'react-redux'
import { startSaving } from '../../redux/actions'
import { isSavingSelector } from '../../redux/selectors'

const SearchQuestion = ({
	position,
	onCancelCallback,
	onCreateNewRequestedCallback,
	onAddCallback,
	searchType,
	intl}) => {
	const theme = useTheme()
	const dispatch = useDispatch()
	const isSaving = useSelector(isSavingSelector)
	const [ addQuestion, setAddQuestion ] = useState(false)
	const [ addedQuestion, setAddedQuestion ] = useState(null)

	const handleQuestionSelected = (question) => {
		setAddQuestion(true)
		setAddedQuestion(question)
	}
	const handleQuestionCleared = () => {
		setAddQuestion(false)
	}

	const addExistingQuestion = () => {
		dispatch(startSaving())
		onAddCallback(false, addedQuestion.id, addedQuestion.questionTypeId, position)
	}

	return (
		<Grid container style={{ minHeight: '100px', padding: '15px' }}>
			<Grid item xs style={{display: 'flex', alignItems: 'center', paddingRight: '16px'}}>
				<IconButton onClick={() => onCancelCallback(false)}>
					<DeleteIcon />
				</IconButton>
			</Grid>		
			<Grid item style={{ flexGrow: '100', border: 'dashed 1px black', padding: '16px', borderRadius: '5px' }}>
				<Grid container alignItems="center">
					<Grid item xs style={{ flexGrow: '100' }}>
						<QuestionCollectionAutoComplete
							label = {intl.formatMessage({ id: 'app.questions.search' })}
							searchType={searchType}
							onQuestionSelectedCallback={handleQuestionSelected}
							onQuestionClearedCallback={handleQuestionCleared}
						/>
					</Grid>
					<Grid item style={{ paddingLeft: '16px' }}>
						{addQuestion ? (
							<Button
								disableRipple={true}
								disableFocusRipple={true}
								disabled={isSaving}
								onClick={addExistingQuestion}
								variant="text"
							>									
								ADD
								<AddCircleOutlineIcon
									style={{
										fontSize: '24px',
										fontWeight: '400',
										marginLeft: '5px'
									}}
								/>
							</Button>
						) : (
							<Button
								disableRipple={true}
								disableFocusRipple={true}
									onClick={onCreateNewRequestedCallback}
									variant="text"
							>
								CREATE NEW
								<AddCircleOutlineIcon
									style={{
										fontSize: '24px',
										fontWeight: '400',
										marginLeft: '5px'
									}}
								/>
							</Button>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

const enhance = compose(injectIntl)

export default enhance(SearchQuestion)
