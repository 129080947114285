import React, { lazy, Suspense } from 'react'
import componentLoader from '../../utilities/componentLoader'

const Bundles = lazy(() => componentLoader(() => import('./components/Bundles')))

export default () => (
	<div>
		<Suspense fallback={<div />}>
			<section>
				<Bundles />
			</section>
		</Suspense>
	</div>
)
