import React, { useEffect, forwardRef, useImperativeHandle, useRef } from 'react'
import { useStore } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import DefaultFormTextField from '../DefaultFormTextField'
import DefaultFormNumberField from '../DefaultFormNumberField'
import { required, mustBeLessThanValue, greaterThanValue } from '../../utilities/validators'
import { uuidv4 } from '../../utilities/utilities'
import { formValueSelector } from 'redux-form'
import UploadImage from './Analog/UploadImage'
import FormCheckBox from '../FormCheckBox'
import { useIntl } from 'react-intl'

const requiredValidator = required
const selector = formValueSelector('createQuestion')
const lowerLessThanMaxValueValidator = mustBeLessThanValue('endValue')
const greaterThanValueValidator = greaterThanValue(0)

export default forwardRef((props, ref) => {
	const store = useStore()
	const startImageRef = useRef()
	const endImageRef = useRef()
	const intl = useIntl()
	useImperativeHandle(ref, () => ({
		getQuestion() {
			return {
				id: uuidv4().replace('-', ''),
				title: selector(store.getState(), 'question'),
				type: 'analog',
				instructions: selector(store.getState(), 'instructions'),
				tag: selector(store.getState(), 'tag'),
				startValue: selector(store.getState(), 'startValue'),
				startText: selector(store.getState(), 'startText'),
				endValue: selector(store.getState(), 'endValue'),
				stepValue: selector(store.getState(), 'stepValue'),
				endText: selector(store.getState(), 'endText'),
				startImage: startImageRef.current.getFile(),
				endImage: endImageRef.current.getFile(),
				isOptional: selector(store.getState(), 'isOptional'),
				displayInstructionAsIcon: selector(store.getState(), 'displayInstructionAsIcon'),
			}
		}
	}))

	useEffect(() => {
		var el = document.getElementsByName('tag')
		if (el != null) {
			el[0].focus()
		}
	}, [])

	return (
		<Grid container direction="column" alignItems="flex-start">
			<Grid item style={{ width: '100%' }}>
				<DefaultFormTextField
					label="Tag"
					variant="filled"
					name="tag"
					validators={[ requiredValidator ]}
					fullWidth
					inputProps={{ maxLength: 254 }}
					maxLength={254}
				/>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				<DefaultFormTextField
					label="Question"
					variant="filled"
					name="question"
					fullWidth
					validators={[ requiredValidator ]}
					inputProps={{ maxLength: 1023 }}
					maxLength={1023}
				/>
			</Grid>
			<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
				<DefaultFormTextField
					label="Instructions"
					variant="filled"
					fullWidth
					name="instructions"
					inputProps={{ maxLength: 2047 }}
					maxLength={2047}
					multiline
					rows="3"
				/>
			</Grid>
			<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '0px' }}>
				<FormCheckBox
					name="displayInstructionAsIcon"
					color="primary"
					label={intl.formatMessage({ id: 'app.survey.questions.displayInstructionAsIcon' })}
				/>
			</Grid>
			<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '0px' }}>
				<FormCheckBox
					name="isOptional"
					color="primary"
					label={intl.formatMessage({ id: 'app.survey.questions.isOptional' })}
				/>
			</Grid>

			<Grid item>
				<Typography variant="h4" style={{ fontWeight: '400', paddingBottom: '10px' }}>
					Analog Answer
				</Typography>
			</Grid>

			<Grid item>
				<Grid container direction="row" spacing={2}>
					<Grid item style={{ paddingBottom: '10px' }}>
						<DefaultFormNumberField
							label="Start Value"
							validators={[ requiredValidator, lowerLessThanMaxValueValidator ]}
							allowDecimal={true}
							variant="filled"
							name="startValue"
							fullWidth
						/>
					</Grid>
					<Grid item style={{ paddingBottom: '10px' }}>
						<DefaultFormNumberField
							label="End Value"
							validators={[ requiredValidator ]}
							allowDecimal={true}
							name="endValue"
							variant="filled"
							fullWidth
						/>
					</Grid>
					<Grid item style={{ paddingBottom: '10px' }}>
						<DefaultFormNumberField
							label="Step Value"
							validators={[ requiredValidator, greaterThanValueValidator ]}
							allowDecimal={true}
							name="stepValue"
							variant="filled"
							fullWidth
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid container direction="row" spacing={2}>
					<Grid item style={{ paddingBottom: '10px' }}>
						<DefaultFormTextField
							label="Start Text"
							variant="filled"
							name="startText"
							fullWidth
							inputProps={{ maxLength: 254 }}
							maxLength={254}
						/>
					</Grid>
					<Grid item style={{ paddingBottom: '10px' }}>
						<DefaultFormTextField
							label="End Text"
							name="endText"
							fullWidth
							variant="filled"
							inputProps={{ maxLength: 254 }}
							maxLength={254}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid container direction="row" spacing={2}>
					<Grid item style={{ paddingBottom: '10px', width: '207px' }}>
						<Grid container direction="row">
							<Grid item>
								<UploadImage name="startImage" ref={startImageRef} />
							</Grid>
							<Grid item style={{ padding: '3px 3px 3px 10px' }}>
								<Typography>Start Image</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item style={{ paddingBottom: '10px', width: '207px' }}>
						<Grid container>
							<Grid item direction="row">
								<UploadImage name="endImage" ref={endImageRef} />
							</Grid>
							<Grid item style={{ padding: '3px 3px 3px 10px' }}>
								<Typography>End Image</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
})
