import React, { useState, Fragment } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { useTheme, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import SurveyBirthDateField from './SurveyBirthDateField'

const TextBirthDateQuestion = ({ question, intl }) => {
	const theme = useTheme()

	return <SurveyBirthDateField label={question.title} backgroundColor="white" fontColor="black" />
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(TextBirthDateQuestion)
