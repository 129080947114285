import { getUser } from '../utilities/userUtilities'
import { authenticatedFetch } from './base'
export const createUser = (email, firstName, lastName, jurisdictionId) => {
	return authenticatedFetch(`/api/admin/user`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ email: email.trim(), firstName, lastName, jurisdictionId })
	})
		.then(function(response) {
			return response.json()
		})
		.then(function(data) {
			return data
		})
}

export const fetch = () => {
	return authenticatedFetch(`/api/admin/user`, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const disableUser = (id) => {
	return authenticatedFetch(`/api/admin/user/${id}/disable`, {
		method: 'post',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			return response.json()
		})
		.then(function(data) {
			return data
		})
}

export const enableUser = (id) => {
	return authenticatedFetch(`/api/admin/user/${id}/enable`, {
		method: 'post',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			return response.json()
		})
		.then(function(data) {
			return data
		})
}
