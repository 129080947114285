import React, { useState, useRef } from 'react'
import { compose } from 'recompose'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'
import { addQuestion, stopBuildingContent, showModal, hideModal } from '../../redux/actions'
import { useTheme } from '@material-ui/core/styles'
import SurveyContentTextBuilder from './ContentTextBuilder'
import { useRouteMatch } from 'react-router-dom'
import { reduxForm } from 'redux-form'
import { useDispatch } from 'react-redux'
import CalmButton from '../../components/CalmButton'
import EntityAuditCommentModal from "../../components/entityAudit/EntityAuditCommentModal"
import { isAuditCommentsRequired } from '../../utilities/auditUtils'

const ContentBuilder = ({ onAddCallback, position, handleSubmit }) => {
	const theme = useTheme()
	const childRef = useRef()
	const match = useRouteMatch()
	const dispatch = useDispatch()
	const [isAdding, setIsAdding] = useState(false)

	const onSubmitForm = (question) => {
		if (isAuditCommentsRequired())
		{
			dispatch(
				showModal(
					EntityAuditCommentModal,
					{
						entityId: match.params.protocolId !== undefined ? match.params.protocolId : match.params.surveyId === undefined ? match.params.bundleId : match.params.surveyId,
						entityTypeId: match.params.protocolId !== undefined ? 17 : match.params.surveyId === undefined ? 15 : 16, //protocol : bundle : survey
						saveCallback: (commentsObj) => {
							dispatch(hideModal())
							setIsAdding(true)
							if (childRef && childRef.current) {
								onAddCallback(false, childRef.current.getQuestion(), commentsObj)
							}
						}
					}
				)
			)
		}
		else 
		{
			setIsAdding(true)
			if (childRef && childRef.current) {
				onAddCallback(false, childRef.current.getQuestion())
			}
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmitForm)}>
			<Grid
				container
				direction="column"
				style={{ backgroundColor: theme.palette.primary.A200, padding: '3%', marginBottom: '20px' }}
			>
				<Grid item>
					<SurveyContentTextBuilder onCancel={null} ref={childRef} />
				</Grid>

				<Grid item style={{ alignSelf: 'flex-end', padding: '3%' }}>
					<Grid container spacing={2}>
						<Grid item>
							<Button
                                variant="outlined"
                                color="primary"
								onClick={() => dispatch(stopBuildingContent())}
							>
								Discard
							</Button>
						</Grid>
						<Grid item>
							<CalmButton
								variant="contained"
                                color="primary"
								type="submit"
								disabled={isAdding}
							>
								Save
							</CalmButton>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</form>
	)
}

const enhance = compose(
	reduxForm({
		form: 'createContent',
		touchOnChange: true,
		touchOnBlur: true
	}),
	injectIntl
)

export default enhance(ContentBuilder)
