import React, { lazy, Suspense } from 'react'
import componentLoader from '../../utilities/componentLoader'

const CircleDetails = lazy(() => componentLoader(() => import('./components/CircleDetails')))

export default () => (
	<div>
		<Suspense fallback={<div/>}>
			<section>
				<CircleDetails />
			</section>
		</Suspense>
	</div>
)
