import * as survey from '../../../apis/survey'
import { createSelector } from 'reselect'
import { hideModal, fetchBuilderSurvey, stopSaving } from '../../actions'
import { Node, LinkList, ListContainer } from '../../../utilities/linkedList'
import history from '../../../utilities/history'

const CLEAR_SURVEYS_REQUESTED = 'CLEAR_SURVEYS_REQUESTED'

export const clearSurveys = () => (dispatch) => {
	return dispatch({ type: CLEAR_SURVEYS_REQUESTED, data: {} })
}

const FETCH_SURVEYS_WITH_METRICS_SUCCESS = 'FETCH_SURVEYS_WITH_METRICS_SUCCESS'
const FETCH_SURVEYS_WITH_METRICS_FAILED = 'FETCH_SURVEYS_WITH_METRICS_FAILED'

export const fetchSurveysWithMetrics = (search) => (dispatch, getState) => {
	let state = getState()
	dispatch({ type: CLEAR_SURVEYS_REQUESTED, data: {} })
	survey.fetchSurveys(search, state.survey.filter).then((response) => {
		if (response && response.isSuccessful && response.surveys) {
			let data = response.surveys

			return dispatch({ 
				type: FETCH_SURVEYS_WITH_METRICS_SUCCESS, 
				data: {
					items: data,
					hasMore: response.hasMore
				} 
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.surveys.fetchListFailed', isSuccess: false }
			})
		}
	})
}

const FETCH_ADDITIONAL_SURVEYS_WITH_METRICS_REQUESTED = 'FETCH_ADDITIONAL_SURVEYS_WITH_METRICS_REQUESTED'
const FETCH_ADDITIONAL_SURVEYS_WITH_METRICS_SUCCESS = 'FETCH_ADDITIONAL_SURVEYS_WITH_METRICS_SUCCESS'
const FETCH_ADDITIONAL_SURVEYS_WITH_METRICS_FAILED = 'FETCH_ADDITIONAL_SURVEYS_WITH_METRICS_FAILED'
export const fetchAdditionalSurveysWithMetrics = (searchPhrase, offset) => (dispatch, getState) => {
	let state = getState()
	dispatch({ type: FETCH_ADDITIONAL_SURVEYS_WITH_METRICS_REQUESTED, data: {} })
	survey.fetchSurveys(searchPhrase, state.survey.filter, offset).then((response) => {
		if (response && response.isSuccessful && response.surveys) {
			let data = response.surveys
			return dispatch({ 
				type: FETCH_ADDITIONAL_SURVEYS_WITH_METRICS_SUCCESS, 
				data: {
					items: data,
					hasMore: response.hasMore
				} 
			})
		} else {
			dispatch({ type: FETCH_ADDITIONAL_SURVEYS_WITH_METRICS_FAILED, data: {}})
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.surveys.fetchListFailed', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_REMAINING_LANGUAGES_REQUESTED = 'FETCH_REMAINING_LANGUAGES_REQUESTED'
const FETCH_REMAINING_LANGUAGES_SUCCESS = 'FETCH_REMAINING_LANGUAGES_SUCCESS'
const FETCH_REMAINING_LANGUAGES_FAILED = 'FETCH_REMAINING_LANGUAGES_FAILED'

export const fetchRemainingLanguages = (id) => (dispatch) => {
	dispatch({ type: FETCH_REMAINING_LANGUAGES_REQUESTED, data: {} })
	survey.fetchRemainingLanguages(id).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({ type: FETCH_REMAINING_LANGUAGES_SUCCESS, data: response.languages })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.surveys.fetchListFailed', isSuccess: false }
			})
		}
	})
}

const CLEAR_SURVEYS_AUTOCOMPLETE_REQUESTED = 'CLEAR_SURVEYS_AUTOCOMPLETE_REQUESTED'
const CLEAR_SURVEYS_AUTOCOMPLETE_SUCCESS = 'CLEAR_SURVEYS_AUTOCOMPLETE_SUCCESS'
const CLEAR_SURVEYS_AUTOCOMPLETE_FAILED = 'CLEAR_SURVEYS_AUTOCOMPLETE_FAILED'

export const clearSurveyAutoComplete = (searchPhrase) => (dispatch) => {
	return dispatch({ type: CLEAR_SURVEYS_AUTOCOMPLETE_REQUESTED, data: {} })
}

const FETCH_SURVEYS_AUTOCOMPLETE_REQUESTED = 'FETCH_SURVEYS_AUTOCOMPLETE_REQUESTED'
const FETCH_SURVEYS_AUTOCOMPLETE_SUCCESS = 'FETCH_SURVEYS_AUTOCOMPLETE_SUCCESS'
const FETCH_SURVEYS_AUTOCOMPLETE_FAILED = 'FETCH_SURVEYS_AUTOCOMPLETE_FAILED'

export const fetchSurveyAutoComplete = (searchPhrase) => (dispatch) => {
	dispatch({ type: FETCH_SURVEYS_AUTOCOMPLETE_REQUESTED, data: {} })
	survey.fetchProtocolAutoComplete(searchPhrase).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({ type: FETCH_SURVEYS_AUTOCOMPLETE_SUCCESS, data: response.data })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.surveys.fetchListFailed', isSuccess: false }
			})
		}
	})
}

const FETCH_SURVEY_REQUESTED = 'FETCH_SURVEY_REQUESTED'
const FETCH_SURVEY_SUCCESS = 'FETCH_SURVEY_SUCCESS'
const FETCH_SURVEY_FAILED = 'FETCH_SURVEY_FAILED'

export const fetchSurvey = (id, instanceId, versionId) => (dispatch) => {
	dispatch({ type: FETCH_SURVEY_REQUESTED, data: {} })
	dispatch(getSurveyLanguages(id))
	survey.fetchSurveyDetails(id, instanceId, versionId).then((response) => {
		if (response && response.isSuccessful) {
			let questions = new Array(0)
			let hash = {}
			let container = new ListContainer()

			processSurveyQuestions(response, container, questions, hash)

			return dispatch({
				type: FETCH_SURVEY_SUCCESS,
				data: { survey: response, list: container, flat: questions, nodes: hash }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.surveys.fetchSurveyFailed', isSuccess: false }
			})
		}
	})
}

const SET_SURVEY_SORT_REQUESTED = 'SET_SURVEY_SORT_REQUESTED'

export const setSurveysSort = (field, isDescending) => (dispatch, state) => {
	let currentState = state()

	let data = currentState.survey.surveys.sort((a, b) => {
		if (isDescending) {
			return a[field] > b[field] ? 1 : -1
		} else {
			return b[field] > a[field] ? 1 : -1
		}
	})
	return dispatch({
		type: SET_SURVEY_SORT_REQUESTED,
		data: { items: data, field: field, isDescending: isDescending }
	})
}

const FETCH_SURVEY_COPY_REQUESTED = 'FETCH_SURVEY_COPY_REQUESTED'
const FETCH_SURVEY_COPY_SUCCESS = 'FETCH_SURVEY_COPY_SUCCESS'
const FETCH_SURVEY_COPY_FAILED = 'FETCH_SURVEY_COPY_FAILED'

export const fetchSurveyCopy = (id, languageId) => (dispatch) => {
	dispatch({ type: FETCH_SURVEY_COPY_REQUESTED, data: {} })
	survey.fetchCopy(id, languageId).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_SURVEY_COPY_SUCCESS, data: response })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.surveys.fetchListFailed', isSuccess: false }
			})
		}
	})
}

const SAVE_SURVEY_COPY_REQUESTED = 'SAVE_SURVEY_COPY_REQUESTED'
const SAVE_SURVEY_COPY_SUCCESS = 'SAVE_SURVEY_COPY_SUCCESS'
const SAVE_SURVEY_COPY_FAILED = 'SAVE_SURVEY_COPY_FAILED'
export const saveSurveyCopy = (id, title, description, langaugeId = 1, commentsObj) => (dispatch) => {
	dispatch({ type: SAVE_SURVEY_COPY_REQUESTED, data: {} })

	survey.saveSurveyCopy(id, title, description, langaugeId, commentsObj).then((survey) => {
		dispatch(stopSaving())
		if (survey && survey.isSuccessful) {
			dispatch(fetchSurvey(id))
			dispatch({ type: SAVE_SURVEY_COPY_SUCCESS, data: survey })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.successfullyUpdated', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.survey.saveSurveyFailed', isSuccess: false }
			})
		}
	})
}

const SAVE_SURVEY_SETTINGS_REQUESTED = 'SAVE_SURVEY_SETTINGS_REQUESTED'
const SAVE_SURVEY_SETTINGS_SUCCESS = 'SAVE_SURVEY_SETTINGS_SUCCESS'
const SAVE_SURVEY_SETTINGS_FAILED = 'SAVE_SURVEY_SETTINGS_FAILED'
export const saveSurveySettings = (id, surveyType, promDescription, promReferences) => (dispatch) => {
	dispatch({ type: SAVE_SURVEY_SETTINGS_REQUESTED, data: {} })

	survey.saveSurveySettings(id, parseInt(surveyType), promDescription, promReferences).then((response) => {
		dispatch(stopSaving())
		if (response && response.isSuccessful) {
			//dispatch({ type: SAVE_SURVEY_SETTINGS_SUCCESS, data: survey })
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.successfullyUpdated', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.survey.saveSurveyFailed', isSuccess: false }
			})
		}
	})
}

const CREATE_SURVEY_REQUESTED = 'CREATE_SURVEY_REQUESTED'
const CREATE_SURVEY_SUCCESS = 'CREATE_SURVEY_SUCCESS'
const CREATE_SURVEY_FAILED = 'CREATE_SURVEY_FAILED'
export const createSurvey = (title, language, surveyType) => (dispatch) => {
	dispatch({ type: CREATE_SURVEY_REQUESTED, data: {} })

	survey.createSurvey(title, language, surveyType).then((survey) => {
		dispatch(stopSaving())
		if (survey && survey.isSuccessful) {
			dispatch({ type: CREATE_SURVEY_SUCCESS, data: survey })
			history.push(`/surveys/${survey.id}/details`)
			return dispatch(hideModal())
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.survey.addSurveyFailed', isSuccess: false }
			})
		}
	})
	return
}

const CREATE_CHILD_SURVEY_REQUESTED = 'CREATE_CHILD_SURVEY_REQUESTED'
const CREATE_CHILD_SURVEY_SUCCESS = 'CREATE_CHILD_SURVEY_SUCCESS'
const CREATE_CHILD_SURVEY_FAILED = 'CREATE_CHILD_SURVEY_FAILED'
export const createChildSurvey = (id, language) => (dispatch) => {
	dispatch({ type: CREATE_CHILD_SURVEY_REQUESTED, data: {} })

	survey.createChildSurvey(id, language).then((survey) => {
		dispatch(stopSaving())
		if (survey && survey.isSuccessful) {
			dispatch({ type: CREATE_CHILD_SURVEY_SUCCESS, data: survey })
			return dispatch(hideModal())
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.survey.createLanguageSurveyFailed', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_SURVEY_LANGUAGES_REQUESTED = 'FETCH_SURVEY_LANGUAGES_REQUESTED'
const FETCH_SURVEY_LANGUAGES_SUCCESS = 'FETCH_SURVEY_LANGUAGES_SUCCESS'
const FETCH_SURVEY_LANGUAGES_FAILED = 'FETCH_SURVEY_LANGUAGES_FAILED'
export const getSurveyLanguages = (id) => (dispatch) => {
	dispatch({ type: FETCH_SURVEY_LANGUAGES_REQUESTED, data: {} })

	survey.fetchLanguages(id).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_SURVEY_LANGUAGES_SUCCESS, data: response.languages })
		} else {
			// return dispatch({
			// 	type: 'SET_SNACKBAR_MESSAGE',
			// 	data: { message: 'app.survey.createLanguageSurveyFailed', isSuccess: false }
			// })
		}
	})
	return
}

const processSurveyQuestions = (survey, container, questions, hash) => {
	if (survey.questions) {
		survey.questions.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1)).map((r) => {
			container.getList().append(r)
		})
		container.getList().traverse((r) => {
			questions.push(r.data.id)
			hash[r.data.id] = r
		})
	}
}

const SAVE_SURVEY_REQUESTED = 'SAVE_SURVEY_REQUESTED'
const SAVE_SURVEY_SUCCESS = 'SAVE_SURVEY_SUCCESS'
const SAVE_SURVEY_FAILED = 'SAVE_SURVEY_FAILED'
export const saveSurvey = (surveyId, name, description) => (dispatch, getState) => {}

const UPDATE_SURVEY_INTERNAL_DESCRIPTION_REQUESTED = 'UPDATE_SURVEY_INTERNAL_DESCRIPTION_REQUESTED'
export const updateSurveyInternalDescription = (description) => (dispatch) => {
	return dispatch({ type: UPDATE_SURVEY_INTERNAL_DESCRIPTION_REQUESTED, data: { description } })
}

const UPDATE_SURVEY_TITLE_REQUESTED = 'UPDATE_SURVEY_TITLE_REQUESTED'
export const updateSurveyTitle = (description) => (dispatch) => {
	return dispatch({ type: UPDATE_SURVEY_TITLE_REQUESTED, data: { description } })
}

const SET_SURVEYS_FILTER_REQUESTED = 'SET_SURVEYS_FILTER_REQUESTED'
const SET_SURVEYS_FILTER_SUCCESS = 'SET_SURVEYS_FILTER_SUCCESS'
const SET_SURVEYS_FILTER_FAILED = 'SET_SURVEYS_FILTER_FAILED'

export const setSurveyFilter = (filter) => (dispatch, getState) => {
	let state = getState()
	dispatch({ type: SET_SURVEYS_FILTER_REQUESTED, data: {} })

	dispatch({ type: SET_SURVEYS_FILTER_SUCCESS, data: filter })
	return dispatch(fetchSurveysWithMetrics(state.survey.searchTerm))
}
const CLONE_SURVEY_REQUESTED = 'CLONE_SURVEY_REQUESTED'
const CLONE_SURVEY_SUCCESS = 'CLONE_SURVEY_SUCCESS'
const CLONE_SURVEY_FAILED = 'CLONE_SURVEY_FAILED'

export const cloneSurvey = (id, newName) => (dispatch) => {
	dispatch({ type: CLONE_SURVEY_REQUESTED, data: {} })

	survey.cloneSurvey(id, newName).then((response) => {
		dispatch(stopSaving())
		if (response && response.isSuccessful) {
			history.push('/surveys/' + response.id + '/details')
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.successfullyUpdated', isSuccess: true }
			})
			return dispatch(hideModal())
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.survey.cloneSurveyFailed', isSuccess: false }
			})
		}
	})
	return
}

const ACTIVATE_SURVEY_REQUESTED = 'ACTIVATE_SURVEY_REQUESTED'
const ACTIVATE_SURVEY_SUCCESS = 'ACTIVATE_SURVEY_SUCCESS'
const ACTIVATE_SURVEY_FAILED = 'ACTIVATE_SURVEY_FAILED'

export const activateSurvey = (id) => (dispatch, getState) => {
	dispatch({ type: ACTIVATE_SURVEY_REQUESTED, data: {} })
	survey.activateSurvey(id).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(fetchSurvey(id))
			var state = getState()
			if (state && state.survey && state.survey.surveys) {
				let newSurveys = [ ...state.survey.surveys ]
				newSurveys.map((r) => {
					if (r.id === id) {
						r.isActive = true
					}
				})

				dispatch({ type: FETCH_SURVEYS_WITH_METRICS_SUCCESS, data: newSurveys })
			}

			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.successfullyUpdated', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.UpdateFailed', isSuccess: false }
			})
		}
	})
}

const DEACTIVATE_SURVEY_REQUESTED = 'DEACTIVATE_SURVEY_REQUESTED'
const DEACTIVATE_SURVEY_SUCCESS = 'DEACTIVATE_SURVEY_SUCCESS'
const DEACTIVATE_SURVEY_FAILED = 'DEACTIVATE_SURVEY_FAILED'

export const deActivateSurvey = (id) => (dispatch, getState) => {
	dispatch({ type: DEACTIVATE_SURVEY_REQUESTED, data: {} })
	survey.deActivateSurvey(id).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(fetchSurvey(id))
			var state = getState()
			if (state && state.survey && state.survey.surveys) {
				let newSurveys = [ ...state.survey.surveys ]
				newSurveys.map((r) => {
					if (r.id === id) {
						r.isActive = false
					}
				})
				dispatch({ type: FETCH_SURVEYS_WITH_METRICS_SUCCESS, data: newSurveys })
			}

			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.successfullyUpdated', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.UpdateFailed', isSuccess: false }
			})
		}
	})
}

const ADD_SURVEY_BUNDLE_REQUESTED = 'ADD_SURVEY_BUNDLE_REQUESTED'
const ADD_SURVEY_BUNDLE_SUCCESS = 'ADD_SURVEY_BUNDLE_SUCCESS'
const ADD_SURVEY_BUNDLE_FAILED = 'ADD_SURVEY_BUNDLE_FAILED'

export const addSurveyBundle = (groupId, position, surveyId, commentsObj) => (dispatch, getState) => {
	dispatch({ type: ADD_SURVEY_BUNDLE_REQUESTED, data: {} })
	let state = getState()

	survey.addBundle(surveyId, position, groupId, commentsObj).then((response) => {
		dispatch(stopSaving())
		if (response && response.isSuccessful) {
			dispatch(fetchBuilderSurvey(surveyId, null, 1))
		}
	})
}

const SET_SURVEY_SEARCH_TERM_REQUESTED = 'SET_SURVEY_SEARCH_TERM_REQUESTED'
const SET_SURVEY_SEARCH_TERM_SUCCESS = 'SET_SURVEY_SEARCH_TERM_SUCCESS'
const SET_SURVEY_SEARCH_TERM_FAILED = 'SET_SURVEY_SEARCH_TERM_FAILED'

export const setSurveySearchTerm = (value) => (dispatch) => {
	dispatch({ type: SET_SURVEY_SEARCH_TERM_REQUESTED, data: {} })
	return dispatch({ type: SET_SURVEY_SEARCH_TERM_SUCCESS, data: value })
}

const FETCH_SURVEYS_BY_QUESTION_REQUESTED = 'FETCH_SURVEYS_BY_QUESTION_REQUESTED'
const FETCH_SURVEYS_BY_QUESTION_SUCCESS = 'FETCH_SURVEYS_BY_QUESTION_SUCCESS'
const FETCH_SURVEYS_BY_QUESTION_FAILED = 'FETCH_SURVEYS_BY_QUESTION_FAILED'

export const fetchQuestionRelatedSurveys = (questionId, questionType) => (dispatch, getState) => {
	dispatch({ type: FETCH_SURVEYS_BY_QUESTION_REQUESTED, data: {} })
	survey.fetchSurveysByQuestion(questionId, questionType).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_SURVEYS_BY_QUESTION_SUCCESS, data: response.surveys })
		} else {
			dispatch({ type: FETCH_SURVEYS_BY_QUESTION_FAILED, data: null })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.surveys.fetchListFailed', isSuccess: false }
			})
		}
	})
}

const FETCH_SURVEYS_BY_BUNDLE_REQUESTED = 'FETCH_SURVEYS_BY_BUNDLE_REQUESTED'
const FETCH_SURVEYS_BY_BUNDLE_SUCCESS = 'FETCH_SURVEYS_BY_BUNDLE_SUCCESS'
const FETCH_SURVEYS_BY_BUNDLE_FAILED = 'FETCH_SURVEYS_BY_BUNDLE_FAILED'

export const fetchBundleRelatedSurveys = (bundleId) => (dispatch, getState) => {
	dispatch({ type: FETCH_SURVEYS_BY_BUNDLE_REQUESTED, data: {} })
	survey.fetchSurveysByBundle(bundleId).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_SURVEYS_BY_BUNDLE_SUCCESS, data: response.surveys })
		} else {
			dispatch({ type: FETCH_SURVEYS_BY_BUNDLE_FAILED, data: null })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.surveys.fetchListFailed', isSuccess: false }
			})
		}
	})
}

const CHANGE_QUESTION_STATE_RELATED_ENTITIES_REQUESTED = 'CHANGE_QUESTION_STATE_RELATED_ENTITIES_REQUESTED'
const CHANGE_QUESTION_STATE_RELATED_ENTITIES_SUCCESS = 'CHANGE_QUESTION_STATE_RELATED_ENTITIES_SUCCESS'
const CHANGE_QUESTION_STATE_RELATED_ENTITIES_FAILED = 'CHANGE_QUESTION_STATE_RELATED_ENTITIES_FAILED'

export const changeSurveyQuestionState = (surveyId, languageId, node, newState, commentsObj) => (dispatch) => {
	dispatch({ type: CHANGE_QUESTION_STATE_RELATED_ENTITIES_REQUESTED, data: {} })
	survey.updateQuestionStateRelatedEntities(surveyId, node.data.id, node.data.questionType, newState, commentsObj).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(fetchBuilderSurvey(surveyId, null, languageId))

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.survey.successfullyChangeQuestionState', isSuccess: true }
			})
		} else {
			dispatch({ type: CHANGE_QUESTION_STATE_RELATED_ENTITIES_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.survey.failedChangeQuestionState', isSuccess: false }
			})
		}
	})
}

const CHANGE_BUNDLE_STATE_RELATED_ENTITIES_REQUESTED = 'CHANGE_BUNDLE_STATE_RELATED_ENTITIES_REQUESTED'
const CHANGE_BUNDLE_STATE_RELATED_ENTITIES_SUCCESS = 'CHANGE_BUNDLE_STATE_RELATED_ENTITIES_SUCCESS'
const CHANGE_BUNDLE_STATE_RELATED_ENTITIES_FAILED = 'CHANGE_BUNDLE_STATE_RELATED_ENTITIES_FAILED'

export const changeBundleStateRelatedEntities = (surveyId, languageId, node, newState, commentsObj) => (dispatch) => {
	dispatch({ type: CHANGE_BUNDLE_STATE_RELATED_ENTITIES_REQUESTED, data: {} })
	survey.updateBundleStateRelatedEntities(surveyId, node.data.bundleEntityId, newState, commentsObj).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(fetchBuilderSurvey(surveyId, null, languageId))

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.survey.successfullyChangeBundleState', isSuccess: true }
			})
		} else {
			dispatch({ type: CHANGE_BUNDLE_STATE_RELATED_ENTITIES_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.survey.failedChangeBundleState', isSuccess: false }
			})
		}
	})
}


const initial = {
	survey: null,
	surveyLanguages: [],
	surveys: [],
	hasSurveys: null,
	isCreatingSurvey: false,
	sortField: 'modifiedDateSeconds',
	isDescending: true,
	originalSurvey: null,
	surveyQuestions: null,
	surveyQuestionIds: null,
	surveyQuestionHash: null,
	originalSurveyQuestions: null,
	originalSurveyQuestionIds: null,
	originalSurveyQuestionHash: null,
	autocomplete: [],
	languages: [],
	filter: 0,
	surveyCopy: null,
	hasMore: true,
	isLoading: false,
	searchTerm: '',
	relatedSurveys: null
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case CLEAR_SURVEYS_AUTOCOMPLETE_REQUESTED:
		case FETCH_SURVEYS_AUTOCOMPLETE_REQUESTED:
			return { ...state, autocomplete: [] }
		case FETCH_SURVEYS_AUTOCOMPLETE_SUCCESS:
			return { ...state, autocomplete: action.data }
		case SET_SURVEY_SEARCH_TERM_SUCCESS:
			return { ...state, searchTerm: action.data }
		case UPDATE_SURVEY_INTERNAL_DESCRIPTION_REQUESTED:
			return {
				...state,
				survey: { ...state.survey, internalDescription: action.data.description }
			}
		case FETCH_SURVEY_COPY_SUCCESS:
			return { ...state, surveyCopy: action.data }
		case CREATE_SURVEY_REQUESTED:
			return { ...state, isCreatingSurvey: true }
		case CREATE_SURVEY_FAILED:
			return { ...state, isCreatingSurvey: false }
		case CREATE_SURVEY_SUCCESS:
			return { ...state, isCreatingSurvey: false }
		case FETCH_SURVEYS_WITH_METRICS_SUCCESS:
			return { 
				...state, 
				surveys: action.data.items, 
				hasSurveys: action.data && action.data.items && action.data.items.length > 0 ,
				hasMore: action.data.hasMore
			}
		case FETCH_ADDITIONAL_SURVEYS_WITH_METRICS_SUCCESS:
			return {
				...state,
				surveys: [ ...state.surveys, ...action.data.items],
				hasMore: action.data.hasMore,
				isLoading: false
			}
		case FETCH_ADDITIONAL_SURVEYS_WITH_METRICS_FAILED:
			return {
				...state,
				hasMore: false,
				isLoading: false
			}
		case FETCH_ADDITIONAL_SURVEYS_WITH_METRICS_REQUESTED:
			return {
				...state,
				isLoading: true
			}
		case CLEAR_SURVEYS_REQUESTED:
			return { ...state, surveys: [], hasSurveys: false, filter: 0 }
		case SAVE_SURVEY_COPY_SUCCESS:
			return { ...state, surveyCopy: action.data }
		case FETCH_SURVEY_SUCCESS:
			return {
				...state,
				survey: action.data.survey,
				originalSurvey: action.data.survey,
				surveyQuestions: action.data.list,
				surveyQuestionHash: action.data.nodes,
				surveyQuestionIds: action.data.flat,
				originalSurveyQuestions: action.data.list,
				originalSurveyQuestionHash: action.data.nodes,
				originalSurveyQuestionIds: action.data.flat,
				surveyRefreshed: true
			}

		case SET_SURVEY_SORT_REQUESTED:
			return {
				...state,
				surveys: [ ...action.data.items ],
				sortField: action.data.field,
				isDescending: action.data.isDescending
			}
		case SET_SURVEYS_FILTER_SUCCESS:
			return { ...state, filter: action.data }
		case FETCH_SURVEY_LANGUAGES_SUCCESS:
			return { ...state, surveyLanguages: action.data }
		case FETCH_SURVEYS_BY_QUESTION_SUCCESS:
			return {
				...state,
				relatedSurveys: action.data
			}
		case FETCH_SURVEYS_BY_BUNDLE_SUCCESS:
			return {
				...state,
				relatedSurveys: action.data
			}

		case 'SIGN_OUT_REQUESTED':
			return initial

		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.survey

export const surveyQuestionIdSelector = (state) => state.survey.surveyQuestionIds
export const isCreatingSurveySelector = createSelector(mainSelector, (state) => state.isCreatingSurvey)

const filterSelector = (state) => state.survey.filter
export const hasSurveySelector = (state) => state.survey.hasSurveys

export const sortSurveysDirectionSelector = createSelector(mainSelector, (state) => {
	return state.isDescending
})

export const sortSurveysFieldSelector = createSelector(mainSelector, (state) => {
	return state.sortField
})

export const surveysSelector = createSelector(mainSelector, filterSelector, (state, filter) => {
	// if (state && state.surveys && state.surveys.length > 0) {
	// 	switch (filter) {
	// 		case 0:
	// 			return state.surveys
	// 		case 1:
	// 			return state.surveys.filter((r) => {
	// 				return r.isActive === true
	// 			})
	// 		case 2:
	// 			return state.surveys.filter((r) => {
	// 				return r.isActive === false
	// 			})
	// 	}
	// }
	// return []

	return state && state.surveys
})

export const surveySelector = createSelector(mainSelector, (state) => {
	return state.survey
})

export const surveyAutoCompleteSelector = createSelector(mainSelector, (state) => {
	return state.autocomplete
})

export const surveyLanguagesSelector = createSelector(mainSelector, (state) => {
	return state.surveyLanguages
})

export const surveyLanguageListSelector = createSelector(mainSelector, (state) => {
	return state.surveyLanguages
})

export const surveyQuestionsSelector = createSelector(mainSelector, (state) => {
	return state.surveyQuestions && state.surveyQuestions.getList()
})

export const surveyRemainingLanguagesSelector = createSelector(mainSelector, (state) => {
	return state && state.availableLanguages
})

export const flatSurveyQuestionsSelector = createSelector(mainSelector, surveyQuestionIdSelector, (state, ids) => {
	if (state && ids && ids.length > 0) {
		let questions = []

		ids.map((r) => {
			if (state.surveyQuestionHash.hasOwnProperty(r)) {
				let x = state.surveyQuestionHash[r]

				questions.push(x)
			} else {
				console.log('No Property', questions)
			}
		})

		return questions
	}
})

export const surveyCopySelector = createSelector(mainSelector, (state) => {
	return state && state.surveyCopy
})

export const hasMoreSurveysSelector = createSelector(mainSelector, (state) => {
	return state.hasMore
})

export const isLoadingSurveysSelector = createSelector(mainSelector, (state) => {
	return state.isLoading
})

export const surveySearchTermSelector = createSelector(mainSelector, (state) => {
	return state.searchTerm
})

export const relatedSurveysSelector = createSelector(mainSelector, (state) => {
	return state.relatedSurveys
})
