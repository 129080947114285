import { authenticatedFetch } from './base'
import { updateQuestion } from './question'

export const createSurvey = (title, language, surveyType) => {
	return authenticatedFetch(`/api/survey`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			title,
			language,
			surveyType
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const saveSurveyCopy = (id, title, description, languageId, commentsObj) => {
	return authenticatedFetch(`/api/survey/copy`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			surveyId: id,
			title,
			description,
			languageId,
			comments: {
				commentText: commentsObj?.commentText,
				commentProtocolVersionId: commentsObj?.protocolVersionId,
				commentRelatedTaskUrl: commentsObj?.relatedTaskUrl,
				commentInvestigatorId: commentsObj?.investigatorId
			}
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const saveSurveySettings = (id, surveyType, promDescription, promReferences) => {
	return authenticatedFetch(`/api/survey/${id}/settings`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			surveyType: surveyType,
			promDescription: promDescription,
			promReferences: promReferences
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const createChildSurvey = (id, language) => {
	return authenticatedFetch(`/api/survey/${id}/translate`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			id,
			languageId: language
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const cloneSurvey = (id, newName) => {
	return authenticatedFetch(`/api/survey/${id}/clone`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			id,
			title: newName
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchProtocolAutoComplete = (searchPhrase) => {
	var url = `/api/survey/autocomplete`

	if (searchPhrase && searchPhrase.length > 0) {
		url = `${url}?searchPhrase=${encodeURIComponent(searchPhrase)}`
	}

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchCopy = (id, languageId) => {
	var url = `/api/survey/${id}/languages/${languageId}/copy`

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchSurveys = (searchPhrase, filter, offset) => {
	var url = `/api/survey`
	let urlHasParams = false

	if (searchPhrase && searchPhrase.length > 0) {
		url = `${url}?searchPhrase=${encodeURIComponent(searchPhrase)}`
		urlHasParams = true
	}

	if(offset){
		url += `${urlHasParams ? '&' : '?'}offset=${offset}`
		urlHasParams = true
	}

	if(Number.isInteger(filter)){
		url += `${urlHasParams ? '&' : '?'}filter=${filter}`
		urlHasParams = true
	}
	
	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchSurveyDetails = (id, instanceId, versionId) => {
	let url = instanceId && versionId 
	? `/api/survey/${id}/version/${versionId}/instance/${instanceId}/details`
	: `/api/survey/${id}/details`;

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchRemainingLanguages = (id) => {
	return authenticatedFetch(`/api/survey/${id}/remaininglanguages`, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchSurvey = (id, languageId = 1) => {
	var url = `/api/survey/${id}/languages/${languageId}`

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchSurveyActiveQuestions = (protocolSurveyId, versionId, languageId = 1) => {
	var url = `/api/survey/protocolSurvey/${protocolSurveyId}/version/${versionId}/languages/${languageId}/activeQuestions`

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchSurveyByVersion = (surveyId, instanceId, protocolVersionId, languageId = 1) => {
	var url = `/api/survey/${surveyId}/protocolSurvey/${instanceId}/version/${protocolVersionId}/languages/${languageId}`

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchSurveyUsageData = (id, instanceId, versionId) => {
	var url = `/api/survey/${id}/version/${versionId}/instance/${instanceId}/usages`

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const changeQuestionState = (protocolId, protocolSurveyId, versionId, questionStateList, bundleStateList, commentsObj) => {
	var url = `/api/survey/instance/${protocolSurveyId}/protocol/${protocolId}/version/${versionId}/questions/updateState`

	var questions = questionStateList.map(q => {
		return {
			entityId: q.id,
			entityTypeId: 1,
			questionTypeId: q.type,
			state: q.state,
			isConditionalVisibility: q.isConditionalVisibility,
			visibilityRule: q.visibilityRule,
			optionalRule: q.optionalRule,
			isOptionalOverride: q.isOptionalOverride,
			isBilateral: q.isBilateral
		}
	})

	var bundles = bundleStateList.map(b => {
		return {
			entityId: b.id,
			entityTypeId: 2,
			questionTypeId: 0,
			state: b.state,
			isConditionalVisibility: b.isConditionalVisibility,
			visibilityRule: b.visibilityRule,
			optionalRule: b.optionalRule,
			isOptionalOverride: b.isOptionalOverride,
			isBilateral: b.isBilateral
		}
	})

	var data = 
	{
		questions: [...questions, ...bundles],
		comments: {
			commentText: commentsObj?.commentText,
			commentProtocolVersionId: commentsObj?.protocolVersionId,
			commentRelatedTaskUrl: commentsObj?.relatedTaskUrl,
			commentInvestigatorId: commentsObj?.investigatorId
		}
	}

	return authenticatedFetch(url, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(data)
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const changeAnswerState = (protocolId, protocolSurveyId, versionId, answerStateList, commentsObj) => {
	var url = `/api/survey/instance/${protocolSurveyId}/protocol/${protocolId}/version/${versionId}/answers/updateState`

	var data = 
	{
		answers: answerStateList.map(a => {
			return {
				answerId: a.id,
				questionId: a.questionId,
				questionTypeId: a.questionType,
				state: a.state
			}
		}),
		comments: {
			commentText: commentsObj?.commentText,
			commentProtocolVersionId: commentsObj?.protocolVersionId,
			commentRelatedTaskUrl: commentsObj?.relatedTaskUrl,
			commentInvestigatorId: commentsObj?.investigatorId
		}
	}

	return authenticatedFetch(url, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(data)
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const addQuestion = (question, position, id, isClone = false, commentsObj) => {
	//I want to move this later

	let url = `/api/survey/${id}/`
	let data = {}

	data.tag = question.tag
	data.title = question.title
	data.instructions = question.instructions
	data.canonicalId = question.canonicalId
	data.isOptional = question.isOptional
	data.baseId = !!isClone ? question.baseId : null
	data.comments = {}
	data.comments.commentText = commentsObj?.commentText
	data.comments.commentProtocolVersionId = commentsObj?.protocolVersionId
	data.comments.commentRelatedTaskUrl = commentsObj?.relatedTaskUrl
	data.comments.commentInvestigatorId = commentsObj?.investigatorId

	switch (question.type) {
		case 'single':
			url = url + 'single'
			data.isPiData = question.isPiData
			data.displayInstructionAsIcon = question.displayInstructionAsIcon
			data.answers = []
			data.isConsentRequired = question.isConsentRequired
			data.isCodeQuestion = question.isCodeQuestion
			data.questionCode = question.questionCode
			data.isDropdown = question.isDropdown
			for (let index = 0; index < question.answers.length; index++) {
				const answer = question.answers[index].answer
				data.answers.push({
					label: answer.answer,
					value: parseInt(answer.value),
					code: answer.code,
					sortOrder: question.answers[index].sortOrder,
					tag: question.answers[index].tag,
				})
			}

			break
		case 'multi':
			url = url + 'multi'
			data.isPiData = question.isPiData
			data.displayInstructionAsIcon = question.displayInstructionAsIcon
			data.answers = []
			data.isConsentRequired = question.isConsentRequired
			data.isCodeQuestion = question.isCodeQuestion
			data.questionCode = question.questionCode
			data.isDropdown = question.isDropdown
			for (let index = 0; index < question.answers.length; index++) {
				const answer = question.answers[index].answer
				data.answers.push({
					label: answer.answer,
					value: parseInt(answer.value),
					code: answer.code,
					sortOrder: question.answers[index].sortOrder,
					tag: question.answers[index].tag
				})
			}

			break
		case 'grouped':
			url = url + 'grouped'
			data.categories = []
			data.minimumValue = question.minimumValue
			data.range = question.range
			data.isPiData = question.isPiData
			data.displayInstructionAsIcon = question.displayInstructionAsIcon
			data.prefix = question.prefix
			data.isDropdown = question.isDropdown
			for (let index = 0; index < question.answers.length; index++) {
				const answer = question.answers[index].answer
				data.categories.push({
					label: answer.question,
					tag: answer.tag,
					sortOrder: question.answers[index].sortOrder
				})
			}

			break
		case 'analog':
			var model = new FormData()
			url = url + 'analog'

			if (!!isClone) {
				model.append('baseId', question.baseId)

				url = url + '/clone'
			}

			model.append('startImageFile', question.startImage)
			if (
				!question.startImage
				&& question.startImagePath?.length > 0
			) {
				model.append('startImagePath', question.startImagePath)
			}
			model.append('endImageFile', question.endImage)
			if (
				!question.endImage
				&& question.endImagePath?.length > 0
			) {
				model.append('endImagePath', question.endImagePath)
			}
			model.append('startValue', question.startValue)
			model.append('endValue', question.endValue)
			model.append('stepValue', question.stepValue)
			model.append('startText', !!question.startText ? question.startText : "")
			model.append('endText', !!question.endText ? question.endText : "")
			model.append('displayInstructionAsIcon', question.displayInstructionAsIcon)
			if (question.tag) {
				model.append('tag', question.tag)
			}
			model.append('title', question.title)
			if (question.instructions) {
				model.append('instructions', question.instructions)
			}
			if (question.canonicalId) {
				model.append('canonicalId', question.canonicalId)
			}
			model.append('isOptional', question.isOptional)

			model.append('commentText', commentsObj?.commentText)
			model.append('commentProtocolVersionId', commentsObj?.protocolVersionId)
			model.append('commentRelatedTaskUrl', commentsObj?.relatedTaskUrl)
			model.append('commentInvestigatorId', commentsObj?.investigatorId)

			if (position) {
				url = url + `?sortOrder=${position}`
			}

			return authenticatedFetch(url, {
				method: 'POST',
				body: model
			})
				.then(function(response) {
					if (response.status === 200) {
						return response.json()
					}
				})
				.then(function(patient) {
					return patient
				})
		case 'tf':
			url = url + 'tf'
			break
		case 'text':
			url = url + 'text'
			data.isRegularExpressionEnabled = question.isRegularExpressionEnabled
			data.regularExpression = question.regularExpression
			data.regularExpressionValidation = question.regularExpressionValidation
			data.isPiData = question.isPiData
			data.displayInstructionAsIcon = question.displayInstructionAsIcon
			data.isConsentRequired = question.isConsentRequired
			break
        case 'file':
			url = url + 'file'
            data.fileType = question.fileType;
            data.hideImageForPatient = question.hideImageForPatient
			data.displayInstructionAsIcon = question.displayInstructionAsIcon
            break
		case 'date':
			url = url + 'date'
			data.isRegularExpressionEnabled = question.isRegularExpressionEnabled
			data.regularExpression = question.regularExpression
			data.regularExpressionValidation = question.regularExpressionValidation
			data.isPiData = question.isPiData
			data.displayInstructionAsIcon = question.displayInstructionAsIcon
			data.isConsentRequired = question.isConsentRequired
			break
		case 'number':
			url = url + 'number'
			data.unitId = question.numberType
			data.digits = question.precision
			data.minimum = question.minimum
			data.maximum = question.maximum
			data.isRegularExpressionEnabled = question.isRegularExpressionEnabled
			data.regularExpression = question.regularExpression
			data.regularExpressionValidation = question.regularExpressionValidation
			data.isPiData = question.isPiData
			data.displayInstructionAsIcon = question.displayInstructionAsIcon
			data.isConsentRequired = question.isConsentRequired
			break
		case 'content':
			url = url + 'copy'
			data = {}
			data.canonicalId = question.canonicalId
			data.subtitle = question.subtitle
			data.bodyCopy = question.bodyCopy
			data.baseId = question.baseId
			data.comments = {}
			data.comments.commentText = commentsObj?.commentText
			data.comments.commentProtocolVersionId = commentsObj?.protocolVersionId
			data.comments.commentRelatedTaskUrl = commentsObj?.relatedTaskUrl
			data.comments.commentInvestigatorId = commentsObj?.investigatorId
			break
	}

	if (!!isClone) {
		url = url + '/clone'
	}

	if (position) {
		url = url + `?sortOrder=${position}`
	}

	return authenticatedFetch(url, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(data)
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const addExistingQuestion = (id, type, position, surveyId, commentsObj) => {
	return authenticatedFetch(`/api/survey/${surveyId}/question/${id}`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			questionId: id,
			typeId: parseInt(type),
			sortOrder: position || 1,
			surveyId: parseInt(id),
			comments: {
				commentText: commentsObj?.commentText,
				commentProtocolVersionId: commentsObj?.protocolVersionId,
				commentRelatedTaskUrl: commentsObj?.relatedTaskUrl,
				commentInvestigatorId: commentsObj?.investigatorId
			}
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const addBundle = (id, position, groupId, commentsObj) => {
	return authenticatedFetch(`/api/survey/${id}/scoringgroup`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			groupId: groupId,
			sortOrder: position || 1,
			surveyId: parseInt(id),
			comments: {
				commentText: commentsObj?.commentText,
				commentProtocolVersionId: commentsObj?.protocolVersionId,
				commentRelatedTaskUrl: commentsObj?.relatedTaskUrl,
				commentInvestigatorId: commentsObj?.investigatorId
			}
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

// export const updateQuestion = (question, id) => {
// 	return authenticatedFetch(`/api/survey/${id}/question/${question.id}`, {
// 		method: 'POST',
// 		headers: { 'content-type': 'application/json' },
// 		body: JSON.stringify({
// 			data: JSON.stringify(question),
// 			title: question.title,
// 			id: question.id
// 		})
// 	})
// 		.then(function(response) {
// 			if (response.status === 200) {
// 				return response.json()
// 			}
// 		})
// 		.then(function(patient) {
// 			return patient
// 		})
// }

export const removeQuestion = (surveyId, questionId, questionType, isBundle) => {
	return authenticatedFetch(`/api/survey/${surveyId}/question/${questionId}/questionType/${questionType}/isBundle/${isBundle}`, {
		method: 'DELETE',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchTranslatedSurvey = (id, languageId) => {
	return authenticatedFetch(`/api/survey/${id}/languages/${languageId}`, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const saveSurvey = (surveyId, settings, protocolId) => {
	var url = `/api/survey/${surveyId}`

	if (protocolId) {
		url = `${url}?protocolId=${protocolId}`
	}

	return authenticatedFetch(`/api/survey/${surveyId}`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ settings: settings })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const swapQuestionPosition = (surveyId, leftQuestionId, leftQuestionType, leftIsBundle, rightQuestionId, rightQuestionType, rightIsBundle) => {
	return authenticatedFetch(`/api/survey/${surveyId}/question/swap`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			leftEntityId: leftQuestionId,
			leftIsBundle: leftIsBundle,
			leftQuestionType: leftQuestionType,
			rightEntityId: rightQuestionId,
			rightIsBundle: rightIsBundle,
			rightQuestionType: rightQuestionType
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const saveSurveyTranslation = (surveyId, baseSurveyId, questions, settings) => {
	return authenticatedFetch(`/api/survey/${surveyId}/language/base/${baseSurveyId}`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ questions: questions, settings: settings })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchSurveyLanguages = (id) => {
	return authenticatedFetch(`/api/survey/${id}/languages`, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const activateSurvey = (surveyId) => {
	return authenticatedFetch(`/api/survey/${surveyId}/activate`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const deActivateSurvey = (surveyId) => {
	return authenticatedFetch(`/api/survey/${surveyId}/deactivate`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchLanguages = (id) => {
	var url = `/api/survey/${id}/languages`

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const moveQuestionToPosition = (id, position) => {
	var url = `/api/survey/surveyQuestionBundle/${id}/moveToPosition/${position | 0}`

	return authenticatedFetch(url, {
		method: 'put'
	})
	.then(function (response) {
		if (response.status === 200) {
			return response.json()
		}
	})
	.then(function (result) {
		return result
	})
}

export const fetchIsSurveyQuestionInUse = (surveyId, questionId, isBundle) => {
	var url = `/api/survey/${surveyId}/question/${questionId}/isBundle/${isBundle}/isInUse`

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchSurveysByQuestion = (questionId, questionType) => {
	var url = `/api/survey/question/${questionId}/questionType/${questionType}`

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchSurveysByBundle = (bundleId) => {
	var url = `/api/survey/bundle/${bundleId}`

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const updateQuestionStateRelatedEntities = (surveyId, questionId, questionTypeId, newState, commentsObj) => {
	return authenticatedFetch(`/api/survey/${surveyId}/changeQuestionStateRelatedEntities`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			questionId: questionId,
			questionType: questionTypeId,
			newState: newState,
			comments: {
				commentText: commentsObj?.commentText,
				commentProtocolVersionId: commentsObj?.protocolVersionId,
				commentRelatedTaskUrl: commentsObj?.relatedTaskUrl,
				commentInvestigatorId: commentsObj?.investigatorId
			}
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const updateBundleStateRelatedEntities = (surveyId, bundleId, newState, commentsObj) => {
	return authenticatedFetch(`/api/survey/${surveyId}/changeBundleStateRelatedEntities`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			questionId: bundleId,
			questionType: 0,
			newState: newState,
			comments: {
				commentText: commentsObj?.commentText,
				commentProtocolVersionId: commentsObj?.protocolVersionId,
				commentRelatedTaskUrl: commentsObj?.relatedTaskUrl,
				commentInvestigatorId: commentsObj?.investigatorId
			}
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const copySurveyInstanceQuestionRules = (instanceId, copyToInstanceId, versionId, languageId) => {
	return authenticatedFetch(`/api/survey/instance/${instanceId}/protocol/${versionId}/language/${languageId}/copyRulesToInstance/${copyToInstanceId}`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const updateSurveyQuestion = (bundleId, question, languageId, commentsObj) => {
	return updateQuestion(question, languageId, `/api/survey/${bundleId}/`, commentsObj)
}

export const fetchSurveyChangeLogs = (surveyId, searchPhrase, keys) => {
	var url = `/api/survey/${surveyId}/changeLogs`

	url = `${url}?searchPhrase=${!!searchPhrase?.length && encodeURIComponent(searchPhrase) || ''}`

	if (!!keys?.length) {
		keys.forEach(x => url = `${url}&keys=${x}`)
	}

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(data) {
			return data
		})
}