import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { newCompletedReportsSelector } from '../redux/selectors'
import { Paper } from '@material-ui/core'
import CalmButton from './CalmButton'
import { Link } from 'react-router-dom'
import { clearNewCompletedReports } from '../redux/actions'

const NewCompletedReportsNotificationHeader = ({ 
	intl, 
	newReports, 
	clearNotification 
}) => {
	return (
		newReports ?
			(<Paper position='fixed' style={{ background: '#09203f' }} square={true}>
				<Slide direction='down' in={true} mountOnEnter unmountOnExit>
					<Toolbar style={{ margin: '0 auto' }}>
						<Grid container direction='row' style={{ padding: '10px 0px', justifyContent: 'center', alignItems: 'center' }}>
							<Grid item style={{ textAlign: 'center', flex: '0.7' }}>
								<Typography
									style={{
										color: 'white',
										fontSize: '16px',
										fontWeight: '600'
									}}>
									{intl.formatMessage({
										id: 'app.reports.newReportCompletedNotificationText'
									})}
								</Typography>
							</Grid>
							<Grid item style={{ textAlign: 'center', flex: '0.3' }}>
								<Link to={'/reports'} style={{textDecoration: 'none'}}>
									<CalmButton
										data-testid="export-raw-circle-report-message-close"
										variant="contained"
										style={{
											margin: "15px 10px",
											minWidth: "140px",
										}}
										color="primary"
									>
										{intl.formatMessage({ id: "app.reports.newReportCompletedNotificationViewButtonLabel" })}
									</CalmButton>
								</Link>
								<CalmButton
									data-testid="export-raw-circle-report-message-close"
									variant="outlined"
									style={{
										margin: "15px 0",
										minWidth: "140px",
									}}
									color="primary"
									onClick={clearNotification}
								>
									{intl.formatMessage({ id: "app.general.closeModalLabel" })}
								</CalmButton>
							</Grid>
						</Grid>
					</Toolbar>
				</Slide>
			</Paper>)
		: null
	)
}

const mapStateToProps = (state) => ({
	newReports: newCompletedReportsSelector(state)
})

const mapDispatchToProps = (dispatch) => ({
	clearNotification: () => dispatch(clearNewCompletedReports())
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(NewCompletedReportsNotificationHeader)
