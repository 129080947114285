import React, { Fragment } from 'react'
import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import LabelIcon from '@material-ui/icons/Label'

export default ({ tag }) => {
	return (
		<Fragment>
			{tag && (
				<Grid container style={{ paddingBottom: '10px' }}>
					<Grid item style={{ marginTop: '3px' }}>
						<LabelIcon />
					</Grid>
					<Grid item>
						<Typography variant="body2" style={{ marginLeft: '5px', fontSize: '17px' }}>
							{tag}
						</Typography>
					</Grid>
				</Grid>
			)}
		</Fragment>
	)
}
