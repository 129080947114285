import { authenticatedFetch } from './base'

export const inviteUserMember = (id, email, languageId) => {
	return authenticatedFetch(`/api/invitations/${id}/invitemember/${languageId}/${email}`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({})
	}).then(function(response) {
		if (response.status === 200) {
			return response.json()
		} else {
			return false
		}
	})
}

export const resendInvitation = (invitationId) => {
	return authenticatedFetch(`/api/invitations/invitation/${invitationId}/resend`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({})
	}).then(function(response) {
		if (response.status === 200) {
			return response.json()
		} else {
			return false
		}
	})
}

export const updateInviteUserMember = (id, email, optionSelected) => {
	return authenticatedFetch(`/api/invitations/${id}/${email}/optionmember/${optionSelected}`, {
		method: 'put',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const deleteUserMember = (id, invitationId, enableSchedule, removalDate) => {
	let url = !id && invitationId
		? `/api/invitations/${invitationId}/deleteMemberInvitation`
        : `/api/invitations/${id}/deleteMember`;

	return authenticatedFetch(url, {
		method: 'put',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({enableSchedule, removalDate})
	}).then(function(response) {
		if (response.status === 200) {
			return response.json()
		} else {
			return false
		}
	})
}
