import React, { useEffect } from 'react'
import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import LabelIcon from '@material-ui/icons/Label'

export default ({ question, setHeightCallback }) => {
	let ref = React.createRef()

	useEffect(
		() => {
			if (setHeightCallback && ref.current) {
				if (ref.current.offsetHeight < 64) {
					setHeightCallback(64)
				} else {
					setHeightCallback(ref.current.offsetHeight)
				}
			}
		},
		[ ref ]
	)

	return (
		<Grid container justify="flex-start" ref={ref} id={question.id} >
			<Grid item style={{ minWidth: '200px' }}>
				<Grid container direction="column" style={{ minWidth: '200px' }}>
					{question &&
					question.tag && (
						<Grid item style={{ maxWidth: '600px' }}>
							<Grid container>
								<Grid item style={{ marginTop: '3px' }}>
									<LabelIcon style={{ opacity: 0.9 }} />
								</Grid>
								<Grid item>
									<Typography variant="caption" style={{ marginLeft: '5px' }}>
										{question.tag}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					)}
					<Grid item style={{ maxWidth: '600px' }}>
						<Link
							to={`/questions/${question.id}/type/${question.questionTypeId}/details`}
							style={{ textDecoration: 'none', fontWeight: '700', fontSize: '18px', color: 'black' }}
						>
						<Typography variant="subtitle1" style={{ textAlign: 'left', wordBreak: 'break-word' }}>
							{question && question.title && question.title.length > 200 ? (
								question.title.substring(0, 200) + '...'
							) : (
								question.title
							)}
						</Typography>
						</Link>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}
