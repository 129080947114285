import React from 'react'

export class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		this.state = { hasError: false }
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true }
	}

    async componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log(error, errorInfo)

        await fetch(`/api/utility/log`, {
            method: 'post',
            headers: { 
                    	'Content-Type': 'application/json', 
                    	'utility-log-location': window.location.href 
			},
            body: JSON.stringify({
				message: JSON.stringify(error && error.message || error),
				priority: 2
			})
        })
    }

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return <h1>Something went wrong.</h1>
		}

		return this.props.children
	}
}
