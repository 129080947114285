import React, { Fragment, useEffect, useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { useIntl } from 'react-intl'
import { Typography, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { isSavingSelector } from '../../../redux/selectors'

const BENCHMARC_MODE = {
	MANUAL: 0,
	AUTOMATIC: 1
}

const BENCHMARC_TYPE = {
	SINGULAR: 0,
	RANGE: 1
}

const ProtocolVersionScoringGroupBenchmarcSettings = ({ handleSubmit, onSaveCallback, versionId, scoringGroupId, settings }) => {
	const isSaving = useSelector(isSavingSelector, shallowEqual)
	const intl = useIntl()

	const [ benchmarcMode, setBenchmarcMode] = useState(0)
	const [ benchmarcType, setBenchmarcType] = useState(0)

	useEffect(
		() => {
			if(settings) {
				setBenchmarcMode(settings.benchmarcMode)
				setBenchmarcType(settings.benchmarcType)
			}
		},
		[ settings ]
	)

	const onSubmit = () => {
		onSaveCallback && onSaveCallback(versionId, scoringGroupId, benchmarcMode, benchmarcType)
	}

	return (
		<Fragment>
			<Grid container justify="flex-end">
				<Grid item>
					<Button
						variant="contained"
						color="primary"
						disabled={isSaving}
						onClick={onSubmit}
					>
						{intl.formatMessage({
							id: 'app.general.saveChangesButtonText'
						})}
					</Button>
				</Grid>
			</Grid>
			<div style={{ backgroundColor: 'rgb(225, 238, 255)', marginTop: '26px', padding: '3%' }}>
				<Grid container direction="column">
					<Typography
						style={{ fontSize: '13px', lineHeight: '16px', letterSpacing: '2.08px', color: '#011020' }}
					>
						{intl.formatMessage({ id: 'app.protocol.scoringGroups.benchmarcModeHeader' })}
					</Typography>

					<Grid item style={{ marginTop: '17px' }}>
						<RadioGroup>
							<Grid
								container
								spacing={1}
								direction='column'
								style={{ width: '100%' }}
							>
								<Grid item xs="auto">
									<FormControlLabel
										label={intl.formatMessage({ id: 'app.protocol.scoringGroups.benchmarcModeManualLabel' })}
										control={
											<Radio
												color="primary"
												onChange={() => setBenchmarcMode(0)}
												checked={benchmarcMode == BENCHMARC_MODE.MANUAL}
											/>
										}
									/>
								</Grid>
								<Grid item xs="auto">
									<FormControlLabel
										label={intl.formatMessage({ id: 'app.protocol.scoringGroups.benchmarcModeAutomaticLabel' })}
										control={
											<Radio
												color="primary"
												onChange={() => setBenchmarcMode(1)}
												checked={benchmarcMode == BENCHMARC_MODE.AUTOMATIC}
												disabled={true} //disabled until the feature is ready
											/>
										}
									/>
								</Grid>
							</Grid>
						</RadioGroup>
					</Grid>
					<Typography
						style={{ fontSize: '13px', lineHeight: '16px', letterSpacing: '2.08px', color: '#011020' }}
					>
						{intl.formatMessage({ id: 'app.protocol.scoringGroups.benchmarcTypeHeader' })}
					</Typography>

					<Grid item style={{ marginTop: '17px' }}>
						<RadioGroup>
							<Grid
								container
								spacing={1}
								direction='column'
								style={{ width: '100%' }}
							>
								<Grid item xs="auto">
									<FormControlLabel
										label={intl.formatMessage({ id: 'app.protocol.scoringGroups.benchmarcTypeSingularLabel' })}
										control={
											<Radio
												color="primary"
												onChange={() => setBenchmarcType(0)}
												checked={benchmarcType == BENCHMARC_TYPE.SINGULAR}
											/>
										}
									/>
								</Grid>
								<Grid item xs="auto">
									<FormControlLabel
										label={intl.formatMessage({ id: 'app.protocol.scoringGroups.benchmarcTypeRangeLabel' })}
										control={
											<Radio
												color="primary"
												onChange={() => setBenchmarcType(1)}
												checked={benchmarcType == BENCHMARC_TYPE.RANGE}
											/>
										}
									/>
								</Grid>
							</Grid>
						</RadioGroup>
					</Grid>
				</Grid>
			</div>
		</Fragment>
	)
}

export default ProtocolVersionScoringGroupBenchmarcSettings