import { authenticatedFetch } from './base'

const API_PATH = "/api/smstemplates";

export const fetchTemplate = (versionId, surveyInstanceId, languageId, type) => {
	var url = `${API_PATH}/version/${versionId}/survey/${surveyInstanceId}/languages/${languageId}/types/${type}`

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(template) {
			return template
		})
}

export const fetchTypes = () => {
	var url = `${API_PATH}/types`

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(template) {
			return template
		})
}

export const updateTemplate = (versionId, surveyInstanceId, languageId, type, htmlPart, commentsObj) => {
	var url = `${API_PATH}/version/${versionId}/survey/${surveyInstanceId}/languages/${languageId}/types/${type}`

	return authenticatedFetch(url, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			htmlPart: htmlPart,
			comments: {
				commentText: commentsObj?.commentText,
				commentProtocolVersionId: commentsObj?.protocolVersionId,
				commentRelatedTaskUrl: commentsObj?.relatedTaskUrl,
				commentInvestigatorId: commentsObj?.investigatorId
			}
		})
	})
		.then(function (response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (template) {
			return template
		})
}

export const sendTemplateSms = (versionId, surveyInstanceId, languageId, type, phoneNumber) => {
	return authenticatedFetch(
		`${API_PATH}/version/${versionId}/survey/${surveyInstanceId}/languages/${languageId}/types/${type}/sendSms`, 
		{
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			phoneNumber: phoneNumber
		})
	})
		.then(function (response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (result) {
			return result
		})
}