import React, { Fragment } from 'react'
import { useTheme, Typography, Radio } from '@material-ui/core'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Tag from './Tag'
import QuestionContainer from './QuestionContainer'
import RequiresTranslationBadge from '../../RequiresTranslationBadge'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Menu from '../../../pages/surveyViewer/components/AnswerControlMenu'
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon  from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import Checkbox from '@material-ui/core/Checkbox'
import IdContainer from './IdContainer'
import { categoryCode } from '../../../utilities/utilities'
import { useRouteMatch } from 'react-router'

const useStyles = makeStyles((theme) => ({
	radioLabel: {
		padding: '0',
		'& .MuiRadio-root': {
			padding: '5px',
		},
		'$&svg': {
			width: '2em',
			height: '2em'
		},
		[theme.breakpoints.down('xs')]: {
			'& .MuiRadio-root': {
				padding: '2px',
			},
		},
	},
	radioWrap: {
		width: '24px',
		[theme.breakpoints.down('xs')]: {
			width: '19px',
		},
	},
	numberWrap: {
		flexWrap: 'nowrap',
		padding: '7px 0',
		'& h6': {
			width: '24px',
			textAlign: 'center',
			paddingLeft: '5px',
			[theme.breakpoints.down('xs')]: {
				width: '19px',
			},
		}
	},
}))

const renderBubble = (from, to, classes) => {
	let bubbles = []
	for (let index = from; index <= to; index++) {
		let r = (
			<Grid item style={{ padingLeft: '5px' }}>
				<Grid container>
					<Grid item style={{ width: '24px' }}>
						<Radio color="primary" disabled={true} classes={{ root: classes.label }} />
					</Grid>
				</Grid>
			</Grid>
		)
		bubbles.push(r)
	}
	return bubbles
}

const renderKey = (from, to) => {
	let bubbles = []
	for (let index = from; index <= to; index++) {
		let r = (
			<div item style={{ padingLeft: '15px' }}>
				<Grid item>
					<Typography variant='subtitle1'>{index}</Typography>
				</Grid>
			</div>
		)
		bubbles.push(r)
	}
	return bubbles
}

const GroupedQuestion = ({ intl, question, surveyEntity, position, positionDisplay, showScoreEntry, handleAnswerStateChange, getCurrentAnswerState, getBackgroundColor, defaultBackgroundColor, archiveAnswer, showState }) => {
	const match = useRouteMatch()
	const classes = useStyles()

	return (
		<Grid data-testid="grouped-question-survey-component" container>
			<Grid item xs={12}>
				<Tag tag={question.tag} />
			</Grid>
			<Grid item xs={12}>
				<QuestionContainer
					question={question}
					surveyEntity={surveyEntity}
					children={
						<Fragment>
							<table role="presentation" style={{ width: '100%', borderCollapse: 'collapse' }} data-testid="grouped-question-container">
								<tr>
										{showState && <td style={{ width: '40px'}}></td>}
										<td style={{ width: '50%'}}></td>										
										<td className={ classes.numberWrap }>
											<Grid container justify='flex-start'>
												{renderKey(question.minimumValue, question.range)}
											</Grid>
										</td>
										<td></td>
										<td></td>

								</tr>
								{question &&
									question.categories &&
									question.categories
										.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
										.map((r) => {
											return (
												<tr key={r.id}
													style={{
														backgroundColor: showState 
															? getBackgroundColor(getCurrentAnswerState(r.baseId, question.baseId, question.questionType) || r.state) 
															: defaultBackgroundColor 
													}}
												>
													{showState && 
														<td style={{ minWidth: '33px', height: '39px' }}>
															{(r.state < 3 && !getCurrentAnswerState(r.baseId, question.baseId, question.questionType) || getCurrentAnswerState(r.baseId, question.baseId, question.questionType) < 3) &&
																<Checkbox
																	icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
																	checkedIcon={<CheckBoxIcon fontSize="large" />}
																	indeterminateIcon={<IndeterminateCheckBoxIcon fontSize="large" />}
																	indeterminate={r.state === 0 && !getCurrentAnswerState(r.baseId, question.baseId, question.questionType)}
																	checked={r.state === 1 && !getCurrentAnswerState(r.baseId, question.baseId, question.questionType) || getCurrentAnswerState(r.baseId, question.baseId, question.questionType) === 1}
																	color="primary"
																	onChange={event => handleAnswerStateChange(r.baseId, question.baseId, question.questionType, event.target.checked)}
																	style={{ padding: '9px 0 0' }} />}
															{(r.state === 3 && !getCurrentAnswerState(r.baseId, question.baseId, question.questionType) || getCurrentAnswerState(r.baseId, question.baseId, question.questionType) === 3) &&

																<HighlightOffIcon
																	fontSize='large'
																	style={{ paddingTop: '9px' }} />}
														</td>}
													<td style={{ padding: '3px 0'}}>
														<Typography variant='subtitle2' style={{ fontSize: '17px' }}>
															{r.label}
															{r.tag && ` (${r.tag}) `}
															{showState && !question.isBundle && ` (${r.casesCount})`}
														</Typography>
													</td>
													<td style={{ verticalAlign: 'top', padding: '3px 0'}}>
														<Grid container style={{ flexWrap: 'nowrap'}}>
															{renderBubble(question.minimumValue, question.range, classes)}
														</Grid>
													</td>
													<td
														style={{
															verticalAlign: 'top', 
															paddingLeft: '10px', 
															paddingTop: '4px'
														}}
													>
														{
														!!match.params.instanceId
														&& 
															<IdContainer
																label={intl.formatMessage({ id: 'app.survey.categoryIdLabel' }).replace('%%Id%%', `${question.baseId}_${r.baseId}`)}
																clipboardText={ `${categoryCode(question.baseId, r.baseId)}==` }
															/>														
														}
													</td>
													<td item style={{ paddingTop: '5px' }}>
													{
														showState
														&& <Menu
															question={question}
															answer={r}
															archiveAnswer={archiveAnswer}
															getCurrentAnswerState={getCurrentAnswerState}
														/>
													}
													</td>
												</tr>
											)
										})}
							</table>
						</Fragment>
					}
				/>
			</Grid>
			{question &&
			!question.id && (
				<Grid item xs={12} style={{ marginTop: '10px' }}>
					<RequiresTranslationBadge />
				</Grid>
			)}
		</Grid>

	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(GroupedQuestion)
