import React, { Fragment, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useTheme from '@material-ui/core/styles/useTheme'
import { bundleQuestionScoringGroupsSelector, isBundleQuestionUsedByCasesSelector } from '../../../redux/selectors'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import Button from '@material-ui/core/Button'
import { changeBundleQuestionState, fetchQuestionUseStatus, hideModal, removeBundleQuestion } from '../../../redux/actions'
import { QUESTION_STATE } from '../../../utilities/constants'
import { CircularProgress } from '@material-ui/core'
import EntityAuditCommentFields from '../../../components/entityAudit/EntityAuditCommentFields'
import { reduxForm } from 'redux-form'
import { investigatorsForProtocolVersionEntityAuditSelector } from '../../../redux/selectors'
import { isAuditCommentsRequired } from '../../../utilities/auditUtils'


const RemoveQuestionModal = ({
    bundleId,
    question,
    newState,
    languageId,
    allEntities,
    intl,
    isBundleQuestionUsedByCases,
    bundleQuestionScoringGroups,
    changeBundleQuestionState,
    loadBundleQuestionUseStatus,
    closeModal,
	entityId,
	entityTypeId,
	handleSubmit,
	form,
    investigators
}) => {
	const theme = useTheme()

	useEffect(
        () => {
            if (newState == QUESTION_STATE.ARCHIVED) {
                loadBundleQuestionUseStatus(bundleId, question)
            }
        },
        []
    )

    const onChangeQuestionState = (values) => {
        localStorage.setItem('entityAuditCommentsPrefill', JSON.stringify({
			commentText: values.commentText,
			protocolVersionId: values.protocolVersionId,
			relatedTaskUrl: values.relatedTaskUrl,
			investigatorId: investigators && investigators.some((x) => x.id === values.investigatorId) ? values.investigatorId : null
		}))
        changeBundleQuestionState(bundleId, question, newState, languageId, allEntities, values)
    }

	return (
        <form onSubmit={handleSubmit(onChangeQuestionState)}>
		<Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            style={{ width: '75rem', padding: '4rem', margin: 'auto', backgroundColor: theme.palette.secondary.main }}
        >
            <Grid item style={{ alignSelf: 'center' }}>
                <Typography
                    variant="h1"
                    style={{
                        color: '#fff',
                        marginBottom: '25px',
                    }}
                >
                    {
                        newState == QUESTION_STATE.IN_USE
                            ? intl.formatMessage({ id: 'app.bundle.setInUseQuestionButtonText' })
                            : intl.formatMessage({ id: 'app.bundle.archiveQuestionButtonText' })
                    }
                </Typography>
            </Grid>
            {
                !bundleQuestionScoringGroups
                && newState != QUESTION_STATE.IN_USE
                && <Grid item>
                    <Grid
                        container
                        spacing={3}
                        alignItems="center"
                    >
                        <Grid item>
                            <Typography
                                variant="h3"
                                style={{color: '#fff'}}
                            >
                                { intl.formatMessage({ id: 'app.bundle.checkDependenciesLabel' }) }
                            </Typography>
                        </Grid>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                </Grid>
            }
            {
                (
                    newState == QUESTION_STATE.IN_USE
                    || (
                        isBundleQuestionUsedByCases == false
                        && bundleQuestionScoringGroups?.length == 0
                    )
                )
                && <Fragment>
                    <Grid item style={{ alignSelf: 'flex-start' }}>
                        <Typography
                            variant="body2"
                            style={{
                                color: '#fff',
                                marginBottom: '25px'
                            }}
                            align="left"
                        >
                            {
                                newState == QUESTION_STATE.IN_USE
                                    ? (
                                        allEntities
                                            ? intl.formatMessage({ id: 'app.bundle.setInUseQuestionRelatedEntitiesWarning' })
                                            : intl.formatMessage({ id: 'app.bundle.setInUseQuestionWarning' })
                                    )
                                    : (
                                        allEntities
                                            ? intl.formatMessage({ id: 'app.bundle.archiveQuestionRelatedEntitiesWarning' })
                                            : intl.formatMessage({ id: 'app.bundle.archiveQuestionWarning' })
                                    )
                            }
                        </Typography>
                    </Grid>
                    { 
                        isAuditCommentsRequired() && 
                        <EntityAuditCommentFields entityId={entityId} entityTypeId={entityTypeId}  formName={form}/>
                    }
                    <Grid
                        item
                    >
                        <Grid
                            container
                            direction='row'
                            justify='center'
                        >
                            <Grid
                                item
                            >
                                <Button
                                    style={{
                                        width: '150px',
                                        marginTop: '40px'
                                    }}
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    onClick={closeModal}
                                >
                                    {intl.formatMessage({ id: 'app.bundle.changeQuestionStateButtonNo' })}
                                </Button>
                            </Grid>
                            <Grid
                                item
                            >
                                <Button
                                    style={{
                                        width: '150px',
                                        marginTop: '40px'
                                    }}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    {intl.formatMessage({ id: 'app.bundle.changeQuestionStateButtonYes' })}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Fragment>
            }
            {
                !!isBundleQuestionUsedByCases
                && <Grid
                    item
                    style={{
                        alignSelf: 'flex-start',
                        paddingTop: '20px'
                    }}
                >
                    <Typography
                        variant="h3"
                        style={{
                            color: '#fff',
                        }}
                        align="left"
                    >
                        {intl.formatMessage({ id: 'app.bundle.questionUsedInTheCases' })}
                    </Typography>
                </Grid>
            }
            {
                bundleQuestionScoringGroups?.length > 0
                && <Fragment>
                    <Grid
                        item
                        style={{
                            alignSelf: 'flex-start',
                            paddingTop: '20px'
                        }}
                    >
                        <Typography
                            variant="h3"
                            style={{
                                color: '#fff',
                            }}
                            align="left"
                        >
                            {intl.formatMessage({ id: 'app.bundle.questionUsedInTheSG' }) + ':'}
                        </Typography>
                    </Grid>
                    <Grid item style={{ alignSelf: 'flex-start' }}>
                        <Typography
                            variant="h4"
                            style={{
                                color: '#fff',
                                paddingTop: '10px',
                                paddingLeft: '20px'
                            }}
                            align="left"
                        >
                            {
                                bundleQuestionScoringGroups.map(scoringGroup => {
                                    return (
                                        <div>
                                            {scoringGroup.name}
                                        </div>
                                    )
                                })
                            }
                        </Typography>
                    </Grid>
                </Fragment>
            }
            {
                (
                    !!isBundleQuestionUsedByCases
                    || bundleQuestionScoringGroups?.length > 0
                )
                && <Grid
                    item
                    style={{ alignSelf: 'center' }}
                >
                    <Button
                        style={{
                            width: '150px',
                            marginTop: '40px'
                        }}
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={closeModal}
                    >
                        {intl.formatMessage({ id: 'app.bundle.changeQuestionStateButtonOk' })}
                    </Button>
                </Grid>
            }
        </Grid>
        </form>
	)
}

const mapStateToProps = (state, ownProps) => ({
    isBundleQuestionUsedByCases: isBundleQuestionUsedByCasesSelector(state),
    bundleQuestionScoringGroups: bundleQuestionScoringGroupsSelector(state),
    investigators: investigatorsForProtocolVersionEntityAuditSelector(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	changeBundleQuestionState: (bundleId, question, newState, languageId, allEntities, values) => {
        dispatch(
            changeBundleQuestionState(
                bundleId,
                question.data.id,
                question.data.questionType,
                newState,
                languageId,
                allEntities,
                values
            )
        )
        dispatch(hideModal())
    },
    loadBundleQuestionUseStatus: (bundleId, question) => dispatch(fetchQuestionUseStatus(bundleId, question)),
    closeModal: () => dispatch(hideModal()),
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm ({
		form: 'changeBundleQuestionState',
		touchOnChange: true,
		touchOnBlur: true
	})
)

export default enhance(RemoveQuestionModal)
