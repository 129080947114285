import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import DefaultFormTextField from '../../DefaultFormTextField'
import DefaultFormNumberField from '../../DefaultFormNumberField'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import { useIntl } from 'react-intl'
import { required } from '../../../utilities/validators'
import { uuidv4 } from '../../../utilities/utilities'
const requiredValidator = required

export default forwardRef((props, ref) => {
	const { onDeleteCallback, answer } = props
	const [ internalAnswer, setAnswer ] = useState(null)
	const [ tag, setTag ] = useState(null)
	const [ id, setId ] = useState(uuidv4().replace('-', ''))
	const intl = useIntl()
	useImperativeHandle(ref, () => ({
		getAnswer() {
			return { question: internalAnswer, tag: tag }
		}
	}))

	useEffect(
		() => {
			if (answer) {
				answer.data.ref = ref
			}
		},
		[ answer, ref ]
	)

	return (
		<Grid container direction="column">
			<Grid item style={{ width: '200px' }}>
				<Grid item style={{ flex: 0.8, paddingRight: '5px' }}>
					<DefaultFormTextField
						label="Tag"
						validators={[ requiredValidator ]}
						name={id + 'tag'}
						variant="filled"
						onChange={(e) => setTag(e.target.value)}
						maxLength={254}
					/>
				</Grid>
			</Grid>
			<Grid item>
				<Grid container style={{ width: '100%' }} alignItems="center">
					<Grid item style={{ marginLeft: '-40px' }}>
						<IconButton onClick={() => onDeleteCallback(answer)}>
							<DeleteIcon />
						</IconButton>
					</Grid>
					<Grid item style={{ flex: 0.8, paddingRight: '5px' }}>
						<DefaultFormTextField
							label={intl.formatMessage({ id: 'app.survey.groupedAnswer.categoryPlaceholderText' })}
							validators={[ requiredValidator ]}
							name={id}
							variant="filled"
							onChange={(e) => setAnswer(e.target.value)}
							maxLength={254}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
})
