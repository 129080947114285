import React from 'react'
import Grid from '@material-ui/core/Grid'
import Tag from './Tag'
import QuestionContainer from './QuestionContainer'
import FormSliderField from '../../FormSliderField'
import RequiresTranslationBadge from '../../RequiresTranslationBadge'

export default ({ question, surveyEntity, position, positionDisplay }) => {
	return (
		<Grid data-testid="survey-analog-question" container>
			<Grid item xs={12}>
				<Tag tag={question.tag} />
			</Grid>
			<Grid item xs={12}>
				<QuestionContainer
					question={question}
					surveyEntity={surveyEntity}
					displayInstructionAsIcon={question.displayInstructionAsIcon}
					children={<FormSliderField question={question} />}
				/>
			</Grid>{' '}
			{question &&
			!question.id && (
				<Grid item xs={12} style={{ marginTop: '10px' }}>
					<RequiresTranslationBadge />
				</Grid>
			)}
		</Grid>
	)
}
