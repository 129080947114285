import React, { useEffect, Fragment, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'
import { fetchReadOnlySurveyByVersion, saveSurveyQuestionState, fetchSurveyUsageData, setSnackbarMessage, showModal, hideModal } from '../../redux/actions'
import { readonlySurveyQuestionListByVersionSelector, surveyUsageDataSelector, surveyInstanceSelector, surveyQuestionsLoadingSelector, protocolSelector } from '../../redux/selectors'
import { connect } from 'react-redux'
import { Link, useRouteMatch } from 'react-router-dom'
import RadioSelectQuestion from '../../components/survey/components/RadioSelectQuestion'
import MultiSelectQuestion from '../../components/survey/components/MultiSelectQuestion'
import ReadonlyTextBlock from '../../components/survey/components/ReadonlyTextBlock'
import NumberQuestion from '../../components/survey/components/NumberQuestion'
import DateQuestion from '../../components/survey/components/DateQuestion'
import TextQuestion from '../../components/survey/components/TextQuestion'
import FileQuestion from '../../components/survey/components/FileQuestion'
import GroupedQuestion from '../../components/survey/components/GroupedQuestion'
import AnalogQuestion from '../../components/survey/components/AnalogQuestion'
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon  from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import { Typography, CircularProgress, Button, Chip } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import QuestionControlMenu from './components/QuestionControlMenu'
import { validateQuestionRule } from '../../utilities/validators'
import { questionValue, questionCode, answerCode, categoryCode, simplifyString } from '../../utilities/utilities'
import ConditionsRow from '../../components/survey/components/ConditionsRow'
import SimulationRequestModal from './components/SimulationRequestModal'
import RuleHelperSnackbar from './components/RuleHelperSnackbar'
import RuleDescriptionRow from './components/RuleDescriptionRow'
import CopySurveyLayoutModal from './components/CopySurveyLayoutModal'
import ReactPaginate from 'react-paginate'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import EntityAuditCommentModal from "../../components/entityAudit/EntityAuditCommentModal"
import { isAuditCommentsRequired } from '../../utilities/auditUtils'

const MAX_ITERATIONS = 1000

const StyledPaginate = styled(ReactPaginate).attrs({
	activeClassName: 'active',
  })`
	margin-bottom: 2rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	list-style-type: none;
	padding: 0 5rem;
  
	li a {
	  border-radius: 7px;
	  padding: 0.1rem 1rem;
	  border: gray 1px solid;
	  cursor: pointer;
	}
	li.previous a,
	li.next a,
	li.break a {
	  border-color: transparent;
	}
	li.active a {
	  background-color: #0366d6;
	  border-color: transparent;
	  color: white;
	  min-width: 32px;
	}
	li.disabled a {
	  color: grey;
	}
	li.disable,
	li.disabled a {
	  cursor: default;
	}
  `

const answerDescription = (surveyEntities, rule) => {
	let result = rule
	let regex = /(A_(?<qId>[\d]{1,})_(?<aId>[\d]{1,}))/g

	let regexResult
	let iterations = 0
	while ((regexResult = regex.exec(result)) !== null) {
		if (iterations > MAX_ITERATIONS) {
			throw 'Maximum number of iterations exceeded {answerDescription}'
		}

		let questionId = regexResult.groups.qId
		let answerId = regexResult.groups.aId
		let key = answerCode(questionId, answerId)
		if (!!questionId && !!answerId && !!surveyEntities[key]?.entity) {
			result = result.replace(RegExp(key, 'g'), `"${surveyEntities[key].entity.label}"`)
		}

		iterations++
	}

	return result
}

const checkableQuestionWithLengthCheckDescription = (surveyEntities, key, rule, intl) => {
	let result = rule
	let regex = new RegExp(`(?<include>${key}\\.includes\\((?<value>".+?")\\)(?<continueAnd>( )?&&( )?)?)|(?<lengthKey>${key}.length==(?<length>[\\d]{1,}))|(?<or>\\|\\|)`, 'g')

	let replaceValues = {
		key: null,
		clearKeys: [],
		values: [],
		andCount: 0,
		length: 0
	}
	const replace = () => {
		if (!!replaceValues.key) {
			let appendAnd = ''
			if (replaceValues.andCount === replaceValues.clearKeys.length + 1) {
				appendAnd = ` ${intl.formatMessage({ id: 'app.survey.ruleDescription.and' })} `
			}
			if (replaceValues.length > 0) {
				if (replaceValues.length != replaceValues.values.length) {
					throw `The number of included answers {${replaceValues.values.length}}
						does not match the length ${replaceValues.length}
						of the array for the question "${key}"`
				}
				else {
					result = result.replace(
						replaceValues.key,
						`${intl.formatMessage(
							{ id: 'app.survey.ruleDescription.includesOnlyAnswers' },
							{
								questionTitle: surveyEntities[key].entity.title,
								answers: replaceValues.values.join(` ${intl.formatMessage({ id: 'app.survey.ruleDescription.and' })} `),
								appendAnd: appendAnd
							}
						)}`
					)
				}
			}
			else {
				result = result.replace(
					replaceValues.key,
					`${intl.formatMessage(
						{ id: 'app.survey.ruleDescription.includesAnswers' },
						{
							questionTitle: surveyEntities[key].entity.title,
							answers: replaceValues.values.join(` ${intl.formatMessage({ id: 'app.survey.ruleDescription.and' })} `),
							appendAnd: appendAnd
						}
					)}`
				)
			}

			replaceValues.clearKeys.map((key) => {
				result = result.replace(key, "")
			})

			replaceValues = {
				key: null,
				clearKeys: [],
				values: [],
				andCount: 0,
				length: 0
			}
		}
	}
	let iterations = 0
	let regexResult
	while ((regexResult = regex.exec(result)) !== null) {
		if (iterations > MAX_ITERATIONS) {
			throw 'Maximum number of iterations exceeded {checkableQuestionWithLengthCheckDescription}'
		}

		let include = regexResult.groups.include
		let value = regexResult.groups.value
		let lengthKey = regexResult.groups.lengthKey
		let length = regexResult.groups.length
		let or = regexResult.groups.or
		let continueAnd = regexResult.groups.continueAnd
		if (!!include) {
			if (!!continueAnd) {
				replaceValues.andCount++
			}
			if (replaceValues.values.includes(value)) {
				throw `Rule contains multiple keys of answer ${value} for the question ${key}`
			}
			else {
				replaceValues.values.push(value)
			}
			if (!replaceValues.key) {
				replaceValues.key = include
			}
			else {
				replaceValues.clearKeys.push(include)
			}
		}
		else if (!!lengthKey) {
			replaceValues.clearKeys.push(lengthKey)
			replaceValues.length = length
		}
		else if (!!or) {
			replace()
		}

		iterations++
	}
	replace()

	return result
}

const checkableQuestionDescription = (surveyEntities, rule, intl) => {
	let result = rule
	let regex = /((Q_(?<iId>[\d]{1,})_(?<qId>[\d]{1,})_(?<qType>[\d]{1,}))\.includes\(".+?"\))/g

	let regexResult
	let iterations = 0
	while ((regexResult = regex.exec(result)) !== null) {
		if (iterations > MAX_ITERATIONS) {
			throw 'Maximum number of iterations exceeded {checkableQuestionDescription}'
		}

		let instanceId = regexResult.groups.iId
		let questionId = regexResult.groups.qId
		let questionType = regexResult.groups.qType
		let key = questionCode(instanceId, questionId, questionType)
		if (!!instanceId && !!questionId && !!questionType && !!surveyEntities[key]?.entity) {
			result = checkableQuestionWithLengthCheckDescription(surveyEntities, key, result, intl)
		}

		iterations++
	}

	return result
}

const questionOrContextDescription = (surveyEntities, rule, intl) => {
	let result = rule
	let regex = /(C_(?<qcId>[\d]{1,})_(?<cId>[\d]{1,}))|(Q_(?<iId>[\d]{1,})_(?<qId>[\d]{1,})_(?<qType>[\d]{1,}))/g

	let regexResult
	let iterations = 0
	while ((regexResult = regex.exec(result)) !== null) {
		if (iterations > MAX_ITERATIONS) {
			throw 'Maximum number of iterations exceeded {questionOrContextDescription}'
		}

		let instanceId = regexResult.groups.iId
		let questionId = regexResult.groups.qId || regexResult.groups.qcId
		let categoryId = regexResult.groups.cId
		let questionType = regexResult.groups.qType
		let key = questionCode(instanceId, questionId, questionType)
		if (!!instanceId && !!questionId && !!questionType && !!surveyEntities[key]?.entity) {
			result = result.replace(
				RegExp(key, 'g'),
				` ${intl.formatMessage(
					{ id: 'app.survey.ruleDescription.answerToQuestion' },
					{ questionTitle: surveyEntities[key].entity.title }
				)}`
			)
		}
		else {
			key = categoryCode(questionId, categoryId)
			if (!!questionId && !!categoryId && !!surveyEntities[key]?.entity) {
				result = result.replace(
					RegExp(key, 'g'),
					` ${intl.formatMessage(
						{ id: 'app.survey.ruleDescription.answerToCategory' },
						{
							categoryTitle: surveyEntities[key].entity.label,
							questionTitle: surveyEntities[key].question.title
						}
					)}`
				)
			}
		}

		iterations++
	}

	return result
}

const ruleDescription = (surveyEntities, rule, intl, isVisibilityDescription) => {
	if (!!rule && rule.length > 0) {
		if (!isVisibilityDescription && rule === 'true') {
			return intl.formatMessage({ id: 'app.survey.ruleDescription.alwaysOptional' })
		}
		else if (!isVisibilityDescription && rule === 'false') {
			return intl.formatMessage({ id: 'app.survey.ruleDescription.neverOptional' })
		}
		else {
			let result = answerDescription(surveyEntities, rule)
			result = checkableQuestionDescription(surveyEntities, result, intl)
			result = questionOrContextDescription(surveyEntities, result, intl)

			result = result.replace(/\.length/g, ` ${intl.formatMessage({ id: 'app.survey.ruleDescription.length' })}`)
			result = result.replace(/==/g, ` ${intl.formatMessage({ id: 'app.survey.ruleDescription.isEqualTo' })} `)
			result = result.replace(/!=/g, ` ${intl.formatMessage({ id: 'app.survey.ruleDescription.isNotEqualTo' })} `)
			result = result.replace(/<=/g, ` ${intl.formatMessage({ id: 'app.survey.ruleDescription.isLessThanEqualTo' })} `)
			result = result.replace(/>=/g, ` ${intl.formatMessage({ id: 'app.survey.ruleDescription.isGreaterThanEqualTo' })} `)
			result = result.replace(/</g, ` ${intl.formatMessage({ id: 'app.survey.ruleDescription.isLessThan' })} `)
			result = result.replace(/>/g, ` ${intl.formatMessage({ id: 'app.survey.ruleDescription.isGreaterThan' })} `)
			result = result.replace(/&&/g, ` ${intl.formatMessage({ id: 'app.survey.ruleDescription.and' })} `)
			result = result.replace(/\|\|/g, ` ${intl.formatMessage({ id: 'app.survey.ruleDescription.or' })} `)

			if (!!isVisibilityDescription) {
				result = intl.formatMessage({ id: 'app.survey.ruleDescription.visibilityPrefix' }).replace("%%text%%", simplifyString(result))
			}
			else {
				result = intl.formatMessage({ id: 'app.survey.ruleDescription.optionalPrefix' }).replace("%%text%%", simplifyString(result))
			}
			return result
		}
	}

	return undefined
}

const SurveyViewerContainer = ({
	onCopySurveyQuestionRules,
	onShowSimulation,
	intl,
	loadSurvey,
	saveSurveyQuestionState,
	questionsLoading,
	questions,
	fetchSurveyUsageData,
	survey,
	usageData,
	showValidationError,
	showDialog,
	changeIsOptional,
	protocol
}) => {
	const theme = useTheme()
	const match = useRouteMatch()

	const [loadState, setLoadState] = useState(0)
	const [surveyInstance, setSurveyInstance] = useState(null)
	const [_surveyEntities, setSurveyEntities] = useState(null)
	const [questionStateList, setQuestionStateList] = useState([])
	const [bundleStateList, setBundleStateList] = useState([])
	const [answerStateList, setAnswerStateList] = useState([])
	const [anyChanges, setAnyChanges] = useState(false)
	const dispatch = useDispatch()

	const clearStates = () => {
		setSurveyEntities(null)
		setQuestionStateList([])
		setBundleStateList([])
		setAnswerStateList([])
		setLoadState(0)
	}

	const loadData = () => {
		clearStates()
		setLoadState(1)
		loadSurvey(match.params.surveyId, match.params.instanceId, match.params.versionId)
		fetchSurveyUsageData(match.params.surveyId, match.params.instanceId, match.params.versionId)
	}

	useEffect(
		() => {
			if (match.params.surveyId) {
				loadData()
			}
		},
		[match.params.surveyId, match.params.versionId, match.params.instanceId]
	)

	useEffect(
		() => {
			if (!questionsLoading && loadState === 1) {
				setLoadState(2)
			}
		},
		[questionsLoading, loadState]
	)

	useEffect(
		() => {
			if (!!questions && questions.length > 0 && !_surveyEntities) {
				initializeSurveyEntities()
			}
		},
		[questions && questions.length]
	)

	useEffect(
		() => {
			if (!!survey && !surveyInstance) {
				setSurveyInstance(survey)
			}
		},
		[survey]
	)

	useEffect(
		() => {
			if (answerStateList.length > 0) {
				updateSurveyEntitiesContext({..._surveyEntities})
			}
		},
		[answerStateList]
	)

	useEffect(
		() => {
			let result = questionStateList.length > 0
				|| bundleStateList.length > 0
				|| answerStateList.length > 0
			if (result !== anyChanges) {
				setAnyChanges(result)
			}
		},
		[questionStateList, bundleStateList, answerStateList]
	)

	const setSurveyEntityDescription = (surveyEntities, surveyEntity) => {
		try {
			surveyEntity.visibilityRuleDescription = ruleDescription(surveyEntities, surveyEntity.visibilityRule, intl, true)
		}
		catch (err) {
			surveyEntity.visibilityRuleDescription = surveyEntity.visibilityRule
		}
		try {
			surveyEntity.optionalRuleDescription = ruleDescription(surveyEntities, surveyEntity.optionalRule, intl, false)
		}
		catch (err) {
			surveyEntity.optionalRuleDescription = surveyEntity.optionalRule
		}
	}

	const getSurveyEntityByQuestion = (surveyEntities, question) => {
		if (!!surveyEntities) {
			let key = questionCode(match.params.instanceId, question.baseId, question.questionType)
			return surveyEntities[key]
		}
		else {
			return null
		}
	}

	const updateSurveyEntitiesContext = (surveyEntities) => {
		questions.map((container) => {
			container.questions.map((question) => {
				let surveyEntity = getSurveyEntityByQuestion(surveyEntities, question)
				setSurveyEntityDescription(surveyEntities, surveyEntity)
				surveyEntity.context = getQuestionEvalContext(question, match.params.instanceId)
			})
		})
		setSurveyEntities(surveyEntities)
	}

	const initializeSurveyEntities = () => {
		let surveyEntities = {}
		questions.map((container) => {
			container.questions.map((question) => {
				surveyEntities[questionCode(match.params.instanceId, question.baseId, question.questionType)] = {
					entity: question,
					isConditionsRowVisible: false,
					isConditionalVisibility: question.isConditionalVisibility,
					state: question.state,
					visibilityRule: question.visibilityRule,
					visibilityRuleDescription: undefined,
					optionalRule: question.optionalRule,
					optionalRuleDescription: undefined,
					isOptionalOverride: question.isOptionalOverride,
					isBilateral: question.isBilateral,
					context: ''
				}
				if (!!question.answers) {
					question.answers.map((answer) => {
						surveyEntities[answerCode(question.baseId, answer.baseId)] = {
							entity: answer,
							question: question
						}
					})
				}
				if (!!question.categories) {
					question.categories.map((category) => {
						surveyEntities[categoryCode(question.baseId, category.baseId)] = {
							entity: category,
							question: question
						}
					})
				}
			})
		})
		updateSurveyEntitiesContext(surveyEntities)
	}

	const getQuestionEvalContext = (question, instanceId) => {
		let result = ""
	
		if (question.questionType !== 8) {
			let values = []
			if (!!question.answers) {
				question.answers.map((answer) => {
					let currentState = getCurrentAnswerState(answer.baseId, question.baseId, question.questionType)
					if (
						(!currentState && answer.state === 1)
						|| (!!currentState && currentState === 1)
						|| question.isBundle
					) {
						result += `let ${answerCode(question.baseId, answer.baseId)}=${answer.baseId}\r\n`
						values.push(`${answer.baseId}`)
					}
				})
			}
			else if (!!question.categories) {
				question.categories.map((category) => {
					let currentState = getCurrentAnswerState(category.baseId, question.baseId, question.questionType)
					if (
						(!currentState && category.state === 1)
						|| (!!currentState && currentState === 1)
						|| question.isBundle
					) {
						result += `let ${categoryCode(question.baseId, category.baseId)}=${questionValue(question.questionType, 1)}\r\n`
						values.push(`${category.baseId}`)
					}
				})
			}

			if (values.length > 0) {
				result += `let ${questionCode(instanceId, question.baseId, question.questionType)}=[${values.join(",")}]\r\n`
			}
			else {
				result += `let ${questionCode(instanceId, question.baseId, question.questionType)}=${questionValue(question.questionType, 1)}\r\n`
			}
		}
	
		return result
	}

	const setSurveyEntityState = (questionId, questionType, state) => {
		let key = questionCode(match.params.instanceId, questionId, questionType)
		let surveyEntity = _surveyEntities[key]
		surveyEntity.state = state
	}

	const setSurveyEntityIsOptional = (questionId, questionType, isOptional) => {
		let key = questionCode(match.params.instanceId, questionId, questionType)
		let surveyEntity = _surveyEntities[key]
		surveyEntity.isOptionalOverride = isOptional
	}

	const setSurveyEntityIsBilateral = (questionId, questionType, isBilateral) => {
		let key = questionCode(match.params.instanceId, questionId, questionType)
		let surveyEntity = _surveyEntities[key]
		surveyEntity.isBilateral = isBilateral
	}

	const getDefaultQuestion = (id, type, isBundle = false) => {
		let result = undefined
		questions.find((questionsContainer) => {
			let question = questionsContainer.questions.find(
				(question) =>
					(
						!isBundle
						&& question.baseId === id
						&& question.questionType === type
					)
					|| (
						isBundle
						&& question.bundleEntityId === id
					)
			)
			if (!!question) {
				result = question
				return true
			}

			return false
		})

		return result
	}

	const changeQuestionState = (id, type, state) => {
		var changedQuestion = { id: id, type: type, state: state }
		let question = questionStateList.find(x => x.id === id && x.type === type)
		let defaultQuestion = getDefaultQuestion(id, type)
		setSurveyEntityState(defaultQuestion.baseId, defaultQuestion.questionType, state)
		closeConditionEditor(defaultQuestion)
		if (!question) {
			changedQuestion.isConditionalVisibility = defaultQuestion.isConditionalVisibility
			changedQuestion.visibilityRule = defaultQuestion.visibilityRule
			changedQuestion.optionalRule = defaultQuestion.optionalRule
			changedQuestion.isOptionalOverride = defaultQuestion.isOptionalOverride
			changedQuestion.isBilateral = defaultQuestion.isBilateral
			setQuestionStateList([...questionStateList, changedQuestion])
		}
		else {
			changedQuestion.isConditionalVisibility = question.isConditionalVisibility
			changedQuestion.visibilityRule = question.visibilityRule
			changedQuestion.optionalRule = question.optionalRule
			changedQuestion.isOptionalOverride = question.isOptionalOverride
			changedQuestion.isBilateral = question.isBilateral
			setQuestionStateList(questionStateList.map(x => x.id === id && x.type === type ? { ...changedQuestion } : x))
		}
	}

	const handleQuestionStateChange = (id, type, event) => {
		changeQuestionState(id, type, event.target.checked ? 1 : 2)
	}

	const archiveQuestion = (id, type, initialState) => {
		let currentState = questionStateList.find(x => x.id === id && x.type === type)?.state
		if (!currentState) {
			currentState = initialState
		}
		changeQuestionState(id, type, currentState === 3 ? 1 : 3)
	}

	const changeBundleState = (id, state) => {
		var changedBundle = { id: id, state: state }
		let bundle = bundleStateList.find(x => x.id === id)
		let defaultBundle = getDefaultQuestion(id, undefined, true)
		setSurveyEntityState(defaultBundle.baseId, defaultBundle.questionType, state)
		closeConditionEditor(defaultBundle)
		if (!bundle) {
			changedBundle.isConditionalVisibility = defaultBundle.isConditionalVisibility
			changedBundle.visibilityRule = defaultBundle.visibilityRule
			changedBundle.optionalRule = defaultBundle.optionalRule
			changedBundle.isOptionalOverride = defaultBundle.isOptionalOverride
			changedBundle.isBilateral = defaultBundle.isBilateral
			setBundleStateList([...bundleStateList, changedBundle])
		}
		else {
			changedBundle.isConditionalVisibility = bundle.isConditionalVisibility
			changedBundle.visibilityRule = bundle.visibilityRule
			changedBundle.optionalRule = bundle.optionalRule
			changedBundle.isOptionalOverride = bundle.isOptionalOverride
			changedBundle.isBilateral = bundle.isBilateral
			setBundleStateList(bundleStateList.map(x => x.id === id ? { ...changedBundle } : x))
		}
	}

	const handleBundleStateChange = (id, event) => {
		changeBundleState(id, event.target.checked ? 1 : 2)
	}

	const archiveBundle = (id, initialState) => {
		let currentState = bundleStateList.find(x => x.id === id)?.state
		if (!currentState) {
			currentState = initialState
		}
		changeBundleState(id, currentState === 3 ? 1 : 3)
	}

	const handleAnswerStateChange = (id, questionId, questionType, checked) => {
		var answer = { id: id, questionId: questionId, questionType: questionType, state: checked ? 1 : 2 }
		if (!answerStateList.find(x => x.id === id && x.questionId === questionId && x.questionType === questionType)) {
			setAnswerStateList([...answerStateList, answer])
		}
		else {
			setAnswerStateList(answerStateList.map(x => x.id === id && x.questionId === questionId && x.questionType === questionType ? { ...answer } : x))
		}
	}

	const archiveAnswer = (id, questionId, questionType, initialState) => {
		if (!answerStateList.find(x => x.id === id && x.questionId === questionId && x.questionType === questionType)) {
			setAnswerStateList([...answerStateList, { id: id, questionId: questionId, questionType: questionType, state: initialState === 3 ? 1 : 3 }])
		}
		else {
			var answer = { id: id, questionId: questionId, questionType: questionType, state: answerStateList.find(x => x.id === id).state === 3 ? 1 : 3 }
			setAnswerStateList(answerStateList.map(x => x.id === id && x.questionId === questionId && x.questionType === questionType ? { ...answer } : x))
		}
	}

	const getCurrentQuestionState = (id, type) => {
		return questionStateList.find(x => x.id === id && x.type == type)?.state
	}

	const getCurrentAnswerState = (id, questionId, questionType) => {
		return answerStateList.find(x => x.id === id && x.questionId === questionId && x.questionType === questionType)?.state
	}

	const getCurrentBundleState = (id) => {
		return bundleStateList.find(x => x.id === id)?.state
	}

	const validateConditions = (stateList) => {
		let result = []
		stateList.map((question) => {
			if (question.state === 1 && question.isConditionalVisibility) {
				let error = validateCondition(question, question.visibilityRule)
				if (!!error && !result.includes(error)) {
					result.push(error)
				}
				error = validateCondition(question, question.optionalRule)
				if (!!error && !result.includes(error)) {
					result.push(error)
				}
			}
		})

		return result
	}

	const handleCopySurveyQuestionRules = () => {
		showDialog(
			CopySurveyLayoutModal,
			{
				surveyId: match.params.surveyId,
				instanceId: match.params.instanceId,
				versionId: match.params.versionId
			}
		)
	}

	const handleShowSimulation = () => {
		if (anyChanges) {
			showDialog(
				SimulationRequestModal,
				{
					onConfirm: onShowSimulation
				}
			)
		}
		else {
			onShowSimulation()
		}
	}

	const handleSaveStateClick = async () => {
		let questionsForValidation = []
		questions.map((container) => {
			let changedQuestion = undefined
			let defaultQuestion = container.questions[0]
			if (defaultQuestion.isBundle) {
				changedQuestion = bundleStateList.find(x => x.id === defaultQuestion.bundleEntityId)
			}
			else {
				changedQuestion = questionStateList.find(x => x.id === defaultQuestion.baseId && x.type === defaultQuestion.questionType)
			}

			if (changedQuestion) {
				questionsForValidation.push(changedQuestion)
			}
			else {
				questionsForValidation.push(defaultQuestion)
			}
		})
		let errors = validateConditions(questionsForValidation)

		if (errors.length) {
			showValidationError(intl.formatMessage({ id: 'app.surveys.conditionsValidationError' }) + ": " + errors.join(", "))
		}
		else {
			let questionStates = [...questionStateList]
			let bundleStates = [...bundleStateList]
			let answerStates = [...answerStateList]
			if (isAuditCommentsRequired())
			{
				dispatch(
					showModal(
						EntityAuditCommentModal,
						{
							entityId: match.params.versionId,
							entityTypeId: 17, //protocol
							saveCallback: (commentsObj) => {
								dispatch(hideModal())
								clearStates()
								saveSurveyQuestionState(
									match.params.protocolId,
									match.params.surveyId,
									match.params.instanceId,
									match.params.versionId,
									questionStates,
									bundleStates,
									answerStates,
									commentsObj
								)
							}
						}
					)
				)
			}
			else
			{
				clearStates()
				saveSurveyQuestionState(
					match.params.protocolId,
					match.params.surveyId,
					match.params.instanceId,
					match.params.versionId,
					questionStates,
					bundleStates,
					answerStates
				)
			}
		}
	}

	const validateCondition = (currentQuestion, value) => {
		let globalContext = ""
		questions.map((questionContainer) => {
			questionContainer.questions.map((question) => {
				if (
					(
						currentQuestion.isBundle
						&& currentQuestion.bundleEntityId !== question.bundleEntityId
					)
					|| (
						!currentQuestion.isBundle
						&& (
							currentQuestion.baseId !== question.baseId
							|| currentQuestion.questionType !== question.questionType
						)
					)
				) {
					let surveyEntity = getSurveyEntityByQuestion(_surveyEntities, question)
					if (surveyEntity.state === 1) {
						globalContext += surveyEntity.context + '\r\n'
					}
				}
			})
		})
		return validateQuestionRule(globalContext, value)
	}
	
	const getRuleDescription = (value, isVisibilityDescription) => {
		return ruleDescription(_surveyEntities, value, intl, isVisibilityDescription)
	}

	const buildQuestionState = (question) => {
		return {
			id: question.baseId,
			type: question.questionType,
			state: question.state,
			isConditionalVisibility: question.isConditionalVisibility,
			visibilityRule: question.visibilityRule,
			optionalRule: question.optionalRule,
			isOptionalOverride: question.isOptionalOverride,
			isBilateral: question.isBilateral
		}
	}

	const buildBundleState = (bundle) => {
		return {
			id: bundle.bundleEntityId,
			state: bundle.state,
			isConditionalVisibility: bundle.isConditionalVisibility,
			visibilityRule: bundle.visibilityRule,
			optionalRule: bundle.optionalRule,
			isOptionalOverride: bundle.isOptionalOverride,
			isBilateral: bundle.isBilateral
		}
	}

	const setSurveyEntityCondition = (question, isConditionalVisibility, visibilityRule, optionalRule) => {
		let surveyEntity = getSurveyEntityByQuestion(_surveyEntities, question)
		surveyEntity.isConditionalVisibility = isConditionalVisibility
		surveyEntity.visibilityRule = visibilityRule
		surveyEntity.optionalRule = optionalRule
		setSurveyEntityDescription(_surveyEntities, surveyEntity)
	}

	const addQuestionCondition = (values) => {
		let question = questionStateList.find(x => x.id === values.question.baseId && x.type === values.question.questionType)

		setSurveyEntityCondition(
			values.question,
			values.isConditionalVisibility,
			values.visibilityRule,
			values.optionalRule
		)

		if (!question) {
			question = buildQuestionState(values.question)
			question.isConditionalVisibility = values.isConditionalVisibility
			question.visibilityRule = values.visibilityRule
			question.optionalRule = values.optionalRule
			setQuestionStateList([...questionStateList, question])
		}
		else {
			question.isConditionalVisibility = values.isConditionalVisibility
			question.visibilityRule = values.visibilityRule
			question.optionalRule = values.optionalRule
			setQuestionStateList(questionStateList.map(x => x.id === question.id && x.type === question.type ? { ...question } : x))
		}
	}

	const addBundleCondition = (values) => {
		var bundle = bundleStateList.find(x => x.id === values.question.bundleEntityId)

		setSurveyEntityCondition(
			values.question,
			values.isConditionalVisibility,
			values.visibilityRule,
			values.optionalRule
		)

		if (!bundle) {
			bundle = buildBundleState(values.question)
			bundle.isConditionalVisibility = values.isConditionalVisibility
			bundle.visibilityRule = values.visibilityRule
			bundle.optionalRule = values.optionalRule
			setBundleStateList([...bundleStateList, bundle])
		}
		else {
			bundle.isConditionalVisibility = values.isConditionalVisibility
			bundle.visibilityRule = values.visibilityRule
			bundle.optionalRule = values.optionalRule
			setBundleStateList(bundleStateList.map(x => x.id === bundle.id ? { ...bundle } : x))
		}
	}

	const changeIsBundleOptional = (id, value) => {
		var changedBundle = { id: id, isOptionalOverride: value }
		let bundle = bundleStateList.find(x => x.id === id)
		let defaultBundle = getDefaultQuestion(id, undefined, true)

		let originalBundle = questions.find(x => x.isBundle && x.bundleEntityId === id)
		console.log(originalBundle)
		if(originalBundle && originalBundle.questions) {
			originalBundle.questions.forEach(q => setSurveyEntityIsOptional(q.baseId, q.questionType, value))
		}
		
		if (!bundle) {
			changedBundle.isConditionalVisibility = defaultBundle.isConditionalVisibility
			changedBundle.visibilityRule = defaultBundle.visibilityRule
			changedBundle.optionalRule = defaultBundle.optionalRule
			changedBundle.state = defaultBundle.state
			changedBundle.isBilateral = defaultBundle.isBilateral
			setBundleStateList([...bundleStateList, changedBundle])
		}
		else {
			changedBundle.isConditionalVisibility = bundle.isConditionalVisibility
			changedBundle.visibilityRule = bundle.visibilityRule
			changedBundle.optionalRule = bundle.optionalRule
			changedBundle.state = bundle.state
			changedBundle.isBilateral = bundle.isBilateral
			setBundleStateList(bundleStateList.map(x => x.id === id ? { ...changedBundle } : x))
		}
	}

	const changeIsQuestionOptional = (id, type, value) => {
		var changedQuestion = { id: id, type: type, isOptionalOverride: value }
		let question = questionStateList.find(x => x.id === id && x.type === type)
		let defaultQuestion = getDefaultQuestion(id, type)
		setSurveyEntityIsOptional(defaultQuestion.baseId, defaultQuestion.questionType, value)
		if (!question) {
			changedQuestion.isConditionalVisibility = defaultQuestion.isConditionalVisibility
			changedQuestion.visibilityRule = defaultQuestion.visibilityRule
			changedQuestion.optionalRule = defaultQuestion.optionalRule
			changedQuestion.state = defaultQuestion.state
			changedQuestion.isBilateral = defaultQuestion.isBilateral
			setQuestionStateList([...questionStateList, changedQuestion])
		}
		else {
			changedQuestion.isConditionalVisibility = question.isConditionalVisibility
			changedQuestion.visibilityRule = question.visibilityRule
			changedQuestion.optionalRule = question.optionalRule
			changedQuestion.state = question.state
			changedQuestion.isBilateral = question.isBilateral
			setQuestionStateList(questionStateList.map(x => x.id === id && x.type === type ? { ...changedQuestion } : x))
		}
	}

	const changeIsBundleBilateral = (id, value) => {
		var changedBundle = { id: id, isBilateral: value }
		let bundle = bundleStateList.find(x => x.id === id)
		let defaultBundle = getDefaultQuestion(id, undefined, true)
		setSurveyEntityIsBilateral(defaultBundle.baseId, defaultBundle.questionType, value)
		if (!bundle) {
			changedBundle.isConditionalVisibility = defaultBundle.isConditionalVisibility
			changedBundle.visibilityRule = defaultBundle.visibilityRule
			changedBundle.optionalRule = defaultBundle.optionalRule
			changedBundle.isOptionalOverride = defaultBundle.isOptionalOverride
			changedBundle.state = defaultBundle.state
			setBundleStateList([...bundleStateList, changedBundle])
		}
		else {
			changedBundle.isConditionalVisibility = bundle.isConditionalVisibility
			changedBundle.visibilityRule = bundle.visibilityRule
			changedBundle.optionalRule = bundle.optionalRule
			changedBundle.isOptionalOverride = bundle.isOptionalOverride
			changedBundle.state = bundle.state
			setBundleStateList(bundleStateList.map(x => x.id === id ? { ...changedBundle } : x))
		}
	}

	const changeIsQuestionBilateral = (id, type, value) => {
		var changedQuestion = { id: id, type: type, isBilateral: value }
		let question = questionStateList.find(x => x.id === id && x.type === type)
		let defaultQuestion = getDefaultQuestion(id, type)
		setSurveyEntityIsBilateral(defaultQuestion.baseId, defaultQuestion.questionType, value)
		if (!question) {
			changedQuestion.isConditionalVisibility = defaultQuestion.isConditionalVisibility
			changedQuestion.visibilityRule = defaultQuestion.visibilityRule
			changedQuestion.optionalRule = defaultQuestion.optionalRule
			changedQuestion.isOptionalOverride = defaultQuestion.isOptionalOverride
			changedQuestion.state = defaultQuestion.state
			setQuestionStateList([...questionStateList, changedQuestion])
		}
		else {
			changedQuestion.isConditionalVisibility = question.isConditionalVisibility
			changedQuestion.visibilityRule = question.visibilityRule
			changedQuestion.optionalRule = question.optionalRule
			changedQuestion.isOptionalOverride = question.isOptionalOverride
			changedQuestion.state = question.state
			setQuestionStateList(questionStateList.map(x => x.id === id && x.type === type ? { ...changedQuestion } : x))
		}
	}

	const getCurrentQuestionIsOptionalOverride = (id, type) => {
		let q = questionStateList.find(x => x.id === id && x.type == type)
		if(!q)
			return undefined
		else
			return q.isOptionalOverride
	}

	const getCurrentBundleIsOptionalOverride = (id) => {
		let b = bundleStateList.find(x => x.id === id)
		if(!b)
			return undefined
		else
			return b.isOptionalOverride
	}

	const getCurrentQuestionIsBilateral = (id, type) => {
		let q = questionStateList.find(x => x.id === id && x.type == type)
		if(!q)
			return undefined
		else
			return q.isBilateral
	}

	const getCurrentBundleIsBilateral = (id) => {
		let b = bundleStateList.find(x => x.id === id)
		if(!b)
			return undefined
		else
			return b.isBilateral
	}
	
	const saveCondition = (values) => {
		if (values.question.isBundle) {
			addBundleCondition(values)
		}
		else {
			addQuestionCondition(values)
		}
		closeConditionEditor(values.question)
	}

	const closeConditionEditor = (question) => {
		let surveyEntity = getSurveyEntityByQuestion(_surveyEntities, question)
		surveyEntity.isConditionsRowVisible = false
		setSurveyEntities({..._surveyEntities})
	}

	const editQuestionCondition = (question) => {
		let surveyEntity = getSurveyEntityByQuestion(_surveyEntities, question)
		surveyEntity.isConditionsRowVisible = true
		setSurveyEntities({..._surveyEntities})
	}

	const editBundleCondition = (bundle) => {
		let question = bundle.questions.find((question) => question.sortOrder === bundle.sortOrder)
		let surveyEntity = getSurveyEntityByQuestion(_surveyEntities, question)
		surveyEntity.isConditionsRowVisible = true
		setSurveyEntities({..._surveyEntities})
	}

	const hasOpenedConditionsRow = (surveyEntities) => {
		return (
			!!surveyEntities
			&& !!Object.keys(surveyEntities).find((key) => !!surveyEntities[key].isConditionsRowVisible)
		)
	}

	const getBackgroundColor = (state) => {
		switch (state) {
			case 0:
				return '#e5e5e5'
			case 1:
				return '#fff'
			case 2:
				return '#c1c1c1'
			case 3:
				return '#7f7f7f'
		}
	}

	const getBundleBackgroundColor = (state) => {
		switch (state) {
			case 0:
				return '#e5e5e5'
			case 1:
				return '#ededed'
			case 2:
				return '#c1c1c1'
			case 3:
				return '#7f7f7f '
		}
	}

	const renderConditionsRow = (surveyEntities, question) => {
		let surveyEntity = getSurveyEntityByQuestion(surveyEntities, question)
		if (
			!!surveyEntity
			&& !!surveyEntity.isConditionsRowVisible
		) {
			return (
				<Grid xs={12} style={{paddingBottom: '10px'}}>
					<ConditionsRow
						initialValues={{
							question: question,
							isConditionalVisibility: surveyEntity.isConditionalVisibility,
							visibilityRule: surveyEntity.visibilityRule,
							optionalRule: surveyEntity.optionalRule
						}}
						ruleValidator={validateCondition}
						getRuleDescription={getRuleDescription}
						onSubmit={saveCondition}
						onDiscard={closeConditionEditor}
						style={{width: '100%'}}
					/>
				</Grid>
			)
		}
	}

	const renderRuleDescription = (surveyEntities, question) => {
		let surveyEntity = getSurveyEntityByQuestion(surveyEntities, question)
		if (
			!!surveyEntity
			&& !!surveyEntity.isConditionalVisibility
			&& surveyEntity.state === 1
		) {
			return (
				<Fragment>
					{
						!!surveyEntity.visibilityRuleDescription?.length
						&& <RuleDescriptionRow
							description={surveyEntity.visibilityRuleDescription}
							style={{
								margin: '0 267px 5px 48px'
							}}
						/>
					}
					{
						!!surveyEntity.optionalRuleDescription?.length
						&& <RuleDescriptionRow
							description={surveyEntity.optionalRuleDescription}
							isVisibilityDescription={false}
							style={{
								margin: '0 267px 5px 48px'
							}}
						/>
					}
				</Fragment>
			)
		} 
	}

	const renderQuestion = (q, ind) => {
		const backgroundColor = !q.isBundle ? getBackgroundColor(getCurrentQuestionState(q.baseId, q.questionType) || q.state) : '';
		return (
			<Grid
				key={`question_${match.params.instanceId}_${q.baseId}_${q.questionType}`}
				container
				className="survey-viewer"
				data-testid="container-current-question-state"
				style={{backgroundColor: backgroundColor, paddingTop: !q.isBundle && '20px', marginTop: !q.isBundle && '20px'}}
			>
				{
					!q.isBundle
					&& renderConditionsRow(_surveyEntities, q)
				}
				<Grid container direction="row" justify="flex-start">
					<Grid container style={{ alignItems: 'flex-start', flex: 0.05, minWidth: '33px', height: '39px', padding: '10px 0 0 10px'  }}>
						{!q.isBundle && (q.state < 3 && !getCurrentQuestionState(q.baseId, q.questionType) || getCurrentQuestionState(q.baseId, q.questionType) < 3) &&
							<Checkbox
								icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
								checkedIcon={<CheckBoxIcon fontSize="large" />}
								indeterminateIcon={<IndeterminateCheckBoxIcon fontSize="large" />}
								indeterminate={q.state === 0 && !getCurrentQuestionState(q.baseId, q.questionType)}
								checked={q.state === 1 && !getCurrentQuestionState(q.baseId, q.questionType) || getCurrentQuestionState(q.baseId, q.questionType) === 1}
								color="primary"
								style={{ marginTop: '-9px' }}
								onChange={handleQuestionStateChange.bind(this, q.baseId, q.questionType)} />}
						{!q.isBundle && (q.state === 3 && !getCurrentQuestionState(q.baseId, q.questionType) || getCurrentQuestionState(q.baseId, q.questionType) === 3) &&

							<HighlightOffIcon
								fontSize='large'
								style={{ padding: '0' }} />}
					</Grid>
					<Grid container style={{ alignItems: 'center', flex: 0.8 }}>
						{(() => {
							switch (q.questionType) {
								case 5:
									return (
										<TextQuestion
											question={q}
											surveyEntity={getSurveyEntityByQuestion(_surveyEntities, q)}
											key={ind}
											position={ind + 1}
											showScoreEntry={false} />
									)
								case 6:
									return (
										<NumberQuestion
											question={q}
											surveyEntity={getSurveyEntityByQuestion(_surveyEntities, q)}
											key={ind}
											position={ind + 1}
											name={'answers.' + q.id}
											showScoreEntry={false} />
									)
								case 8:
									return (
										<ReadonlyTextBlock
											question={q}
											surveyEntity={getSurveyEntityByQuestion(_surveyEntities, q)}
											key={ind}
											position={ind + 1}
										/>
									)
								case 3:
									return (
										<GroupedQuestion
											question={q}
											surveyEntity={getSurveyEntityByQuestion(_surveyEntities, q)}
											key={ind}
											position={ind + 1}
											showScoreEntry={false}
											getCurrentAnswerState={getCurrentAnswerState}
											handleAnswerStateChange={handleAnswerStateChange}
											archiveAnswer={archiveAnswer}
											getBackgroundColor={getBackgroundColor}
											defaultBackgroundColor={backgroundColor}
											showState={!q.isBundle && (q.state === 1 && !getCurrentQuestionState(q.baseId, q.questionType) || getCurrentQuestionState(q.baseId, q.questionType) === 1)} />
									)
								case 1:
									return (
										<RadioSelectQuestion
											question={q}
											surveyEntity={getSurveyEntityByQuestion(_surveyEntities, q)}
											key={ind}
											position={ind + 1}
											showScoreEntry={false}
											getCurrentAnswerState={getCurrentAnswerState}
											handleAnswerStateChange={handleAnswerStateChange}
											archiveAnswer={archiveAnswer}
											getBackgroundColor={getBackgroundColor}
											defaultBackgroundColor={backgroundColor}
											showState={!q.isBundle && (q.state === 1 && !getCurrentQuestionState(q.baseId, q.questionType) || getCurrentQuestionState(q.baseId, q.questionType) === 1)} />
									)

								case 7:
									return (
										<DateQuestion
											question={q}
											surveyEntity={getSurveyEntityByQuestion(_surveyEntities, q)}
											key={ind}
											position={ind + 1}
											showScoreEntry={false} />
									)
								case 2:
									return (
										<MultiSelectQuestion
											question={q}
											surveyEntity={getSurveyEntityByQuestion(_surveyEntities, q)}
											key={ind}
											position={ind + 1}
											showScoreEntry={false}
											getCurrentAnswerState={getCurrentAnswerState}
											handleAnswerStateChange={handleAnswerStateChange}
											archiveAnswer={archiveAnswer}
											getBackgroundColor={getBackgroundColor}
											defaultBackgroundColor={backgroundColor}
											showState={!q.isBundle && (q.state === 1 && !getCurrentQuestionState(q.baseId, q.questionType) || getCurrentQuestionState(q.baseId, q.questionType) === 1)} />

									)
								case 9:
									return (
										<AnalogQuestion
											question={q}
											surveyEntity={getSurveyEntityByQuestion(_surveyEntities, q)}
											key={ind}
											position={ind + 1}
											showScoreEntry={false} />
									)
									case 10: case 11:
									return (
										<FileQuestion
											question={q}
											surveyEntity={getSurveyEntityByQuestion(_surveyEntities, q)}
											key={ind}
											position={ind + 1}
											showScoreEntry={false}
											disabled={true} />
									)
								default:
									return <div />
							}
						})()}
					</Grid>
					<Grid container style={{ alignItems: 'flex-start', flexWrap: 'nowrap', flex: 0.3, justifyContent: 'flex-end'}}>	
						{!q.isBundle &&
							<Fragment>
								<Grid item>
									<Link
										to={`/relatedEntities?questionId=${q.id}&questionType=${q.questionType}`}
										style={{
											textDecoration: 'none',

											fontSize: '18px',
											color: 'black'
										}}
									>
										<Typography
											style={{
												fontSize: '12px',
												lineHeight: '25px',
												letterSpacing: '0.2px',
												color: '#011020',
												textDecoration: 'underline'
											}}
										>
											{intl.formatMessage({ id: 'app.survey.usageStats' }).replace('%%ReferenceCount%%', q.baseReferencesCount).replace('%%CasesCount%%', q.casesCount)}
										</Typography>
									</Link>
								</Grid>
								<Grid item style={{ margin: '-7px 0 0 0px' }}>
									<QuestionControlMenu
										question={q}
										surveyState={survey.state}
										archiveQuestion={archiveQuestion}
										getCurrentQuestionState={getCurrentQuestionState}
										archiveBundle={archiveBundle}
										getCurrentBundleState={getCurrentBundleState}
										addCondition={editQuestionCondition}
										changeIsQuestionOptional={changeIsQuestionOptional}
										changeIsBundleOptional={changeIsBundleOptional}
										getCurrentQuestionIsOptionalOverride={getCurrentQuestionIsOptionalOverride}
										getCurrentBundleIsOptionalOverride={getCurrentBundleIsOptionalOverride}
										changeIsQuestionBilateral={changeIsQuestionBilateral}
										getCurrentBundleIsBilateral={getCurrentBundleIsBilateral}
										getCurrentQuestionIsBilateral={getCurrentQuestionIsBilateral}
										canChangeBilateral={protocol && protocol.isBilateral}
									/>
								</Grid>
							</Fragment>}
					</Grid>
				</Grid>
			</Grid>
		)
	}

	const renderContainer = (container) => {
		if (container.isBundle) {
			return (
				<Fragment
					key={`bundle_${container.bundleEntityId}_${container.questionType}`}
				>
					<div className="survey-viewer" style={{ backgroundColor: getBackgroundColor(getCurrentBundleState(container.bundleEntityId) || container.state), marginTop: '20px' }}>
						{
							renderConditionsRow(_surveyEntities, container.questions[0])
							// openedConditions.map((initialValues) => {
							// 	if (container.bundleEntityId === initialValues.question.bundleEntityId) {
							// 		return (
							// 			<Grid xs={12} style={{paddingBottom: '10px'}}>
							// 				<ConditionsRow
							// 					initialValues={initialValues}
							// 					ruleValidator={validateCondition}
							// 					getRuleDescription={getRuleDescription}
							// 					onSubmit={saveCondition}
							// 					onDiscard={closeConditionEditor}
							// 				/>
							// 			</Grid>
							// 		)
							// 	}
							// 	else {
							// 		return null
							// 	}
							// })
						}
						<Grid container direction="row" justify="flex-start">
							<Grid item style={{ alignItems: 'center', flex: 0.05, minWidth: '33px', height: '39px', padding: '10px 0 0 10px' }}>
								{(container.state < 3 && !getCurrentBundleState(container.bundleEntityId) || getCurrentBundleState(container.bundleEntityId) < 3) &&
									<Checkbox
										icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
										checkedIcon={<CheckBoxIcon fontSize="large" />}
										indeterminateIcon={<IndeterminateCheckBoxIcon fontSize="large" />}
										indeterminate={container.state === 0 && !getCurrentBundleState(container.bundleEntityId)}
										checked={container.state === 1 && !getCurrentBundleState(container.bundleEntityId) || getCurrentBundleState(container.bundleEntityId) === 1}
										color="primary"
										onChange={handleBundleStateChange.bind(this, container.bundleEntityId)} />}
								{(container.state === 3 && !getCurrentBundleState(container.bundleEntityId) || getCurrentBundleState(container.bundleEntityId) === 3) &&

									<HighlightOffIcon
										fontSize='large'
										style={{ padding: '0' }} />}
							</Grid>

							<Grid container style={{ alignItems: 'center', flex: 0.95, backgroundColor: getBundleBackgroundColor(getCurrentBundleState(container.bundleEntityId) || container.state), padding: '0 2%', marginBottom: '20px' }}>
								<Grid container alignItems="center">
									<Grid item style={{ flex: 1 }}>
										<Grid container alignItems="center">
											<Grid item style={{ flex: 0.02 }}>
												<GroupWorkIcon
													style={{ color: theme.palette.primary.A800, paddingTop: '5px' }}
												/>
											</Grid>
											<Grid item style={{ flex: 0.73 }}>
												<Typography style={{ color: theme.palette.primary.A800, marginLeft: '10px' }}>
													Start Scoring Group ({container.bundleName})
												</Typography>
											</Grid>
											<Grid item style={{ display: 'flex', alignItems: 'flex-start', flexWrap: 'nowrap', flex: 0.25, justifyContent: 'flex-end' }}>
												<Link
													to={`/relatedEntities?bundleId=${container.bundleEntityId}`}
													style={{
														textDecoration: 'none',

														fontSize: '18px',
														color: 'black'
													}}
												>
													<Typography
														style={{
															fontSize: '13px',
															lineHeight: '25px',
															letterSpacing: '0.25px',
															color: '#011020',
															marginLeft: '18px'
														}}
													>
														{intl.formatMessage({ id: 'app.survey.usageStats' }).replace('%%ReferenceCount%%', container.referencesCount).replace('%%CasesCount%%', container.casesCount)}
													</Typography>
												</Link>
												<Grid item style={{ margin: '-7px 0 0 15px' }}>
													<QuestionControlMenu
														question={container}
														surveyState={survey.state}
														archiveQuestion={archiveQuestion}
														getCurrentQuestionState={getCurrentQuestionState}
														archiveBundle={archiveBundle}
														getCurrentBundleState={getCurrentBundleState}
														addCondition={editBundleCondition}
														changeIsQuestionOptional={changeIsQuestionOptional}
														changeIsBundleOptional={changeIsBundleOptional}
														getCurrentQuestionIsOptionalOverride={getCurrentQuestionIsOptionalOverride}
														getCurrentBundleIsOptionalOverride={getCurrentBundleIsOptionalOverride}
														changeIsQuestionBilateral={changeIsQuestionBilateral}
														changeIsBundleBilateral={changeIsBundleBilateral}
														getCurrentBundleIsBilateral={getCurrentBundleIsBilateral}
														getCurrentQuestionIsBilateral={getCurrentQuestionIsBilateral}
														canChangeBilateral={protocol && protocol.isBilateral}
													/>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>

								{protocol && protocol.isBilateral && <Grid item style={{ flex: 1, paddingBottom: '10px' }}>
									<Chip
										style={{
											fontSize: '14px'
										}}
										label={intl.formatMessage(
											{
												id: container.isBilateral && getCurrentBundleIsBilateral(container.bundleEntityId) === undefined
													|| getCurrentBundleIsBilateral(container.bundleEntityId) ? 'app.survey.questionIsBilateralLabel' : 'app.survey.questionIsNotBilateralLabel'
											}
										)}
										color="primary"
										variant="outlined" />
								</Grid>}
							</Grid>

						</Grid>
						{
							renderRuleDescription(_surveyEntities, container.questions[0])
						}
						{
							container.questions.map((q, ind) => {
								return renderQuestion(q, ind)
							})
						}
						<Grid container direction="row" justify="flex-start">
							<Grid container style={{ alignItems: 'flex-start', flex: 0.05, marginTop: '-9px' }}></Grid>
							<Grid
								item
								style={{
									backgroundColor: getBundleBackgroundColor(getCurrentBundleState(container.bundleEntityId) || container.state),
									padding: '0 2%',
									minHeight: '40px',
									flex: 0.95,
								}}
							>
								<Grid container alignItems="center" style={{ marginTop: '7px' }}>
									<Grid item>
										<GroupWorkIcon style={{ color: theme.palette.primary.A800, paddingTop: '5px' }} />
									</Grid>
									<Grid item>
										<Typography style={{ color: theme.palette.primary.A800, marginLeft: '10px' }}>
											End Scoring Group
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</div>
				</Fragment>)
		}
		else {
			return container.questions.map((q, ind) => {
				return renderQuestion(q, ind)
			})
		}
	}

	const itemsPerPage = 100
	const [itemOffset, setItemOffset] = useState(0)
	const [currentPage, setCurrentPage] = useState(1)

	const endOffset = itemOffset + itemsPerPage
	const currentItems = questions?.slice(itemOffset, endOffset)
	const pageCount = Math.ceil(questions?.length / itemsPerPage)

	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % questions?.length
		setCurrentPage(event.selected)

		setItemOffset(newOffset)
	}

	return (
		<Fragment>
			{
				!questionsLoading
				&& !!surveyInstance
				&& questions.length > 0
				&& <Grid container direction="column">
					<Grid
						item
						xs={12}
					>
						<Grid
							container
							justify="space-between"
							alignItems="flex-end"
							style={{ paddingLeft: '2%', minHeight: '81px', borderBottom: 'solid 1px rgba(0,0,0,0.3)', paddingBottom: '10px' }}
						>
							<Grid item style={{flex: 0.25, paddingBottom: '10px'}}>
								<Typography
									style={{
										fontSize: '25px',
										lineHeight: '30px',
										letterSpacing: '0.25px',
										color: '#011020',
										fontWeight: '700',
										marginLeft: '18px'
									}}
								>
									Survey Content
								</Typography>
							</Grid>
							<Grid item style={{flex: 0.2, paddingBottom: '10px'}}>
								<Link
									to={`/relatedEntities?surveyId=${survey.surveyId}`}
									style={{
										textDecoration: 'none',

										fontSize: '18px',
										color: 'black'
									}}
								>
									<Typography
										style={{
											fontSize: '15px',
											lineHeight: '25px',
											letterSpacing: '0.25px',
											color: '#011020',
											marginLeft: '18px',
											textDecoration: 'underline'
										}}
									>
										{intl.formatMessage({id: 'app.survey.usageStats'}).replace('%%ReferenceCount%%', usageData?.referencesCount || 0).replace('%%CasesCount%%', usageData?.casesCount || 0)}
									</Typography>
								</Link>
							</Grid>
							<Grid item style={{flex: 0.55, display: 'flex', justifyContent: 'flex-end'}}>
								<Grid
									container
									justify='flex-end'
									alignItems='flex-end'
								>
									<Grid
										item
										
									>
										<Grid
											container
											direction='column'
											justify='flex-end'
										>
											<Grid item>
												<Button
													variant="contained"
													color="primary"
													onClick={handleCopySurveyQuestionRules}
													style={{
														width: '150px'
													}}
												>
													{intl.formatMessage({ id: 'app.general.copyLayout' })}
												</Button>
											</Grid>
											<Grid item>
												<Button
													variant="contained"
													color="primary"
													onClick={handleShowSimulation}
													style={{
														width: '150px',
														paddingRight: '10px'
													}}
												>
													{intl.formatMessage({ id: 'app.general.startSimulateButtonText' })}
												</Button>
											</Grid>
										</Grid>
									</Grid>
									<Grid item>
										<Button
											variant="contained"
											color="primary"
											disabled={!anyChanges}
											onClick={handleSaveStateClick}
											style={{
												width: '100px'
											}}
										>
											{intl.formatMessage({ id: 'app.general.saveChangesButtonText' })}
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<StyledPaginate
								nextLabel="next >"
								onPageChange={handlePageClick}
								pageRangeDisplayed={3}
								marginPagesDisplayed={2}
								pageCount={pageCount > 1 ? pageCount : 0}
								previousLabel="< previous"
								breakLabel="..."
								initialPage={Math.ceil(itemOffset / itemsPerPage)}
								renderOnZeroPageCount={null}
								forcePage={currentPage}
							/>
						{
							currentItems.map((container) => {
								return (
									<Fragment key={`bundle_or_question_${container.baseId}_${container.questionType}`}>
										{
											renderContainer(container)
										}
									</Fragment>
								)
							})
						}
						<StyledPaginate
								nextLabel="next >"
								onPageChange={handlePageClick}
								pageRangeDisplayed={3}
								marginPagesDisplayed={2}
								pageCount={pageCount > 1 ? pageCount : 0}
								previousLabel="< previous"
								breakLabel="..."
								initialPage={Math.ceil(itemOffset / itemsPerPage)}
								renderOnZeroPageCount={null}
								forcePage={currentPage}
							/>
					</Grid>
				</Grid>
			}
			{
				<RuleHelperSnackbar
					open={hasOpenedConditionsRow(_surveyEntities)}
				/>
			}
			{
				!!questionsLoading
				&& <Grid
					container
					justify="center"
            		alignContent="center"
					style={{ margin: '30px' }}
				>
					<CircularProgress />
				</Grid>
			}
		</Fragment >
	)
}

const mapStateToProps = (state) => ({
	questionsLoading: surveyQuestionsLoadingSelector(state),
	questions: readonlySurveyQuestionListByVersionSelector(state),
	survey: surveyInstanceSelector(state),
	usageData: surveyUsageDataSelector(state),
	protocol: protocolSelector(state)
})

const mapDispatchToProps = (dispatch) => ({
	loadSurvey: (id, protocolSurveyId, versionId) => dispatch(fetchReadOnlySurveyByVersion(id, protocolSurveyId, versionId)),
	saveSurveyQuestionState: (
		protocolId,
		surveyId,
		protocolSurveyId,
		versionId,
		questionStateList,
		bundleStateList,
		answerStateList,
		commentsObj
	) => dispatch(saveSurveyQuestionState(
		protocolId,
		surveyId,
		protocolSurveyId,
		versionId,
		questionStateList,
		bundleStateList,
		answerStateList,
		commentsObj
	)),
	fetchSurveyUsageData: (id, protocolSurveyId, versionId) => dispatch(fetchSurveyUsageData(id, protocolSurveyId, versionId)),
	showValidationError: (message) => dispatch(setSnackbarMessage(message, false)),
	showDialog: (dialogComponent, options) => dispatch(showModal(dialogComponent, options))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(SurveyViewerContainer)
