import React, { useState } from 'react'
import { FormattedDate, injectIntl } from 'react-intl'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import Menu from './Menu'
import { Grid } from '@material-ui/core'

const SubscriberRow = ({ sponsor, intl, showResetButton }) => {
	return (
		<TableRow
			key={sponsor.id}
			style={{
				backgroundColor: sponsor.isArchived ? '#F6F6F6' : 'white'
			}}
		>
			<TableCell align="left" size="medium" style={{ position: 'relative', padding: '0' }}>
				<div style={{
						backgroundColor: `${sponsor && sponsor.isArchived ? '#bbb' : '#007aff'}`,
						width: '4px',
						height: 'calc(100% - 16px)',
						position: 'absolute',
						left: 0,
						top: '8px'
				}}></div>
				<div style={{ marginLeft: '15px' }}>
				<Typography
                    variant="body2"
                    style={{
						display: 'block',
						fontWeight: '700',
						fontSize: '17px',
						lineHeight: '20px',
						letterSpacing: '0.16px',
						color: '#011020',
						marginBottom: '5px', 
						wordBreak: 'break-word'
					}}
                >
                    {sponsor.name}
                </Typography>
				</div>
			</TableCell>
            <TableCell align="left" size="small" style={{ wordBreak: 'break-all' }}>
                <Typography
                    variant="body2"
                    style={{
						textAlign: 'left',
						fontWeight: '400',
						fontSize: '17px',
						lineHeight: '22px',
						letterSpacing: '0.51px',
					}}
                >
                    {sponsor.stripeCustomerId}
                </Typography>
			</TableCell>
            <TableCell align="left" size="small" style={{ wordBreak: 'break-all' }}>
                <Typography
                    variant="body2"
                    style={{
						textAlign: 'left',
						fontWeight: '400',
						fontSize: '17px',
						lineHeight: '22px',
						letterSpacing: '0.51px'
					}}
                >
                    {sponsor.stripeSubscriptionId}
                </Typography>
			</TableCell>
            <TableCell align="left" size="small">
                <Typography
                    variant="body2"
                    style={{
						textAlign: 'left',
						fontWeight: '400',
						fontSize: '17px',
						lineHeight: '22px',
						letterSpacing: '0.51px'
					}}
                >
                    { intl.formatMessage({ id: sponsor.stripeAccount === 2 ? 'app.sponsors.stripeAccountEurope' : 'app.sponsors.stripeAccountMain' })}
                </Typography>
			</TableCell>
            <TableCell align="left" size="small">
                <Typography
                    variant="body2"
                    style={{
						textAlign: 'left',
						fontWeight: '400',
						fontSize: '17px',
						lineHeight: '22px',
						letterSpacing: '0.51px'
					}}
                >
                    {sponsor.sponsoredCirclesCount}
                </Typography>
			</TableCell>
            <TableCell align="left" size="small">
                <Typography
                    variant="body2"
                    style={{
						textAlign: 'left',
						fontWeight: '400',
						fontSize: '17px',
						lineHeight: '22px',
						letterSpacing: '0.51px'
					}}
                >
                    {sponsor.sponsoredUsersCount}
                </Typography>
			</TableCell>
			<TableCell align="left" size="small">
                <Typography
                    variant="body2"
                    style={{
						textAlign: 'left',
						fontWeight: '400',
						fontSize: '17px',
						lineHeight: '22px',
						letterSpacing: '0.51px'
					}}
                >
                    {sponsor.sponsoredCasesCount}
                </Typography>
			</TableCell>
			<TableCell align="left" size="small">
				<Grid container justify="flex-start" direction="column">
					<Grid item>
						<Typography variant="body2" style={{ textAlign: 'left', fontWeight: '400' }}>
							{sponsor.modifiedDate && <FormattedDate value={sponsor.modifiedDate} />}
						</Typography>
					</Grid>
					{sponsor.modifiedByName && (
							<Grid item>
								<Typography
									variant="body2"
									style={{ textAlign: 'left', fontWeight: '400', wordBreak: 'break-word' }}
								>
									{sponsor.modifiedByName}
								</Typography>
							</Grid>
						)}
				</Grid>
			</TableCell>
			<TableCell align="left" size="small">
				<Typography
					variant="body2"
					style={{
						textAlign: 'left',
						fontWeight: '400',
						fontSize: '17px',
						lineHeight: '22px',
						letterSpacing: '0.51px',
						maxWidth: '100px'
					}}
				>
					{sponsor && intl.formatMessage({ id: sponsor.isArchived ? 'app.sponsors.archivedStatusLabel' : 'app.sponsors.activeStatusLabel' })}
				</Typography>
			</TableCell>
			<TableCell align="left" size="small">
				<Typography
					variant="body2"
					style={{
						textAlign: 'left',
						fontWeight: '400',
						fontSize: '17px',
						lineHeight: '22px',
						letterSpacing: '0.51px',
						maxWidth: '100px'
					}}
				>
					{sponsor && intl.formatMessage({ id: sponsor.isExecutiveUsage ? 'app.sponsors.executiveUsageUseLabel' : 'app.sponsors.executiveUsageNoLabel' })}
				</Typography>
			</TableCell>
			<TableCell align="right" size="small" style={{ padding: '5px 0' }}>
				{sponsor && <Menu sponsor={sponsor} showResetButton={showResetButton} />}
			</TableCell>
		</TableRow>
	)
}

export default injectIntl(SubscriberRow)
