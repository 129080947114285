import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import Avatar from '@material-ui/core/Avatar'

const NumberedRowTwoColumns = ({ number, labelFirst, labelSecond }) => {
	return (
		<Grid container direction="column">
			<Grid item style={{marginTop: '-10px'}}>
				<Grid container direction="row" alignItems="center">
					<Grid item style={{ marginLeft: '-7px' }}>
						<Avatar
							style={{
								width: 20,
								height: 20,
								color: 'black',
								backgroundColor: '#7AB5E0'
							}}
						>
							{number}
						</Avatar>
					</Grid>
					<Grid item style={{ paddingLeft: '10px', width: '100%', flex: 1}}>
						<Grid container direction="row" alignItems="baseline" justify="flex-start" spacing={2} style={{width: '100%', margin: '0'}}>
							<Grid item style={{ flex: 0.5, width: '100%', paddingLeft: '0' }}>
								<Typography
									style={{
										color: '#fff',
										fontWeight: '400',
										fontSize: '22px'
									}}
									align="left"
								>
									{labelFirst}
								</Typography>
							</Grid>
							<Grid item style={{ paddingLeft: '18px', flex: 0.5, width: '100%' }}>
								<Typography
									style={{
										color: '#fff',
										fontWeight: '400',
										fontSize: '22px'
									}}
									align="left"
								>
									{labelSecond}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

const enhance = compose(injectIntl)

export default enhance(NumberedRowTwoColumns)