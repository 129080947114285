import React, { Fragment, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import QuestionContainer from './QuestionContainer'
import TextQuestion from '../../components/survey/components/TextQuestion'
import FileQuestion from '../../components/survey/components/FileQuestion'
import TFQuestion from '../../components/survey/components/TFQuestion'
import MultiSelectQuestion from '../../components/survey/components/MultiSelectQuestion'
import TextBirthDateQuestion from '../../components/survey/components/TextBirthDateQuestion'
import ReadonlyTextBlock from '../../components/survey/components/ReadonlyTextBlock'
import RadioSelectQuestion from '../../components/survey/components/RadioSelectQuestion'
import NumberQuestion from '../../components/survey/components/NumberQuestion'
import DateQuestion from '../../components/survey/components/DateQuestion'
import GroupedQuestion from '../../components/survey/components/GroupedQuestion'
import AnalogQuestion from '../survey/components/AnalogQuestion'
import ReactPaginate from 'react-paginate'
import styled from 'styled-components'

const StyledPaginate = styled(ReactPaginate).attrs({
	activeClassName: 'active',
  })`
	margin-bottom: 2rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	list-style-type: none;
	padding: 0 5rem;
  
	li a {
	  border-radius: 7px;
	  padding: 0.1rem 1rem;
	  border: gray 1px solid;
	  cursor: pointer;
	}
	li.previous a,
	li.next a,
	li.break a {
	  border-color: transparent;
	}
	li.active a {
	  background-color: #0366d6;
	  border-color: transparent;
	  color: white;
	  min-width: 32px;
	}
	li.disabled a {
	  color: grey;
	}
	li.disable,
	li.disabled a {
	  cursor: default;
	}
  `

const renderQuestions = (
	collection,
	list,
	onAddCallback,
	onAddExistingCallback,
	onAddBundleCallback,
	onSwapCallback,
	onRemoveCallback,
	onUpdateCallback,
	onStartUpdatingQuestionCallback,
	ignoreBundles = false,
	suppressNonDiscreteQuestions = false,
	showScoreEntry,
	searchType,
	isBundle,
	propertyStateName,
	onChangeBundleStateCallback,
	onChangeQuestionStateCallback,
	sameStateAllEntities,
	canSetInUseState,
	canSetInUseStateAllEntities,
	canSetNotInUseState,
	canSetNotInUseStateAllEntities,
	canSetArchiveState,
	canSetArchiveStateAllEntities,
	isOptionalDisabled,
	canCloneQuestion
) => {
	if (list) {
		var rendered = new Array()
		var counter = 0
		var displayCount = 0

		collection.map((node, i) => {
			counter++
			if (node.data.questionType !== 8) {
				displayCount++
			}

			const key = `${node.data.questionType}_${node.data.id}_${counter}`

			switch (node.data.questionType) {
				case 6:
					rendered.push(
						<QuestionContainer
							key={key}
							node={node}
							position={counter}
							list={list}
							onAddCallback={onAddCallback}
							onAddExistingCallback={onAddExistingCallback}
							onAddBundleCallback={onAddBundleCallback}
							onSwapCallback={onSwapCallback}
							onRemoveCallback={onRemoveCallback}
							onUpdateCallback={onUpdateCallback}
							ignoreBundles={ignoreBundles}
							onStartUpdatingQuestionCallback={onStartUpdatingQuestionCallback}
							suppressNonDiscreteQuestions={suppressNonDiscreteQuestions}
							showScoreEntry={showScoreEntry}
							searchType={searchType}
							isBundle={isBundle}
							totalQuestions={collection.length}
							propertyStateName={propertyStateName}
							onChangeBundleStateCallback={onChangeBundleStateCallback}
							onChangeQuestionStateCallback={onChangeQuestionStateCallback}
							sameStateAllEntities={sameStateAllEntities}
							canSetInUseState={canSetInUseState}
							canSetInUseStateAllEntities={canSetInUseStateAllEntities}
							canSetNotInUseState={canSetNotInUseState}
							canSetNotInUseStateAllEntities={canSetNotInUseStateAllEntities}
							canSetArchiveState={canSetArchiveState}
							canSetArchiveStateAllEntities={canSetArchiveStateAllEntities}
							isOptionalDisabled={isOptionalDisabled}
							canCloneQuestion={canCloneQuestion}
						>
							<NumberQuestion
								question={node.data}
								position={counter}
								key={node.data.id}
								positionDisplay={displayCount}
							/>
						</QuestionContainer>
					)
					break
				case 5:
					rendered.push(
						<QuestionContainer
							key={key}
							node={node}
							position={counter}
							list={list}
							onAddCallback={onAddCallback}
							onAddExistingCallback={onAddExistingCallback}
							onAddBundleCallback={onAddBundleCallback}
							onSwapCallback={onSwapCallback}
							onRemoveCallback={onRemoveCallback}
							onUpdateCallback={onUpdateCallback}
							ignoreBundles={ignoreBundles}
							onStartUpdatingQuestionCallback={onStartUpdatingQuestionCallback}
							suppressNonDiscreteQuestions={suppressNonDiscreteQuestions}
							showScoreEntry={showScoreEntry}
							searchType={searchType}
							isBundle={isBundle}
							totalQuestions={collection.length}
							propertyStateName={propertyStateName}
							onChangeBundleStateCallback={onChangeBundleStateCallback}
							onChangeQuestionStateCallback={onChangeQuestionStateCallback}
							sameStateAllEntities={sameStateAllEntities}
							canSetInUseState={canSetInUseState}
							canSetInUseStateAllEntities={canSetInUseStateAllEntities}
							canSetNotInUseState={canSetNotInUseState}
							canSetNotInUseStateAllEntities={canSetNotInUseStateAllEntities}
							canSetArchiveState={canSetArchiveState}
							canSetArchiveStateAllEntities={canSetArchiveStateAllEntities}
							isOptionalDisabled={isOptionalDisabled}
							canCloneQuestion={canCloneQuestion}
						>
							<TextQuestion
								question={node.data}
								position={counter}
								key={node.data.id}
								positionDisplay={displayCount}
							/>
						</QuestionContainer>
					)
					break
				// case 4:
				// 	rendered.push(
				// 		<QuestionContainer
				// 			node={node}
				// 			position={counter}
				// 			list={list}
				// 			key={node.data.id}
				// 			onAddCallback={onAddCallback}
				// 			onAddExistingCallback={onAddExistingCallback}
				// 			onAddBundleCallback={onAddBundleCallback}
				// 			onSwapCallback={onSwapCallback}
				// 			onRemoveCallback={onRemoveCallback}
				// 			onUpdateCallback={onUpdateCallback}
				// 			ignoreBundles={ignoreBundles}
				// 			onStartUpdatingQuestionCallback={onStartUpdatingQuestionCallback}
				// 			suppressNonDiscreteQuestions={suppressNonDiscreteQuestions}
				// 			showScoreEntry={showScoreEntry}
				// 		>
				// 			<TFQuestion
				// 				question={node.data}
				// 				position={counter}
				// 				key={node.data.id}
				// 				positionDisplay={displayCount}
				// 			/>
				// 		</QuestionContainer>
				// 	)
				// 	break
				case 7:
					rendered.push(
						<QuestionContainer
							key={key}
							node={node}
							position={counter}
							list={list}
							onAddCallback={onAddCallback}
							onAddExistingCallback={onAddExistingCallback}
							onAddBundleCallback={onAddBundleCallback}
							onSwapCallback={onSwapCallback}
							onRemoveCallback={onRemoveCallback}
							onUpdateCallback={onUpdateCallback}
							ignoreBundles={ignoreBundles}
							onStartUpdatingQuestionCallback={onStartUpdatingQuestionCallback}
							suppressNonDiscreteQuestions={suppressNonDiscreteQuestions}
							showScoreEntry={showScoreEntry}
							searchType={searchType}
							isBundle={isBundle}
							totalQuestions={collection.length}
							propertyStateName={propertyStateName}
							onChangeBundleStateCallback={onChangeBundleStateCallback}
							onChangeQuestionStateCallback={onChangeQuestionStateCallback}
							sameStateAllEntities={sameStateAllEntities}
							canSetInUseState={canSetInUseState}
							canSetInUseStateAllEntities={canSetInUseStateAllEntities}
							canSetNotInUseState={canSetNotInUseState}
							canSetNotInUseStateAllEntities={canSetNotInUseStateAllEntities}
							canSetArchiveState={canSetArchiveState}
							canSetArchiveStateAllEntities={canSetArchiveStateAllEntities}
							isOptionalDisabled={isOptionalDisabled}
							canCloneQuestion={canCloneQuestion}
						>
							<DateQuestion
								question={node.data}
								position={counter}
								key={node.data.id}
								positionDisplay={displayCount}
							/>
						</QuestionContainer>
					)
					break
				case 2:
					rendered.push(
						<QuestionContainer
							key={key}
							node={node}
							position={counter}
							list={list}
							onAddCallback={onAddCallback}
							onAddExistingCallback={onAddExistingCallback}
							onAddBundleCallback={onAddBundleCallback}
							onSwapCallback={onSwapCallback}
							onRemoveCallback={onRemoveCallback}
							onUpdateCallback={onUpdateCallback}
							ignoreBundles={ignoreBundles}
							onStartUpdatingQuestionCallback={onStartUpdatingQuestionCallback}
							suppressNonDiscreteQuestions={suppressNonDiscreteQuestions}
							showScoreEntry={showScoreEntry}
							searchType={searchType}
							isBundle={isBundle}
							totalQuestions={collection.length}
							propertyStateName={propertyStateName}
							onChangeBundleStateCallback={onChangeBundleStateCallback}
							onChangeQuestionStateCallback={onChangeQuestionStateCallback}
							canSetInUseState={canSetInUseState}
							sameStateAllEntities={sameStateAllEntities}
							canSetInUseStateAllEntities={canSetInUseStateAllEntities}
							canSetNotInUseState={canSetNotInUseState}
							canSetNotInUseStateAllEntities={canSetNotInUseStateAllEntities}
							canSetArchiveState={canSetArchiveState}
							canSetArchiveStateAllEntities={canSetArchiveStateAllEntities}
							isOptionalDisabled={isOptionalDisabled}
							canCloneQuestion={canCloneQuestion}
						>
							<MultiSelectQuestion
								question={node.data}
								position={counter}
								key={node.data.id}
								positionDisplay={displayCount}
								showScoreEntry={showScoreEntry}
							/>
						</QuestionContainer>
					)
					break
				case 8:
					rendered.push(
						<QuestionContainer
							key={key}
							node={node}
							position={counter}
							list={list}
							onAddCallback={onAddCallback}
							onAddExistingCallback={onAddExistingCallback}
							onAddBundleCallback={onAddBundleCallback}
							onSwapCallback={onSwapCallback}
							onRemoveCallback={onRemoveCallback}
							onUpdateCallback={onUpdateCallback}
							ignoreBundles={ignoreBundles}
							onStartUpdatingQuestionCallback={onStartUpdatingQuestionCallback}
							suppressNonDiscreteQuestions={suppressNonDiscreteQuestions}
							showScoreEntry={showScoreEntry}
							searchType={searchType}
							isBundle={isBundle}
							totalQuestions={collection.length}
							propertyStateName={propertyStateName}
							onChangeBundleStateCallback={onChangeBundleStateCallback}
							onChangeQuestionStateCallback={onChangeQuestionStateCallback}
							sameStateAllEntities={sameStateAllEntities}
							canSetInUseState={canSetInUseState}
							canSetInUseStateAllEntities={canSetInUseStateAllEntities}
							canSetNotInUseState={canSetNotInUseState}
							canSetNotInUseStateAllEntities={canSetNotInUseStateAllEntities}
							canSetArchiveState={canSetArchiveState}
							canSetArchiveStateAllEntities={canSetArchiveStateAllEntities}
							isOptionalDisabled={isOptionalDisabled}
							canCloneQuestion={canCloneQuestion}
						>
							<ReadonlyTextBlock
								question={node.data}
								position={counter}
								key={node.data.id}
								positionDisplay={displayCount}
							/>
						</QuestionContainer>
					)
					break
				case 1:
					rendered.push(
						<QuestionContainer
							key={key}
							node={node}
							position={counter}
							list={list}
							onAddCallback={onAddCallback}
							onAddExistingCallback={onAddExistingCallback}
							onAddBundleCallback={onAddBundleCallback}
							onSwapCallback={onSwapCallback}
							onRemoveCallback={onRemoveCallback}
							onUpdateCallback={onUpdateCallback}
							ignoreBundles={ignoreBundles}
							onStartUpdatingQuestionCallback={onStartUpdatingQuestionCallback}
							suppressNonDiscreteQuestions={suppressNonDiscreteQuestions}
							showScoreEntry={showScoreEntry}
							searchType={searchType}
							isBundle={isBundle}
							totalQuestions={collection.length}
							propertyStateName={propertyStateName}
							onChangeBundleStateCallback={onChangeBundleStateCallback}
							onChangeQuestionStateCallback={onChangeQuestionStateCallback}
							sameStateAllEntities={sameStateAllEntities}
							canSetInUseState={canSetInUseState}
							canSetInUseStateAllEntities={canSetInUseStateAllEntities}
							canSetNotInUseState={canSetNotInUseState}
							canSetNotInUseStateAllEntities={canSetNotInUseStateAllEntities}
							canSetArchiveState={canSetArchiveState}
							canSetArchiveStateAllEntities={canSetArchiveStateAllEntities}
							isOptionalDisabled={isOptionalDisabled}
							canCloneQuestion={canCloneQuestion}
						>
							<RadioSelectQuestion
								question={node.data}
								position={counter}
								key={node.data.id}
								positionDisplay={displayCount}
								showScoreEntry={showScoreEntry}
							/>
						</QuestionContainer>
					)
					break
				case 3:
					rendered.push(
						<QuestionContainer
							key={key}
							position={counter}
							node={node}
							list={list}
							onAddCallback={onAddCallback}
							onAddExistingCallback={onAddExistingCallback}
							onAddBundleCallback={onAddBundleCallback}
							onSwapCallback={onSwapCallback}
							onRemoveCallback={onRemoveCallback}
							onUpdateCallback={onUpdateCallback}
							ignoreBundles={ignoreBundles}
							onStartUpdatingQuestionCallback={onStartUpdatingQuestionCallback}
							suppressNonDiscreteQuestions={suppressNonDiscreteQuestions}
							showScoreEntry={showScoreEntry}
							searchType={searchType}
							isBundle={isBundle}
							totalQuestions={collection.length}
							propertyStateName={propertyStateName}
							onChangeBundleStateCallback={onChangeBundleStateCallback}
							onChangeQuestionStateCallback={onChangeQuestionStateCallback}
							sameStateAllEntities={sameStateAllEntities}
							canSetInUseState={canSetInUseState}
							canSetInUseStateAllEntities={canSetInUseStateAllEntities}
							canSetNotInUseState={canSetNotInUseState}
							canSetNotInUseStateAllEntities={canSetNotInUseStateAllEntities}
							canSetArchiveState={canSetArchiveState}
							canSetArchiveStateAllEntities={canSetArchiveStateAllEntities}
							isOptionalDisabled={isOptionalDisabled}
							canCloneQuestion={canCloneQuestion}
						>
							<GroupedQuestion
								question={node.data}
								key={node.data.id}
								position={counter}
								positionDisplay={displayCount}
								showScoreEntry={showScoreEntry}
							/>
						</QuestionContainer>
					)
					break
				case 9:
					rendered.push(
						<QuestionContainer
							key={key}
							position={counter}
							node={node}
							list={list}
							onAddCallback={onAddCallback}
							onAddExistingCallback={onAddExistingCallback}
							onAddBundleCallback={onAddBundleCallback}
							onSwapCallback={onSwapCallback}
							onRemoveCallback={onRemoveCallback}
							onUpdateCallback={onUpdateCallback}
							ignoreBundles={ignoreBundles}
							onStartUpdatingQuestionCallback={onStartUpdatingQuestionCallback}
							suppressNonDiscreteQuestions={suppressNonDiscreteQuestions}
							showScoreEntry={showScoreEntry}
							searchType={searchType}
							isBundle={isBundle}
							totalQuestions={collection.length}
							propertyStateName={propertyStateName}
							onChangeBundleStateCallback={onChangeBundleStateCallback}
							onChangeQuestionStateCallback={onChangeQuestionStateCallback}
							sameStateAllEntities={sameStateAllEntities}
							canSetInUseState={canSetInUseState}
							canSetInUseStateAllEntities={canSetInUseStateAllEntities}
							canSetNotInUseState={canSetNotInUseState}
							canSetNotInUseStateAllEntities={canSetNotInUseStateAllEntities}
							canSetArchiveState={canSetArchiveState}
							canSetArchiveStateAllEntities={canSetArchiveStateAllEntities}
							isOptionalDisabled={isOptionalDisabled}
							canCloneQuestion={canCloneQuestion}
						>
							<AnalogQuestion
								question={node.data}
								key={node.data.id}
								position={counter}
								positionDisplay={displayCount}
							/>
						</QuestionContainer>
					)
					break
				case 10: case 11:
					rendered.push(
						<QuestionContainer
							key={key}
							node={node}
							position={counter}
							list={list}
							onAddCallback={onAddCallback}
							onAddExistingCallback={onAddExistingCallback}
							onAddBundleCallback={onAddBundleCallback}
							onSwapCallback={onSwapCallback}
							onRemoveCallback={onRemoveCallback}
							onUpdateCallback={onUpdateCallback}
							ignoreBundles={ignoreBundles}
							onStartUpdatingQuestionCallback={onStartUpdatingQuestionCallback}
							suppressNonDiscreteQuestions={suppressNonDiscreteQuestions}
							showScoreEntry={showScoreEntry}
							searchType={searchType}
							isBundle={isBundle}
							totalQuestions={collection.length}
							propertyStateName={propertyStateName}
							onChangeBundleStateCallback={onChangeBundleStateCallback}
							onChangeQuestionStateCallback={onChangeQuestionStateCallback}
							sameStateAllEntities={sameStateAllEntities}
							canSetInUseState={canSetInUseState}
							canSetInUseStateAllEntities={canSetInUseStateAllEntities}
							canSetNotInUseState={canSetNotInUseState}
							canSetNotInUseStateAllEntities={canSetNotInUseStateAllEntities}
							canSetArchiveState={canSetArchiveState}
							canSetArchiveStateAllEntities={canSetArchiveStateAllEntities}
							isOptionalDisabled={isOptionalDisabled}
							canCloneQuestion={canCloneQuestion}
						>
							<FileQuestion
								question={node.data}
								position={counter}
								key={node.data.id}
								positionDisplay={displayCount}
								disabled={true}
							/>
						</QuestionContainer>
					)
					break
			}
		})
		return rendered
	}
	return new Array(0)
}

const BuilderContainer = ({
	headerTitle,
	onAddCallback,
	onAddExistingCallback,
	onAddBundleCallback,
	onSwapCallback,
	onRemoveCallback,
	onUpdateCallback,
	onStartUpdatingQuestionCallback,
	ignoreBundles,
	list,
	collection,
	suppressNonDiscreteQuestions = false,
	showPreview = true,
	showScoreEntry,
	searchType,
	isBundle,
	propertyStateName,
	onChangeBundleStateCallback,
	onChangeQuestionStateCallback,
	sameStateAllEntities,
	canSetInUseState,
	canSetInUseStateAllEntities,
	canSetNotInUseState,
	canSetNotInUseStateAllEntities,
	canSetArchiveState,
	canSetArchiveStateAllEntities,
	isOptionalDisabled = false,
	canCloneQuestion = true
}) => {

	const itemsPerPage = 100
	const [itemOffset, setItemOffset] = useState(0)
	const [currentPage, setCurrentPage] = useState(1)

	const endOffset = itemOffset + itemsPerPage
	const currentItems = collection?.slice(itemOffset, endOffset)
	const pageCount = Math.ceil(collection?.length / itemsPerPage)

	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % collection?.length
		setCurrentPage(event.selected)

		setItemOffset(newOffset)
	}

	return (
		<Fragment>
			<Grid container direction="column">
				{
					!!headerTitle
					&& <Grid item>
						<Grid container justify="space-between" alignItems="center">
							<Grid item>
								<Typography variant="h6">
									{headerTitle}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				}
				
				<Grid item>
					{collection && collection.length > 0 ? (
						<div>
							<StyledPaginate
								nextLabel="next >"
								onPageChange={handlePageClick}
								pageRangeDisplayed={3}
								marginPagesDisplayed={2}
								pageCount={pageCount > 1 ? pageCount : 0}
								previousLabel="< previous"
								breakLabel="..."
								initialPage={Math.ceil(itemOffset / itemsPerPage)}
								renderOnZeroPageCount={null}
								forcePage={currentPage}
							/>
							{renderQuestions(
								currentItems,
								list,
								onAddCallback,
								onAddExistingCallback,
								onAddBundleCallback,
								onSwapCallback,
								onRemoveCallback,
								onUpdateCallback,
								onStartUpdatingQuestionCallback,
								suppressNonDiscreteQuestions,
								ignoreBundles,
								showScoreEntry,
								searchType,
								isBundle,
								propertyStateName,
								onChangeBundleStateCallback,
								onChangeQuestionStateCallback,
								sameStateAllEntities,
								canSetInUseState,
								canSetInUseStateAllEntities,
								canSetNotInUseState,
								canSetNotInUseStateAllEntities,
								canSetArchiveState,
								canSetArchiveStateAllEntities,
								isOptionalDisabled,
								canCloneQuestion
							)}
							<StyledPaginate
								nextLabel="next >"
								onPageChange={handlePageClick}
								pageRangeDisplayed={3}
								marginPagesDisplayed={2}
								pageCount={pageCount > 1 ? pageCount : 0}
								previousLabel="< previous"
								breakLabel="..."
								initialPage={Math.ceil(itemOffset / itemsPerPage)}
								renderOnZeroPageCount={null}
								forcePage={currentPage}
							/>
						</div>
					) : (
						<QuestionContainer
							list={list}
							suppressNonDiscreteQuestions={suppressNonDiscreteQuestions}
							onAddCallback={onAddCallback}
							onAddExistingCallback={onAddExistingCallback}
							onAddBundleCallback={onAddBundleCallback}
							onSwapCallback={onSwapCallback}
							onUpdateCallback={onUpdateCallback}
							onRemoveCallback={onRemoveCallback}
							ignoreBundles={ignoreBundles}
							showScoreEntry={showScoreEntry}
							searchType={searchType}
							onStartUpdatingQuestionCallback={onStartUpdatingQuestionCallback}
						/>
					)}
				</Grid>
			</Grid>
		</Fragment>
	)
}

export default BuilderContainer
