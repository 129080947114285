import * as api from '../../../apis/question'
import * as bundleApi from '../../../apis/bundle'
import * as surveyApi from '../../../apis/survey'
import { createSelector } from 'reselect'
import { stopSaving } from '../../actions'

const UPDATE_SURVEY_QUESTION_REQUESTED = 'UPDATE_SURVEY_QUESTION_REQUESTED'
const UPDATE_SURVEY_QUESTION_SUCCESS = 'UPDATE_SURVEY_QUESTION_SUCCESS'
const UPDATE_SURVEY_QUESTION_FAILED = 'UPDATE_SURVEY_QUESTION_FAILED'

export const updateEntityQuestion = (question, languageId, onUpdateCallback, id, surveyId, archivedQuestionsVisible, commentsObj) => (dispatch, getState) => {
	dispatch({ type: UPDATE_SURVEY_QUESTION_REQUESTED, data: {} })

	const updateQuestion = !!surveyId
		? surveyApi.updateSurveyQuestion
		: bundleApi.updateBundleQuestion

	return updateQuestion(id, question, languageId, commentsObj).then((response) => {
		if (response && response.isSuccessful) {
			if (surveyId && question.hasOwnProperty('isOptional')) {
				api.setSurveyQuestionOptional(surveyId, question, commentsObj).then((response) => {
					if (onUpdateCallback) {
						dispatch(onUpdateCallback(id, () => dispatch(stopUpdatingQuestion), languageId, archivedQuestionsVisible))
					}
					dispatch({
						type: 'SET_SNACKBAR_MESSAGE',
						data: { message: 'app.general.successfullyUpdated', isSuccess: true }
					})
					return dispatch(stopSaving())
				})
			} else {
				if (onUpdateCallback) {
					dispatch(onUpdateCallback(id, () => dispatch(stopUpdatingQuestion), languageId, archivedQuestionsVisible))
				}
				dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.general.successfullyUpdated', isSuccess: true }
				})
				return dispatch(stopSaving())
			}
		} else {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.UpdateFailed', isSuccess: false }
			})
			return dispatch(stopSaving())
		}
	})
}

const SET_ANALOG_IMAGE_REQUESTED = 'SET_ANALOG_IMAGE_REQUESTED'
const SET_ANALOG_IMAGE_SUCCESS = 'SET_ANALOG_IMAGE_SUCESS'
const SET_ANALOG_IMAGE_FAILED = 'SET_ANALOG_IMAGE_FAILED'
export const setImage = (file) => (dispatch) => {
	dispatch({ type: SET_ANALOG_IMAGE_REQUESTED, data: {} })

	let formData = new FormData()
	formData.append('files', file)
	return api.setAnalogImage(formData).then((response) => {
		if (response) {
			// if (onImageUpdated) dispatch(onImageUpdated(id))
			return dispatch({ type: SET_ANALOG_IMAGE_SUCCESS, data: response })
		} else {
			return dispatch({ type: SET_ANALOG_IMAGE_FAILED, data: {} })
		}
	})
}

const START_UPDATING_QUESTION_REQUESTED = 'START_UPDATING_QUESTION_REQUESTED'
export const startUpdatingQuestion = (containerId) => (dispatch, getState) => {
	return dispatch({
		type: START_UPDATING_QUESTION_REQUESTED,
		data: {
			containerId: containerId
		}
	})
}

const STOP_UPDATING_QUESTION_REQUESTED = 'STOP_UPDATING_QUESTION_REQUESTED'
export const stopUpdatingQuestion = () => (dispatch, getState) => {
	return dispatch({ type: STOP_UPDATING_QUESTION_REQUESTED, data: {} })
}

const UPDATE_QUESTION_REQUESTED = 'UPDATE_QUESTION_REQUESTED'
const UPDATE_QUESTION_SUCCESS = 'UPDATE_QUESTION_SUCCESS'
const UPDATE_QUESTION_FAILED = 'UPDATE_QUESTION_FAILED'
export const updateQuestion = (question, languageId, commentsObj) => (dispatch) => {
	dispatch({ type: UPDATE_QUESTION_REQUESTED, data: {} })

	return api.updateQuestion(question, languageId, '/api/question/', commentsObj).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(stopUpdatingQuestion())

			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.successfullyUpdated', isSuccess: true }
			})
			
			dispatch(stopSaving())

			return true
		} else {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.UpdateFailed', isSuccess: false }
			})

			dispatch(stopSaving())

			return false
		}
	})
}

const initial = {
	isUpdatingQuestion: false,
	currentContainerId: null,
	surveyQuestionCloneId: null,
	bundleQuestionCloneId: null
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case START_UPDATING_QUESTION_REQUESTED:
			return {
				...state,
				isUpdatingQuestion: true,
				currentContainerId: action.data.containerId,
				surveyQuestionCloneId: action.data.surveyQuestionCloneId,
				bundleQuestionCloneId: action.data.bundleQuestionCloneId
			}
			break
		case STOP_UPDATING_QUESTION_REQUESTED:
			return {
				...state,
				isUpdatingQuestion: false,
				currentContainerId: null,
				surveyQuestionCloneId: null,
				bundleQuestionCloneId: null
			}
			break

		case 'SIGN_OUT_REQUESTED':
			return initial

		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.questionEditor

export const isUpdatingQuestionSelector = createSelector(mainSelector, (state) => {
	return state && state.isUpdatingQuestion
})

export const isUpdatingQuestionContainerIdSelector = createSelector(mainSelector, (state) => {
	return state && state.currentContainerId
})
