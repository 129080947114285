import React, { Fragment, useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/Search'
import { debounceMilliseconds, minimumSearchCharacters } from '../../../utilities/constants'
import { connect, useDispatch } from 'react-redux'
import { Grid, Switch, Typography, useTheme } from '@material-ui/core'
import { useConstant } from '../../../utilities/useConstant'
import { changeArchivedBundlesVisibility, fetchGroupBundles } from '../../../redux/actions'
import { showArchivedBundlesSelector } from '../../../redux/selectors'
var debounce = require('lodash/debounce')

const SearchQuestion = ({
	intl,
	groupId,
	isArchivedBundlesVisible,
	changeArchivedBundlesVisibility
}) => {
	const theme = useTheme()
	const dispatch = useDispatch()
	const [ searchPhrase, setSearchPhrase ] = useState('')


	useEffect(() => {}, [])


	const onChange = (value) => {
		setSearchPhrase(value)

		if (value && value.length >= minimumSearchCharacters) {
			dispatch(fetchGroupBundles(groupId, value, isArchivedBundlesVisible))
		}
		if (!value || value.length === 0) {
			dispatch(fetchGroupBundles(groupId, '', isArchivedBundlesVisible))
		}
	}

	const OnSearchChanged = useConstant(() => debounce(onChange, debounceMilliseconds, { leading: false }))

	const onChangeArchivedBundlesVisibility = (e) => {
		const visible = e.target.checked

		changeArchivedBundlesVisibility(visible)
		dispatch(fetchGroupBundles(groupId, searchPhrase, visible))
	}


	return (

		<Grid item style={{ backgroundColor: theme.palette.primary.A200, marginBottom: 0 }}>
			<Grid container style={{ minHeight: '100px', width: '100%', padding: '16px' }}>
				<Grid item style={{ flexGrow: '100', border: 'dashed 1px black', padding: '16px', borderRadius: '5px' }}>
					<FormControl variant="filled" fullWidth className="filled-form-control">
						<InputLabel>
							{intl.formatMessage({ id: 'app.sg.searchQuestion' })}
						</InputLabel>
						<FilledInput
							placeholder={intl.formatMessage({ id: 'app.general.searchPlaceholder' })}
							startAdornment={
							<SearchIcon style={{ margin: '18px 0 0 11px', color: 'black', display: 'block' }} />
						}
						onChange={(e) => OnSearchChanged(e.target.value)}
					/>
					</FormControl>
				</Grid>
				<Grid
					container
					direction='row'
					alignItems='center'
					style={{
						paddingTop: '10px'
					}}
				>
					<Grid item>
						<Typography
							variant='subtitle1'
							color='primary'
						>
							{intl.formatMessage({ id: 'app.scoringGroup.showArchivedSwitchText' })}
						</Typography>
					</Grid>
					<Grid item>
						<Switch
							checked={isArchivedBundlesVisible}
							onChange={onChangeArchivedBundlesVisibility}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

const mapStateToProps = (state, ownProps) => ({
	isArchivedBundlesVisible: showArchivedBundlesSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	changeArchivedBundlesVisibility: (visible) => dispatch(changeArchivedBundlesVisibility(visible))
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl
)

export default enhance(SearchQuestion)
