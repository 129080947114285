import * as api from '../../../apis/jurisdiction'
import { createSelector } from 'reselect'
import * as cookies from '../../../utilities/cookies'

const FETCH_JURISDICTIONS_REQUESTED = 'FETCH_JURISDICTIONS_REQUESTED'
const FETCH_JURISDICTIONS_SUCCESS = 'FETCH_JURISDICTIONS_SUCCESS'
const FETCH_JURISDICTIONS_FAILED = 'FETCH_JURISDICTIONS_FAILED'

export const fetchJurisdictions = () => (dispatch, getState) => {
	var state = getState()

	if (!state.jurisdiction.jurisdictions || state.jurisdiction.jurisdictions.length === 0) {
		dispatch({ type: FETCH_JURISDICTIONS_REQUESTED, data: {} })
		api.fetchJurisdictions().then((jurisdictions) => {
			if (jurisdictions) {
				return dispatch({ type: FETCH_JURISDICTIONS_SUCCESS, data: jurisdictions })
			} else {
				return dispatch({ type: FETCH_JURISDICTIONS_FAILED, data: {} })
			}
		})
	}
}

const initial = {
	jurisdictions: []
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_JURISDICTIONS_SUCCESS:
			return { ...state, jurisdictions: action.data }
		default:
			return { ...state }
	}
}

export const mainSelector = (state) => state.jurisdiction

export const jurisdictionsSelector = (state) => state.jurisdiction.jurisdictions
