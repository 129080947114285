import React from 'react'

export default ({ width = 16, height = 20 }) => (
	<svg width={`${width}px`} height={`${height}px`} viewBox="0 0 16 20" version="1.1">
		<defs>
			<path
				d="M10,0 L2,0 C0.9,0 0.01,0.9 0.01,2 L0,18 C0,19.1 0.89,20 1.99,20 L14,20 C15.1,20 16,19.1 16,18 L16,6 L10,0 Z M10.94,18 L8,16.28 L5.06,18 L5.84,14.67 L3.25,12.43 L6.66,12.14 L8,9 L9.34,12.14 L12.75,12.43 L10.16,14.67 L10.94,18 Z M9,7 L9,1.5 L14.5,7 L9,7 Z"
				id="path-1"
			/>
		</defs>
		<g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g id="Line-/-Survey-/-Standard" transform="translate(-44.000000, -23.000000)">
				<g id="Icon-/-Survey-Special" transform="translate(40.000000, 21.000000)">
					<polygon id="Path" points="0 0 24 0 24 24 0 24" />
					<g id="description-24px-(1)" stroke-width="1" fill-rule="evenodd">
						<polygon id="Path" points="0 0 24 0 24 24 0 24" />
						<g id="↳-Icon-Color" transform="translate(4.000000, 2.000000)">
							<mask id="mask-2" fill="white">
								<use href="#path-1" />
							</mask>
							<use id="Mask" fill="#000000" fill-rule="nonzero" href="#path-1" />
							<g id="✱-/-Color-/-Icons-/-Selected-/-On-Surface-/-Blue" mask="url(#mask-2)" fill="#007AFF">
								<g transform="translate(-4.000000, -2.000000)" id="Container">
									<rect x="0" y="0" width="24" height="24" />
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
)
