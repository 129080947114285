import React, { Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { useTheme } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Paper } from '@material-ui/core'
import { reduxForm, reset } from 'redux-form'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { bundleSelector, surveySelector } from '../../../redux/selectors'
import { saveSurveySettings } from '../../../redux/actions'
import RelatedProtocols from '../../relatedEntities/components/RelatedProtocols'
import RelatedSurveys from '../../relatedEntities/components/RelatedSurveys'

const SurveySettings = ({ bundle }) => {
	const theme = useTheme()

	return (
		<Paper style={{ marginTop: '8px' }}>
			<Grid container direction="column" justify="space-evenly">
				<Grid item style={{ height: '100%', width: '100%', marginTop: '19px', marginBottom: '19px' }}>
					<div style={{padding: '0 3%'}}>
						<Grid
							container
							direction="column"
							style={{
								backgroundColor: theme.palette.primary.A200, 
								padding: '0 3%',
								paddingTop: '20px'
							}}
						>
							<Grid item>
								{
									!!bundle?.id
									&& <RelatedProtocols
										bundleId={bundle.id}
									/>
								}
							</Grid>
							<Grid item>
								{
									!!bundle?.id
									&& <RelatedSurveys
										bundleId={bundle.id}
									/>
								}
							</Grid>
						</Grid>
					</div>
				</Grid>
			</Grid>
		</Paper>
	)
}

const mapStateToProps = (state, ownProps) => ({
	bundle: bundleSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(SurveySettings)
