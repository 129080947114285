import React from 'react'
import { TableHead } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import { compose } from 'recompose'
import withStyles from '@material-ui/core/styles/withStyles'
import AdministratorsHeader from './AdministratorsHeader'
import AdministratorRow from './AdministratorRow'
import TableBody from '@material-ui/core/TableBody'

const styles = {
	root: {
		background: 'black'
	},
	input: {
		color: 'white',
		backgroundColor: 'rgb(36, 48, 65)'
	}
}

const AdministratorsTable = ({ data, user }) => {
	return (
        <Table data-testid="administrator-table"
            stickyHeader>
			<TableHead>
				<AdministratorsHeader />
			</TableHead>
			<TableBody>
				{data &&
					data.map((administrator) => (
						<AdministratorRow administrator={administrator} user={user} key={administrator.id} />
					))}
			</TableBody>
		</Table>
	)
}

const enhance = compose(withStyles(styles))

export default enhance(AdministratorsTable)
