import React from 'react'
import { reduxForm } from 'redux-form'
import { compose, shallowEqual } from 'recompose'
import { injectIntl } from 'react-intl'
import { connect, useDispatch, useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { email, required } from '../../utilities/validators'
import { isSavingSelector } from '../../redux/selectors'
import { Typography, useTheme } from '@material-ui/core'
import CalmButton from '../CalmButton'
import { sendTemplatePush, startSaving } from '../../redux/actions'
import FormTextField from '../FormTextField'


const SendTestPushModal = ({ intl, handleSubmit, languageId, type, versionId, surveyInstanceId, sendPush }) => {
	const theme = useTheme()
	const dispatch = useDispatch()
	const isSaving = useSelector(isSavingSelector, shallowEqual)

	const onSubmitForm = (values) => {
		dispatch(startSaving())
		sendPush(versionId, surveyInstanceId, languageId, type, values.email)
	}

	return (
		<form onSubmit={handleSubmit(onSubmitForm)}>
			<Grid
				container
				direction="column"
				className="admin-modal"
			>
				<Grid item style={{ width: '100%', textAlign: 'center' }}>
					<Typography
						variant="h1"
						style={{ color: 'white', paddingTop: '48px', paddingBottom: '32px' }}
					>
						{intl.formatMessage({ id: 'app.template.sendTestPushHeader' })}
					</Typography>
				</Grid>

				<Grid item style={{ width: '100%', textAlign: 'center' }}>
					<FormTextField
						label={intl.formatMessage({ id: 'app.general.email' }) + '*'}
						fullWidth
						type="text"
						name="email"
						margin="normal"
						variant="filled"
						validators={[ required, email ]}
					/>
				</Grid>
				<Grid
					item
					style={{
						width: '100%',
						marginTop: '10px',
						textAlign: 'center'
					}}
				>
                    <CalmButton
                        variant="contained"
                        color="primary"
						style={{
							width: '200px'
						}}
						type="submit"
						size="large"
						disabled={isSaving}
					>
						{intl.formatMessage({ id: 'app.template.sendPushSubmitButton' })}
					</CalmButton>
				</Grid>
			</Grid>
		</form>
	)
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => ({
	sendPush: (versionId, surveyInstanceId, languageId, type, email) => {
		dispatch(sendTemplatePush(versionId, surveyInstanceId, languageId, type, email))
	}
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: 'sendTestPush'
	}),
	injectIntl
)

export default enhance(SendTestPushModal)
