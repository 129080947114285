import React from 'react'
import Grid from '@material-ui/core/Grid'
import Container from '../../../components/questionPreview/Container'


export default ({ list, collection }) => {
	return (
		<form>
			<Grid
				container
				direction="column"
				justify="flex-start"
				alignItems="center"
				style={{ width: '75rem', padding: '4rem', margin: 'auto', }}
			>
				<Grid item style={{ alignSelf: 'flex-start', width: '100%' }}>
					{<Container list={list} collection={collection} />}
				</Grid>
			</Grid>
		</form>
	)
}
