import React, {useEffect} from 'react'
import {withStyles} from "@material-ui/core"
import {injectIntl} from "react-intl"
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Typography, Grid } from "@material-ui/core"
import AddMemberButton from "../components/AddMemberButton"
import LabelPaper from "../../../components/LabelPaper"
import SubscriptionTableEmpty from "../components/SubscriptionTableEmpty"
import SubscriptionSponsorInner from "./SubscriptionSponsorInner"
import { subscriptionChangesSelector } from "../../../redux/selectors"
import { fetchSubscriptionChanges } from "../../../redux/actions"


const styles = () => ({
    button: {
        '&:hover': {
            backgroundColor: 'transparent'
        },
    },
    gradient: {
        background:
            'linear-gradient(45deg,  #d4e1f7 0%,#d4e1f7 50%,#ffffff 50%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */',
        filter:
            "progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4e1f7', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */"
    }
})

const SubscriptionSponsor = ({ user, loadSubscriptionChanges, subscriptionChanges, classes, intl }) => {

    useEffect(() => {
        if(!!user && !!user.id)
        {
            loadSubscriptionChanges(user.id, 100, 0)
        }
    }, [user])

    return (
        <div style={{ position: 'relative' }}>
            <div test-id="subscription-sponsor">
                <Grid container direction="column" style={{ color: 'black' }}>
                    <Grid item style={{ width: '100%' }}>
                        <LabelPaper
                            label={intl.formatMessage({ id: 'app.userProfile.subscriptionSponsor' })}
                            Component={
                                SubscriptionSponsorInner
                            }
                            data={subscriptionChanges}
                            user={user}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    subscriptionChanges: subscriptionChangesSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    loadSubscriptionChanges: (userId, count, offset) => dispatch(fetchSubscriptionChanges(userId, count, offset)),
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withStyles(styles))

export default enhance(SubscriptionSponsor)
