import { authenticatedFetch } from './base'

export const addCircle = (
	name,
	status,
	PHI,
	numberCases,
	numberUsers,
	isSponsored,
	observationalProtocolId,
	founderId,
	administratorId,
	pathologyId,
	treatmentId,
	sponsorId
) => {
	return authenticatedFetch(`/api/circle/admin`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			name,
			status,
			isPhi : PHI,
			numberCases,
			numberUsers,
			isSponsored,
			observationalProtocolId,
			founderId,
			administratorId,
			pathologyId,
			treatmentId,
			sponsorId
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const fetchCircles = (searchPhrase, filter, offset) => {
	var url = `/api/circle`
	let urlHasParams = false

	if (searchPhrase && searchPhrase.length > 0) {
		url = `${url}?searchPhrase=${encodeURIComponent(searchPhrase)}`
		urlHasParams = true
	}

	if(offset){
		url += `${urlHasParams ? '&' : '?'}offset=${offset}`
		urlHasParams = true
	}

	if(Number.isInteger(filter)){
		url += `${urlHasParams ? '&' : '?'}filter=${filter}`
		urlHasParams = true
	}

	return authenticatedFetch(url, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const generateCircleLink = (circleId) => {
	return authenticatedFetch(`/api/circle/${circleId}/generatelink`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchCircleUsageStatistics = (
	startDate,
	endDate
) => {
	let obj = {
		startDate,
		endDate
	}

	let url = '/api/circle/usageStatistics?' + new URLSearchParams(obj).toString()
	return authenticatedFetch(url, { method: 'get' })
		.then(function (response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (response) {
			return response
		})
}

export const fetchCircleUsageStatisticsRawData = (
	startDate,
	endDate
) => {
	let obj = {
		startDate,
		endDate
	}

	let url = '/api/circle/usageStatisticsWithRawData?' + new URLSearchParams(obj).toString()
	return authenticatedFetch(url, { method: 'get' })
		.then(function (response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (response) {
			return response
		})
}

export const fetchExecutiveUsageStatistics = (
	startDate,
	endDate,
	executiveStatisticType
) => {
	let obj = {
		startDate,
		endDate,
		executiveStatisticType
	}

	let url = '/api/circle/executiveUsageStatistics?' + new URLSearchParams(obj).toString()
	return authenticatedFetch(url, { method: 'get' })
		.then(function (response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (response) {
			return response
		})
}

export const generateCircleInvitationLink = (id, isSponsored) => {
	return authenticatedFetch(`/api/circle/${id}/generateinvitationlink?isSponsored=${isSponsored}`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchCircle = (id) => {
	return authenticatedFetch(`/api/circle/${id}/admin`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const fetchCircleAutoComplete = (searchPhrase = '') => {
	return authenticatedFetch(`/api/circle/autocomplete?searchPhrase=${encodeURIComponent(searchPhrase)}`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const fetchCircleByProtocolAutoComplete = (protocolId, searchPhrase = '') => {
	return authenticatedFetch(`/api/circle/protocol/${protocolId}/autocomplete?searchPhrase=${encodeURIComponent(searchPhrase)}`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const fetchCircleByUserAutoComplete = (userId, searchPhrase = '', protocolVersionId = null) => {
	return authenticatedFetch(`/api/circle/user/${userId}/autocomplete?searchPhrase=${encodeURIComponent(searchPhrase)}&protocolVersionId=${protocolVersionId ?? null}`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const archiveCircle = (id) => {
	return authenticatedFetch(`/api/circle/${id}`, { method: 'DELETE' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const fetchCircleMembers = (id, searchPhrase) => {
	var url = `/api/circle/${id}/members`

	if (searchPhrase && searchPhrase.length > 0) {
		url = `${url}?searchPhrase=${encodeURIComponent(searchPhrase)}`
	}

	return authenticatedFetch(url, { method: 'get' }).then(function(response) {
		if (response.status === 200) {
			return response.json()
		}
	})
}

export const setBackgroundImage = (entity, id, photo) => {
	//users or circle
	let url = `/api/${entity}/${id}/background`

	return authenticatedFetch(url, {
		method: 'post',
		body: photo
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const resetBackgroundImage = (entity, id) => {
	//users or circle
	let url = `/api/${entity}/${id}/background`

	return authenticatedFetch(url, {
		method: 'DELETE'
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const updateCircleDetailsById = (circle) => {
	return authenticatedFetch(`/api/circle/${circle.id}`, {
		method: 'put',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(circle)
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const updateTerms = (id, terms) => {
	return authenticatedFetch(`/api/circle/${id}/terms`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ terms: terms })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const reinstateCircleArchive = (circleId) => {
	return authenticatedFetch(`/api/circle/${circleId}/reinstate`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const makeAdminMemberByCircleUserId = (userId, circleId) => {
	return authenticatedFetch(`/api/circle/${circleId}/member/${userId}/makeAdministrator`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const makeSponsorMemberByCircleUserId = (userId, circleId) => {
	return authenticatedFetch(`/api/circle/${circleId}/member/${userId}/makeSponsor`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const removeUserCircleById = (email, circleId) => {
	return authenticatedFetch(`/api/circle/${circleId}/member/${email}/removeUserCircle`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const submitLeaveCircleById = (circleId) => {
	return authenticatedFetch(`/api/circle/${circleId}/leave`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const fetchTreatment = (id) => {
	var url = `/api/treatment/getSingle/${id}`

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(data) {
			return data
		})
}

export const fetchPathology = (id) => {
	var url = `/api/pathologies/getSingle/${id}`

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(data) {
			return data
		})
}

export const updateCircleTreatment = (data) => {
	return authenticatedFetch(`/api/circle/${data.circleId}/updateTreatment/${data.treatmentId}`, {
		method: 'put',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify()
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(data) {
			return data
		})
}

export const updateCirclePathology = (data) => {
	return authenticatedFetch(`/api/circle/${data.circleId}/updatePathology/${data.pathologyId}`, {
		method: 'put',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify()
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(data) {
			return data
		})
}

export const updatePlan = (userCount, caseCount, subscriptionExpiration, circleId) => {
	return authenticatedFetch(`/api/circle/${circleId}/updatePlan`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ userCount: userCount, caseCount: caseCount, subscriptionExpiration: subscriptionExpiration })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response
			}
		})
}

export const getStatisticsExport = (id) => {

	return authenticatedFetch(`/api/circle/${id}/export`, { method: 'GET' })
		.then(function (response) {
			if (response.status === 200) {
				const header = response.headers.get("content-disposition")
				if(!header) {
					return { isSuccessful: false }
				}
				
				const fileName = header.split('"')[1];
				return response.blob().then(blob => [fileName, blob])
			}
		})
		.then(function ([filename, blob]) {
			try {
				var element = document.createElement('a')
				element.setAttribute('href', URL.createObjectURL(blob))
				element.setAttribute('download', filename)

				element.style.display = 'none'
				document.body.appendChild(element)

				element.click()

				document.body.removeChild(element)
				return { isSuccessful: true }
			} catch (e) {
				console.error(e)
				return { isSuccessful: false }
			}
		})
}

export const changeHidePatientResults = (circleId) => {
	return authenticatedFetch(`/api/circle/${circleId}/changeHidePatientResults`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify()
	})
		.then(function(response) {
			if (response.status === 200) {
				return response
			}
		})
}

export const changeAccessToPiData = (circleId, isAdmin) => {
	return authenticatedFetch(`/api/circle/${circleId}/changeAccessToPIData?isAdmin=${isAdmin}`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify()
	})
		.then(function(response) {
			if (response.status === 200) {
				return response
			}
		})
}

export const changeAccessToAllData = (circleId, isAdmin) => {
	return authenticatedFetch(`/api/circle/${circleId}/changeAccessToAllData?isAdmin=${isAdmin}`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify()
	})
		.then(function(response) {
			if (response.status === 200) {
				return response
			}
		})
}

export const stopSponsoringMember = (userId, circleId) => {
	return authenticatedFetch(`/api/circle/${circleId}/member/${userId}/stopSponsoring`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const stopSponsoringMemberInvitation = (invitationId, circleId) => {
	return authenticatedFetch(`/api/circle/${circleId}/member/${invitationId}/stopSponsoringInvitation`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const startSponsoringMember = (userId, circleId) => {
	return authenticatedFetch(`/api/circle/${circleId}/member/${userId}/startSponsoring`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const startSponsoringMemberInvitation = (invitationId, circleId) => {
	return authenticatedFetch(`/api/circle/${circleId}/member/${invitationId}/startSponsoringInvitation`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}

export const alterCanChangeCaseFunding = (circleId) => {
	return authenticatedFetch(`/api/circle/${circleId}/alterCanChangeCaseFunding`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response
			}
		})
}

export const changeDefaultCaseFunding = (circleId, value) => {
	return authenticatedFetch(`/api/circle/${circleId}/changeDefaultCaseFunding/${value}`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response
			}
		})
}

export const changeCaseFunding = (circleId, userId, value) => {
	return authenticatedFetch(`/api/circle/${circleId}/member/${userId}/changeCaseFunding/${value}`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const changeCaseSponsor = (circleId, userId, caseFunding, caseSponsorId) => {
	return authenticatedFetch(`/api/circle/${circleId}/member/${userId}/changeCaseSponsor`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			caseFunding, 
			caseSponsorId
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const getCircleReportDataExport = (id, headersType) => {
	let params = {
		headersType
	}

	return authenticatedFetch(`/api/circle/${id}/data/export?${new URLSearchParams(params).toString()}`, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const fetchCircleMemberPatientInvitationLink = (circleId, userId) => {
	return authenticatedFetch(`/api/circle/${circleId}/generatelink/${userId}`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const changeMemberCasesRate = (circleId, userId, casesRate) => {
	return authenticatedFetch(`/api/circle/changeMemberCasesRate`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			circleId,
			userId,
			casesRate
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const exportNonCompliantSubscribers = (circleId, startDate, endDate) => {
	let searchParams = {
		startDate,
		endDate
	}

	const urlPart = `/api/circle/${circleId}/exportNonCompliantSubscribers?${new URLSearchParams(searchParams).toString()}`

	return authenticatedFetch(urlPart, { method: 'GET' })
		.then(function (response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (response) {
			return response
		})
}

export const exportNonCompliantSurveys = (circleId, selectedProtocolVersion, filters, fileName) => {
	let searchParams = {
		...filters,
		selectedProtocolVersion,
		fileName
	}

	const urlPart = `/api/circle/${circleId}/exportNonCompliantSurveys?${new URLSearchParams(searchParams).toString()}`

	return authenticatedFetch(urlPart, { method: 'GET' })
		.then(function (response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (response) {
			return response
		})
}

export const exportNonComplainSurveysDef = (circleId, minSurveyId, maxSurveyId, includeNonCompliantSurveys) => {
	let searchParams = {
		minSurveyId,
		maxSurveyId,
		includeNonCompliantSurveys
	}

	const urlPart = `/api/circle/${circleId}/exportNonCompliantSurveys?${new URLSearchParams(searchParams).toString()}`

	return authenticatedFetch(urlPart, { method: 'GET' })
		.then(function (response) {
			if (response.status === 200) {
				const header = response.headers.get("content-disposition")
				if(!header) {
					return { isSuccessful: false }
				}
				
				const fileName = header.split('"')[1];
				return response.blob().then(blob => ({fileName, blob}))
			}
			else {
				return { isSuccessful: false }
			}
		})
		.then(function (response) {
			try {
				const {fileName, blob} = response

				var element = document.createElement('a')
				element.setAttribute('href', URL.createObjectURL(blob))
				element.setAttribute('download', fileName)

				element.style.display = 'none'
				document.body.appendChild(element)

				element.click()

				document.body.removeChild(element)
				return { isSuccessful: true }
			} catch (e) {
				console.error(e)
				return { isSuccessful: false }
			}
		})
}

export const exportNonCompliantPatients = (circleId, startDate, endDate, allPatients) => {
	let searchParams = {
		startDate,
		endDate, 
		allPatients
	}

	const urlPart = `/api/circle/${circleId}/exportNonCompliantPatients?${new URLSearchParams(searchParams).toString()}`

	return authenticatedFetch(urlPart, { method: 'GET' })
		.then(function (response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (response) {
			return response
		})
}