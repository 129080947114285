import React, { useState, useRef } from 'react'
import { compose } from 'recompose'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'
import { useTheme } from '@material-ui/core/styles'
import QuestionTypeSelector from './QuestionTypeSelector'
import QuestionTypeTextBuilder from './QuestionTypeTextBuilder'
import QuestionTypeFileBuilder from './QuestionTypeFileBuilder'
import QuestionTypeTFBuilder from './QuestionTypeTFBuilder'
import QuestionTypeNumberBuilder from './QuestionTypeNumberBuilder'
import SearchQuestion from './SearchQuestion'
import QuestionTypeMultiSelectBuilder from './MultiSelect/QuestionTypeMultiSelectBuilder'
import QuestionTypeRadioSelectBuilder from './RadioSelect/QuestionTypeRadioSelectBuilder'
import ContentTextBuilder from './ContentTextBuilder'
import QuestionTypeDateBuilder from './QuestionTypeDateBuilder'
import QuestionTypeGroupedBuilder from './Grouped/QuestionTypeGroupedBuilder'
import { useRouteMatch } from 'react-router-dom'
import { reduxForm, formValueSelector } from 'redux-form'
import Bundle from '../survey/components/Bundle'
import { startAddingQuestion, stopBuildingQuestion, showModal, hideModal } from '../../redux/actions'
import { isAddingQuestionSelector } from '../../redux/selectors'
import { useDispatch } from 'react-redux'
import { uuidv4 } from '../../utilities/utilities'
import { useSelector, shallowEqual } from 'react-redux'
import QuestionTypeAnalogBuilder from './QuestionTypeAnalogBuilder'
import CalmButton from '../../components/CalmButton'
import EntityAuditCommentModal from "../../components/entityAudit/EntityAuditCommentModal"
import { isAuditCommentsRequired } from '../../utilities/auditUtils'

const renderBuilder = (type, onCancel, childRef, showScoreEntry) => {
	switch (type) {
		// case 'tf':
		// 	return <QuestionTypeTFBuilder onCancel={onCancel} ref={childRef} />
		// 	break
		case 'file':
			return <QuestionTypeFileBuilder onCancel={onCancel} ref={childRef} />
		case 'text':
			return <QuestionTypeTextBuilder onCancel={onCancel} ref={childRef} />
			break
		case 'date':
			return <QuestionTypeDateBuilder onCancel={onCancel} ref={childRef} />
			break
		case 'content':
			return <ContentTextBuilder onCancel={onCancel} ref={childRef} />
			break
		case 'number':
			return <QuestionTypeNumberBuilder onCancel={onCancel} ref={childRef} />
			break
		case 'single':
			return <QuestionTypeRadioSelectBuilder onCancel={onCancel} ref={childRef} showScoreEntry={showScoreEntry} />
		case 'multi':
			return <QuestionTypeMultiSelectBuilder onCancel={onCancel} ref={childRef} showScoreEntry={showScoreEntry} />
		case 'grouped':
			return <QuestionTypeGroupedBuilder onCancel={onCancel} ref={childRef} showScoreEntry={showScoreEntry} />
		case 'analog':
			return <QuestionTypeAnalogBuilder onCancel={onCancel} ref={childRef} />
		case 'scoring':
			return <Bundle onCancel={onCancel} ref={childRef} />
	}
}

const QuestionBuilder = ({
	intl,
	onAddCallback,
	onAddExistingCallback,
	position,
	handleSubmit,
	suppressNonDiscreteQuestions = false,
	showScoreEntry,
	searchType,
	node,
	list,
						containerId
}) => {
	const dispatch = useDispatch()
	const theme = useTheme()
	const [ type, setType ] = useState(null)
	const [ showBuilder, setShowBuilder ] = useState(false)
	const childRef = useRef()
	const isAdding = useSelector(isAddingQuestionSelector, shallowEqual)
	const match = useRouteMatch()

	const onCreateNewRequestedCallback = () => {
		setShowBuilder(true)
	}

	const onAddQuestionCallback = (question, commentsObj) => {
		onAddCallback(false, question, commentsObj)
	}

	const onSubmitForm = (values) => {
		let q = childRef.current.getQuestion()
		if (isAuditCommentsRequired())
		{
			dispatch(
				showModal(
					EntityAuditCommentModal,
					{
						entityId: match.params.protocolId !== undefined ? match.params.protocolId : match.params.surveyId === undefined ? match.params.bundleId : match.params.surveyId,
							entityTypeId: match.params.protocolId !== undefined ? 17 : match.params.surveyId === undefined ? 15 : 16, //protocol : bundle : survey
						saveCallback: (commentsObj) => {
							dispatch(hideModal())							
							dispatch(startAddingQuestion())
							onAddQuestionCallback(q, commentsObj)
						}
					}
				)
			)
		}
		else 
		{
			dispatch(startAddingQuestion())
			onAddQuestionCallback(q)
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmitForm)}>
			<Grid
				container
				direction="column"
				style={{ backgroundColor: theme.palette.primary.A200, marginBottom: showBuilder ? '40px' : 0 }}
			>
				<Grid item>
					<SearchQuestion
						position={position}
						searchType={searchType}
						onAddCallback={onAddExistingCallback}
						onCancelCallback={() => dispatch(stopBuildingQuestion())}
						onCreateNewRequestedCallback={onCreateNewRequestedCallback}
					/>
				</Grid>

				{showBuilder && (
					<Grid item style={{ padding: '3%', width: '100%' }}>
						<Grid container direction="row" style={{ width: '100%' }} justify="flex-start">
							<Grid item style={{ flex: 0.2 }}>
								<QuestionTypeSelector
									typeSelectedCallback={setType}
									suppressNonDiscreteQuestions={suppressNonDiscreteQuestions}
								/>
							</Grid>
							<Grid item style={{ flex: 0.8 }}>
								{type && renderBuilder(type, null, childRef, showScoreEntry)}
							</Grid>
						</Grid>
					</Grid>
				)}
				{showBuilder && (
					<Grid item style={{ alignSelf: 'flex-end', padding: '3%' }}>
						<Grid container spacing={2}>
							<Grid item>
								<Button
									variant="outlined"
                                    color="primary"
									onClick={() => dispatch(stopBuildingQuestion())}
								>
									{intl.formatMessage({ id: 'app.Builder.discardButtonText' })}
								</Button>
							</Grid>
							<Grid item>
								<CalmButton
									variant="contained"
                                    color="primary" 
									disabled={isAdding}
									type="submit"
								>
									{intl.formatMessage({ id: 'app.Builder.saveButtonText' })}
								</CalmButton>
							</Grid>
						</Grid>
					</Grid>
				)}
			</Grid>
		</form>
	)
}

const enhance = compose(
	reduxForm({
		form: 'createQuestion',
		touchOnChange: true,
		touchOnBlur: true
	}),
	injectIntl
)

export default enhance(QuestionBuilder)
