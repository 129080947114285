import React from "react"
import EntityAuditTableCell from "./EntityAuditTableCell"


const EntityAuditUserTableCell = ({entityLog, ...other}) => {
    return (
        <EntityAuditTableCell
            {...other}
        >
            { entityLog.userName }
        </EntityAuditTableCell>
    )
}

export default EntityAuditUserTableCell