import React from "react";
import { TableCell } from "@material-ui/core";
import { FormattedDate } from "react-intl";


const RelatedServiceProviderRemoveTableCell = ({
    serviceProvider
}) => {
    return (
        <TableCell>
            {
                !!serviceProvider.removeDate
                && <FormattedDate value={serviceProvider.removeDate} />
            }
        </TableCell>
    )
}

export default RelatedServiceProviderRemoveTableCell;
