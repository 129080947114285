import React, { useRef } from 'react'
import Grid from '@material-ui/core/Grid'
import { useTheme } from '@material-ui/core/styles'
import Answer from './Answer'
import UpDownCommandBar from '../UpDownCommandBar'

export default ({ answer, showScoreEntry }) => {
	const theme = useTheme()
	const ref = useRef()
	return (
		<Grid
			container
			justify="space-between"
			alignItems="flex-start"
			className='block-answer-container'
			style={{ backgroundColor: theme.palette.primary.A200, width: '100%' }}
		>
			<Grid item style={{ width: '90%' }}>
				<Answer ref={ref} answer={answer} showScoreEntry={showScoreEntry} />
			</Grid>
		</Grid>
	)
}
