import * as survey from '../../../apis/survey'
import { createSelector } from 'reselect'
import { getSurveyLanguages } from '../../actions'
import { ListContainer } from '../../../utilities/linkedList'
import { hideModal, stopSaving } from '../core'
import { processQuestions } from '../../../utilities/questions'

const FETCH_SURVEY_BUILDER_REQUESTED = 'FETCH_SURVEY_BUILDER_REQUESTED'
const FETCH_SURVEY_BUILDER_SUCCESS = 'FETCH_SURVEY_BUILDER_SUCCESS'
const FETCH_SURVEY_BUILDER_FAILED = 'FETCH_SURVEY_BUILDER_FAILED'

export const fetchBuilderSurvey = (id, onFetchCompleteCallback, languageId = 1) => (dispatch) => {
	dispatch({ type: FETCH_SURVEY_BUILDER_REQUESTED, data: {} })
	survey.fetchSurvey(id, languageId).then((response) => {
		if (response && response.isSuccessful) {
			let questionIds = new Array(0)
			let hash = {}
			let container = new ListContainer()

			processQuestions(response, container, questionIds, hash)

			if (onFetchCompleteCallback) {
				dispatch(onFetchCompleteCallback())
			}
			return dispatch({
				type: FETCH_SURVEY_BUILDER_SUCCESS,
				data: {
					survey: response,
					list: container,
					ids: questionIds,
					hash: hash,
					settings: {
						title: response.title,
						internalDescription: response.internalDescription
					}
				}
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.surveys.fetchSurveyFailed', isSuccess: false }
			})
		}
	})
}

const SWAP_QUESTION_POSITION_REQUESTED = 'SWAP_QUESTION_POSITION_REQUESTED'
const SWAP_QUESTION_POSITION_SUCCESS = 'SWAP_QUESTION_POSITION_SUCCESS'
const SWAP_QUESTION_POSITION_NOP = 'SWAP_QUESTION_POSITION_NOP'

export const swapQuestionPosition = (questionA, questionB, surveyId) => (dispatch, getState) => {
	dispatch({ type: SWAP_QUESTION_POSITION_REQUESTED, data: {} })
	let state = getState()

	if (state.builder.surveyBuilder.list) {
		let container = { ...state.builder.surveyBuilder.list }

		survey.swapQuestionPosition(
			surveyId,
			questionA.data.instanceId,
			questionA.data.questionType,
			questionA.data.isBundle,
			questionB.data.instanceId,
			questionB.data.questionType,
			questionB.data.isBundle
		).then((response) => {
			if (response && response.isSuccessful) {
				// let questionIds = new Array(0)
				// let hash = {}
				// let container = new ListContainer()
				// processSurveyQuestions(response, container, questionIds, hash)
				dispatch(fetchBuilderSurvey(surveyId, null, 1))
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.survey.swapSurveyPositionSuccessfully', isSuccess: true }
				})
				// return dispatch({
				// 	type: SWAP_QUESTION_POSITION_SUCCESS
				// 	// data: { list: container, ids: questionIds, hash: hash }
				// })
			} else {
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.survey.swapSurveyPositionFailed', isSuccess: false }
				})
			}
		})
	}
	return dispatch({ type: SWAP_QUESTION_POSITION_NOP, data: null })
}

const ADD_EXISTING_QUESTION_REQUESTED = 'ADD_EXISTING_REQUESTED'
const ADD_EXISTING_QUESTION_SUCCESS = 'ADD_EXISTING_QUESTION_SUCCESS'
const ADD_EXISTING_QUESTION_FAILED = 'ADD_EXISTING_QUESTION_FAILED'

export const addExistingQuestion = (id, type, position, surveyId, commentsObj) => (dispatch, getState) => {
	dispatch({ type: ADD_EXISTING_QUESTION_REQUESTED, data: {} })
	let state = getState()

	survey.addExistingQuestion(id, type, position, surveyId, commentsObj).then((response) => {
		dispatch(stopAddingQuestion())
		dispatch(stopSaving())
		if (response && response.isSuccessful) {
			dispatch(getSurveyLanguages(surveyId))
			dispatch(fetchBuilderSurvey(surveyId, null, 1))

			dispatch(stopBuildingQuestion())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.surveyBuilder.addSurveyQuestionSuccessful', isSuccess: true }
			})

			return
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.surveyBuilder.failedToAddSurveyQuestion', isSuccess: false }
			})
		}
	})
}

const ADD_QUESTION_REQUESTED = 'ADD_QUESTION_REQUESTED'
const ADD_QUESTION_SUCCESS = 'ADD_QUESTION_SUCCESS'
const ADD_QUESTION_FAILED = 'ADD_QUESTION_FAILED'

export const addQuestion = (newQuestion, position, surveyId, isClone = false, commentsObj) => (dispatch, getState) => {
	dispatch({ type: ADD_QUESTION_REQUESTED, data: {} })
	let state = getState()

	survey.addQuestion(newQuestion, position, surveyId, isClone, commentsObj).then((response) => {
		dispatch(stopAddingQuestion())
		dispatch(stopSaving())
		if (response && response.isSuccessful) {
			dispatch(getSurveyLanguages(surveyId))
			dispatch(fetchBuilderSurvey(surveyId, null, 1))
			dispatch(stopBuildingQuestion())
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.surveyBuilder.addSurveyQuestionSuccessful', isSuccess: true }
			})

			return
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.surveyBuilder.failedToAddSurveyQuestion', isSuccess: false }
			})
		}
	})
}

const REMOVE_QUESTION_REQUESTED = 'REMOVE_QUESTION_REQUESTED'
const REMOVE_QUESTION_SUCCESS = 'REMOVE_QUESTION_SUCCESS'
const REMOVE_QUESTION_FAILED = 'REMOVE_QUESTION_FAILED'

export const removeQuestion = (question, surveyId) => (dispatch) => {
	dispatch({ type: REMOVE_QUESTION_REQUESTED, data: {} })

	var entityId = question.data.isBundle ? question.data.bundleEntityId : question.data.id

	survey.removeQuestion(surveyId, entityId, question.data.questionType, question.data.isBundle).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(fetchBuilderSurvey(surveyId, null, 1))
			dispatch(stopBuildingQuestion())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.surveyBuilder.removeSuccess', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.surveyBuilder.removeFailed', isSuccess: false }
			})
		}
	})
}

const SAVE_BUILDER_REQUESTED = 'SAVE_BUILDER_REQUESTED'
const SAVE_BUILDER_SUCCESS = 'SAVE_BUILDER_SUCCESS'
const SAVE_BUILDER_FAILED = 'SAVE_BUILDER_FAILED'
export const saveBuilder = (surveyId, protocolId) => (dispatch, getState) => {
	dispatch({ type: SAVE_BUILDER_REQUESTED, data: {} })
	let state = getState()
	if (state.builder.surveyBuilder && state.builder.surveyBuilder.list) {
		let container = { ...state.builder.surveyBuilder.list }
		let questions = new Array(0)
		let hash = {}
		let index = 0

		container.getList().traverse((r) => {
			let question = {}
			question.surveyId = surveyId
			question.sortOrder = index

			if (isNaN(r.data.id)) {
				delete r.data.id
			} else {
				question.id = r.data.id
			}

			question.data = JSON.stringify(r.data)
			question.title = r.data.title
			questions.push(question)
			index++
		})

		let settings = state.builder.surveyBuilder.settings

		survey.saveSurvey(surveyId, settings, protocolId).then((survey) => {
			if (survey && survey.isSuccessful) {
				let questions = new Array(0)
				let hash = {}
				let container = new ListContainer()

				processQuestions(survey, container, questions, hash)

				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'Success', isSuccess: true }
				})
			} else {
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'TODO', isSuccess: false }
				})
			}
		})
	}
	return
}

const MOVE_QUESTION_TO_POSITION_REQUESTED = 'MOVE_QUESTION_TO_POSITION_REQUESTED'
const MOVE_QUESTION_TO_POSITION_SUCCESS = 'MOVE_QUESTION_TO_POSITION_SUCCESS'
const MOVE_QUESTION_TO_POSITION_FAILED = 'MOVE_QUESTION_TO_POSITION_FAILED'
export const moveQuestionToPosition = (id, position) => (dispatch, getState) => {
	let state = getState()
	dispatch({ type: MOVE_QUESTION_TO_POSITION_REQUESTED, data: {} })

	return survey.moveQuestionToPosition(id, position).then((response) => {
		dispatch(stopSaving())
		if (response && response.isSuccessful) {
			dispatch(hideModal())
			dispatch(fetchBuilderSurvey(state.survey.surveyCopy.id, null, state.survey.surveyCopy.languageId))
			return dispatch({ type: MOVE_QUESTION_TO_POSITION_SUCCESS, data: response })
		} else {
			return dispatch({ type: MOVE_QUESTION_TO_POSITION_FAILED, data: {} })
		}
	})
}


const UPDATE_SURVEY_INTERNAL_DESCRIPTION_REQUESTED = 'UPDATE_SURVEY_INTERNAL_DESCRIPTION_REQUESTED'
export const updateSurveyInternalDescription = (description) => (dispatch) => {
	return dispatch({ type: UPDATE_SURVEY_INTERNAL_DESCRIPTION_REQUESTED, data: { description } })
}

const UPDATE_SURVEY_TITLE_REQUESTED = 'UPDATE_SURVEY_TITLE_REQUESTED'
export const updateSurveyTitle = (description) => (dispatch) => {
	return dispatch({ type: UPDATE_SURVEY_TITLE_REQUESTED, data: { description } })
}

const START_BUILDING_QUESTION_REQUESTED = 'START_BUILDING_QUESTION_REQUESTED'
export const startBuildingQuestion = (callback, containerId) => (dispatch) => {
	return dispatch({ type: START_BUILDING_QUESTION_REQUESTED, data: { containerId: containerId } })
}

const STOP_BUILDING_QUESTION_REQUESTED = 'STOP_BUILDING_QUESTION_REQUESTED'
export const stopBuildingQuestion = () => (dispatch, getState) => {
	return dispatch({ type: STOP_BUILDING_QUESTION_REQUESTED, data: {} })
}

const START_ADDING_BUNDLE_REQUESTED = 'START_ADDING_BUNDLE_REQUESTED'
export const startAddingBundle = (callback, containerId) => (dispatch, getState) => {
	return dispatch({ type: START_ADDING_BUNDLE_REQUESTED, data: { containerId: containerId } })
}

const STOP_ADDING_BUNDLE_REQUESTED = 'STOP_ADDING_BUNDLE_REQUESTED'
export const stopAddingBundle = () => (dispatch, getState) => {
	return dispatch({ type: STOP_ADDING_BUNDLE_REQUESTED, data: {} })
}

const START_BUILDING_CONTENT_REQUESTED = 'START_BUILDING_CONTENT_REQUESTED'
export const startBuildingContent = (containerId) => (dispatch) => {
	return dispatch({ type: START_BUILDING_CONTENT_REQUESTED, data: { containerId: containerId } })
}

const STOP_BUILDING_CONTENT_REQUESTED = 'STOP_BUILDING_CONTENT_REQUESTED'
export const stopBuildingContent = () => (dispatch, getState) => {
	return dispatch({ type: STOP_BUILDING_CONTENT_REQUESTED, data: {} })
}

const START_ADDING_QUESTION_REQUESTED = 'START_ADDING_QUESTION_REQUESTED'
export const startAddingQuestion = () => (dispatch) => {
	return dispatch({ type: START_ADDING_QUESTION_REQUESTED, data: {} })
}

const STOP_ADDING_QUESTION_REQUESTED = 'STOP_ADDING_QUESTION_REQUESTED'
export const stopAddingQuestion = () => (dispatch, getState) => {
	return dispatch({ type: STOP_ADDING_QUESTION_REQUESTED, data: {} })
}

const IS_SURVEY_QUESTION_IN_USE_REQUESTED = 'IS_SURVEY_QUESTION_IN_USE_REQUESTED'
const IS_SURVEY_QUESTION_IN_USE_SUCCESS = 'IS_SURVEY_QUESTION_IN_USE_SUCCESS'
const IS_SURVEY_QUESTION_IN_USE_FAILED = 'IS_SURVEY_QUESTION_IN_USE_FAILED'
export const fetchIsSurveyQuestionInUse = (surveyId, question) => (dispatch) => {
	dispatch({ type: IS_SURVEY_QUESTION_IN_USE_REQUESTED, data: {} })

	survey.fetchIsSurveyQuestionInUse(surveyId, question.data.id, question.data.isBundle).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: IS_SURVEY_QUESTION_IN_USE_SUCCESS, data: response.data })
		} else {
			return dispatch({ type: IS_SURVEY_QUESTION_IN_USE_FAILED, data: {} })
		}
	})
}


const initial = {
	isBuildingQuestion: false,
	isAddingQuestion: false,
	isAddingBundle: false,
	isBuildingContent: false,
	containerId: null,
	currentContainerId: null,
	currentContentContainerId: null,
	currentBundleContainerId: null,
	surveyBuilder: {
		survey,
		questionIds: [],
		questionList: new ListContainer(),
		questionHash: {},
		settings: {}
	},
	isQuestionUsed: null
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case START_ADDING_BUNDLE_REQUESTED:
			return { ...state, isAddingBundle: true, currentBundleContainerId: action.data.containerId }
			break
		case STOP_ADDING_BUNDLE_REQUESTED:
			return { ...state, isAddingBundle: false, currentBundleContainerId: null }
			break
		case START_ADDING_QUESTION_REQUESTED:
			return { ...state, isAddingQuestion: true }
			break
		case STOP_ADDING_QUESTION_REQUESTED:
			return { ...state, isAddingQuestion: false }
			break
		case START_BUILDING_QUESTION_REQUESTED:
			return { ...state, isBuildingQuestion: true, currentContainerId: action.data.containerId }
			break
		case STOP_BUILDING_QUESTION_REQUESTED:
			return { ...state, isBuildingQuestion: false, currentContainerId: null }
			break
		case START_BUILDING_CONTENT_REQUESTED:
			return { ...state, isBuildingContent: true, currentContentContainerId: action.data.containerId }
			break
		case STOP_BUILDING_CONTENT_REQUESTED:
			return { ...state, isBuildingContent: false, currentContentContainerId: null }
			break
		case UPDATE_SURVEY_INTERNAL_DESCRIPTION_REQUESTED:
			return {
				...state,
				surveyBuilder: {
					...state.surveyBuilder,
					settings: { ...state.surveyBuilder.settings, internalDescription: action.data.description }
				}
			}
		case UPDATE_SURVEY_TITLE_REQUESTED:
			return {
				...state,
				survey: { ...state.survey, title: action.data.title }
			}
		case FETCH_SURVEY_BUILDER_SUCCESS:
			return {
				...state,
				isUpdatingQuestionCallback: null,
				surveyBuilder: {
					...state.surveyBuilder,
					survey: action.data.survey,
					list: action.data.list,
					hash: action.data.hash,
					ids: action.data.ids,
					settings: action.data.settings
				}
			}
		case FETCH_SURVEY_BUILDER_REQUESTED:
			return {
				...state,
				isUpdatingQuestionCallback: null,
				surveyBuilder: {
					survey,
					questionIds: [],
					questionList: new ListContainer(),
					questionHash: {},
					settings: {}
				}
			}
		case ADD_EXISTING_QUESTION_SUCCESS:
		case ADD_QUESTION_SUCCESS:
			return {
				...state,
				surveyBuilder: {
					...state.surveyBuilder,
					list: action.data.list,
					hash: action.data.hash,
					ids: action.data.ids
				}
			}
		case SWAP_QUESTION_POSITION_SUCCESS:
			return {
				...state,
				surveyBuilder: {
					...state.surveyBuilder,
					list: action.data.list,
					hash: action.data.hash,
					ids: action.data.ids
				}
			}
		case IS_SURVEY_QUESTION_IN_USE_REQUESTED:
			return {
				...state,
				isQuestionUsed: null
			}
		case IS_SURVEY_QUESTION_IN_USE_SUCCESS:
			return {
				...state,
				isQuestionUsed: action.data
			}
		case 'SIGN_OUT_REQUESTED':
			return initial

		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.builder

export const isBuildingBundleContainerIdSelector = createSelector(mainSelector, (state) => {
	return state && state.currentBundleContainerId
})

export const isAddingQuestionSelector = createSelector(mainSelector, (state) => {
	return state && state.isAddingQuestion
})
export const isAddingBundleSelector = createSelector(mainSelector, (state) => {
	return state && state.isAddingBundle
})

export const isBuildingQuestionSelector = createSelector(mainSelector, (state) => {
	return state && state.isBuildingQuestion
})

export const isBuildingQuestionContainerIdSelector = createSelector(mainSelector, (state) => {
	return state && state.currentContainerId
})
export const isBuildingContentSelector = createSelector(mainSelector, (state) => {
	return state && state.isBuildingContent
})

export const isBuildingContentContainerIdSelector = createSelector(mainSelector, (state) => {
	return state && state.currentContentContainerId
})

export const builderSurveySelector = createSelector(mainSelector, (state) => {
	if (state && state.surveyBuilder && state.surveyBuilder.survey) {
		return state.surveyBuilder.survey
	}
})

export const builderSurveyQuestionListSelector = createSelector(mainSelector, (state) => {
	if (state && state.surveyBuilder && state.surveyBuilder.questionList) {
		return state.surveyBuilder.questionList
	}
})

export const builderSurveyQuestionHashSelector = createSelector(mainSelector, (state) => {
	if (state && state.surveyBuilder && state.surveyBuilder.hash) {
		return state.surveyBuilder.hash
	}
})

export const builderSurveyQuestionIdSelector = createSelector(mainSelector, (state) => {
	if (state && state.surveyBuilder && state.surveyBuilder.ids) {
		return state.surveyBuilder.ids
	}
})

const getBuilderSurveyQuestions = (ids, hash) => {
	if (
		ids?.length > 0
		&& !!hash
	) {
		let questions = []

		ids.map((r) => {
			if (hash.hasOwnProperty(r)) {
				let x = hash[r]
				questions.push(x)
			} else {
				console.log('No Property', questions)
			}
		})

		return questions
	}

	return null
}

export const builderSurveyQuestionsSelector = createSelector(
	mainSelector,
	builderSurveyQuestionIdSelector,
	builderSurveyQuestionHashSelector,
	(state, ids, hash) => {
		let result = getBuilderSurveyQuestions(ids, hash)

		return result
	}
)

export const isSurveyQuestionUsedByCasesSelector = createSelector(mainSelector, (state) => {
	return state?.isQuestionUsed
})
