import React, { Fragment, useState } from 'react'
import { injectIntl } from 'react-intl'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Menu from './Menu'
import { Typography } from '@material-ui/core'

const AdministratorRow = ({ administrator, user }) => {
	const [ minHeight, setMinHeight ] = useState(45)

	return (
		<TableRow key={administrator.id}>
			<TableCell align="left" size="small" style={{ padding: 0 }}>
				<div
					style={{
						minHeight: `${minHeight}px`,
						borderLeft: `solid 4px ${!administrator.enabled ? '#444e5e' : '#86ceb4'}`
					}}
				>
					&nbsp;
				</div>
			</TableCell>
			{/* <TableCell align="left" size="small">
				<Typography variant="subtitle1">{administrator.id}</Typography>
			</TableCell> */}
			<TableCell align="left" size="small">
				<Typography variant="subtitle1">{administrator.email}</Typography>
			</TableCell>
			<TableCell align="left" size="small" style={{ minWidth: '300px' }}>
				<Typography variant="subtitle1">{administrator.name}</Typography>
			</TableCell>

			<TableCell align="right" size="small" style={{ padding: '5px 0' }}>
				<Menu administrator={administrator} />
			</TableCell>
		</TableRow>
	)
}

export default injectIntl(AdministratorRow)
