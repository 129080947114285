import * as api from '../../../apis/terms'
import { createSelector } from 'reselect'

const FETCH_PATIENT_TERMS_REQUESTED = 'FETCH_PATIENT_TERMS_REQUESTED'
const FETCH_PATIENT_TERMS_SUCCESS = 'FETCH_PATIENT_TERMS_SUCCESS'
const FETCH_PATIENT_TERMS_FAILED = 'FETCH_PATIENT_TERMS_FAILED'

export const fetchPatientTerms = (languageId, type) => (dispatch) => {
	dispatch({ type: FETCH_PATIENT_TERMS_REQUESTED, data: {} })
	api.fetchTerms(languageId, type).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_PATIENT_TERMS_SUCCESS, data: response })
		} else {
			return dispatch({ type: FETCH_PATIENT_TERMS_FAILED, data: null })
		}
	})
	return
}

const FETCH_CLINICIAN_TERMS_REQUESTED = 'FETCH_CLINICIAN_TERMS_REQUESTED'
const FETCH_CLINICIAN_TERMS_SUCCESS = 'FETCH_CLINICIAN_TERMS_SUCCESS'
const FETCH_CLINICIAN_TERMS_FAILED = 'FETCH_CLINICIAN_TERMS_FAILED'

export const fetchClinicianTerms = (languageId, type) => (dispatch) => {
	dispatch({ type: FETCH_CLINICIAN_TERMS_REQUESTED, data: {} })
	api.fetchTerms(languageId, type).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_CLINICIAN_TERMS_SUCCESS, data: response })
		} else {
			return dispatch({ type: FETCH_CLINICIAN_TERMS_FAILED, data: null })
		}
	})
	return
}

const UPDATE_TERM_REQUESTED = 'UPDATE_TERM_REQUESTED'
const UPDATE_TERM_SUCCESS = 'UPDATE_TERM_SUCCESS'
const UPDATE_TERM_FAILED = 'UPDATE_TERM_FAILED'

export const updateTerm = (body, languageId, type) => (dispatch) => {
	dispatch({ type: UPDATE_TERM_REQUESTED, data: {} })

	api.updateTerm(body, languageId, type).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({ type: UPDATE_TERM_SUCCESS, data: null })

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.terms.updateSuccessful`, isSuccess: true }
			})
		} else {
			dispatch({ type: UPDATE_TERM_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.terms.updateFailed`, isSuccess: false }
			})
		}
	})
	return
}

const FETCH_CIRCLE_TERMS_REQUESTED = 'FETCH_CIRCLE_TERMS_REQUESTED'
const FETCH_CIRCLE_TERMS_SUCCESS = 'FETCH_CIRCLE_TERMS_SUCCESS'
const FETCH_CIRCLE_TERMS_FAILED = 'FETCH_CIRCLE_TERMS_FAILED'

export const fetchCircleTerms = (circleId, languageId) => (dispatch) => {
	dispatch({ type: FETCH_CIRCLE_TERMS_REQUESTED })
	api.fetchCircleTerms(circleId, languageId).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_CIRCLE_TERMS_SUCCESS, data: response })
		} else {
			return dispatch({ type: FETCH_CIRCLE_TERMS_FAILED, data: null })
		}
	})
	return
}

const UPDATE_CIRCLE_TERM_REQUESTED = 'UPDATE_CIRCLE_TERM_REQUESTED'
const UPDATE_CIRCLE_TERM_SUCCESS = 'UPDATE_CIRCLE_TERM_SUCCESS'
const UPDATE_CIRCLE_TERM_FAILED = 'UPDATE_CIRCLE_TERM_FAILED'

export const updateCircleTerm = (circleId, languageId, body) => (dispatch) => {
	dispatch({ type: UPDATE_CIRCLE_TERM_REQUESTED, data: {} })

	api.updateCircleTerm(circleId, languageId, body).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({ type: UPDATE_CIRCLE_TERM_SUCCESS, data: null })

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.terms.updateSuccessful`, isSuccess: true }
			})
		} else {
			dispatch({ type: UPDATE_CIRCLE_TERM_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.terms.updateFailed`, isSuccess: false }
			})
		}
	})
	return
}

const INCREASE_CIRCLE_TERMS_VERSION_REQUESTED = 'INCREASE_CIRCLE_TERMS_VERSION_REQUESTED'
const INCREASE_CIRCLE_TERMS_VERSION_SUCCESS = 'INCREASE_CIRCLE_TERMS_VERSION_SUCCESS'
const INCREASE_CIRCLE_TERMS_VERSION_FAILED = 'INCREASE_CIRCLE_TERMS_VERSION_FAILED'

export const increaseCircleTermsVersion = (circleId, languageId) => (dispatch) => {
	dispatch({ type: INCREASE_CIRCLE_TERMS_VERSION_REQUESTED, data: {} })

	api.increaseCircleTermsVersion(circleId, languageId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({ type: INCREASE_CIRCLE_TERMS_VERSION_SUCCESS, data: null })
			dispatch(fetchCircleTerms(circleId, languageId))

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.terms.increaseVersionSuccess`, isSuccess: true }
			})
		} else {
			dispatch({ type: INCREASE_CIRCLE_TERMS_VERSION_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.terms.increaseVersionFailed`, isSuccess: false }
			})
		}
	})
	return
}

const RESET_TERM = 'RESET_TERM'

export const resetTerm = () => (dispatch) => {
	dispatch({ type: RESET_TERM, data: {} })
}

const initial = {
	circleTerm: null,
	circleTermsVersionHistory: [],
	clinicianTerm: null,
	patientTerm: null,
	isSaving: false
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_CIRCLE_TERMS_SUCCESS:
			let versionHistory = action.data.termsHistory
			return {
				...state,
				circleTerm: action.data.body,
				circleTermsVersionHistory: versionHistory
			}
		case FETCH_CIRCLE_TERMS_FAILED:
			return {
				...state,
				circleTerm: '',
				circleTermsVersionHistory: []
			}
		case FETCH_PATIENT_TERMS_REQUESTED:
			return { ...state, patientTerm: '' }
		case FETCH_PATIENT_TERMS_SUCCESS:
			return { ...state, patientTerm: action.data.body }
		case FETCH_PATIENT_TERMS_FAILED:
			return { ...state, patientTerm: '' }
		case FETCH_CLINICIAN_TERMS_REQUESTED:
			return { ...state, clinicianTerm: '' }
		case FETCH_CLINICIAN_TERMS_SUCCESS:
			return { ...state, clinicianTerm: action.data.body }
		case FETCH_CLINICIAN_TERMS_FAILED:
			return { ...state, clinicianTerm: '' }
		case RESET_TERM:
			return { ...state, terms: '' }
		case UPDATE_CIRCLE_TERM_SUCCESS:
		case UPDATE_CIRCLE_TERM_FAILED:
		case INCREASE_CIRCLE_TERMS_VERSION_SUCCESS:
			return { ...state, isSaving: false }
		case INCREASE_CIRCLE_TERMS_VERSION_FAILED:
		case UPDATE_TERM_SUCCESS:
		case UPDATE_TERM_FAILED:
			return { ...state, isSaving: false }
		case UPDATE_CIRCLE_TERM_REQUESTED:
		case INCREASE_CIRCLE_TERMS_VERSION_REQUESTED:
			return { ...state, isSaving: true }
		case UPDATE_TERM_REQUESTED:
			return { ...state, isSaving: true }
		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.term

export const clinicianTermSelector = createSelector(mainSelector, (state) => {
	return state && state.clinicianTerm
})

export const patientTermSelector = createSelector(mainSelector, (state) => {
	return state && state.patientTerm
})

export const isSavingTermsSelector = createSelector(mainSelector, (state) => {
	return state && state.isSaving
})

export const circleTermSelector = createSelector(mainSelector, (state) => {
	return state && state.circleTerm
})

export const termsVersionHistorySelector = createSelector(mainSelector, (state) => {
	return state && state.circleTermsVersionHistory
})

export const termsCurrentVerstionNumberSelector = createSelector(mainSelector, (state) => {
	return state && state.circleTermsVersionHistory.slice(-1).pop()?.versionNumber
})

export const termsCurrentVerstionCreatedBySelector = createSelector(mainSelector, (state) => {
	return state && state.circleTermsVersionHistory.slice(-1).pop()?.userName
})

export const termsCurrentVerstionCreateDateSelector = createSelector(mainSelector, (state) => {
	return state && state.circleTermsVersionHistory.slice(-1).pop()?.createDate
})
