import React from "react";
import { TableCell } from "@material-ui/core";
import { Link } from "react-router-dom/cjs/react-router-dom";


const RelatedServiceProviderNameTableCell = ({
    serviceProvider
}) => {
    const getText = () => {
        let result = serviceProvider.serviceProviderName

        return result
    }

    const getBorderColor = () => {
        return serviceProvider.isArchived ? 'lightgrey' : 'transparent'
    }

    const getTextColor = () => {
        return serviceProvider.isArchived ? 'grey' : 'blue'
    }

    return (
        <TableCell>
                <Link
                    to={`/serviceProviders/${serviceProvider.serviceProviderId}`}
                    target="_blank"
                    style={{
                        textDecoration: 'none',
                        color: getTextColor()
                    }}
                >
                    {getText()}
                </Link>
        </TableCell>
    )
}

export default RelatedServiceProviderNameTableCell;
