import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { resendUserInvitation, showModal } from '../../../redux/actions'
import { useDispatch } from 'react-redux'

const ResendInvitationMenuItem = ({ intl, subscriber, onComplete }) => {
	const history = useHistory()
	const dispatch = useDispatch()
	const onClick = () => {
		if (onComplete) {
			onComplete()
		}

		dispatch(resendUserInvitation(subscriber.email))
	}

	return (
		<MenuItem
			onClick={() => {
				onClick()
			}}
		>
			{intl.formatMessage({ id: 'app.subscribers.resendInvitationMenuItemText' })}
		</MenuItem>
	)
}

const enhance = compose(injectIntl)

export default enhance(ResendInvitationMenuItem)
