import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { useTheme, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import Select from 'react-select'
import QuestionContainer from './QuestionContainer'

const RadioSelectQuestion = ({ question, positionDisplay, intl }) => {
	const theme = useTheme()

	return (
		<Grid container direction="column">
			<Grid item>
				{question &&
					<QuestionContainer
						title={question.title}
						positionDisplay={positionDisplay}
						instructions={question.instructions}
						displayInstructionAsIcon={question.displayInstructionAsIcon}
						children={
							<FormControl data-testid="question-preview-select-component" component="fieldset" className="form-control select-component" style={{  marginLeft: '-20px' }}>
								{question.answers && <FormGroup>
									{!question.isDropdown &&
										question.answers
											.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
											.map((r, i) => {
												return (
													<FormControlLabel
														key={i}
														style={{ color: 'black' }}
														color="primary"
														control={<Radio disabled value={r && r.value} key={i} />}
														labelPlacement="end"
														label={
															<Typography variant="subtitle2">
																{r && `${r.label} ( ${r.value} )`}
															</Typography>
														}
													/>
												)
											})}
									{question.isDropdown &&
										<Select
											classNamePrefix='react-select-styled'
											noOptionsMessage={() => null}
											menuPosition="fixed"
											menuPortalTarget={document.body} 
											styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
											isDisabled={true}
											options={question.answers.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))}
											placeholder={intl.formatMessage({id: 'app.survey.question.dropdownPlaceholder'})}
										/>}
								</FormGroup>}
							</FormControl>
						}
					/>}
			</Grid>
		</Grid>
	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(RadioSelectQuestion)
