import React, { useState, useEffect } from 'react'
import { makeStyles, TextField } from "@material-ui/core"
import { compose } from 'recompose'
import { connect } from 'react-redux'


const useLabelStyles = makeStyles({
	root: {
		marginLeft: 0,
		'&$disabled': {
			color: '#6C737B'
		}
	},
	focused: {
		'&$focused': {
			color: 'white'
		}
	},
	disabled: {
		color: '#6C737B'
	}
})

const useInputStyles = makeStyles({
	root: {
		//color: 'white',
		//backgroundColor: 'rgb(36, 48, 65)',
		'&$disabled': {
			color: '#6C737B'
			//backgroundColor: 'rgb(36, 48, 65)'
		}
	},
	focused: {
		//color: 'white',
		//backgroundColor: 'rgb(36, 48, 65)',
		'&$focused': {
			//	color: 'white'
			//backgroundColor: 'rgb(36, 48, 65)'
		}
	},
	disabled: {
		color: '#6C737B'
		//backgroundColor: 'rgb(36, 48, 65)'
	}
})

const helperTextStyles = makeStyles({
	root: {
		marginLeft: 0,
		marginRight: 0
	}
})


const RuleTextField = ({
	backgroundColor = 'rgb(36, 48, 65)',
    fontColor = 'white',
    style,
    ...props
}) => {
	var labelStyles = useLabelStyles()
	var inputStyles = useInputStyles()
    var helperStyles = helperTextStyles()

    return (
        <TextField
            {...props}
            style={{ ...style, backgroundColor: backgroundColor, color: fontColor }}
            InputLabelProps={{
                classes: {
                    root: labelStyles.root,
                    focused: labelStyles.focused,
                    disabled: labelStyles.disabled
                }
            }}
            InputProps={{
                classes: {
                    root: inputStyles.root,
                    focused: inputStyles.focused,
                    disabled: inputStyles.disabled
                }
            }}
            FormHelperTextProps={{ classes: { root: helperStyles.root, contained: helperStyles.root } }}
        />
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
)

export default enhance(RuleTextField)
