import React, { Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { useTheme } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Paper } from '@material-ui/core'
import { reduxForm, reset } from 'redux-form'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import StandardCustomSelector from '../../surveys/components/StandardCustomSelector'
import DefaultFormTextField from '../../../components/DefaultFormTextField'
import { surveySelector } from '../../../redux/selectors'
import { saveSurveySettings } from '../../../redux/actions'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import RelatedProtocols from '../../relatedEntities/components/RelatedProtocols'

const SurveySettings = ({ save, survey, handleSubmit }) => {
	const intl = useIntl()
	const dispatch = useDispatch()
	const theme = useTheme()

	const onSubmitForm = (values) => {
		if (values) {
			save(values.id, values.surveyType, values.promDescription, values.promReferences)
		}
	}

	let light = true
	return (
		<form onSubmit={handleSubmit(onSubmitForm)}>
			<Paper style={{ marginTop: '8px' }}>
				<Grid container direction="column" justify="space-evenly">
					<Grid item style={{ marginRight: '3%', marginTop: '20px' }}>
						<Grid container justify="flex-end" spacing={1}>
							<Grid item>
								<Button
                                    variant="contained"
                                    color="primary"
									type="submit"
								>
									{intl.formatMessage({ id: 'app.protocols.saveChangesButtonText' })}
								</Button>
							</Grid>
						</Grid>
					</Grid>

					<Grid item style={{ height: '100%', width: '100%', marginTop: '8px' }}>
						<div style={{ padding: '0 3%' }}>
							<Grid
								container
								direction="column"
								style={{ backgroundColor: theme.palette.primary.A200, padding: '0 3%' }}
							>
								<Grid item>
									<Grid
										container
										direction="column"
										justify="flex-start"
										style={{ minHeight: '120px' }}
									>
										<Grid item>
											<Typography
												style={{
													fontSize: '20px',
													lineHeight: '25px',
													letterSpacing: '0.25px',
													fontWeight: '700',
													marginBottom: '33px',
													marginTop: '20px'
												}}
											>
												Survey Library Settings
											</Typography>
										</Grid>
										<Grid item style={{ marginTop: '20px' }}>
											<StandardCustomSelector name="surveyType" light={true} />
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</div>
					</Grid>
					<Grid item style={{ height: '100%', width: '100%', marginTop: '19px' }}>
						<div style={{ padding: '0 3%' }}>
							<Grid
								container
								direction="column"
								style={{ backgroundColor: theme.palette.primary.A200, padding: '3%' }}
							>
								<Grid item>
									<Grid container direction="column">
										<Grid item>
											<Typography
												variant="h4"
												style={{
													fontSize: '20px',
													lineHeight: '25px',
													letterSpacing: '0.25px',
													fontWeight: '700',
													marginBottom: '33px',
													marginTop: '20px'
												}}
											>
												PROM Description for Patient report
											</Typography>
										</Grid>

										<Grid item>
											<DefaultFormTextField
												label=""
												variant="filled"
												name="promDescription"
												inputProps={{ maxLength: 1023 }}
												multiline
												rows={4}
												maxLength={1023}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</div>
					</Grid>
					<Grid item style={{ height: '100%', width: '100%', marginTop: '19px' }}>
						<div style={{ padding: '0 3%' }}>
							<Grid
								container
								direction="column"
								style={{ backgroundColor: theme.palette.primary.A200, padding: '3%' }}
							>
								<Grid item>
									<Grid container direction="column">
										<Grid item>
											<Typography
												variant="h4"
												style={{
													fontSize: '20px',
													lineHeight: '25px',
													letterSpacing: '0.25px',
													fontWeight: '700',
													marginBottom: '33px',
													marginTop: '20px'
												}}
											>
												PROM References for Patient report
											</Typography>
										</Grid>

										<Grid item>
											<DefaultFormTextField
												label=""
												variant="filled"
												name="promReferences"
												inputProps={{ maxLength: 1023 }}
												multiline
												rows={4}
												maxLength={1023}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</div>
					</Grid>
					<Grid item style={{ height: '100%', width: '100%', marginTop: '19px', marginBottom: '19px' }}>
						<div style={{padding: '0 3%'}}>
							<Grid
								container
								direction="column"
								style={{
									backgroundColor: theme.palette.primary.A200, 
									padding: '0 3%',
									paddingTop: '20px'
								}}
							>
								<Grid item>
									{
										!!survey
										&& <RelatedProtocols
											surveyId={survey.id}
										/>
									}
								</Grid>
							</Grid>
						</div>
					</Grid>
				</Grid>
			</Paper>{' '}
		</form>
	)
}

const mapStateToProps = (state, ownProps) => ({
	initialValues: surveySelector(state),
	survey: surveySelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	save: (id, surveyType, promDescripton, promReferences) =>
		dispatch(saveSurveySettings(id, surveyType, promDescripton, promReferences))
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'surveySettings',
		enableReinitialize: true
	})
)

export default enhance(SurveySettings)
