import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import DefaultFormTextField from '../../DefaultFormTextField'
import { required } from '../../../utilities/validators'
import { useSelector, shallowEqual } from 'react-redux'
import { languageNameSelector } from '../../../redux/selectors'
const requiredValidator = required

export default forwardRef((props, ref) => {
	const { onDeleteCallback, answer, showScoreEntry } = props
	const [ categoryText, setCategoryText ] = useState(null)
	const [ id, setId ] = useState('')
	let languages = useSelector(languageNameSelector, shallowEqual)
	useImperativeHandle(ref, () => ({
		getAnswer() {
			return { question: categoryText, canonicalId: answer.data.baseId }
		}
	}))
	useEffect(
		() => {
			if (answer) {
				setId(answer.data.answerId)
				if (answer.data) {
					setCategoryText(answer.data.label)
				}
			}
			answer.ref = ref
		},
		[ answer, ref ]
	)

	return (
		<Grid container style={{ width: '100%' }} alignItems="center">
			<Grid item style={{ flex: 1, paddingRight: '5px' }}>
				<Grid item style={{ width: '100%' }}>
					<Typography variant="subtitle2">
						Category ({languages(1)}): {answer.data.baseLabel}
					</Typography>
				</Grid>
				<DefaultFormTextField
					label={`Category (${languages(answer.data.languageId)})`}
					validators={[ requiredValidator ]}
					name={id}
					variant="filled"
				/>
			</Grid>
		</Grid>
	)
})
