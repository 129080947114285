import React, { Fragment, createRef, useState, useEffect } from 'react'
import { Tooltip, Typography, makeStyles, Button } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import InfoIcon from '@material-ui/icons/Info'
import { useIntl } from 'react-intl'

const useTooltipStyles = makeStyles(theme => ({
	tooltip: {
	  backgroundColor: '#e6f2ff',
	  color: 'black',
	  boxShadow: '0 1px 1px #ccc', 
	  maxWidth: '768px',
	  width: 'auto'
	}
  }))

export default ({ title, instructions, positionDisplay, children, displayInstructionAsIcon }) => {
	let ref = createRef()
	const [ width, setWidth ] = useState()
	const tooltipStyles = useTooltipStyles()
	const intl = useIntl()

	useEffect(() => {
		if (ref.current) {
			setWidth(ref.current.offsetHeight)
		}
	}, [])
	return (
		<Fragment>
			{title && (
				<Grid container direction='column' style={{ width: '100%' }}>
					<Grid item style={{ width: '100%' }}>
						<Grid container direction='row' spacing={1}>
							<Grid item>
								<Typography variant='subtitle1' style={{ fontSize: '17px', letterSpacing: '0.25px' }}>
									{title}
								</Typography>
							</Grid>
							{displayInstructionAsIcon
							&& instructions
							&& instructions.trim()
							&&
							<Grid item style={{ display: 'flex', alignItems: 'center'}}>
								<Tooltip enterTouchDelay={0}
									title={
										<Typography color="inherit" style={{ fontSize: '12px' }}>
											<span dangerouslySetInnerHTML={{ __html: instructions }} />
										</Typography>
									}
									classes={{
										tooltip: tooltipStyles.tooltip
									}}
								>
									<InfoIcon style={{ fontSize: '20px', color: '#007aff', marginRight: '5px', marginTop: '6px' }}  />
								</Tooltip>
								<Grid item>
								<Button
								data-testid="writable-question-instructions"
								style={{ display: 'flex', textAlign: 'left', width: 'auto' }} >
								<Typography
									data-testid="writable-question-instructions-helper"
									variant='body2'
									style={{ opacity: '0.8', color: '#000' }}>
									{intl.formatMessage({ id: 'app.survey.questions.instructionIconHelperText' })}
								</Typography>
							</Button>
							</Grid>
							</Grid>}
						</Grid>

					</Grid>
					{instructions
						&& !displayInstructionAsIcon
						&& (
							<Grid item style={{ marginTop: '16px' }}>
								<Grid container>
									<Grid item>
										<Typography
											variant='subtitle2'
											style={{ fontSize: '17px', letterSpacing: '0.51px' }}
										>
											<span dangerouslySetInnerHTML={{ __html: instructions }} />
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						)}
					<Grid item style={{ marginTop: '5px', width: '100%' }}>
						{children && (
							<Grid container style={{ width: '100%' }}>
								<Grid item xs={12} lg={8}>
									{children}
								</Grid>
							</Grid>
						)}
					</Grid>
				</Grid>
			)}
		</Fragment>
	)
}
