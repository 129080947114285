import React, { useState, useEffect, forwardRef } from 'react'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import Pencil from '../../../assets/pencil'
import { useTheme } from '@material-ui/core'
import { useDispatch, connect } from 'react-redux'
import { compose } from 'recompose'
import { setImage } from '../../../redux/actions'
import { useImperativeHandle } from 'react'

export default forwardRef((props, ref) => {
	const theme = useTheme()
	const { defaultPath, dark, disabled } = props

	const [previewFile, setPreviewFile] = useState('')
	const [fileToSend, setFileToSend] = useState('')

	var file = ''

	useImperativeHandle(ref, () => ({
		getFile() {
			return fileToSend
		}
	}))

	const setAnalogImage = (e) => {
		file = e.target.files[0]
		setFileToSend(file)
		const reader = new FileReader()

		reader.onloadend = () => {
			setPreviewFile(reader.result)
		}
		if (file) {
			reader.readAsDataURL(file)
			setPreviewFile(reader.result)
		} else {
			setPreviewFile('')
		}
		// e.target.files = null
	}

	return (
		<Grid item style={{ width: '100%' }}>
			<Grid container justify='centr' style={{ maxHeight: '10px' }}>
				<Grid
					item
					onClick={() => {
						if (!!disabled) {
							return
						}
						
						document.getElementById(props.name).click()
					}}
				>
					<input
						id={props.name}
						type='file'
						accept='.jpg, .png, .bmp'
						name={props.name}
						style={{ display: 'none' }}
						onChange={setAnalogImage}
						disabled={disabled}
					/>
					{!previewFile && !defaultPath && (
						<div
							style={{
								cursor: 'pointer',
								height: '32px',
								width: '32px',
								backgroundColor: dark ? 'transparent' : '#CDD9E8'
							}}>
							<Pencil fill={theme.palette.primary.A800} height='24px' viewBox='0 -6 16 28' />
						</div>
					)}
					{previewFile && (
						<div
							style={{
								cursor: 'pointer'
							}}>
							<img src={previewFile} height='32px' width='32px' />
						</div>
					)}
					{defaultPath && !previewFile && (
						<div
							style={{
								cursor: 'pointer'
							}}>
							<img src={defaultPath} height='32px' width='32px' />
						</div>
					)}
				</Grid>
			</Grid>
		</Grid>
	)
})
