import React, { Fragment, useState } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { useTheme } from '@material-ui/core/styles'
import BundleCollectionAutoComplete from './BundleCollectionAutoComplete'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import { isSavingSelector } from '../../../redux/selectors'
import { startSaving } from '../../../redux/actions'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'

const SearchBundle = ({ position, onAddCallback, intl }) => {
	const theme = useTheme()
	const dispatch = useDispatch()
	const isSaving = useSelector(isSavingSelector)
	const [ addBundle, setAddBundle ] = useState(false)
	const [ addedBundle, setAddedBundle ] = useState(null)

	const handleBundleSelected = (group) => {
		setAddBundle(true)
		setAddedBundle(group)
	}
	const handleBundleCleared = () => {
		setAddBundle(false)
	}

	const addBundleCallback = () => {
		if (addedBundle) {
			dispatch(startSaving())
			onAddCallback(addedBundle.id, position)
		}
	}

	return (
		<Grid container style={{ minHeight: '100px', padding: '15px' }}>
			<Grid item style={{ flexGrow: '100', border: 'dashed 1px black', padding: '16px', borderRadius: '5px' }}>
				<Grid container alignItems="center">
					<Grid item xs style={{ flexGrow: '100' }}>
						<BundleCollectionAutoComplete
							label={intl.formatMessage({ id: 'app.bundles.search' })}
							onQuestionSelectedCallback={handleBundleSelected}
							onQuestionClearedCallback={handleBundleCleared}
						/>
					</Grid>
					<Grid item style={{ paddingLeft: '16px' }}>
						<Button
							disableRipple={true}
							disableFocusRipple={true}
							disabled={isSaving}
							onClick={addBundleCallback}
							variant="text"
						>
								ADD
							<AddCircleOutlineIcon
								style={{
									fontSize: '24px',
									fontWeight: '400',
									marginLeft: '5px'
								}}
							/>
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

const enhance = compose(injectIntl)

export default enhance(SearchBundle)
