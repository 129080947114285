import { authenticatedFetch } from './base'

export const fetchTimeZones = () => {
	return authenticatedFetch(`/api/timezone`, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}
