import React from 'react'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { sortSponsorsDirectionSelector, sortSponsorsFieldSelector } from '../../../redux/selectors'
import { setSponsorSort } from '../../../redux/actions'
import { connect } from 'react-redux'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const SponsorHeader = ({ intl, isDescending, changeSort, field }) => {
	return (
		<TableRow>
			<TableCell align="left" sortDirection={false} style={{ border: 'none' }}>
				<TableSortLabel
					active={field === 'name'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('name', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="subtitle1"
						style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase',
							minWidth: '200px'
						}}
					>
						{intl.formatMessage({ id: 'app.sponsors.nameColumnLabel' })}
					</Typography>
				</TableSortLabel>
			</TableCell>
            <TableCell align="left" sortDirection={false} style={{ border: 'none' }}>
                <TableSortLabel
					active={field === 'stripeCustomerId'}
                    direction={isDescending ? 'desc' : 'asc'}
                    onClick={(e) => changeSort('stripeCustomerId', !isDescending)}
                    IconComponent={ExpandMoreIcon}
                >
                    <Typography
                        variant="subtitle1"
                        style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase'
						}}
                    >
						{intl.formatMessage({ id: 'app.sponsors.stripeCustomerIdColumnLabel' })}
                    </Typography>
                </TableSortLabel>
			</TableCell>
			<TableCell align="left" sortDirection={false} style={{ border: 'none' }}>
                <TableSortLabel
					active={field === 'stripeSubscriptionId'}
                    direction={isDescending ? 'desc' : 'asc'}
                    onClick={(e) => changeSort('stripeSubscriptionId', !isDescending)}
                    IconComponent={ExpandMoreIcon}
                >
                    <Typography
                        variant="subtitle1"
                        style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase'
						}}
                    >
						{intl.formatMessage({ id: 'app.sponsors.stripeSubscriptionIdColumnLabel' })}
                    </Typography>
                </TableSortLabel>
			</TableCell>
			<TableCell align="left" sortDirection={false} style={{ border: 'none' }}>
                <TableSortLabel
					active={field === 'stripeAccount'}
                    direction={isDescending ? 'desc' : 'asc'}
                    onClick={(e) => changeSort('stripeAccount', !isDescending)}
                    IconComponent={ExpandMoreIcon}
                >
                    <Typography
                        variant="subtitle1"
                        style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase'
						}}
                    >
						{intl.formatMessage({ id: 'app.sponsors.stripeAccountColumnLabel' })}
                    </Typography>
                </TableSortLabel>
			</TableCell>
            <TableCell align="left" sortDirection={false} style={{ border: 'none' }}>
                <TableSortLabel
					active={field === 'sponsoredCirclesCount'}
                    direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('sponsoredCirclesCount', !isDescending)}
                    IconComponent={ExpandMoreIcon}
                >
                    <Typography
                        variant="subtitle1"
                        style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase'
						}}
                    >
						{intl.formatMessage({ id: 'app.sponsors.sponsoredCirclesColumnLabel' })}
                    </Typography>
                </TableSortLabel>
            </TableCell>
			<TableCell align="left" style={{ border: 'none' }}>
				<TableSortLabel
					active={field === 'sponsoredUsersCount'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('sponsoredUsersCount', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="subtitle1"
						style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase'
						}}
					>
						{intl.formatMessage({ id: 'app.sponsors.sponsoredSubscribersColumnLabel' })}
					</Typography>
				</TableSortLabel>
			</TableCell>
			<TableCell align="left" style={{ border: 'none' }}>
				<TableSortLabel
					active={field === 'sponsoredCasesCount'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('sponsoredCasesCount', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="subtitle1"
						style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase'
						}}
					>
						{intl.formatMessage({ id: 'app.sponsors.sponsoredCasesColumnLabel' })}
					</Typography>
				</TableSortLabel>
			</TableCell>
            <TableCell align="left" style={{ border: 'none', width: '201px', maxWidth: '201px' }}>
				<TableSortLabel
					sortDirection={true}
					active={field === 'modifiedDateSeconds'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('modifiedDateSeconds', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="subtitle1"
						style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase'
						}}
					>
						{intl.formatMessage({ id: 'app.sponsors.modifiedColumnLabel' })}
					</Typography>
				</TableSortLabel>
			</TableCell>
			<TableCell align="left" style={{ border: 'none' }}>
				<TableSortLabel
					active={field === 'isArchived'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('isArchived', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="subtitle1"
						style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase'
						}}
					>
						{intl.formatMessage({ id: 'app.sponsors.statusColumnLabel' })}
					</Typography>
				</TableSortLabel>
			</TableCell>
			<TableCell align="left" style={{ border: 'none' }}>
				<TableSortLabel
					active={field === 'isExecutiveUsage'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('isExecutiveUsage', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="subtitle1"
						style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase'
						}}
					>
						{intl.formatMessage({ id: 'app.sponsors.executiveUsageColumnLabel' })}
					</Typography>
				</TableSortLabel>
			</TableCell>
			<TableCell align="left" style={{ width: '10px', padding: '6px 0px 6px 0px', border: 'none' }} />
		</TableRow>
	)
}

const mapStateToProps = (state, ownProps) => ({
	isDescending: sortSponsorsDirectionSelector(state),
	field: sortSponsorsFieldSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	changeSort: (field, direction) => dispatch(setSponsorSort(field, direction))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(SponsorHeader)
