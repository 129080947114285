import React, { useEffect, useState, Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import { compose } from 'recompose'
import { connect, useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import LockIcon from '@material-ui/icons/Lock'
import {
	fetchSurvey,
	showModal,
	hideModal,
	fetchBuilderSurvey,
	addQuestion,
	addSurveyBundle,
	swapQuestionPosition,
	updateEntityQuestion,
	fetchLanguages,
	addExistingQuestion,
	deActivateSurvey,
	activateSurvey,
	changeBundleStateRelatedEntities,
	fetchCloneSurveyQuestion,
	fetchCancelCloneSurveyQuestion
} from '../../../redux/actions'
import {
	surveySelector,
	builderSurveyQuestionListSelector,
	builderSurveyQuestionsSelector,
	builderSurveyQuestionIdSelector
} from '../../../redux/selectors'
import CreateIcon from '@material-ui/icons/Create'
import { useTheme } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import LanguageNavigator from './LanguageNavigator'
import Container from '../../../components/questionBuilder/Container'
import ViewContainer from '../../../components/questionViewer/Container'
import PrivateRoute from '../../../routes/PrivateRoute'
import SurveySettings from './SurveySettings'
import SurveyViewerContainer from '../../surveyViewer/SurveyViewerContainer'
import EditSurveyModal from './EditSurveyModal'
import CloneSurveyModal from './CloneSurveyModal'
import PreviewSurveyModal from './PreviewSurveyModal'
import SurveyName from './SurveyName'
import RemoveSurveyQuestionModal from './RemoveSurveyQuestionModal'
import ChangeSurveyQuestionStateModal from './ChangeSurveyQuestionStateModal'
import SurveyAudit from './SurveyAudit'
import EntityAuditCommentModal from "../../../components/entityAudit/EntityAuditCommentModal"
import { isAuditCommentsRequired } from '../../../utilities/auditUtils'


const SurveyDetail = ({
	intl,
	edit,
	loadSurvey,
	loadBuilderSurvey,
	clone,
	survey,
	questionList,
	questionCollection,
	addExistingQuestion,
	addSurveyQuestion,
	addSurveyBundle,
	swapQuestionPosition,
	removeSurveyQuestion,
	updateSurveyQuestion,
	changeSurveyQuestionState,
	changeBundleStateRelatedEntities
}) => {
	const match = useRouteMatch()
	const theme = useTheme()
	const [ showEdit, setShowEdit ] = useState(false)
	const [ tabIndex, setTabIndex ] = useState(1)
	const dispatch = useDispatch()

	useEffect(
		() => {
			if (match.params.surveyId) {
				dispatch(fetchLanguages())

				loadSurvey(match.params.surveyId)
				if (match.params.languageId) {
					loadBuilderSurvey(match.params.surveyId, match.params.languageId)
				}
			}
		},
		[ match.params.surveyId, match.params.languageId ]
	)
	
	const handleChangeTabIndex = (_, value) => {
		setTabIndex(value)
	}

	const cloneSurvey = (survey) => {
		dispatch(showModal(CloneSurveyModal, { survey: survey }))
	}

	const changeActivation = (survey) => {
		if (survey) {
			if (survey.isActive) {
				dispatch(deActivateSurvey(survey.id))
			} else {
				dispatch(activateSurvey(survey.id))
			}
		}
	}

	const onChangeBundleStateRelatedEntities = (question, newState) => {
		if (isAuditCommentsRequired())
		{
			dispatch(
				showModal(
					EntityAuditCommentModal,
					{
						entityId: match.params.surveyId,
						entityTypeId: 16, //survey
						saveCallback: (commentsObj) => {
							dispatch(hideModal())
							changeBundleStateRelatedEntities(question, newState, commentsObj)
						}
					}
				)
			)
		}
		else {
			changeBundleStateRelatedEntities(question, newState)
		}
	}

	const onChangeSurveyQuestionState = (question, newState, allEntities) => {
		changeSurveyQuestionState(question, newState, allEntities)
	}

	return (
		<div
			style={{
				padding: '3%'
			}}
		>
			<Grid
				container
				justify="space-between"
				style={{
					width: '100%'
				}}
			>
				<Grid item style={{ width: '100%', padding: '0', paddingBottom: '16px' }}>
					<Grid container justify="flex-end" style={{ width: '100%' }} spacing={1}>
						<Grid item style={{ flex: 1 }}>
							&nbsp;
						</Grid>
						<Grid item>
							<Button
								onClick={() => changeActivation(survey)}
								variant="outlined"
								style={{
									color: theme.palette.secondary.dark,
									border: `solid 1px ${theme.palette.secondary.dark}`,
									fontSize: '15px',
									lineHeight: '16px',
									letterSpacing: '1.29px',
									height: '36px'
								}}
							>
								{survey && survey.isActive ? (
									intl.formatMessage({ id: 'app.survey.deactivateButtonLabel' })
								) : (
									intl.formatMessage({ id: 'app.survey.activateButtonLabel' })
								)}
							</Button>
						</Grid>

						{survey &&
						!survey.isLocked && (
							<Fragment>
								<Grid item>
									<Button
										onClick={() =>
											dispatch(
												showModal(PreviewSurveyModal, {
													list: questionList,
													collection: questionCollection
												})
											)}
										variant="outlined"
										color="secondary"
									>
										{intl.formatMessage({ id: 'app.survey.previewButtonLabel' })}
									</Button>
								</Grid>
							</Fragment>
						)}
					</Grid>
				</Grid>
			</Grid>

			<Grid container direction="column" style={{ padding: '0' }}>
				<Grid item style={{ width: '100%', cursor: showEdit ? 'pointer' : '' }}>
					<Grid container justify="space-between" style={{ width: '100%' }} alignItems="center">
						<Grid item style={{ flex: 0.9 }}>
							<Grid container direction="column">
								<Grid item>
									<Typography variant="h1" style={{ wordBreak: 'break-word' }}>
										{survey && survey.title && survey.title.length > 200 ? (
											survey.title.substring(0, 200) + '...'
										) : (
											survey && survey.title
										)}
									</Typography>
								</Grid>
								<Grid item>
									<Typography
										variant="subtitle1"
										style={{ wordBreak: 'break-word', marginTop: '-20px' }}
									>
										{survey && survey.description}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid item style={{ width: '100%', cursor: false ? 'pointer' : '', marginBottom: '16px', marginTop: '16px' }}>
					<Grid container justify="space-between" alignItems="center">
						<Grid item xs={12}>
							<Tabs
								value={tabIndex}
								onChange={handleChangeTabIndex}
								style={{
									borderBottom: '1px solid #505050'
								}}
							>
								<Tab label={ intl.formatMessage({ id: 'app.survey.contentButtonText' }) } value={1} style={{fontSize: "20px"}} />
								<Tab label={ intl.formatMessage({ id: 'app.survey.auditButtonText' }) } value={2} style={{fontSize: "20px"}} />
							</Tabs>
						</Grid>
					</Grid>
				</Grid>

				<Grid item>
					{
						tabIndex === 1
						&& <Grid container direction="row" spacing={1}>
							<Grid item style={{ flex: 0.3 }}>
								<LanguageNavigator survey={survey} />
							</Grid>
							<Grid item style={{ flex: 0.7, height: '100%' }}>
								<Grid container direction="column">
									<Grid item>
										<PrivateRoute
											exact={true}
											path="/surveys/:surveyId/details"
											component={SurveySettings}
										/>

										<PrivateRoute
											exact={true}
											path="/surveys/:surveyId/languages/:languageId"
											survey={survey}
											component={SurveyName}
										/>

										{survey &&
										!survey.isLocked &&
										match.params.languageId == 1 && (
											<PrivateRoute
												exact={true}
												path="/surveys/:surveyId/languages/:languageId"
												survey={survey}
												list={questionList}
												collection={questionCollection}
												onAddExistingCallback={addExistingQuestion}
												onAddCallback={addSurveyQuestion}
												onAddBundleCallback={addSurveyBundle}
												onSwapCallback={swapQuestionPosition}
												onRemoveCallback={removeSurveyQuestion}
												onUpdateCallback={updateSurveyQuestion}
												suppressNonDiscreteQuestions={false}
												showScoreEntry={false}
												headerTitle={intl.formatMessage({ id: 'app.survey.surveyContentHeader' })}
												component={Container}
												propertyStateName={"state"}
												onChangeBundleStateCallback={onChangeBundleStateRelatedEntities}
												onChangeQuestionStateCallback={onChangeSurveyQuestionState}
												sameStateAllEntities={false}
												canSetInUseStateAllEntities={true}
												canSetNotInUseStateAllEntities={true}
												canSetArchiveStateAllEntities={true}
												canCloneQuestion={true}
											/>
										)}
										{survey &&
										match.params.languageId == 1 &&
										survey.isLocked && (
											<Paper style={{ minHeight: '100vh', padding: '2% 3% 2% 3%' }}>
												<PrivateRoute
													exact={true}
													path="/surveys/:surveyId/languages/:languageId"
													survey={survey}
													showScoreEntry={false}
													component={SurveyViewerContainer}
												/>
											</Paper>
										)}
										{survey &&
										match.params.languageId != 1 && (
											<PrivateRoute
												exact={true}
												path="/surveys/:surveyId/languages/:languageId"
												survey={survey}
												list={questionList}
												collection={questionCollection}
												onUpdateCallback={updateSurveyQuestion}
												languageId={match.params.languageId}
												headerTitle={intl.formatMessage({ id: 'app.survey.surveyContentHeader' })}
												component={ViewContainer}
												showScoreEntry={false}
											/>
										)}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					}
					{
						tabIndex === 2
						&& <SurveyAudit />
					}
				</Grid>
			</Grid>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	survey: surveySelector(state),
	questionList: builderSurveyQuestionListSelector(state),
	questionCollection: builderSurveyQuestionsSelector(state),
	ids: builderSurveyQuestionIdSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	loadSurvey: (id) => dispatch(fetchSurvey(id)),
	addExistingQuestion: (id, type, position) => 
	{
		if (isAuditCommentsRequired())
		{
			dispatch(
				showModal(
					EntityAuditCommentModal,
					{
						entityId: ownProps.match.params.surveyId,
						entityTypeId: 16, //survey
						saveCallback: (commentsObj) => {
							dispatch(hideModal())
							dispatch(addExistingQuestion(id, type, position, ownProps.match.params.surveyId, commentsObj))
						}
					}
				)
			)
		}
		else
		{
			dispatch(addExistingQuestion(id, type, position, ownProps.match.params.surveyId))
		}
	},
	addSurveyQuestion: (data, position, isClone, commentsObj) => dispatch(addQuestion(data, position, ownProps.match.params.surveyId, isClone, commentsObj)),
	addSurveyBundle: (id, position, commentsObj) => dispatch(addSurveyBundle(id, position, ownProps.match.params.surveyId, commentsObj)),
	removeSurveyQuestion: (question) => dispatch(
		showModal(
			RemoveSurveyQuestionModal,
			{
				question: question,
				surveyId: ownProps.match.params.surveyId
			}
		)
	),
	swapQuestionPosition: (nodeA, nodeB) =>
		dispatch(swapQuestionPosition(nodeA, nodeB, ownProps.match.params.surveyId)),
	loadBuilderSurvey: (id, languageId) => dispatch(fetchBuilderSurvey(id, null, languageId)),
	updateSurveyQuestion: (question) =>
	{
		if (isAuditCommentsRequired())
		{
			dispatch(
				showModal(
					EntityAuditCommentModal,
					{
						entityId: ownProps.match.params.surveyId,
						entityTypeId: 16, //survey
						saveCallback: (commentsObj) => {
							dispatch(hideModal())
							dispatch(updateEntityQuestion(
								question,
								ownProps.match.params.languageId,
								fetchBuilderSurvey,
								ownProps.match.params.surveyId,
								ownProps.match.params.surveyId,
								null,
								commentsObj))
						}
					}
				)
			)
		}
		else
		{
			dispatch(updateEntityQuestion(
				question,
				ownProps.match.params.languageId,
				fetchBuilderSurvey,
				ownProps.match.params.surveyId,
				ownProps.match.params.surveyId,
				null))
		}
	},
	edit: () => dispatch(showModal(EditSurveyModal, { id: ownProps.match.params.surveyId, survey: ownProps.survey })),
	changeSurveyQuestionState: (question, newState, allEntities) => dispatch(
		showModal(
			ChangeSurveyQuestionStateModal,
			{
				surveyId: ownProps.match.params.surveyId,
				languageId: ownProps.match.params.languageId,
				question, question,
				newState: newState,
				allEntities: allEntities,
				entityId: ownProps.match.params.surveyId,
				entityTypeId: 16 //survey
			}
		)
	),
	changeBundleStateRelatedEntities: (question, newState, commentsObj) => dispatch(
		changeBundleStateRelatedEntities(
			ownProps.match.params.surveyId,
			ownProps.match.params.languageId,
			question,
			newState,
			commentsObj
		)
	)
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(SurveyDetail)
