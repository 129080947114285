import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { useRouteMatch } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import { Typography, jssPreset } from '@material-ui/core'
import { Link } from 'react-router-dom'
import LanguageRow from './LanguageRow'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { languageListSelector } from '../../../redux/selectors'
import { fetchLanguages } from '../../../redux/actions'
import { useIntl } from 'react-intl'
import { useTheme } from '@material-ui/core'

export default ({ currentLanguage, onLanguageChange }) => {
	const languages = useSelector(languageListSelector, shallowEqual)
	const match = useRouteMatch()
	const dispatch = useDispatch()
	const theme = useTheme()
	const intl = useIntl()

	const getBackgroundColor = (language) => {
		if (language.id == currentLanguage) {
			return theme.palette.secondary.A600
		} else {
			return '#fff'
		}
	}

	const getFontColor = (language) => {
		if (language.id == currentLanguage) {
			return '#fff'
		} else {
			return '#000'
		}

		return '#000'
	}

	const getBorderColor = (language) => {
		if (language.id == currentLanguage) {
			return theme.palette.primary.A800
		} else {
			return '#fff'
		}
	}
	useEffect(() => {
		dispatch(fetchLanguages())
	}, [])

	return (
		<Paper>
			<Grid container direction="column" style={{ width: '100%' }}>
				<Grid
					item
					style={{
						width: '100%',
						borderBottom: 'solid 1px rgba(0,0,0,0.5)',
						minHeight: '100px',
						padding: '0 11px 0 20px'
					}}
				>
					<Grid container justify="space-between" alignItems="center" style={{ minHeight: '100px' }}>
						<Grid item>
							<Typography variant="subtitle1" data-testid="testid-language-label">
								{intl.formatMessage({ id: 'app.survey.languageLabel' })}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Grid container direction="column">
						{languages &&
							languages.filter((d) => d.id === 1).map((r, i) => (
								<Grid
									item
									style={{
										padding: '0 11px 0 20px',
										backgroundColor: getBackgroundColor(r),
										color: getFontColor(r),
										borderLeft: `solid 4px ${getBorderColor(r)}`,
										borderBottom: 'solid 1px black',
										cursor: 'pointer'
									}}
									onClick={() => onLanguageChange(r.id)}
									key={r.id}
								>
									<LanguageRow language={r} onLanguageChange={onLanguageChange} />
								</Grid>
							))}
					</Grid>
				</Grid>
				<Grid item>
					<Grid container direction="column">
						{languages &&
							languages
								.filter((d) => d.id != 1)
								.sort((a, b) => {
									return a.label < b.label ? -1 : 1
								})
								.map((r, i) => (
									<Grid
										item
										style={{
											padding: '0 11px 0 20px',
											backgroundColor: getBackgroundColor(r),
											color: getFontColor(r),
											borderLeft: `solid 4px ${getBorderColor(r)}`,
											borderBottom: 'solid 1px black',
											cursor: 'pointer'
										}}
										key={r.id}
										onClick={() => onLanguageChange(r.id)}
									>
										<LanguageRow language={r} />
									</Grid>
								))}
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	)
}
