import React, { useEffect, forwardRef, useImperativeHandle } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { uuidv4 } from '../../utilities/utilities'
import DefaultFormTextField from '../DefaultFormTextField'
import { useStore } from 'react-redux'
import { formValueSelector } from 'redux-form'
import { required } from '../../utilities/validators'
const requiredValidator = required
const selector = formValueSelector('createContent')

export default forwardRef((props, ref) => {
	const store = useStore()

	useImperativeHandle(ref, () => ({
		getQuestion() {
			return {
				id: uuidv4().replace('-', ''),
				title: '',
				type: 'content',
				subtitle: selector(store.getState(), 'subtitle'),
				bodyCopy: selector(store.getState(), 'bodyCopy')
			}
		}
	}))

	useEffect(() => {
		var el = document.getElementsByName('subtitle')
		if (el != null) {
			el[0].focus()
		}
	}, [])

	return (
		<Grid container direction="column" alignItems="flex-start">
			<Grid item>
				<Typography>Readonly Content</Typography>
			</Grid>

			<Grid item style={{ width: '100%', paddingBottom: '20px' }}>
				<DefaultFormTextField
					label="Subtitle"
					variant="filled"
					name="subtitle"
					fullWidth
					validators={[ requiredValidator ]}
					inputProps={{ maxLength: 2047 }}
					maxLength={2047}
					rows="5"
				/>
			</Grid>
			<Grid item style={{ width: '100%', paddingBottom: '20px' }}>
				<DefaultFormTextField
					label="Body Copy"
					variant="filled"
					name="bodyCopy"
					fullWidth
					multiline={true}
					rows="5"
					validators={[ requiredValidator ]}
					inputProps={{ maxLength: 2047 }}
					maxLength={2047}
				/>
			</Grid>
		</Grid>
	)
})
