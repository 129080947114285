import React from 'react'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Radio from '@material-ui/core/Radio'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { Field, reduxForm } from 'redux-form'
import Lock from '@material-ui/icons/Lock'
import LockOpen from '@material-ui/icons/LockOpen'
import useTheme from '@material-ui/core/styles/useTheme'
import SponsoredValue from './SponsoredValue'


const SponsorSelection = ({ intl, availableUsers, availableCases }) => {
	var theme = useTheme()
	return (
		<Grid container direction="row" spacing={2}>
			<Grid item style={{ flex: 0.5 }}>
				<Paper
					elevation={0}
					style={{
						borderRadius: 0,
						padding: 0,
						margin: 0,
						height: '110px',
						backgroundColor: 'rgb(24, 35, 48)'
					}}
				>
					<SponsoredValue
						label={intl.formatMessage({ id: 'app.general.cases' })}
						entityType={intl.formatMessage({ id: 'app.general.cases' })}
						available={availableCases}
						name="numberCases"
					/>
				</Paper>
			</Grid>
			<Grid item style={{ flex: 0.5 }}>
				<Paper
					elevation={0}
					style={{
						borderRadius: 0,
						padding: 0,
						margin: 0,
						height: '110px',
						backgroundColor: 'rgb(24, 35, 48)'
					}}
				>
					<SponsoredValue
						label={intl.formatMessage({ id: 'app.general.users' })}
						entityType={intl.formatMessage({ id: 'app.general.users' })}
						available={availableUsers}
						name="numberUsers"
					/>
				</Paper>
			</Grid>
		</Grid>
	)
}

const enhance = compose(injectIntl)

export default enhance(SponsorSelection)
