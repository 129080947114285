import React from 'react'
import { Grid, TableCell, TableRow, Typography } from '@material-ui/core'
import { injectIntl, FormattedDate } from 'react-intl'

export default ({ subscription }) => {
	return (
		<TableRow>
			<TableCell align="left" style={{ padding: '5px 0' }} />
			<TableCell></TableCell>
			<TableCell align="left">
                <Grid container>
                    <Grid item>
                        <Typography
                            variant="body2"
                            style={{
								textAlign: 'left',
								fontWeight: '400',
								fontSize: '17px',
								lineHeight: '22px',
								letterSpacing: '0.51px'
							}}
                        >
                            <FormattedDate value={subscription.startDate} />
                        </Typography>
					</Grid>
					<Grid item style={{ margin: '5px' }}>&mdash;</Grid>
                    <Grid item>
                        <Typography
                            variant="body2"
                            style={{
								textAlign: 'left',
								fontWeight: '400',
								fontSize: '17px',
								lineHeight: '22px',
								letterSpacing: '0.51px'
							}}
						>
							<FormattedDate value={subscription.endDate} />
                        </Typography>
                    </Grid>
                </Grid>
			</TableCell>
            <TableCell
                data-testid="member-row-2fa-state"
                align="left">
				<Grid container direction="column">
                    <Grid item>
                        <Typography
                            variant="body2"
                            style={{
								textAlign: 'left',
								fontWeight: '400',
								fontSize: '17px',
								lineHeight: '22px',
								letterSpacing: '0.51px'
							}}
                        >
							<FormattedDate year='numeric' month='long' day='2-digit' value={subscription.modifiedDate} />
                        </Typography>
					</Grid>
                    <Grid item>
                        <Typography
                            variant="body2"
                            style={{
								textAlign: 'left',
								fontWeight: '400',
								fontSize: '17px',
								lineHeight: '22px',
								letterSpacing: '0.51px'
							}}
                        >
							{subscription.modifiedByName}
                        </Typography>
                    </Grid>
				</Grid>
            </TableCell>
            <TableCell align="left" size="small">
                <Typography
                    variant="body2"
                    style={{
						textAlign: 'left',
						fontWeight: '400',
						fontSize: '17px',
						lineHeight: '22px',
						letterSpacing: '0.51px'
					}}
                >
					{subscription.sponsorName}
                </Typography>
            </TableCell>
		</TableRow>
	)
}
