import React from "react"
import {
    Button,
    Grid,
    Typography,
    useTheme
} from "@material-ui/core"
import { injectIntl } from "react-intl"
import { connect, useDispatch } from "react-redux"
import { compose } from "recompose"
import history from "../../../utilities/history"
import { hideModal } from "../../../redux/actions"


const CantUpdateStatusModal = ({
    errorCode,
    redirectUrl,
    intl
}) => {
    const theme = useTheme()
    const dispatch = useDispatch()

    const processedErrors = [63, 64, 65, 66, 67]

    const handleOkButtonClick = () => {
        history.push(redirectUrl)
        dispatch(hideModal())
    }

    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            style={{ width: '75rem', padding: '4rem', margin: 'auto', backgroundColor: theme.palette.secondary.main }}
        >
            <Grid item>
                <Typography
                    variant="h1"
                    style={{
                        color: '#fff',
                        marginBottom: '25px',
                        textAlign: 'center'
                    }}
                >
                    {intl.formatMessage({ id: 'app.imports.cantUpdateStatusModal.title' })}
                </Typography>
            </Grid>
            {
                processedErrors.includes(errorCode)
                && <Grid item style={{ alignSelf: 'flex-start', width: '100%', paddingBottom: '20px' }}>
                    <Typography
                        variant="h4"
                        style={{
                            color: 'white',
                            paddingBottom: '10px',
                            fontWeight: 300
                        }}
                    >
                        {intl.formatMessage({ id: `app.imports.cantUpdateStatusModal.description.${errorCode}` })}
                    </Typography>
                </Grid>
            }
            <Grid item>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOkButtonClick}
                    style={{
                        width: '100px'
                    }}
                >
                    {intl.formatMessage({ id: 'app.imports.cantUpdateStatusModal.okButton' })}
                </Button>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl
)

export default enhance(CantUpdateStatusModal)
