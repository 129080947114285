import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { compose } from 'recompose'
import { reduxForm, change, formValueSelector } from 'redux-form'
import DefaultFormTextField from '../../components/DefaultFormTextField'
import { emailTemplateErrorSelector, isSavingSelector } from '../../redux/selectors'
import { connect, useDispatch, useStore } from 'react-redux'
import { useTheme, Typography } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { required } from '../../utilities/validators'
import { useSelector, shallowEqual } from 'react-redux'
import SendTestEmailModal from './SendTestEmailModal'
import SendTestSmsModal from './SendTestSmsModal'
import SendTestPushModal from './SendTestPushModal'
import { showModal } from '../../redux/actions'
import {
    isEmailTemplateType,
    isSmsTemplateType,
    isPushTemplateType
} from '../../redux/functions'

const requiredValidator = required

const EmailTemplatesForm = ({ template, defaultTemplate, languageId, type, handleSubmit, onSaveCallback, templateError, versionId, surveyInstanceId, circleId }) => {
	const dispatch = useDispatch()
	const isSaving = useSelector(isSavingSelector, shallowEqual)
	const theme = useTheme()
	const intl = useIntl()
	const selector = formValueSelector('emailTemplate')
	const store = useStore()

	useEffect(
		() => {
			dispatch(change('emailTemplate', 'htmlPart', template ? template.htmlPart : ''))
			dispatch(change('emailTemplate', 'subject', template ? template.subjectPart : ''))
			dispatch(change('emailTemplate', 'defaultSubject', defaultTemplate ? defaultTemplate.subjectPart : ''))
			dispatch(change('emailTemplate', 'defaultHtmlPart', defaultTemplate ? defaultTemplate.htmlPart : ''))
		},
		[ template ]
	)

	const isEmailType = isEmailTemplateType(type)
	const isSmsType = isSmsTemplateType(type)
	const isPushType = isPushTemplateType(type)

	const onSubmit = (values) => {
		if (values && languageId) {
			onSaveCallback && onSaveCallback(languageId, type, values.htmlPart, values.subject)
		}
	}

	const sendTestEmail = () => {
		dispatch(showModal(SendTestEmailModal,
			{
				versionId, 
				surveyInstanceId,
				circleId,
				languageId, 
				type
			}
		))
	}

	const sendTestSms = () => {
		dispatch(showModal(SendTestSmsModal,
			{
				versionId, 
				surveyInstanceId,
				languageId, 
				type
			}
		))
	}

	const sendTestPush = () => {
		dispatch(showModal(SendTestPushModal,
			{
				versionId, 
				surveyInstanceId,
				languageId, 
				type
			}
		))
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container justify="flex-end">
				{isEmailType &&
					<Grid item>
						<Button
                            variant="contained"
                            color="primary"
							onClick={sendTestEmail}
							style={{
								marginRight: '20px',
							}}
							disabled={isSaving}
						>
							{intl.formatMessage({
								id: 'app.template.sendEmailButton'
							})}
						</Button>
					</Grid>
				}
				{isSmsType &&
					<Grid item>
						<Button
                            variant="contained"
                            color="primary"
							onClick={sendTestSms}
							style={{
								marginRight: '20px',
							}}
							disabled={isSaving}
						>
							{intl.formatMessage({
								id: 'app.template.sendSmsButton'
							})}
						</Button>
					</Grid>
				}
				{isPushType &&
					<Grid item>
						<Button
                            variant="contained"
                            color="primary"
							onClick={sendTestPush}
							style={{
								marginRight: '20px',
							}}
							disabled={isSaving}
						>
							{intl.formatMessage({
								id: 'app.template.sendPushButton'
							})}
						</Button>
					</Grid>
				}
				<Grid item>
					<Button
                        variant="contained"
                        color="primary"
						disabled={isSaving}
						type="submit"
					>
						{intl.formatMessage({
							id: 'app.general.saveChangesButtonText'
						})}
					</Button>
				</Grid>
			</Grid>
			{template && template.fields && <Grid container direction="column">
				<Typography
					style={{ fontSize: '16px', lineHeight: '16px', letterSpacing: '2.08px', color: '#011020' }}
				>
					{intl.formatMessage({ id: 'app.templates.fieldsList' })}:
				</Typography>

				<Grid item style={{ marginTop: '17px' }}>
					<Typography
						style={{ fontSize: '13px', lineHeight: '16px', letterSpacing: '2.08px', color: '#011020' }}
					>
						{template.fields.join(', ')}
					</Typography>
				</Grid>
			</Grid>}
			{templateError && <Grid container direction="column">
				<Grid item style={{ marginTop: '17px' }}>
					<Typography
						style={{ fontSize: '13px', lineHeight: '16px', letterSpacing: '2.08px', color: 'red', whiteSpace: 'pre-wrap' }}
					>
						{templateError}
					</Typography>
				</Grid>
			</Grid>}
			{isEmailType &&
				<div style={{ backgroundColor: 'rgb(225, 238, 255)', marginTop: '26px', padding: '3%' }}>
					<Grid container direction="column">
						<Typography
							style={{ fontSize: '13px', lineHeight: '16px', letterSpacing: '2.08px', color: '#011020' }}
						>
							{intl.formatMessage({ id: 'app.templates.subject' })}
						</Typography>
						<Grid item style={{ marginTop: '17px' }}>
							<DefaultFormTextField
								variant="filled"
								name="subject"
								fullWidth
								multiline={true}
								rows={15}
								validators={[requiredValidator]}
							/>
						</Grid>
					</Grid>
				</div>
			}

			<div style={{ backgroundColor: 'rgb(225, 238, 255)', marginTop: '26px', padding: '3%' }}>
				<Grid container direction="column">
					<Typography
						style={{ fontSize: '13px', lineHeight: '16px', letterSpacing: '2.08px', color: '#011020' }}
					>
						{ 
							isPushType || isSmsType
								? intl.formatMessage({ id: 'app.templates.message' })
								: intl.formatMessage({ id: 'app.templates.body' })
						}
					</Typography>

					<Grid item style={{ marginTop: '17px' }}>
						<DefaultFormTextField
							variant="filled"
							name="htmlPart"
							fullWidth
							multiline={true}
							rows={15}
							validators={[requiredValidator]}
						/>
					</Grid>
				</Grid>
			</div>
			{
				(versionId || circleId) && isEmailType &&
				<div style={{ backgroundColor: 'rgb(225, 238, 255)', marginTop: '26px', padding: '3%' }}>
					<Grid container direction="column">
						<Typography
							style={{ fontSize: '13px', lineHeight: '16px', letterSpacing: '2.08px', color: '#011020' }}
						>
							{intl.formatMessage({ id: 'app.templates.defaultSubject' })}
						</Typography>
						<Grid item style={{ marginTop: '17px' }}>
							<DefaultFormTextField
								variant="filled"
								name="defaultSubject"
								fullWidth
								multiline={true}
								rows={15}
								disabled={true}
							/>
						</Grid>
					</Grid>
				</div>
			}
			{
				(versionId || circleId) &&
				<div style={{ backgroundColor: 'rgb(225, 238, 255)', marginTop: '26px', padding: '3%' }}>
					<Grid container direction="column">
						<Typography
							style={{ fontSize: '13px', lineHeight: '16px', letterSpacing: '2.08px', color: '#011020' }}
						>
							{
								isPushType || isSmsType
									? intl.formatMessage({ id: 'app.templates.defaultMessage' })
									: intl.formatMessage({ id: 'app.templates.defaultBody' })
							}
						</Typography>
						<Grid item style={{ marginTop: '17px' }}>
							<DefaultFormTextField
								variant="filled"
								name="defaultHtmlPart"
								fullWidth
								multiline={true}
								rows={15}
								disabled={true}
							/>
						</Grid>
					</Grid>
				</div>
			}
		</form>
	)
}

const mapStateToProps = (state, ownProps) => ({
	templateError: emailTemplateErrorSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: 'emailTemplate',
		touchOnChange: true,
		touchOnBlur: true
	})
)

export default enhance(EmailTemplatesForm)
