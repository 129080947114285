import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { useTheme } from '@material-ui/core/styles'
import Menu from './Menu'
import Typography from '@material-ui/core/Typography'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import EditQuestionContainer from '../questionTranslator/QuestionContainer'
import { useSelector, shallowEqual } from 'react-redux'
import { isUpdatingQuestionSelector, isUpdatingQuestionContainerIdSelector } from '../../redux/selectors'
import { uuidv4 } from '../../utilities/utilities'

const QuestionContainer = ({
	node,
	children,
	onRemoveCallback,
	onUpdateCallback,
	languageId,
	ignoreBundles,
	showScoreEntry
}) => {
	const theme = useTheme()
	const isUpdating = useSelector(isUpdatingQuestionSelector, shallowEqual)
	const isUpdatingContainerId = useSelector(isUpdatingQuestionContainerIdSelector, shallowEqual)
	const [ containerId, setContainerId ] = useState(uuidv4().replace('-', ''))

	const onEditCompleteCallback = (question, languageId) => {
		if (onUpdateCallback) onUpdateCallback(question, null, languageId)
	}

	const isStartBundle = (node) => {
		if (!(node && node.data && node.data.isBundle)) {
			return false
		}
		let isPrevBundle = node && node.prev && node.prev.data.isBundle

		if (!isPrevBundle && (node && node.data && node.data.isBundle)) {
			return true
		} else {
			//Check is part of same group
			return isPrevBundle && node.prev.data.bundleGroupId !== node.data.bundleGroupId
		}
		return false
	}

	const isEndBundle = (node) => {
		if (node) {
			if (node.next && !node.next.data.isBundle && node.data.isBundle) {
				return true
			}
			if (!node.next && node.data.isBundle) {
				return true
			}
			if (node.next && node.next.data.isBundle && node.data.isBundle) {
				if (node.next.data.bundleEntityId !== node.data.bundleEntityId) {
					return true
				}
			}
		}
		return false
	}

	return (
		<Grid container direction="column">
			{ignoreBundles &&
			isStartBundle(node) && (
				<Grid
					item
					style={{
						backgroundColor: '#ededed',
						padding: '0 2%',
						marginTop: '15px',
						minHeight: '40px'
					}}
				>
					<Grid container alignItems="center" style={{ marginTop: '7px' }}>
						<Grid item>
							<GroupWorkIcon style={{ color: theme.palette.primary.A800, paddingTop: '5px' }} />
						</Grid>
						<Grid item>
							<Typography style={{ color: theme.palette.primary.A800, marginLeft: '10px' }}>
								Start Scoring Group &nbsp;{node && node.data && node.data.bundleName}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			)}
			<Grid item>&nbsp;</Grid>
			{children && (
				<Grid
					item
					style={{
						backgroundColor: theme.palette.primary.A200,
						padding: '0 2%'
					}}
				>
					<Grid container direction="column">
						<Grid item>
							<Grid container justify="flex-end">
								<Grid item>
									<Grid container>
										<Grid item>
											<Menu
												node={node}
												onRemoveCallback={onRemoveCallback}
												containerId={containerId}
												languageId={languageId}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>

							<Grid item style={{ padding: '' }}>
								{isUpdating && isUpdatingContainerId === containerId ? (
									<EditQuestionContainer
										question={node}
										onUpdateCallback={onEditCompleteCallback}
										showScoreEntry={showScoreEntry}
									/>
								) : (
									children
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}

			{ignoreBundles &&
			isEndBundle(node) && (
				<Grid
					item
					style={{
						backgroundColor: '#ededed',
						padding: '0 2%',
						marginTop: '15px',
						minHeight: '40px'
					}}
				>
					<Grid container alignItems="center" style={{ marginTop: '7px' }}>
						<Grid item>
							<GroupWorkIcon style={{ color: theme.palette.primary.A800, paddingTop: '5px' }} />
						</Grid>
						<Grid item>
							<Typography style={{ color: theme.palette.primary.A800, marginLeft: '10px' }}>
								End Scoring Group
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			)}
		</Grid>
	)
}

const enhance = compose(injectIntl)

export default enhance(QuestionContainer)
