import * as api from '../../../apis/configuration'
import { createSelector } from 'reselect'
import * as cookies from '../../../utilities/cookies'
import { hideModal } from '../../actions'

const FETCH_CONFIGURATION_REQUESTED = 'FETCH_CONFIGURATION_REQUESTED'
const FETCH_CONFIGURATION_SUCCESS = 'FETCH_CONFIGURATION_SUCCESS'
const FETCH_CONFIGURATION_FAILED = 'FETCH_CONFIGURATION_FAILED'
const FETCH_CONFIGURATION_NOP = 'FETCH_CONFIGURATION_NOP'

export const fetchConfiguration = (key) => (dispatch, getState) => {
	var state = getState()

	dispatch({ type: FETCH_CONFIGURATION_REQUESTED, data: {} })
	api.fetchConfiguration().then((configuration) => {
		if (configuration) {
			return dispatch({ type: FETCH_CONFIGURATION_SUCCESS, data: configuration })
		} else {
			return dispatch({ type: FETCH_CONFIGURATION_FAILED, data: {} })
		}
	})

	return dispatch({ type: FETCH_CONFIGURATION_NOP, data: {} })
}

const SAVE_CONFIGURATION_REQUESTED = 'SAVE_CONFIGURATION_REQUESTED'
const SAVE_CONFIGURATION_SUCCESS = 'SAVE_CONFIGURATION_SUCCESS'
const SAVE_CONFIGURATION_FAILED = 'SAVE_CONFIGURATION_FAILED'
const SAVE_CONFIGURATION_NOP = 'SAVE_CONFIGURATION_NOP'

export const saveConfiguration = (values) => (dispatch, getState) => {
	var state = getState()
	dispatch({ type: SAVE_CONFIGURATION_REQUESTED, data: {} })
	var config = configurationSelector(state)
	if (config) {
		config.userPrice = values.userPrice
		config.casePrice = values.casePrice
		config.thresholdVolume = values.thresholdVolume
		config.percentDiscount = values.percentDiscount
		config.billingContactEmail = values.billingContactEmail

		api.saveConfiguration(config).then((configuration) => {
			if (configuration) {
				dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.general.successfullyUpdated', isSuccess: true }
				})
				return dispatch({ type: SAVE_CONFIGURATION_SUCCESS, data: configuration })
			} else {
				return dispatch({ type: SAVE_CONFIGURATION_FAILED, data: {} })
			}
		})
	}

	return dispatch({ type: SAVE_CONFIGURATION_NOP, data: {} })
}

const SAVE_SYSTEM_TERMS_REQUESTED = 'SAVE_SYSTEM_TERMS_REQUESTED'
const SAVE_SYSTEM_TERMS_SUCCESS = 'SAVE_SYSTEM_TERMS_SUCCESS'
const SAVE_SYSTEM_TERMS_FAILED = 'SAVE_SYSTEM_TERMS_FAILED'

export const saveSystemTerms = (terms) => (dispatch, getState) => {
	var state = getState()
	dispatch({ type: SAVE_SYSTEM_TERMS_REQUESTED, data: {} })

	if (terms) {
		api.saveTerms(terms).then((configuration) => {
			if (configuration && configuration.isSuccessful) {
				dispatch(hideModal())
				dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.general.successfullyUpdated', isSuccess: true }
				})
				return dispatch({ type: SAVE_SYSTEM_TERMS_SUCCESS, data: configuration.value })
			} else {
				return dispatch({ type: SAVE_SYSTEM_TERMS_FAILED, data: {} })
			}
		})
	}

	return dispatch({ type: SAVE_CONFIGURATION_NOP, data: {} })
}

const FETCH_SYSTEM_TERMS_REQUESTED = 'FETCH_SYSTEM_TERMS_REQUESTED'
const FETCH_SYSTEM_TERMS_SUCCESS = 'FETCH_SYSTEM_TERMS_SUCCESS'
const FETCH_SYSTEM_TERMS_FAILED = 'FETCH_SYSTEM_TERMS_FAILED'

export const fetchSystemTerms = () => (dispatch, getState) => {
	var state = getState()
	dispatch({ type: FETCH_SYSTEM_TERMS_REQUESTED, data: {} })

	api.getTerms().then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_SYSTEM_TERMS_SUCCESS, data: response.value })
		} else {
			return dispatch({ type: FETCH_SYSTEM_TERMS_FAILED, data: {} })
		}
	})
}

const initial = {
	configurations: {},
	configuration: {},
	terms: ''
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_CONFIGURATION_SUCCESS:
			return { ...state, configuration: action.data }
		case SAVE_SYSTEM_TERMS_SUCCESS:
		case FETCH_SYSTEM_TERMS_SUCCESS:
			return { ...state, terms: action.data }
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.configuration
export const termsSelector = (state) => {
	return state.configuration.terms
}

export const configurationSelector = createSelector(mainSelector, (state) => {
	if (state.configuration) {
		return state.configuration
	}
	return {}
})

export const countryListSelector = createSelector(mainSelector, (state) => {
	if (state.countries) {
		return state.countries.map((r) => {
			return {
				id: r.id,
				label: r.name
			}
		})
	}
	return []
})
