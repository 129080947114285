import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { overdueOperationsSelector } from '../redux/selectors'
import { Paper } from '@material-ui/core'

const ExternalAuthHeader = ({ intl, overdueOperations }) => {
	return (
		overdueOperations && overdueOperations.length ?
		(<Paper position='fixed' style={{ background: '#F54337' }} square={true}>
			<Slide direction='down' in={true} mountOnEnter unmountOnExit>
				<Toolbar style={{ margin: '0 auto' }}>
					<Grid data-testid="overdue-operations-header" container direction='column' style={{ padding: '10px 0' }}>
						<Grid item style={{ textAlign: 'center' }}>
							<Typography
								style={{
									color: 'white',
									fontSize: '14px'
								}}>
								{intl.formatMessage({
									id: 'app.general.overdueOperations'
								})}
							</Typography>
						</Grid>
						<Grid item style={{ textAlign: 'center' }}>
							<Typography
								style={{
									color: 'white',
									fontSize: '14px'
								}}>
								{overdueOperations.join(', ')}
							</Typography>
						</Grid>
					</Grid>
				</Toolbar>
			</Slide>
		</Paper>)
		: null
	)
}

const mapStateToProps = (state) => ({
	overdueOperations: overdueOperationsSelector(state)
})

const mapDispatchToProps = () => ({})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(ExternalAuthHeader)
