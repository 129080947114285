import { authenticatedFetch } from './base'

export const search = (searchPhrase, offset) => {
	var url = `/api/report`
	let urlHasParams = false

	if (searchPhrase && searchPhrase.length > 0) {
		url = `${url}?searchPhrase=${encodeURIComponent(searchPhrase)}`
		urlHasParams = true
	}

	if(offset){
		url += `${urlHasParams ? '&' : '?'}offset=${offset}`
		urlHasParams = true
	}

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(sponsor) {
			return sponsor
		})
}

export const downloadReportExport = (id) => {
	return authenticatedFetch(`/api/report/${id}/downloadReport`, { method: 'GET' })
		.then(function (response) {
			if (response.status === 200) {
				let header = response.headers.get("content-disposition")
				if (!header) {
					return { isSuccessful: false }
				}

				let encodedFileName = header.substring(header.indexOf("%22") + 3)
				let decodedFileName = decodeURIComponent(encodedFileName)
				let fileName = decodedFileName.substring(0, decodedFileName.length - 1)
				return response.blob().then(blob => [fileName, blob])
			}
		})
		.then(function ([filename, blob]) {
			try {
				var element = document.createElement('a')
				element.setAttribute('href', URL.createObjectURL(blob))
				element.setAttribute('download', filename)

				element.style.display = 'none'
				document.body.appendChild(element)

				element.click()

				document.body.removeChild(element)
				return { isSuccessful: true }
			} catch (e) {
				console.error(e)
				return { isSuccessful: false }
			}
		})
}

export const getNewCompletedReports = () => {
	return authenticatedFetch(`/api/report/getNewCompletedReports`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const clearNewCompletedReports = () => {
	return authenticatedFetch(`/api/report/clearNewCompletedReports`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}