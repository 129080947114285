import React, { useEffect, useState } from 'react'
import Switch from '@material-ui/core/Switch'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { Grid, Typography } from '@material-ui/core'
import Popover from '../components/Popover'

const PIDataToggle = ({ onChange, defaultValue, disabled, color='primary', fontColor = 'black', intl }) => {

	const [isPIData, setisPIData] = useState(defaultValue)

	useEffect(() => {
		onChange(defaultValue)
	}, [])

	const handleChangeValue = (e) => {
		if (!!disabled) {
			return
		}

		setisPIData(e.target.checked)
		if(onChange) {
			onChange(e.target.checked)
		}
	}

	return (
		<Grid item style={{ width: '100%', zIndex: 100 }}>
			<Grid container justify="flex-end" style={{ position: 'relative' }}>
					<Grid item style={{ paddingTop: '6px' }}>
						<Popover style={{ marginRight: '10px', color: fontColor }} text={intl.formatMessage({ id: 'app.survey.questions.isPiData.disclaimer' })} anchorOriginV='center' anchorOriginH='right' transformOriginV='center'>
							<HelpOutlineIcon />
						</Popover>						
					</Grid>
					<Grid item style={{ paddingTop: '6px' }}>
						<Typography
							variant="body2"
							style={{ fontSize: '17px', color: fontColor, opacity: !!disabled ? 0.33 : 1.0 }}
						>
							{intl.formatMessage({ id: 'app.survey.questions.isPiData' })}
							</Typography>
					</Grid>
					<Grid item>
							<Switch
								checked={isPIData}
								onChange={handleChangeValue}
								value="isPIData"
								color={color}
								inputProps={{ 'aria-label': 'secondary checkbox' }}
								style={{ opacity: !!disabled ? 0.33 : 1.0 }}
							/>
					</Grid>
				
			</Grid>
		</Grid>
	)
}

const enhance = compose(injectIntl)

export default enhance(PIDataToggle)