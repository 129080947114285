import * as country from '../../../apis/country'
import { createSelector } from 'reselect'
import * as cookies from '../../../utilities/cookies'

const FETCH_COUNTRYS_REQUESTED = 'FETCH_COUNTRYS_REQUESTED'
const FETCH_COUNTRYS_SUCCESS = 'FETCH_COUNTRYS_SUCCESS'
const FETCH_COUNTRYS_FAILED = 'FETCH_COUNTRYS_FAILED'
const FETCH_COUNTRYS_NOP = 'FETCH_COUNTRYS_NOP'

export const fetchCountries = () => (dispatch, getState) => {
	var state = getState()

	if (!state.country.countries || state.country.countries.length === 0) {
		dispatch({ type: FETCH_COUNTRYS_REQUESTED, data: {} })
		country.fetchCountries().then((countries) => {
			if (countries) {
				return dispatch({ type: FETCH_COUNTRYS_SUCCESS, data: countries })
			} else {
				return dispatch({ type: FETCH_COUNTRYS_FAILED, data: {} })
			}
		})
	}
	return dispatch({ type: FETCH_COUNTRYS_NOP, data: {} })
}

const initial = {
	countries: []
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_COUNTRYS_SUCCESS:
			return { ...state, countries: action.data }
		default:
			return { ...state }
	}
}

export const mainSelector = (state) => state.country

export const countriesSelector = (state) => state.country.countries

export const countryListSelector = createSelector(mainSelector, (state) => {
	if (state.countries) {
		return state.countries.map((r) => {
			return {
				id: r.id,
				label: r.name
			}
		})
	}
	return []
})
