import { getUser } from '../utilities/userUtilities'
import { authenticatedFetch } from './base'

export const search = (searchPhrase, offset) => {
	var url = `/api/question`
	let urlHasParams = false

	if (searchPhrase && searchPhrase.length > 0) {
		url = `${url}?searchPhrase=${encodeURIComponent(searchPhrase)}`
		urlHasParams = true
	}

	if(offset){
		url += `${urlHasParams ? '&' : '?'}offset=${offset}`
		urlHasParams = true
	}

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const autocompleteSearch = (searchPhrase, searchType = 1, surveyId) => {
	var url = `/api/question`

	if (searchPhrase && searchPhrase.length > 0) {
		url = `/api/question/autocomplete?searchPhrase=${encodeURIComponent(searchPhrase)}&searchType=${searchType}`

		if(surveyId){
			url += `&surveyId=${surveyId}`
		}
	}

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const setSurveyQuestionOptional = (surveyId, question, commentsObj) => {
	let id = question.id
	let isOptional = question.isOptional
	let typeId = 0

	if (question.type === 'single') {
		typeId = 1
	} else if (question.type === 'multi') {
		typeId = 2
	} else if (question.type === 'grouped') {
		typeId = 3
	} else if (question.type === 'analog') {
		typeId = 9
	} else if (question.type === 'text') {
		typeId = 5
	} else if (question.type === 'number') {
		typeId = 6
	} else if (question.type === 'date') {
		typeId = 7
	} else if (question.type === 'file') {
		typeId = question.fileType
	}

	return authenticatedFetch(`/api/survey/${surveyId}/question/${id}/type/${typeId}/optional/${isOptional}`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			commentText: commentsObj?.commentText,
			commentProtocolVersionId: commentsObj?.protocolVersionId,
			commentRelatedTaskUrl: commentsObj?.relatedTaskUrl,
			commentInvestigatorId: commentsObj?.investigatorId
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const updateQuestion = (question, languageId, endpoint, commentsObj) => {
	let url = endpoint
	if (isNaN(question.id)) {
		question.id = null
	}
	let data = {}
	data.id = question.id
	data.tag = question.tag
	data.title = question.title
	data.instructions = question.instructions
	data.canonicalId = question.canonicalId
	data.isOptional = question.isOptional
	data.isPiData = question.isPiData
	data.displayInstructionAsIcon = question.displayInstructionAsIcon
	data.comments = {}
	data.comments.commentText = commentsObj?.commentText
	data.comments.commentProtocolVersionId = commentsObj?.protocolVersionId
	data.comments.commentRelatedTaskUrl = commentsObj?.relatedTaskUrl
	data.comments.commentInvestigatorId = commentsObj?.investigatorId

	switch (question.type) {
		case 'single':
			url = url + 'single'
			data.answers = []
			data.isConsentRequired = question.isConsentRequired
			data.isCodeQuestion = question.isCodeQuestion
			data.questionCode = question.questionCode
			data.isDropdown = question.isDropdown
			
			for (let index = 0; index < question.answers.length; index++) {
				const answer = question.answers[index].answer

				data.answers.push({
					id: question.answers[index].id,
					label: answer.answer,
					canonicalId: answer.canonicalId,
					value: answer.value,
					code: answer.code,
					sortOrder: question.answers[index].sortOrder,
					tag: question.answers[index].tag
				})
			}

			break
		case 'multi':
			url = url + 'multi'
			data.answers = []
			data.isConsentRequired = question.isConsentRequired
			data.isCodeQuestion = question.isCodeQuestion
			data.questionCode = question.questionCode
			data.isDropdown = question.isDropdown

			for (let index = 0; index < question.answers.length; index++) {
				const answer = question.answers[index].answer
				data.answers.push({
					id: question.answers[index].id,
					label: answer.answer,
					canonicalId: answer.canonicalId,
					value: answer.value,
					code: answer.code,
					sortOrder: question.answers[index].sortOrder,
					tag: question.answers[index].tag
				})
			}

			break
		case 'grouped':
			url = url + 'grouped'
			data.categories = []
			data.minimumValue = question.minimumValue
			data.range = question.range
			data.isDropdown = question.isDropdown
			for (let index = 0; index < question.answers.length; index++) {
				const answer = question.answers[index].answer

				data.categories.push({
					id: question.answers[index].id,
					label: answer.question,
					tag: answer.tag,
					canonicalId: question.answers[index].canonicalId,
					sortOrder: question.answers[index].sortOrder
				})
			}

			break
		case 'tf':
			url = url + 'tf'
			break
		case 'text':
			url = url + 'text'
			data.isRegularExpressionEnabled = question.isRegularExpressionEnabled
			data.regularExpression = question.regularExpression
			data.regularExpressionValidation = question.regularExpressionValidation
			data.isConsentRequired = question.isConsentRequired
			break
		case 'file':
			url = url + 'file';
			data.fileType = question.fileType;
			data.hideImageForPatient = question.hideImageForPatient
			break
		case 'date':
			url = url + 'date'
			data.isRegularExpressionEnabled = question.isRegularExpressionEnabled
			data.regularExpression = question.regularExpression
			data.regularExpressionValidation = question.regularExpressionValidation
			data.isConsentRequired = question.isConsentRequired
			break
		case 'number':
			url = url + 'number'
			data.unitId = question.numberType
			data.digits = question.precision
			data.minimum = question.minimum
			data.maximum = question.maximum
			data.isRegularExpressionEnabled = question.isRegularExpressionEnabled
			data.regularExpression = question.regularExpression
			data.regularExpressionValidation = question.regularExpressionValidation
			data.isConsentRequired = question.isConsentRequired
			break
		case 'content':
			url = url + 'copy'
			data = {}

			data.id = question.id
			data.languageId = languageId
			data.canonicalId = question.canonicalId
			data.subtitle = question.subtitle
			data.bodyCopy = question.bodyCopy
			data.comments = {}
			data.comments.commentText = commentsObj?.commentText
			data.comments.commentProtocolVersionId = commentsObj?.protocolVersionId
			data.comments.commentRelatedTaskUrl = commentsObj?.relatedTaskUrl
			data.comments.commentInvestigatorId = commentsObj?.investigatorId
			break
		case 'analog':
			var model = new FormData()
			url = url + 'analog'
			model.append('id', question.id)
			model.append('isOptional', question.isOptional)
			if (question.startImage == '' && !!question.startImagePath && question.startImagePath != 'null') {
				model.append('startImagePath', question.startImagePath)
			}
			model.append('startImageFile', question.startImage)
			if (question.endImage == '' && !!question.endImagePath && question.endImagePath != 'null') {
				model.append('endImagePath', question.endImagePath)
			}
			model.append('endImageFile', question.endImage)
			model.append('startValue', question.startValue)
			model.append('endValue', question.endValue)
			model.append('stepValue', question.stepValue)
			model.append('startText', !!question.startText ? question.startText : "")
			model.append('endText', !!question.endText ? question.endText : "")
			model.append('displayInstructionAsIcon', question.displayInstructionAsIcon)
			if (question.tag) {
				model.append('tag', question.tag)
			}
			model.append('title', question.title)
			if (question.instructions) {
				model.append('instructions', question.instructions)
			}
			if (question.canonicalId) {
				model.append('canonicalId', question.canonicalId)
			}
			model.append('languageId', languageId)

			model.append('commentText', commentsObj?.commentText)
			model.append('commentProtocolVersionId', commentsObj?.protocolVersionId)
			model.append('commentRelatedTaskUrl', commentsObj?.relatedTaskUrl)
			model.append('commentInvestigatorId', commentsObj?.investigatorId)

			return authenticatedFetch(url, {
				method: 'PUT',
				body: model
			})
				.then(function(response) {
					if (response.status === 200) {
						return response.json()
					}
				})
				.then(function(patient) {
					return patient
				})

			break
	}
	if (data.sortOrder) {
		url = url + `?order=${data.sortOrder}`
	}
	data.languageId = parseInt(languageId)
	return authenticatedFetch(url, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(data)
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const setAnalogImage = (file) => {
	var url = `/api/question/analog/photo`

	return authenticatedFetch(url, {
		method: 'post',
		body: file
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(obj) {
			return obj
		})
}

export const fetchQuestion = (questionId, questionType, languageId) => {
	var url = `/api/question/${questionId}/type/${questionType}/language/${languageId}`

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(data) {
			return data
		})
}

export const fetchQuestionReferences = (questionId, questionType) => {
	var url = `/api/question/${questionId}/type/${questionType}/references`

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(data) {
			return data
		})
}

export const fetchQuestionChangeLogs = (questionId, questionType, searchPhrase, keys) => {
	var url = `/api/question/${questionId}/type/${questionType}/changeLogs`

	url = `${url}?searchPhrase=${!!searchPhrase?.length && encodeURIComponent(searchPhrase) || ''}`

	if (!!keys?.length) {
		keys.forEach(x => url = `${url}&keys=${x}`)
	}

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(data) {
			return data
		})
}
