import React, { useRef } from 'react'
import Grid from '@material-ui/core/Grid'
import { useTheme } from '@material-ui/core/styles'
import Answer from './Answer'
import UpDownCommandBar from '../UpDownCommandBar'

export default ({ answer, onDeleteAnswerCallback, onSwapRequestedCallback, dark, disabled }) => {
	const theme = useTheme()
	const ref = useRef()

	return (
		<Grid
			container
			justify="space-between"
			alignItems="flex-start"
			className='block-answer-container'
			style={{ backgroundColor: dark ? 'transparent' : theme.palette.primary.A200, width: '100%' }}
		>
			<Grid item style={{ flex: 0.9 }}>
				<Answer
					ref={ref}
					answer={answer}
					onDeleteCallback={onDeleteAnswerCallback}
					dark={dark}
					disabled={disabled}
				/>
			</Grid>
			{
				!disabled
				&& <Grid item style={{ flex: 0.1 }}>
					<UpDownCommandBar node={answer} onSwapRequested={onSwapRequestedCallback} dark={dark} />
				</Grid>
			}
		</Grid>
	)
}
