import { uuidv4 } from "./utilities"
import { required } from "./validators"

const requiredValidator = required


export const getQuestionKey = (node) => {
	return `${node.data.sortOrder}_${node.data.id}`
}

export const questionSort = (a, b) => {
	if (a.sortOrder > b.sortOrder) {
		return 1
	}
	if (a.sortOrder < b.sortOrder) {
		return -1
	}

	if (typeof(a.cloneId) == "string") {
		return -1
	}

	return 0
}

export const processQuestions = (response, container, questions, hash, tags = null) => {
	if (response.questions) {
		response.questions.sort(questionSort).map((r) => {
			container.getList().append(r)
		})
		container.getList().traverse((r) => {
            // const key = `qt_${r.data.questionType}_${r.data.id}`
            const key = getQuestionKey(r)

			questions.push(key)
			hash[key] = r
			if (
                r.data.tag
                && !!tags
            ) {
				tags.push({
                    label: r.data.title,
                    tag: r.data.tag
                })
			}
		})
	}
}

export const getTagValidators = (question) => {
    let result = []
    
    if (!!question.data.cloneId) {
        result = requiredValidator
    }

    return result
}

export const cloneQuestion = (question) => {
    const data = question.data

	const sortOrder = data.sortOrder + 1

	return {
		data: {
			...data,
			id: null,
			tag: null,
			sortOrder,
			cloneId: uuidv4().replace('-', '')
		}
	}
}
