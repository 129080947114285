import React, { useEffect } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { showModal } from '../../../redux/actions'
import CloneProtocolModal from './CloneProtocolModal'

const CloneProtocolMenuItem = ({ intl, onClickHandler, onComplete, protocol, loadCase, show }) => {
	useEffect(() => {}, [])

	const onClick = () => {
		//loadCase(caseModel.id)
		show()
	}

	return (
		<MenuItem
			onClick={() => {
				onClick()
				onComplete()
			}}
		>
			{intl.formatMessage({ id: 'app.protocols.cloneProtocolMenuItem' })}
		</MenuItem>
	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
	show: () => dispatch(showModal(CloneProtocolModal, { protocol: ownProps.protocol }))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(CloneProtocolMenuItem)
