import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import FormTextField from '../../components/FormTextField'
import { FormTypeAheadTextField } from '../../components/FormTypeAheadTextField'
import { reduxForm, change } from 'redux-form'
import { useTheme } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'
import { phoneNumber, required } from '../../utilities/validators'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { updateUserDetailsById, fetchCountries, fetchStates, fetchTimeZones, fetchLanguages } from '../../redux/actions'
import { userDetailsSelector, countriesSelector, statesSelector, timeZonesSelector, languagesSelector } from '../../redux/selectors'
import TimeZoneSelector from '../../components/TimeZoneSelector'
import OutlinedIconHelpTooltip from '../../components/OutlinedIconHelpTooltip'
import LanguageSelectorV2 from '../../components/LanguageSelectorV2'
import { PhoneNumberField } from '../../components/PhoneNumberField'

const styles = {
	root: {},
	input: {
		color: 'white',
		fontSize: '1.7rem'
	}
}

const UpdateUserProfile = ({
	classes,
	intl,
	handleSubmit,
	submitRequest,
	userDetails,
	loadCountries,
	countries,
	loadStates,
	states,
	resetCountryState,
	loadTimeZones,
	timeZones,
	fetchLanguages,
	languages
}) => {
	const theme = useTheme()
	const [ countryStateChange, setCountryStateChange ] = useState(false)
	const US = 232

	useEffect(() => {
		loadCountries()
		loadTimeZones()
		loadStates(US)
		fetchLanguages()
	}, [])

	const onSubmitForm = (values) => {
		console.log('values')
		console.log(values)
		let countryId = 0
		if (values.team && values.team.countryName) {
			let country = countries.filter((r) => {
				if (r.name.toLowerCase() === values.team.countryName.toLowerCase()) {
					return r.id
				}
			})
			if (country && country[0]) {
				countryId = country[0].id
			}
		}
		var user = {
			...userDetails,
			firstName: values.firstName,
			lastName: values.lastName,
			credentials: values.credentials,
			timeZoneId: values.timeZoneId,
			communicationOn: values.languageId,
			team: {
				teamName: values.team.teamName ? values.team.teamName : null,
				countryId: countryId ? countryId : null,
				streetAddress: values.team.streetAddress ? values.team.streetAddress : null,
				city: values.team.city ? values.team.city : null,
				state: values.team.state ? values.team.state : null,
				phoneNumber: values.team.phoneNumber ? values.team.phoneNumber : null,
				postalCode: values.team.postalCode ? values.team.postalCode : null,
			}
		}
		console.log('user submitted')
		console.log(user)
		submitRequest(user)
	}

	const handleCountryChange = (countryName) => {
		countryName === 'United States' ? setCountryStateChange(true) : setCountryStateChange(false)
		resetCountryState()
	}

	return (
		<form onSubmit={handleSubmit(onSubmitForm)}>
			<Grid
				container
				className="admin-modal admin-modal-wide"
				style={{
					flexDirection: 'column',
					flexWrap: 'nowrap'
				}}
			>
				<Grid item>
					<Typography
						variant="h1"
						style={{ color: 'white', paddingBottom: '32px', textAlign: 'center' }}
					>
						{intl.formatMessage({ id: 'app.profile.edit.title' })}
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="h4" style={{ color: 'white', fontWeight: '400' }}>
						{intl.formatMessage({ id: 'app.profile.edit.subtitle' })}
					</Typography>
				</Grid>
				<Grid item>
					<Grid container justify="flex-start" style={{ width: '100%' }}>
						<Grid item style={{ flex: 0.5 }}>
							<FormTextField
								label={intl.formatMessage({ id: 'app.profile.edit.firstName' })}
								fullWidth
								type="text"
								name="firstName"
								margin="normal"
								variant="filled"
								validators={[ required ]}
							/>
						</Grid>
						<Grid item style={{ flex: 0.5, paddingLeft: '16px' }}>
							<FormTextField
								label={intl.formatMessage({ id: 'app.profile.edit.lastName' })}
								fullWidth
								type="text"
								name="lastName"
								margin="normal"
								variant="filled"
								validators={[ required ]}
							/>
						</Grid>
					</Grid>
					<Grid item style={{ paddingBottom: '23px' }}>
						<FormTextField
							label={intl.formatMessage({ id: 'app.profile.edit.profession' })}
							fullWidth
							type="text"
							name="credentials"
							margin="normal"
							variant="filled"
						/>
					</Grid>
				</Grid>
				<Grid item style={{ color: 'white' }}>
					<Grid container alignItems='center'>
						<Grid item>
							<Typography variant="h4" style={{ fontWeight: '400' }}>
								{intl.formatMessage({ id: 'app.general.timeZoneLabel' })}
							</Typography>
						</Grid>
						<Grid item style={{ marginLeft: '5px' }}>
							<OutlinedIconHelpTooltip
								text={intl.formatMessage({ id: 'app.general.timeZoneTooltipText' })}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item style={{ marginTop: '16px' }}>
					<TimeZoneSelector
						label={intl.formatMessage({ id: 'app.general.timeZoneLabel' })}
						fullWidth
						data={timeZones}
						name="timeZoneId"
						variant="filled"
					/>
				</Grid>
				<Grid item style={{ color: 'white' }}>
					<Typography variant="h4" style={{ fontWeight: '400' }}>
						{intl.formatMessage({ id: 'app.general.languageLabel' })}
					</Typography>
				</Grid>
				<Grid item style={{ marginTop: '16px' }}>
					<LanguageSelectorV2
						label={intl.formatMessage({ id: 'app.general.communicationOnLabel' })}
						fullWidth
						data={languages}
						name="languageId"
						variant="filled"
					/>
				</Grid>
				<Grid item>
					<Typography variant="h4" style={{ color: 'white', fontWeight: '400' }}>
						Team Information
					</Typography>
				</Grid>

				<Grid item>
					<Grid item>
						<FormTextField
							label={intl.formatMessage({ id: 'app.profile.edit.teamName' }) + '*'}
							fullWidth
							type="text"
							name="team.teamName"
							margin="normal"
							variant="filled"
						/>
					</Grid>
					<Grid container justify="flex-start" style={{ width: '100%' }}>
						<Grid item style={{ flex: 0.5, paddingTop: '16px' }}>
							<FormTypeAheadTextField
								suggestions={countries && countries.map((r) => r.name)}
								label="Country"
								name="team.countryName"
								onChange={handleCountryChange}
								maxLength={255}
							/>
						</Grid>
						<Grid item style={{ flex: 0.5, paddingLeft: '16px' }}>
							<PhoneNumberField
								label={intl.formatMessage({ id: 'app.profile.edit.phoneNumber' })}
								name='team.phoneNumber'
								phoneField='team.phoneNumber'
								validators={[phoneNumber]}
								style={{marginTop: '15px'}}
							/>
						</Grid>
					</Grid>
					<Grid item>
						<FormTextField
							label={intl.formatMessage({ id: 'app.profile.edit.streetAddress' })}
							fullWidth
							type="text"
							name="team.streetAddress"
							margin="normal"
							variant="filled"
						/>
					</Grid>

					<Grid container justify="flex-start" style={{ width: '100%' }}>
						<Grid item style={{ flex: 0.4, paddingBottom: '23px' }}>
							<FormTextField
								label={intl.formatMessage({ id: 'app.profile.edit.city' })}
								fullWidth
								type="text"
								name="team.city"
								margin="normal"
								variant="filled"
							/>
						</Grid>
						{!countryStateChange && (
							<Grid item style={{ flex: 0.3 }}>
								<FormTextField
									style={{ marginTop: '16px', marginLeft: '8px' }}
									label={intl.formatMessage({ id: 'app.profile.edit.state' })}
									type="text"
									name="team.state"
									variant="filled"
									maxLength={255}
								/>
							</Grid>
						)}
						{countryStateChange && (
							<Grid item style={{ flex: 0.3 }}>
								<FormTypeAheadTextField
									style={{ marginTop: '16px', marginLeft: '8px', marginRight: '-8px' }}
									label={intl.formatMessage({ id: 'app.profile.edit.state' })}
									suggestions={states.map((s) => s.name)}
									name="team.state"
								/>
							</Grid>
						)}
						<Grid item style={{ flex: 0.3, paddingLeft: '16px' }}>
							<FormTextField
								label={intl.formatMessage({ id: 'app.profile.edit.postalCode' })}
								fullWidth
								type="text"
								name="team.postalCode"
								margin="normal"
								variant="filled"
							/>
						</Grid>
					</Grid>
				</Grid>

				<Grid item>
					<Grid item style={{ textAlign: 'center' }}>
						<Button
							style={{
								width: '200px'
							}}
							type="submit"
                            variant="contained"
                            color="primary"
						>
							{intl.formatMessage({ id: 'app.profile.edit.save' })}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</form>
	)
}

const mapStateToProps = (state, ownProps) => ({
	userDetails: userDetailsSelector(state),
	initialValues: userDetailsSelector(state),
	countries: countriesSelector(state),
	states: statesSelector(state),
	timeZones: timeZonesSelector(state),
	languages: languagesSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	loadCountries: () => dispatch(fetchCountries()),
	loadTimeZones: () => dispatch(fetchTimeZones()),
	loadStates: (countryId) => dispatch(fetchStates(countryId)),
	submitRequest: (user) => dispatch(updateUserDetailsById(user)),
	resetCountryState: () => dispatch(change('userProfile', 'state', '')),
	fetchLanguages: () => dispatch(fetchLanguages())
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: 'userProfile'
	}),
	injectIntl,
	withStyles(styles)
)

export default enhance(UpdateUserProfile)
