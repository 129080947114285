import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { useTheme } from '@material-ui/core'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'

const MemberEmpty = ({ intl }) => {
	const theme = useTheme()

	return (
		<Grid container direction="column">
			<Grid item style={{ padding: '64px', maxWidth: '50%' }}>
				<Typography
					variant="h2"
					style={{ fontWeight: '400', color: theme.palette.primary.A800, paddingBottom: '12px' }}
				>
					{intl.formatMessage({ id: 'app.userProfile.subscriptionTableEmptyHeader' })}
				</Typography>
			</Grid>
		</Grid>
	)
}

export default injectIntl(MemberEmpty)
