import React, {useEffect} from 'react'
import {withStyles} from "@material-ui/core"
import {injectIntl} from "react-intl"
import { compose } from 'recompose'
import { connect } from 'react-redux'
import Grid from "@material-ui/core/Grid"
import AddMemberButton from "../components/AddMemberButton"
import LabelPaper from "../../../components/LabelPaper"
import MemberEmpty from "../components/MemberEmpty"
import Member from "./Member"
import {userInvitationsSelector, canAddTeamMemberButtonSelector} from "../../../redux/selectors"
import {getTeamMembersById} from "../../../redux/actions"


const styles = () => ({
    button: {
        '&:hover': {
            backgroundColor: 'transparent'
        },
    },
    gradient: {
        background:
            'linear-gradient(45deg,  #d4e1f7 0%,#d4e1f7 50%,#ffffff 50%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */',
        filter:
            "progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4e1f7', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */"
    }
})


const TeamMembers = ({user, fetchInvites, invitations, showAddMemberButton, classes, intl}) => {

    useEffect(() => {
        if(user)
        {
            fetchInvites(user.id)
        }
    }, [user])

    return (
        <div style={{ position: 'relative' }}>
            <div style={{ padding: '0' }}>
                <Grid container direction="column" style={{ color: 'black' }}>
                    <Grid item style={{ width: '100%' }}>
                        <Grid container justify="center" style={{ maxHeight: '10px' }}>
                            <Grid item style={{ width: '100%', paddingTop: '20px', paddingRight: '21px' }}>
                                <Grid container justify="flex-end">                                    
                                    <AddMemberButton />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item style={{ width: '100%' }}>
                        {invitations && invitations.length === 0 ? (
                            <LabelPaper
                                Component={MemberEmpty}
                                label="Members"
                                classes={classes.gradient}
                            />
                        ) : (
                            <LabelPaper
                                label={intl.formatMessage({id: 'app.userProfile.teamMembers'})}
                                Component={Member}
                                data={invitations}
                                user={user}
                            />
                        )}
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    invitations: userInvitationsSelector(state),
    showAddMemberButton: canAddTeamMemberButtonSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchInvites: (id) => dispatch(getTeamMembersById(id)),
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withStyles(styles))

export default enhance(TeamMembers)
