import { authenticatedFetch } from './base'

export const inviteServiceProviderMember = (id, email, serviceProviderId, languageId) => {
	return authenticatedFetch(`/api/serviceProviderinvitation/invitemember/${languageId}/${serviceProviderId}/${encodeURIComponent(email.trim())}`, {
		method: 'post'
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(member) {
			return member
		})
}

export const updateServiceProviderInviteUserMember = (id, email, optionSelected) => {
	return authenticatedFetch(`/api/serviceProviderinvitation/${id}/${email}/optionmember/${optionSelected}`, {
		method: 'put'
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const resendServiceProviderInvitation = (invitationId, serviceProviderId) => {
	return authenticatedFetch(`/api/serviceProviderinvitation/${serviceProviderId}/${invitationId}/resend`, {
		method: 'post'
	}).then(function(response) {
		if (response.status === 200) {
			return response.json()
		} else {
			return false
		}
	})
}

export const removeInviteServiceProviderById = (serviceProviderId, userId) => {
    return authenticatedFetch(`/api/serviceProvider/${serviceProviderId}/invite/${userId}`, {
            method: 'delete'
        })
        .then(function (response) {
            if (response.status === 200) {
                return response.json()
            }
        })
        .then(function (response) {
            return response
        })
}
