import moment from 'moment'

export const required = (value) => (value !== undefined && value !== null ? undefined : 'Required')

export const requiredNotEmpty = (value) => (!!value && (''+value).trim().length > 0 ? undefined : 'Required')

export const passwordMatch = (matchName) => (value, allValues, props) => {
	return value && allValues[matchName] && value !== allValues[matchName] ? `Passwords must match` : undefined
}

export const passwordStrength = (value) => {
	//special characters
	//var strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')
	var strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})')
	if (value && value.indexOf(' ') > -1) {
		return 'Spaces are not allowed'
	}
	return value && strongRegex.test(value)
		? undefined
		: '8 character minimum. Must contain at least 1 upper case, 1 lower case, 1 number.'
}

export const email = (value) => {
	var strongRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i
	return value && strongRegex.test(value) ? undefined : 'Invalid email address'
}

export const url = (value) => {
	var strongRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/i
	return (!value || value && strongRegex.test(value)) ? undefined : 'Invalid URL'
}

export const birthDate = (value) => {
	if (value) {
		let d = new Date(value)
		var date = moment(d)
		var now = moment()
		if (date.isValid() && date.year() < 1900) {
			return 'Invalid date: Birth year must be greater than 1900'
		}

		if (date.isValid() && date.isAfter(now)) {
			return 'Invalid date: Must be born on or before ' + now.format('YYYY/MM/DD')
		}

		return date.isValid() ? undefined : 'Invalid Date'
	}
}

export const date = (value) => {
	if (value) {
		var date = moment(value)
		let isValidDate = date.isValid()
		if (isValidDate && date.year() < 1900) {
			return 'Invalid date: Year must be greater than 1900'
		}
		if (isValidDate && date.year() >= 2100) {
			return 'Invalid date'
		}
		return isValidDate ? undefined : 'Invalid Date'
	}
}

export const number = (value) => (value && isNaN(Number(value)) ? 'Must be a number' : undefined)

export const integerNumber = (value) => {
	let intNum = parseInt(value, 10)
	let flNum = parseFloat(value, 10)

	if (
		!!value
		&& (
			isNaN(intNum)
			|| intNum !== flNum
		)
	) {
		return 'Must be a number'
	}
	return undefined
}

export const positiveIntegerNumber = (value) => (value && (isNaN(Number(value)) || parseFloat(value) < 0 || !Number.isInteger(Number(value))) ? 'Must be a positive whole number' : undefined)

export const notEmpty = (value) => {
	return (
		(
			typeof value === 'string'
			|| value instanceof String
		)
		&& !value.trim().length ? 'Empty value is not allowed' : undefined
	);
}

export const greaterThanValue = (val) => (value) => {
	return value && value <= val ? `Must be greater than ${val}` : undefined
}

export const maxValue = (max) => (value) => {
	return value && value > max ? `Must be less than ${max}` : undefined
}

export const mustBeLessThanValue = (matchName) => (value, allValues, props) => {
	let upper = allValues[matchName]
	let lower = value
	if (!isNaN(upper) && !isNaN(lower)) {
		let u = parseFloat(upper)
		let l = parseFloat(lower)

		return l > u ? `Must be less than ${u}` : undefined
	}
	//check if they are dates
	let upperDate = Date.parse(upper)
	let lowerDate = Date.parse(lower)
	if (!isNaN(upperDate) && !isNaN(lowerDate))
	{
		return lowerDate > upperDate ? `Must be less than ${upper}` : undefined
	}

	return undefined
}

export const upperLessThanValue = (matchName) => (value, allValues, props) => {
	return value && value > allValues[matchName] ? `Must be greater than ${allValues[matchName]}` : undefined
}

export const phoneNumber = (value) => {
	if (value) {
		var startsWithZero = value.startsWith('0')
		return !startsWithZero && !isNaN(value) ? undefined : 'Invalid Phone Number'
	}
}

export const mustBeGreaterThanDueValue = (valueDueUnitName, limitDueValueName, limitDueUnitName) => (value, allValues, props) => {
	let sourceValue = value
	let dueUnit = parseInt(allValues[valueDueUnitName])
	switch (dueUnit) {
		case 0:
			break
		case 1:
			sourceValue = sourceValue * 7
			break
		case 2:
			sourceValue = sourceValue * 30
			break
		default:
			break
	}

	let limitValue = parseInt(allValues[limitDueValueName])
	let limitDueUnit = parseInt(allValues[limitDueUnitName])
	switch (limitDueUnit) {
		case 0:
			break
		case 1:
			limitValue = limitValue * 7
			break
		case 2:
			limitValue = limitValue * 30
			break
		default:
			break
	}
	
	return sourceValue < limitValue ? `Must be greater or equal to ${limitValue} days` : undefined
}

export const analogRequired = (value) => (value || value === 0 ? undefined : 'Required')

export const checkboxRequired = (value) => {
	if (!value) return 'Required'

	return value.length > 0 ? undefined : 'Required'
}

export const isValidNumber = (value) => {
	if (!value) return undefined
	return !isNaN(value) ? undefined : 'The entered value must be a valid number.'
}

export const minMaxValueRequired = (value, allValues, props, name) => {
	if (!value) return undefined
	var fieldName = name.split('.')
	fieldName = fieldName[2]
	fieldName = fieldName.replace('_', '')
	var result = props.collection[0].find((item) => item.data.uniqueId == fieldName).data
	if (result.minimum != null) {
		if (value < result.minimum) return `The entered value must be greater or equal to ${result.minimum}.`
	}
	if (result.maximum != null) {
		if (value > result.maximum) return `The entered value must be less than ${result.maximum}.`
	}
}

export const validateQuestionRule = (questionsContext, rule) => {
	try
	{
		const ruleToEval = 
			rule ? 
			rule.replace('&&', '&').replace('||','|') : //replacing is to avoid circuit evaluation and catch all syntax errors
			null;

		eval(`${questionsContext}Boolean(${ruleToEval})`)
	}
	catch(err)
	{
		return `Syntax error: ${err}` 
	}

	return undefined
}

export const requiredArray = (value) => {
	if(value === undefined || value === null || value.length === 0)
		return 'Required'

	if(Array.isArray(value) && value.length > 0)
		return undefined

	try {
		let arr = JSON.parse(value)
		return arr.length > 0 ? undefined : 'Required'
	}
	catch {
		return 'Required'
	}
}