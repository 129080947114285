import React, { useState, useEffect, Fragment } from 'react'
import IconButton from '@material-ui/core/IconButton'
import MuiMenu from '@material-ui/core/Menu'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import AssignAdministratorMenuItem from './AssignAdministratorMenuItem'
import ArchiveMenuItem from './ArchiveMenuItem'
import ReinstateMenuItem from './ReinstateMenuItem'
import GenerateLinkMenuItem from './GenerateLinkMenuItem'
import InviteMenuItem from './InviteMenuItem'
import GetStatisticsMenuItem from './GetStatisticsMenuItem'
import GenerateInvitationLinkMenuItem from './GenerateInvitationLinkMenuItem'

const ITEM_HEIGHT = 48

export default ({ intl, circle }) => {
	const [ anchorEl, setAnchorEl ] = useState(null)
	const open = Boolean(anchorEl)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	useEffect(() => {}, [])

	return (
		<div>
			<Fragment>
				<IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
					<MoreVertIcon />
				</IconButton>
				<MuiMenu
					id="long-menu"
					anchorEl={anchorEl}
					keepMounted
					open={open}
					onClose={handleClose}
					PaperProps={{
						style: {
							maxHeight: ITEM_HEIGHT * 6.5,
							width: 200,
							backgroundColor: '#243041',
							color: 'white',
							fontWeight: '400'
						}
					}}
				>
					{circle && circle.isArchived && <ReinstateMenuItem circle={circle} onComplete={handleClose} />}
					{circle && !circle.isArchived && <ArchiveMenuItem circle={circle} onComplete={handleClose} />}
					<AssignAdministratorMenuItem circle={circle} onComplete={handleClose} />
					<GenerateLinkMenuItem circle={circle} onComplete={handleClose} />
					{/* <InviteMenuItem circle={circle} onComplete={handleClose} />
					<GetStatisticsMenuItem circle={circle} onComplete={handleClose} /> */}
					<GenerateInvitationLinkMenuItem circle={circle} onComplete={handleClose} />
				</MuiMenu>
			</Fragment>
		</div>
	)
}
