import React, { Fragment, useState } from 'react'
import { injectIntl, FormattedDate } from 'react-intl'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import NameCell from './NameCell'
import ModifiedCell from './ModifiedCell'
import ProtocolNameCell from './ProtocolNameCell'
import CircleType from './CircleType'
import Menu from './Menu'
import LatralityCell from './LatralityCell'

const CircleRow = ({ circle, user }) => {
	const [ minHeight, setMinHeight ] = useState(64)

	return (
		<TableRow
			// key={protocol.id}
			style={{
				backgroundColor: circle.isArchived ? '#F6F6F6' : 'white'
			}}
		>
			<TableCell align="left" size="small" style={{ padding: '6px 6px 6px 0' }}>
				<div
					style={{
						minHeight: `${minHeight - 16}px`,
						borderLeft: `solid 4px ${circle.isArchived ? '#bbb' : '#007aff'}`
					}}
				>
					&nbsp;
				</div>
			</TableCell>
			<TableCell align="left" size="small" style={{ padding: '6px' }}>
				<CircleType circle={circle} />
			</TableCell>
			<TableCell align="left" size="small">
				<NameCell circle={circle} setHeightCallback={setMinHeight} />
			</TableCell>
			<TableCell align="left" size="small">
				<LatralityCell isBilateral={circle.isBilateral} setHeightCallback={setMinHeight} />
			</TableCell>
			<TableCell align="left" size="small">
				<ProtocolNameCell circle={circle} setHeightCallback={setMinHeight} />
			</TableCell>
            <TableCell align="left" size="small">
                <Typography variant="subtitle1" style={{ textAlign: 'left', fontWeight: '400' }}>
					{circle.sponsorName}
                </Typography>
            </TableCell>
			<TableCell align="left" size="small">
				<Typography variant="subtitle1" style={{ textAlign: 'left', fontWeight: '400' }}>
					{circle.numberUsers}
				</Typography>
			</TableCell>
			<TableCell align="left" size="small">
				<Typography variant="subtitle1" style={{ textAlign: 'left', fontWeight: '400' }}>
					{circle.totalSharedCases}
				</Typography>
			</TableCell>
            <TableCell align="left" size="small">
                <Typography variant="subtitle1" style={{ textAlign: 'left', fontWeight: '400' }}>
					{circle.casesCreated}
                </Typography>
            </TableCell>
			<TableCell align="right" size="small" style={{ padding: '5px 0' }}>
				<Menu circle={circle} />
			</TableCell>
		</TableRow>
	)
}

export default injectIntl(CircleRow)
