import React from "react";
import { TableCell } from "@material-ui/core";
import { useIntl } from "react-intl";


const RelatedServiceProviderDeafultAssigneeTableCell = ({
    serviceProvider
}) => {
    const intl = useIntl()

    return (
        <TableCell>
            { intl.formatMessage({ id: `app.userProfile.relatedServiceProviders.defaultAssignee.${serviceProvider.isDefaultAssignee}` }) }
        </TableCell>
    )
}

export default RelatedServiceProviderDeafultAssigneeTableCell;
