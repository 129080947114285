import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useTheme from '@material-ui/core/styles/useTheme'
import { isCreatingProtocolSelector } from '../../../redux/selectors'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import FormTextField from '../../../components/FormTextField'
import Button from '@material-ui/core/Button'
import { saveSystemTerms } from '../../../redux/actions'

//mode edit = 1    add != 1
const TermsUpdateModal = ({ intl, terms, handleSubmit, isCreating, update, mode = 1 }) => {
	const theme = useTheme()

	const onSubmitForm = (values) => {
		update(values.terms)
	}
	return (
		<form onSubmit={handleSubmit(onSubmitForm)}>
			<Grid
				container
				direction="column"
				justify="flex-start"
				alignItems="center"
				style={{
					minWidth: '75rem',
					padding: '4rem',
					margin: 'auto',
					backgroundColor: theme.palette.secondary.main
				}}
			>
				<Grid item style={{ alignSelf: 'center' }}>
					<Typography
						variant="h1"
						style={{
							color: '#fff',
							marginBottom: '25px',
						}}
					>
						{terms ? 'Edit Terms & Conditions' : 'Add New Terms & Conditions'}
					</Typography>
				</Grid>
				<Grid item style={{ alignSelf: 'flex-start', width: '100%' }}>
					<Typography
						variant="body1"
						style={{
							color: '#fff',
							marginBottom: '10px',
							fontWeight: '400'
						}}
						align="left"
					>
						Terms & Conditions
					</Typography>
					<FormTextField
						fullWidth
						type="text"
						name="terms"
						maxLength={8000}
						variant="filled"
						multiline={true}
						rows={10}
					/>
				</Grid>

				<Grid item>
					<Grid container spacing={1}>
						<Grid item>
							<Button
								label={intl.formatMessage({ id: 'app.valueTypes.saveButtonLabel' })}
								style={{
									width: '267px',
									marginTop: '40px'
								}}
								disabled={isCreating}
								type="submit"
                                variant="contained"
                                color="primary"
							>
								{intl.formatMessage({ id: 'app.valueTypes.saveButtonLabel' })}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</form>
	)
}

const mapStateToProps = (state, ownProps) => ({
	initialValues: { terms: ownProps.terms }
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	update: (terms) => dispatch(saveSystemTerms(terms))
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'saveTerms',
		touchOnChange: true,
		touchOnBlur: true,
		enableReinitialize: true
	})
)

export default enhance(TermsUpdateModal)
