import React from 'react'
import { Field, reduxForm } from 'redux-form'
import TextField from '@material-ui/core/TextField'
import { withStyles, makeStyles } from '@material-ui/core/styles'

const styles = {
	// input: {
	// 	color: 'white',
	// 	fontSize: '1rem',
	// 	backgroundColor: 'rgb(36, 48, 65)'
	// },
	// root: {
	// 	color: 'white',
	// 	'&$disabled': {
	// 		color: 'red',
	// 		backgroundColor: 'rgb(36, 48, 65)'
	// 	}
	// },
	// focused: {
	// 	color: 'white',
	// 	backgroundColor: 'rgb(36, 48, 65)',
	// 	'&$focused': {
	// 		color: 'white',
	// 		backgroundColor: 'rgb(36, 48, 65)'
	// 	}
	// },
	// disabled: {
	// 	color: 'white',
	// 	backgroundColor: 'rgb(36, 48, 65)'
	// }
}

const useLabelStyles = makeStyles({
	root: {
		marginLeft: 0,
		'&$disabled': {
			color: '#6C737B'
		}
	},
	focused: {
		'&$focused': {
			color: 'white'
		}
	},
	disabled: {
		color: '#6C737B'
	}
})

const useInputStyles = makeStyles({
	root: {
		//color: 'white',
		//backgroundColor: 'rgb(36, 48, 65)',
		'&$disabled': {
			color: '#6C737B'
			//backgroundColor: 'rgb(36, 48, 65)'
		}
	},
	focused: {
		//color: 'white',
		//backgroundColor: 'rgb(36, 48, 65)',
		'&$focused': {
			//	color: 'white'
			//backgroundColor: 'rgb(36, 48, 65)'
		}
	},
	disabled: {
		color: '#6C737B'
		//backgroundColor: 'rgb(36, 48, 65)'
	}
})

const FormHelperTextStyles = makeStyles({
	root: {
		marginLeft: 0,
		marginRight: 0
	}
})

const renderTextField = ({
	classes,
	label,
	input,
	meta: { touched, invalid, error },
	inputLabelProps,
	inputProps,
	style,
	fullWidth,
	margin,
	variant,
	type,
	labelStyles,
	inputStyles,
	helperStyles,
	maxLength,
	disabled,
	backgroundColor,
	fontColor,
	...custom
}) => {
	return (
		<div className="global-input-wrapper">
			<TextField
				label={label}
				placeholder={label}
				error={touched && invalid}
				helperText={touched && error}
				{...input}
				margin="dense"
				InputLabelProps={{
					classes: {
						root: labelStyles.root,
						focused: labelStyles.focused,
						disabled: labelStyles.disabled
					}
				}}
				InputProps={{
					classes: {
						root: inputStyles.root,
						focused: inputStyles.focused,
						disabled: inputStyles.disabled
					}
				}}
				disabled={disabled}
				FormHelperTextProps={{ classes: { root: helperStyles.root, contained: helperStyles.root } }}
				inputProps={{ maxLength: maxLength }}
				style={{ ...style, backgroundColor: backgroundColor, color: fontColor }}
				classes={{ root: inputStyles.root, error: inputStyles.error, disabled: inputStyles.disabled }}
				fullWidth
				margin={margin}
				variant={variant}
				{...custom}
				type={type}
				shrink="true"
			/>
		</div>
	)
}

const FormTextField = ({
	classes,
	label,
	name,
	inputLabelProps,
	inputProps,
	style,
	fullWidth,
	margin,
	variant,
	type,
	validators,
	disabled,
	maxLength = 255,
	backgroundColor = 'rgb(36, 48, 65)',
	fontColor = 'white',
	...custom
}) => {
	var labelStyles = useLabelStyles()
	var inputStyles = useInputStyles()
	var helperStyles = FormHelperTextStyles()
	return (
		<Field
			name={name}
			component={renderTextField}
			label={label}
			inputLabelProps={inputLabelProps}
			inputProps={inputProps}
			style={style}
			fullWidth={fullWidth}
			margin={margin}
			variant={variant}
			type={type}
			validate={validators}
			maxLength={maxLength}
			classes={classes}
			labelStyles={labelStyles}
			inputStyles={inputStyles}
			helperStyles={helperStyles}
			disabled={disabled}
			fontColor={fontColor}
			backgroundColor={backgroundColor}
			{...custom}
		/>
	)
}

export default withStyles(styles)(FormTextField)
