import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useTheme from '@material-ui/core/styles/useTheme'
import { connect, useDispatch } from 'react-redux'
import { reduxForm, change } from 'redux-form'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { startSaving, hideModal,  } from '../../redux/actions'
import CalmButton from '../../components/CalmButton'
import EntityAuditCommentFields from './EntityAuditCommentFields'
import { investigatorsForProtocolVersionEntityAuditSelector } from '../../redux/selectors'


const EntityAuditCommentModal = ({ 
	saveCallback,
	intl, 
	handleSubmit, 
	entityId,
	entityTypeId,
	form,
	investigators
 }) => {
	const theme = useTheme()
	const dispatch = useDispatch()

	const onSubmitForm = (values) => {
		localStorage.setItem('entityAuditCommentsPrefill', JSON.stringify({
			commentText: values.commentText,
			protocolVersionId: values.protocolVersionId,
			relatedTaskUrl: values.relatedTaskUrl,
			investigatorId: investigators && investigators.some((x) => x.id === values.investigatorId) ? values.investigatorId : null
		}))
		if (!!saveCallback) {
			saveCallback(values)
		}
		else {
			dispatch(hideModal())
		}
	}

	useEffect(() => {}, [])

	return (
		<form onSubmit={handleSubmit(onSubmitForm)}>
			<Grid
				container
				direction="column"
				justify="flex-start"
				alignItems="center"
				style={{ width: '75rem', padding: '4rem', margin: 'auto', backgroundColor: theme.palette.secondary.main }}
			>
				<Grid item style={{ alignSelf: 'center' }}>
					<Typography
						variant="h1"
						style={{
							color: '#fff',
							marginBottom: '25px',
						}}
					>
						{intl.formatMessage({ id: 'app.audit.commentModal.Header' })}
					</Typography>
				</Grid>
				<EntityAuditCommentFields entityId={entityId} entityTypeId={entityTypeId} formName={form}/>
				<Grid item>
					<CalmButton
						label={intl.formatMessage({ id: 'app.general.saveChangesButtonText' })}
						style={{
							width: '267px',
							marginTop: '40px'
						}}
						type="submit"
                        variant="contained"
                        color="primary"
					>
						{intl.formatMessage({ id: 'app.general.saveChangesButtonText' })}
					</CalmButton>
				</Grid>
			</Grid>
		</form>
	)
}

const mapStateToProps = (state, ownProps) => ({
	investigators: investigatorsForProtocolVersionEntityAuditSelector(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	start: () => dispatch(startSaving()),
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'entityAuditComment',
		touchOnChange: true,
		touchOnBlur: true
	})
)

export default enhance(EntityAuditCommentModal)
