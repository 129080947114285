import React from "react";
import { TableCell } from "@material-ui/core";


const RelatedServiceProviderRoleTableCell = ({
    serviceProvider
}) => {
    return (
        <TableCell>
            {serviceProvider.roleName}
        </TableCell>
    )
}

export default RelatedServiceProviderRoleTableCell;
