import { createSelector } from 'reselect'

const ADD_UNIT_REQUESTED = 'ADD_UNIT_REQUESTED'
const ADD_UNIT_SUCCESS = 'ADD_UNIT_SUCCESS'
const ADD_UNIT_FAILED = 'ADD_UNIT_FAILED'
const SEARCH_UNITS_SUCCESS = 'SEARCH_UNITS_SUCCESS'
const FETCH_UNITS_SUCCESS = 'FETCH_UNITS_SUCCESS'
const FETCH_UNIT_SUCCESS = 'FETCH_UNIT_SUCCESS'
const FETCH_UNITS_BY_TYPE_SUCCESS = 'FETCH_UNITS_BY_TYPE_SUCCESS'
const FETCH_TYPES_SUCCESS = 'FETCH_TYPES_SUCCESS'

export const fetchFileTypes = () => (dispatch) => {
	return dispatch({ type: FETCH_TYPES_SUCCESS, data: [{ id: 0, name: 'Image' }, { id: 1, name: 'PDF' }] });
}

const mainSelector = (state) => [] //state.fileTypes

export const fileTypesSelector = createSelector(mainSelector, (state) => {

    state.fileTypes = state.fileTypes || [{ id: 10, name: 'Image' }, { id: 11, name: 'PDF' }];

    if (state && state.fileTypes && state.fileTypes.length > 0) {

        var s = state.fileTypes.map((r) => {
			return { value: r.id, name: r.name }
		})

		return s
	}
	return []
})

