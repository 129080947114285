import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { injectIntl, useIntl } from 'react-intl'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import format from "date-fns/format";
import frLocale from "date-fns/locale/fr";
import ruLocale from "date-fns/locale/ru";
import enLocale from "date-fns/locale/en-US";
import enGBLocale from "date-fns/locale/en-GB";
import esLocale from "date-fns/locale/es";
import itLocale from "date-fns/locale/it";
import deLocale from "date-fns/locale/de";
import daLocale from "date-fns/locale/da";
import plLocale from "date-fns/locale/pl";
import trLocale from "date-fns/locale/tr";

const useInputStyles = makeStyles({
	root: (props) => ({
		color: props.design === 'light' ? '#000' : 'white',
		backgroundColor: props.design === 'light' ? 'rgba(0, 0, 0, 0.09)' : 'rgb(36, 48, 65)',
		marginTop: '0',
		marginBottom: '0',
		'&$disabled': {
			color: '#6C737B',
			backgroundColor: props.design === 'light' ? 'rgba(0, 0, 0, 0.09)' : 'rgb(36, 48, 65)',
		},
	}),
	focused: (props) => ({
		color: props.design === 'light' ? '#000' : 'white',
		backgroundColor: props.design === 'light' ? 'rgba(0, 0, 0, 0.09)' : 'rgb(36, 48, 65)',
	}),
	disabled: (props) => ({
		color: '#6C737B',
		backgroundColor: props.design === 'light' ? 'rgba(0, 0, 0, 0.09)' : 'rgb(36, 48, 65)',
		marginTop: '0',
		marginBottom: '0',
	}),
});

const useLabelStyles = makeStyles({
	root: (props) => ({
		color: props.design === 'light' ? '#000' : 'white',
		'&$disabled': {
			color: '#6C737B',
		},
		marginLeft: '0',
	}),
	focused: (props) => ({
		color: props.design === 'light' ? '#000' : 'white',
		'&$focused': {
			color: props.design === 'light' ? '#000' : 'white',
		}
	}),
	disabled: (props) => ({
		color: '#6C737B',
		marginLeft: '0',
	}),
});

const useIconButtonStyles = makeStyles({
	root: (props) => ({
		color: props.design === 'light' ? 'rgba(0, 0, 0, 0.54) !important' : 'white !important'
	})
});


const useHelperTextStyles = makeStyles({
	root: {
		marginLeft: 0,
		marginRight: 0
	}
})

const localeMap = {
	en: enLocale,
	es: enLocale,
	fr: enLocale,
	ru: enLocale,
	it: enLocale,
	de: enLocale,
	da: enLocale,
	pl: enLocale,
	tr: enLocale,
	'en-GB': enLocale
}

class LocalizedUtils extends DateFnsUtils {
	getCalendarHeaderText(date) {
		return format(date, "LLLL", { locale: this.locale });
	}

	getDatePickerHeaderText(date) {
		return format(date, useIntl().formatMessage({ id: 'app.general.datePickerHeaderFormat' }), { locale: this.locale });
	}
}

const transformDate = (date) => {
	if (date) {
		return date.toLocaleDateString('en-US')
	}

	return date
}

const LocalizedDatePicker = ({
	defaultDate,
	onDateChange,
	label,
	disabled,
	error,
	externalInputStyles,
	externalLabelStyles,
	externalHelperStyles,
	inputProps,
	openToYear,
	disablePast,
	disableFuture,
	minDate,
	maxDate,
	intl,
	touched,
	views = ['year', 'month', 'date'],
	startWeekOnMonday,
	shouldDisableDate,
	design = "light", // "light" design is default. Can be also "dark" for modal windows.
}) => {
	const [changed, setChanged] = useState(false)
	const [currentDate, setCurrentDate] = useState(defaultDate ? transformDate(new Date(defaultDate)) : null)

	useEffect(() => {
		setChanged(touched)
	}, [touched])

	useEffect(() => {
		if (intl && localeMap[intl.locale] && localeMap[intl.locale].options) {
			localeMap[intl.locale].options.weekStartsOn = startWeekOnMonday ? 1 : 0
		}
	}, [startWeekOnMonday, intl, intl.locale])

	useEffect(() => {
		let newDate = defaultDate ? transformDate(new Date(defaultDate)) : null
		setCurrentDate(newDate)
		if (onDateChange) {
			onDateChange(newDate)
		}
	}, [defaultDate])

	const inputStyles = externalInputStyles ?? useInputStyles({ design });
	const labelStyles = externalLabelStyles ?? useLabelStyles({ design });
	var helperStyles = externalHelperStyles ?? useHelperTextStyles()
	const iconButtonStyles = useIconButtonStyles({ design });

	const handleDateChange = (date) => {
		setChanged(false)
		const newDate = transformDate(date)
		setCurrentDate(newDate)
		if (onDateChange) {
			onDateChange(newDate)
		}
	}

	const checkError = () => {
		return error === 'Required' && currentDate ? null : error
	}

	return (
		<MuiPickersUtilsProvider utils={LocalizedUtils} locale={localeMap[intl.locale]}>
			<KeyboardDatePicker
				data-testid="survey-change-date-modal-date-picker"
				autoOk
				variant='inline'
				format={intl.formatMessage({ id: 'app.general.datePickerFormat' })}
				margin='normal'
				id='date-picker-inline'
				label={label}
				value={currentDate}
				disabled={disabled}
				disablePast={disablePast}
				disableFuture={disableFuture}
				minDate={minDate}
				maxDate={maxDate}
				placeholder={intl.formatMessage({ id: 'app.general.datePickerPlaceholder' })}
				onChange={handleDateChange}
				inputVariant='filled'
				helperText={changed && checkError()}
				InputProps={{
					classes: {
						root: inputStyles.root,
						disabled: inputStyles.disabled
					},
					inputProps: {
						style: {
						  padding: label ? '27px 12px 10px' : '20px 12px 17px',
						  ...(inputProps?.style || {}),
						},
						...inputProps,
					},
				}}
				InputLabelProps={{
					classes: {
						root: labelStyles.root,
						focused: labelStyles.focused,
						disabled: labelStyles.disabled
					}
				}}
				FormHelperTextProps={{
					classes: {
						root: helperStyles.root,
						contained: helperStyles.root
					}
				}}
				classes={{
					root: inputStyles.root,
					disabled: inputStyles.disabled,
				}}
				inputProps={inputProps}
				error={changed && checkError()}
				onBlur={() => setChanged(true)}
				views={views}
				openTo={openToYear ? 'year' : 'date'}
				KeyboardButtonProps={{
					...inputProps,
					classes: iconButtonStyles,
				}}
				shouldDisableDate={shouldDisableDate}
			/>
		</MuiPickersUtilsProvider>
	)
}

const mapStateToProps = () => ({})

const mapDispatchToProps = () => ({})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(LocalizedDatePicker)
