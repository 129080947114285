import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import DefaultFormTextField from '../DefaultFormTextField'
import { formValueSelector } from 'redux-form'
import { required } from '../../utilities/validators'
import { useStore } from 'react-redux'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'
const requiredValidator = required
const selector = formValueSelector('editQuestion')

export default forwardRef((props, ref) => {
	const { question } = props
	const store = useStore()
	const dispatch = useDispatch()
	useEffect(
		() => {
			if (question && question.data) {
				dispatch(change('editQuestion', 'question', question.data.title))
				dispatch(change('editQuestion', 'instructions', question.data.instructions))
				dispatch(change('editQuestion', 'tag', question.data.tag))
			}
		},
		[ question ]
	)

	useImperativeHandle(ref, () => ({
		getQuestion() {
			return {
				id: question.data.id,
				title: selector(store.getState(), 'question'),
				type: 'tf',
				instructions: selector(store.getState(), 'instructions'),
				tag: selector(store.getState(), 'tag')
			}
		}
	}))

	return (
		<Grid container direction="column" alignItems="flex-start">
			<Grid item style={{ width: '100%' }}>
				<DefaultFormTextField
					label="Tag"
					variant="filled"
					fullWidth
					name="tag"
					inputProps={{ maxLength: 254 }}
					maxLength={254}
				/>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				<DefaultFormTextField
					label="Question"
					variant="filled"
					fullWidth
					name="question"
					validators={[ requiredValidator ]}
					inputProps={{ maxLength: 1023 }}
					maxLength={1023}
				/>
			</Grid>

			<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
				<DefaultFormTextField
					label="Instructions"
					variant="filled"
					name="instructions"
					fullWidth
					inputProps={{ maxLength: 2047 }}
					rows="3"
					maxLength={2047}
					multiline
				/>
			</Grid>
		</Grid>
	)
})
