import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import DefaultFormTextField from '../../DefaultFormTextField'
import DefaultFormNumberField from '../../DefaultFormNumberField'
import { makeStyles } from '@material-ui/core/styles'
import { required } from '../../../utilities/validators'
import { uuidv4 } from '../../../utilities/utilities'
import { useSelector, shallowEqual } from 'react-redux'
import { languageNameSelector } from '../../../redux/selectors'
const requiredValidator = required

export default forwardRef((props, ref) => {
	const { onDeleteCallback, answer, showScoreEntry } = props
	const [ internalAnswer, setAnswer ] = useState(null)
	const [ value, setValue ] = useState(null)
	const [ id, setId ] = useState(uuidv4().replace('-', ''))
	const [ scoreId, setScoreId ] = useState(uuidv4().replace('-', ''))
	let languages = useSelector(languageNameSelector, shallowEqual)

	useImperativeHandle(ref, () => ({
		getAnswer() {
			let a = []
			return { answer: internalAnswer, value, canonicalId: answer.data.baseId }
		}
	}))

	useEffect(
		() => {
			if (answer) {
				setId(answer.data.answerId)
				setScoreId(answer.data.scoreId)
				if (answer.data.answer) {
					setAnswer(answer.data.label)
					setValue(answer.data.value)
				}
			}

			answer.ref = ref
		},
		[ answer, ref ]
	)

	return (
		<Grid container style={{ width: '100%' }} alignItems="center">
			<Grid item style={{ width: '100%' }}>
				<Typography variant="subtitle2">
					Label ({languages(1)}): {answer.data.baseLabel}
				</Typography>
			</Grid>
			<Grid item style={{ flex: 1, paddingRight: '5px' }}>
				<DefaultFormTextField
					label={`Label (${languages(answer.data.languageId)})`}
					validators={[ requiredValidator ]}
					name={id}
					variant="filled"
					onChange={(e) => setAnswer(e.target.value)}
				/>
			</Grid>
			{showScoreEntry && (
				<Grid item style={{ paddingLeft: '5px' }}>
					<DefaultFormNumberField
						label="Value"
						name={scoreId}
						disabled={true}
						variant="filled"
						allowDecimal={true}
						validators={[ requiredValidator ]}
					/>
				</Grid>
			)}
		</Grid>
	)
})
