import React from "react"
import { hideModal } from "../../../redux/actions"
import { compose } from "recompose"
import { connect } from "react-redux"
import { injectIntl } from "react-intl"
import { useTheme, Grid, Typography, Button } from "@material-ui/core"


const SimulationRequestModal = ({
    onConfirm,
    style,
    closeDialog,
    intl
}) => {
    const theme = useTheme()
    
    const handleConfirm = () => {
        if (onConfirm) {
            onConfirm()
        }
        closeDialog()
    }

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{
                padding: '4rem',
                backgroundColor: theme.palette.secondary.main,
                ...style
            }}
        >
            <Grid
                item
                style={{
                    margin: '20px 0'
                }}
            >
                <Typography
                    style={{
                    	fontSize: '16px',
                    	lineHeight: '16px',
                    	color: 'white',
                    	fontWeight: '400'
                    }}
                >
                    {intl.formatMessage({ id: 'app.survey.simulationRequestDialog.text' })}
                </Typography>
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={3}
                style={{
                    marginTop: '20px'
                }}
            >
                <Grid item >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleConfirm}
                    >
                        {intl.formatMessage({ id: 'app.survey.simulationRequestDialog.buttonYes' })}
                    </Button>
                    </Grid>
                    <Grid item >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={closeDialog}
                    >
                        {intl.formatMessage({ id: 'app.survey.simulationRequestDialog.buttonNo' })}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
	closeDialog: () => dispatch(hideModal()),
})

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)

export default enhance(SimulationRequestModal)
