import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { clearCircleInvitationLink, showModal } from '../../../redux/actions'
import CreateInvitationLinkModal from './CreateInvitationLinkModal'

export default ({ circle, onComplete }) => {
	const dispatch = useDispatch()
	const intl = useIntl()
	const onClick = () => {
		if (onComplete) {
			onComplete()
		}
		dispatch(showModal(CreateInvitationLinkModal, { circle: circle }))
		dispatch(clearCircleInvitationLink())
	}

	return (
		<MenuItem
			onClick={() => {
				onClick()
			}}
		>
			{intl.formatMessage({ id: 'app.circles.generateInvitationLink' })}
		</MenuItem>
	)
}