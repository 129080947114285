import React from 'react'
import { Field, reduxForm } from 'redux-form'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'

const renderCheckboxField = ({
	label,
	input,
	meta: { touched, invalid, error },
	inputLabelProps,
	inputProps,
	style,
	fullWidth,
	margin,
	variant,
	type,
    position = 'start',
    color,
	fontColor,
	...custom
}) => {
	const {disabled} = custom
	return (
		<FormGroup row>
			<FormControlLabel
				control={<Checkbox color={color} checked={input.value ? true : false} style={style} onChange={input.onChange} disabled={disabled} />}
                label={<span style={{...style, color: fontColor, opacity: disabled ? 0.33 : 1 }}>{ label }</span>}
                style={{ ...style, marginLeft: '-0', color: fontColor }}
				labelPlacement={position}
			/>
		</FormGroup>
	)
}

export default ({
	label,
	name,
	inputLabelProps,
	inputProps,
	style,
	fullWidth,
	margin,
	variant,
	type,
	validators,
	onChange,
    position, 
    color,
	fontColor,
	disabled
}) => (
	<Field
		name={name}
		component={renderCheckboxField}
		label={label}
		inputLabelProps={inputLabelProps}
		inputProps={inputProps}
		style={style}
		fullWidth
		margin={margin}
		variant={variant}
		type={type}
		validate={validators}
		onChange={onChange}
        position={position}
        color={color}
		fontColor={fontColor || color}
		disabled={disabled}
	/>
)
