import React from 'react'
import QuestionContainer from './QuestionContainer'
import FileQuestion from '../FileQuestion'

export default ({ question, position, positionDisplay }) => {
    return (
        <QuestionContainer
            title={question.title}
            position={position}
            positionDisplay={positionDisplay}
            instructions={question.instructions}
            optional={question.isOptional}
            displayInstructionAsIcon={question.displayInstructionAsIcon}
            children={
                <div data-testid="file-question-survey-component">
                    <FileQuestion
                        question={question}
                        position={position}
                        positionDisplay={positionDisplay}
                        disabled={true}
                        />
                </div>
            }
        />
	)
}
