import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Typography, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { useIntl } from 'react-intl'

const getResultsText = (data, intl) => {
	if (data && data.length > 0) {
		if (data.length === 1) {
			return intl.formatMessage({ id: 'app.search.singleResult' })
		} else {
			return intl.formatMessage({ id: 'app.search.manyResult' }).replace('%%ResultCount%%', data.length)
		}
	} else {
		return ''
	}
}

export default ({ hasData, data, headerText, getContent, hasSearch }) => {
	const intl = useIntl()

	return (
		<Fragment>
			<Fragment>
				{hasSearch &&
				hasData && (
					<Grid
						item
						style={{
							alignSelf: 'center',
							marginTop: '15px',
							width: '100%',
							borderBottom: '2px solid rgba(0,0,0,0.3)'
						}}
					>
						<Typography variant="h6">
							{headerText} <Typography variant="caption">{getResultsText(data, intl)}</Typography>
						</Typography>
					</Grid>
				)}
				<Fragment>
					{hasSearch &&
					!hasData && (
						<Fragment>
							<Grid
								item
								style={{
									alignSelf: 'center',
									marginTop: '15px',
									width: '100%',
									borderBottom: '2px solid rgba(0,0,0,0.3)'
								}}
							>
								<Typography variant="h6"> {headerText} </Typography>
							</Grid>
							<Grid
								item
								style={{
									alignSelf: 'center',
									marginTop: '15px',
									width: '100%'
								}}
							>
								<Typography variant="body2">No matches found</Typography>
							</Grid>
						</Fragment>
					)}
				</Fragment>

				<Table>
					<TableBody>
						{hasData &&
							data &&
							data.length > 0 &&
							data.map((r) => {
								return (
									<TableRow style={{ borderBottom: '1px solid rgba(0,0,0,0.3)' }}>
										<TableCell style={{ padding: '16px 0' }}>{getContent(r)}</TableCell>
									</TableRow>
								)
							})}
					</TableBody>
				</Table>
			</Fragment>
		</Fragment>
	)
}
