import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { showModal } from '../../../redux/actions'
import { useDispatch } from 'react-redux'
import ManageSubscriptionModal from '../ManageSubscriptionModal'
import ManageSubscriptionCreditsModal from '../ManageSubscriptionCreditsModal'

const ManageSubscriptionMenuItem = ({ intl, subscriber, onComplete }) => {
	const history = useHistory()
	const dispatch = useDispatch()
	const onClick = () => {
		dispatch(showModal(ManageSubscriptionCreditsModal, { subscriber: subscriber }))
		onComplete()
		//history.push(`/protocols/${protocol.id}/details`)
	}

	return (
		<MenuItem
			onClick={() => {
				onClick()
			}}
		>
			Manage Credits
		</MenuItem>
	)
}

const enhance = compose(injectIntl)

export default enhance(ManageSubscriptionMenuItem)
