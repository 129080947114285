import * as users from '../../../apis/user'
import * as invitations from '../../../apis/userInvitation'
import { createSelector } from 'reselect'
import * as cookies from '../../../utilities/cookies'
import { hideModal } from '../core'
import { setSnackbarMessage } from '../../actions'
import * as circleInvitation from '../../../apis/circleInvitation'
import { getUser } from '../../../utilities/userUtilities'

const CLEAR_USER_REQUESTED = 'CLEAR_USER_REQUESTED'

export const clearUserDetails = () => (dispatch) => {
	dispatch({ type: CLEAR_USER_REQUESTED, data: {} })
}

const FETCH_USER_REQUESTED = 'FETCH_USER_REQUESTED'
const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
const FETCH_USER_FAILED = 'FETCH_USER_FAILED'

export const getUserDetailsById = (id) => (dispatch) => {
	if (!id) {
		return dispatch({ type: FETCH_USER_REQUESTED, data: {} })
	}
	return users.fetchUserById(id).then((user) => {
		if (user) {
			return dispatch({ type: FETCH_USER_SUCCESS, data: user })
		} else {
			return dispatch({ type: FETCH_USER_FAILED, data: {} })
		}
	})
	return
}

const FETCH_FULL_USER_REQUESTED = 'FETCH_FULL_USER_REQUESTED'
const FETCH_FULL_USER_SUCCESS = 'FETCH_FULL_USER_SUCCESS'
const FETCH_FULL_USER_FAILED = 'FETCH_FULL_USER_FAILED'

export const getFullUserById = (id) => (dispatch) => {
	dispatch({ type: FETCH_FULL_USER_REQUESTED, data: {} })
	return users.fetchFullUserById(id).then((user) => {
		if (user) {
			return dispatch({ type: FETCH_FULL_USER_SUCCESS, data: user })
		} else {
			return dispatch({ type: FETCH_FULL_USER_FAILED, data: {} })
		}
	})
	return
}

const FETCH_USER_NOTIFICATION_SETTINGS_REQUESTED = 'FETCH_USER_NOTIFICATION_SETTINGS_REQUESTED'
const FETCH_USER_NOTIFICATION_SETTINGS_SUCCESS = 'FETCH_USER_NOTIFICATION_SETTINGS_SUCCESS'
const FETCH_USER_NOTIFICATION_SETTINGS_FAILED = 'FETCH_USER_NOTIFICATION_SETTINGS_FAILED'

export const getUserNotificationSettingsById = (id) => (dispatch) => {
	dispatch({ type: FETCH_USER_NOTIFICATION_SETTINGS_REQUESTED, data: {} })
	return users.fetchUserNotificationSettingsById(id).then((settings) => {
		if (settings) {
			return dispatch({ type: FETCH_USER_NOTIFICATION_SETTINGS_SUCCESS, data: settings })
		} else {
			return dispatch({ type: FETCH_USER_NOTIFICATION_SETTINGS_FAILED, data: {} })
		}
	})
	return
}

const UPDATE_USER_NOTIFICATION_SETTINGS_REQUESTED = 'UPDATE_USER_NOTIFICATION_SETTINGS_REQUESTED'
const UPDATE_USER_NOTIFICATION_SETTINGS_SUCCESS = 'UPDATE_USER_NOTIFICATION_SETTINGS_SUCCESS'
const UPDATE_USER_NOTIFICATION_SETTINGS_FAILED = 'UPDATE_USER_NOTIFICATION_SETTINGS_FAILED'

export const updateUserNotificationSettingsById = (id, outlier, protocol, invitiation) => (dispatch) => {
	dispatch({ type: UPDATE_USER_NOTIFICATION_SETTINGS_REQUESTED, data: {} })
	return users.updateUserNotificationSettingsById(id, outlier, invitiation, protocol).then((settings) => {
		if (settings) {
			return dispatch({ type: UPDATE_USER_NOTIFICATION_SETTINGS_SUCCESS, data: settings })
		} else {
			return dispatch({ type: UPDATE_USER_NOTIFICATION_SETTINGS_FAILED, data: {} })
		}
	})
	return
}

const UPDATE_USER_DETAILS_REQUESTED = 'UPDATE_USER_DETAILS_REQUESTED'
const UPDATE_USER_DETAILS_SUCCESS = 'UPDATE_USER_DETAILS_SUCCESS'
const UPDATE_USER_DETAILS_FAILED = 'UPDATE_USER_DETAILS_FAILED'

export const updateUserDetailsById = (user) => (dispatch) => {
	dispatch({ type: UPDATE_USER_DETAILS_REQUESTED, data: {} })
	return users.updateUserDetailsById(user).then((user) => {
		if (user) {
			dispatch(hideModal())
			return dispatch({ type: UPDATE_USER_DETAILS_SUCCESS, data: user })
		} else {
			return dispatch({ type: UPDATE_USER_DETAILS_FAILED, data: {} })
		}
	})
	return
}

const SET_USER_PATHOLOGIES_AND_PROMS_REQUESTED = 'SET_USER_PATHOLOGIES_AND_PROMS_REQUESTED'
const SET_USER_PATHOLOGIES_AND_PROMS_SUCCESS = 'SET_USER_PATHOLOGIES_AND_PROMS_SUCCESS'
const SET_USER_PATHOLOGIES_AND_PROMS_FAILED = 'SET_USER_PATHOLOGIES_AND_PROMS_FAILED'

export const setInitialUserPathologiesAndProms = (pathology, proms) => (dispatch) => {
	dispatch({ type: SET_USER_PATHOLOGIES_AND_PROMS_REQUESTED, data: {} })

	let user = cookies.get('user')
	return users.setPathologiesAndProms(user.id, [ pathology ], proms, true).then((response) => {
		if (response) {
			dispatch(getFullUserById(user.id))
			return dispatch(hideModal())
		} else {
			//TODO: Show message
			dispatch(setSnackbarMessage('Unable to edit profile. Please enter all required fields.'))
			return dispatch({ type: SET_USER_PATHOLOGIES_AND_PROMS_FAILED, data: {} })
		}
	})
	return
}

const IGNORE_USER_PATHOLOGIES_AND_PROMS_REQUESTED = 'IGNORE_USER_PATHOLOGIES_AND_PROMS_REQUESTED'
const IGNORE_USER_PATHOLOGIES_AND_PROMS_SUCCESS = 'IGNORE_USER_PATHOLOGIES_AND_PROMS_SUCCESS'
const IGNORE_USER_PATHOLOGIES_AND_PROMS_FAILED = 'IGNORE_USER_PATHOLOGIES_AND_PROMS_FAILED'

export const ignoreInitialUserPathologiesAndProms = () => (dispatch) => {
	dispatch({ type: IGNORE_USER_PATHOLOGIES_AND_PROMS_REQUESTED, data: {} })

	let user = cookies.get('user')
	return users.ignoreInitialPathologiesAndProms(user.id).then((response) => {
		if (response) {
			dispatch(getFullUserById(user.id))
			return dispatch(hideModal())
		} else {
			return dispatch({ type: IGNORE_USER_PATHOLOGIES_AND_PROMS_FAILED, data: {} })
		}
	})
	return
}

const SET_USER_LANGUAGE_REQUESTED = 'SET_USER_LANGUAGE_REQUESTED'
const SET_USER_LANGUAGE_SUCCESS = 'SET_USER_LANGUAGE_SUCCESS'
const SET_USER_LANGUAGE_FAILED = 'SET_USER_LANGUAGE_FAILED'

export const setUserLanguage = (language) => (dispatch) => {
	dispatch({ type: SET_USER_LANGUAGE_REQUESTED, data: {} })
	let user = cookies.get('user')
	return users.updateUserLanguage(user.id, language).then((response) => {
		if (response) {
			dispatch(getFullUserById(user.id))
		} else {
			return dispatch({ type: SET_USER_LANGUAGE_FAILED, data: {} })
		}
	})
	return
}

const SET_USER_INVITATION_MEMBER_REQUESTED = 'SET_USER_INVITATION_MEMBER_REQUESTED'
const SET_USER_INVITATION_MEMBER_SUCCESS = 'SET_USER_INVITATION_MEMBER_SUCCESS'
const SET_USER_INVITATION_MEMBER_FAILED = 'SET_USER_INVITATION_MEMBER_FAILED'

export const inviteUserMember = (id, email, languageId) => (dispatch) => {
	dispatch({ type: SET_USER_INVITATION_MEMBER_REQUESTED, data: {} })

	return invitations.inviteUserMember(id, email, languageId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(getTeamMembersById(id))
			dispatch({ type: SET_USER_INVITATION_MEMBER_SUCCESS, data: response })
			return dispatch(hideModal())
		} else {
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.errorMessages.${response.errorCode}`, isSuccess: false }
			})
		}
	})
	return
}

const RESEND_USER_INVITATION_REQUESTED = 'RESEND_USER_INVITATION_REQUESTED'
const RESEND_USER_INVITATION_SUCCESS = 'RESEND_USER_INVITATION_SUCCESS'
const RESEND_USER_INVITATION_FAILED = 'RESEND_USER_INVITATION_FAILED'

export const resendInvitationEmail = (invitationId) => (dispatch) => {
	dispatch({ type: RESEND_USER_INVITATION_REQUESTED, data: {} })

	let user = cookies.get('user')

	return invitations.resendInvitation(invitationId).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.userProfile.teamMembeResendInvitateSuccess', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.userProfile.teamMembeResendInvitateFailed', isSuccess: false }
			})
		}
	})
	return
}

const UPDATE_USER_INVITATION_MEMBER_REQUESTED = 'SET_USER_INVITATION_MEMBER_REQUESTED'
const UPDATE_USER_INVITATION_MEMBER_SUCCESS = 'SET_USER_INVITATION_MEMBER_SUCCESS'
const UPDATE_USER_INVITATION_MEMBER_FAILED = 'SET_USER_INVITATION_MEMBER_FAILED'

export const updateInviteUserMember = (optionSelected) => (dispatch) => {
	dispatch({ type: UPDATE_USER_INVITATION_MEMBER_REQUESTED, data: {} })

	let user = cookies.get('user')

	return invitations.updateInviteUserMember(user.id, user.email, optionSelected).then((response) => {
		if (response) {
			dispatch({ type: UPDATE_USER_INVITATION_MEMBER_SUCCESS, data: {} })

			return dispatch(hideModal())
		} else {
			return dispatch({ type: UPDATE_USER_INVITATION_MEMBER_FAILED, data: {} })
		}
	})
	return
}

const UPDATE_CIRCLE_INVITATION_MEMBER_REQUESTED = 'UPDATE_CIRCLE_INVITATION_MEMBER_REQUESTED'
const UPDATE_CIRCLE_INVITATION_MEMBER_SUCCESS = 'UPDATE_CIRCLE_INVITATION_MEMBER_SUCCESS'
const UPDATE_CIRCLE_INVITATION_MEMBER_FAILED = 'UPDATE_CIRCLE_INVITATION_MEMBER_FAILED'

export const updateCircleInviteUserMember = (optionSelected) => (dispatch) => {
	dispatch({ type: UPDATE_CIRCLE_INVITATION_MEMBER_REQUESTED, data: {} })
	let user = cookies.get('user')

	return circleInvitation.updateCircleInviteUserMember(user.id, user.email, optionSelected).then((response) => {
		if (response) {
			dispatch({ type: UPDATE_CIRCLE_INVITATION_MEMBER_SUCCESS, data: {} })

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'Successfully', isSuccess: true }
			})
		} else {
			dispatch({ type: UPDATE_CIRCLE_INVITATION_MEMBER_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'Failed', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_USER_INVITATIONS_REQUESTED = 'FETCH_USER_INVITATIONS_REQUESTED'
const FETCH_USER_INVITATIONS_SUCCESS = 'FETCH_USER_INVITATIONS_SUCCESS'
const FETCH_USER_INVITATIONS_FAILED = 'FETCH_USER_INVITATIONS_FAILED'

export const getTeamMembersById = (id) => (dispatch) => {
	dispatch({ type: FETCH_USER_INVITATIONS_REQUESTED, data: {} })

	return users.fetchTeamMembers(id).then((userInvitations) => {
		if (userInvitations) {
			return dispatch({ type: FETCH_USER_INVITATIONS_SUCCESS, data: userInvitations.members })
		} else {
			return dispatch({ type: FETCH_USER_INVITATIONS_FAILED, data: {} })
		}
	})
	return
}

const REMOVE_USER_INVITATION_MEMBER_REQUESTED = 'REMOVE_USER_INVITATION_MEMBER_REQUESTED'
const REMOVE_USER_INVITATION_MEMBER_SUCCESS = 'REMOVE_USER_INVITATION_MEMBER_SUCCESS'
const REMOVE_USER_INVITATION_MEMBER_FAILED = 'REMOVE_USER_INVITATION_MEMBER_FAILED'

export const deleteUserMember = (id, invitationId, currentUserId, enableSchedule, removalDate) => (dispatch) => {
	dispatch({ type: REMOVE_USER_INVITATION_MEMBER_REQUESTED, data: {} })
	return invitations.deleteUserMember(id, invitationId, enableSchedule, removalDate).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(hideModal())
			dispatch(getTeamMembersById(currentUserId))

			dispatch({
				type: REMOVE_USER_INVITATION_MEMBER_SUCCESS,
				data: response
			})
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: enableSchedule
						? 'app.userProfile.teamMemberScheduleRemovalSuccess'
						: 'app.userProfile.teamMemberRemoveInviteSuccess',
					isSuccess: true
				}
			})
		} else {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.userProfile.teamMemberRemoveInviteFailed',
					isSuccess: false
				}
			})
			return dispatch({
				type: REMOVE_USER_INVITATION_MEMBER_FAILED,
				data: {}
			})
		}
	})
	return
}

const UPLOAD_USER_IMAGE_REQUESTED = 'UPLOAD_USER_IMAGE_REQUESTED'
const UPLOAD_USER_IMAGE_SUCCESS = 'UPLOAD_USER_IMAGE_SUCCESS'
const UPLOAD_USER_IMAGE_FAILED = 'UPLOAD_USER_IMAGE_FAILED'

export const setPhoto = (entity, id, photo, onImageUpdated) => (dispatch) => {
	dispatch({ type: UPLOAD_USER_IMAGE_REQUESTED, data: {} })
	let formData = new FormData()
	formData.append('files', photo)
	return users.setUserImage(entity, id, formData).then((response) => {
		if (response) {
			if (onImageUpdated) dispatch(onImageUpdated(id))
			return dispatch({ type: UPLOAD_USER_IMAGE_SUCCESS, data: response })
		} else {
			return dispatch({ type: UPLOAD_USER_IMAGE_FAILED, data: {} })
		}
	})
	return
}

const RESET_USER_IMAGE_REQUESTED = 'RESET_USER_IMAGE_REQUESTED'
const RESET_USER_IMAGE_SUCCESS = 'RESET_USER_IMAGE_SUCCESS'
const RESET_USER_IMAGE_FAILED = 'RESET_USER_IMAGE_FAILED'

export const resetPhoto = (entity, id, onImageUpdated) => (dispatch) => {
	dispatch({ type: RESET_USER_IMAGE_REQUESTED, data: {} })
	return users.resetUserImage(entity, id).then((response) => {
		if (response) {
			if (onImageUpdated) dispatch(onImageUpdated(id))
			return dispatch({ type: RESET_USER_IMAGE_SUCCESS, data: response })
		} else {
			return dispatch({ type: RESET_USER_IMAGE_FAILED, data: {} })
		}
	})
	return
}

const UPLOAD_TEAM_LOGO_REQUESTED = 'UPLOAD_TEAM_LOGO_REQUESTED'
const UPLOAD_TEAM_LOGO_SUCCESS = 'UPLOAD_TEAM_LOGO_SUCCESS'
const UPLOAD_TEAM_LOGO_FAILED = 'UPLOAD_TEAM_LOGO_FAILED'

export const setTeamLogo = (id, photo, onImageUpdated) => (dispatch) => {
	dispatch({ type: UPLOAD_TEAM_LOGO_REQUESTED, data: {} })
	let formData = new FormData()
	formData.append('files', photo)
	return users.setTeamLogo(id, formData).then((response) => {
		if (response) {
			if (onImageUpdated) dispatch(onImageUpdated(id))
			return dispatch({ type: UPLOAD_TEAM_LOGO_SUCCESS, data: response })
		} else {
			return dispatch({ type: UPLOAD_TEAM_LOGO_FAILED, data: {} })
		}
	})
	return
}

const FETCH_USER_AUTOCOMPLETE_REQUESTED = 'FETCH_USER_AUTOCOMPLETE_REQUESTED'
const FETCH_USER_AUTOCOMPLETE_SUCCESS = 'FETCH_USER_AUTOCOMPLETE_SUCCESS'
const FETCH_USER_AUTOCOMPLETE_FAILED = 'FETCH_USER_AUTOCOMPLETE_FAILED'

export const fetchUserAutoComplete = (searchPhrase, subscribersOnly, activeSubscriptionOnly) => (dispatch) => {
	dispatch({ type: FETCH_USER_AUTOCOMPLETE_REQUESTED, data: {} })

	users.fetchUserAutoComplete(searchPhrase, subscribersOnly, activeSubscriptionOnly).then((users) => {
		if (users) {
			return dispatch({ type: FETCH_USER_AUTOCOMPLETE_SUCCESS, data: users.users })
		} else {
			return dispatch({ type: FETCH_USER_AUTOCOMPLETE_FAILED, data: {} })
		}
	})
	return
}

const FETCH_ADMINISTRATOR_AUTOCOMPLETE_REQUESTED = 'FETCH_ADMINISTRATOR_AUTOCOMPLETE_REQUESTED'
const FETCH_ADMINISTRATOR_AUTOCOMPLETE_SUCCESS = 'FETCH_ADMINISTRATOR_AUTOCOMPLETE_SUCCESS'
const FETCH_ADMINISTRATOR_AUTOCOMPLETE_FAILED = 'FETCH_ADMINISTRATOR_AUTOCOMPLETE_FAILED'

export const fetchAdminAutoComplete = (searchPhrase, subscribersOnly) => (dispatch) => {
	dispatch({ type: FETCH_ADMINISTRATOR_AUTOCOMPLETE_REQUESTED, data: {} })
	users.fetchUserAutoComplete(searchPhrase, subscribersOnly).then((users) => {
		if (users) {
			return dispatch({ type: FETCH_ADMINISTRATOR_AUTOCOMPLETE_SUCCESS, data: users.users })
		} else {
			return dispatch({ type: FETCH_ADMINISTRATOR_AUTOCOMPLETE_FAILED, data: {} })
		}
	})
	return
}

const SET_USER_CIRCLES_FILTER_REQUESTED = 'SET_USER_CIRCLES_FILTER_REQUESTED'
const SET_USER_CIRCLES_FILTER_SUCCESS = 'SET_USER_CIRCLES_FILTER_SUCCESS'
const SET_USER_CIRCLES_FILTER_FAILED = 'SET_USER_CIRCLES_FILTER_FAILED'

export const setUserCircleFilter = (filter) => (dispatch) => {
	dispatch({ type: SET_USER_CIRCLES_FILTER_REQUESTED, data: {} })

	return dispatch({ type: SET_USER_CIRCLES_FILTER_SUCCESS, data: filter })
}

const FETCH_USER_CIRCLES_REQUESTED = 'FETCH_USER_CIRCLES_REQUESTED'
const FETCH_USER_CIRCLES_SUCCESS = 'FETCH_USER_CIRCLES_SUCCESS'
const FETCH_USER_CIRCLES_FAILED = 'FETCH_USER_CIRCLES_FAILED'

export const getUserCirclesById = (id) => (dispatch) => {
	dispatch({ type: FETCH_USER_CIRCLES_REQUESTED, data: {} })
	users.fetchUserCirclesById(id).then((data) => {
		if (data && data.isSuccessful) {
			return dispatch({ type: FETCH_USER_CIRCLES_SUCCESS, data: data.circles })
		} else {
			return dispatch({ type: FETCH_USER_CIRCLES_FAILED, data: {} })
		}
	})
	return
}

const SEARCH_USER_RELATED_SERVICE_PROVIDERS_REQUESTED = 'SEARCH_USER_RELATED_SERVICE_PROVIDERS_REQUESTED'
const SEARCH_USER_RELATED_SERVICE_PROVIDERS_SUCCESS = 'SEARCH_USER_RELATED_SERVICE_PROVIDERS_SUCCESS'
const SEARCH_USER_RELATED_SERVICE_PROVIDERS_FAILED = 'SEARCH_USER_RELATED_SERVICE_PROVIDERS_FAILED'

export const searchUserRelatedServiceProviders = (userId, userRelatedServiceProviderFilter) => (dispatch) => {
	if (
		!userId
		|| !userRelatedServiceProviderFilter
	) {
		return Promise.resolve();
	}

	dispatch({ type: SEARCH_USER_RELATED_SERVICE_PROVIDERS_REQUESTED, data: {} })

	users.searchUserRelatedServiceProviders(userId, userRelatedServiceProviderFilter).then((data) => {
		if (data && data.isSuccessful) {
			return dispatch({ type: SEARCH_USER_RELATED_SERVICE_PROVIDERS_SUCCESS, data: data.serviceProviders })
		} else {
			return dispatch({ type: SEARCH_USER_RELATED_SERVICE_PROVIDERS_FAILED, data: {} })
		}
	})
	return
}

const initial = {
	userDetails: null,
	fullUser: null,
	notificationSettings: null,
	invitation: null,
	userInvitations: [],
	autoCompleteSuggestions: [],
	adminsAutoCompleteSuggestions: [],
	circleFilter: 0,
	circles: [],
	relatedServiceProviders: []
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_USER_AUTOCOMPLETE_SUCCESS:
			return { ...state, autoCompleteSuggestions: action.data }
		case FETCH_ADMINISTRATOR_AUTOCOMPLETE_SUCCESS:
			return { ...state, adminsAutoCompleteSuggestions: action.data }
		case REMOVE_USER_INVITATION_MEMBER_SUCCESS:
			const id = action.data.invitationId
			return { ...state, userInvitations: state.userInvitations.filter((item) => item.invitationId !== id) }
		case SET_USER_INVITATION_MEMBER_SUCCESS:
			return {
				...state,
				userInvitations: [
					...state.userInvitations.slice(0, action.data),
					action.data,
					...state.userInvitations.slice(action.pos)
				]
			}
		case UPLOAD_USER_IMAGE_SUCCESS:
			return { ...state, userDetails: action.data }
		case FETCH_USER_INVITATIONS_SUCCESS:
			return { ...state, userInvitations: action.data }
		case FETCH_USER_NOTIFICATION_SETTINGS_SUCCESS:
			return { ...state, notificationSettings: action.data }
		case FETCH_USER_SUCCESS:
			return { ...state, userDetails: action.data }
		case CLEAR_USER_REQUESTED:
			return { ...state, userDetails: {} }
		case FETCH_FULL_USER_SUCCESS:
			return { ...state, fullUser: action.data }
		case UPDATE_USER_DETAILS_SUCCESS:
			return { ...state, userDetails: action.data }
		case SET_USER_CIRCLES_FILTER_SUCCESS:
			return { ...state, circleFilter: action.data }
		case FETCH_USER_CIRCLES_REQUESTED:
			return { ...state, circles: [] }
		case FETCH_USER_CIRCLES_SUCCESS:
			return { ...state, circles: action.data }

		case SEARCH_USER_RELATED_SERVICE_PROVIDERS_REQUESTED:
			return {
				...state,
				relatedServiceProviders: []
			}
		case SEARCH_USER_RELATED_SERVICE_PROVIDERS_SUCCESS:
			return {
				...state,
				relatedServiceProviders: action.data
			}

		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.user

export const userDetailsSelector = createSelector(mainSelector, (user) => user.userDetails)
export const userNotificationSettingsSelector = createSelector(mainSelector, (user) => user.notificationSettings)
export const userInviteMemberSelector = createSelector(mainSelector, (user) => user.invitation)
export const userInvitationsSelector = createSelector(mainSelector, (user) => {
	return user.userInvitations && user.userInvitations.length > 0
		? user.userInvitations.sort((a, b) => {
				return a.isOwner > b.isOwner ? -1 : 1
			})
		: []
})
export const canAddTeamMemberButtonSelector = createSelector(mainSelector, (state) => {
	let user = getUser()
	if (user && state.userDetails) {
		if (state.userDetails.role && state.userDetails.role.id === user.role.id) {
			return true
		}
	}
	return false
})
export const fullUserSelector = createSelector(mainSelector, (state) => (state ? state.fullUser : null))

export const statsSelector = createSelector(mainSelector, (state) => {
	if (state && state.fullUser && state.fullUser.statsModel) {
		return state.fullUser.statsModel
	}
	return false
})

export const userAutoCompleteSuggestionSelector = createSelector(mainSelector, (state) => {
	if (state && state.autoCompleteSuggestions) {
		return state.autoCompleteSuggestions
	}
	return []
})

export const adminAutoCompleteSuggestionSelector = createSelector(mainSelector, (state) => {
	if (state && state.adminsAutoCompleteSuggestions) {
		return state.adminsAutoCompleteSuggestions
	}
	return []
})

export const userCircleFilterSelector = createSelector(mainSelector, (state) => {
	return state.circleFilter
})

export const userCirclesSelector = createSelector(mainSelector, (state) => {
	if(state && !isNaN(state.circleFilter)) {
		switch (state.circleFilter) {
			case 0:
				return state.circles.filter(x => !x.removalDate)
			case 1:
				return state.circles.filter(x => !x.removalDate && (x.roleId === 3 || x.roleId === 7))
			case 2:
				return state.circles.filter(x => !x.removalDate && x.isArchived)
			case 3:
				return state.circles.filter(x => x.removalDate)
			default:
				break;
		}
	}
	return []
})

export const userRelatedServiceProvidersSelector = createSelector(mainSelector, (state) => {
	return state.relatedServiceProviders
})
