import React, { Fragment, useState } from "react"
import { copySurveyInstaceQuestionRules, hideModal } from "../../../redux/actions"
import { compose } from "recompose"
import { connect } from "react-redux"
import { injectIntl } from "react-intl"
import { useTheme, Grid, Typography, Button, RadioGroup, Radio, FormControlLabel } from "@material-ui/core"
import { postTreatmentSurveySelector, preTreatmentSurveySelector, treatmentSurveySelector } from "../../../redux/selectors"


const CopySurveyLayoutModal = ({
    surveyId,
    instanceId,
    versionId,
    onCopySurveyQuestionRules,
    style,
    closeDialog,
    intl,
    preTreatmentSurveys,
    treatmentSurveys,
    postTreatmentSurveys
}) => {
    const theme = useTheme()

    const [copyToInstanceId, setCopyToInstanceId] = useState(0)

    const surveyInstanceFilter = (surveyInstance) => {
        return surveyInstance.survey.id == surveyId && surveyInstance.surveyInstanceId != instanceId
    }

    const surveyInstanceMapper = (surveyInstance) => {
        return (
            <FormControlLabel
                label={surveyInstance.survey.name}
                control={
                    <Radio color="secondary"
                        checked={copyToInstanceId == surveyInstance.surveyInstanceId}
                        onChange={
                            (e) => {
                                if (e.target.checked) {
                                    setCopyToInstanceId(surveyInstance.surveyInstanceId)
                                }
                            }
                        }
                    />
                }
            />
        )
    }

    const filteredPreTreatmentSurveys = !!preTreatmentSurveys
        ? preTreatmentSurveys.filter(surveyInstanceFilter)
        : []
    const filteredTreatmentSurveys = !!treatmentSurveys
        ? treatmentSurveys.filter(surveyInstanceFilter)
        : []
    const filteredPostTreatmentSurveys = !!postTreatmentSurveys
        ? postTreatmentSurveys.filter(surveyInstanceFilter)
        : []

    const hasAnySurvey = filteredPreTreatmentSurveys.length > 0
        || filteredTreatmentSurveys.length > 0
        || filteredPostTreatmentSurveys.length > 0;
   
    const handleConfirm = () => {
        if (onCopySurveyQuestionRules) {
            onCopySurveyQuestionRules(instanceId, copyToInstanceId, versionId)
        }
        closeDialog()
    }

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{
                padding: '4rem',
                backgroundColor: theme.palette.secondary.main,
                width: '40vw',
                ...style
            }}
        >
            <Grid
                item
                style={{
                    margin: '20px 0'
                }}
            >
                <Typography
                    variant="h2"
                    style={{
                    	lineHeight: '16px',
                    	color: 'white',
                    	fontWeight: '400'
                    }}
                >
                    {intl.formatMessage({ id: 'app.protocol.copySurvey.modalTitle' })}
                </Typography>
            </Grid>
            <Grid
                item
                style={{
                    width: '100%',
                    padding: '20px 0'
                }}
            >
                {
                    hasAnySurvey
                        ? (
                            <Typography
                                style={{
                                    fontSize: '20px',
                                    lineHeight: '16px',
                                    color: 'white',
                                    fontWeight: '400',
                                    paddingTop: '20px'
                                }}
                            >
                                {intl.formatMessage({ id: 'app.protocol.copySurvey.selectSurveyText' })}
                            </Typography>
                        ) : (
                            <Typography
                                style={{
                                    fontSize: '20px',
                                    lineHeight: '16px',
                                    color: 'white',
                                    fontWeight: '400',
                                    padding: '20px 0'
                                }}
                            >
                                {intl.formatMessage({ id: 'app.protocol.copySurvey.noSurveysAvailableText' })}
                            </Typography>
                        )
                }
            </Grid>
            {
                hasAnySurvey
                    ? (
                        <Fragment>
                            <Grid
                                item
                                style={{
                                    width: '100%',
                                    paddingBottom: '20px'
                                }}
                            >
                                <div
                                    style={{
                                        maxHeight: '40vh',
                                        overflow: 'auto'
                                    }}
                                >
                                <RadioGroup>
                                    {
                                        filteredPreTreatmentSurveys.length > 0
                                        && <Fragment>
                                            <Typography
                                                style={{
                                                    fontSize: '16px',
                                                    lineHeight: '16px',
                                                    color: 'lightgray',
                                                    fontWeight: '400',
                                                    paddingTop: '20px'
                                                }}
                                            >
                                                {intl.formatMessage({ id: 'app.surveyNavigator.pretreatmentLabel' })}
                                            </Typography>
                                            {
                                                filteredPreTreatmentSurveys.map(surveyInstanceMapper)
                                            }
                                        </Fragment>
                                    }
                                    {
                                        filteredTreatmentSurveys.length > 0
                                        && <Fragment>
                                            <Typography
                                                style={{
                                                    fontSize: '16px',
                                                    lineHeight: '16px',
                                                    color: 'lightgray',
                                                    fontWeight: '400',
                                                    paddingTop: '20px'
                                                }}
                                            >
                                                {intl.formatMessage({ id: 'app.surveyNavigator.treatmentLabel' })}
                                            </Typography>
                                            {
                                                filteredTreatmentSurveys.map(surveyInstanceMapper)
                                            }
                                        </Fragment>
                                    }
                                    {
                                        filteredPostTreatmentSurveys.length > 0
                                        && <Fragment>
                                            <Typography
                                                style={{
                                                    fontSize: '16px',
                                                    lineHeight: '16px',
                                                    color: 'lightgray',
                                                    fontWeight: '400',
                                                    paddingTop: '20px'
                                                }}
                                            >
                                                {intl.formatMessage({ id: 'app.surveyNavigator.posttreatmentLabel' })}
                                            </Typography>
                                            {
                                                filteredPostTreatmentSurveys.map(surveyInstanceMapper)
                                            }
                                        </Fragment>
                                    }
                                </RadioGroup>
                                </div>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}
                                style={{
                                    paddingTop: '20px'
                                }}
                            >
                                <Grid item >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={!copyToInstanceId}
                                        onClick={handleConfirm}
                                        style={{
                                            width: '100px'
                                        }}
                                    >
                                        {intl.formatMessage({ id: 'app.protocol.copySurvey.yesButton' })}
                                    </Button>
                                </Grid>
                                <Grid item >
                                    <Button
                                        variant="contained"
                                        onClick={closeDialog}
                                        style={{
                                            width: '100px',
                                            color: "black"
                                        }}
                                    >
                                        {intl.formatMessage({ id: 'app.protocol.copySurvey.noButton' })}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Fragment>
                    ) : (
                        <Grid item >
                            <Button
                                variant="contained"
                                onClick={closeDialog}
                                style={{
                                    width: '100px',
                                    color: "black"
                                }}
                            >
                                {intl.formatMessage({ id: 'app.protocol.copySurvey.okButton' })}
                            </Button>
                        </Grid>
                    )
            }       
        </Grid>
    )
}

const mapStateToProps = (state) => ({
	preTreatmentSurveys: preTreatmentSurveySelector(state),
	treatmentSurveys: treatmentSurveySelector(state),
	postTreatmentSurveys: postTreatmentSurveySelector(state)
})

const mapDispatchToProps = (dispatch) => ({
    onCopySurveyQuestionRules: (instanceId, copyToInstanceId, versionId) => dispatch(
        copySurveyInstaceQuestionRules(
            instanceId,
            copyToInstanceId,
            versionId
        )
    ),
	closeDialog: () => dispatch(hideModal())
})

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)

export default enhance(CopySurveyLayoutModal)
