import React, { useEffect } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

const ShowBaseProtocolMenuItem = ({ intl, onClickHandler, onComplete, protocol, loadCase, show }) => {
	useEffect(() => {}, [])

	const onClick = () => {
		//loadCase(caseModel.id)
		show()
	}

	return (
		<MenuItem
			onClick={() => {
				onClick()
				onComplete()
			}}
		>
			{intl.formatMessage({ id: 'app.protocols.showBaseMenuItem' })}
		</MenuItem>
	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(ShowBaseProtocolMenuItem)
