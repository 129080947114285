import { authenticatedFetch } from './base'

export const fetchTemplate = (versionId, surveyInstanceId, circleId, languageId, type) => {
	var url = `/api/emailtemplates/version/${versionId}/survey/${surveyInstanceId}/circle/${circleId}/languages/${languageId}/types/${type}`

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(template) {
			return template
		})
}

export const fetchTypes = () => {
	var url = `/api/emailtemplates/types`

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(template) {
			return template
		})
}

export const updateTemplate = (versionId, surveyInstanceId, circleId, languageId, type, htmlPart, subjectPart, commentsObj) => {
	var url = `/api/emailtemplates/version/${versionId}/survey/${surveyInstanceId}/circle/${circleId}/languages/${languageId}/types/${type}`

	return authenticatedFetch(url, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			htmlPart: htmlPart,
			subjectPart: subjectPart,
			comments: { 
				commentText: commentsObj?.commentText,
				commentProtocolVersionId: commentsObj?.protocolVersionId,
				commentRelatedTaskUrl: commentsObj?.relatedTaskUrl,
				commentInvestigatorId: commentsObj?.investigatorId
			}
		})
	})
		.then(function (response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (template) {
			return template
		})
}

export const sendTemplateEmail = (versionId, surveyInstanceId, circleId, languageId, type, email) => {
	return authenticatedFetch(
		`/api/emailtemplates/version/${versionId}/survey/${surveyInstanceId}/circle/${circleId}/languages/${languageId}/types/${type}/sendEmail`, 
		{
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			email: email
		})
	})
		.then(function (response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function (result) {
			return result
		})
}