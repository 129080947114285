import React from "react"
import { TableCell, useTheme } from "@material-ui/core"


const EntityAuditTableCell = ({...other}) => {
    const theme = useTheme()

    return (
        <TableCell
            {...other}
        />
    )
}

export default EntityAuditTableCell