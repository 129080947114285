import React, { Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import QuestionContainer from './QuestionContainer'
import TextQuestion from './TextQuestion'
import FileQuestion from './FileQuestion'
import TFQuestion from './TFQuestion'
import MultiSelectQuestion from './MultiSelectQuestion'
import TextBirthDateQuestion from './TextBirthDateQuestion'
import ReadonlyTextBlock from './ReadonlyTextBlock'
import RadioSelectQuestion from './RadioSelectQuestion'
import NumberQuestion from './NumberQuestion'
import DateQuestion from './DateQuestion'
import GroupedQuestion from './GroupedQuestion'
import AnalogQuestion from '../survey/components/AnalogQuestion'

const renderQuestions = (collection, list) => {
	if (list) {
		var rendered = new Array()
		var counter = 0
		var displayCount = 0
		collection.map((node, i) => {
			counter++
			if (node.data.questionType !== 8) {
				displayCount++
			}
			switch (node.data.questionType) {
				case 6:
					rendered.push(
						<NumberQuestion question={node.data} positionDisplay={displayCount} key={node.data.id} />
					)
					break
				case 5:
					rendered.push(
						<TextQuestion question={node.data} key={node.data.id} positionDisplay={displayCount} />
					)
					break
				// case 4:
				// 	rendered.push(<TFQuestion question={node.data} key={node.data.id} positionDisplay={displayCount} />)
				// 	break
				case 7:
					rendered.push(
						<DateQuestion question={node.data} key={node.data.id} positionDisplay={displayCount} />
					)
					break
				case 2:
					rendered.push(
						<MultiSelectQuestion question={node.data} key={node.data.id} positionDisplay={displayCount} />
					)
					break
				case 8:
					rendered.push(
						<ReadonlyTextBlock question={node.data} key={node.data.id} positionDisplay={displayCount} />
					)
					break
				case 1:
					rendered.push(
						<RadioSelectQuestion question={node.data} key={node.data.id} positionDisplay={displayCount} />
					)
					break
				case 7:
					rendered.push(
						<TextBirthDateQuestion
							question={node.data}
							key={node.data.id}
							position={counter}
							positionDisplay={displayCount}
						/>
					)
					break
				case 3:
					rendered.push(
						<GroupedQuestion
							question={node.data}
							key={node.data.id}
							position={counter}
							positionDisplay={displayCount}
						/>
					)
					break
				case 9:
					rendered.push(
						<AnalogQuestion
							question={node.data}
							key={node.data.id}
							position={counter}
							positionDisplay={displayCount}
						/>
					)
					break
				case 10: case 11:
					rendered.push(
						<FileQuestion question={node.data} key={node.data.id} positionDisplay={displayCount} disabled={true} />
					)
					break
			}
		})
		return rendered
	}
	return new Array(0)
}

const BuilderContainer = ({
	headerTitle,

	list,
	collection
}) => {
	return (
		<Fragment>
			<Grid container direction='column' className="white-background">
				<Grid item>
					<Grid container justify='space-between' alignItems='center'>
						<Grid item>
							<Typography variant="h6">
								{headerTitle}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					{collection && collection.length > 0 ? (
						<div>{renderQuestions(collection, list)}</div>
					) : (
						<QuestionContainer list={list} />
					)}
				</Grid>
			</Grid>
		</Fragment>
	)
}

export default BuilderContainer
