import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import DefaultFormTextField from '../../DefaultFormTextField'
import { required } from '../../../utilities/validators'
import FormTextField from '../../FormTextField'
const requiredValidator = required

export default forwardRef((props, ref) => {
	const { onDeleteCallback, answer, dark, disabled } = props
	const [ answerText, setAnswerText ] = useState(null)
	const [ id, setId ] = useState('')
	const [ tagId, setTagId ] = useState('')

	useImperativeHandle(ref, () => ({
		getAnswer() {
			return { question: answerText }
		}
	}))
	useEffect(
		() => {
			if (answer) {
				setId(answer.data.answerId)
				setTagId(answer.data.tagId)
				if (answer.data.answer) {
					setAnswerText(answer.data.answer.question)
				}
			}
			answer.ref = ref
		},
		[ answer, ref ]
	)

	const AdaptiveFormTextField = dark ? FormTextField : DefaultFormTextField

	return (
		<Grid container style={{ width: '100%', display: 'block' }}>
			{!answer.data.baseId && <Grid item style={{ marginLeft: '-40px' }}>
				<IconButton onClick={() => onDeleteCallback(answer)} disabled={disabled}>
					<DeleteIcon />
				</IconButton>
			</Grid> }
			<Grid item>
				<Grid container direction="column">
					<Grid item style={{ flex: 1, paddingRight: '5px' }}>
						<AdaptiveFormTextField
							label="Tag"
							validators={[ requiredValidator ]}
							name={tagId}
							variant="filled"
							disabled={disabled}
						/>
					</Grid>
					<Grid item style={{ flex: 1, paddingRight: '5px' }}>
						<AdaptiveFormTextField
							label="Category"
							validators={[ requiredValidator ]}
							name={id}
							variant="filled"
							disabled={disabled}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
})
