import { Divider, List, ListItem, ListItemText, makeStyles, Typography } from '@material-ui/core'
import React, { Fragment, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import { fetchBundleRelatedSurveys, fetchQuestionRelatedSurveys } from '../../../redux/actions'
import { relatedSurveysSelector } from '../../../redux/selectors'


const useStyles = makeStyles({
	link: {
		marginBottom: "5px",
		fontSize: "16px"
	},
	icon: {
		display: "inline-block", marginBottom:"-4px", marginLeft: "10px", marginRight: "5px"
	}
})

const RelatedSurveys = ({
    questionId,
    questionType,
    bundleId,
    style,
    surveys,
    loadByQuestion,
    loadByBundle,
    intl
}) => {
	const classes = useStyles()

    useEffect(
        () => {
            if (!!questionId) {
                loadByQuestion(questionId, questionType)
            }
            else if (!!bundleId) {
                loadByBundle(bundleId)
            }
        },
        []
    )

    return (
        <div
            style={{
                ...style
            }}
        >
            <Typography variant="h4"
            >
                { intl.formatMessage({ id: "app.relatedEntities.usedBySurveys" }).replace("%%USED_COUNT%%", surveys?.length) }
            </Typography>

            <List>
                {
                    !!surveys
                    && surveys.map(
                        (survey) => {
                            return (
                                <Fragment>
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                <Link to={`/surveys/${survey.id}/details`}>
                                                    <Typography variant="body2" className={classes.link}>
                                                        {survey.title}
                                                    </Typography>
                                                </Link>
                                            }
                                        />
                                    </ListItem>
                                    <Divider component="li" />
                                </Fragment>
                            )
                        }
                    )
                }
            </List>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    surveys: relatedSurveysSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    loadByQuestion: (questionId, questionType) => dispatch(fetchQuestionRelatedSurveys(questionId, questionType)),
    loadByBundle: (bundleId) => dispatch(fetchBundleRelatedSurveys(bundleId)),
})

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)

export default enhance(RelatedSurveys)