import React from "react"
import QuestionPage from "./components/QuestionPage"
import { Suspense } from "react"


export default ({}) => {
    return (
        <div>
            <Suspense fallback={<div />}>
                <section>
                    <QuestionPage />
                </section>
            </Suspense>
        </div>
    )
}