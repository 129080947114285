import React from "react";
import { Table, TableBody } from "@material-ui/core";
import RelatedServiceProviderTableHeader from "./RelatedServiceProviderTableHeader";
import RelatedServiceProviderTableEmptyRow from "./RelatedServiceProviderTableEmptyRow";
import RelatedServiceProviderTableRow from "./RelatedServiceProviderTableRow";
import { userRelatedServiceProvidersSelector } from "../../../redux/selectors";
import { compose } from "recompose";
import { connect } from "react-redux";


const RelatedServiceProvidersTable = ({
    serviceProviders
}) => {
    return (
        <Table>
            <RelatedServiceProviderTableHeader />
            <TableBody>
                {
                    !!serviceProviders?.length
                    && serviceProviders.map((serviceProvider, index) => {
                        return (
                            <RelatedServiceProviderTableRow
                                key={`service-provider-related-serviceProvider-${index}`}
                                serviceProvider={serviceProvider}
                            />
                        )
                    })
                }
                {
                    !serviceProviders?.length
                    && <RelatedServiceProviderTableEmptyRow />
                }
            </TableBody>
        </Table>
    )
}

const mapStateToProps = (state, ownProps) => ({
    serviceProviders: userRelatedServiceProvidersSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(RelatedServiceProvidersTable);
