import React, { useState } from 'react'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { lighten, makeStyles } from '@material-ui/core/styles'
import { sortQuestionsDirectionSelector, sortQuestionsFieldSelector } from '../../../redux/selectors'
import { setQuestionSort } from '../../../redux/actions'
import { connect } from 'react-redux'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(3)
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2)
	},
	table: {
		minWidth: 750
	},
	tableWrapper: {
		overflowX: 'auto'
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1
	}
}))

const ProtocolHeader = ({ intl, isDescending, changeSort, field }) => {
	return (
		<TableRow>
			<TableCell size="small" align="left" style={{ width: '4px', padding: 0, border: 'none' }} />
			<TableCell
				size="small"
				align="left"
				style={{ width: '10px', padding: '6px 0px 6px 25px', border: 'none' }}
			/>
			<TableCell align="left" style={{ border: 'none' }}>
				<TableSortLabel
					active={field === 'title'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('title', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="subtitle1"
						style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase'
						}}
					>
						{intl.formatMessage({ id: 'app.questions.titleColumnLabel' })}
					</Typography>
				</TableSortLabel>
			</TableCell>
			<TableCell>
				<Typography
					variant="subtitle1"
					style={{
						fontWeight: 400,
						letterSpacing: '2.08px',
						fontSize: '13px',
						lineHeight: '16px',
						color: '#011020',
						textTransform: 'uppercase'
					}}
				>
					{intl.formatMessage({ id: 'app.questions.questionIdColumnLabel' })}
				</Typography>
			</TableCell>
			<TableCell align="left" style={{ border: 'none' }}>
				<TableSortLabel
					active={field === 'questionTypeId'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('questionTypeId', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="subtitle1"
						style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase'
						}}
					>
						Type
					</Typography>
				</TableSortLabel>
			</TableCell>
			<TableCell align="left" style={{ border: 'none' }}>
				<TableSortLabel
					active={field === 'surveyCount'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('surveyCount', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="subtitle1"
						style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase'
						}}
					>
						{intl.formatMessage({ id: 'app.questions.surveyCountColumnLabel' })}
					</Typography>
				</TableSortLabel>
			</TableCell>
			<TableCell align="left" style={{ border: 'none' }}>
				<TableSortLabel
					active={field === 'languageCount'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('languageCount', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="subtitle1"
						style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase'
						}}
					>
						{intl.formatMessage({ id: 'app.protocols.languages' })}
					</Typography>
				</TableSortLabel>
			</TableCell>
			<TableCell align="left" style={{ border: 'none', maxWidth: '120px', width: '120px' }}>
				<TableSortLabel
					sortDirection={false}
					active={field === 'modifiedDateSeconds'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('modifiedDateSeconds', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="subtitle1"
						style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase'
						}}
					>
						{intl.formatMessage({ id: 'app.questions.modifiedColumnLabel' })}
					</Typography>
				</TableSortLabel>
			</TableCell>

			<TableCell align="left" style={{width: '71px', textAlign: 'center', padding: '6px 0px 6px 0px', border: 'none' }} />
		</TableRow>
	)
}

const mapStateToProps = (state, ownProps) => ({
	isDescending: sortQuestionsDirectionSelector(state),
	field: sortQuestionsFieldSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	changeSort: (field, direction) => dispatch(setQuestionSort(field, direction))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(ProtocolHeader)
