import React from "react"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { compose } from "recompose"
import EntityAudit from "../../../components/entityAudit/EntityAudit"
import { questionChangeLogKeysSelector, questionChangeLogsSelector } from "../../../redux/selectors"
import { searchQuestionChangeLogs } from "../../../redux/actions"


const QuestionAudit = ({
    questionId,
    questionType,
    keys,
    logs,
    search
}) => {
    return (
        <EntityAudit
            keys={keys}
            logs={logs}
            onSearch={search}
        />
    )
}

const mapStateToProps = (state, ownProps) => ({
    keys: questionChangeLogKeysSelector(state),
	logs: questionChangeLogsSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    search: (searchPhrase, keys) => dispatch(searchQuestionChangeLogs(ownProps.questionId, ownProps.questionType, searchPhrase, keys))
})

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)

export default enhance(QuestionAudit)
