import { Grid, Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { injectIntl } from "react-intl"
import { connect, useDispatch } from "react-redux"
import { compose } from "recompose"
import { hideModal } from "../../redux/actions"
import { cloneQuestion } from "../../utilities/questions"
import EditQuestionContainer from '../questionEditor/QuestionContainer'


const QuestionContainer = ({
	node,
	list,
	children,
	onAddCallback,
	onAddExistingCallback,
	onAddBundleCallback,
	onSwapCallback,
	onRemoveCallback,
	onUpdateCallback,
	onCancelCloneCallback,
	position = 0,
	ignoreBundles = false,
	suppressNonDiscreteQuestions = false,
	showScoreEntry,
	searchType,
	isBundle,
	intl,
	moveToPosition,
	propertyStateName,
	onChangeBundleStateCallback,
	onChangeQuestionStateCallback,
	sameStateAllEntities,
	canSetInUseState,
	canSetInUseStateAllEntities,
	canSetNotInUseState,
	canSetNotInUseStateAllEntities,
	canSetArchiveState,
	canSetArchiveStateAllEntities,
}) => {
    const dispatch = useDispatch()

    const [question, setQuestion] = useState(null)


    useEffect(
        () => {
            if (!!node) {
                let copyOfQuestion = cloneQuestion(node)
                setQuestion(copyOfQuestion)
            }
        },
        [node]
    )


    const onEditCompleteCallback = () => {}

    const cancelCloneQuestion = () => {
        dispatch(hideModal())
    }

    
    return (
        <Grid
            container
            direction="column"
            className="admin-modal"
        >
            <Grid item style={{ width: '100%', textAlign: 'center' }}>
                <Typography
                    variant="h1"
                    style={{ color: 'white', paddingBottom: '32px' }}
                >
                    {intl.formatMessage({ id: 'app.question.cloneTitle' })}
                </Typography>
            </Grid>

            <Grid
                item
            >
                <EditQuestionContainer
                    question={question}
                    onAddCallback={onAddCallback}
                    onUpdateCallback={onEditCompleteCallback}
                    onCancelCloneCallback={cancelCloneQuestion}
                    showScoreEntry={showScoreEntry}
                    isBundle={isBundle}
                    isDialog={true}
                />
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(QuestionContainer)
