import { authenticatedFetch } from './base'

export const addTreatment = (internalName) => {
	return authenticatedFetch(`/api/treatment`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			internalName: internalName
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchTreatment = (id) => {
	var url = `/api/treatment/${id}`

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchTreatments = (searchPhrase) => {
	var url = `/api/treatment`

	if (searchPhrase && searchPhrase.length > 0) {
		url = `${url}?searchPhrase=${encodeURIComponent(searchPhrase)}`
	}
	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const updateTreatment = (id, name, description, baseTreatmentId, languageId, internalName, imageUrl) => {
	return authenticatedFetch(`/api/treatment`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			name: name,
			description: description,
			baseTreatmentId: baseTreatmentId,
			id: id,
			languageId: languageId,
			internalName: internalName,
			imageUrl: imageUrl
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const updateTreatmentInternalName = (id, internalName) => {
	return authenticatedFetch(`/api/treatment/${id}/internalname?internalName=${encodeURIComponent(internalName)}`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}