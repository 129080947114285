import React, { useEffect, useState } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { injectIntl } from 'react-intl'
import Lock from '@material-ui/icons/Lock'
import LockOpen from '@material-ui/icons/LockOpen'

const CircleType = ({ circle, intl }) => {
	const [ tooltip, setToolTip ] = useState('')

	useEffect(() => {
		if (circle && circle.phi) {
			setToolTip(intl.formatMessage({ id: 'app.circles.phi' }))
		} else {
			setToolTip(intl.formatMessage({ id: 'app.circles.phiFree' }))
		}
	}, [])

	return (
		<Tooltip
			title={tooltip}
			disableFocusListener
			disableTouchListener
			placement="right"
			style={{ fontSize: '14px' }}
		>
			<div>
				{circle && circle.phi && <Lock style={{ fontSize: '24px', color: '#444e5e' }} />}
				{circle && !circle.phi && <LockOpen style={{ fontSize: '24px', color: '#86ceb4' }} />}
			</div>
		</Tooltip>
	)
}

export default injectIntl(CircleType)
