import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { useRouteMatch } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { startSaving, updateTriggerTemplate, fetchPatientUpdatesTemplate, fetchPatientUpdatesTemplateTypes, showModal, hideModal } from '../../redux/actions'
import { patientUpdateTriggerTemplateSelector } from '../../redux/selectors'
import LanguageNavigator from '../../pages/treatment/components/LanguageNavigator'
import PatientUpdateTriggerTemplatesForm from './PatientUpdateTriggerTemplatesForm'
import PatientUpdateTriggerTemplateTypeFilter from './PatientUpdateTriggerTemplateTypeFilter'
import EntityAuditCommentModal from "../../components/entityAudit/EntityAuditCommentModal"
import { isAuditCommentsRequired } from '../../utilities/auditUtils'


const styles = () => ({
	button: {
		'&:hover': {
			backgroundColor: 'transparent'
		},
		paddingTop: '32px'
	},
	gradient: {
		background:
			'linear-gradient(45deg,  #d4e1f7 0%,#d4e1f7 50%,#ffffff 50%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */',
		filter:
			"progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4e1f7', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */"
	}
})

const PatientUpdateTriggerTemplates = ({ loadPatienUpdatesTemplateTypes, versionId, getTemplate, template, onSave }) => {
	const [ currentLanguage, setCurrentLanguage ] = useState(1)
	const [ currentType, setCurrentType ] = useState(1)

	const dispatch = useDispatch()

    useEffect(() => {
        loadPatienUpdatesTemplateTypes()
    }, [])

	useEffect(
		() => {
			getTemplate(versionId, currentLanguage, currentType)
		},
		[ currentLanguage, currentType, versionId ]
	)

	const onSaveCallback = (versionId, languageId, type, body) => {
		if (versionId === undefined || //don't show modal for admin settings
			!isAuditCommentsRequired())
		{
			dispatch(startSaving())
			onSave(versionId, languageId, type, body)
		}
		else {
			dispatch(
				showModal(
					EntityAuditCommentModal,
					{
						entityId: versionId,
						entityTypeId: 17, //protocol
						saveCallback: (commentsObj) => {
							dispatch(hideModal())
							dispatch(startSaving())
							onSave(versionId, languageId, type, body, commentsObj)
						}
					}
				)
			)	
		}	
	}

	return (
		<Grid item>
			<Grid container direction="row" spacing={2}>
				<Grid item style={{ flex: 0.3 }}>
				<Paper
					square={true}
					style={{
						minWidth: '200px',
						marginLeft: '32px',
						marginRight: '32px',
						transform: 'translateY(-50%)',
						backgroundColor: '#011020',
						display: 'table',
						minHeight: '48px',
						marginBottom: '-50px'
					}}
					>
						<PatientUpdateTriggerTemplateTypeFilter setFilter={setCurrentType} currentFilter={currentType} />
				</Paper>
					<LanguageNavigator currentLanguage={currentLanguage} onLanguageChange={setCurrentLanguage} />
				</Grid>
				<Grid item style={{ flex: 0.7, height: '100%' }}>
					<Paper style={{ padding: '3%' }}>
						<PatientUpdateTriggerTemplatesForm
							template={template}
							languageId={currentLanguage}
							type={currentType}
							onSaveCallback={onSaveCallback}
							versionId={versionId}
						/>
					</Paper>
				</Grid>
			</Grid>
		</Grid>
	)
}

const mapStateToProps = (state, ownProps) => ({
	template: patientUpdateTriggerTemplateSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    loadPatienUpdatesTemplateTypes: () => dispatch(fetchPatientUpdatesTemplateTypes()),
	getTemplate: (protocolVersionId, languageId, type) => dispatch(fetchPatientUpdatesTemplate(protocolVersionId, languageId, type)),
	onSave: (protocolVersionId, languageId, type, body, commentsObj) => dispatch(updateTriggerTemplate(protocolVersionId, languageId, type, body, commentsObj))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withStyles(styles))

export default enhance(PatientUpdateTriggerTemplates)
