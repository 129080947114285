import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { showModal } from '../../../redux/actions'
import AssignAdminUserModal from './AssignAdminUserModal'
import * as api from '../../../apis/circle'

export default ({ circle, onComplete }) => {
	const dispatch = useDispatch()
	const intl = useIntl()
	const onClick = () => {
		if (onComplete) {
			onComplete()
		}

		api.generateCircleLink(circle.id).then((response) => {
			if (response && response.isSuccessful) {
				
                navigator.permissions.query({ name: 'clipboard-write' }).then((result) => {
                    if (result.state == 'granted' || result.state == 'prompt') {
                        navigator.clipboard.writeText(response.link).then(
                            function () {
                                return dispatch({
                                    type: 'SET_SNACKBAR_MESSAGE',
                                    data: { message: 'app.circles.generateLinkCopiedToClipboard', isSuccess: true }
                                })
                            },
                            function () {
                                /* clipboard write failed */
                            }
                        )
                    } else {
                        return dispatch({
                            type: 'SET_SNACKBAR_MESSAGE',
                            data: { message: 'app.circles.generateLinkAccessToClipboardIsDenied', isSuccess: false }
                        })
                    }
                }).catch(() => {
                    let successful = false;

                    try
                    {
                        var textArea = document.createElement("textarea");
                        textArea.value = response.link;

                        textArea.style.top = "0";
                        textArea.style.left = "0";
                        textArea.style.position = "fixed";

                        document.body.appendChild(textArea);

                        textArea.focus();
                        textArea.select();

                        successful = document.execCommand('copy');

                        document.body.removeChild(textArea);
                    }
                    catch (err)
                    {
                        console.error('Unable to copy', err);
                    }

                    if (successful) {
                        return dispatch({
                            type: 'SET_SNACKBAR_MESSAGE',
                            data: { message: 'app.circles.generateLinkCopiedToClipboard', isSuccess: true }
                        })
                    }
                    else
                    {
                        return dispatch({
                            type: 'SET_SNACKBAR_MESSAGE',
                            data: { message: 'app.circles.generateLinkFailedToGenerateLink', isSuccess: false }
                        })
                    }
                })

            } else {
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.circles.generateLinkFailedToGenerateLink', isSuccess: false }
				})
			}
		})
	}

	return (
		<MenuItem
			onClick={() => {
				onClick()
			}}
		>
			{intl.formatMessage({ id: 'app.circles.generateLinkMenuItemText' })}
		</MenuItem>
	)
}
