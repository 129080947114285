import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TeamTableHeader from '../../../components/member/MemberTableHeader'
import TeamMemberRow from './MemberRow'


export default ({ data, user, circle }) => (
    <Table style={{width: 'calc(100% + 64px)', margin: '8px 32px 0 -32px'}}>
        <TeamTableHeader />
        <TableBody>
            {data &&
            data
                .map((member) => <TeamMemberRow member={member} user={user} key={member.invitationId} />)}
        </TableBody>
    </Table>
)
