import { authenticatedFetch } from './base'

export const addScoringGroup = (values) => {
	return authenticatedFetch(`/api/scoring`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(values)
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const getScoringGroupBundles = (id, searchPhrase, showArchivedBundles) => {
	var url = `/api/scoring/${id}/bundle`
	var query = ''

	if (searchPhrase && searchPhrase.length > 0) {
		query += `searchPhrase=${encodeURIComponent(searchPhrase)}`
	}

	if (
		showArchivedBundles != undefined
		&& showArchivedBundles != null
	) {
		query += `${query.length > 0 ? '&' : ''}showArchived=${showArchivedBundles}`
	}

	if (query.length > 0) {
		url += `?${query}`
	}

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const addScoringGroupBundle = (id, bundleId) => {
	return authenticatedFetch(`/api/scoring/${id}/bundle/${bundleId}`, {
		method: 'post',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const autocompleteSearch = (searchPhrase) => {
	var url = `/api/bundle`

	if (searchPhrase && searchPhrase.length > 0) {
		url = `/api/bundle/autocomplete?searchPhrase=${encodeURIComponent(searchPhrase)}`
	}

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchGroups = (searchPhrase, offset) => {
	var url = `/api/scoring`
	let urlHasParams = false

	if (searchPhrase && searchPhrase.length > 0) {
		url = `/api/scoring?searchPhrase=${encodeURIComponent(searchPhrase)}`
		urlHasParams = true
	}

	if(offset){
		url += `${urlHasParams ? '&' : '?'}offset=${offset}`
		urlHasParams = true
	}

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchCopy = (id, languageId) => {
	var url = `/api/scoring/${id}/copy/languages/${languageId}`

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const saveCopy = (id, languageId, values, commentsObj) => {
	return authenticatedFetch(`/api/scoring/${id}/copy`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			id: id,
			languageId: languageId,
			...values,
			comments: {
				commentText: commentsObj?.commentText,
				commentProtocolVersionId: commentsObj?.protocolVersionId,
				commentRelatedTaskUrl: commentsObj?.relatedTaskUrl,
				commentInvestigatorId: commentsObj?.investigatorId
			}
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const editBundle = (id, name, tagName, description) => {
	return authenticatedFetch(`/api/bundle/${id}`, {
		method: 'put',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			name: name,
			tagName: tagName,
			description: description
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchBundle = (id) => {
	return authenticatedFetch(`/api/bundle/${id}`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(prom) {
			return prom
		})
}

export const deleteBundle = (id) => {
	return authenticatedFetch(`/api/bundle/${id}`, { method: 'DELETE' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(prom) {
			return prom
		})
}

export const fetchBundles = (searchPhrase) => {
	var url = `/api/bundle`

	if (searchPhrase && searchPhrase.length > 0) {
		url = `${url}?searchPhrase=${encodeURIComponent(searchPhrase)}`
	}

	return authenticatedFetch(url, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(prom) {
			return prom
		})
}

export const fetchFormula = (id) => {
	var url = `/api/scoring/${id}/formula`

	return authenticatedFetch(url, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(prom) {
			return prom
		})
}

export const fetchGroup = (id) => {
	return authenticatedFetch(`/api/scoring/${id}`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(prom) {
			return prom
		})
}

export const editGroup = (id, name) => {
	return authenticatedFetch(`/api/scoring/${id}?name=${encodeURIComponent(name)}`, { method: 'POST' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(prom) {
			return prom
		})
}

export const fetchSGBundles = (id) => {
	return authenticatedFetch(`/api/bundle/${id}`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(prom) {
			return prom
		})
}

export const addExistingBundleQuestion = (id, bundleId) => {
	return authenticatedFetch(`/api/bundle/${bundleId}`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const validateFormula = (id, extendedFormula) => {
	return authenticatedFetch(`/api/scoring/${id}/validateFormula`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			extendedFormula: extendedFormula
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const saveFormula = (id, formula, extendedFormula, isExtendedMode, upperFence, lowerFence, upperBounds, lowerBounds, commentsObj) => {
	return authenticatedFetch(`/api/scoring/${id}/formula`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			id: id,
			elements: formula,
			extendedFormula: extendedFormula,
			isExtendedMode: isExtendedMode,
			upperFence: upperFence,
			lowerFence: lowerFence,
			upperBounds: upperBounds,
			lowerBounds: lowerBounds,
			comments: {
				commentText: commentsObj?.commentText,
				commentProtocolVersionId: commentsObj?.protocolVersionId,
				commentRelatedTaskUrl: commentsObj?.relatedTaskUrl,
				commentInvestigatorId: commentsObj?.investigatorId
			}
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const updateHideFromPatient = (id) => {
	return authenticatedFetch(`/api/scoring/${id}/updateHideFromPatient`, {
		method: 'PUT',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response
			}
		})
}

export const fetchIsScoringGroupBundleInUse = (id, bundleId) => {
	return authenticatedFetch(`/api/scoring/${id}/bundle/${bundleId}/isInUse`, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const removeScoringGroupBundle = (id, bundleId) => {
	return authenticatedFetch(`/api/scoring/${id}/bundle/${bundleId}`, {
		method: 'DELETE',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const updateScoringGroupBundleState = (scoringGroupId, bundleId, newState) => {
	return authenticatedFetch(`/api/scoring/${scoringGroupId}/changeBundleState`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			bundleId: bundleId,
			newState: newState
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(data) {
			return data
		})
}

export const searchRelatedProtocols = (scoringGroupId, searchPhrase) => {
	var url = `/api/scoring/${scoringGroupId}/relatedProtocols`
	var query = ''

	if (searchPhrase && searchPhrase.length > 0) {
		query += `searchPhrase=${encodeURIComponent(searchPhrase)}`
	}

	if (query.length > 0) {
		url += `?${query}`
	}

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchScoringGroupChangeLogs = (scoringGroupId, searchPhrase, keys) => {
	var url = `/api/scoring/${scoringGroupId}/changeLogs`

	url = `${url}?searchPhrase=${!!searchPhrase?.length && encodeURIComponent(searchPhrase) || ''}`

	if (!!keys?.length) {
		keys.forEach(x => url = `${url}&keys=${x}`)
	}

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(data) {
			return data
		})
}
