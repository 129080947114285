import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { filterInvestigatorsSelector, investigatorsForProtocolVersionEntityAuditSelector,  protocolVersionsForCircleSelector, selectedInvestigatorEntityAuditSelector, selectedProtocolVersionEntityAuditSelector } from '../../redux/selectors'
import { setSelectedInvestigatorForEntityAudit } from '../../redux/actions'
import FormSelect from '../../components/FormSelect'


const InvestigatorsSelect = ({
	intl,
	investigators,
	selectedInvestigatorEntityAudit,
}) => {

	useEffect((selectedInvestigatorEntityAudit) => {
	  }, [selectedInvestigatorEntityAudit]);

	  const handleChange = (e) => {
		setSelectedInvestigatorForEntityAudit(e.target.value)
	}

	return (
		<div className="global-input-wrapper">
			<FormSelect
				data={
					investigators.map((r) => {
						return { value: r.id, name: r.name }
					})}
				//defaultValue={investigators.length ? investigators[0].id : null}
				style={{ color: 'white' }}
				label={intl.formatMessage({ id: 'app.audit.commentModal.investigatorPlaceholder' })}
				floatLabel={false}
				fullWidth
				name="investigatorId"
				value={selectedInvestigatorEntityAudit}
    			onChange={handleChange}
				/*disabled={investigators.length == 1 }*/
			/>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	filterInvestigators: filterInvestigatorsSelector(state),
	investigators: investigatorsForProtocolVersionEntityAuditSelector(state),
	protocolVersions: protocolVersionsForCircleSelector(state),
	selectedProtocolVersion: selectedProtocolVersionEntityAuditSelector(state),
	selectedInvestigatorEntityAudit: selectedInvestigatorEntityAuditSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	setSelectedInvestigator: (id) => dispatch(setSelectedInvestigatorForEntityAudit(id)),
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(InvestigatorsSelect)
