import React, { useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormTextField from "./FormTextField";
import {email, required} from "../utilities/validators";
import Button from "@material-ui/core/Button";


const FormCalendly = ({ ...other}) => {

    const calendlyUrl = 'https://calendly.com/angelgr/one-on-one?hide_event_type_details=1&background_color=011020&text_color=ffffff&primary_color=136aec'
    const head = document.querySelector('head')
    const script = document.createElement('script')

    script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js')
    head.appendChild(script)

    return (
        <Grid
            container
            direction="row"
            alignItems="center"
            style={{
                width: '632px',
                height: '630px',
                paddingLeft: '0px',
                paddingRight: '0px',
                backgroundColor: '#011020',
                overflow: 'hidden'
            }}>
            <Grid item style={{textAlign: 'center'}}>
                <Typography
                    variant="h1"
                    style={{color: 'white', paddingBottom: '32px', paddingLeft: '100px'}}
                >
                    Schedule a Demo
                </Typography>
            </Grid>
            <Grid item>
                <Grid item style={{width: '100%', textAlign: 'center'}}>
                    <div id="schedule_form">
                        <div
                            className="calendly-inline-widget"
                            data-url={calendlyUrl}
                            style={{ minWidth: '630px', height: '460px' }} />
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FormCalendly
