import React, { useState, forwardRef, useEffect, useImperativeHandle } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { uuidv4 } from '../../utilities/utilities'
import { useSelector, shallowEqual } from 'react-redux'
import { unitsSelector, measurementTypesSelector } from '../../redux/selectors'
import { useIntl } from 'react-intl'
import { useDispatch, connect } from 'react-redux'
import { fetchMeasurementTypes, fetchUnitsByType } from '../../redux/actions'
import DefaultFormTextField from '../DefaultFormTextField'
import DefaultFormNumberField from '../DefaultFormNumberField'
import FormFilledInputSelectLight from '../FormFilledInputSelectLight'
import { useStore } from 'react-redux'
import { change, formValueSelector } from 'redux-form'
import { required, mustBeLessThanValue } from '../../utilities/validators'
import FormCheckBox from '../FormCheckBox'
import PIDataToggle from '../PIDataToggle'

const requiredValidator = required
const selector = formValueSelector('createQuestion')
const lowerLessThanMaxValueValidator = mustBeLessThanValue('maximum')
const useStyles = makeStyles((theme) => ({
	root: {
		color: 'black'
	},
	label: {
		color: 'black',
		marginLeft: '-3px'
	},
	instructionTextRoot: {
		color: 'black',
		marginLeft: '-2px'
	},
	instructionTextLabel: {
		color: 'black',
		marginLeft: '-2px'
	},
	formControl: {
		minWidth: 120,
		color: 'black'
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	menuRoot: {
		color: 'black'
	},
	menuItem: {
		color: 'black'
	}
}))

const q = forwardRef((props, ref) => {
	const units = useSelector(unitsSelector, shallowEqual)
	const types = useSelector(measurementTypesSelector, shallowEqual)
	const selectedType = useSelector((state) => selector(state, 'measurementType'), shallowEqual)
	const dispatch = useDispatch()
	const store = useStore()
	const intl = useIntl()

	useEffect(() => {
		dispatch(fetchMeasurementTypes())
		//dispatch(fetchUnits())
	}, [])

	useEffect(
		() => {
			if (selectedType > 0) {
				dispatch(fetchUnitsByType(selectedType))
			}
		},
		[ selectedType ]
	)

	useImperativeHandle(ref, () => ({
		getQuestion() {
			return {
				id: uuidv4().replace('-', ''),
				title: selector(store.getState(), 'question'),
				tag: selector(store.getState(), 'tag'),
				type: 'number',
				instructions: selector(store.getState(), 'instructions'),
				numberType: selector(store.getState(), 'numberType'),
				precision: selector(store.getState(), 'precision'),
				minimum: selector(store.getState(), 'minimum'),
				maximum: selector(store.getState(), 'maximum'),
				isRegularExpressionEnabled: selector(store.getState(), 'isRegularExpressionEnabled'),
				regularExpression: selector(store.getState(), 'regularExpression'),
                regularExpressionValidation: selector(store.getState(), 'regularExpressionValidation'),
				isOptional: selector(store.getState(), 'isOptional'),
				isPiData: selector(store.getState(), 'isPiData'),
                isConsentRequired: selector(store.getState(), 'isConsentRequired'),
				displayInstructionAsIcon: selector(store.getState(), 'displayInstructionAsIcon'),
			}
		}
	}))

	useEffect(() => {
		var el = document.getElementsByName('tag')
		if (el != null) {
			el[0].focus()
		}
	}, [])
	return (
		<Grid container direction="column" alignItems="flex-start">
			<Grid item style={{ width: '100%' }}>
				<PIDataToggle defaultValue={false} onChange={(value) => dispatch(change('createQuestion', 'isPiData', value))}/>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				<DefaultFormTextField
					label="Tag"
					variant="filled"
					name="tag"
					fullWidth
					validators={[ requiredValidator ]}
					inputProps={{ maxLength: 254 }}
					maxLength={254}
				/>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				<DefaultFormTextField
					variant="filled"
					label="Question"
					name="question"
					fullWidth
					validators={[ requiredValidator ]}
					inputProps={{ maxLength: 1023 }}
					maxLength={1023}
				/>
			</Grid>

			<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
				<DefaultFormTextField
					label="Instructions"
					variant="filled"
					name="instructions"
					fullWidth
					inputProps={{ maxLength: 2047 }}
					maxLength={2047}
					multiline
					rows="3"
				/>
			</Grid>
			<FormCheckBox
				name="displayInstructionAsIcon"
				color="primary"
				label={intl.formatMessage({ id: 'app.survey.questions.displayInstructionAsIcon' })}
			/>
			<FormCheckBox
				name="isOptional"
				color="primary"
				label={intl.formatMessage({ id: 'app.survey.questions.isOptional' })}
			/>
            <FormCheckBox
				name="isRegularExpressionEnabled"
                color="primary"
				label={intl.formatMessage({ id: 'app.survey.questions.regularExpression.checkBox' })}
			/>
            {/* <FormCheckBox
                name="isConsentRequired"
                color="primary"
                label={intl.formatMessage({ id: 'app.survey.questions.isConsentRequired.checkBox' })}
            /> */}
			<Grid item>
				<Typography variant="h4" style={{ fontWeight: '400', paddingTop: '10px', paddingBottom: '10px' }}>
					Number Answer
				</Typography>
			</Grid>
			<Grid item>
				<Grid container direction="row" spacing={2}>
					<Grid item>
						<DefaultFormNumberField
							label="Minimum"
							name="minimum"
							variant="filled"
							allowDecimal={true}
							validators={[ lowerLessThanMaxValueValidator ]}
						/>
					</Grid>
					<Grid item>
						<DefaultFormNumberField label="Maximum" name="maximum" variant="filled" allowDecimal={true} />
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid container spacing={2}>
					<Grid item style={{ width: '200px' }}>
						<FormFilledInputSelectLight
							label="Measurement Type"
							name="measurementType"
							validators={[ requiredValidator ]}
							data={types}
							style={{ color: 'black' }}
							fullWidth
						/>
					</Grid>
					<Grid item style={{ width: '200px' }}>
						<FormFilledInputSelectLight
							label="Unit"
							name="numberType"
							validators={[ requiredValidator ]}
							data={
								units &&
								units.map((r) => {
									return { name: r.suffix, value: r.id }
								})
							}
							style={{ color: 'black' }}
							fullWidth
							disabled={!selectedType}
						/>
					</Grid>
					<Grid item style={{ width: '100px' }}>
						<FormFilledInputSelectLight
							validators={[ requiredValidator ]}
							label="Digits"
							name="precision"
							data={[
								{ name: 1, value: 1 },
								{ name: 2, value: 2 },
								{ name: 3, value: 3 },
								{ name: 4, value: 4 },
								{ name: 5, value: 5 }
							]}
							style={{ color: 'black' }}
							fullWidth
						/>
					</Grid>
				</Grid>
			</Grid>
            {
				props.isRegularExpressionEnabled &&
				<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
					<Typography variant="h4" style={{ fontWeight: '400', paddingBottom: '10px' }}>
						{intl.formatMessage({ id: 'app.survey.questions.regularExpression.label' })}
					</Typography>
					<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
						<DefaultFormTextField
							label={intl.formatMessage({ id: 'app.survey.questions.regularExpression.value' })}
							variant="filled"
							fullWidth
							name="regularExpression"
							validators={[requiredValidator]}
							inputProps={{ maxLength: 1024 }}
							maxLength={1024}
							multiline
							rows="3"
						/>
					</Grid>
					<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
						<DefaultFormTextField
							label={intl.formatMessage({ id: 'app.survey.questions.regularExpression.message' })}
							variant="filled"
							fullWidth
							name="regularExpressionValidation"
							validators={[requiredValidator]}
							inputProps={{ maxLength: 1024 }}
							maxLength={1024}
							multiline
							rows="3"
						/>
					</Grid>
				</Grid>
			}
		</Grid>
	)
})

const mapStateToProps = (state) => ({
    isRegularExpressionEnabled: selector(state, 'isRegularExpressionEnabled')
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(q)