import React, { useState } from 'react'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { lighten, makeStyles } from '@material-ui/core/styles'
import { sortCirclesFieldSelector, sortCirclesDirectionSelector } from '../../../redux/selectors'
import { setCirclesSort } from '../../../redux/actions'
import { connect } from 'react-redux'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(3)
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2)
	},
	table: {
		minWidth: 750
	},
	tableWrapper: {
		overflowX: 'auto'
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1
	}
}))

const CircleHeader = ({ intl, isDescending, changeSort, field }) => {
	return (
		<TableRow>
			<TableCell size="small" align="left" style={{ width: '4px', padding: 0, border: 'none' }} />
			<TableCell
				size="small"
				align="left"
				style={{ width: '10px', padding: '6px 0px 6px 25px', border: 'none' }}
			/>
			<TableCell align="left" sortDirection={false} style={{ border: 'none' }}>
				<TableSortLabel
					active={field === 'name'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('name', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="subtitle1"
						style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase',
							minWidth: '200px'
						}}
					>
						{intl.formatMessage({ id: 'app.circles.nameColumnLabel' })}
					</Typography>
				</TableSortLabel>
			</TableCell>
			<TableCell align="left" sortDirection={false} style={{ border: 'none' }}>
				<TableSortLabel
					active={field === 'isBilateral'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('isBilateral', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="subtitle1"
						style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase',
							minWidth: '200px'
						}}
					>
						{intl.formatMessage({ id: 'app.laterality.lateralityLabel' }).toUpperCase()}
					</Typography>
				</TableSortLabel>
			</TableCell>
			<TableCell align="left" style={{ border: 'none' }}>
				<TableSortLabel
					active={field === 'observationalProtocolName'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('observationalProtocolName', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="subtitle1"
						style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase'
						}}
					>
						{intl.formatMessage({ id: 'app.circles.protocolColumnLabel' })}
					</Typography>
				</TableSortLabel>
			</TableCell>
            <TableCell align="left" style={{ border: 'none' }}>
                <TableSortLabel
                    active={field === 'sponsorName'}
                    direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('sponsorName', !isDescending)}
                    IconComponent={ExpandMoreIcon}
                >
                    <Typography
                        variant="subtitle1"
                        style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase'
						}}
                    >
						{intl.formatMessage({ id: 'app.circles.sponsorNameColumnLabel' })}
                    </Typography>
                </TableSortLabel>
            </TableCell>
			<TableCell align="left" style={{ border: 'none' }}>
				<TableSortLabel
					active={field === 'numberUsers'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('numberUsers', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="subtitle1"
						style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase'
						}}
					>
						{intl.formatMessage({ id: 'app.circles.memberCountColumnLabel' })}
					</Typography>
				</TableSortLabel>
			</TableCell>
			<TableCell align="left" style={{ border: 'none' }}>
				<TableSortLabel
					active={field === 'totalSharedCases'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('totalSharedCases', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="subtitle1"
						style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase'
						}}
					>
						{intl.formatMessage({ id: 'app.circles.totalCasesSharedColumnLabel' })}
					</Typography>
				</TableSortLabel>
			</TableCell>
            <TableCell align="left" style={{ border: 'none' }}>
                <TableSortLabel
					active={field === 'casesCreated'}
                    direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('casesCreated', !isDescending)}
                    IconComponent={ExpandMoreIcon}
                >
                    <Typography
                        variant="subtitle1"
                        style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase'
						}}
                    >
                        {intl.formatMessage({ id: 'app.circles.casesUsedCountColumnLabel' })}
                    </Typography>
                </TableSortLabel>
            </TableCell>
			<TableCell align="left" style={{ width: '10px', padding: '6px 0px 6px 0px', border: 'none' }} />
		</TableRow>
	)
}

const mapStateToProps = (state, ownProps) => ({
	isDescending: sortCirclesDirectionSelector(state),
	field: sortCirclesFieldSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	changeSort: (field, direction) => dispatch(setCirclesSort(field, direction))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(CircleHeader)
