import { authenticatedFetch } from './base'

export const fetchSubscription = () => {
	return authenticatedFetch(`/api/subscription`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(subscription) {
			return subscription
		})
}

export const fetchSubscriptionChanges = (userId, count, offset) => {
	return authenticatedFetch(`/api/subscription/log/${userId}/${count}/${offset}`, { method: 'get' })
        .then(function (response) {
            if (response.status === 200) {
                return response.json()
            }
        })
        .then(function (data) {
			return data
        })
}

export const fetchPlan = (id) => {
	return authenticatedFetch(`/api/subscription/${id}/plan`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(subscription) {
			return subscription
		})
}

export const fetchSubscriptionUsersCases = (userId) => {
	return authenticatedFetch(`/api/subscription/usersCases/${userId}`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(subscription) {
			return subscription
		})
}

export const fetchSubscriptionCases = () => {
	return authenticatedFetch(`/api/subscription/cases`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(subscription) {
			return subscription
		})
}

export const applyCreditChanges = (id, cases, users) => {
	return authenticatedFetch(`/api/subscription/${id}/credits`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			caseCount: cases,
			userCount: users
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(subscription) {
			return subscription
		})
}

export const applyChanges = (id, expiration, sponsorId) => {
	return authenticatedFetch(`/api/subscription/${id}`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			expirationDate: expiration,
			sponsorId
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(subscription) {
			return subscription
		})
}

export const fetchSubscriptionUsers = () => {
	return authenticatedFetch(`/api/subscription/users`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(subscription) {
			return subscription
		})
}

export const fetchCircleUsersCases = (circleId) => {
	return authenticatedFetch(`/api/subscription/circleUsersCases/${circleId}`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(subscription) {
			return subscription
		})
}

export const checkCanSponsor = (email, circleId) => {
    let url = `/api/subscription/canSponsor/${encodeURIComponent(email.trim())}`;

    if(circleId)
    {
    	url += `?circleId=${circleId}`;
    }

    return authenticatedFetch(url, { method: 'get' })
        .then(function (response) {
            if (response.status === 200) {
                return response.json()
            }
        })
        .then(function (resp) {
            return resp
        })
}

export const expireSubscription = (userId) => {
	return authenticatedFetch(`/api/subscription/expire/${userId}`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(subscription) {
			return subscription
		})
}