import { getUser } from '../utilities/userUtilities'
import { authenticatedFetch } from './base'

export const search = (searchPhrase) => {
	var url = `/api/units`

	if (searchPhrase && searchPhrase.length > 0) {
		url = `${url}?searchPhrase=${encodeURIComponent(searchPhrase)}`
	}

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetch = (id) => {
	var url = `/api/units/${id}`

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchUnitsByType = (type) => {
	var url = `/api/units/type/${type}`

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const fetchTypes = () => {
	var url = `/api/units/types`

	return authenticatedFetch(url, { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const addUnit = (name, suffix, measurementTypeId, languageId, rootId) => {
	return authenticatedFetch(`/api/units`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			name: name,
			suffix: suffix,
			measurementTypeId: measurementTypeId,
			languageId: languageId,
			rootId: rootId
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const deactivateUnit = (id) => {
	return authenticatedFetch(`/api/units/${id}`, {
		method: 'DELETE',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(result) {
			return result
		})
}

export const editUnit = (id, name, suffix, measurementTypeId) => {
	return authenticatedFetch(`/api/units/${id}`, {
		method: 'put',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			name: name,
			suffix: suffix,
			id: id,
			measurementTypeId: measurementTypeId
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const updateUnit = (id, name, suffix) => {
	return authenticatedFetch(`/api/units/${id}`, {
		method: 'put',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			id,
			name,
			suffix
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const createOrUpdateMeasurementType = (measurementTypeId, name) => {
	return authenticatedFetch(`/api/units/measurementType`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			measurementTypeId,
			name
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const enableMeasurementType = (measurementTypeId, enabled) => {
	return authenticatedFetch(`/api/units/measurementType/enable`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({
			measurementTypeId,
			enabled
		})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}

export const searchMeasurementTypes = (searchPhrase, offset, rowsPerPage = 25) => {
	let url = '/api/units/measurementType'

	var queryData = new URLSearchParams({
		searchPhrase,
		offset,
		rowsPerPage
	})

	url += `?${queryData.toString()}`

	return authenticatedFetch(url, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}
