import React from 'react'
import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import useTheme from '@material-ui/core/styles/useTheme'

const ProtocolsEmpty = ({ intl }) => {
	const theme = useTheme()
	return (
		<Grid container direction="row" style={{ paddingBottom: '32px' }}>
			<Grid item style={{ padding: '64px', maxWidth: '50%' }}>
				<Typography
					variant="h2"
					style={{ fontWeight: '400', color: theme.palette.primary.A800, paddingBottom: '12px' }}
				>
					{intl.formatMessage({ id: 'app.questions.noFilter' })}
				</Typography>
				{/* <Typography variant="subtitle2" style={{ fontWeight: '600' }}>
					{intl.formatMessage({ id: 'app.protocols.emptyMessage' })}
				</Typography> */}
			</Grid>
			<Grid item style={{ padding: '64px', maxWidth: '50%', marginLeft: '72px' }} />
		</Grid>
	)
}

const enhance = compose(injectIntl)

export default enhance(ProtocolsEmpty)
