import React, { useState, useEffect, Fragment } from 'react'
import Person from '@material-ui/icons/Person'
import PersonOutlined from '@material-ui/icons/PersonOutlined'
import Tooltip from '@material-ui/core/Tooltip'
import moment from 'moment'
import { injectIntl } from 'react-intl'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'

const MemberStatusIcon = ({ member, intl }) => {
	const [ tooltip, setToolTip ] = useState('')

	useEffect(() => {
		if (member && member.dateJoined && moment(member.dateJoined).isValid()) {
			setToolTip(moment(member.dateJoined).format('LL'))
		} else {
			setToolTip(intl.formatMessage({ id: 'app.userProfile.teamMemberPendingText' }))
		}
	}, [])

	return (
		<Tooltip title={tooltip} disableFocusListener disableTouchListener>
			<div>
				{member && !member.isOwner && member.dateJoined && <Person style={{ fontSize: '24px' }} />}
				{member && !member.isOwner && !member.dateJoined && <PersonOutlined style={{ fontSize: '24px' }} />}
				{member && member.isOwner && <SupervisorAccountIcon style={{ fontSize: '24px' }} />}
			</div>
		</Tooltip>
	)
}

export default injectIntl(MemberStatusIcon)
