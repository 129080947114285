import React, { useState } from 'react'
import { injectIntl, useIntl } from 'react-intl'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import CircleNameCell from './CircleNameCell'
import { Link } from 'react-router-dom'

const CircleRow = ({ circle }) => {
	const intl = useIntl()
	const [ minHeight, setMinHeight ] = useState(64)

	return (
		<TableRow
			style={{
				backgroundColor: circle.isArchived ? '#F6F6F6' : 'white'
			}}
		>
			<TableCell align="left" size="small" style={{ padding: '6px 6px 6px 0' }}>
				<div
					style={{
						minHeight: `${minHeight - 16}px`,
						borderLeft: `solid 4px ${circle.isArchived ? '#bbb' : '#007aff'}`
					}}
				>
					&nbsp;
				</div>
			</TableCell>
			<TableCell align="left" size="small">
				<CircleNameCell circle={circle} setHeightCallback={setMinHeight} />
			</TableCell>
            <TableCell align="left" size="small">
				<Typography variant="subtitle1" style={{ textAlign: 'left', fontWeight: '400' }}>
					{circle.protocolVersionId && 
					<Link
						to={`/protocols/${circle.protocolId}/version/${circle.protocolVersionId}/details`}
						style={{
							display: 'block',
							fontWeight: '700',
							fontSize: '17px',
							lineHeight: '20px',
							letterSpacing: '0.16px',

						}}
					>
						<span style={{ display: 'block', marginBottom: '5px' }}>
							{circle.protocolName}
						</span>
					</Link>}
				</Typography>
            </TableCell>
            <TableCell align="left" size="small">
                <Typography variant="subtitle1" style={{ textAlign: 'left', fontWeight: '400' }}>
					{circle.roleName}
                </Typography>
            </TableCell>
			<TableCell align="left" size="small">
				<Typography variant="subtitle1" style={{ textAlign: 'left', fontWeight: '400' }}>
					{intl.formatDate(circle.invitationDate, {
								year: 'numeric',
								month: 'short',
								day: '2-digit'
							})}
				</Typography>
			</TableCell>
			<TableCell align="left" size="small">
				<Typography variant="subtitle1" style={{ textAlign: 'left', fontWeight: '400' }}>
				{circle.removalDate && intl.formatDate(circle.removalDate, {
								year: 'numeric',
								month: 'short',
								day: '2-digit'
							})}
				</Typography>
			</TableCell>
		</TableRow>
	)
}

export default injectIntl(CircleRow)
