import React from 'react'
import Grid from '@material-ui/core/Grid'
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'
import { Typography } from '@material-ui/core'

const LanguageNavigator = ({ language, onLanguageChange }) => {
	return (
		<Grid container justify="space-between" alignItems="center" style={{ minHeight: '100px', cursor: 'pointer' }}>
			<Grid item>
				<Grid container direction="column" onClick={() => onLanguageChange(language.languageId)}>
					<Grid item>
						{language && (
							<Typography
								variant="subtitle1"
								style={{
									color: 'inherit',
									textAlign: 'left',
									fontWeight: '400',
									wordBreak: 'break-word'
								}}
							>
								{language && language.displayName}
							</Typography>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

const enhance = compose(injectIntl)

export default enhance(LanguageNavigator)
