import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import DefaultFormTextField from '../../DefaultFormTextField'
import DefaultFormNumberField from '../../DefaultFormNumberField'
import { makeStyles } from '@material-ui/core/styles'
import { required } from '../../../utilities/validators'
import { uuidv4 } from '../../../utilities/utilities'
import FormTextField from '../../FormTextField'
import FormNumberTextField from '../../FormNumberTextField'
import { useIntl } from 'react-intl'
const requiredValidator = required

export default forwardRef((props, ref) => {
	const { onDeleteCallback, answer, showScoreEntry, dark, disabled, showCode } = props

	const intl = useIntl()

	const [ internalAnswer, setAnswer ] = useState(null)
	const [ value, setValue ] = useState(null)
	const [ code, setCode ] = useState(null)
	const [ id, setId ] = useState(uuidv4().replace('-', ''))
	const [ scoreId, setScoreId ] = useState(uuidv4().replace('-', ''))
	const [ codeId, setCodeId ] = useState(uuidv4().replace('-', ''))

	useImperativeHandle(ref, () => ({
		getAnswer() {
			let a = []
			return { answer: internalAnswer, value, code }
		}
	}))

	useEffect(
		() => {
			if (answer) {
				setId(answer.data.answerId)
				setScoreId(answer.data.scoreId)
				setCodeId(answer.data.codeId)
				if (answer.data.answer) {
					setAnswer(answer.data.answer.answer)
					setValue(answer.data.answer.value)
					setValue(answer.data.answer.code)
				}
			}
			answer.ref = ref
		},
		[ answer, ref ]
	)

	const AdaptiveFormTextField = dark ? FormTextField : DefaultFormTextField
	const AdaptiveFormNumberField = dark ? FormNumberTextField : DefaultFormNumberField

	return (
		<Grid container style={{ width: '100%' }} alignItems="center">
			{!answer.data.baseId && <Grid item style={{ marginLeft: '-40px' }}>
				<IconButton onClick={() => onDeleteCallback(answer)} disabled={disabled}>
					<DeleteIcon />
				</IconButton>
			</Grid> }

			<Grid item style={{ flex: 1, paddingRight: '5px' }}>
				<AdaptiveFormTextField
					label="Answer"
					validators={[ requiredValidator ]}
					name={id}
					variant="filled"
					onChange={(e) => setAnswer(e.target.value)}
					disabled={disabled}
				/>
			</Grid>
			{showScoreEntry && (
				<Grid item style={{ paddingLeft: '5px' }}>
					<AdaptiveFormNumberField
						label="Score"
						name={scoreId}
						variant="filled"
						allowDecimal={true}
						validators={[ requiredValidator ]}
						onChange={(e) => setValue(e.target.value)}
						disabled={disabled}
					/>
				</Grid>
			)}
			{showCode &&
				<Grid item style={{ paddingLeft: '5px' }}>
					<AdaptiveFormTextField
						label={intl.formatMessage({ id: 'app.survey.questions.answerCodeLabel' })}
						validators={[requiredValidator]}
						name={codeId}
						variant="filled"
						onChange={(e) => setCode(e.target.value)}
						disabled={disabled}
					/>
				</Grid>
			}
		</Grid>
	)
})
