import React, { useState } from 'react'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { patientUpdateTriggerTemplateTypeSelector } from '../../redux/selectors'

const Filter = ({ intl, currentFilter, setFilter, types }) => {

	const onChangeFilter = (event, item) => {
		setFilter(event.target.value)
	}

	return (
		<FormControl
			style={{ textAlign: 'center', width: '100%', overflow: 'hidden', minHeight: '48px' }}
		>
			<Select
				value={currentFilter}
				onChange={onChangeFilter}
				autoWidth
				displayEmpty={true}
				style={{
					color: 'white',
					fontSize: '20px',
					lineHeight: '1',
					letterSpacing: '0.25px',
					fontWeight: '700',
					padding: '0 0 0 24px',
					minHeight: '48px',
				}}
			>
				{types.map((filter) => (
					<MenuItem value={filter.id} id={filter.id}>
						{filter.value} <KeyboardArrowDownIcon style={{ color: 'white', fontSize: '22px', margin: '0 0 -5px 5px' }} />
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}

const mapStateToProps = (state, ownProps) => ({
	types: patientUpdateTriggerTemplateTypeSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)
export default enhance(Filter)
