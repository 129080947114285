import Grid from '@material-ui/core/Grid'
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import Typography from '@material-ui/core/Typography'
import DefaultFormTextField from '../DefaultFormTextField'
import DefaultFormNumberField from '../DefaultFormNumberField'
import { useStore, useDispatch } from 'react-redux'
import { change } from 'redux-form'
import { formValueSelector } from 'redux-form'
import UploadImage from './Analog/UploadImage'
import { required, mustBeLessThanValue } from '../../utilities/validators'
import FormCheckBox from '../FormCheckBox'
import { useIntl } from 'react-intl'
import { getTagValidators } from '../../utilities/questions'
import FormTextField from '../FormTextField'
import FormNumberTextField from '../FormNumberTextField'
const selector = formValueSelector('editQuestion')
const lowerLessThanMaxValueValidator = mustBeLessThanValue('endValue')
const requiredValidator = required

export default forwardRef((props, ref) => {
	const { question, dark, canSetOptional = true } = props
	const store = useStore()
	const startImageRef = useRef()
	const endImageRef = useRef()
	const dispatch = useDispatch()
	const intl = useIntl()
	useEffect(
		() => {
			if (question && question.data) {
				dispatch(change('editQuestion', 'question', question.data.title))
				dispatch(change('editQuestion', 'instructions', question.data.instructions))
				dispatch(change('editQuestion', 'tag', question.data.tag))
				dispatch(change('editQuestion', 'startText', question.data.startText))
				dispatch(change('editQuestion', 'endText', question.data.endText))
				dispatch(change('editQuestion', 'startValue', question.data.startValue.toString()))
				dispatch(change('editQuestion', 'endValue', question.data.endValue.toString()))
				dispatch(change('editQuestion', 'stepValue', question.data.stepValue.toString()))
				dispatch(change('editQuestion', 'startImagePath', question.data.startImagePath))
				dispatch(change('editQuestion', 'endImagePath', question.data.endImagePath))
				dispatch(change('editQuestion', 'isOptional', question.data.isOptional))
				dispatch(change('editQuestion', 'displayInstructionAsIcon', question.data.displayInstructionAsIcon))
			}
		},
		[ question ]
	)

	useImperativeHandle(ref, () => ({
		getQuestion() {
			const startImage = startImageRef.current.getFile()
			const endImage = endImageRef.current.getFile()

			return {
				id: question.data.id,
				title: selector(store.getState(), 'question'),
				type: 'analog',
				instructions: selector(store.getState(), 'instructions'),
				isOptional: selector(store.getState(), 'isOptional'),
				tag: selector(store.getState(), 'tag'),
				startValue: selector(store.getState(), 'startValue'),
				endValue: selector(store.getState(), 'endValue'),
				stepValue: selector(store.getState(), 'stepValue'),
				startText: selector(store.getState(), 'startText'),
				endText: selector(store.getState(), 'endText'),
				startImagePath: question.data.startImagePath,
				startImage: startImage,
				endImagePath: question.data.endImagePath,
				endImage: endImage,
				displayInstructionAsIcon: selector(store.getState(), 'displayInstructionAsIcon'),
			}
		}
	}))
	useEffect(() => {
		var el = document.getElementsByName('tag')
		if (el != null) {
			el[0].focus()
		}
	}, [])

	const AdaptiveFormTextField = dark ? FormTextField : DefaultFormTextField
	const AdaptiveFormNumberField = dark ? FormNumberTextField : DefaultFormNumberField

	const disabled=!!question.data.cloneId

	return (
		<Grid container direction='column' alignItems='flex-start'>
			<Grid item style={{ width: '100%' }}>
				<AdaptiveFormTextField
					label='Tag'
					variant='filled'
					name='tag'
					fullWidth
					inputProps={{ maxLength: 254 }}
					maxLength={254}
					validators={getTagValidators(question)}
				/>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				<AdaptiveFormTextField
					label='Question'
					variant='filled'
					name='question'
					fullWidth
					validators={[ requiredValidator ]}
					inputProps={{ maxLength: 1023 }}
					maxLength={1023}
					disabled={disabled}
				/>
			</Grid>
			<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
				<AdaptiveFormTextField
					label='Instructions'
					variant='filled'
					fullWidth
					name='instructions'
					inputProps={{ maxLength: 2047 }}
					maxLength={2047}
					multiline
					rows='3'
					disabled={disabled}
				/>
			</Grid>
			<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '0px' }}>
				<FormCheckBox
					name="displayInstructionAsIcon"
					color={dark ? "secondary" : "primary"}
					fontColor={dark ? "white" : "black"}
					label={intl.formatMessage({ id: 'app.survey.questions.displayInstructionAsIcon' })}
					disabled={disabled}
				/>
			</Grid>
			{
				!!canSetOptional
				&& <Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '0px' }}>
					<FormCheckBox
						name='isOptional'
						color={dark ? "secondary" : "primary"}
						fontColor={dark ? "white" : "black"}
						label={intl.formatMessage({ id: 'app.survey.questions.isOptional' })}
						disabled={disabled}
					/>
				</Grid>
			}
			<Grid item>
				<Typography variant="h4" style={{ fontWeight: '400', paddingBottom: '10px', color: dark ? 'white' : 'initial' }}>
					Analog Answer
				</Typography>
			</Grid>

			<Grid item>
				<Grid container direction='row' spacing={2}>
					<Grid item style={{ paddingBottom: '10px' }}>
						<AdaptiveFormNumberField
							label='Start Value'
							validators={[ requiredValidator, lowerLessThanMaxValueValidator ]}
							allowDecimal={true}
							variant='filled'
							name='startValue'
							fullWidth
							disabled={disabled}
						/>
					</Grid>
					<Grid item style={{ paddingBottom: '10px' }}>
						<AdaptiveFormNumberField
							label='End Value'
							validators={[ requiredValidator ]}
							allowDecimal={true}
							name='endValue'
							variant='filled'
							fullWidth
							disabled={disabled}
						/>
					</Grid>
					<Grid item style={{ paddingBottom: '10px' }}>
						<AdaptiveFormNumberField
							label='Step Value'
							validators={[ requiredValidator ]}
							allowDecimal={true}
							name='stepValue'
							variant='filled'
							fullWidth
							disabled={disabled}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid container direction='row' spacing={2}>
					<Grid item style={{ paddingBottom: '10px' }}>
						<AdaptiveFormTextField
							label='Start Text'
							variant='filled'
							name='startText'
							fullWidth
							inputProps={{ maxLength: 254 }}
							maxLength={254}
							disabled={disabled}
						/>
					</Grid>
					<Grid item style={{ paddingBottom: '10px' }}>
						<AdaptiveFormTextField
							label='End Text'
							name='endText'
							fullWidth
							variant='filled'
							inputProps={{ maxLength: 254 }}
							maxLength={254}
							disabled={disabled}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid container direction='row' spacing={2}>
					<Grid item style={{ paddingBottom: '10px', width: '207px' }}>
						<Grid container direction='row'>
							<Grid item>
								<UploadImage
									name='startImage'
									defaultPath={question.data.startImagePath}
									ref={startImageRef}
									dark={dark}
									disabled={disabled}
								/>
							</Grid>
							<Grid item style={{ padding: '3px 3px 3px 10px', color: dark ? 'white' : 'initial' }}>
								<Typography>Start Image</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item style={{ paddingBottom: '10px', width: '207px' }}>
						<Grid container>
							<Grid item direction='row'>
								<UploadImage
									name='endImage'
									defaultPath={question.data.endImagePath}
									ref={endImageRef}
									dark={dark}
									disabled={disabled}
								/>
							</Grid>
							<Grid item style={{ padding: '3px 3px 3px 10px', color: dark ? 'white' : 'initial' }}>
								<Typography>End Image</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
})
