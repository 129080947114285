import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined'
import { Chip } from '@material-ui/core'
import { useIntl } from 'react-intl'

export default ({ member }) => {
	const intl = useIntl()
	const onCopy = () => {
		let copyText = document.getElementById(member.email)
		copyText.select()
		document.execCommand('copy')
	}

	return (
		<Grid container direction="column" alignContent="flex-start" alignItems="flex-start">
			<Grid item>
				<Typography
					variant="subtitle1"
					style={{
						wordBreak: 'break-word',
						maxWidth: '300px',
						textAlign: 'left'
					}}
				>
					{member && (member.name || member.memberName)}
				</Typography>
			</Grid>
			<Grid item>
				<Grid container>
					<Grid item>
						<Typography variant="body2">{member && member.email} </Typography>
					</Grid>
					<Grid item style={{ paddingLeft: '5px', marginTop: '3px' }}>
						<input
							type="text"
							id={member.email}
							name="email"
							value={member.email}
							style={{ position: 'absolute', left: '-9999px' }}
						/>
						<FileCopyOutlined style={{ color: 'grey', cursor: 'pointer' }} onClick={onCopy} />
					</Grid>
				</Grid>
			</Grid>
			{member.removalScheduledAt && <Grid item>
				<Chip
					variant="outlined"
					size="small"
					style={{
						fontSize: '14px'
					}}
					label={intl.formatMessage({id: 'app.userProfile.teamMemberPendingRemovalLabel'}, {RemovalDate: member.removalScheduledAt.split('T')[0]})}
				/>
			</Grid>}
		</Grid>
	)
}
