import React from "react"
import { searchBundleChangeLogs } from "../../../redux/actions"
import { bundleChangeLogKeysSelector, bundleChangeLogsSelector } from "../../../redux/selectors"
import EntityAudit from "../../../components/entityAudit/EntityAudit"
import { useRouteMatch } from "react-router"
import { compose } from "recompose"
import { connect } from "react-redux"
import { injectIntl } from "react-intl"


const BundleAudit = ({
    logs,
    keys,
    search
}) => {
    const match = useRouteMatch()

    const handleSearchBarValuesChanged = (searchPhrase, searchKeys) => {
        const bundleId = match?.params?.bundleId

        search(bundleId, searchPhrase, searchKeys)
    }

    return (
        <EntityAudit
            logs={logs}
            keys={keys}
            onSearch={handleSearchBarValuesChanged}
        />
    )
}

const mapStateToProps = (state, ownProps) => ({
    keys: bundleChangeLogKeysSelector(state),
	logs: bundleChangeLogsSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    search: (bundleId, searchPhrase, keys) => dispatch(searchBundleChangeLogs(bundleId, searchPhrase, keys))
})

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)

export default enhance(BundleAudit)
