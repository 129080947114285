import * as circle from '../../../apis/circle'
import * as cookies from '../../../utilities/cookies'
import * as api from '../../../apis/bundle'
import * as resources from '../../../apis/resource'
import history from '../../../utilities/history'
import { createSelector } from 'reselect'
import { hideModal } from '../core'
import { getUser } from '../../../utilities/userUtilities'
import { setFormula, updateUpperFence, updateLowerFence, stopSaving, fetchBundleBuilderQuestions } from '../../actions'

const ADD_BUNDLE_REQUESTED = 'ADD_BUNDLE_REQUESTED'
const ADD_BUNDLE_SUCCESS = 'ADD_BUNDLE_SUCCESS'
const ADD_BUNDLE_FAILED = 'ADD_BUNDLE_FAILED'

export const addBundle = (name) => (dispatch) => {
	dispatch({ type: ADD_BUNDLE_REQUESTED, data: {} })

	api.addBundle(name).then((group) => {
		dispatch(stopSaving())
		if (group && group.isSuccessful) {
			history.push(`/bundles/${group.id}/languages/1`)
			dispatch(hideModal())
			return dispatch({ type: ADD_BUNDLE_SUCCESS, data: group })
		} else {
			dispatch({ type: ADD_BUNDLE_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.bundle.failedToCreateErrorText`, isSuccess: false }
			})
		}
	})
	return
}

const EDIT_BUNDLE_REQUESTED = 'EDIT_BUNDLE_REQUESTED'
const EDIT_BUNDLE_SUCCESS = 'EDIT_BUNDLE_SUCCESS'
const EDIT_BUNDLE_FAILED = 'EDIT_BUNDLE_FAILED'

export const editBundle = (id, name, tagName, description) => (dispatch) => {
	dispatch({ type: EDIT_BUNDLE_REQUESTED, data: {} })

	api.editBundle(id, name, tagName, description).then((response) => {
		dispatch(stopSaving())
		if (response && response.isSuccessful) {
			dispatch(fetchBundle(id))
			dispatch(hideModal())
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.groups.updateSuccessful', isSuccess: true }
			})
			return dispatch({ type: EDIT_BUNDLE_SUCCESS, data: response })
		} else {
			dispatch({ type: EDIT_BUNDLE_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.groups.failedToCreateErrorText`, isSuccess: false }
			})
		}
	})
	return
}

const DELETE_BUNDLE_REQUESTED = 'DELETE_BUNDLE_REQUESTED'
const DELETE_BUNDLE_SUCCESS = 'DELETE_BUNDLE_SUCCESS'
const DELETE_BUNDLE_FAILED = 'DELETE_BUNDLE_FAILED'

export const deleteBundle = (id) => (dispatch, getState) => {
	dispatch({ type: DELETE_BUNDLE_REQUESTED, data: {} })

	api.deleteBundle(id).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(fetchBundles())

			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.general.successfullyRemoved`, isSuccess: true }
			})
		} else {
			dispatch({ type: DELETE_BUNDLE_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.bundles.failedToDeleteErrorText`, isSuccess: false }
			})
		}
	})
	return
}

const FETCH_BUNDLE_REQUESTED = 'FETCH_BUNDLE_REQUESTED'
const FETCH_BUNDLE_SUCCESS = 'FETCH_BUNDLE_SUCCESS'
const FETCH_BUNDLE_FAILED = 'FETCH_BUNDLE_FAILED'

export const fetchBundle = (id) => (dispatch) => {
	dispatch({ type: FETCH_BUNDLE_REQUESTED, data: {} })

	api.fetchBundle(id).then((group) => {
		if (group && group.isSuccessful) {
			if (group.bundleNotFound) {
				return dispatch({ type: 'BUNDLE_NOT_FOUND', data: null })
			} else {
				dispatch(setFormula(group.formula))
				dispatch(updateUpperFence(group.upperFence))
				dispatch(updateLowerFence(group.lowerFence))
				return dispatch({ type: FETCH_BUNDLE_SUCCESS, data: group })
			}
		} else {
			dispatch({ type: FETCH_BUNDLE_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.bundle.failedToLoadBundle', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_BUNDLES_REQUESTED = 'FETCH_BUNDLES_REQUESTED'
const FETCH_BUNDLES_SUCCESS = 'FETCH_BUNDLES_SUCCESS'
const FETCH_BUNDLES_FAILED = 'FETCH_BUNDLES_FAILED'

export const fetchBundles = (searchPhrase) => (dispatch) => {
	dispatch({ type: FETCH_BUNDLE_REQUESTED, data: {} })

	api.fetchBundles(searchPhrase).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ 
				type: FETCH_BUNDLES_SUCCESS, 
				data: {
					items: response.bundles,
					hasMore: response.hasMore
				} 
			})
		} else {
			dispatch({ type: FETCH_BUNDLES_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.bundle.failedToLoadGroups', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_ADDITIONAL_BUNDLES_REQUESTED = 'FETCH_ADDITIONAL_BUNDLES_REQUESTED'
const FETCH_ADDITIONAL_BUNDLES_SUCCESS = 'FETCH_ADDITIONAL_BUNDLES_SUCCESS'
const FETCH_ADDITIONAL_BUNDLES_FAILED = 'FETCH_ADDITIONAL_BUNDLES_FAILED'

export const fetchAdditionalBundles = (searchPhrase, offset) => (dispatch) => {
	dispatch({ type: FETCH_ADDITIONAL_BUNDLES_REQUESTED, data: {} })

	api.fetchBundles(searchPhrase, offset).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ 
				type: FETCH_ADDITIONAL_BUNDLES_SUCCESS, 
				data: {
					items: response.bundles,
					hasMore: response.hasMore
				} 
			})
		} else {
			dispatch({ type: FETCH_ADDITIONAL_BUNDLES_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.bundle.failedToLoadGroups', isSuccess: false }
			})
		}
	})
	return
}

const SORT_BUNDLES_REQUESTED = 'SORT_BUNDLES_REQUESTED'
export const setBundleSort = (field, isDescending) => (dispatch, state) => {
	let currentState = state()

	let data = currentState.score.bundles.sort((a, b) => {
		if (isDescending) {
			return a[field] > b[field] ? -1 : 1
		} else {
			return b[field] > a[field] ? -1 : 1
		}
	})
	return dispatch({
		type: SORT_BUNDLES_REQUESTED,
		data: { items: data, field: field, isDescending: isDescending }
	})
}

const CLEAR_BUNDLE_AUTOCOMPLETE_REQUESTED = 'CLEAR_BUNDLE_AUTOCOMPLETE_REQUESTED'

export const clearBundleAutocomplete = () => (dispatch) => {
	dispatch({ type: CLEAR_BUNDLE_AUTOCOMPLETE_REQUESTED, data: {} })
	return
}

const QUESTION_BUNDLE_AUTOCOMPLETE_REQUESTED = 'QUESTION_BUNDLE_AUTOCOMPLETE_REQUESTED'
const QUESTION_BUNDLE_AUTOCOMPLETE_SUCCESS = 'QUESTION_BUNDLE_AUTOCOMPLETE_SUCCESS'
const QUESTION_BUNDLE_AUTOCOMPLETE_FAILED = 'QUESTION_BUNDLE_AUTOCOMPLETE_FAILED'

export const searchBundleAutocomplete = (searchPhrase, surveyId) => (dispatch) => {
	dispatch({ type: QUESTION_BUNDLE_AUTOCOMPLETE_REQUESTED, data: {} })
	api.autocompleteSearch(searchPhrase, surveyId).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: QUESTION_BUNDLE_AUTOCOMPLETE_SUCCESS, data: response.bundles })
		} else {
			return dispatch({ type: QUESTION_BUNDLE_AUTOCOMPLETE_FAILED, data: {} })
		}
	})
	return
}

const SET_BUNDLE_SEARCH_TERM_REQUESTED = 'SET_BUNDLE_SEARCH_TERM_REQUESTED'
const SET_BUNDLE_SEARCH_TERM_SUCCESS = 'SET_BUNDLE_SEARCH_TERM_SUCCESS'
const SET_BUNDLE_SEARCH_TERM_FAILED = 'SET_BUNDLE_SEARCH_TERM_FAILED'

export const setBundleSearchTerm = (value) => (dispatch) => {
	dispatch({ type: SET_BUNDLE_SEARCH_TERM_REQUESTED, data: {} })
	return dispatch({ type: SET_BUNDLE_SEARCH_TERM_SUCCESS, data: value })
}

const FETCH_IS_QUESTION_IN_USE_REQUESTED = 'FETCH_IS_QUESTION_IN_USE_REQUESTED'
const FETCH_IS_QUESTION_IN_USE_SUCCESS = 'FETCH_IS_QUESTION_IN_USE_SUCCESS'
const FETCH_IS_QUESTION_IN_USE_FAILED = 'FETCH_IS_QUESTION_IN_USE_FAILED'

export const fetchQuestionUseStatus = (bundleId, question) => (dispatch) => {
	dispatch({ type: FETCH_IS_QUESTION_IN_USE_REQUESTED, data: null })

	api.getQuestionUseStatus(bundleId, question.data.id, question.data.questionType).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch(
				{
					type: FETCH_IS_QUESTION_IN_USE_SUCCESS,
					data: {
						isUsedByCases: response.isUsedByCases,
						scoringGroups: response.scoringGroups
					}
				}
			)
		} else {
			dispatch({ type: FETCH_IS_QUESTION_IN_USE_FAILED, data: null })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.bundle.failedToLoadGroups', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_BUNDLES_BY_QUESTION_REQUESTED = 'FETCH_BUNDLES_BY_QUESTION_REQUESTED'
const FETCH_BUNDLES_BY_QUESTION_SUCCESS = 'FETCH_BUNDLES_BY_QUESTION_SUCCESS'
const FETCH_BUNDLES_BY_QUESTION_FAILED = 'FETCH_BUNDLES_BY_QUESTION_FAILED'

export const fetchQuestionRelatedBundles = (questionId, questionType) => (dispatch) => {
	dispatch({ type: FETCH_BUNDLES_BY_QUESTION_REQUESTED, data: null })

	api.getBundlesByQuestion(questionId, questionType).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch(
				{
					type: FETCH_BUNDLES_BY_QUESTION_SUCCESS,
					data: response.bundles
				}
			)
		} else {
			dispatch({ type: FETCH_BUNDLES_BY_QUESTION_FAILED, data: null })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.bundle.failedToLoadGroups', isSuccess: false }
			})
		}
	})
	return
}

const SET_ARCHIVED_BUNDLE_QUESTIONS_VISIBLE = 'SET_ARCHIVED_BUNDLE_QUESTIONS_VISIBLE'

export const setArchivedBundleQuestionVisible = (visible) => (dispatch) => {
	dispatch({ type: SET_ARCHIVED_BUNDLE_QUESTIONS_VISIBLE, data: visible })

	return
}

const CHANGE_BUNDLE_QUESTION_STATE_REQUESTED = 'CHANGE_BUNDLE_QUESTION_STATE_REQUESTED'
const CHANGE_BUNDLE_QUESTION_STATE_SUCCESS = 'CHANGE_BUNDLE_QUESTION_STATE_SUCCESS'
const CHANGE_BUNDLE_QUESTION_STATE_FAILED = 'CHANGE_BUNDLE_QUESTION_STATE_FAILED'

export const changeBundleQuestionState = (bundleId, questionId, questionTypeId, newState, languageId, allEntities = false, commentsObj) => (dispatch, state) => {
	dispatch({ type: CHANGE_BUNDLE_QUESTION_STATE_REQUESTED, data: {} })

	if (allEntities) {
		api.updateBundleQuestionStateRelatedEntities(bundleId, questionId, questionTypeId, newState, commentsObj).then((response) => {
			if (response && response.isSuccessful) {
				let currentState = state()

				dispatch(fetchBundleBuilderQuestions(bundleId, null, languageId, currentState.score.archivedQuestionsVisible))
				
				return dispatch({ type: CHANGE_BUNDLE_QUESTION_STATE_SUCCESS, data: {} })
			} else if (response.errorCode == 40) {
				dispatch({ type: CHANGE_BUNDLE_QUESTION_STATE_FAILED, data: {} })
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: `app.bundle.questionUsedInTheCases`, isSuccess: false }
				})
			} else if (response.errorCode == 41) {
				dispatch({ type: CHANGE_BUNDLE_QUESTION_STATE_FAILED, data: {} })
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: `app.bundle.questionUsedInTheSG`, isSuccess: false }
				})
			} else {
				dispatch({ type: CHANGE_BUNDLE_QUESTION_STATE_FAILED, data: {} })
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: `app.bundle.failedToChangeQuestionState`, isSuccess: false }
				})
			}
		})
	} else {
		api.updateBundleQuestionState(bundleId, questionId, questionTypeId, newState, commentsObj).then((response) => {
			if (response && response.isSuccessful) {
				let currentState = state()

				dispatch(fetchBundleBuilderQuestions(bundleId, null, languageId, currentState.score.archivedQuestionsVisible))

				return dispatch({ type: CHANGE_BUNDLE_QUESTION_STATE_SUCCESS, data: {} })
			} else if (response.errorCode == 40) {
				dispatch({ type: CHANGE_BUNDLE_QUESTION_STATE_FAILED, data: {} })
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: `app.bundle.questionUsedInTheCases`, isSuccess: false }
				})
			} else if (response.errorCode == 41) {
				dispatch({ type: CHANGE_BUNDLE_QUESTION_STATE_FAILED, data: {} })
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: `app.bundle.questionUsedInTheSG`, isSuccess: false }
				})
			} else {
				dispatch({ type: CHANGE_BUNDLE_QUESTION_STATE_FAILED, data: {} })
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: `app.bundle.failedToChangeQuestionState`, isSuccess: false }
				})
			}
		})
	}
	return
}

const CLONE_BUNDLE_REQUESTED = 'CLONE_BUNDLE_REQUESTED'
const CLONE_BUNDLE_SUCCESS = 'CLONE_BUNDLE_SUCCESS'
const CLONE_BUNDLE_FAILED = 'CLONE_BUNDLE_FAILED'

export const cloneBundle = (id, name, prefix) => (dispatch) => {
	dispatch({ type: CLONE_BUNDLE_REQUESTED, data: {} })

	api.cloneBundle(id, name, prefix).then((group) => {
		dispatch(stopSaving())
		if (group && group.isSuccessful) {
			history.push(`/bundles/${group.id}/languages/1`)
			dispatch(hideModal())
			return dispatch({ type: CLONE_BUNDLE_SUCCESS, data: group })
		} else {
			dispatch({ type: CLONE_BUNDLE_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.bundle.failedToCloneErrorText`, isSuccess: false }
			})
		}
	})
	return
}

const FETCH_BUNDLE_CHANGE_LOGS_REQUESTED = 'FETCH_BUNDLE_CHANGE_LOGS_REQUESTED'
const FETCH_BUNDLE_CHANGE_LOGS_SUCCESS = 'FETCH_BUNDLE_CHANGE_LOGS_SUCCESS'
const FETCH_BUNDLE_CHANGE_LOGS_FAILED = 'FETCH_BUNDLE_CHANGE_LOGS_FAILED'
export const searchBundleChangeLogs = (bundleId, searchPhrase = null, keys = []) => (dispatch) => {
	dispatch({ type: FETCH_BUNDLE_CHANGE_LOGS_REQUESTED, data: {} })

	api.fetchBundleChangeLogs(bundleId, searchPhrase, keys).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({
				type: FETCH_BUNDLE_CHANGE_LOGS_SUCCESS,
				data: {
					logs: response.logs,
					keys: response.keys
				}
			})
		} else {
			dispatch({ type: FETCH_BUNDLE_CHANGE_LOGS_FAILED, data: {} })

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.audit.failedToFetch', isSuccess: false }
			})
		}
	})
	return
}


const initial = {
	bundle: null,
	bundleNotFound: false,
	bundleRequestReceived: false,
	bundles: [],
	autoCompleteQuestions: [],
	sortField: 'modifiedDateSeconds',
	isDescending: true,
	isBundleEditing: false,
	hasMore: true,
	isLoading: false,
	searchTerm: '',
	bundleQuestionUseStatus: null,
	questionRelatedBundles: null,
	archivedQuestionsVisible: false,
	logs: [],
	logKeys: []
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_BUNDLE_REQUESTED:
			return { ...state, bundleNotFound: false, bundleRequestReceived: false, bundle: [] }
		case 'BUNDLE_NOT_FOUND':
			return { ...state, bundleNotFound: true, bundleRequestReceived: true, bundle: [] }
		case SET_BUNDLE_SEARCH_TERM_SUCCESS:
			return { ...state, searchTerm: action.data }
		case CLEAR_BUNDLE_AUTOCOMPLETE_REQUESTED:
			return { ...state, autoCompleteQuestions: [] }
		case QUESTION_BUNDLE_AUTOCOMPLETE_SUCCESS:
			return { ...state, autoCompleteQuestions: action.data }
		case EDIT_BUNDLE_REQUESTED:
		case ADD_BUNDLE_REQUESTED:
		case CLONE_BUNDLE_REQUESTED:
			return { ...state, isBundleEditing: true }
		case EDIT_BUNDLE_FAILED:
		case ADD_BUNDLE_FAILED:
		case CLONE_BUNDLE_FAILED:
			return { ...state, isBundleEditing: false }
		case EDIT_BUNDLE_SUCCESS:
		case ADD_BUNDLE_SUCCESS:
		case CLONE_BUNDLE_SUCCESS:
			return { ...state, bundle: action.data, isBundleEditing: true }
		case DELETE_BUNDLE_SUCCESS:
		case FETCH_BUNDLES_SUCCESS:
			return { 
				...state,
				bundles: action.data.items,
				hasMore: action.data.hasMore 
			}
		case FETCH_ADDITIONAL_BUNDLES_SUCCESS:
			return {
				...state,
				bundles: [ ...state.bundles, ...action.data.items],
				hasMore: action.data.hasMore,
				isLoading: false
			}
		case FETCH_ADDITIONAL_BUNDLES_FAILED:
			return {
				...state,
				hasMore: false,
				isLoading: false
			}
		case FETCH_ADDITIONAL_BUNDLES_REQUESTED:
			return {
				...state,
				isLoading: true
			}
		case FETCH_BUNDLE_SUCCESS:
			return { ...state, bundle: action.data, bundleRequestReceived: true, bundleNotFound: false }
		case SORT_BUNDLES_REQUESTED:
			return {
				...state,
				bundles: [ ...action.data.items ],
				sortField: action.data.field,
				isDescending: action.data.isDescending
			}
		case FETCH_IS_QUESTION_IN_USE_REQUESTED:
			return { ...state, bundleQuestionUseStatus: null }
		case FETCH_IS_QUESTION_IN_USE_SUCCESS:
			return { ...state, bundleQuestionUseStatus: action.data }
		case FETCH_BUNDLES_BY_QUESTION_SUCCESS:
			return {
				...state,
				questionRelatedBundles: action.data
			}
		case SET_ARCHIVED_BUNDLE_QUESTIONS_VISIBLE:
			return {
				...state,
				archivedQuestionsVisible: action.data
			}

		/** Bundle change logs */
		case FETCH_BUNDLE_CHANGE_LOGS_REQUESTED:
			return {
				...state,
				logs: [],
				logKeys: []
			}
		case FETCH_BUNDLE_CHANGE_LOGS_SUCCESS:
			return {
				...state,
				logs: action.data.logs,
				logKeys: action.data.keys
			}

		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.score
export const bundleNotFoundSelector = (state) => state.score.bundleNotFound
export const bundleRequestReceivedSelector = (state) => state.score.bundleRequestReceived
export const bundlesSelector = createSelector(mainSelector, (state) => state.bundles)
export const bundleSelector = createSelector(mainSelector, (state) => state.bundle)
export const autocompleteBundleSelector = createSelector(mainSelector, (state) => {
	return state.autoCompleteQuestions ? state.autoCompleteQuestions : []
})

export const sortBundlesDirectionSelector = createSelector(mainSelector, (state) => {
	return state.isDescending
})

export const sortBundlesFieldSelector = createSelector(mainSelector, (state) => {
	return state.sortField
})

export const hasMoreBundlesSelector = createSelector(mainSelector, (state) => {
	return state.hasMore
})

export const isLoadingBundlesSelector = createSelector(mainSelector, (state) => {
	return state.isLoading
})

export const bundleSearchTermSelector = createSelector(mainSelector, (state) => {
	return state.searchTerm
})

export const isBundleQuestionUsedByCasesSelector = createSelector(mainSelector, (state) => {
	return state.bundleQuestionUseStatus?.isUsedByCases
})

export const bundleQuestionScoringGroupsSelector = createSelector(mainSelector, (state) => {
	return state.bundleQuestionUseStatus?.scoringGroups
})

export const relatedBundlesSelector = createSelector(mainSelector, (state) => {
	return state.questionRelatedBundles
})

export const isArchivedBundleQuestionVisibleSelector = createSelector(mainSelector, (state) => {
	return state.archivedQuestionsVisible
})

export const bundleChangeLogsSelector = createSelector(
	mainSelector,
	(state) => {
		return !!state && state.logs
	}
)

export const bundleChangeLogKeysSelector = createSelector(
	mainSelector,
	(state) => {
		return !!state && state.logKeys
	}
)
