import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'
import { showModal } from '../../../redux/actions'
import { useDispatch } from 'react-redux'
import ExpireSubscriptionModal from '../ExpireSubscriptionModal'

const ExpireSubscriptionMenuItem = ({ intl, subscriber, onComplete }) => {
	const dispatch = useDispatch()
	const onClick = () => {
		dispatch(showModal(ExpireSubscriptionModal, { subscriber: subscriber }))
		onComplete()
	}

	return (
		<MenuItem
			onClick={() => {
				onClick()
			}}
		>
			{intl.formatMessage({ id: "app.subscriber.expireSubscriptionMenuItem" })}
		</MenuItem>
	)
}

const enhance = compose(injectIntl)

export default enhance(ExpireSubscriptionMenuItem)
