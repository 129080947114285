import React from 'react'
import Grid from '@material-ui/core/Grid'
import Tag from './Tag'
import QuestionContainer from './QuestionContainer'
import RequiresTranslationBadge from '../../RequiresTranslationBadge'
import FileQuestion from '../../FileQuestion'

export default ({ question, surveyEntity, position, positionDisplay, value, counter, displayCount, validate, disabled, ...rest }) => {
    return (
        <Grid data-testid="survey-file-question"  container direction='column'>
            <Grid item>
                <Tag tag={question.tag} />
            </Grid>
            {question &&
                !question.id && (
                    <Grid item style={{ marginTop: '10px' }}>
                        <RequiresTranslationBadge />
                    </Grid>
                )}
            <QuestionContainer
                question={question}
                surveyEntity={surveyEntity}
                children={
                    <div data-testid="file-question-survey-component">
                        <FileQuestion
                            question={question}
                            position={position}
                            positionDisplay={positionDisplay}
                            value={value}
                            counter={counter}
                            displayCount={displayCount}
                            validate={validate}
                            disabled={disabled}
                            {...rest} />
                    </div>
                }
            />
        </Grid>
    );
}
