import React, { useImperativeHandle, forwardRef, Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Grid from '@material-ui/core/Grid'
import { TextField, Typography } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useConstant } from '../../utilities/useConstant'
import { search } from '../../redux/actions'
import {
	globalSearchCasesSelector,
	globalSearchCirclesSelector,
	globalSearchProtocolsSelector,
	globalSearchSurveysSelector,
	hasSearchCasesSelector,
	hasSearchCirclesSelector,
	hasSearchProtocolsSelector,
	hasSearchSurveysSelector
} from '../../redux/selectors'
import { useSelector, shallowEqual } from 'react-redux'
import { debounceMilliseconds, minimumSearchCharacters } from '../../utilities/constants'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import SearchResult from './SearchResult'
import { Link } from 'react-router-dom'
var debounce = require('lodash/debounce')

const useStyles = makeStyles({
	list: {
		width: 350
	},
	fullList: {
		width: 'auto'
	}
})

export default forwardRef((props, ref) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const intl = useIntl()
	const [ open, setOpen ] = React.useState(false)
	const [ hasSearch, setHasSearch ] = React.useState(false)

	const hasProtocols = useSelector(hasSearchProtocolsSelector, shallowEqual)
	const hasCircles = useSelector(hasSearchCirclesSelector, shallowEqual)
	const hasCases = useSelector(hasSearchCasesSelector, shallowEqual)
	const hasSurveys = useSelector(hasSearchSurveysSelector, shallowEqual)

	const cases = useSelector(globalSearchCasesSelector, shallowEqual)
	const protocols = useSelector(globalSearchProtocolsSelector, shallowEqual)
	const circles = useSelector(globalSearchCirclesSelector, shallowEqual)
	const surveys = useSelector(globalSearchSurveysSelector, shallowEqual)

	useImperativeHandle(ref, () => ({
		open() {
			dispatch(search())
			setOpen(true)
		}
	}))

	const toggleDrawer = () => (event) => {
		if (event.key === 'Tab' || event.key === 'Shift') {
			return
		}

		setOpen(!open)
	}

	const onChange = (value) => {
		if (value && value.length >= minimumSearchCharacters) {
			setHasSearch(true)
			dispatch(search(value))
		} else {
			setHasSearch(true)
		}
	}

	const OnSearchChanged = useConstant(() => debounce(onChange, debounceMilliseconds, { leading: true }))

	const sideList = (side) => (
		<div className={classes.list} role="presentation">
			<Grid container direction="column" style={{ padding: '20px' }}>
				<Grid item style={{ alignSelf: 'space-between' }}>
					<Grid container justify="space-between" alignItems="center">
						<Grid item>
							<Typography variant="subtitle1">
								{intl.formatMessage({ id: 'app.general.searchResults' })}
							</Typography>{' '}
						</Grid>
						<Grid item>
							<IconButton aria-label="close" onClick={() => setOpen(false)}>
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
				</Grid>
				<Grid item style={{ marginTop: '15px' }}>
					<TextField
						variant="filled"
						onChange={(e) => OnSearchChanged(e.target.value)}
						fullWidth
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon style={{ margin: '-5px -9px 0 11px', color: 'black', display: 'block' }} />
								</InputAdornment>
							)
						}}
						inputProps={{ style: { color: 'black' } }}
					/>
				</Grid>
				<SearchResult
					headerText={intl.formatMessage({ id: 'app.general.circles' })}
					hasSearch={hasSearch}
					hasData={hasCircles}
					data={circles}
					getContent={(r) => (
						<Fragment>
							<Link
								to={(location) => {
									return { ...location, pathname: `/circles/${r.id}` }
								}}
								style={{ textDecoration: 'none', fontWeight: '700', fontSize: '18px', color: 'black' }}
							>
								<Typography
									variant="body1"
									style={{ color: 'rgba(0,0,0,0.6)', marginLeft: '-16px' }}
									onClick={toggleDrawer()}
								>
									{r.name}
								</Typography>
							</Link>
							<Typography variant="body2" style={{ marginLeft: '-16px' }}>
								{r.protocolName}
							</Typography>
						</Fragment>
					)}
				/>
				<SearchResult
					headerText={intl.formatMessage({ id: 'app.general.protocols' })}
					hasSearch={hasSearch}
					hasData={hasProtocols}
					data={protocols}
					getContent={(r) => (
						<Link
							to={(location) => {
								toggleDrawer()
								return { ...location, pathname: `/protocols/${r.id}/details` }
							}}
							style={{ textDecoration: 'none', fontWeight: '700', fontSize: '18px', color: 'black' }}
						>
							<Typography
								variant="body1"
								style={{ color: 'rgba(0,0,0,0.6)', marginLeft: '-16px' }}
								onClick={toggleDrawer()}
							>
								{r.name}
							</Typography>
						</Link>
					)}
				/>

				<SearchResult
					headerText={intl.formatMessage({ id: 'app.general.surveys' })}
					hasSearch={hasSearch}
					hasData={hasSurveys}
					data={surveys}
					getContent={(r) => (
						<Link
							to={(location) => {
								toggleDrawer()
								return { ...location, pathname: `/surveys/${r.id}/details` }
							}}
							style={{ textDecoration: 'none', fontWeight: '700', fontSize: '18px', color: 'black' }}
						>
							<Typography
								variant="body1"
								style={{ color: 'rgba(0,0,0,0.6)', marginLeft: '-16px' }}
								onClick={toggleDrawer()}
							>
								{console.log(r)}
								{r.title}
							</Typography>
						</Link>
					)}
				/>
			</Grid>
		</div>
	)

	return (
		<div>
			<Drawer open={open} onClose={toggleDrawer()}>
				{sideList('left')}
			</Drawer>
		</div>
	)
})
