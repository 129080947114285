import React, { useEffect, useState, useCallback } from 'react'
import Grid from '@material-ui/core/Grid'
import { 
	isSavingSelector, 
	formattedProtocolsSelector, 
	protocolVersionsForEntityAuditSelector,
	entityAuditPrefillCommentsValuesSelector,
	investigatorsForProtocolVersionEntityAuditSelector
 } from '../../redux/selectors'
import { connect, useDispatch } from 'react-redux'
import { change } from 'redux-form'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import InvestigatorsSelect from './InvestigatorsSelect'
import ProtocolVersionSelectorForEntityAudit from './ProtocolVersionSelectorForEntityAudit'
import FormTextField from '../FormTextField'
import { requiredNotEmpty, url } from '../../utilities/validators'
import { 
	fetchInvestigatorsForProtocolVersionEntityAudit, 
	fetchProtocolVersionsForEntity
} from '../../redux/modules/observationalProtocol/actions'


const requiredNotEmptyValidator = requiredNotEmpty
const urlValidator = url

const EntityAuditCommentFields = ({ 
	intl, 
	entityId, 
	entityTypeId, 
	formName,
	protocolVersions, 
	getProtocolVersions,
	setInitialValues,
	investigators
}) => {
	
	const dispatch = useDispatch()

	useEffect(() => {
		if (!investigators || investigators.length === 0)
		{
			dispatch(change(formName, 'investigatorId', null));
		}
	}, [investigators])
	
	useEffect(() => {
		const fetchData = async () => {
            try {
				await getProtocolVersions(entityId, entityTypeId)
				const prefillValues = JSON.parse(localStorage.getItem('entityAuditCommentsPrefill')) || {};
				setInitialValues(prefillValues);
			} catch (error) {
				console.error('Error fetching protocol versions:', error);
			}
		};

		fetchData();

	  }, [setInitialValues]);

	return (
		<Grid
			container
			direction="column"
			justify="flex-start"
			alignItems="center"
		>
			<Grid item style={{ alignSelf: 'flex-start', width: '100%' }}>
				<FormTextField
					label={intl.formatMessage({ id: 'app.audit.commentModal.commentTextPlaceholder' })}
					fullWidth
					type="text"
					name="commentText"
					maxLength={500}
					validators={[ requiredNotEmptyValidator ]}
					variant="filled"
				/>
			</Grid>
			<Grid item style={{ alignSelf: 'flex-start', width: '100%' }}>
				<FormTextField
					label={intl.formatMessage({ id: 'app.audit.commentModal.taskUrlPlaceholder' })}
					fullWidth
					type="text"
					name="relatedTaskUrl"
					maxLength={2048}
					validators={[ urlValidator ]}
					variant="filled"

				/>
			</Grid>
			{protocolVersions.length > 0 && (
			<Grid item style={{ alignSelf: 'flex-start', width: '100%' }}>
				<div className="global-input-wrapper">
				<ProtocolVersionSelectorForEntityAudit
							name="protocolVersionId"
							label={intl.formatMessage({ id: 'app.audit.commentModal.protocolPlaceholder' })}
							floatLabel={false}
							data={protocolVersions}
							disabled={protocolVersions.length === 0}
						/>
				</div>
			</Grid>
			)}
			{protocolVersions.length > 0 &&  (
			<Grid item style={{ alignSelf: 'flex-start', width: '100%' }}>
				<InvestigatorsSelect disabled={protocolVersions.length === 0}/>
			</Grid>
			)}
		</Grid>
	)
}

const mapStateToProps = (state, ownProps) => ({
	isSaving: isSavingSelector(state),
	initialValues: entityAuditPrefillCommentsValuesSelector(state),
	protocolVersions: protocolVersionsForEntityAuditSelector(state),
	protocols: formattedProtocolsSelector(state),
	investigators: investigatorsForProtocolVersionEntityAuditSelector(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	getInvestigatorsForProtocolVersion: (id) => dispatch(fetchInvestigatorsForProtocolVersionEntityAudit(id)),
	setInitialValues: (values) => dispatch(setInitialValuesThunk(ownProps.formName, values)),
	getProtocolVersions: (entityId, entityTypeId) => dispatch(fetchProtocolVersionsForEntity(entityId, entityTypeId)),
})

const setInitialValuesThunk = (formName, values) => {
    return (dispatch, getState) => {
        const state = getState();
		const protocolVersions = state.protocol.protocolVersionsForEntityAudit;

        dispatch(change(formName, 'commentText', values.commentText));
        dispatch(change(formName, 'relatedTaskUrl', values.relatedTaskUrl));

        if (values.protocolVersionId &&
            protocolVersions &&
            protocolVersions.some((x) => x.id === values.protocolVersionId)
        ) {
            dispatch(change(formName, 'protocolVersionId', values.protocolVersionId));
            dispatch(fetchInvestigatorsForProtocolVersionEntityAudit(values.protocolVersionId));
            dispatch(change(formName, 'investigatorId', values.investigatorId));
        }
    };
};

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl
)

export default enhance(EntityAuditCommentFields)
