import { authenticatedFetch } from './base'

export const inviteCircleMember = (id, email, circleId, languageId, sponsored, caseFunding, caseSponsorId) => {
	return authenticatedFetch(`/api/circleinvitation/invitemember/${circleId}/${encodeURIComponent(email.trim())}`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ sponsored, languageId, caseFunding, caseSponsorId })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(member) {
			return member
		})
}

export const updateCircleInviteUserMember = (id, email, optionSelected) => {
	return authenticatedFetch(`/api/circleinvitation/${id}/${email}/optionmember/${optionSelected}`, {
		method: 'put',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({})
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const resendCircleInvitation = (invitationId, circleId) => {
	return authenticatedFetch(`/api/circleinvitation/${circleId}/${invitationId}/resend`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({})
	}).then(function(response) {
		if (response.status === 200) {
			return response.json()
		} else {
			return false
		}
	})
}
