import React, { useState, Fragment } from 'react'
import { compose } from 'recompose'
import { makeStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import Button from '@material-ui/core/Button'

import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles({
	label: {
        justifyContent: 'flex-start', 
        paddingLeft: '3px'
	}
})

const SurveyQuestionTypeSelector = ({ typeSelectedCallback, intl, suppressNonDiscreteQuestions = false }) => {
	const theme = useTheme()
	const classes = useStyles()

	const [ value, setValue ] = useState('Boolean')

	return (

		<Grid container direction='column'>
			<Grid item>
				<Typography variant="h6">
					{intl.formatMessage({ id: 'app.surveyBuilder.questionTypeSelectLabel' })}
				</Typography>
			</Grid>
			<Grid item>
				<Grid container direction='column'>
					<Grid item style={{ width: '100%' }}>
						<Grid container alignItems='center' style={{ width: '100%' }}>
							<Grid item style={{ height: '100%' }}>
								<div
									style={{
										background: theme.palette.secondary.A700,
										minHeight: '100%',
										width: '3px'
									}}>
									&nbsp;
								</div>
							</Grid>
							<Grid item>
								<Button autoCapitalize={false} classes={{ label: classes.label }}>
									<Typography
										variant='caption'
										classes={{ label: classes.label }}
										onClick={() => {
											setValue('single')
											typeSelectedCallback('single')
										}}>
										{intl.formatMessage({ id: 'app.surveyBuilder.questionTypeSingleLabel' })}
									</Typography>
								</Button>
							</Grid>
						</Grid>
					</Grid>
					<Grid item style={{ width: '100%' }}>
						<Grid container alignItems='center' style={{ width: '100%' }}>
							<Grid item style={{ height: '100%' }}>
								<div
									style={{
										background: theme.palette.secondary.A700,
										minHeight: '100%',
										width: '3px'
									}}>
									&nbsp;
								</div>
							</Grid>
							<Grid item>
								<Button autoCapitalize={false} classes={{ label: classes.label }}>
									<Typography
										variant='caption'
										classes={{ label: classes.label }}
										onClick={() => {
											setValue('mutli')
											typeSelectedCallback('multi')
										}}>
										{intl.formatMessage({ id: 'app.surveyBuilder.questionTypeMultipleLabel' })}
									</Typography>
								</Button>
							</Grid>
						</Grid>
					</Grid>
					<Grid item style={{ width: '100%' }}>
						<Grid container alignItems='center' style={{ width: '100%' }}>
							<Grid item style={{ height: '100%' }}>
								<div
									style={{
										background: theme.palette.secondary.A700,
										minHeight: '100%',
										width: '3px'
									}}>
									&nbsp;
								</div>
							</Grid>
							<Grid item>
								<Button autoCapitalize={false} classes={{ label: classes.label }}>
									<Typography
										variant='caption'
										classes={{ label: classes.label }}
										onClick={() => {
											setValue('grouped')
											typeSelectedCallback('grouped')
										}}>
										{intl.formatMessage({ id: 'app.surveyBuilder.questionTypeGroupLabel' })}
									</Typography>
								</Button>
							</Grid>
						</Grid>
					</Grid>
					<Grid item style={{ width: '100%' }}>
						<Grid container alignItems='center' style={{ width: '100%' }}>
							<Grid item style={{ height: '100%' }}>
								<div
									style={{
										background: theme.palette.secondary.A700,
										minHeight: '100%',
										width: '3px'
									}}>
									&nbsp;
								</div>
							</Grid>
							<Grid item>
								<Button autoCapitalize={false} classes={{ label: classes.label }}>
									<Typography
										variant='caption'
										classes={{ label: classes.label }}
										onClick={() => {
											setValue('analog')
											typeSelectedCallback('analog')
										}}>
										{intl.formatMessage({ id: 'app.surveyBuilder.qustionTypeAnalogLabel' })}
									</Typography>
								</Button>
							</Grid>
						</Grid>
					</Grid>
					<Grid item style={{ width: '100%' }}>
						<Grid container alignItems='center' style={{ width: '100%' }}>
							<Grid item style={{ height: '100%' }}>
								<div
									style={{
										background: theme.palette.secondary.A700,
										minHeight: '100%',
										width: '3px'
									}}>
									&nbsp;
								</div>
							</Grid>
							<Grid item>
								<Button autoCapitalize={false} classes={{ label: classes.label }}>
									<Typography
										variant='caption'
										classes={{ label: classes.label }}
										onClick={() => {
											setValue('date')
											typeSelectedCallback('date')
										}}>
										{intl.formatMessage({ id: 'app.surveyBuilder.questionTypeDateLabel' })}
									</Typography>
								</Button>
							</Grid>
						</Grid>
					</Grid>
					{/* <Grid item style={{ width: '100%' }}>
						<Grid container alignItems="center" style={{ width: '100%' }}>
							<Grid item style={{ height: '100%' }}>
								<div
									style={{
										background: theme.palette.secondary.A700,
										minHeight: '100%',
										width: '3px'
									}}
								>
									&nbsp;
								</div>
							</Grid>
							<Grid item>
								<Button autoCapitalize={false} classes={{ label: classes.label }}>
									<Typography
										variant="caption"
										classes={{ label: classes.label }}
										onClick={() => {
											setValue('tf')
											typeSelectedCallback('tf')
										}}
									>
										{intl.formatMessage({ id: 'app.surveyBuilder.questionTypeTFLabel' })}
									</Typography>
								</Button>
							</Grid>
						</Grid>
					</Grid> */}
				</Grid>
			</Grid>
			<Grid item style={{ marginTop: '20px' }}>
				<Typography variant="h6">
					{intl.formatMessage({ id: 'app.surveyBuilder.questionTypeFillInLabel' })}
				</Typography>
			</Grid>
			<Grid item>
				<Grid container direction='column'>
					{!suppressNonDiscreteQuestions && (
						<Grid container alignItems='center' style={{ width: '100%' }}>
							<Grid item style={{ height: '100%' }}>
								<div
									style={{
										background: theme.palette.secondary.A700,
										minHeight: '100%',
										width: '3px'
									}}>
									&nbsp;
								</div>
							</Grid>
							<Grid item>
								<Button autoCapitalize={false} classes={{ label: classes.label }}>
									<Typography
										variant='caption'
										classes={{ label: classes.label }}
										onClick={() => {
											setValue('text')
											typeSelectedCallback('text')
										}}>
										{intl.formatMessage({ id: 'app.surveyBuilder.questionTypeTextLabel' })}
									</Typography>
								</Button>
							</Grid>
						</Grid>
					)}

					<Grid container alignItems='center' style={{ width: '100%' }}>
						<Grid item style={{ height: '100%' }}>
							<div
								style={{
									background: theme.palette.secondary.A700,
									minHeight: '100%',
									width: '3px'
								}}>
								&nbsp;
							</div>
						</Grid>
						<Grid item>
							<Button autoCapitalize={false} classes={{ label: classes.label }}>
								<Typography
									variant='caption'
									classes={{ label: classes.label }}
									onClick={() => {
										setValue('number')
										typeSelectedCallback('number')
									}}>
									{intl.formatMessage({ id: 'app.surveyBuilder.questionTypeNumberLabel' })}
								</Typography>
							</Button>
						</Grid>
					</Grid>
					<Grid container alignItems='center' style={{ width: '100%' }}>
						<Grid item style={{ height: '100%' }}>
							<div
								style={{
									background: theme.palette.secondary.A700,
									minHeight: '100%',
									width: '3px'
								}}>
								&nbsp;
							</div>
						</Grid>
						<Grid item>
							<Button autoCapitalize={false} classes={{ label: classes.label }}>
								<Typography
									variant='caption'
									classes={{ label: classes.label }}
									onClick={() => {
										setValue('file')
										typeSelectedCallback('file')
									}}>
									{intl.formatMessage({ id: 'app.surveyBuilder.questionTypeFileLabel' })}
								</Typography>
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>

	)
}

const enhance = compose(injectIntl)

export default enhance(SurveyQuestionTypeSelector)
