import React, { useState, useEffect, Fragment } from 'react'
import IconButton from '@material-ui/core/IconButton'
import MuiMenu from '@material-ui/core/Menu'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import RemoveMenuItem from './RemoveMenuItem'
import MenuItem from '@material-ui/core/MenuItem'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { startUpdatingQuestion } from '../../redux/actions'
import LockIcon from '@material-ui/icons/Lock'
import { QUESTION_STATE } from '../../utilities/constants'


const ITEM_HEIGHT = 48

const ChangeStateMenuItem = ({
	question,
	newState,
	allEntities,
	onChangeStateCallback,
	handleClose,
	intl
}) => {
	const getChangeStateButtonText = () => {
		let title = ""

		switch (newState) {
			case QUESTION_STATE.IN_USE:
				if (allEntities) {
					title = intl.formatMessage({ id: 'app.bundle.setInUseQuestionRelatedEntitiesButtonText' })
				} else {
					title = intl.formatMessage({ id: 'app.bundle.setInUseQuestionButtonText' })
				}
				break
			case QUESTION_STATE.NOT_USED:
				if (allEntities) {
					title = intl.formatMessage({ id: 'app.bundle.setNotInUseQuestionRelatedEntitiesButtonText' })
				} else {
					title = intl.formatMessage({ id: 'app.bundle.setNotInUseQuestionButtonText' })
				}
				break
			case QUESTION_STATE.ARCHIVED:
				if (allEntities) {
					title = intl.formatMessage({ id: 'app.bundle.archiveQuestionRelatedEntitiesButtonText' })
				} else {
					title = intl.formatMessage({ id: 'app.bundle.archiveQuestionButtonText' })
				}
				break
			default:
				break
		}

		return title
	}

	return (
		<MenuItem
			onClick={() => {
				onChangeStateCallback(question, newState, allEntities)
				handleClose()
			}}
			disabled={question.data.isLocked}
		>
			{
				getChangeStateButtonText()
			}
			
			{
				question.data.isLocked
				&& <LockIcon
					style={{
						fontSize: '24px',
						color: '#949494',
						marginTop: '-8px'
					}}
				/>
			}
		</MenuItem>
	)
}

export default ({
	node,
	onRemoveCallback,
	onMoveToPosition,
	onCloneCallback,
	containerId,
	showMoveToPosition,
	propertyStateName,
	onChangeStateCallback,
	sameStateAllEntities,
	canSetInUseState,
	canSetInUseStateAllEntities,
	canSetNotInUseState,
	canSetNotInUseStateAllEntities,
	canSetArchiveState,
	canSetArchiveStateAllEntities,
	canEdit = true,
	canClone = true
}) => {
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)
	const intl = useIntl()
	const dispatch = useDispatch()
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	useEffect(() => { }, [])

	return (
		<div>
			{node &&
				node.data && (<Fragment>
					<IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
						<MoreVertIcon />
					</IconButton>
					<MuiMenu
						id="long-menu"
						anchorEl={anchorEl}
						keepMounted
						open={open}
						onClose={handleClose}
						PaperProps={{
							style: {
								maxHeight: ITEM_HEIGHT * 8,
								width: 300
							}
						}}
					>
						{
							!!canClone
							&& !!onCloneCallback
							&& <MenuItem
								onClick={() => {
									onCloneCallback()
									handleClose()
								}}
								disabled={node.data.isLocked}
							>
								{intl.formatMessage({ id: 'app.surveyBuilder.cloneButtonText' })}
								{node.data.isLocked && (
									<LockIcon style={{ fontSize: '24px', color: '#949494', marginTop: '-8px' }} />
								)}
							</MenuItem>
						}

						{canEdit && <MenuItem
							onClick={() => {
								dispatch(startUpdatingQuestion(containerId))
								handleClose()
							}}
							disabled={node.data.isLocked}
						>
							{intl.formatMessage({ id: 'app.surveyBuilder.editButtonText' })}
							{node.data.isLocked && (
								<LockIcon style={{ fontSize: '24px', color: '#949494', marginTop: '-8px' }} />
							)}
						</MenuItem>}

						{/* {
							!!onRemoveCallback
							&& <MenuItem
								onClick={() => {
									handleClose()
									onRemoveCallback(node)
								}}
								disabled={node.data.isLocked}
							>
								{intl.formatMessage({ id: 'app.surveyBuilder.removeButtonText' })}
								{node.data.isLocked && (
									<LockIcon style={{ fontSize: '24px', color: '#949494', marginTop: '-8px' }} />
								)}
							</MenuItem>
						} */}

						{showMoveToPosition && <MenuItem
							onClick={() => {
								onMoveToPosition()
								handleClose()
							}}
							disabled={node.data.isLocked}
						>
							{intl.formatMessage({ id: 'app.survey.moveToPositionLabel' })}
							{node.data.isLocked && (
								<LockIcon style={{ fontSize: '24px', color: '#949494', marginTop: '-8px' }} />
							)}
						</MenuItem>}

						{
							canSetInUseState
							&& !!onChangeStateCallback
							&& node.data[propertyStateName] != QUESTION_STATE.IN_USE
							&& <ChangeStateMenuItem
								question={node}
								newState={QUESTION_STATE.IN_USE}
								allEntities={false}
								onChangeStateCallback={onChangeStateCallback}
								handleClose={handleClose}
								intl={intl}
							/>
						}
						{
							canSetNotInUseState
							&& !!onChangeStateCallback
							&& node.data[propertyStateName] != QUESTION_STATE.NOT_USED
							&& <ChangeStateMenuItem
								question={node}
								newState={QUESTION_STATE.NOT_USED}
								allEntities={false}
								onChangeStateCallback={onChangeStateCallback}
								handleClose={handleClose}
								intl={intl}
							/>
						}
						{
							canSetArchiveState
							&& !!onChangeStateCallback
							&& node.data[propertyStateName] != QUESTION_STATE.ARCHIVED
							&& <ChangeStateMenuItem
								question={node}
								newState={QUESTION_STATE.ARCHIVED}
								allEntities={false}
								onChangeStateCallback={onChangeStateCallback}
								handleClose={handleClose}
								intl={intl}
							/>
						}
						{
							canSetInUseStateAllEntities
							&& !!onChangeStateCallback
							&& (
								(
									!sameStateAllEntities
									&& node.data[propertyStateName] != QUESTION_STATE.IN_USE
								)
								|| (
									sameStateAllEntities
									&& node.data[propertyStateName] == QUESTION_STATE.IN_USE
								)
							)
							&& <ChangeStateMenuItem
								question={node}
								newState={QUESTION_STATE.IN_USE}
								allEntities={true}
								onChangeStateCallback={onChangeStateCallback}
								handleClose={handleClose}
								intl={intl}
							/>
						}
						{
							canSetNotInUseStateAllEntities
							&& !!onChangeStateCallback
							&& (
								(
									!sameStateAllEntities
									&& node.data[propertyStateName] != QUESTION_STATE.NOT_USED
								)
								|| (
									sameStateAllEntities
									&& node.data[propertyStateName] == QUESTION_STATE.NOT_USED
								)
							)
							&& <ChangeStateMenuItem
								question={node}
								newState={QUESTION_STATE.NOT_USED}
								allEntities={true}
								onChangeStateCallback={onChangeStateCallback}
								handleClose={handleClose}
								intl={intl}
							/>
						}
						{
							canSetArchiveStateAllEntities
							&& !!onChangeStateCallback
							&& (
								(
									!sameStateAllEntities
									&& node.data[propertyStateName] != QUESTION_STATE.ARCHIVED
								)
								|| (
									sameStateAllEntities
									&& node.data[propertyStateName] == QUESTION_STATE.ARCHIVED
								)
							)
							&& <ChangeStateMenuItem
								question={node}
								newState={QUESTION_STATE.ARCHIVED}
								allEntities={true}
								onChangeStateCallback={onChangeStateCallback}
								handleClose={handleClose}
								intl={intl}
							/>
						}


						{/* <RemoveMenuItem question={node} onComplete={handleClose} onRemoveCallback={onRemoveCallback} /> */}
					</MuiMenu>
				</Fragment>)
			}
		</div>
	)
}
