import React from 'react'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { makeStyles } from '@material-ui/core/styles'
import { sortSubscriberFieldSelector, sortSubscriberDirectionSelector } from '../../../redux/selectors'
import { setSubscriberSort } from '../../../redux/actions'
import { connect } from 'react-redux'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(3)
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2)
	},
	table: {
		minWidth: 750
	},
	tableWrapper: {
		overflowX: 'auto'
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1
	}
}))

const SubscriberHeader = ({ intl, isDescending, changeSort, field }) => {
	return (
		<TableRow>
			<TableCell size="small" align="left" style={{ border: 'none', padding: '6px 0px 6px 0px' }} />

			<TableCell align="left" sortDirection={false} style={{ border: 'none' }}>
				<TableSortLabel
					active={field === 'name'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('name', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="subtitle1"
						style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase',
							minWidth: '200px'
						}}
					>
						{intl.formatMessage({ id: 'app.subscribers.nameColumnLabel' })}
					</Typography>
				</TableSortLabel>
			</TableCell>
            <TableCell align="left" sortDirection={false} style={{ border: 'none' }}>
                <TableSortLabel
					active={field === 'sponsorName'}
                    direction={isDescending ? 'desc' : 'asc'}
                    onClick={(e) => changeSort('sponsorName', !isDescending)}
                    IconComponent={ExpandMoreIcon}
                >
                    <Typography
                        variant="subtitle1"
                        style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase'
						}}
                    >
						{intl.formatMessage({ id: 'app.subscribers.sponsorNameColumnLabel' })}
                    </Typography>
                </TableSortLabel>
			</TableCell>
            <TableCell align="left" sortDirection={false} style={{ border: 'none' }}>
                <TableSortLabel
					active={field === 'subscriptionExpires'}
                    direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('subscriptionExpires', !isDescending)}
                    IconComponent={ExpandMoreIcon}
                >
                    <Typography
                        variant="subtitle1"
                        style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase'
						}}
                    >
						{intl.formatMessage({ id: 'app.subscribers.subscriptionExpirationDateColumnLabel' })}
                    </Typography>
                </TableSortLabel>
            </TableCell>
            <TableCell align="left" sortDirection={false} style={{ border: 'none' }}>
                <TableSortLabel
					active={field === 'isOpenApiEnabled'}
                    direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('isOpenApiEnabled', !isDescending)}
                    IconComponent={ExpandMoreIcon}
                >
                    <Typography
                        variant="subtitle1"
                        style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase'
						}}
                    >
						{intl.formatMessage({ id: 'app.subscribers.apiColumnLabel' })}
                    </Typography>
                </TableSortLabel>
            </TableCell>
            <TableCell align='left' style={{ border: 'none' }}>
                <TableSortLabel
					active={field === 'language'}
                    direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('language', !isDescending)}
                    IconComponent={ExpandMoreIcon}
                >
                    <Typography variant='subtitle1' style={{ fontWeight: 400, fontSize: '13px', color: '#232c3b', textTransform: 'uppercase' }}>
						{intl.formatMessage({ id: 'app.general.languageLabel' })}
                    </Typography>
                </TableSortLabel>
            </TableCell>
			<TableCell align="left" style={{ border: 'none' }}>
				<TableSortLabel
					active={field === 'jurisdictionName'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('jurisdictionName', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="subtitle1"
						style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase'
						}}
					>
						{intl.formatMessage({ id: 'app.subscribers.jurisdiction' })}
					</Typography>
				</TableSortLabel>
			</TableCell>
            <TableCell align='left' style={{ border: 'none' }}>
                <TableSortLabel
                    active={field === 'isMFAEnabled'}
                    direction={isDescending ? 'desc' : 'asc'}
                    onClick={(e) => changeSort('isMFAEnabled', !isDescending)}
                    IconComponent={ExpandMoreIcon}
                >
                    <Typography variant='subtitle1' style={{ fontWeight: 400, fontSize: '13px', color: '#232c3b', textTransform: 'uppercase' }}>
                        {intl.formatMessage({ id: 'app.userProfile.teamMemberTableMember2FAStateHeader' })}
                    </Typography>
                </TableSortLabel>
            </TableCell>
			<TableCell align="left" style={{ border: 'none' }}>
				<TableSortLabel
					active={field === 'teamName'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('teamName', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="subtitle1"
						style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase'
						}}
					>
						{intl.formatMessage({ id: 'app.subscribers.teamLabel' })}
					</Typography>
				</TableSortLabel>
			</TableCell>
			<TableCell align="left" style={{ border: 'none' }}>
				<TableSortLabel
					active={field === 'circleCount'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('circleCount', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="subtitle1"
						style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase'
						}}
					>
						{intl.formatMessage({ id: 'app.subscribers.circleCountLabel' })}
					</Typography>
				</TableSortLabel>
			</TableCell>

			<TableCell align="left" style={{ border: 'none' }}>
				<TableSortLabel
					active={field === 'lastLoginSeconds'}
					direction={isDescending ? 'desc' : 'asc'}
					onClick={(e) => changeSort('lastLoginSeconds', !isDescending)}
					IconComponent={ExpandMoreIcon}
				>
					<Typography
						variant="subtitle1"
						style={{
							fontWeight: 400,
							letterSpacing: '2.08px',
							fontSize: '13px',
							lineHeight: '16px',
							color: '#011020',
							textTransform: 'uppercase'
						}}
					>
						{intl.formatMessage({ id: 'app.subscribers.lastLoginLabel' })}
					</Typography>
				</TableSortLabel>
			</TableCell>
			<TableCell align="left" style={{ width: '10px', padding: '6px 0px 6px 0px', border: 'none' }} />
		</TableRow>
	)
}

const mapStateToProps = (state, ownProps) => ({
	isDescending: sortSubscriberDirectionSelector(state),
	field: sortSubscriberFieldSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	changeSort: (field, direction) => dispatch(setSubscriberSort(field, direction))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(SubscriberHeader)
