import React, {  } from 'react'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import EmailTemplates from '../../../components/emailTemplates/EmailTemplates'
import { useIntl } from 'react-intl'

const EmailTemplatesSection = () => {
	const intl = useIntl()
	return (
		<Grid item>
			<Typography variant="h1"
				style={{
					marginBottom: '47px'
				}}
			>
				{intl.formatMessage({id: 'app.templates.emailTemplatesSectionHeader'})}
			</Typography>
				
			<EmailTemplates />
		</Grid>
	)
}

export default EmailTemplatesSection
