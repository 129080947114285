import React, {useEffect} from 'react'
import {withStyles} from "@material-ui/core"
import {injectIntl} from "react-intl"
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Typography, Grid, Avatar } from "@material-ui/core"
import { Link } from 'react-router-dom'
import AddMemberButton from "../components/AddMemberButton"
import LabelPaper from "../../../components/LabelPaper"
import SubscriptionTableEmpty from "../components/SubscriptionTableEmpty"
import SubscriptionTable from "./SubscriptionTable"
import { subscriptionChangesSelector } from "../../../redux/selectors"
import { fetchSubscriptionChanges } from "../../../redux/actions"


const styles = () => ({
    button: {
        '&:hover': {
            backgroundColor: 'transparent'
        },
    },
    gradient: {
        background:
            'linear-gradient(45deg,  #d4e1f7 0%,#d4e1f7 50%,#ffffff 50%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */',
        filter:
            "progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4e1f7', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */"
    }
})

const SubscriptionSponsorInner = ({ user, loadSubscriptionChanges, subscriptionChanges, classes, intl }) => {

    return (
        <Grid container style={{ paddingTop: '32px'}}>
            { user && user.sponsorName && 
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                style={{
                                    textAlign: 'left',
                                    fontWeight: '700',
                                    fontSize: '17px',
                                    lineHeight: '22px',
                                    letterSpacing: '0.51px'
                                }}
                            >
                                {intl.formatMessage({ id: 'app.userProfile.subscriptionSponsor.information' })}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '2em' }}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="Subtitle2"
                                        style={{
                                            textAlign: 'left',
                                            fontWeight: '700',
                                            fontSize: '17px',
                                            lineHeight: '22px',
                                            letterSpacing: '0.51px'
                                        }}
                                    >
                                        {user && user.sponsorName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        variant='subtitle2'
                                        style={{
                                            opacity: 0.38,
                                            fontSize: '13px',
                                            lineHeight: '16px',
                                            letterSpacing: '2.08px',
                                            marginTop: '3px'
                                        }}>
                                        Sponsor
                                </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
}
            { user && user.sponsorCircleId &&
            <Grid item xs={6}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography
                            variant="Subtitle2"
                            style={{
                                textAlign: 'left',
                                fontWeight: '700',
                                fontSize: '17px',
                                lineHeight: '22px',
                                letterSpacing: '0.51px'
                            }}
                        >
                            {intl.formatMessage({ id: 'app.userProfile.subscriptionSponsor.circleInformation' })}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{marginTop: '2em'}}>
                        <Grid container>
                            <Grid item xs={3}>
                                <Avatar alt="" src={user.sponsorImageUrl ? user.sponsorImageUrl : '/avatar.png'} style={{ margin: '10px', width: '90px', height: '90px', alignSelf: 'center' }} />
                            </Grid>
                            <Grid item xs={9} style={{ padding: '20px 0' }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="Subtitle2"
                                            style={{
                                                textAlign: 'left',
                                                fontWeight: '600',
                                                fontSize: '14px',
                                                lineHeight: '22px',
                                                letterSpacing: '0.51px'
                                            }}
                                        >
                                            {user.sponsorCircleName}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Link to={`/circles/${user.sponsorCircleId}`} style={{ textDecoration: 'none', color: 'rgb(19, 104, 236)' }}>
                                            <Typography variant="body2">
                                                {intl.formatMessage({ id: 'app.circle.details' })}
                                            </Typography>
                                        </Link>
                                    </Grid>
                                    {user.protocolAlias && <Grid item xs={12} style={{ marginTop: '2em' }}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="Subtitle2"
                                                    style={{
                                                        textAlign: 'left',
                                                        fontWeight: '700',
                                                        fontSize: '17px',
                                                        lineHeight: '22px',
                                                        letterSpacing: '0.51px'
                                                    }}
                                                >
                                                    {user.protocolAlias}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant='subtitle2'
                                                    style={{
                                                        opacity: 0.38,
                                                        fontSize: '13px',
                                                        lineHeight: '16px',
                                                        letterSpacing: '2.08px',
                                                        marginTop: '3px'
                                                    }}>
                                                    {intl.formatMessage({ id: 'app.protocols.editProtocolAliasPlaceholder' })}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            }
        </Grid>
    )
}

const mapStateToProps = (state, ownProps) => ({
    subscriptionChanges: subscriptionChangesSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    loadSubscriptionChanges: (userId, count, offset) => dispatch(fetchSubscriptionChanges(userId, count, offset)),
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withStyles(styles))

export default enhance(SubscriptionSponsorInner)