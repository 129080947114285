import React from 'react'
import { compose } from 'recompose'
import { useTheme, withStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import { showModal } from '../../../redux/actions'
import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Add from '@material-ui/icons/Add'
import TermsUpdateModal from './TermsUpdateModal'
import { useDispatch } from 'react-redux'

const styles = (theme) => ({
})

const AddTermsButton = ({ terms, classes, intl }) => {
	const theme = useTheme()
	const dispatch = useDispatch()
	const onAddClick = () => {
		dispatch(showModal(TermsUpdateModal, { terms: terms, mode: 0 }))
	}

	return (
        <Button onClick={onAddClick}
            variant="text">
				{terms ? 'EDIT TERMS' : 'ADD TERMS'}
			<Add style={{ height: '24px', marginLeft: '5px', paddingRight: 30 }} />
		</Button>
	)
}

const enhance = compose(injectIntl, withStyles(styles))

export default enhance(AddTermsButton)
