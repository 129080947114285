import React, { useState } from 'react'
import { change, Field, reduxForm } from 'redux-form'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import InputBase from '@material-ui/core/InputBase'
import FormHelperText from '@material-ui/core/FormHelperText'
import MaskedInput from 'react-text-mask'
import LocalizedDatePicker from './LocalizedDatePicker'

function TextMaskCustom(props) {
	const { inputRef, hasFocus, ...other } = props

	return (
		<div>
			<MaskedInput
				{...other}
				ref={(ref) => {
					inputRef(ref ? ref.inputElement : null)
				}}
				mask={[ /\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/ ]}
				placeholderChar={'\u2000'}
				showMask={hasFocus}
				guide={false}
				style={{ marginTop: '22px', color: 'white', marginLeft: '10px' }}
			/>
		</div>
	)
}

const renderTextField = ({
	classes,
	label,
	input,
	meta: { touched, invalid, error, form, dispatch },
	inputLabelProps,
	inputProps,
	style,
	design,
	fullWidth,
	margin,
	variant,
	type,
	maxLength,
	setHasFocus,
	hasFocus,
	disabled,
	openToYear,
	disablePast,
	disableFuture,
	minDate,
	defaultDate,
	...custom
}) => {

	const handleChange = (date) => {
		dispatch(change(form, input.name, date));
	}

	return (
		<div className="global-input-wrapper">
			<FormControl
				error={touched && invalid}
				fullWidth
			>
				<LocalizedDatePicker
					label={label}
					onDateChange={handleChange}
					disabled={disabled}
					error={error}
					defaultDate={defaultDate || input && input.value}
					design={design}
					inputProps={{
						...inputProps,
						style: {
						  color: disabled ? '#6C737B' : (design === 'light' ? '#000' : 'white'),
						  padding: label ? '27px 12px 10px' : '20px 12px 17px',
						  ...(inputProps?.style || {}),
						}
					}}
					openToYear={openToYear}
					touched={touched}
					disablePast={disablePast}
					disableFuture={disableFuture}
					minDate={minDate}
				/>
			</FormControl>
		</div>
	)
}

const FormDateField = ({
	classes,
	label,
	name,
	inputLabelProps,
	inputProps,
	style,
	design = "light", // "light" design is default. Can be also "dark" for modal windows.
	fullWidth,
	margin,
	variant,
	type,
	validators,
	maxLength = 10,
	disabled,
	openToYear,
	disablePast,
	disableFuture,
	minDate,
	defaultDate
}) => {
	const [ hasFocus, setHasFocus ] = useState(defaultDate)
	return (
		<Field
			name={name}
			hasFocus={hasFocus}
			setHasFocus={setHasFocus}
			component={renderTextField}
			label={label}
			inputLabelProps={inputLabelProps}
			inputProps={inputProps}
			style={style}
			design={design} 
			fullWidth={fullWidth}
			margin={margin}
			variant={variant}
			type={type}
			validate={validators}
			maxLength={maxLength}
			classes={classes}
			disabled={disabled}
			openToYear={openToYear}
			defaultDate={defaultDate}
			disablePast={disablePast}
			disableFuture={disableFuture}
			minDate={minDate}
		/>
	)
}

export default FormDateField;
