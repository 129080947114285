import React, { Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import { compose } from 'recompose'
import { reduxForm } from 'redux-form'
import { injectIntl } from 'react-intl'
import Paper from '@material-ui/core/Paper'
import BuilderContainer from './BuilderContainer'

const Container = ({
	headerTitle,
	list,
	collection,
	onUpdateCallback,
	onStartUpdatingQuestionCallback,
	suppressNonDiscreteQuestions = false,
	showScoreEntry,
	languageId,
	ignoreBundles
}) => {
	return (
		<Paper style={{ minHeight: '100vh', padding: '2% 3% 2% 3%' }}>
			<Grid container direction="column">
				<Fragment>
					<Grid item style={{ marginTop: '10px' }}>
						<BuilderContainer
							headerTitle={headerTitle}
							list={list}
							collection={collection}
							onUpdateCallback={onUpdateCallback}
							onStartUpdatingQuestionCallback={onStartUpdatingQuestionCallback}
							suppressNonDiscreteQuestions={suppressNonDiscreteQuestions}
							showScoreEntry={showScoreEntry}
							languageId={languageId}
							ignoreBundles={ignoreBundles}
						/>
					</Grid>
				</Fragment>
			</Grid>
		</Paper>
	)
}

const enhance = compose(
	reduxForm({
		form: 'survey'
	}),
	injectIntl
)

export default enhance(Container)
