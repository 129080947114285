import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useTheme from '@material-ui/core/styles/useTheme'
import { useIntl } from 'react-intl'
import { reduxForm } from 'redux-form'
import { compose, shallowEqual } from 'recompose'
import Button from '@material-ui/core/Button'
import { clearCircleInvitationLink, generateCircleInvitationLink } from '../../../redux/actions'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Checkbox, FormControlLabel, InputAdornment, TextField } from '@material-ui/core'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { circleInvitationLinkSelector } from '../../../redux/selectors'

const CreateInvitationLinkModal = ({ circle, invitationLink, generateLink }) => {
	const theme = useTheme()
	const intl = useIntl()
	const dispatch = useDispatch()

	const [isSponsored, setIsSponsored] = useState(false)
    const [errorText, setErrorText] = useState()

	const onClick = () => {
		setErrorText(null)
		let today = new Date().toLocaleDateString('en-US')
		if (isSponsored && (!circle.subscriptionExpirationDate || new Date(circle.subscriptionExpirationDate) < new Date(today))) {
			setErrorText(intl.formatMessage({ id: 'app.circle.startSponsoringMemberWrongDate' }))
			return
		}
		generateLink(circle.id, isSponsored)
	}

	const handleSponsoredChange = (e) => {
		dispatch(clearCircleInvitationLink())
		setErrorText(null)
		if (e && e.target && e.target.checked === true) {
			setIsSponsored(true)
		} else {
			setIsSponsored(false)
		}
	}

	const copyLink = () => {
		if (!invitationLink) {
			return
		}

		navigator.clipboard.writeText(invitationLink).then(
			function () {
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.circles.generateLinkCopiedToClipboard', isSuccess: true }
				})
			},
			function () {
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.circles.generateLinkCouldntCopyToClipboard', isSuccess: false }
				})
			}
		)
	}

	return (
		<form>
			<Grid
				container
				direction="column"
				justify="flex-start"
				alignItems="center"
				style={{ width: '75rem', padding: '4rem', margin: 'auto', backgroundColor: theme.palette.secondary.main }}
			>
				<Grid item style={{ alignSelf: 'flex-start', display: 'flex', justifyContent: 'center', width: '100%' }}>
					<Typography
						variant="h2"
						style={{
							color: '#fff',
							marginBottom: '25px',
							fontWeight: '400'
						}}
						align="left"
					>
						{intl.formatMessage({ id: 'app.circles.generateInvitationLink' })}
					</Typography>
				</Grid>
				<Grid item style={{ alignSelf: 'flex-start', width: '100%', color: 'white', backgroundColor: 'rgb(36, 48, 65)' }}>
					<TextField
						data-testid="invitation-link-field"
						id="invitation-link-field"
						variant="filled"
						// disabled={true}
						onKeyDown={(event) => {
							event.preventDefault();
						}}
						fullWidth
						inputProps={{
							style: { paddingTop: '19px', paddingBottom: '18px' },
						}}
						InputProps={{							
							endAdornment: (
								<InputAdornment data-testid="invitation-link-field-inner" position="start">
									<FileCopyOutlinedIcon data-testid="invitation-link-field-icon" style={{ width: 'auto', cursor: 'pointer', padding: '0 10px 0 7px', color: 'white', fontSize: '22px', transform: 'translateY(-18px) translateX(10px)' }} onClick={() => { copyLink() }} />
								</InputAdornment>
							)
						}}
						value={invitationLink}
					/>
				</Grid>
				{/*<Grid item style={{ marginTop: '20px', width: '100%', textAlign: 'center' }}> 
					<FormControlLabel
						labelPlacement="end"
						control={
							<Checkbox
								onChange={handleSponsoredChange}
								value="sponsored"
								color="secondary"
							/>
						}
						label={
							<Typography
								variant="h4"
								style={{
									color: 'white'
								}}
							>
								{intl.formatMessage({ id: 'app.circle.sponsorUser' })}
							</Typography>
						}
					/>
				</Grid>*/}
				<Grid item>
					<Button
						label={intl.formatMessage({ id: 'app.general.generateButtonLabel' })}
						style={{
							width: '267px',
							marginTop: '40px'
						}}
						onClick={onClick}
                        variant="contained"
                        color="primary"
					>
						{intl.formatMessage({ id: 'app.general.generateButtonLabel' })}
					</Button>
				</Grid>
				{errorText && (
						<Grid item style={{ paddingTop: '10px', width: '100%' }}>
							<Typography variant="caption" style={{ color: 'red' }}>
								{errorText}
							</Typography>
						</Grid>
					)}
			</Grid>
		</form>
	)
}


const mapStateToProps = (state, ownProps) => ({
	invitationLink: circleInvitationLinkSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	generateLink: (id, isSponsored) => dispatch(generateCircleInvitationLink(id, isSponsored))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: 'generateInvitationLink',
		touchOnChange: true,
		touchOnBlur: true
	})
)

export default enhance(CreateInvitationLinkModal)
