import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  inputRoot: {
    color: 'black',
  },
  inputLabel: {
    marginLeft: '0px',
    color: 'black',
  },
});

export default ({ label, initialValue, onChangeCallback, type, maxLength, min, max, pattern, placeholder }) => {
  const classes = useStyles();
  const [value, setValue] = useState(initialValue || '');

  const onChange = (e) => {
    setValue(e.target.value);
    onChangeCallback && onChangeCallback(e.target.value);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <TextField
      id="filled-textfield-currency"
      label={label}
      variant="filled"
      fullWidth
      type={type}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      InputLabelProps={{
        classes: {
          root: classes.inputLabel,
        },
      }}
      InputProps={{
        classes: {
          root: classes.inputRoot,
        },
      }}
      inputProps={{
        maxLength: maxLength,
        min: min,
        max: max,
        pattern: pattern,
      }}
    />
  );
};
