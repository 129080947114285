import React, { useEffect } from 'react'
import FormSelect from '../../components/FormSelect'
import { selectedInvestigatorEntityAuditSelector, selectedProtocolVersionEntityAuditSelector } from '../../redux/selectors'
import { setSelectedProtocolVersionForEntityAudit, setSelectedInvestigatorForEntityAudit } from '../../redux/actions'
import {fetchInvestigatorsForProtocolVersionEntityAudit} from '../../redux/modules/observationalProtocol/actions'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { required } from '../../utilities/validators'


const requiredValidator = required

const ProtocolVersionSelectorForEntityAudit = ({ 
	label, 
	floatLabel,
	name,
	data, 
	selectedProtocolVersionEntityAudit,
	setProtocolVersion,
	getInvestigatorsForProtocolVersion,
}) => {

	useEffect(() => {
		if(selectedProtocolVersionEntityAudit) {
			getInvestigatorsForProtocolVersion(selectedProtocolVersionEntityAudit)
		}
	}, [selectedProtocolVersionEntityAudit ])

	const handleChange = (e) => {
		setProtocolVersion(e.target.value)
	}

	return (
		<div className="global-input-wrapper">
			<FormSelect
				data={
					data.map((r) => {
						return { value: r.id, name: r.name }
					})}
				value={selectedProtocolVersionEntityAudit}
				style={{ color: 'white' }}
				label={label}
				floatLabel={floatLabel}
				fullWidth
				name={name}
				onChange={handleChange}
				validators={[ requiredValidator ]}
			/>
		</div>

	)
}

const mapStateToProps = (state, ownProps) => ({
	selectedProtocolVersionEntityAudit: selectedProtocolVersionEntityAuditSelector(state),
	selectedInvestigatorEntityAudit: selectedInvestigatorEntityAuditSelector(state)
	})


const mapDispatchToProps = (dispatch, ownProps) => ({
	setProtocolVersion: (id) => dispatch(setSelectedProtocolVersionForEntityAudit(id)),
	getInvestigatorsForProtocolVersion: (id) => dispatch(fetchInvestigatorsForProtocolVersionEntityAudit(id)),
	setSelectedInvestigator: (id) => dispatch(setSelectedInvestigatorForEntityAudit(id)),
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl
)

export default enhance(ProtocolVersionSelectorForEntityAudit)
