import * as responseRate from '../../../apis/responseRate'
import { createSelector } from 'reselect'
import { NaturePeopleOutlined } from '@material-ui/icons'

const COMPLIANCE_TYPE = {
	CASES: 1,
	PATIENTS: 2,
	BASELINE: 3
}

const SURVEY_COMPLIANCE_TYPE = {
	WEEKLY: 1,
	CUMULATIVE: 2
}

const FETCH_RESPONSE_RATE_REQUESTED = 'FETCH_RESPONSE_RATE_REQUESTED'
const FETCH_RESPONSE_RATE_SUCCESS = 'FETCH_RESPONSE_RATE_SUCCESS'
const FETCH_RESPONSE_RATE_FAILED = 'FETCH_RESPONSE_RATE_FAILED'

export const fetchResponseRate = (circleId) => (dispatch) => {
	dispatch({
		type: FETCH_RESPONSE_RATE_REQUESTED, data: {
			sgFilter: null,
			sgFilters: null,
			sgCharts: null,
			sgChart: null,
			circleCasesRate: null
		}
	})
	responseRate.fetchResponseRate(circleId).then((response) => {
		if (response && response.isSuccessful) {
			let sgFilter = 0
			let sgFilters = new Array(0)
			let sgSchema = response.schema
			let sgChart = null

			if (!!sgSchema?.length) {
				const population = ["sg.allRate"]
				const originalPopulation = population.reduce((a, v) => ({ ...a, [v]: v}), {})

				sgSchema = sgSchema.map((item, index) => {
					let resultItem = {
						...item,
						coordinates: item.coordinates.map(pointItem => {
							let populationPointItem = {
								xAxis: pointItem.xAxis,
								labelTreatmentPhaseId: pointItem.labelTreatmentPhaseId,
								labelUnit: pointItem.labelUnit,
								labelValue: pointItem.labelValue,
								"sg.allRate": pointItem.allRate,
								allCases: pointItem.allCases,
								originalPopulation: originalPopulation,
							}

							return populationPointItem
						}),
						population: population,
						expectedPopulation: null,
						cases: ["allCases"],
						restricted: [],
						colors: {
							"sg.allRate": "black"
						}
					}

					sgFilters.push({ option: resultItem.scoringGroupName + resultItem.scoringGroupId, label: resultItem.scoringGroupName })

					return resultItem
				})
			}

			sgFilters = sgFilters.sort((a, b) => a.label > b.label ? 1 : a.label < b.label ? -1 : 0)
			if (sgFilters.length > 0) {
				sgFilter = sgFilters[0]
				sgChart = sgSchema.find(x => x.scoringGroupName + x.scoringGroupId === sgFilter.option)
			}

			return dispatch({
				type: FETCH_RESPONSE_RATE_SUCCESS,
				data: {
					sgFilter: sgFilter,
					sgFilters: sgFilters,
					sgCharts: sgSchema,
					sgChart: sgChart
				}
			})
		} else {
			return dispatch({ type: FETCH_RESPONSE_RATE_FAILED, data: {} })
		}
	})
}

const SET_SG_CHART_FILTER_REQUESTED = 'SET_SG_CHART_FILTER_REQUESTED'
const SET_SG_CHART_FILTER_SUCCESS = 'SET_SG_CHART_FILTER_SUCCESS'
const SET_SG_CHART_FILTER_FAILED = 'SET_SG_CHART_FILTER_FAILED'
export const setSGChartFilter = (filter) => (dispatch) => {
	dispatch({ type: SET_SG_CHART_FILTER_REQUESTED, data: {} })

	if (!!filter) {
		dispatch({ type: SET_SG_CHART_FILTER_SUCCESS, data: { sgFilter: filter } })
	} else {
		return dispatch({ type: SET_SG_CHART_FILTER_FAILED, data: {} })
	}

}

const FETCH_CIRCLE_CASES_RATE_REQUESTED = 'FETCH_CIRCLE_CASES_RATE_REQUESTED'
const FETCH_CIRCLE_CASES_RATE_SUCCESS = 'FETCH_CIRCLE_CASES_RATE_SUCCESS'
const FETCH_CIRCLE_CASES_RATE_FAILED = 'FETCH_CIRCLE_CASES_RATE_FAILED'

export const fetchCircleCasesRate = (circleId) => (dispatch) => {
	dispatch({
		type: FETCH_CIRCLE_CASES_RATE_REQUESTED, data: {
			sgFilter: null,
			sgFilters: null,
			sgCharts: null,
			sgChart: null,
			circleCasesRate: null
		}
	})
	responseRate.fetchCircleCasesRate(circleId).then((response) => {
		handleComplianceRateResponse(dispatch, response)
	})
}

const FETCH_CUMULATIVE_SURVEY_COMPLIANCE_RATE_REQUESTED = 'FETCH_CUMULATIVE_SURVEY_COMPLIANCE_RATE_REQUESTED'
const FETCH_CUMULATIVE_SURVEY_COMPLIANCE_RATE_SUCCESS = 'FETCH_CUMULATIVE_SURVEY_COMPLIANCE_RATE_SUCCESS'
const FETCH_CUMULATIVE_SURVEY_COMPLIANCE_RATE_FAILED = 'FETCH_CUMULATIVE_SURVEY_COMPLIANCE_RATE_FAILED'

export const fetchCumulativeSurveyComplianceRate = (circleId, userId) => (dispatch, getState) => {
	dispatch({
		type: FETCH_CUMULATIVE_SURVEY_COMPLIANCE_RATE_REQUESTED, data: {}
	})

	let state = getState()

	let filters = {
		onboarding: state.chart.onboarding,
		periodFrom: state.chart.periodFrom,
		periodTo: state.chart.periodTo,
		surveyCompliance: state.chart.surveyCompliance,
		compliantSurveys: state.chart.compliantSurveys,
		filterInvestigators: state.chart.filterInvestigators,
		investigators: state.chart.includedInvestigators,
		filterFunders: state.chart.filterFunders,
		funders: state.chart.includedFunders
	}

	responseRate.fetchSurveyCumulativeComplianceRate(circleId, userId, filters).then((response) => {
		if (response && response.isSuccessful) {
			let item = response.schema[0]
			let schemaInfos = processComplianceSchema(item)
			let schemaResult = compileComplianceSchema(item, schemaInfos)

			return dispatch({ type: FETCH_CUMULATIVE_SURVEY_COMPLIANCE_RATE_SUCCESS, data: schemaResult })
		} else {
			return dispatch({ type: FETCH_CUMULATIVE_SURVEY_COMPLIANCE_RATE_FAILED, data: {} })
		}
	})
}

const handleComplianceRateResponse = (dispatch, response) => {
	if (response && response.isSuccessful) {
		let complianceFilter = 0
		let complianceFilters = new Array(0)
		let complianceSchema = response.schema
		let complianceChart = null

		if (complianceSchema.length > 0) {


			complianceSchema = complianceSchema.map(item => {
				let schemaInfos = processComplianceSchema(item)

				complianceFilters.push({
					option: item.complianceType,
					subOption: item.baselineComplianceType ?? null
				})

				const schemaResult = compileComplianceSchema(item, schemaInfos)

				return schemaResult
			})
		}

		if (complianceFilters.length > 0) {
			complianceFilter = complianceFilters[0]
			complianceChart = complianceSchema.find(x => x.complianceType === complianceFilter.option)
		}

		return dispatch({
			type: FETCH_CIRCLE_CASES_RATE_SUCCESS,
			data: {
				complianceFilter: complianceFilter,
				complianceFilters: complianceFilters,
				complianceCharts: complianceSchema,
				complianceChart: complianceChart
			}
		})
	} else {
		return dispatch({ type: FETCH_CIRCLE_CASES_RATE_FAILED, data: {} })
	}
}

function processComplianceSchema(item) {
	const mainKey = item.complianceType === COMPLIANCE_TYPE.CASES
		? "cases"
		: item.complianceType === COMPLIANCE_TYPE.PATIENTS
			? "patients"
			: item.complianceType === COMPLIANCE_TYPE.BASELINE
				? "baseline"
				: "default"

	let schemaInfos = []
	if (item.complianceType === COMPLIANCE_TYPE.BASELINE) {
		schemaInfos = item.schemaInfo.map(info => {
			const allRateKey = `${mainKey}.${info.delegateId}.allRate`
			const allExpectedRateKey = `${mainKey}.${info.delegateId}.allExpectedRate`

			let colors = {}
			colors[allRateKey] = info.delegateId === 0
				? "#000000"
				: info.delegateId === 1
					? "#5da510"
					: "#c197cb"

			return {
				delegateId: info.delegateId,
				hasRestrictions: false,
				allRateKey: allRateKey,
				allExpectedRateKey: allExpectedRateKey,
				allRate: info.allRate,
				schemaKey: "delegateId",
				colors: colors
			}
		})
	}
	else {
		const allRateKey = `${mainKey}.allRate`
		const allExpectedRateKey = `${mainKey}.allExpectedRate`

		let colors = {}
		colors[allRateKey] = "#000000"

		schemaInfos.push({
			allRateKey: allRateKey,
			allExpectedRateKey: allExpectedRateKey,
			allRate: item.allCases,
			schemaKey: null,
			colors: colors
		})
	}
	return schemaInfos
}

const compileComplianceSchema = (item, schemaInfos) => {
	let coordinates = []
	let population = []
	let restricted = []
	let colors = {}
	let resultPointItem = null

	let subIndex = 0

	item.coordinates.map(pointItem => {
		if (
			!!resultPointItem
			&& resultPointItem.xAxis !== pointItem.xAxis
		) {
			coordinates.push({...resultPointItem})

			resultPointItem = null
			subIndex = 0
		}

		if (!resultPointItem) {
			resultPointItem = {
				xAxis: pointItem.xAxis,
				labelTreatmentPhaseId: pointItem.treatmentPhaseId ?? null,
				labelUnit: pointItem.labelUnit ?? null,
				labelValue: pointItem.labelValue,
				population: [],
				originalPopulation: {},
				expectedPopulation: {},
			}
		}

		let schemaInfo = schemaInfos.find(schemaInfo =>
				!schemaInfo.schemaKey
				|| (
					!!schemaInfo.schemaKey
					&& pointItem[schemaInfo.schemaKey] === schemaInfo[schemaInfo.schemaKey]
				)
			)

		if (!!schemaInfo) {
			const allRateKey = `${schemaInfo.allRateKey}__${subIndex}`
			const allExpectedRateKey = `${schemaInfo.allExpectedRateKey}__${subIndex}`
			
			resultPointItem[allRateKey] = pointItem.allRate
			resultPointItem[allExpectedRateKey] = pointItem.allExpectedRate

			resultPointItem.originalPopulation[allRateKey] = schemaInfo.allRateKey
			resultPointItem.originalPopulation[allExpectedRateKey] = schemaInfo.allExpectedRateKey

			resultPointItem.population.push(allRateKey)

			resultPointItem.expectedPopulation[allRateKey] = allExpectedRateKey
		}

		subIndex++
	})

	if (!!resultPointItem) {
		coordinates.push(resultPointItem)
	}

	let totalRates = {}
	let expectedPopulation = {}
	let delegates = {}

	schemaInfos.map(schemaInfo => {
		population.push(schemaInfo.allRateKey)

		if (item.hasRestrictions) {
			restricted.push(schemaInfo.allRateKey)
		}

		expectedPopulation[schemaInfo.allRateKey] = schemaInfo.allExpectedRateKey

		colors[schemaInfo.allRateKey] = schemaInfo.colors[schemaInfo.allRateKey]

		totalRates[schemaInfo.allRateKey] = schemaInfo.allRate

		if (!Number.isNaN(parseInt(schemaInfo.delegateId))) {
			delegates[schemaInfo.delegateId] = [
				schemaInfo.allRateKey
			]
		}
	})

	let result = {
		complianceType: item.complianceType,
		baselineComplianceType: item.baselineComplianceType ?? null,
		coordinates: coordinates,
		population: population,
		expectedPopulation: expectedPopulation,
		cases: [...population],
		restricted: restricted,
		colors: colors,
		delegates: delegates,
		minScore: item.minScore,
		maxScore: item.maxScore,
		...totalRates
	}

	return result
}

const SET_COMPLIANCE_CHART_FILTER_REQUESTED = 'SET_COMPLIANCE_CHART_FILTER_REQUESTED'
const SET_COMPLIANCE_CHART_FILTER_SUCCESS = 'SET_COMPLIANCE_CHART_FILTER_SUCCESS'
const SET_COMPLIANCE_CHART_FILTER_FAILED = 'SET_COMPLIANCE_CHART_FILTER_FAILED'
export const setComplianceChartFilter = (filter) => (dispatch) => {
	dispatch({ type: SET_COMPLIANCE_CHART_FILTER_REQUESTED, data: {} })

	if (!!filter) {
		dispatch({ type: SET_COMPLIANCE_CHART_FILTER_SUCCESS, data: { complianceFilter: filter } })
	} else {
		return dispatch({ type: SET_COMPLIANCE_CHART_FILTER_FAILED, data: {} })
	}

}

const RESET_SURVEY_COMPLIANCE_FILTER_REQUESTED = 'RESET_SURVEY_COMPLIANCE_FILTER_REQUESTED'
export const resetSurveyComplianceFilters = (filter) => (dispatch) => {
	dispatch({ type: RESET_SURVEY_COMPLIANCE_FILTER_REQUESTED, data: filter })
}

const SET_SHOW_COMPLIANCE_FILTERS_REQUESTED = 'SET_SHOW_COMPLIANCE_FILTERS_REQUESTED'
export const setShowComplianceFilters = (value) => (dispatch) => {
	dispatch({ type: SET_SHOW_COMPLIANCE_FILTERS_REQUESTED, data: value })
}

const SET_ONBOARDING_FILTER_REQUESTED = 'SET_ONBOARDING_FILTER_REQUESTED'
export const setOnboardingFilter = (value) => (dispatch) => {
	dispatch({ type: SET_ONBOARDING_FILTER_REQUESTED, data: value })
}

const SET_PERIOD_FROM_FILTER_REQUESTED = 'SET_PERIOD_FROM_FILTER_REQUESTED'
export const setPeriodFromFilter = (value) => (dispatch) => {
	dispatch({ type: SET_PERIOD_FROM_FILTER_REQUESTED, data: value })
}

const SET_PERIOD_TO_FILTER_REQUESTED = 'SET_PERIOD_TO_FILTER_REQUESTED'
export const setPeriodToFilter = (value) => (dispatch) => {
	dispatch({ type: SET_PERIOD_TO_FILTER_REQUESTED, data: value })
}

const SET_SURVEY_COMPLIANCE_FILTER_REQUESTED = 'SET_SURVEY_COMPLIANCE_FILTER_REQUESTED'
export const setSurveyComplianceFilter = (value) => (dispatch) => {
	dispatch({ type: SET_SURVEY_COMPLIANCE_FILTER_REQUESTED, data: value })
}

const SET_COMPLIANT_SURVEYS_FILTER_REQUESTED = 'SET_COMPLIANT_SURVEYS_FILTER_REQUESTED'
export const setCompliantSurveysFilter = (value) => (dispatch) => {
	dispatch({ type: SET_COMPLIANT_SURVEYS_FILTER_REQUESTED, data: value })
}

const SET_FILTER_INVESTIGATORS_REQUESTED = 'SET_FILTER_INVESTIGATORS_REQUESTED'
export const setFilterInvestigators = (value) => (dispatch) => {
	dispatch({ type: SET_FILTER_INVESTIGATORS_REQUESTED, data: value })
}

const SET_INCLUDED_INVESTIGATORS_REQUESTED = 'SET_INCLUDED_INVESTIGATORS_REQUESTED'
export const setIncludedInvestigators = (value) => (dispatch) => {
	dispatch({ type: SET_INCLUDED_INVESTIGATORS_REQUESTED, data: value })
}

const SET_FILTER_FUNDERS_REQUESTED = 'SET_FILTER_FUNDERS_REQUESTED'
export const setFilterFunders = (value) => (dispatch) => {
	dispatch({ type: SET_FILTER_FUNDERS_REQUESTED, data: value })
}

const SET_INCLUDED_FUNDERS_REQUESTED = 'SET_INCLUDED_FUNDERS_REQUESTED'
export const setIncludedFunders = (value) => (dispatch) => {
	dispatch({ type: SET_INCLUDED_FUNDERS_REQUESTED, data: value })
}

const initial = {
	sgCharts: [],
	sgFilter: 0,
	sgFilters: [],
	sgChart: null,
	complianceCharts: [],
	complianceFilter: 0,
	complianceFilters: [],
	complianceChart: null,
	isLoadingCircleAnalytics: true,
	showComplianceFilters: false,
	onboarding: 0,
	periodFrom: null,
	periodTo: null,
	surveyCompliance: 0,
	compliantSurveys: [],
	filterInvestigators: false,
	includedInvestigators: [],
	filterFunders: false,
	includedFunders: []
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_RESPONSE_RATE_SUCCESS:
			return {
				...state,
				sgCharts: action.data.sgCharts,
				sgFilters: action.data.sgFilters,
				sgFilter: action.data.sgFilter,
				sgChart: action.data.sgChart,
				isLoadingCircleAnalytics: false
			}
		case FETCH_RESPONSE_RATE_REQUESTED:
			return { 
				...state, 
				sgCharts: null, 
				sgFilters: null, 
				sgFilter: null, 
				sgChart: null,
				isLoadingCircleAnalytics: true 
			}
		case FETCH_RESPONSE_RATE_FAILED:
			return {
				...state,
				isLoadingCircleAnalytics: false
			}
		case SET_SG_CHART_FILTER_SUCCESS:
			return { ...state, sgFilter: action.data.sgFilter, sgChart: state.sgCharts.find(({ scoringGroupName, scoringGroupId }) => scoringGroupName + scoringGroupId === action.data.sgFilter.option) }
		case FETCH_CIRCLE_CASES_RATE_SUCCESS:
			return {
				...state,
				complianceCharts: action.data.complianceCharts,
				complianceFilters: action.data.complianceFilters,
				complianceFilter: action.data.complianceFilter,
				complianceChart: action.data.complianceChart,
				isLoadingCircleAnalytics: false
			}
		case FETCH_CIRCLE_CASES_RATE_REQUESTED:
			return {
				...state,
				complianceCharts: null,
				complianceFilters: null,
				complianceFilter: null,
				complianceChart: null,
				isLoadingCircleAnalytics: true
			}
		case FETCH_CUMULATIVE_SURVEY_COMPLIANCE_RATE_REQUESTED:
			return {
				...state,
				complianceCharts: state.complianceCharts.filter(item => item.complianceType !== COMPLIANCE_TYPE.BASELINE || item.baselineComplianceType !== SURVEY_COMPLIANCE_TYPE.CUMULATIVE),
				complianceChart: null
			}
		case FETCH_CUMULATIVE_SURVEY_COMPLIANCE_RATE_SUCCESS:
			return {
				...state,
				complianceCharts: [...state.complianceCharts.filter(item => item.complianceType !== COMPLIANCE_TYPE.BASELINE || item.baselineComplianceType !== SURVEY_COMPLIANCE_TYPE.CUMULATIVE), action.data],
				complianceChart: action.data
			}
		case FETCH_CIRCLE_CASES_RATE_FAILED:
			return {
				...state,
				isLoadingCircleAnalytics: false
			}
		case SET_COMPLIANCE_CHART_FILTER_SUCCESS:
			return {
				...state,
				complianceFilter: action.data.complianceFilter,
				complianceChart: state.complianceCharts.find(
					({ complianceType, baselineComplianceType }) =>
						complianceType === action.data.complianceFilter.option
						&& baselineComplianceType === action.data.complianceFilter.subOption
				)
			}
		case RESET_SURVEY_COMPLIANCE_FILTER_REQUESTED:
			return {
				...state,
				onboarding: 0,
		    	periodFrom: null,
		    	periodTo: null,
		    	surveyCompliance: 0,
		    	compliantSurveys: [],
		    	filterInvestigators: false,
		    	includedInvestigators: [],
		    	filterFunders: false,
		    	includedFunders: []
			}
		case SET_ONBOARDING_FILTER_REQUESTED:
			return  {
				...state,
				onboarding: action.data
			}
		case SET_PERIOD_FROM_FILTER_REQUESTED:
			return  {
				...state,
				periodFrom: action.data
			}
		case SET_PERIOD_TO_FILTER_REQUESTED:
			return  {
				...state,
				periodTo: action.data
			}
		case SET_SURVEY_COMPLIANCE_FILTER_REQUESTED:
			return {
				...state,
				surveyCompliance: action.data
			}
		case SET_COMPLIANT_SURVEYS_FILTER_REQUESTED:
			return {
				...state,
				compliantSurveys: action.data
			}
		case SET_FILTER_INVESTIGATORS_REQUESTED:
			return {
				...state,
				filterInvestigators: action.data
			}
		case SET_INCLUDED_INVESTIGATORS_REQUESTED:
			return {
				...state,
				includedInvestigators: action.data
			}
		case SET_FILTER_FUNDERS_REQUESTED:
			return {
				...state,
				filterFunders: action.data
			}
		case SET_INCLUDED_FUNDERS_REQUESTED:
			return {
				...state,
				includedFunders: action.data
			}
		case SET_SHOW_COMPLIANCE_FILTERS_REQUESTED:
			return {
				...state,
				showComplianceFilters: action.data
			}
		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.chart

export const sgSchemaSelector = createSelector(mainSelector, (state) => {
	if (state && state.sgCharts && state.sgCharts.length > 0)
		return state.sgCharts
	else
		return null
})

export const sgFilterSelector = createSelector(mainSelector, (state) => {
	return state && !!state.sgFilter?.option && state.sgFilter
})

export const selectedSGChartSelector = createSelector(mainSelector, (state) => {
	return state && state.sgChart
})

export const sgFiltersSelector = createSelector(mainSelector, (state) => {
	return state && state.sgFilters
})

export const sampleResultSelector = createSelector(mainSelector, (state) => {
	return [
		{
			scoringGroupId: 1,
			scoringGroupName: 'Sample Results',
			population: ['My Patients', 'All Circle Patients'],
			minScore: 75,
			maxScore: 100,
			coordinates: [
				{
					xAxis: 20190320,
					'My Patients': 30,
					'All Circle Patients': 20,
				},
				{
					xAxis: 20190321,
					'My Patients': 40,
					'All Circle Patients': 35,
				},
				{
					xAxis: 20190322,
					'My Patients': 65,
					'All Circle Patients': 55,
				},
				{
					xAxis: 20190323,
					'My Patients': 60,
					'All Circle Patients': 50,
				},
				{
					xAxis: 20190324,
					'My Patients': 65,
					'All Circle Patients': 50,
				},
				{
					xAxis: 20190325,
					'My Patients': 75,
					'All Circle Patients': 65,
				},
			],
		},
	]
})

export const selectedCompianceChartSelector = createSelector(mainSelector, (state) => {
	return state && state.complianceChart
})

export const compianceChartsSelector = createSelector(mainSelector, (state) => {
	return state && state.complianceCharts
})

export const complianceFiltersSelector = createSelector(mainSelector, (state) => {
	return state && state.complianceFilters
})

export const complianceFilterSelector = createSelector(mainSelector, (state) => {
	return state && state.complianceFilter
})

export const isLoadingCircleAnalyticsSelector = createSelector(mainSelector, (state) => {
	return state && state.isLoadingCircleAnalytics
})

export const showComplianceFiltersSelector = createSelector(mainSelector, (state) => {
	return state && state.showComplianceFilters
})

export const onboardingFilterSelector = createSelector(mainSelector, (state) => {
	return state && state.onboarding
})

export const periodFromFilterSelector = createSelector(mainSelector, (state) => {
	return state && state.periodFrom
})

export const periodToFilterSelector = createSelector(mainSelector, (state) => {
	return state && state.periodTo
})

export const surveyComplianceFilterSelector = createSelector(mainSelector, (state) => {
	return state && state.surveyCompliance
})

export const compliantSurveysFilterSelector = createSelector(mainSelector, (state) => {
	return state && state.compliantSurveys
})

export const filterInvestigatorsSelector = createSelector(mainSelector, (state) => {
	return state && state.filterInvestigators
})

export const includedInvestigatorsSelector = createSelector(mainSelector, (state) => {
	return state && state.includedInvestigators
})

export const filterFundersSelector = createSelector(mainSelector, (state) => {
	return state && state.filterFunders
})

export const includedFundersSelector = createSelector(mainSelector, (state) => {
	return state && state.includedFunders
})