import { Grid, Paper } from "@material-ui/core"
import React from "react"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { compose } from "recompose"
import EntityAuditSearchBar from "./EntityAuditSearchBar"
import EntityAuditTable from "./EntityAuditTable"


const EntityAudit = ({
    logs,
    keys,
    onSearch
}) => {
    return (
        <Paper>
            <Grid
                container
                direction="column"
                alignItems="center"
            >
                <Grid item style={{width: '100%'}}>
                    <EntityAuditSearchBar
                        logs={logs}
                        keys={keys}
                        onSearch={onSearch}
                    />
                </Grid>

                <Grid
                    item 
                    style={{
                        width: '100%',
                        paddingTop: '30px'
                    }}
                >
                    <EntityAuditTable
                        logs={logs}
                        style={{
                            maxHeight: '50rem'
                        }}
                    />
                </Grid>
            </Grid>
        </Paper>
    )
}

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = (dispatch, ownProps) => ({
})

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)

export default enhance(EntityAudit)
