import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useTheme from '@material-ui/core/styles/useTheme'
import { useIntl } from 'react-intl'
import { reduxForm } from 'redux-form'
import { compose } from 'recompose'
import { required } from '../../../utilities/validators'
import Button from '@material-ui/core/Button'
import { makeAdministratorMember } from '../../../redux/actions'
import UserSelector from '../../circle/components/UserSelector'
import { useDispatch } from 'react-redux'
const requiredValidator = required

const AssignAdminUserModal = ({ handleSubmit, isCreating, circle }) => {
	const theme = useTheme()
	const intl = useIntl()
	const dispatch = useDispatch()

	const onSubmitForm = (values) => {
		if (circle && circle.id && values && values.administratorId) {
			dispatch(makeAdministratorMember(values.administratorId, circle.id))
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmitForm)}>
			<Grid
				container
				direction="column"
				justify="flex-start"
				alignItems="center"
				style={{ width: '75rem', padding: '4rem', margin: 'auto', backgroundColor: theme.palette.secondary.main }}
			>
				<Grid item style={{ alignSelf: 'center' }}>
					<Typography
						variant="h1"
						style={{
							color: '#fff',
							marginBottom: '25px',
						}}
					>
						{intl.formatMessage({ id: 'app.circles.assignAdminHeaderText' })}
					</Typography>
				</Grid>
				<Grid item style={{ alignSelf: 'flex-start', width: '100%' }}>
					<UserSelector
						validators={[ requiredValidator ]}
						name="administratorId"
						id="administratorId"
                        subscribersOnly={true}
						label={`${intl.formatMessage({ id: 'app.circles.assignAdminPlaceholderText' })}*`}
					/>
				</Grid>

				<Grid item>
					<Button
						label={intl.formatMessage({ id: 'app.protocols.createButtonLabel' })}
						style={{
							width: '267px',
							marginTop: '40px'
						}}
						disabled={isCreating}
						type="submit"
                        variant="contained"
                        color="primary"
					>
						{intl.formatMessage({ id: 'app.circles.assignAdminButtonText' })}
					</Button>
				</Grid>
			</Grid>
		</form>
	)
}

const enhance = compose(
	reduxForm({
		form: 'assignAdministrator',
		touchOnChange: true,
		touchOnBlur: true
	})
)

export default enhance(AssignAdminUserModal)
