import React, { Fragment, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useTheme from '@material-ui/core/styles/useTheme'
import { isScoringGroupBundleUsedSelector, showArchivedBundlesSelector } from '../../../redux/selectors'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import Button from '@material-ui/core/Button'
import { changeScoringGroupBundleState, fetchIsScoringGroupBundleInUse, hideModal, removeScoringGroupBundle } from '../../../redux/actions'
import { BUNDLE_STATE } from '../../../utilities/constants'


const ChangeScoringGroupBundleStateModal = ({
    scoringGroupId,
    bundle,
    newState,
    intl,
    isScoringGroupBundleUsedByCases,
    isArchivedBundlesVisible,
    changeBundleState,
    loadScoringGroupBundleUseStatus,
    closeModal
}) => {
	const theme = useTheme()

	useEffect(
        () => {
            loadScoringGroupBundleUseStatus(scoringGroupId, bundle)
        },
        []
    )

    const getTitle = () => {
        let result = ""

        switch (newState) {
            case BUNDLE_STATE.ARCHIVED:
                result = intl.formatMessage({ id: 'app.scoringGroup.setArchivedBundleModalTitle' })
                break
            default:
                break
        }

        return result
    }

    const getWarningText = () => {
        let result = ""

        switch (newState) {
            case BUNDLE_STATE.ARCHIVED:
                result = intl.formatMessage({ id: 'app.scoringGroup.setArchivedBundleWarning' })
                break
            default:
                break
        }

        return result
    }

    const onChangeBundleState = () => {
        changeBundleState(scoringGroupId, bundle, newState, isArchivedBundlesVisible)
    }

	return (
		<Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            style={{ width: '75rem', padding: '4rem', margin: 'auto', backgroundColor: theme.palette.secondary.main }}
        >
            <Grid item style={{ alignSelf: 'center' }}>
                <Typography
                    variant="h1"
                    style={{
                        color: '#fff',
                        marginBottom: '25px',
                    }}
                >
                    {getTitle()}
                </Typography>
            </Grid>
            {
                isScoringGroupBundleUsedByCases == false
                && <Fragment>
                    <Grid item style={{ alignSelf: 'flex-start' }}>
                        <Typography
                            variant="h3"
                            style={{
                                color: '#fff',
                            }}
                            align="left"
                        >
                            {getWarningText()}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                    >
                        <Grid
                            container
                            direction='row'
                            justify='center'
                        >
                            <Grid
                                item
                            >
                                <Button
                                    style={{
                                        width: '150px',
                                        marginTop: '40px'
                                    }}
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    onClick={onChangeBundleState}
                                >
                                    {intl.formatMessage({ id: 'app.scoringGroup.changeBundleStateButtonYes' })}
                                </Button>
                            </Grid>
                            <Grid
                                item
                            >
                                <Button
                                    style={{
                                        width: '150px',
                                        marginTop: '40px'
                                    }}
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    onClick={closeModal}
                                >
                                    {intl.formatMessage({ id: 'app.scoringGroup.changeBundleStateButtonNo' })}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Fragment>
            }
            {
                !!isScoringGroupBundleUsedByCases
                && <Fragment>
                    <Grid item style={{ alignSelf: 'flex-start' }}>
                        <Typography
                            variant="h3"
                            style={{
                                color: '#fff',
                            }}
                            align="left"
                        >
                            {intl.formatMessage({ id: 'app.scoringGroup.changeBundleStateIsUsedByCases' })}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        style={{ alignSelf: 'center' }}
                    >
                        <Button
                            style={{
                                width: '150px',
                                marginTop: '40px'
                            }}
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={closeModal}
                        >
                            {intl.formatMessage({ id: 'app.scoringGroup.changeBundleStateButtonOk' })}
                        </Button>
                    </Grid>
               </Fragment>
            }
        </Grid>
	)
}

const mapStateToProps = (state, ownProps) => ({
    isScoringGroupBundleUsedByCases: isScoringGroupBundleUsedSelector(state),
    isArchivedBundlesVisible: showArchivedBundlesSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	changeBundleState: (scoringGroupId, bundle, newState, showArchivedBundles) => {
        dispatch(changeScoringGroupBundleState(scoringGroupId, bundle, newState, showArchivedBundles))
        dispatch(hideModal())
    },
    loadScoringGroupBundleUseStatus: (scoringGroupId, bundle) => dispatch(fetchIsScoringGroupBundleInUse(scoringGroupId, bundle)),
    closeModal: () => dispatch(hideModal())
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl
)

export default enhance(ChangeScoringGroupBundleStateModal)
