import * as cookies from '../../../utilities/cookies'
import * as api from '../../../apis/pathology'
import history from '../../../utilities/history'
import { createSelector } from 'reselect'
import { hideModal } from '../core'

const ADD_PATHOLOGY_REQUESTED = 'ADD_PATHOLOGY_REQUESTED'
const ADD_PATHOLOGY_SUCCESS = 'ADD_PATHOLOGY_SUCCESS'
const ADD_PATHOLOGY_FAILED = 'ADD_PATHOLOGY_FAILED'

export const addPathology = (internalName) => (dispatch) => {
	dispatch({ type: ADD_PATHOLOGY_REQUESTED, data: {} })

	api.addPathology(internalName).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({ type: ADD_PATHOLOGY_SUCCESS, data: {} })
			history.push(`/pathologies/${response.id}`)
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.pathologies.creationSuccessful`, isSuccess: true }
			})
		} else {
			dispatch({ type: ADD_PATHOLOGY_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.pathologies.creationFailed`, isSuccess: false }
			})
		}
	})
	return
}

const UPDATE_PATHOLOGY_REQUESTED = 'UPDATE_PATHOLOGY_REQUESTED'
const UPDATE_PATHOLOGY_SUCCESS = 'UPDATE_PATHOLOGY_SUCCESS'
const UPDATE_PATHOLOGY_FAILED = 'UPDATE_PATHOLOGY_FAILED'

export const updatePathology = (id, name, description, basePathologyId, languageId, internalName, imageUrl) => (
	dispatch
) => {
	dispatch({ type: UPDATE_PATHOLOGY_REQUESTED, data: {} })

	api.updatePathology(id, name, description, basePathologyId, languageId, internalName, imageUrl).then((response) => {
		if (response && response.isSuccessful) {
			if (response.pathologies && response.pathologies.length > 0) {
				let t = {
					parentPathology: response.pathologies.find((d) => (d.languageId = 1)),
					pathologies: response.pathologies
				}
				dispatch({ type: UPDATE_PATHOLOGY_SUCCESS, data: t })
			}

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.pathologies.updateSuccessful`, isSuccess: true }
			})
		} else {
			dispatch({ type: UPDATE_PATHOLOGY_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.pathologies.updateFailed`, isSuccess: false }
			})
		}
	})
	return
}

const UPDATE_PATHOLOGY_INTERNAL_NAME_REQUESTED = 'UPDATE_PATHOLOGY_INTERNAL_NAME_REQUESTED'
const UPDATE_PATHOLOGY_INTERNAL_NAME_SUCCESS = 'UPDATE_PATHOLOGY_INTERNAL_NAME_SUCCESS'
const UPDATE_PATHOLOGY_INTERNAL_NAME_FAILED = 'UPDATE_PATHOLOGY_INTERNAL_NAME_FAILED'

export const updatePathologyInternalName = (id, internalName) => (dispatch) => {
	dispatch({ type: UPDATE_PATHOLOGY_INTERNAL_NAME_REQUESTED, data: {} })

	api.updatePathologyInternalName(id, internalName).then((response) => {
		if (response && response.isSuccessful) {
			if (response.pathologies && response.pathologies.length > 0) {
				let t = {
					parentPathology: response.pathologies.find((d) => (d.languageId = 1)),
					pathologies: response.pathologies
				}
				dispatch({ type: UPDATE_PATHOLOGY_INTERNAL_NAME_SUCCESS, data: t })
				dispatch(hideModal())
			}

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.pathologies.updateSuccessful`, isSuccess: true }
			})
		} else {
			dispatch({ type: UPDATE_PATHOLOGY_INTERNAL_NAME_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.pathologies.updateFailed`, isSuccess: false }
			})
		}
	})
	return
}

const FETCH_PATHOLOGIES_REQUESTED = 'FETCH_PATHOLOGIES_REQUESTED'
const FETCH_PATHOLOGIES_SUCCESS = 'FETCH_PATHOLOGIES_SUCCESS'
const FETCH_PATHOLOGIES_FAILED = 'FETCH_PATHOLOGIES_FAILED'

export const fetchPathologies = (searchPhrase) => (dispatch) => {
	dispatch({ type: FETCH_PATHOLOGIES_REQUESTED, data: {} })

	api.fetchPathologies(searchPhrase).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({ type: FETCH_PATHOLOGIES_SUCCESS, data: response.pathologies })
		} else {
			dispatch({ type: FETCH_PATHOLOGIES_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.pathologies.loadingFailed`, isSuccess: false }
			})
		}
	})
	return
}

const FETCH_PATHOLOGY_REQUESTED = 'FETCH_PATHOLOGY_REQUESTED'
const FETCH_PATHOLOGY_SUCCESS = 'FETCH_PATHOLOGY_SUCCESS'
const FETCH_PATHOLOGY_FAILED = 'FETCH_PATHOLOGY_FAILED'

export const fetchPathology = (id) => (dispatch) => {
	dispatch({ type: FETCH_PATHOLOGY_REQUESTED, data: {} })

	api.fetchPathology(id).then((response) => {
		if (response && response.isSuccessful) {
			if (response.pathologies && response.pathologies.length > 0) {
				let t = {
					parentPathology: response.pathologies.find((d) => (d.languageId = 1)),
					pathologies: response.pathologies
				}

				dispatch({ type: FETCH_PATHOLOGY_SUCCESS, data: t })
			}
		} else {
			dispatch({ type: FETCH_PATHOLOGY_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.pathologies.loadingFailed`, isSuccess: false }
			})
		}
	})
	return
}

const SORT_PATHOLOGIES_REQUESTED = 'SORT_PATHOLOGIES_REQUESTED'
export const setPathologySort = (field, isDescending) => (dispatch, state) => {
	let currentState = state()

	let data = currentState.pathology.pathologies.sort((a, b) => {
		if (isDescending) {
			return a[field] > b[field] ? -1 : 1
		} else {
			return b[field] > a[field] ? -1 : 1
		}
	})
	return dispatch({
		type: SORT_PATHOLOGIES_REQUESTED,
		data: { items: data, field: field, isDescending: isDescending }
	})
}

const FETCH_PATHOLOGY_AUTOCOMPLETE_REQUESTED = 'FETCH_PATHOLOGY_AUTOCOMPLETE_REQUESTED'
const FETCH_PATHOLOGY_AUTOCOMPLETE_SUCCESS = 'FETCH_PATHOLOGY_AUTOCOMPLETE_SUCCESS'
const FETCH_PATHOLOGY_AUTOCOMPLETE_FAILED = 'FETCH_PATHOLOGY_AUTOCOMPLETE_FAILED'

export const fetchPathologyAutoComplete = (searchPhrase, noMatchesText) => (dispatch) => {
	dispatch({ type: FETCH_PATHOLOGY_AUTOCOMPLETE_REQUESTED, data: {} })

	api.fetchPathologies(searchPhrase).then((response) => {
		if (response && response.isSuccessful) {
			{
				if (response.pathologies && response.pathologies.length > 0) {
					return dispatch({ type: FETCH_PATHOLOGY_AUTOCOMPLETE_SUCCESS, data: response.pathologies })
				} else {
					return dispatch({
						type: FETCH_PATHOLOGY_AUTOCOMPLETE_SUCCESS,
						data: [ { id: -1, name: noMatchesText } ]
					})
				}
			}
		} else {
			return dispatch({ type: FETCH_PATHOLOGY_AUTOCOMPLETE_FAILED, data: {} })
		}
	})
	return
}

const SET_PATHOLOGY_SEARCH_TERM_REQUESTED = 'SET_PATHOLOGY_SEARCH_TERM_REQUESTED'
const SET_PATHOLOGY_SEARCH_TERM_SUCCESS = 'SET_PATHOLOGY_SEARCH_TERM_SUCCESS'
const SET_PATHOLOGY_SEARCH_TERM_FAILED = 'SET_QUESTION_SEARCH_TERM_FAILED'

export const setPathologySearchTerm = (value) => (dispatch) => {
	dispatch({ type: SET_PATHOLOGY_SEARCH_TERM_REQUESTED, data: {} })
	return dispatch({ type: SET_PATHOLOGY_SEARCH_TERM_SUCCESS, data: value })
}

const initial = {
	pathology: null,
	isAddingInProgress: false,
	pathologies: [],
	sortField: 'modifiedDateSeconds',
	isDescending: true,
	autoCompleteSuggestions: [],
	searchTerm: ''
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case SORT_PATHOLOGIES_REQUESTED:
			return {
				...state,
				pathologies: [ ...action.data.items ],
				sortField: action.data.field,
				isDescending: action.data.isDescending
			}
		case ADD_PATHOLOGY_REQUESTED:
			return { ...state, isAddingInProgress: true }
		case ADD_PATHOLOGY_FAILED:
		case ADD_PATHOLOGY_SUCCESS:
			return { ...state, isAddingInProgress: false }
		case FETCH_PATHOLOGIES_SUCCESS:
			return { ...state, pathologies: action.data }
		case UPDATE_PATHOLOGY_INTERNAL_NAME_SUCCESS:
		case UPDATE_PATHOLOGY_SUCCESS:
		case FETCH_PATHOLOGY_SUCCESS:
			return { ...state, pathology: action.data }
		case FETCH_PATHOLOGY_AUTOCOMPLETE_SUCCESS:
			return { ...state, autoCompleteSuggestions: action.data}
		case SET_PATHOLOGY_SEARCH_TERM_SUCCESS:
			return {
				...state,
				searchTerm: action.data
			}

		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.pathology

export const isAddPathologyInProgressSelector = createSelector(mainSelector, (state) => {
	return state && state.isAddingInProgress
})

export const pathologiesSelector = createSelector(mainSelector, (state) => {
	return state && state.pathologies
})
export const pathologySelector = createSelector(mainSelector, (state) => {
	return state && state.pathology
})

export const sortPathologiesDirectionSelector = createSelector(mainSelector, (state) => {
	return state.isDescending
})

export const sortPathologiesFieldSelector = createSelector(mainSelector, (state) => {
	return state.sortField
})

export const pathologyAutoCompleteSuggestionSelector = createSelector(mainSelector, (state) => {
	if (state && state.autoCompleteSuggestions) {
		return state.autoCompleteSuggestions
	}
	return []
})

export const pathologySearchTermSelector = createSelector(mainSelector, (state) => {
	return state.searchTerm
})
