import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { useTheme } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Paper } from '@material-ui/core'
import { reduxForm, reset } from 'redux-form'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import DefaultFormTextField from '../../../components/DefaultFormTextField'
import { surveyCopySelector, surveySelector, isSavingSelector } from '../../../redux/selectors'
import { saveSurveyCopy, fetchSurveyCopy, startSaving, showModal, hideModal } from '../../../redux/actions'
import { useRouteMatch } from 'react-router-dom'
import CalmButton from '../../../components/CalmButton'
import EntityAuditCommentModal from "../../../components/entityAudit/EntityAuditCommentModal"
import { isAuditCommentsRequired } from '../../../utilities/auditUtils'

const SurveyName = ({ save, survey, handleSubmit, getCopy, start, isSaving }) => {
	const intl = useIntl()
	const dispatch = useDispatch()
	const theme = useTheme()
	const match = useRouteMatch()

	const onSubmitForm = (values) => {
		if (isAuditCommentsRequired())
		{
			if (values && survey) {
				dispatch(
					showModal(
						EntityAuditCommentModal,
						{
							entityId: values.id,
							entityTypeId: 16, //survey
							saveCallback: (commentsObj) => {
								dispatch(hideModal())
								start()
								save(values.id, match.params.languageId, values.title, values.description, commentsObj)
							}
						}
					)
				)
			}
		}
		else 
		{
			start()
			save(values.id, match.params.languageId, values.title, values.description)
		}
	}

	useEffect(
		() => {
			if (survey) {
				getCopy(survey.id, match.params.languageId)
			}
		},
		[ survey && survey.id, match.params.languageId ]
	)

	return (
		<form onSubmit={handleSubmit(onSubmitForm)}>
			<Paper style={{ marginBottom: '3%' }}>
				<Grid container direction="column" justify="space-evenly">
					<Grid item style={{ marginRight: '3%', marginTop: '20px' }}>
						<Grid container justify="flex-end" spacing={1}>
							<Grid item>
								<CalmButton
                                    variant="contained"
                                    color="primary"
									type="submit"
									disabled={isSaving}
								>
									{intl.formatMessage({ id: 'app.protocols.saveChangesButtonText' })}
								</CalmButton>
							</Grid>
						</Grid>
					</Grid>

					<Grid item style={{ height: '100%', width: '100%', marginTop: '19px' }}>
						<div style={{ margin: '0 3% 2% 3%' }}>
							<Grid container direction="column" style={{ backgroundColor: theme.palette.primary.A200 }}>
								<Grid item>
									<Grid container direction="column" style={{ padding: '0 3%' }}>
										<Grid item>
											<Typography
												variant="h4"
												style={{
													fontSize: '20px',
													lineHeight: '25px',
													letterSpacing: '0.25px',
													fontWeight: '700',
													marginBottom: '33px',
													marginTop: '20px'
												}}
											>
												Survey Name
											</Typography>
										</Grid>

										<Grid item>
											<DefaultFormTextField
												label=""
												variant="filled"
												name="title"
												inputProps={{ maxLength: 254 }}
												maxLength={254}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</div>
					</Grid>
				</Grid>
			</Paper>{' '}
		</form>
	)
}

const mapStateToProps = (state, ownProps) => ({
	initialValues: surveyCopySelector(state),
	survey: surveySelector(state),
	isSaving: isSavingSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	getCopy: (id, languageId) => dispatch(fetchSurveyCopy(id, languageId)),
	save: (id, languageId, title, description, commentsObj) => dispatch(saveSurveyCopy(id, title, description, languageId, commentsObj)),
	start: () => dispatch(startSaving())
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'surveySettings',
		enableReinitialize: true
	})
)

export default enhance(SurveyName)
