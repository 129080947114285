import React, { Fragment, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { compose } from 'recompose'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import { connect, useDispatch } from 'react-redux'
import { useConstant } from '../../../utilities/useConstant'
import { debounceMilliseconds, minimumSearchCharacters } from '../../../utilities/constants'
import { searchSGRelatedProtocols } from '../../../redux/actions'
var debounce = require('lodash/debounce')


const RelatedEntitiesSearchBar = ({
	searchRelatedProtocols
}) => {
	const intl = useIntl()

	const [ filter, setCurrentFilter ] = useState(null)


	useEffect(
		() => {
			searchRelatedProtocols(null)
		},
		[]
	)


	const onChange = (e) => {
		setCurrentFilter(e.target.value)
		OnSearchChanged(e.target.value)
	}

	const changeFilter = (value) => {
		if (value && value.length >= minimumSearchCharacters) {
			searchRelatedProtocols(value)
		}
		else if (!value?.length) {
			searchRelatedProtocols(null)
		}
	}

	const OnSearchChanged = useConstant(() => debounce(changeFilter, debounceMilliseconds, { leading: true }))

	return (
		<div
			datatest-id="related-entities-search-bar-container"
		>
			<Fragment style={{ width: '100%' }}>
				<Paper style={{ width: '100%', padding: '10px', boxSizing: 'border-box' }}>
					<InputBase
						fullWidth
						placeholder={intl.formatMessage({ id: 'app.general.searchPlaceholder' })}
						startAdornment={
							<div style={{ margin: '2px 10px 0 4px' }}>
								<SearchIcon />
							</div>
						}
						value={filter}
						onChange={onChange}
						style={{ minHeight: '34px', lineHeight: 'normal'}}
					/>
				</Paper>
			</Fragment>
		</div>
	)
}


const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	searchRelatedProtocols: (searchPhrase) => dispatch(searchSGRelatedProtocols(ownProps.groupId, searchPhrase))
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(RelatedEntitiesSearchBar)
