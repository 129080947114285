import React, { useEffect, forwardRef, useImperativeHandle } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { uuidv4 } from '../../utilities/utilities'
import DefaultFormTextField from '../DefaultFormTextField'
import { useStore, connect, useDispatch } from 'react-redux'
import { change, formValueSelector } from 'redux-form'
import { required } from '../../utilities/validators'
import { useIntl } from 'react-intl'
import FormCheckBox from '../FormCheckBox'
import PIDataToggle from '../PIDataToggle'

const requiredValidator = required
const selector = formValueSelector('createQuestion')

const q = forwardRef((props, ref) => {
	const store = useStore()
	const intl = useIntl()
	const dispatch = useDispatch()

	useImperativeHandle(ref, () => ({
		getQuestion() {
			return {
				id: uuidv4().replace('-', ''),
				title: selector(store.getState(), 'question'),
				type: 'date',
				instructions: selector(store.getState(), 'instructions'),
				tag: selector(store.getState(), 'tag'),
				isRegularExpressionEnabled: selector(store.getState(), 'isRegularExpressionEnabled'),
                regularExpression: selector(store.getState(), 'regularExpression'),
                regularExpressionValidation: selector(store.getState(), 'regularExpressionValidation'),
				isOptional: selector(store.getState(), 'isOptional'),
				isPiData: selector(store.getState(), 'isPiData'),
                isConsentRequired: selector(store.getState(), 'isConsentRequired'),
				displayInstructionAsIcon: selector(store.getState(), 'displayInstructionAsIcon'),
			}
		}
	}))

	useEffect(() => {
		var el = document.getElementsByName('tag')
		if (el != null) {
			el[0].focus()
		}
	}, [])
	return (
		<Grid container direction="column" alignItems="flex-start">
			<Grid item style={{ width: '100%' }}>
				<PIDataToggle defaultValue={false} onChange={(value) => dispatch(change('createQuestion', 'isPiData', value))}/>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				<DefaultFormTextField
					label="Tag"
					variant="filled"
					name="tag"
					fullWidth
					validators={[ requiredValidator ]}
					inputProps={{ maxLength: 254 }}
					maxLength={254}
				/>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				<DefaultFormTextField
					label="Question"
					variant="filled"
					name="question"
					fullWidth
					validators={[ requiredValidator ]}
					inputProps={{ maxLength: 1023 }}
					maxLength={1023}
				/>
			</Grid>

			<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
				<DefaultFormTextField
					label="Instructions"
					variant="filled"
					fullWidth
					name="instructions"
					inputProps={{ maxLength: 2047 }}
					maxLength={2047}
					multiline
					rows="3"
				/>
			</Grid>
			<FormCheckBox
				name="displayInstructionAsIcon"
				color="primary"
				label={intl.formatMessage({ id: 'app.survey.questions.displayInstructionAsIcon' })}
			/>
			<FormCheckBox
				name="isOptional"
				color="primary"
				label={intl.formatMessage({ id: 'app.survey.questions.isOptional' })}
			/>
            <FormCheckBox
				name="isRegularExpressionEnabled"
                color="primary"
				label={intl.formatMessage({ id: 'app.survey.questions.regularExpression.checkBox' })}
			/>
            {/* <FormCheckBox
                name="isConsentRequired"
                style={{ color: '#011020' }}
                label={intl.formatMessage({ id: 'app.survey.questions.isConsentRequired.checkBox' })}
            /> */}
            {
				props.isRegularExpressionEnabled &&
				<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
					<Typography variant="h4" style={{ fontWeight: '400', paddingBottom: '10px' }}>
						{intl.formatMessage({ id: 'app.survey.questions.regularExpression.label' })}
					</Typography>
					<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
						<DefaultFormTextField
							label={intl.formatMessage({ id: 'app.survey.questions.regularExpression.value' })}
							variant="filled"
							fullWidth
							name="regularExpression"
							validators={[requiredValidator]}
							inputProps={{ maxLength: 1024 }}
							maxLength={1024}
							multiline
							rows="3"
						/>
					</Grid>
					<Grid item style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px' }}>
						<DefaultFormTextField
							label={intl.formatMessage({ id: 'app.survey.questions.regularExpression.message' })}
							variant="filled"
							fullWidth
							name="regularExpressionValidation"
							validators={[requiredValidator]}
							inputProps={{ maxLength: 1024 }}
							maxLength={1024}
							multiline
							rows="3"
						/>
					</Grid>
				</Grid>
			}
		</Grid>
	)
})

const mapStateToProps = (state) => ({
    isRegularExpressionEnabled: selector(state, 'isRegularExpressionEnabled')
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(q)