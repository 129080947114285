import React, { useRef } from 'react'
import Grid from '@material-ui/core/Grid'
import { useTheme } from '@material-ui/core/styles'
import Answer from './Answer'
import UpDownCommandBar from '../UpDownCommandBar'

export default ({ answer, onDeleteAnswerCallback, onSwapRequestedCallback, showScoreEntry, dark, disabled, showCode }) => {
	const theme = useTheme()
	const ref = useRef()
	return (
		<Grid
			container
			justify="space-between"
			alignItems="flex-start"
			className='block-answer-container'
			key={answer.id}
			style={{ backgroundColor: dark ? 'transparent' : theme.palette.primary.A200, width: '100%' }}
		>
			<Grid item style={{ width: '90%' }}>
				<Answer
					ref={ref}
					answer={answer}
					onDeleteCallback={onDeleteAnswerCallback}
					showScoreEntry={showScoreEntry}
					dark={dark}
					disabled={disabled}
					showCode={showCode}
				/>
			</Grid>
			{
				!disabled
				&& <Grid item>
					<UpDownCommandBar node={answer} onSwapRequested={onSwapRequestedCallback} dark={dark} />
				</Grid>
			}
		</Grid>
	)
}
