import React, { useState, useEffect, Fragment } from 'react'
import IconButton from '@material-ui/core/IconButton'
import MuiMenu from '@material-ui/core/Menu'
import MuiMenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { useDispatch } from 'react-redux'
import { unlockReminder } from '../../redux/actions'

const ITEM_HEIGHT = 48

export default ({ reminder }) => {
	const [ anchorEl, setAnchorEl ] = useState(null)
	const open = Boolean(anchorEl)
	const dispatch = useDispatch()
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	useEffect(() => {}, [])

	return (
		<div>
			<Fragment>
				<IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
					<MoreVertIcon />
				</IconButton>
				<MuiMenu
					id="long-menu"
					anchorEl={anchorEl}
					keepMounted
					open={open}
					onClose={handleClose}
					PaperProps={{
						style: {
							maxHeight: ITEM_HEIGHT * 4.5,
							width: 200
						}
					}}
				>
					<MuiMenuItem onClick={() => dispatch(unlockReminder(reminder.caseId))}>Reset Lock</MuiMenuItem>
				</MuiMenu>
			</Fragment>
		</div>
	)
}
