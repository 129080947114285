import { createSelector } from 'reselect'
import * as utility from '../../../apis/utility'
const SHOW_MODAL = 'SHOW_MODAL'

export const showModal = (component, options, canClose = true) => (dispatch) => {
	return dispatch({
		type: SHOW_MODAL,
		data: { component: component, options: options, show: true, canClose: canClose }
	})
}

const SET_SNACKBAR_MESSAGE = 'SET_SNACKBAR_MESSAGE'
export const setSnackbarMessage = (message, isSuccess = null) => (dispatch) => {
	return dispatch({ type: SET_SNACKBAR_MESSAGE, data: { message: message, isSuccess: isSuccess } })
}

export const hideModal = () => (dispatch) => {
	return dispatch({ type: SHOW_MODAL, data: { component: null, show: false, options: null } })
}

const CLEAR_SNACKBAR_MESSAGE = 'CLEAR_SNACKBAR_MESSAGE'
export const clearSnackbarMessage = () => (dispatch) => {
	return dispatch({ type: SET_SNACKBAR_MESSAGE, data: { message: null, isSuccess: null } })
}

const START_SAVING_REQUESTED = 'START_SAVING_REQUESTED'
export const startSaving = () => (dispatch) => {
	return dispatch({ type: START_SAVING_REQUESTED, data: {} })
}

const STOP_SAVING_REQUESTED = 'STOP_SAVING_REQUESTED'
export const stopSaving = () => (dispatch, getState) => {
	return dispatch({ type: STOP_SAVING_REQUESTED, data: {} })
}

const SET_UPLOADING_COUNT = 'SET_UPLOADING_COUNT'
export const setUploadingCount = (val) => (dispatch, getState) => {
	return dispatch({ type: SET_UPLOADING_COUNT, data: val })
}

const FETCH_CURRENT_BACKEND_VERSION_REQUESTED = 'FETCH_CURRENT_BACKEND_VERSION_REQUESTED'
const FETCH_CURRENT_BACKEND_VERSION_SUCCESS = 'FETCH_CURRENT_BACKEND_VERSION_SUCCESS'
const FETCH_CURRENT_BACKEND_VERSION_FAILED = 'FETCH_CURRENT_BACKEND_VERSION_FAILED'

export const fetchCurrentBackendVersion = () => (dispatch) => {
    dispatch({ type: FETCH_CURRENT_BACKEND_VERSION_REQUESTED, data: {} })

    return utility.fetchCurrentBackendVersion().then((response) => {
        if (response && response.isSuccessful) {
            return dispatch({ type: FETCH_CURRENT_BACKEND_VERSION_SUCCESS, data: response.data })
        } else {
            return dispatch({ type: FETCH_CURRENT_BACKEND_VERSION_FAILED, data: response.data })
        }
    })
}

const SET_VERSION_MISMATCH_ALERT_VISIBILITY_SUCCESS = 'SET_VERSION_MISMATCH_ALERT_VISIBILITY_SUCCESS'

export const setVersionMismatchAlertVisibility = (state) => (dispatch) => {
    dispatch({ type: SET_VERSION_MISMATCH_ALERT_VISIBILITY_SUCCESS, data: state })
}

const initial = {
	show: false,
	component: null,
	options: null,
	params: null,
	message: null,
	isSnackMessageSuccess: false,
	canClose: true,
	isSaving: false,
    currentBackendVersion: null,
    isVersionMismatchAlertVisible: false
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case START_SAVING_REQUESTED:
			return { ...state, isSaving: true }
		case STOP_SAVING_REQUESTED:
			return { ...state, isSaving: false }
		case SET_UPLOADING_COUNT:
			return {...state, uploadingCount: action.data }
		case SHOW_MODAL:
			return {
				...state,
				show: action.data.show,
				component: action.data.component,
				params: action.data.options,
				canClose: action.data.canClose
			}
		case SET_SNACKBAR_MESSAGE:
			return { ...state, message: action.data.message, isSnackMessageSuccess: action.data.isSuccess }
		case CLEAR_SNACKBAR_MESSAGE:
			return { ...state, message: null, isSnackMessageSuccess: false }
		case 'SIGN_OUT_REQUESTED':
			return initial
        case FETCH_CURRENT_BACKEND_VERSION_SUCCESS:
            return {
                ...state, currentBackendVersion: action.data
            }
        case SET_VERSION_MISMATCH_ALERT_VISIBILITY_SUCCESS:
            return { ...state, isVersionMismatchAlertVisible: action.data }
        default:
			return { ...state }
	}
}
const mainSelector = (state) => state.core
export const confirmedSelector = (state) => {
	return state.auth.confirmed
}

export const errorSelector = (state) => {
	return state.auth.error
}

export const isSavingSelector = createSelector(mainSelector, (state) => {
	return state.isSaving
})

export const uploadingCountSelector = createSelector(mainSelector, (state) => {
	return state.uploadingCount
})

export const currentBackendVersionSelector = createSelector(mainSelector, (state) => {
    return state.currentBackendVersion
})

export const isVersionMismatchAlertVisibleSelector = createSelector(mainSelector, (state) => {
    return state.isVersionMismatchAlertVisible
})