import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { useTheme } from '@material-ui/core'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'

const MemberEmpty = ({ intl }) => {
	const theme = useTheme()

	return (
		<Grid container direction="column">
			<Grid item style={{ padding: '64px', maxWidth: '50%' }}>
				<Typography
					variant="h2"
					style={{ fontWeight: '400', color: theme.palette.primary.A800, paddingBottom: '12px' }}
				>
					{intl.formatMessage({ id: 'app.userProfile.teamMemberTableMemberEmptyHeader' })}
				</Typography>
				<Typography variant="subtitle2" style={{ fontWeight: '600' }}>
					{intl.formatMessage({ id: 'app.userProfile.teamMemberTableMemberEmptyParagraph' })}
				</Typography>
				<Button
					to="/circles"
					component={Link}
					style={{
						color: theme.palette.primary.A800,
						textDecoration: 'none',
						textAlign: 'left',
						fontSize: '1.8rem',
						paddingLeft: 0,
						paddingTop: '10px',
						fontWeight: '400',
						textTransform: 'uppercase',
						lineHeight: '0',
						letterSpacing: '2.08px'
					}}
					variant="text"
				>
					{intl.formatMessage({ id: 'app.userProfile.teamMemberTableMemberEmptyCircleLinkText' })}
				</Button>
				{/* <IconButton style={{ color: theme.palette.primary.A800 }}>
					<Typography>
						{intl.formatMessage({ id: 'app.userProfile.teamMemberTableMemberEmptyCircleLinkText' })}
					</Typography>
				</IconButton> */}
			</Grid>
		</Grid>
	)
}

export default injectIntl(MemberEmpty)
