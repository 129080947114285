/*
 * Login
 */

 export const LOGIN_SIGN_IN = "Sign In";
 export const LOGIN_EMAIL = "Email";
 export const LOGIN_PASS = "Password";
 export const LOGIN_SUBMIT_BUTTON = "Submit";
 export const LOGIN_SIGN_IN_BUTTON = "Sign Up";

