import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { getUser } from '../utilities/userUtilities'

export default ({ component: Component, location, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) =>
				getUser() ? (
					<Component {...props} {...rest} />
				) : (
					<Redirect
						to={{
							pathname: `/auth/login`,
							search: `?returnUrl=${location.pathname}`
						}}
					/>
				)}
		/>
	)
}
