import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import FormNumberTextField from '../../../components/FormNumberTextField'
import { number, maxValue } from '../../../utilities/validators'
import Link from '@material-ui/core/Link'
const numberValidator = number
const maxValueValidator = maxValue

const DistributeValue = ({ intl, label, available = 0, entityType, name, current = 0 }) => {
	return (
		<Grid container direction="row" style={{ paddingTop: '10px' }}>
			<Grid item style={{ color: 'white', flex: 0.7, paddingLeft: '10px' }}>
				<Grid container>
					<Grid item style={{ paddingRight: '40px' }}>
						<Typography
							style={{
								color: '#fff',
								fontWeight: '400',
								fontSize: '22px'
							}}
							align="left"
						>
							{label}
						</Typography>
					</Grid>
					<Grid item>
						<Typography style={{ color: '#a2a7ab', fontSize: '14px' }}>
							{`${current} ${intl.formatMessage({ id: 'app.general.current' })} ${entityType}`}
						</Typography>
						<Typography style={{ color: '#a2a7ab', fontSize: '14px' }}>
							{`${available} ${intl.formatMessage({ id: 'app.general.available' })} ${entityType}`}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			{available && (
				<Grid item style={{ color: 'white', flex: 0.3, paddingRight: '20px' }}>
					<Grid container direction="column">
						<Grid item>
							<FormNumberTextField
								type="text"
								name={name}
								maxLength={4}
								variant="filled"
								validators={[ numberValidator, maxValueValidator(9999) ]}
							/>
							<Typography style={{ color: '#a2a7ab', fontSize: '0.9em' }}>
								{`${intl.formatMessage({ id: 'app.general.assign' })} ${entityType}`}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			)}
			{!available && (
				<Grid item style={{ color: 'white', flex: 0.3, paddingRight: '20px' }}>
					<Grid container direction="column">
						<Grid item>
							<Link to="/plan">
								<Typography
									style={{
										color: '#a2a7ab',
										fontSize: '0.9em',
										textDecoration: 'none',
										paddingTop: '6px'
									}}
								>
									Get more {entityType.toLowerCase()}
								</Typography>
							</Link>
						</Grid>
					</Grid>
				</Grid>
			)}
		</Grid>
	)
}

const enhance = compose(injectIntl)

export default enhance(DistributeValue)
