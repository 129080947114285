import * as api from '../../../apis/sponsors'
import { hideModal, stopSaving } from '../../actions'
import { createSelector } from 'reselect'

export const getErrorMesssageByCode = (errorCode, defaultMessage) => {
	switch (errorCode) {
		case 32:
			return 'app.sponsors.stripeSubscriptionDoesNotExist'
		case 33:
			return 'app.sponsors.stripeSubscriptionDoesNotBelongToUser'
		case 34:
			return 'app.sponsors.stripeCustomerDoesNotExist'
		case 35:
			return 'app.sponsors.duplicateSponsor'
		case 36:
			return 'app.sponsors.sponsorAlreadyHasSubscription'
		case 37:
			return 'app.sponsors.customerIdIsEmpty'
		default:
			return defaultMessage
	}
}

const ADD_SPONSOR_REQUESTED = 'ADD_SPONSOR_REQUESTED'
const ADD_SPONSOR_SUCCESS = 'ADD_SPONSOR_SUCCESS'
const ADD_SPONSOR_FAILED = 'ADD_SPONSOR_FAILED'

export const addSponsor = (values) => (dispatch, getState) => {
	dispatch({ type: ADD_SPONSOR_REQUESTED, data: null })
	let state = getState()
	api.addSponsor(values).then((sponsor) => {
		dispatch(stopSaving())
		if (sponsor && sponsor.isSuccessful) {
			dispatch(hideModal())
			dispatch(fetchSponsors(state.sponsors.searchTerm))
			dispatch({ type: ADD_SPONSOR_SUCCESS, data: null })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.general.successfullyUpdated`, isSuccess: true }
			})
		} 
		else {
			dispatch({ type: ADD_SPONSOR_FAILED, data: null })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: getErrorMesssageByCode(sponsor.errorCode, 'app.sponsors.addFailed'), isSuccess: false }
			})
		}
	})
	return
}

const ARCHIVE_SPONSOR_REQUESTED = 'ARCHIVE_SPONSOR_REQUESTED'
const ARCHIVE_SPONSOR_SUCCESS = 'ARCHIVE_SPONSOR_SUCCESS'
const ARCHIVE_SPONSOR_FAILED = 'ARCHIVE_SPONSOR_FAILED'

export const archiveSponsor = (id) => (dispatch, getState) => {
	dispatch({ type: ARCHIVE_SPONSOR_REQUESTED, data: {} })
	let state = getState()
	api.archiveSponsor(id).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(fetchSponsors(state.sponsors.searchTerm))
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.general.success`, isSuccess: true }
			})
		} else {
			if (response.errorCode === 31) {
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: `app.sponsors.sponsorIsInUseErrorMessage`, isSuccess: false }
				})
			}
			else {
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: `app.general.failed`, isSuccess: false }
				})
			}
		}
	})
	return
}

const UNARCHIVE_SPONSOR_REQUESTED = 'UNARCHIVE_SPONSOR_REQUESTED'
const UNARCHIVE_SPONSOR_SUCCESS = 'UNARCHIVE_SPONSOR_SUCCESS'
const UNARCHIVE_SPONSOR_FAILED = 'UNARCHIVE_SPONSOR_FAILED'

export const unarchiveSponsor = (id) => (dispatch, getState) => {
	dispatch({ type: UNARCHIVE_SPONSOR_REQUESTED, data: {} })
	let state = getState()
	api.unarchiveSponsor(id).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(fetchSponsors(state.sponsors.searchTerm))
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.general.success`, isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.general.failed`, isSuccess: false }
			})
		}
	})
	return
}

const EDIT_SPONSOR_REQUESTED = 'EDIT_SPONSOR_REQUESTED'
const EDIT_SPONSOR_SUCCESS = 'EDIT_SPONSOR_SUCCESS'
const EDIT_SPONSOR_FAILED = 'EDIT_SPONSOR_FAILED'

export const editSponsor = (id, values) => (dispatch, getState) => {
	dispatch({ type: EDIT_SPONSOR_REQUESTED, data: {} })
	let state = getState()
	api.editSponsor(id, values).then((sponsor) => {
		dispatch(stopSaving())
		if (sponsor && sponsor.isSuccessful) {			
			dispatch(hideModal())
			dispatch(fetchSponsors(state.sponsors.searchTerm))
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.general.successfullyUpdated`, isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: getErrorMesssageByCode(sponsor.errorCode, 'app.general.UpdateFailed'), isSuccess: false }
			})
		}
	})
	return
}

const RESET_SPONSOR_REQUESTED = 'RESET_SPONSOR_REQUESTED'
const RESET_SPONSOR_SUCCESS = 'RESET_SPONSOR_SUCCESS'
const RESET_SPONSOR_FAILED = 'RESET_SPONSOR_FAILED'

export const resetSponsorSubscription = (id) => (dispatch) => {
	dispatch({ type: RESET_SPONSOR_REQUESTED, data: {} })
	api.resetSponsorSubscription(id).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.general.success`, isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.general.failed`, isSuccess: false }
			})
		}
	})
	return
}

const FETCH_SPONSORS_REQUESTED = 'FETCH_SPONSORS_REQUESTED'
const FETCH_SPONSORS_SUCCESS = 'FETCH_SPONSORS_SUCCESS'
const FETCH_SPONSORS_FAILED = 'FETCH_SPONSORS_FAILED'

export const fetchSponsors = (searchPhrase) => (dispatch) => {
	dispatch({ type: FETCH_SPONSORS_REQUESTED, data: {} })
	api.search(searchPhrase).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_SPONSORS_SUCCESS, data: response })
		} else {
			return dispatch({ type: FETCH_SPONSORS_FAILED, data: {} })
		}
	})
	return
}

const FETCH_ADDITIONAL_SPONSORS_REQUESTED = 'FETCH_ADDITIONAL_SPONSORS_REQUESTED'
const FETCH_ADDITIONAL_SPONSORS_SUCCESS = 'FETCH_ADDITIONAL_SPONSORS_SUCCESS'
const FETCH_ADDITIONAL_SPONSORS_FAILED = 'FETCH_ADDITIONAL_SPONSORS_FAILED'

export const searchAdditionalSponsors = (searchPhrase, offset) => (dispatch) => {
	dispatch({ type: FETCH_ADDITIONAL_SPONSORS_REQUESTED, data: {} })
	api.search(searchPhrase, offset).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ 
				type: FETCH_ADDITIONAL_SPONSORS_SUCCESS, 
				data: response
			})
		} else {
			return dispatch({ type: FETCH_ADDITIONAL_SPONSORS_FAILED, data: {} })
		}
	})
	return
}

const FETCH_SPONSOR_REQUESTED = 'FETCH_SPONSOR_REQUESTED'
const FETCH_SPONSOR_SUCCESS = 'FETCH_SPONSOR_SUCCESS'
const FETCH_SPONSOR_FAILED = 'FETCH_SPONSOR_FAILED'

export const fetchSponsor = (id) => (dispatch) => {
	dispatch({ type: FETCH_SPONSOR_REQUESTED, data: {} })
	api.fetch(id).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_SPONSOR_SUCCESS, data: response })
		} else {
			return dispatch({ type: FETCH_SPONSOR_FAILED, data: {} })
		}
	})
	return
}

const CLEAR_SPONSOR_REQUESTED = 'CLEAR_SPONSOR_REQUESTED'

export const clearSponsor = () => (dispatch) => {
	return dispatch({ type: CLEAR_SPONSOR_REQUESTED, data: {} })
}

const SET_SPONSOR_SORT_REQUESTED = 'SET_SPONSOR_SORT_REQUESTED'

export const setSponsorSort = (field, isDescending) => (dispatch, state) => {
	let currentState = state()

	let x = [ ...currentState.sponsors.sponsors ]

	let data = x.sort((a, b) => {
		if (isDescending) {
			if(typeof a[field] === 'string' && typeof b[field] === 'string')
				return a[field].toLowerCase() > b[field].toLowerCase() ? -1 : 1
			else
				return (a[field]===null)-(b[field]===null) || -(a[field]>b[field])||+(a[field]<b[field])
		} else {
			if(typeof a[field] === 'string' && typeof b[field] === 'string')
				return b[field].toLowerCase() > a[field].toLowerCase() ? -1 : 1
			else
				return (a[field]===null)-(b[field]===null) || +(a[field]>b[field])||-(a[field]<b[field])
		}
	})

	return dispatch({
		type: SET_SPONSOR_SORT_REQUESTED,
		data: { items: data, field: field, isDescending: isDescending }
	})
}

const SET_SPONSOR_SEARCH_TERM_REQUESTED = 'SET_SPONSOR_SEARCH_TERM_REQUESTED'
const SET_SPONSOR_SEARCH_TERM_SUCCESS = 'SET_SPONSOR_SEARCH_TERM_SUCCESS'
const SET_SPONSOR_SEARCH_TERM_FAILED = 'SET_SPONSOR_SEARCH_TERM_FAILED'

export const setSponsorSearchTerm = (value) => (dispatch) => {
	dispatch({ type: SET_SPONSOR_SEARCH_TERM_REQUESTED, data: {} })
	return dispatch({ type: SET_SPONSOR_SEARCH_TERM_SUCCESS, data: value })
}

const FETCH_SPONSORS_AUTOCOMPLETE_REQUESTED = 'FETCH_SPONSORS_AUTOCOMPLETE_REQUESTED'
const FETCH_SPONSORS_AUTOCOMPLETE_SUCCESS = 'FETCH_SPONSORS_AUTOCOMPLETE_SUCCESS'
const FETCH_SPONSORS_AUTOCOMPLETE_FAILED = 'FETCH_SPONSORS_AUTOCOMPLETE_FAILED'

export const fetchSponsorsAutocomplete = (searchPhrase, allowArchived) => (dispatch) => {
	dispatch({ type: FETCH_SPONSORS_AUTOCOMPLETE_REQUESTED, data: {} })
	api.searchAutocomplete(searchPhrase, allowArchived).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_SPONSORS_AUTOCOMPLETE_SUCCESS, data: response })
		} else {
			return dispatch({ type: FETCH_SPONSORS_AUTOCOMPLETE_FAILED, data: {} })
		}
	})
	return
}

const CREATE_SPONSOR_SUBSCRIPTION_REQUESTED = 'CREATE_SPONSOR_SUBSCRIPTION_REQUESTED'
const CREATE_SPONSOR_SUBSCRIPTION_SUCCESS = 'CREATE_SPONSOR_SUBSCRIPTION_SUCCESS'
const CREATE_SPONSOR_SUBSCRIPTION_FAILED = 'CREATE_SPONSOR_SUBSCRIPTION_FAILED'

export const createSponsorSubscription = (id, values) => (dispatch, getState) => {
	dispatch({ type: CREATE_SPONSOR_SUBSCRIPTION_REQUESTED, data: {} })
	let state = getState()
	api.createSubscription(id, values).then((response) => {
		dispatch(stopSaving())
		if (response && response.isSuccessful) {
			dispatch(hideModal())
			dispatch(fetchSponsors(state.sponsors.searchTerm))
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.general.success`, isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: getErrorMesssageByCode(response.errorCode, 'app.general.failed'), isSuccess: false }
			})
		}
	})
	return
}

const CHECK_RESET_STRIPE_BUTTON_REQUESTED = 'CHECK_RESET_STRIPE_BUTTON_REQUESTED'
const CHECK_RESET_STRIPE_BUTTON_SUCCESS = 'CHECK_RESET_STRIPE_BUTTON_SUCCESS'
const CHECK_RESET_STRIPE_BUTTON_FAILED = 'CHECK_RESET_STRIPE_BUTTON_FAILED'

export const checkResetStripeButtonVisibility = () => (dispatch) => {
	dispatch({ type: CHECK_RESET_STRIPE_BUTTON_REQUESTED, data: {} })
	api.checkResetButtonVisibility().then((response) => {
		if (response) {
			return dispatch({ type: CHECK_RESET_STRIPE_BUTTON_SUCCESS, data: response })
		} else {
			return dispatch({ type: CHECK_RESET_STRIPE_BUTTON_FAILED, data: {} })
		}
	})
	return
}

const initial = {
	sponsors: [],
	sponsor: null,
	sortField: 'name',
	isDescending: false,
	measurementTypes: [],
	searchTerm: '',
	hasMore: true,
	isLoading: false,
	sponsorsAutoComplete: [],
	resetStripeButtonIsVisible: false
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case ADD_SPONSOR_REQUESTED:
			return { ...state, isCreating: true }
		case ADD_SPONSOR_SUCCESS:
			return { ...state, isCreating: false }
		case ADD_SPONSOR_FAILED:
			return { ...state, isCreating: false }
		case FETCH_SPONSOR_REQUESTED:
		case CLEAR_SPONSOR_REQUESTED:
			return { ...state, sponsor: null }
		case FETCH_SPONSOR_SUCCESS:
			return { ...state, sponsor: action.data }
		case FETCH_SPONSORS_REQUESTED:
			return { ...state, sponsors: [] }
		case FETCH_SPONSORS_SUCCESS:
			return { 
				...state, 
				sponsors: action.data.sponsors,
				hasMore: action.data.hasMore,
				sortField: 'name',
				isDescending: false
			 }
		case FETCH_ADDITIONAL_SPONSORS_SUCCESS:
			return {
				...state,
				sponsors: [ ...state.sponsors, ...action.data.sponsors],
				hasMore: action.data.hasMore,
				isLoading: false
			}
		case FETCH_ADDITIONAL_SPONSORS_FAILED:
			return {
				...state,
				hasMore: false,
				isLoading: false
			}
		case FETCH_ADDITIONAL_SPONSORS_REQUESTED:
			return {
				...state,
				isLoading: true
			}
		case FETCH_SPONSORS_AUTOCOMPLETE_SUCCESS:
			return {
				...state,
				sponsorsAutoComplete: action.data.sponsors
			}
		case SET_SPONSOR_SORT_REQUESTED:
			return {
				...state,
				sponsors: action.data.items,
				sortField: action.data.field,
				isDescending: action.data.isDescending
			}
		case SET_SPONSOR_SEARCH_TERM_SUCCESS:
			return { ...state, searchTerm: action.data }
		case CHECK_RESET_STRIPE_BUTTON_SUCCESS:
			return { ...state, resetStripeButtonIsVisible: action.data.isVisible }
		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.sponsors

export const sponsorsSelector = createSelector(mainSelector, (state) => {
	return state.sponsors
})

export const hasSponsorsSelector = createSelector(mainSelector, (state) => {
	return state.sponsors && state.sponsors.length > 0
})

export const sortSponsorsDirectionSelector = createSelector(mainSelector, (state) => {
	return state.isDescending
})

export const sortSponsorsFieldSelector = createSelector(mainSelector, (state) => {
	return state.sortField
})

export const sponsorSelector = createSelector(mainSelector, (state) => {
	return state.sponsor
})

export const isCreatingSponsorsSelector = createSelector(mainSelector, (state) => {
	return state.isCreating
})

export const sponsorSearchTermSelector = createSelector(mainSelector, (state) => {
	return state.searchTerm
})

export const hasMoreSponsorsSelector = createSelector(mainSelector, (state) => {
	return state.hasMore
})

export const isLoadingSponsorsSelector = createSelector(mainSelector, (state) => {
	return state.isLoading
})

export const sponsorsAutocompleteSelector = createSelector(mainSelector, (state) => {
	return state.sponsorsAutoComplete
})

export const resetStripeButtonIsVisibleSelector = createSelector(mainSelector, (state) => {
	return state.resetStripeButtonIsVisible
})
