import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import AddElementBuilderButton from './AddElementBuilderButton'
import { useTheme } from '@material-ui/core/styles'
import Menu from './Menu'
import EditQuestionContainer from '../questionEditor/QuestionContainer'
import { useSelector, shallowEqual, connect, useDispatch } from 'react-redux'
import { isUpdatingQuestionSelector, isUpdatingQuestionContainerIdSelector } from '../../redux/selectors'
import { questionId, uuidv4 } from '../../utilities/utilities'
import MoveQuestionToPositionModal from './MoveQuestionToPositionModal'
import { showModal } from '../../redux/actions'
import CloneQuestionModal from './CloneQuestionModal'
import IdContainer from '../survey/components/IdContainer'


const BUNDLE_STATE = {
	UNDEFINED: 0,
	IN_USE: 1,
	NOT_USED: 2,
	ARCHIVED: 3
}

const QuestionContainer = ({
	node,
	list,
	children,
	onAddCallback,
	onAddExistingCallback,
	onAddBundleCallback,
	onSwapCallback,
	onRemoveCallback,
	onUpdateCallback,
	position = 0,
	ignoreBundles = false,
	suppressNonDiscreteQuestions = false,
	showScoreEntry,
	searchType,
	isBundle,
	intl,
	moveToPosition,
	propertyStateName,
	onChangeBundleStateCallback,
	onChangeQuestionStateCallback,
	sameStateAllEntities,
	canSetInUseState,
	canSetInUseStateAllEntities,
	canSetNotInUseState,
	canSetNotInUseStateAllEntities,
	canSetArchiveState,
	canSetArchiveStateAllEntities,
	canCloneQuestion,
	isOptionalDisabled = false
}) => {
	const theme = useTheme()
	const dispatch = useDispatch()
	const isUpdating = useSelector(isUpdatingQuestionSelector, shallowEqual)
	const isUpdatingContainerId = useSelector(isUpdatingQuestionContainerIdSelector, shallowEqual)
	const [ containerId, setContainerId ] = useState(uuidv4().replace('-', ''))
	const [ leadingContainerId, setLeadingContainerId ] = useState(uuidv4().replace('-', ''))
	const [ trailingContainerId, setTrailingContainerId ] = useState(uuidv4().replace('-', ''))
	const onEditCompleteCallback = (question) => {
		if (onUpdateCallback) onUpdateCallback(question)
	}

	const isShowEditor = isUpdating && isUpdatingContainerId === containerId

	useEffect(
		() => {
			if (!!node?.data?.cloneId) {
				setContainerId(node.data.cloneId)
			}
		},
		[node?.data?.cloneId]
	)

	const renderPreviousButton = (node) => {
		if (!isAddButtonVisible()) {
			return false
		}

		let isBundle = node && node.data && node.data.isBundle
		let isNextBundle = node && node.next && node.next.data.isBundle

		if (!isBundle) {
			return true
		}

		let isStart = isStartBundle(node)

		if (isStart) return true
	}

	const isStartBundle = (node) => {
		if (node) {
			if (!(node && node.data && node.data.isBundle)) {
				return false
			}
			let isPrevBundle = node && node.prev && node.prev.data.isBundle

			if (!isPrevBundle && (node && node.data && node.data.isBundle)) {
				return true
			} else {
				//Check is part of same group
				return isPrevBundle && node.prev.data.instanceId !== node.data.instanceId
			}
		}
		return false
	}

	const isEndBundle = (node) => {
		if (node) {
			if (node.next && !node.next.data.isBundle && node.data.isBundle) {
				return true
			}
			if (!node.next && node.data.isBundle) {
				return true
			}
			if (node.next && node.next.data.isBundle && node.data.isBundle) {
				if (node.next.data.bundleEntityId !== node.data.bundleEntityId) {
					return true
				}
			}
		}
		return false
	}

	const cloneQuestion = () => {
		dispatch(
			showModal(
				CloneQuestionModal,
				{
					node,
					onAddCallback,
					showScoreEntry,
					isBundle
				}
			)
		)
	}

	const isAddButtonVisible = () => {
		return (
			!!onAddCallback
			|| !!onAddExistingCallback
			|| !!onAddBundleCallback
		)
	}

	const swapUp = (node) => {
		let prevNode = node

		while (!!prevNode) {
			if (prevNode.data.instanceId !== 0) {
				if (prevNode.data.instanceId != node.data.instanceId) {
					break
				}
			}
			else if (prevNode.data.id != node.data.id) {
				break
			}

			prevNode = prevNode.prev

			if (!prevNode) {
				return
			}
		}

		onSwapCallback(node, prevNode)
	}

	const swapDown = (node) => {
		let nextNode = node

		while (!!nextNode) {
			if (nextNode.data.instanceId !== 0) {
				if (nextNode.data.instanceId != node.data.instanceId) {
					break
				}
			}
			else if (nextNode.data.id != node.data.id) {
				break
			}

			nextNode = nextNode.next

			if (!nextNode) {
				return
			}
		}

		onSwapCallback(node, nextNode)
	}


	return (
		<Grid container direction="column">
			{renderPreviousButton(node) ? (
				<Grid item style={{ minHeight: '50px' }}>
					<div style={{ transform: 'translateY(-25px)' }}>
						<AddElementBuilderButton
							position={Math.trunc(node && node.data && node.data.sortOrder || 1)}
							node={node}
							list={list}
							containerId={leadingContainerId}
							onAddCallback={onAddCallback}
							onAddExistingCallback={onAddExistingCallback}
							ignoreBundles={ignoreBundles}
							onAddBundleCallback={onAddBundleCallback}
							suppressNonDiscreteQuestions={suppressNonDiscreteQuestions}
							showScoreEntry={showScoreEntry}
							searchType={searchType}
						/>
					</div>
				</Grid>
			) : (
				<div>&nbsp;</div>
			)}
			{!ignoreBundles &&
			isStartBundle(node) && (
				<Grid item>
					<Grid item style={{ backgroundColor: '#ededed', padding: '0 2%' }}>
						<Grid container alignItems="center">
							<Grid item style={{ flex: 1 }}>
								<Grid container alignItems="center">
									<Grid item>
										<GroupWorkIcon
											style={{ color: theme.palette.primary.A800, paddingTop: '5px' }}
										/>
									</Grid>
									<Grid item>
										<Typography style={{ color: theme.palette.primary.A800, marginLeft: '10px' }}>
											Start Scoring Group ({node && node.data && node.data.bundleName})
										</Typography>
									</Grid>
								</Grid>
							</Grid>
							<Grid item>
								{
									!!node
									&& !!isAddButtonVisible
									&& <Grid item style={{ display: 'flex', alignItems: 'center', padding: '7px 0' }}>
										{!node.data.isBundle && <IdContainer
											label={"ID: " + questionId(node.data.id, node.data.questionType)}
											clipboardText={questionId(node.data.id, node.data.questionType)}
										/>}
										<Typography style={{ margin: '0 25px' }}>
											{intl.formatMessage({ id: 'app.survey.questionPositionLabel' }, { pos: Math.trunc(node.data.sortOrder) })}
										</Typography>
									</Grid>
								}
							</Grid>
							<Grid item>
								{node &&
								node.prev && (
									<IconButton onClick={() => swapUp(node)}>
										<ArrowUpward />
									</IconButton>
								)}
							</Grid>

							<Grid item>
								{node &&
								node.next && (
									<IconButton onClick={() => swapDown(node)}>
										<ArrowDownward />
									</IconButton>
								)}
							</Grid>

							<Grid item>
								{node &&
								//node.next &&
								(
									<Menu
										node={node}
										onRemoveCallback={onRemoveCallback}
										onMoveToPosition={moveToPosition}
										showMoveToPosition={isAddButtonVisible() && true}
										containerId={containerId}
										canEdit={false}
										propertyStateName={'rootState'}
										onChangeStateCallback={onChangeBundleStateCallback}
										sameStateAllEntities={sameStateAllEntities}
										canSetInUseStateAllEntities={true}
										canSetNotInUseStateAllEntities={true}
										canSetArchiveStateAllEntities={true}
										canClone={false}
									/>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
			{children && (
				<Grid
					item
					className={`${ignoreBundles && node.data[propertyStateName] === BUNDLE_STATE.ARCHIVED ? 'block-archived' : ''}`}
					style={{
						backgroundColor: ignoreBundles && node.data[propertyStateName] === BUNDLE_STATE.ARCHIVED ? 'rgb(127, 127, 127)' : theme.palette.primary.A200,
						padding: '0 2%'
					}}
				>
					<Grid container direction="column">
						<Grid item>
							<Grid container justify="flex-end" style={{ minHeight: '50px' }}>
								{
									!node.data.cloneId
									&& 
									<Grid item>
										<Grid container>
											<Grid item>
												{
													!!node
													&& !node.data.isBundle
													&& isAddButtonVisible()
													&& <Grid item style={{ display: 'flex', alignItems: 'center', padding: '7px 0' }}>
														<IdContainer
															label={"ID: " + questionId(node.data.id, node.data.questionType)}
															clipboardText={questionId(node.data.id, node.data.questionType)}
														/>
														<Typography style={{ margin: '0 25px' }}>
															{intl.formatMessage({ id: 'app.survey.questionPositionLabel' }, { pos: Math.trunc(node.data.sortOrder) })}
														</Typography>
													</Grid>
												}
											</Grid>
											<Grid item>
												{node &&
												node.prev &&
												!node.data.isBundle && (
													<IconButton onClick={() => swapUp(node)}>
														<ArrowUpward />
													</IconButton>
												)}
											</Grid>

											<Grid item>
												{node &&
												node.next &&
												!node.data.isBundle && (
													<IconButton onClick={() => swapDown(node)}>
														<ArrowDownward />
													</IconButton>
												)}
											</Grid>

											<Grid item>
												{node && node.data && (
													<Menu
														node={node}
														onRemoveCallback={onRemoveCallback}
														onMoveToPosition={moveToPosition}
														onCloneCallback={cloneQuestion}
														containerId={containerId}
														showMoveToPosition={isAddButtonVisible() && !node.data.isBundle}
														propertyStateName={propertyStateName}
														onChangeStateCallback={onChangeQuestionStateCallback}
														sameStateAllEntities={sameStateAllEntities}
														canSetInUseState={!node.data.isBundle && canSetInUseState}
														canSetInUseStateAllEntities={!node.data.isBundle && canSetInUseStateAllEntities}
														canSetNotInUseState={!node.data.isBundle && canSetNotInUseState}
														canSetNotInUseStateAllEntities={!node.data.isBundle && canSetNotInUseStateAllEntities}
														canSetArchiveState={!node.data.isBundle && canSetArchiveState}
														canSetArchiveStateAllEntities={!node.data.isBundle && canSetArchiveStateAllEntities}
														canClone={!!canCloneQuestion && !node.data.isBundle}
													/>
												)}
											</Grid>
										</Grid>
									</Grid>
								}
							</Grid>

							<Grid item className="block-archived-questions" style={{ marginTop: '-16px' }}>
								{isUpdating && isUpdatingContainerId === containerId ? (
									<EditQuestionContainer
										question={node}
										onUpdateCallback={onEditCompleteCallback}
										showScoreEntry={showScoreEntry}
										isBundle={isBundle}
										isOptionalDisabled={isOptionalDisabled}
									/>
								) : (
									children
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
			{!ignoreBundles &&
			isEndBundle(node) && (
				<Grid
					item
					style={{
						backgroundColor: '#ededed',
						padding: '0 2%',
						marginTop: '10px',
						minHeight: '40px'
					}}
				>
					<Grid container alignItems="center" style={{ marginTop: '7px' }}>
						<Grid item>
							<GroupWorkIcon style={{ color: theme.palette.primary.A800, paddingTop: '5px' }} />
						</Grid>
						<Grid item>
							<Typography style={{ color: theme.palette.primary.A800, marginLeft: '10px' }}>
								End Scoring Group
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			)}
			{
				!!node
				&& !node.next
				&& isAddButtonVisible()
				&& <Grid item style={{ marginTop: '-16px', paddingBottom: '20px' }}>
					<AddElementBuilderButton
						position={Math.trunc(node && node.data ? node.data.sortOrder + 1 : 1)}
						node={node}
						list={list}
						isLast={true}
						containerId={trailingContainerId}
						ignoreBundles={ignoreBundles}
						onAddCallback={onAddCallback}
						onAddExistingCallback={onAddExistingCallback}
						onAddBundleCallback={onAddBundleCallback}
						suppressNonDiscreteQuestions={suppressNonDiscreteQuestions}
						showScoreEntry={showScoreEntry}
						searchType={searchType}
					/>
				</Grid>
			}
		</Grid>
	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
	moveToPosition: () => dispatch(
		showModal(
			MoveQuestionToPositionModal,
			{
				id: ownProps.node.data.instanceId,
				totalQuestions: ownProps.totalQuestions
			}
		)
	)
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(QuestionContainer)
