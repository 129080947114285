import * as protocol from '../../../apis/observationalProtocol'
import { createSelector } from 'reselect'
import { hideModal, stopSaving, fetchSurvey } from '../../actions'
import history from '../../../utilities/history'
import { change } from 'redux-form'
import ProtocolSchema from './../../../pages/circleDetails/components/FormSchema.json'
import { Node, LinkList, ListContainer } from '../../../utilities/linkedList'
import { uuidv4 } from '../../../utilities/utilities'

const CREATE_OBSERVATIONAL_PROTOCOL_REQUESTED = 'CREATE_OBSERVATIONAL_PROTOCOL_REQUESTED'
const CREATE_OBSERVATIONAL_PROTOCOL_SUCCESS = 'CREATE_OBSERVATIONAL_PROTOCOL_SUCCESS'
const CREATE_OBSERVATIONAL_PROTOCOL_FAILED = 'CREATE_OBSERVATIONAL_PROTOCOL_FAILED'

export const addProtocol = (values) => (dispatch) => {
	if (!values || !values.name) {
		return dispatch({ type: CREATE_OBSERVATIONAL_PROTOCOL_SUCCESS, data: null })
	}

	dispatch({ type: CREATE_OBSERVATIONAL_PROTOCOL_REQUESTED, data: {} })
	protocol.createObservationalProtocol(values).then((observationProtocol) => {
		dispatch(stopSaving())
		if (observationProtocol && observationProtocol.isSuccessful) {
			dispatch(hideModal())
			history.push(`/protocols/${observationProtocol.id}/version/${observationProtocol.defaultProtocolVersionId}/details`)
			return dispatch({ type: CREATE_OBSERVATIONAL_PROTOCOL_SUCCESS, data: observationProtocol })
		} else {
			dispatch({ type: CREATE_OBSERVATIONAL_PROTOCOL_FAILED })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocols.createProtocolFailed', isSuccess: false }
			})
		}
	})
	return
}

const OBSERVATIONAL_PROTOCOL_INSERT_SURVEY_REQUESTED = 'OBSERVATIONAL_PROTOCOL_INSERT_SURVEY_REQUESTED'
const OBSERVATIONAL_PROTOCOL_SURVEY_SUCCESS = 'OBSERVATIONAL_PROTOCOL_SURVEY_SUCCESS'
const OBSERVATIONAL_PROTOCOL_SURVEY_FAILED = 'OBSERVATIONAL_PROTOCOL_SURVEY_FAILED'

export const insertSurvey = (protocolId, versionId, surveyId, sortOrder, phaseId) => (dispatch) => {
	dispatch({ type: OBSERVATIONAL_PROTOCOL_INSERT_SURVEY_REQUESTED, data: {} })
	protocol.insertSurvey(protocolId, versionId, surveyId, sortOrder, phaseId).then((observationProtocol) => {
		dispatch(stopSaving())
		if (observationProtocol && observationProtocol.isSuccessful) {
			dispatch(fetchProtocolVersion(protocolId, versionId))
			dispatch(hideModal())
			return dispatch({ type: OBSERVATIONAL_PROTOCOL_SURVEY_SUCCESS, data: observationProtocol })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.cloneProtocolFailed', isSuccess: false }
			})
		}
	})
	return
}

const CLONE_OBSERVATIONAL_PROTOCOL_REQUESTED = 'CLONE_OBSERVATIONAL_PROTOCOL_REQUESTED'
const CLONE_OBSERVATIONAL_PROTOCOL_SUCCESS = 'CLONE_OBSERVATIONAL_PROTOCOL_SUCCESS'
const CLONE_OBSERVATIONAL_PROTOCOL_FAILED = 'CLONE_OBSERVATIONAL_PROTOCOL_FAILED'

export const cloneProtocol = (id, name, description) => (dispatch) => {
	if (!name) {
		return dispatch({ type: CLONE_OBSERVATIONAL_PROTOCOL_SUCCESS, data: null })
	}

	dispatch({ type: CLONE_OBSERVATIONAL_PROTOCOL_REQUESTED, data: {} })
	protocol.cloneObservationalProtocol(id, name, description).then((response) => {
		dispatch(stopSaving())
		if (response && response.isSuccessful) {
			dispatch(hideModal())
			history.push(`/protocols/${response.id}/details`)
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.successfullyUpdated', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.cloneProtocolFailed', isSuccess: false }
			})
		}
	})
	return
}

const SAVE_OBSERVATIONAL_PROTOCOL_REQUESTED = 'SAVE_OBSERVATIONAL_PROTOCOL_REQUESTED'
const SAVE_OBSERVATIONAL_PROTOCOL_SUCCESS = 'SAVE_OBSERVATIONAL_PROTOCOL_SUCCESS'
const SAVE_OBSERVATIONAL_PROTOCOL_FAILED = 'SAVE_OBSERVATIONAL_PROTOCOL_FAILED'

export const saveProtocol = (id, description, isPrivate) => (dispatch) => {
	dispatch({ type: SAVE_OBSERVATIONAL_PROTOCOL_REQUESTED, data: {} })
	protocol.saveObservationalProtocol(id, description, isPrivate).then((observationProtocol) => {
		dispatch(stopSaving())
		if (observationProtocol && observationProtocol.isSuccessful) {
			dispatch(hideModal())
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.successfullyUpdated', isSuccess: true }
			})
			return dispatch(fetchProtocol(id))
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.saveProtocolFailed', isSuccess: false }
			})
		}
	})
	return
}

const SAVE_OBSERVATIONAL_PROTOCOL_DEFAULTS_REQUESTED = 'SAVE_OBSERVATIONAL_PROTOCOL_DEFAULTS_REQUESTED'
const SAVE_OBSERVATIONAL_PROTOCOL_DEFAULTS_SUCCESS = 'SAVE_OBSERVATIONAL_PROTOCOL_DEFAULTS_SUCCESS'
const SAVE_OBSERVATIONAL_PROTOCOL_DEFAULTS_FAILED = 'SAVE_OBSERVATIONAL_PROTOCOL_DEFAULTS_FAILED'

export const saveProtocolSettings = (id, versionId, values) => (dispatch) => {
	dispatch({ type: SAVE_OBSERVATIONAL_PROTOCOL_DEFAULTS_REQUESTED, data: {} })
	protocol.saveObservationalProtocolSettings(id, versionId, values).then((observationProtocol) => {
		dispatch(stopSaving())
		if (observationProtocol && observationProtocol.isSuccessful) {
			dispatch(hideModal())
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.successfullyUpdated', isSuccess: true }
			})
			return dispatch(versionId ? fetchProtocolVersion(id, versionId) : fetchProtocol(id))
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.saveProtocolFailed', isSuccess: false }
			})
		}
	})
	return
}

export const editProtocolName = (id, name, alias, versionId) => (dispatch) => {
	if (!name) {
		return dispatch({ type: SAVE_OBSERVATIONAL_PROTOCOL_SUCCESS, data: null })
	}

	dispatch({ type: SAVE_OBSERVATIONAL_PROTOCOL_REQUESTED, data: {} })
	protocol.saveObservationalProtocolName(id, name, alias, versionId).then((observationProtocol) => {
		if (observationProtocol && observationProtocol.isSuccessful) {
			dispatch(hideModal())
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.successfullyUpdated', isSuccess: true }
			})
			dispatch(stopSaving())
			return dispatch(versionId ? fetchProtocolVersion(id, versionId) : fetchProtocol(id))
		} else {
            dispatch(stopSaving())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.saveProtocolFailed', isSuccess: false }
			})
		}
	})
	return
}

export const editSurveyCustomName = (protocolId, surveyId, instanceId, versionId, name = null) => (dispatch) => {
	dispatch({ type: SAVE_OBSERVATIONAL_PROTOCOL_REQUESTED, data: {} })
	
	protocol.saveSurveyCustomName(instanceId, versionId, name).then((observationProtocol) => {
		let successMessage = name?.length > 0 ? 'app.general.successfullyUpdated' : 'app.general.resetSurveyNameSuccess'
		let failMessage = name?.length > 0 ? 'app.protocol.saveProtocolFailed' : 'app.protocol.resetSurveyNameFailed'

		if (observationProtocol && observationProtocol.isSuccessful) {
			dispatch(hideModal())

            dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: successMessage, isSuccess: true }
			})

			dispatch(stopSaving())

			dispatch(fetchProtocolVersion(protocolId, versionId))

			dispatch(fetchSurveyInstance(protocolId, surveyId, instanceId, versionId))

            dispatch(fetchSurvey(surveyId, instanceId, versionId))

            return;

        } else {
			dispatch(stopSaving())

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: failMessage, isSuccess: false }
			})
		}
	})
	return
}

const FETCH_OBSERVATIONAL_PROTOCOL_REQUESTED = 'FETCH_OBSERVATIONAL_PROTOCOL_REQUESTED'

export const clearProtocol = () => (dispatch) => {
	return Promise.all([ dispatch({ type: FETCH_OBSERVATIONAL_PROTOCOL_REQUESTED, data: null }) ])
}

export const clearProtocolDetails = (id) => (dispatch) => {
	return dispatch({ type: FETCH_OBSERVATIONAL_PROTOCOL_DETAILS_SUCCESS, data: {} })
}

const FETCH_OBSERVATIONAL_PROTOCOL_DETAILS_SUCCESS = 'FETCH_OBSERVATIONAL_PROTOCOL_DETAILS_SUCCESS'

export const fetchProtocolDetails = (id, versionId) => (dispatch) => {
	protocol.getObservationalProtocolDetail(id, versionId).then((observationProtocol) => {
		if (observationProtocol && observationProtocol.isSuccessful) {
			return dispatch({ type: FETCH_OBSERVATIONAL_PROTOCOL_DETAILS_SUCCESS, data: observationProtocol })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.fetchProtocolFailed', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_OBSERVATIONAL_PROTOCOL_SUCCESS = 'FETCH_OBSERVATIONAL_PROTOCOL_SUCCESS'
const FETCH_OBSERVATIONAL_PROTOCOL_FAILED = 'FETCH_OBSERVATIONAL_PROTOCOL_FAILED'

export const fetchProtocol = (id) => (dispatch) => {
	if (!id) {
		return dispatch({ type: FETCH_OBSERVATIONAL_PROTOCOL_SUCCESS, data: null })
	}

	protocol.getObservationalProtocol(id).then((observationProtocol) => {
		if (observationProtocol && observationProtocol.isSuccessful) {
			return dispatch({ type: FETCH_OBSERVATIONAL_PROTOCOL_SUCCESS, data: observationProtocol })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.fetchProtocolFailed', isSuccess: false }
			})
		}
	})
	return
}

export const fetchProtocolByCircle = (id, circleId) => (dispatch) => {
	if (!id) {
		return dispatch({ type: FETCH_OBSERVATIONAL_PROTOCOL_SUCCESS, data: null })
	}

	protocol.getObservationalProtocolByCircle(id,circleId).then((observationProtocol) => {
		if (observationProtocol && observationProtocol.isSuccessful) {
			return dispatch({ type: FETCH_OBSERVATIONAL_PROTOCOL_SUCCESS, data: observationProtocol })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.fetchProtocolFailed', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_OBSERVATIONAL_PROTOCOL_VERSION_REQUESTED = 'FETCH_OBSERVATIONAL_PROTOCOL_VERSION_REQUESTED'
const FETCH_OBSERVATIONAL_PROTOCOL_VERSION_SUCCESS = 'FETCH_OBSERVATIONAL_PROTOCOL_VERSION_SUCCESS'
const FETCH_OBSERVATIONAL_PROTOCOL_VERSION_FAILED = 'FETCH_OBSERVATIONAL_PROTOCOL_VERSION_FAILED'

export const fetchProtocolVersion = (id, versionId) => (dispatch) => {
	dispatch({ type: FETCH_OBSERVATIONAL_PROTOCOL_VERSION_REQUESTED, data: {}})
	if (!id) {
		return dispatch({ type: FETCH_OBSERVATIONAL_PROTOCOL_VERSION_SUCCESS, data: null })
	}

	protocol.getObservationalProtocolVersion(id, versionId).then((observationProtocol) => {
		if (observationProtocol && observationProtocol.isSuccessful) {
			return dispatch({ type: FETCH_OBSERVATIONAL_PROTOCOL_VERSION_SUCCESS, data: observationProtocol })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.fetchProtocolFailed', isSuccess: false }
			})
		}
	})
	return
}

export const openProtocolRootVersion = (id) => (dispatch) => {
	protocol.getObservationalProtocolRootVersion(id).then((response) => {
		if (response) {
			history.push(`/protocols/${id}/version/${response}/details`)
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.fetchProtocolFailed', isSuccess: false }
			})
		}
	})
	return
}

const CLEAR_OBSERVATIONAL_PROTOCOLS_REQUESTED = 'CLEAR_OBSERVATIONAL_PROTOCOLS_REQUESTED'

export const clearProtocols = () => (dispatch) => {
	return dispatch({ type: CLEAR_OBSERVATIONAL_PROTOCOLS_REQUESTED, data: null })
}

const FETCH_OBSERVATIONAL_PROTOCOLS_SUCCESS = 'FETCH_OBSERVATIONAL_PROTOCOLS_SUCCESS'
const FETCH_OBSERVATIONAL_PROTOCOLS_FAILED = 'FETCH_OBSERVATIONAL_PROTOCOLS_FAILED'
export const fetchProtocols = () => (dispatch) => {
	dispatch({ type: CLEAR_OBSERVATIONAL_PROTOCOLS_REQUESTED, data: {} })
	protocol.getObservationalProtocols().then((observationProtocols) => {
		if (observationProtocols && observationProtocols.isSuccessful) {
			return dispatch({ type: FETCH_OBSERVATIONAL_PROTOCOLS_SUCCESS, data: {items: observationProtocols.data} })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.fetchProtocolsFailed', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_RELATED_OBSERVATIONAL_PROTOCOLS_REQUESTED = 'FETCH_RELATED_OBSERVATIONAL_PROTOCOLS_REQUESTED'
const FETCH_RELATED_OBSERVATIONAL_PROTOCOLS_SUCCESS = 'FETCH_RELATED_OBSERVATIONAL_PROTOCOLS_SUCCESS'
const FETCH_RELATED_OBSERVATIONAL_PROTOCOLS_FAILED = 'FETCH_RELATED_OBSERVATIONAL_PROTOCOLS_FAILED'
export const fetchRelatedProtocols = (baseId) => (dispatch) => {
	dispatch({ type: FETCH_RELATED_OBSERVATIONAL_PROTOCOLS_REQUESTED, data: {} })
	protocol.getRelatedObservationalProtocols(baseId).then((observationProtocols) => {
		if (observationProtocols && observationProtocols.isSuccessful) {
			return dispatch({ type: FETCH_RELATED_OBSERVATIONAL_PROTOCOLS_SUCCESS, data: observationProtocols.rows })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.fetchProtocolsFailed', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_OBSERVATIONAL_PROTOCOLS_WITH_METRICS_REQUESTED = 'FETCH_OBSERVATIONAL_PROTOCOLS_WITH_METRICS_REQUESTED'
const FETCH_OBSERVATIONAL_PROTOCOLS_WITH_METRICS_SUCCESS = 'FETCH_OBSERVATIONAL_PROTOCOLS_WITH_METRICS_SUCCESS'
const FETCH_OBSERVATIONAL_PROTOCOLS_WITH_METRICS_FAILED = 'FETCH_OBSERVATIONAL_PROTOCOLS_WITH_METRICS_FAILED'
export const fetchProtocolsWithMetrics = (searchPhrase) => (dispatch, getState) => {
	let state = getState()
	dispatch({ type: FETCH_OBSERVATIONAL_PROTOCOLS_WITH_METRICS_REQUESTED, data: {} })
	protocol.getObservationalProtocolsWithMetrics(searchPhrase, state.protocol.filter).then((observationProtocols) => {
		if (observationProtocols && observationProtocols.isSuccessful && observationProtocols.rows) {
			let data = observationProtocols.rows
			return dispatch({ 
				type: FETCH_OBSERVATIONAL_PROTOCOLS_SUCCESS, 
				data: {
					items: data,
					hasMore: observationProtocols.hasMore
				} 
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.fetchProtocolsFailed', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_ADDITIONAL_OBSERVATIONAL_PROTOCOLS_WITH_METRICS_REQUESTED = 'FETCH_ADDITIONAL_OBSERVATIONAL_PROTOCOLS_WITH_METRICS_REQUESTED'
const FETCH_ADDITIONAL_OBSERVATIONAL_PROTOCOLS_WITH_METRICS_SUCCESS = 'FETCH_ADDITIONAL_OBSERVATIONAL_PROTOCOLS_WITH_METRICS_SUCCESS'
const FETCH_ADDITIONAL_OBSERVATIONAL_PROTOCOLS_WITH_METRICS_FAILED = 'FETCH_ADDITIONAL_OBSERVATIONAL_PROTOCOLS_WITH_METRICS_FAILED'
export const fetchAdditionalProtocolsWithMetrics = (searchPhrase, offset) => (dispatch, getState) => {
	let state = getState()
	dispatch({ type: FETCH_ADDITIONAL_OBSERVATIONAL_PROTOCOLS_WITH_METRICS_REQUESTED, data: {} })
	protocol.getObservationalProtocolsWithMetrics(searchPhrase, state.protocol.filter, offset).then((observationProtocols) => {
		if (observationProtocols && observationProtocols.isSuccessful && observationProtocols.rows) {
			let data = observationProtocols.rows
			return dispatch({ 
				type: FETCH_ADDITIONAL_OBSERVATIONAL_PROTOCOLS_WITH_METRICS_SUCCESS, 
				data: {
					items: data,
					hasMore: observationProtocols.hasMore
				} 
			})
		} else {
			dispatch({ type: FETCH_ADDITIONAL_OBSERVATIONAL_PROTOCOLS_WITH_METRICS_FAILED, data: {}})
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.fetchProtocolsFailed', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_SURVEY_INSTANCE_REQUESTED = 'FETCH_SURVEY_INSTANCE_REQUESTED'
const FETCH_SURVEY_INSTANCE_SUCCESS = 'FETCH_SURVEY_INSTANCE_SUCCESS'
const FETCH_SURVEY_INSTANCE_FAILED = 'FETCH_SURVEY_INSTANCE_FAILED'

export const fetchSurveyInstance = (protocolId, surveyId, instanceId, versionId) => (dispatch) => {
	dispatch({ type: FETCH_SURVEY_INSTANCE_REQUESTED, data: {} })
	protocol.fetchSurveyInstance(protocolId, surveyId, instanceId, versionId).then((instance) => {
		if (instance && instance.isSuccessful) {
			return dispatch({ type: FETCH_SURVEY_INSTANCE_SUCCESS, data: instance })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.fetchProtocolsFailed', isSuccess: false }
			})
		}
	})
	return
}

const SAVE_SURVEY_INSTANCE_REQUESTED = 'SAVE_SURVEY_INSTANCE_REQUESTED'
const SAVE_SURVEY_INSTANCE_SUCCESS = 'SAVE_SURVEY_INSTANCE_SUCCESS'
const SAVE_SURVEY_INSTANCE_FAILED = 'SAVE_SURVEY_INSTANCE_FAILED'

export const saveSurveyInstance = (protocolId, surveyId, instanceId, data, versionId, commentsObj) => (dispatch) => {
	dispatch({ type: SAVE_SURVEY_INSTANCE_REQUESTED, data: {} })

	protocol.saveSurveyInstance(protocolId, surveyId, instanceId, data, versionId, commentsObj).then((instance) => {
		dispatch(stopSaving())
		if (instance && instance.isSuccessful) {
			dispatch(versionId ? fetchProtocolVersion(protocolId, versionId) : fetchProtocol(protocolId, instanceId, versionId))
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.saveSettingsSuccess', isSuccess: true }
			})
			return dispatch({ type: SAVE_SURVEY_INSTANCE_SUCCESS, data: instance })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.saveSettingsFailed', isSuccess: false }
			})
		}
	})
	return
}

const CREATE_FROM_EXISTING_REQUESTED = 'CREATE_FROM_EXISTING_REQUESTED'
const CREATE_FROM_EXISTING_SUCCESS = 'CREATE_FROM_EXISTING_SUCCESS'
const CREATE_FROM_EXISTING_FAILED = 'CREATE_FROM_EXISTING_FAILED'

export const createFromExisting = (protocolId, versionId, circleId, userId) => (dispatch) => {
	dispatch({ type: CREATE_FROM_EXISTING_REQUESTED, data: {} })

	protocol.createFromExisting(versionId, circleId, userId).then((result) => {
		dispatch(stopSaving())
		if (result && result.isSuccessful) {
			history.push(`/protocols/${protocolId}/version/${result.newVersionId}/details`)
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.createFromExistingSuccess', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.createFromExistingFailed', isSuccess: false }
			})
		}
	})
	return
}

const SWAP_SURVEY_POSITION_REQUESTED = 'SWAP_SURVEY_POSITION_REQUESTED'
const SWAP_SURVEY_POSITION_SUCCESS = 'SWAP_SURVEY_POSITION_SUCCESS'
const SWAP_SURVEY_POSITION_FAILED = 'SWAP_SURVEY_POSITION_FAILED'
export const updateSurveyPositions = (protocolId, versionId, surveys) => (dispatch) => {
	dispatch({ type: SWAP_SURVEY_POSITION_REQUESTED, data: {} })
	let s = []

	if (
		surveys &&
		surveys.map((r) => {
			s.push({ surveyInstanceId: r.data.surveyInstanceId, sortOrder: r.data.sortOrder, isInitiator: r.data.isInitiator })
		})
	)
		protocol.updateSurveyPositions(protocolId, versionId, s).then((survey) => {
			if (survey && survey.isSuccessful) {
				dispatch({ type: SWAP_SURVEY_POSITION_SUCCESS, data: survey })

				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.protocol.swapSurveyPositionSuccess', isSuccess: true }
				})
			} else {
				dispatch(fetchProtocol(protocolId))
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.protocol.swapSurveyPositionFailed', isSuccess: false }
				})
			}
		})
	return
}

const REMOVE_SURVEY_INSTANCE_REQUESTED = 'REMOVE_SURVEY_INSTANCE_REQUESTED'
const REMOVE_SURVEY_INSTANCE_SUCCESS = 'REMOVE_SURVEY_INSTANCE_SUCCESS'
const REMOVE_SURVEY_INSTANCE_FAILED = 'REMOVE_SURVEY_INSTANCE_FAILED'
export const removeSurveyInstance = (protocolId, surveyId, instanceId) => (dispatch) => {
	dispatch({ type: REMOVE_SURVEY_INSTANCE_REQUESTED, data: {} })
	let s = []

	protocol.deleteSurveyInstance(protocolId, surveyId, instanceId).then((survey) => {
		if (survey && survey.isSuccessful) {
			history.push(`/protocols/${protocolId}/details`)
			return dispatch({ type: REMOVE_SURVEY_INSTANCE_SUCCESS, data: survey })
		} else {
			dispatch(fetchProtocol(protocolId))
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.swapSurveyPositionFailed', isSuccess: false }
			})
		}
	})
	return
}

const CHANGE_SURVEY_INSTANCE_STATE_REQUESTED = 'CHANGE_SURVEY_INSTANCE_STATE_REQUESTED'
const CHANGE_SURVEY_INSTANCE_STATE_SUCCESS = 'CHANGE_SURVEY_INSTANCE_STATE_SUCCESS'
const CHANGE_SURVEY_INSTANCE_STATE_FAILED = 'CHANGE_SURVEY_INSTANCE_STATE_FAILED'
export const changeSurveyInstanceState = (protocolId, versionId, instanceId, state, commentsObj) => (dispatch) => {
	dispatch({ type: CHANGE_SURVEY_INSTANCE_STATE_REQUESTED, data: {} })
	let s = []

	protocol.changeSurveyInstanceState(protocolId, versionId, instanceId, state, commentsObj).then((survey) => {
		if (survey && survey.isSuccessful) {
			history.push(`/protocols/${protocolId}/version/${versionId}/details`)
			return dispatch({ type: CHANGE_SURVEY_INSTANCE_STATE_SUCCESS, data: survey })
		} else {
			dispatch(fetchProtocol(protocolId))
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.chaangeSurveyStateFailed', isSuccess: false }
			})
		}
	})
	return
}

const REVERT_SURVEY_REQUESTED = 'REVERT_SURVEY_REQUESTED'
export const revertLoadedSurvey = () => (dispatch) => {
	return dispatch({ type: REVERT_SURVEY_REQUESTED, data: {} })
}

const UPDATE_SURVEY_DELEGATE_REQUESTED = 'UPDATE_SURVEY_DELEGATE_REQUESTED'
export const updateSurveyDelegateId = (id) => (dispatch) => {
	return dispatch({ type: UPDATE_SURVEY_DELEGATE_REQUESTED, data: { id } })
}

const UPDATE_SURVEY_TREATMENT_PHASE_REQUESTED = 'UPDATE_SURVEY_TREATMENT_PHASE_REQUESTED'
export const updateSurveyTreatmentPhaseId = (id) => (dispatch) => {
	return dispatch({ type: UPDATE_SURVEY_TREATMENT_PHASE_REQUESTED, data: { id } })
}

const UPDATE_SURVEY_REMINDER_VALUE_REQUESTED = 'UPDATE_SURVEY_REMINDER_VALUE_REQUESTED'
export const updateSurveyReminderValue = (value) => (dispatch) => {
	return dispatch({ type: UPDATE_SURVEY_REMINDER_VALUE_REQUESTED, data: { value } })
}

const UPDATE_SURVEY_REMINDER_UNIT_REQUESTED = 'UPDATE_SURVEY_REMINDER_UNIT_REQUESTED'
export const updateSurveyReminderUnit = (unit) => (dispatch) => {
	return dispatch({ type: UPDATE_SURVEY_REMINDER_UNIT_REQUESTED, data: { unit } })
}

const SET_PROTOCOL_SORT_REQUESTED = 'SET_PROTOCOL_SORT_REQUESTED'

export const setProtocolsSort = (field, isDescending) => (dispatch, state) => {
	let currentState = state()

	let data = currentState.protocol.observationalProtocols.sort((a, b) => {
		if (isDescending) {
			return a[field] > b[field] ? -1 : 1
		} else {
			return b[field] > a[field] ? -1 : 1
		}
	})
	return dispatch({
		type: SET_PROTOCOL_SORT_REQUESTED,
		data: { items: data, field: field, isDescending: isDescending }
	})
}

const SET_PROTOCOLS_FILTER_REQUESTED = 'SET_PROTOCOLS_FILTER_REQUESTED'
const SET_PROTOCOLS_FILTER_SUCCESS = 'SET_PROTOCOLS_FILTER_SUCCESS'
const SET_PROTOCOLS_FILTER_FAILED = 'SET_PROTOCOLS_FILTER_FAILED'

export const setProtocolFilter = (filter) => (dispatch, getState) => {
	let state = getState()
	dispatch({ type: SET_PROTOCOLS_FILTER_REQUESTED, data: {} })

	dispatch({ type: SET_PROTOCOLS_FILTER_SUCCESS, data: filter })
	return dispatch(fetchProtocolsWithMetrics(state.protocol.searchTerm))
}

const SET_PROTOCOLS_SURVEYS_FILTER_REQUESTED = 'SET_PROTOCOLS_SURVEYS_FILTER_REQUESTED'
const SET_PROTOCOLS_SURVEYS_FILTER_SUCCESS = 'SET_PROTOCOLS_SURVEYS_FILTER_SUCCESS'
const SET_PROTOCOLS_SURVEYS_FILTER_FAILED = 'SET_PROTOCOLS_SURVEYS_FILTER_FAILED'

export const setProtocolSurveyFilter = (filter) => (dispatch) => {
	dispatch({ type: SET_PROTOCOLS_SURVEYS_FILTER_REQUESTED, data: {} })

	return dispatch({ type: SET_PROTOCOLS_SURVEYS_FILTER_SUCCESS, data: filter })
}

const SET_SHOW_ARCHIVED_SURVEYS_FILTER_REQUESTED = 'SET_SHOW_ARCHIVED_SURVEYS_FILTER_REQUESTED'
const SET_SHOW_ARCHIVED_SURVEYS_FILTER_SUCCESS = 'SET_SHOW_ARCHIVED_SURVEYS_FILTER_SUCCESS'
const SET_SHOW_ARCHIVED_SURVEYS_FILTER_FAILED = 'SET_SHOW_ARCHIVED_SURVEYS_FILTER_FAILED'

export const setShowArchivedSurveysFilter = (filter) => (dispatch) => {
	dispatch({ type: SET_SHOW_ARCHIVED_SURVEYS_FILTER_REQUESTED, data: {} })

	return dispatch({ type: SET_SHOW_ARCHIVED_SURVEYS_FILTER_SUCCESS, data: filter })
}

const FETCH_PROTOCOL_AUTOCOMPLETE_REQUESTED = 'FETCH_PROTOCOL_AUTOCOMPLETE_REQUESTED'
const FETCH_PROTOCOL_AUTOCOMPLETE_SUCCESS = 'FETCH_PROTOCOL_AUTOCOMPLETE_SUCCESS'
const FETCH_PROTOCOL_AUTOCOMPLETE_FAILED = 'FETCH_PROTOCOL_AUTOCOMPLETE_FAILED'

export const fetchProtocolAutoComplete = (searchPhrase, entityId, entityTypeId) => (dispatch) => {
	dispatch({ type: FETCH_PROTOCOL_AUTOCOMPLETE_REQUESTED, data: {} })
	protocol.fetchProtocolAutoComplete(searchPhrase, entityId, entityTypeId).then((protocols) => {
		if (protocols) {
			return dispatch({ type: FETCH_PROTOCOL_AUTOCOMPLETE_SUCCESS, data: protocols.protocols })
		} else {
			return dispatch({ type: FETCH_PROTOCOL_AUTOCOMPLETE_FAILED, data: {} })
		}
	})
	return
}

const FETCH_CIRCLES_REQUESTED = 'FETCH_CIRCLES_REQUESTED'
const FETCH_CIRCLES_SUCCESS = 'FETCH_CIRCLES_SUCCESS'
const FETCH_CIRCLES_FAILED = 'FETCH_CIRCLES_FAILED'

export const fetchProtocolCircles = (protocolId, searchPhrase) => (dispatch) => {
	dispatch({ type: FETCH_CIRCLES_REQUESTED, data: {} })
	protocol.getCircles(protocolId, searchPhrase).then((circles) => {
		if (circles) {
			return dispatch({ type: FETCH_CIRCLES_SUCCESS, data: circles.data })
		} else {
			return dispatch({ type: FETCH_CIRCLES_FAILED, data: {} })
		}
	})
	return
}

const FETCH_SUBSCRIBERS_REQUESTED = 'FETCH_SUBSCRIBERS_REQUESTED'
const FETCH_SUBSCRIBERS_SUCCESS = 'FETCH_SUBSCRIBERS_SUCCESS'
const FETCH_SUBSCRIBERS_FAILED = 'FETCH_SUBSCRIBERS_FAILED'

export const fetchProtocolSubscribers = (protocolId, searchPhrase) => (dispatch) => {
	dispatch({ type: FETCH_SUBSCRIBERS_REQUESTED, data: {} })
	protocol.getSubscribers(protocolId, searchPhrase).then((subscribers) => {
		if (subscribers) {
			return dispatch({ type: FETCH_SUBSCRIBERS_SUCCESS, data: subscribers.data })
		} else {
			return dispatch({ type: FETCH_SUBSCRIBERS_FAILED, data: {} })
		}
	})
	return
}

const FETCH_VERSIONS_REQUESTED = 'FETCH_VERSIONS_REQUESTED'
const FETCH_VERSIONS_SUCCESS = 'FETCH_VERSIONS_SUCCESS'
const FETCH_VERSIONS_FAILED = 'FETCH_VERSIONS_FAILED'

export const fetchProtocolVersions = (protocolId, searchPhrase, showArchived, offset) => (dispatch) => {
	dispatch({ type: FETCH_VERSIONS_REQUESTED, data: {} })
	protocol.getVersions(protocolId, searchPhrase, showArchived, offset).then((versions) => {
		if (versions) {
			return dispatch({
				type: FETCH_VERSIONS_SUCCESS,
				data: {
					items: versions.data,
					hasMore: versions.hasMore
				}
			})
		} else {
			return dispatch({ type: FETCH_VERSIONS_FAILED, data: {} })
		}
	})
	return
}

const FETCH_ADDITIONAL_VERSIONS_REQUESTED = 'FETCH_ADDITIONAL_VERSIONS_REQUESTED'
const FETCH_ADDITIONAL_VERSIONS_SUCCESS = 'FETCH_ADDITIONAL_VERSIONS_SUCCESS'
const FETCH_ADDITIONAL_VERSIONS_FAILED = 'FETCH_ADDITIONAL_VERSIONS_FAILED'

export const fetchAdditionalProtocolVersions = (protocolId, searchPhrase, showArchived, offset) => (dispatch) => {
	dispatch({ type: FETCH_ADDITIONAL_VERSIONS_REQUESTED, data: {} })
	protocol.getVersions(protocolId, searchPhrase, showArchived, offset).then((versions) => {
		if (versions) {
			return dispatch({
				type: FETCH_ADDITIONAL_VERSIONS_SUCCESS,
				data: {
					items: versions.data,
					hasMore: versions.hasMore
				}
			})
		} else {
			return dispatch({ type: FETCH_ADDITIONAL_VERSIONS_FAILED, data: {} })
		}
	})
	return
}

const FETCH_SUB_VERSIONS_REQUESTED = 'FETCH_SUB_VERSIONS_REQUESTED'
const FETCH_SUB_VERSIONS_SUCCESS = 'FETCH_SUB_VERSIONS_SUCCESS'
const FETCH_SUB_VERSIONS_FAILED = 'FETCH_SUB_VERSIONS_FAILED'

export const fetchProtocolSubVersions = (versionId, searchPhrase, offset) => (dispatch) => {
	dispatch({ type: FETCH_SUB_VERSIONS_REQUESTED, data: {} })
	protocol.getSubVersions(versionId, searchPhrase, offset).then((versions) => {
		if (versions) {
			return dispatch({
				type: FETCH_SUB_VERSIONS_SUCCESS,
				data: {
					items: versions.data,
					hasMore: versions.hasMore
				}
			})
		} else {
			return dispatch({ type: FETCH_SUB_VERSIONS_FAILED, data: {} })
		}
	})
	return
}

const FETCH_SURVEY_QUESTIONS_REQUESTED = 'FETCH_SURVEY_QUESTIONS_REQUESTED'
const FETCH_SURVEY_QUESTIONS_SUCCESS = 'FETCH_SURVEY_QUESTIONS_SUCCESS'
const FETCH_SURVEY_QUESTIONS_FAILED = 'FETCH_SURVEY_QUESTIONS_FAILED'
export const fetchSurveyQuestions = (observationProtocolSurveyId, circleId) => (dispatch) => {
	dispatch({ type: FETCH_SURVEY_QUESTIONS_REQUESTED, data: {} })

	protocol.getSurveyQuestionsForCircle(observationProtocolSurveyId, circleId).then((survey) => {
		// if (questions && questions.isSuccessful) {
		// 	if (questions.questions && questions.questions.length > 0) {
		// 		questions.questions.map((r, i) => {
		// 			r.sortOrder = i
		// 			r.data = JSON.parse(r.data)
		// 		})
		// 	}
		// 	return dispatch({
		// 		type: FETCH_SURVEY_QUESTIONS_SUCCESS,
		// 		data: { questions: questions.questions }
		// 	})
		// } else {
		// 	return dispatch({
		// 		type: 'SET_SNACKBAR_MESSAGE',
		// 		data: { message: 'app.protocol.fetchSurveyFailed', isSuccess: false }
		// 	})
		// }
		if (survey && survey.isSuccessful) {
			let questions = new Array(0)
			let hash = {}
			let container = new ListContainer()

			processSurveyQuestions(survey, container, questions, hash)
			return dispatch({
				type: FETCH_SURVEY_QUESTIONS_SUCCESS,
				data: { survey: survey, list: container, flat: questions, nodes: hash }
			})
		} else {
			dispatch({
				type: FETCH_SURVEY_QUESTIONS_FAILED,
				data: {}
			})
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.fetchSurveyFailed', isSuccess: false }
			})
		}
	})
	return
}

const processSurveyQuestions = (survey, container, questions, hash, languageId) => {
	if (survey.questions) {
		let q = survey.questions.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1)).map((r, i) => {
			r.sortOrder = i

			if (isNaN(r.id)) {
				r.id = uuidv4().replace('-', '')
			}
			return r
		})
		q.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1)).map((r, i) => {
			container.getList().append(r)
		})

		container.getList().traverse((r) => {
			questions.push(r.data.sortOrder)
			hash[r.data.sortOrder] = r
		})
	}
}

const DEACTIVATE_PROTOCOL_REQUESTED = 'DEACTIVATE_PROTOCOL_REQUESTED'
const DEACTIVATE_PROTOCOL_SUCCESS = 'DEACTIVATE_PROTOCOL_SUCCESS'
const DEACTIVATE_PROTOCOL_FAILED = 'DEACTIVATE_PROTOCOL_FAILED'
export const deactivate = (id) => (dispatch) => {
	dispatch({ type: DEACTIVATE_PROTOCOL_REQUESTED, data: {} })
	protocol.deactivate(id).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.deactivateSuccess', isSuccess: true }
			})
			return dispatch(fetchProtocolsWithMetrics())
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: `app.errorMessages.${response && response.errorCode ? response.errorCode : 0}`,
					isSuccess: false
				}
			})
		}
	})
	return
}

const ACTIVATE_PROTOCOL_REQUESTED = 'ACTIVATE_PROTOCOL_REQUESTED'
const ACTIVATE_PROTOCOL_SUCCESS = 'ACTIVATE_PROTOCOL_SUCCESS'
const ACTIVATE_PROTOCOL_FAILED = 'ACTIVATE_PROTOCOL_FAILED'
export const activate = (id) => (dispatch) => {
	dispatch({ type: ACTIVATE_PROTOCOL_REQUESTED, data: {} })

	protocol.activate(id).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.activateSuccess', isSuccess: true }
			})
			return dispatch(fetchProtocolsWithMetrics())
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: `app.errorMessages.${response && response.errorCode ? response.errorCode : 0}`,
					isSuccess: false
				}
			})
		}
	})
	return
}

const SET_PROTOCOL_SEARCH_TERM_REQUESTED = 'SET_PROTOCOL_SEARCH_TERM_REQUESTED'
const SET_PROTOCOL_SEARCH_TERM_SUCCESS = 'SET_PROTOCOL_SEARCH_TERM_SUCCESS'
const SET_PROTOCOL_SEARCH_TERM_FAILED = 'SET_PROTOCOL_SEARCH_TERM_FAILED'

export const setProtocolSearchTerm = (value) => (dispatch) => {
	dispatch({ type: SET_PROTOCOL_SEARCH_TERM_REQUESTED, data: {} })
	return dispatch({ type: SET_PROTOCOL_SEARCH_TERM_SUCCESS, data: value })
}


const ADD_OBSERVATIONAL_PROTOCOL_PERMISSION_REQUESTED = 'ADD_OBSERVATIONAL_PROTOCOL_PERMISSION_REQUESTED'
const ADD_OBSERVATIONAL_PROTOCOL_PERMISSION_SUCCESS = 'ADD_OBSERVATIONAL_PROTOCOL_PERMISSION_SUCCESS'
const ADD_OBSERVATIONAL_PROTOCOL_PERMISSION_FAILED = 'ADD_OBSERVATIONAL_PROTOCOL_PERMISSION_FAILED'

export const addPermission = (protocolId, versionId, serviceProviderId, commentsObj) => (dispatch) => {
	dispatch({ type: ADD_OBSERVATIONAL_PROTOCOL_PERMISSION_REQUESTED, data: {} })
	protocol.addPermission(protocolId, versionId, serviceProviderId, commentsObj).then((observationProtocol) => {
		dispatch(stopSaving())
		if (observationProtocol && observationProtocol.isSuccessful) {
			dispatch(hideModal())
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.successfullyUpdated', isSuccess: true }
			})
			return dispatch(versionId ? fetchProtocolVersion(protocolId, versionId) : fetchProtocol(protocolId))
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.saveProtocolFailed', isSuccess: false }
			})
		}
	})
	return
}

const MAKE_PROTOCOL_VERSION_DEFAULT_REQUESTED = 'MAKE_PROTOCOL_VERSION_DEFAULT_REQUESTED'
const MAKE_PROTOCOL_VERSION_DEFAULT_SUCCESS = 'MAKE_PROTOCOL_VERSION_DEFAULT_SUCCESS'
const MAKE_PROTOCOL_VERSION_DEFAULT_FAILED = 'MAKE_PROTOCOL_VERSION_DEFAULT_FAILED'

export const makeProtocolVersionDefault = (versionId) => (dispatch) => {
	dispatch({ type: MAKE_PROTOCOL_VERSION_DEFAULT_REQUESTED, data: {} })
	protocol.makeVersionDefault(versionId).then((response) => {
		dispatch(stopSaving())
		if (response && response.isSuccessful) {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.successfullyUpdated', isSuccess: true }
			})
			return dispatch(fetchProtocolSubVersions(versionId))
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: response.errorMessage || 'app.protocol.saveProtocolFailed', isSuccess: false }
			})
		}
	})
	return
}

const CHANGE_PROTOCOL_VERSION_VISIBILITY_REQUESTED = 'CHANGE_PROTOCOL_VERSION_VISIBILITY_REQUESTED'
const CHANGE_PROTOCOL_VERSION_VISIBILITY_SUCCESS = 'CHANGE_PROTOCOL_VERSION_VISIBILITY_SUCCESS'
const CHANGE_PROTOCOL_VERSION_VISIBILITY_FAILED = 'CHANGE_PROTOCOL_VERSION_VISIBILITY_FAILED'

export const changeProtocolVersionCustomerVisibility = (versionId) => (dispatch) => {
	dispatch({ type: CHANGE_PROTOCOL_VERSION_VISIBILITY_REQUESTED, data: {} })
	protocol.changeVersionCustomerVisibility(versionId).then((response) => {
		dispatch(stopSaving())
		if (response && response.isSuccessful) {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.successfullyUpdated', isSuccess: true }
			})
			return dispatch(fetchProtocolSubVersions(versionId))
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: response.errorMessage || 'app.protocol.saveProtocolFailed', isSuccess: false }
			})
		}
	})
	return
}

const CREATE_NEW_PROTOCOL_SUBVERSION_REQUESTED = 'CREATE_NEW_PROTOCOL_SUBVERSION_REQUESTED'
const CREATE_NEW_PROTOCOL_SUBVERSION_SUCCESS = 'CREATE_NEW_PROTOCOL_SUBVERSION_SUCCESS'
const CREATE_NEW_PROTOCOL_SUBVERSION_FAILED = 'CREATE_NEW_PROTOCOL_SUBVERSION_FAILED'

export const createNewProtocolSubversion = (protocolId, versionId, values) => (dispatch) => {
	dispatch({ type: CREATE_NEW_PROTOCOL_SUBVERSION_REQUESTED, data: {} })
	protocol.createNewSubversion(versionId, values).then((response) => {
		dispatch(stopSaving())
		dispatch(hideModal())
		if (response && response.isSuccessful) {
			history.push(`/protocols/${protocolId}/version/${response.newVersionId}/details`)
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.createFromExistingSuccess', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.createFromExistingFailed', isSuccess: false }
			})
		}
	})
	return
}

const UPDATE_PROTOCOL_SUBVERSION_REQUESTED = 'UPDATE_PROTOCOL_SUBVERSION_REQUESTED'
const UPDATE_PROTOCOL_SUBVERSION_SUCCESS = 'UPDATE_PROTOCOL_SUBVERSION_SUCCESS'
const UPDATE_PROTOCOL_SUBVERSION_FAILED = 'UPDATE_PROTOCOL_SUBVERSION_FAILED'

export const updateProtocolSubversion = (versionId, values) => (dispatch) => {
	dispatch({ type: UPDATE_PROTOCOL_SUBVERSION_REQUESTED, data: {} })
	protocol.updateSubVersion(versionId, values).then((response) => {
		dispatch(stopSaving())
		dispatch(hideModal())
		if (response && response.isSuccessful) {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.successfullyUpdated', isSuccess: true }
			})
			return dispatch(fetchProtocolSubVersions(versionId))
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.saveProtocolFailed', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_SUB_VERSION_DETAILS_REQUESTED = 'FETCH_SUB_VERSION_DETAILS_REQUESTED'
const FETCH_SUB_VERSION_DETAILS_SUCCESS = 'FETCH_SUB_VERSION_DETAILS_SUCCESS'
const FETCH_SUB_VERSION_DETAILS_FAILED = 'FETCH_SUB_VERSION_DETAILS_FAILED'

export const fetchProtocolSubVersionDetails = (versionId) => (dispatch) => {
	dispatch({ type: FETCH_SUB_VERSION_DETAILS_REQUESTED, data: {} })
	protocol.getSubVersionDetails(versionId).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({
				type: FETCH_SUB_VERSION_DETAILS_SUCCESS,
				data: response
			})
		} else {
			return dispatch({ type: FETCH_SUB_VERSION_DETAILS_FAILED, data: {} })
		}
	})
	return
}

const COPY_PROTOCOL_VERSION_LAYOUT_REQUESTED = 'COPY_PROTOCOL_VERSION_LAYOUT_REQUESTED'
const COPY_PROTOCOL_VERSION_LAYOUT_SUCCESS = 'COPY_PROTOCOL_VERSION_LAYOUT_SUCCESS'
const COPY_PROTOCOL_VERSION_LAYOUT_FAILED = 'COPY_PROTOCOL_VERSION_LAYOUT_FAILED'

export const copyProtocolVersionLayout = (protocolId, sourceVersionId, targetVersionId) => (dispatch) => {
	dispatch({ type: COPY_PROTOCOL_VERSION_LAYOUT_REQUESTED, data: {} })
	protocol.copyVersionLayout(sourceVersionId, targetVersionId).then((response) => {
		dispatch(stopSaving())
		dispatch(hideModal())
		if (response && response.isSuccessful) {
			history.push(`/protocols/${protocolId}/version/${targetVersionId}/details`)
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.copyVersionLayoutSuccess', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.copyVersionLayoutFailed', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_PROTOCOLS_BY_QUESTION_REQUESTED = 'FETCH_PROTOCOLS_BY_QUESTION_REQUESTED'
const FETCH_PROTOCOLS_BY_QUESTION_SUCCESS = 'FETCH_PROTOCOLS_BY_QUESTION_SUCCESS'
const FETCH_PROTOCOLS_BY_QUESTION_FAILED = 'FETCH_PROTOCOLS_BY_QUESTION_FAILED'

export const fetchQuestionRelatedProtocols = (questionId, questionType) => (dispatch) => {
	dispatch({ type: FETCH_PROTOCOLS_BY_QUESTION_REQUESTED, data: null })

	protocol.getProtocolsByQuestion(questionId, questionType).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch(
				{
					type: FETCH_PROTOCOLS_BY_QUESTION_SUCCESS,
					data: response.rows
				}
			)
		} else {
			dispatch({ type: FETCH_PROTOCOLS_BY_QUESTION_FAILED, data: null })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.fetchProtocolsFailed', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_PROTOCOLS_BY_BUNDLE_REQUESTED = 'FETCH_PROTOCOLS_BY_BUNDLE_REQUESTED'
const FETCH_PROTOCOLS_BY_BUNDLE_SUCCESS = 'FETCH_PROTOCOLS_BY_BUNDLE_SUCCESS'
const FETCH_PROTOCOLS_BY_BUNDLE_FAILED = 'FETCH_PROTOCOLS_BY_BUNDLE_FAILED'

export const fetchBundleRelatedProtocols = (bundleId) => (dispatch) => {
	dispatch({ type: FETCH_PROTOCOLS_BY_BUNDLE_REQUESTED, data: null })

	protocol.getProtocolsByBundle(bundleId).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch(
				{
					type: FETCH_PROTOCOLS_BY_BUNDLE_SUCCESS,
					data: response.rows
				}
			)
		} else {
			dispatch({ type: FETCH_PROTOCOLS_BY_BUNDLE_FAILED, data: null })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.fetchProtocolsFailed', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_PROTOCOLS_BY_SURVEY_REQUESTED = 'FETCH_PROTOCOLS_BY_SURVEY_REQUESTED'
const FETCH_PROTOCOLS_BY_SURVEY_SUCCESS = 'FETCH_PROTOCOLS_BY_SURVEY_SUCCESS'
const FETCH_PROTOCOLS_BY_SURVEY_FAILED = 'FETCH_PROTOCOLS_BY_SURVEY_FAILED'

export const fetchSurveyRelatedProtocols = (bundleId) => (dispatch) => {
	dispatch({ type: FETCH_PROTOCOLS_BY_SURVEY_REQUESTED, data: null })

	protocol.getProtocolsBySurvey(bundleId).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch(
				{
					type: FETCH_PROTOCOLS_BY_SURVEY_SUCCESS,
					data: response.rows
				}
			)
		} else {
			dispatch({ type: FETCH_PROTOCOLS_BY_SURVEY_FAILED, data: null })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.fetchProtocolsFailed', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_AND_USER_REQUESTED = 'FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_AND_USER_REQUESTED'
const FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_AND_USER_SUCCESS = 'FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_AND_USER_SUCCESS'
const FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_AND_USER_FAILED = 'FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_AND_USER_FAILED'

export const fetchProtocolVersionsForCircleAndUser = (circleId, userId) => (dispatch) => {
	dispatch({ type: FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_AND_USER_REQUESTED, data: {} })

	protocol.fetchVersionsForCircleAndUser(circleId, userId).then((protocols) => {
		if (protocols) {
			return dispatch({ type: FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_AND_USER_SUCCESS, data: protocols.data })
		} else {
			return dispatch({ type: FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_AND_USER_FAILED, data: {} })
		}
	})
	return
}

const FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_REQUESTED = 'FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_REQUESTED'
const FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_SUCCESS = 'FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_SUCCESS'
const FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_FAILED = 'FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_FAILED'

export const fetchProtocolVersionsForCircle = (circleId) => (dispatch) => {
	dispatch({ type: FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_REQUESTED, data: {} })

	protocol.fetchVersionsForCircle(circleId).then((protocols) => {
		if (protocols) {
			dispatch({ type: FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_SUCCESS, data: protocols })
			dispatch(setSelectedComplianceProtocolVersion(protocols.find(x => x.userId === null)?.id))
		} else {
			return dispatch({ type: FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_FAILED, data: {} })
		}
	})
	return
}

const FETCH_PROTOCOL_VERSIONS_FOR_ENTITY_REQUESTED = 'FETCH_PROTOCOL_VERSIONS_FOR_ENTITY_REQUESTED'
const FETCH_PROTOCOL_VERSIONS_FOR_ENTITY_SUCCESS = 'FETCH_PROTOCOL_VERSIONS_FOR_ENTITY_SUCCESS'
const FETCH_PROTOCOL_VERSIONS_FOR_ENTITY_FAILED = 'FETCH_PROTOCOL_VERSIONS_FOR_ENTITY_FAILED'

export const fetchProtocolVersionsForEntity = (entityId, entityTypeId) => (dispatch) => {
	dispatch({ type: FETCH_PROTOCOL_VERSIONS_FOR_ENTITY_REQUESTED, data: {} })

	return protocol.fetchVersionsForEntity(entityId, entityTypeId).then((protocols) => {
		if (protocols) {
			dispatch({ type: FETCH_PROTOCOL_VERSIONS_FOR_ENTITY_SUCCESS, data: protocols })
			//dispatch(setSelectedProtocolVersionForEntityAudit(protocols.find(x => x.userId === null)?.id))
		} else {
			return dispatch({ type: FETCH_PROTOCOL_VERSIONS_FOR_ENTITY_FAILED, data: {} })
		}
	})
}

const FETCH_BILATERAL_AREAS_REQUESTED = 'FETCH_BILATERAL_AREAS_REQUESTED'
const FETCH_BILATERAL_AREAS_SUCCESS = 'FETCH_BILATERAL_AREAS_SUCCESS'
const FETCH_BILATERAL_AREAS_FAILED = 'FETCH_BILATERAL_AREAS_FAILED'

export const fetchBilateralAreas = (versionId, languageId) => (dispatch) => {
	dispatch({ type: FETCH_BILATERAL_AREAS_REQUESTED, data: {} })

	protocol.fetchBilateralAreas(versionId, languageId).then((data) => {
		if (data && data.isSuccessful) {
			return dispatch({ type: FETCH_BILATERAL_AREAS_SUCCESS, data: data })
		} else {
			return dispatch({ type: FETCH_BILATERAL_AREAS_FAILED, data: {} })
		}
	})
	return
}

const SAVE_BILATERAL_AREAS_REQUESTED = 'SAVE_BILATERAL_AREAS_REQUESTED'
const SAVE_BILATERAL_AREAS_SUCCESS = 'SAVE_BILATERAL_AREAS_SUCCESS'
const SAVE_BILATERAL_AREAS_FAILED = 'SAVE_BILATERAL_AREAS_FAILED'

export const saveBilateralAreas = (versionId, languageId, values) => (dispatch) => {
	dispatch({ type: SAVE_BILATERAL_AREAS_REQUESTED, data: {} })
	protocol.saveBilateralAreas(versionId, languageId, values).then((observationProtocol) => {
		dispatch(stopSaving())
		if (observationProtocol && observationProtocol.isSuccessful) {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.successfullyUpdated', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.updateBilateralAreasFailed', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_SCORING_GROUPS_REQUESTED = 'FETCH_SCORING_GROUPS_REQUESTED'
const FETCH_SCORING_GROUPS_SUCCESS = 'FETCH_SCORING_GROUPS_SUCCESS'
const FETCH_SCORING_GROUPS_FAILED = 'FETCH_SCORING_GROUPS_FAILED'

export const fetchProtocolScoringGroups = (versionId) => (dispatch) => {
	dispatch({ type: FETCH_SCORING_GROUPS_REQUESTED, data: {} })

	protocol.fetchScoringGroups(versionId).then((data) => {
		if (data && data.isSuccessful) {
			return dispatch({ type: FETCH_SCORING_GROUPS_SUCCESS, data: data.scoringGroups })
		} else {
			return dispatch({ type: FETCH_SCORING_GROUPS_FAILED, data: {} })
		}
	})
	return
}

const FETCH_PROTOCOL_SCORING_GROUP_DISCLAIMER_REQUESTED = 'FETCH_PROTOCOL_SCORING_GROUP_DISCLAIMER_REQUESTED'
const FETCH_PROTOCOL_SCORING_GROUP_DISCLAIMER_SUCCESS = 'FETCH_PROTOCOL_SCORING_GROUP_DISCLAIMER_SUCCESS'
const FETCH_PROTOCOL_SCORING_GROUP_DISCLAIMER_FAILED = 'FETCH_PROTOCOL_SCORING_GROUP_DISCLAIMER_FAILED'

export const fetchProtocolScoringGroupDisclaimer = (versionId, scoringGroupId, languageId) => (dispatch) => {
	dispatch({ type: FETCH_PROTOCOL_SCORING_GROUP_DISCLAIMER_REQUESTED, data: {} })

	protocol.fetchScoringGroupDisclaimer(versionId, scoringGroupId, languageId).then((data) => {
		if (data && data.isSuccessful) {
			return dispatch({ type: FETCH_PROTOCOL_SCORING_GROUP_DISCLAIMER_SUCCESS, data: data })
		} else {
			return dispatch({ type: FETCH_PROTOCOL_SCORING_GROUP_DISCLAIMER_FAILED, data: {} })
		}
	})
	return
}

const FETCH_PROTOCOL_SCORING_GROUP_SURVEYS_REQUESTED = 'FETCH_PROTOCOL_SCORING_GROUP_SURVEYS_REQUESTED'
const FETCH_PROTOCOL_SCORING_GROUP_SURVEYS_SUCCESS = 'FETCH_PROTOCOL_SCORING_GROUP_SURVEYS_SUCCESS'
const FETCH_PROTOCOL_SCORING_GROUP_SURVEYS_FAILED = 'FETCH_PROTOCOL_SCORING_GROUP_SURVEYS_FAILED'

export const fetchProtocolScoringGroupSurveys = (versionId, scoringGroupId) => (dispatch) => {
	dispatch({ type: FETCH_PROTOCOL_SCORING_GROUP_SURVEYS_REQUESTED, data: {} })

	protocol.fetchScoringGroupSurveys(versionId, scoringGroupId).then((data) => {
		if (data) {
			return dispatch({ type: FETCH_PROTOCOL_SCORING_GROUP_SURVEYS_SUCCESS, data: data })
		} else {
			return dispatch({ type: FETCH_PROTOCOL_SCORING_GROUP_SURVEYS_FAILED, data: {} })
		}
	})
	return
}

const FETCH_PROTOCOL_SCORING_GROUP_SETTINGS_REQUESTED = 'FETCH_PROTOCOL_SCORING_GROUP_SETTINGS_REQUESTED'
const FETCH_PROTOCOL_SCORING_GROUP_SETTINGS_SUCCESS = 'FETCH_PROTOCOL_SCORING_GROUP_SETTINGS_SUCCESS'
const FETCH_PROTOCOL_SCORING_GROUP_SETTINGS_FAILED = 'FETCH_PROTOCOL_SCORING_GROUP_SETTINGS_FAILED'

export const fetchProtocolScoringGroupSettings = (versionId, scoringGroupId) => (dispatch) => {
	dispatch({ type: FETCH_PROTOCOL_SCORING_GROUP_SETTINGS_REQUESTED, data: {} })

	protocol.fetchScoringGroupSettings(versionId, scoringGroupId).then((data) => {
		if (data) {
			return dispatch({ type: FETCH_PROTOCOL_SCORING_GROUP_SETTINGS_SUCCESS, data: data })
		} else {
			return dispatch({ type: FETCH_PROTOCOL_SCORING_GROUP_SETTINGS_FAILED, data: {} })
		}
	})
	return
}

const FETCH_PROTOCOL_CHANGE_LOGS_REQUESTED = 'FETCH_PROTOCOL_CHANGE_LOGS_REQUESTED'
const FETCH_PROTOCOL_CHANGE_LOGS_SUCCESS = 'FETCH_PROTOCOL_CHANGE_LOGS_SUCCESS'
const FETCH_PROTOCOL_CHANGE_LOGS_FAILED = 'FETCH_PROTOCOL_CHANGE_LOGS_FAILED'
export const searchProtocolChangeLogs = (protocolVersionId, searchPhrase = null, keys = []) => (dispatch) => {
	dispatch({ type: FETCH_PROTOCOL_CHANGE_LOGS_REQUESTED, data: {} })

	protocol.fetchProtocolChangeLogs(protocolVersionId, searchPhrase, keys).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({
				type: FETCH_PROTOCOL_CHANGE_LOGS_SUCCESS,
				data: {
					logs: response.logs,
					keys: response.keys
				}
			})
		} else {
			dispatch({ type: FETCH_PROTOCOL_CHANGE_LOGS_FAILED, data: {} })

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.audit.failedToFetch', isSuccess: false }
			})
		}
	})
	return
}


const FETCH_SURVEYS_FOR_PROTOCOL_VERSION_REQUESTED = 'FETCH_SURVEYS_FOR_PROTOCOL_VERSION_REQUESTED'
const FETCH_SURVEYS_FOR_PROTOCOL_VERSION_SUCCESS = 'FETCH_SURVEYS_FOR_PROTOCOL_VERSION_SUCCESS'
const FETCH_SURVEYS_FOR_PROTOCOL_VERSION_FAILED = 'FETCH_SURVEYS_FOR_PROTOCOL_VERSION_FAILED'

export const fetchSurveysForProtocolVersion = (versionId) => (dispatch) => {
	dispatch({ type: FETCH_SURVEYS_FOR_PROTOCOL_VERSION_REQUESTED, data: {} })

	protocol.fetchSurveysForProtocolVersion(versionId).then((surveys) => {
		if (surveys) {
			dispatch({ type: FETCH_SURVEYS_FOR_PROTOCOL_VERSION_SUCCESS, data: surveys })
		} else {
			return dispatch({ type: FETCH_SURVEYS_FOR_PROTOCOL_VERSION_FAILED, data: {} })
		}
	})
	return
}

const FETCH_INVESTIGATORS_FOR_PROTOCOL_VERSION_REQUESTED = 'FETCH_INVESTIGATORS_FOR_PROTOCOL_VERSION_REQUESTED'
const FETCH_INVESTIGATORS_FOR_PROTOCOL_VERSION_SUCCESS = 'FETCH_INVESTIGATORS_FOR_PROTOCOL_VERSION_SUCCESS'
const FETCH_INVESTIGATORS_FOR_PROTOCOL_VERSION_FAILED = 'FETCH_INVESTIGATORS_FOR_PROTOCOL_VERSION_FAILED'

export const fetchInvestigatorsForProtocolVersion = (circleId, versionId) => (dispatch) => {
	dispatch({ type: FETCH_INVESTIGATORS_FOR_PROTOCOL_VERSION_REQUESTED, data: {} })

	protocol.fetchInvestigatorsForProtocolVersion(circleId, versionId).then((investigators) => {
		if (investigators) {
			dispatch({ type: FETCH_INVESTIGATORS_FOR_PROTOCOL_VERSION_SUCCESS, data: investigators })
		} else {
			return dispatch({ type: FETCH_INVESTIGATORS_FOR_PROTOCOL_VERSION_FAILED, data: {} })
		}
	})
	return
}

const FETCH_INVESTIGATORS_FOR_PROTOCOL_VERSION_ENTITY_AUDIT_REQUESTED = 'FETCH_INVESTIGATORS_FOR_PROTOCOL_VERSION_ENTITY_AUDIT_REQUESTED'
const FETCH_INVESTIGATORS_FOR_PROTOCOL_VERSION_ENTITY_AUDIT_SUCCESS = 'FETCH_INVESTIGATORS_FOR_PROTOCOL_VERSION_ENTITY_AUDIT_SUCCESS'
const FETCH_INVESTIGATORS_FOR_PROTOCOL_VERSION_ENTITY_AUDIT_FAILED = 'FETCH_INVESTIGATORS_FOR_PROTOCOL_VERSION_ENTITY_AUDIT_FAILED'

export const fetchInvestigatorsForProtocolVersionEntityAudit = (versionId) => (dispatch) => {
	dispatch({ type: FETCH_INVESTIGATORS_FOR_PROTOCOL_VERSION_ENTITY_AUDIT_REQUESTED, data: {} })

	protocol.fetchInvestigatorsForProtocolVersionEntityAudit(versionId).then((investigators) => {
		if (investigators) {
			dispatch({ type: FETCH_INVESTIGATORS_FOR_PROTOCOL_VERSION_ENTITY_AUDIT_SUCCESS, data: investigators })
			
			if (investigators.length) {
				dispatch({type: SET_SELECTED_INVESTIGATOR_ENTITY_AUDIT_REQUESTED, data: investigators[0].id})
			}

		} else {
			return dispatch({ type: FETCH_INVESTIGATORS_FOR_PROTOCOL_VERSION_ENTITY_AUDIT_FAILED, data: {} })
		}
	})
	return
}

const FETCH_FUNDERS_FOR_PROTOCOL_VERSION_REQUESTED = 'FETCH_FUNDERS_FOR_PROTOCOL_VERSION_REQUESTED'
const FETCH_FUNDERS_FOR_PROTOCOL_VERSION_SUCCESS = 'FETCH_FUNDERS_FOR_PROTOCOL_VERSION_SUCCESS'
const FETCH_FUNDERS_FOR_PROTOCOL_VERSION_FAILED = 'FETCH_FUNDERS_FOR_PROTOCOL_VERSION_FAILED'

export const fetchFundersForProtocolVersion = (circleId, versionId) => (dispatch) => {
	dispatch({ type: FETCH_FUNDERS_FOR_PROTOCOL_VERSION_REQUESTED, data: {} })

	protocol.fetchFundersForProtocolVersion(circleId, versionId).then((surveys) => {
		if (surveys) {
			dispatch({ type: FETCH_FUNDERS_FOR_PROTOCOL_VERSION_SUCCESS, data: surveys })
		} else {
			return dispatch({ type: FETCH_FUNDERS_FOR_PROTOCOL_VERSION_FAILED, data: {} })
		}
	})
	return
}

const SET_SELECTED_COMPLIANCE_PROTOCOL_VERSION_REQUESTED = 'SET_SELECTED_COMPLIANCE_PROTOCOL_VERSION_REQUESTED'

export const setSelectedComplianceProtocolVersion = (versionId) => (dispatch) => {
	return dispatch({ type: SET_SELECTED_COMPLIANCE_PROTOCOL_VERSION_REQUESTED, data: versionId })
}

const SET_SELECTED_PROTOCOL_VERSION_ENTITY_AUDIT_REQUESTED = 'SET_SELECTED_PROTOCOL_VERSION_ENTITY_AUDIT_REQUESTED'

export const setSelectedProtocolVersionForEntityAudit = (versionId) => (dispatch) => {
	return dispatch({ type: SET_SELECTED_PROTOCOL_VERSION_ENTITY_AUDIT_REQUESTED, data: versionId })
}

const SET_SELECTED_INVESTIGATOR_ENTITY_AUDIT_REQUESTED = 'SET_SELECTED_INVESTIGATOR_ENTITY_AUDIT_REQUESTED'

export const setSelectedInvestigatorForEntityAudit = (investigatorId) => (dispatch) => {
	return dispatch({ type: SET_SELECTED_INVESTIGATOR_ENTITY_AUDIT_REQUESTED, data: investigatorId })
}

const UPDATE_PROTOCOL_VERSION_SG_DISCLAIMER_REQUESTED = 'UPDATE_PROTOCOL_VERSION_SG_DISCLAIMER_REQUESTED'
const UPDATE_PROTOCOL_VERSION_SG_DISCLAIMER_SUCCESS   = 'UPDATE_PROTOCOL_VERSION_SG_DISCLAIMER_SUCCESS'
const UPDATE_PROTOCOL_VERSION_SG_DISCLAIMER_FAILED    = 'UPDATE_PROTOCOL_VERSION_SG_DISCLAIMER_FAILED'

export const updateProtocolVersionScoringGroupDisclaimer = (versionId, scoringGroupId, languageId, disclaimer, commentsObj) => (dispatch) => {
	dispatch({ type: UPDATE_PROTOCOL_VERSION_SG_DISCLAIMER_REQUESTED, data: {} })

	protocol.updateProtocolVersionScoringGroupDisclaimer(versionId, scoringGroupId, languageId, disclaimer, commentsObj).then((response) => {
		dispatch(stopSaving())
		if (response && response.isSuccessful) {
			dispatch({ type: UPDATE_PROTOCOL_VERSION_SG_DISCLAIMER_SUCCESS, data: response.data })

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.template.updateSuccessful`, isSuccess: true }
			})
		} else {
			dispatch({ type: UPDATE_PROTOCOL_VERSION_SG_DISCLAIMER_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.template.updateFailed', isSuccess: false }
			})
		}
	})

	return
}

const UPDATE_PROTOCOL_VERSION_SG_SETTINGS_REQUESTED = 'UPDATE_PROTOCOL_VERSION_SG_SETTINGS_REQUESTED'
const UPDATE_PROTOCOL_VERSION_SG_SETTINGS_SUCCESS   = 'UPDATE_PROTOCOL_VERSION_SG_SETTINGS_SUCCESS'
const UPDATE_PROTOCOL_VERSION_SG_SETTINGS_FAILED    = 'UPDATE_PROTOCOL_VERSION_SG_SETTINGS_FAILED'

export const updateProtocolVersionScoringGroupSettings = (versionId, scoringGroupId, benchmarcMode, benchmarcType, commentsObj) => (dispatch) => {
	dispatch({ type: UPDATE_PROTOCOL_VERSION_SG_SETTINGS_REQUESTED, data: {} })

	protocol.updateProtocolVersionScoringGroupSettings(versionId, scoringGroupId, benchmarcMode, benchmarcType, commentsObj).then((response) => {
		dispatch(stopSaving())
		if (response && response.isSuccessful) {
			dispatch({ type: UPDATE_PROTOCOL_VERSION_SG_SETTINGS_SUCCESS, data: response.data })
			dispatch(fetchProtocolScoringGroupSettings(versionId, scoringGroupId))
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.general.successfullyUpdated`, isSuccess: true }
			})
		} else {
			dispatch({ type: UPDATE_PROTOCOL_VERSION_SG_SETTINGS_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.saveProtocolFailed', isSuccess: false }
			})
		}
	})

	return
}

const UPDATE_PROTOCOL_VERSION_SG_SURVEY_SETTINGS_REQUESTED = 'UPDATE_PROTOCOL_VERSION_SG_SURVEY_SETTINGS_REQUESTED'
const UPDATE_PROTOCOL_VERSION_SG_SURVEY_SETTINGS_SUCCESS   = 'UPDATE_PROTOCOL_VERSION_SG_SURVEY_SETTINGS_SUCCESS'
const UPDATE_PROTOCOL_VERSION_SG_SURVEY_SETTINGS_FAILED    = 'UPDATE_PROTOCOL_VERSION_SG_SURVEY_SETTINGS_FAILED'

export const updateProtocolVersionScoringGroupSurveySettings = (versionId, scoringGroupId, surveyInstanceId, values, commentsObj) => (dispatch) => {
	dispatch({ type: UPDATE_PROTOCOL_VERSION_SG_SURVEY_SETTINGS_REQUESTED, data: {} })

	protocol.updateProtocolVersionScoringGroupSurveySettings(versionId, scoringGroupId, surveyInstanceId, values, commentsObj).then((response) => {
		dispatch(stopSaving())
		if (response && response.isSuccessful) {
			dispatch({ type: UPDATE_PROTOCOL_VERSION_SG_SURVEY_SETTINGS_SUCCESS, data: response.data })
			dispatch(fetchProtocolScoringGroupSurveys(versionId, scoringGroupId))
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.general.successfullyUpdated`, isSuccess: true }
			})
		} else {
			dispatch({ type: UPDATE_PROTOCOL_VERSION_SG_SURVEY_SETTINGS_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.protocol.saveProtocolFailed', isSuccess: false }
			})
		}
	})

	return
}

const initial = {
	observationalProtocol: null,
	observationalProtocolDetails: null,
	observationalProtocolPreTreatmentSurveys: [],
	observationalProtocolTreatmentSurveys: [],
	observationalProtocolPostTreatmentSurveys: [],
	observationalProtocols: [],
	survey: null,
	surveyInstance: null,
	isCreating: false,
	surveyRefreshed: false,
	surveyQuestionsLoading: false,
	isCreatingSurvey: false,
	sortField: 'modifiedDateSeconds',
	isDescending: true,
	filter: 0,
	surveyFiter: 2,
	hasProtocols: false,
	hasRelatedProtocols: false,
	relatedObservationalProtocols: [],
	units: [ { id: 0, label: 'Inches' }, { id: 1, label: 'Centimeters' } ],
	autoCompleteSuggestions: [],
	questions: [],
	circles: [],
	subscribers: [],
	versions: [],
	showArchivedSurveys: true,
	delegateId : 0,
	hasMore: true,
	isLoading: false,
	searchTerm: '',
	isLoadingVersions: false,
	hasMoreVersions: true,
	subVersions: [],
	isLoadingSubVersions: false,
	hasMoreSubVersions: true,
	currentSubVersion: null,
	relatedProtocols: null,
	protocolVersionsForCircleAndUser: [],
	bilateralAreas: null,
	scoringGroups: [],
	protocolVersionsForCircle: [],
	protocolVersionsForEntityAudit: [],
	bilateralAreas: null,
	scoringGroups: [],
	logs: [],
	logKeys: [],
	selectedComplianceProtocolVersion: null,
	surveysForProtocolVesion: [],
	investigatorsForProtocolVesion: [],
	investigatorsForProtocolVersionEntityAudit: [],
	fundersForProtocolVesion: [],
	scoringGroupDisclaimer: null,
	scoringGroupSurveys: [],
	scoringGroupSettings: null,
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_SURVEY_QUESTIONS_REQUESTED:
			return {
				...state,
				surveyQuestionsLoading: true
			}
		case FETCH_SURVEY_QUESTIONS_SUCCESS:
			return {
				...state,
				survey: action.data.survey,
				originalSurvey: action.data.survey,
				surveyQuestions: action.data.list,
				surveyQuestionHash: action.data.nodes,
				surveyQuestionIds: action.data.flat,
				originalSurveyQuestions: action.data.list,
				originalSurveyQuestionHash: action.data.nodes,
				originalSurveyQuestionIds: action.data.flat,
				surveyRefreshed: true,
				surveyQuestionsLoading: false
			}
		case FETCH_SURVEY_QUESTIONS_FAILED:
			return {
				...state,
				surveyQuestionsLoading: false
			}
		// return {
		// 	...state,
		// 	questions: action.data
		// }
		case FETCH_PROTOCOL_AUTOCOMPLETE_SUCCESS:
			return { ...state, autoCompleteSuggestions: action.data }
		case CREATE_OBSERVATIONAL_PROTOCOL_FAILED:
			return { ...state, isCreating: false }
		case FETCH_SURVEY_INSTANCE_SUCCESS:
			return { ...state, surveyInstance: action.data }
		case SET_PROTOCOL_SEARCH_TERM_SUCCESS:
			return { ...state, searchTerm: action.data }
		case CLONE_OBSERVATIONAL_PROTOCOL_REQUESTED:
			return {
				...state,
				observationalProtocol: null,
				observationalProtocolPreTreatmentSurveys: null,
				observationalProtocolTreatmentSurveys: null,
				observationalProtocolPostTreatmentSurveys: null
			}
		case UPDATE_SURVEY_DELEGATE_REQUESTED:
			return {
				...state,
				survey: { ...state.survey, delegateId: action.data.id }
			}
		case UPDATE_SURVEY_REMINDER_UNIT_REQUESTED:
			return {
				...state,
				survey: { ...state.survey, reminderUnit: action.data.unit }
			}
		case UPDATE_SURVEY_REMINDER_VALUE_REQUESTED:
			return {
				...state,
				survey: { ...state.survey, reminderValue: action.data.value }
			}
		case UPDATE_SURVEY_TREATMENT_PHASE_REQUESTED:
			return {
				...state,
				survey: { ...state.survey, treatmentPhaseId: action.data.id }
			}
		case FETCH_OBSERVATIONAL_PROTOCOL_SUCCESS:
		case FETCH_OBSERVATIONAL_PROTOCOL_VERSION_SUCCESS:
		case SAVE_OBSERVATIONAL_PROTOCOL_SUCCESS:
			return {
				...state,
				observationalProtocol: action.data,
				observationalProtocolPreTreatmentSurveys: action.data && action.data.preTreatmentSurveys,
				observationalProtocolTreatmentSurveys: action.data && action.data.treatmentSurveys,
				observationalProtocolPostTreatmentSurveys: action.data && action.data.postTreatmentSurveys
			}

		case FETCH_OBSERVATIONAL_PROTOCOL_REQUESTED:
		//case FETCH_OBSERVATIONAL_PROTOCOL_VERSION_REQUESTED:
			return {
				...state,
				observationalProtocol: null,
				observationalProtocolPreTreatmentSurveys: [],
				observationalProtocolTreatmentSurveys: [],
				observationalProtocolPostTreatmentSurveys: []
			}
		case FETCH_OBSERVATIONAL_PROTOCOL_DETAILS_SUCCESS:
			return {
				...state,
				observationalProtocolDetails: action.data
			}
		case CLEAR_OBSERVATIONAL_PROTOCOLS_REQUESTED:
			return {
				...state,
				observationalProtocols: [],
				hasProtocols: false,
				filter: 0
			}
		case FETCH_OBSERVATIONAL_PROTOCOLS_SUCCESS:
			return {
				...state,
				observationalProtocols: action.data.items,
				hasProtocols: action.data && action.data.items.length > 0,
				hasMore: action.data.hasMore
			}
		case FETCH_ADDITIONAL_OBSERVATIONAL_PROTOCOLS_WITH_METRICS_SUCCESS:
			return {
				...state,
				observationalProtocols: [ ...state.observationalProtocols, ...action.data.items],
				hasMore: action.data.hasMore,
				isLoading: false
			}
		case FETCH_ADDITIONAL_OBSERVATIONAL_PROTOCOLS_WITH_METRICS_FAILED:
			return {
				...state,
				hasMore: false,
				isLoading: false
			}
		case FETCH_ADDITIONAL_OBSERVATIONAL_PROTOCOLS_WITH_METRICS_REQUESTED:
			return {
				...state,
				isLoading: true
			}
		case FETCH_RELATED_OBSERVATIONAL_PROTOCOLS_SUCCESS:
			return {
				...state,
				relatedObservationalProtocols: action.data,
				hasRelatedProtocols: action.data.length > 0
			}
		case REVERT_SURVEY_REQUESTED:
			return {
				...state,
				survey: state.originalSurvey,
				originalSurvey: state.originalSurvey,
				surveyQuestions: state.originalSurveyQuestions,
				surveyQuestionHash: state.originalSurveyQuestionHash,
				surveyQuestionIds: state.originalSurveyQuestionIds,
				surveyRefreshed: true
			}

		case CREATE_OBSERVATIONAL_PROTOCOL_SUCCESS:
		case SAVE_OBSERVATIONAL_PROTOCOL_SUCCESS:
			var x = [ ...state.observationalProtocols ]
			Array.prototype.push.apply(x, [ action.data ])
			return {
				...state,
				observationalProtocols: x,
				isCreating: false,
				hasProtocols: state.observationalProtocols.length > 0
			}
		case CREATE_OBSERVATIONAL_PROTOCOL_REQUESTED:
			return { ...state, isCreating: true }
		case SET_PROTOCOL_SORT_REQUESTED:
			return {
				...state,
				observationalProtocols: [ ...action.data.items ],
				sortField: action.data.field,
				isDescending: action.data.isDescending
			}
		case SET_PROTOCOLS_FILTER_SUCCESS:
			return { ...state, filter: action.data }
		case SET_PROTOCOLS_SURVEYS_FILTER_SUCCESS:
			return { ...state, surveyFiter: action.data }
		case FETCH_CIRCLES_SUCCESS:
			return { ...state, circles: action.data }
		case FETCH_SUBSCRIBERS_SUCCESS:
			return { ...state, subscribers: action.data }
		case FETCH_VERSIONS_REQUESTED:
			return { ...state, isLoadingVersions: true}
		case FETCH_VERSIONS_SUCCESS:
			return { 
				...state, 
				versions: action.data.items, 
				isLoadingVersions: false, 
				hasMoreVersions: action.data.hasMore
			}
		case FETCH_VERSIONS_FAILED:
			return { ...state, isLoadingVersions: false, hasMoreVersions: false}
		case FETCH_ADDITIONAL_VERSIONS_REQUESTED:
			return { ...state, isLoadingVersions: true}
		case FETCH_ADDITIONAL_VERSIONS_SUCCESS:
			return { 
				...state, 
				versions: [ ...state.versions, ...action.data.items],
				isLoadingVersions: false, 
				hasMoreVersions: action.data.hasMore
			}
		case FETCH_ADDITIONAL_VERSIONS_FAILED:
			return { ...state, isLoadingVersions: false, hasMoreVersions: false}
		case FETCH_SUB_VERSIONS_REQUESTED:
			return { ...state, isLoadingSubVersions: true}
		case FETCH_SUB_VERSIONS_SUCCESS:
			return { 
				...state, 
				subVersions: action.data.items, 
				isLoadingSubVersions: false, 
				hasMoreSubVersions: action.data.hasMore
			}
		case FETCH_SUB_VERSIONS_FAILED:
			return { ...state, isLoadingSubVersions: false, hasMoreSubVersions: false}
		case SET_SHOW_ARCHIVED_SURVEYS_FILTER_SUCCESS:
			return { ...state, showArchivedSurveys: action.data}	
		case FETCH_SUB_VERSION_DETAILS_SUCCESS:
			return {
				...state,
				currentSubVersion: action.data
			}
		case FETCH_PROTOCOLS_BY_QUESTION_SUCCESS:
			return {
				...state,
				relatedProtocols: action.data
			}
		case FETCH_PROTOCOLS_BY_BUNDLE_SUCCESS:
			return {
				...state,
				relatedProtocols: action.data
			}
		case FETCH_PROTOCOLS_BY_SURVEY_SUCCESS:
			return {
				...state,
				relatedProtocols: action.data
			}
		case FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_AND_USER_FAILED:
		case FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_AND_USER_REQUESTED:
			return {
				...state,
				protocolVersionsForCircleAndUser: []
			}
		case FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_AND_USER_SUCCESS:
			return {
				...state,
				protocolVersionsForCircleAndUser: action.data
			}
		case FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_FAILED:
		case FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_REQUESTED:
			return {
				...state,
				protocolVersionsForCircle: []
			}
		case FETCH_PROTOCOL_VERSIONS_FOR_CIRCLE_SUCCESS:
			return {
				...state,
				protocolVersionsForCircle: action.data
			}
		case FETCH_PROTOCOL_VERSIONS_FOR_ENTITY_FAILED:
		case FETCH_PROTOCOL_VERSIONS_FOR_ENTITY_REQUESTED:
			return {
				...state,
				protocolVersionsForEntityAudit: []
			}
		case FETCH_PROTOCOL_VERSIONS_FOR_ENTITY_SUCCESS:
			return {
				...state,
				protocolVersionsForEntityAudit: action.data
			}
		case FETCH_BILATERAL_AREAS_FAILED:
		case FETCH_BILATERAL_AREAS_REQUESTED:
			return {
				...state,
				bilateralAreas: null
			}
		case FETCH_BILATERAL_AREAS_SUCCESS:
			return {
				...state,
				bilateralAreas: action.data
			}
		case FETCH_SCORING_GROUPS_REQUESTED:
		case FETCH_SCORING_GROUPS_FAILED:
			return {
				...state,
				scoringGroups: []
			}
		case FETCH_SCORING_GROUPS_SUCCESS:
			return {
				...state,
				scoringGroups: action.data
			}
		case FETCH_SCORING_GROUPS_REQUESTED:
		case FETCH_SCORING_GROUPS_FAILED:
			return {
				...state,
				scoringGroupDisclaimer: null
			}
		case FETCH_PROTOCOL_SCORING_GROUP_DISCLAIMER_SUCCESS:
			return {
				...state,
				scoringGroupDisclaimer: action.data
			}
		case FETCH_PROTOCOL_SCORING_GROUP_SURVEYS_REQUESTED:
		case FETCH_PROTOCOL_SCORING_GROUP_SURVEYS_FAILED:
			return {
				...state,
				scoringGroupSurveys: []
			}
		case FETCH_PROTOCOL_SCORING_GROUP_SURVEYS_SUCCESS:
			return {
				...state,
				scoringGroupSurveys: action.data
			}
		case FETCH_PROTOCOL_SCORING_GROUP_SETTINGS_REQUESTED:
		case FETCH_PROTOCOL_SCORING_GROUP_SETTINGS_FAILED:
			return {
				...state,
				scoringGroupSettings: null
			}
		case FETCH_PROTOCOL_SCORING_GROUP_SETTINGS_SUCCESS:
			return {
				...state,
				scoringGroupSettings: action.data
			}
		/** Protocol change logs */
		case FETCH_PROTOCOL_CHANGE_LOGS_REQUESTED:
			return {
				...state,
				logs: [],
				logKeys: []
			}
		case FETCH_PROTOCOL_CHANGE_LOGS_SUCCESS:
			return {
				...state,
				logs: action.data.logs,
				logKeys: action.data.keys
			}

		case SET_SELECTED_COMPLIANCE_PROTOCOL_VERSION_REQUESTED:
			return {
				...state,
				selectedComplianceProtocolVersion: action.data
			}
		case SET_SELECTED_PROTOCOL_VERSION_ENTITY_AUDIT_REQUESTED:
			return {
				...state,
				selectedProtocolVersionEntityAudit: action.data,
			}
		case SET_SELECTED_INVESTIGATOR_ENTITY_AUDIT_REQUESTED:
			return {
				...state,
				selectedInvestigatorEntityAudit: action.data,
			}
		case FETCH_SURVEYS_FOR_PROTOCOL_VERSION_FAILED:
		case FETCH_SURVEYS_FOR_PROTOCOL_VERSION_REQUESTED:
			return {
				...state,
				surveysForProtocolVesion: []
			}
		case FETCH_SURVEYS_FOR_PROTOCOL_VERSION_SUCCESS:
			return {
				...state,
				surveysForProtocolVesion: action.data
			}
		case FETCH_INVESTIGATORS_FOR_PROTOCOL_VERSION_FAILED:
		case FETCH_INVESTIGATORS_FOR_PROTOCOL_VERSION_REQUESTED:
			return {
				...state,
				investigatorsForProtocolVesion: []
			}
		case FETCH_INVESTIGATORS_FOR_PROTOCOL_VERSION_SUCCESS:
			return {
				...state,
				investigatorsForProtocolVesion: action.data
			}
		case FETCH_INVESTIGATORS_FOR_PROTOCOL_VERSION_ENTITY_AUDIT_FAILED:
		case FETCH_INVESTIGATORS_FOR_PROTOCOL_VERSION_ENTITY_AUDIT_REQUESTED:
			return {
				...state,
				investigatorsForProtocolVersionEntityAudit: []
			}
		case FETCH_INVESTIGATORS_FOR_PROTOCOL_VERSION_ENTITY_AUDIT_SUCCESS:
			return {
				...state,
				investigatorsForProtocolVersionEntityAudit: action.data
			}
		case FETCH_FUNDERS_FOR_PROTOCOL_VERSION_FAILED:
		case FETCH_FUNDERS_FOR_PROTOCOL_VERSION_REQUESTED:
			return {
				...state,
				fundersForProtocolVesion: []
			}
		case FETCH_FUNDERS_FOR_PROTOCOL_VERSION_SUCCESS:
			return {
				...state,
				fundersForProtocolVesion: action.data
			}
		case 'SIGN_OUT_REQUESTED':
			return initial

		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.protocol
const filterSelector = (state) => state.protocol.filter
export const surveyFilterSelector = (state) => state.protocol.surveyFiter

export const surveyRefreshedSelector = (state) => state.protocol.surveyRefreshed
export const surveyQuestionsLoadingSelector = (state) => state.protocol.surveyQuestionsLoading

export const hasProtocolSelector = createSelector(mainSelector, (state) => {
	return state.hasProtocols
})
export const sortProtocolsDirectionSelector = createSelector(mainSelector, (state) => {
	return state.isDescending
})

export const sortProtocolsFieldSelector = createSelector(mainSelector, (state) => {
	return state.sortField
})

export const isCreatingProtocolSelector = createSelector(mainSelector, (state) => state.isCreating)

export const protocolSelector = createSelector(mainSelector, (state) => {
	if (state && state.observationalProtocol) {
		var result = {
			...state.observationalProtocol,
			isPrivate: state.observationalProtocol && state.observationalProtocol.isPrivate ? '1' : '0'
		}
		return result
	}
	return null
})

export const surveyInstanceSelector = createSelector(mainSelector, (state) => {
	let surveyInstance = state.surveyInstance
	if (surveyInstance) surveyInstance.alternativeDelegateId = 3;
	return surveyInstance;
})

export const delegateIdSelector = createSelector(mainSelector, (state) => state.delegateId)

export const preTreatmentSurveySelector = createSelector(mainSelector, surveyFilterSelector, (state, filter) => {
	if (!state.observationalProtocolPreTreatmentSurveys) {
		return []
	}
	
	switch (filter) {
		case 0:
			return state.observationalProtocolPreTreatmentSurveys.filter((r) => {
				return r.surveyState === 1 || (state.showArchivedSurveys && (r.surveyState === 2 || r.surveyState === 3))
			})
		case 1:
			return state.observationalProtocolPreTreatmentSurveys.filter((r) => {
				return r.surveyRootState === 1 || (state.showArchivedSurveys && (r.surveyRootState === 2 || r.surveyRootState === 3))
			})
		case 2:
			return state.observationalProtocolPreTreatmentSurveys.filter((r) => {
				return r.surveyState === 1 || r.surveyState === 0 || (state.showArchivedSurveys && (r.surveyState === 2 || r.surveyState === 3))
			})

	}
})
export const treatmentSurveySelector = createSelector(mainSelector, surveyFilterSelector, (state, filter) => 
{
	if (!state.observationalProtocolTreatmentSurveys) {
		return []
	}
	
	switch (filter) {
		case 0:
			return state.observationalProtocolTreatmentSurveys.filter((r) => {
				return r.surveyState === 1 || (state.showArchivedSurveys && (r.surveyState === 2 || r.surveyState === 3))
			})
		case 1:
			return state.observationalProtocolTreatmentSurveys.filter((r) => {
				return r.surveyRootState === 1 || (state.showArchivedSurveys && (r.surveyRootState === 2 || r.surveyRootState === 3))
			})
		case 2:
			return state.observationalProtocolTreatmentSurveys.filter((r) => {
				return r.surveyState === 1 || r.surveyState === 0 || (state.showArchivedSurveys && (r.surveyState === 2 || r.surveyState === 3))
			})
	}
})
export const postTreatmentSurveySelector = createSelector(mainSelector, surveyFilterSelector, (state, filter) => {
	if (state.observationalProtocolPostTreatmentSurveys && state.observationalProtocolPostTreatmentSurveys.length > 0) {
		state.observationalProtocolPostTreatmentSurveys
			.map((r) => {
				if (r.dueUnit === 0) {
					r.sortOrder = r.dueValue
				} else if (r.dueUnit === 1) {
					r.sortOrder = r.dueValue * 7
				} else if (r.dueUnit === 2) {
					r.sortOrder = r.dueValue * 30
				}
			})
			.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
	} else {
		return []
	}

	switch (filter) {
		case 0:
			return state.observationalProtocolPostTreatmentSurveys.filter((r) => {
				return r.surveyState === 1 || (state.showArchivedSurveys && (r.surveyState === 2 || r.surveyState === 3))
			})
		case 1:
			return state.observationalProtocolPostTreatmentSurveys.filter((r) => {
				return r.surveyRootState === 1 || (state.showArchivedSurveys && (r.surveyRootState === 2 || r.surveyRootState === 3))
			})
		case 2:
			return state.observationalProtocolPostTreatmentSurveys.filter((r) => {
				return r.surveyState === 1 || r.surveyState === 0 || (state.showArchivedSurveys && (r.surveyState === 2 || r.surveyState === 3))
			})
	}
})

export const protocolsSelector = createSelector(mainSelector, filterSelector, (state, filter) => {
	// switch (filter) {
	// 	case 0:
	// 		return state.observationalProtocols.filter((r) => {
	// 			return r.isActive
	// 		})
	// 	case 1:
	// 		return state.observationalProtocols.filter((r) => {
	// 			return !r.isPrivate
	// 		})
	// 	case 2:
	// 		return state.observationalProtocols.filter((r) => {
	// 			return r.isPrivate
	// 		})
	// 	case 3:
	// 		return state.observationalProtocols.filter((r) => {
	// 			return !r.isActive
	// 		})
	// }

	return state.observationalProtocols
})

export const relatedProtocolsSelector = createSelector(mainSelector, (state, filter) => {
	return state && state.relatedObservationalProtocols
})

export const hasRelatedProtocolSelector = createSelector(mainSelector, (state) => {
	return state.hasRelatedProtocols
})

export const formattedProtocolsSelector = createSelector(mainSelector, (state) => {
	if (state && state.observationalProtocols) {
		return state.observationalProtocols.map((r) => {
			return {
				id: r.id,
				name: r.name,
				label: r.name,
				isActive: r.isActive
			}
		})
	}
	return []
})

export const childProtocolListSelector = createSelector(mainSelector, (state) => {
	if (state.observationalProtocol && state.observationalProtocol.children) {
		return state.observationalProtocol.children.map((r) => {
			return {
				label: r.name,
				id: r.id
			}
		})
	}
	return null
})
export const protocolSchemaSelector = createSelector(mainSelector, (state) => {
	return ProtocolSchema
})
export const protocolSchemaFormSelector = createSelector(mainSelector, (state) => {
	var data = ProtocolSchema.sections.filter((item) => item.id === '1')

	return data
})

export const protocolAutoCompleteSuggestionSelector = createSelector(mainSelector, (state) => {
	if (state && state.autoCompleteSuggestions) {
		return state.autoCompleteSuggestions
	}
	return []
})

// export const questionsSelector = createSelector(mainSelector, (state) => {
// 	return (
// 		state.questions &&
// 		state.questions.questions &&
// 		state.questions.questions.length > 0 &&
// 		state.questions.questions.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
// 	)
// })

export const protocolDetailsSelector = createSelector(mainSelector, (state) => {
	if (state && state.observationalProtocol) {
		var result = {
			...state.observationalProtocolDetails,
			isPrivate: state.observationalProtocolDetails && state.observationalProtocolDetails.isPrivate ? '1' : '0'
		}
		return result
	}
	return null
})

export const questionListSelector = createSelector(mainSelector, (state) => {
	if (state && state.surveyQuestions) return state.surveyQuestions
})

export const questionHashSelector = createSelector(mainSelector, (state) => {
	if (state && state.surveyQuestionHash) return state.surveyQuestionHash
})

export const questionIdSelector = createSelector(mainSelector, (state) => {
	if (state && state.surveyQuestionIds) return state.surveyQuestionIds
})

export const questionsSelector = createSelector(
	mainSelector,
	questionIdSelector,
	questionHashSelector,
	(state, ids, hash) => {
		if (state && ids && ids.length > 0 && hash) {
			let questions = []
			ids.map((r) => {
				if (hash.hasOwnProperty(r)) {
					let x = hash[r]
					questions.push(x)
				} else {
					console.log('No Property', questions)
				}
			})

			return questions
		}
	}
)
export const surveySelector = createSelector(mainSelector, (state) => {
	return state.survey
})

export const protocolCirclesSelector = createSelector(mainSelector, (state) => {
	if (state && state.circles) return state.circles
})

export const protocolSubscribersSelector = createSelector(mainSelector, (state) => {
	if (state && state.subscribers) return state.subscribers
})

export const protocolVersionsSelector = createSelector(mainSelector, (state) => {
	if (state && state.versions) return state.versions
})

export const showArchivedSurveysSelector = createSelector(mainSelector, (state) => {
	return state.showArchivedSurveys
})

export const isRootVersionSelector = createSelector(mainSelector, (state) => {
	return state && state.observationalProtocol && state.observationalProtocol.isRootVersion
})

export const hasMoreProtocolsSelector = createSelector(mainSelector, (state) => {
	return state.hasMore
})

export const isLoadingProtocolsSelector = createSelector(mainSelector, (state) => {
	return state.isLoading
})

export const protocolSearchTermSelector = createSelector(mainSelector, (state) => {
	return state.searchTerm
})

export const isLoadingProtocolVersionsSelector = createSelector(mainSelector, (state) => {
	return state.isLoadingVersions
})

export const hasMoreProtocolVersionsSelector = createSelector(mainSelector, (state) => {
	return state.hasMoreVersions
})

export const protocolSubVersionsSelector = createSelector(mainSelector, (state) => {
	if (state && state.subVersions) return state.subVersions
})

export const isLoadingProtocolSubVersionsSelector = createSelector(mainSelector, (state) => {
	return state.isLoadingSubVersions
})

export const hasMoreProtocolSubVersionsSelector = createSelector(mainSelector, (state) => {
	return state.hasMoreSubVersions
})

export const nextProtocolSubVersionNumberSelector = createSelector(mainSelector, (state) => {
	if (!state || !state.subVersions) 
		return 0
	
	let numbers = state.subVersions.map(v => v.versionNumber)
	return Math.max(...numbers) + 1
})

export const firstProtocolSubversionSelector = createSelector(mainSelector, (state) => {
	if (!state || !state.subVersions || state.subVersions.length < 1) 
		return null
	
	return state.subVersions.reduce((prev, curr) => prev.id < curr.id ? prev : curr)
})

export const protocolSubversionDetailsSelector = createSelector(mainSelector, (state) => {
	return state.currentSubVersion
})

export const relatedObservationProtocolsSelector = createSelector(mainSelector, (state) => {
	return state.relatedProtocols
})

export const defaultProtocolVersionForCirlceAndUserSelector = createSelector(mainSelector, (state) => {
	if(state && state.protocolVersionsForCircleAndUser && state.protocolVersionsForCircleAndUser.length > 0)
		return state.protocolVersionsForCircleAndUser.find(x => x.isDefault)
})

export const protocolVersionsForCircleAndUserSelector = createSelector(mainSelector, (state) => {
	return state && state.protocolVersionsForCircleAndUser
})

export const selectedComplianceProtocolVersionSelector = createSelector(mainSelector, (state) => {
		return state.selectedComplianceProtocolVersion
})

export const selectedProtocolVersionEntityAuditSelector = createSelector(mainSelector, (state) => {
	return state.selectedProtocolVersionEntityAudit
})

export const selectedInvestigatorEntityAuditSelector = createSelector(mainSelector, (state) => {
	return state.selectedInvestigatorEntityAudit
})

export const protocolVersionsForCircleSelector = createSelector(mainSelector, (state) => {
	return state && state.protocolVersionsForCircle
})

export const protocolVersionsForEntityAuditSelector = createSelector(mainSelector, (state) => {
	return state && state.protocolVersionsForEntityAudit
})

export const bilateralAreasSelector = createSelector(mainSelector, (state) => {
	return state && state.bilateralAreas
})

export const protocolScoringGroupsSelector = createSelector(mainSelector, (state) => {
	return state && state.scoringGroups
})

export const protocolChangeLogsSelector = createSelector(
	mainSelector,
	(state) => {
		return !!state && state.logs
	}
)

export const protocolChangeLogKeysSelector = createSelector(
	mainSelector,
	(state) => {
		return !!state && state.logKeys
	}
)

export const surveysForProtocolVesionSelector = createSelector(mainSelector, (state) => {
	return state && state.surveysForProtocolVesion
})

export const investigatorsForProtocolVesionSelector = createSelector(mainSelector, (state) => {
	return state && state.investigatorsForProtocolVesion
})

export const investigatorsForProtocolVersionEntityAuditSelector = createSelector(mainSelector, (state) => {
	return state && state.investigatorsForProtocolVersionEntityAudit
})

export const fundersForProtocolVesionSelector = createSelector(mainSelector, (state) => {
	return state && state.fundersForProtocolVesion
})

export const entityAuditPrefillCommentsValuesSelector = createSelector(mainSelector, (state) => {
	if (state && state.entityAuditCommentsPrefill) return state.entityAuditCommentsPrefill
})

export const protocolVersionScoringGroupDisclaimerSelector = createSelector(mainSelector, (state) => {
	return state && state.scoringGroupDisclaimer
})

export const protocolVersionScoringGroupSurveysSelector = createSelector(mainSelector, (state) => {
	return state && state.scoringGroupSurveys
})

export const protocolVersionScoringGroupSettingsSelector = createSelector(mainSelector, (state) => {
	return state && state.scoringGroupSettings
})