import React, { Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import { compose } from 'recompose'
import { reduxForm } from 'redux-form'
import { injectIntl } from 'react-intl'
import Paper from '@material-ui/core/Paper'
import BuilderContainer from './BuilderContainer'
import { Switch, Typography } from '@material-ui/core'

const Container = ({
	intl,
	headerTitle,
	list,
	collection,
	onAddCallback,
	onAddExistingCallback,
	onAddBundleCallback,
	onSwapCallback,
	onRemoveCallback,
	onUpdateCallback,
	ignoreBundles,
	onStartUpdatingQuestionCallback,
	suppressNonDiscreteQuestions = false,
	showScoreEntry,
    isBundle,
	propertyStateName,
	onChangeBundleStateCallback,
	onChangeQuestionStateCallback,
	archivedQuestionsVisible,
	onArchivedQuestionsVisibleCallback,
	sameStateAllEntities = true,
	canSetInUseState = false,
	canSetInUseStateAllEntities = false,
	canSetNotInUseState = false,
	canSetNotInUseStateAllEntities = false,
	canSetArchiveState = false,
	canSetArchiveStateAllEntities = false,
	canCloneQuestion = true,
	isOptionalDisabled = false,
	searchType = 1,
}) => {
	const onSwitchArchivedVisible = (e) => {
		if (!!onArchivedQuestionsVisibleCallback) {
			onArchivedQuestionsVisibleCallback(e.target.checked)
		}
	}

	return (
		<Paper style={{ minHeight: '100vh', padding: '2% 3% 2% 3%' }}>
			<Grid container direction="column">
				{
					!!onArchivedQuestionsVisibleCallback
					&& <Grid item>
						<Grid
							container
							alignItems='center'
						>
							<Grid item>
								<Typography
									variant="body2"
									style={{ fontSize: '17px', color: 'black' }}
								>
									{intl.formatMessage({ id: 'app.bundle.showArchivedQuestions' })}
								</Typography>
							</Grid>
							<Grid item>
								<Switch
									checked={archivedQuestionsVisible}
									onChange={onSwitchArchivedVisible}
								/>
							</Grid>
						</Grid>
					</Grid>
				}
				<Fragment>
					<Grid item style={{ marginTop: '10px' }}>
						<BuilderContainer
							headerTitle={headerTitle}
							list={list}
							showScoreEntry={showScoreEntry}
							ignoreBundles={ignoreBundles}
							onAddCallback={onAddCallback}
							onAddExistingCallback={onAddExistingCallback}
							onSwapCallback={onSwapCallback}
							onRemoveCallback={onRemoveCallback}
							onAddBundleCallback={onAddBundleCallback}
							collection={collection}
							onUpdateCallback={onUpdateCallback}
							onStartUpdatingQuestionCallback={onStartUpdatingQuestionCallback}
							suppressNonDiscreteQuestions={suppressNonDiscreteQuestions}
							searchType={searchType}
							isBundle={isBundle}
							propertyStateName={propertyStateName}
							onChangeBundleStateCallback={onChangeBundleStateCallback}
							onChangeQuestionStateCallback={onChangeQuestionStateCallback}
							sameStateAllEntities={sameStateAllEntities}
							canSetInUseState={canSetInUseState}
							canSetInUseStateAllEntities={canSetInUseStateAllEntities}
							canSetNotInUseState={canSetNotInUseState}
							canSetNotInUseStateAllEntities={canSetNotInUseStateAllEntities}
							canSetArchiveState={canSetArchiveState}
							canSetArchiveStateAllEntities={canSetArchiveStateAllEntities}
							canCloneQuestion={canCloneQuestion}
							isOptionalDisabled={isOptionalDisabled}
						/>
					</Grid>
				</Fragment>
			</Grid>
		</Paper>
	)
}

const enhance = compose(
	reduxForm({
		form: 'survey'
	}),
	injectIntl
)

export default enhance(Container)
