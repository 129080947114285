import React from 'react'
import { TableHead } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import { compose } from 'recompose'
import withStyles from '@material-ui/core/styles/withStyles'
import CircleHeader from './CircleHeader'
import CircleRow from './CircleRow'
import TableBody from '@material-ui/core/TableBody'
import { CircularProgress } from '@material-ui/core'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { connect } from 'react-redux'
import { circleSearchTermSelector, hasMoreCirclesSelector, isLoadingCirclesSelector } from '../../../redux/selectors'
import { searchAdditionalCircles } from '../../../redux/actions'

const styles = {
	root: {
		background: 'black'
	},
	input: {
		color: 'white',
		backgroundColor: 'rgb(36, 48, 65)'
	}
}

const CircleTable = ({ data, user, search, hasMore, getCircles, loading }) => {

	const getAdditionalCircles = () => {
		getCircles(search, data.length)
	}

	const [sentryRef] = useInfiniteScroll({
		loading,
		hasNextPage: hasMore,
		onLoadMore: getAdditionalCircles,
		delayInMs: 1000
	});

	return (
		<div className="admin-table-fullheight">
		    <Table data-testid="circle-table" stickyHeader>
				<TableHead>
					<CircleHeader />
				</TableHead>
				<TableBody style={{ borderTop: 'none' }}>
					{data && data.map((circle) => <CircleRow circle={circle} user={user} key={circle.id} />)}
				</TableBody>
			</Table>
			{data && data.length > 0 && (loading || hasMore) && (
				<div className="admin-table-progress" ref={sentryRef}>
					<CircularProgress />
				</div>
			)}
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	loading: isLoadingCirclesSelector(state),
	hasMore: hasMoreCirclesSelector(state),
	search: circleSearchTermSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	getCircles: (search, offset) => dispatch(searchAdditionalCircles(search, offset)),
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))

export default enhance(CircleTable)
