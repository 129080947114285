import React, { useState } from 'react'
import useTheme from '@material-ui/core/styles/useTheme'
import { useIntl } from 'react-intl'
import { reduxForm } from 'redux-form'
import { compose } from 'recompose'
import FormDateField from '../../../components/FormDateField'
import CalmButton from '../../../components/CalmButton'
import { fetchCircleUsageStatistics, fetchCircleUsageStatisticsRawData, fetchExecutiveUsageStatistics } from '../../../redux/actions'
import { isReportDownloadingSelector } from '../../../redux/selectors'
import { required } from '../../../utilities/validators'
import { connect } from 'react-redux'
import { Grid, Typography, CircularProgress, RadioGroup, Radio, FormControlLabel } from '@material-ui/core'


const requiredValidator = required

const UsageStatisticsModal = ({
	form,
	handleSubmit,
	downloadCircleUsageStatistics,
	downloadCircleUsageStatisticsRawData,
	downloadExecutiveUsageStatistics,
	isReportDownloading
}) => {
	const theme = useTheme()
	const intl = useIntl()

	const USAGE_STATISTICS_TYPE = {
		EXECUTIVE_USAGE: 0,
		BASIC_USAGE: 1,
		RAW_DATA: 2
	}

	const EXECUTIVE_STATISTICS_TYPE = {
		NONE: 0,
		ALL_FUNDERS: 1,
		EXECUTIVE_FUNDERS: 2
	}

	const [currentUsageStatisticsType, setCurrentUsageStatisticsType] = useState(USAGE_STATISTICS_TYPE.BASIC_USAGE)
	const [currentExecutiveStatisticType, setCurrentExecutiveStatisticType] = useState(EXECUTIVE_STATISTICS_TYPE.EXECUTIVE_FUNDERS)

	const handleChangeShowExecutiveUsage = (e) => {
		if (e.target.checked) {
			setCurrentUsageStatisticsType(USAGE_STATISTICS_TYPE.EXECUTIVE_USAGE)
		}
	}

	const handleSetExecutiveFunders = (e) => {
		if (e.target.checked) {
			setCurrentExecutiveStatisticType(EXECUTIVE_STATISTICS_TYPE.EXECUTIVE_FUNDERS)
		}
	}

	const handleSetAllFunders = (e) => {
		if (e.target.checked) {
			setCurrentExecutiveStatisticType(EXECUTIVE_STATISTICS_TYPE.ALL_FUNDERS)
		}
	}

	const handleChangeShowBasicUsage = (e) => {
		if (e.target.checked) {
			setCurrentUsageStatisticsType(USAGE_STATISTICS_TYPE.BASIC_USAGE)
		}
	}

	const handleChangeShowRawData = (e) => {
		if (e.target.checked) {
			setCurrentUsageStatisticsType(USAGE_STATISTICS_TYPE.RAW_DATA)
		}
	}

	const onSubmitForm = (values) =>
	{
		switch (currentUsageStatisticsType) {
			case USAGE_STATISTICS_TYPE.EXECUTIVE_USAGE:
				downloadExecutiveUsageStatistics(values.startDate, values.endDate, currentExecutiveStatisticType)
				break
			case USAGE_STATISTICS_TYPE.RAW_DATA:
				downloadCircleUsageStatisticsRawData(
					values.startDate,
					values.endDate
				)
				break
			default:
				downloadCircleUsageStatistics(values.startDate, values.endDate)
				break
		}
    }
	
	return (
		<form onSubmit={handleSubmit(onSubmitForm)}>
			<Grid
				container
				direction="column"
				justify="flex-start"
				alignItems="center"
				style={{ width: '75rem', padding: '4rem', margin: 'auto', backgroundColor: theme.palette.secondary.main }}
			>
				<Grid item style={{ alignSelf: 'flex-start', display: 'flex', justifyContent: 'center', width: '100%' }}>
					<Typography
						variant="h2"
						style={{
							color: '#fff',
							marginBottom: '25px',
							fontWeight: '400'
						}}
						align="left"
					>
						{intl.formatMessage({ id: 'app.circles.usageStatistics' })}
					</Typography>
				</Grid>
				<Grid item container style={{ width: '100%' }} spacing={2}>
                    <Grid item>
					<Typography
                        variant="h6"
                        style={{
							color: '#fff',
							marginBottom: '25px',
							fontWeight: '400'
						}}
                        align="left"
                    >
                        {intl.formatMessage({ id: 'app.circles.usageStatistics.dateRangeName' })}
						</Typography>
                    </Grid>
                </Grid>
				<Grid item container style={{ width: '100%' }} spacing={2}>
                    <Grid item style={{ flex: 0.5 }}>
						<FormDateField
                            label="From"
                            fullWidth
                            type="text"
                            name="startDate"
                            validators={[requiredValidator]}
                            variant="filled"
							design="dark"
                        />
                    </Grid>
                    <Grid item style={{ flex: 0.5 }}>
                        <FormDateField
                            label="To"
                            fullWidth
                            type="text"
                            name="endDate"
                            validators={[requiredValidator]}
                            variant="filled"
							design="dark"
                        />
                    </Grid>
                </Grid>
				<Grid
					item
					style={{
						width: '100%'
					}}
				>
					<RadioGroup>
						<Grid
							container
							spacing={2}
							direction='column'
							style={{ width: '100%' }}
						>
							{/* <Grid item xs="auto">
								<FormControlLabel
									label={intl.formatMessage({ id: 'app.circles.usageStatistics.executiveUsageLabel' })}
									control={
										<Radio color="secondary"
											onChange={handleChangeShowExecutiveUsage}
											checked={currentUsageStatisticsType == USAGE_STATISTICS_TYPE.EXECUTIVE_USAGE}
										/>
									}
								/>
							</Grid>
							{
								currentUsageStatisticsType == USAGE_STATISTICS_TYPE.EXECUTIVE_USAGE
								&& <Grid
									item
									container
									xs="auto"
									direction='column'
									spacing={1}
									style={{
										padding: 0,
										margin: '0px 0px 15px 30px'
									}}
								>
									<Grid item xs="auto">
										<FormControlLabel
											label={intl.formatMessage({ id: 'app.circles.executiveStatistics.executiveFundersLabel' })}
											control={
												<Radio color="secondary"
													onChange={handleSetExecutiveFunders}
													checked={currentExecutiveStatisticType == EXECUTIVE_STATISTICS_TYPE.EXECUTIVE_FUNDERS}
												/>
											}
										/>
									</Grid>
									<Grid item xs="auto">
										<FormControlLabel
											label={intl.formatMessage({ id: 'app.circles.executiveStatistics.allFundersLabel' })}
											control={
												<Radio color="secondary"
													onChange={handleSetAllFunders}
													checked={currentExecutiveStatisticType == EXECUTIVE_STATISTICS_TYPE.ALL_FUNDERS}
												/>
											}
										/>
									</Grid>
								</Grid>
							} */}
							<Grid item xs="auto">
								<FormControlLabel
									label={intl.formatMessage({ id: 'app.circles.usageStatistics.basicUsageLabel' })}
									control={
										<Radio color="secondary"
											onChange={handleChangeShowBasicUsage}
											checked={currentUsageStatisticsType == USAGE_STATISTICS_TYPE.BASIC_USAGE}
										/>
									}
								/>
							</Grid>
							<Grid item xs="auto">
								<FormControlLabel
									label={intl.formatMessage({ id: 'app.circles.usageStatistics.showRawDataName' })}
									control={
										<Radio color="secondary"
											onChange={handleChangeShowRawData}
											checked={currentUsageStatisticsType == USAGE_STATISTICS_TYPE.RAW_DATA}
										/>
									}
								/>
							</Grid>
						</Grid>
					</RadioGroup>
				</Grid>
				<Grid item>
					<CalmButton
						label={intl.formatMessage({ id: 'app.circles.usageStatistics.exportButton' })}
						style={{

							width: '267px',
							marginTop: '40px'
						}}
						disabled={isReportDownloading}
                        type="submit"
                        variant="contained"
                        color="primary"
					>
						{isReportDownloading ? <CircularProgress size={25} color="white"/> : intl.formatMessage({ id: 'app.circles.usageStatistics.exportButton' })}
					</CalmButton>
				</Grid>
			</Grid>
		</form>
	)
}


const mapStateToProps = (state, ownProps) => ({
	isReportDownloading: isReportDownloadingSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	downloadCircleUsageStatistics: (startDate, endDate) => dispatch(fetchCircleUsageStatistics(startDate, endDate)),
	downloadCircleUsageStatisticsRawData: (startDate, endDate) => dispatch(fetchCircleUsageStatisticsRawData(startDate, endDate)),
	downloadExecutiveUsageStatistics: (startDate, endDate, executiveStatisticType) => dispatch(fetchExecutiveUsageStatistics(startDate, endDate, executiveStatisticType))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: 'exportUsageStatistics',
		touchOnChange: true,
		touchOnBlur: true
	})
)

export default enhance(UsageStatisticsModal)
