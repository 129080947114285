import React, {  } from 'react'
import Grid from '@material-ui/core/Grid'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import Paper from '@material-ui/core/Paper'
import { Typography } from '@material-ui/core'
import { protocolVersionScoringGroupSurveysSelector } from '../../../redux/selectors'
import { useTheme } from '@material-ui/core'
import { useSelector, shallowEqual } from 'react-redux'

const SurveyBenchmarcNavigator = ({currentSurvey, onSurveyChange }) => {
	var theme = useTheme()
	const dispatch = useDispatch()
	const intl = useIntl()
	const surveys = useSelector(protocolVersionScoringGroupSurveysSelector, shallowEqual)

	const getBackgroundColor = (survey) => {
		if (survey.surveyInstanceId == currentSurvey?.surveyInstanceId) {
			return theme.palette.secondary.A600
		} else {
			return '#fff'
		}
	}

	const getFontColor = (survey) => {
		if (survey.surveyInstanceId == currentSurvey?.surveyInstanceId) {
			return '#fff'
		} else {
			return '#000'
		}
	}

	const getBorderColor = (survey) => {
		// if (survey.surveyInstanceId == currentSurvey?.surveyInstanceId) {
		// 	return theme.palette.primary.A800
		// } else {
		// 	return '#fff'
		// }
		if (survey.scoringGroupState === 1 && survey.surveyState === 1) {
			return theme.palette.primary.A800
		} else {
			return '#bbb'
		}
	}

	const getSurveyName = (survey) => {
		if(!survey)
			return null

		let result = `${survey.surveyName} ${survey.dueValue} `
		switch (survey.dueUnit) {
			case 0:
				result += intl.formatMessage({ id: 'app.general.days' })
				break;
			case 1:
				result += intl.formatMessage({ id: 'app.general.weeks' })
				break;
			case 2:
				result += intl.formatMessage({ id: 'app.general.months' })
				break;
			default:
				break;
		}

		return result
	}

	return (
		<Paper>
			<Grid container direction="column" style={{ width: '100%' }}>
				<Grid
					item
					style={{
						width: '100%',
						borderBottom: 'solid 1px rgba(0,0,0,0.5)',
						minHeight: '100px',
						padding: '0 11px 0 20px'
					}}
				>
					<Grid container justify="space-between" alignItems="center" style={{ minHeight: '100px' }}>
						<Grid item>
							<Typography variant="subtitle1" data-testid="testid-language-label">
								{intl.formatMessage({ id: 'app.protocol.scoringGroups.benchmarcSurveysHeader' })}</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Grid container direction="column">
						{surveys &&
							surveys
								.map((r, i) => {
									return (
										<Grid
											item
											style={{
												padding: '0 11px 0 20px',
												backgroundColor: getBackgroundColor(r),
												color: getFontColor(r),
												borderLeft: `solid 4px ${getBorderColor(r)}`
											}}
											key={r.surveyInstanceId}
											onClick={() => onSurveyChange(r)}
										>
											<Grid container justify="space-between" alignItems="center" style={{ minHeight: '100px', cursor: 'pointer' }}>
												<Grid item>
													<Grid container direction="column">
														<Grid item>
															{r && (
																<Typography
																	variant="subtitle1"
																	style={{
																		color: 'inherit',
																		textAlign: 'left',
																		fontWeight: '400',
																		wordBreak: 'break-word'
																	}}
																>
																	{getSurveyName(r)}
																</Typography>
															)}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									)
								})}
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	)
}

export default SurveyBenchmarcNavigator