import { getUser } from '../utilities/userUtilities'
import { authenticatedFetch } from './base'

export const fetchStats = () => {
	return authenticatedFetch('/api/statistics', { method: 'GET' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(patient) {
			return patient
		})
}
