import React from "react";
import { TableCell, TableHead, TableRow } from "@material-ui/core";
import { useIntl } from "react-intl";


const RelatedProtocolTableHeader = ({}) => {
    const intl = useIntl()


    return (
        <TableHead>
            <TableRow>
                <TableCell>
                    { intl.formatMessage({ id: "app.sg.details.relatedProtocols.protocolModofocationLabel" }) }
                </TableCell>
                <TableCell>
                    { intl.formatMessage({ id: "app.sg.details.relatedProtocols.circleNameLabel" }) }
                </TableCell>
                <TableCell>
                    { intl.formatMessage({ id: "app.sg.details.relatedProtocols.versionNameLabel" }) }
                </TableCell>
                <TableCell>
                    { intl.formatMessage({ id: "app.sg.details.relatedProtocols.visibleToPatientLabel" }) }
                </TableCell>
            </TableRow>
        </TableHead>
    )
}

export default RelatedProtocolTableHeader;
