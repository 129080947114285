import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { compose } from 'recompose'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import { connect } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import { debounceMilliseconds, minimumSearchCharacters } from '../../utilities/constants'
import { useConstant } from '../../utilities/useConstant'
var debounce = require('lodash/debounce')


const useStyles = makeStyles((theme) => ({
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: '#ededf1',
		'&:hover': {
			backgroundColor: '#ededf1'
		},
		margin: '10px !important',
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(3),
			width: 'auto'
		},
		height: '60px'
	},
	searchIcon: {
		width: theme.spacing(7),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	inputRoot: {
		color: 'inherit',
		width: '100%'
	},
	inputInput: {
		padding: '20px 8px 8px 40px',
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '100%'
		}
	}
}))

const EntityAuditSearchBar = ({
	logs,
    keys,
    onSearch
}) => {
	const intl = useIntl()
	const classes = useStyles()

	const [ currentKeys, setCurrentKeys ] = useState([])
	const [ namedKeys, setNamedKeys ] = useState([])


	useEffect(
		() => {
			onSearch(null, [])
		},
		[]
	)

	useEffect(
		() => {
			if (
				!!keys?.length
				&& !!logs?.length
				&& !!keys.filter(x => !currentKeys.includes(x)).length
			) {
				const namedKeys = keys.map(x => {
					const key = x.substring(0, 1).toLowerCase() + x.substring(1)

					let namedKey = intl.formatMessage({ id: `app.auditTable.entity.${logs[0].entityTypeId}.keys.${key}` })

					return {
						key: key,
						namedKey: namedKey
					}
				})

				setCurrentKeys([...keys])
				setNamedKeys([...namedKeys])
			}
		},
		[keys, logs]
	)


	const onChange = (e) => {
		OnSearchChanged(e.target.value, namedKeys)
	}

	const changeFilter = (value, namedKeys) => {
		if (value && value.length >= minimumSearchCharacters) {
			const keys = namedKeys.filter(x => !!value.split(' ').find(y => x.namedKey.includes(y))).map(x => x.key)

			console.log(keys)

			onSearch(value, keys)
		}
		else if (!value?.length) {
			onSearch(null, [])
		}
	}

	const OnSearchChanged = useConstant(() => debounce(changeFilter, debounceMilliseconds, { leading: true }))

	return (
		<Grid item className={classes.search}>
			<div className={classes.searchIcon}>
				<SearchIcon />
			</div>
			<InputBase
				datatest-id="service-provider-related-entities-search-input"
				placeholder="Search"
				classes={{
					root: classes.inputRoot,
					input: classes.inputInput
				}}
				inputProps={{ 'aria-label': 'search' }}
				onChange={onChange}
			/>
		</Grid>
	)
}


const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = (dispatch, ownProps) => ({
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(EntityAuditSearchBar)
