import React from "react"
import { injectIntl } from "react-intl"
import { connect, useDispatch } from "react-redux"
import { compose } from "recompose"
import { questionReferencesLoadingSelector, questionReferencesSelector } from "../../../redux/selectors"
import { CircularProgress, Divider, Grid, List, ListItem, ListItemText, Paper, Typography, makeStyles, useTheme } from "@material-ui/core"
import { Fragment } from "react"
import { useRouteMatch } from "react-router"
import { useEffect } from "react"
import { loadQuestionReferences } from "../../../redux/actions"
import { Link } from 'react-router-dom'


const useStyles = makeStyles({
	link: {
		marginBottom: "5px",
		fontSize: "16px"
	},
	icon: {
		display: "inline-block", marginBottom:"-4px", marginLeft: "10px", marginRight: "5px"
	}
})


const QuestionDetails = ({
    references,
    isLoading,
    intl
}) => {
    const theme = useTheme()
    const dispatch = useDispatch()
	const match = useRouteMatch()
	const classes = useStyles()


    useEffect(
		() => {
			const questionId = match?.params?.questionId
			const questionType = match?.params?.questionType

			if (
				!!questionId
				&& !!questionType
			) {
				dispatch(
					loadQuestionReferences(questionId, questionType)
				)
			}
		},
		[match?.params?.questionId, match?.params?.questionType]
	)


    return (
        <Paper
            style={{
                margin: '0',
                padding: '20px 0'
            }}
        >
            <Grid container direction="column" justify="space-evenly">
                <Grid item style={{ height: '100%', width: '100%' }}>
                    <div style={{ padding: '0 3%' }}>
                        <Grid
                            container
                            direction="column"
                            style={{ backgroundColor: theme.palette.primary.A200, padding: '0 3%' }}
                        >
                            <Grid item>
                                <Grid
                                    container
                                    direction="column"
                                    justify="flex-start"
                                    style={{
                                        minHeight: '120px',
                                        padding: '15px 0'
                                    }}
                                >
                                    {
                                        !references
                                        && !!isLoading
                                        && <Grid item xs={12}>
                                            <Grid
                                                container
                                                justifyContent="center"
                                            >
                                                <Grid
                                                    item
                                                    style={{
                                                        paddingTop: '25px'
                                                    }}
                                                >
                                                    <CircularProgress/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }

                                    {
                                        !references
                                        && !isLoading
                                        && <Grid item>
                                            <Typography
                                                style={{
                                                    fontSize: '20px',
                                                    lineHeight: '25px',
                                                    letterSpacing: '0.25px',
                                                    fontWeight: '700'
                                                }}
                                            >
                                                {
                                                    intl.formatMessage({ id: "app.question.noReferences" })
                                                }
                                            </Typography>
                                        </Grid>
                                    }

                                    {
                                        (
                                            !!references?.surveys?.length
                                            || !!references?.protocols?.length
                                            || !!references?.bundles?.length
                                            || !!references?.sg?.length
                                        ) && <Fragment>
                                            {
                                                !!references?.surveys?.length
                                                &&  <Fragment>
                                                    <Grid item>
                                                        <Typography
                                                            style={{
                                                                fontSize: '20px',
                                                                lineHeight: '25px',
                                                                letterSpacing: '0.25px',
                                                                fontWeight: '700',
                                                                paddingTop: '15px',
                                                                paddingBottom: '10px'
                                                            }}
                                                        >
                                                            {
                                                                intl
                                                                    .formatMessage({ id: "app.question.references.surveys" })
                                                                    .replace("%%COUNT%%", references.surveys.length)
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    
                                                    <List>
                                                        {
                                                            references
                                                                .surveys
                                                                .map(survey => (
                                                                    <Fragment>
                                                                        <ListItem>
                                                                            <ListItemText
                                                                                primary={
                                                                                    <Link
                                                                                        to={`/surveys/${survey.id}/details`}
                                                                                    >
                                                                                        <Typography variant="body2" className={classes.link}>
                                                                                            {survey.title}
                                                                                        </Typography>
                                                                                    </Link>
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                        <Divider component="li" />
                                                                    </Fragment>
                                                                ))
                                                        }
                                                    </List>
                                                </Fragment>
                                            }
                                            
                                            {
                                                !!references?.protocols?.length
                                                &&  <Fragment>
                                                    <Grid item>
                                                        <Typography
                                                            style={{
                                                                fontSize: '20px',
                                                                lineHeight: '25px',
                                                                letterSpacing: '0.25px',
                                                                fontWeight: '700',
                                                                paddingTop: '15px',
                                                                paddingBottom: '10px'
                                                            }}
                                                        >
                                                            {
                                                                intl
                                                                    .formatMessage({ id: "app.question.references.protocols" })
                                                                    .replace("%%COUNT%%", references.protocols?.length)
                                                            }
                                                        </Typography>
                                                    </Grid>

                                                    <List>
                                                        {
                                                            references
                                                                .protocols
                                                                .map(protocol => (
                                                                    <Fragment>
                                                                        <ListItem>
                                                                            <ListItemText
                                                                                primary={
                                                                                    <Link
                                                                                        to={`/protocols/${protocol.id}/version/${protocol.defaultVersionId}/details`}
                                                                                    >
                                                                                        <Typography variant="body2" className={classes.link}>
                                                                                            {protocol.name}
                                                                                        </Typography>
                                                                                    </Link>
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                        <Divider component="li" />
                                                                    </Fragment>
                                                                ))
                                                        }
                                                    </List>
                                                </Fragment>
                                            }
                                            
                                            {
                                                !!references?.bundles?.length
                                                &&  <Fragment>
                                                    <Grid item>
                                                        <Typography
                                                            style={{
                                                                fontSize: '20px',
                                                                lineHeight: '25px',
                                                                letterSpacing: '0.25px',
                                                                fontWeight: '700',
                                                                paddingTop: '15px',
                                                                paddingBottom: '10px'
                                                            }}
                                                        >
                                                            {
                                                                intl
                                                                    .formatMessage({ id: "app.question.references.bundles" })
                                                                    .replace("%%COUNT%%", references.bundles?.length)
                                                            }
                                                        </Typography>
                                                    </Grid>

                                                    <List>
                                                        {
                                                            references
                                                                .bundles
                                                                .map(bundle => (
                                                                    <Fragment>
                                                                        <ListItem>
                                                                            <ListItemText
                                                                                primary={
                                                                                    <Link
                                                                                        to={`/bundles/${bundle.bundleId}/languages/1`}
                                                                                    >
                                                                                        <Typography variant="body2" className={classes.link}>
                                                                                            {bundle.bundleName}
                                                                                        </Typography>
                                                                                    </Link>
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                        <Divider component="li" />
                                                                    </Fragment>
                                                                ))
                                                        }
                                                    </List>
                                                </Fragment>
                                            }
                                            
                                            {
                                                !!references?.scoringGroups?.length
                                                &&  <Fragment>
                                                    <Grid item>
                                                        <Typography
                                                            style={{
                                                                fontSize: '20px',
                                                                lineHeight: '25px',
                                                                letterSpacing: '0.25px',
                                                                fontWeight: '700',
                                                                paddingTop: '15px',
                                                                paddingBottom: '10px'
                                                            }}
                                                        >
                                                            {
                                                                intl
                                                                    .formatMessage({ id: "app.question.references.sg" })
                                                                    .replace("%%COUNT%%", references.scoringGroups?.length)
                                                            }
                                                        </Typography>
                                                    </Grid>

                                                    <List>
                                                        {
                                                            references
                                                                .scoringGroups
                                                                .map(sg => (
                                                                    <Fragment>
                                                                        <ListItem>
                                                                            <ListItemText
                                                                                primary={
                                                                                    <Link
                                                                                        to={`/sg/${sg.id}`}
                                                                                    >
                                                                                        <Typography variant="body2" className={classes.link}>
                                                                                            {sg.name}
                                                                                        </Typography>
                                                                                    </Link>
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                        <Divider component="li" />
                                                                    </Fragment>
                                                                ))
                                                        }
                                                    </List>
                                                </Fragment>
                                            }
                                        </Fragment>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </Paper>
    )
}

const mapStateToProps = (state, ownProps) => ({
    references: questionReferencesSelector(state),
    isLoading: questionReferencesLoadingSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
})

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)

export default enhance(QuestionDetails)
