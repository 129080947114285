import React, { Fragment, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { injectIntl, FormattedDate } from 'react-intl'
import { compose } from 'recompose'
import useTheme from '@material-ui/core/styles/useTheme'
import { kNumber } from '../../../utilities/userUtilities'
import { fetchPlan } from '../../../redux/actions'
import { planSelector } from '../../../redux/selectors'
import Tooltip from '@material-ui/core/Tooltip'
import { useDispatch } from 'react-redux'
import { useSelector, shallowEqual } from 'react-redux'

const YourPlan = ({ intl, user }) => {
	const theme = useTheme()
	const dispatch = useDispatch()
	const plan = useSelector(planSelector, shallowEqual)

	useEffect(() => {
		if (!!user && !!user.id) {
			dispatch(fetchPlan(user.id))
		}
	}, [user])
	return (
		<div data-testid="your-plan">
			{!(plan && plan.subscriptionExpires) && (
				<Typography data-testid="your-plan-no-plan" variant='body1' style={{ color: theme.palette.secondary.main, fontWeight: '400' }}>
					{intl.formatMessage({ id: 'app.account.managePlan.noPlanYet' })}
				</Typography>
			)}
			{(plan && plan.subscriptionExpires) && (
				<Grid container direction='column'>
					<Grid
						data-testid="your-plan-inner"
						container
						direction='row'
						justify='space-around'
						alignItems='center'
						style={{ padding: '30px 2%' }}>
						<Grid item xs={12} sm={4}>
							<Typography data-testid="your-plan-subscription" variant="h6" style={{ color: theme.palette.secondary.main }}>
								{intl.formatMessage({ id: 'app.general.subscription' })}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography data-testid="your-plan-joined" variant="h6" style={{ color: theme.palette.secondary.main }}>
								<FormattedDate year='numeric' month='long' day='2-digit' value={plan.subscriptionJoined} />
							</Typography>
							<Typography variant='body2' style={{ color: theme.palette.secondary.main }}>
								{intl.formatMessage({ id: 'app.account.managePlan.subscriptionStart' })}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography data-testid="your-plan-expires-date" variant="h6" style={{ color: theme.palette.secondary.main }}>
								<FormattedDate year='numeric' month='long' day='2-digit' value={plan.subscriptionExpires} />
							</Typography>
							<Typography data-testid="your-plan-expires" variant='body2' style={{ color: theme.palette.secondary.main }}>
								{intl.formatMessage({ id: 'app.account.managePlan.expires' })}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			)}
		</div>
	)
}

const enhance = compose(injectIntl)

export default enhance(YourPlan)
