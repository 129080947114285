import { Divider, List, ListItem, ListItemText, makeStyles, Typography } from '@material-ui/core'
import React, { Fragment, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import { fetchBundleRelatedProtocols, fetchQuestionRelatedProtocols, fetchSurveyRelatedProtocols } from '../../../redux/actions'
import { relatedObservationProtocolsSelector } from '../../../redux/selectors'


const useStyles = makeStyles({
	link: {
		marginBottom: "5px",
		fontSize: "16px"
	},
	icon: {
		display: "inline-block", marginBottom:"-4px", marginLeft: "10px", marginRight: "5px"
	}
})

const RelatedProtocols = ({
    questionId,
    questionType,
    bundleId,
    surveyId,
    style,
    protocols,
    loadByQuestion,
    loadByBundle,
    loadBySurvey,
    intl
}) => {
	const classes = useStyles()

    useEffect(
        () => {
            if (!!questionId) {
                loadByQuestion(questionId, questionType)
            }
            else if (!!bundleId) {
                loadByBundle(bundleId)
            }
            else if (!!surveyId) {
                loadBySurvey(surveyId)
            }
        },
        []
    )
    return (
        <div
            style={{
                ...style
            }}
        >
            <Typography variant="h4"
            >
                { intl.formatMessage({ id: "app.relatedEntities.usedByProtocols" }).replace("%%USED_COUNT%%", protocols?.length) }
            </Typography>

            <List>
                {
                    !!protocols
                    && protocols.map(
                        (protocol) => {
                            return (
                                <Fragment>
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                <Link to={`/protocols/${protocol.id}/version/${protocol.defaultVersionId}/details`}>
                                                    <Typography variant="body2" className={classes.link}>
                                                        {protocol.name}
                                                    </Typography>
                                                </Link>
                                            }
                                        />
                                    </ListItem>
                                    <Divider component="li" />
                                </Fragment>
                            )
                        }
                    )
                }
            </List>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    protocols: relatedObservationProtocolsSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    loadByQuestion: (questionId, questionType) => dispatch(fetchQuestionRelatedProtocols(questionId, questionType)),
    loadByBundle: (bundleId) => dispatch(fetchBundleRelatedProtocols(bundleId)),
    loadBySurvey: (surveyId) => dispatch(fetchSurveyRelatedProtocols(surveyId)),
})

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)

export default enhance(RelatedProtocols)