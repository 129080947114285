import React, { Fragment, useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import QuestionBuilder from './QuestionBuilder'
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined'
import Popover from '@material-ui/core/Popover'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import { useTheme } from '@material-ui/core/styles'
import ContentBuilder from './ContentBuilder'
import BundleSelector from './BundleSelector'
import {
	startBuildingQuestion,
	stopBuildingQuestion,
	startBuildingContent,
	stopBuildingContent,
	startAddingBundle,
	stopAddingBundle,
	stopAddingQuestion
} from '../../redux/actions'
import {
	isBuildingQuestionSelector,
	isAddingBundleSelector,
	isBuildingQuestionContainerIdSelector,
	isBuildingContentSelector,
	isBuildingContentContainerIdSelector,
	isBuildingBundleContainerIdSelector
} from '../../redux/selectors'
import { useDispatch } from 'react-redux'
import { uuidv4 } from '../../utilities/utilities'
import { useSelector, shallowEqual } from 'react-redux'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles((theme) => ({
	typography: {
		padding: theme.spacing(2)
	}
}))

const AddQuestionBuilderButton = ({
	position,
	node,
	list,
	containerId,
	onAddCallback,
	onAddExistingCallback,
	onAddBundleCallback,
	ignoreBundles = false,
	isLast = false,
	suppressNonDiscreteQuestions = false,
	showScoreEntry,
	searchType
}) => {
	const theme = useTheme()
	const dispatch = useDispatch()
	const isBuildingQuestion = useSelector(isBuildingQuestionSelector)
	const isAddingBundle = useSelector(isAddingBundleSelector)
	const buildingContainerId = useSelector(isBuildingQuestionContainerIdSelector)
	const bundleContainerId = useSelector(isBuildingBundleContainerIdSelector)
	const isBuildingContent = useSelector(isBuildingContentSelector)
	const buildingContentContainerId = useSelector(isBuildingContentContainerIdSelector)
	const [ showQuestionBuilder, setShowQuestionBuilder ] = useState(false)
	const classes = useStyles()
	const intl = useIntl()
	const [ anchorEl, setAnchorEl ] = React.useState(null)
	const [ showContentBuilder, setShowContentBuilder ] = useState(null)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const onContentSelection = () => {
		setAnchorEl(null)
		dispatch(startBuildingContent(containerId))
		setShowContentBuilder(true)
	}

	const onQuestionSelection = () => {
		dispatch(startBuildingQuestion(null, containerId))
		setAnchorEl(null)
	}

	const onBundleSelection = () => {
		dispatch(startAddingBundle(null, containerId))
		setAnchorEl(null)
	}

	const onAddQuestionCallback = (show, question, commentsObj) => {
		if (question) {
			if (node && node.data && node.data.sortOrder) {
				onAddCallback(question, Math.trunc(isLast ? node.data.sortOrder + 1 : node.data.sortOrder), false, commentsObj)
			} else {
				onAddCallback(question, 1, false, commentsObj)
			}
		}
	}

	const onAddExistingQuestionCallback = (show, id, typeId, position, commentsObj) => {
		if (id) {
			if (node && node.data && node.data.sortOrder) {
				onAddExistingCallback(id, typeId, Math.trunc(isLast ? node.data.sortOrder + 1 : node.data.sortOrder), commentsObj)
			} else {
				onAddExistingCallback(id, typeId, position || 1, commentsObj)
			}
		}
	}

	const onAddGroupCallback = (show, id, commentsObj) => {
		dispatch(stopAddingBundle())
		onAddBundleCallback(id, position || 1, commentsObj)
	}

	const bundleKeyPressHandler = (e) => {
		if (e && e.keyCode === 27) {
			dispatch(stopAddingBundle())
		}
	}
	const questionKeyPressHandler = (e) => {
		if (e && e.keyCode === 27) {
			dispatch(stopBuildingQuestion())
		}
	}
	const copyKeyPressHandler = (e) => {
		if (e && e.keyCode === 27) {
			dispatch(stopBuildingContent())
		}
	}

	const open = Boolean(anchorEl)
	return (
		<Fragment>
			{!showQuestionBuilder && (
				<div style={{ borderBottom: 'solid 1px black' }}>
					<Menu
						id="simple-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
					>
						<MenuItem onClick={onContentSelection}>
							<Typography variant="body2">
								{intl.formatMessage({ id: 'app.survey.surveyReadOnlyContentButtonText' })}
							</Typography>
						</MenuItem>
						{!ignoreBundles && (
							<MenuItem onClick={onBundleSelection}>
								<Typography variant="body2">
									{intl.formatMessage({ id: 'app.survey.surveyBundleContentButtonText' })}{' '}
								</Typography>
							</MenuItem>
						)}
						<MenuItem onClick={onQuestionSelection}>
							<Typography variant="body2">
								{intl.formatMessage({ id: 'app.survey.surveyQuestionButtonText' })}{' '}
							</Typography>
						</MenuItem>
					</Menu>
					<IconButton
						onClick={handleClick}
						style={{ transform: 'translate(-50%, 50%)', marginLeft: '50%' }}
						color="primary"
					>
						<AddCircleOutlinedIcon
							style={{
								fontSize: '24px',
								backgroundColor: 'white',
								color: theme.palette.secondary.A700
							}}
						/>
					</IconButton>
				</div>
			)}
			{isBuildingContent &&
			buildingContentContainerId == containerId && (
				<div style={{ paddingTop: '40px', marginBottom: '40px' }} onKeyDown={copyKeyPressHandler}>
					<ContentBuilder onAddCallback={onAddQuestionCallback} position={position} node={node} list={list} />
				</div>
			)}
			{isAddingBundle &&
			bundleContainerId == containerId && (
				<div style={{ paddingTop: '40px', marginBottom: '40px' }} onKeyDown={bundleKeyPressHandler}>
					<BundleSelector
						onAddCallback={onAddGroupCallback}
						onCancelCallback={() => dispatch(stopAddingBundle())}
						position={position}
						node={node}
						list={list}
					/>
				</div>
			)}
			{isBuildingQuestion &&
			buildingContainerId == containerId && (
				<div style={{ paddingTop: '40px' }} onKeyDown={questionKeyPressHandler}>
					<QuestionBuilder
						position={position}
						node={node}
						list={list}
						searchType={searchType}
						containerId={containerId}
						showScoreEntry={showScoreEntry}
						onAddCallback={onAddQuestionCallback}
						onAddExistingCallback={onAddExistingQuestionCallback}
						suppressNonDiscreteQuestions={suppressNonDiscreteQuestions}
					/>
				</div>
			)}
		</Fragment>
	)
}

export default AddQuestionBuilderButton
