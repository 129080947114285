import * as cookies from '../../../utilities/cookies'
import * as api from '../../../apis/treatment'
import history from '../../../utilities/history'
import { createSelector } from 'reselect'
import { hideModal } from '../core'

const ADD_TREATMENT_REQUESTED = 'ADD_TREATMENT_REQUESTED'
const ADD_TREATMENT_SUCCESS = 'ADD_TREATMENT_SUCCESS'
const ADD_TREATMENT_FAILED = 'ADD_TREATMENT_FAILED'

export const addTreatment = (internalName) => (dispatch) => {
	dispatch({ type: ADD_TREATMENT_REQUESTED, data: {} })

	api.addTreatment(internalName).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({ type: ADD_TREATMENT_SUCCESS, data: {} })
			history.push(`/treatments/${response.id}`)
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.treatments.creationSuccessful`, isSuccess: true }
			})
		} else {
			dispatch({ type: ADD_TREATMENT_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.treatments.creationFailed`, isSuccess: false }
			})
		}
	})
	return
}

const UPDATE_TREATMENT_REQUESTED = 'UPDATE_TREATMENT_REQUESTED'
const UPDATE_TREATMENT_SUCCESS = 'UPDATE_TREATMENT_SUCCESS'
const UPDATE_TREATMENT_FAILED = 'UPDATE_TREATMENT_FAILED'

export const updateTreatment = (id, name, description, baseTreatmentId, languageId, internalName, imageUrl) => (
	dispatch
) => {
	dispatch({ type: UPDATE_TREATMENT_REQUESTED, data: {} })

	api.updateTreatment(id, name, description, baseTreatmentId, languageId, internalName, imageUrl).then((response) => {
		if (response && response.isSuccessful) {
			if (response.treatments && response.treatments.length > 0) {
				let t = {
					parentTreatment: response.treatments.find((d) => (d.languageId = 1)),
					treatments: response.treatments
				}
				dispatch({ type: UPDATE_TREATMENT_SUCCESS, data: t })
			}

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.treatments.updateSuccessful`, isSuccess: true }
			})
		} else {
			dispatch({ type: UPDATE_TREATMENT_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.treatments.updateFailed`, isSuccess: false }
			})
		}
	})
	return
}

const UPDATE_TREATMENT_INTERNAL_NAME_REQUESTED = 'UPDATE_TREATMENT_INTERNAL_NAME_REQUESTED'
const UPDATE_TREATMENT_INTERNAL_NAME_SUCCESS = 'UPDATE_TREATMENT_INTERNAL_NAME_SUCCESS'
const UPDATE_TREATMENT_INTERNAL_NAME_FAILED = 'UPDATE_TREATMENT_INTERNAL_NAME_FAILED'

export const updateTreatmentInternalName = (id, internalName) => (dispatch) => {
	dispatch({ type: UPDATE_TREATMENT_INTERNAL_NAME_REQUESTED, data: {} })

	api.updateTreatmentInternalName(id, internalName).then((response) => {
		if (response && response.isSuccessful) {
			if (response.treatments && response.treatments.length > 0) {
				let t = {
					parentTreatment: response.treatments.find((d) => (d.languageId = 1)),
					treatments: response.treatments
				}
				dispatch({ type: UPDATE_TREATMENT_INTERNAL_NAME_SUCCESS, data: t })
				dispatch(hideModal())
			}

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.treatments.updateSuccessful`, isSuccess: true }
			})
		} else {
			dispatch({ type: UPDATE_TREATMENT_INTERNAL_NAME_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.treatments.updateFailed`, isSuccess: false }
			})
		}
	})
	return
}

const FETCH_TREATMENTS_REQUESTED = 'FETCH_TREATMENTS_REQUESTED'
const FETCH_TREATMENTS_SUCCESS = 'FETCH_TREATMENTS_SUCCESS'
const FETCH_TREATMENTS_FAILED = 'FETCH_TREATMENTS_FAILED'

export const fetchTreatments = (searchPhrase) => (dispatch) => {
	dispatch({ type: FETCH_TREATMENTS_REQUESTED, data: {} })

	api.fetchTreatments(searchPhrase).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({ type: FETCH_TREATMENTS_SUCCESS, data: response.treatments })
		} else {
			dispatch({ type: FETCH_TREATMENTS_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.treatments.loadingFailed`, isSuccess: false }
			})
		}
	})
	return
}

const FETCH_TREATMENT_REQUESTED = 'FETCH_TREATMENT_REQUESTED'
const FETCH_TREATMENT_SUCCESS = 'FETCH_TREATMENT_SUCCESS'
const FETCH_TREATMENT_FAILED = 'FETCH_TREATMENT_FAILED'

export const fetchTreatment = (id) => (dispatch) => {
	dispatch({ type: FETCH_TREATMENT_REQUESTED, data: {} })

	api.fetchTreatment(id).then((response) => {
		if (response && response.isSuccessful) {
			if (response.treatments && response.treatments.length > 0) {
				let t = {
					parentTreatment: response.treatments.find((d) => (d.languageId = 1)),
					treatments: response.treatments
				}
				dispatch({ type: FETCH_TREATMENT_SUCCESS, data: t })
			}
		} else {
			dispatch({ type: FETCH_TREATMENT_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.treatments.loadingFailed`, isSuccess: false }
			})
		}
	})
	return
}

const SORT_TREATMENTS_REQUESTED = 'SORT_TREATMENTS_REQUESTED'
export const setTreatmentSort = (field, isDescending) => (dispatch, state) => {
	let currentState = state()

	let data = currentState.treatment.treatments.sort((a, b) => {
		if (isDescending) {
			return a[field] > b[field] ? -1 : 1
		} else {
			return b[field] > a[field] ? -1 : 1
		}
	})
	return dispatch({
		type: SORT_TREATMENTS_REQUESTED,
		data: { items: data, field: field, isDescending: isDescending }
	})
}

const FETCH_TREATMENT_AUTOCOMPLETE_REQUESTED = 'FETCH_TREATMENT_AUTOCOMPLETE_REQUESTED'
const FETCH_TREATMENT_AUTOCOMPLETE_SUCCESS = 'FETCH_TREATMENT_AUTOCOMPLETE_SUCCESS'
const FETCH_TREATMENT_AUTOCOMPLETE_FAILED = 'FETCH_TREATMENT_AUTOCOMPLETE_FAILED'

export const fetchTreatmentAutoComplete = (searchPhrase, noMatchesText) => (dispatch) => {
	dispatch({ type: FETCH_TREATMENT_AUTOCOMPLETE_REQUESTED, data: {} })

	api.fetchTreatments(searchPhrase).then((response) => {
		if (response && response.isSuccessful) {
			{
				if (response.treatments && response.treatments.length > 0) {
					return dispatch({ type: FETCH_TREATMENT_AUTOCOMPLETE_SUCCESS, data: response.treatments })
				} else {
					return dispatch({
						type: FETCH_TREATMENT_AUTOCOMPLETE_SUCCESS,
						data: [ { id: -1, name: noMatchesText } ]
					})
				}
			}
		} else {
			return dispatch({ type: FETCH_TREATMENT_AUTOCOMPLETE_FAILED, data: {} })
		}
	})
	return
}

const SET_TREATMENT_SEARCH_TERM_REQUESTED = 'SET_TREATMENT_SEARCH_TERM_REQUESTED'
const SET_TREATMENT_SEARCH_TERM_SUCCESS = 'SET_TREATMENT_SEARCH_TERM_SUCCESS'
const SET_TREATMENT_SEARCH_TERM_FAILED = 'SET_UNIT_FILTER_FAILED'

export const setTreatmentSearchTerm = (value) => (dispatch) => {
	dispatch({ type: SET_TREATMENT_SEARCH_TERM_REQUESTED, data: {} })

	return dispatch({ type: SET_TREATMENT_SEARCH_TERM_SUCCESS, data: value })
}

const initial = {
	treatment: null,
	isAddingInProgress: false,
	treatments: [],
	sortField: 'modifiedDateSeconds',
	isDescending: true,
	autoCompleteSuggestions: [],
	searchTerm: ''
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case SORT_TREATMENTS_REQUESTED:
			return {
				...state,
				treatments: [ ...action.data.items ],
				sortField: action.data.field,
				isDescending: action.data.isDescending
			}
		case ADD_TREATMENT_REQUESTED:
			return { ...state, isAddingInProgress: true }
		case ADD_TREATMENT_FAILED:
		case ADD_TREATMENT_SUCCESS:
			return { ...state, isAddingInProgress: false }
		case FETCH_TREATMENTS_SUCCESS:
			return { ...state, treatments: action.data }
		case UPDATE_TREATMENT_INTERNAL_NAME_SUCCESS:
		case UPDATE_TREATMENT_SUCCESS:
		case FETCH_TREATMENT_SUCCESS:
			return { ...state, treatment: action.data }
		case FETCH_TREATMENT_AUTOCOMPLETE_SUCCESS:
			return { ...state, autoCompleteSuggestions: action.data }
		case SET_TREATMENT_SEARCH_TERM_SUCCESS:
			return {
				...state,
				searchTerm: action.data
			}

		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.treatment

export const isAddTreatmentInProgressSelector = createSelector(mainSelector, (state) => {
	return state && state.isAddingInProgress
})

export const treatmentsSelector = createSelector(mainSelector, (state) => {
	return state && state.treatments
})
export const treatmentSelector = createSelector(mainSelector, (state) => {
	return state && state.treatment
})

export const sortTreatmentsDirectionSelector = createSelector(mainSelector, (state) => {
	return state.isDescending
})

export const sortTreatmentsFieldSelector = createSelector(mainSelector, (state) => {
	return state.sortField
})

export const treatmentAutoCompleteSuggestionSelector = createSelector(mainSelector, (state) => {
	if (state && state.autoCompleteSuggestions) {
		return state.autoCompleteSuggestions
	}
	return []
})

export const treatmentSearchTermSelector = createSelector(mainSelector, (state) => {
	return state.searchTerm
})
