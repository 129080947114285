import React, { useEffect } from 'react'
import { TableHead } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import { compose } from 'recompose'
import withStyles from '@material-ui/core/styles/withStyles'
import SponsorHeader from './SponsorHeader'
import SponsorRow from './SponsorRow'
import TableBody from '@material-ui/core/TableBody'
import { CircularProgress } from '@material-ui/core'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { connect } from 'react-redux'
import { hasMoreSponsorsSelector, isLoadingSponsorsSelector, resetStripeButtonIsVisibleSelector, sponsorSearchTermSelector } from '../../../redux/selectors'
import { checkResetStripeButtonVisibility, searchAdditionalSponsors } from '../../../redux/actions'

const styles = {
	root: {
		background: 'black'
	},
	input: {
		color: 'white',
		backgroundColor: 'rgb(36, 48, 65)'
	}
}

const SubscriberTable = ({ data, search, hasMore, getSponsors, loading, checkResetButtonVisibility, resetStripeButtonIsVisible }) => {

	useEffect(() => {
		checkResetButtonVisibility()
	}, [])

	const getAdditionalSponsors = () => {
		getSponsors(search, data.length)
	}

	const [sentryRef] = useInfiniteScroll({
		loading,
		hasNextPage: hasMore,
		onLoadMore: getAdditionalSponsors,
		delayInMs: 1000
	});

	return (
		<div className="admin-table-fullheight">
			<Table stickyHeader data-testid="sponsors-table">
				<TableHead>
					<SponsorHeader />
				</TableHead>
				<TableBody style={{ borderTop: 'none' }}>
					{data &&
						data.map((sponsor) => <SponsorRow sponsor={sponsor} key={sponsor.id} showResetButton={resetStripeButtonIsVisible} />)}
				</TableBody>
			</Table>
			{data && data.length > 0 && (loading || hasMore) && (
				<div className="admin-table-progress" ref={sentryRef}>
					<CircularProgress />
				</div>
			)}
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	loading: isLoadingSponsorsSelector(state),
	hasMore: hasMoreSponsorsSelector(state),
	search: sponsorSearchTermSelector(state),
	resetStripeButtonIsVisible: resetStripeButtonIsVisibleSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	getSponsors: (search, offset) => dispatch(searchAdditionalSponsors(search, offset)),
	checkResetButtonVisibility: () => dispatch(checkResetStripeButtonVisibility())
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))

export default enhance(SubscriberTable)
