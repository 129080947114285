import React, { useEffect } from 'react'
import { withStyles } from "@material-ui/core"
import { injectIntl } from "react-intl"
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Typography, Grid } from "@material-ui/core"
import LabelPaper from "../../../components/LabelPaper"
import YourPlanInner from "./YourPlanInner"


const styles = () => ({
    button: {
        '&:hover': {
            backgroundColor: 'transparent'
        },
    },
    gradient: {
        background:
            'linear-gradient(45deg,  #d4e1f7 0%,#d4e1f7 50%,#ffffff 50%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */',
        filter:
            "progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4e1f7', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */"
    }
})

const YourSponsor = ({ user, loadSubscriptionChanges, subscriptionChanges, classes, intl }) => {
    
    return (
        <div style={{ position: 'relative' }}>
            <div style={{ padding: '0' }}>
                <Grid container direction="column" style={{ color: 'black' }}>
                    <Grid item style={{ width: '100%' }}>
                        <LabelPaper
                            label={intl.formatMessage({ id: 'app.account.managePlan.expires' })}
                            Component={
                                YourPlanInner
                            }
                            user={user}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

const enhance = compose(connect(null, null), injectIntl, withStyles(styles))

export default enhance(YourSponsor)