import React from 'react'
import { compose } from 'recompose'
import withStyles from '@material-ui/core/styles/withStyles'
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'
import MuiTooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

const Tooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: 'white',
		color: 'black',
		fontSize: '1px',
		border: '1px solid white'
	}
}))(MuiTooltip)

const OutlinedIconHelpTooltip = ({ text, size }) => {
	return (
		<Tooltip
			title={
				<Typography color="inherit" style={{ fontSize: '12px' }}>
					{text}
				</Typography>
			}
			placement="left"
		>
			<HelpOutlineOutlinedIcon style={{ width: size || '15px', height: size || '15px'}}/>
		</Tooltip>
	)
}

const enhance = compose()

export default enhance(OutlinedIconHelpTooltip)
