import React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { hideModal } from '../redux/actions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/styles'

const styles = (theme) => ({
	dialogStyle: {
		'& .MuiDialog-container > .MuiPaper-root.MuiDialog-paperFullScreen': {
			backgroundColor: 'rgb(1, 16, 32)',
		},
		[theme.breakpoints.down('sm')]: {
			maxWidth: '100vw',
			overflowX: 'auto'
		},
	},
	root: {
		margin: 0,
        padding: 0,            
		"& h2": {
			marginBottom: '0'
		}  
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
		zIndex: 111
	}
})

const ModalContainer = ({ Component, show, onHide, title, classes, params, canClose }) => {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

	return (
		<div>
			<Dialog
				open={show}
				onClose={onHide}
				disableBackdropClick={true}
				maxWidth={params && params.modalContainerStyle && params.modalContainerStyle.maxWidth !== undefined ? params.modalContainerStyle.maxWidth : 'lg'}
				fullScreen={fullScreen}
				className={classes.dialogStyle}
			>
				<DialogTitle id="form-dialog-title" className={classes.root}>
					{title}
					{canClose && (
						<IconButton
							aria-label="close"
							className={classes.closeButton}
							onClick={params && params.onClose ? params.onClose : onHide}
							style={params && params.closeButtonStyle ? params.closeButtonStyle : {}}
						>
							<CloseIcon style={{ width: '20px', height: '20px' }} />
						</IconButton>
					)}
				</DialogTitle>
				<DialogContent style={{ padding: 0 }}>{Component && <Component {...params} />}</DialogContent>
			</Dialog>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	Component: state.core.component,
	show: state.core.show,
	params: state.core.params,
	canClose: state.core.canClose
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	onHide: () => dispatch(hideModal())
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))

export default enhance(ModalContainer)
