import React from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { useTheme } from '@material-ui/core/styles'
import SearchBundle from './SearchBundle'
import Bundle from './Bundle'
import { useDispatch } from 'react-redux'
import { addScoringGroupBundle, startSaving } from '../../../redux/actions'

export default ({ id, onAddTag }) => {
	const theme = useTheme()
	const dispatch = useDispatch()

	const onAddBundle = (bundleId) => {
		if (id > 0 && bundleId > 0) {
			dispatch(startSaving)
			dispatch(addScoringGroupBundle(id, bundleId))
		}
	}
	return (
		<div style={{ width: '100%', marginBottom: '3%' }}>
			<Grid container direction="column">
				<Paper style={{ padding: '3%' }}>
					<Grid item style={{ backgroundColor: theme.palette.primary.A200, marginBottom: 0 }}>
						<SearchBundle onAddCallback={onAddBundle} />{' '}
					</Grid>
				</Paper>

				<Grid item style={{ marginTop: '18px' }}>
					<Paper style={{ padding: '1% 3% 3% 3%' }}>
						<Bundle groupId={id} onAddTag={onAddTag} />
					</Paper>
				</Grid>
			</Grid>
		</div>
	)
}
