import React, { Fragment, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import withStyles from '@material-ui/core/styles/withStyles'
import SearchBar from './SearchBar'
import { fetchSponsors, setSponsorSearchTerm } from '../../../redux/actions'
import CompositeLabelPaper from '../../../components/CompositeLabelPaper'
import { sponsorSearchTermSelector, sponsorsSelector } from '../../../redux/selectors'
import SponsorTable from './SponsorTable'
import AddSponsorButton from './AddSponsorButton'

const styles = {
	root: {
		background: 'black'
	},
	input: {
		color: 'white',
		backgroundColor: 'rgb(36,48,65)'
	},
	gradient: {
		background:
			'linear-gradient(45deg,  #d4e1f7 0%,#d4e1f7 50%,#ffffff 50%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */',
		filter:
			"progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4e1f7', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */"
	}
}

const Sponsors = ({
	intl,
	sponsors,
	getSponsors,
	sponsorFilter,
	setSearch
}) => {


	useEffect(() => {
		if (sponsors?.length == 0) {
			getSponsors(sponsorFilter)
		}
	}, [])

	return (
		<div className="admin-fullheight-wrapper">
			<Fragment>
				<Grid container justify="space-between" alignItems="center" style={{ paddingBottom: '32px' }}>
					<Grid item>
						<Typography variant="h1" id="headerText" >
							{intl.formatMessage({ id: 'app.sponsors.pageHeader' })}
						</Typography>
					</Grid>
					<Grid item>
						<AddSponsorButton />
					</Grid>
				</Grid>
				<Grid container justify="space-between" style={{ paddingBottom: '50px' }}>
					<Grid item style={{ flex: 1 }}>
						<SearchBar onSearchChange={setSearch} />
					</Grid>
				</Grid>
				<CompositeLabelPaper
					label={intl.formatMessage({ id: 'app.sponsors.allSponsors' })}
					Component={SponsorTable}
					data={sponsors}
				/>
			</Fragment>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	sponsors: sponsorsSelector(state),
	sponsorFilter: sponsorSearchTermSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	getSponsors: (filter) => dispatch(fetchSponsors(filter)),
	setSearch: (value) => dispatch(setSponsorSearchTerm(value))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withStyles(styles))

export default enhance(Sponsors)
