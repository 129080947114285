import React, { useEffect, useState } from 'react'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { setCircleFilter } from '../../../redux/actions'
import { circleFilterSelector } from '../../../redux/selectors'

const Filter = ({
	intl,
	setFilter,
	circleFilter
}) => {
	const f = [
		{ id: 2, label: intl.formatMessage({ id: 'app.circles.activeFilter' }) },
		{ id: 0, label: intl.formatMessage({ id: 'app.circles.noFilter' }) },
		{ id: 1, label: intl.formatMessage({ id: 'app.circles.archivedFilter' }) }
	]
	const [ filter, setCurrentFilter ] = useState(0)


	useEffect(
		() => {
			setCurrentFilter(
				f.find(x =>
					x.label === circleFilter
				).id
			)
			setFilter(circleFilter)
		},
		[]
	)


	const onChangeFilter = (event, item) => {
		setCurrentFilter(event.target.value)
		setFilter(event.target.value)
	}

	return (
		<FormControl
			style={{ textAlign: 'center', width: '100%', overflow: 'hidden', minHeight: '48px' }}
		>
			<Select
				value={filter}
				onChange={onChangeFilter}
				autoWidth
				displayEmpty={true}
				style={{
					color: 'white',
					fontSize: '20px',
					lineHeight: '1',
					letterSpacing: '0.25px',
					fontWeight: '700',
					padding: '0 0 0 24px',
					minHeight: '48px',
				}}
			>
				{f.map((filter) => (
					<MenuItem value={filter.id} id={filter.id}>
						{filter.label} <KeyboardArrowDownIcon style={{ color: 'white', fontSize: '22px', margin: '0 0 -5px 5px' }} />
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}

const mapStateToProps = (state, ownProps) => ({
	circleFilter: circleFilterSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	setFilter: (id) => dispatch(setCircleFilter(id))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)
export default enhance(Filter)
