import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { useRouteMatch } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import { Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import LanguageRow from './LanguageRow'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { surveyLanguageListSelector } from '../../../redux/selectors'
import { getSurveyLanguages } from '../../../redux/actions'
import { useIntl } from 'react-intl'
import { useTheme } from '@material-ui/core'

export default () => {
	const languages = useSelector(surveyLanguageListSelector, shallowEqual)
	const match = useRouteMatch()
	const dispatch = useDispatch()
	const theme = useTheme()
	const intl = useIntl()

	const getBackgroundColor = (language) => {
		if (match.params && match.params.languageId) {
			if (language.id == match.params.languageId) {
				return theme.palette.secondary.A600
			} else {
				return '#fff'
			}
		}
	}

	const getFontColor = (language) => {
		if (match.params && match.params.languageId) {
			if (language.id == match.params.languageId) {
				return '#fff'
			} else {
				return '#000'
			}
		}
		return '#000'
	}

	const getBorderColor = (language) => {
		if (match.params && match.params.languageId) {
			if (language.id == match.params.languageId) {
				return theme.palette.primary.A800
			} else {
				return '#fff'
			}
		}
	}

	const getDetailsBackgroundColor = () => {
		if (match.path.toLowerCase().indexOf('details') > -1) {
			return theme.palette.secondary.A600
		} else {
			return '#fff'
		}
	}

	const getDetailsFontColor = () => {
		if (match.path.toLowerCase().indexOf('details') > -1) {
			return '#fff'
		} else {
			return '#000'
		}
	}

	const getDetailsBorderColor = () => {
		if (match.path.toLowerCase().indexOf('details') > -1) {
			return theme.palette.primary.A800
		} else {
			return '#fff'
		}
	}
	useEffect(() => {
		dispatch(getSurveyLanguages(match.params.surveyId))
	}, [])

	return (
		<Paper>
			<Grid container direction="column" style={{ width: '100%' }}>
				<Grid
					item
					style={{
						width: '100%',
						borderBottom: 'solid 1px rgba(0,0,0,0.5)',
						minHeight: '100px',
						padding: '0 11px 0 20px',
						backgroundColor: getDetailsBackgroundColor(),
						borderLeft: `solid 4px ${getDetailsBorderColor()}`
					}}
				>
					{languages && (
						<Link
							to={`/surveys/${match.params.surveyId}/details`}
							style={{
								textDecoration: 'none',
								fontSize: '18px'
							}}
						>
							{' '}
							<Grid container justify="space-between" alignItems="center" style={{ minHeight: '100px' }}>
								{' '}
								<Grid item>
									<Typography
										variant="subtitle1"
										style={{
											color: getDetailsFontColor(),
											textAlign: 'left',
											wordBreak: 'break-word',
											fontSize: '13px',
											lineHeight: '16px',
											letterSpacing: '2.08px',
											fontWeight: '400'
										}}
									>
										{intl.formatMessage({ id: 'app.survey.detailsRow' })}
									</Typography>
								</Grid>{' '}
							</Grid>
						</Link>
					)}
				</Grid>
				<Grid
					item
					style={{
						width: '100%',
						borderBottom: 'solid 1px rgba(0,0,0,0.5)',
						minHeight: '100px',
                        padding: '0 11px 0 20px',                        
					}}
				>
					<Grid container justify="space-between" alignItems="center" style={{ minHeight: '100px' }}>
						<Grid item>
							<Typography variant="subtitle1" data-testid="testid-language-label">								
								{intl.formatMessage({ id: 'app.survey.languageLabel' })}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Grid container direction="column">
						{languages &&
							languages.filter((d) => d.id === 1).map(
								(r, i) =>
									match && match.params.surveyId ? (
										<Link
											to={`/surveys/${match.params.surveyId}/languages/${r.id}`}
											style={{
												textDecoration: 'none',
												color: 'inherit'
											}}
										>
											<Grid
												item
												style={{
													padding: '0 11px 0 20px',
													backgroundColor: getBackgroundColor(r),
													color: getFontColor(r),
													borderLeft: `solid 4px ${getBorderColor(r)}`,
													borderBottom: 'solid 1px black'
												}}
												key={r.id}
											>
												<LanguageRow language={r} />
											</Grid>
										</Link>
									) : null
							)}
					</Grid>
				</Grid>
				<Grid item>
					<Grid container direction="column">
						{languages &&
							languages
								.filter((d) => d.id != 1)
								.sort((a, b) => {
									return a.displayName < b.displayName ? -1 : 1
								})
								.map(
									(r, i) =>
										match && match.params.surveyId ? (
											<Link
												to={`/surveys/${match.params.surveyId}/languages/${r.id}`}
												style={{
													textDecoration: 'none',
													color: 'inherit'
												}}
											>
												<Grid
													item
													style={{
														padding: '0 11px 0 20px',
														backgroundColor: getBackgroundColor(r),
														color: getFontColor(r),
														borderLeft: `solid 4px ${getBorderColor(r)}`,
														borderBottom: 'solid 1px black'
													}}
													key={r.id}
												>
													<LanguageRow language={r} />
												</Grid>
											</Link>
										) : null
								)}
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	)
}
