import React, { useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import AddTermsButton from './AddTermsButton'
import LabelPaper from '../../../components/LabelPaper'
import { withStyles, Typography } from '@material-ui/core'
import TermsViewer from './TermsViewer'
import LanguageNavigator from '../../treatment/components/LanguageNavigator'
import PatientTermsForm from './PatientTermsForm'
import { fetchPatientTerms, updateTerm } from '../../../redux/actions'
import { connect } from 'react-redux'
import { patientTermSelector } from '../../../redux/selectors'

const styles = () => ({
	button: {
		'&:hover': {
			backgroundColor: 'transparent'
		},
		paddingTop: '32px'
	},
	gradient: {
		background:
			'linear-gradient(45deg,  #d4e1f7 0%,#d4e1f7 50%,#ffffff 50%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */',
		filter:
			"progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4e1f7', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */"
	}
})

const PatientTerms = ({ getTerm, term, onSave }) => {
	const [ currentLanguage, setCurrentLanguage ] = useState(1)
	const [ currentVersion, setCurrentVersion ] = useState(null)
	const [ parent, setParent ] = useState(null)

	useEffect(
		() => {
			getTerm(currentLanguage)
		},
		[ currentLanguage ]
	)

	return (
		<Grid item>
			<Typography variant="h1"
				style={{
					marginBottom: '27px'
				}}
			>
				Patient's Terms and Conditions
			</Typography>

			<Grid container direction="row" spacing={2}>
				<Grid item style={{ flex: 0.3 }}>
					<LanguageNavigator currentLanguage={currentLanguage} onLanguageChange={setCurrentLanguage} />
				</Grid>
				<Grid item style={{ flex: 0.7, height: '100%' }}>
					<Paper style={{ padding: '3%' }}>
						{/* <TreatmentForm treatment={currentVersion} languageId={currentLanguage} parent={parent} /> */}
						<PatientTermsForm
							term={term}
							formName="patientsTerms"
							languageId={currentLanguage}
							parent={parent}
							onSaveCallback={onSave}
						/>
					</Paper>
				</Grid>
			</Grid>
		</Grid>
	)
}

const mapStateToProps = (state, ownProps) => ({
	term: patientTermSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	getTerm: (languageId) => dispatch(fetchPatientTerms(languageId, 0)),
	onSave: (body, languageId) => dispatch(updateTerm(body, languageId, 0))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withStyles(styles))

export default enhance(PatientTerms)
