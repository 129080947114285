import React from "react"
import { useIntl } from "react-intl"
import EntityAuditTableCell from "./EntityAuditTableCell"


const EntityAuditEntityTableCell = ({entityLog, ...other}) => {
    const intl = useIntl()

    const entityTypeName = (intityTypeId, entitySubTypeId) => {
        let result = ''

        if (intityTypeId < 12) {
            result = intl.formatMessage({ id: 'app.auditTable.entityType.question' })
        }
        else if (intityTypeId < 15) {
            result = intl.formatMessage({ id: 'app.auditTable.entityType.answer' })
        }
        else if (intityTypeId == 15) {
            result = intl.formatMessage({ id: 'app.auditTable.entityType.bundle' })
        }
        else if (intityTypeId == 16) {
            switch (entitySubTypeId) {
                case 1:
                    result = intl.formatMessage({ id: 'app.auditTable.entityType.survey.communication' })

                    break
                case 2:
                    result = intl.formatMessage({ id: 'app.auditTable.entityType.survey.settings' })

                    break
                case 3:
                    result = intl.formatMessage({ id: 'app.auditTable.entityType.survey.laterality' })

                    break
                default:
                    result = intl.formatMessage({ id: 'app.auditTable.entityType.survey' })

                    break
            }
        }
        else if (intityTypeId == 17) {
            switch (entitySubTypeId) {
                case 1:
                    result = intl.formatMessage({ id: 'app.auditTable.entityType.protocol.communication' })

                    break
                case 2:
                    result = intl.formatMessage({ id: 'app.auditTable.entityType.protocol.settings' })

                    break
                case 3:
                    result = intl.formatMessage({ id: 'app.auditTable.entityType.protocol.laterality' })

                    break
                default:
                    result = intl.formatMessage({ id: 'app.auditTable.entityType.protocol' })

                    break
            }
        }
        else if (intityTypeId == 18) {
            result = intl.formatMessage({ id: 'app.auditTable.entityType.benchmarkMessage' })
        }
        else if (intityTypeId == 19) {
            result = intl.formatMessage({ id: 'app.auditTable.entityType.protocolAccess' })
        }
        else if (intityTypeId == 20) {
            switch (entitySubTypeId) {
                case 2:
                    result = intl.formatMessage({ id: 'app.auditTable.entityType.scoringGroup.settings' })

                    break
                default:
                    result = intl.formatMessage({ id: 'app.auditTable.entityType.scoringGroup' })

                    break
            }
        }
        else if (intityTypeId == 21) {
            result = intl.formatMessage({ id: 'app.auditTable.entityType.surveySettings' })
        }

        return result
    }

    return (
        <EntityAuditTableCell
            {...other}
        >
            { entityTypeName(entityLog.entityTypeId, entityLog.entitySubTypeId) }
        </EntityAuditTableCell>
    )
}

export default EntityAuditEntityTableCell