import React from 'react'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Radio from '@material-ui/core/Radio'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { Field, change } from 'redux-form'
import useTheme from '@material-ui/core/styles/useTheme'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import SurveySpecial from '../../../assets/surveySpecial'
import { useDispatch } from 'react-redux'
//import { hideModal } from '../../redux/actions'

const Selector = ({ theme, input, meta, intl, value, light, currentValue, ...rest }) => {
	console.log(currentValue)
	const dispatch = useDispatch()

	const onRadioClick = (value) => {
		dispatch(change(meta.form, input.name, value))
	}

	return (
		<Grid container direction="column" justify="flex-start" style={{ minHeight: '120px' }}>
			<Grid item>
				<FormControl style={{ width: '100%', minHeight: '47px' }}>
					<RadioGroup {...input} {...rest}>
						<Grid container direction="row" alignItems="baseline" justify="flex-start" spacing={2}>
							<Grid
								item
								style={{
									color: 'white',
									flex: 0.5,
									justifySelf: 'flex-start',
									cursor: 'pointer'
								}}
								onClick={() => onRadioClick('0')}
							>
								<Paper
									elevation={0}
									style={{
										borderRadius: 0,
										padding: 0,
										margin: 0,
										height: '67px'
									}}
								>
									<Grid
										container
										alignItems="center"
										style={{
											height: '100%',
											backgroundColor: light ? theme.palette.lightCell : theme.palette.darkCell
										}}
										spacing={1}
									>
										<Grid item>
											<Radio
                                                color={ light ? "primary" : "secondary" }
												style={{ width: '100%' }}
												value="0"
											/>
										</Grid>

										<Grid item style={{ flex: 1 }}>
											<Typography
												style={{
													color: light ? '#011020' : '#fff',
													fontWeight: '700',
													fontSize: '17px',
													lineHeight: '24px',
													letterSpacing: '0.16'
												}}
											>
												{intl.formatMessage({ id: 'app.general.standard' }).toUpperCase()}
											</Typography>
										</Grid>
										<Grid item>
											<DescriptionOutlinedIcon
												style={{
													fontSize: '50px',
													color: '#6ED1B2'
												}}
											/>
										</Grid>
									</Grid>
								</Paper>
								{currentValue === '0' && (
									<Typography variant="caption" style={{ color: 'white' }}>
										{intl.formatMessage({ id: 'app.survey.standardHelperText' })}
									</Typography>
								)}
							</Grid>

							<Grid
								item
								style={{
									color: 'white',
									flex: 0.5,
									justifySelf: 'flex-start',
									cursor: 'pointer'
								}}
								onClick={() => onRadioClick('1')}
							>
								<Paper
									elevation={0}
									style={{
										borderRadius: 0,
										padding: 0,
										margin: 0,
										height: '67px'
									}}
								>
									<Grid
										container
										alignItems="center"
										style={{
											height: '100%',
											backgroundColor: light ? theme.palette.lightCell : theme.palette.darkCell
										}}
										spacing={1}
									>
										<Grid item>
											<Radio 
												color={ light ? "primary" : "secondary" }
												style={{ width: '100%' }}
												value="1"
											/>
										</Grid>

										<Grid item style={{ flex: 1 }}>
											<Typography
												style={{
													color: light ? '#011020' : '#fff',
													fontWeight: '700',
													fontSize: '17px',
													lineHeight: '24px',
													letterSpacing: '0.16'
												}}
											>
												{intl.formatMessage({ id: 'app.general.custom' }).toUpperCase()}
											</Typography>
										</Grid>
										<Grid item>
											<SurveySpecial height="42" width="46" />
										</Grid>
									</Grid>
								</Paper>
								{currentValue === '1' && (
									<Typography variant="caption" style={{ color: 'white' }}>
										{intl.formatMessage({ id: 'app.survey.customHelperText' })}
									</Typography>
								)}
							</Grid>
						</Grid>
					</RadioGroup>
				</FormControl>
			</Grid>
		</Grid>
	)
}

const StandardCustomSelector = ({ intl, backgroundColor, light, name, currentValue }) => {
	var theme = useTheme()
	return (
		<div>
			<Field
				component={Selector}
				intl={intl}
				theme={theme}
				light={light}
				name={name}
				currentValue={currentValue}
			/>
		</div>
	)
}

const enhance = compose(injectIntl)

export default enhance(StandardCustomSelector)
