import React from "react"
import { useIntl } from "react-intl"
import EntityAuditTableCell from "./EntityAuditTableCell"


const EntityAuditActionTableCell = ({entityLog, ...other}) => {
    const intl = useIntl()

    return (
        <EntityAuditTableCell
            {...other}
        >
            { intl.formatMessage({ id: `app.auditTable.action.${entityLog.entityActionTypeId}` }) }
        </EntityAuditTableCell>
    )
}

export default EntityAuditActionTableCell