import * as api from '../../../apis/imports'
import { createSelector } from 'reselect'
import { hideModal, showModal, stopSaving } from '../core'
import history from '../../../utilities/history'
import CantUpdateStatusModal from '../../../pages/imports/components/CantUpdateStatusModal'

const ADD_IMPORT_REQUESTED = 'ADD_IMPORT_REQUESTED'
const ADD_IMPORT_SUCCESS = 'ADD_IMPORT_SUCCESS'
const ADD_IMPORT_FAILED = 'ADD_IMPORT_FAILED'

export const addImport = (values) => (dispatch) => {
	dispatch({ type: ADD_IMPORT_REQUESTED, data: null })

	api.addImport(values).then((result) => {
		dispatch(stopSaving())
		if (result && result.isSuccessful) {
			dispatch(hideModal())
			dispatch({ type: ADD_IMPORT_SUCCESS, data: null })

			history.push(`/imports/${result.id}/type/${result.importType}/operation/${result.operationType}/step/0`)

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.imports.addingSuccess`, isSuccess: true }
			})
		} 
		else {
			dispatch({ type: ADD_IMPORT_FAILED, data: null })
			if(result.errorCode === 57) {
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.imports.userHasActiveImportError', isSuccess: false }
				})
			}

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.imports.addingFailed', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_IMPORTS_REQUESTED = 'FETCH_IMPORTS_REQUESTED'
const FETCH_IMPORTS_SUCCESS = 'FETCH_IMPORTS_SUCCESS'
const FETCH_IMPORTS_FAILED = 'FETCH_IMPORTS_FAILED'

export const fetchImports = (searchPhrase) => (dispatch) => {
	dispatch({ type: FETCH_IMPORTS_REQUESTED, data: {} })
	api.search(searchPhrase).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_IMPORTS_SUCCESS, data: response })
		} else {
			return dispatch({ type: FETCH_IMPORTS_FAILED, data: {} })
		}
	})
	return
}

const FETCH_ADDITIONAL_IMPORTS_REQUESTED = 'FETCH_ADDITIONAL_IMPORTS_REQUESTED'
const FETCH_ADDITIONAL_IMPORTS_SUCCESS = 'FETCH_ADDITIONAL_IMPORTS_SUCCESS'
const FETCH_ADDITIONAL_IMPORTS_FAILED = 'FETCH_ADDITIONAL_IMPORTS_FAILED'

export const searchAdditionalImports = (searchPhrase, offset) => (dispatch) => {
	dispatch({ type: FETCH_ADDITIONAL_IMPORTS_REQUESTED, data: {} })
	api.search(searchPhrase, offset).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ 
				type: FETCH_ADDITIONAL_IMPORTS_SUCCESS, 
				data: response
			})
		} else {
			return dispatch({ type: FETCH_ADDITIONAL_IMPORTS_FAILED, data: {} })
		}
	})
	return
}

const SET_IMPORT_SORT_REQUESTED = 'SET_IMPORT_SORT_REQUESTED'

export const setImportSort = (field, isDescending) => (dispatch, state) => {
	let currentState = state()

	let x = [ ...currentState.imports.imports ]

	let data = x.sort((a, b) => {
		if (isDescending) {
			if(typeof a[field] === 'string' && typeof b[field] === 'string')
				return a[field].toLowerCase() > b[field].toLowerCase() ? -1 : 1
			else
				return (a[field]===null)-(b[field]===null) || -(a[field]>b[field])||+(a[field]<b[field])
		} else {
			if(typeof a[field] === 'string' && typeof b[field] === 'string')
				return b[field].toLowerCase() > a[field].toLowerCase() ? -1 : 1
			else
				return (a[field]===null)-(b[field]===null) || +(a[field]>b[field])||-(a[field]<b[field])
		}
	})

	return dispatch({
		type: SET_IMPORT_SORT_REQUESTED,
		data: { items: data, field: field, isDescending: isDescending }
	})
}

const GET_NEW_COMPLETED_IMPORTS_REQUESTED = 'GET_NEW_COMPLETED_IMPORTS_REQUESTED'
const GET_NEW_COMPLETED_IMPORTS_SUCCESS = 'GET_NEW_COMPLETED_IMPORTS_SUCCESS'
const GET_NEW_COMPLETED_IMPORTS_FAILED = 'GET_NEW_COMPLETED_IMPORTS_FAILED'

export const getNewCompletedImports = () => (dispatch, getState) => {
	dispatch({ type: GET_NEW_COMPLETED_IMPORTS_REQUESTED, data: {} })

	api.getNewCompletedImports().then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: GET_NEW_COMPLETED_IMPORTS_SUCCESS, data: response.result })
		} else {
			return dispatch({ type: GET_NEW_COMPLETED_IMPORTS_FAILED, data: {} })
		}
	})
}

const SET_IMPORT_SEARCH_TERM_REQUESTED = 'SET_IMPORT_SEARCH_TERM_REQUESTED'
const SET_IMPORT_SEARCH_TERM_SUCCESS = 'SET_IMPORT_SEARCH_TERM_SUCCESS'

export const setImportSearchTerm = (value) => (dispatch) => {
	dispatch({ type: SET_IMPORT_SEARCH_TERM_REQUESTED, data: {} })
	return dispatch({ type: SET_IMPORT_SEARCH_TERM_SUCCESS, data: value })
}

const CLEAR_NEW_COMPLETED_IMPORTS_REQUESTED = 'CLEAR_NEW_COMPLETED_IMPORTS_REQUESTED'
const CLEAR_NEW_COMPLETED_IMPORTS_SUCCESS = 'CLEAR_NEW_COMPLETED_IMPORTS_SUCCESS'
const CLEAR_NEW_COMPLETED_IMPORTS_FAILED = 'CLEAR_NEW_COMPLETED_IMPORTS_FAILED'

export const clearNewCompletedImports = () => (dispatch) => {
	dispatch({ type: CLEAR_NEW_COMPLETED_IMPORTS_REQUESTED, data: {} })

	api.clearNewCompletedImports().then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: CLEAR_NEW_COMPLETED_IMPORTS_SUCCESS, data: response.result })
		} else {
			return dispatch({ type: CLEAR_NEW_COMPLETED_IMPORTS_FAILED, data: {} })
		}
	})
}

const GET_BUCKET_URL_REQUESTED = 'GET_BUCKET_URL_REQUESTED'
const GET_BUCKET_URL_SUCCESS = 'GET_BUCKET_URL_SUCCESS'
const GET_BUCKET_URL_FAILED = 'GET_BUCKET_URL_FAILED'

export const getBucketUrl = () => (dispatch) => {
	dispatch({ type: GET_BUCKET_URL_REQUESTED, data: {} })

	api.fetchBucketUrl().then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: GET_BUCKET_URL_SUCCESS, data: response.data })
		} else {
			return dispatch({ type: GET_BUCKET_URL_FAILED, data: {} })
		}
	})
}

const GET_PREVIEW_DATA_REQUESTED = 'GET_PREVIEW_DATA_REQUESTED'
const GET_PREVIEW_DATA_SUCCESS = 'GET_PREVIEW_DATA_SUCCESS'
const GET_PREVIEW_DATA_FAILED = 'GET_BUCKET_URL_FAILED'

export const getPreviewData = (file) => (dispatch) => {
	dispatch({ type: GET_PREVIEW_DATA_REQUESTED, data: {} })

	api.fetchPreviewData(file).then((response) => {
		if (response && response.isSuccessful) {
			let data = {
				fields: response.fields,
				rows: response.rows
			}

			return dispatch({ type: GET_PREVIEW_DATA_SUCCESS, data: data })
		} else {
			if (response.errorCode == 59) {
				dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: `${response.errorMessage}`, isSuccess: false }
				})
			}
			else {
				dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: `app.imports.validationErrors.${response.errorCode}`, isSuccess: false }
				})
			}

			return dispatch({ type: GET_PREVIEW_DATA_FAILED, data: {} })
		}
	})
}

const RESET_PREVIEW_DATA = 'RESET_PREVIEW_DATA'

export const resetPreviewData = () => (dispatch) => {
	return dispatch({ type: RESET_PREVIEW_DATA, data: {} })
}

const ADD_DATA_REQUESTED = 'ADD_DATA_REQUESTED'
const ADD_DATA_SUCCESS = 'ADD_DATA_SUCCESS'
const ADD_DATA_FAILED = 'ADD_DATA_FAILED'

export const addData = (importId, file) => (dispatch) => {
	dispatch({ type: ADD_DATA_REQUESTED, data: {} })

	api.fetchAddData(importId, file).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.imports.rawDataSaved`, isSuccess: true }
			})

			dispatch(getRedirect(importId))

			return dispatch({ type: ADD_DATA_SUCCESS, data: {} })
		} else {
			if (response.errorCode == 59) {
				dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: `${response.errorMessage}`, isSuccess: false }
				})
			}
			else {
				dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: `app.imports.validationErrors.${response.errorCode}`, isSuccess: false }
				})
			}

			return dispatch({ type: GET_PREVIEW_DATA_FAILED, data: {} })
		}
	})
}


const FETCH_IMPORT_MAPPING_FILE_HEADERS_REQUESTED = 'FETCH_IMPORT_MAPPING_FILE_HEADERS_REQUESTED'
const FETCH_IMPORT_MAPPING_FILE_HEADERS_SUCCESS = 'FETCH_IMPORT_MAPPING_FILE_HEADERS_SUCCESS'
const FETCH_IMPORT_MAPPING_FILE_HEADERS_FAILED = 'FETCH_IMPORT_MAPPING_FILE_HEADERS_FAILED'

export const fetchImportFileHeaders = (importId) => (dispatch) => {
	dispatch({ type: FETCH_IMPORT_MAPPING_FILE_HEADERS_REQUESTED, data: {} })
	api.fetchImportFileHeaders(importId).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_IMPORT_MAPPING_FILE_HEADERS_SUCCESS, data: response.headers })
		} else {
			return dispatch({ type: FETCH_IMPORT_MAPPING_FILE_HEADERS_FAILED, data: {} })
		}
	})
	return
}

const FETCH_IMPORT_DETAILS_REQUESTED = 'FETCH_IMPORT_DETAILS_REQUESTED'
const FETCH_IMPORT_DETAILS_SUCCESS = 'FETCH_IMPORT_DETAILS_SUCCESS'
const FETCH_IMPORT_DETAILS_FAILED = 'FETCH_IMPORT_DETAILS_FAILED'

export const fetchImportDetails = (importId) => (dispatch) => {
	dispatch({ type: FETCH_IMPORT_DETAILS_REQUESTED, data: {} })
	api.fetchImportDetails(importId).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_IMPORT_DETAILS_SUCCESS, data: response })
		} else {
			return dispatch({ type: FETCH_IMPORT_DETAILS_FAILED, data: {} })
		}
	})
	return
}

const ADD_IMPORT_MAPPING_REQUESTED = 'ADD_IMPORT_MAPPING_REQUESTED'
const ADD_IMPORT_MAPPING_SUCCESS = 'ADD_IMPORT_MAPPING_SUCCESS'
const ADD_IMPORT_MAPPING_FAILED = 'ADD_IMPORT_MAPPING_FAILED'

export const addImportMapping = (id, values) => (dispatch) => {
	dispatch({ type: ADD_IMPORT_MAPPING_REQUESTED, data: null })
	api.addImportMapping(id, values).then((result) => {
		dispatch(stopSaving())
		if (result && result.isSuccessful) {
			dispatch({ type: ADD_IMPORT_MAPPING_SUCCESS, data: null })

			dispatch(getRedirect(id))

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.imports.mappingAddingSuccess`, isSuccess: true }
			})					
		} 
		else {
			dispatch({ type: ADD_IMPORT_MAPPING_FAILED, data: null })

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.imports.mappingAddingFailed', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_LAST_PATIENT_MAPPING_REQUESTED = 'FETCH_LAST_PATIENT_MAPPING_REQUESTED'
const FETCH_LAST_PATIENT_MAPPING_SUCCESS = 'FETCH_LAST_PATIENT_MAPPING_SUCCESS'
const FETCH_LAST_PATIENT_MAPPING_FAILED = 'FETCH_LAST_PATIENT_MAPPING_FAILED'

export const fetchLastPatientMapping = (importId) => (dispatch) => {
	dispatch({ type: FETCH_LAST_PATIENT_MAPPING_REQUESTED, data: {} })
	api.fetchLastPatientMapping(importId).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_LAST_PATIENT_MAPPING_SUCCESS, data: response })
		} else {
			return dispatch({ type: FETCH_LAST_PATIENT_MAPPING_FAILED, data: {} })
		}
	})
	return
}

const FETCH_LAST_CASE_MAPPING_REQUESTED = 'FETCH_LAST_CASE_MAPPING_REQUESTED'
const FETCH_LAST_CASE_MAPPING_SUCCESS = 'FETCH_LAST_CASE_MAPPING_SUCCESS'
const FETCH_LAST_CASE_MAPPING_FAILED = 'FETCH_LAST_CASE_MAPPING_FAILED'

export const fetchLastCaseMapping = (importId) => (dispatch) => {
	dispatch({ type: FETCH_LAST_CASE_MAPPING_REQUESTED, data: {} })
	api.fetchLastCaseMapping(importId).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_LAST_CASE_MAPPING_SUCCESS, data: response })
		} else {
			return dispatch({ type: FETCH_LAST_CASE_MAPPING_FAILED, data: {} })
		}
	})
	return
}

const GET_LAST_SURVEY_MAPPING_REQUESTED = 'GET_LAST_SURVEY_MAPPING_REQUESTED'
const GET_LAST_SURVEY_MAPPING_SUCCESS = 'GET_LAST_SURVEY_MAPPING_SUCCESS'
const GET_LAST_SURVEY_MAPPING_FAILED = 'GET_LAST_SURVEY_MAPPING_FAILED'

export const getLastSurveyMapping = (importId) => (dispatch) => {
	dispatch({ type: GET_LAST_SURVEY_MAPPING_REQUESTED, data: {} })
	api.fetchLastSurveyMapping(importId).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: GET_LAST_SURVEY_MAPPING_SUCCESS, data: response.data })
		} else {
			return dispatch({ type: GET_LAST_SURVEY_MAPPING_FAILED, data: {} })
		}
	})
	return
}

export const showImportEmptyValuesError = () => (dispatch) => {
	return dispatch({
		type: 'SET_SNACKBAR_MESSAGE',
		data: { message: 'app.imports.requiredFieldHasEmptyValuesError', isSuccess: false }
	})
}

const GET_REDIRECT_REQUESTED = 'ADD_DATA_REQUESTED'
const GET_REDIRECT_SUCCESS = 'ADD_DATA_SUCCESS'
const GET_REDIRECT_FAILED = 'ADD_DATA_FAILED'

export const getRedirect = (importId) => (dispatch) => {
	dispatch({ type: GET_REDIRECT_REQUESTED, data: {} })

	api.fetchRedirect(importId).then((response) => {
		if (response && response.isSuccessful) {
			let location = history.location
			if (location.pathname != response.redirectTo) {
				history.push(response.redirectTo)
			}

			return dispatch({ type: GET_REDIRECT_SUCCESS, data: {} })
		} else {
			return dispatch({ type: GET_REDIRECT_FAILED, data: {} })
		}
	})
}

const CONFIRM_IMPORT_REQUESTED = 'CONFIRM_IMPORT_REQUESTED'
const CONFIRM_IMPORT_SUCCESS = 'CONFIRM_IMPORT_SUCCESS'
const CONFIRM_IMPORT_FAILED = 'CONFIRM_IMPORT_FAILED'

export const confirmImport = (id) => (dispatch) => {
	dispatch({ type: CONFIRM_IMPORT_REQUESTED, data: null })
	api.confirmImport(id).then((result) => {
		dispatch(stopSaving())
		if (result && result.isSuccessful) {
			dispatch({ type: CONFIRM_IMPORT_SUCCESS, data: null })
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: `app.imports.confirmation.success`, isSuccess: true }
			})

			history.push(`/imports`)
			return
		} 
		else {
			dispatch({ type: CONFIRM_IMPORT_FAILED, data: null })

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.imports.confirmation.error', isSuccess: false }
			})
		}
	})
	return
}

const FETCH_IMPORT_LOGS_REQUESTED = 'FETCH_IMPORT_LOGS_REQUESTED'
const FETCH_IMPORT_LOGS_SUCCESS = 'FETCH_IMPORT_LOGS_SUCCESS'
const FETCH_IMPORT_LOGS_FAILED = 'FETCH_IMPORT_LOGS_FAILED'

export const fetchImportLogs = (importId) => (dispatch) => {
	dispatch({ type: FETCH_IMPORT_LOGS_REQUESTED, data: {} })
	api.fetchImportLogs(importId).then((response) => {
		if (response) {
			dispatch({ type: FETCH_IMPORT_LOGS_SUCCESS })

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.exportSuccess', isSuccess: true }
			})
		} else {
			dispatch({ type: FETCH_IMPORT_LOGS_FAILED })

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.exportFailed', isSuccess: false }
			})
		}
	})
	return
}

const CANCEL_IMPORT_REQUESTED = 'CANCEL_IMPORT_REQUESTED'
const CANCEL_IMPORT_SUCCESS = 'CANCEL_IMPORT_SUCCESS'
const CANCEL_IMPORT_FAILED = 'CANCEL_IMPORT_FAILED'

export const cancelImport = (importId) => (dispatch) => {
	dispatch({ type: CANCEL_IMPORT_REQUESTED, data: {} })
	api.cancelImport(importId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({ type: CANCEL_IMPORT_SUCCESS, data: response })
			dispatch(fetchImports())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.imports.cancelSuccessMessage', isSuccess: true }
			})
		} else {
			dispatch({ type: CANCEL_IMPORT_FAILED, data: {} })

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.imports.cancelErrorMessage', isSuccess: false }
			})
		}
	})
	return
}

const GET_CAN_UPDATE_STATUS_REQUESTED = 'GET_CAN_UPDATE_STATUS_REQUESTED'

export const getCanUpdateStatus = (importId) => (dispatch) => {
	dispatch({ type: GET_CAN_UPDATE_STATUS_REQUESTED, data: {} })

	api.fetchCanUpdateStatus(importId).then((response) => {
		if (response && response.isSuccessful) {
			return
		} else if (!!response) {
			dispatch(
				showModal(
					CantUpdateStatusModal,
					{
						errorCode: response.errorCode,
						redirectUrl: response.redirectTo
					},
					false
				)
			)

			return
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.imports.canUpdateStatusFetchError', isSuccess: false }
			})
		}
	})
}

const GET_IMPORT_STATISITC_REQUESTED = 'GET_IMPORT_STATISITC_REQUESTED'
const GET_IMPORT_STATISITC_FAILED = 'GET_IMPORT_STATISITC_FAILED'

export const getImportStatistic = (importId) => (dispatch) => {
	dispatch({ type: GET_IMPORT_STATISITC_REQUESTED, data: {} })

	return api.fetchImportStatistic(importId)
		.then(response => {
			if (!!response?.isSuccessful) {
				let statistic = getStatisticArray(response)

				return statistic
			}
			else {
				dispatch({ type: GET_IMPORT_STATISITC_FAILED, data: {} })

				return []
			}
		})
}

const getStatisticArray = (statistic) => {
	let fields = {}

	const addField = (fieldName) => {
		fields[fieldName] = {
			order: Object.keys(fields).length
		}
	}

	addField("totalRows")
	addField("createdPatients")
	addField("failedCreatePatients")
	addField("updatedPatients")
	addField("failedUpdatePatients")
	addField("createdCases")
	addField("failedCreateCases")
	addField("updatedCases")
	addField("failedUpdateCases")
	addField("archivedCases")
	addField("completedSurveys")
	addField("failedCompleteSurveys")
	addField("updatedSurveys")
	addField("failedUpdateSurveys")
	addField("processedScoringGroups")
	addField("failedScoringGroups")
	addField("parsedCells")
	addField("failedCells")
	addField("emptyCells")
	addField("enabledNotificationPatients")
	addField("disabledNotificationPatients")

	let result = Object.keys(statistic)
		.filter(key => !!fields[key])
		.map(key => ({
			field: key,
			value: statistic[key],
			order: fields[key].order
		}))
		.sort((a, b) => a.order < b.order ? -1 : a.order > b.order ? 1 : 0 )

	return result
}


const initial = {
	imports: [],
	sortField: 'name',
	isDescending: false,
	searchTerm: '',
	hasMore: true,
	isLoading: false,
	newCompletedImports: false,
	downloadingImports: [],
	bucketUrl: null,
	previewData: null,
	fileHeaders: [],
	importDetails: null,
	lastMappingLoaded: false,
	lastPatientMapping: null,
	lastCaseMapping: null,
	lastSurveyMapping: null
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_IMPORTS_REQUESTED:
			return { ...state, imports: [] }
		case FETCH_IMPORTS_SUCCESS:
			return { 
				...state, 
				imports: action.data.imports,
				hasMore: action.data.hasMore,
				sortField: 'name',
				isDescending: false
			 }
		case FETCH_ADDITIONAL_IMPORTS_SUCCESS:
			return {
				...state,
				imports: [ ...state.imports, ...action.data.imports],
				hasMore: action.data.hasMore,
				isLoading: false
			}
		case FETCH_ADDITIONAL_IMPORTS_FAILED:
			return {
				...state,
				hasMore: false,
				isLoading: false
			}
		case FETCH_ADDITIONAL_IMPORTS_REQUESTED:
			return {
				...state,
				isLoading: true
			}
		case SET_IMPORT_SORT_REQUESTED:
			return {
				...state,
				imports: action.data.items,
				sortField: action.data.field,
				isDescending: action.data.isDescending
			}
		case SET_IMPORT_SEARCH_TERM_SUCCESS:
			return { ...state, searchTerm: action.data }
		case GET_NEW_COMPLETED_IMPORTS_SUCCESS:
			return { ...state, newCompletedImports: action.data }
		case CLEAR_NEW_COMPLETED_IMPORTS_REQUESTED:
			return { ...state, newCompletedImports: false }

		case GET_BUCKET_URL_SUCCESS:
			return {
				...state,
				bucketUrl: action.data
			}

		case GET_PREVIEW_DATA_REQUESTED:
			return {
				...state,
				previewData: null
			}
		case GET_PREVIEW_DATA_SUCCESS:
			return {
				...state,
				previewData: action.data
			}

		case RESET_PREVIEW_DATA:
			return {
				...state,
				previewData: null
			}

		case FETCH_IMPORT_MAPPING_FILE_HEADERS_SUCCESS:
			return { ...state, fileHeaders: action.data }
		case FETCH_IMPORT_DETAILS_SUCCESS:
			return { ...state, importDetails: action.data }

		case FETCH_LAST_PATIENT_MAPPING_REQUESTED:
			return {
				...state,
				lastMappingLoaded: false,
				lastPatientMapping: null
			}
		case FETCH_LAST_PATIENT_MAPPING_SUCCESS:
			return {
				...state,
				lastMappingLoaded: true,
				lastPatientMapping: action.data
			}
		case FETCH_LAST_PATIENT_MAPPING_FAILED:
			return {
				...state,
				lastMappingLoaded: true
			}

		case FETCH_LAST_CASE_MAPPING_REQUESTED:
			return {
				...state,
				lastMappingLoaded: false,
				lastCaseMapping: null
			}
		case FETCH_LAST_CASE_MAPPING_SUCCESS:
			return {
				...state,
				lastMappingLoaded: true,
				lastCaseMapping: action.data
			}
		case FETCH_LAST_CASE_MAPPING_FAILED:
			return {
				...state,
				lastMappingLoaded: true
			}

		case GET_LAST_SURVEY_MAPPING_REQUESTED:
			return {
				...state,
				lastMappingLoaded: false,
				lastSurveyMapping: null
			}
		case GET_LAST_SURVEY_MAPPING_SUCCESS:
			return {
				...state,
				lastMappingLoaded: true,
				lastSurveyMapping: action.data
			}
		case GET_LAST_SURVEY_MAPPING_FAILED:
			return {
				...state,
				lastMappingLoaded: true
			}

		case 'SIGN_OUT_REQUESTED':
			return initial
		default:
			return { ...state }
	}
}


const mainSelector = (state) => state.imports

export const importsSelector = createSelector(mainSelector, (state) => {
	return state.imports
})

export const hasImportsSelector = createSelector(mainSelector, (state) => {
	return state.imports && state.imports.length > 0
})

export const sortImportsDirectionSelector = createSelector(mainSelector, (state) => {
	return state.isDescending
})

export const sortImportsFieldSelector = createSelector(mainSelector, (state) => {
	return state.sortField
})

export const importSearchTermSelector = createSelector(mainSelector, (state) => {
	return state.searchTerm
})

export const hasMoreImportsSelector = createSelector(mainSelector, (state) => {
	return state.hasMore
})

export const isLoadingImportsSelector = createSelector(mainSelector, (state) => {
	return state.isLoading
})

export const newCompletedImportsSelector = createSelector(mainSelector, (state) => {
	return state.newCompletedImports
})

export const bucketUrlSelector = createSelector(
	mainSelector,
	(state) => {
		return state.bucketUrl
	}
)

export const previewDataSelector = createSelector(
	mainSelector,
	(state) => {
		return state.previewData
	}
)

export const importFileHeadersSelector = createSelector(mainSelector, (state) => {
	return state.fileHeaders
})

export const importDetailsSelector = createSelector(mainSelector, (state) => {
	return state.importDetails
})

export const lastMappingLoadedSelector = createSelector(mainSelector, (state) => {
	return state.lastMappingLoaded
})

export const importLastPatientMappingSelector = createSelector(mainSelector, (state) => {
	return state.lastPatientMapping
})

export const importLastCaseMappingSelector = createSelector(mainSelector, (state) => {
	return state.lastCaseMapping
})

export const importLastSurveyMappingSelector = createSelector(mainSelector, (state) => {
	return state.lastSurveyMapping
})
