import React, { useEffect, useState } from 'react'
import { useTheme, createMuiTheme } from '@material-ui/core/styles'
import { compose } from 'recompose'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { useLocation } from 'react-router-dom'
import { newUserChangePassword } from '../../redux/actions'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import FormTextField from '../../components/FormTextField'
import { reduxForm, change } from 'redux-form'
import { required, passwordStrength } from '../../utilities/validators'
import { passwordMatch } from '../../utilities/validators'
import * as S from '../../utilities/StringConstants'
import '../../styles/index.scss'
import { injectIntl, intlShape } from 'react-intl'
import queryString from 'query-string'
import MobileDetect from 'mobile-detect'

const styles = {
	root: {},
	input: {
		color: 'white',
		fontSize: '1.7rem'
	}
}

const requiredValidator = required
const passwordStrengthValidator = passwordStrength
const passwordMatchValidator = passwordMatch('password')

const ConfirmForgotPassword = ({ classes, submitRequest, handleSubmit, intl, changeEmail }) => {
	const location = useLocation()
    const theme = useTheme()
    
	const [ isMobile ] = useState(new MobileDetect(window.navigator.userAgent))
	useEffect(() => {
		let params = queryString.parse(location.search)

		if (params.email) {
			changeEmail(params.email)
		}
	}, [])

	const onSubmitForm = (values) => {
		submitRequest(values.email, values.oldPassword, values.newPassword)
	}
	return (
		<form
			onSubmit={handleSubmit(onSubmitForm)}
			style={{ minWidth: isMobile.mobile() ? '200px' : '65rem' }}
		>
			<Grid
				container
				direction="column"
				alignItems="center"
				justify="center"
				style={{
					minWidth: isMobile.mobile() ? '200px' : '65rem'
				}}
			>
				<Grid item>
					<Grid
						container
						direction="column"
						justify="center"
						style={{
							backgroundColor: theme.palette.secondary.main,
							padding: '10%'
						}}
					>
						<Grid item>
							<Typography
								variant="h1"
								style={{
									color: '#fff',
									textAlign: 'center',
								}}
							>
								{intl.formatMessage({ id: 'app.confirmPasswordChange.title' })}
							</Typography>
						</Grid>

						<Grid
							item
							style={{
								marginBottom: '1rem'
							}}
						>
							<FormTextField
								label={intl.formatMessage({ id: 'app.confirmPasswordChange.email' })}
								fullWidth
								type="text"
								name="email"
								margin="normal"
								variant="filled"
								inputLabelProps={{
									className: classes.input
								}}
								inputProps={{
									className: classes.input
								}}
								validators={[ requiredValidator ]}
							/>
						</Grid>
						<Grid
							item
							style={{
								marginBottom: '1rem'
							}}
						>
							<FormTextField
								label={intl.formatMessage({ id: 'app.changePassword.oldPasswordLabel' })}
								fullWidth
								type="password"
								name="oldPassword"
								margin="normal"
								variant="filled"
								inputLabelProps={{
									className: classes.input
								}}
								inputProps={{
									className: classes.input
								}}
								validators={[ requiredValidator ]}
							/>
						</Grid>
						<Grid
							item
							style={{
								marginBottom: '1rem'
							}}
						>
							<FormTextField
								label={intl.formatMessage({ id: 'app.changePassword.newPasswordLabel' })}
								fullWidth
								type="password"
								name="newPassword"
								margin="normal"
								variant="filled"
								inputLabelProps={{
									className: classes.input
								}}
								inputProps={{
									className: classes.input
								}}
								validators={[ requiredValidator ]}
							/>
						</Grid>

						<Grid
							item
							style={{
								maxwidth: '50%',
								alignSelf: 'center'
							}}
						>
							<Button
                                variant="contained"
                                color="primary"
								type="submit"
								size="large"
							>
								{intl.formatMessage({ id: 'app.changePassword.buttonLabel' })}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</form>
	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
	changeEmail: (email) => dispatch(change('changePassword', 'email', email)),
	submitRequest: (email, oldPassword, newPassword) => dispatch(newUserChangePassword(email, oldPassword, newPassword))
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'changePassword'
	}),
	withStyles(styles)
)

export default enhance(ConfirmForgotPassword)
