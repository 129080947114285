import React, { useEffect, useState } from 'react'
import { reduxForm, change } from 'redux-form'
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { inviteCircleMember } from '../../redux/modules/circle'
import InvitationForm from '../../components/InvitationForm'
import { fetchCircle } from '../../redux/actions'
import { circleSelector } from '../../redux/selectors'

const formName = 'inviteCircleMember'

const InviteCircleMember = ({ circleId, handleSubmit, submitCircleInvitationRequest, fetchCircle, circle, changeLanguage }) => {
	useEffect(() => {
        fetchCircle(circleId)
	}, [])

    useEffect(() => {
    	if(circle)
		    changeLanguage(circle.administratorLanguageId)
        },
		circle && circle.administratorLanguageId)

	const onSubmitInvitationForm = (values) => {
		submitCircleInvitationRequest(values.email, circleId, values.languageId, values.sponsored, values.caseFunding, values.caseSponsorId)
	}

	return (
		<div>
			<InvitationForm 
			    handleSubmit={handleSubmit} 
				onSubmitForm={onSubmitInvitationForm}
				availableCredits={circle ? circle.availableSubscriptions : 0}
			    circleId={circleId}
			 />
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
    circle: circleSelector(state),
    circleId: state.core.params.circleId,
	initialValues: {caseSponsorId: circleSelector(state)?.defaultCaseFunding == 0 ? circleSelector(state)?.sponsorId : null}
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	submitCircleInvitationRequest: (email, circleId, languageId, sponsored, caseFunding, caseSponsorId) => {
		dispatch(inviteCircleMember(email, circleId, languageId, sponsored, caseFunding, caseSponsorId))
	},
	fetchCircle: (circleId) => dispatch(fetchCircle(circleId)),
    changeLanguage: (languageId) => dispatch(change(formName, 'languageId', languageId))
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: formName,
		enableReinitialize: true
	}),
	injectIntl
)

export default enhance(InviteCircleMember)
