import React from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import { Field } from 'redux-form'

const useStyles = makeStyles({
	inputRoot: {
		color: 'black'
	},
	inputFocused: {
		color: 'black'
	},
	inputLabel: {
		marginLeft: '0px',
		color: 'black'
	},
	selectMenu: {
		color: 'black',
		height: '20px'
	},
	selectIcon: {
		fontSize: '24px'
	},
	error: {
		backgroundColor: 'transparent'
	}
})

const renderTextField = ({ label, maxLength, placeholder, classes, input, meta: { touched, invalid, error }, ...other }) => {
	return (
		<TextField
			label={label}
			variant="filled"
			fullWidth
			shrink
			error={touched && invalid}
			helperText={touched && error}
			placeholder={placeholder}
			{...input}
			InputLabelProps={{
				classes: {
					root: classes.inputLabel,
					focused: classes.inputLabel
				}
			}}
			InputProps={{
				classes: {
					root: classes.inputRoot,
					focused: classes.focused,
					input: classes.focused
				}
			}}
			inputProps={{
				style: { color: 'black' },
				maxLength: maxLength
			}}
			FormHelperTextProps={{
				classes: {
					error: classes.error
				}
			}}
			{...other}
		/>
	)
}

const FormTextFieldLight = ({ label, name, onChange, data, validators, ...other }) => {
	var classes = useStyles()
	return (
		<Field
			component={renderTextField}
			name={name}
			classes={classes}
			onChange={onChange}
			validate={validators}
			label={label}
			{...other}
		/>
	)
}

export default FormTextFieldLight
