import React, { Fragment, useState } from 'react'
import { injectIntl, FormattedDate } from 'react-intl'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import NameCell from './NameCell'
import ModifiedCell from './ModifiedCell'
//import ProtocolType from './ProtocolType'
import Menu from './Menu'
import { questionId } from '../../../utilities/utilities'
import IdContainer from '../../../components/survey/components/IdContainer'

const getType = (id) => {
	switch (id) {
		case 1:
			return 'Single'
		case 2:
			return 'Multi'
		case 3:
			return 'Grouped'
		// case 4:
		// 	return 'True/False'
		case 5:
			return 'Text'
		case 6:
			return 'Number'
		case 7:
			return 'Date'
		case 9:
			return 'Analog'
		case 10: case 11:
			return 'File'
	}
}

const QuestionRow = ({ question, user }) => {
	const [minHeight, setMinHeight] = useState(64)

	return (
		<TableRow
			key={question.id}
			data-testid="question-table-row"
			
			style={{
				// backgroundColor: '#fff'
			}}
		>
			<TableCell align="left" size="small" style={{ padding: 0 }}>
				<div
					style={{
						minHeight: `${minHeight - 16}px`,
						borderLeft: `solid 4px ${'#007aff'}`
					}}
				>
					&nbsp;
				</div>
			</TableCell>
			<TableCell align="left" size="small" style={{ padding: 0 }}>
				{/* <ProtocolType protocol={protocol} /> */}
			</TableCell>
			<TableCell align="left" size="small">
				<NameCell question={question} setHeightCallback={setMinHeight} />
			</TableCell>
			<TableCell>
				<IdContainer
					label={questionId(question.id, question.questionTypeId)}
					clipboardText={questionId(question.id, question.questionTypeId)}
				/>
			</TableCell>
			<TableCell align="left" size="small">
				<Typography variant="body2" style={{ textAlign: 'left', fontWeight: '400' }}>
					{getType(question.questionTypeId)}
				</Typography>
			</TableCell>
			<TableCell align="left" size="small">
				<Typography variant="body2" style={{ textAlign: 'left', fontWeight: '400' }}>
					{question.surveyCount}
				</Typography>
			</TableCell>
			<TableCell align="left" size="small">
				<Typography variant="body2" style={{ textAlign: 'left', fontWeight: '400' }}>
					{question.translatePercent}%
				</Typography>
			</TableCell>
			<TableCell align="left" size="small" style={{ maxWidth: '120px', width: '120px' }}>
				<ModifiedCell question={question} />
			</TableCell>

			<TableCell align="right" size="small" style={{ padding: '5px 0', width: '71px', textAlign: 'center' }}>
				{/* <Menu protocol={protocol} /> */}
			</TableCell>
		</TableRow>
	)
}

export default injectIntl(QuestionRow)
