import React from "react"
import { Fragment } from "react"
import { Grid, Tab, Tabs } from "@material-ui/core"
import { injectIntl } from "react-intl"
import { connect, useDispatch } from "react-redux"
import { compose } from "recompose"
import { useEffect } from "react"
import { loadQuestion } from "../../../redux/actions"
import { useRouteMatch } from "react-router"
import { questionBuilderListSelector, questionBuilderQuestionsSelector } from "../../../redux/selectors"
import QuestionContent from "./QuestionContent"
import { useState } from "react"
import QuestionAudit from "./QuestionAudit"
import IdContainer from "../../../components/survey/components/IdContainer"
import { questionId } from "../../../utilities/utilities"


const QuestionPage = ({
	questionList,
	questionCollection,
	intl
}) => {
	const match = useRouteMatch()
	const dispatch = useDispatch()

	const [ tabIndex, setTabIndex ] = useState(1)


	useEffect(
		() => {
			const questionId = match?.params?.questionId
			const questionType = match?.params?.questionType
			const languageId = match?.params?.languageId

			if (
				!!questionId
				&& !!questionType
				&& !!languageId
			) {
				dispatch(
					loadQuestion(questionId, questionType, languageId)
				)
			}
		},
		[match?.params?.questionId, match?.params?.questionType, match?.params.languageId]
	)


	const handleChangeTabIndex = (_, value) => {
		setTabIndex(value)
	}


	return (
        <div
			style={{ padding: '3%' }}
		>
			<Grid container direction="column">
				<Grid item style={{ paddingBottom: '16px', width: '100%', cursor: false ? 'pointer' : '' }}>
					<Grid container justify="space-between" alignItems="center" style={{ padding: '0' }}>
						<Grid item xs={12} style={{ position: 'relative' }}>
							<div style={{ position: 'absolute', top: '8px', right: '0', zIndex: '1' }}>
								<IdContainer
									size="medium"
									label={"ID: " + questionId(match?.params?.questionId, match?.params?.questionType)}
									clipboardText={questionId(match?.params?.questionId, match?.params?.questionType)}
								/>
							</div>
							<Tabs
								value={tabIndex}
								onChange={handleChangeTabIndex}
								style={{
									borderBottom: '1px solid #505050'
								}}
							>
								<Tab label={ intl.formatMessage({ id: 'app.question.contentButtonText' }) } value={1} style={{fontSize: "20px"}} />
								<Tab label={ intl.formatMessage({ id: 'app.question.auditButtonText' }) } value={2} style={{fontSize: "20px"}} />
							</Tabs>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					{
						tabIndex == 1
						&& <QuestionContent questionList={questionList} questionCollection={questionCollection} />
					}
					{
						tabIndex == 2
						&& <QuestionAudit questionId={match?.params?.questionId} questionType={match?.params?.questionType} />
					}
				</Grid>
			</Grid>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
	questionList: questionBuilderListSelector(state),
	questionCollection: questionBuilderQuestionsSelector(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
})

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)

export default enhance(QuestionPage)
