import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Grid, Paper } from "@material-ui/core";
import LanguageNavigator from "./LanguageNavigator";
import GroupForm from "./GroupForm";
import DetailsNavigator from "./DetailsNavigator";
import RelatedEntities from "./RelatedEntities";


const ScoringGroupDetails = ({
    groupId
}) => {
    const [ selectedOptionId, setSelectedOptionId ] = useState(null)
    const [ currentLanguage, setCurrentLanguage ] = useState(1)


    const onSelectedOptionChange = (optionId) => {
        setSelectedOptionId(optionId)
        setCurrentLanguage(null)
    }

    const onLanguageChange = (language) => {
        setSelectedOptionId(null)
        setCurrentLanguage(language)
    }


    return (
        <Grid container direction="row" spacing={2}>
            <Grid item style={{ flex: 0.3, paddingTop: '3%' }}>
                <Grid container direction="column">
                    <Grid item>
                        <DetailsNavigator
                            selectedOptionId={selectedOptionId}
                            onSelectCallback={onSelectedOptionChange}
                        />
                    </Grid>
                    <Grid item>
                        <LanguageNavigator
                            currentLanguage={currentLanguage}
                            onLanguageChange={onLanguageChange}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item style={{ flex: 0.7, paddingTop: '3%' }}>
                <Paper style={{ padding: '3%' }}>
                    {
                        !!selectedOptionId
                        && <RelatedEntities groupId={groupId} />
                    }
                    {
                        !!currentLanguage
                        && <GroupForm groupId={groupId} languageId={currentLanguage} />
                    }
                </Paper>
            </Grid>
        </Grid>
    )
}

export default ScoringGroupDetails;
