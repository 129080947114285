import React, { Fragment } from 'react'
import { compose } from 'recompose'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { useTheme } from '@material-ui/core/styles'
import { getSubscriberStatisticsExport } from '../../../redux/actions'

const styles = {
	root: {
		background: 'black'
	},
	input: {
		color: 'white',
		backgroundColor: 'rgb(36, 48, 65)'
	}
}

const GetStatisticsButton = ({ getStatistics, intl, userId }) => {
	const theme = useTheme()

	return (
		<Fragment>
				<Button
                    variant="contained"
                    color="primary"
					style={{
						whiteSpace: 'nowrap',
						minWidth: '200px',
						margin: '15px'
					}}
					onClick={() => getStatistics(userId)}
				>
					{intl.formatMessage({ id: 'app.general.getStatisticsMenuItemText' })}
				</Button>
		</Fragment>
	)
}

const mapStateToProps = () => ({})
const mapDispatchToProps = (dispatch) => ({
	getStatistics: (userId) =>
		dispatch(getSubscriberStatisticsExport(userId))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withRouter, injectIntl, withStyles(styles))

export default enhance(GetStatisticsButton)
