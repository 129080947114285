import React, { useEffect } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { enableUser } from '../../../redux/actions'

const EnableAdministratorMenuItem = ({ intl, onClickHandler, onComplete, administrator, disable }) => {
	useEffect(() => {}, [])

	const onClick = () => {
		disable(administrator.id)
		onComplete()
	}

	return (
		<MenuItem
			onClick={() => {
				onClick()
			}}
		>
			Enable
		</MenuItem>
	)
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
	disable: (id) => dispatch(enableUser(id))
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(EnableAdministratorMenuItem)
