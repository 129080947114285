import React, { useState, useEffect, Fragment } from 'react'
import IconButton from '@material-ui/core/IconButton'
import MuiMenu from '@material-ui/core/Menu'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import MenuItem from '@material-ui/core/MenuItem'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { startUpdatingQuestion } from '../../redux/actions'
const ITEM_HEIGHT = 48

export default ({ node, onRemoveCallback, containerId }) => {
	const [ anchorEl, setAnchorEl ] = useState(null)
	const open = Boolean(anchorEl)
	const intl = useIntl()
	const dispatch = useDispatch()
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	useEffect(() => {}, [])

	return (
		<div>
			<Fragment>
				<IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
					<MoreVertIcon />
				</IconButton>
				<MuiMenu
					id="long-menu"
					anchorEl={anchorEl}
					keepMounted
					open={open}
					onClose={handleClose}
					PaperProps={{
						style: {
							maxHeight: ITEM_HEIGHT * 4.5,
							width: 200
						}
					}}
				>
					<MenuItem
						onClick={() => {
							dispatch(startUpdatingQuestion(containerId))
							handleClose()
						}}
					>
						{intl.formatMessage({ id: 'app.surveyBuilder.editButtonText' })}
					</MenuItem>
				</MuiMenu>
			</Fragment>
		</div>
	)
}
