import React from 'react'
import { TableHead } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import { compose } from 'recompose'
import withStyles from '@material-ui/core/styles/withStyles'
import CircleHeader from './CircleHeader'
import CircleRow from './CircleRow'
import TableBody from '@material-ui/core/TableBody'
import { connect } from 'react-redux'

const styles = {
	root: {
		background: 'black'
	},
	input: {
		color: 'white',
		backgroundColor: 'rgb(36, 48, 65)'
	}
}

const CircleTable = ({ data, user }) => {
	return (
		<div className="admin-table-fullheight">
		    <Table data-testid="circle-table" stickyHeader>
				<TableHead>
					<CircleHeader />
				</TableHead>
				<TableBody style={{ borderTop: 'none' }}>
					{data && data.map((circle) => <CircleRow circle={circle} user={user} key={circle.id} />)}
				</TableBody>
			</Table>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = (dispatch, ownProps) => ({
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))

export default enhance(CircleTable)
