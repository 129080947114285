import React, { useEffect, Fragment } from 'react'
import { useTheme, createMuiTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { compose } from 'recompose'
import { fetchUsers } from '../../../redux/actions'
import { adminUsersSelector } from '../../../redux/selectors'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Grid } from '@material-ui/core'
import FormTextFieldLight from '../../../components/FormTextFieldLight'
import FormFilledNumberTextFieldLight from '../../../components/FormFilledNumberTextFieldLight'
import { reduxForm } from 'redux-form'
import { useDispatch } from 'react-redux'
import { fetchConfiguration, saveConfiguration } from '../../../redux/actions'
import { useSelector, shallowEqual } from 'react-redux'
import { configurationSelector } from '../../../redux/selectors'
import { required, email, birthDate } from '../../../utilities/validators'
const styles = {
	root: {},
	input: {
		color: 'white',
		fontSize: '1.7rem'
	}
}
const emailValidator = email
const requiredValidator = required
const AdminUserOverview = ({ handleSubmit, intl, load, save }) => {
	const theme = useTheme()
	const dispatch = useDispatch()

	useEffect(() => {
		load()
	}, [])
	const onSubmitForm = (values) => {
		save(values)
	}
	return (
		<form onSubmit={handleSubmit(onSubmitForm)} style={{ paddingBottom: '32px' }}>
			<Grid container direction="column" style={{ width: '100%' }}>
				<Grid item>
					<Grid container justify="flex-end">
						<Grid item style={{ justifyContent: 'flex-end' }}>
							<Button
                                variant="contained"
                                color="primary"
								type="submit"
								validators={[ emailValidator ]}
							>
								SAVE
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item style={{ paddingTop: '20px' }}>
					<Typography variant="subtitle1">Price Configuration (USD)</Typography>
				</Grid>
				<Grid item style={{ paddingTop: '20px' }}>
					<Grid container spacing={2}>
						<Grid item>
							<FormFilledNumberTextFieldLight
								name="userPrice"
								label="Price Per User"
								validators={[ requiredValidator ]}
							/>
						</Grid>
						<Grid item>
							<FormFilledNumberTextFieldLight
								name="casePrice"
								label="Price Per Case"
								validators={[ requiredValidator ]}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item style={{ paddingTop: '20px' }}>
					<Typography variant="subtitle1">Promotion Configuration</Typography>
				</Grid>
				<Grid item style={{ paddingTop: '20px' }}>
					<Grid container spacing={2}>
						<Grid item>
							<FormFilledNumberTextFieldLight
								name="thresholdVolume"
								label="Promotion Threshold"
								validators={[ requiredValidator ]}
							/>
						</Grid>
						<Grid item>
							<FormFilledNumberTextFieldLight
								name="percentDiscount"
								label="Percent Discount"
								validators={[ requiredValidator ]}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item style={{ paddingTop: '20px' }}>
					<Typography variant="subtitle1">Billing Contact Email</Typography>
				</Grid>
				<Grid item style={{ paddingTop: '20px' }}>
					<FormTextFieldLight
						name="billingContactEmail"
						label="Email Address"
						validators={[ requiredValidator, emailValidator ]}
					/>
				</Grid>
			</Grid>
		</form>
	)
}

const mapStateToProps = (state, ownProps) => ({
	initialValues: configurationSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	load: () => dispatch(fetchConfiguration()),
	save: (value) => dispatch(saveConfiguration(value))
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'billingAddress',
		enableReinitialize: true
	})
)

export default enhance(AdminUserOverview)
