import { authenticatedFetch } from './base'

export const fetchConfiguration = () => {
	return authenticatedFetch(`/api/configuration`, { method: 'get' })
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(user) {
			return user
		})
}

export const saveConfiguration = (config) => {
	return authenticatedFetch(`/api/configuration`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(config)
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(circle) {
			return circle
		})
}
export const getTerms = () => {
	return authenticatedFetch(`/api/configuration/5`, {
		method: 'GET',
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}

export const saveTerms = (terms) => {
	return authenticatedFetch(`api/configuration/5/terms`, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ value: terms })
	})
		.then(function(response) {
			if (response.status === 200) {
				return response.json()
			}
		})
		.then(function(response) {
			return response
		})
}
