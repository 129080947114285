import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useTheme from '@material-ui/core/styles/useTheme'
import { connect } from 'react-redux'
import { reduxForm, change, formValueSelector } from 'redux-form'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import FormTextField from '../../../components/FormTextField'
import JurisdictionSelector from '../../../components/JurisdictionSelector'
import { required } from '../../../utilities/validators'
import Button from '@material-ui/core/Button'

import { createUser, fetchJurisdictions } from '../../../redux/actions'
import { jurisdictionsSelector } from '../../../redux/selectors'

const requiredValidator = required

const AddAdministratorModal = ({ intl, handleSubmit, submitRequest, isCreating, jurisdictions, loadJurisdictions, setRegion }) => {
	const theme = useTheme()

	const onSubmitForm = (values) => {
		submitRequest(values.email, values.firstName, values.lastName, values.jurisdiction)
	}

	useEffect(() => {
		setRegion(1)
		loadJurisdictions()
	}, [])

	return (
		<form onSubmit={handleSubmit(onSubmitForm)}>
			<Grid
				container
				direction="column"
				justify="flex-start"
				alignItems="center"
				style={{ width: '45rem', padding: '4rem', margin: 'auto', backgroundColor: theme.palette.secondary.main }}
			>
				<Grid item>
					<Typography
						variant="h1"
						style={{
							color: '#fff',
							textAlign: 'center',
							marginBottom: '67px'
						}}
					>
						{intl.formatMessage({ id: 'app.addUser.addUserTitle' })}
					</Typography>
				</Grid>
				<Grid item style={{ width: '100%' }}>
					<FormTextField
						label={intl.formatMessage({ id: 'app.addUser.emailLabel' })}
						fullWidth
						type="text"
						name="email"
						margin="normal"
						variant="filled"
						validators={[ requiredValidator ]}
					/>
				</Grid>
				<Grid item style={{ width: '100%' }}>
					<FormTextField
						label={intl.formatMessage({ id: 'app.addUser.firstNameLabel' })}
						fullWidth
						type="text"
						name="firstName"
						margin="normal"
						variant="filled"
						validators={[ requiredValidator ]}
					/>
				</Grid>
				<Grid item style={{ width: '100%' }}>
					<FormTextField
						label={intl.formatMessage({ id: 'app.addUser.lastNameLabel' })}
						fullWidth
						type="text"
						name="lastName"
						margin="normal"
						variant="filled"
						validators={[ requiredValidator ]}
					/>
				</Grid>
				<Grid item style={{ width: '100%' }}>
					<JurisdictionSelector
						label={intl.formatMessage({ id: 'app.general.jurisdictionLabel' })}
						name="jurisdiction"
						validators={[requiredValidator]}
						data={jurisdictions}
						disabled={true}
						fullWidth
					/>
				</Grid>
				<Grid item>
					<Button
						label={intl.formatMessage({ id: 'app.addUser.addUserUserButtonText' })}
						style={{
							width: '267px',
							marginTop: '40px'
						}}
						disabled={isCreating}
						type="submit"
                        variant="contained"
                        color="primary"
					>
						{intl.formatMessage({ id: 'app.addUser.addUserUserButtonText' })}
					</Button>
				</Grid>
			</Grid>
		</form>
	)
}

const mapStateToProps = (state, ownProps) => ({
	jurisdictions: jurisdictionsSelector(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	loadJurisdictions: () => dispatch(fetchJurisdictions()),
	setRegion: (region) => dispatch(change('registration', 'jurisdiction', region)),
	submitRequest: (email, firstName, lastName, jurisdiction) => dispatch(createUser(email, firstName, lastName, jurisdiction))
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	injectIntl,
	reduxForm({
		form: 'registration',
		touchOnChange: true,
		touchOnBlur: true
	})
)

export default enhance(AddAdministratorModal)
