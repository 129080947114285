import React, { useEffect, useState } from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { newReportExportInitiatedSelector } from '../redux/selectors'
import { Paper } from '@material-ui/core'
import CalmButton from './CalmButton'
import { clearReportExportInitiatedMessage } from '../redux/actions'

const ReportExportInitiatedNotificationHeader = ({
	intl,
	reportExportInitiated,
	clearReportExportInitiatedMesssage
}) => {
	useEffect(
		() => {
			clearTimeout(hideTimer)
			setShow(reportExportInitiated)
			if(reportExportInitiated) {
				setHideTimer(setTimeout(() => clearReportExportInitiatedMesssage(), 10000))
			}
		},
		[reportExportInitiated]
	)
	
	const [show, setShow] = useState(false)
	const [ hideTimer, setHideTimer] = useState(null)
	
	return (
		<Paper
			data-testid='report-export-initiated-header'
			position='fixed' style={{ background: '#09203f' }} square={true}>
			<Slide direction='down' in={show} mountOnEnter unmountOnExit>
				<Toolbar style={{ margin: '0 auto' }}>
					<Grid container direction='row' data-testid="report-export-initiated-notif-header" style={{ padding: '10px 0px', justifyContent: 'center', alignItems: 'center' }}>
						<Grid item style={{ textAlign: 'center', flex: 0.7 }} >
							<Typography
								style={{
									color: 'white',
									fontSize: '16px'
								}}>
								{intl.formatMessage({ id: 'app.reports.reportExportIsInitiated' })}
							</Typography>
						</Grid>
						<Grid item style={{ textAlign: 'center', flex: 0.3 }}>
							<CalmButton
								data-testid="export-raw-circle-report-message-close"
								variant="contained"
								style={{
									margin: "15px 0",
									minWidth: "210px",
								}}
								color="primary"
								onClick={clearReportExportInitiatedMesssage}
							>
								{intl.formatMessage({ id: "app.general.closeModalLabel" })}
							</CalmButton>
						</Grid>
					</Grid>
				</Toolbar>
			</Slide>
		</Paper>
	)
}

const mapStateToProps = (state) => ({
	reportExportInitiated: newReportExportInitiatedSelector(state)
})

const mapDispatchToProps = (dispatch) => ({
	clearReportExportInitiatedMesssage: () => dispatch(clearReportExportInitiatedMessage())
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)

export default enhance(ReportExportInitiatedNotificationHeader)
