import { Table, TableBody, TableRow } from "@material-ui/core"
import React, { Fragment } from "react"
import { connect } from "react-redux"
import { compose } from "recompose"
import EntityAuditTableHeader from "./EntityAuditTableHeader"
import EntityAuditActionTableCell from "./EntityAuditActionTableCell"
import EntityAuditLocalizationTableCell from "./EntityAuditLocalizationTableCell"
import EntityAuditEntityTableCell from "./EntityAuditEntityTableCell"
import EntityAuditUserTableCell from "./EntityAuditUserTableCell"
import EntityAuditActionDateTableCell from "./EntityAuditActionDateTableCell"
import EntityAuditValueTableCell from "./EntityAuditValueTableCell"
import EntityAuditCommentsTableCell from "./EntityAuditCommentsTableCell"


const EntityAuditTable = ({
    logs,
    ...other
}) => {
    return (
        <Table
            {...other}
        >
            <EntityAuditTableHeader />
            <TableBody>
                {
                    logs?.map(x => {
                        return (
                            <TableRow>
                                <EntityAuditEntityTableCell entityLog={x} style={{ width: '5%' }} />
                                <EntityAuditLocalizationTableCell entityLog={x} style={{ width: '5%' }} />
                                <EntityAuditActionTableCell entityLog={x} style={{ width: '10%' }} />
                                <EntityAuditValueTableCell entityLog={x} />
                                <EntityAuditUserTableCell entityLog={x} style={{ width: '10%' }} />
                                <EntityAuditActionDateTableCell entityLog={x} style={{ width: '10%' }} />
                                <EntityAuditCommentsTableCell entityLog={x} style={{width: '10%' }} />
                            </TableRow>
                        )
                    })
                }
            </TableBody>
        </Table>
    )
}

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = (dispatch, ownProps) => ({
})

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(EntityAuditTable)
