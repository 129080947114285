import * as api from '../../../apis/statistics'

const FETCH_STATISTICS_REQUESTED = 'FETCH_STATISTICS_REQUESTED'
const FETCH_STATISTICS_SUCCESS = 'FETCH_STATISTICS_SUCCESS'
const FETCH_STATISTICS_FAILED = 'FETCH_STATISTICS_FAILED'

export const fetchStatistics = () => (dispatch) => {
	dispatch({ type: FETCH_STATISTICS_REQUESTED, data: {} })

	api.fetchStats().then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_STATISTICS_SUCCESS, data: response })
		} else {
			return dispatch({ type: FETCH_STATISTICS_FAILED, data: {} })
		}
	})
	return
}

const initial = {
	statistics: null
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case FETCH_STATISTICS_SUCCESS:
			return { ...state, statistics: action.data }
		default:
			return { ...state }
	}
}

export const statisticsSelector = (state) => {
	return state.statistics.statistics
}
